import { CustomText } from "@temp/components";
import { Colors, Images } from "@temp/assets";
import React from "react";
import "../LoadDetailPoster/style.scss";
import { isBrowser } from "react-device-detect";
import { Accordion, AccordionSummary } from "@mui/material/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import rootReducer from "../../../store/reducers";
import { useSelector } from "react-redux";
import moment from "moment";
export type RootState = ReturnType<typeof rootReducer>;
import { RenderRTL } from "./RenderRTL";
import { phoneNumberFormater } from "@temp/utils/helper";
export const PosterContract = ({
  loadDetail,
  expand,
  setExpand,
  bid_deposit_fee_amount,
  set_bid_deposit_fee_amount,
  type,
  for_bit_amount,
  is_recipient = true
}) => {
  const metaData = useSelector((state: RootState) => state.Auth.metaData);

  let format_num = loadDetail?.poster?.phone_no
  let new_phone = format_num?.split("")
  new_phone?.splice(3, 0, " ");
 
  return (
    <div className="accordion-container">
      <Accordion
        onChange={() => setExpand(!expand)}
        expanded={expand}
        className="accordion-background"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <CustomText.OpenSansSemiBold
            textStyle={{
              fontSize: "0.875rem",
              color: Colors.Black,
            }}
          >
            <span className="titleName">Contract Details</span>
            <span className="seperator">/</span>
            <span className="urduLabel">معاہدے کی تفصیلات</span>

          </CustomText.OpenSansSemiBold>
        </AccordionSummary>
        <div className="accordion-container">
          <div className="accordion-content">
            <div className="fw-5 grayLabel">
              <span className="titleName">Request From</span>
              <span className="seperator">/</span>
              <span className="urduLabel">درخواست بھجنے والا</span>
            </div>
            <div className="load-detail-user-container">
              <div className="load-detail-user">
                <img
                  src={
                    loadDetail?.poster?.picture || metaData?.default_avatar
                  }
                />
              </div>
              <div className="user-phone-whatsapp">
                <div className="user-detail">
                  <div className="">
                    {loadDetail?.poster?.name}
                  </div>
                  {
                    loadDetail?.poster?.phone_no && (
                      <div className="title">
                        {phoneNumberFormater(loadDetail?.poster?.phone_code, loadDetail?.poster?.phone_no)}
                      </div>
                    )
                  }
                </div>
                {
                  loadDetail?.poster?.phone_no && (
                    <div className="whatsapp-button-container">
                      {isBrowser ? (
                        <>
                          <a
                            href={`tel:92${loadDetail?.poster?.phone_no}`}
                            target="blank"
                            style={{ marginRight: 30 }}
                            className="phone-whatsapp-button"
                          >
                            <img
                              alt=""
                              style={{ height: "100%" }}
                              src={Images.Phone}
                            />
                          </a>
                          <a
                            href={`https://wa.me/+92${loadDetail?.poster?.phone_no}`}
                            target="blank"
                            className="phone-whatsapp-button"
                          >
                            <img
                              alt=""
                              style={{ height: "100%" }}
                              src={Images.WhatsAppBgGreen}
                            />
                          </a>
                        </>
                      ) : (
                        <>
                          <img
                            alt=""
                            className="phone-whatsapp-button"
                            style={{ height: "100%", marginRight: 30 }}
                            src={Images.Phone}
                            onClick={() => {
                              if (sendEventToAndroid("loadDetailPhone")) {
                                window.Android.loadDetailPhone(
                                  String(loadDetail?.poster?.phone_no)
                                );
                              } else if (
                                sendEventToiOS("loadDetailPhone")
                              ) {
                                window.webkit.messageHandlers.loadDetailPhone.postMessage(
                                  JSON.stringify({
                                    phoneNumber:
                                      loadDetail?.poster?.phone_no,
                                  })
                                );
                              } else {
                              }
                            }}
                          />
                          <img
                            onClick={() => {
                              if (
                                sendEventToAndroid("loadDetailWhatsapp")
                              ) {
                                window.Android.loadDetailWhatsapp(
                                  String(loadDetail?.poster?.phone_no)
                                );
                              } else if (
                                sendEventToiOS("loadDetailWhatsapp")
                              ) {
                                window.webkit.messageHandlers.loadDetailWhatsapp.postMessage(
                                  JSON.stringify({
                                    phoneNumber:
                                      loadDetail?.poster?.phone_no,
                                  })
                                );
                              } else {
                              }
                            }}
                            className="phone-whatsapp-button"
                            alt=""
                            style={{ height: "100%" }}
                            src={Images.WhatsAppBgGreen}
                          />
                        </>
                      )}
                    </div>
                  )
                }
              </div>
            </div>
            <RenderRTL
              title={"Contract Name"}
              val={loadDetail?.contract_name}
              is_recipient={is_recipient}
              sep={"/"}
              urdutitle={"معاہدے کا نام"}
            />
            <RenderRTL
              title={"Reference Number"}
              sep={"/"}
              urdutitle={"حوالہ نمبر"}
              val={loadDetail?.ref_no || "--"}
              is_recipient={is_recipient}
            />
            <RenderRTL
              title={"Inquiry Type"}
              sep={"/"}
              urdutitle={"انکوائری کی قسم"}
              val={loadDetail?.inquiry_type?.title}
              is_recipient={is_recipient}
            />
            <RenderRTL
              title={"Contract Type"}
              sep={"/"}
              urdutitle={"معاہدے کی قسم"}
              val={loadDetail?.load_type?.title}
              is_recipient={is_recipient}
            />
            {
              loadDetail?.cargo_description ?
                <RenderRTL
                  title={"Cargo Description"}
                  sep={"/"}
                  urdutitle={"کارگو کی تفصیل"}
                  val={loadDetail?.cargo_description}
                  is_recipient={is_recipient}
                /> : null
            }

            <div className="grayLabel" style={{ marginBottom: 4 }}>
              Contract Period
              <span className="seperator">/</span>
              <span className="urduLabel">معاہدہ کی مدت</span>
            </div>

            <div className="contract-period-detail">
              <span>
                <span style={{
                  color: "#8A8888",

                }}>Start Date</span>
                <span className="seperator" style={{
                  color: "#8A8888",

                }}>/</span>
                <span className="urduLabel" style={{
                  color: "#8A8888",

                }}>شروع کرنے کی تاریخ</span>
              </span>
              <CustomText.OpenSansSemiBold textStyle={{ marginLeft: 5 }}>
                {`${moment(
                  loadDetail?.contract_start_date
                ).format(
                  "DD MMM, YYYY"
                )}`}
              </CustomText.OpenSansSemiBold>
            </div>

            <div className="contract-period-detail">
              <span>
                <span style={{
                  color: "#8A8888",

                }}>End Date</span>
                <span className="seperator" style={{
                  color: "#8A8888",

                }}>/</span>
                <span className="urduLabel" style={{
                  color: "#8A8888",

                }}>آخری تاریخ</span>
              </span>

              <CustomText.OpenSansSemiBold textStyle={{ marginLeft: 5 }}>
                {`${moment(
                  loadDetail.contract_end_date
                ).format(
                  "DD MMM, YYYY"
                )}`}
              </CustomText.OpenSansSemiBold>
            </div>

            {
              loadDetail?.is_container_deposit_by_transporter && !loadDetail?.is_quote_price_deposit_fee_inclusive ?
                <div className="inputLabel" style={{
                  PointerEvent: !dateIsGreater(loadDetail?.deadline_at) ? 'none' : 'auto'
                }}>
                  <div
                    className="label"
                  >
                    Deposit fee amount (Without GST)
                  </div>
                  <div className="field">
                    <CurrencyFormat
                      style={{
                        height: 35
                      }}
                      value={bid_deposit_fee_amount}
                      onValueChange={(values) => {
                        const { value } = values;
                        set_bid_deposit_fee_amount(value)
                      }}
                      disabled={!dateIsGreater(loadDetail?.deadline_at) ? true : false}
                      thousandSeparator={true}
                      suffix={" (PKR)"}
                    />
                  </div>
                </div> : null
            }

            <div style={{ height: 16 }}></div>
          </div>

        </div>
      </Accordion>
    </div>
  );
};
