import { MenuItem, Select } from "@mui/material/";
import { VehicleIncDec } from "@temp/components";
import React from "react";
let timmerRef_weight_inc = null;
let timmerRef_weight_dec = null;

export const ItemFields = ({ item, new_load_detail, set_new_load_detail, index, postLoadMeta, handlePriceChange, updateValue }) => {
    return (
        <div>
            <div className="inputField">
                <div className="label">Dimensions</div>
                <div className="field">
                    <div className={`input-container-new ${item?.dimensions ? 'filled' : ''}`}>
                        <input
                            type={"text"}
                            onChange={(e) => {
                                if (e.target.value.length <= 30) {
                                    let data = { ...new_load_detail }
                                    let obj = data?.categories[index]
                                    obj["dimensions"] = e.target.value
                                    set_new_load_detail(data)
                                } else { }
                            }}
                            value={item.dimensions}
                            aria-describedby="standard-weight-helper-text"
                            className={`new-inut-field`}
                            placeholder={"45x76x26"}
                        />
                        <span className="inputSlide"></span>
                    </div>
                </div>
            </div>

            <div className="fw-5">
                Unit of Dimension
            </div>
            <Select
                placeholder="Select Hours"
                name="vehicle_type"
                className="add-vehicle-select-box2 customSelectBox"
                style={{ marginBottom: '1.8rem' }}
                value={item?.unit_dimension_value}
                onChange={(e) => {
                    let data = { ...new_load_detail }
                    let obj = data?.categories[index]
                    obj["unit_dimension_value"] = e.target.value
                    set_new_load_detail(data)
                }}
            >
                <MenuItem value={"Select Unit"}>
                    Select Unit
                </MenuItem>
                {postLoadMeta?.unit_basis_dimensions?.map((dimen, index) => {
                    return (
                        <MenuItem
                            key={index}
                            selected={
                                dimen == item.unit_dimension_value
                            }
                            value={dimen}
                        >
                            {dimen}
                        </MenuItem>
                    );
                })}
            </Select>

            <VehicleIncDec
                className="labelWithIncrementField"
                text="Item Quantity"
                value={item?.unit_quantity}
                name={"item_quantity"}
                withInputBox={true}
                updateValue={(val) => {
                    
                    if(updateValue){
                        updateValue({key:"unit_quantity", val:val})
                    }
                }}
                onTouchStart={(type) => {
                    if (type == "inc") {
                        handlePriceChange({ ...new_load_detail, unit_quantity: item.unit_quantity++ });
                        timmerRef_weight_inc = setInterval(() => {
                            handlePriceChange({ ...new_load_detail, unit_quantity: item.unit_quantity++ });
                        }, 300);
                    } else {
                        if (item?.unit_quanity > 1) {
                            handlePriceChange({ ...new_load_detail, unit_quantity: item.unit_quantity-- });
                            timmerRef_weight_dec = setInterval(() => {
                                handlePriceChange({ ...new_load_detail, unit_quantity: item.unit_quantity-- });
                            }, 300);
                        }
                    }
                }}
                onTouchEnd={() => {
                    clearInterval(timmerRef_weight_inc);
                    clearInterval(timmerRef_weight_dec);
                }}
                onMouseDown={(type) => {
                    if (type == "inc") {
                        handlePriceChange({ ...new_load_detail, unit_quantity: item.unit_quantity++ });
                        timmerRef_weight_inc = setInterval(() => {
                            handlePriceChange({ ...new_load_detail, unit_quantity: item.unit_quantity++ });
                        }, 300);
                    } else {
                        
                        if (item?.unit_quantity > 1) {
                            handlePriceChange({ ...new_load_detail, unit_quantity: item.unit_quantity-- });
                            timmerRef_weight_dec = setInterval(() => {
                                handlePriceChange({ ...new_load_detail, unit_quantity: item.unit_quantity-- });
                            }, 300);
                        }
                    }
                }}
                onMouseUp={() => {
                    clearInterval(timmerRef_weight_inc);
                    clearInterval(timmerRef_weight_dec);
                }}
            />

            <div className="simpleLabel fw-5">Item Description</div>
            <div
                className="textareaWrapper"
                style={{ marginBottom: 25 }}
            >
                <textarea
                    maxLength={250}
                    onChange={(e) => {
                        let data = { ...new_load_detail }
                        let obj = data?.categories[index]
                        obj["unit_description"] = e.target.value
                        set_new_load_detail(data)
                    }}
                    value={item?.unit_description}
                    id={"cargo_description"}
                    name={"cargo_description"}
                    placeholder="Optional"
                />
            </div>



        </div>
    )
}