import { CustomModal, CustomText, CustomizedSelects, PrimaryButton, SimpleInput, SimpleModal } from "@temp/components";
import React, { useState, useEffect } from "react";
import "../LoadDetailPoster/style.scss";
import { Images, Colors } from "@temp/assets";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
import { LOAD_TYPE } from "@temp/constants";
import { getFullTime, triggerMixpanelEvent } from "@temp/utils/helper";
import moment from "moment";
import { LoadAction } from "@temp/store/actions";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import InfoIcon from "@mui/icons-material/Info";
import Radio from "@mui/material//Radio";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

var numeral = require("numeral");


export type RootState = ReturnType<typeof rootReducer>;
export const AwardedVehicles = ({
  index,
  addVehicle,
  final_category,
  addRef,
  lclDetail,
  loadId,
  loadDetail,
  final_cat_id,
  userRole,
  orignal_recipient_id,
  required_vehicles
}) => {

  const location = useLocation();
  const { state } = location;
  const [showModal, setShowModal] = useState(false)
  const [showTrackerModal, setShowTrackerModal] = useState(false);
  const [tplDeviceImei, setTplDeviceImei] = useState("");
  const [editData, setEditData] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory();
  const ProfileData = useSelector((state: RootState) => state?.Profile?.profile);
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const deviceImei = useSelector((state: RootState) => metaData?.vehicle_tracking_services);
  const [DeviceImeiDropDownLabel, setDeviceImeiDropDownLabel] = useState(null);
  const [DeviceImeiDropDownValue, setDeviceImeiDropDownValue] = useState(null);
  const [showModalWaterMark, setShowModalWaterMark] = useState(false);
  const [showInfoWaterMark, setShowInfoWaterMark] = useState("");

  let find =
    final_category?.vehicles_drivers_refs.filter(
      (cat) => cat?.vehicle_no == index && cat?.recipient_id == orignal_recipient_id
    ) ||
    lclDetail?.vehicles_drivers_refs?.filter(
      (cat2) => cat2?.vehicle_no == index && cat2?.recipient_id == orignal_recipient_id
    );
  let added_by_recipient = final_category?.vehicles_drivers_refs.filter(
    (cat) => cat?.recipient_id == orignal_recipient_id
  ) ||
    lclDetail?.vehicles_drivers_refs?.filter(
      (cat2) => cat2?.recipient_id == orignal_recipient_id
    )
  let already_added_by_recipient = added_by_recipient?.sort((a, b) => a?.vehicle_no - b?.vehicle_no)?.map((item, ind) => {
    return {
      ...item,
      is_container_reff: ind + 1 <= required_vehicles ? true : false,
    }
  });

  const handleSubmit = (params) => {
    setShowModal(false)
    let payload = {
      load_id: loadDetail?.id,
      id: editData?.vehicle?.id,
      brand: editData?.vehicle?.brand,
      model: editData?.vehicle?.model,
      master_vehicle_type_id: editData?.vehicle?.master_vehicle_type?.id,
      color: editData?.vehicle?.color,
      plate_number: editData?.vehicle?.plate_number,
      year: editData?.vehicle?.year,
      driver_phone_no: editData?.driver?.phone_no,
      driver_phone_code: "92",
      driver_name: editData?.driver?.name,
      link_device: params == "add" ? true : false,
      tracking_vehicle_reg_no: params == "add" ? tplDeviceImei : "",
      tracking_service_provider: params == "add" ? DeviceImeiDropDownValue : ""
    }

    // return
    dispatch(LoadAction.addTrackingId(payload))
  }

  const handleRemove = () => {
    setTplDeviceImei("")
    // setDeviceImeiDropDownValue("")
    // setDeviceImeiDropDown("")
    handleSubmit('remove')
  }
  return (
    <div>
      <div
        className={`awarded-vehicle-list-recipient`}
      >
        {find.length ? (
          <div>
            {find.map((item, ind) => {
              if (item.recipient_id === orignal_recipient_id)
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      if (item?.status == 1) {
                        triggerMixpanelEvent("View Active Load", "view active load from awarded vehicle list")
                        history.push(`/job-loads/${loadId}/active/${item?.id}`, {
                          fromPoster: userRole == "posted" ? true : false,
                          vehicle_data: {
                            index, cat_id: final_category?.id ? final_category?.id : final_cat_id ? final_cat_id : null,
                            containers: already_added_by_recipient,
                            required_vehicles,
                            load_id: loadDetail?.id,
                            vehicle: item?.vehicle
                          }
                        });
                      }
                    }}
                    style={{
                      paddingTop: 5,
                      cursor: item?.status == 1 ? 'pointer' : 'default'
                    }}
                    className={ind !== find.length - 1 ? "addRefAndDriver" : ""}
                  >
                    <div className="flex_row_div">
                      <CustomText.OpenSansSemiBold textStyle={{ marginLeft: 7 }}>Details of Vehicle {index}</CustomText.OpenSansSemiBold>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: 12
                        }}
                      >
                        <CustomText.OpenSansSemiBold
                          textStyle={{
                            fontSize: "0.688rem",
                            color:
                              item?.status == 0
                                ? Colors.Orange
                                : item?.status == 1
                                  ? Colors.Primary
                                  : Colors.Red,
                          }}
                        >
                          {item?.status == 0 && Number(moment(loadDetail?.deadline_at).format('x')) < getFullTime(new Date())
                            ? "Awaiting response"
                            : item?.status == 0 && Number(moment(loadDetail?.deadline_at).format('x')) > getFullTime(new Date()) ?
                              "No Response"
                              : item?.status == 1
                                ? "View"
                                : item?.status == 2
                                  ? "Declined"
                                  : "Completed"}
                        </CustomText.OpenSansSemiBold>
                        <img
                          style={{
                            height: 16,
                            marginLeft: 5,
                          }}
                          src={Images.BlueRightArrow}
                        />
                      </div>
                    </div>

                    <div>
                      {
                        item?.is_replaced == 1 && item?.replaced_by !== null ?
                          <div className="vehicleDetailsTextWrap">
                            <div className="fw-5" style={{
                              marginLeft: 5
                            }}>
                              Replaced Vehicle
                            </div>
                            <CustomText.OpenSansRegular textStyle={{
                              marginLeft: 5,
                              display: 'flex'
                            }}>

                              <span className="leftReplaceVehicle">{`From - ${item?.replaced_by?.vehicle?.plate_number} `}
                                <span>{`${moment(item?.replaced_by?.created_at).format('DD-MM-YYYY hh:mm A')} `}</span>
                              </span>
                              <span className="rightReplaceVehicle">{`To - ${item?.vehicle?.plate_number} `}
                                <span>{`${moment(item?.created_at).format('DD-MM-YYYY hh:mm A')} `}</span>
                              </span>
                            </CustomText.OpenSansRegular>
                          </div>
                          : null
                      }

                      {
                        item?.is_replaced == 0 ?
                          <div className="vehicleDetailsTextWrap">
                            <div className="fw-5" style={{
                              marginLeft: 5
                            }}>
                              Plate Number
                            </div>
                            <CustomText.OpenSansRegular textStyle={{
                              marginLeft: 5
                            }}>
                              {`${item?.vehicle?.plate_number} (${moment(item?.created_at).format('DD-MM-YYYY hh:mm A')})`}
                            </CustomText.OpenSansRegular>
                          </div>
                          : null}

                    </div>

                    {item?.container_ref_one || item?.no_of_packages ? (
                      <>
                        <div style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: 'space-between',
                          paddingRight: 15
                        }}>
                          <div>
                            {
                              item?.container_ref_one &&
                              <div className="vehicleDetailsTextWrap">
                                <div className="flexRowWrap">
                                  <div className="fw-5" style={{
                                    marginLeft: 5
                                  }}>
                                    {/* Container Number 1 */}
                                    {`${item?.container_ref_one && item?.container_ref_two ? "Container Number 1" : "Container Number"}`}
                                  </div>
                                  {
                                    item?.container_ref_one || item?.container_ref_two || item?.weight_one || item?.no_of_packages || item?.weight_two
                                      ?
                                      <>
                                        <div className="icons-edit"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            if (loadDetail?.status_name == "Completed") {

                                            }
                                            else if (final_category) {
                                              addRef({
                                                index,
                                                cat_id: final_category?.id,
                                                ref_data: item,
                                                final_category,
                                                category: true,
                                                loadDetail: loadDetail,
                                                is_cross_stuff: final_cat_id ? true : false,
                                                already_added: already_added_by_recipient,

                                              });
                                            } else {
                                              addRef({
                                                index,
                                                cat_id: final_category?.id,
                                                ref_data: item,
                                                final_category: lclDetail,
                                                category: false,
                                                loadDetail: loadDetail,
                                                is_cross_stuff: final_cat_id ? true : false,
                                                already_added: already_added_by_recipient,
                                              });
                                            }
                                          }}
                                        ></div>
                                      </> : null
                                  }
                                </div>
                                <CustomText.OpenSansRegular textStyle={{
                                  marginLeft: 5
                                }}>
                                  {`${item?.container_ref_one}`}
                                </CustomText.OpenSansRegular>
                              </div>
                            }
                            {
                              item?.weight_one &&
                              <div className="vehicleDetailsTextWrap">
                                <div className="flexRowWrap">
                                  <div className="fw-5" style={{
                                    marginLeft: 5
                                  }}>
                                    {/* Weight  */}
                                    {`${item?.container_ref_one && item?.container_ref_two ? "Weight 1" : "Weight"}`}
                                  </div>
                                  {
                                    item?.container_ref_one || item?.container_ref_two || item?.weight_one || item?.no_of_packages || item?.weight_two
                                      ?
                                      <>
                                        <div className="icons-edit"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            if (loadDetail?.status_name == "Completed") {

                                            }

                                            if (final_category) {
                                              addRef({
                                                index,
                                                cat_id: final_category?.id,
                                                ref_data: item,
                                                final_category,
                                                category: true,
                                                loadDetail: loadDetail,
                                                is_cross_stuff: final_cat_id ? true : false,
                                                already_added: already_added_by_recipient,

                                              });
                                            } else {
                                              addRef({
                                                index,
                                                cat_id: final_category?.id,
                                                ref_data: item,
                                                final_category: lclDetail,
                                                category: false,
                                                loadDetail: loadDetail,
                                                is_cross_stuff: final_cat_id ? true : false,
                                                already_added: already_added_by_recipient,
                                              });
                                            }
                                          }}
                                        ></div>
                                      </> : null
                                  }
                                </div>
                                <CustomText.OpenSansRegular textStyle={{
                                  marginLeft: 5
                                }}>
                                  {`${numeral(item?.weight_one).format("0,0.00")} Kg`}
                                </CustomText.OpenSansRegular>
                              </div>
                            }
                            {
                              item?.container_ref_two &&
                              <div className="vehicleDetailsTextWrap">
                                <div className="flexRowWrap">
                                  <div className="fw-5" style={{
                                    marginLeft: 5
                                  }}>
                                    Container Number 2
                                  </div>
                                  {
                                    item?.container_ref_one || item?.container_ref_two || item?.weight_one || item?.no_of_packages || item?.weight_two
                                      ?
                                      <>
                                        <div className="icons-edit"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            if (loadDetail?.status_name == "Completed") {

                                            }

                                            if (final_category) {
                                              addRef({
                                                index,
                                                cat_id: final_category?.id,
                                                ref_data: item,
                                                final_category,
                                                category: true,
                                                loadDetail: loadDetail,
                                                is_cross_stuff: final_cat_id ? true : false,
                                                already_added: already_added_by_recipient,

                                              });
                                            } else {
                                              addRef({
                                                index,
                                                cat_id: final_category?.id,
                                                ref_data: item,
                                                final_category: lclDetail,
                                                category: false,
                                                loadDetail: loadDetail,
                                                is_cross_stuff: final_cat_id ? true : false,
                                                already_added: already_added_by_recipient,
                                              });
                                            }
                                          }}
                                        ></div>
                                      </> : null
                                  }
                                </div>
                                <CustomText.OpenSansRegular textStyle={{
                                  marginLeft: 5
                                }}>
                                  {`${item?.container_ref_two}`}
                                </CustomText.OpenSansRegular>
                              </div>
                            }

                            {
                              item?.no_of_packages &&
                              <div className="vehicleDetailsTextWrap">
                                <div className="flexRowWrap">
                                  <div className="fw-5" style={{
                                    marginLeft: 5
                                  }}>
                                    No. of Packages
                                  </div>
                                  {
                                    item?.container_ref_one || item?.container_ref_two || item?.weight || item?.no_of_packages || item?.weight_two
                                      ?
                                      <>
                                        <div className="icons-edit"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            if (loadDetail?.status_name == "Completed") {

                                            }

                                            if (final_category) {
                                              addRef({
                                                index,
                                                cat_id: final_category?.id,
                                                ref_data: item,
                                                final_category,
                                                category: true,
                                                loadDetail: loadDetail,
                                                is_cross_stuff: final_cat_id ? true : false,
                                                already_added: already_added_by_recipient,

                                              });
                                            } else {
                                              addRef({
                                                index,
                                                cat_id: final_category?.id,
                                                ref_data: item,
                                                final_category: lclDetail,
                                                category: false,
                                                loadDetail: loadDetail,
                                                is_cross_stuff: final_cat_id ? true : false,
                                                already_added: already_added_by_recipient,
                                              });
                                            }
                                          }}
                                        ></div>
                                      </> : null
                                  }
                                </div>
                                <CustomText.OpenSansRegular textStyle={{
                                  marginLeft: 5
                                }}>
                                  {`${item?.no_of_packages}`}
                                </CustomText.OpenSansRegular>
                              </div>
                            }

                            {
                              item?.weight_two &&
                              <div className="vehicleDetailsTextWrap">
                                <div className="flexRowWrap">
                                  <div className="fw-5" style={{
                                    marginLeft: 5
                                  }}>
                                    Weight 2
                                  </div>
                                  {
                                    item?.container_ref_one || item?.container_ref_two || item?.weight_one || item?.no_of_packages || item?.weight_two
                                      ?
                                      <>
                                        <div className="icons-edit"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            if (loadDetail?.status_name == "Completed") {

                                            }

                                            if (final_category) {
                                              addRef({
                                                index,
                                                cat_id: final_category?.id,
                                                ref_data: item,
                                                final_category,
                                                category: true,
                                                loadDetail: loadDetail,
                                                is_cross_stuff: final_cat_id ? true : false,
                                                already_added: already_added_by_recipient,

                                              });
                                            } else {
                                              addRef({
                                                index,
                                                cat_id: final_category?.id,
                                                ref_data: item,
                                                final_category: lclDetail,
                                                category: false,
                                                loadDetail: loadDetail,
                                                is_cross_stuff: final_cat_id ? true : false,
                                                already_added: already_added_by_recipient,
                                              });
                                            }
                                          }}
                                        ></div>
                                      </> : null
                                  }
                                </div>
                                <CustomText.OpenSansRegular textStyle={{
                                  marginLeft: 5
                                }}>
                                  {`${numeral(item?.weight_two).format("0,0.00")} Kg`}
                                </CustomText.OpenSansRegular>
                              </div>
                            }

                          </div>
                          <div>
                            <div
                              style={{
                                height: 50,
                                width: 55,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                                setEditData(item)
                                setTplDeviceImei(item?.vehicle?.tracking_vehicle_reg_no)
                                setDeviceImeiDropDownValue(item?.vehicle?.tracking_service_provider ? item?.vehicle?.tracking_service_provider : "")
                                setDeviceImeiDropDownLabel(deviceImei?.find((itm)=>itm?.value === item?.vehicle?.tracking_service_provider)?.label)
                                setShowModal(loadDetail?.status_name == "Completed" ? false : true)

                              }}>
                              <img style={{ height: 30 }}
                                src={item?.vehicle?.tracking_vehicle_reg_no && item?.vehicle?.tracking_service_provider ?
                                  Images.TrackingPinLocation
                                  : Images?.TrackingPinLocationBlue} />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          onClick={(e) => {
                            e.stopPropagation()
                            if (final_category) {
                              if (loadDetail?.status_name == "Completed") {

                              }

                              addRef({
                                index,
                                cat_id: final_category?.id,
                                ref_data: item,
                                final_category,
                                category: true,
                                loadDetail: loadDetail,
                                is_cross_stuff: final_cat_id ? true : false,
                                already_added: already_added_by_recipient,
                              });
                            } else {
                              addRef({
                                index,
                                cat_id: final_category?.id,
                                ref_data: item,
                                final_category: lclDetail,
                                category: false,
                                loadDetail: loadDetail,
                                is_cross_stuff: final_cat_id ? true : false,
                                already_added: already_added_by_recipient,
                              });
                            }
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            paddingLeft: 10,
                            paddingRight: 15,
                            pointerEvents: loadDetail?.show_recipient_mark_complete_btn ? 'auto' : 'none'
                          }}
                        >
                          {
                            loadDetail?.is_cross_stuffing_required ?
                              <div>
                                <CustomText.OpenSansSemiBold
                                  textStyle={{
                                    color: Colors.NavyBlue,
                                    fontSize: "0.75rem",
                                    textDecoration: "underline",
                                    marginTop: 5,
                                    marginBottom: 5,
                                  }}
                                >
                                  {
                                    index <= required_vehicles ? "Add Container Number" : "Enter Number of Packages"
                                  }
                                </CustomText.OpenSansSemiBold>
                              </div> :
                              <div>
                                <CustomText.OpenSansSemiBold
                                  textStyle={{
                                    color: Colors.NavyBlue,
                                    fontSize: "0.75rem",
                                    textDecoration: "underline",
                                    marginTop: 5,
                                    marginBottom: 5,
                                  }}
                                >
                                  {
                                    loadDetail?.load_type?.title == LOAD_TYPE.fcl && !loadDetail?.is_cross_stuffing_required
                                      ? "Add Container Number" : "Enter Number of Packages"
                                  }
                                </CustomText.OpenSansSemiBold>
                              </div>
                          }
                          <div style={{
                            height: 50,
                            width: 55,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                            onClick={(e) => {
                              e.stopPropagation()
                              setEditData(item)
                              setTplDeviceImei(item?.vehicle?.tracking_vehicle_reg_no)
                              setDeviceImeiDropDownValue(item?.vehicle?.tracking_service_provider ? item?.vehicle?.tracking_service_provider : "")
                              setDeviceImeiDropDownLabel(deviceImei?.find((itm)=>itm?.value === item?.vehicle?.tracking_service_provider)?.label)
                              setShowModal(loadDetail?.status_name == "Completed" ? false : true)

                            }}>
                            <img style={{ height: 30 }}
                              src={item?.vehicle?.tracking_vehicle_reg_no && item?.vehicle?.tracking_service_provider ?
                                Images.TrackingPinLocation
                                : Images?.TrackingPinLocationBlue} />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              else {
                return <div></div>;
              }
            })}
            {find?.filter((item) => item.status == 2).length == find.length && (
              <div
                style={{
                  borderTop: "1px solid #c0c0cd",
                  marginTop: 5,
                  paddingTop: 5,
                  pointerEvents: loadDetail?.show_recipient_mark_complete_btn ? 'auto' : 'none'
                }}
              >
                <div
                  onClick={() => {
                    if (final_category) {
                      addVehicle({ index, cat_id: final_category?.id ? final_category?.id : final_cat_id ? final_cat_id : null, containers: already_added_by_recipient, required_vehicles: loadDetail?.is_cross_stuffing_required || loadDetail?.is_unit_basis ? null : required_vehicles });

                    } else {
                      addVehicle({ index, cat_id: final_category?.id ? final_category?.id : final_cat_id ? final_cat_id : null, containers: already_added_by_recipient, required_vehicles: loadDetail?.is_cross_stuffing_required || loadDetail?.is_unit_basis ? null : required_vehicles });

                    }
                  }}
                  className="add-vehicle-button"
                >
                  <img style={{ height: 16 }} src={Images.AddBlueButton} />
                  <CustomText.OpenSansRegular textStyle={{ marginLeft: 3 }}>
                    {`Add Vehicle ${index} Details`}
                  </CustomText.OpenSansRegular>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            onClick={() => {
              if (final_category) {
                addVehicle({ index, cat_id: final_category?.id ? final_category?.id : final_cat_id ? final_cat_id : null, containers: already_added_by_recipient, required_vehicles: loadDetail?.is_cross_stuffing_required || loadDetail?.is_unit_basis ? null : required_vehicles });
              } else {
                addVehicle({ index, cat_id: final_category?.id ? final_category?.id : final_cat_id ? final_cat_id : null, containers: already_added_by_recipient });
              }
            }}
            className="add-vehicle-button"
            style={{
              pointerEvents: loadDetail?.show_recipient_mark_complete_btn ? 'auto' : 'none'
            }}
          >
            <img style={{ height: 16 }} src={Images.AddBlueButton} />
            <CustomText.OpenSansRegular textStyle={{ marginLeft: 3 }}>
              {`Add Vehicle ${index} Details`}
            </CustomText.OpenSansRegular>
          </div>
        )}
      </div>
      <CustomModal
        showModal={showModal}
        toggleModal={() => {
          setShowModal(false)
        }}
        modalStyle={{
          minHeight: 'unset',
        }}
      >

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 0
        }}>
          <CustomText.OpenSansSemiBold>Add Tracker</CustomText.OpenSansSemiBold>
          <span
            className="icons-cross directionRight"
            onClick={() => setShowModal(false)}
          ></span>
        </div>

        <div style={{ marginTop: 15, marginBottom: 15 }} className="add-vehicle-input-wrapper">
          <FadeInComponent>
            <div className="bodNone" style={{ marginBottom: '1.5rem' }}>
              <div className="inputField">
                <div
                  onClick={() => {
                    setShowTrackerModal(true);
                  }}
                >
                  <div className={`customSelectBox`}>
                    {DeviceImeiDropDownLabel ? DeviceImeiDropDownLabel : "Select Tracker"} <ArrowDropDownIcon />
                  </div>
                </div>
                <div className="label" style={{ marginTop: 13 }}>
                  Enter Device ID
                  <InfoIcon
                    onClick={() => {
                      setShowInfoWaterMark(
                        metaData?.tracking_information);
                      setShowModalWaterMark(true);
                    }}
                  />
                </div>
                <div className="field">
                  <SimpleInput
                    value={tplDeviceImei}
                    onChange={(event) => {
                      setTplDeviceImei(event.target.value);
                    }}
                    placeholder=" "
                    maxLength={30}
                  />
                </div>
              </div>
            </div>
          </FadeInComponent>
        </div>

        <PrimaryButton
          title={"Submit"}
          onClick={() => {
            handleSubmit('add')
          }}
        />
        {
          DeviceImeiDropDownValue?.length &&
            tplDeviceImei?.length ?
            <PrimaryButton
              title={"Remove"}
              onClick={handleRemove}
              buttonStyle={{
                marginTop: 10
              }}

            /> : null
        }
      </CustomModal>

      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showTrackerModal}
        toggleModal={setShowTrackerModal}
      >
        {deviceImei?.map((tracker, index) => {

          return (
            <div className="flexRow CustomGreenRadio">
              <Radio
                checked={DeviceImeiDropDownLabel == tracker?.label}
                value={tracker?.label}
                onClick={() => {
                  setDeviceImeiDropDownValue(tracker?.value)
                  setDeviceImeiDropDownLabel(tracker?.label)
                  setShowTrackerModal(false)
                }}

                style={{
                  color: "#7ac251",
                }}
              />
              <CustomText.OpenSansRegular
                textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
              >
                {tracker?.label}
              </CustomText.OpenSansRegular>
            </div>
          );
        })}
      </CustomModal>

      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showModalWaterMark}
        toggleModal={setShowModalWaterMark}
      >
        <div>
          <InfoIcon className="icon" />
          <p className="title">Information</p>
          <p>
            {showInfoWaterMark}
          </p>
        </div>
      </CustomModal>
    </div>
  );
};
