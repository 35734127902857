import React from "react";
import { ISuccessPageProps } from "./types";
import { Images } from "@temp/assets";
import { CustomText, PrimaryButton } from "@components";
import "./style.scss";
import { useHistory } from "react-router-dom";
const Page: React.FC<ISuccessPageProps> = (props) => {
  const history = useHistory();
  return (
    <div className="succes-wrapper">
      <div>
        <img
          alt=""
          style={{
            width: 105,
            height: 53,
            marginTop: 80,
          }}
          src={Images.MgLogo}
        />
      </div>
      <div className="wc-text">
        <CustomText.OpenSansSemiBold
          textStyle={{ fontSize: "1.25rem", textAlign: "center" }}
        >
          Welcome to the <span className="green">MeriGari</span> Family!
        </CustomText.OpenSansSemiBold>
      </div>
      <div className="success-img">
        <img alt="" src={Images.SuccessMobile} />
      </div>
      <div className="thanks-text-container">
        <p className="thanks-text">
        Thank you for successfully submitting the registration form! 
        You will be notified as soon as your registration has been verified.
        </p>
      </div>
      <div className="btn-go-to-home">
        <PrimaryButton
        buttonStyle={{
          width:340
        }}
          title="OK"
          onClick={() => {
            // history.replace("/login", { from: "login" });
            for (let i = 0; i < history.length; i++) {
              history.replace("/");
            }
          }}
        />
      </div>
    </div>
  );
};

export default Page;
