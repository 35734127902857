import { Accordion, AccordionSummary, InputAdornment, OutlinedInput } from "@mui/material/";
import { Colors } from "@temp/assets";
import { CustomModal, CustomText, NewInput, PrimaryButton, SimpleInput } from "@temp/components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../LoadDetailPoster/style.scss";
import { RenderRTL } from "./RenderRTL";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../LoadDetailPoster/style.scss";
import CloseIcon from "@mui/icons-material/Close";

export const NegotiateBid = ({
    toggleModal,
    showModal,
    set_bid_negotiated_amount,
    bid_negotiated_amount,
    handleUpdateBid,
    bid_negotiated_detention_rate,
    set_bid_negotiated_detention_rate,
    bid_negotiated_detention_tarif_policy,
    set_negotiated_bid_detention_tarif_policy,
    bid_negotiated_price_loading,
    set_bid_negotiated_price_loading,
    bid_negotiated_price_unloading,
    set_bid_negotiated_price_unloading,
    loadDetail,
    final_category,
    item2,
    price_unit

}) => {
    const [inputType, setInputType] = useState('text')
    
    return (
        <div className="accordion-container"
            style={{ marginBottom: 0 }}
        >

            <CustomModal modalStyle={{
                width: 310,
                alignItems: "unset",
                maxHeight: "500px"
            }} showModal={showModal} toggleModal={toggleModal} className={"negotiationBidModal"}>
                <div className="negotiationBodyContent">
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingTop: 10,
                        paddingBottom: 20
                    }}>
                        <CustomText.OpenSansBold>
                            Bid Negotiation (Without GST)
                        </CustomText.OpenSansBold>
                        <span
                            className="icons-cross"
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                toggleModal(!showModal)
                            }}
                            color=""
                        ></span>
                    </div>
                    <CustomText.OpenSansSemiBold textStyle={{
                        marginBottom: 15
                    }}>
                        Please enter negotiated bid amount to send back to recipient.
                    </CustomText.OpenSansSemiBold>
                    <div className="inputField" >
                        <div className="label">
                            {/* Amount */}
                            {
                                loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Spot Rate" ?
                                    `Price / ${loadDetail?.is_unit_basis ? "Item" : loadDetail?.price_unit}`
                                    : loadDetail?.quote_by == "vehicle" ? "Price / Vehicle" :
                                        loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Contract Rate" ? `Price / ${loadDetail?.is_unit_basis ? "Item" : price_unit}` : ""

                            }

                        </div>
                        <div className="field">
                            <NewInput
                                onChange={(e) => {
                                    set_bid_negotiated_amount(e.target.value)
                                }
                                }
                                value={bid_negotiated_amount}
                                showEnd={"PKR"}
                                decimal={loadDetail?.quote_by == "vehicle" ? false :true}
                            />
                        </div>
                    </div>

                    {
                        item2?.bid?.bid_price_loading || item2?.bid?.loading_price_per_freight ?
                            <div className="inputField" >
                                <div className="label">
                                    {/* Loading Price */}
                                    {
                                        loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Spot Rate" ?
                                            `Loading Price / ${loadDetail?.is_unit_basis ? "Item" : loadDetail?.price_unit}`
                                            : loadDetail?.quote_by == "vehicle" ?
                                                "Loading Price / Vehicle" :
                                                loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Contract Rate" ? `Loading Price / ${loadDetail?.is_unit_basis ? "Item" : price_unit}` : ""
                                    }
                                </div>
                                <div className="field">
                                    <NewInput
                                        onChange={(e) => {
                                            set_bid_negotiated_price_loading(e.target.value)
                                        }
                                        }
                                        value={bid_negotiated_price_loading}
                                        showEnd={"PKR"}
                                        decimal={loadDetail?.quote_by == "vehicle" ? false :true}
                                    />
                                </div>
                            </div>
                            : null}

                    {
                        item2?.bid?.bid_price_unloading || item2?.bid?.unloading_price_per_freight ?
                            <div className="inputField" >
                                <div className="label">
                                    {/* Unloading Price */}
                                    {
                                        loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Spot Rate" ?
                                            `Unloading Price / ${loadDetail?.is_unit_basis ? "Item" : loadDetail?.price_unit}`
                                            : loadDetail?.quote_by == "vehicle" ?
                                                "Unloading Price / Vehicle" :
                                                loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Contract Rate" ? `Unloading Price / ${loadDetail?.is_unit_basis ? "Item" : price_unit}` : ""
                                    }
                                </div>
                                <div className="field">
                                    <NewInput
                                        onChange={(e) => {
                                            set_bid_negotiated_price_unloading(e.target.value)
                                        }
                                        }
                                        value={bid_negotiated_price_unloading}
                                        showEnd={"PKR"}
                                        decimal={loadDetail?.quote_by == "vehicle" ? false :true}
                                    />
                                </div>
                            </div>
                            : null}

                    {
                        item2?.bid?.bid_detention_rate ?
                            <div className="inputField" >
                                <div className="label">
                                    Detention Rate
                                    {/* {
                            loadDetail?.quote_by == "weight_volume" ?
                                ` Detention Rate / ${loadDetail?.is_unit_basis ? "Item" : loadDetail?.price_unit}`
                                : " Detention Rate / Vehicle"
                        } */}

                                </div>
                                <div className="field">
                                    <NewInput
                                        onChange={(e) => {
                                            set_bid_negotiated_detention_rate(e.target.value)
                                        }}
                                        value={bid_negotiated_detention_rate}
                                        showEnd={"PKR"}
                                        decimal={loadDetail?.quote_by == "vehicle" ? false :true}
                                    // placeholder={"Optional"}
                                    />
                                </div>

                            </div>
                            : null}
                    {
                        loadDetail?.quote_by !== "vehicle" && item2?.bid?.detention_tarif_policy ?
                            <div className="inputField" >
                                <div className="label">
                                    Detention Tariff & Policy
                                </div>
                                <div className="textareaWrapper">
                                    <textarea style={{ outline: "none", }}
                                        value={bid_negotiated_detention_tarif_policy}
                                        onChange={(e) => {
                                            set_negotiated_bid_detention_tarif_policy(e.target.value)
                                        }}
                                        // placeholder="Optional"
                                        maxLength={500}
                                    />
                                </div>

                            </div>
                            : null}


                    {/* <OutlinedInput
                    onInput={(e) => {
                        set_bid_negotiated_amount(e.target.value)
                    }}
                    onFocus={() => {
                        setInputType('number')
                    }}
                    onBlur={() => {
                        setInputType('text')
                    }}
                    value={inputType == 'number' ? bid_negotiated_amount : bid_negotiated_amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    type={inputType}
                    style={{ height: 35, marginTop: 10 }}
                    aria-describedby="standard-weight-helper-text"
                    endAdornment={
                        <InputAdornment position="end">(PKR)</InputAdornment>
                    }
                    className="rightFormField"
                /> */}
                    <PrimaryButton
                        title="Update Bid"
                        disabled={
                            // handleSubmitBidButtons()
                            (bid_negotiated_amount?.length!== 0  && bid_negotiated_price_loading?.length!== 0 && bid_negotiated_price_unloading?.length!== 0
                                && bid_negotiated_detention_tarif_policy?.length !== 0 && bid_negotiated_detention_rate?.length !== 0
                            ) ? false : true
                        }

                        onClick={() => {
                            handleUpdateBid()
                        }}
                        buttonStyle={{
                            height: 40,
                            marginTop: 30,
                            marginBottom: 20
                        }}
                        buttonTitleStyle={{
                            fontSize: "1rem",
                            marginTop: 5
                        }}
                    />
                </div>
            </CustomModal>
        </div>
    );
};
