import React, { useEffect, useState } from "react";
import { IVehiclePageProps } from "./types";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DrawerAction, LoadAction } from "@temp/store/actions";
import { CustomModal, CustomText, PhoneInput, PrimaryButton, SimpleCheckBox, SimpleInput, TextInput } from "@components";
import rootReducer from "../../../store/reducers";
import { Grid, OutlinedInput } from "@mui/material/";
import TitleLayout from "@temp/components/TitleLayout";
import EmptyPlaceholder from "@temp/components/EmptyPlaceholder/EmptyPlaceholder";
import { phoneNumberFormater, preventNonNumericalInput, showMessage } from "@temp/utils/helper";
import SimplePhoneInput from "@temp/components/SimplePhoneInput";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<IVehiclePageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location

  const vehicleData = useSelector((state: RootState) => state.Drawer.vehicleData);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [search, setSearch] = useState("");

  const [vehicle_plate_no, set_vehicle_plate_no] = useState('')
  const [driver_phone_no, set_driver_phone_no] = useState('')

  const [driver_name, set_driver_name] = useState('')
  const [saved_vehicle, set_save_vehicle] = useState(false)
  // const [vehicle_index, set_vehicle_index] = useState(state?.already_added)

  const [selectedVehicle, setSelectedVehicle] = useState([])
  const [allocated_vehicles, set_allocated_vehicles] = useState([])
  const [edited_data, set_edited_data] = useState(null)


  const handlePasteEdited = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    // Remove non-numeric characters
    const numericValue = paste.replace(/[^0-9]/g, '');
    // Prevent pasting if the first character is 0
    if (event?.target?.value.length > 10) {
      return;
    }

    if (numericValue.startsWith('0') || numericValue.startsWith('92')) {
      return;
    }
    // Set the pasted value to driverMobileNumber

    set_edited_data({
      ...edited_data,
      driver_phone_no: numericValue
    });

  }

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    // Remove non-numeric characters
    const numericValue = paste.replace(/[^0-9]/g, '');
    // Prevent pasting if the first character is 0
    if (event?.target?.value.length > 10) {
      return;
    }

    if (numericValue.startsWith('0') || numericValue.startsWith('92')) {
      return;
    }
    // Set the pasted value to driverMobileNumber
    set_driver_phone_no(numericValue)

  }

  const handleChange = (e) => {
    let newValue = e.target.value;
    // Remove non-digit characters (including hyphens)
    newValue = newValue.replace(/[^0-9]/g, '');
    // Remove specific disallowed numbers like '92'
    if (newValue.startsWith('92')) {
      newValue = newValue.slice(2); // Remove '92' from the start
    }
    // Remove leading zeros
    newValue = newValue.replace(/^0+/, '');
    // Limit length to 10 digits
    newValue = newValue.slice(0, 10);
    set_driver_phone_no(newValue);
  };


  const validateInput = (value) => {
    // Prevent input starting with 0, 92, or a hyphen
    const invalidPatterns = /^(0|92|-)/;
    return !invalidPatterns.test(value);
  };

  const handleChangeEdited = (e) => {
    const { name, value } = e.target;


    if (validateInput(value) || value === '') {
      if (e.target.value.length <= 10) {
        set_edited_data({
          ...edited_data,
          driver_phone_no: e.target.value
        })
      }
    }
  };


  const handleSelectVehicle = (data) => {
    let new_data = [...selectedVehicle]
    let find = new_data.find((item) => item?.driver_id == data?.driver?.id)
    let find_index = new_data.findIndex(item => item?.driver_id == data?.driver?.id)
    if (find) {
      new_data.splice(find_index, 1)
      // set_vehicle_index((prev)=>{
      //   let new_data = prev - 1
      //   return new_data
      // })
    } else {
      if (state?.from == 'detail') {
        // if (allocated_vehicles?.find(vehi => vehi?.vehicle_plate_no == data?.plate_number)) {
        //   showMessage.current({ message: 'This Vehicle is already Placed in your Allocated List.', status: 'error' })
        // } else {
        new_data.push({
          vehicle_plate_no: data?.plate_number,
          driver_phone_no: data?.driver?.phone_no,
          driver_phone_code: "92",
          driver_name: data?.driver_name,
          driver_id: data?.driver?.id,
          vehicle_id: data?.id,
          job_load_category_id: state?.cat_id,
          // vehicle_no: vehicle_index + 1,
          saved_vehicle: false,
          is_active_load: data?.is_active_load
        })
        // set_vehicle_index(vehicle_index + 1)
        // }
      } else {
        new_data[0] = {
          vehicle_plate_no: data?.plate_number,
          driver_phone_no: data?.driver?.phone_no,
          driver_phone_code: "92",
          driver_name: data?.driver_name,
          driver_id: data?.driver?.id,
          vehicle_id: data?.id,
          job_load_category_id: state?.cat_id,
          vehicle_no: state?.vehicle_no,
          saved_vehicle: false,
          is_replace: true,
          replace_vehicle_id: state?.vehicle?.id,
          is_active_load: data?.is_active_load
        }
      }
    }
    setSelectedVehicle(new_data)
  }
  const callback = (data) => {

    if (data) {
      history.go(-2)
    } else {
      history.goBack()
    }
  }
  useEffect(() => {
    dispatch(DrawerAction.GetVehicleSelector());
  }, []);

  const getSearchedNetwork = (params) => {
    dispatch(DrawerAction.SearchNetwork(params));
  };

  const deleteVehicle = () => {
    setShow(false);
    dispatch(DrawerAction.DeleteVehicleSelector({ id: currentId }));
  };
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  return (
    <TitleLayout titleOnly={true} titleOnlyText={state?.from == "detail" ? "Select Vehicles" : "Replace Vehicle"}>
      <div style={{
        height: 'calc(100vh - 100px)',
        overflow: 'auto'
      }} className="vehicleContentWrapper">
        <div className="titleSection">Select from My Vehicles</div>

        <div className={`searchContainer ${search ? "filled" : ""}`}>
          <input
            type="text"
            placeholder="Search"
            className="searchInput"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <span className={`icons-search ${search ? '' : 'active'}`}></span>
          <span
            className={`icons-cross ${search ? 'active' : ''}`}
            onClick={() => {
              setSearch("");
              getSearchedNetwork("");
            }}
          ></span>
        </div>

        <div style={{ height: 288 }} className="vehicleContentWrap">
          <div className="vehicle-wrapper">
            {vehicleData
              .filter((item) => item?.plate_number?.toLowerCase().includes(search?.toLowerCase()) ||
                (item?.master_vehicle_type ? item?.master_vehicle_type?.title : "").toLowerCase().includes(search?.toLowerCase())
              )?.length ?
              <>
                {
                  vehicleData
                    .filter((item) => item?.plate_number?.toLowerCase().includes(search?.toLowerCase()) ||
                      (item?.master_vehicle_type ? item?.master_vehicle_type?.title : "").toLowerCase().includes(search?.toLowerCase())
                    )
                    ?.map((item, index) => {
                      let find_vehicle = selectedVehicle?.find((vehicle) => vehicle?.driver_id == item?.driver?.id)
                      return (
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            handleSelectVehicle(item)
                          }}
                          className={`info-wrapper box ${find_vehicle ? 'active' : ''}`}
                        >
                          <div className="img-container">
                            <CustomText.OpenSansSemiBold
                              textStyle={{
                                fontSize: "1.375rem",
                                marginTop: 18,
                                color: Colors.Black,
                                paddingBottom: 8
                              }}
                            >
                              {item?.plate_number}
                            </CustomText.OpenSansSemiBold>
                          </div>
                          <div className="user-details">
                            <CustomText.OpenSansRegular
                              textStyle={{
                                fontSize: "1rem",
                                color: Colors.Grayish,
                                marginTop: 7,
                              }}
                            >
                              {item?.master_vehicle_type?.title}
                            </CustomText.OpenSansRegular>
                            <div className="divider"></div>
                            <CustomText.OpenSansSemiBold
                              textStyle={{
                                fontSize: "0.875rem",
                                color: Colors.Black,
                                marginTop: 7,
                              }}
                            >
                              {item?.driver_name}
                            </CustomText.OpenSansSemiBold>

                            <CustomText.OpenSansRegular
                              textStyle={{
                                fontSize: "0.75rem",
                                color: Colors.Grayish,
                                marginTop: 3,
                              }}
                            >
                              {/* {`+${item?.driver?.phone_code} ${item?.driver?.phone_no}`} */}
                              {`${`${phoneNumberFormater(item?.driver?.phone_code, item?.driver?.phone_no)}`} `}


                            </CustomText.OpenSansRegular>
                          </div>
                        </div>
                      );
                    })
                }
              </> :
              <div className="network-empty-component">
                <EmptyPlaceholder
                  image={Images.noVehicleNew}
                  title="No Vehicles Added!"
                  detail="You have no vehicle added, Please add vehicle."
                  // buttonText="Add New Vehicle"
                  onClick={() => {
                    history.push("/add-vehicle");
                  }}
                />
              </div>
            }
          </div>
        </div>

        <div style={{ marginTop: 10, marginBottom: 16 }}>
          <PrimaryButton
            title={state?.from == "detail" ? "allocate vehicle" : "Replace vehicle"}
            onClick={() => {
              if (state?.from == 'detail') {
                let new_data = [...allocated_vehicles]
                let additional_vehicle = selectedVehicle.map((item, index) => {
                  return {
                    ...item,
                    vehicle_no: state?.already_added && allocated_vehicles.length ? state?.already_added + allocated_vehicles.length + index + 1 :
                      allocated_vehicles.length ? allocated_vehicles.length + 1 + index : state?.already_added + 1 + index
                  }
                })
                new_data = [...new_data, ...additional_vehicle]
                if (state?.required_vehicles) {
                  if (new_data.length > state?.required_vehicles - state.already_added) {
                    showMessage.current({
                      message:
                        // 'Allocated vehicles can not exceed than required vehicles.', 
                        "You can not allocate more than required no. of vehicles",
                      status: 'error'
                    })
                  } else {
                    set_allocated_vehicles(new_data)
                    setSelectedVehicle([])
                  }
                } else {
                  set_allocated_vehicles(new_data)
                  setSelectedVehicle([])
                }
              } else {
                if (state?.vehicle?.plate_number == selectedVehicle?.[0]?.vehicle_plate_no) {
                  showMessage.current({
                    message:
                      // `This ${selectedVehicle?.[0]?.vehicle_plate_no} Vehicle is Currently you have Placed`, 
                      `This ${selectedVehicle?.[0]?.vehicle_plate_no} is the same as the current plate number`,
                    status: 'error'
                  })
                } else {
                  dispatch(LoadAction.addBulkVehicles({
                    load_id: state?.load_id, vehicles: selectedVehicle, cb: callback, is_replace: true
                  }))
                }
              }
            }}
            disabled={!selectedVehicle?.length}
          />
        </div>

        {/* add new vehicle  */}
        <div>
          <div className="titleSection">Add New Vehicle</div>
          <div className="inputField">
            <div className="label"> Vehicle Plate No.</div>
            <div className="field">
              <SimpleInput
                onChange={(e) => {
                  if (e.target.value.length > 15) {
                    showMessage.current({
                      message: "Plate Number should not be greater than 15 characters",
                      status: "error"
                    })
                  } else {
                    set_vehicle_plate_no(e.target.value)
                  }
                }}
                placeholder="Enter vehicle plate no."
                value={vehicle_plate_no}
                type="text"
                aria-describedby="standard-weight-helper-text"
              />
            </div>
          </div>

          <div className="inputField">
            <div className="label"> Driver Phone No.</div>
            <div className="field">
              {/* <PhoneInput
                value={driver_phone_no}
                phoneCode={"92"}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    set_driver_phone_no(e.target.value)
                  }
                }}
                id="phone-number"
                placeholder={"3000000000"}
              /> */}

              <SimplePhoneInput
                value={driver_phone_no}
                onChange={handleChange}
                onPaste={handlePaste}
                placeholder="3000000000"
                maxLength={10}
                id="phone-number"
              />
            </div>
          </div>

          <div className="inputField">
            <div className="label">Driver Name</div>
            <div className="field">
              <TextInput
                onChange={(e) => {
                  if (e.target.value.length > 25) {
                    showMessage.current({
                      message: "Driver Name should not be greater than 25 characters",
                      status: "error"
                    })
                  } else {
                    set_driver_name(e.target.value)
                  }
                }
                }
                value={driver_name}
                type="text"
                aria-describedby="standard-weight-helper-text"
                placeholder="Optional"
              />
            </div>
          </div>

          <SimpleCheckBox
            checked={saved_vehicle}
            handleChange={(e) => set_save_vehicle(e.target.checked)}
            title="Save to My Vehicles"
          />

          <div style={{ marginTop: 10, marginBottom: 16 }}>
            <PrimaryButton
              title={state?.from == "detail" ? "allocate vehicle" : "Replace vehicle"}
              onClick={() => {
                if (state?.from == "detail") {

                  let data = {
                    vehicle_plate_no,
                    driver_phone_no,
                    driver_phone_code: "92",
                    driver_name,
                    saved_vehicle,
                    driver_id: null,
                    vehicle_id: null,
                    job_load_category_id: state?.cat_id,
                    vehicle_no: state?.already_added && allocated_vehicles.length ? state?.already_added + allocated_vehicles.length + 1 :
                      state?.already_added ? state?.already_added + 1 : allocated_vehicles.length + 1
                  }
                  if (state?.required_vehicles) {
                    if (allocated_vehicles.length >= state?.required_vehicles - state.already_added) {
                      showMessage.current({
                        message:
                          // 'Allocated vehicles can not exceed than required vehicles.',
                          "You can not allocate more than required no. of vehicles",
                        status: 'error'
                      })
                    } else {
                      let new_data = [...allocated_vehicles]
                      new_data.push(data)
                      set_allocated_vehicles(new_data)
                    }
                  } else {
                    let new_data = [...allocated_vehicles]
                    new_data.push(data)
                    set_allocated_vehicles(new_data)
                  }

                } else {
                  let data = {
                    vehicle_plate_no,
                    driver_phone_no,
                    driver_phone_code: "92",
                    driver_name,
                    saved_vehicle,
                    driver_id: null,
                    vehicle_id: null,
                    job_load_category_id: state?.cat_id,
                    vehicle_no: state?.vehicle_no,
                    is_replace: true,
                    replace_vehicle_id: state?.vehicle?.id
                  }
                  if (state?.vehicle?.plate_number == data?.vehicle_plate_no) {
                    showMessage.current({
                      message:
                        // `This ${data?.vehicle_plate_no} Vehicle is Currently you have Placed`,
                        `This ${data?.vehicle_plate_no} is the same as the current plate number`,
                      status: 'error'
                    })
                  } else {
                    dispatch(LoadAction.addBulkVehicles({
                      load_id: state?.load_id, vehicles: [data], cb: callback, is_replace: true
                    }))
                  }
                }
                set_vehicle_plate_no('')
                set_driver_name('')
                set_driver_phone_no('')
                set_save_vehicle(false)
              }}
              disabled={!vehicle_plate_no || driver_phone_no?.length < 10}
            />
          </div>
        </div>

        {/* allocated vehicles */}
        {state?.from == 'detail' ?
          <div>
            <div style={{ marginBottom: 10 }}>

              <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <div className="titleSection" style={{ marginBottom: 0 }}>
                  Allocated Vehicles
                </div>
                <div className="chip">
                  {
                    state?.required_vehicles ?
                      allocated_vehicles?.length + state?.already_added + "/" + state?.required_vehicles + " Vehicles"
                      : state?.already_added + allocated_vehicles.length
                  }
                </div>
              </div>

              {
                allocated_vehicles?.map((item, index) => {

                  return (
                    <div style={{ opacity: item?.is_active_load ? 0.6 : 1 }} className="sectionBox allocatedVehicle">
                      <div className="cornerBtn">
                        <span className="blue-icon-btn">
                          <span className="icons-edit" style={{ marginTop: 2, cursor: 'pointer' }}
                            onClick={() => {
                              set_edited_data({ ...item, index })
                              setShowModal(true)
                            }} ></span>
                        </span>

                        <span className="icons-cross" style={{ cursor: 'pointer' }}
                          onClick={() => {
                            let new_data = [...allocated_vehicles]
                            new_data.splice(index, 1)
                            let update = new_data.map((item, index) => {
                              return {
                                ...item,
                                vehicle_no: state?.already_added + index + 1
                              }
                            })
                            set_allocated_vehicles(update)
                            // let ind = state?.already_added + 1
                            // new_data.forEach((vehicle, vehicle_index) => {
                            //   vehicle['vehicle_no'] = ind
                            //   ind = ind + 1
                            // })
                            // set_vehicle_index(new_data.length + state?.already_added)
                          }}>
                        </span>
                      </div>

                      <div className="formGrid">
                        <div className="grayLabel">
                          Vehicle No
                        </div>
                        <div className="fw-5">
                          {item?.vehicle_no}
                        </div>
                      </div>

                      <div className="formGrid">
                        <div className="grayLabel">
                          Vehicle Plate No.
                        </div>
                        <div className="fw-5">
                          {item?.vehicle_plate_no}
                        </div>
                      </div>

                      {
                        item?.driver_name &&
                        <div className="formGrid">
                          <div className="grayLabel">
                            Driver Name
                          </div>
                          <div className="fw-5">
                            {item?.driver_name}
                          </div>
                        </div>
                      }

                      <div className="formGrid">
                        <div className="grayLabel">
                          Driver Phone No.
                        </div>
                        <div className="fw-5">
                          {/* {item?.driver_phone_no} */}
                          {`${`${phoneNumberFormater(item?.driver_phone_code, item?.driver_phone_no)}`} `}



                        </div>
                      </div>
                    </div>
                  )
                })
              }

            </div>
            {
              allocated_vehicles.length ?
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <PrimaryButton
                    title={"Save & Continue"}
                    onClick={() => {
                      dispatch(LoadAction.addBulkVehicles({ load_id: state?.load_id, vehicles: allocated_vehicles, cb: callback, is_replace: false }))
                    }}
                    disabled={!allocated_vehicles.length}
                  />
                </div> : null
            }
          </div> : null
        }

      </div>
      <CustomModal
        showModal={showModal}
        toggleModal={() => {
          setShowModal(false)
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 25
        }}>
          <CustomText.OpenSansSemiBold>Edit Vehicle</CustomText.OpenSansSemiBold>
        </div>

        {/* <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 10 }}>
          <Grid item xs={12}>
            <div className="transactionFieldLabel">Driver Name</div>
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              onInput={(e) => {
                if (e.target.value.length > 25) {
                  showMessage.current({
                    message: "Driver Name should not be greater than 25 characters",
                    status: "error"
                  })
                } else {
                  set_edited_data({
                    ...edited_data,
                    driver_name: e.target.value
                  })
                }

              }}
              value={edited_data?.driver_name}
              type="text"
              style={{ height: 35, width: '100%' }}
              aria-describedby="standard-weight-helper-text"
              className="rightFormField"
              placeholder="Optional"
            />
          </Grid>
        </Grid>

        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 10 }}>
          <Grid item xs={12}>
            <div className="transactionFieldLabel"> Driver Phone No.</div>
          </Grid>
          <Grid item xs={12}>
            <div style={{
              width: "96%"
            }} className="contact_pickup_dropoff outline-input">
              <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.75rem", fontWeight: "400" }}>
                +92
              </CustomText.OpenSansSemiBold>
              <input
                className="contact_pickup_dropoff_input"
                type="number"
                onKeyPress={(event) => preventNonNumericalInput(event)}
                inputMode="numeric"
                pattern="\d*"
                value={edited_data?.driver_phone_no}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    set_edited_data({
                      ...edited_data,
                      driver_phone_no: e.target.value
                    })
                  }
                }}
              />
            </div>
          </Grid>
        </Grid> */}

        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 10 }}>
          <FadeInComponent>
            <div className="inputField">
              <div className="label">Driver Name</div>
              <div className="field">
                <SimpleInput
                  onChange={(e) => {
                    if (e.target.value.length > 25) {
                      showMessage.current({
                        message: "Driver Name should not be greater than 25 characters",
                        status: "error"
                      })
                    } else {
                      set_edited_data({
                        ...edited_data,
                        driver_name: e.target.value
                      })
                    }

                  }}
                  value={edited_data?.driver_name}
                  type="text"
                  style={{ height: 35, width: '100%' }}
                  aria-describedby="standard-weight-helper-text"
                  className="rightFormField"
                  placeholder="Optional"

                />
                {/* <span className="icons-edit editIcon newEditIcon"></span> */}
              </div>
            </div>
          </FadeInComponent>

        </Grid>

        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 10 }}>
          <FadeInComponent>
            <div className="inputField">
              <div className="label">Driver Phone No.</div>
              <div className="field">
                {/* <PhoneInput
                  value={edited_data?.phone_no}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      set_edited_data({
                        ...edited_data,
                        phone_no: e.target.value
                      })
                    }
                  }}
                  phoneCode={"92"}
                  id="phone-number"
                  placeholder={"3000000000"}
                />*/}

                <SimplePhoneInput
                  value={edited_data?.driver_phone_no}
                  onChange={handleChangeEdited}
                  onPaste={handlePasteEdited}
                  placeholder="3000000000"
                  maxLength={10}
                  id="phone-number"
                />
                {/* <span className="icons-edit editIcon newEditIcon"></span> */}
              </div>
            </div>
          </FadeInComponent>


        </Grid>


        <PrimaryButton
          title={"Submit"}
          disabled={edited_data?.driver_phone_no?.length < 10}
          onClick={() => {
            let new_data = [...allocated_vehicles]
            new_data[edited_data?.index] = {
              vehicle_plate_no: edited_data?.vehicle_plate_no,
              driver_phone_no: edited_data?.driver_phone_no,
              driver_phone_code: "92",
              driver_name: edited_data?.driver_name,
              saved_vehicle: edited_data?.saved_vehicle,
              driver_id: null,
              vehicle_id: null,
              job_load_category_id: edited_data?.job_load_category_id,
              vehicle_no: edited_data?.vehicle_no
            }
            set_allocated_vehicles(new_data)
            set_edited_data(null)
            setShowModal(false)
          }}
        />

      </CustomModal>
    </TitleLayout>
  );
};

export default Page;
