import React from "react";
import { IProps } from "./types";
import "./style.scss";
import Dialog from "@mui/material//Dialog";

export const CustomModal: React.FC<IProps> = ({
  showModal,
  children,
  toggleModal,
  modalStyle,
  className,
  isFullScreen,
  modalBackPressDisable = false 
}: IProps) => {
  return (
    <Dialog
      fullScreen={isFullScreen ? true : false}
      PaperProps={{
        style: {
          color: "black",
          borderRadius: 20,
          minHeight: 206,
          padding: 15,
          minWidth: 280,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...modalStyle,
        },
      }}
      onClose={(e) => {
        if(!modalBackPressDisable){
          toggleModal(!showModal);
        }
      }}
      aria-labelledby="simple-dialog-title"
      open={showModal}
      className={className}
    >
      {children}
    </Dialog>
  );
};

export default CustomModal;
