import React from "react";
import { IProps } from "./types";

const Progress_bar: React.FC<IProps> = ({
  bgcolor,
  progress,
  height,
}: IProps) => {
  const Childdiv = {
    width: `${progress}%`,
  };

  return (
    <div className="progressBarWrapper">
      <div className="progressBar" style={Childdiv} />
    </div>
  );
};

export default Progress_bar;
