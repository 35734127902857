import { LOAD_TYPE } from "@temp/constants";
import dayjs from "dayjs";

const getInquiryType = (id: number, arr: any) => {
  return arr?.find((val: object) => val?.id === id);
};

const getPackageById = (id: number, arr: any) => {
  return arr?.find((val: object) => val.id === id);
};

const getPreferredVehicleById = (id: number, arr: any) => {
  return arr?.find((val: object) => val.id === id);
};

const convertArrayToObject = (arr: any) => {
  const data = arr?.map((cat: any) => {
    return {
      cargo_volume: cat?.cargo_volume || null,
      gross_weight: cat?.gross_weight || null,
      total_freight_ton: cat?.total_freight_ton || null,
      no_of_packages: cat?.no_of_packages || null,
      cargo_liter: cat?.cargo_liter,
      is_boxed: cat?.is_boxed ? true : false,
      is_loading_required: cat?.is_loading_required ? true : false,
      is_sidewall: cat?.is_sidewall ? true : false,

      is_any_suitable_vehicle: cat?.is_any_suitable_vehicle ? true : false,
      is_flat_bed_vehicle: cat?.is_flat_bed_vehicle ? true : false,
      is_low_bed_vehicle: cat?.is_low_bed_vehicle ? true : false,
      is_dum_truck_vehicle: cat?.is_dum_truck_vehicle ? true : false,

      is_unloading_required: cat?.is_unloading_required ? true : false,
      loading_requirements: cat?.loading_requirements,
      packageType: cat?.package_type,
      PreferredVehicleType: cat?.preferred_vehicle,
      unloading_requirements: cat?.unloading_requirements,
    };
  });
  return data?.length > 0 ? data[0] : null;
};

export const convertDataForFclAndBulkLoadDetailRedux = (
  loadDetail: any,
  packageType: any,
  load_type: any,
  inquiry_type: any,
  PreferredVehicleType: any,
  from = null,
  loadType
) => {
  if (loadDetail) {
    let new_docs = {};
    let temp_bill_of_lading = [];
    let temp_packing_list = [];
    let temp_other_uploads = [];
    loadDetail?.documents.forEach((item) => {
      if (
        item?.type_name == "bill_of_ladings" ||
        item?.type_name == "bill_of_lading"
      ) {
        temp_bill_of_lading.push({
          file_name: item.file_name,
          cloudinary_url: item.path,
          file_url: item.relative_path,
          mime_type: item.mime_type,
        });
      } else if (
        item?.type_name == "packing_list" ||
        item?.type_name == "packing_list"
      ) {
        temp_packing_list.push({
          file_name: item.file_name,
          cloudinary_url: item.path,
          file_url: item.relative_path,
          mime_type: item.mime_type,
        });
      } else {
        if (temp_other_uploads.find((itm) => itm.title == item.other_type)) {
          temp_other_uploads[
            temp_other_uploads.findIndex((it) => it.title == item.other_type)
          ].files.push({
            file_name: item.file_name,
            cloudinary_url: item.path,
            file_url: item.relative_path,
            mime_type: item.mime_type,
          });
        } else {
          temp_other_uploads.push({
            title: item?.other_type,
            files: [
              {
                file_name: item.file_name,
                cloudinary_url: item.path,
                file_url: item.relative_path,
                mime_type: item.mime_type,
              },
            ],
          });
        }
      }
    });
    new_docs["bill_of_ladings"] = temp_bill_of_lading;
    new_docs["packing_lists"] = temp_packing_list;
    new_docs["other_uploads"] = temp_other_uploads;
    let history_recipients = []
    let recipients = [];
    loadDetail?.recipients?.forEach((recipient) => {
      let find = recipients.find((item) => item?.phone_no == recipient?.recipient?.phone_no)
      if (find == undefined) {
        recipients.push({
          phone_no: recipient?.recipient?.phone_no,
          phone_code: recipient?.recipient?.phone_code,
        });
        history_recipients.push({
          ...recipient
        })
      }
    });

    if (from == 'forward') {
      recipients.push({ phone_no: loadDetail?.poster?.phone_no, phone_code: loadDetail?.poster?.phone_code })
    }

    let draftData = {
      allocates: loadDetail?.allocates,
      is_allocated: loadDetail?.is_allocated,
      from,
      fromClone: true,
      load_id: loadDetail?.id,
      title: loadDetail.title,
      inquiry_type: loadDetail.inquiry_type.id,
      load_type: loadDetail.load_type.id,
      additional_requirements: loadDetail?.additional_requirements,
      instructions: loadDetail?.instructions ? loadDetail?.instructions : null,
      instructions_voice_note: loadDetail?.instructions_voice_note ? {
        cloudinary_url: loadDetail?.instructions_voice_note,
        file_url: loadDetail?.instructions_voice_note_object?.file_url
      } : null,
      cargo_description: loadDetail?.cargo_description,
      vehicleScreen: {
        values: {
          load_name: loadDetail.title,
          ref_no: loadDetail?.ref_no,
          cargo_description: loadDetail?.cargo_description,
          gross_weight: loadDetail.gross_weight,
          gross_weight_in_kgs: loadDetail.gross_weight ? Number(loadDetail.gross_weight * 1000) : null,
          cargo_liter: loadDetail?.cargo_liter,
          cargo_volume: loadDetail.cargo_volume,
          no_of_packages: loadDetail.no_of_packages,
          total_freight_ton: loadDetail.total_freight_ton,
          is_shared_vehicle_not_allowed:
            loadDetail.is_shared_vehicle_not_allowed,

          quote_by: loadDetail.quote_by,
          is_loading_required: loadDetail?.is_loading_required ? true : false,
          is_unloading_required: loadDetail?.is_unloading_required
            ? true
            : false,
          loading_requirements: loadDetail?.loading_requirements,
          unloading_requirements: loadDetail?.unloading_requirements,

          is_boxed: loadDetail?.is_boxed ? true : false,
          is_sidewall: loadDetail?.is_sidewall ? true : false,
          is_any_suitable_vehicle: loadDetail?.is_any_suitable_vehicle ? true : false,
          is_flat_bed_vehicle: loadDetail?.is_flat_bed_vehicle ? true : false,
          is_low_bed_vehicle: loadDetail?.is_low_bed_vehicle ? true : false,
          is_dum_truck_vehicle: loadDetail?.is_dum_truck_vehicle ? true : false,


          price: loadDetail?.price_per_freight > 0 ? loadDetail?.price_per_freight : null,
          detention_free_time: loadDetail?.detention_free_time,

          detention_included_transport: loadDetail?.detention_included_transport
            ? true
            : false,
          vehicle_detention_rates: loadDetail?.detentionRates,
          detention_rate: loadDetail?.detention_rate,
          package_type_other: loadDetail?.package_type_other,

          total_amount: loadDetail?.total_offer_price > 0 ? Number(loadDetail?.total_offer_price) : null,
          // (Number(loadDetail?.price_per_freight) *
          //   Number(loadDetail.total_freight_ton)) + (Number(loadDetail?.loading_price_per_freight) + Number(loadDetail?.unloading_price_per_freight)),
          loading_price: loadDetail.loading_price_per_freight > 0 ? loadDetail?.loading_price_per_freight : null,
          unloading_price: loadDetail.unloading_price_per_freight > 0 ? loadDetail?.unloading_price_per_freight : null,
          max_bid: loadDetail?.max_bid && loadDetail?.max_bid != 0 ? loadDetail.max_bid : null,
          price_unit: loadDetail?.price_unit,
        },
        addedVehicles: loadDetail?.categories?.map((item) => {
          
          item?.recipients?.forEach((recipient) => {
            let find = recipients?.find((item) => item?.phone_no == recipient?.recipient?.phone_no)
            if (find == undefined) {
              recipients.push({
                phone_no: recipient?.recipient?.phone_no,
                phone_code: recipient?.recipient?.phone_code,
              });
              history_recipients.push({
                ...recipient
              })
            }
          });
          return {
            selectVehicleTypeObj: {
              ...item?.vehicle,
            },
            vehicle_quantity: item?.vehicle_quantity ? item?.vehicle_quantity : "",
            containerType: {
              ...item?.container_type,
            },

            packageType: item?.package_type?.id
              ? {
                ...item?.package_type,
              }
              : {
                id: item.package_type_id,
              },
            package_type_other: item?.package_type_other,
            max_vehicle_weight: item?.max_vehicle_weight,
            total_amount: item?.total_offer_price,
            price: item?.offer_price_per_vehicle,
            loading_price: item?.loading_price,
            unloading_price: item?.unloading_price,
            is_loading_required: item?.is_loading_required ? true : false,
            is_unloading_required: item?.is_unloading_required ? true : false,
            loading_requirements: item?.loading_requirements,
            unloading_requirements: item?.unloading_requirements,
            vehicles_drivers_refs: item?.vehicles_drivers_refs,
            new_recipients: item?.recipients,

            values: {
              is_out_of_guage: item?.is_out_of_guage ? true : false,
              is_twin_load: item?.is_twin_load ? true : false,
              is_boxed: item?.is_boxed ? true : false,
              is_sidewall: item?.is_sidewall ? true : false,
              is_any_suitable_vehicle: loadDetail?.is_any_suitable_vehicle ? true : false,
              is_flat_bed_vehicle: loadDetail?.is_flat_bed_vehicle ? true : false,
              is_low_bed_vehicle: loadDetail?.is_low_bed_vehicle ? true : false,
              is_dum_truck_vehicle: loadDetail?.is_dum_truck_vehicle ? true : false,
              package_type_other: item?.package_type_other,
              loading_price: item?.loading_price,
              unloading_price: item?.unloading_price,
              is_loading_required: item?.is_loading_required ? true : false,
              is_unloading_required: item?.is_unloading_required ? true : false,
              loading_requirements: item?.loading_requirements,
              unloading_requirements: item?.unloading_requirements,
              required_temperature: [item?.required_temperature_min
                ? Number(item?.required_temperature_min) : 0,
              item?.required_temperature_max ? Number(item?.required_temperature_max) : 0],
              is_genset: item?.is_genset ? true : false,
            },
            detention_free_time: item?.detention_free_time,




            detention_included_transport: item?.detention_included_transport
              ? true
              : false,
            detention_rate: item?.detention_rate,
            max_bid: item?.max_bid && item?.max_bid != 0 ? item.max_bid : null,


          };
        }),
        added_units: loadDetail?.categories?.map((item) => {
          return {
            gross_weight: item?.gross_weight || null,
            cargo_volume: item?.cargo_volume || null,
            no_of_packages: item?.no_of_packages || null,
            total_freight_ton: item?.total_freight_ton,
            gross_weight_in_kgs: item?.gross_weight ? Number(item?.gross_weight) * 1000 : null,
            dimensions: item?.dimensions,
            unit_dimension_value: item?.unit_dimension_value,
            unit_quantity: item?.unit_quantity,
            unit_description: item?.unit_description,
            is_unit_basis: true,
            unit_edit_mode: false,
            unit_edit_id: null,
            detention_additional_charges: item?.detention_additional_charges || null,
            detention_free_time: item?.detention_free_time,
            detention_rate: item?.detention_rate,
            is_loading_required: item?.is_loading_required,
            is_unloading_required: item?.is_unloading_required,
            loading_requirements: item?.loading_requirements,
            unloading_requirements: item?.unloading_requirements,
            loading_price: item?.loading_price,
            unloading_price: item?.unloading_price,
            price: item?.offer_price_per_vehicle,
            total_amount: item?.total_offer_price,
            max_bid: item?.max_bid,
            vehicles_drivers_refs: item?.vehicles_drivers_refs,
            new_recipients: item?.recipients,

          };
        }),
        is_unit_basis: loadDetail?.is_unit_basis ? true : false,
        packageType:
          loadDetail.quote_by === "weight_volume"
            ? getPackageById(loadDetail.package_type_id, packageType)
            : null,
        PreferredVehicleType:
          loadDetail.quote_by === "weight_volume" ? getPreferredVehicleById(loadDetail.preferred_vehicle_id, PreferredVehicleType)
            : null,
        is_allow_partial_load: loadDetail?.is_allow_partial_load ? true : false,
        vehicles_drivers_refs: loadDetail?.vehicles_drivers_refs,
        new_recipients: loadDetail?.recipients,
        cargo_description: loadDetail?.cargo_description,
      },
      additionalScreen: {
        is_hse_compliant_vehicle: loadDetail.is_hse_compliant_vehicle
          ? true
          : false,

        is_weightment_received_required: loadDetail.is_weightment_received_required
          ? true
          : false,

        weightment_received_name: loadDetail.weightment_received_name
          ? loadDetail.weightment_received_name
          : null,

        is_bonded_vehicle_required: loadDetail.is_bonded_vehicle_required
          ? true
          : false,
        is_tarpaulin_plastic_cover: loadDetail.is_tarpaulin_plastic_cover
          ? true
          : false,
        rate_validity_date: loadDetail?.rate_validity_date ? new Date(loadDetail.rate_validity_date) : null,

        is_covid_certf_req_driver: loadDetail.is_covid_certf_req_driver
          ? true
          : false,
        is_vehicle_tracking: loadDetail.is_vehicle_tracking ? true : false,

        is_shipper_owned_container: loadDetail.is_shipper_owned_container
          ? true
          : false,

        is_container_deposit_by_transporter: loadDetail?.is_container_deposit_by_transporter
          ? true
          : false,
        is_quote_price_deposit_fee_inclusive: loadDetail?.is_quote_price_deposit_fee_inclusive
          ? true
          : false,
        container_deposit_amount: loadDetail.container_deposit_amount
          ? loadDetail.container_deposit_amount
          : "",
        shipping_line_name: loadDetail.shipping_line_name
          ? loadDetail.shipping_line_name
          : "",
        is_cross_stuffing_required: loadDetail.is_cross_stuffing_required ? true : false,
        is_cross_stuffing_box_vehicle_required: loadDetail.is_cross_stuffing_box_vehicle_required
          ? true
          : false,
        cross_stuffing_no_of_packages: loadDetail.cross_stuffing_no_of_packages ? loadDetail.cross_stuffing_no_of_packages : "",

        is_advance_vehicle_driver_docs: loadDetail.is_advance_vehicle_driver_docs ? true : false,

        is_driver_negative_pcr_required: loadDetail?.is_driver_negative_pcr_required ? true : false,

        driver_requried_document_details: loadDetail.driver_requried_document_details
          ? loadDetail.driver_requried_document_details
          : null,

        is_under_hook_delivery_required: loadDetail.is_under_hook_delivery_required
          ? true
          : false,

        under_hook_delivery: loadDetail.under_hook_delivery
          ? loadDetail.under_hook_delivery
          : null,
      },


      detention_included_transport: loadDetail?.detention_included_transport ? true : false,

      transaction_type_id: loadDetail.transaction_type.id,
      location: loadDetail?.locations?.map((item) => {
        return {
          address: item?.raw_address,
          lat: item?.lat,
          lng: item?.long,
          description: item?.description,
          timestamp: null,
          title: item?.title,
          contact_name: item?.contact_name,
          contact_phone_no: item?.contact_phone_no,
          contact_phone_code: "92",
          city: item?.city,
          pickup_accordion: false,
          dropoff_accordion: false,
        };
      }),
      additional_pickups: loadDetail?.additional_pickups?.map((item) => {
        return {
          ...item,
          lng: item?.long,
          address: item?.raw_address,
          pickup_accordion: false,
          dropoff_accordion: false,
        }
      }),
      additional_dropoffs: loadDetail?.additional_dropoffs?.map((item) => {
        return {
          ...item,
          lng: item?.long,
          address: item?.raw_address,
        }
      }),

      responseDeadline: from == "clone" ? null : {
      //   pickupDate: new Date(loadDetail.pickup_date),
      //   pickupTime: loadDetail?.pickup_time ? new Date(`${loadDetail.pickup_date} ${loadDetail.pickup_time}`) : null,
      //   responseDate: new Date(loadDetail.deadline_at),
      //   responseTime: new Date(loadDetail.deadline_at),
      //   deliveryDate: loadDetail?.delivery_at
      //     ? new Date(loadDetail.delivery_at)
      //     : null,
      //   deliveryTime: loadDetail?.delivery_at
      //     ? new Date(loadDetail.delivery_at)
      //     : null,
      //   emptyReturnDeadlineAtDate: loadDetail?.empty_return_deadline_at
      //     ? new Date(loadDetail.empty_return_deadline_at)
      //     : null,
      //   emptyReturnDeadlineAtTime: loadDetail?.empty_return_deadline_at
      //     ? new Date(loadDetail.empty_return_deadline_at)
      //     : null,
      //   rateValidityDate: loadDetail?.rate_validity_date
      //     ? new Date(loadDetail.rate_validity_date)
      //     : null,
      pickupDate: dayjs(new Date(loadDetail.pickup_date)).format("YYYY-MM-DD"),
      pickupTime: loadDetail?.pickup_time ? dayjs(new Date(`${loadDetail.pickup_date} ${loadDetail.pickup_time}`)).format("HH:mm:ss") : null,
      responseDate: dayjs(new Date(loadDetail.deadline_at)).format("YYYY-MM-DD"),
      responseTime: dayjs(new Date(loadDetail.deadline_at)).format("HH:mm:ss"),
      is_empty_return: loadDetail.is_empty_return,
      empty_return_yard_name: loadDetail.empty_return_yard_name,
      deliveryDate: loadDetail?.delivery_at
        ? dayjs(new Date(loadDetail.delivery_at)).format("YYYY-MM-DD")
        : null,
      deliveryTime: loadDetail?.delivery_at
        ? dayjs(new Date(loadDetail.delivery_at)).format("HH:mm:ss")
        : null,
      emptyReturnDeadlineAtDate: loadDetail?.empty_return_deadline_at
        ? dayjs(new Date(loadDetail.empty_return_deadline_at)).format("YYYY-MM-DD")
        : null,
      emptyReturnDeadlineAtTime: loadDetail?.empty_return_deadline_at
        ? dayjs(new Date(loadDetail.empty_return_deadline_at)).format("HH:mm:ss")
        : null,
      rateValidityDate: loadDetail?.rate_validity_date
        ? dayjs(new Date(loadDetail.rate_validity_date)).format("YYYY-MM-DD")
        : null,
      },
      poster_deadline_date: new Date(loadDetail.deadline_at),
      poster_deadline_Time: new Date(loadDetail.deadline_at),

      PaymentScreen: {
        pt_is_advance: Boolean(loadDetail.pt_is_advance),
        pt_advance_value: loadDetail.pt_advance_value,
        pt_is_ondelivery: Boolean(loadDetail.pt_is_ondelivery),
        pt_ondelivery_value: loadDetail.pt_ondelivery_value,
        pt_is_credit: Boolean(loadDetail.pt_is_credit),
        pt_credit_percentage: loadDetail.pt_credit_percentage,
        pt_credit_days: loadDetail.pt_credit_days,
        is_pt_fuel_card:
          loadDetail.pt_fuel_card !== 0 && loadDetail.pt_fuel_card !== "0"
            ? true
            : false,
        pt_fuel_card_value: loadDetail.pt_fuel_card_value,
        is_gst_inclusive: Boolean(loadDetail.is_gst_inclusive),
        sales_tax_province: loadDetail.sales_tax_province,
        is_withholding_tax_inclusive: Boolean(
          loadDetail.is_withholding_tax_inclusive
        ),
      },
      documents: new_docs,
      ref_no: loadDetail.ref_no,
      recipients: from == 'forward' ? [] : recipients,
      forward_recipients: recipients,
      history_recipients: loadType == "Loads Received" ? [] : history_recipients,
    };


    if (
      getInquiryType(loadDetail.inquiry_type.id, inquiry_type)?.title ===
      "Contract Rate"
    ) {
      let addedVehiclesTemp = []

      const data = {
        contractDetails: {
          is_unit_basis: loadDetail?.is_unit_basis ? true : false,
          contract_name: loadDetail?.contract_name,
          contract_remarks: loadDetail?.remarks,
          contract_start_date: from == "clone" ? null : loadDetail?.contract_start_date,
          contract_end_date: from == "clone" ? null : loadDetail?.contract_end_date,
          // deadline_date: from == "clone" ? null : loadDetail?.deadline_at,
          // deadline_time: from == "clone" ? null : loadDetail?.deadline_at,
          deadline_date: from == "clone" ? null : dayjs(loadDetail?.deadline_at).format("YYYY-MM-DD"),
          deadline_time: from == "clone" ? null : dayjs(loadDetail?.deadline_at).format("HH:mm:ss"),
          quote_by: loadDetail?.quote_by,
          is_hse_compliant_vehicle: loadDetail?.is_hse_compliant_vehicle,

          is_weightment_received_required: loadDetail?.is_weightment_received_required,
          weightment_received_name: loadDetail?.weightment_received_name,
          is_bonded_vehicle_required: loadDetail?.is_bonded_vehicle_required,
          is_tarpaulin_plastic_cover: loadDetail.is_tarpaulin_plastic_cover,
          rate_validity_date: loadDetail?.rate_validity_date ? new Date(loadDetail.rate_validity_date) : null,

          is_covid_certf_req_driver: loadDetail?.is_covid_certf_req_driver,
          is_vehicle_tracking: loadDetail.is_vehicle_tracking,

          is_advance_vehicle_driver_docs: loadDetail.is_advance_vehicle_driver_docs,
          is_driver_negative_pcr_required: loadDetail?.is_driver_negative_pcr_required,
          detention_included_transport: loadDetail?.detention_included_transport,

          cargo_description: loadDetail?.cargo_description,
          is_cross_stuffing_required: loadDetail.is_cross_stuffing_required ? true : false,
          is_cross_stuffing_box_vehicle_required: loadDetail.is_cross_stuffing_box_vehicle_required
            ? true
            : false,
          cross_stuffing_no_of_packages: loadDetail.cross_stuffing_no_of_packages ? loadDetail.cross_stuffing_no_of_packages : "",
          driver_requried_document_details: loadDetail?.driver_requried_document_details,

          is_under_hook_delivery_required: loadDetail?.is_under_hook_delivery_required,
          under_hook_delivery: loadDetail?.under_hook_delivery,


        },
        location: loadDetail?.contract_locations?.map((loc: any) => {
          
          return {
            dropoff: {
              lat: loc?.dropoff?.lat,
              lng: loc?.dropoff?.long,
              address: loc?.dropoff?.raw_address,
              description: loc?.dropoff?.description,
              type: loc?.dropoff?.type,
              city: loc?.dropoff?.city,
              id: loc?.dropoff?.id,
              title: loc?.dropoff?.title,
              contact_name: loc?.dropoff?.contact_name,
              contact_phone_no: loc?.dropoff?.contact_phone_no,
              contact_phone_code: "92"
            },
            pickup: {
              attribute:
                loadDetail.load_type?.title === LOAD_TYPE.fcl
                  ? {
                    cargo_volume: loc?.cargo_volume || null,
                    gross_weight: loc?.gross_weight || null,
                    gross_weight_in_kgs: loc?.gross_weight ? Number(loc?.gross_weight) * 1000 : null,
                    total_freight_ton: loc?.total_freight_ton || null,
                    no_of_packages: loc?.no_of_packages || null,
                  }
                  : loadDetail.load_type?.title === LOAD_TYPE.bulk
                    ? loadDetail?.quote_by === "vehicle"
                      ? { ...loc?.pickup?.attributes, gross_weight_in_kgs: Number(loc?.pickup?.attributes?.gross_weight) * 1000, inputType: ["text", "text", "text"] }
                      : {
                        ...loc?.pickup?.attributes,
                        inputType: ["text", "text", "text"],
                        gross_weight_in_kgs: Number(loc?.pickup?.attributes?.gross_weight) * 1000,
                        new_recipients: loc?.recipients,

                        packageType: getPackageById(
                          loc?.pickup?.attributes?.package_type_id,
                          packageType
                        ),

                        PreferredVehicleType:
                          getPreferredVehicleById(loc?.pickup?.attributes?.preferred_vehicle_id,
                            PreferredVehicleType),

                      }
                    : convertArrayToObject(loc?.categories),
              lat: loc?.pickup?.lat,
              lng: loc?.pickup?.long,
              address: loc?.pickup?.raw_address,
              description: loc?.pickup?.description,
              type: loc?.pickup?.type,
              city: loc?.pickup?.city,
              id: loc?.pickup?.id,
              title: loc?.pickup?.title,
              contact_name: loc?.pickup?.contact_name,
              contact_phone_no: loc?.pickup?.contact_phone_no,
              contact_phone_code: "92"

            },
            items: loadDetail?.is_unit_basis ? [] : loc?.categories?.map((item) => {
              
              let obj_vehicle = {
                containerType: item?.container_type,
                PreferredVehicleType: getPreferredVehicleById(item?.preferred_vehicles, PreferredVehicleType),
                max_vehicle_weight: item?.max_vehicle_weight,
                packageType: getPackageById(item?.package_type_id, packageType),
                vehicle_quantity: item?.vehicle_quantity ? item?.vehicle_quantity : "",
                selectVehicleTypeObj: item?.vehicle,
                new_recipients: item?.recipients,
                values:
                  load_type === LOAD_TYPE.fcl
                    ? {
                      is_boxed: item?.is_boxed ? true : false,
                      is_loading_required: item?.is_loading_required
                        ? true
                        : false,
                      is_out_of_guage: item?.is_out_of_guage ? true : false,
                      is_sidewall: item?.is_sidewall ? true : false,
                      is_any_suitable_vehicle: loadDetail?.is_any_suitable_vehicle ? true : false,
                      is_flat_bed_vehicle: loadDetail?.is_flat_bed_vehicle ? true : false,
                      is_low_bed_vehicle: loadDetail?.is_low_bed_vehicle ? true : false,
                      is_dum_truck_vehicle: loadDetail?.is_dum_truck_vehicle ? true : false,
                      is_twin_load: item?.is_twin_load ? true : false,
                      is_unloading_required: item?.is_unloading_required
                        ? true
                        : false,
                      is_loading_requirements: item?.loading_requirements,
                      unloading_requirements: item?.unloading_requirements,
                      is_shipper_owned_container:
                        item?.is_shipper_owned_container,
                      is_genset: item?.is_genset ? true : false,
                      required_temperature: [item?.required_temperature_min
                        ? Number(item?.required_temperature_min) : 0,
                      item?.required_temperature_max ? Number(item?.required_temperature_max) : 0]
                    }
                    : {
                      cargo_volume: item?.cargo_volume || null,
                      gross_weight: item?.gross_weight || null,
                      gross_weight_in_kgs: item?.gross_weight ? Number(item?.gross_weight) * 1000 : null,
                      total_freight_ton: item?.total_freight_ton || null,
                      no_of_packages: item?.no_of_packages || null,
                      is_boxed: item?.is_boxed ? true : false,
                      is_sidewall: item?.is_sidewall ? true : false,
                      is_any_suitable_vehicle: loadDetail?.is_any_suitable_vehicle ? true : false,
                      is_flat_bed_vehicle: loadDetail?.is_flat_bed_vehicle ? true : false,
                      is_low_bed_vehicle: loadDetail?.is_low_bed_vehicle ? true : false,
                      is_dum_truck_vehicle: loadDetail?.is_dum_truck_vehicle ? true : false,
                      is_loading_required: item?.is_loading_required
                        ? true
                        : false,
                      is_unloading_required: item?.is_unloading_required
                        ? true
                        : false,
                      loading_requirements: item?.loading_requirements,
                      packageType: item?.package_type,
                      unloading_requirements: item?.unloading_requirements,
                      detention_free_time: item?.detention_free_time,
                      is_genset: item?.is_genset ? true : false,
                      required_temperature: [item?.required_temperature_min ? Number(item?.required_temperature_min) : 0,
                      item?.required_temperature_max ? Number(item?.required_temperature_max) : 0],
                      max_bid: item?.max_bid && item?.max_bid != 0 ? item.max_bid : null,

                      detention_included_transport:
                        item?.detention_included_transport,
                      package_type_other: item?.package_type_other,
                    },

              };
              if (!addedVehiclesTemp?.find((item) => item?.PreferredVehicleType?.id == obj_vehicle?.PreferredVehicleType?.id && item?.selectVehicleTypeObj?.id == obj_vehicle?.selectVehicleTypeObj?.id)) {
                addedVehiclesTemp.push(obj_vehicle)
              }
              return obj_vehicle
            }),
            added_units: !loadDetail?.is_unit_basis ? [] : loc?.categories?.map((item) => {
              return {
                gross_weight: item?.gross_weight || null,
                cargo_volume: item?.cargo_volume || null,
                no_of_packages: item?.no_of_packages || null,
                total_freight_ton: item?.total_freight_ton,
                gross_weight_in_kgs: item?.gross_weight ? Number(item?.gross_weight) * 1000 : null,
                dimensions: item?.dimensions,
                unit_dimension_value: item?.unit_dimension_value,
                unit_quantity: item?.unit_quantity,
                unit_description: item?.unit_description,
                is_unit_basis: true,
                unit_edit_mode: false,
                unit_edit_id: null,
                detention_additional_charges: item?.detention_additional_charges || null,
                detention_free_time: item?.detention_free_time,
                detention_rate: item?.detention_rate,
                is_loading_required: item?.is_loading_required,
                is_unloading_required: item?.is_unloading_required,
                loading_requirements: item?.loading_requirements,
                unloading_requirements: item?.unloading_requirements,
                loading_price: item?.loading_price,
                unloading_price: item?.unloading_price,
                price: item?.price,
                total_amount: item?.total_amount,
                max_bid: item?.max_bid,
                new_recipients: item?.recipients,
              };
            })
          };
        }),
      };

      // draftData.vehicleScreen = null;
      draftData.vehicleScreen = {
        addedVehicles: [],
        addedVehiclesTemp,
      };
      draftData = { ...draftData, ...data };

    }
    if (loadDetail?.is_unit_basis && loadDetail?.inquiry_type?.title == 'Spot Rate') {
      draftData["vehicleScreen"]["addedVehicles"] = []
    } else if (!loadDetail?.is_unit_basis && loadDetail?.inquiry_type?.title == 'Spot Rate') {
      draftData["vehicleScreen"]["added_units"] = []
    }
    return { ...draftData };
  }
};

export const convertDataForLoadDetailRedux = (
  loadDetail: any,
  packageType: any,
  load_type: any,
  inquiry_type: any,
  from = null,
  loadType

) => {
  if (loadDetail) {
    let new_docs = {};
    let temp_bill_of_lading = [];
    let temp_packing_list = [];
    let temp_other_uploads = [];
    loadDetail?.documents.forEach((item) => {
      if (
        item?.type_name == "bill_of_ladings" ||
        item?.type_name == "bill_of_lading"
      ) {
        temp_bill_of_lading.push({
          file_name: item.file_name,
          cloudinary_url: item.path,
          file_url: item.relative_path,
          mime_type: item.mime_type,
        });
      } else if (
        item?.type_name == "packing_lists" ||
        item?.type_name == "packing_list"
      ) {
        temp_packing_list.push({
          file_name: item.file_name,
          cloudinary_url: item.path,
          file_url: item.relative_path,
          mime_type: item.mime_type,
        });
      } else {
        if (temp_other_uploads.find((itm) => itm.title == item.other_type)) {
          temp_other_uploads[
            temp_other_uploads.findIndex((it) => it.title == item.other_type)
          ].files.push({
            file_name: item.file_name,
            cloudinary_url: item.path,
            file_url: item.relative_path,
            mime_type: item.mime_type,
          });
        } else {
          temp_other_uploads.push({
            title: item?.other_type,
            files: [
              {
                file_name: item.file_name,
                cloudinary_url: item.path,
                file_url: item.relative_path,
                mime_type: item.mime_type,
              },
            ],
          });
        }
      }
    });
    new_docs["bill_of_ladings"] = temp_bill_of_lading;
    new_docs["packing_lists"] = temp_packing_list;
    new_docs["other_uploads"] = temp_other_uploads;

    let recipients = [];
    let history_recipients = []

    if (from == 'forward') {
      recipients.push({ phone_no: loadDetail?.poster?.phone_no, phone_code: loadDetail?.poster?.phone_code })
    }

    let draftData = {
      from,
      allocates: loadDetail?.allocates,
      is_allocated: loadDetail?.is_allocated,
      fromClone: true,
      load_id: loadDetail?.id,
      title: loadDetail.title,
      inquiry_type: loadDetail.inquiry_type.id,
      load_type: loadDetail.load_type.id,
      instructions: loadDetail?.instructions ? loadDetail?.instructions : null,
      additional_requirements: loadDetail?.additional_requirements,
      instructions_voice_note: loadDetail?.instructions_voice_note ? {
        cloudinary_url: loadDetail?.instructions_voice_note,
        file_url: loadDetail?.instructions_voice_note_object?.file_url
      } : null,
      is_empty_return: loadDetail.is_empty_return,
      empty_return_yard_name: loadDetail.empty_return_yard_name,

      vehicleScreen: {
        values: {
          load_name: loadDetail.title,
          cargo_description: loadDetail?.cargo_description,
          ref_no: loadDetail?.ref_no

        },
        addedVehicles: loadDetail?.categories?.map((item) => {
          
          item?.recipients?.forEach((recipient) => {
            let find = recipients.find((item) => item?.phone_no == recipient?.recipient?.phone_no)
            if (find == undefined) {
              recipients.push({
                phone_no: recipient?.recipient?.phone_no,
                phone_code: recipient?.recipient?.phone_code,
              });
              history_recipients.push({
                ...recipient
              })
            }
          });
          return {
            selectVehicleTypeObj: {
              ...item?.vehicle,
            },
            vehicle_quantity: item?.vehicle_quantity ? item?.vehicle_quantity : "",
            containerType: {
              ...item?.container_type,
            },
            vehicles_drivers_refs: item?.vehicles_drivers_refs,
            new_recipients: item?.recipients,
            max_vehicle_weight: item?.max_vehicle_weight,
            total_amount: item?.total_offer_price,
            price: item?.offer_price_per_vehicle,
            detention_rate: item?.detention_rate,
            detention_free_time: item?.detention_free_time,
            // is_genset: item?.is_genset ? true : false,


            max_bid: item?.max_bid && item?.max_bid != 0 ? item.max_bid : null,
            detention_included_transport: item?.detention_included_transport
              ? true
              : false,
            detention_additional_charges: item?.detention_additional_charges,
            package_type_other: item?.package_type_other,
            loading_price: item?.loading_price,
            unloading_price: item?.unloading_price,
            is_loading_required: item?.is_loading_required ? true : false,
            is_unloading_required: item?.is_unloading_required ? true : false,
            loading_requirements: item?.loading_requirements,
            unloading_requirements: item?.unloading_requirements,

            values: {
              is_out_of_guage: item?.is_out_of_guage ? true : false,
              is_twin_load: item?.is_twin_load ? true : false,
              is_shipper_owned_container: item?.is_shipper_owned_container
                ? true
                : false,

              required_temperature: [item?.required_temperature_min ? Number(item?.required_temperature_min) : 0,
              item?.required_temperature_max ? Number(item?.required_temperature_max) : 0],
              is_genset: item?.is_genset ? true : false,
            },
          };
        }),
        is_allow_partial_load: loadDetail?.is_allow_partial_load ? true : false,
      },
      additionalScreen: {
        is_hse_compliant_vehicle: loadDetail.is_hse_compliant_vehicle
          ? true
          : false,
        is_weightment_received_required: loadDetail.is_weightment_received_required
          ? true
          : false,

        weightment_received_name: loadDetail.weightment_received_name
          ? loadDetail.weightment_received_name
          : null,

        is_bonded_vehicle_required: loadDetail?.is_bonded_vehicle_required
          ? true
          : false,

        is_tarpaulin_plastic_cover: loadDetail.is_tarpaulin_plastic_cover
          ? true
          : false,

        rate_validity_date: loadDetail?.rate_validity_date ? new Date(loadDetail.rate_validity_date) : null,

        is_covid_certf_req_driver: loadDetail.is_covid_certf_req_driver
          ? true
          : false,
        is_vehicle_tracking: loadDetail.is_vehicle_tracking ? true : false,
        is_shipper_owned_container: loadDetail.is_shipper_owned_container
          ? true
          : false,
        is_container_deposit_by_transporter: loadDetail.is_container_deposit_by_transporter
          ? true
          : false,
        is_quote_price_deposit_fee_inclusive: loadDetail?.is_quote_price_deposit_fee_inclusive
          ? true
          : false,
        container_deposit_amount: loadDetail.container_deposit_amount
          ? loadDetail.container_deposit_amount
          : "",
        shipping_line_name: loadDetail.shipping_line_name
          ? loadDetail.shipping_line_name
          : "",

        is_cross_stuffing_required: loadDetail.is_cross_stuffing_required ? true : false,
        is_cross_stuffing_box_vehicle_required: loadDetail.is_cross_stuffing_box_vehicle_required
          ? true
          : false,
        cross_stuffing_no_of_packages: loadDetail.cross_stuffing_no_of_packages ? loadDetail.cross_stuffing_no_of_packages : "",

        is_advance_vehicle_driver_docs: loadDetail.is_advance_vehicle_driver_docs ? true : false,

        is_driver_negative_pcr_required: loadDetail?.is_driver_negative_pcr_required ? true : false,

        driver_requried_document_details: loadDetail.driver_requried_document_details
          ? loadDetail.driver_requried_document_details
          : null,
        is_under_hook_delivery_required: loadDetail.is_under_hook_delivery_required
          ? true
          : false,

        under_hook_delivery: loadDetail.under_hook_delivery
          ? loadDetail.under_hook_delivery
          : null,
      },


      detention_included_transport: loadDetail?.detention_included_transport ? true : false,

      transaction_type_id: loadDetail.transaction_type.id,
      location: loadDetail?.locations?.map((item) => {
        return {
          address: item?.raw_address,
          lat: item?.lat,
          lng: item?.long,
          description: item?.description,
          timestamp: null,
          title: item?.title,
          contact_name: item?.contact_name,
          contact_phone_no: item?.contact_phone_no,
          contact_phone_code: "92",
          pickup_accordion: false,
          dropoff_accordion: false,
          city: item?.city
        };
      }),
      additional_pickups: loadDetail?.additional_pickups?.map((item) => {
        return {
          ...item,
          lng: item?.long,
          address: item?.raw_address,
          pickup_accordion: false,
          dropoff_accordion: false
        }
      }),
      additional_dropoffs: loadDetail?.additional_dropoffs?.map((item) => {
        return {
          ...item,
          lng: item?.long,
          address: item?.raw_address,
          pickup_accordion: false,
          dropoff_accordion: false
        }
      }),

      responseDeadline: from == "clone" ? null : {
        pickupDate: dayjs(new Date(loadDetail.pickup_date)).format("YYYY-MM-DD"),
        pickupTime: loadDetail?.pickup_time ? dayjs(new Date(`${loadDetail.pickup_date} ${loadDetail.pickup_time}`)).format("HH:mm:ss") : null,
        responseDate: dayjs(new Date(loadDetail.deadline_at)).format("YYYY-MM-DD"),
        responseTime: dayjs(new Date(loadDetail.deadline_at)).format("HH:mm:ss"),
        is_empty_return: loadDetail.is_empty_return,
        empty_return_yard_name: loadDetail.empty_return_yard_name,
        deliveryDate: loadDetail?.delivery_at
          ? dayjs(new Date(loadDetail.delivery_at)).format("YYYY-MM-DD")
          : null,
        deliveryTime: loadDetail?.delivery_at
          ? dayjs(new Date(loadDetail.delivery_at)).format("HH:mm:ss")
          : null,
        emptyReturnDeadlineAtDate: loadDetail?.empty_return_deadline_at
          ? dayjs(new Date(loadDetail.empty_return_deadline_at)).format("YYYY-MM-DD")
          : null,
        emptyReturnDeadlineAtTime: loadDetail?.empty_return_deadline_at
          ? dayjs(new Date(loadDetail.empty_return_deadline_at)).format("HH:mm:ss")
          : null,
        rateValidityDate: loadDetail?.rate_validity_date
          ? dayjs(new Date(loadDetail.rate_validity_date)).format("YYYY-MM-DD")
          : null,
      },
      poster_deadline_date: new Date(loadDetail.deadline_at),
      poster_deadline_Time: new Date(loadDetail.deadline_at),
      PaymentScreen: {
        pt_is_advance: Boolean(loadDetail.pt_is_advance),
        pt_advance_value: loadDetail.pt_advance_value,
        pt_is_ondelivery: Boolean(loadDetail.pt_is_ondelivery),
        pt_ondelivery_value: loadDetail.pt_ondelivery_value,
        pt_is_credit: Boolean(loadDetail.pt_is_credit),
        pt_credit_percentage: loadDetail.pt_credit_percentage,
        pt_credit_days: loadDetail.pt_credit_days,
        is_pt_fuel_card:
          loadDetail.pt_fuel_card !== 0 && loadDetail.pt_fuel_card !== "0"
            ? true
            : false,
        pt_fuel_card_value: loadDetail.pt_fuel_card_value,
        is_gst_inclusive: Boolean(loadDetail.is_gst_inclusive),
        sales_tax_province: loadDetail.sales_tax_province,
        is_withholding_tax_inclusive: Boolean(
          loadDetail.is_withholding_tax_inclusive
        ),
      },
      documents: new_docs,
      ref_no: loadDetail.ref_no,
      recipients: from == 'forward' ? [] : recipients,
      forward_recipients: recipients,
      history_recipients: loadType == "Loads Received" ? [] : history_recipients,

    };

    if (
      getInquiryType(loadDetail.inquiry_type.id, inquiry_type)?.title ===
      "Contract Rate"
    ) {
      let addedVehiclesTemp = []
      const data = {
        contractDetails: {
          contract_name: loadDetail?.contract_name,
          contract_remarks: loadDetail?.remarks,
          contract_start_date: from == "clone" ? null : loadDetail?.contract_start_date,
          contract_end_date: from == "clone" ? null : loadDetail?.contract_end_date,
          // deadline_date: from == "clone" ? null : loadDetail?.deadline_at,
          // deadline_time: from == "clone" ? null : loadDetail?.deadline_at,
          deadline_date: from == "clone" ? null : dayjs(loadDetail?.deadline_at).format("YYYY-MM-DD"),
          deadline_time: from == "clone" ? null : dayjs(loadDetail?.deadline_at).format("HH:mm:ss"),
          quote_by: loadDetail?.quote_by,
          is_hse_compliant_vehicle: loadDetail?.is_hse_compliant_vehicle,
          is_weightment_received_required: loadDetail?.is_weightment_received_required,
          weightment_received_name: loadDetail?.weightment_received_name,
          is_bonded_vehicle_required: loadDetail?.is_bonded_vehicle_required,
          is_tarpaulin_plastic_cover: loadDetail?.is_tarpaulin_plastic_cover,
          rate_validity_date: loadDetail?.rate_validity_date ? new Date(loadDetail.rate_validity_date) : null,

          is_covid_certf_req_driver: loadDetail?.is_covid_certf_req_driver,
          is_vehicle_tracking: loadDetail.is_vehicle_tracking,
          is_advance_vehicle_driver_docs: loadDetail?.is_advance_vehicle_driver_docs,
          is_driver_negative_pcr_required: loadDetail?.is_driver_negative_pcr_required,
          detention_included_transport: loadDetail?.detention_included_transport,
          is_cross_stuffing_required: loadDetail.is_cross_stuffing_required ? true : false,
          is_cross_stuffing_box_vehicle_required: loadDetail.is_cross_stuffing_box_vehicle_required
            ? true
            : false,
          cross_stuffing_no_of_packages: loadDetail.cross_stuffing_no_of_packages ? loadDetail.cross_stuffing_no_of_packages : "",
          driver_requried_document_details: loadDetail?.driver_requried_document_details,
          cargo_description: loadDetail?.cargo_description,

          is_under_hook_delivery_required: loadDetail?.is_under_hook_delivery_required,
          under_hook_delivery: loadDetail?.under_hook_delivery,

        },
        location: loadDetail?.contract_locations?.map((loc: any) => {
          return {
            dropoff: {
              lat: loc?.dropoff?.lat,
              lng: loc?.dropoff?.long,
              address: loc?.dropoff?.raw_address,
              description: loc?.dropoff?.description,
              type: loc?.dropoff?.type,
              city: loc?.dropoff?.city,
              id: loc?.dropoff?.id,
              title: loc?.dropoff?.title,
              contact_name: loc?.dropoff?.contact_name,
              contact_phone_no: loc?.dropoff?.contact_phone_no,
              contact_phone_code: "92"
            },
            pickup: {
              attribute: {
                cargo_volume: loc?.cargo_volume || null,
                gross_weight: loc?.gross_weight || null,
                gross_weight_in_kgs: loc?.gross_weight ? Number(loc?.gross_weight) * 1000 : null,
                no_of_packages: loc?.no_of_packages || null,
                total_freight_ton: loc?.total_freight_ton || null,
                detention_free_time: loc?.detention_free_time,
                cargo_liter: loc?.cargo_liter,



                detention_included_transport: loc?.detention_included_transport
                  ? true
                  : false,
              },
              lat: loc?.pickup?.lat,
              lng: loc?.pickup?.long,
              address: loc?.pickup?.raw_address,
              description: loc?.pickup?.description,
              type: loc?.pickup?.type,
              city: loc?.pickup?.city,
              id: loc?.pickup?.id,
              title: loc?.pickup?.title,
              contact_name: loc?.pickup?.contact_name,
              contact_phone_no: loc?.pickup?.contact_phone_no,
              contact_phone_code: "92"
            },
            items: loc?.categories?.map((item) => {
              
              let obj_vehicle = {
                containerType: item?.container_type,
                max_vehicle_weight: item?.max_vehicle_weight,
                packageType: getPackageById(item?.package_type_id, packageType),
                vehicle_quantity: item?.vehicle_quantity ? item?.vehicle_quantity : "",
                selectVehicleTypeObj: item?.vehicle,
                new_recipients: item?.recipients,
                values: {
                  is_boxed: item?.is_boxed ? true : false,
                  is_loading_required: item?.is_loading_required ? true : false,
                  is_out_of_guage: item?.is_out_of_guage ? true : false,
                  is_sidewall: item?.is_sidewall ? true : false,
                  is_any_suitable_vehicle: loadDetail?.is_any_suitable_vehicle ? true : false,
                  is_flat_bed_vehicle: loadDetail?.is_flat_bed_vehicle ? true : false,
                  is_low_bed_vehicle: loadDetail?.is_low_bed_vehicle ? true : false,
                  is_dum_truck_vehicle: loadDetail?.is_dum_truck_vehicle ? true : false,
                  is_twin_load: item?.is_twin_load ? true : false,
                  is_unloading_required: item?.is_unloading_required
                    ? true
                    : false,
                  is_loading_requirements: item?.loading_requirements,
                  unloading_requirements: item?.unloading_requirements,
                  is_shipper_owned_container: item?.is_shipper_owned_container,
                  detention_free_time: item?.detention_free_time,
                  is_genset: item?.is_genset ? true : false,
                  required_temperature: [item?.required_temperature_min ? Number(item?.required_temperature_min) : 0,
                  item?.required_temperature_max ? Number(item?.required_temperature_max) : 0],


                  detention_included_transport: item?.detention_included_transport
                    ? true
                    : false,
                  package_type_other: item?.package_type_other,
                },
              };
              if (!addedVehiclesTemp?.find((item) => item?.containerType?.id == obj_vehicle?.containerType?.id && item?.selectVehicleTypeObj?.id == obj_vehicle?.selectVehicleTypeObj?.id)) {
                addedVehiclesTemp.push(obj_vehicle)
              }
              return obj_vehicle
            }),
          };
        }),
      };
      draftData.vehicleScreen = {
        addedVehicles: [],
        addedVehiclesTemp,
        values: {
          cargo_description: loadDetail?.cargo_description
        }
      };
      draftData = { ...draftData, ...data };
    }

    return { ...draftData };
  }
};
