import React from "react";
import { AddIVehiclePageProps } from "./types";

import Page from "./Page";

const View: React.FC<AddIVehiclePageProps> = ({
  room,
  role,
  id,
  canPlay,
  data = {},
}: AddIVehiclePageProps) => {
  return <Page role={role} room={room} id={id} canPlay={canPlay} data={data} />;
};

export default View;
