import React from "react";
import { Route, Redirect } from "react-router-dom";
import { TOKEN } from "@app/constants";
import { getItem } from "@utils/helper";
import { IPrivateRoutesProps } from "./types";
import * as paths from "./paths";

const PrivateRoutes: React.FC<IPrivateRoutesProps> = ({
  component: Component,
  restricted,
  ...rest
}) => {
  const currentToken = getItem(TOKEN);
  return (
    <Route
      {...rest}
      render={props =>
        (currentToken) ? (
          <Component {...props} />
        ) : (
          <Redirect to={paths.BASE_URL} />
        )
      }
    />
  );
};

export default PrivateRoutes;