import React from "react";
import Page from "./Page";
import { PaymentTermsProps } from "./Page";

const View: React.FC<PaymentTermsProps> = ({
    index = '1',
}) => {
  return <Page index={index}/>;
};

export default View;
