import { Grid } from "@mui/material/";
import { Colors } from "@temp/assets";
import { CustomText } from "@temp/components";
import { LoadAction } from "@temp/store/actions";
import React from "react";
import { useDispatch } from "react-redux";
import "../ContractDetailPoster/style.scss";
var numeral = require("numeral");

export const NeotiateBidAcceptReject = ({
    bid,
    loadDetail,
    price_unit
}) => {

    const dispatch = useDispatch()
    let is_negotiate = bid?.bid?.bid_is_negotiated ? true : false
    const accept = () => {
        dispatch(LoadAction.negotiateBidAcceptRejectRecipient({ load_id: loadDetail.id, job_load_recipient_bid_id: bid?.bid?.id, negotiate_status: true }))
    }
    const reject = () => {
        dispatch(LoadAction.negotiateBidAcceptRejectRecipient({ load_id: loadDetail.id, job_load_recipient_bid_id: bid?.bid?.id, negotiate_status: false }))
    }
    return (
        <div>
            {
                is_negotiate ?
                    <div style={{
                        marginBottom: 10
                    }}>

                        <div className="dividing-bordered"></div>
                        <Grid item xs={12} style={{ paddingBottom: 0, marginBottom: 10 }}>
                            <CustomText.OpenSansBold>
                                Negotiated Bid
                                <span className="seperator">/</span>
                                <span className="urduLabel">بات چیت کے ذر یعے طے کی گٔی بولی</span>
                            </CustomText.OpenSansBold>
                        </Grid>
                        {/* <div className="formGrid">
                            <div className="grayLabel">
                                <span className="seperator">/</span>
                                <span className="urduLabel" style={{ margin: '0.3rem 0' }}>بات چیت کے ذر یعے طے کی گٔی قیمت (GST کے بغیر)</span>
                            </div>
                            <div className="fw-5">
                                {`${numeral(bid?.bid?.bid_negotiated_amount).format('0,0')} PKR`}
                            </div>
                        </div> */}
                        {
                            bid?.bid?.bid_negotiated_amount &&
                            <div className="formGrid">
                                <div className="grayLabel">
                                    {/* Negotiated Price (Without GST) */}
                                    {/* {`Negotiated Price / ${loadDetail?.price_unit} (Without GST) `} */}
                                    {
                                        loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Spot Rate" ?
                                            ` Negotiated Price / ${loadDetail?.is_unit_basis ? "Item" : loadDetail?.price_unit}`
                                            : loadDetail?.quote_by == "vehicle" ?
                                                "Negotiated Price / Vehicle" :
                                                loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Contract Rate" ? `Negotiated Price / ${loadDetail?.is_unit_basis ? "Item" : price_unit}` : ""
                                    }

                                    <span className="seperator">/</span>
                                    <span className="urduLabel" style={{ margin: '0.3rem 0' }}>بات چیت کے ذر یعے طے کی گٔی قیمت (GST کے بغیر)</span>
                                </div>
                                <div className="fw-5">
                                    {
                                        loadDetail?.quote_by == "vehicle" ?
                                            `${numeral(bid?.bid?.bid_negotiated_amount).format('0,0')} PKR`
                                            :
                                            `${numeral(bid?.bid?.bid_negotiated_amount).format('0,0.00')} PKR`}
                                </div>
                            </div>
                        }

                        {
                            bid?.bid?.bid_negotiated_price_loading &&
                            <div className="formGrid">
                                <div className="grayLabel">
                                    {/* {`Negotiated Loading Price / ${loadDetail?.price_unit} (Without GST) `} */}
                                    {
                                        loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Spot Rate" ?
                                            ` Negotiated Loading Price / ${loadDetail?.is_unit_basis ? "Item" : loadDetail?.price_unit}`
                                            : loadDetail?.quote_by == "vehicle" ?
                                                "Negotiated Loading / Vehicle" :
                                                loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Contract Rate" ? `Loading Price / ${loadDetail?.is_unit_basis ? "Item" : price_unit}` : ""
                                    }
                                    <span className="seperator">/</span>
                                    <span className="urduLabel" style={{ margin: '0.3rem 0' }}>بات چیت کے ذر یعے طے کی گٔی قیمت (GST کے بغیر)</span>
                                </div>
                                <div className="fw-5">
                                    {

                                        loadDetail?.quote_by == "vehicle" ?
                                            `${numeral(bid?.bid?.bid_negotiated_price_loading).format('0,0')} PKR`
                                            :
                                            `${numeral(bid?.bid?.bid_negotiated_price_loading).format('0,0.00')} PKR`}
                                </div>
                            </div>
                        }

                        {
                            bid?.bid?.bid_negotiated_price_unloading &&
                            <div className="formGrid">
                                <div className="grayLabel">
                                    {/* {`Negotiated Unloading Price / ${loadDetail?.price_unit} (Without GST)`} */}
                                    {
                                        loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Spot Rate" ?
                                            ` Negotiated Unloading Price / ${loadDetail?.is_unit_basis ? "Item" : loadDetail?.price_unit}`
                                            : loadDetail?.quote_by == "vehicle" ?
                                                "Negotiated Unloading / Vehicle" :
                                                loadDetail?.quote_by == "weight_volume" && loadDetail?.inquiry_type?.title == "Contract Rate" ? `Unloading Price / ${loadDetail?.is_unit_basis ? "Item" : price_unit}` : ""
                                    }
                                    <span className="seperator">/</span>
                                    <span className="urduLabel" style={{ margin: '0.3rem 0' }}>بات چیت کے ذر یعے طے کی گٔی قیمت (GST کے بغیر)</span>
                                </div>
                                <div className="fw-5">
                                    {loadDetail?.quote_by == "vehicle" ?
                                        `${numeral(bid?.bid?.bid_negotiated_price_unloading).format('0,0')} PKR`
                                        :
                                        `${numeral(bid?.bid?.bid_negotiated_price_unloading).format('0,0.00')} PKR`}
                                </div>
                            </div>
                        }


                        {
                            bid?.bid?.bid_negotiated_detention_rate ?
                                <div className="formGrid">
                                    <div className="grayLabel">
                                        Negotiated Detention Rate (Without GST)
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">بات چیت کے ذر یعے طے کیا گیا ڈیٹنشن ریٹ (کے بغیر GST)</span>
                                    </div>
                                    <div className="fw-5">
                                        {
                                            loadDetail?.quote_by == "vehicle" ?
                                                `${numeral(bid?.bid?.bid_negotiated_detention_rate).format('0,0')} PKR`
                                                :
                                                `${numeral(bid?.bid?.bid_negotiated_detention_rate).format('0,0.00')} PKR`}
                                    </div>
                                </div> : null
                        }

                        {
                            bid?.bid?.bid_negotiated_detention_tarif_policy ?
                                <div className="formGrid">
                                    <div className="grayLabel">Negotiated Detention Tariff & Policy
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">بات چیت کے ذر یعے طے کیا گیا ڈیٹنشن ٹیرف اور پالیسی </span>

                                    </div>
                                    <div className="fw-5">
                                        {bid?.bid?.bid_negotiated_detention_tarif_policy}
                                    </div>
                                </div> : null
                        }
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Grid item xs={6} style={{ paddingBottom: 0, marginBottom: 0 }}>
                                <div className="nogotiate_button" onClick={() => {
                                    accept()
                                }} style={{
                                    backgroundColor: Colors.Primary,
                                    width: 'fit-content',
                                    padding: '0.25rem 0.75rem',
                                    borderRadius: 24,
                                    color: "white"
                                }}>
                                    <span className="titleName" style={{
                                        color: "white",
                                        paddingTop: '5px',
                                        paddingRight: '3px'
                                    }}>
                                        Accept
                                    </span>
                                    <span className="seperator">/</span>
                                    <span className="urduLabel">قبول کریں</span>
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: 0, marginBottom: 0 }}>
                                <div className="nogotiate_button" onClick={() => {
                                    reject()
                                }} style={{
                                    backgroundColor: Colors.Red,
                                    width: 'fit-content',
                                    padding: '0.25rem 0.75rem',
                                    borderRadius: 24,
                                    color: "white"
                                }}>
                                    <span className="titleName" style={{
                                        color: "white",
                                        paddingTop: '5px',
                                        paddingRight: '3px'
                                    }}>
                                        Reject
                                    </span>
                                    <span className="seperator">/</span>
                                    <span className="urduLabel">رد کرنا</span>
                                </div>
                            </Grid>
                        </Grid>
                    </div> : null
            }
        </div>
    );
};









