import { PrimaryButton, TitleLayout, SimpleInput, PhoneInput } from "@temp/components";
import React, { FC, useEffect, useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { DrawerAction } from "@temp/store/actions";
import { useHistory } from "react-router";
import { validateNameNumber } from "@temp/utils/helper";
import SimplePhoneInput from "@temp/components/SimplePhoneInput";

export const AddLocation: FC = (props: any) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [locationName, setLocationName] = useState("");
  const [contact_name, set_contact_name] = useState("");
  const [contact_number, set_contact_number] = useState("");
  const [index, setIndex] = useState(null);
  const history = useHistory();
  const [data, setData] = useState(null);
  useEffect(() => {
    const id = props.match.params.index;
    setIndex(id);
  }, []);

  const getCity = (formatted_address: any) => {
    var itemLocality = null;
    if (formatted_address.length) {
      formatted_address.forEach((address: any) => {
        if (address.types[0] == "locality") {
          itemLocality = address.long_name;
        }
      });
    }
    return itemLocality;
  };
  
  const handleAutoComplete = (e) => {
    setSearch(e.target.value);
    var element = document.getElementById(`map-address`) as HTMLInputElement;
    
    setData({
      lat: null,
      long: null,
      raw_address: e.target.value,
      city: null,
      model_name: "user",
      // type: Number(index) === 0 ? "pickup" : "dropoff",
    });
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["establishment"],
      };

      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );
      
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const new_address = document.querySelector(`#map-address`)?.value;
        
        if (place) {
          getCity(place);
        }
        if (!place.geometry || !place.geometry.location) {
          return;
        } else {
          var coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          const lat = coordinates?.lat;
          const long = coordinates?.lng;
          const raw_address = new_address;
          setSearch(raw_address);
          const city = getCity(place.address_components);

          setData({
            lat,
            long,
            raw_address,
            city,
            model_name: "user",
            // type: Number(index) === 0 ? "pickup" : "dropoff",
          });
        }
      });
    }
  };

  const cb = () => {
    history.replace("/locations" 
    // + (Number(index) === 0 ? "pick" : "drop")
    );
  };

  const handleDisable = () => {
    return data?.raw_address?.length && locationName ? false : true
  }

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    // Remove non-numeric characters
    const numericValue = paste.replace(/[^0-9]/g, '');
    // Prevent pasting if the first character is 0
    if (event?.target?.value.length > 10) {
      return;
    }
    // if (numericValue[0] !== '0') {
    //   set_contact_number(numericValue);
    // }

    if (numericValue.startsWith('0') || numericValue.startsWith('92')) {
      return;
    }
    // Set the pasted value to driverMobileNumber
    set_contact_number(numericValue);

  }

  const handleChange = (e) => {
    let newValue = e.target.value;
    // Remove non-digit characters (including hyphens)
    newValue = newValue.replace(/[^0-9]/g, '');
    // Remove specific disallowed numbers like '92'
    if (newValue.startsWith('92')) {
      newValue = newValue.slice(2); // Remove '92' from the start
    }
    // Remove leading zeros
    newValue = newValue.replace(/^0+/, '');
    // Limit length to 10 digits
    newValue = newValue.slice(0, 10);
    set_contact_number(newValue);
  };
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  return (
    <TitleLayout titleOnly={true} titleOnlyText="Add Location">
      <div className="addLocationWrapper">
        <div className="" style={{ width: "100%" }}>
          <div className="inputField">
            <div className="label">Location Name</div>
            <div className="field">
              <SimpleInput
                onChange={(e) => setLocationName(e.target.value)}
                value={locationName}
                placeholder={"Enter your location name"}
              />
            </div>
          </div>
        </div>
        <div className="" style={{ width: "100%" }}>
          <div className="inputField">
            <div className="label">Add Address</div>
            <div className="field customMapField customMapFieldNew newPadAdd">
              <input
                onChange={handleAutoComplete}
                value={search}
                name="search"
                placeholder={"Search for your exact address"}
                id={`map-address`}
                autoComplete="off"
                className="searchInput"
              />
              {search !== "" && (
                <ClearIcon
                  onClick={() => {
                    setSearch("");
                    setData(null);
                  }}
                  className="deleteLocationIcon"
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className="inputField mtop8">
            <div className="label">Contact Name </div>
            <div className="field">
              <SimpleInput
                type="text"
                value={contact_name}
                onChange={(e) => {
                  set_contact_name(e.target.value)
                }}
                placeholder="Enter Contact Name"
              />
            </div>
          </div>
          <div className="inputField driverNumberField">
            <div className="label">Contact Number</div>
            <div className="field">
              {/* <PhoneInput
                value={contact_number}
                phoneCode={"92"}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    set_contact_number(e.target.value)
                  } else {
                  }
                }}
                id="phone-number"
                placeholder={"3000000000"}
              /> */}
              <SimplePhoneInput
                value={contact_number}
                onChange={handleChange}
                onPaste={handlePaste}
                placeholder="3000000000"
                maxLength={10}
                id="phone-number"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="button-primary">
        <PrimaryButton
          title="Add Location"
          disabled={handleDisable()}
          onClick={() => {
            if (validateNameNumber(contact_name, contact_number)) {
              dispatch(DrawerAction.addLocation({
                data: {
                  ...data,
                  title: locationName,
                  contact_name: contact_name,
                  contact_phone_no: contact_number
                },
                cb,
              }))
            } else {
            }
          }}
        />
      </div>
    </TitleLayout>
  );
};