import React, { FC, useEffect, useState } from "react";
import {
  MaterialUIPickers,
  TitleLayout,
  PrimaryButton,
  SimpleInput,
} from "@temp/components";
import InfoIcon from "@mui/icons-material/Info";
import "./style.scss";
// import { isBefore, isEqual } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import { useHistory } from "react-router";
import { CONTRACT_LOCATION } from "@temp/app/routes";
import { CONTRACT_ADD_NEW_LOCATION } from "@temp/app/routes";
import moment from "moment";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import dayjs from 'dayjs';

const getMillisec = (t1, t2) => {
  let temp = typeof (t2) === "string" ? t2 : dayjs(t2).format('HH:mm:ss')
  return moment(`${t1} ${temp}`).format('x')
  // return moment(`${t1} ${t2}`).format('x')
  // return moment(`${dayjs(t1).format('YYYY-MM-DD')} ${dayjs(t2).format('HH:mm:ss')}`).format('x')
}


export const ContractDetails: FC = () => {

  const [contractName, setContractName] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [ref_no, setRef_no] = useState("");
  const [deadlineDate, setDeadlineDate] = useState(null);
  const [deadlineTime, setDeadlineTime] = useState(null);
  const [contractDateError, setContractDateError] = useState(false);
  const [isError, setError] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const ContractDetails = useSelector((state: any) => state?.Profile?.postLoad?.contractDetails);
  const postLoad = useSelector((state: any) => state?.Profile?.postLoad);


  
  

  useEffect(() => {
    if (ContractDetails) {
      setContractName(ContractDetails?.contract_name);
      setRef_no(postLoad?.ref_no);
      if (ContractDetails?.contract_start_date) {
        setStartDate(ContractDetails?.contract_start_date);
        setEndDate(ContractDetails?.contract_end_date);
        setDeadlineDate(ContractDetails?.deadline_date);
        setDeadlineTime(ContractDetails?.deadline_time);
      }
    }
  }, [ContractDetails]);

  const checkMaxMinDate = (startDate, deadlineDate) => {
    if (startDate && deadlineDate) {
      if (startDate < deadlineDate) {
        setError(true)
      } else {
        setError(false)
      }
    }
  }

  useEffect(() => {
    checkMaxMinDate(startDate, deadlineDate)
  }, [startDate, deadlineDate])


  const handleSubmit = () => {
    const contract = {
      contract_name: contractName,
      contract_start_date: startDate,
      contract_end_date: endDate,
      deadline_date: deadlineDate,
      deadline_time: typeof(deadlineTime) ==='string'?deadlineTime: moment(new Date(deadlineTime)).format('HH:mm:ss'),
      // deadlineTime,
    };

    dispatch(
      ProfileAction.postLoadData({
        ref_no,
        contractDetails: {
          ...ContractDetails,
          ...contract,
        },
      })
    );
    if (postLoad?.fromClone) {
      history.push(CONTRACT_LOCATION);
    } else {
      if (postLoad?.location.length) {
        history.push(CONTRACT_LOCATION);
      } else {
        history.push(CONTRACT_ADD_NEW_LOCATION, { from: 'contract' });
      }
    }

  };

  const compareStartEndDate = (first, second) => {
    if (first && second) {
      
      // if (dayjs().isBefore(first, second) || dayjs().isSame(first, second)) {
        if (first < second) {
        setContractDateError(false);
      } else {
        setContractDateError(true);
      }
      // if (isBefore(first, second) || isEqual(first, second)) {
      //   setContractDateError(false);
      // } else {
      //   setContractDateError(true);
      // }
    }
  }

  const handleDisabled = () => {
    if (!isError && startDate && endDate && contractName && contractName.split("").length > 0 && deadlineTime && deadlineDate && getMillisec(deadlineDate, deadlineTime) > (new Date().getTime() + 5000) && !contractDateError) {
      return false
    } else {
      return true
    }
  }
  {
    
  }

  return (
    <TitleLayout
      pageNumber={3}
      progress={30}
      contract={true}
      title="Contract Details"
    >
      <div className="contractDetailWrapper">
        <FadeInComponent className="mb-3">
          <div className="sectionBox">
            <div className="inputField">
              <label className="label">Contract Name</label>
              <div className="field">
                <SimpleInput
                  isPKR={false}
                  value={contractName}
                  placeholder="Enter Contract Name"
                  onChange={(e) => {
                    setContractName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="inputField">
              <label className="label">Customer Ref No.</label>
              <div className="field">
                <SimpleInput
                  isPKR={false}
                  value={ref_no}
                  placeholder={"(Optional)"}
                  onChange={(e) => {
                    setRef_no(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </FadeInComponent>

        <FadeInComponent className="mb-3">
          <div className="sectionBox">
            <div className="titleSection">
              Contract Period
            </div>
            <p>Start Date</p>
            <div className="DatePickerFieldWrap">
              <MaterialUIPickers
                date={startDate}
                setDate={(e) => {
                  
                  setStartDate(e);
                  compareStartEndDate(e, endDate);
                }}
                minDate={
                  moment().add(1, 'days')
                  // dayjs().add(1,'day')
                }
                from={true}
                fromContract={true}
                maxDate={"3050-01-01"}
              />
            </div>
            <p style={{ marginTop: '0.7rem' }}>End Date</p>
            <div className="DatePickerFieldWrap">
              <MaterialUIPickers
                date={endDate}
                minDate={
                  startDate ?
                  moment(startDate, "YYYY-MM-DD").add(1, 'day')
                  :
                  moment().add(2, 'days')
                  // moment(new Date(), "YYYY-MM-DD")
                }
                setDate={(e) => {
                  
                  setEndDate(e);
                  compareStartEndDate(startDate, e);
                }}
                from={true}
                maxDate={"3050-01-01"}
              />
            </div>
            {contractDateError && (
              <div className="error-container info">
                <div>
                  <InfoIcon className="error-icon" />
                </div>
                <div className="error-text">
                  Start date should be less than End date.
                </div>
              </div>
            )}
          </div>
        </FadeInComponent>

        <FadeInComponent className="mb-3">
          <div className="sectionBox">
            <div className="titleSection">Response Deadline</div>
            <div className="DatePickerFieldWrap">
              <MaterialUIPickers
                date={deadlineDate}
                setDate={(e) => {
                  setDeadlineDate(e);
                }}
                setTime={(e) => {
                  setDeadlineTime(e);
                }}
                time={deadlineTime}
                maxDate={startDate}
                from={true}
              />
            </div>
            {isError && (
              <div className="error-container info">
                <div>
                  <InfoIcon className="error-icon" />
                </div>
                <div className="error-text">
                  Start date should be greater than Response Deadline.
                </div>
              </div>
            )}
          </div>
        </FadeInComponent>

        {Number(getMillisec(deadlineDate, deadlineTime)) < (new Date().getTime() + 5000) && (
          <FadeInComponent>
            <div className="error-container info">
              <div>
                <InfoIcon className="error-icon" />
              </div>
              <div className="error-text">
                Response deadline should be greater than current time.
              </div>
            </div>
          </FadeInComponent>
        )}
      </div>

      <FadeInComponent>
        <PrimaryButton
          disabled={handleDisabled()}
          rightIcon={true}
          title="Continue"
          onClick={() => {
            handleSubmit();
          }}
        />
      </FadeInComponent>
    </TitleLayout>
  );
};

export default ContractDetails;
