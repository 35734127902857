import { combineReducers } from "redux";

// import all reducers
import AuthReducer from "./AuthReducer";
import ProfileReducer from "./ProfileReducer";
import DrawerReducer from "./DrawerReducer";
import LoadReducer from "./LoadReducer";
import CounterReducer from './CounterReducer';

const rootReducer = combineReducers({
  // reducers will go here
  Auth: AuthReducer,
  Profile: ProfileReducer,
  Drawer: DrawerReducer,
  Load:LoadReducer,
  Counter:CounterReducer
});

// export
export default rootReducer;
