import { Grid, MenuItem,  Select } from '@mui/material/';
import { CustomizedSwitches, CustomModal, CustomText, NewInput, OutLineButton, TextInput, VehicleIncDec } from '@temp/components';
import React, { useState } from 'react'
var numeral = require("numeral");
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from 'react-redux';
import OutlineButton from '@temp/components/OutLineButton';
let timmerRef_weight_inc = null
let timmerRef_weight_dec = null
let initial_values = {
    is_unit_basis: true,
    unit_description: '',
    dimensions: '',
    unit_quantity: '',
    unit_edit_mode: false,
    unit_edit_id: null,
    gross_weight: 0,
    cargo_volume: null,
    total_freight_ton: null,
    no_of_packages: null,
    gross_weight_in_kgs: null,
    is_loading_required: false,
    loading_requirements: '',
    is_unloading_required: false,
    unloading_requirements: '',
    unit_dimension_value: ''
}
export const UnitsLocation = ({ added_units, set_added_units, is_unit_basis, loc_index, handleDeleteUnit, hanleEditUnit }) => {
    const [unitBasisValues, setUnitBasisValues] = useState(initial_values)
    const [showModalIcon, toggleModalIcon] = useState(false);
    const [info, setInfo] = useState("");
    const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
    const [unit_edit_modal, set_edit_unit_modal] = useState(false)
    const [unit_edit_data, set_edit_unit_data] = useState(null)
    const [unit_edit_mode, set_unit_edit_mode] = useState(false)
    const [edit_unit_index, set_edit_unit_index] = useState(null)
    const [item_quantity_tem, set_item_quantity_tem] = useState(1)

    const handleDisable = () => {
        return unitBasisValues?.gross_weight && unitBasisValues?.cargo_volume && Number(item_quantity_tem) > 0 && unitBasisValues.dimensions && (unitBasisValues?.unit_dimension_value && unitBasisValues?.unit_dimension_value !== "Select Dimensions") ? false : true
    }

    const SwitchableWithText = (props: any) => {
        return (
            <Grid
                container
                spacing={1}
                style={{ marginBottom: 0, alignItems: "center" }}
            >
                <Grid
                    item
                    xs={10}
                    style={{ color: "rgb(13 21 9)", fontSize: "0.875rem", display: "flex" }}
                >
                    {props?.icon && (
                        <img src={props?.icon} width="16px" height="16px"></img>
                    )}
                    <div style={{ marginBottom: 5, marginTop: 0 }}>{props?.text}</div>
                </Grid>

                <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ marginTop: 0 }}>
                        <CustomizedSwitches {...props} />
                    </div>
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            {/* applies to only unit basis  */}
            <div>
                {
                    is_unit_basis ?
                        <div>
                            <div style={{ marginTop: 10, }}>
                                <div className="inputField">
                                    <div className="label">Gross Weight</div>
                                    <div className="field">
                                        <NewInput
                                            // type="text"
                                            onChange={(e) => {
                                                let grossWeight = e.target.value;
                                                setUnitBasisValues({
                                                    ...unitBasisValues,
                                                    gross_weight_in_kgs: grossWeight ? Number(grossWeight) : null,
                                                    gross_weight: grossWeight / 1000,
                                                    total_freight_ton: grossWeight / 1000 > unitBasisValues?.cargo_volume ? grossWeight / 1000 : unitBasisValues.cargo_volume
                                                })
                                            }}
                                            value={unitBasisValues.gross_weight_in_kgs ? unitBasisValues.gross_weight_in_kgs : ""}
                                            showEnd={"KG"}
                                            decimal={true}                                        />
                                    </div>
                                </div>
                                {/* gross weight in kgs field  */}
                                <div className="inputField">
                                    <div className="label">Gross Weight in Tons</div>
                                    <div className="field">
                                        <NewInput
                                            id="total_freight_ton"
                                            name="total_freight_ton"
                                            value={
                                                numeral(unitBasisValues.gross_weight).format("0,0.00")
                                            }
                                            disabled={true}
                                            aria-describedby="standard-weight-helper-text"
                                            showEnd={"Ton"}

                                        />
                                    </div>
                                </div>
                                {/* cargo volume field  */}
                                <div className="inputField">
                                    <div className="label">Cargo Volume</div>
                                    <div className="field">
                                        <NewInput
                                            onChange={(e) => {
                                                let cargoVolume = e.target.value
                                                setUnitBasisValues({
                                                    ...unitBasisValues,
                                                    cargo_volume: cargoVolume ? Number(cargoVolume) : null,
                                                    total_freight_ton: cargoVolume > unitBasisValues.gross_weight ? cargoVolume : unitBasisValues.gross_weight
                                                })
                                            }}
                                            value={unitBasisValues.cargo_volume ? unitBasisValues?.cargo_volume : ''}
                                            showEnd={"CBM"}
                                            decimal={true}

                                        />
                                    </div>
                                </div>
                                {/* total freight ton faded field readonly  */}
                                <div className="inputField">
                                    <div className="label">
                                        {
                                            "Total Weight"
                                        }
                                        {unitBasisValues.cargo_volume ? (
                                            <InfoIcon
                                                onClick={() => {
                                                    setInfo(
                                                        "Higher of weight in tons and volume in CBM."
                                                    );
                                                    toggleModalIcon(true);
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                    <div className="field">
                                        <NewInput
                                            id="total_freight_ton"
                                            name="total_freight_ton"
                                            value={
                                                numeral(unitBasisValues.total_freight_ton).format("0,0.00")
                                            }
                                            disabled={true}
                                            style={{ height: 35 }}
                                            aria-describedby="standard-weight-helper-text"
                                            showEnd={!unitBasisValues.cargo_volume ? "Ton" : "F.Ton"}
                                            className="rightFormField customDisable textBlack"
                                            
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* cargo volume field  */}
                            <div className="inputField">
                                <div className="label">Dimensions</div>
                                <div className="field">
                                    <div className={`input-container-new ${unitBasisValues?.dimensions ? 'filled' : ''}`}>
                                        <input
                                            type={"text"}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 30) {
                                                    setUnitBasisValues({
                                                        ...unitBasisValues,
                                                        dimensions: e.target.value,
                                                    });
                                                } else { }
                                            }}
                                            value={unitBasisValues.dimensions}
                                            aria-describedby="standard-weight-helper-text"
                                            className={`new-inut-field`}
                                            placeholder={"45x76x26"}
                                        />
                                        <span class="inputSlide"></span>
                                    </div>
                                </div>
                            </div>

                            <div className="fw-5">Unit of Dimension</div>
                            <Select
                                placeholder="Select Hours"
                                name="vehicle_type"
                                className="add-vehicle-select-box2"
                                style={{ marginBottom: '1.5rem' }}
                                value={unitBasisValues?.unit_dimension_value ? unitBasisValues?.unit_dimension_value : 'Select Unit'}
                                onChange={(e) => {
                                    setUnitBasisValues({
                                        ...unitBasisValues,
                                        unit_dimension_value: e.target.value
                                    })
                                }}
                            >
                                <MenuItem
                                    value={"Select Unit"}
                                >
                                    Select Unit
                                </MenuItem>
                                {postLoadMeta?.unit_basis_dimensions?.map((item) => {
                                    return (
                                        <MenuItem
                                            selected={item == unitBasisValues.unit_dimension_value}
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <VehicleIncDec
                                className="labelWithIncrementField"
                                text="Item Quantity"
                                value={item_quantity_tem}
                                name={"item_quantity"}
                                withInputBox={true}
                                updateValue={(val) => {
                                    set_item_quantity_tem(val);
                                }}
                                onTouchStart={(type) => {
                                    if (type == "inc") {
                                        timmerRef_weight_inc = setInterval(() => {
                                            set_item_quantity_tem((prev) => prev + 1);
                                        }, 200);
                                    } else {
                                        if (item_quantity_tem > 0) {
                                            timmerRef_weight_dec = setInterval(() => {
                                                set_item_quantity_tem((prev) => {
                                                    if (prev > 1) {
                                                        return prev - 1;
                                                    } else {
                                                        return 1;
                                                    }
                                                });
                                            }, 200);
                                        }
                                    }
                                }}
                                onTouchEnd={() => {
                                    clearInterval(timmerRef_weight_inc);
                                    clearInterval(timmerRef_weight_dec);
                                }}
                                onMouseDown={(type) => {
                                    if (type == "inc") {
                                        set_item_quantity_tem((prev) => prev + 1);
                                        timmerRef_weight_inc = setInterval(() => {
                                            set_item_quantity_tem((prev) => prev + 1);
                                        }, 200);
                                    } else {
                                        set_item_quantity_tem((prev) => {
                                            if (prev > 1) {
                                                return prev - 1;
                                            } else {
                                                return 1;
                                            }
                                        });
                                        timmerRef_weight_dec = setInterval(() => {
                                            set_item_quantity_tem((prev) => {
                                                if (prev > 1) {
                                                    return prev - 1;
                                                } else {
                                                    return 1;
                                                }
                                            });
                                        }, 200);
                                    }
                                }}
                                onMouseUp={() => {
                                    clearInterval(timmerRef_weight_inc);
                                    clearInterval(timmerRef_weight_dec);
                                }}
                                disableMin={
                                    item_quantity_tem
                                        ? item_quantity_tem > 0
                                            ? false
                                            : true
                                        : true
                                }
                                disabledMax={false}
                            />
                            <div className="fw-5 mb-3">Item Description</div>
                            <div className='textareaWrapper' style={{ marginBottom: '1.5rem' }} >
                                <textarea
                                    style={{
                                        outline: "none",
                                    }}
                                    maxLength={250}
                                    onChange={(e) => {
                                        setUnitBasisValues({
                                            ...unitBasisValues,
                                            unit_description: e.target.value
                                        })
                                    }}
                                    value={unitBasisValues?.unit_description}
                                    id={"cargo_description"}
                                    name={"cargo_description"}
                                    placeholder="Optional"
                                />
                            </div>

                            {/* is loading  */}
                            <Grid item xs={12}>
                                <SwitchableWithText
                                    id={"is_loading_required"}
                                    handleChange={(e) => {
                                        setUnitBasisValues({
                                            ...unitBasisValues,
                                            is_loading_required: e.target.checked
                                        })
                                    }}
                                    checked={unitBasisValues?.is_loading_required}
                                    name="is_loading_required"
                                    text="Loading Required"
                                />

                                {
                                    unitBasisValues?.is_loading_required &&
                                    <div className="inputField">
                                        <div className="label">
                                            Specify Labour / Equipment Requirement
                                        </div>
                                        <div className="field">
                                            <TextInput
                                                type="text"
                                                onChange={(e) => {
                                                    setUnitBasisValues({
                                                        ...unitBasisValues,
                                                        loading_requirements: e.target.value
                                                    })
                                                }}
                                                value={unitBasisValues?.loading_requirements}
                                                id={"loading_requirements"}
                                                name="loading_requirements"
                                                aria-describedby="standard-weight-helper-text"
                                                className="rightFormField"
                                                placeholder="Optional"
                                            />
                                        </div>
                                    </div>
                                }

                                {/* is un loading  */}

                                <SwitchableWithText
                                    id={"is_unloading_required"}
                                    handleChange={(e) => {
                                        setUnitBasisValues({
                                            ...unitBasisValues,
                                            is_unloading_required: e.target.checked
                                        })
                                    }}
                                    checked={unitBasisValues?.is_unloading_required}
                                    name="is_unloading_required"
                                    text="Unloading Required"
                                />

                                {
                                    unitBasisValues?.is_unloading_required &&
                                    <div className="inputField">
                                        <div className="label">
                                            Specify Labour / Equipment Requirement
                                        </div>
                                        <div className="field">
                                            <TextInput
                                                type="text"
                                                onChange={(e) => {
                                                    setUnitBasisValues({
                                                        ...unitBasisValues,
                                                        unloading_requirements: e.target.value
                                                    })
                                                }}
                                                value={unitBasisValues?.unloading_requirements}
                                                id={"unloading_requirements"}
                                                name="unloading_requirements"
                                                style={{ height: 35, width: "100%" }}
                                                aria-describedby="standard-weight-helper-text"
                                                className="rightFormField"
                                                placeholder="Optional"
                                            />
                                        </div>
                                    </div>
                                }
                            </Grid>

                            <OutLineButton
                                style={{ marginTop: '1rem' }}
                                type={"submit"}
                                title={unit_edit_mode ? "Update Item"
                                    : "Add another Item"
                                }
                                disabled={
                                    handleDisable()
                                }
                                onClick={() => {
                                    if (unit_edit_mode) {
                                        let data = {
                                            ...unitBasisValues,
                                            unit_quantity: item_quantity_tem
                                        }
                                        hanleEditUnit(loc_index, edit_unit_index, data)
                                        set_edit_unit_data(null)
                                        set_unit_edit_mode(false)
                                        set_edit_unit_index('')
                                    } else {
                                        let data = {
                                            ...unitBasisValues,
                                            unit_quantity: item_quantity_tem
                                        }
                                        set_added_units(loc_index, data)
                                    }
                                    setUnitBasisValues(initial_values)
                                    set_item_quantity_tem(1)
                                }}
                            />

                            {/* render added units  */}

                            <div style={{ marginTop: 30, marginBottom: 20 }}>
                                {
                                    added_units.map((item, index) => {
                                        return (
                                            <div key={index} className="addVehicleRow" style={{ display: 'flex', alignItems: 'center' }}>
                                                <div onClick={() => {
                                                    set_edit_unit_modal(true)
                                                    set_edit_unit_data(item)
                                                    set_edit_unit_index(index)
                                                    set_item_quantity_tem(Number(item?.unit_quantity))
                                                }} className="addVehicleRowContent fw-5">
                                                    <span className='fw-5'>{`${item?.unit_quantity} X Units`}</span>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        handleDeleteUnit(loc_index, index)
                                                    }}
                                                    className="addVehicleRowDelete"
                                                    style={{
                                                        cursor: "pointer",
                                                        display: 'flex',
                                                        justifyContent: 'flex-end'
                                                    }}>
                                                    <span className='icons-cross'></span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div> :
                        null
                }
            </div>
            {/* info modal  */}
            <CustomModal
                modalStyle={{
                    width: 300,
                    minHeight: 100,
                }}
                showModal={showModalIcon}
                toggleModal={toggleModalIcon}
            >
                <div
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <InfoIcon color="primary" style={{ fontSize: 40 }} />
                    <CustomText.OpenSansRegular>{info}</CustomText.OpenSansRegular>
                </div>
            </CustomModal>

            <CustomModal showModal={unit_edit_modal} toggleModal={() => {
                set_edit_unit_modal(!unit_edit_modal)
                set_unit_edit_mode(false)
            }}
                modalStyle={{
                    minWidth: 330
                }}
            >
                <div className="detailModal">
                    <div className="display-flex-row mb-3">
                        <div className="titleSection mb-0">
                            Item Category
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                set_edit_unit_modal(!unit_edit_modal)
                                set_unit_edit_mode(false)
                            }}
                        >
                            <span className="modalClose icons-cross"></span>
                        </div>
                    </div>
                    <div className="formGrid">
                        <div className="grayLabel">
                            Gross weight
                        </div>
                        <div className="fw-5">
                            {unit_edit_data?.gross_weight}
                        </div>
                    </div>

                    <div className="formGrid">
                        <div className="grayLabel">
                            Cargo volume
                        </div>
                        <div className="fw-5">
                            {unit_edit_data?.cargo_volume}
                        </div>
                    </div>

                    <div className="formGrid">
                        <div className="grayLabel">
                            Dimensions
                        </div>
                        <div className="fw-5">
                            {unit_edit_data?.dimensions}
                        </div>
                    </div>

                    <div className="formGrid">
                        <div className="grayLabel">
                            Unit of Dimension
                        </div>
                        <div className="fw-5">
                            {unit_edit_data?.unit_dimension_value}
                        </div>
                    </div>

                    <div className="formGrid">
                        <div className="grayLabel">
                            Item Quantity
                        </div>
                        <div className="fw-5">
                            {unit_edit_data?.unit_quantity}
                        </div>
                    </div>
                </div>
                <OutlineButton
                    title="EDIT"
                    buttonStyle={{ marginTop: 15 }}
                    onClick={() => {
                        setUnitBasisValues(unit_edit_data)
                        set_unit_edit_mode(true)
                        set_edit_unit_modal(false)
                    }}
                />

            </CustomModal>
        </div>
    )
}