import { LOAD_TYPE } from "@temp/constants";
import React from "react";
import "../LoadDetailPoster/style.scss";
var numeral = require("numeral");

export const ThreeColumnRTL = ({ loadDetail, item, forward = false, is_recipient = false }) => {
  
  return (
    <div className="ThreeColumnRTL">

      {/* for containerized only */}
      {
        loadDetail?.load_type?.title == LOAD_TYPE.fcl && (
          <div className="formGrid">
            <div className="grayLabel">
              <span>Vehicle Type</span>
              {
                is_recipient &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">گاڑی کی قسم</span>
                </>
              }
            </div>
            <div className="fw-5">
              {
                !forward ? item?.vehicle?.title : loadDetail?.new_category?.vehicle?.title
              }
            </div>
          </div>
        )
      }

      {
        loadDetail?.quote_by == "weight_volume" && (
          <div>
            <div className="formGrid">
              <div className="grayLabel">
                <span>Gross Weight in KG</span>
                {
                  is_recipient &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">مجموعی وزن (کلو)</span>
                  </>
                }
              </div>

              <div className="fw-5">
                {!forward ? (
                  <>
                    {
                      !loadDetail?.is_unit_basis ? (numeral(Number(loadDetail?.gross_weight * 1000)).format("0,0.00")) + " KG" : numeral(Number(item?.gross_weight * 1000)).format("0,0.00") + " KG"
                    }
                  </>
                ) : (
                  <>
                    {
                      (numeral(Number(loadDetail?.gross_weight * 1000)).format("0,0.00")) ? `${numeral(Number(loadDetail?.gross_weight * 1000)).format("0,0.00")} KG` : "N/A"
                    }
                  </>
                )}
              </div>
            </div>


            <div className="formGrid">
              <div className="grayLabel">
                <span>Gross Weight in Ton</span>
                {
                  is_recipient &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">مجموعی وزن (ٹن)</span>
                  </>
                }
              </div>

              <div className="fw-5">
                {!forward ? (
                  <>
                    {
                      !loadDetail?.is_unit_basis ? (numeral(loadDetail?.gross_weight).format("0,0.00")) + " TON" : numeral(item?.gross_weight).format("0,0.00") + " TON"
                    }
                  </>
                ) : (
                  <>
                    {
                      (numeral(loadDetail?.gross_weight).format("0,0.00"))
                    }
                  </>
                )}
              </div>
            </div>

            {
              !loadDetail?.is_unit_basis && loadDetail?.cargo_volume > 0 ?
                <div className="formGrid">
                  <div className="grayLabel">
                    <span>Cargo Volume</span>
                    {
                      is_recipient &&
                      <>
                        <span className="seperator">/</span>
                        <span className="urduLabel">کارگو والیوم</span>
                      </>
                    }
                  </div>
                  <div className="fw-5">
                    {!forward ? (
                      <>
                        {
                          numeral(loadDetail?.cargo_volume).format("0,0.00") + " CBM"
                        }
                      </>
                    ) : (
                      <>
                        {
                          numeral(loadDetail?.cargo_volume).format("0,0.00") + " CBM"
                        }
                      </>
                    )}
                  </div>
                </div> : null
            }

            {
              loadDetail?.is_unit_basis && item?.cargo_volume > 0 ?
                <div className="formGrid">
                  <div className="grayLabel">
                    <span>Cargo Volume</span>
                    {
                      is_recipient &&
                      <>
                        <span className="seperator">/</span>
                        <span className="urduLabel">کارگو والیوم</span>
                      </>
                    }
                  </div>
                  <div className="fw-5">
                    {!forward ? (
                      <>
                        {
                          numeral(item?.cargo_volume).format("0,0.00") + " CBM"
                        }
                      </>
                    ) : (
                      <>
                        {
                          numeral(item?.cargo_volume).format("0,0.00") + " CBM"
                        }
                      </>
                    )}
                  </div>
                </div> : null
            }


            {
              loadDetail?.is_unit_basis ? (
                <div className="formGrid">
                  <div className="grayLabel">
                    <span>
                      {/* Total Freight Ton */}
                      Total Weight
                    </span>
                    {
                      is_recipient &&
                      <>
                        <span className="seperator">/</span>
                        <span className="urduLabel">کل وزن </span>
                      </>
                    }
                  </div>
                  <div className="fw-5">
                    {
                      numeral(item?.total_freight_ton).format("0,0.00") + ` F.TON`
                    }
                  </div>
                </div>
              ) : null
            }
            {
              !loadDetail?.is_unit_basis ? (
                <div className="formGrid">
                  <div className="grayLabel">
                    <span>
                      {/* Total Freight Ton */}
                      Total Weight
                    </span>
                    {
                      is_recipient &&
                      <>
                        <span className="seperator">/</span>
                        <span className="urduLabel">کل وزن </span>
                      </>
                    }
                  </div>
                  <div className="fw-5">
                    {
                      numeral(loadDetail?.total_freight_ton).format("0,0.00") + `${!loadDetail.cargo_volume ? " TON" : " F.TON"
                      }`
                    }
                  </div>
                </div>
              ) : null
            }
          </div>
        )
      }
    </div>
  );
};
