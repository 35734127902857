import React, { useEffect, useMemo, useRef, useState } from "react";

export const FadeInComponent = (props: any) => {
  const { children, className, noWidth = true, dataSize, is_apply=true, propStyle } = props;
  const ref = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);
  return (
    <div ref={ref} data-size={dataSize ? dataSize : ''} className={`${className ? className : ''} ${isIntersecting && is_apply ? 'active' : ''} ${is_apply?"fadeInComponent":""}`} style={propStyle ? propStyle : { width: noWidth ? '100%' : '' }}>{children}</div>
  ) 
};

export default FadeInComponent;
