import React, { FC } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import CategoryContainer from "./CategoryContainer";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Grid,
} from "@mui/material/";
import { ExitToAppRounded, ExpandMore } from "@mui/icons-material";
import { ConfirmLoadProps } from "./types";
import { CustomText, LocationTimeline, OutLineButton } from "@temp/components";
import { useSelector } from "react-redux";
import { CONTRACT_LOCATION } from "@temp/app/routes";
import { Images } from "@temp/assets";
var numeral = require("numeral");


export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 17px;
  border-radius: 10px;
`;
const StyledCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
`;

export const ContractLocationDetails: FC<ConfirmLoadProps> = ({
  locations,
  editable,
  fromHistory,
  is_recipient
}: ConfirmLoadProps) => {
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const postLoadMeta = useSelector(
    (state: any) => state?.Profile?.postLoadMeta
  );

  const load_type = postLoadMeta.load_types.find(
    (val: any) => val.id === PostLoad.load_type
  );

  const history = useHistory();
  const onEdit = (id) => {
    history.push(`/contract-location/add-new`, { id, from: 'contract' });

  };

  return (
    <>
      {locations?.map((location, index) => {
        return (
          <div key={index}>
            <LocationSection
              location={location}
              index={index}
              onEdit={() => onEdit(index)}
              loadType={load_type}
              quoteBy={PostLoad?.contractDetails?.quote_by}
              editable={editable}
              PostLoadData={PostLoad}
              fromHistory={fromHistory}
              is_recipient={is_recipient}
            />
          </div>
        );
      })}
    </>
  );
};

const LocationSection = ({ location, onEdit, index, loadType, quoteBy, editable, PostLoadData, fromHistory, is_recipient }) => {
  const locationObj = [location?.pickup, location?.dropoff];
  const FormRow = ({ label, value, sep, urdutitle, is_recipient, fromHistory }) => {
    return (
      <div className="formGrid">
        <div className="grayLabel">
          <span className="titleName">{label}</span>

          {
            fromHistory && is_recipient &&
            <>
              <span className="seperator">
                {sep}
              </span>
              <span className="urduLabel lhEdit">
                {urdutitle}
              </span>
            </>
          }
        </div>
        <div className="fw-5">
          {value}
        </div>
      </div>
    );
  };

  const CategoryHeader = ({ title, sep, is_recipient, fromHistory, urdutitle }) => {
    return (
      <div className="fw-6 mb-3">
        {title}

        {
          fromHistory && is_recipient &&
          <>
            <span className="seperator">
              {sep}
            </span>
            <span className="urduLabel lhEdit">
              {urdutitle}
            </span>
          </>
        }
      </div>
    );
  };

  return (
    <Accordion className="confirm-load-accordion" style={{ backgroundColor: "#FAFAFA" }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="fw-6">
          {`Route ${index + 1}`}
          {
            fromHistory && is_recipient &&
            `  / مقام  ${index + 1} : ${locationObj[0]?.title} - ${locationObj[1]?.title}`
          }
        </div>
        {
          editable ?
            <div className="editBtnWrapper">
              <OutLineButton
                classProp='tinyBtn'
                dataColor="blue"
                title="Edit"
                icon={
                  <span className="icons-edit"></span>
                }
                onClick={onEdit}
              />
            </div> : null
        }
      </AccordionSummary>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Grid className="contractLocationDetail" container spacing={1} style={{ overflowY: "scroll" }}>
          <div className="load-detail-location-container">
            <LocationTimeline
              location={locationObj && locationObj?.length ? locationObj : []}
              handleModal={(value: boolean) => { }}
              disabled={0}
              is_recipient={is_recipient}
              fromHistory={fromHistory}
            ></LocationTimeline>

          </div>


          {location?.pickup?.attribute?.gross_weight && !PostLoadData?.contractDetails?.is_unit_basis && (
            <>
              <FormRow
                label="Gross Weight in KG"
                value={numeral(Number(location?.pickup?.attribute?.gross_weight * 1000)).format('0,0.00') + " KG"}
                sep="/"
                urdutitle="مجموعی وزن (کلو)"
                is_recipient={is_recipient}
                fromHistory={fromHistory}

              />
              <FormRow
                label="Gross Weight in Ton"
                value={numeral(location?.pickup?.attribute?.gross_weight).format('0,0.00') + " Ton"}
                sep="/"
                urdutitle="مجموعی وزن (ٹن)"
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />

              <FormRow
                label="Cargo Volume"
                value={location?.pickup?.attribute?.cargo_volume ? numeral(location?.pickup?.attribute?.cargo_volume).format('0,0.00') + " CBM" : "N/A"}
                urdutitle={"کارگو والیوم"}
                sep="/"
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
              <FormRow
                label=
                "Total Weight"
                value={location?.pickup?.attribute?.total_freight_ton
                  ? numeral(location?.pickup?.attribute?.total_freight_ton).format("0,0.00")
                  + `${!location?.pickup?.attribute?.cargo_volume ? " Ton" : " F.Ton"}`
                  : ""}
                urdutitle={"کل وزن"}
                sep="/"
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
              <FormRow
                label="Number of Packages"
                value={location?.pickup?.attribute?.no_of_packages ? numeral(location?.pickup?.attribute?.no_of_packages).format('0,0') : "N/A"}
                urdutitle={"پیکجز کی تعداد"}
                sep="/"
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
              {location?.pickup?.attribute?.cargo_liter ?
                <FormRow
                  label="Liter"
                  value={location?.pickup?.attribute?.cargo_liter ? numeral(location?.pickup?.attribute?.cargo_liter).format("0,0.00") + " " + "liter" : "N/A"}
                  urdutitle={"لیٹر"}
                  sep="/"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
                : null}
            </>

          )}

          {quoteBy === "weight_volume" && !PostLoadData?.contractDetails?.is_unit_basis && (
            <>
              {location?.pickup?.attribute?.is_sidewall && (
                <FormRow
                  label="Half Body"
                  value={
                    location?.pickup?.attribute?.is_sidewall ? "YES" : "NO"
                  }

                />
              )}

              {location?.pickup?.attribute?.is_boxed && (
                <FormRow
                  label="Boxed"
                  value={location?.pickup?.attribute?.is_boxed ? "YES" : "NO"}
                />
              )}

              <FormRow
                label="Packaging type"
                value={
                  location?.pickup?.attribute?.packageType?.title === "Other"
                    ? location?.pickup?.attribute?.packageType?.title +
                    " (" +
                    location?.pickup?.attribute?.package_type_other +
                    ")"
                    : location?.pickup?.attribute?.packageType?.title || ""
                }
                sep="/"
                urdutitle=" پیکیجنگ کی قسم"
                is_recipient={is_recipient}
                fromHistory={fromHistory}

              />

              <FormRow
                label="Vehicle Type"
                value={
                  location?.pickup?.attribute?.PreferredVehicleType?.title

                }
                sep="/"
                urdutitle={" گاڑی کی قسم"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
              {location?.pickup?.attribute?.is_loading_required ?
                <FormRow
                  label="Loading Required"
                  value={
                    location?.pickup?.attribute?.is_loading_required
                      ? "YES"
                      : "NO"
                  }
                  sep="/"
                  urdutitle={"لوڈنگ"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                /> : null}

              {location?.pickup?.attribute?.is_loading_required &&
                location?.pickup?.attribute?.loading_requirements ? (
                <FormRow
                  label="Specify Labour / Equipment Requirement"
                  value={location?.pickup?.attribute?.loading_requirements}
                  sep="/"
                  urdutitle={"لوڈنگ درکار ہے۔"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              ) : null}

              {location?.pickup?.attribute?.is_unloading_required ?
                <FormRow
                  label="Unloading Required"
                  value={
                    location?.pickup?.attribute?.is_unloading_required
                      ? "YES"
                      : "NO"
                  }
                  sep="/"
                  urdutitle={"ان لوڈنگ"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                /> : null}

              {location?.pickup?.attribute?.is_unloading_required &&
                location?.pickup?.attribute?.unloading_requirements ? (
                <FormRow
                  label="Specify Labour / Equipment Requirement"
                  value={location?.pickup?.attribute?.unloading_requirements}
                  sep="/"
                  urdutitle={"ان لوڈنگ درکار ہے۔"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              ) : null}

              <FormRow label="Free Time Required"
                value={location?.pickup?.attribute?.detention_free_time + " hrs"}
                sep="/"
                urdutitle={"فری وقت درکار "}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />

              {location?.pickup?.attribute?.detention_included_transport ?
                <FormRow
                  label="Mandatory Detention included in Transport Price?"
                  value={location?.pickup?.attribute?.detention_included_transport ? "Yes" : "No"}
                  sep="/"
                  urdutitle={"لازمی ڈیٹنشن ٹرانسپورٹ کی قیمت میں شامل ہے؟"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}


                />
                : null}

              <div style={{ width: '100%' }}>
                {fromHistory && PostLoadData?.history_recipients?.length ?
                  <>
                    {
                      location?.pickup?.attribute?.new_recipients?.filter((award) => award?.status !== 4).length ?
                        <>
                          <div className="fw-5 mb-3">
                            Recipients
                          </div>

                          {location?.pickup?.attribute?.new_recipients?.filter((award) => award?.status !== 4)?.map((loadDetail) => {
                            return (
                              <div style={{ marginBottom: 0, marginTop: 10 }} key={index} className="detail-category-recepient-container">
                                <div>
                                  <img
                                    alt="profile"
                                    style={{ border: "1px solid #0D5296" }}
                                    className="load-detail-user"
                                    src={
                                      loadDetail?.recipient?.picture ||
                                      Images.myProfileImage
                                    }
                                  />
                                </div>

                                <div className="load-history-profile-name">
                                  <CustomText.OpenSansSemiBold>
                                    {loadDetail?.recipient?.company?.length ?
                                      loadDetail?.recipient?.company[0]?.name : loadDetail?.recipient?.name
                                      || "UnRegistered User"}
                                  </CustomText.OpenSansSemiBold>

                                  <CustomText.OpenSansRegular>
                                    {
                                      loadDetail?.recipient?.company?.length ?
                                        loadDetail?.recipient?.name : loadDetail?.recipient?.company_name
                                    }
                                  </CustomText.OpenSansRegular>

                                  {
                                    loadDetail?.bid?.price_per_freight ?
                                      <div className="chip">
                                        PKR {numeral(loadDetail?.bid?.price_per_freight).format('0,0.00')} / {location?.pickup?.attribute?.price_unit}
                                      </div>
                                      : null
                                  }
                                </div>
                              </div>
                            )
                          })}

                        </> : null
                    }

                    {
                      location?.pickup?.attribute?.new_recipients?.filter((award) => award?.status == 4).length ?
                        <>
                          <div style={{ marginTop: 20 }} className="fw-5 mb-3">
                            Awarded Recipients
                          </div>

                          {location?.pickup?.attribute?.new_recipients?.filter((award) => award?.status == 4)?.map((loadDetail) => {
                            return (
                              <div style={{ marginBottom: 0, marginTop: 10 }} key={index} className="detail-category-recepient-container">
                                <div>
                                  <img
                                    alt="profile"
                                    style={{ border: "1px solid #0D5296" }}
                                    className="load-detail-user"
                                    src={
                                      loadDetail?.recipient?.picture ||
                                      Images.myProfileImage
                                    }
                                  />
                                </div>

                                <div className="load-history-profile-name">
                                  <CustomText.OpenSansSemiBold>
                                    {loadDetail?.recipient?.company?.length ?
                                      loadDetail?.recipient?.company[0]?.name : loadDetail?.recipient?.name
                                      || "UnRegistered User"}
                                  </CustomText.OpenSansSemiBold>

                                  <CustomText.OpenSansRegular>
                                    {
                                      loadDetail?.recipient?.company?.length ?
                                        loadDetail?.recipient?.name : loadDetail?.recipient?.company_name
                                    }
                                  </CustomText.OpenSansRegular>

                                  {
                                    loadDetail?.bid?.price_per_freight ?
                                      <div className="chip">
                                        PKR {numeral(loadDetail?.bid?.price_per_freight).format('0,0.00')} / {location?.pickup?.attribute?.price_unit}
                                      </div>
                                      : null
                                  }
                                </div>
                              </div>
                            )
                          })}

                        </> : null
                    }



                  </> : null}
              </div>

            </>
          )}

          {!!location?.items?.length && (
            <StyledCategoryContainer>
              {location?.items.map((val, index) => (
                <CategoryContainer
                  value={val}
                  index={index}
                  key={index}
                  fromConfirm={true}
                  otherData={PostLoadData}
                  accordianMode={false}
                  contract={true}
                  categoryLength={location?.items?.length}
                  fromHistory={fromHistory}
                  is_recipient={is_recipient}
                />
              ))}
            </StyledCategoryContainer>
          )}
          {
            PostLoadData?.contractDetails?.is_unit_basis ?
              <div style={{
                paddingBottom: 30
              }}>
                {
                  location?.added_units?.map((value, cat_ind) => {
                    return (
                      <div>
                        <CategoryHeader
                          title={location?.added_units.length > 1 ? `Item Category ${cat_ind + 1}` : "Item Category"}
                          urdutitle={location?.added_units.length > 1 ? `آئٹم کی کیٹاگری ${cat_ind + 1}` : "آئٹم کی کیٹاگری "}
                          sep="/"
                          is_recipient={is_recipient}
                          fromHistory={fromHistory}

                        />
                        <Grid className="contractLocationDetail" container spacing={1} style={{ overflowY: "scroll" }}>
                          <FormRow
                            label="Gross Weight in Ton"
                            value={numeral(
                              value?.gross_weight
                            ).format("0,0.00") + " Ton"}
                            sep="/"
                            urdutitle="مجموعی وزن (ٹن)"
                            is_recipient={is_recipient}
                            fromHistory={fromHistory}
                          />
                          <FormRow
                            label="Gross Weight in KG"
                            value={numeral(
                              Number(value?.gross_weight * 1000)
                            ).format("0,0.00") + " KG"}
                            sep="/"
                            urdutitle="مجموعی وزن (کلو)"
                            is_recipient={is_recipient}
                            fromHistory={fromHistory}
                          />
                          {
                            value?.cargo_volume ?
                              <FormRow
                                label="Cargo Volume"
                                value={
                                  numeral(
                                    value?.cargo_volume
                                  ).format("0,0.00") + " CBM"
                                }
                                urdutitle={"کارگو والیوم"}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}
                              /> : null
                          }
                          {
                            value?.total_freight_ton ?
                              <FormRow
                                label=
                                "Total Weight"
                                value={
                                  numeral(
                                    value?.total_freight_ton
                                  ).format("0,0.00")
                                  + " F.Ton"
                                }
                                urdutitle={"کل وزن"}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}
                              /> : null
                          }
                          <FormRow
                            label="Dimensions"
                            value={`${value?.dimensions} ${value?.unit_dimension_value}`}
                            urdutitle={" پیمائش"}
                            sep="/"
                            is_recipient={is_recipient}
                            fromHistory={fromHistory}
                          />
                          <FormRow
                            label="Item Quantity"
                            value={value?.unit_quantity}
                            urdutitle={"آئٹم کی مقدار"}
                            sep="/"
                            is_recipient={is_recipient}
                            fromHistory={fromHistory}
                          />

                          {
                            value?.unit_description ?
                              <FormRow
                                label="Item Description"
                                value={value?.unit_description}
                                urdutitle={"آئٹم کی تفصیل"}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}
                              /> : null
                          }
                          {
                            value?.is_loading_required ?
                              <FormRow
                                label="Loading Required"
                                value={"Yes"}
                                sep="/"
                                urdutitle={"لوڈنگ"}
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}
                              /> : null
                          }
                          {
                            value?.loading_requirements ?
                              <FormRow
                                label="Specify Labour / Equipment Requirement"
                                value={value?.loading_requirements}
                                sep="/"
                                urdutitle={"لوڈنگ درکار ہے۔"}
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}
                              /> : null
                          }
                          {
                            value?.is_unloading_required ?
                              <FormRow
                                label="UnLoading Required"
                                value={"Yes"}
                                sep="/"
                                urdutitle={"ان لوڈنگ"}
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}
                              /> : null
                          }
                          {
                            value?.unloading_requirements ?
                              <FormRow
                                label="Specify Labour / Equipment Requirement"
                                value={value?.unloading_requirements}
                                sep="/"
                                urdutitle={"ان لوڈنگ درکار ہے۔"}
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}
                              /> : null
                          }

                          {
                            value?.detention_free_time ?
                              <FormRow
                                label="Free Time Required"
                                value={value?.detention_free_time + " hrs"}
                                sep="/"
                                urdutitle={"فری وقت درکار "}
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}
                              /> : null
                          }

                          <Grid item xs={12}>
                            {fromHistory && PostLoadData?.history_recipients?.length ?
                              <>
                                {
                                  value.new_recipients?.filter((award) => award?.status !== 4).length ?
                                    <>
                                      <div className="fw-5 mb-3">
                                        Recipients
                                      </div>

                                      {value.new_recipients?.filter((award) => award?.status !== 4)?.map((loadDetail) => {
                                        return (
                                          <div style={{ marginBottom: 0, marginTop: 10 }} key={index} className="detail-category-recepient-container">
                                            <div>
                                              <img
                                                alt="profile"
                                                style={{ border: "1px solid #0D5296" }}
                                                className="load-detail-user"
                                                src={
                                                  loadDetail?.recipient?.picture ||
                                                  Images.myProfileImage
                                                }
                                              />
                                            </div>

                                            <div className="load-history-profile-name">
                                              <CustomText.OpenSansSemiBold>
                                                {loadDetail?.recipient?.company?.length ?
                                                  loadDetail?.recipient?.company[0]?.name : loadDetail?.recipient?.name
                                                  || "UnRegistered User"}
                                              </CustomText.OpenSansSemiBold>

                                              <CustomText.OpenSansRegular>
                                                {
                                                  loadDetail?.recipient?.company?.length ?
                                                    loadDetail?.recipient?.name : loadDetail?.recipient?.company_name
                                                }
                                              </CustomText.OpenSansRegular>

                                              {
                                                loadDetail?.bid?.bid_price_per_vehicle ?
                                                  <div className="chip">
                                                    PKR {numeral(loadDetail?.bid?.bid_price_per_vehicle).format('0,0.00')} / Item
                                                  </div>
                                                  : null
                                              }
                                            </div>
                                          </div>
                                        )
                                      })}

                                    </> : null
                                }
                                {
                                  value.new_recipients?.filter((award) => award?.status == 4).length ?
                                    <>
                                      <div style={{ marginTop: 20 }} className="fw-5 mb-3">
                                        Awarded Recipients
                                      </div>

                                      {value.new_recipients?.filter((award) => award?.status == 4)?.map((loadDetail) => {

                                        return (
                                          <div style={{ marginBottom: 0, marginTop: 10 }} key={index} className="detail-category-recepient-container">
                                            <div>
                                              <img
                                                alt="profile"
                                                style={{ border: "1px solid #0D5296" }}
                                                className="load-detail-user"
                                                src={
                                                  loadDetail?.recipient?.picture ||
                                                  Images.myProfileImage
                                                }
                                              />
                                            </div>

                                            <div className="load-history-profile-name">
                                              <CustomText.OpenSansSemiBold>
                                                {loadDetail?.recipient?.company?.length ?
                                                  loadDetail?.recipient?.company[0]?.name : loadDetail?.recipient?.name
                                                  || "UnRegistered User"}
                                              </CustomText.OpenSansSemiBold>

                                              <CustomText.OpenSansRegular>
                                                {
                                                  loadDetail?.recipient?.company?.length ?
                                                    loadDetail?.recipient?.name : loadDetail?.recipient?.company_name
                                                }
                                              </CustomText.OpenSansRegular>

                                              {
                                                loadDetail?.bid?.bid_price_per_vehicle ?
                                                  <div className="chip">
                                                    PKR {numeral(loadDetail?.bid?.bid_price_per_vehicle).format('0,0.00')} / Item
                                                  </div>
                                                  : null
                                              }
                                            </div>
                                          </div>
                                        )
                                      })}

                                    </> : null
                                }
                              </> : null}
                          </Grid>
                        </Grid>
                        <div className="dividing-bordered" style={{ marginLeft: 0, marginRight: 0 }}></div>
                      </div>
                    )
                  })
                }
              </div> : null
          }
        </Grid>
      </div>
    </Accordion>
  );
};

export default ContractLocationDetails;
