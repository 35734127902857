import React, { useEffect, useState } from "react";
import { IContractDetailRecipientPageProps } from "./types";
import "./style.scss";
import {
  CustomText,
  PrimaryButton,
  TitleLayout,
  CustomModal,
  Counter,
  OutLineButton,
} from "@components";
import { Colors } from "@temp/assets";
import { Accordion, AccordionSummary, Checkbox } from "@mui/material/";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import OutlineButton from "@temp/components/OutLineButton";
import { LoadAction, ProfileAction } from "@temp/store/actions";
import { getFullTime, isObjectEmpty, showMessage } from "@temp/utils/helper";
import { useHistory } from "react-router-dom";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import {
  PaymentTerms,
  AdditionalDetail,
  TwoColumnRTLContractVehicle,
  TwoColumnRTLContractWeight,
  UploadedDocuments,
  PosterContract,
} from "../components";
import { LOAD_TYPE } from "@temp/constants";
import { RespondedStatusVehile } from "./RespondedStatusVehile";
import { RespondedStatusWeight } from "./RespondedStatusWeight";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { convertDataForFclAndBulkLoadDetailRedux, convertDataForLoadDetailRedux } from "@temp/utils/dataFormat";
import { LOAD_HISTORY_URL } from "@temp/app/routes";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";


const Page: React.FC<IContractDetailRecipientPageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    setTimeout(() => {
      dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
      dispatch(ProfileAction.JobLoadMeta(0));
    }, 100);
  }, []);

  const loadDetail = useSelector((state: RootState) => state.Load.loadDetail);
  const profile = useSelector((state: RootState) => state.Profile.profile);
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const postLoadMeta = useSelector((state: RootState) => state?.Profile?.postLoadMeta);

  const [showModal, setShowModal] = useState(false);
  const [responded, setResponded] = useState([]);
  const [expand, setExpand] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [category_numbers, set_category_numbers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectAllCat, setSelectAllCat] = useState(false);
  const [all_declined_disable, set_all_declined_disable] = useState(false)
  const [respondToLcl, setRespondToLcl] = useState([]);
  const [currentIndex, setCuttentIndex] = useState([]);
  const [appearPopUp, setAppearPopUp] = useState(false);
  const [isDeadlineComplete, setIsDeadlineComplete] = useState(false);
  const [bid_deposit_fee_amount, set_bid_deposit_fee_amount] = useState('')
  const [contract_locations, set_contract_locations] = useState([])

  const PostLoad = useSelector((state: RootState) => state?.Profile?.postLoad);
  const load_type = postLoadMeta?.load_types?.find((val: any) => val?.id === PostLoad?.load_type);
  const packageType = postLoadMeta.package_types;
  const inquiry_type = postLoadMeta.inquiry_types;
  const PreferredVehicleType = postLoadMeta?.preferred_vehicles;
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (loadDetail?.is_load_history) {
      // setShowModalNoti(true)
      cloneALoadCallBackLoadHistory(loadDetail)
    }
  }, [loadDetail])

  const isShowIndicationDot = (jobLoadRecId) => {
    if (loadDetail?.is_viewer) {
    } else {
      // return
      dispatch(LoadAction.showIndicationDots({
        load_id: loadDetail?.id,
        recipients: jobLoadRecId,
      }))
    }
  }

  const isShowIndicationDotForWeight = (jobLoadRecId) => {
    if (loadDetail?.is_viewer) {
    } else {
      dispatch(LoadAction.showIndicationDots({
        load_id: loadDetail?.id,
        recipients: [jobLoadRecId?.id],
      }))
    }
  }

  const cloneALoadCallBackLoadHistory = (loadDetailHistory: any) => {
    // setShowModalNoti(false)
    const callBack = () => {
      if (!isObjectEmpty(loadDetailHistory)) {
        let data = {};
        if (
          loadDetailHistory.load_type.title === LOAD_TYPE.bulk ||
          loadDetailHistory.load_type.title === LOAD_TYPE.bulk
        ) {
          data = convertDataForFclAndBulkLoadDetailRedux(
            loadDetailHistory,
            packageType,
            load_type,
            inquiry_type,
            PreferredVehicleType,
            "history",
            "Loads Received"
          );
        } else {
          data = convertDataForLoadDetailRedux(
            loadDetailHistory,
            packageType,
            load_type,
            inquiry_type,
            "history",
            "Loads Received"
          );
        }
        
        // return
        if (!isObjectEmpty(data)) {
          dispatch(ProfileAction.postLoadData({ ...data }));
          history.push("/confirm-load", { from_load_history: true, fromNoti: true });
        }
      }
    }
    dispatch(ProfileAction.JobLoadMeta(callBack));
  };

  useEffect(() => {
    if (loadDetail) {
      set_contract_locations(loadDetail?.contract_locations?.map((item) => { return { ...item, new_cat: [] } }))
      let bids = []
      loadDetail?.contract_locations?.forEach((location) => {
        location?.categories?.forEach((category) => {
          if (category?.my_bid) {
            bids.push({ ...category?.my_bid, job_load_category_id: category?.id })
          }
        })
      })
      setResponded(bids)
    }
  }, [loadDetail])

  useEffect(() => {
    if (loadDetail) {
      if (loadDetail?.contract_locations) {
        setCuttentIndex(loadDetail?.bid_indexes);
        let newArr = [];
        let tem_id;
        let new_dentention = []
        loadDetail?.contract_locations.map((item, index) => {
          tem_id = item?.pickup?.id;
          item?.recipients?.forEach((item2) => {
            if (item2?.recipient?.id == profile?.id && item2?.bid && item2?.status == 1) {
              if (item2?.bid?.detentionRates.length) {
                item2?.bid?.detentionRates.forEach(element => {
                  new_dentention.push(element)
                });
              }
              newArr.push({
                loc_id: tem_id,
                bid_freight_ton: item2?.bid?.bid_freight_ton
                  ? item2?.bid?.bid_freight_ton
                  : "",
                price_per_freight: item2?.bid?.price_per_freight
                  ? item2?.bid?.price_per_freight
                  : "",
                loading_price_per_freight: item2?.bid?.loading_price_per_freight
                  ? item2?.bid?.loading_price_per_freight
                  : "",
                loading_price_total_freight: "",
                unloading_price_per_freight: item2?.bid
                  ?.unloading_price_per_freight
                  ? item2?.bid?.unloading_price_per_freight
                  : "",
                unloading_price_freight: item2?.bid?.unloading_price_freight
                  ? item2?.bid?.unloading_price_freight
                  : "",
                recipient_item: item2,
                loading: item?.pickup?.attributes?.is_loading_required,
                unloading: item?.pickup?.attributes?.is_unloading_required,
                total_freight_ton: item?.pickup?.attributes?.total_freight_ton,
                bid_detention_rate: item2?.bid?.bid_detention_rate,
                bid_detention_additional_charges: item2?.bid?.bid_detention_additional_charges,
                detention_tarif_policy: item2?.bid?.detention_tarif_policy,

              });
            }
          });
        });
        setRespondToLcl(newArr);
      }
    }
  }, [loadDetail]);

  const selectCat = (id, number) => {
    let selected_cat = [...selectedCategories];
    let numbers = [...category_numbers]
    if (selected_cat.indexOf(id) === -1) {
      selected_cat.push(id);
      numbers.push(number)
    } else {
      selected_cat.splice(selected_cat.indexOf(id), 1);
      numbers.splice(number, 1)
    }
    setSelectedCategories(selected_cat);
    set_category_numbers(numbers)
    if (loadDetail?.all_cats?.length == selected_cat.length) {
      setSelectAllCat(true);
    } else {
      setSelectAllCat(false);
    }
  };

  const selectLoc = (id) => {
    let selected_cat = [...selectedLocations];
    if (selected_cat.indexOf(id) === -1) {
      selected_cat.push(id);
    } else {
      selected_cat.splice(selected_cat.indexOf(id), 1);
    }
    setSelectedLocations(selected_cat);
    if (loadDetail?.contract_locations?.length == selected_cat.length) {
      setSelectAllCat(true);
    } else {
      setSelectAllCat(false);
    }
  };

  const respond = (item) => {
    let new_respond = [...responded];
    if (new_respond.indexOf(item?.category_id) === -1) {
      new_respond.push({
        job_load_category_id: item?.id,
        no_of_vehicles: "",
        bid_price_per_vehicle: "",
        bid_price_loading: "",
        bid_price_unloading: "",
        bid_detention_rate: "",
        loading: item?.is_loading_required ? true : false,
        unloading: item?.is_unloading_required ? true : false
      });
    } else {
      new_respond.splice(new_respond.indexOf(item), 1);
    }
    setResponded(new_respond);
  };

  const callBackToDashboard = () => {
    history.push('/dashboard/contract-recieved/new', { showPostedRecievedTabs: false, showTabs: true })
  };


  // submit and update bid vehicle 
  const acceptLoad = () => {
    let flag_price = false
    responded.forEach((item) => {
      item["bid_deposit_fee_amount"] = bid_deposit_fee_amount
      if (Number(item?.bid_price_per_vehicle) < 1) {
        flag_price = true
      }
    });
    if (!flag_price) {
      if (loadDetail?.is_container_deposit_by_transporter) {
        if (!loadDetail?.is_quote_price_deposit_fee_inclusive) {
          if (bid_deposit_fee_amount) {
            dispatch(LoadAction.acceptLoad({
              load_id: params?.id, responded, callBackToDashboard
            }));
          } else {
            showMessage.current({ message: "Bid deposit fee amount is required.", status: 'error' })
          }
        } else {
          dispatch(LoadAction.acceptLoad({
            load_id: params?.id, responded, callBackToDashboard
          }));
        }
      } else {
        dispatch(LoadAction.acceptLoad({
          load_id: params?.id, responded, callBackToDashboard
        }));
      }

    } else {
      showMessage.current({
        message: "Price per vehicle is required.",
        status: "error",
      });
    }
  };

  // submit and update bid weight volume 
  const acceptLclWeight = () => {
    let total_freight = 0;
    let new_array = [];
    respondToLcl.forEach((item) => {
      let freight = Number(item?.bid_freight_ton);
      let initial_price =
        freight * Number(item?.price_per_freight) +
        (freight * Number(item?.loading_price_per_freight) +
          freight * item?.unloading_price_per_freight);
      total_freight = total_freight + freight;
      new_array.push({
        ...item,
        location_id: item?.loc_id,
        loading_price_total_freight: initial_price,
        total_price_freight: initial_price,
      });
    });
    dispatch(
      LoadAction.acceptLoad({ load_id: params?.id, responded: new_array, callBackToDashboard })
    );
  };

  // decline load by recipient 
  const declineByRecipient = () => {
    if (selectedCategories.length > 0) {
      dispatch(
        LoadAction.recipientDeclined({
          load_id: params?.id,
          job_load_category_ids: selectedCategories,
          category_numbers
        })
      );
    } else {
      dispatch(
        LoadAction.recipientDeclined({
          load_id: params?.id,
          job_load_location_ids: selectedLocations,
          category_numbers
        })
      );
    }
  };

  // handle disbale button vehicle 
  const [firsTime, setFirstTime] = useState(true)
  useEffect(() => {
    if (responded.length) {
      setFirstTime(false)
    } else {
      setFirstTime(true)
    }
  }, [responded])

  const handleDisableVehicle = () => {
    if (loadDetail?.transaction_type?.title == "Bid") {
      if (loadDetail?.quote_by == 'vehicle' || loadDetail?.quote_by == "weight_volume") {
        if (firsTime) {
          return true
        } else {
          return responded?.filter(
            (val) => (val.loading &&
              (Number(val.bid_price_loading) === 0 || val.bid_price_loading === '')) ||
              (val.unloading &&
                (Number(val.bid_price_unloading) === 0 || val.bid_price_unloading === "")) ||
              Number(val.bid_price_per_vehicle) === 0 ||
              val.bid_price_per_vehicle === ""
          ).length
            ? true
            : false;
        }
      }
    } else {
      return responded.length ? false : true
    }
  }

  // handle disable button weight volume 
  const [firsTime2, setFirstTime2] = useState(true)
  useEffect(() => {
    if (respondToLcl.length) {
      setFirstTime2(false)
    } else {
      setFirstTime2(true)
    }
  }, [respondToLcl])
  const handleDisableWeight = () => {
    if (firsTime2) {
      return true
    } else {
      return respondToLcl.filter(
        (val) => (val.loading &&
          (Number(val.loading_price_per_freight) === 0)) ||
          (val.unloading &&
            (Number(val.unloading_price_per_freight) === 0)) ||
          !val?.price_per_freight
        // || !val?.bid_detention_rate
      ).length ? true : false;
    }
  }

  const [recipient_uploaded, set_recipient_uploaded] = useState([])
  const uploadFileRecipient = (data) => {
    let final_data = {
      ...data,
      path: data?.file_url,
    }
    let new_data = [...recipient_uploaded]
    new_data.push(final_data)
    set_recipient_uploaded(new_data)
  }

  // render left right info 
  const renderRTL = (key, val) => {
    return (
      <div className="formGrid">
        <div className="grayLabel">{key}</div>
        <div className="fw-5">{val}</div>
      </div>
    );
  };

  const handleChangeFile = (event: any) => {
    const formData = new FormData();
    formData.append("file", event?.target?.files[0]);
    dispatch(ProfileAction.uploadFile({ data: formData, cb: uploadFileRecipient }));
  };

  const handleRefresh = async () => {
    dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
  }

  // render response deadline 
  const renderComplete = () => {
    setIsDeadlineComplete(true);
  }
  const RenderResponseDeadline = ({ date, completed, iconComponent }: any) => {
    if (moment(date) >= moment()) {
      setIsDeadlineComplete(false);
    } else {
      setIsDeadlineComplete(true);
    }
    return !isDeadlineComplete ? (
      <div className="details-Content mb-3" style={{ marginTop: 0, flexDirection: 'column' }}>
        <div className="" style={{ paddingRight: 5 }}>
          <p className="customCounterTitle fw-5 text-center mb-3" style={{ alignItems: "unset" }}>
            <span className="titleName">Bidding Response Deadline</span>
            <span className="seperator">/</span>
            <span className="urduLabel">ریٹ دینے کا بقیہ وقت </span>
          </p>
        </div>
        <Counter date={moment(date).toDate()} renderComplete={renderComplete} iconComponent={iconComponent} />
      </div>
    ) : (
      ""
    );
  };
  

  const [is_seen_locs, set_is_seen_locs] = useState([])
  const [is_seen, set_is_seen] = useState(false)

  if (loadDetail) {
    let is_deadline = getFullTime(loadDetail?.deadline_at) > getFullTime(new Date())
    return (
      <TitleLayout progressFlag={true} progress={0} titleOnly titleOnlyText={`Contract ID - ${loadDetail?.id}`}>
        {params?.id && params?.type == "received" ? (

          <PullToRefresh
            onRefresh={handleRefresh}
          >
            <div className="loadDetailWrapper contractDetailWrapper">

              {/* success fees info */}
              <div>
                {
                  loadDetail?.next_action_step ?
                    <div className="error-container info">
                      <div>
                        <InfoIcon className="error-icon" />
                      </div>
                      <div className="error-text">
                        {loadDetail?.next_action_step}
                      </div>
                    </div> : null
                }
                {
                  postLoadMeta?.success_fee?.is_charge_contract_success_fee == true ?
                    <div className="error-container info">
                      <div>
                        <InfoIcon className="error-icon" />
                      </div>
                      <div className="error-text">
                        Note: The transaction is subject to{" "}
                        {`${metaData.success_fee} %`} success fee.
                      </div>
                    </div>
                    : null
                }
              </div>

              {/*response deadline*/}
              <FadeInComponent>
                <RenderResponseDeadline date={loadDetail?.deadline_at} />
              </FadeInComponent>

              {/* poster */}
              <FadeInComponent>
                <PosterContract
                  loadDetail={loadDetail}
                  expand={expand}
                  setExpand={setExpand}
                  type={params?.type}
                  set_bid_deposit_fee_amount={set_bid_deposit_fee_amount}
                  bid_deposit_fee_amount={bid_deposit_fee_amount}
                  for_bit_amount={true}
                  is_recipient={true}
                />
              </FadeInComponent>

              {/* Payment Terms */}
              <FadeInComponent>
                <PaymentTerms loadDetail={loadDetail} is_recipient={true} />
              </FadeInComponent>

              {/* additional detail */}
              <FadeInComponent>
                <AdditionalDetail loadDetail={loadDetail} is_recipient={true} />
              </FadeInComponent>

              {/*  Location Details */}
              {contract_locations?.length > 0 ? (
                <div>
                  {contract_locations?.map((location, index) => {
                    
                    let respod_bulk_obj = respondToLcl.find((item) => item?.loc_id == location?.pickup?.id)
                    let is_bid = location?.recipients?.find((recipt) => recipt?.recipient?.id == profile?.id && recipt?.status !== 0)
                    let is_negotiate_bid_weight_volume = location?.recipients?.find((item) => item?.recipient?.id == profile?.id && item?.bid && item?.status !== 4)
                    return (
                      <FadeInComponent>
                        <div className="accordion-container">
                          <Accordion className="accordion-background" onClick={() => {
                            let jobLoadRecId = []
                            let jobLoadRecIdWeight = location?.recipients?.find((rec) => rec?.recipient?.id == profile?.id)
                            if (location?.categories?.length) {
                              location.categories?.forEach((itm) => {
                                jobLoadRecId.push(itm?.recipients?.find((rec) => rec?.recipient?.id == profile?.id))
                              })
                            } 
                            if(location?.categories?.length){
                              if (!jobLoadRecId[0]?.is_seen) {
                                if (!is_seen_locs?.find((loc) => loc == location?.pickup?.id)) {
                                  isShowIndicationDot(jobLoadRecId.map((item) => item?.id))
                                  let locs = [...is_seen_locs]
                                  locs.push(location?.pickup?.id)
                                  set_is_seen_locs(locs)
                                }
                              }
                            }else{
                              if (!jobLoadRecIdWeight?.is_seen) {
                                if (!is_seen_locs?.find((loc) => loc == location?.pickup?.id)) {
                                  isShowIndicationDotForWeight(jobLoadRecIdWeight)
                                  let locs = [...is_seen_locs]
                                  locs.push(location?.pickup?.id)
                                  set_is_seen_locs(locs)
                                }
                              }
                            }
                          }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              onClick={() => {
                                if (!location?.is_data) {
                                  location['new_cat'] = location?.categories
                                  set_contract_locations([...contract_locations])
                                  location['is_data'] = true
                                } else {
                                  location['new_cat'] = []
                                  set_contract_locations([...contract_locations])
                                  location['is_data'] = false
                                }
                              }}
                            >
                              <CustomText.OpenSansSemiBold
                                textStyle={{
                                  fontSize: "0.875rem",
                                  color: Colors.Black,
                                }}
                              >
                                {/* Location {` ${index + 1}`} */}
                                <span>Location {` ${index + 1} `}</span>
                                <span className="seperator">/</span>
                                <span className="urduLabel"> مقام {` ${index + 1} `}</span>
                                {` : ${location?.pickup?.title} - ${location?.dropoff?.title}`}
                              </CustomText.OpenSansSemiBold>
                            </AccordionSummary>
                            <div className="accordion-content">
                              <div className="load-detail-location-container">
                                <div className="load-detail-location-info">
                                  <>
                                    <div className="pickup">
                                      <div className="load-detail-dots-container">
                                        <div className="load-detail-location-start">
                                          <div className="load-detail-location-start-inner"></div>
                                        </div>
                                        <div className="load-detail-location-end">
                                          <div className="load-detail-location-end-inner"></div>
                                        </div>
                                      </div>
                                      <div>
                                        <CustomText.OpenSansSemiBold>
                                          {location?.pickup?.title || ""}
                                        </CustomText.OpenSansSemiBold>
                                        <CustomText.OpenSansRegular >
                                          {location?.pickup?.raw_address}
                                        </CustomText.OpenSansRegular>
                                      </div>
                                    </div>
                                    <div className="dropoff">
                                      <CustomText.OpenSansSemiBold>
                                        {location?.dropoff?.title || ""}
                                      </CustomText.OpenSansSemiBold>

                                      <CustomText.OpenSansRegular>
                                        {location?.dropoff?.raw_address}
                                      </CustomText.OpenSansRegular>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>
                            <div style={{ marginTop: 10, }} className="accordion-content">
                              {location?.categories?.length ? (
                                <>
                                  {location?.new_cat?.map((category, index) => {
                                    let is_negotiate_bid = category?.recipients?.find((item) => item?.recipient?.id == profile?.id && item?.bid && item?.status !== 4)
                                    // let alreadyResponded = category?.recipients?.find((itm) => (itm?.status !== 0) || (itm?.recipient?.id === profile?.id && itm?.status !== 0));
                                    let alreadyResponded = category?.recipients?.find((itm) => itm?.recipient?.id === profile?.id && itm?.status !== 0);
                                    return (
                                      <>
                                        <div className="dividing-bordered"></div>
                                        <div className="category-title-container">
                                          <div className="fw-5 mb-3">
                                            {
                                              loadDetail?.quote_by == 'vehicle' ?
                                                <>
                                                  <span>Vehicle Category</span>
                                                  {
                                                    location?.new_cat?.length > 1 ?
                                                      <>
                                                        <span>{` ${index + 1}`}</span>
                                                        <>
                                                          <span className="seperator">/</span>
                                                          <span>{` ${index + 1} `}</span>
                                                          <span className="urduLabel">گاڑی کی کیٹاگری</span>
                                                        </>
                                                      </> :
                                                      <>
                                                        <>
                                                          <span className="seperator">/</span>
                                                          <span className="urduLabel">گاڑی کی کیٹاگری</span>
                                                        </>
                                                      </>
                                                  }
                                                </> :
                                                <>
                                                  <span>Item Category</span>
                                                  {
                                                    location?.new_cat?.length > 1 ?
                                                      <>
                                                        <span>{` ${index + 1}`}</span>
                                                        <>
                                                          <span className="seperator">/</span>
                                                          <span>{` ${index + 1} `}</span>
                                                          <span className="urduLabel"> آئٹم کی کیٹاگری </span>
                                                        </>
                                                      </> :
                                                      <>
                                                        <>
                                                          <span className="seperator">/</span>
                                                          <span className="urduLabel"> آئٹم کی کیٹاگری </span>
                                                        </>
                                                      </>
                                                  }
                                                </>
                                            }
                                          </div>
                                        </div>

                                        <TwoColumnRTLContractVehicle
                                          loadDetail={loadDetail}
                                          category={category}
                                          location={location}
                                          is_recipient={true}
                                        />

                                        {/* already respond to contract  */}
                                        {/* <div className="dividing-bordered"></div> */}
                                        <RespondedStatusVehile
                                          alreadyResponded={alreadyResponded}
                                          is_deadline={is_deadline}
                                          category={category}
                                          loadDetail={loadDetail}
                                          is_negotiate_bid={is_negotiate_bid}
                                          set_contract_locations={set_contract_locations}
                                          contract_locations={contract_locations}
                                          responded={responded}
                                          setResponded={setResponded}
                                          respond={respond}
                                        />
                                        {/* <div style={{ height: 16 }}></div> */}
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                // { lcl and bulk quote by weight and volume}
                                <div>
                                  <div className="category-title-container mb-3">
                                    <div className="fw-5" style={{ marginTop: 3 }}>
                                      Cargo Details
                                      <span className="seperator">/</span>
                                      <span className="urduLabel">کارگو کی تفصیلات</span>
                                    </div>
                                    <div className="chip">
                                      <span>
                                        {`${location?.pickup?.attributes?.no_of_packages ? location?.pickup?.attributes?.no_of_packages : "N/A"} Packages`}
                                      </span>
                                      <span className="seperator">/</span>
                                      <span className="urduLabel">
                                        {
                                          `${location?.pickup?.attributes?.no_of_packages ? location?.pickup?.attributes?.no_of_packages : "N/A"} پیکیجز`
                                        }
                                      </span>
                                    </div>
                                  </div>

                                  <TwoColumnRTLContractWeight
                                    loadDetail={loadDetail}
                                    location={location}
                                    is_recipient={true}
                                  />

                                  <div className="dividing-bordered"></div>

                                  <RespondedStatusWeight
                                    is_deadline={is_deadline}
                                    loadDetail={loadDetail}
                                    is_bid={is_bid}
                                    is_negotiate_bid_weight_volume={is_negotiate_bid_weight_volume}
                                    currentIndex={currentIndex}
                                    index={index}
                                    setCuttentIndex={setCuttentIndex}
                                    respondToLcl={respondToLcl}
                                    setRespondToLcl={setRespondToLcl}
                                    location={location}
                                    respod_bulk_obj={respod_bulk_obj}



                                  />
                                  <div style={{ height: 16 }}></div>
                                </div>
                              )}
                            </div>
                          </Accordion>
                        </div>
                      </FadeInComponent>
                    );
                  })}
                </div>
              ) : null}

              {/* Uploaded Documents */}
              <FadeInComponent>
                <UploadedDocuments loadDetail={loadDetail} is_recipient={true} />
              </FadeInComponent>
              {/* submit bid and decline button for vehicle basis  */}
              {(is_deadline && loadDetail?.all_cats?.length) ? (
                <FadeInComponent>
                  <div
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 20, }}
                  >
                    <PrimaryButton
                      onClick={() => {
                        let arr = []
                        let num_arr = []
                        if (loadDetail?.contract_locations?.length > 0) {
                          loadDetail?.all_cats?.forEach((element, index) => {
                            if (element.recipient_status == 2) {
                              arr.push(
                                element?.id
                              )
                              num_arr.push(index + 1)
                            }
                          });
                          setSelectedCategories(arr);
                          set_category_numbers(num_arr)

                          if (loadDetail?.all_cats.length == arr.length) {
                            set_all_declined_disable(true)
                            setSelectAllCat(true)
                          }
                        }
                        setShowModal(!showModal);
                      }}
                      buttonStyle={{ backgroundColor: Colors.Red, width: "48%", }}
                      is_recipient={true}
                      title="decline"
                      urdutitle="مسترد"
                    />

                    <PrimaryButton
                      disabled={handleDisableVehicle()}
                      onClick={() => {
                        if (postLoadMeta?.success_fee?.is_charge_contract_success_fee) {
                          setAppearPopUp(true);
                        } else {
                          acceptLoad()
                        }
                      }}
                      buttonStyle={{ width: "48%", }}
                      title={loadDetail?.already_bid.length ? "Update Bid" : "Submit Bid"}
                      is_recipient={true}
                      urdutitle={
                        loadDetail?.already_bid.length ? "بولی کو اپ ڈیٹ کریں" : "بولی ریٹ جمع کروائیں"
                      }
                    />
                  </div>
                </FadeInComponent>
              ) : null}

              {/* submit bit and decline button for weigh volume basis */}
              {(is_deadline && !loadDetail?.all_cats?.length) ? (
                <FadeInComponent>
                  <div
                    style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 20, }}
                  >
                    <PrimaryButton
                      onClick={() => {
                        let arr = []
                        loadDetail?.contract_locations?.map((location) => {
                          let is_declined = location?.recipients?.find(item => item?.recipient?.id == profile?.id)
                          if (is_declined?.status == 2) {
                            arr.push(location?.pickup?.id)
                          }
                        })
                        setSelectedLocations(arr)
                        if (loadDetail?.contract_locations.length == arr.length) {
                          set_all_declined_disable(true)
                          setSelectAllCat(true)
                        }
                        setShowModal(!showModal);
                      }}
                      buttonStyle={{ backgroundColor: Colors.Red, width: "48%", }}
                      is_recipient={true}
                      title="decline"
                      urdutitle="مسترد"
                    />
                    <PrimaryButton
                      disabled={handleDisableWeight()}
                      onClick={() => {
                        if (postLoadMeta?.success_fee?.is_charge_contract_success_fee) {
                          setAppearPopUp(true)
                        } else {
                          acceptLclWeight()
                        }
                      }}
                      buttonStyle={{ width: "48%", }}
                      title={loadDetail?.bid_indexes.length ? "Update Bid" : "Submit Bid"}
                      is_recipient={true}
                      urdutitle={
                        loadDetail?.already_bid.length ? "بولی کو اپ ڈیٹ کریں" : "بولی ریٹ جمع کروائیں"
                      }
                    />
                  </div>
                </FadeInComponent>
              ) : null}

            </div>
          </PullToRefresh>

        ) : null}
        <CustomModal showModal={showModal} toggleModal={setShowModal}>
          <div
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.125rem" }}>
              <span className="declineMsgWrap">
                <span className="titleName">Are you sure want to decline contract?</span>
                <span className="seperator">/</span>
                <span className="urduLabel">کیا آپ واقعی معاہدے کو مسترد کرنا چاہتے ہیں؟</span>
              </span>
            </CustomText.OpenSansSemiBold>
          </div>
          <div>
            {
              (loadDetail?.quote_by == 'vehicle' || loadDetail?.is_unit_basis) && loadDetail?.contract_locations?.length ?
                <div>
                  <div className="flexRow">
                    <Checkbox
                      checked={selectAllCat}
                      disabled={all_declined_disable}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let new_ids = [...selectedCategories];
                          let new_numbers = [...category_numbers]
                          loadDetail?.all_cats?.forEach((element, index) => {
                            if (new_ids.indexOf(element?.id) == -1) {
                              new_ids.push(element?.id);
                              new_numbers.push(index + 1)
                            }
                          });
                          setSelectedCategories(new_ids);
                          set_category_numbers(new_numbers)

                          setSelectAllCat(e.target.checked)
                        } else {
                          let new_ids = []
                          let new_nums = []
                          loadDetail?.all_cats?.forEach((element, index) => {
                            if (element?.recipient_status == 2) {
                              new_ids.push(element?.id)
                              new_nums.push(index + 1)
                            }
                          });
                          setSelectedCategories(new_ids);
                          set_category_numbers(new_nums)

                          setSelectAllCat(e.target.checked)
                        }
                      }}
                      style={{
                        color: "#7ac251",
                      }}
                    />
                    <CustomText.OpenSansRegular
                      textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
                    >
                      {
                        loadDetail?.is_unit_basis ? "All Item Categories" : "All Vehicle Categories"
                      }
                      <span className="seperator">/</span>
                      <span className="urduLabel">
                        {
                          loadDetail?.is_unit_basis ? "تمام آئٹم  کیٹاگری" : "تمام گاڑیوں کے کیٹاگری"
                        }
                      </span>

                    </CustomText.OpenSansRegular>
                  </div>
                  <div>
                    {loadDetail?.contract_locations?.map((item, loc_index) => {
                      return (
                        <>
                          <CustomText.OpenSansSemiBold>
                            Location {loc_index + 1}
                            <span className="seperator">/</span>
                            <span className="urduLabel" style={{ paddingRight: '0.3rem' }}> مقام {loc_index + 1} </span>
                          </CustomText.OpenSansSemiBold>
                          {item?.categories.map((item2, index) => {
                            return (
                              <div className="flexRow">
                                <Checkbox
                                  checked={
                                    selectedCategories.indexOf(item2?.id) == -1
                                      ? false
                                      : true
                                  }
                                  disabled={item2?.recipient_status == 2 ? true : false}
                                  onClick={() => {
                                    selectCat(item2?.id, index + 1);
                                  }}
                                  style={{
                                    color: "#7ac251",
                                  }}
                                />
                                <CustomText.OpenSansRegular
                                  textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
                                >
                                  {`${loadDetail?.is_unit_basis ? "Item Category" : "Category"} ${index + 1}`} {item2?.recipient_status == 2 && " (declined)"}
                                  <span className="seperator">/</span>
                                  <span className="urduLabel" style={{ paddingRight: '0.3rem' }}> {`${loadDetail?.is_unit_basis ? "آئٹم  کیٹاگری " : " کیٹاگری "} ${index + 1}`} </span>
                                </CustomText.OpenSansRegular>
                              </div>
                            );
                          })}
                        </>
                      );
                    })}
                  </div>
                </div> :
                <div>
                  <div className="flexRow">
                    <Checkbox
                      checked={selectAllCat}
                      disabled={all_declined_disable}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let new_ids = [...selectedLocations];
                          loadDetail?.contract_locations?.forEach((element) => {
                            if (new_ids.indexOf(element?.pickup?.id) == -1) {
                              new_ids.push(element?.pickup?.id);
                            }
                          });
                          setSelectedLocations(new_ids);
                          setSelectAllCat(e.target.checked)
                        } else {
                          let arr = []
                          loadDetail?.contract_locations?.forEach((location) => {
                            let is_declined = location?.recipients?.find(item => item?.recipient?.id == profile?.id)
                            if (is_declined?.status == 2) {
                              arr.push(location?.pickup?.id)
                            }
                          })
                          setSelectedLocations(arr);
                          setSelectAllCat(e.target.checked)
                        }
                      }}
                      style={{
                        color: "#7ac251",
                      }}
                    />
                    <CustomText.OpenSansRegular
                      textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
                    >
                      All Location
                      <span className="seperator">/</span>
                      <span className="urduLabel" style={{ paddingRight: '0.3rem' }}> تمام مقامات </span>
                    </CustomText.OpenSansRegular>
                  </div>
                  <div>
                    {loadDetail?.contract_locations?.map((item2, index) => {
                      let is_declined = item2?.recipients?.find(item => item?.recipient?.id == profile?.id)
                      return (
                        <div className="flexRow">
                          <Checkbox
                            disabled={is_declined?.status == 2 ? true : false}
                            checked={
                              selectedLocations.indexOf(item2?.pickup?.id) == -1
                                ? false
                                : true
                            }
                            onClick={() => {
                              selectLoc(item2?.pickup?.id);
                            }}
                            style={{
                              color: "#7ac251",
                            }}
                          />
                          <div>
                            <span className="titleName"
                              style={{ fontSize: "0.875rem", marginLeft: 10 }}
                            >
                              {`Location ${index + 1} `} {is_declined?.status == 2 && " (declined)"}

                            </span>
                            <span className="seperator">/</span>
                            <span className="urduLabel">
                              {`مقام ${index + 1} `} {is_declined?.status == 2 && " (declined)"}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
            }
          </div>
          <PrimaryButton
            onClick={() => {
              if (selectedCategories?.length) {
                if (selectedCategories.length) {
                  declineByRecipient();
                  setShowModal(false);
                  if (selectAllCat) {
                    setSuccessModal(true)
                  }
                } else {
                  showMessage.current({
                    message: "Please select category.",
                    status: "error",
                  });
                }
              } else {
                if (selectedLocations.length) {
                  declineByRecipient();
                  setShowModal(false);
                  if (selectAllCat) {
                    setSuccessModal(true)
                  }
                } else {
                  showMessage.current({
                    message: "Please select location.",
                    status: "error",
                  });
                }
              }
            }}
            buttonStyle={{
              backgroundColor: Colors.Red,
              marginTop: 10,
              marginBottom: 10,
              height: 40,
            }}
            is_recipient={true}
            title="decline"
            urdutitle="مسترد"
          />
          <OutlineButton
            is_recipient={true}
            title="Cancel"
            urdutitle="منسوخ "
            onClick={() => setShowModal(false)}
            buttonStyle={{
              height: 40,
            }}
          />
        </CustomModal>

        {/* success modal fcl */}
        <CustomModal showModal={appearPopUp} toggleModal={setAppearPopUp}>
          <span className="icons-cross directionRight" onClick={() => setAppearPopUp(false)} ></span>
          <div style={{ marginTop: 10, textAlign: "center", }} >
            <div className="imgWrapper">
              <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.125rem" }}>
                Do you agree to pay the Success Fee?
              </CustomText.OpenSansSemiBold>
            </div>
            <CustomText.OpenSansRegular
              textStyle={{ fontSize: "0.875rem", textAlign: "center", padding: "0 25px", margin: "20px 0", maxWidth: 300, }}>
              {postLoadMeta?.success_fee?.charge_contract_success_fee_note}
            </CustomText.OpenSansRegular>
          </div>

          <div className="modalFooterWrapper">
            <OutLineButton onClick={() => {
              setAppearPopUp(false)
            }} title="NO" />
            <PrimaryButton
              onClick={() => {
                if (loadDetail?.quote_by == "weight_volume") {
                  acceptLclWeight();
                } else {
                  acceptLoad();
                }
                setAppearPopUp(false);
              }}
              title="YES, AGREE"
            />
          </div>
        </CustomModal>

        <CustomModal showModal={successModal} toggleModal={setSuccessModal}>
          <div
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "1.125rem", textAlign: "center" }}
            >
              Successfully Declined!
            </CustomText.OpenSansSemiBold>
          </div>
          <div
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <CustomText.OpenSansRegular
              textStyle={{
                fontSize: "0.875rem",
                textAlign: "center",
                maxWidth: 250,
                padding: "0 25px",
              }}
            >
              Your Contract ID - {loadDetail?.id} has been moved to load history. <br />
              <span
                className="blueLink"
                onClick={() => {
                  dispatch(ProfileAction.updateHistoryFilters({
                    loadType: "Loads Received",
                    inquiryType: 'contract',
                  }))
                  setTimeout(() => {
                    history.replace(LOAD_HISTORY_URL)
                  }, 500);
                }
                }
              >
                View Load
              </span>
            </CustomText.OpenSansRegular>
          </div>
          <PrimaryButton
            onClick={() => {
              // setSuccessModal(false);
              history.replace('/dashboard/contract-recieved/new', { showPostedRecievedTabs: false, showTabs: true });
            }}
            buttonStyle={{
              marginTop: 10,
              marginBottom: 10,
              height: 40,
            }}
            title="OKAY"
          />
        </CustomModal>
      </TitleLayout>
    );
  } else {
    return <div></div>;
  }
};
export default Page;