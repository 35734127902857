import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Images } from "@temp/assets";
import {
	CustomText,
	CustomModal,
} from "@components";
import { RenderRTL } from "../../../src/views/LoadDetail/components/RenderRTL";

const TruckLocation = (params) => {
	const [showTrackerPin, setShowTrackerPin] = React.useState(false);
	return (
		<>
			<div
				style={{
					textAlign: 'center',
					transform: "translate(-50%, -50%)",
					position: 'absolute',
				}}
			>
				<img
					{...params}
					src={Images.TruckLocation}
					width="35px"
					height="35px"
					style={{ objectFit: "contain", }}
					onClick={() => {
						setShowTrackerPin(true)
					}}
					position={{
						lat: params.lat,
						long: params.lng

					}}
				></img>
			</div>

			<CustomModal
				modalStyle={{
					width: 300,
					minHeight: 100,
				}}
				showModal={showTrackerPin}
				toggleModal={setShowTrackerPin}
			>
				<span style={{ cursor: "pointer" }} className="icons-cross floatRight" onClick={() => { setShowTrackerPin(false); }}></span>

				<div
					style={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<CustomText.OpenSansBold
						textStyle={{
							fontSize: "1rem",
							marginTop: 4

						}}>Vehicle Details
					</CustomText.OpenSansBold>
					{
						params?.marker?.lat || params?.marker?.lat ?
							<RenderRTL
								title={"Lat: "}
								val={params?.marker?.lat ? params?.marker?.lat : params?.marker?.lat}
							/> : null}


					{
						params?.marker?.lng || params?.marker?.lng ?
							<RenderRTL
								title={"Lng:"}
								val={params?.marker?.lng ? params?.marker?.lng : params?.marker?.lng}
							/> : null}

					{
						params?.marker?.chasis_no || params?.marker?.ChasisNo ?
							<RenderRTL
								title="Chasis Number: "
								val={params?.marker?.chasis_no ? params?.marker?.chasis_no : params?.marker?.ChasisNo
								}
							/> : null}

					{
						params?.marker?.vrn_color || params?.marker?.VrnColor ?
							<RenderRTL
								title="Color: "
								val={params?.marker?.vrn_color ? params?.marker?.vrn_color : params?.marker?.VrnColor}
							/> : null}

					{
						params?.marker?.is_idle ?
							<RenderRTL
								title={"Is Idle: "}
								val={params?.marker?.is_idle ? params?.marker?.is_idle : ""}
							/> : null}

					{
						params?.marker?.is_moving ?
							<RenderRTL
								title={"Is Moving: "}
								val={params?.marker?.is_moving ? params?.marker?.is_moving : ""}
							/> : null}

					{
						params?.marker?.reg_no ?
							<RenderRTL
								title={"Reg. No.: "}
								val={params?.marker?.reg_no ? params?.marker?.reg_no : ""}
							/> : null}

					{
						params?.marker?.registrationNo ?
							<RenderRTL
								title={"Reg. No.: "}
								val={params?.marker?.registrationNo}
							/> : null}


					{/* {
            params?.marker?.current_address ?
              <RenderRTL
                title="Current Address: "
                val={params?.marker?.current_address}
              /> : null} */}

					{
						params?.marker?.location ?
							<RenderRTL
								title="Current Addres: "
								val={params?.marker?.location}
							/> : null}

					{
						params?.marker?.address ?
							<RenderRTL
								title="Current Address: "
								val={params?.marker?.address}
							/> : null}

					{
						params?.marker?.engine_no || params?.marker?.EngineNo ?
							<RenderRTL
								title="Engine Number: "
								val={params?.marker?.engine_no ? params?.marker?.engine_no : params?.marker?.EngineNo}
							/> : null}

					{params?.marker?.ignition || params?.marker?.Ignition ?
						<RenderRTL
							title="Ignition: "
							val={params?.marker?.ignition ? params?.marker?.ignition : params?.marker?.Ignition}
						/> : null}

					{/* {
						params?.marker?.speed || params?.marker?.Speed ?
							<RenderRTL
								title="Speed: "
								val={params?.marker?.speed ? params?.marker?.speed : params?.marker?.Speed}
							/> : null} */}

					{params?.marker?.speed ?
						<RenderRTL
							title="Speed: "
							val={params?.marker?.speed}
						/> : null}


				</div>
			</CustomModal>
		</>
	);
}

const DriverLocation = (params) => (
	<img
		{...params}
		src={Images.DriverLocation}
		width="50px"
		height="50px"
		style={{ objectFit: "contain" }}
	></img>
);
const handleMapp = async (directionsRenderer, map) => {
	await directionsRenderer.setMap(map);
}
export class GoogleMap extends Component {
	render() {
		const { screen, locations, marker, driverMarker, additional_locations, viewer } = this.props;
		const bounds = new window.google.maps.LatLngBounds();
		let coordinates = [];
		let waypts = []
		if (locations && locations.length) {
			coordinates = locations?.map((model: any) => {
				if (model?.lat && model?.long) {
					const { lat, long } = model;
					const latLng = new window.google.maps.LatLng(Number(lat), Number(long));
					bounds.extend(latLng);
					return latLng;
				}
			});
		}
		if (additional_locations && additional_locations.length) {
			additional_locations.map((model, index) => {
				if (model?.lat && model?.long) {
					waypts.push({
						location: { lat: Number(model?.lat), lng: Number(model?.long), indexedDB: index },
						stopover: true,
					})
				}
			})
		}

		const apiIsLoaded = (map, maps) => {
			const directionsRenderer = new google.maps.DirectionsRenderer(
				marker?.length == 1
				&&
				{
					preserveViewport: true,
				}
			);
			const promiseOne = new Promise((res, rej) => {
				const directionsService = new google.maps.DirectionsService();
				const origin = coordinates &&
					coordinates.length && {
					lat: coordinates[0]?.lat(),
					lng: coordinates[0]?.lng(),
				};
				const destination = {
					lat: coordinates[1]?.lat(),
					lng: coordinates[1]?.lng(),
				};
				directionsService.route(
					{
						origin: origin,
						destination: destination,
						waypoints: waypts,
						optimizeWaypoints: true,
						travelMode: google.maps.TravelMode.DRIVING,

					},
					async (result, status) => {
						if (status === google.maps.DirectionsStatus.OK) {
							await directionsRenderer.setDirections(result);
							res(result)
						}
					}

				);

			})
			promiseOne.then(() => {
				handleMapp(directionsRenderer, map)
			})
		};

		let hasLatLong = locations[0]?.lat && locations[0]?.long && locations[1]?.lat && locations[1]?.long ? true : false
		let hasMarker = marker.length ? true : false

		const createMapOptions = (maps) => {
			return {
				styles: [
					{
						featureType: "landmark",
						elementType: "geometry",
						stylers: [
							{
								visibility: "on"
							}
						]
					}
				]
			};
		}
		return (
			<>
				<GoogleMapReact
					onGoogleApiLoaded={({ map, maps }) => {
						screen === "active_load"
						apiIsLoaded(map, maps)
					}}
					bootstrapURLKeys={{
						key: String(process.env.REACT_APP_GOOGLE_KEY),
					}}
					center={this.props.center}
					// center={
					// 	hasLatLong ?
					// 		this?.props?.center
					// 		: hasMarker ? { lat: Number(marker[0]?.lat), lng: Number(marker[0]?.lng) }
					// 			:
					// 			this.props.center}
					defaultZoom={this?.props?.zoom}
					options={createMapOptions}
				>
					{screen === "active_load" && marker?.length && marker?.map((item) => {
						return (
							(item?.lat || item?.lng) &&
							<TruckLocation
								marker={item}
								lat={item?.lat}
								lng={item?.lng}
							/>
						)
					})}

					{screen === "active_load" && driverMarker && (
						<DriverLocation lat={driverMarker?.lat} lng={driverMarker?.lng} />
					)}
				</GoogleMapReact>
			</>
		);
	}
}

