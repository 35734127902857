import React, { useState } from "react";
import { CustomText, CustomModal, PrimaryButton, PhoneInput, SimpleInput } from "@temp/components";
import { Colors, Images } from "@temp/assets";
import { isBrowser } from "react-device-detect";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import { phoneNumberFormater, showMessage, preventNonNumericalInput } from "@temp/utils/helper";
import { Grid, OutlinedInput } from "@mui/material/";
import { DataObjectRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { DrawerAction, LoadAction } from "@temp/store/actions";
import { useParams } from "react-router";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { Close } from "@mui/icons-material";
import SimplePhoneInput from "@temp/components/SimplePhoneInput";


export const ContactCard = ({ poster, style, active = true, details, driverDetail, myDetail }) => {

  const params = useParams();

  const [showModal, toggleModal] = useState(false);
  const [edited_data, set_edited_data] = useState(null)
  const dispatch = useDispatch()

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    // Remove non-numeric characters
    const numericValue = paste.replace(/[^0-9]/g, '');
    // Prevent pasting if the first character is 0
    if (event?.target?.value.length > 10) {
      return;
    }
    // if (numericValue[0] !== '0') {
    //   set_edited_data(numericValue);
    // }

    if (numericValue.startsWith('0') || numericValue.startsWith('92')) {
      return;
    }
    // Set the pasted value to driverMobileNumber
    set_edited_data({
      ...edited_data,
      phone_no : numericValue});

  }

  const validateInput = (value) => {
    // Prevent input starting with 0, 92, or a hyphen
    const invalidPatterns = /^(0|92|-)/;
    return !invalidPatterns.test(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;


    if (validateInput(value) || value === '') {
      if (event.target.value.length <= 10) {
        set_edited_data({
          ...edited_data,
          phone_no: event.target.value
        })
      }
    }
  };


  const handleEditDriverDetail = (data) => {
    let payload = {
      phone_no: data?.phone_no,
      phone_code: data?.phone_code,
      name: data?.name,
    }
    set_edited_data(payload)
  }

  const handleSubmit = () => {
    let final_data = {
      driver_phone_no: edited_data?.phone_no,
      driver_phone_code: edited_data?.phone_code,
      driver_name: edited_data?.name,
      id: driverDetail?.vehicle?.id,
      load_id: params?.load_id,
      job_load_driver_id: params?.job_load_driver_id,
      driver_id: driverDetail?.driver?.id

    }
    dispatch(LoadAction.updateDriverDetail(final_data));
  }

  
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

  return (
    <div
      className={`load-detail-user-container ${active ? "active" : ""}`}
      style={style}
    >
      <div className="load-detail-user">
        <img
          style={{ height: 36, width: 36, borderRadius: 18 }}
          src={
            poster?.picture ||
            "https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg"
          }
        />
      </div>
      <div className="user-phone-whatsapp">
        <div className="user-detail">
          <div style={{
            display: "flex", flexDirection: "row", alignItems: "center"
          }}>
            {poster?.name}
            {
              details ?
                <span className="icons-edit icon-btn contactCardIcon"
                  onClick={() => {
                    toggleModal(true)
                    handleEditDriverDetail(poster)

                  }} ></span>
                : null}
          </div>
          <div className="title" style={{
            display: "flex", flexDirection: "row", alignItems: "center"
          }}>
            {
              phoneNumberFormater(poster?.phone_code, poster?.phone_no)
            }
            {
              details ?
                <span className="icons-edit icon-btn contactCardIcon"
                  onClick={() => {
                    toggleModal(true)
                    handleEditDriverDetail(poster)
                  }} ></span>
                : null}
          </div>
        </div>
        <div className="whatsapp-button-container">

          {
            isBrowser ?
              <>
                <a
                  href={active ? `tel:92${poster?.phone_no}` : "#"}
                  target={active ? "blank" : ""}
                  style={{ marginRight: 30 }}
                  className="phone-whatsapp-button"
                >
                  <img
                    alt=""
                    style={{ height: "100%" }}
                    src={active ? Images.Phone : Images.PhoneGray}
                  />
                </a>
                <a
                  href={active ? `https://wa.me/+92${poster?.phone_no}` : "#"}
                  target={active ? "blank" : ""}
                  className="phone-whatsapp-button"
                >
                  <img
                    alt=""
                    style={{ height: "100%" }}
                    src={active ? Images.WhatsAppBgGreen : Images.WhatsappDarkGray}
                  />
                </a>
              </> :
              <>
                <>
                  <img
                    alt=""
                    className="phone-whatsapp-button"
                    style={{ height: "100%", marginRight: 30 }}
                    src={Images.Phone}
                    onClick={() => {
                      if (sendEventToAndroid("activeLoadPhone")) {
                        window.Android.activeLoadPhone(
                          String(poster?.phone_no)
                        );
                      } else if (sendEventToiOS("activeLoadPhone")) {
                        window.webkit.messageHandlers.activeLoadPhone.postMessage(
                          JSON.stringify({
                            phoneNumber: poster?.phone_no,
                          })
                        );
                      } else {
                      }
                    }}
                  />
                  <img
                    onClick={() => {
                      if (sendEventToAndroid("activeLoadWhatsapp")) {
                        window.Android.activeLoadWhatsapp(
                          String(poster?.phone_no)
                        );
                      } else if (sendEventToiOS("activeLoadWhatsapp")) {
                        window.webkit.messageHandlers.activeLoadWhatsapp.postMessage(
                          JSON.stringify({
                            phoneNumber: poster?.phone_no,
                          })
                        );
                      } else {
                      }
                    }}
                    className="phone-whatsapp-button"
                    alt=""
                    style={{ height: "100%" }}
                    src={Images.WhatsAppBgGreen}
                  />
                </>
              </>

          }

        </div>
      </div>
      <CustomModal
        showModal={showModal}
        toggleModal={toggleModal}
      >
        <div className="editVehicleModal">
          <Close
            className="closeIcon"
            onClick={() => {
              toggleModal(!showModal);
            }}
          />
          <CustomText.OpenSansSemiBold>Edit Vehicle</CustomText.OpenSansSemiBold>
        </div>

        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 10 }}>
          <FadeInComponent>
            <div className="inputField">
              <div className="label">Driver Name (Optional)</div>
              <div className="field">
                <SimpleInput
                  onChange={(e) => {
                    if (e.target.value.length > 25) {
                      showMessage.current({
                        message: "Driver Name should not be greater than 25 characters",
                        status: "error"
                      })
                    } else {
                      set_edited_data({
                        ...edited_data,
                        name: e.target.value
                      })
                    }

                  }}
                  value={edited_data?.name}
                  type="text"
                  style={{ height: 35, width: '100%' }}
                  aria-describedby="standard-weight-helper-text"
                  className="rightFormField"
                  placeholder="Optional"

                />
                {/* <span className="icons-edit editIcon newEditIcon"></span> */}
              </div>
            </div>
          </FadeInComponent>

        </Grid>

        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 10 }}>
          <FadeInComponent>
            <div className="inputField">
              <div className="label">Driver Phone No.</div>
              <div className="field">
                {/* <PhoneInput
                  value={edited_data?.phone_no}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      set_edited_data({
                        ...edited_data,
                        phone_no: e.target.value
                      })
                    }
                  }}
                  phoneCode={"92"}
                  id="phone-number"
                  placeholder={"3000000000"}
                />*/}
                
                <SimplePhoneInput
                  value={edited_data?.phone_no}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  placeholder="3000000000"
                  maxLength={10}
                  id="phone-number"
                />
                {/* <span className="icons-edit editIcon newEditIcon"></span> */}
              </div>
            </div>
          </FadeInComponent>


        </Grid>
        <PrimaryButton
          title={"Submit"}
          disabled={edited_data?.phone_no?.length < 10}
          onClick={() => {
            set_edited_data(null)
            toggleModal(false)
            handleSubmit()
          }}
        />

      </CustomModal>
    </div>
  );
};
