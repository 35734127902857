import React, { FC, useState, useRef } from "react";
import styled from "styled-components";
import { TransactionTypeProps } from "./type";
import "./style.scss";
import { useSelector } from "react-redux";
var numeral = require("numeral");
import { css } from "@emotion/css";
import CurrencyFormat from "react-currency-format";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Grid,
    Select,
    MenuItem,
    Checkbox,
    OutlinedInput,
    InputAdornment,
} from "@mui/material/";
import { ExpandMore } from "@mui/icons-material";
import { LOAD_TYPE } from "@temp/constants";
import InfoIcon from "@mui/icons-material/Info";
import { CustomModal, CustomText, NewInput } from "@temp/components";
import { SimpleCheckBox } from "@components";


export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 100%;
  background-color: rgb(250, 250, 250);
  margin-bottom: 20px;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 0 0 !important;
`;

const pageTitle = css({
    color: "rgb(13 21 9)",
    fontSize: "0.875rem",
    marginTop: 30,
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
});

export const UnitCategory: FC<TransactionTypeProps> = ({
    value,
    index,
    onChangePrice,
    screenTitle,
    from,
    added_units
}: TransactionTypeProps) => {

    const [info, setInfo] = useState("");
    const [showModal, toggleModal] = useState(false);

    const FormRow = ({ label, value, adornement }) => {
        return (
            <>
                <Grid item xs={6}>
                    <div className="transactionFieldLabel">
                        {label}
                        {label == "Total Price" ? (
                            <InfoIcon
                                onClick={() => {
                                    setInfo(
                                        "Price is for all vehicles in category, inclusive of requested free time and 1 day mandatory detention."
                                    );
                                    toggleModal(true);
                                }}
                                style={{ height: 17, position: "absolute", cursor: "pointer" }}
                            />
                        ) : null}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="transactionRightFieldLabel">
                        {value} {adornement}
                    </div>
                </Grid>
            </>
        );
    };
    const [isExpanded, setIsExpanded] = useState(true);
    const handleChange = (check) => {
        setIsExpanded(check);
    };
    const postLoadMeta = useSelector((state) => state?.Profile?.postLoadMeta);
    const PostLoad = useSelector((state) => state?.Profile?.postLoad);
    const hoursData = useSelector(
        (state) => state?.Profile?.postLoadMeta?.detention_free_time_hrs?.map((item) => { return { key: `${item} hrs`, value: item } })
    );

    const load_type = postLoadMeta.load_types.find(
        (val: object) => val.id === PostLoad.load_type
    );
    let inputFocus = useRef();


    return (
        <>
            <Maincontainer>
                <Accordion
                    expanded={isExpanded}
                    onChange={() => handleChange(!isExpanded)}
                    className="offerAccordionContainer"
                    style={{ backgroundColor: "rgb(250,250,250)" }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={{ paddingTop: "5px", fontWeight: "bold" }}>
                            {added_units.length > 1 ? `Item Category ${index + 1}` : "Item Category"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: 0, paddingBottom: 10 }}>
                        <Grid
                            container
                            spacing={1}
                            style={{ overflowY: "scroll", margin: 0, alignItems: "center" }}
                        >

                            <FormRow
                                label="Gross Weight in KG"
                                // value={`${numeral(Number(value?.gross_weight * 1000)).format("0,0")}`}
                                value={value.gross_weight
                                    ? numeral(value.gross_weight_in_kgs).format("0,0.00")
                                    : ""}
                                adornement={'KG'}
                            />
                            <FormRow
                                label="Gross Weight in Ton"
                                value={`${numeral(value?.gross_weight).format("0,0.00")}`}
                                adornement={'TON'}
                            />


                            {
                                value?.cargo_volume ?
                                    <FormRow
                                        label="Cargo Volume"
                                        value={`${numeral(value?.cargo_volume).format("0,0.00")}`}
                                        adornement={"CBM"}
                                    /> : null
                            }

                            {
                                value?.total_freight_ton ?
                                    <FormRow
                                        label=
                                        // "Freight Ton"
                                        "Total Weight"
                                        value={`${numeral(value?.total_freight_ton).format("0,0.00")}`}
                                        adornement={"F.Ton"}
                                    /> : null
                            }
                            <FormRow
                                label="Dimensions"
                                // value={value?.dimensions}
                                value={`${value?.dimensions} ${value?.unit_dimension_value}`}
                            />

                            <FormRow
                                label="Item Quantity"
                                value={value?.unit_quantity}
                            />
                            {
                                value?.unit_description ?
                                    <FormRow
                                        label="Item Description"
                                        value={value?.unit_description}
                                    /> : null
                            }

                            {screenTitle !== LOAD_TYPE.fcl ? (
                                <>
                                    {value?.is_loading_required ?
                                        <FormRow
                                            label="Loading"
                                            value={value?.is_loading_required ? "YES" : "NO"}
                                        /> : null}

                                    {value?.is_loading_required ?
                                        value?.loading_requirements && (
                                            <FormRow
                                                label="Labour / Equipment "
                                                value={value?.loading_requirements || ""}
                                            />
                                        ) : null}

                                    {value?.is_unloading_required ?
                                        <FormRow
                                            label="Unloading"
                                            value={value?.is_unloading_required ? "YES" : "NO"}
                                        /> : null}

                                    {value?.is_unloading_required ?
                                        value?.unloading_requirements && (
                                            <FormRow
                                                label="Labour / Equipment "
                                                value={value?.unloading_requirements || ""}
                                            />
                                        ) : null}
                                </>
                            ) : null}

                            <>
                                {from !== "offer" ? (
                                    <Grid item xs={12}>
                                        <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                            <div className="label">Max Bid (Without GST)</div>
                                            <div className="field">
                                                <NewInput
                                                    onChange={(e) => {
                                                        onChangePrice(
                                                            index,
                                                            e.target.value,
                                                            "max_bid",
                                                            false,
                                                        );
                                                    }}
                                                    value={value?.max_bid ? value?.max_bid : ""}
                                                    showEnd={"PKR"}
                                                    placeholder={"Optional"}
                                                    decimal={true}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                ) : null}


                                <Grid item xs={6}>
                                    <div className="transactionFieldLabel">
                                        Free Time Required
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="userSelectBox">
                                        <Select
                                            placeholder="Select Hours"
                                            name="vehicle_type"
                                            className="add-vehicle-select-box2"
                                            value={
                                                value?.detention_free_time
                                                    ? value.detention_free_time
                                                    : "Select Hours"
                                            }
                                            onChange={(e) => {

                                                onChangePrice(
                                                    index,
                                                    e.target.value,
                                                    "detention_free_time",
                                                    true
                                                );
                                            }}
                                        >
                                            {hoursData?.map((item) => {
                                                return (
                                                    <MenuItem
                                                        selected={item?.vaue == value.detention_free_time}
                                                        value={item?.value}
                                                    >
                                                        {item?.key}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </Grid>


                                {from == "offer" ? (
                                    <>
                                        <Grid item xs={12}>
                                            <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                                <div className="label">
                                                    Price / Item (Without GST)
                                                    <InfoIcon
                                                        onClick={() => {
                                                            setInfo("Price is inclusive of Free Time.");
                                                            toggleModal(true);
                                                        }}
                                                    />
                                                </div>
                                                <div className="field">
                                                    <NewInput
                                                        onChange={(e) => {
                                                            onChangePrice(
                                                                index,
                                                                e.target.value,
                                                                "price",
                                                            );
                                                        }}
                                                        value={value?.price ? value?.price : ""}
                                                        showEnd={"PKR"}
                                                        decimal={true}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: 0, marginBottom: 12 }}>
                                            {PostLoad?.additionalScreen?.is_cross_stuffing_required ?
                                                <div className="error-container info">
                                                    <div>
                                                        <InfoIcon className="error-icon" />
                                                    </div>
                                                    <div className="error-text">
                                                        Offer price should be inclusive of local transportation + shifting charges + upcountry transportation.
                                                    </div>
                                                </div> : null
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                                <div className="label">
                                                    Detention Rate (Without GST)
                                                    <InfoIcon
                                                        onClick={() => {
                                                            setInfo(
                                                                "Detention is applicable on a daily basis from the expiry of free time."
                                                            );
                                                            toggleModal(true);
                                                        }}

                                                    />
                                                </div>
                                                <div className="field">
                                                    <NewInput
                                                        onChange={(e) => {
                                                            onChangePrice(
                                                                index,
                                                                e.target.value,
                                                                "detention_rate",
                                                                true
                                                            );
                                                        }}
                                                        value={value?.detention_rate > 0 ? value?.detention_rate : ""}
                                                        showEnd={"PKR"}
                                                        placeholder={
                                                            "Optional"
                                                        }
                                                        decimal={true}
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    </>
                                ) : null}
                            </>

                            {screenTitle !== LOAD_TYPE.fcl ? (
                                <>
                                    {value?.is_loading_required && from == "offer" ? (
                                        <>
                                            <Grid item xs={12}>
                                                <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                                    <div className="label">
                                                        Loading Price / Item (Without GST)
                                                    </div>
                                                    <div className="field">
                                                        <NewInput
                                                            onChange={(e) => {
                                                                onChangePrice(
                                                                    index,
                                                                    e.target.value,
                                                                    "loading_price",
                                                                );
                                                            }}
                                                            value={value?.loading_price > 0 ? value?.loading_price : ""}
                                                            showEnd={"PKR"}
                                                            decimal={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </>
                                    ) : null}

                                    {value?.is_unloading_required && from == "offer" ? (
                                        <>
                                            <Grid item xs={12}>
                                                <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                                    <div className="label">
                                                        Unloading Price / Item (Without GST)
                                                    </div>
                                                    <div className="field">
                                                        <NewInput
                                                            onChange={(e) => {
                                                                onChangePrice(
                                                                    index,
                                                                    e.target.value,
                                                                    "unloading_price",
                                                                );
                                                            }}
                                                            value={value?.unloading_price > 0 ? value?.unloading_price : ""}
                                                            showEnd={"PKR"}
                                                            decimal={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </>
                                    ) : null}
                                </>
                            ) : null}

                            {from == "offer" ? (
                                <>
                                    <Grid item xs={12}>
                                        <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                            <div className="label">
                                                Total Price (Without GST)
                                            </div>
                                            <div className="field">
                                                <NewInput
                                                    value={
                                                        numeral(Number(value?.total_amount || 0)).format("0,0.00")
                                                    }
                                                    showEnd={"PKR"}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ paddingBottom: 0, marginBottom: 0 }}
                                    >
                                        <div className="simpleLabel fw-5">
                                            Other Additional Charges (if applicable)
                                        </div>
                                    </Grid>
                                    <Grid style={{ marginTop: -6 }} item xs={12}>
                                        <div className="textareaWrapper">
                                            <textarea
                                                style={{
                                                    outline: "none",
                                                }}
                                                maxLength={250}
                                                onChange={(e) => {
                                                    onChangePrice(
                                                        index,
                                                        e.target.value,
                                                        "detention_additional_charges",
                                                        true
                                                    );
                                                }}
                                                value={
                                                    value?.detention_additional_charges
                                                        ? value?.detention_additional_charges
                                                        : null
                                                }
                                                id={"cargo_description"}
                                                name={"cargo_description"}
                                                placeholder="Optional"
                                            />
                                        </div>
                                    </Grid>
                                </>
                            ) : null}
                        </Grid>
                    </AccordionDetails>

                </Accordion>

                <CustomModal
                    modalStyle={{
                        width: 300,
                        minHeight: 100,
                    }}
                    showModal={showModal}
                    toggleModal={toggleModal}
                >
                    <div
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <InfoIcon color="primary" style={{ fontSize: 40 }} />
                        <CustomText.OpenSansRegular>{info}</CustomText.OpenSansRegular>
                    </div>
                </CustomModal>
            </Maincontainer>
        </>


    );
};
