import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { InquiryTypeProps } from "./types";
import { CounterAtion, ProfileAction } from "@temp/store/actions";
import rootReducer from "../../../store/reducers";
import TitleLayout from "@temp/components/TitleLayout";
import "./style.scss";
import LoadCard from "@temp/components/LoadCard";
import { Images } from "@temp/assets";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { showMessage } from "@utils/helper";
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<InquiryTypeProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const postLoadMeta = useSelector(
    (state: RootState) => state.Profile.postLoadMeta
  );

  const handleSubmit = (inquiry_type) => {
    dispatch(
      ProfileAction.postLoadData({
        vehicleScreen: null,
        PaymentScreen: null,
        location: [],
        recipients: [],
        ref_no: "",
        responseDeadline: null,
        transaction_type_id: null,
        load_type: null,
        inquiry_type,
      })
    );
    history.push("/load-type");
  };

  const getCardImage = (value: string) => {
    if (value == "Spot Rate") {
      return Images.SpotRate
    } else if (value == "Contract Rate") {
      return Images.ContractRate
    }
  }

  const getImageStyle = (value: string) => {
    if (value == "Spot Rate") {
      return {
        width: '58%',
        marginLeft: -15,
        marginTop: -24,
      }
    } else if (value == "Contract Rate") {
      return {
        width: '100%',
        marginTop: -10,
      }
    }
  }

  const [buttonEnabled, setButtonEnabled] = useState(false);

  const now = new Date();
  const dayOfWeek = now.getDay();
  const hour = now.getHours();

  const btn = () => {

    if (dayOfWeek !== 0 && hour >= 9 && hour < 17) {
      setButtonEnabled(true);

    } else {
      setButtonEnabled(false);
      // alert('addedd')
      // showMessage.current({
      //   message: "addedddd",
      //   status: 'error'
      // })
    }

  };

  return (
    <TitleLayout pageNumber={1} progress={10} title="Inquiry Type">
      <div className="secondaryContainer">
        <div className="inquryTypeBoxWrapper">
          <FadeInComponent>
            <LoadCard
              disabled="true"
              // disabled={!buttonEnabled}
              title="MG Express Quote"
              subtitle="Get Instant Quote from MG Approved"
              img={Images.Express}
              onClick={
                () => {
                  // btn
                  history.replace("/mg-express-quote-screen")
                }
              }

              imgStyle={{
                width: '45%',
                marginTop: -24
              }}
            />
          </FadeInComponent>

          {postLoadMeta.inquiry_types.map((val, index) => {
            return (
              <FadeInComponent key={index}>
                <div style={{ marginBottom: 10 }} >
                  <LoadCard
                    disabled="false"
                    title={val?.title}
                    subtitle={val?.title == "Spot Rate" ? "Request rates for single loads" : "Request Long term rates for multiple loads"}
                    img={getCardImage(val?.title)}
                    onClick={() => {
                      dispatch(ProfileAction.postLoadDataRemove());
                      dispatch(CounterAtion.resetPostloadCounterData())
                      handleSubmit(val.id);
                    }}
                    imgStyle={getImageStyle(val?.title)}
                  />
                </div>
              </FadeInComponent>
            )
          }
          )}
          <FadeInComponent>
            <LoadCard
              disabled="false"
              title="Awarded Contracts"
              subtitle="Request Vehicles under awarded contract rates"
              img={Images.AwardContract}
              onClick={() => {
                history.replace('/dashboard/contract-posted/awarded', { showPostedRecievedTabs: false })
              }}
              imgStyle={{
                width: '50%',
                marginTop: -12
              }}
            />
          </FadeInComponent>
        </div>

      </div>
    </TitleLayout>
  );
};

export default Page;
