
import React from "react";
import ReactAudioPlayer from "react-audio-player";

export const AutioPlayer = ({ url }) => {
    return (
        <ReactAudioPlayer
            style={{
                marginTop: 10
            }}
            src={url}
            autoPlay={false}
            controls
            loop={false}
        />
    )
}