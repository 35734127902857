import * as React from 'react';
import styled from 'styled-components';

type InputSize = 'large' | 'medium' | 'small';
export interface LabelProps
  extends Omit<React.ComponentPropsWithoutRef<'label'>, 'size'> {
  /** if label is disabled or not */
  disabled?: boolean;
  /** label size */
  size?: InputSize;
}

const MediumLabel = styled.label<LabelProps>`
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: block;
  margin-bottom: 5px;
  color: '#000';
`;

const LargeLabel = styled(MediumLabel)`
  height: 18px;
  font-size: 16px;
  line-height: 18px;
`;

const SmallLabel = styled(MediumLabel)`
  font-size: 12px;
  line-height: 14px;
`;

export const Label = ({ size, children, disabled, ...rest }: LabelProps) => {
  const LabelComp =
    size === 'medium'
      ? MediumLabel
      : size === 'large'
      ? LargeLabel
      : SmallLabel;

  return (
    <LabelComp size={size} disabled={disabled} {...rest}>
      {children}
    </LabelComp>
  );
};

export default Label;
