import React, { useEffect, useRef, useState } from "react";
import { IDashBoardPageProps } from "./types";
import "./style.scss";
import { CustomText, ButtomTabs, DrawerNav, CustomModal, OutLineButton, LoadListingStatus } from "@components";
import { Colors, Images } from "@temp/assets";
import { useDispatch, useSelector } from "react-redux";
import {
  CounterAtion,
  LoadAction,
  ProfileAction,
} from "@temp/store/actions";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import { useHistory, useLocation, useParams } from "react-router-dom";
import Radio from "@mui/material//Radio";
import { green } from "@mui/material//colors";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress, MenuItem, Select } from "@mui/material/";
import { AddCircleOutlineOutlined, CheckCircle, Close } from "@mui/icons-material";
import "reactjs-popup/dist/index.css";
// import { withStyles } from "@mui/material//styles";
import { isObjectEmpty, triggerMixpanelEvent } from "@temp/utils/helper";
import moment from "moment";
import {
  convertDataForFclAndBulkLoadDetailRedux,
  convertDataForLoadDetailRedux,
} from "@temp/utils/dataFormat";
import { SELECT_VEHICLE, CONTRACT_DETAILS_URL } from "@routes";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";
import { LOAD_TYPE } from "@temp/constants";
import TouchRipple from "@mui/material//ButtonBase/TouchRipple";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { Button } from "@mui/material/";
import Dialog from "@mui/material//Dialog";
import DialogTitle from "@mui/material//DialogTitle";
import { Mixpanel } from "@temp/utils/mixpanel";
import { log } from "console";

var searchFlag = false
const Page: React.FC<IDashBoardPageProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams()
  const location = useLocation()
  const { state } = location
  // Reducers data 
  const loadType = useSelector((state: RootState) => state.Profile.loadType);
  const inquiryType = useSelector((state: RootState) => state.Profile.inquiryType);
  const activeCategory = useSelector((state: RootState) => state.Profile.activeCategory);
  let loads = useSelector((state: RootState) => state.Load.loads);
  const load_current_page = useSelector((state: RootState) => state.Load.load_current_page);
  const load_last_page = useSelector((state: RootState) => state.Load.load_last_page);
  const postLoadMeta = useSelector((state: RootState) => state?.Profile?.postLoadMeta);
  const packageType = postLoadMeta.package_types;
  const PreferredVehicleType = postLoadMeta?.preferred_vehicles;
  const load_type = postLoadMeta.load_types;
  const inquiry_type = postLoadMeta.inquiry_types;
  // State data
  const [forwardType, setForwardType] = useState("All");
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedValue, setSelectedValue] = useState("All");
  const [showLink, setShowLink] = useState(false);
  const [addRecipients, setAddRecipients] = useState([]);
  const [show_location_modal, set_show_location_modal] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [location_type, set_location_type] = useState(null)
  const [showCancel, setShowCancel] = useState(false)
  const [idDelete, setIdDelete] = useState("")
  
  
  


  useEffect(() => {
    setTimeout(() => {
      getLoads({
        refresh: true,
        inquiryType: params?.type == "spot-posted" || params?.type == "spot-recieved" ? "spot" : "contract",
        activeCategory: params?.tabs,
        loadType: params?.type == 'spot-posted' || params?.type == "contract-posted" ? "Loads Posted" : "Loads Received",
        selectedValue: selectedValue || "All",
        search,
        load_allocate_type:
          forwardType == "All"
            ? "all"
            : forwardType === "Allocated"
              ? "allocated"
              : "forwarded",
      });
    }, 100);
    dispatch(ProfileAction.setDashboardFilters({
      loadType: params?.type == 'spot-posted' || params?.type == "contract-posted" ? "Loads Posted" : "Loads Received",
      inquiryType: params?.type == "spot-posted" || params?.type == "spot-recieved" ? "spot" : "contract",
      activeCategory: params?.tabs,
      selectedValue: selectedValue || "All"
    }))
    return () => {
      searchFlag = false
    }
  }, []);

  const callback = () => {
    getLoads({
      refresh: true,
      inquiryType,
      activeCategory,
      loadType,
      search,
      selectedValue,
      load_allocate_type:
        forwardType == "All"
          ? "all"
          : forwardType === "Allocated"
            ? "allocated"
            : "forwarded",
    });
  };

  const getLoads = (data) => {
    
    dispatch(
      LoadAction.getLoads({
        refresh: data.refresh,
        inquiry_type:
          data.inquiryType === "spot" ? "spot_rate" : "contract_rate",
        status: data.activeCategory,
        load_post_type:
          data.loadType === "Loads Received" ? "received" : "posted",
        search: data?.search ? data?.search : "",
        fromSearch: data?.fromSearch,
        next_page:
          data?.refresh || data?.fromSearch || search
            ? 1
            : Number(load_current_page) + 1,
        load_type:
          data.selectedValue === "All"
            ? "all"
            : data.selectedValue === LOAD_TYPE.fcl
              ? "fcl"
              : data.selectedValue === LOAD_TYPE.bulk
                ? "bulk"
                : data.selectedValue === "Loads with Response"
                  ? "loads_with_response"
                  : "loads_without_response",
        load_allocate_type:
          data?.loadType == "Loads Received" ? data?.load_allocate_type : "",
      })
    );
  };

  const handleChange = (event) => {
    switch (event?.target.value) {
      case "All":
        triggerMixpanelEvent("Load Listing Filter All", "Load Histry Filter All")
      case "Containerized":
        triggerMixpanelEvent("Load Listing Filter Containerized", "Load Histry Filter Containerized")
      case "Non-Containerized":
        triggerMixpanelEvent("Load Listing Filter Non-Containerized", "Load Histry Filter Non-Containerized")
    }
    getLoads({
      refresh: true,
      inquiryType,
      activeCategory,
      loadType,
      selectedValue: event.target.value,
      search,
      load_allocate_type:
        forwardType == "All"
          ? "all"
          : forwardType === "Allocated"
            ? "allocated"
            : "forwarded",
    });
    dispatch(
      ProfileAction.setDashboardFilters({
        loadType,
        inquiryType,
        selectedValue: event.target.value,
        activeCategory
      })
    );
    setSelectedValue(event.target.value);
    setShowModal(false);
  };

  const handleClickCategory = (type: string) => {
    history.replace(`/dashboard/${params.type}/${type}`, { showTabs: true, reports: state?.reports })
    getLoads({
      refresh: true,
      inquiryType,
      activeCategory: type,
      loadType,
      selectedValue,
      search,
      load_allocate_type:
        forwardType == "All"
          ? "all"
          : forwardType === "Allocated"
            ? "allocated"
            : "forwarded",
    });
    dispatch(
      ProfileAction.setDashboardFilters({
        loadType,
        inquiryType,
        selectedValue,
        activeCategory: type,
      })
    );
  };

  const handleChangeForward = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setForwardType(event.target.value as string);
    getLoads({
      refresh: true,
      inquiryType,
      activeCategory,
      loadType,
      selectedValue,
      search,
      load_allocate_type:
        event.target.value == "All"
          ? "all"
          : event.target.value === "Allocated"
            ? "allocated"
            : "forwarded",
    });
  };

  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        getLoads({
          refresh: false,
          inquiryType,
          activeCategory: activeCategory,
          loadType,
          selectedValue,
          load_allocate_type: forwardType,
          search: search,
          fromSearch: true,
        })
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (searchFlag) {
      if (search == "") {
        getLoads({
          refresh: false,
          inquiryType,
          activeCategory: activeCategory,
          loadType,
          selectedValue,
          load_allocate_type: forwardType,
          search: search,
          fromSearch: true,
        })
      }
    }
  }, [search])

  const cloneALoadCallBack = (loadDetail: any) => {
    const callBack = () => {
      if (!isObjectEmpty(loadDetail)) {
        let data = {};
        if (loadDetail.load_type.title === LOAD_TYPE.bulk) {
          data = convertDataForFclAndBulkLoadDetailRedux(
            loadDetail,
            packageType,
            load_type,
            inquiry_type,
            PreferredVehicleType,
            "clone",
            null
          );
        } else {
          data = convertDataForLoadDetailRedux(
            loadDetail,
            packageType,
            load_type,
            inquiry_type,
            "clone",
            null
          );
        }
        // return 
        if (!isObjectEmpty(data)) {
          dispatch(ProfileAction.postLoadData({ ...data }));
          if (loadDetail.inquiry_type.title === "Spot Rate") {
            history.push(SELECT_VEHICLE);
          } else {
            history.push(CONTRACT_DETAILS_URL);
          }
        }
      }
    }
    dispatch(ProfileAction.JobLoadMeta(callBack));
  };

  const cloneALoad = (id: number) => {
    dispatch(
      LoadAction.getLoadDetail({
        load_id: id,
        cb: (data) => {
          cloneALoadCallBack(data);
        },
      })
    );
  };


  const handleCloneALoad = (event: any, id: number) => {
    event.stopPropagation();
    if (event.target.value === "clone") {
      triggerMixpanelEvent("Post a Load start", "Click on Post a Load button to make a new load")
      dispatch(ProfileAction.postLoadDataRemove());
      dispatch(CounterAtion.resetPostloadCounterData())
      cloneALoad(id);
    }
    else if (event.target.value === "cancel") {
      setIdDelete(id)
      setShowCancel(true)
    }
  };

  const cancelLoad = () => {
    setShowCancel(false);
    dispatch(
      LoadAction.cancelLoadHomeScreen({
        load_id: idDelete,
        cb: callback,
      })
    );
  };

//   const GreenRadio = withStyles({
//     root: {
//       color: green[400],
//       "&$checked": {
//         color: green[600],
//       },
//     },
//     checked: {},
//   })((props) => <Radio color="default" {...props} />);

const handleRefresh = async() => {
  getLoads({
    refresh: true,
    inquiryType,
    activeCategory,
    loadType,
    selectedValue,
    search,
    load_allocate_type:
      forwardType == "All"
        ? "all"
        : forwardType === "Allocated"
          ? "allocated"
          : "forwarded",
  });
}

  const RenderLoads = ({ load }) => {
    
    const rippleRef = useRef(null);
    const onRippleStart = (e) => {
      rippleRef.current.start(e);
    };
    const onRippleStop = (e) => {
      rippleRef.current.stop(e);
    };

    return (
      <>
        {
          // load?.is_mg_express ?
          //   <div>

          //   </div>
          //   :
          <div
            type="button"
            onMouseDown={onRippleStart}
            onMouseUp={onRippleStop}
            onClick={(e) => {
              e.stopPropagation();
              Mixpanel.track("view load detail", {
                name: 'click on view load detail',
                des: 'open load detail from listing.'
              })
              if (loadType == "Loads Received") {
                dispatch(LoadAction.loadDetailEmpty())
                setTimeout(() => {
                  if (load.inquiry_type === "Spot Rate") {
                    triggerMixpanelEvent("View Load Detail", "view load detail from load listing screen")
                    history.push(`/load-details-recipient/${load.id}/received`, {
                      load_id: load.id,
                      ref_no: load?.ref_no,
                    });
                  } else {
                    triggerMixpanelEvent("View Contract Detail", "view contract detail from load listing screen")
                    history.push(`/contract-detail-recepient/${load.id}/received`, {
                      load_id: load.id,
                      ref_no: load?.ref_no,
                    });
                  }
                }, 200)
              } else {
                dispatch(LoadAction.loadDetailEmpty())
                setTimeout(() => {
                  if (load?.inquiry_type == "Spot Rate") {
                    triggerMixpanelEvent("View Load Detail", "view load detail from load listing screen")
                    history.push(`/load-details-poster/${load.id}/posted`, {
                      load_id: load.id,
                      ref_no: load?.ref_no,
                      activeTab: activeCategory,
                    });
                  } else {
                    triggerMixpanelEvent("View Contract Detail", "view contract detail from load listing screen")
                    history.push(`/contract-details-poster/${load.id}/posted`, {
                      load_id: load.id,
                      ref_no: load?.ref_no,
                      activeTab: activeCategory,
                    });
                  }
                }, 200)
              }
            }}
            className="sectionBox dashboardSectionBox"
          >
            <div className={loadType == "Load Posted" ? "left-container" : "left-container active"}>
              <div style={{ paddingBottom: 5, marginBottom: '10px' }} className="load-id">

                <div style={{ alignItems: 'center' }} className="loadDetail">
                  <p className="fw-6 LoadIdWrap">
                    {load?.inquiry_type !== "Contract Rate" ? `Load ID - ${load?.id}` : `Contract ID - ${load?.id}`}
                    {
                      loadType == "Loads Received" &&
                      <>
                        <span className="seperator">/</span>
                        <span className="urduLabel">{load?.inquiry_type !== "Contract Rate" ? `لوڈ آئی ڈی - ${load?.id}` : ` معاہدے کی آئی ڈی - ${load?.id}`}</span>
                      </>
                    }
                  </p>
                  <div style={{
                    width: load?.inquiry_type?.id == 2 ? 100 : 100,
                    marginLeft: 4
                  }} className="fcl-tag">
                    <span>{load?.load_type}</span>
                  </div>
                  {load?.status == 4 ?
                    <div style={{
                      width: load?.inquiry_type?.id == 2 ? 100 : 100,
                      marginLeft: 4
                    }} className="fcl-tag">
                      <span>{load?.status_name}</span>
                    </div>
                    : null}
                  {
                    load?.inquiry_type !== "Contract Rate" && load?.total_awarded > 0 ?
                      <div style={{
                        border: "1px solid gray",
                        height: 20,
                        borderRadius: 15,
                        paddingLeft: 5,
                        paddingRight: 5,
                        marginLeft: 5,
                        background: "#fff"
                      }} className="flexRow">
                        <span style={{ color: Colors.Primary, fontSize: "0.675rem", position: "relative", top: "1px" }} className="icons-truck active"></span>
                        <div style={{ fontSize: "0.575rem", marginLeft: 5 }} className="fw-5">
                          {load?.counter}
                        </div>
                      </div>
                      : null
                  }
                  <div className="flexRow">
                    {
                      load?.is_allocated ?
                        <div className="fcl-tag" style={{ padding: "3px 8px" }}>
                          <img
                            src={Images.AllocatedIcon}
                            alt=""
                          />
                        </div>
                        : null
                    }

                    {
                      load?.is_forwarded && !load?.parent_id ?
                        <div className="fcl-tag" style={{ padding: "3px 8px" }}>
                          <img
                            src={Images?.ForwardGreenIcon}
                            alt=""
                          />
                        </div>
                        : null
                    }
                    {
                      load?.parent_id && load?.is_poster ?
                        <div className="fcl-tag">
                          <img
                            src={Images?.ForwardGreenIcon}
                            alt=""
                          />
                          <span style={{ marginLeft: 5 }}>{load?.parent_id}</span>
                        </div> : null

                    }
                  </div>
                </div>



                <div className="" style={{ display: 'flex', alignItems: 'center', marginTop: 0, height: 20 }}>
                  {loadType === "Loads Posted" && (
                    <div className="select-container customSelect noBorderSelect">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="dot-select"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleCloneALoad(e, load.id)}
                        IconComponent={(_props) => (
                          <span className="icons-dots" onClick={(e) => e.stopPropagation()}></span>
                        )}
                        value={''}
                      >
                        <MenuItem value="clone">
                          <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>
                            Clone
                          </CustomText.OpenSansSemiBold>
                        </MenuItem>

                        {
                          load?.total_awarded > 0 ? null :
                            <MenuItem value="cancel">
                              <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>
                                Cancel
                              </CustomText.OpenSansSemiBold>
                            </MenuItem>
                        }
                      </Select>
                    </div>
                  )}
                </div>
              </div>

              {loadType == "Loads Received" ?
                <>
                  <div className="formGrid">
                    <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                      <span className="titleName">{load?.inquiry_type == "Spot Rate" ? "Load From" : "Contract From"}</span>
                      {
                        loadType == "Loads Received" &&
                        <>
                          <span className="seperator">/</span>
                          <span className="urduLabel">{load?.inquiry_type !== "Contract Rate" ? "لوڈ بھجنے والا" : "معاہدہ بھجنے والا"}</span>
                        </>
                      }
                    </div>
                    <div className="fw-5">
                      <span style={{ textTransform: "capitalize" }}>{`${load?.poster} `}</span>
                    </div>
                  </div>

                  {load?.poster_system_company || load?.poster_profile_company ? (
                    <div className="formGrid">
                      <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                        <span className="titleName">Company Name</span>
                        {
                          loadType == "Loads Received" &&
                          <>
                            <span className="seperator">/</span>
                            <span className="urduLabel">کمپنی کا نام</span>
                          </>
                        }
                      </div>
                      <div className="fw-5" style={{ textTransform: "capitalize" }}>
                        {load?.poster_system_company || load?.poster_profile_company}
                      </div>
                    </div>
                  ) : null}
                </> : null
              }

              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                  <span className="titleName">{load?.inquiry_type == "Contract Rate" || load?.contract_id ? "Contract Name" : "Load Name"}</span>
                  {
                    loadType == "Loads Received" &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">{load?.inquiry_type == "Contract Rate" || load?.contract_id ? "معاہدے کا نام" : "لوڈ کا نام"}</span>
                    </>
                  }
                </div>
                <div className="fw-5">
                  {load?.inquiry_type == "Contract Rate" || load?.contract_id ? load?.contract_name : load?.title}
                </div>
              </div>
              
              {
                load?.contract_id ?
                  <div className="formGrid">
                    <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                      <span className="titleName">{"Load Name"}</span>
                      {
                        loadType == "Loads Received" &&
                        <>
                          <span className="seperator">/</span>
                          <span className="urduLabel">{"لوڈ کا نام"}</span>
                        </>
                      }
                    </div>
                    <div className="fw-5">
                      {load?.title}
                    </div>
                  </div>
                  : null}


              <div>
                {loadType === "Loads Received" ?
                  <div className="formGrid">
                    <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                      <span className="titleName">Received Date & Time</span>
                      {
                        loadType == "Loads Received" &&
                        <>
                          <span className="seperator">/</span>
                          <span className="urduLabel">موصول ہونے کی تاریخ اور وقت</span>
                        </>
                      }
                    </div>
                    <div className="fw-5">
                      {`${moment(load?.created_at).format("DD MMM, YYYY hh:mm A")}`}
                    </div>
                  </div>
                  : null}
              </div>

              {/* response deadline  */}
              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                  <span className="titleName">Response Deadline (Date & Time)</span>
                  {
                    loadType == "Loads Received" &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">ریٹ دینے کی معیاد </span>
                    </>
                  }
                </div>
                <div className="fw-5">
                  {`${moment(load?.deadline_at).format("DD MMM, YYYY hh:mm A")}`}
                </div>
              </div>

              {/* pickup locations  */}
              <div className="formGrid">
                <div className="grayLabel dBox" style={{ marginBottom: '0.25rem', display: 'inline-block' }}>
                  <span className="titleName">Pick-up</span>
                  {
                    loadType == "Loads Received" &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">پک اپ</span>
                    </>
                  }
                </div>

                {<div className="fw-5 justifyBetween">
                  <div className="fw-5">
                    {
                      load?.pickups?.length > 1
                        ? "Multiple " : load?.pickups[0]
                    }
                  </div>
                  <div className="recepient-container">
                    {load?.pickups?.length > 1 ?
                      <>
                        <div
                          className="smallBlueLabel"
                          onClick={(e) => {
                            e.stopPropagation()
                            setAllLocations(load?.pickups)
                            set_location_type({
                              type: 'pickup',
                              load_type: load.inquiry_type
                            })
                            set_show_location_modal(true)
                          }}
                        >
                          See All
                        </div>
                      </> : null}
                  </div>
                </div>
                }
              </div>

              {/* dropoff locations  */}
              <div className="formGrid">
                <div className="grayLabel dBox" style={{ marginBottom: '0.25rem', display: 'inline-block' }}>
                  <span className="titleName">Drop-off</span>
                  {
                    loadType == "Loads Received" &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">ڈراپ-آف</span>
                    </>
                  }
                </div>
                <div className="fw-5 justifyBetween">
                  <div className="fw-5">
                    {
                      load?.dropoffs?.length > 1
                        ? "Multiple" : load?.dropoffs[0]
                    }
                  </div>
                  <div className="recepient-container">
                    {load?.dropoffs?.length > 1 ?
                      <>
                        <div
                          className="smallBlueLabel"
                          onClick={(e) => {
                            e.stopPropagation()
                            setAllLocations(load?.dropoffs)
                            set_location_type({
                              type: 'dropoff',
                              load_type: load.inquiry_type
                            })
                            set_show_location_modal(true)
                          }}
                        >
                          See All
                        </div>
                      </> : null}
                  </div>
                </div>
              </div>
            </div>
            {/* three dot menu for clone and cancel*/}
            <div>
              {
                loadType == "Load Posted" && (
                  <div className="right-container"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPopUp(true);
                    }}
                  >
                    <img alt="" style={{ height: 16 }} src={Images.iconMenu} />
                  </div>
                )
              }
            </div>
            <div className="textBtn">OPEN</div>
            <TouchRipple ref={rippleRef} center={false} />
          </div>
        }
      </>
    );
  };

  const getActiveClass = (value) => {
    const type = loadType === "Loads Posted"
      ? "two-category-button"
      : "three-category-button";
    const active = activeCategory === value ? ' active' : ''
    return `${type} ${active}`;
  }


  return (
    <div className="root-center-container">
      {/* dropdwon load posted, load received */}
      <div className="loads-top-wrappers">
        <div className="topDropDownWrap">
          <div className="drop-down" style={{ justifyContent: 'space-between' }}>
            {
              state?.reports ?
                <button onClick={() => {
                  history.goBack()
                }} className="header-buttons">
                  <img
                    src={Images.BackArrow}
                    alt="Back"
                  />
                </button> :
                <DrawerNav />
            }
            <p className="title">
              {history?.location?.pathname == "/dashboard/contract-posted/awarded" ||
                history?.location?.pathname == "/dashboard/contract-recieved/awarded" ?
                "Awarded Contracts" :
                params.type == "contract-recieved" ?
                  "Contract Received" :
                  params.type == "contract-posted" ?
                    "Contract Posted" :
                    params.type == "spot-posted" ?
                      "Spot Posted" :
                      params.type == "spot-recieved" ?
                        "Spot Received" : ""}
            </p>
            <div></div>
          </div>
        </div>

        {/* Top Tabs spot contract  */}

        <div className="tabContainerMainWrap">
          <FadeInComponent>
            <div className={`searchContainer ${search ? "filled" : ""}`} style={{ marginBottom: '1rem' }}>
              <input
                className="searchInput"
                onChange={(e) => {
                  setSearch(e.target.value)
                  searchFlag = true
                }}
                type="text"
                autoComplete="off"
                name="search"
                style={{ width: "100%" }}
                value={search}
                placeholder="Search by name or id"
              />
              <span className={`icons-search ${search ? '' : 'active'}`}></span>
              <span
                className={`icons-cross ${search ? 'active' : ''}`}
                onClick={() => {
                  setSearch("")
                  // searchFlag = false
                }}
              ></span>
            </div>
          </FadeInComponent>

          {/* Tabs new, open, awarded  */}
          {(params?.type == "spot-posted" || params.type == "spot-recieved") && (
            <FadeInComponent>
              <div className={`tab-btn radioTabs ${loadType === "Loads Received" ? 'threeTab' : 'twoTab'}`}>
                {loadType === "Loads Received" && (
                  <div
                    onClick={() => {
                      handleClickCategory("new");
                      triggerMixpanelEvent("Spot Loads Recieved New Listing", "Click on Spot Loads Recieved New listing")
                    }}
                    className={`three-category-button ${activeCategory === "new" ? 'active' : ''}`}
                  >
                    New
                  </div>
                )}

                <div
                  onClick={() => {
                    handleClickCategory("open");
                    if (loadType === "Loads Received") {
                      triggerMixpanelEvent("Spot Loads Recieved Open Listing", "Click on Spot Loads Recieved Open listing")
                    } else {
                      triggerMixpanelEvent("Spot Loads Posted Open Listing", "Click on Spot Loads Posted Open listing")
                    }
                  }}
                  className={getActiveClass('open')}
                >
                  Open
                </div>


                <div
                  onClick={() => {
                    handleClickCategory("awarded");
                    if (loadType === "Loads Received") {
                      triggerMixpanelEvent("Spot Loads Recieved Awarded Listing", "Click on Spot Loads Recieved Awarded listing")
                    } else {
                      triggerMixpanelEvent("Spot Loads Posted Awarded Listing", "Click on Spot Loads Posted Awarded listing")
                    }
                  }}
                  className={getActiveClass('awarded')}
                >
                  Awarded
                </div>
                <div className="radioTab_slider"></div>
              </div>
            </FadeInComponent>
          )}

          {
            (params?.type == "contract-recieved" && params?.tabs !== "awarded") && (
              <FadeInComponent>
                <div className={`tab-btn radioTabs ${loadType !== "Loads Received" ? 'threeTab' : 'twoTab'}`}>
                  {loadType === "Loads Received" && (
                    <div
                      onClick={() => {
                        handleClickCategory("new");
                        triggerMixpanelEvent("Contract Inquiry Recieved New Listing", "Click on Contract Inquiry Recieved New listing")
                      }}
                      className={`three-category-button ${activeCategory === "new" ? 'active' : ''}`}
                    >
                      New
                    </div>
                  )}

                  <div
                    onClick={() => {
                      handleClickCategory("open");
                      triggerMixpanelEvent("Contract Inquiry Recieved Open Listing", "Click on Contract Inquiry Recieved Open listing")
                    }}
                    className={getActiveClass('open')}
                  >
                    Open
                  </div>
                  <div className="radioTab_slider"></div>
                </div>
              </FadeInComponent>
            )
          }

          {
            state?.showPostedRecievedTabs && (
              <FadeInComponent>
                <div className={`tab-btn radioTabs twoTab mb-3`}>

                  <div
                    onClick={() => {
                      getLoads({
                        refresh: true,
                        inquiryType,
                        activeCategory,
                        loadType: 'Loads Posted',
                        selectedValue,
                        search,
                        load_allocate_type:
                          forwardType == "All"
                            ? "all"
                            : forwardType === "Allocated"
                              ? "allocated"
                              : "forwarded",
                      })
                      dispatch(
                        ProfileAction.setDashboardFilters({
                          loadType: 'Loads Posted',
                          inquiryType,
                          selectedValue,
                          activeCategory,
                        })
                      );
                      triggerMixpanelEvent("Contract Inquiry Posted Awarded Listing", "Click on Contract Inquiry Posted Awarded listing")
                      history.replace('/dashboard/contract-posted/awarded', { showPostedRecievedTabs: true, showTabs: true, reports: state?.reports })

                    }}
                    className={`two-category-button ${params.type == "contract-posted" ? "active" : ''}`}
                  >
                    Contract Posted
                  </div>

                  <div
                    onClick={() => {
                      getLoads({
                        refresh: true,
                        inquiryType,
                        activeCategory,
                        loadType: 'Loads Received',
                        selectedValue,
                        search,
                        load_allocate_type:
                          forwardType == "All"
                            ? "all"
                            : forwardType === "Allocated"
                              ? "allocated"
                              : "forwarded",
                      })
                      dispatch(
                        ProfileAction.setDashboardFilters({
                          loadType: 'Loads Received',
                          inquiryType,
                          selectedValue,
                          activeCategory,
                        })
                      );
                      triggerMixpanelEvent("Contract Inquiry Received Awarded Listing", "Click on Contract Inquiry Received Awarded listing")
                      history.replace('/dashboard/contract-recieved/awarded', { showPostedRecievedTabs: true, showTabs: true, reports: state?.reports })
                    }}
                    className={`two-category-button ${params.type == "contract-recieved" ? "active" : ''}`}
                  >
                    Contract Received
                  </div>
                  <div className="radioTab_slider"></div>
                </div>
              </FadeInComponent>
            )
          }
        </div>

        {/* filter container  */}
        <div className="filterContainerMainWrap">
          <div className="filter-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span className="icons-all" onClick={() => setShowModal(true)}></span>
              <div
                style={{
                  marginLeft: 10,
                  marginTop: 3,
                }}
              >
                <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>
                  {selectedValue}
                </CustomText.OpenSansSemiBold>
              </div>
            </div>
            {loadType === "Loads Received" && (
              <div className="select-container customSelect noBorderSelect">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={forwardType}
                  onChange={handleChangeForward}
                >
                  <MenuItem value="All">
                    <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>
                      All
                    </CustomText.OpenSansSemiBold>
                  </MenuItem>
                  <MenuItem value="Allocated">
                    <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>
                      Allocated
                    </CustomText.OpenSansSemiBold>
                  </MenuItem>
                </Select>
              </div>
            )}
          </div>
        </div>

        <div>
        </div>

      </div>

      {/* Loads maping  */}
      <div style={{
        paddingTop: state?.showTabs ? 160 : 110
      }} className="load-listing-container remCustomPadonMob">
        <div>
          {
            loads.length ?
              <PullToRefresh
                onRefresh={handleRefresh}
              >
                <InfiniteScroll
                  dataLength={loads.length}
                  next={() => {
                    getLoads({
                      refresh: false,
                      inquiryType,
                      search,
                      activeCategory: activeCategory,
                      loadType,
                      selectedValue,
                      load_allocate_type: forwardType,
                    });
                  }}
                  hasMore={load_last_page > load_current_page}
                  loader={<div className="load-more-loader"> <CircularProgress /></div>}
                  style={{ padding: 15 }}
                  height={'calc(100vh - 242px)'}
                  scrollThreshold={0.8}
                >
                  {loads.length > 0 ? loads?.map((load: object, index: number) => {
                    return (
                      <FadeInComponent key={index}>
                        <RenderLoads load={load} />
                      </FadeInComponent>
                    )
                  }) : null}
                </InfiniteScroll>
              </PullToRefresh>
              :
              <div className="network-empty-component">
                <div className="dataPlaceholder">
                  <div className="placeholderContentWrapper">
                    <div className="image">
                      <img src={Images.homeScreenNew} />
                    </div>
                    <p>
                      You have no activity please press on Post a Load button{" "}
                      {" "}
                      to{" "}
                      {inquiryType === "spot"
                        ? "post a new load request."
                        : "post a new contract request."}
                    </p>
                  </div>
                </div>
              </div>
          }
        </div>
      </div>

      <ButtomTabs activeTab="home" />

      {/* FIlter modal  */}
      <div>
        <CustomModal
          showModal={showModal}
          toggleModal={setShowModal}
          modalStyle={{
            width: 316,
          }}
          className={"allSmallModal"}
        >
          <div>
            <div className="filterBy">
              <Radio
                checked={selectedValue === "All"}
                onChange={handleChange}
                value="All"
                name="radio-button-demo"
                inputProps={{ "aria-label": "All" }}
              />

              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginLeft: 6,
                  marginTop: 13,
                }}
              >
                All
                {
                  loadType == "Loads Received" &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">تمام</span>
                  </>
                }
              </CustomText.OpenSansRegular>
            </div>
            <div className="filterBy">
              <Radio
                checked={selectedValue === LOAD_TYPE.fcl}
                onChange={handleChange}
                value={LOAD_TYPE.fcl}
                name="radio-button-demo"
                inputProps={{ "aria-label": LOAD_TYPE.fcl }}
              />

              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginLeft: 6,
                  marginTop: 13,
                }}
              >
                {LOAD_TYPE.fcl}
                {
                  loadType == "Loads Received" &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">کنٹینرائزڈ</span>
                  </>
                }
              </CustomText.OpenSansRegular>
            </div>
            <div className="filterBy">
              <Radio
                checked={selectedValue === LOAD_TYPE.bulk}
                onChange={handleChange}
                value={LOAD_TYPE.bulk}
                name="radio-button-demo"
                inputProps={{ "aria-label": LOAD_TYPE.bulk }}
              />

              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginLeft: 6,
                  marginTop: 13,
                }}
              >
                {LOAD_TYPE.bulk}
                {
                  loadType == "Loads Received" &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">نون-کنٹینرائزڈ</span>
                  </>
                }
              </CustomText.OpenSansRegular>
            </div>
            {loadType !== "Loads Received" && inquiryType == "spot" && (
              <>
                <div className="filterBy">
                  <Radio
                    checked={selectedValue === "Loads with Response"}
                    onChange={handleChange}
                    value="Loads with Response"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "Loads with Response" }}
                  />

                  <CustomText.OpenSansRegular
                    textStyle={{
                      fontSize: "0.875rem",
                      color: Colors.Black,
                      marginLeft: 6,
                      marginTop: 13,
                    }}
                  >
                    Loads with Response
                  </CustomText.OpenSansRegular>
                </div>
                <div className="filterBy">
                  <Radio
                    checked={selectedValue === "Loads without Response"}
                    onChange={handleChange}
                    value={"Loads without Response"}
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "Loads without Response" }}
                  />

                  <CustomText.OpenSansRegular
                    textStyle={{
                      fontSize: "0.875rem",
                      color: Colors.Black,
                      marginLeft: 6,
                      marginTop: 13,
                    }}
                  >
                    Loads without Response
                  </CustomText.OpenSansRegular>
                </div>
              </>
            )}
          </div>
        </CustomModal>
      </div>

      {/* clone and cancel modal  */}
      <div>
        <CustomModal
          showModal={showPopUp}
          toggleModal={setShowPopUp}
          modalStyle={{
            width: 290,
          }}
        >
          <div>
            <div className="more-options-heading">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "1.125rem",
                  color: Colors.Black,
                }}
              >
                More Options
              </CustomText.OpenSansSemiBold>
              <Close onClick={() => setShowPopUp(false)} />
            </div>
            <div
              onClick={() => {
                setShowPopUp(false);
              }}
              className="more-options"
            >
              <img src={Images.clone} className="popup-img" alt="" />
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginTop: 18,
                  marginLeft: 10,
                }}
              >
                Clone
              </CustomText.OpenSansRegular>
            </div>
            <div
              onClick={() => {
                setShowPopUp(false);
              }}
              className="more-options"
            >
              <img src={Images.removeLoad} className="popup-img" alt="" />
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginTop: 18,
                  marginLeft: 10,
                }}
              >
                Remove Load
              </CustomText.OpenSansRegular>
            </div>
          </div>
        </CustomModal>
      </div>

      {/* see link modal */}
      <div >
        <CustomModal
          showModal={showLink}
          toggleModal={setShowLink}
          modalStyle={{
            width: 290,
          }}
        >
          <div>
            <div className="more-options-heading">
              <CustomText.OpenSansBold
                textStyle={{
                  fontSize: "1.125rem",
                  color: Colors.Black,
                }}
              >
                Awarded Recipients
              </CustomText.OpenSansBold>
              <Close onClick={() => setShowLink(false)} />
            </div>

            {addRecipients?.map((value, index) => {
              return (
                <div key={index} style={{ marginTop: 24, marginLeft: 14 }}>
                  {value ? value : ''}
                </div>
              )
            })}
          </div>
        </CustomModal>
      </div>

      {/* modal for all locations */}
      <div className="allLocationModal">
        <CustomModal
          showModal={show_location_modal}
          toggleModal={set_show_location_modal}
          modalStyle={{ width: 300, minHeight: "unset", alignItems: "unset" }}
        >
          <div>
            <div className="more-options-heading">
              <CustomText.OpenSansBold
                textStyle={{
                  fontSize: "1.125rem",
                  color: Colors.Black,
                }}
              >
                {
                  location_type?.type == 'pickup' ? "Pickup Locations" : "Dropoff Locations"
                }
                {
                  loadType == "Loads Received" &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">
                      {
                        location_type?.type == 'pickup' ? "پک اپ مقامات" : "ڈراپ آف مقامات"
                      }
                    </span>
                  </>
                }
              </CustomText.OpenSansBold>
              <Close onClick={() => set_show_location_modal(false)} />
            </div>

            {
              allLocations?.map((value, index) => {
                return (
                  <div key={index} style={{ marginTop: 24, marginLeft: 14 }}>
                    {location_type?.load_type == 'Spot Rate' ? ` ${index + 1} - ${value}` : `${value}`}
                  </div>
                )
              })
            }
          </div>
        </CustomModal>
      </div>

      {/* cancel modal */}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgb(241,241,241)",
            color: "black",
            borderRadius: 20,
            width: 286,
          },
        }}
        onClose={() => {
          setShowCancel(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={showCancel}
      >
        <div className="logout-modal-background">
          <DialogTitle id="simple-dialog-title">
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "1.25rem", textAlign: "center" }}
            >
              Cancel
            </CustomText.OpenSansSemiBold>
            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginTop: 20,
                width: 220
              }}
            >
              {inquiryType == "spot" ? "Are you sure you want to cancel this Spot Load?" :
                "Are you sure you want to cancel this Contract?"
              }
            </CustomText.OpenSansRegular>
            <div className="action-button-container">
              <Button
                onClick={() => {
                  setShowCancel(false);
                }}
                style={{
                  backgroundColor: Colors.Grayish,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  No
                </CustomText.OpenSansSemiBold>
              </Button>
              <Button
                onClick={() => {
                  cancelLoad()
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  Yes
                </CustomText.OpenSansSemiBold>
              </Button>
            </div>
          </DialogTitle>
        </div>
      </Dialog>
    </div>
  );

};
export default Page;

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );
  return debouncedValue;
}