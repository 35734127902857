import React from "react";
// import { withStyles } from "@mui/material//styles";
import { IProps } from "./types";
import Switch from "@mui/material//Switch";

// const PurpleSwitch = withStyles({
//   checked: {},
//   track: {},
// })(Switch);

export default function CustomizedSwitches(props: IProps) {
  return (
    <Switch
      checked={props?.checked}
      onChange={props?.handleChange}
      name={props?.name}
      id={props?.id}
    />
  );
}
