import React, { useEffect } from "react";
import { Grid } from "@mui/material/";
import CustomText from "../CustomText";
import { phoneNumberFormater, showMessage } from "@temp/utils/helper";
import { isBrowser } from "react-device-detect";
import { Images } from "@temp/assets";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../utils/mobileEventHandler";
import { fromPairs } from "lodash";

export const AdditionalPickupDropoff: React.FC<any> = ({
  additional_pickup,
  additional_dropoffs,
  setDataPickup,
  dataPickup,
  setDataDoropoff,
  dataDropoff,
  setShowModal,
  type,
  recipient_status,
  is_recipient = false,
  is_viewer,
  fromActive,
  fromHistory = false,
}: any) => {

  return (
    <>
      {
        additional_pickup?.length ?
          <Grid item xs={12} className="formGrid">
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <p className="grayLabel">
                <span className="titleName">Additional Pickups</span>
                {
                  fromHistory && is_recipient &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">اضافی  پک اپ</span>
                  </>
                }
              </p>

            </div>
            {
              additional_pickup?.map((item, index) => {
                return (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}>
                    <div style={{
                      width: "8%",
                      marginTop: 5
                    }}>
                      <div className="load-detail-dots-container">
                        <div style={{
                          height: 12,
                          width: 12,
                          borderRadius: 6
                        }} className="load-detail-location-start">
                          <div style={{
                            height: 8,
                            width: 8,
                            borderRadius: 4
                          }} className="load-detail-location-start-inner"></div>
                        </div>
                      </div>
                    </div>
                    <div key={index} className="additionalBoxDetail" style={{ marginTop: 5, marginBottom: 0, width: "92%" }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                        <CustomText.OpenSansSemiBold>
                          {`${index + 2} -`} {item?.title}
                        </CustomText.OpenSansSemiBold>
                        {
                          type == 'posted' ? (
                            <div>
                              <span style={{
                                cursor: 'pointer'
                              }} onClick={() => {
                                setDataPickup({
                                  ...dataPickup,
                                  id: item?.id,
                                  address_disable: item?.raw_address ? true : false,
                                  raw_address: item?.raw_address,
                                  contact_name: item?.contact_name,
                                  contact_phone_no: item?.contact_phone_no,
                                  lat: item?.lat,
                                  long: item?.long
                                });
                                setDataDoropoff({
                                  ...dataDropoff,
                                  id: additional_dropoffs[index]?.id,
                                  address_disable: additional_dropoffs[index]?.raw_address ? true : false,
                                  raw_address: additional_dropoffs[index]?.raw_address,
                                  contact_name: additional_dropoffs[index]?.contact_name,
                                  contact_phone_no: additional_dropoffs[index]?.contact_phone_no,
                                  lat: additional_dropoffs[index]?.lat,
                                  long: additional_dropoffs[index]?.long
                                })
                                setShowModal(true)
                              }} className="icons-edit editIcon"></span>
                            </div>
                          ) : null
                        }

                      </div>
                      {
                        // !is_viewer && fromActive !== true ?
                        <>
                          <CustomText.OpenSansRegular>
                            {item?.address}
                          </CustomText.OpenSansRegular>
                          {(type == "posted") || (type == "received" && recipient_status?.status == 4) ?
                            <div style={{ marginTop: 15 }}>
                              {
                                item?.contact_name?.length ? (
                                  <>
                                    <div style={{ width: "75%" }}>
                                      <div className="formGrid">
                                        <div className="grayLabel">
                                          <span className="titleName">Contact Name</span>
                                          {
                                            fromHistory && is_recipient &&
                                            <>
                                              <span className="seperator">/</span>
                                              <span className="urduLabel">رابطے کا نام</span>
                                            </>
                                          }
                                        </div>
                                        <div className="fw-5">
                                          {item?.contact_name}
                                        </div>
                                      </div>
                                    </div>

                                  </>
                                ) : null
                              }

                              {
                                item?.contact_phone_no?.length ? (
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                  }}>
                                    <div style={{ width: "75%" }} className="formGrid">
                                      <div className="grayLabel">
                                        <span className="titleName">Contact Number</span>
                                        {
                                          fromHistory && is_recipient &&
                                          <>
                                            <span className="seperator">/</span>
                                            <span className="urduLabel">رابطہ نمبر</span>
                                          </>
                                        }
                                      </div>
                                      <div className="fw-5">
                                        {
                                          phoneNumberFormater(item?.contact_phone_code, item?.contact_phone_no)
                                        }
                                      </div>
                                    </div>
                                    { (fromActive || is_viewer) &&
                                      <div style={{ width: "25%" }}>
                                        <div className="whatsapp-button-container">
                                          {
                                            isBrowser ?
                                              <>
                                                <a
                                                  href={`tel:92${item?.contact_phone_no}`}
                                                  target={"blank"}
                                                  style={{ marginRight: 18 }}
                                                  className="phone-whatsapp-button"
                                                >
                                                  <img
                                                    alt=""
                                                    style={{ height: "100%" }}
                                                    src={Images.Phone}
                                                  />
                                                </a>
                                                <a
                                                  href={`https://wa.me/+92${item?.contact_phone_no}`}
                                                  target={"blank"}
                                                  className="phone-whatsapp-button"
                                                >
                                                  <img
                                                    alt=""
                                                    style={{ height: "100%" }}
                                                    src={Images.WhatsAppBgGreen}
                                                  />
                                                </a>
                                              </> :
                                              <>
                                                <img
                                                  alt=""
                                                  className="phone-whatsapp-button"
                                                  style={{ height: "100%", marginRight: 18 }}
                                                  src={Images.Phone}
                                                  onClick={() => {
                                                    if (sendEventToAndroid("activeLoadPhone")) {
                                                      window.Android.activeLoadPhone(
                                                        String(item?.contact_phone_no)
                                                      );
                                                    } else if (sendEventToiOS("activeLoadPhone")) {
                                                      window.webkit.messageHandlers.activeLoadPhone.postMessage(
                                                        JSON.stringify({
                                                          phoneNumber: item?.contact_phone_no,
                                                        })
                                                      );
                                                    } else {
                                                    }
                                                  }}
                                                />
                                                <img
                                                  onClick={() => {
                                                    if (sendEventToAndroid("activeLoadWhatsapp")) {
                                                      window.Android.activeLoadWhatsapp(
                                                        String(item?.contact_phone_no)
                                                      );
                                                    } else if (sendEventToiOS("activeLoadWhatsapp")) {
                                                      window.webkit.messageHandlers.activeLoadWhatsapp.postMessage(
                                                        JSON.stringify({
                                                          phoneNumber: item?.contact_phone_no,
                                                        })
                                                      );
                                                    } else {
                                                    }
                                                  }}
                                                  className="phone-whatsapp-button"
                                                  alt=""
                                                  style={{ height: "100%" }}
                                                  src={Images.WhatsAppBgGreen}
                                                />
                                              </>
                                          }
                                        </div>
                                      </div>
                                    }
                                  </div>
                                ) : null
                              }
                            </div> : null
                          }
                        </>
                        // : null
                      }
                    </div>
                  </div>
                )
              })
            }
          </Grid> : null
      }
      {
        additional_dropoffs?.length ?
          <Grid style={{ marginTop: 0 }} item xs={12} className="formGrid">
            <p className="grayLabel">
              <span className="titleName">Additional Dropoffs</span>
              {
               fromHistory &&  is_recipient &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">اضافی ڈراپ آف</span>
                </>
              }
            </p>
            {
              additional_dropoffs?.map((item, index) => {
                return (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}>
                    <div style={{
                      width: "8%",
                      marginTop: 5
                    }}>
                      <div className="load-detail-dots-container">
                        <div style={{
                          height: 12,
                          width: 12,
                          borderRadius: 6
                        }} className="load-detail-location-end">
                          <div style={{
                            height: 8,
                            width: 8,
                            borderRadius: 4
                          }} className="load-detail-location-end-inner"></div>
                        </div>
                      </div>
                    </div>
                    <div key={index} className="additionalBoxDetail" style={{ marginTop: 5, marginBottom: 0, width: '92%' }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                        <CustomText.OpenSansSemiBold>
                          {`${index + 2} -`} {item?.title}
                        </CustomText.OpenSansSemiBold>
                        {
                          type == 'posted' ? (
                            <div>
                              <span style={{
                                cursor: 'pointer'
                              }} onClick={() => {
                                setDataDoropoff({
                                  ...dataPickup,
                                  id: item?.id,
                                  address_disable: item?.raw_address ? true : false,
                                  raw_address: item?.raw_address,
                                  contact_name: item?.contact_name,
                                  contact_phone_no: item?.contact_phone_no,
                                  lat: item?.lat,
                                  long: item?.long

                                });
                                setDataPickup({
                                  ...dataDropoff,
                                  id: additional_pickup[index]?.id,
                                  address_disable: additional_pickup[index]?.raw_address ? true : false,
                                  raw_address: additional_pickup[index]?.raw_address,
                                  contact_name: additional_pickup[index]?.contact_name,
                                  contact_phone_no: additional_pickup[index]?.contact_phone_no,
                                  lat: additional_pickup[index]?.lat,
                                  long: additional_pickup[index]?.long
                                })
                                setShowModal(true)
                              }} className="icons-edit editIcon"></span>
                            </div>
                          ) : null
                        }
                      </div>

                      {
                        // !is_viewer &&  fromActive !== true  ?
                        <>
                          <CustomText.OpenSansRegular>
                            {item?.address}
                          </CustomText.OpenSansRegular>
                          {(type == "posted") || (type == "received" && recipient_status?.status == 4) ?
                            <div style={{ marginTop: 15 }}>
                              {
                                item?.contact_name?.length ? (
                                  <div className="formGrid">
                                    <div className="grayLabel">
                                      <span className="titleName">Contact Name</span>
                                      {
                                        fromHistory && is_recipient &&
                                        <>
                                          <span className="seperator">/</span>
                                          <span className="urduLabel">رابطے کا نام</span>
                                        </>
                                      }
                                    </div>
                                    <div className="fw-5">
                                      {item?.contact_name}
                                    </div>
                                  </div>
                                ) : null
                              }
                              {
                                item?.contact_phone_no?.length ? (
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                  }}>
                                    <div style={{ width: "75%" }} className="formGrid">
                                      <div className="grayLabel">
                                        <span className="titleName">Contact Number</span>
                                        {
                                          fromHistory && is_recipient &&
                                          <>
                                            <span className="seperator">/</span>
                                            <span className="urduLabel">رابطہ نمبر</span>
                                          </>
                                        }
                                      </div>
                                      <div className="fw-5">
                                        {
                                          phoneNumberFormater(item?.contact_phone_code, item?.contact_phone_no)
                                        }
                                      </div>
                                    </div>
                                    {
                                      (fromActive || is_viewer) &&

                                      <div style={{ width: "25%" }}>
                                        <div className="whatsapp-button-container">
                                          {
                                            isBrowser ?
                                              <>
                                                <a
                                                  href={`tel:92${item?.contact_phone_no}`}
                                                  target={"blank"}
                                                  style={{ marginRight: 18 }}
                                                  className="phone-whatsapp-button"
                                                >
                                                  <img
                                                    alt=""
                                                    style={{ height: "100%" }}
                                                    src={Images.Phone}
                                                  />
                                                </a>
                                                <a
                                                  href={`https://wa.me/+92${item?.contact_phone_no}`}
                                                  target={"blank"}
                                                  className="phone-whatsapp-button"
                                                >
                                                  <img
                                                    alt=""
                                                    style={{ height: "100%" }}
                                                    src={Images.WhatsAppBgGreen}
                                                  />
                                                </a>
                                              </> :
                                              <>
                                                <img
                                                  alt=""
                                                  className="phone-whatsapp-button"
                                                  style={{ height: "100%", marginRight: 18 }}
                                                  src={Images.Phone}
                                                  onClick={() => {
                                                    if (sendEventToAndroid("activeLoadPhone")) {
                                                      window.Android.activeLoadPhone(
                                                        String(item?.contact_phone_no)
                                                      );
                                                    } else if (sendEventToiOS("activeLoadPhone")) {
                                                      window.webkit.messageHandlers.activeLoadPhone.postMessage(
                                                        JSON.stringify({
                                                          phoneNumber: item?.contact_phone_no,
                                                        })
                                                      );
                                                    } else {
                                                    }
                                                  }}
                                                />
                                                <img
                                                  onClick={() => {
                                                    if (sendEventToAndroid("activeLoadWhatsapp")) {
                                                      window.Android.activeLoadWhatsapp(
                                                        String(item?.contact_phone_no)
                                                      );
                                                    } else if (sendEventToiOS("activeLoadWhatsapp")) {
                                                      window.webkit.messageHandlers.activeLoadWhatsapp.postMessage(
                                                        JSON.stringify({
                                                          phoneNumber: item?.contact_phone_no,
                                                        })
                                                      );
                                                    } else {
                                                    }
                                                  }}
                                                  className="phone-whatsapp-button"
                                                  alt=""
                                                  style={{ height: "100%" }}
                                                  src={Images.WhatsAppBgGreen}
                                                />
                                              </>

                                          }
                                        </div>
                                      </div>

                                    }
                                  </div>
                                ) : null
                              }
                            </div> : null
                          }
                        </>
                        // : null
                      }
                    </div>
                  </div>
                )
              })
            }
          </Grid> : null
      }
    </>
  )
};
