/* eslint-disable radix */
import React, { useEffect } from "react";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";

import FormControlLabel from "@mui/material//FormControlLabel";
import Checkbox from "@mui/material//Checkbox";
// import { withStyles } from "@mui/material//styles";
import TextField from "@mui/material//TextField";
import { useHistory } from "react-router-dom";
import InputAdornment from "@mui/material//InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import rootReducer from "../../store/reducers";
import "./style.scss";
import { MenuItem, Select } from "@mui/material/";
import CustomText from "../CustomText";
import { Colors } from "@temp/assets";
export type RootState = ReturnType<typeof rootReducer>;

export interface PaymentTermsProps {
  index: string;
}
const TaxContainer = styled.div`
  background-color: #fafafa;
  width: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  // margin: 0 10px 0 10px;
  /* align-items: center; */
  /* justify-content: center; */
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  margin-bottom: 10px;
`;

const DetailsContainer = styled.div`
  // height: 85vh;
  justify-content: space-between;
  max-width: 360px;
  // width: 100%;
  margin: auto;
  padding: 0 15px;
`;

const ButtonContainer = styled.div`
  // height: 15vh;
`;

const StyledDetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 10px;
`;

// const GreenCheckbox = withStyles({
//   root: {
//     color: "#7AC251",
//     "&$checked": {
//       color: "#7AC251",
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);

const Page: React.FC<PaymentTermsProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const postLoadData = useSelector(
    (state: RootState) => state.Profile.postLoad
  );
  React.useEffect(() => {
    if (postLoadData?.PaymentScreen) setState(postLoadData?.PaymentScreen);
  }, []);
  const [isError, setIsError] = React.useState(true);


  const currencies = [];
  for (let i = 1; i <= 60; i++) {
    if (i === 1) {
      currencies.push({
        value: `${i}`,
        label: `${i} Days`,
      });
    }
    if (i % 5 === 0)
      currencies.push({
        value: `${i}`,
        label: `${i} Days`,
      });
  }
  const [state, setState] = React.useState({
   ...props?.stateData
  });

  

  const checkShouldBeHundred = () => {
    const {
      pt_advance_value,
      pt_ondelivery_value,
      pt_credit_percentage,

      pt_is_advance,
      pt_is_ondelivery,
      pt_is_credit,
    } = state;

    if (
      Number(pt_is_advance ? pt_advance_value : 0) +
        Number(pt_is_ondelivery ? pt_ondelivery_value : 0) +
        Number(pt_is_credit ? pt_credit_percentage : 0) ===
      100
    ) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  };
  React.useEffect(() => {
    checkShouldBeHundred();
  }, [state]);

  useEffect(()=>{
    props?.getData({...state,isError})
  },[state])

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    checkShouldBeHundred();
  };
  const handleSubmit = () => {
    dispatch(ProfileAction.postLoadData({ PaymentScreen: state }));

    if (
      postLoadData?.documents?.bill_of_ladings.length ||
      postLoadData?.documents?.other_uploads.length ||
      postLoadData?.documents?.packing_lists.length
    ) {
      history.push("uploadedDocument");
    } else {
      history.push("uploadDoc");
    }
  };
  const handleChangeTextValue = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    checkShouldBeHundred();
  };


  return (
    <div className="paymentTermsWrapper">
      <CustomText.OpenSansSemiBold
        textStyle={{
          fontSize: "0.875rem",
          color: Colors.Black,
        }}
      >
        Payment Terms
      </CustomText.OpenSansSemiBold>
      <StyledDetailsBox>
        <div className="detailBoxRow">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.pt_is_advance}
                onChange={handleChange}
                name="pt_is_advance"
              />
            }
            label="Advance"
          />
          <TextField
            variant="outlined"
            value={state.pt_advance_value}
            name="pt_advance_value"
            disabled={!state.pt_is_advance}
            onChange={(e) => {
              if (e?.target?.value.length <= 3 && e?.target?.value <= 100)
                handleChangeTextValue(e);
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </div>
        <div className="detailBoxRow">
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.pt_is_ondelivery}
                onChange={handleChange}
                name="pt_is_ondelivery"
              />
            }
            label="On Delivery"
          />
          <TextField
            variant="outlined"
            disabled={!state.pt_is_ondelivery}
            value={state.pt_ondelivery_value}
            name={"pt_ondelivery_value"}
            onChange={(e) => {
              if (e?.target?.value.length <= 3 && e?.target?.value <= 100)
                handleChangeTextValue(e);
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </div>
        <div className="detailBoxRow multiSelect">
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.pt_is_credit}
                onChange={handleChange}
                name="pt_is_credit"
              />
            }
            label="Credit"
          />
          <div className="innerColWrapper">
            <TextField
              disabled={!state.pt_is_credit}
              variant="outlined"
              value={state.pt_credit_percentage}
              name={"pt_credit_percentage"}
              onChange={(e) => {
                if (e?.target?.value.length <= 3 && e?.target?.value <= 100)
                  handleChangeTextValue(e);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
            <Select
              id="outlined-select-date-native"
              select
              defaultValue={state?.pt_credit_days}
              name="pt_credit_days"
              onChange={handleChangeTextValue}
            
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="detailBoxRow">
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.is_pt_fuel_card}
                onChange={handleChange}
                name="is_pt_fuel_card"
              />
            }
            label="Fuel Card"
          />
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            disabled={!state.is_pt_fuel_card}
            variant="outlined"
            name={"pt_fuel_card_value"}
            value={state.pt_fuel_card_value}
            onChange={(e) => {
              if (e?.target?.value.length <= 3 && e?.target?.value <= 100)
                handleChangeTextValue(e);
            }}
          />
        </div>
      </StyledDetailsBox>
      <TaxContainer>
        Tax
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={state.is_gst_inclusive}
              onChange={handleChange}
              name="is_gst_inclusive"
            />
          }
          label="Sales Tax Invoice required"
        />
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={state.is_withholding_tax_inclusive}
              onChange={handleChange}
              name="is_withholding_tax_inclusive"
            />
          }
          label="Inclusive of 3% with holding Tax"
        />
      </TaxContainer>
      {isError && (
        <div className="error-container info">
          <div>
            <InfoIcon className="error-icon" />
          </div>
          <div className="error-text">
            Total of Advance, On Delivery and Credit must be equal to 100%.
          </div>
        </div>
      )}
    </div>
  );
};



export default Page;
