import { AuthAction, DrawerAction } from "../actions";

const INITIAL_STATE = {
  activeDrawerTab: "",
  notificationsData: [],
  networkDataCopy: [],
  networkData: [],
  locationsData: [],
  vehicleData: [],
  loadDraftData: [],
  addNetworkData: [],
  searchNetworkData: [],
  editVehicleData: [],
  deleteVehicleData: [],
  account_balance: [],
  account_data_current_page: 1,
  account_data_last_page: 1,
  balance: "",
  deleteNetworkData: [],
  isLoading: false,
  faqs: [],
  promotions: [],
  promotionDetail: null,
  notifications_data_current_page: 1,
  notifications_data_last_page: 1,
};

function Reducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    // get network

    case DrawerAction.ADD_OTP_NETWORK:
      return { ...state, isLoading: true };
    case DrawerAction.ADD_OTP_NETWORK_SUCCESS:
      return { ...state, isLoading: false };
    case DrawerAction.ADD_OTP_NETWORK_FAIL:
      return { ...state, isLoading: false };

    case AuthAction.TOGGLE_LOADER:
      return { ...state, isLoading: false };

    case DrawerAction.DELETE_LOCATION[0]:
      return { ...state, isLoading: true };
    case DrawerAction.DELETE_LOCATION[1]:
      return {
        ...state,
        isLoading: false,
      };
    case DrawerAction.DELETE_LOCATION[2]:
      return { ...state, isLoading: false };

    case DrawerAction.ADD_LOCATION[0]:
      return { ...state, isLoading: true };
    case DrawerAction.ADD_LOCATION[1]:
      return {
        ...state,
        isLoading: false,
      };
    case DrawerAction.ADD_LOCATION[2]:
      return { ...state, isLoading: false };

    case DrawerAction.GET_NETWORK:
      return { ...state, isLoading: true, searchNetworkData: [] };
    case DrawerAction.GET_NETWORK_SUCCESS:
      return {
        ...state,
        networkData: action?.payload || [],
        networkDataCopy: action?.payload || [],
        isLoading: false,
      };
    case DrawerAction.GET_NETWORK_FAIL:
      return { ...state, isLoading: false };

    case DrawerAction.GET_ACCOUNT_BALANCE:
      return {
        ...state,
        account_balance: action?.payload?.refresh
          ? []
          : [...state.account_balance],
        isLoading: action?.payload?.refresh,
      };
    case DrawerAction.GET_ACCOUNT_BALANCE_SUCCESS:
      return {
        ...state,
        account_balance: [
          ...state?.account_balance,
          ...action?.payload?.invoices?.data,
        ],
        account_balance_current_page:
          action?.payload?.invoices?.meta.current_page,
        account_balance_last_page: action?.payload?.invoices?.meta.last_page,
        balance: action?.payload?.balance,
        isLoading: false,
      };
    case DrawerAction.GET_ACCOUNT_BALANCE_FAIL:
      return { ...state, isLoading: false };

    // get saved locations

    case DrawerAction.GET_SAVED_LOCATIONS:
      return { ...state, isLoading: true };
    case DrawerAction.GET_SAVED_LOCATIONS_SUCCESS:
      return { ...state, locationsData: action.payload, isLoading: false };
    case DrawerAction.GET_SAVED_LOCATIONS_FAIL:
      return { ...state, isLoading: false };

    // get vehicle selector

    case DrawerAction.GET_VEHICLE_SELECTOR:
      return { ...state, isLoading: true };
    case DrawerAction.GET_VEHICLE_SELECTOR_SUCCESS:
      return { ...state, vehicleData: action.payload, isLoading: false };
    case DrawerAction.GET_VEHICLE_SELECTOR_FAIL:
      return { ...state, isLoading: false };

    //get notifications

    case DrawerAction.GET_NOTIFICATIONS:
      return {
        ...state,
        notificationsData:
          action?.payload?.refresh || action?.payload?.fromSearch
            ? []
            : [...state.notificationsData],
        isLoading: action?.payload?.refresh,
        // isLoading: true 
      };
    case DrawerAction.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsData: [...state?.notificationsData, ...action?.payload?.data],
        notifications_data_current_page: action?.payload?.meta?.current_page,
        notifications_data_last_page: action?.payload?.meta?.last_page,
        isLoading: false

        // new added
        // notificationsData: [...state?.notificationsData, ...action?.payload?.data],
        // notifications_data_current_page: action?.payload?.query
        //   ? 1
        //   : action?.payload?.meta.current_page,
        // notifications_data_last_page: action?.payload?.query
        //   ? 1
        //   : action?.payload?.meta.last_page,
        // isLoading: false
      };
    case DrawerAction.GET_NOTIFICATIONS_FAIL:
      return { ...state, isLoading: false };

    //get load draft

    case DrawerAction.GET_LOAD_DRAFT:
      return { ...state, isLoading: true };
    case DrawerAction.GET_LOAD_DRAFT_SUCCESS:
      return {
        ...state,
        loadDraftData: action.payload?.loads?.data,
        isLoading: false,
      };
    case DrawerAction.GET_LOAD_DRAFT_FAIL:
      return { ...state, isLoading: false };

    case DrawerAction.ADD_NETWORK:
      return { ...state, isLoading: true };
    case DrawerAction.ADD_NETWORK_SUCCESS:
      return { ...state, isLoading: false };
    case DrawerAction.ADD_NETWORK_FAIL:
      return { ...state, isLoading: false };

    case DrawerAction.GET_FAQS:
      return { ...state, isLoading: true };
    case DrawerAction.GET_FAQS_SUCCESS:
      return { ...state, isLoading: false, faqs: action.payload };
    case DrawerAction.GET_FAQS_FAIL:
      return { ...state, isLoading: false };

    case DrawerAction.GET_PROMOTIONS:
      return { ...state, isLoading: true };
    case DrawerAction.GET_PROMOTIONS_SUCCESS:
      return { ...state, isLoading: false, promotions: action.payload };
    case DrawerAction.GET_PROMOTIONS_FAIL:
      return { ...state, isLoading: false };

    case DrawerAction.GET_PROMOTION_DETAIL:
      return { ...state, isLoading: true };
    case DrawerAction.GET_PROMOTION_DETAIL_SUCCESS:
      return { ...state, isLoading: false, promotionDetail: action.payload };
    case DrawerAction.GET_PROMOTION_DETAIL_FAIL:
      return { ...state, isLoading: false };

    case DrawerAction.SEARCH_NETWORK:
      return { ...state, isLoading: true };
    case DrawerAction.SEARCH_NETWORK_SUCCESS:
      return { ...state, searchNetworkData: action.payload, isLoading: false };
    case DrawerAction.SEARCH_NETWORK_FAIL:
      return { ...state, isLoading: false, searchNetworkData: [] };

    case DrawerAction.ADD_VEHICLE_SELECTOR:
      return { ...state, isLoading: true };
    case DrawerAction.ADD_VEHICLE_SELECTOR_SUCCESS:
      return { ...state, isLoading: false };
    case DrawerAction.ADD_VEHICLE_SELECTOR_FAIL:
      return { ...state, isLoading: false };

    case DrawerAction.SEARCH_MY_NETWORK:
      let new_data = [...state.networkDataCopy];
      let final_data = new_data.filter(
        (item) =>
          item?.networkUser?.name
            .toLowerCase()
            .includes(action.payload.toLowerCase()) ||
          item?.networkUser?.phone_no.includes(action.payload?.replace(/^0+/, "")) ||
          (item?.networkUser?.company &&
            item?.networkUser?.company.length &&
            item?.networkUser?.company[0].name
              .toLowerCase()
              .includes(action.payload.toLowerCase())) || (item?.networkUser?.company_name?.toLowerCase().includes(action?.payload?.toLowerCase()))
      );
      return { ...state, networkData: final_data };

    //edit vehicle data

    case DrawerAction.EDIT_VEHICLE_SELECTOR:
      return { ...state, isLoading: true };
    case DrawerAction.EDIT_VEHICLE_SELECTOR_SUCCESS:
      return { ...state, isLoading: false };
    case DrawerAction.EDIT_VEHICLE_SELECTOR_FAIL:
      return { ...state, isLoading: false };

    // delete vehicle data
    case DrawerAction.DELETE_VEHICLE_SELECTOR:
      return { ...state, isLoading: true };
    case DrawerAction.DELETE_VEHICLE_SELECTOR_SUCCESS:
      return { ...state, isLoading: false };
    case DrawerAction.DELETE_VEHICLE_SELECTOR_FAIL:
      return { ...state, isLoading: false };

    // delete network
    case DrawerAction.DELETE_NETWORK:
      return { ...state, isLoading: true };
    case DrawerAction.DELETE_NETWORK_SUCCESS:
      return { ...state, isLoading: false };
    case DrawerAction.DELETE_NETWORK_FAIL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
export default Reducer;
