import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { AuthAction, LoadAction } from "../actions";
var INITIAL_STATE = {
  isLoading: false,
  loads: [],
  load_current_page: 1,
  load_last_page: 1,
  loadDetail: null,
  load_history: [],
  load_history_current_page: 1,
  load_history_last_page: 1,
  allocate_job: "",
  forwardLoadData: null,
  forwardLoadDataCopy: null,
  requestVehicleData: null,
  requestVehicleDataCopy: null,
  activeLoadDetail: null,
  selectedCategries: [],
  activeLoading: false,
  vehiclePinPointMarker: [],
  vehicleMarker: []
};
function Reducer() {
  var _action$payload, _action$payload2, _action$payload3, _action$payload4, _action$payload5, _action$payload6, _action$payload7, _action$payload8, _action$payload9, _action$payload10, _action$payload11, _action$payload12, _action$payload13, _action$payload14, _action$payload15, _action$payload16;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case AuthAction.TOGGLE_LOADER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.CREATE_FROM_DRAFT[0]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.SET_REQUEST_VEHICLE_CATEGORY:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedCategries: action === null || action === void 0 ? void 0 : action.payload
      });
    case LoadAction.CREATE_FROM_DRAFT[1]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.CREATE_FROM_DRAFT[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.GET_LOADS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loads: action !== null && action !== void 0 && (_action$payload = action.payload) !== null && _action$payload !== void 0 && _action$payload.refresh || action !== null && action !== void 0 && (_action$payload2 = action.payload) !== null && _action$payload2 !== void 0 && _action$payload2.fromSearch ? [] : _toConsumableArray(state.loads),
        isLoading: action === null || action === void 0 ? void 0 : (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.refresh
      });
    case LoadAction.GET_LOADS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loads: action !== null && action !== void 0 && (_action$payload4 = action.payload) !== null && _action$payload4 !== void 0 && _action$payload4.fromNoti ? action === null || action === void 0 ? void 0 : (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data : [].concat(_toConsumableArray(state === null || state === void 0 ? void 0 : state.loads), _toConsumableArray(action === null || action === void 0 ? void 0 : (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.data)),
        load_current_page: action !== null && action !== void 0 && (_action$payload7 = action.payload) !== null && _action$payload7 !== void 0 && _action$payload7.search ? 1 : action === null || action === void 0 ? void 0 : (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.meta.current_page,
        load_last_page: action !== null && action !== void 0 && (_action$payload9 = action.payload) !== null && _action$payload9 !== void 0 && _action$payload9.search ? 1 : action === null || action === void 0 ? void 0 : (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.meta.last_page,
        isLoading: false
      });
    case LoadAction.GET_LOADS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // get load detail
    case LoadAction.GET_LOAD_DETAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true,
        loadDetail: null
      });
    case LoadAction.GET_LOAD_DETAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadDetail: action === null || action === void 0 ? void 0 : action.payload,
        isLoading: false
      });
    case LoadAction.GET_LOAD_DETAIL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // award job
    case LoadAction.AWARD_JOB:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.AWARD_JOB_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.AWARD_JOB_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // cancel
    case LoadAction.CANCEL_LOAD:
      return _objectSpread({}, state);
    case LoadAction.CANCEL_LOAD_SUCCESS:
      return _objectSpread({}, state);
    case LoadAction.CANCEL_LOAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // recepient decline
    case LoadAction.RECIPIENT_DECLINED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.RECIPIENT_DECLINED_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.RECIPIENT_DECLINED_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // poster decline
    case LoadAction.POSTER_DECLINED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.POSTER_DECLINED_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.POSTER_DECLINED_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // accept load
    case LoadAction.ACCEPT_LOAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.ACCEPT_LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ACCEPT_LOAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.RESET_STORE:
      return INITIAL_STATE;

    //load history

    case LoadAction.GET_LOADS_HISTORY:
      return _objectSpread(_objectSpread({}, state), {}, {
        // load_history: action?.payload?.refresh ? [] : [...state.load_history],
        load_history: action !== null && action !== void 0 && (_action$payload11 = action.payload) !== null && _action$payload11 !== void 0 && _action$payload11.refresh || action !== null && action !== void 0 && (_action$payload12 = action.payload) !== null && _action$payload12 !== void 0 && _action$payload12.fromSearch ? [] : _toConsumableArray(state.load_history),
        // isLoading: action?.payload?.refresh,
        isLoading: action === null || action === void 0 ? void 0 : (_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : _action$payload13.refresh
      });
    case LoadAction.GET_LOADS_HISTORY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        load_history: [].concat(_toConsumableArray(state === null || state === void 0 ? void 0 : state.load_history), _toConsumableArray(action === null || action === void 0 ? void 0 : (_action$payload14 = action.payload) === null || _action$payload14 === void 0 ? void 0 : _action$payload14.data)),
        load_history_current_page: action === null || action === void 0 ? void 0 : (_action$payload15 = action.payload) === null || _action$payload15 === void 0 ? void 0 : _action$payload15.meta.current_page,
        load_history_last_page: action === null || action === void 0 ? void 0 : (_action$payload16 = action.payload) === null || _action$payload16 === void 0 ? void 0 : _action$payload16.meta.last_page,
        isLoading: false
      });
    case LoadAction.GET_LOADS_HISTORY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    //allocate load

    case LoadAction.ALLOCATE_LOAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.ALLOCATE_LOAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ALLOCATE_LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.RESET_STORE:
      return INITIAL_STATE;
    case LoadAction.SET_ALLOCATE_JOB:
      return _objectSpread(_objectSpread({}, state), {}, {
        allocate_job: action === null || action === void 0 ? void 0 : action.payload
      });
    case LoadAction.UPDATE_RESPONSE_DEADLINE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.UPDATE_RESPONSE_DEADLINE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.UPDATE_RESPONSE_DEADLINE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    ////forward load
    case LoadAction.GET_FORWARD_LOAD_DETAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true,
        forwardLoadData: null
      });
    case LoadAction.GET_FORWARD_LOAD_DETAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        forwardLoadData: action === null || action === void 0 ? void 0 : action.payload,
        forwardLoadDataCopy: Object.assign({}, _objectSpread({}, action === null || action === void 0 ? void 0 : action.payload)),
        isLoading: false
      });
    case LoadAction.GET_FORWARD_LOAD_DETAIL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.SET_FORWARD_LOAD_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        forwardLoadData: action === null || action === void 0 ? void 0 : action.payload
      });
    case LoadAction.FORWARD_LOAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.FORWARD_LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.FORWARD_LOAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_DRIVER_AND_REF:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.ADD_DRIVER_AND_REF_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_DRIVER_AND_REF_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // cancel load from home screen
    case LoadAction.CANCEL_LOAD_HOME_SCREEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.CANCEL_LOAD_HOME_SCREEN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.CANCEL_LOAD_HOME_SCREEN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.GET_ACTIVE_LOAD_DETAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeLoading: true,
        activeLoadDetail: null
      });
    case LoadAction.GET_ACTIVE_LOAD_DETAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeLoading: false,
        activeLoadDetail: action === null || action === void 0 ? void 0 : action.payload
      });
    case LoadAction.GET_ACTIVE_LOAD_DETAIL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeLoading: false
      });
    case LoadAction.GET_REQUEST_VEHICLE_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.GET_REQUEST_VEHICLE_DATA_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        requestVehicleData: action === null || action === void 0 ? void 0 : action.payload,
        requestVehicleDataCopy: action === null || action === void 0 ? void 0 : action.payload
      });
    case LoadAction.GET_REQUEST_VEHICLE_DATA_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // contact at pickup and drop off
    case LoadAction.CONTACT_PICK_UP_DROP_OFF:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.CONTACT_PICK_UP_DROP_OFF_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.CONTACT_PICK_UP_DROP_OFF_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.GET_DRIVER_MARKER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.GET_DRIVER_MARKER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.GET_DRIVER_MARKER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.GET_VEHICLE_MARKER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.GET_VEHICLE_MARKER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.GET_VEHICLE_MARKER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        vehicleMarker: action === null || action === void 0 ? void 0 : action.payload,
        isLoading: false
      });
    case LoadAction.PIN_POINTS_FOR_VEHICLE:
      return _objectSpread({}, state);
    case LoadAction.PIN_POINTS_FOR_VEHICLE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        vehiclePinPointMarker: action === null || action === void 0 ? void 0 : action.payload
      });
    case LoadAction.PIN_POINTS_FOR_VEHICLE_FAIL:
      return _objectSpread({}, state);
    case LoadAction.SET_REQUEST_VEHIVLE_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        requestVehicleDataCopy: action === null || action === void 0 ? void 0 : action.payload
      });
    case LoadAction.POST_REQUEST_VEHICLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.POST_REQUEST_VEHICLE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        requestVehicleDataCopy: null,
        requestVehicleData: null
      });
    case LoadAction.POST_REQUEST_VEHICLE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ACCEPT_REQUEST_VEHICLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.ACCEPT_REQUEST_VEHICLE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ACCEPT_REQUEST_VEHICLE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_VIEWER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.ADD_VIEWER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_VIEWER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.REMOVE_VIEWER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.REMOVE_VIEWER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.REMOVE_VIEWER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_REQUIREMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.ADD_REQUIREMENTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_REQUIREMENTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.MARK_AS_COMPLETE_BY_RECIPIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.MARK_AS_COMPLETE_BY_RECIPIENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.MARK_AS_COMPLETE_BY_RECIPIENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.MARK_AS_COMPLETE_BY_POSTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.MARK_AS_COMPLETE_BY_POSTER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.MARK_AS_COMPLETE_BY_POSTER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.NAGOTIATE_BID_POSTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.NAGOTIATE_BID_POSTER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.NAGOTIATE_BID_POSTER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.AWARD_CONTRACT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.AWARD_CONTRACT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.AWARD_CONTRACT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_VEHICLE_LAST_LOCATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.ADD_VEHICLE_LAST_LOCATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_VEHICLE_LAST_LOCATION_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.UPLOAD_FILES_RECIPIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.UPLOAD_FILES_RECIPIENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.UPLOAD_FILES_RECIPIENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.LOAD_DETAIL_EMPTY:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadDetail: null
      });

    // get Load Tracking Credentials
    case LoadAction.HANDLE_TRACKING_CREDENTIALS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.HANDLE_TRACKING_CREDENTIALS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.HANDLE_TRACKING_CREDENTIALS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_BULK_VEHICLES:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.ADD_BULK_VEHICLES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_BULK_VEHICLES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_BULK_VEHICLE_REFS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.ADD_BULK_VEHICLE_REFS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_BULK_VEHICLE_REFS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_TRACKING_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.ADD_TRACKING_ID_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.ADD_TRACKING_ID_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.UPLOAD_DOC_RECIPIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.UPLOAD_DOC_RECIPIENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.UPLOAD_DOC_RECIPIENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.SHOW_INDICATION_DOTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.SHOW_INDICATION_DOTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.SHOW_INDICATION_DOTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.REMOVE_DOCUMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeLoading: true
      });
    case LoadAction.REMOVE_DOCUMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeLoading: false
      });
    case LoadAction.REMOVE_DOCUMENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeLoading: false
      });
    case LoadAction.SHOW_INDICATION_DOTS_VIEWER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.SHOW_INDICATION_DOTS_VIEWER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.SHOW_INDICATION_DOTS_VIEWER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.UPDATE_DRIVER_DETAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case LoadAction.UPDATE_DRIVER_DETAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case LoadAction.UPDATE_DRIVER_DETAIL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    default:
      return state;
  }
}
export default Reducer;