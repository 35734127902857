import { Grid } from "@mui/material/";
import { Images } from "@temp/assets";
import { CustomModal, CustomText, NewInput, PrimaryButton, SimpleInput } from "@temp/components";
import React, { useEffect, useState } from "react";
import "../LoadDetailPoster/style.scss";
import InfoIcon from "@mui/icons-material/Info";
import { RecipientPrices, RecipientStatus, VehicleCounter } from "../components";
import { NeotiateBidAcceptReject } from "../components/NegotiateBidAcceptReject";
import { RespondVehicle } from "./RespondVehicle";
import { LOAD_TYPE } from "@temp/constants";
import CloseIcon from "@mui/icons-material/Close";
import { price_units_transalation, showMessage } from "@temp/utils/helper";
import { RespondWeight } from "./RespondWeight";
var numeral = require("numeral");

export const RespondedStatusWeight = ({
    is_deadline,
    loadDetail,
    is_bid,
    is_negotiate_bid_weight_volume,
    currentIndex,
    setCuttentIndex,
    index,
    respondToLcl,
    setRespondToLcl,
    location,
    respod_bulk_obj,

}) => {
    const [info, setInfo] = useState('')
    const [showModal, toggleModal] = useState(false)



    return (
        <div>
            {
                !is_deadline || is_bid?.status == 4 || is_bid?.status == 2 ?
                    <div>
                        {
                            is_bid && (
                                <div>
                                    <div className="fw-5">
                                        <span className="titleName">Response</span>
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">جواب</span>
                                    </div>
                                    <div className="mb-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <RecipientStatus from={'recipient'} status={is_bid?.status} loadDetail={loadDetail} decline_by={is_bid?.declined_by} is_recipient={true} />
                                        {
                                            is_bid?.status !== 2 ?
                                                <>
                                                    <div className="chip">
                                                        {`${numeral(is_bid?.bid?.price_per_freight).format("0,0.00")} PKR / ${location?.pickup?.attributes?.price_unit}`}
                                                        <div></div>
                                                        <span className="urduLabel">
                                                            {
                                                                `${price_units_transalation[location?.pickup?.attributes?.price_unit]} / ${numeral(is_bid?.bid?.price_per_freight).format("0,0.00")} PKR`
                                                            }
                                                        </span>
                                                    </div>
                                                </> : null
                                        }
                                    </div>
                                </div>

                            )
                        }
                        {
                            is_bid?.status !== 2 &&
                            <RecipientPrices
                                no_of_vehicles={null}
                                no_of_freight={is_bid?.bid?.awarded_freight_ton || is_bid?.bid?.bid_freight_ton}
                                price_per_vehicle={null}
                                price_per_freight={is_bid?.bid?.price_per_freight}
                                loading_price={is_bid?.bid?.loading_price_per_freight || null}
                                unloading_price={is_bid?.bid?.unloading_price_per_freight || null}
                                detention_rate={is_bid?.bid?.bid_detention_rate}
                                is_unit_basis={loadDetail?.is_unit_basis}
                                is_negotiate_bid={is_bid?.bid?.bid_negotiated_amount}
                                from={'contract'}
                                price_unit={location?.pickup?.attributes?.price_unit}
                                detention_tarif_policy={is_bid?.bid?.detention_tarif_policy}
                                bid_detention_additional_charges={is_bid?.bid?.bid_detention_additional_charges}
                            />
                        }
                        <div >
                            {
                                is_negotiate_bid_weight_volume ?
                                    <NeotiateBidAcceptReject
                                        bid={is_negotiate_bid_weight_volume}
                                        loadDetail={loadDetail}
                                        price_unit={location?.pickup?.attributes?.price_unit}
                                    /> : null
                            }
                        </div>
                    </div> :
                    <>
                        {currentIndex.indexOf(index) !== -1 ? (
                            <div>
                                <div
                                    className="titleSection mb-3"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div className="fw-5">
                                        <span className="titleName">Enter Bid</span>
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">بولی درج کریں</span>
                                    </div>
                                    <div
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            let new_data = [...currentIndex];
                                            let newArr = [...respondToLcl]
                                            // if (currentIndex.find(item=> item == index)) {
                                            let findIndex = currentIndex.findIndex(item => item == index)
                                            new_data.splice(findIndex, 1);
                                            newArr.splice(findIndex, 1)
                                            // }
                                            setCuttentIndex(new_data);
                                            setRespondToLcl(newArr)
                                        }}
                                    >
                                        <span className="icons-cross color-danger"></span>
                                    </div>
                                </div>

                                {/* <div className="inputField">
                                    <div className="field">
                                        <NewInput
                                            onChange={(e) => {
                                                let iindex = respondToLcl.findIndex((item) => item?.loc_id == location?.pickup?.id);
                                                let new_data = [...respondToLcl];
                                                if (e.target.value > Number(location?.pickup?.attributes?.total_freight_ton)) {
                                                    showMessage.current({ message: "Fright ton should be less than total fright ton.", status: 'error' })
                                                    new_data[iindex]["bid_freight_ton"] = location?.pickup?.attributes?.total_freight_ton
                                                    setRespondToLcl(new_data);
                                                } else {
                                                    new_data[iindex]["bid_freight_ton"] = e.target.value;
                                                    setRespondToLcl(new_data);
                                                }
                                            }}
                                            value={respod_bulk_obj?.bid_freight_ton ? respod_bulk_obj?.bid_freight_ton : ""}
                                            showEnd={"Ton"}
                                        />

                                    </div>
                                </div> */}

                                {(loadDetail?.quote_by == "weight_volume" &&
                                    location?.pickup.attributes
                                        ?.total_offer_price &&
                                    loadDetail?.transaction_type?.title ==
                                    "Offer") ||
                                    (loadDetail?.transaction_type.title ==
                                        "Bid" && (
                                            <div className="inputField">
                                                <div className="label  divBlockComplete">
                                                    <div className="divBlock" style={{ width: '100%' }}>
                                                        <span className="titleName">Price / {location?.pickup?.attributes?.price_unit} (Without GST)</span>
                                                        <span className="seperator">/</span>
                                                    </div>
                                                    <div className="divBlock">
                                                        <span className="urduLabel">ریٹ / {price_units_transalation[location?.pickup?.attributes?.price_unit]} (GST کے بغیر)</span>
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    {/* <NewInput
                                                        onChange={(e) => {
                                                            let iindex = respondToLcl.findIndex((item) =>item?.loc_id == location?.pickup?.id);
                                                            let new_data = [...respondToLcl];
                                                            new_data[iindex]["price_per_freight"] = e.target.value;
                                                            setRespondToLcl(new_data);
                                                        }}
                                                        value={respod_bulk_obj?.price_per_freight ? respod_bulk_obj?.price_per_freight : ""}
                                                        showEnd={"PKR"}
                                                    /> */}
                                                    <RespondWeight
                                                        my_bid={{ key: "price_per_freight", value: respod_bulk_obj?.price_per_freight, loc_id: location?.pickup?.id }}
                                                        setResponded={setRespondToLcl}
                                                        responded={respondToLcl}
                                                        type={'number'}
                                                        decimal={true}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                {loadDetail?.transaction_type?.title == "Bid" &&
                                    loadDetail?.quote_by == "weight_volume" &&
                                    location?.pickup.attributes?.is_loading_required ? (
                                    <div className="inputField">
                                        <div className="label divBlockComplete">
                                            <div className="divBlock" style={{ width: '100%' }}>
                                                <span className="titleName">Loading Price / {location?.pickup?.attributes?.price_unit} (without GST)</span>
                                                <span className="seperator">/</span>
                                            </div>
                                            <div className="divBlock">
                                                <span className="urduLabel">لوڈنگ ریٹ / {price_units_transalation[location?.pickup?.attributes?.price_unit]} (GST کے بغیر)</span>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <RespondWeight
                                                my_bid={{ key: "loading_price_per_freight", value: respod_bulk_obj?.loading_price_per_freight, loc_id: location?.pickup?.id }}
                                                setResponded={setRespondToLcl}
                                                responded={respondToLcl}
                                                type={'number'}
                                                decimal={true}
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                {loadDetail?.transaction_type.title == "Bid" &&
                                    loadDetail?.quote_by == "weight_volume" &&
                                    location?.pickup.attributes.is_unloading_required ? (
                                    <div className="inputField">
                                        <div className="label divBlockComplete">
                                            <div className="divBlock" style={{ width: '100%' }}>
                                                <span className="titleName">Unloading Price / {location?.pickup?.attributes?.price_unit} (Without GST)</span>
                                                <span className="seperator">/</span>
                                            </div>
                                            <div className="divBlock">
                                                <span className="urduLabel">ان لوڈنگ ریٹ / {price_units_transalation[location?.pickup?.attributes?.price_unit]} (GST کے بغیر)</span>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <RespondWeight
                                                my_bid={{ key: "unloading_price_per_freight", value: respod_bulk_obj?.unloading_price_per_freight, loc_id: location?.pickup?.id }}
                                                setResponded={setRespondToLcl}
                                                responded={respondToLcl}
                                                type={'number'}
                                                decimal={true}
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                { }

                                <div className="inputField">
                                    <div className="label divBlockComplete">
                                        <div className="divBlock" style={{ width: '100%' }}>
                                            <span className="titleName">Detention Rate (without GST)</span>
                                            <span className="seperator">/</span>
                                        </div>
                                        <div className="divBlock">
                                            <span className="urduLabel">ڈیٹنشن ریٹ (GST کے بغیر)</span>
                                        </div>
                                        <InfoIcon onClick={() => {
                                            setInfo('Please enter daily Detention Rates only for applicable vehicle categories.')
                                            toggleModal(true)
                                        }} />
                                    </div>
                                    <div className="field">
                                        <RespondWeight
                                            my_bid={{ key: "bid_detention_rate", value: respod_bulk_obj?.bid_detention_rate, loc_id: location?.pickup?.id }}
                                            setResponded={setRespondToLcl}
                                            responded={respondToLcl}
                                            type={'number'}
                                            placeholder={'Optional'}
                                            decimal={true}

                                        />
                                    </div>
                                </div>

                                <div className="inputField">
                                    <div className="label divBlockComplete">
                                        <div className="divBlock" style={{ width: '100%' }}>
                                            <span className="titleName">Detention Tariff & Policy</span>
                                            <span className="seperator">/</span>
                                        </div>
                                        <div className="divBlock">
                                            <span className="urduLabel">ڈیٹنشن ٹیرف اور پالیسی</span>
                                        </div>

                                    </div>
                                    <div className="textareaWrapper">
                                        <RespondWeight
                                            my_bid={{ key: "detention_tarif_policy", value: respod_bulk_obj?.detention_tarif_policy, loc_id: location?.pickup?.id }}
                                            setResponded={setRespondToLcl}
                                            responded={respondToLcl}
                                            type={'textarea'}
                                            placeholder={'Optional'}

                                        />
                                    </div>
                                </div>

                                {/* other additional charges */}
                                <div className="inputField">
                                    <div className="label divBlockComplete">
                                        <div className="divBlock" style={{ width: '100%' }}>
                                            <span className="titleName">Other Additional Charges (if applicable) </span>
                                            <span className="seperator">/</span>
                                        </div>
                                        <div className="divBlock">
                                            <span className="urduLabel">اضافی چارجز ( اگر لاگو ہوں )</span>
                                        </div>
                                        <InfoIcon onClick={() => {
                                            setInfo('Please enter other charges that are applicable to the request. Where category specific, please identify which categories they apply to.')
                                            toggleModal(true)
                                        }} />
                                    </div>
                                    <div className="textareaWrapper">
                                        <RespondWeight
                                            my_bid={{ key: "bid_detention_additional_charges", value: respod_bulk_obj?.bid_detention_additional_charges, loc_id: location?.pickup?.id }}
                                            setResponded={setRespondToLcl}
                                            responded={respondToLcl}
                                            type={'textarea'}
                                        />
                                    </div>
                                </div>
                                {
                                    is_negotiate_bid_weight_volume ?
                                        <NeotiateBidAcceptReject
                                            bid={is_negotiate_bid_weight_volume}
                                            loadDetail={loadDetail}
                                            price_unit={location?.pickup?.attributes?.price_unit}
                                        /> : null
                                }
                            </div>
                        ) : (
                            <div>
                                {
                                    is_deadline ?
                                        <PrimaryButton
                                            buttonStyle={{
                                                height: 44,
                                            }}
                                            title="Respond"
                                            is_recipient={true}
                                            urdutitle={"جواب دیں"}
                                            onClick={() => {
                                                let new_data = [...currentIndex];
                                                let newArr = [...respondToLcl]
                                                new_data.push(index);
                                                newArr.push({
                                                    loc_id: location?.pickup?.id,
                                                    bid_freight_ton: "",
                                                    price_per_freight: "",
                                                    loading_price_per_freight: "",
                                                    loading_price_total_freight: "",
                                                    unloading_price_per_freight: "",
                                                    unloading_price_freight: "",
                                                    loading: location?.pickup?.attributes?.is_loading_required ? true : false,
                                                    unloading: location?.pickup?.attributes?.is_unloading_required ? true : false,
                                                    total_freight_ton: location?.pickup?.attributes?.total_freight_ton,
                                                    bid_detention_rate: '',
                                                    bid_detention_additional_charges: "",
                                                    detention_tarif_policy: ""
                                                });
                                                setCuttentIndex(new_data);
                                                setRespondToLcl(newArr)
                                            }}
                                        /> : null
                                }
                            </div>
                        )}
                    </>
            }

            {/* info modal  */}
            <CustomModal
                modalStyle={{
                    width: 300,
                    minHeight: 100,
                }}
                showModal={showModal}
                toggleModal={toggleModal}
            >
                <div
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <InfoIcon color="primary" style={{ fontSize: 40 }} />
                    <CustomText.OpenSansRegular>{info}</CustomText.OpenSansRegular>
                </div>
            </CustomModal>
        </div>
    )
};
