import { Colors } from "@temp/assets";
import { CustomText } from "@temp/components";
import { getFullTime } from "@temp/utils/helper";
import moment from "moment";
import React from "react";
import '../LoadDetailPoster/style.scss'

export const RecipientStatusContract = ({ recipient, style={}, fromRecipient=false, loadDetail }) => {
  return (
    <div
      className="recipient-status"
      style={{
        backgroundColor:
          recipient?.status == 2 && recipient?.declined_by == "Recipient" || (recipient?.declined_by == "Poster" && fromRecipient)
            ? Colors.Redish
            : recipient?.status == 2 && recipient?.declined_by == "Poster" || recipient?.status == 1
            ? Colors.LightGreenish
            : recipient.status === 0
            ? Colors.Bluish
            : recipient.status == 4
            ? Colors.Yellowish
            : null,
            ...style
      }}
    >
      <CustomText.OpenSansRegular
        textStyle={{
          color:
            recipient?.status == 2 && recipient?.declined_by == "Recipient" || (recipient?.declined_by == "Poster" && fromRecipient)
              ? Colors.Red
              : recipient?.status == 2 && recipient?.declined_by == "Poster" || recipient?.status === 1 || recipient.status == 1
              ? Colors.Primary
              : recipient.status == 0
              ? Colors.NavyBlue
              : recipient?.status == 4
              ? Colors.Orange
              : null,
        }}
      >
        {recipient?.status === 2 && recipient?.declined_by == "Recipient"
          ? "Declined by Recipient"
          : recipient?.status == 2 && recipient?.declined_by  == "Poster" && fromRecipient
          ? "Declined by Poster"
          :recipient?.status == 2 && recipient.declined_by == 'Poster' && !fromRecipient || recipient.status == 1
          ? `${loadDetail?.inquiry_type?.title =="Contract Rate"?'Responded':'Accepted'}`
          : recipient?.status == 0
          ? `${Number(moment(loadDetail?.deadline_at).format('x')) > getFullTime(new Date().getTime())? "Awaiting Response":"No Response"}` 
          : recipient?.status == 4
          ? "Awarded"
          : null}
      </CustomText.OpenSansRegular>
    </div>
  );
};
