import { put, call, delay } from "redux-saga/effects";
import ApiCaller from "@temp/api/api";
import { ResponseGenerator } from "@temp/api/globalTypes";
import DrawerAction from "../actions/DrawerAction";
import LoadActions from "../actions/LoadAction";
import { getItem, showMessage, triggerMixpanelEvent } from "@utils/helper";
import Endpoints from "../endpoints";
import { accountBalanceDummyData } from "@temp/utils/constants";

import { notificationsDummyData } from "@temp/utils/constants";

import { draftLoadDummyData } from "@temp/utils/constants";
import { ProfileAction } from "../actions";

export class DrawerSaga {
  static *GetNetwork(params: any) {
    const { payload } = params;
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_NETWORK_URL({ only_company_users: payload?.only_company_users }),
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response && response?.status === 200) {
      yield put({
        type: DrawerAction.GET_NETWORK_SUCCESS,
        payload: response?.data?.data?.networks?.data || [],
      });
    } else {
      yield put({ type: DrawerAction.GET_NETWORK_FAIL });
    }
  }

  static *GetAccountBalance(params: any) {
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_ACCOUNT_STATEMENT_URL,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response && response?.status === 200) {
      yield put({
        type: DrawerAction.GET_ACCOUNT_BALANCE_SUCCESS,
        payload: {
          invoices: response?.data?.data?.invoices,
          balance: response?.data?.data?.balance,
        },
      });
    } else {
      yield put({ type: DrawerAction.GET_ACCOUNT_BALANCE_FAIL });
    }
  }

  static *GetFaqs(params: any) {
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_FAQS_URL,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response && response?.status === 200) {
      yield put({
        type: DrawerAction.GET_FAQS_SUCCESS,
        payload: response?.data?.data?.faqs
      });
    } else {
      yield put({ type: DrawerAction.GET_FAQS_FAIL });
    }
  }

  static *GetPromotions(params: any) {
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_SAVED_PROMOTIONS,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response && response?.status === 200) {
      yield put({
        type: DrawerAction.GET_PROMOTIONS_SUCCESS,
        payload: response?.data?.data?.promotions?.data
      });
    } else {
      yield put({ type: DrawerAction.GET_PROMOTIONS_FAIL });
    }
  }

  static *GetPromotionDetail(params: any) {
    const token = getItem("token");
    const { payload } = params
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_PROMOTION_DETAIL({ ...payload }),
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response && response?.status === 200) {
      yield put({
        type: DrawerAction.GET_PROMOTION_DETAIL_SUCCESS,
        payload: response?.data?.data?.promotion
      });
    } else {
      yield put({ type: DrawerAction.GET_PROMOTION_DETAIL_FAIL });
    }
  }

  static *GetSavedLocations(params: any) {
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_SAVED_LOCATIONS,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response && response?.status === 200) {
      yield put({
        type: DrawerAction.GET_SAVED_LOCATIONS_SUCCESS,
        payload: response?.data?.data?.locations,
      });
    } else {
      yield put({ type: DrawerAction.GET_SAVED_LOCATIONS_FAIL });
    }
  }

  static *GetVehicleSelector(params: any) {
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_VEHICLE_URL,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response && response?.status === 200) {
      yield put({
        type: DrawerAction.GET_VEHICLE_SELECTOR_SUCCESS,
        payload: response?.data?.data?.vehicles,
      });
    } else {
      yield put({ type: DrawerAction.GET_VEHICLE_SELECTOR_FAIL });
    }
  }

  static *GetNotifications(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const data: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_NOTIFICATIONS_LISTING(payload),
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (data && data?.status === 200) {
      yield put({
        type: DrawerAction.GET_NOTIFICATIONS_SUCCESS,
        payload: {
          ...data?.data?.data?.notifications,
          query: payload?.query,
          fromSearch: payload?.fromSearch,
        }
        // data?.data?.data?.notifications?.data || [],
      });
    } else {
      yield put({ type: DrawerAction.GET_NOTIFICATIONS_FAIL });
    }
  }

  static *GetLoadDraft(params: any) {
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_LOAD_DRAFT_URL,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      null,
      true
    );
    if (response && response?.status === 200) {
      yield put({
        type: DrawerAction.GET_LOAD_DRAFT_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({ type: DrawerAction.GET_LOAD_DRAFT_FAIL });
    }
  }

  static *addLocation(params: any) {
    const token = getItem("token");
    const {
      payload: { data, cb },
    } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ADD_LOCATION,
      data,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200 || response?.status === 201) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({ type: DrawerAction.ADD_LOCATION[1] });
      yield put({ type: DrawerAction.GET_SAVED_LOCATIONS })
      yield put({ type: ProfileAction.JOB_LOAD_META[0] })
      if (cb) {
        cb();
      }
    } else {
      yield put({ type: DrawerAction.ADD_LOCATION[2] });
    }
  }
  static *AddNetwork(params: any) {
    const token = getItem("token");
    const { payload } = params;
    let phoneNumber = null;
    let id = null;
    let new_payload = payload?.selectedNetwork.map((item) => {
      if (item?.id) {
        phoneNumber = Number(item?.phone_no);
        id = item?.id
        return {
          network_id: item?.id,
        };
      } else {
        phoneNumber = Number(item?.phone_no);
        return {
          phone_no: Number(item?.phone_no),
          phone_code: "92",
        };
      }
    });
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ADD_NETWORK_URL,
      new_payload,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {
      yield put({
        type: DrawerAction.ADD_NETWORK_SUCCESS,
      });
      triggerMixpanelEvent("Save user by number in my network",
        "Click on my add button contacts button to save user by number in my network")
      yield put({
        type: DrawerAction.GET_NETWORK,
      });
      if (payload.cb) {
        payload.cb(phoneNumber, id);
      }
      if (new_payload.length == 1) {
        if (!new_payload[0]?.network_id) {
          showMessage.current({
            message: "Your request has been sent .",
            status: "success",
          });
        } else {
          showMessage.current({
            message: "Contact added successfully.",
            status: "success",
          });
        }
      }
    } else {
      yield put({ type: DrawerAction.ADD_NETWORK_FAIL });
    }
  }

  static *SearchNetwork(params: any) {
    const { payload } = params;
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.SEARCH_NETWORK_URL({ phone: payload }),
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 200) {
      yield put({
        type: DrawerAction.SEARCH_NETWORK_SUCCESS,

        payload: response?.data?.data?.company_networks,
      });
    } else {
      yield put({ type: DrawerAction.SEARCH_NETWORK_FAIL });
    }
  }

  static *AddOtpNetwork(params: any) {
    const { payload } = params;
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ADD_OTP_NETWORK,
      { qr_code: payload?.qr_code?.text },
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {

      yield put({
        type: DrawerAction.ADD_OTP_NETWORK_SUCCESS,
      });
      // yield put({
      //   type: DrawerAction.GET_NETWORK,
      // });
      showMessage.current({
        message: "Contact added successfully.",
        status: "success",
      });
      triggerMixpanelEvent("Save user by qr code in my network",
        "Click on QR scan to save user in my network")
      if (payload?.cb) {
        payload.cb();
      }

    } else {
      if (payload?.cb) {
        payload.cb();
      }
      yield put({ type: DrawerAction.ADD_OTP_NETWORK_FAIL });
    }
  }

  static *AddVehicleSelector(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ADD_VEHICLE_URL,
      payload,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 201) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });

      yield put({
        type: DrawerAction.ADD_VEHICLE_SELECTOR_SUCCESS,
      });
      triggerMixpanelEvent("Add new vehicle",
        "Click on add button for adding vehicle")
      if (payload?.cb) {
        payload?.cb();
      }
    } else {
      yield put({ type: DrawerAction.ADD_VEHICLE_SELECTOR_FAIL });
    }
  }

  static *EditVehicleSelector(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Put,
      Endpoints.EDIT_VEHICLE_URL({ id: payload?.id }),
      { ...payload },
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: DrawerAction.EDIT_VEHICLE_SELECTOR_SUCCESS,
      });
      triggerMixpanelEvent("Update vehicle",
        "Click on edit button for updating vehicle")
      let new_response = response?.data?.data?.vehicle;
      let new_data = {
        driver_id: new_response?.driver?.id,
        vehicle_id: new_response?.id,
        container_ref_one: "",
        container_ref_two: "",
        vehicle_no: payload?.vehicle_no,
        load_id: payload?.load_id,
        job_load_category_id: payload?.cat_id,
        cb_2: payload?.cb_2,
      };
      if (payload?.from === "detail") {
        yield put({ type: LoadActions.ADD_DRIVER_AND_REF, payload: new_data });
      } else {
        if (payload?.cb) {
          payload?.cb();
        }
      }
    } else {
      yield put({ type: DrawerAction.EDIT_VEHICLE_SELECTOR_FAIL });
    }
  }

  static *DeleteLocation(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Delete,
      Endpoints.DELETE_LOCATION_URL(payload),
      {},
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });

      yield put({
        type: DrawerAction.DELETE_LOCATION[1],
      });

      triggerMixpanelEvent("delete location",
        "To delete location from your listing")

      yield put({
        type: DrawerAction.GET_SAVED_LOCATIONS,
      });
    } else {
      yield put({ type: DrawerAction.DELETE_LOCATION[2] });
    }
  }

  static *DeleteVehicleSelector(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Delete,
      Endpoints.DELETE_VEHICLE_URL({ id: payload?.id }),
      {},
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: DrawerAction.DELETE_VEHICLE_SELECTOR_SUCCESS,
      });
      triggerMixpanelEvent("Delete a vehicle",
        "click on delete button for removing vehicle from listing")
      yield put({
        type: DrawerAction.GET_VEHICLE_SELECTOR,
      });
    } else {
      yield put({ type: DrawerAction.DELETE_VEHICLE_SELECTOR_FAIL });
    }
  }
  static *DeleteNetwork(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.DELETE_NETWORK_URL({ network_user_id: payload?.network_user_id }),
      {
        phone_no: payload?.network_user_id,
        phone_code: "92"
      },
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: DrawerAction.DELETE_NETWORK_SUCCESS,
      });
      triggerMixpanelEvent("Delete user from my network",
        "Click on button to delete user from my network list")
      yield put({
        type: DrawerAction.GET_NETWORK, payload: { only_company_users: "" }
      });
    } else {
      yield put({ type: DrawerAction.DELETE_NETWORK_FAIL });
    }
  }

}
