import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
export function useAudioPlayer() {
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    duration = _useState2[0],
    setDuration = _useState2[1];
  var _useState3 = useState(),
    _useState4 = _slicedToArray(_useState3, 2),
    curTime = _useState4[0],
    setCurTime = _useState4[1];
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    playing = _useState6[0],
    setPlaying = _useState6[1];
  var _useState7 = useState(),
    _useState8 = _slicedToArray(_useState7, 2),
    clickedTime = _useState8[0],
    setClickedTime = _useState8[1];
  useEffect(function () {
    var audio = document.getElementById("audio");

    // state setters wrappers
    var setAudioData = function setAudioData() {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    };
    var setAudioTime = function setAudioTime() {
      return setCurTime(audio.currentTime);
    };

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);
    audio.addEventListener("timeupdate", setAudioTime);

    // React state listeners: update DOM on React state changes
    playing ? audio.play() : audio.pause();
    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    // effect cleanup
    return function () {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    };
  });
  return {
    curTime: curTime,
    duration: duration,
    playing: playing,
    setPlaying: setPlaying,
    setClickedTime: setClickedTime
  };
}