import React, { FC, useState } from "react";
import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Button
} from "@mui/material/";
import { useSelector } from "react-redux";
var numeral = require("numeral");
import { ExitToAppRounded, ExpandMore } from "@mui/icons-material";
import { ConfirmLoadProps } from "./types";
import { LOAD_TYPE } from "@temp/constants";
import { CustomText, OutLineButton } from "@temp/components";
import { Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { HistoryVehicle } from "./HistoryVehicles";

export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 20px;
  border-radius: 10px;
`;

export const CategoryContainer: FC<ConfirmLoadProps> = ({
  value,
  index,
  otherData,
  accordianMode = true,
  contract = false,
  fromConfirm,
  fromHistory = false,
  categoryLength,
  editable,
  is_recipient

}: ConfirmLoadProps) => {


  const postLoadMeta = useSelector(
    (state: any) => state?.Profile?.postLoadMeta
  );
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const load_type = postLoadMeta.load_types.find(
    (val: any) => val.id === PostLoad.load_type
  );
  const [isExpanded, setIsExpanded] = useState(true);
  const handleChange = (check) => {
    setIsExpanded(check);
  };
  const profile = useSelector((state: any) => state.Profile.profile);
  const orignal_recipient_id = PostLoad?.is_allocated ? PostLoad?.allocates[0]?.allocated_by : profile?.id

  const history = useHistory();
  const onEdit = () => {
    history.push("/selectVehicle");
  };
  const CategoryHeader = ({ title, sep, is_recipient, fromHistory, urdutitle }) => {
    return (
      <p className="fw-6">
        {title}

        {
          fromHistory && is_recipient &&
          <>
            <span className="seperator">
              {sep}
            </span>
            <span className="urduLabel lhEdit">
              {urdutitle}
            </span>
          </>
        }
      </p>
    );
  };

  const CategoryDetail = () => {
    return (
      <Grid container spacing={1} >
        <FormRow
          label="Vehicle Type"
          value={value?.selectVehicleTypeObj?.title}
          sep="/"
          urdutitle={" گاڑی کی قسم"}
          is_recipient={is_recipient}
          fromHistory={fromHistory}

        />

        {value?.containerType?.title ? (
          <FormRow label="Container Type"
            value={value?.containerType?.title}
            sep="/"
            urdutitle={"کنٹینر کی قسم"}
            is_recipient={is_recipient}
            fromHistory={fromHistory}

          />
        ) : null}

        {
          value?.max_bid > 0 ?
            (<FormRow label="Max Bid (Without GST)"
              value={(numeral(value?.max_bid).format("0,0")) + " PKR"}
              sep="/"
              urdutitle={"زیادہ سے زیادہ بولی  (GST کے بغیر)"}
              is_recipient={is_recipient}
              fromHistory={fromHistory}

            />) : null
        }

        {

          <FormRow
            label={load_type.title === LOAD_TYPE.fcl && value?.max_vehicle_weight ? "Max Cargo Weight Per Vehicle (Without Container)"
              : value?.max_vehicle_weight ? "Max Cargo Weight Per Vehicle " : ""
            }
            value={
              value.max_vehicle_weight
                ? value.max_vehicle_weight + " Ton"
                : "0 Ton"
            }
            urdutitle={load_type.title === LOAD_TYPE.fcl && value?.max_vehicle_weight ? "زیادہ سے زیادہ کارگو وزن فی گاڑی (کنٹینر کے بغیر)"
              : value?.max_vehicle_weight ? "زیادہ سے زیادہ کارگو وزن فی گاڑی " : ""
            }
            sep="/"
            is_recipient={is_recipient}
            fromHistory={fromHistory}
          />
        }

        {load_type.title === LOAD_TYPE.bulk && value?.selectVehicleTypeObj?.sub_title && (
          <FormRow
            label="Vehicle Capacity"
            value={value?.selectVehicleTypeObj?.sub_title}
            sep="/"
            urdutitle="گاڑی کی گنجائش"
            is_recipient={is_recipient}
            fromHistory={fromHistory}
          />
        )}

        {
          value?.vehicle_quantity ?
            <FormRow
              label={PostLoad?.inquiry_type == 2 ? "Estimated Number of Vehicle(s)" : "Number of Vehicle(s)"}
              value={value?.vehicle_quantity}
              sep="/"
              urdutitle={PostLoad?.inquiry_type == 2 ? "گاڑیوں کی تخمینی تعداد" : "گاڑیوں کی تعداد"}
              is_recipient={is_recipient}
              fromHistory={fromHistory}

            /> : ""}

        {PostLoad?.load_type === 1 && value?.vehicle_quantity ?
          <FormRow
            label={PostLoad?.inquiry_type == 1
              ? "Number of Container(s)" : "Estimated Number of Container(s)"}
            value={value?.values?.is_twin_load == 1 ? Number(value?.vehicle_quantity) * 2 : value?.vehicle_quantity}
            sep="/"
            urdutitle={PostLoad?.inquiry_type == 1
              ? "کنٹینرز کی تعداد" : "کنٹینرز کی تخمینی تعداد"}
            is_recipient={is_recipient}
            fromHistory={fromHistory}
          />
          : ""}


        {/* {
          value?.values?.is_genset ?
            <FormRow
              label="Genset"
              value={value?.values?.is_genset ? "Yes" : "No"}
            /> : null
        } */}

        {/* {
          value?.values?.required_temperature ?
            <>
              <FormRow2
                label="Required Temperature"
                value1={value?.values?.required_temperature[0]}
                value2={value?.values?.required_temperature[1]}

              />
            </>
            : null
        } */}


        {value.values?.is_guage || value?.values?.is_out_of_guage ? (
          <FormRow label="Out of Guage"
            value="YES"
            sep="/"
            urdutitle="گیج سے باہر"
            is_recipient={is_recipient}
            fromHistory={fromHistory}

          />
        ) : null}

        {value.values?.is_twin || value?.values?.is_twin_load ? (
          <FormRow
            label="Twin Load (2x20 Ft)"
            value="YES"
            sep="/"
            urdutitle="ٹوئن لوڈ (2x20 Ft)"
            is_recipient={is_recipient}
            fromHistory={fromHistory}
          />
        ) : null}

        {load_type.title === LOAD_TYPE.fcl && value?.values?.is_shipper_owned_container ? (
          <FormRow
            label="Shipper Owned Container"
            value={value?.values?.is_shipper_owned_container ? "YES" : "NO"}
            sep="/"
            urdutitle="شپپر کی ملکیت والا کنٹینر"
            is_recipient={is_recipient}
            fromHistory={fromHistory}
          />
        ) : null}

        {load_type.title === LOAD_TYPE.bulk &&
          value?.selectVehicleTypeObj?.is_sidewall === 1 && (
            <FormRow
              label="Half Body"
              value={value?.values?.is_sidewall ? "YES" : "NO"}
              sep="/"
              is_recipient={is_recipient}
              fromHistory={fromHistory}
            />
          )}

        {load_type.title === LOAD_TYPE.bulk &&
          value?.selectVehicleTypeObj?.is_boxed === 1 && (
            <FormRow
              label="Box"
              value={value?.values?.is_boxed ? "YES" : "NO"}
              sep="/"
              is_recipient={is_recipient}
              fromHistory={fromHistory}
            />
          )}

        {
          (
            <>
              {
                PostLoad?.load_type !== 1 ?
                  <FormRow
                    label="Packaging type"
                    value={
                      value?.packageType?.title === "Other"
                        ? value?.packageType?.title +
                        " (" +
                        value?.values?.package_type_other +
                        ")"
                        : value?.packageType?.title || ""
                    }
                    sep="/"
                    urdutitle=" پیکیجنگ کی قسم"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}
                  />
                  : null}




            </>
          )
        }

        {fromConfirm ?
          <>
            {PostLoad?.inquiry_type === 1 ? (
              <>
                <FormRow
                  label="Free Time Required"
                  value={value?.detention_free_time + " hrs"}
                  sep="/"
                  urdutitle={"فری وقت درکار "}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </>
            ) : (
              <>
                <FormRow
                  label="Free Time Required"
                  value={value?.values?.detention_free_time + " hrs"}
                  sep="/"
                  urdutitle={"فری وقت درکار "}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </>
            )}

            {
              value?.detention_included_transport || value?.values?.detention_included_transport ?
                <FormRow
                  label="Mandatory Detention included in Transport Price"
                  value={
                    PostLoad?.inquiry_type === 1 ?
                      value?.detention_included_transport ? "Yes" : "No" :
                      value?.values?.detention_included_transport ? "Yes" : "No"
                  }
                  sep="/"
                  urdutitle={"لازمی ڈیٹنشن ٹرانسپورٹ کی قیمت میں شامل ہے؟"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                /> : null}

            {
              PostLoad?.inquiry_type === 1 ?
                <>
                  {
                    value?.values?.required_temperature?.length && value?.values?.required_temperature
                      && (value?.containerType?.provide_temperature_control == 1
                        || value?.selectVehicleTypeObj?.provide_temperature_control
                        == 1) ?
                      <>
                        <FormRow2
                          label="Required Temperature"
                          value1={value?.values?.required_temperature ? value?.values?.required_temperature[0] : null}
                          value2={value?.values?.required_temperature ? value?.values?.required_temperature[1] : null}
                          sep="/"
                          urdutitle={"مطلوبہ درجہ حرارت"}
                          is_recipient={is_recipient}
                          fromHistory={fromHistory}
                        />
                      </>
                      : null
                  }
                  {
                    // PostLoad?.load_type === 1 && value?.is_genset 
                    // value?.selectVehicleTypeObj?.provide_genset !== 0 && value?.is_genset
                    // 

                    value?.containerType?.provide_genset == 1 ||
                      value?.selectVehicleTypeObj?.provide_genset == 1
                      // &&
                      // value?.is_genset

                      ?
                      <FormRow
                        label="Genset Required"
                        value={value?.values?.is_genset ? "Yes" : "No"}
                        sep="/"
                        urdutitle={"جنریٹر کی ضرورت ہے"}
                        is_recipient={is_recipient}
                        fromHistory={fromHistory}
                      />
                      : null
                  }


                </> :
                <>

                  {
                    value?.values?.required_temperature?.length && value?.values?.required_temperature
                      && (value?.containerType?.provide_temperature_control
                        == 1
                        || value?.selectVehicleTypeObj?.provide_temperature_control
                        == 1)
                      ?
                      <>
                        <FormRow2
                          label="Required Temperature"
                          value1={value?.values?.required_temperature ? value?.values?.required_temperature[0] : null}
                          value2={value?.values?.required_temperature ? value?.values?.required_temperature[1] : null}
                          sep="/"
                          urdutitle={"مطلوبہ درجہ حرارت"}
                          is_recipient={is_recipient}
                          fromHistory={fromHistory}
                        />
                      </>
                      : null
                  }

                  {
                    // PostLoad?.load_type === 1 && value?.values?.is_genset
                    value?.containerType?.provide_genset == 1 ||
                      value?.selectVehicleTypeObj?.provide_genset == 1
                      // value?.values?.is_genset
                      ?
                      < FormRow
                        label="Genset Required"
                        value={value?.values?.is_genset ? "Yes" : "No"}
                        sep="/"
                        urdutitle={"جنریٹر کی ضرورت ہے"}
                        is_recipient={is_recipient}
                        fromHistory={fromHistory}
                      />
                      : null
                  }
                </>
            }
          </> : null
        }

        {
          otherData?.transaction_type_id === 1 && (
            <FormRow
              label="Price / Vehicle (Without GST)"
              value={numeral(value?.price).format("0,0.00") + " PKR"}
              sep="/"
              urdutitle={`ریٹ / فی گاڑی (GST کے بغیر)`}
              is_recipient={is_recipient}
              fromHistory={fromHistory}
            />

          )
        }

        {
          value?.detention_rate > 0 ?
            <FormRow
              label="Detention Rate (Without GST)"
              value={numeral(value?.detention_rate).format("0,0") + " PKR"}
              sep="/"
              urdutitle={"ڈیٹنشن ریٹ (GST کے بغیر)"}
              is_recipient={is_recipient}
              fromHistory={fromHistory}
            />
            : null
        }

        {
          otherData?.transaction_type_id === 1 && (
            value.values?.is_twin || value?.values?.is_twin_load && otherData?.load_type === 1 ?
              <FormRow
                label="Price / Container (Without GST)"
                value={numeral(Number(value?.price) / 2).format("0,0.00") + " PKR"}
                sep="/"
                urdutitle={"قیمت / کنٹینر (GST کے بغیر)"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
              : null
          )
        }

        {
          otherData?.transaction_type_id === 1 && (
            <>
              {value?.is_loading_required ||
                value?.values?.is_loading_required ?
                <FormRow
                  label="Loading Required"
                  value={
                    value?.is_loading_required ||
                      value?.values?.is_loading_required
                      ? "YES"
                      : "NO"
                  }
                  sep="/"
                  urdutitle={"لوڈنگ"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
                : null}

              {((value?.is_loading_required && value?.loading_requirements) ||
                (value?.values?.is_loading_required &&
                  value?.values?.loading_requirements)) ? (
                <FormRow
                  label="Specify Labour / Equipment Requirement"
                  value={
                    value?.loading_requirements ||
                    value?.values?.loading_requirements
                  }
                  sep="/"
                  urdutitle={"لوڈنگ درکار ہے۔"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              ) : null}

              {value?.is_unloading_required ||
                value?.values?.is_unloading_required ?
                <FormRow
                  label="Unloading Required"
                  value={
                    value?.is_unloading_required ||
                      value?.values?.is_unloading_required
                      ? "YES"
                      : "NO"
                  }
                  sep="/"
                  urdutitle={"ان لوڈنگ"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                /> : null}

              {((value?.is_unloading_required &&
                value?.unloading_requirements) ||
                (value?.values?.is_unloading_required &&
                  value?.values?.unloading_requirements)) ? (
                <FormRow
                  label="Specify Labour / Equipment Requirement"
                  value={
                    value?.unloading_requirements ||
                    value?.values?.unloading_requirements
                  }
                  sep="/"
                  urdutitle={"ان لوڈنگ درکار ہے۔"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              ) : null}
              {value?.is_loading_required ? (
                <FormRow
                  label="Loading Price / Vehicle (Without GST)"
                  value={
                    numeral(value?.loading_price).format("0,0.00") + " PKR"
                  }
                  sep="/"
                  urdutitle={"لوڈنگ قیمت / گاڑی"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              ) : null}
              {value?.is_unloading_required ? (
                <FormRow
                  label="Unloading Price / Vehicle (Without GST)"
                  value={
                    numeral(value?.unloading_price).format("0,0.00") +
                    " PKR" || "N/A"
                  }
                  sep="/"
                  urdutitle={"ان لوڈنگ قیمت / گاڑی"}
                  urdutitle={"ان۔"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              ) : null}
            </>
          )
        }


        {
          otherData?.transaction_type_id === 1 ? (
            <FormRow
              label="Total Price (Without GST)"
              value={numeral(value?.total_amount).format("0,0.00") + " PKR"}
              sep="/"
              urdutitle="کل رقم (GST کے بغیر)"
              is_recipient={is_recipient}
              fromHistory={fromHistory}
            />
          ) : null
        }


        {/* prices added for recipient */}
        {
          <>
            {PostLoad?.transaction_type_id == 2 && is_recipient &&
              <>
                {value?.new_recipients?.filter((itm) => itm?.recipient_id == profile.id)
                  .map((reci) => {
                    return (
                      <>
                        {
                          reci?.bid?.bid_price_per_vehicle &&
                          <>
                            <CustomText.OpenSansBold
                              textStyle={{
                                marginLeft: 8,
                              }}
                            >
                              {
                                reci?.status == 4 ? "Awarded Price" : "Responded Price"
                              }
                            </CustomText.OpenSansBold>

                            <FormRow
                              label={`Price / Vehicle  (Without GST)`}
                              value={`PKR ${numeral(reci?.bid?.bid_price_per_vehicle).format("0,0.00")}`}
                              urdutitle={`ریٹ / فی گاڑی (GST کے بغیر)`}
                              fromHistory={fromHistory}
                              sep="/"
                              is_recipient={is_recipient}

                            />
                          </>
                        }

                      </>
                    );
                  })}

              </>
            }
          </>
        }

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          {fromConfirm && fromHistory && otherData?.history_recipients?.length ?
            <>
              {
                value?.new_recipients?.filter((not_award) => not_award?.status !== 4).length ?
                  <>
                    <div className="dividing-bordered"></div>
                    <CustomText.OpenSansBold textStyle={{
                      marginLeft: 4,
                      marginBottom: 10
                    }}>
                      Recipients
                    </CustomText.OpenSansBold>

                    {value?.new_recipients?.filter((stat) =>
                      stat?.status !== 4)
                      ?.map((item) => {
                        let history_vechiles = value?.vehicles_drivers_refs?.filter((cat) => cat?.recipient_id == item?.recipient?.id)?.sort((a, b) => a?.vehicle_no - b?.vehicle_no);
                        return (
                          <>
                            <div style={{ marginBottom: 0, marginTop: 10 }} className="detail-category-recepient-container" >
                              <div>
                                <img
                                  style={{ border: "1px solid #0D5296" }}
                                  alt="profile"
                                  className="load-detail-user"
                                  src={item?.recipient?.picture || Images.myProfileImage}
                                />
                              </div>

                              <div style={{ width: "80%" }} className="main-recipients">
                                <div className="load-history-profile-name">
                                  <CustomText.OpenSansSemiBold>
                                    {item?.recipient?.company?.length ?
                                      item?.recipient?.company[0]?.name : item?.recipient?.name
                                      || "UnRegistered User"}
                                  </CustomText.OpenSansSemiBold>

                                  <CustomText.OpenSansRegular>
                                    {
                                      item?.recipient?.company?.length ?
                                        item?.recipient?.name : item?.recipient?.company_name
                                    }
                                  </CustomText.OpenSansRegular>

                                  {item?.bid?.bid_price_per_vehicle ?
                                    <div className="chip">
                                      {" PKR " + numeral(item?.bid?.bid_price_per_vehicle).format("0,0.00")} / Vehicle
                                    </div>
                                    : null}

                                </div>
                              </div >
                            </div>
                            <HistoryVehicle history_vechiles={history_vechiles} />
                          </>
                        )
                      })}

                  </> : null
              }
              <>
                {
                  value?.new_recipients?.filter((award) => award?.status == 4).length ?
                    <>
                      <div className="dividing-bordered"></div>
                      <CustomText.OpenSansBold
                        textStyle={{
                          marginLeft: 4,
                        }}
                      >
                        Awarded Recipients
                      </CustomText.OpenSansBold>
                      {
                        value?.new_recipients?.filter((stat) =>
                          stat?.status == 4)?.map((reci) => {
                            let history_vechiles = value?.vehicles_drivers_refs?.filter((cat) => cat?.recipient_id == reci?.recipient?.id)?.sort((a, b) => a?.vehicle_no - b?.vehicle_no);
                            return (
                              <>
                                <div style={{ marginBottom: 0, marginTop: 10 }} className="detail-category-recepient-container" >
                                  <div>
                                    <img
                                      style={{ border: "1px solid #0D5296" }}
                                      alt="profile"
                                      className="load-detail-user"
                                      src={reci?.recipient?.picture || Images.myProfileImage}
                                    />
                                  </div>

                                  <div style={{ width: "80%" }} className="main-recipients">
                                    <div className="load-history-profile-name">
                                      <CustomText.OpenSansSemiBold>
                                        {reci?.recipient?.company?.length ?
                                          reci?.recipient?.company[0]?.name : reci?.recipient?.name
                                          || "UnRegistered User"}
                                      </CustomText.OpenSansSemiBold>

                                      <CustomText.OpenSansRegular>
                                        {
                                          reci?.recipient?.company?.length ?
                                            reci?.recipient?.name : reci?.recipient?.company_name
                                        }
                                      </CustomText.OpenSansRegular>

                                      {
                                        reci?.bid?.bid_price_per_vehicle ?
                                          <div className="chip">
                                            {" PKR " + numeral(reci?.bid?.bid_price_per_vehicle).format("0,0.00")} / Vehicle
                                          </div>
                                          : null
                                      }
                                    </div>
                                  </div >
                                </div>
                                <HistoryVehicle history_vechiles={history_vechiles} />
                              </>
                            )
                          })
                      }
                    </> : null
                }
              </>
            </> : null}

          {
            fromConfirm && fromHistory && !otherData?.history_recipients?.length ?
              <div>
                <HistoryVehicle history_vechiles={value?.vehicles_drivers_refs?.filter((vehicle) => vehicle?.recipient_id == orignal_recipient_id)?.sort((a, b) => a?.vehicle_no - b?.vehicle_no)} />
              </div> : null

          }
        </Grid>
      </Grid >
    );
  };

  const FormRow = ({ label, value, adornement, sep, urdutitle, is_recipient, fromHistory }) => {
    return (
      <Grid item xs={12} className="formGrid">
        <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
          <span className="titleName">{label}</span>

          {
            fromHistory && is_recipient &&
            <>
              <span className="seperator">
                {sep}
              </span>
              <span className="urduLabel lhEdit">
                {urdutitle}
              </span>
            </>
          }
        </div>
        <div className="tempData">
          <div className="fw-5">{value} {label == "Required Temperature" ?
            <><span style={{ marginLeft: 3 }}>&#176;</span><span>C</span></> : null} {adornement}</div>
        </div>
      </Grid>
    );
  };

  const FormRow2 = ({ label, value1, value2, sep, urdutitle, is_recipient, fromHistory }) => {
    return (
      <Grid item xs={12} className="formGrid">
        <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
          <span className="titleName">{label}</span>
          {
            fromHistory && is_recipient &&
            <>
              <span className="seperator">
                {sep}
              </span>
              <span className="urduLabel lhEdit">
                {urdutitle}
              </span>
            </>
          }
        </div>
        <div className="tempData">
          <div className="fw-5"><span style={{ width: 50 }}>Min: </span>{value1} {<><span style={{ marginLeft: 3 }}>&#176;</span><span>C</span></>} - </div>
          <div className="fw-5"><span style={{ width: 50 }}>Max: </span> {value2} {<><span style={{ marginLeft: 3 }}>&#176;</span><span>C</span></>}</div>
        </div>
      </Grid>
    );
  };

  return (
    <>
      {accordianMode ? (
        <Accordion
          className="confirm-load-accordion"
          style={{ backgroundColor: "#FAFAFA" }}
          expanded={isExpanded}
          onChange={() => handleChange(!isExpanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <CategoryHeader
              title={categoryLength > 1 ? ` Vehicle Category ${index + 1}` : " Vehicle Category"}
              urdutitle={categoryLength > 1 ? `گاڑی کی کیٹاگری ${index + 1}` : "گاڑی کی کیٹاگری "}
              sep="/"
              is_recipient={is_recipient}
              fromHistory={fromHistory}

            />
            {editable ? (
              <div className="editBtnWrapper">
                <OutLineButton
                  classProp='tinyBtn'
                  dataColor="blue"
                  title="Edit"
                  icon={
                    <span className="icons-edit"></span>
                  }
                  onClick={onEdit}
                />
              </div>
            ) : null}

          </AccordionSummary>
          <AccordionDetails>
            <CategoryDetail />
          </AccordionDetails>
        </Accordion>
      ) : (
        <div className="categoryWrapper">
          <CategoryHeader
            title={categoryLength > 1 ? `Category ${index + 1}` : "Category"}
            urdutitle={categoryLength > 1 ? ` کیٹاگری ${index + 1}` : " کیٹاگری "}
            sep="/"
            is_recipient={is_recipient}
            fromHistory={fromHistory}

          />
          <CategoryDetail />
        </div>
      )}
    </>
  );
};

export default CategoryContainer;
