import React, { useEffect, useState } from "react";
import {
  BackHeader,
  PrimaryButton,
  SignupContainer,
  CustomText,
} from "@components";
import { useHistory, useLocation } from "react-router-dom";
import { AuthAction } from "@temp/store/actions";
import { Colors } from "@temp/assets";
import "./style.scss";
import { ResetPageProps } from "./types";
import { showMessage, validatePassword, setItem } from "@utils/helper";
import { useDispatch, useSelector } from "react-redux";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import rootReducer from "../../../store/reducers";
import {
  validateUpper,
  validateLower,
  validateSpecial,
  validateNum,
  validateNumeric,
} from "@utils/helper";
import VisibilityOnIcon from "@mui/icons-material/Visibility";

export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<ResetPageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const signupData = useSelector((state: RootState) => state.Auth.signupData);


  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState(!state?.isForgot ? signupData?.password : "");
  const [confirmPassword, setConfirmPassword] = useState(!state?.isForgot ? signupData?.confirmPass : "");

  const [passwordChanged, setPasswordChanged] = useState("");

  const [upperCaseText, setUpperCaseText] = useState(false);
  const [lowerCaseText, setLowerCaseText] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [checkNum, setCheckNum] = useState(false);
  const [checkNumeric, setCheckNumeric] = useState(false);

  const profile = useSelector((state: RootState) => state.Profile.profile);

  const callBack = (data: any) => {
    history.replace('/login')
    setItem("token", null);
    setItem("temp_token", null);
    setItem("toc_accepted", null);
    setItem("changed_password", null);
    // window.location.reload();
    dispatch(AuthAction.resetStore());
  };



  const handleNext = () => {
    if (upperCaseText ||
      confirmPassword ||
      lowerCaseText ||
      newPassword ||
      checkNumeric ||
      newPassword !== confirmPassword) {
      // history.push("/review-detail",
      if (state?.isForgot) {
        let setup_password = {
          name: profile.name,
          password: newPassword,
          password_confirmation: confirmPassword,
          cb: callBack,
        };
        dispatch(AuthAction.setupPassword(setup_password));

      } else {
        dispatch(
          AuthAction.SetSignupData({ ...signupData, password: newPassword, confirmPass: confirmPassword })
        );
        history.push("/terms", {
          phoneNumber: state?.phoneNumber,
          whatsapp: state?.whatsapp,
          mobileNetwork: state?.mobileNetwork,
          city_id: state?.city_id,
          front_image:state?.front_image,
          back_image:state?.back_image
        });
      }

    } else {
    }
  };

  // const handleChange = (event) => {
  //   if (event.target.name === "new-pass") {
  //     let testPass = validatePassword(event.target.value);
  //     if (testPass) {
  //       setUpperCaseText(true);
  //     } else {
  //       setUpperCaseText(false);
  //     }
  //     setNewPassword(event.target.value);
  //   } else if (event?.target.name == "old-pass") {
  //     setOldPassword(event.target.value);
  //   } else {
  //     setConfirmPassword(event.target.value);
  //   }
  // };

  useEffect(() => {
    if (!state?.isForgot) {
      let testPass = validateUpper(signupData?.password);
      if (testPass) {
        setUpperCaseText(true);
      } else {
        setUpperCaseText(false);
      }
      let testPassLower = validateLower(signupData?.password);
      if (signupData?.password) {
        if (testPassLower) {
          setLowerCaseText(true);
        } else {
          setLowerCaseText(false);
        }
      }

      let testPassNum = validateNum(signupData?.password);
      if (testPassNum) {
        setCheckNum(true);
      } else {
        setCheckNum(false);
      }
      let testPassNumeric = validateNumeric(signupData?.password);
      if (testPassNumeric) {
        setCheckNumeric(true);
      } else {
        setCheckNumeric(false);
      }
    }
  }, [])

  const handlePassword = (event) => {
    if (event.target.name === "new-pass") {
      let testPass = validateUpper(event.target.value);
      if (testPass) {
        setUpperCaseText(true);
      } else {
        setUpperCaseText(false);
      }
      let testPassLower = validateLower(event.target.value);
      if (testPassLower) {
        setLowerCaseText(true);
      } else {
        setLowerCaseText(false);
      }
      let testPassNum = validateNum(event.target.value);
      if (testPassNum) {
        setCheckNum(true);
      } else {
        setCheckNum(false);
      }
      let testPassNumeric = validateNumeric(event.target.value);
      if (testPassNumeric) {
        setCheckNumeric(true);
      } else {
        setCheckNumeric(false);
      }
      setNewPassword(event.target.value);
    } else if (event.target.name === "old-pass") {
      setOldPassword(event.target.value);
    } else {
      setConfirmPassword(event.target.value);
      if (newPassword === event.target.value) {
        showMessage.current({
          message: "Password Matched",
          status: "success"
        })
      }
    }
  };

  const [passwordType, setPasswordType] = useState("password");
  const [oldPasswordType, setOldPasswordType] = useState("password")
  const [retpePasswordType, setRetypePassword] = useState('password')
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const toggleOldPassword = () => {
    if (oldPasswordType === "password") {
      setOldPasswordType("text")
      return;
    }
    setOldPasswordType("password")
  }
  const toggleRetypePassword = () => {
    
    if (retpePasswordType == "password") {
      setRetypePassword("text")
    } else {
      setRetypePassword("password")
    }
  }
  const handleSubmit = () => {
    // let reset_password = {
    //   name: profile.name,
    //   old_password: oldPassword,
    //   password: newPassword,
    //   password_confirm: confirmPassword,
    //   cb: callBack,
    // }
    let setup_password = {
      name: profile.name,
      password: newPassword,
      password_confirmation: confirmPassword,
      cb: callBack,
    };

    if (newPassword === confirmPassword) {
      dispatch(AuthAction.setupPassword(setup_password));
    } else {
      showMessage.current({
        message: "The password doesn't match",
        status: "error",
      });
    }
  };

  return (
    <div className="signup-wrapper setPasswordWrapper">
      <BackHeader
        title={state?.isForgot ? "Reset Password" : "Set Password"}
        onClick={() => {
          history.goBack();
        }}
      />
      <SignupContainer containerHeight="">
        <div className="insideafterOTPContainer">
          {state?.reset ? (
            <div
              style={{
                borderBottom: "0.4px solid #f1f1f1",
              }}
            >
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                  marginTop: 10,
                }}
              >
                Old Password
              </CustomText.OpenSansSemiBold>
              <input
                value={oldPassword}
                onChange={handlePassword}
                name="old-pass"
                className="input-pw"
                type={oldPasswordType}
                style={{
                  padding: 3,
                }}
              />
              {
                oldPasswordType !== "password" ?
                  <VisibilityOnIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={toggleOldPassword} />
                  :
                  <VisibilityOffIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={toggleOldPassword} />
              }
            </div>
          ) : null}
          <div
            style={{
              borderBottom: "0.4px solid #f1f1f1",
            }}
          >
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "0.875rem", color: Colors.Grayish, marginTop: 10 }}
            >
              {state?.reset ? "Password" : "New Password"}
            </CustomText.OpenSansSemiBold>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
              <input
                value={newPassword}
                onChange={handlePassword}
                name="new-pass"
                className="input-pw"
                type={passwordType}
                style={{
                  padding: 3,
                }}
              />
              {
                passwordType !== "password" ?
                  <VisibilityOnIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={togglePassword} />
                  :
                  <VisibilityOffIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={togglePassword} />
              }
            </div>
          </div>
          <div>
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "0.875rem", color: Colors.Grayish, marginTop: 10 }}
            >
              Retype Password
            </CustomText.OpenSansSemiBold>
            <div style={{ borderBottom: "0.4px solid #f1f1f1", display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
              <input
                value={confirmPassword}
                onChange={handlePassword}
                name="confirm-pass"
                className="input-pw"
                style={{
                  padding: 3,
                }}
                type={retpePasswordType}
              />
              {
                retpePasswordType !== "password" ?
                  <VisibilityOnIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={toggleRetypePassword} />
                  :
                  <VisibilityOffIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={toggleRetypePassword} />
              }
            </div>
          </div>

          <div className="characters-wrapper">
            <div className="characters">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {newPassword?.length > 6 ? (
                  <CheckCircleIcon style={{ color: "rgb(122,194,81)" }} />
                ) : (
                  <RadioButtonUncheckedIcon
                    style={{ color: "rgb(122,194,81)" }}
                  />
                )}

                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "0.75rem",
                    color: Colors.Black,
                    marginLeft: 6,
                    padding: 8,
                  }}
                >
                  Contain more than 6 characters
                </CustomText.OpenSansRegular>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {lowerCaseText ? (
                  <CheckCircleIcon style={{ color: "rgb(122,194,81)" }} />
                ) : (
                  <RadioButtonUncheckedIcon
                    style={{ color: "rgb(122,194,81)" }}
                  />
                )}

                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "0.75rem",
                    color: Colors.Black,
                    marginLeft: 6,
                    padding: 8,
                  }}
                >
                  Contain at least 1 Lowercase
                </CustomText.OpenSansRegular>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {upperCaseText ? (
                  <CheckCircleIcon style={{ color: "rgb(122,194,81)" }} />
                ) : (
                  <RadioButtonUncheckedIcon
                    style={{ color: "rgb(122,194,81)" }}
                  />
                )}

                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "0.75rem",
                    color: Colors.Black,
                    marginLeft: 6,
                    padding: 8,
                  }}
                >
                  Contain at least 1 uppercase
                </CustomText.OpenSansRegular>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {checkNumeric ? (
                  <CheckCircleIcon style={{ color: "rgb(122,194,81)" }} />
                ) : (
                  <RadioButtonUncheckedIcon
                    style={{ color: "rgb(122,194,81)" }}
                  />
                )}

                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "0.75rem",
                    color: Colors.Black,
                    marginLeft: 6,
                    padding: 8,
                  }}
                >
                  Contain at least 1 Numeric digit
                </CustomText.OpenSansRegular>
              </div>
            </div>
          </div>
        </div>
        <div className="bottomSignupScreen setupPassword">
          <div className="insideSignupBtnWrap">
            <PrimaryButton
              title="Continue"
              disabled={
                !upperCaseText ||
                !confirmPassword ||
                !lowerCaseText ||
                !newPassword ||
                !checkNumeric ||
                newPassword !== confirmPassword
              }
              onClick={() => {
                // handleSubmit();
                handleNext()
              }}
            />
          </div>
        </div>
      </SignupContainer>
    </div>
  );
};

export default Page;
