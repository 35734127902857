import { put, call, select } from "redux-saga/effects";
import ApiCaller from "@temp/api/api";
import { ResponseGenerator } from "@temp/api/globalTypes";
import { LoadAction } from "../actions";
import { getItem, showMessage, triggerMixpanelEvent } from "@utils/helper";
import Endpoints from "../endpoints";
export const getUser = (state) => state.Profile.profile;

export class LoadSaga {
  static *getLoads(params: any) {
    const token = getItem("token");
    const { payload } = params;
    let new_data = {
      ...payload,
      load_type:
        payload?.load_type == "fcl"
          ? "Containerized"
          : payload?.load_type == "bulk"
            ? "Non-Containerized"
            : payload?.load_type,
    };
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_LOADS_URL({ ...new_data }),
      {
        Authorization: `Bearer ${token}`,
      },
      null,
      true
    );
    if (response?.status === 200) {
      yield put({
        type: LoadAction.GET_LOADS_SUCCESS,
        payload: {
          ...response?.data?.data?.loads,
          search: payload?.search,
          fromSearch: payload?.fromSeach,
          fromNoti: payload?.fromNoti,
        },
      });
    } else {
      yield put({ type: LoadAction.GET_LOADS_FAIL });
    }
  }

  static *getLoadDetail(params: any) {
    const token = getItem("token");
    const { payload } = params;
    let { id } = yield select(getUser);
    const response: ResponseGenerator = yield call(

      ApiCaller.Get,
      Endpoints.GET_LOAD_DETAIL_URL({ ...payload }),
      {
        Authorization: `Bearer ${token}`,
      }
    );
    let new_arr = [];
    let currentCatId = null;
    let updateBid = false;
    let awardVehicleNoArray = []
    let awardVehicleNoArrayUnit = []
    let vehicles_drivers_refs_all = []
    if (response?.status === 200) {
      if (response?.data?.data?.jobLoad?.categories.length) {
        let is_contain_stuffin_partial_load =
          response?.data?.data?.jobLoad?.is_container_deposit_by_transporter ||
            response?.data?.data?.jobLoad?.is_cross_stuffing_required ||
            response?.data?.data?.jobLoad?.is_allow_partial_load
            ? true
            : false;
        response?.data?.data?.jobLoad?.categories.forEach((element) => {
          // awardVehicleNoArray.push(element?.vehicles_drivers_refs.length || 1)
          awardVehicleNoArrayUnit.push(element?.vehicles_drivers_refs.filter((item) => item?.recipient_id == id).length || 1)
          let find_recipient = element?.recipients?.find(recipt => recipt?.recipient?.id == id)
          if (element?.vehicles_drivers_refs.length > find_recipient?.bid?.awarded_no_of_vehicles) {
            awardVehicleNoArray.push(element?.vehicles_drivers_refs.length)
          } else {
            awardVehicleNoArray.push(find_recipient?.bid?.awarded_no_of_vehicles)
          }
          element?.vehicles_drivers_refs?.forEach(element2 => {
            vehicles_drivers_refs_all.push(element2)
          });
          currentCatId = element.id;
          element?.recipients?.forEach((item) => {
            if (
              (item?.bid && item?.recipient?.id == id) ||
              (item?.bid && item?.recipient?.id == response?.data?.data?.jobLoad?.allocates && response?.data?.data?.jobLoad?.allocates[0]?.allocated_by)
            ) {
              updateBid = true;
              new_arr.push({
                job_load_category_id: currentCatId,
                no_of_vehicles: item?.bid?.no_of_vehicles,
                bid_price_per_vehicle: item?.bid?.bid_price_per_vehicle,
                bid_price_loading: item?.bid?.bid_price_loading,
                bid_price_unloading: item?.bid?.bid_price_unloading,
                bid_detention_rate: item?.bid?.bid_detention_rate,
                bid_detention_additional_charges:
                  item?.bid?.bid_detention_additional_charges,
                bid_deposit_fee_amount: item?.bid?.bid_deposit_fee_amount,
                is_bid: true,
                status: item?.status,
                fieldType: ["text", "text", "text", "text", "text", "text"],
                detention_tarif_policy: item?.bid?.detention_tarif_policy,

                // is_disabled:is_contain_stuffin_partial_load
              });
            }
          });
        });
      } else {
        response?.data?.data?.jobLoad?.recipients?.forEach((item) => {
          if (
            (item?.bid && item?.recipient?.id == id) ||
            (item?.bid &&
              item?.recipient?.id ==
              response?.data?.data?.jobLoad?.allocates && response?.data?.data?.jobLoad?.allocates[0]?.allocated_by)
          ) {
            updateBid = true;
          }
        });
      }

      let bid_indexes = [];
      let cats = []

      if (
        response?.data?.data?.jobLoad?.inquiry_type?.title == "Contract Rate"
      ) {

        response?.data?.data?.jobLoad?.contract_locations?.forEach(
          (item, index) => {
            item?.categories?.forEach((cat) => {
              cats?.push(cat)
            })
            item?.recipients?.forEach((recip) => {
              if (recip?.recipient?.id == id && recip?.status == 1) {
                bid_indexes.push(index);
              }
            });
          }
        );
        response
      }
      if (payload?.callBack) {
        payload.callBack("200");
      }
      yield put({
        type: LoadAction.GET_LOAD_DETAIL_SUCCESS,
        payload: {
          ...response?.data?.data?.jobLoad,
          recipients: response?.data?.data?.jobLoad?.is_unit_basis ? [] : response?.data?.data?.jobLoad.recipients,
          already_bid: response?.data?.data?.jobLoad?.inquiry_type?.title == "Contract Rate" ? cats.filter((item) => item?.my_bid) : new_arr,
          awardVehicleNoArray: awardVehicleNoArray,
          awardVehicleNoArrayUnit: awardVehicleNoArrayUnit,
          vehicles_drivers_refs_all,
          all_cats: cats,
          updateBid,
          bid_indexes,
          is_container_deposit_by_transporter: !response?.data?.data?.jobLoad?.contract_id ? response?.data?.data?.jobLoad?.is_container_deposit_by_transporter : 0
        },
      });
      if (payload?.cb) {
        payload.cb({
          ...response?.data?.data?.jobLoad,
          already_bid: response?.data?.data?.jobLoad?.inquiry_type?.title == "Contract Rate" ? cats.filter((item) => item?.my_bid) : new_arr,
          categories: response?.data?.data?.jobLoad?.inquiry_type?.title == "Contract Rate" ? cats : response?.data?.data?.jobLoad?.categories,
          updateBid,
          bid_indexes,
        });
      }

    } else if (response?.status === 403) {
      if (payload?.callBack) {
        payload.callBack("403");

      }
      yield put({ type: LoadAction.GET_LOAD_DETAIL_FAIL });
    }
    else {
      yield put({ type: LoadAction.GET_LOAD_DETAIL_FAIL });
    }
  }

  static *getRequestVehicleData(params: any) {
    const token = getItem("token");
    const { payload } = params;
    let { id } = yield select(getUser);
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_LOAD_DETAIL_URL({ ...payload }),
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {
      let contract_locations = [];
      let new_cat = [];
      let new_recipient = [];
      let date = new Date().getTime();
      let new_deadline = new Date(date);
      let new_pickup_at = new Date(date + 10000);

      if (payload?.data?.category_id) {
        response?.data?.data?.jobLoad?.contract_locations?.forEach(
          (item, index) => {
            if (item?.pickup?.id == payload?.data?.location_id) {
              contract_locations.push(item);
            }
          }
        );

        if (contract_locations[0]?.categories.length) {
          contract_locations[0]?.categories.forEach((item) => {
            if (item?.id == payload?.data?.category_id) {
              new_cat.push(item);
            }
          });
          contract_locations[0]["categories"] = new_cat;
        }

        if (new_cat.length) {
          new_cat[0]?.recipients?.forEach((item) => {
            if (item?.id == payload?.data?.recipient_id) {
              new_recipient.push(item);
            }
          });
          contract_locations[0]["categories"][0]["recipients"] = new_recipient;
        }
        yield put({
          type: LoadAction.GET_REQUEST_VEHICLE_DATA_SUCCESS,
          payload: {
            ...response?.data?.data?.jobLoad,
            deadline_at: new_deadline,
            pickup_at: new_pickup_at,
            contract_locations,
          },
        });
      } else {
        response?.data?.data?.jobLoad?.contract_locations?.forEach(
          (item, index) => {
            if (item?.pickup?.id == payload?.data?.location_id) {
              contract_locations.push(item);
            }
          }
        );

        if (contract_locations[0].recipients.length) {
          contract_locations[0]?.recipients?.forEach((item) => {
            if (item?.id == payload?.data?.recipient_id) {
              new_recipient.push(item);
            }
          });
          contract_locations[0]["categories"] = [];
          contract_locations[0]["recipients"] = new_recipient;
        }
        yield put({
          type: LoadAction.GET_REQUEST_VEHICLE_DATA_SUCCESS,
          payload: {
            ...response?.data?.data?.jobLoad,
            deadline_at: new_deadline,
            pickup_at: new_pickup_at,
            contract_locations,
          },
        });
      }
    } else {
      yield put({ type: LoadAction.GET_REQUEST_VEHICLE_DATA_FAIL });
    }
  }

  static *awardJob(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.AWARD_LOAD_URL({ ...payload }),
      {
        job_load_category_id: payload?.category_id,
        recepients: payload?.awarded,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201) {
      triggerMixpanelEvent("Award Load", "Award load by poster.")
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.AWARD_JOB_SUCCESS,
        payload: response?.data?.data?.loads,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.load_id },
      });
    }
    else if (response?.status === 422) {
      // if (payload?.callBack) {
      //   payload.callBack("422");
      // }
      // showMessage.current({
      //   message: response.data.message,
      //   status: "error",
      // });
      yield put({ type: LoadAction.AWARD_JOB_FAIL });
    }
    else {
      yield put({ type: LoadAction.AWARD_JOB_FAIL });
    }
  }

  static *cancelLoad(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_LOADS_URL({ payload }),
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {
      yield put({
        type: LoadAction.CANCEL_LOAD_SUCCESS,
        payload: response?.data?.data?.loads,
      });
    } else {
      yield put({ type: LoadAction.CANCEL_LOAD_FAIL });
    }
  }

  static *recipientDeclined(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.DECLINE_BY_RECIPIENT({ ...payload }),
      {
        ...payload,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201) {
      triggerMixpanelEvent("Decline Load by recipient", "decline load by recipient.")
      yield put({
        type: LoadAction.RECIPIENT_DECLINED_SUCCESS,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.load_id },
      });
    } else {
      yield put({ type: LoadAction.RECIPIENT_DECLINED_FAIL });
    }
  }

  static *posterDeclined(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.DECLINE_BY_POSTER({ ...payload }),
      {
        job_load_recipient_id: payload?.job_load_recipient_id,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201) {
      triggerMixpanelEvent("Decline Load by Poster", "decline load by Poster.")
      yield put({
        type: LoadAction.POSTER_DECLINED_SUCCESS,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.load_id },
      });
    } else {
      yield put({ type: LoadAction.POSTER_DECLINED_FAIL });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.load_id },
      });
    }
  }

  static *acceptLoad(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ACCEPT_LOAD_URL({ ...payload }),
      payload?.responded,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201) {
      showMessage.current({
        message: response.data.message,
        status: "success",
      });
      yield put({
        type: LoadAction.ACCEPT_LOAD_SUCCESS,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.load_id },
      });
      if (payload?.callBackToDashboard) {
        payload?.callBackToDashboard();
      }
    } else {
      yield put({ type: LoadAction.ACCEPT_LOAD_FAIL });
    }
  }

  //// get load history
  static *getLoadsHistory(params: any) {
    const token = getItem("token");
    const { payload } = params;
    let new_data = {
      ...payload,
      load_history_type:
        payload?.load_history_type == "fcl"
          ? "Containerized"
          : payload?.load_history_type == "bulk"
            ? "Non-Containerized"
            : payload?.load_history_type,

    };
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_LOADS_HISTORY_URL({ ...new_data }),
      {
        Authorization: `Bearer ${token}`,
      },
      null,
      true
    );

    if (response?.status === 200) {
      yield put({
        type: LoadAction.GET_LOADS_HISTORY_SUCCESS,
        // payload: response?.data?.data?.loads,
        payload: {
          ...response?.data?.data?.loads,
          search: payload?.search,
          fromSearch: payload?.fromSeach,
        },
      });
    } else {
      yield put({ type: LoadAction.GET_LOADS_HISTORY_FAIL });
    }
  }

  static *allocateLoad(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ALLOCATE_LOAD_URL({ ...payload }),
      {
        ...payload,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201) {
      triggerMixpanelEvent("Allocate load", "Allocate load to another recipient by R1")
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      if (payload?.cb) {
        payload.cb();
      }
      yield put({
        type: LoadAction.ALLOCATE_LOAD_SUCCESS,
      });
    } else {
      yield put({ type: LoadAction.ALLOCATE_LOAD_FAIL });
    }
  }

  static *updateResponseDeadline(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.UPDATE_RESPONSE_DEADLINE_API({ ...payload }),
      {
        // deadline_at: payload?.deadline_at,
        ...payload,
        // _method: "patch",
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201 || response?.status === 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.UPDATE_RESPONSE_DEADLINE_SUCCESS,
        payload: response?.data?.data?.loads,
      });
      triggerMixpanelEvent("Update Load Dates and time by poster",
        "Update Load Dates and time by poster")
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.load_id },
      });
    } else {
      yield put({ type: LoadAction.UPDATE_RESPONSE_DEADLINE_FAIL });
    }
  }

  static *getForwardLoadDetail(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_LOAD_DETAIL_URL({ ...payload }),
      {
        Authorization: `Bearer ${token}`,
      }
    );

    let new_job_detail;
    if (response?.data?.data?.jobLoad?.categories.length) {
      new_job_detail = {
        ...response?.data?.data?.jobLoad,
        new_category: response?.data?.data?.jobLoad?.categories?.filter(
          (item) => item?.id == payload?.cat_id
        )[0],
      };
    } else {
      new_job_detail = {
        ...response?.data?.data?.jobLoad,
        new_category: {
          ...response?.data?.data?.jobLoad,
        },
      };
    }

    delete new_job_detail?.categories;
    new_job_detail["total_offer_vehicles"] =
      new_job_detail?.new_category?.vehicle_quantity;
    new_job_detail["deadline_by_poster"] = new_job_detail?.deadline_at;
    new_job_detail["freight_ton_by_poster"] = new_job_detail?.total_freight_ton;

    if (response?.status === 200) {
      yield put({
        type: LoadAction.GET_FORWARD_LOAD_DETAIL_SUCCESS,
        payload: {
          ...new_job_detail,
        },
      });
    } else {
      yield put({ type: LoadAction.GET_FORWARD_LOAD_DETAIL_FAIL });
    }
  }

  static *forwardLoad(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.FORWARD_LOAD_URL({ ...payload }),
      {
        ...payload?.forwardData,
        status: 1,
        id: payload?.load_id
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201) {
      showMessage?.current({
        message: response?.data?.message,
        status: "success",
      });
      if (payload?.forwardData?.cb) {
        payload?.forwardData.cb();
      }
      yield put({
        type: LoadAction.FORWARD_LOAD_SUCCESS,
      });
    } else {
      yield put({ type: LoadAction.FORWARD_LOAD_FAIL });
    }
  }

  static *addDriverAndRef(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ADD_DRIVER_AND_REF({ ...payload }),
      {
        ...payload,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201) {
      showMessage?.current({
        message: response?.data?.message,
        status: "success",
      });
      if (payload?.cb_2) {
        payload?.cb_2();
      }
      yield put({
        type: LoadAction.ADD_DRIVER_AND_REF_SUCCESS,
      });
    } else {
      yield put({ type: LoadAction.ADD_DRIVER_AND_REF_FAIL });
    }
  }

  static *createFromDraft(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.CREATE_FROM_DRAFT({ id: payload.id }),
      {
        ...payload.data,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201 || response?.status === 200) {
      yield put({ type: LoadAction.CREATE_FROM_DRAFT[1] });
      payload.cb(payload.data);
    } else {
      yield put({ type: LoadAction.CREATE_FROM_DRAFT[2] });
    }
  }

  static *cancelLoadHomeScreen(params: any) {
    const token = getItem("token");
    const { payload } = params;

    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.CANCEL_LOAD_HOME_SCREEN_URL(payload),
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {
      yield put({
        type: LoadAction.CANCEL_LOAD_HOME_SCREEN_SUCCESS,
        payload: null,
      });
      showMessage.current({ message: "Load has been cancelled", status: 'success' })
      if (payload?.cb) {
        payload?.cb();
      }
    } else {
      yield put({ type: LoadAction.CANCEL_LOAD_HOME_SCREEN_FAIL });
    }
  }

  static *getActiveLoadDetail(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_ACTIVE_LOAD_DETAIL_URL(payload),
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 200) {
      yield put({
        type: LoadAction.GET_ACTIVE_LOAD_DETAIL_SUCCESS,
        payload: {
          ...response?.data?.data?.jobLoad,
        },
      });
      if (payload?.cb)
        payload.cb({
          ...response?.data?.data?.jobLoad,
        });
    } else {
      yield put({ type: LoadAction.GET_ACTIVE_LOAD_DETAIL_FAIL });
    }
  }

  static *contactPickUpDropOff(params: any) {
    const token = getItem("token");
    const { payload } = params;

    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.CONTACT_PICK_UP_DROP_OFF_URL({ load_id: payload?.load_id }),
      {
        ...payload?.payload,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      if (payload?.cb)
        payload.cb({
          ...response?.data?.data,
        });
      yield put({
        type: LoadAction.CONTACT_PICK_UP_DROP_OFF_SUCCESS,
        payload: response?.data?.data?.loads,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.load_id },
      });
    } else {
      yield put({ type: LoadAction.CONTACT_PICK_UP_DROP_OFF_FAIL });
    }
  }

  static *getDriverMarker(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_DRIVER_MARKER(payload),
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 200) {
      const data = {
        lat: Number(response?.data.data.driverLocation.lat),
        lng: Number(response?.data.data.driverLocation.long),
      };

      yield put({
        type: LoadAction.GET_DRIVER_MARKER_SUCCESS,
        payload: data,
      });
      if (payload.cb) {
        payload.cb(data);
      }
    } else {
      yield put({ type: LoadAction.GET_DRIVER_MARKER_FAIL });
    }
  }
  static *getVehicleMarker(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_VEHICLE_MARKER(payload),
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 200) {
      if (response?.data?.data?.error_msg) {
        yield put({ type: LoadAction.GET_VEHICLE_MARKER_FAIL });
        payload.cb(null);
      } else {
        const data = {
          // lat: Number(response?.data.data.vehicle_location.lat),
          // lng: Number(response?.data.data.vehicle_location.long),
          // chasis_no: response?.data?.data.vehicle_location?.chasis_no,
          // vrn_color: response?.data?.data?.vehicle_location?.vrn_color,
          // // current_address:response?.data?.data?.vehicle_location?.current_address,
          // location: response?.data?.data?.vehicle_location?.location,
          // engine_no: response?.data?.data?.vehicle_location?.engine_no,
          // ignition: response?.data?.data?.vehicle_location?.ignition,
          // speed: response?.data?.data?.vehicle_location?.speed,

          lat: Number(response?.data.data.vehicle_location.lat),
          // lng: Number(response?.data.data.vehicle_location.long),
          lng: Number(response?.data.data.vehicle_location.lng),
          chasis_no: response?.data?.data.vehicle_location?.chasis_no,
          vrn_color: response?.data?.data?.vehicle_location?.vrn_color,
          location: response?.data?.data?.vehicle_location?.location,
          engine_no: response?.data?.data?.vehicle_location?.engine_no,
          ignition: response?.data?.data?.vehicle_location?.ignition,
          speed: response?.data?.data?.vehicle_location?.speed,
          is_idle: response?.data?.data?.vehicle_location?.is_idle,
          is_moving: response?.data?.data?.vehicle_location?.is_moving,
          reg_no: response?.data?.data?.vehicle_location?.reg_no,
        };

        yield put({
          type: LoadAction.GET_VEHICLE_MARKER_SUCCESS,
          payload: data,
        });
        if (payload.cb) {
          payload.cb(data);
        }
      }
    } else {
      if (payload.cb) {
        payload.cb(null);
      }
      yield put({ type: LoadAction.GET_VEHICLE_MARKER_FAIL });
    }
  }

  static *postRequestVehicle(params: any) {
    const token = getItem("token");
    const { payload } = params;

    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.POST_REQUEST_VEHICLE_URL({ load_id: payload?.contract_id }),
      {
        ...payload,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      if (payload?.cb) {
        payload.cb();
      }
      yield put({
        type: LoadAction.POST_REQUEST_VEHICLE_SUCCESS,
      });
    } else {
      yield put({ type: LoadAction.POST_REQUEST_VEHICLE_FAIL });
    }
  }

  static *acceptRequestVehicle(params: any) {
    const token = getItem("token");
    const { payload } = params;
    // return
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ACCEPT_REQUEST_VEHILE_URL({ load_id: payload?.load_id }),
      {
        ...payload,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201 || response?.status == 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.ACCEPT_REQUEST_VEHICLE_SUCCESS,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.load_id },
      });
    } else {
      yield put({ type: LoadAction.ACCEPT_REQUEST_VEHICLE_FAIL });
    }
  }

  static *addViewer(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ADD_VIEWER_URL({ load_id: payload?.job_load_id }),
      {
        ...payload,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );


    if (response?.status === 201 || response?.status == 200) {
      triggerMixpanelEvent("Added as Viewer", "added as a load viewer by poster")
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.ADD_VIEWER_SUCCESS,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.job_load_id },
      });
      if (payload?.cb) {
        payload.cb();
      }
    } else {
      yield put({ type: LoadAction.ADD_VIEWER_FAIL });
    }
  }

  static *addRequirements(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ADDITIONAL_REQUIREMENTS_URL({ ...payload }),
      {
        ...payload,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );


    if (response?.status === 201 || response?.status == 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.ADD_REQUIREMENTS_SUCCESS,
      });
      triggerMixpanelEvent("Update additional requirements of load by poster",
        "Update additional requirements of load by poster")

      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { ...payload },
      });
    } else {
      yield put({ type: LoadAction.ADD_REQUIREMENTS_FAIL });
    }
  }

  static *markAsCompleteByRecipient(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.MARK_AS_COMPLETE_BY_RECIPIENT({ load_id: payload?.load_id }),
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      true,
    );


    if (response?.status === 201 || response?.status == 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.MARK_AS_COMPLETE_BY_RECIPIENT_SUCCESS,
      });
      triggerMixpanelEvent("Mark complete by recipient",
        "Mark complete by recipient")
      if (payload?.cb) {
        payload?.cb();
      }
      // yield put({
      //   type: LoadAction.GET_LOAD_DETAIL,
      //   payload: { load_id: payload?.load_id },
      // });
    } else {
      yield put({ type: LoadAction.MARK_AS_COMPLETE_BY_RECIPIENT_FAIL });
    }
  }

  static *markAsCompleteByPoster(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.MARK_AS_COMPLETE_BY_POSTER({ load_id: payload?.load_id }),
      {
        job_load_recipient_ids: payload.job_load_recipient_ids,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );


    if (response?.status === 201 || response?.status == 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.MARK_AS_COMPLETE_BY_POSTER_SUCCESS,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.load_id },
      });
    } else {
      yield put({ type: LoadAction.MARK_AS_COMPLETE_BY_POSTER_FAIL });
    }
  }

  static *updateDriverChecklist(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.UPDATE_DRIVER_CHECKLIST({
        load_id: payload?.load_id,
        checklist_id: payload.checklist_id,
      }),
      {
        ...payload,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );


    if (response?.status === 201 || response?.status == 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.UPDATE_DRIVER_CHECKLIST_SUCCESS,
      });
      triggerMixpanelEvent("Driver checklist item update by recipient",
        "To update driver checklist by recipient ")
      if (payload?.cb) {
        payload?.cb();
      } else {
        yield put({
          type: LoadAction.GET_ACTIVE_LOAD_DETAIL,
          payload: {
            load_id: payload?.load_id,
            job_load_driver_id: payload?.job_load_driver_id,
          },
        });
      }
    }
    else if (response?.status === 403) {
      LoadAction.GET_ACTIVE_LOAD_DETAIL
      yield put({ type: LoadAction.GET_ACTIVE_LOAD_DETAIL, payload: { load_id: payload?.load_id, job_load_driver_id: payload?.job_load_driver_id } })

    }
    else {
      yield put({ type: LoadAction.UPDATE_DRIVER_CHECKLIST_FAIL });
    }
  }

  static *negotiateBidPoster(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.NEGOTIATE_BID_POSTER_URL({
        load_id: payload?.load_id,
        job_load_recipient_bid_id: payload.job_load_recipient_bid_id,
      }),
      {
        ...payload,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );


    if (response?.status === 201 || response?.status == 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.NAGOTIATE_BID_POSTER_SUCCESS,
      });
      triggerMixpanelEvent("Negotiate Bid by poster",
        "Negotiate Bid by poster")

      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: {
          load_id: payload?.load_id,
        },
      });
    } else {
      yield put({ type: LoadAction.NAGOTIATE_BID_POSTER_FAIL });
    }
  }

  static *negotiateBidAcceptRejectRecipient(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.NEGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_URL({
        load_id: payload?.load_id,
        job_load_recipient_bid_id: payload.job_load_recipient_bid_id,
      }),
      {
        negotiate_status: payload?.negotiate_status,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );


    if (response?.status === 201 || response?.status == 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_SUCCESS,
      });
      triggerMixpanelEvent("Negotiate Bid by recipient",
        "Negotiate Bid by recipient")
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: {
          load_id: payload?.load_id,
        },
      });
    } else {
      yield put({
        type: LoadAction.NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_FAIL,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: {
          load_id: payload?.load_id,
        },
      })
    }
  }

  static *awardContract(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.AWARD_CONTRACT_URL({
        load_id: payload?.load_id,
      }),
      {
        ...payload?.awarded_contract,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );


    if (response?.status === 201 || response?.status == 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.AWARD_CONTRACT_SUCCESS,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: {
          load_id: payload?.load_id,
        },
      });
    } else {
      yield put({ type: LoadAction.AWARD_CONTRACT_FAIL });
    }
  }

  static *addVehicleLastLocation(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ADD_VEHICLE_LAST_LOCATION_URL({
        load_id: payload?.load_id,
      }),
      {
        location: payload?.location,
        job_load_driver_id: payload?.job_load_driver_id,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );


    if (response?.status === 201 || response?.status == 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.ADD_VEHICLE_LAST_LOCATION_SUCCESS,
      });
      triggerMixpanelEvent("Last location of vehicle update by recipient",
        "To update last location by recipient")
      yield put({
        type: LoadAction.GET_ACTIVE_LOAD_DETAIL,
        payload: {
          load_id: payload?.load_id,
          job_load_driver_id: payload?.job_load_driver_id,
        },
      });
    } else {
      yield put({ type: LoadAction.ADD_VEHICLE_LAST_LOCATION_FAIL });
    }
  }

  static *uploadFilesRecipient(params: any) {
    const token = getItem("token");
    const { payload } = params;


    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.UPLOAD_FILES_RECIPIENT_URL(payload?.job_load_driver_id),
      {
        uploads: [{ ...payload }],
        job_load_driver_id: payload?.job_load_driver_id,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 200 || response.status == 201) {
      yield put({
        type: LoadAction.UPLOAD_FILES_RECIPIENT_SUCCESS,
      });
      triggerMixpanelEvent("Driver file uploads by recipient",
        "To upload files by recipient ")
      yield put({
        type: LoadAction.GET_ACTIVE_LOAD_DETAIL,
        payload: {
          load_id: payload?.load_id,
          job_load_driver_id: payload?.job_load_driver_id,
        },
      });
    } else {
      yield put({ type: LoadAction.UPLOAD_FILES_RECIPIENT_FAIL });
    }
  }
  static *hanldeTrackingCredentials(params: any) {
    const token = getItem("token");
    const { payload } = params;

    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.LOAD_TRACKING_CREDENTIALS({
        load_id: payload?.load_id,
      }),
      {
        ...payload
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200 || response?.status === 201) {
      yield put({ type: LoadAction.HANDLE_TRACKING_CREDENTIALS_SUCCESS });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: {
          load_id: payload?.load_id,
        }
      });
    }
    else {
      yield put({ type: LoadAction.HANDLE_TRACKING_CREDENTIALS_FAIL });
    }
  }

  static *addBulkVehicles(params: any) {
    const token = getItem("token");
    const { payload } = params;
    let vehicles = payload?.vehicles
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ADD_BULK_VEHICLES_API({ ...payload }),
      {
        vehicles,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201 || response?.status == 200) {
      triggerMixpanelEvent("Add bulk vehicle by recipient", "Add bulk vehicle by recipient")
      yield put({
        type: LoadAction.ADD_BULK_VEHICLES_SUCCESS,
      });
      if (payload?.cb) {
        // if(response?.data?.data?.id){
        //   payload?.cb({load_id:payload?.load_id,vehicle_id:response?.data?.data?.id})
        // }else{
        //   payload?.cb()
        // }
        payload?.cb(payload?.is_replace ? true : false)
      }
    } else {
      yield put({ type: LoadAction.ADD_BULK_VEHICLES_FAIL });
    }
  }

  static *addBulkVehicleRefs(params: any) {
    const token = getItem("token");
    const { payload } = params;
    let data = payload?.vehicle_ref_data
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.ADD_BULK_VEHICLE_REFS_API({ ...payload }),
      {
        ...data,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201 || response?.status == 200) {
      triggerMixpanelEvent("Add bulk reference by recipient", "Add bulk vehicle by recipient")
      yield put({
        type: LoadAction.ADD_BULK_VEHICLE_REFS_SUCCESS,
      });
      if (payload?.cb) {
        payload?.cb()
      }
    } else {
      yield put({ type: LoadAction.ADD_BULK_VEHICLE_REFS_FAIL });
    }
  }

  static *addTrackingId(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Put,
      Endpoints.EDIT_VEHICLE_URL({ id: payload?.id }),
      { ...payload },
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {
      yield put({ type: LoadAction.ADD_TRACKING_ID_SUCCESS })
      yield put({ type: LoadAction?.GET_LOAD_DETAIL, payload: { load_id: payload?.load_id } })

    } else {
      yield put({ type: LoadAction.ADD_TRACKING_ID_FAIL });
    }
  }

  static *uploadDocRecipient(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.UPLOAD_DOC_RECIPIENT_URL({ ...payload }),
      { documents: payload?.documents },
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200 || response?.status == 201) {
      yield put({ type: LoadAction.UPLOAD_DOC_RECIPIENT_SUCCESS })
      // yield put({type:LoadAction.GET_LOAD_DETAIL, payload:{load_id:payload?.load_id}})
    } else {
      yield put({ type: LoadAction.UPLOAD_DOC_RECIPIENT_FAIL });
    }
  }

  static *showIndicationDots(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.SHOW_INDICATION_DOTS({ ...payload }), {
      ...payload
    },
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200 || response?.status == 201) {
      yield put({ type: LoadAction.SHOW_INDICATION_DOTS_SUCCESS })
    } else {
      yield put({ type: LoadAction.SHOW_INDICATION_DOTS_FAIL });
    }
  }

  static *showIndicationDotsViewer(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.SHOW_INDICATION_DOTS_VIEWER({ ...payload }), {
      ...payload
    },
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200 || response?.status == 201) {
      yield put({ type: LoadAction.SHOW_INDICATION_DOTS_VIEWER_SUCCESS })
    } else {
      yield put({ type: LoadAction.SHOW_INDICATION_DOTS_VIEWER_FAIL });
    }
  }

  static *removeViewer(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Delete,
      Endpoints.REMOVE_VIEWER(payload),
      {},
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );


    if (response?.status === 201 || response?.status == 200) {
      triggerMixpanelEvent("remove as Viewer", "remove as a load viewer by poster")
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.REMOVE_VIEWER_SUCCESS,
      });
      yield put({
        type: LoadAction.GET_LOAD_DETAIL,
        payload: { load_id: payload?.load_id },
      });
    } else {
      yield put({ type: LoadAction.REMOVE_VIEWER_FAIL });
    }
  }

  static *pinPointsForVehicle(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_PIN_POINTS_FOR_TRACKER(payload),
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200 || response?.status == 201) {
      let data = [];
      response?.data?.data?.locations?.map((item) => {
        return (
          data.push({
            // Lat: Number(item.Lat),
            // Long: Number(item?.Long),
            ChasisNo: item?.ChasisNo,
            VrnColor: item?.VrnColor,
            // Location: item?.Location,
            location: item?.location,
            lat: Number(item.lat),
            lng: Number(item?.lng),
            EngineNo: item?.EngineNo,
            Ignition: item?.Ignition,
            // Speed: item?.Speed,
            speed: item?.speed,
            reg_no: item?.reg_no,
            is_idle: item?.is_idle,
            is_moving: item?.is_moving,
            vehicle_id: item?.vehicle_id
          })
        )
      })
      yield put({
        type: LoadAction.PIN_POINTS_FOR_VEHICLE_SUCCESS,
        payload: data,
      });

    } else {
      yield put({ type: LoadAction.PIN_POINTS_FOR_VEHICLE_FAIL });
    }
  }

  static *removeDocument(params: any) {
    const token = getItem("token");
    const { payload } = params;

    const response: ResponseGenerator = yield call(
      ApiCaller.Delete,
      Endpoints.REMOVE_DOCUMENT(payload),
      {},
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 201 || response?.status == 200 || response?.status == 202) {
      triggerMixpanelEvent("remove as doc", "remove as a doc")
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.REMOVE_DOCUMENT_SUCCESS,
      });
      yield put({
        type: LoadAction.GET_ACTIVE_LOAD_DETAIL,
        payload: {
          load_id: payload?.load_id,
          job_load_driver_id: payload?.job_load_driver_id,
        },
      });
    } else {
      yield put({ type: LoadAction.REMOVE_DOCUMENT_FAIL });
    }
  }

  static *updateDriverDetail(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Put,
      Endpoints.UPDATE_DRIVER_DETAIL({ id: payload?.id, driver_id : payload?.driver_id }),
      { ...payload },
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );


    if (response?.status === 201 || response?.status == 200 || response?.status == 202) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      });
      yield put({
        type: LoadAction.UPDATE_DRIVER_DETAIL_SUCCESS,
      });
      yield put({
        type: LoadAction.GET_ACTIVE_LOAD_DETAIL,
        payload: {
          load_id: payload?.load_id,
          job_load_driver_id: payload?.job_load_driver_id,
        },
      });
    } else {
      yield put({ type: LoadAction.UPDATE_DRIVER_DETAIL_FAIL });
    }


    // if (response?.status === 201 || response?.status == 200) {
    //   showMessage.current({
    //     message: response?.data?.message,
    //     status: "success",
    //   });
    //   yield put({
    //     type: LoadAction.UPDATE_DRIVER_DETAIL_SUCCESS,
    //   });
    //   triggerMixpanelEvent("Driver detail details by recipient",
    //     "To update driver detail by recipient ")
    //   if (payload?.cb) {
    //     payload?.cb();
    //   } else {
    //     yield put({
    //       type: LoadAction.GET_ACTIVE_LOAD_DETAIL,
    //       payload: {
    //         load_id: payload?.load_id,
    //         job_load_driver_id: payload?.job_load_driver_id,
    //       },
    //     });
    //   }
    // }
    // else if (response?.status === 403) {
    //   LoadAction.GET_ACTIVE_LOAD_DETAIL
    //   yield put({ type: LoadAction.GET_ACTIVE_LOAD_DETAIL, payload: { load_id: payload?.load_id, job_load_driver_id: payload?.job_load_driver_id } })

    // }
    // else {
    //   yield put({ type: LoadAction.UPDATE_DRIVER_DETAIL_FAIL });
    // }
  }
}

