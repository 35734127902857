export const { API_URL } = process.env;
export const NOTIFICATION_TIMEOUT = 5000;
export const TOKEN = "token";

export const INQUIRY_TYPE = {
  spotRate: "Spot Rate",
  contractRate: "Contract Rate",
};

export const LOAD_TYPE = {
  fcl: "Containerized",
  bulk: "Non-Containerized",
  lcl: "LCL",
};

export const TRANSACTION_TYPE = {
  bid: "Bid",
  offer: "Offer",
  offerId: 1,
  bidId: 2,
};

export const QUOTE_BY = {
  vehicle: "vehicle",
  weightVolume: "weight_volume",
};
