import { Colors } from "@temp/assets";
import { AuthAction, ProfileAction } from "../actions";

const INITIAL_STATE = {
  profile: {},
  isLoading: false,
  postLoad: {},
  activeDrawerTab: "",
  postLoadMeta: {},
  loadType: "Loads Received",
  inquiryType: "spot",
  activeCategory: "new",
  selectedValue: "All",

  historyLoadType: "Loads Received",
  historyInquiryType: "spot",

  active_loads: [],
  active_load_current_page: 1,
  active_load_last_page: 1,
  activeLoadType: "Loads Received",
  // activeLoadType: "received",
  reportsData: {},
  dataSpot: null,
  dataContract: null,
  bar_chart_data: null,

  activeCategoryReports: "posted",
  // activeDateFilter: "weekly",
  activeDateFilter: "today",
  fromResponseDate: new Date(),
  responseDate: new Date(),

  // vehicle
  activeCategoryVehicle: "posted",
  activeDateVehicle: "today",
  fromResponseDateVehicle: new Date(),
  responseDateVehicle: new Date(),


  // load detail
  activeCategoryDetail: "posted",
  activeDateDetail: "today",
  fromResponseDateDetail: new Date(),
  responseDateDetail: new Date(),

  mgExpressData: {},

  // new dashboard data
  dashboardData: {},
  chart_data: [],
  listing_data: [],
  load_detail_data: [],
  dashboard_vehicle_current_page: 1,
  dashboard_vehicle_last_page: 1,
  dashboardArry: [],
  new_dashboard_report: [],
  new_dashboard_load_detail: [],
  counts: {}
};

function Reducer(state = INITIAL_STATE, action: any) {
  
  
  
  


  switch (action.type) {
    case ProfileAction.SET_REPORTS_FILTER:
      return {
        ...state,
        activeCategoryReports: action?.payload?.activeCategoryReports,
        activeDateFilter: action?.payload?.activeDateFilter,
        fromResponseDate: action?.payload?.fromResponseDate,
        responseDate: action?.payload?.responseDate,
      }

    case ProfileAction.SET_VEHICLE_FILTER:
      return {
        ...state,
        activeCategoryVehicle: action?.payload?.activeCategoryVehicle,
        activeDateVehicle: action?.payload?.activeDateVehicle,
        fromResponseDateVehicle: action?.payload?.fromResponseDateVehicle,
        responseDateVehicle: action?.payload?.responseDateVehicle,
      }

    case ProfileAction.SET_DETAIL_FILTER:
      return {
        ...state,
        activeCategoryDetail: action?.payload?.activeCategoryDetail,
        activeDateDetail: action?.payload?.activeDateDetail,
        fromResponseDateDetail: action?.payload?.fromResponseDateDetail,
        responseDateDetail: action?.payload?.responseDateDetail,
      }

    case ProfileAction.DASHBOARD_REPORTS:
      return { ...state, isLoading: true };
    case ProfileAction.DASHBOARD_REPORTS_SUCCESS:
      return {
        ...state,
        reportsData: action.payload,
        dataSpot: {
          labels: [
            `Containerized`,
            `Non-Containerized`,
            `Bid`,
            `Offer`
          ],
          datasets: [
            {
              label: "Spot Data",
              data: [
                action.payload?.spot?.containerized,
                action.payload?.spot?.non_containerized,
                action.payload?.spot?.bid_counts,
                action.payload?.spot?.offer_counts
              ],
              backgroundColor: [Colors.Primary, Colors.NavyBlue, Colors.Yellow, Colors.LightWhite,],
              hoverOffset: 4,
            },
          ],
          key: new Date().getTime()
        },
        dataContract: {
          labels: [
            `Containerized`,
            `Non-Containerized`,
            `Bid`,
          ],
          datasets: [
            {
              label: "Contract Data",
              data: [
                action.payload?.contract?.containerized,
                action.payload?.contract?.non_containerized,
                action.payload?.contract?.bid_counts,
              ],
              backgroundColor: [Colors.Primary, Colors.NavyBlue, Colors.Yellow],
              hoverOffset: 4,
            },
          ],
          key: new Date().getTime()
        },
        bar_chart_data: {
          labels: action.payload?.labels,
          datasets: [
            {
              label: 'Containerized',
              data: action?.payload?.chart_data?.containerized,
              backgroundColor: Colors.Primary,
              borderColor: Colors.Primary,
            },
            {
              label: 'Non-Containerized',
              data: action?.payload?.chart_data?.non_containerized,
              backgroundColor: Colors.NavyBlue,
              borderColor: Colors.NavyBlue,
            },
            {
              label: 'Bid',
              data: action?.payload?.chart_data?.bid,
              backgroundColor: Colors.Yellow,
              borderColor: Colors.Yellow,
            },
            {
              label: 'Offer',
              data: action?.payload?.chart_data?.offer,
              backgroundColor: Colors.LightWhite,
              borderColor: Colors.LightWhite,
            },
          ],
        },
        isLoading: false
      };
    case ProfileAction.DASHBOARD_REPORTS_FAIL:
      return { ...state, isLoading: false };

    case ProfileAction.DOWNLOAD_CSV_REPORT:
      return { ...state, isLoading: true, };
    case ProfileAction.DOWNLOAD_CSV_REPORT_SUCCESS:
      return { ...state, isLoading: false };
    case ProfileAction.DOWNLOAD_CSV_REPORT_FAIL:
      return { ...state, isLoading: false };


    case ProfileAction.UPDATE_LOCATION:
      return {
        ...state,
        isLoading: true,
      }

    case ProfileAction.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false
      }

    case ProfileAction.UPDATE_LOCATION_FAIL:
      return {
        ...state,
        isLoading: false
      }

    case ProfileAction.UPDATE_ACTIVE_FILTERS:
      return {
        ...state,
        activeLoadType: action?.payload?.loadType
      }

    case ProfileAction.GET_ACTIVE_LOADS:
      return {
        ...state,
        isLoading: action?.payload?.refresh,
        active_loads: action.payload?.refresh || action?.payload?.fromSearch ? [] : [...state?.active_loads],
      }

    case ProfileAction.GET_ACTIVE_LOADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        active_loads: action?.payload?.fromSearch ? action?.payload.data : [...state?.active_loads, ...action?.payload?.data],
        active_load_current_page: action?.payload?.meta.current_page,
        active_load_last_page: action?.payload?.meta.last_page
      }

    case ProfileAction.GET_ACTIVE_LOADS_FAIL:
      return {
        ...state,
        isLoading: false
      }

    case AuthAction.TOGGLE_LOADER:
      return { ...state, isLoading: false };

    case ProfileAction.UPDATE_FCM_TOKEN[0]:
      return { ...state, isLoading: true };

    case ProfileAction.UPDATE_FCM_TOKEN[1]:
      return { ...state, isLoading: false };
    case ProfileAction.UPDATE_FCM_TOKEN[2]:
      return { ...state, isLoading: false };

    case ProfileAction.POST_LOAD[0]:
      return { ...state, isLoading: true };

    case ProfileAction.POST_LOAD[1]:
      return { ...state, isLoading: false };
    case ProfileAction.POST_LOAD[2]:
      return { ...state, isLoading: false };

    case ProfileAction.UPLOAD_FILE[0]:
      return { ...state, isLoading: true };

    case ProfileAction.UPLOAD_FILE[1]:
      return { ...state, isLoading: false };
    case ProfileAction.UPLOAD_FILE[2]:
      return { ...state, isLoading: false };

    case ProfileAction.POST_LOAD_DATA_REMOVE:
      return { ...state, postLoad: {} };

    case ProfileAction.JOB_LOAD_META[0]:
      return { ...state, isLoading: true };

    case ProfileAction.JOB_LOAD_META[1]:
      return { ...state, postLoadMeta: action.payload, isLoading: false };
    case ProfileAction.JOB_LOAD_META[2]:
      return { ...state, isLoading: false };

    case ProfileAction.POST_LOAD_DATA:
      return {
        ...state,
        postLoad: {
          ...state.postLoad,
          ...action.payload,
        },
      };

    case ProfileAction.GET_PROFILE:
      return { ...state, isLoading: true, postLoad: {} };
    case ProfileAction.GET_PROFILE_SUCCESS:
      return { ...state, profile: action.payload, isLoading: false };
    case ProfileAction.GET_PROFILE_FAIL:
      return { ...state, isLoading: false };

    // update profile //
    case ProfileAction.UPDATE_PROFILE:
      return { ...state, isLoading: true };
    case ProfileAction.UPDATE_PROFILE_SUCCESS:
      return { ...state, isLoading: false };
    case ProfileAction.UPDATE_PROFILE_FAIL:
      return { ...state, isLoading: false };

    case ProfileAction.SET_ACTIVE_DRAWER_TAB:
      return { ...state, activeDrawerTab: action.payload };

    case ProfileAction.CHANGE_PHONE_NUMBER_REQUEST:
      return { ...state, isLoading: true };
    case ProfileAction.CHANGE_PHONE_NUMBER_REQUEST_SUCCESS:
      return { ...state, isLoading: false };
    case ProfileAction.CHANGE_PHONE_NUMBER_REQUEST_FAIL:
      return { ...state, isLoading: false };

    case ProfileAction.CHANGE_PHONE_NUMBER:
      return { ...state, isLoading: true };
    case ProfileAction.CHANGE_PHONE_NUMBER_SUCCESS:
      return { ...state, isLoading: false };
    case ProfileAction.CHANGE_PHONE_NUMBER_FAIL:
      return { ...state, isLoading: false };

    case ProfileAction.CHANGE_PASSWORD:
      return { ...state, isLoading: true };
    case ProfileAction.CHANGE_PASSWORD_SUCCESS:
      return { ...state, isLoading: false };
    case ProfileAction.CHANGE_PASSWORD_FAIL:
      return { ...state, isLoading: false };

    case ProfileAction.SET_DASHBOARD_FILTERS:
      const { payload } = action;
      return {
        ...state,
        loadType: payload?.loadType,
        inquiryType: payload?.inquiryType,
        activeCategory: payload?.activeCategory,
        selectedValue: payload?.selectedValue,
      };

    case ProfileAction.UPDATE_HISTORY_FILTERS:
      return {
        ...state,
        historyLoadType: action?.payload?.loadType,
        historyInquiryType: action?.payload?.inquiryType,
      };
    case ProfileAction.DEL_PROFILE_IMAGE:
      return { ...state, isLoading: true };
    case ProfileAction.DEL_PROFILE_IMAGE_SUCCESS:
      return { ...state, profile: action.payload, isLoading: false };
    case ProfileAction.DEL_PROFILE_IMAGE_FAIL:
      return { ...state, isLoading: false };

    case ProfileAction.DOCUMENTS_MG_EXPRESS:
      return { ...state, isLoading: true, };
    case ProfileAction.DOCUMENTS_MG_EXPRESS_SUCCESS:
      return { ...state, mgExpressData: action.payload, isLoading: false };
    case ProfileAction.DOCUMENTS_MG_EXPRESS_FAIL:
      return { ...state, isLoading: false };

    // new dashboard data vehicle pagination
    case ProfileAction.DASHBOARD_DATA:
      return {
        ...state,
        isLoading: action?.payload?.refresh,
          // action?.payload?.fromSearch ||
          //   action?.payload?.search
          //   ? false : true,
        dashboardArry: action?.payload?.fromSearch || action?.payload?.search
          || action?.payload?.next_page === 1
          ? [] : [...state?.dashboardArry],
        counts: action?.payload?.counts
      };
    case ProfileAction.DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardArry: [...state?.dashboardArry, ...action?.payload?.data],
        isLoading: false,
        dashboard_vehicle_current_page: action?.payload?.meta?.current_page,
        dashboard_vehicle_last_page: action?.payload?.meta?.last_page,
        counts: action?.payload?.counts

      };
    case ProfileAction.DASHBOARD_DATA_FAIL:
      return { ...state, isLoading: false };

    // new dashboard report
    case ProfileAction.DASHBOARD_REPORT_NEW:
      return {
        ...state,
        isLoading: true,
        // new_dashboard_report: [...state?.new_dashboard_report],
        new_dashboard_report: state?.new_dashboard_report,
      };
    case ProfileAction.DASHBOARD_REPORT_NEW_SUCCESS:

      let data = []
      action?.payload?.PostedAwardedDate?.map((item) => {
        
        data.push({
          date: item?.dated,
          Posted: { Contract: item?.posted_contract, Spot: item?.posted_spot },
          Awarded: { Contract: item?.awarded_contract, Spot: item?.awarded_spot }
        })
      })
      return {
        ...state,
        // new_dashboard_report: [action?.payload],
        new_dashboard_report: action?.payload,
        chart_data: data,
        isLoading: false,

      };
    case ProfileAction.DASHBOARD_REPORT_NEW_FAIL:
      return { ...state, isLoading: false };


    // new dashboard load detail
    case ProfileAction.DASHBOARD_LOAD_DETAIL:
      return {
        ...state,
        isLoading: true,
        // new_dashboard_load_detail: [...state.new_dashboard_load_detail],
        new_dashboard_load_detail: state.new_dashboard_load_detail,

      };
    case ProfileAction.DASHBOARD_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        // new_dashboard_load_detail: [action?.payload],
        new_dashboard_load_detail: action?.payload,
        isLoading: false,
      };
    case ProfileAction.DASHBOARD_LOAD_DETAIL_FAIL:
      return { ...state, isLoading: false };

    case AuthAction.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default Reducer;
