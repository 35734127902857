import React, { useState } from "react";
// import { makeStyles, createStyles, Theme } from "@mui/material//styles";
import Grid from "@mui/material//Grid";
import { css } from "@emotion/css";
import { Button } from "@mui/material/";
import "./style.scss";
import { SlideupModal } from "../Modals/SlideupModal";
import OutlineButton from "../OutLineButton";
import { LOAD_TYPE } from "@temp/constants";
import { useSelector } from "react-redux";


// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       flexGrow: 1,
//       marginTop: 15,
//     },
//     paper: {
//       backgroundColor: "transparent",
//       border: "none",
//     },
//   })
// );

const container = css({
  borderRadius: 4,
});
export const VehicleTable: React.FC = (props: any) => {
//   const classes = useStyles();
  const { handleEdit } = props;
  const [currentIndex, setCurrentIndex] = useState(null);
  const [category, setCategory] = useState({});
  const [showModal, setShowModal] = useState(false);
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const handleCategoryOpen = (data) => {
    setShowModal(true);
    setCategory(data);
  };

  const handleCategoryClose = () => {
    setShowModal(false);
    setCategory({});
  };

  const editCategory = () => {

    setShowModal(false);
    handleEdit(currentIndex);

  };

  const FormRow = (params: any) => {
    const { index } = params;
    return (
      <div
        key={index}
        style={{ display: "flex", width: "100%" }}
        className="addVehicleRow"
        onClick={() => setCurrentIndex(index)}
      >
        <div
          className="addVehicleRowContent"
          onClick={() =>
            handleCategoryOpen({
              categoryTitle: `Category ${params?.index + 1}`,
              screenTitle: props?.screenTitle,
              ...params,
            })
          }
        >
          {`${params?.vehicle_quantity}` ?
            <>
              {`${params?.vehicle_quantity} x `}
              {`${params?.selectVehicleTypeObj?.title} `}
              {/* // {`${params?.vehicle_quantity} x `}
          // {`${params?.selectVehicleTypeObj?.title} `} */}
              {`${params?.containerType?.code || ""} `}
            </> :
            <>
              {`${params?.selectVehicleTypeObj?.title} `} {`${params?.containerType?.code || ""} `}
            </>
          }
          {`${(props?.loadType === LOAD_TYPE.fcl ||
            (props?.loadType === "BULK" &&
              props?.tempQuoteBy === "vehicle")) &&
            params?.max_vehicle_weight
            ? params?.max_vehicle_weight + "Tons"
            : ""
            } `}
        </div>

        {PostLoad?.from !== "forward" ?
          <>
            <div
              className="addVehicleRowDelete"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                style={{
                  fontWeight: "bold",
                  fontSize: "0.938",

                  color: "white",
                  backgroundColor: "transparent",
                  borderRadius: 4,
                  boxShadow: "none",
                  padding: 0,
                  margin: 0,
                  minWidth: 25,

                  lineHeight: 0,
                }}
                onClick={() => {
                  props.onClickDelete(params.index);
                }}
              >
                <span className="icons-cross"></span>
              </Button>
            </div>
          </> : null}
      </div>
    );
  };
  return (
    <div className={container}>
      <div className="titleSection">Saved Categories</div>
      <div
	//    className={classes.root}
	   >
        <Grid container>
          {props?.addedVehicles?.map((val: object, index: number) => (
            <div style={{ width: "100%" }} key={index}>
              <FormRow {...val} index={index} />
            </div>
          ))}
          <CategoryModal
            showModal={showModal}
            setShowModal={setShowModal}
            handleClose={handleCategoryClose}
            data={category}
            currentIndex={currentIndex}
            editCategory={editCategory}

          />
        </Grid>
      </div>
    </div>
  );
};

export const CategoryModal = (props) => {
  const { showModal, data, handleClose, editCategory } = props;
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);

  const ShowContractFields = () => {
    return (
      <div>
        {data?.values?.is_loading_required ? (
          <div className="formGrid">
            <div className="grayLabel">
              Loading Required
            </div>
            <div className="fw-5">
              {data?.values?.is_loading_required ? "YES" : "NO"}
            </div>
          </div>
        ) : (
          ""
        )}

        {data?.values?.is_loading_required &&
          data?.values?.loading_requirements ? (
          <div className="formGrid">
            <div className="grayLabel">
              Specify Labour / Equipment Requirement
            </div>
            <div className="fw-5">
              {data?.values?.loading_requirements}
            </div>
          </div>
        ) : (
          ""
        )}

        {data?.values?.is_shipper_owned_container ? (
          <div className="formGrid">
            <div className="grayLabel">
              Shipper Owned Container(SOC)
            </div>
            <div className="fw-5">
              {data?.values?.is_shipper_owned_container ? "YES" : "NO"}
            </div>
          </div>
        ) : (
          ""
        )}

        {data?.values?.is_unloading_required ? (
          <div className="formGrid">
            <div className="grayLabel">
              Unloading Required
            </div>
            <div className="fw-5">
              {data?.values?.is_unloading_required ? "YES" : "NO"}
            </div>
          </div>
        ) : (
          ""
        )}

        {data?.values?.is_unloading_required &&
          data?.values?.unloading_requirements ? (
          <div className="formGrid">
            <div className="grayLabel">
              Specify Labour / Equipment Requirement
            </div>
            <div className="fw-5">
              {data?.values?.unloading_requirements}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  return (
    <SlideupModal open={showModal} onClose={handleClose}>
      <div className="modalContainer">
        <h2 className="titleSection">{data?.categoryTitle}</h2>
        <div className="detailModal">
          {data?.selectVehicleTypeObj?.title && (
            <div className="formGrid">
              <div className="grayLabel">
                Vehicle Type
              </div>
              <div className="fw-5">
                {data.selectVehicleTypeObj.title}
              </div>
            </div>
          )}

          <div className="formGrid">
            <div className="grayLabel">
              Max Cargo Weight Per Vehicle (Tons)
            </div>
            <div className="fw-5">
              {data?.max_vehicle_weight}

            </div>
          </div>

          {data?.vehicle_quantity && (
            <div className="formGrid">
              <div className="grayLabel">
                {PostLoad?.inquiry_type == 2 ? "Estimated Number of Vehicles" : "Number of Vehicles"}
              </div>
              <div className="fw-5">
                {data?.vehicle_quantity}
              </div>
            </div>
          )}

          {data?.containerType?.title ? (
            <div className="formGrid">
              <div className="grayLabel">
                Container Type
              </div>
              <div className="fw-5">
                {data?.containerType?.title}
              </div>
            </div>
          ) : (
            ""
          )}

          {data?.screenTitle && data?.screenTitle !== LOAD_TYPE.fcl ? (
            <div className="formGrid">
              <div className="grayLabel">
                Packaging type
              </div>
              <div className="fw-5">
                {data?.packageType?.title}

                {data?.packageType?.title === "Other" &&
                  " (" + data?.values?.package_type_other + ")"}
              </div>
            </div>
          ) : (
            ""
          )}

          {(
            data?.containerType?.is_guage === 1 && data.values.is_out_of_guage) ||
            data?.screenTitle == "BULK" ? (
            <div className="formGrid">
              <div className="grayLabel">
                Out of Gauge
              </div>
              <div className="fw-5">
                {data.values.is_out_of_guage ? "YES" : "NO"}
              </div>
            </div>
          ) : (
            ""
          )}

          {(
            data?.selectVehicleTypeObj?.provide_genset == 1 || data?.containerType?.provide_genset == 1
            // &&
            //  data?.values?.is_genset
            )
            ? (
              <div className="formGrid">
                <div className="grayLabel">
                  Genset Required              </div>
                <div className="fw-5">
                  {data?.values?.is_genset ? "YES" : "NO"}
                </div>
              </div>
            ) : (
              ""
            )}

          {( data?.values?.required_temperature?.length && data?.values?.required_temperature)
          
          && (data?.containerType?.provide_temperature_control ==1
            || data?.selectVehicleTypeObj?.provide_temperature_control
            ==1 )
          
          
          // (data?.selectVehicleTypeObj?.provide_temperature_control == 1 || data?.containerType?.provide_temperature_control == 1)
            ? (
              <div className="formGrid">
                <div className="grayLabel">
                  Required Temperature
                </div>
                <>
                
                  <div className="fw-5">{`Min: ${data?.values?.required_temperature[0]}`} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                  <div className="fw-5">{`Max: ${data?.values?.required_temperature[1]}`} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                
                </>
              </div>
            ) : (
              ""
            )}

          {data?.selectVehicleTypeObj?.is_support_twin_load === 1 && data?.values.is_twin_load
            ? (
              <div className="formGrid">
                <div className="grayLabel">
                  Twin Load (2x20 Ft)
                </div>
                <div className="fw-5">
                  {data?.values.is_twin_load ? "YES" : "NO"}
                </div>
              </div>
            ) : (
              ""
            )}

          {data?.selectVehicleTypeObj?.is_boxed === 1 && data?.values.is_boxed
            ? (
              <div className="formGrid">
                <div className="grayLabel">
                  Box Vehicle
                </div>
                <div className="fw-5">
                  {data?.values.is_boxed ? "YES" : "NO"}
                </div>
              </div>
            ) : (
              ""
            )}

          {data?.selectVehicleTypeObj?.is_sidewall === 1 && data?.values.is_sidewall
            ? (
              <div className="formGrid">
                <div className="grayLabel">
                  Sidewall
                </div>
                <div className="fw-5">
                  {data?.values.is_sidewall ? "YES" : "NO"}
                </div>
              </div>
            ) : (
              ""
            )}
          <ShowContractFields />
          <OutlineButton
            title="EDIT"
            buttonStyle={{ marginTop: 10 }}
            onClick={() => editCategory()}
          />
        </div>
      </div>
    </SlideupModal >
  );
};

export default VehicleTable;
