import React, { useEffect, useRef, useState } from "react";
import {
  PrimaryButton,
  OutLineButton,
  CustomText,
  CustomModal,
  QrModal,
} from "@components";

import { Images, Colors } from "@temp/assets";
import "./style.scss";
import { MyProfilePageProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import { useHistory } from "react-router-dom";
import rootReducer from "../../../store/reducers";
import { useScreenshot } from "usescreenshot-react";
import { Close } from "@mui/icons-material";
import TitleLayout from "@temp/components/TitleLayout";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Radio from "@mui/material//Radio";
import { phoneNumberFormater, showMessage } from "@temp/utils/helper";
import { AuthAction } from "@temp/store/actions";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<MyProfilePageProps> = (props) => {
  const { image, takeScreenshot, isLoading, isError } = useScreenshot();
  const ref = useRef<HTMLDivElement>(null);
  const [showModal, toggleModal] = useState(false);
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const declineReasons = useSelector((state: RootState) => state.Auth.metaData.account_deletion_reasons);
  const [qrCode, setQrCode] = useState("");
  const profile = useSelector((state: RootState) => state.Profile.profile);

  const [showReasonModal, setShowReasonModal] = useState(false);
  const [declineReasonOther, setDeclineReasonOther] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [respondModal, setRespondModal] = useState(false);

  const deleteCallBack = () => {
    history.push("/change-phone-otp", {
      "phone_code": "92",
      phoneNumber: profile?.phone_no,
      "otp_method": "sms",
      "reason": declineReasonOther ? declineReasonOther : selectedValue
    }
    );
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setDeclineReasonOther("");
  };

  useEffect(() => {
    setQrCode(
      `http://api.qrserver.com/v1/create-qr-code/?data=${profile?.qr_code}&size=400x400&bgcolor=ffffff`
    );
  }, []);

  const getImage = () => {
    if (!ref.current) {
      return;
    }
    takeScreenshot(ref.current, {
      backgroundColor: null,
      logging: false,
    }).catch()
  };
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // if (!profile?.id) {
    dispatch(ProfileAction.GetProfile());
    // }
  }, []);

  return (
    <TitleLayout titleOnly={true} titleOnlyText="My Profile">
      <div className="myProfileMainWrapper">
        <FadeInComponent>
          <div>
            <img
              style={{
                height: 100,
                width: 100,
                borderRadius: 100,
              }}
              src={profile?.picture || metaData?.default_avatar}
              className="my-profile-image"
              alt=""
            />
          </div>
        </FadeInComponent>
        {

        }

        <FadeInComponent>
          <div className="my-profile-info">
            <CustomText.OpenSansBold
              textStyle={{
                fontSize: "0.875rem",
                color: Colors.Black,
              }}
            >
              {profile?.name}
            </CustomText.OpenSansBold>

            {/* <CustomText.OpenSansBold
              textStyle={{
                fontSize: "0.625rem",
                color: Colors.Grayish,
                marginTop: 4,
              }}
            >
              {profile?.user_professions?.length ? profile?.user_professions[0]?.name : ""}
            </CustomText.OpenSansBold> */}

            <div
              onClick={() => {
                history.push("edit-profile");
              }}
              className="edit-profile-button"
            >
              <span className="icons-edit editIcon"></span>
              <CustomText.OpenSansBold
                textStyle={{
                  fontSize: "0.75rem",
                  color: Colors.LightBlue,
                  fontWeight: "normal",
                  margin: "0 0 0 4px",
                  position: 'relative',
                  top: '2px'
                }}
              >
                Edit Profile
              </CustomText.OpenSansBold>
            </div>
          </div>
        </FadeInComponent>

        <div className="my-profile-details sectionBox">
          {/* added nic num field */}

          {/* <div className="detailsInfoWrap">
            <CustomText.OpenSansSemiBold
              textStyle={{
                fontSize: 14,
                color: Colors.Grayish,
              }}
            >
              CNIC
            </CustomText.OpenSansSemiBold>
            <div className="my-profile-info-text">
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: 16,
                  color: "#000",
                }}
              >
                {profile?.cnic}
              </CustomText.OpenSansRegular>
            </div>
          </div> */}
          <FadeInComponent>
            <div className="detailsInfoWrap">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                }}
              >
                Name
              </CustomText.OpenSansSemiBold>
              <div className="my-profile-info-text">
                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "0.875rem",
                    color: "#000",

                  }}
                >
                  {profile?.name}
                </CustomText.OpenSansRegular>
              </div>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div className="detailsInfoWrap">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                }}
              >
                City Name
              </CustomText.OpenSansSemiBold>
              <div className="my-profile-info-text">
                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "0.875rem",
                    color: "#000",

                  }}
                >
                  {metaData?.cities?.find(item => item?.id == profile?.city_id)?.label}
                </CustomText.OpenSansRegular>
              </div>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div className="detailsInfoWrap">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                }}
              >
                User Role
              </CustomText.OpenSansSemiBold>
              <div className="my-profile-info-text">
                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "0.875rem",
                    color: "#000",

                  }}
                >
                  {
                    // profile?.user_professions?.length > 0
                    //   ? 
                    profile?.user_professions?.map(
                      (item: any, index: number) => {
                        return (
                          <span key={index}>
                            {item.name}
                            {/* {index + 1 == profile?.user_professions?.length
                              ? ""
                              : ", "} */}
                          </span>
                        );
                      }
                    )
                    // : "--"
                  }
                </CustomText.OpenSansRegular>
              </div>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div className="detailsInfoWrap">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                }}
              >
                Email
              </CustomText.OpenSansSemiBold>
              <div className="my-profile-info-text">
                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "0.875rem",
                    color: "#000",

                  }}
                >
                  {profile?.email || "--"}
                </CustomText.OpenSansRegular>
              </div>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div className="detailsInfoWrap">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                }}
              >
                Company Name
              </CustomText.OpenSansSemiBold>
              <div className="my-profile-info-text">
                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "0.875rem",
                    color: "#000",

                  }}
                >
                  {profile?.company?.length ?
                    profile?.company[0]?.name
                    : profile?.company_name || "--"}

                  {/* {profile?.company_name || "--"} */}
                </CustomText.OpenSansRegular>
              </div>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div className="detailsInfoWrap">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                }}
              >
                Phone
              </CustomText.OpenSansSemiBold>
              <div className="my-profile-info-text">
                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "0.875rem",
                    color: "#000",

                  }}
                >
                  {/* +92{profile?.phone_no} */}
                  {`${`${phoneNumberFormater(profile?.phone_code, profile?.phone_no)}`} `}
                </CustomText.OpenSansRegular>
              </div>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            {profile?.whatsapp_phone_no ? (
              <div className="detailsInfoWrap">
                <CustomText.OpenSansSemiBold
                  textStyle={{
                    fontSize: "0.875rem",
                    color: Colors.Grayish,
                  }}
                >
                  Whatsapp Number
                </CustomText.OpenSansSemiBold>
                <div className="my-profile-info-text">
                  <CustomText.OpenSansRegular
                    textStyle={{
                      fontSize: "0.875rem",
                      color: "#000",

                    }}
                  >
                    {/* +92{profile?.whatsapp_phone_no} */}
                    {`${`${phoneNumberFormater(profile?.phone_code, profile?.whatsapp_phone_no)}`} `}
                  </CustomText.OpenSansRegular>
                </div>
              </div>
            ) : (
              <div className="detailsInfoWrap">
                <CustomText.OpenSansSemiBold
                  textStyle={{
                    fontSize: "0.875rem",
                    color: Colors.Grayish,
                  }}
                >
                  Whatsapp Number
                </CustomText.OpenSansSemiBold>
                <div className="my-profile-info-text">
                  <CustomText.OpenSansRegular
                    textStyle={{
                      fontSize: "0.875rem",
                      color: "#000",

                    }}
                  >
                    {/* +92{profile?.phone_no} */}
                    {`${`${phoneNumberFormater(profile?.phone_code, profile?.phone_no)}`} `}
                  </CustomText.OpenSansRegular>
                </div>
              </div>
            )}
          </FadeInComponent>
          {
            profile?.associate_emails?.length > 0 ?
              <FadeInComponent>
                <div className="detailsInfoWrap">
                  <CustomText.OpenSansSemiBold
                    textStyle={{
                      fontSize: "0.875rem",
                      color: Colors.Grayish,
                    }}
                  >
                    Added Email Addresses
                  </CustomText.OpenSansSemiBold>
                  <div className="smallGreyText mtopZero mbotCustom">These emails will be receiving a copy of Bid Results and Booking Confirmations.</div>
                  <div className="my-profile-info-text profileExtraEmailWrap">
                    <CustomText.OpenSansRegular
                      textStyle={{
                        fontSize: "0.875rem",
                        color: "#000",

                      }}
                    >
                      <ul>
                        {

                          profile?.associate_emails?.map((item) => {
                            return (
                              <li>{item?.email}</li>
                            )
                          })
                        }
                      </ul>
                      {/* {profile?.associate_emails || "--"} */}
                    </CustomText.OpenSansRegular>
                  </div>
                </div>
              </FadeInComponent>

              : null}

        </div>
        <div className="dividing-bordered"></div>

        <FadeInComponent>
          <div className="detailsInfoWrap">
            <CustomText.OpenSansSemiBold
              textStyle={{
                fontSize: "0.875rem",
                color: Colors.Grayish,
              }}
            >
              Account Settings
            </CustomText.OpenSansSemiBold>


          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="del-account" onClick={() => {
            toggleModal(!showModal);
          }}>
            <CustomText.OpenSansSemiBold
              textStyle={{
                fontSize: "0.875rem",
                color: Colors.Black,
                cursor: "pointer",

              }}
            >
              Share QR code
            </CustomText.OpenSansSemiBold>

            <ArrowForwardIosIcon />

          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="del-account" onClick={() => {
            history.push("/reset-password");
          }}>
            <CustomText.OpenSansSemiBold
              textStyle={{
                fontSize: "0.875rem",
                color: Colors.Black,
                cursor: "pointer",

              }}
            >
              Change Password
            </CustomText.OpenSansSemiBold>
            <ArrowForwardIosIcon

              style={{

              }} />
          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="del-account" onClick={() => {
            // setShowReasonModal(true)
            setRespondModal(true)

          }}>
            <CustomText.OpenSansSemiBold
              textStyle={{
                fontSize: "0.875rem",
                color: Colors.Red,
                cursor: "pointer",

              }}
            >
              Delete Account
            </CustomText.OpenSansSemiBold>
            <ArrowForwardIosIcon

              style={{
                color: "black",

              }} />
          </div>
        </FadeInComponent>

      </div>
      {/* <div className="profileBtnsWrap">
        <div>
          <OutLineButton
            buttonStyle={{
              height: 44,
              fontSize: 16,
            }}
            icon={Images.QRCode}
            title="Show QR Code"
            onClick={() => {
              toggleModal(!showModal);
            }}
          />
        </div>
        <div
          style={{
            marginTop: 20,
          }}
        >
          <PrimaryButton
            buttonStyle={{
              height: 44,
              fontSize: 16,
            }}
            title="Reset Password"
            onClick={() => {
              history.push("/reset-password");
            }}
          />
        </div>
      </div> */}
      <QrModal showModal={showModal} toggleModal={toggleModal}>
        <div className="qr-code-wrapper">
          <Close
            className="closeIcon"
            onClick={() => {
              toggleModal(!showModal);
            }}
          />
          <div className="modal-profile-container">
            <img
              style={{
                height: "100%",
                width: "100%",
              }}
              src={profile?.picture || Images.myProfileImage}
              alt=""
            />
          </div>
          <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>
            {profile?.name}
          </CustomText.OpenSansSemiBold>
          <CustomText.OpenSansRegular
            textStyle={{ fontSize: "0.625rem", marginTop: 4 }}
          >
            {profile?.user_profession}
          </CustomText.OpenSansRegular>
          <div className="qr-image-container">
            <div className="insideQRCodeContainer">
              <div className="qrImgContainer">
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  src={qrCode}
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* <PrimaryButton
            buttonStyle={{
              height: 36,
              width: 164,
              marginTop: 12,
            }}
            buttonTitleStyle={{
              fontSize: 14,
            }}
            title="Share"
            onClick={() => {}}
          /> */}
        </div>
      </QrModal>

      {/* Provide Reason Modal */}
      <CustomModal
        showModal={showReasonModal}
        toggleModal={setShowReasonModal}
        modalStyle={{ minHeight: "unset" }}
      >
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.125rem" }}>
            Please Provide Reason
          </CustomText.OpenSansSemiBold>
        </div>
        <div>

          {declineReasons?.map((reason, index) => {
            return (
              <div className="flexRow customFlexRow">
                <Radio
                  checked={selectedValue === reason}
                  onChange={handleChange}
                  value={reason}
                  onClick={() => { }}
                  style={{
                    color: "#7ac251",
                  }}
                />
                <CustomText.OpenSansRegular
                  textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
                >
                  {reason}
                </CustomText.OpenSansRegular>
              </div>
            );
          })}


          {selectedValue.toLowerCase() === "other" && (
            <div>
              <input
                placeholder="Give reason"
                type="text"
                className="input-box formControl"
                value={declineReasonOther}
                onChange={(e) => {
                  if (e.target.value.length <= 100) {
                    setDeclineReasonOther(e.target.value);
                  }
                  // setDeclineReasonOther(e.target.value);
                }}
              />
            </div>
          )}
        </div>
        <PrimaryButton
          onClick={() => {
            // 
            if ((selectedValue.length) && (selectedValue.toLowerCase() !== "other")) {
              // setRespondModal(true);
              setShowReasonModal(false);
              const payload = {
                phoneNumber: profile?.phone_no,
                type: "sms",
                delete_from: "delete",
                deleteCallBack,
              }
              dispatch(
                AuthAction.LogInWihtOtp(payload)
              );
            } else if (selectedValue.toLowerCase() === "other") {
              if (!declineReasonOther?.length) {
                showMessage.current({
                  message: "Please provide reason.",
                  status: "error",
                });
              } else {
                setRespondModal(true);
              }
            } else {
              showMessage.current({
                message: "Please select reason.",
                status: "error",
              });
            }
          }}
          buttonStyle={{
            marginTop: 10,
            marginBottom: 10,
            height: 40,
          }}
          title="Continue"
        // disabled={!declineReasonOther?.length}
        />

        <OutLineButton
          buttonStyle={{
            height: 44,
            fontSize: "0.875rem",
          }}
          title="Cancel"
          onClick={() => {
            setShowReasonModal(false)

          }}
        />
      </CustomModal>

      {/* Respond Delete Modal */}
      <CustomModal showModal={respondModal} toggleModal={setRespondModal}>
        <div
          style={{
            marginTop: 10,
            textAlign: "center",
          }}
        >
          <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.125rem" }}>
            Confirm Delete?
          </CustomText.OpenSansSemiBold>
          <CustomText.OpenSansRegular
            textStyle={{
              fontSize: "0.875rem",
              textAlign: "center",
              maxWidth: 250,
              padding: "0 25px",
              margin: "20px 0",
            }}
          >
            Upon deletion, you will lose all your data. This action cannot be undone. Please note that Meri Gari may retain some anonymized data for analytical purposes. Do you want to delete?
            {/* When You delete your account you will lose all your data,
            loads and saved locations if you have.The action cannot be undone. 
            Do you want to delete? */}
          </CustomText.OpenSansRegular>
        </div>
        <div className="modalFooterWrapper">

          <PrimaryButton
            buttonStyle={{
              backgroundColor: Colors.Red,
              height: 44,
              width: 330,
            }}
            onClick={() => {
              // setRespondModal(false);
              // const payload = {
              //   phoneNumber: profile?.phone_no,
              //   type: "sms",
              //   delete_from: "delete",
              //   deleteCallBack,
              // }
              // dispatch(
              //   AuthAction.LogInWihtOtp(payload)
              // );
              setRespondModal(false);
              setShowReasonModal(true)

            }}
            title="YES, DELETE"
          />
        </div>
      </CustomModal>
    </TitleLayout>
  );
};

export default Page;
