import React, { FC } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material/";
import { ExitToAppRounded, ExpandMore } from "@mui/icons-material";
import { AdditionalPickupDropoff, CustomText, LocationTimeline, OutLineButton } from "@temp/components";
import { useSelector } from "react-redux";
import { phoneNumberFormater } from "@temp/utils/helper";
export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 17px;
  border-radius: 10px;
`;

export const LocationDetails: FC<ConfirmLoadProps> = ({
  value,
  index,
  otherData,
  editable,
  additionalLocations,
  is_recipient,
  fromHistory
}: ConfirmLoadProps) => {

  const PostLoad = useSelector((state) => state?.Profile?.postLoad);
  const pickupDate = otherData?.pickupDate
    ? moment(otherData.pickupDate).format("MMM DD, YYYY")
    : "-";

  const pickupTime = otherData?.pickupTime
    ? moment(otherData?.pickupTime, "HH:mm:ss").format(
      "hh:mm A"
    )
    : null;


  const responseDate = otherData?.responseDate
    ? moment(otherData.responseDate).format("MMM DD, YYYY")
    : "-";

  const responseTime = otherData?.responseTime
    // ? moment(otherData.responseTime).format("hh:mm A")
    ? moment(otherData.responseTime, "HH:mm:ss").format("hh:mm A")
    : "-";


  const deliveryDate = otherData?.deliveryDate
    ? moment(otherData.deliveryDate).format("MMM DD, YYYY")
    : "-";

  const deliveryTime = otherData?.deliveryTime
    ? moment(otherData.deliveryTime, "HH:mm:ss").format("hh:mm A")
    : null;


  const emptyReturnDeadlineAtDate = otherData?.emptyReturnDeadlineAtDate
    ? moment(otherData.emptyReturnDeadlineAtDate).format("MMM DD, YYYY")
    : "-";

  const emptyReturnDeadlineAtTime = otherData?.emptyReturnDeadlineAtTime
    ? moment(otherData.emptyReturnDeadlineAtTime, "HH:mm:ss").format("hh:mm A")
    : null;

  const rateValidityDate = otherData?.rateValidityDate
    ? moment(otherData.rateValidityDate).format("MMM DD, YYYY")
    : "-";

  const FormRow = ({ label, value, sep, urdutitle, is_recipient, fromHistory }) => {
    return (
      <>
        <Grid item xs={12} className="formGrid">
          <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
          <span className="titleName">{label}</span>
          {
            fromHistory && is_recipient &&
            <>
              <span className="seperator">
                {sep}
              </span>
              <span className="urduLabel lhEdit">
                {urdutitle}
              </span>
            </>
          }
          </div>
          <div className="fw-5">{value}</div>
        </Grid>
      </>
    );
  };

  const history = useHistory();
  const onEdit = () => {
    if (PostLoad?.inquiry_type == 1) {
      history.push(`/spot-location/add-new`, { from: 'spot' });
    } else {
      history.push("/contract-location/add-new", { from: 'contract' });
    }
  };

  return (
    <>
      <Accordion className="confirm-load-accordion" style={{ backgroundColor: "#FAFAFA" }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className="fw-6">
            Location Details
            {
              fromHistory && is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">تفصیلات</span>
              </>
            }
          </p>
          {editable ? (
            <div className="editBtnWrapper">
              <OutLineButton
                classProp='tinyBtn'
                dataColor="blue"
                title="Edit"
                icon={
                  <span className="icons-edit"></span>
                }
                onClick={onEdit}
              />
            </div>
          ) : null}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} style={{ overflowY: "scroll" }}>
            <Grid item xs={12}>
              <p className="grayLabel">
                Location
                {
                  fromHistory && is_recipient &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">مقام</span>
                  </>
                }
              </p>
              <div className="load-detail-location-container">
                <div className="load-detail-location-info">
                  {value.length && (
                    <>
                      <div className="pickup">
                        <div className="load-detail-dots-container" style={{ width: '10%' }}>
                          <div className="load-detail-location-start">
                            <div className="load-detail-location-start-inner"></div>
                          </div>
                          <div className="load-detail-location-end">
                            <div className="load-detail-location-end-inner"></div>
                          </div>
                        </div>
                        <div>
                          <p className="title">{value[0]?.title || ""}</p>
                          <p className="address">{value[0]?.address}</p>
                          <div style={{ marginBottom: 35, marginTop: 15 }}>
                            {
                              value?.[0]?.contact_name?.length ? (
                                <div className="formGrid">
                                  <div className="grayLabel">
                                    Contact Name
                                    {
                                      fromHistory && is_recipient &&
                                      <>
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">رابطے کا نام</span>
                                      </>
                                    }
                                  </div>
                                  <div className="fw-5">
                                    {value?.[0]?.contact_name}
                                  </div>
                                </div>
                              ) : null
                            }
                            {
                              value?.[0]?.contact_phone_no?.length ? (
                                <div className="formGrid mb-0">
                                  <div className="grayLabel">
                                    Contact Number
                                    {
                                      fromHistory && is_recipient &&
                                      <>
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">رابطہ نمبر</span>
                                      </>
                                    }
                                  </div>
                                  <div className="fw-5">
                                    {
                                      phoneNumberFormater(value?.[0]?.contact_phone_code, value?.[0]?.contact_phone_no)
                                    }

                                  </div>
                                </div>
                              ) : null
                            }
                          </div>
                        </div>
                      </div>
                      <div className="dropoff">
                        <p className="title">{value[1]?.title || ""}</p>
                        <p className="address">{value[1]?.address}</p>
                        <div style={{ marginBottom: 35, marginTop: 15 }}>
                          {
                            value?.[1]?.contact_name?.length ? (
                              <div className="formGrid">
                                <div className="grayLabel">
                                  Contact Name
                                  {
                                    fromHistory && is_recipient &&
                                    <>
                                      <span className="seperator">/</span>
                                      <span className="urduLabel">رابطے کا نام</span>
                                    </>
                                  }
                                </div>
                                <div className="fw-5">
                                  {value?.[1]?.contact_name}
                                </div>
                              </div>
                            ) : null
                          }
                          {
                            value?.[1]?.contact_phone_no?.length ? (
                              <div className="formGrid mb-0">
                                <div className="grayLabel">
                                  Contact Number
                                  {
                                    fromHistory && is_recipient &&
                                    <>
                                      <span className="seperator">/</span>
                                      <span className="urduLabel">رابطہ نمبر</span>
                                    </>
                                  }
                                </div>
                                <div className="fw-5">
                                  {
                                    phoneNumberFormater(value?.[1]?.contact_phone_code, value?.[1]?.contact_phone_no)
                                  }

                                </div>
                              </div>
                            ) : null
                          }
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <AdditionalPickupDropoff
                additional_pickup={additionalLocations?.additional_pickup}
                additional_dropoffs={additionalLocations?.additional_dropoffs}
                type="received"
                recipient_status={{ status: 4 }}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />

            </Grid>
            {pickupDate && pickupTime ?
              <FormRow
                label="Pick Up Date & Time"
                value={pickupDate + " " + pickupTime}
                sep="/"
                urdutitle="پک اپ کی تاریخ اور وقت"
                is_recipient={is_recipient}
                fromHistory={fromHistory}

              /> :
              <FormRow
                label="Pick Up Date"
                value={pickupDate}
                sep="/"
                urdutitle="پک اپ کی تاریخ"
                is_recipient={is_recipient}
                fromHistory={fromHistory}

              />
            }

            <FormRow
              label={`${PostLoad?.transaction_type_id == 2 ? "Bidding" : "Offer"} Response Deadline`}
              value={responseDate + " " + responseTime}
              sep={"/"}
              urdutitle={"ریٹ دینے کی معیاد"}
              is_recipient={is_recipient}
              fromHistory={fromHistory}

            />

            {PostLoad?.responseDeadline?.deliveryDate ?
              <>
                {deliveryDate && deliveryTime ?
                  (
                    <FormRow
                      label="Delivery Deadline"
                      value={deliveryDate + " " + deliveryTime}
                      sep="/"
                      urdutitle="ڈیلیوری کی آخری تاریخ اور وقت"
                      is_recipient={is_recipient}
                      fromHistory={fromHistory}

                    />
                  ) :
                  <FormRow
                    label="Delivery Date"
                    value={deliveryDate}
                    sep="/"
                    urdutitle="ڈیلیوری کی آخری تاریخ"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}

                  />
                }
              </>
              : null}

            {PostLoad?.responseDeadline?.emptyReturnDeadlineAtDate ?
              <>
                {emptyReturnDeadlineAtDate && emptyReturnDeadlineAtTime ?
                  (
                    <FormRow
                      label="Empty Pickup / Return Deadline"
                      value={emptyReturnDeadlineAtDate + " " + emptyReturnDeadlineAtTime}
                      sep="/"
                      urdutitle={"ایم ٹی پک اپ / ڈراپ-آف  کی آخری تاریخ اور وقت "}
                      is_recipient={is_recipient}
                      fromHistory={fromHistory}

                    />
                  ) :
                  <FormRow
                    label="Empty Pickup / Return Deadline"
                    value={emptyReturnDeadlineAtDate}
                    sep="/"
                    urdutitle={"ایم ٹی پک اپ / ڈراپ-آف  کی آخری تاریخ اور وقت "}
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}

                  />
                }
              </>
              : null}



            {PostLoad?.responseDeadline?.rateValidityDate ?
              <FormRow
                label="Rate Validity"
                value={rateValidityDate}
                sep="/"
                urdutitle="کرائے کی معیاد"
                is_recipient={is_recipient}
                fromHistory={fromHistory}

              />
              : null}

            {PostLoad?.is_empty_return ?
              (
                <FormRow
                  label="Empty Pickup or Return Yard / City"
                  value={PostLoad?.empty_return_yard_name}
                  sep={"/"}
                  urdutitle={"ایم ٹی پک اپ / ڈراپ-آف یارڈ/شہر کا نام"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}

                />
              ) : null
            }

          </Grid>

        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LocationDetails;
