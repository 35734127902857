import * as React from "react";
import styled from "styled-components";
import {
  ComponentPropsWithRef,
  ElementType,
} from "react";

import { Label } from "../Label";
import CustomText from "../CustomText";

type InputSize = 'large' | 'medium' | 'small';
type BaseFieldProps = {
  /** Passing a label will also output Label component */
  label?: string;
  /** Controlled size of all Fields */
  size?: InputSize;
};

export type BaseFieldWithRef<T extends ElementType> = BaseFieldProps &
  Omit<ComponentPropsWithRef<T>, "size">;

export interface RadioButtonProps
  extends Omit<BaseFieldWithRef<"input">, "type"> { }

const RADIO_SIZE = 16;
const CHECK_SIZE = 8;
const CHECK_GAP = (RADIO_SIZE - CHECK_SIZE) / 2;

const RadioWrapper = styled.div`
  display: inline-block;
`;

const LabelRadioButton = styled(Label)`
  position: relative;
  cursor: pointer;
  display: inline-block;
  color: #000000;
  font-style: normal;
  font-size: 14px;
  /* margin-right: 70px; */
`;

const Mark = styled.span`
  display: inline-block;
  position: relative;
  width: ${RADIO_SIZE}px;
  height: ${RADIO_SIZE}px;
  top: 2px;
  left: 0px;
  border-radius: 50%;
  margin: 0px 5px 0px 5px;

  &:before {
    content: "";
    box-sizing: border-box;
    border: 2px solid #7AC251;
    width: ${RADIO_SIZE}px;
    height: ${RADIO_SIZE}px;
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
  }

  &:after {
    content: "";
    width: ${CHECK_SIZE}px;
    height: ${CHECK_SIZE}px;
    border-radius: 50%;
    background-color: #7AC251;
    opacity: 0;
    position: absolute;
    top: ${CHECK_GAP}px;
    left: ${CHECK_GAP}px;
  }
`;

const StyledRadioButton = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:hover + ${Mark} {
    &:after {
      opacity: 0.5;
    }
  }

  &:checked + ${Mark} {
    &:after {
      opacity: 1;
    }
  }
`;

const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ id, name, label, size, value, ...props }, ref) => {
    return (
      <RadioWrapper className={id === value ? 'active' : ''}>
        <LabelRadioButton className={id === value ? 'checked' : ''} htmlFor={props.id}>
          <StyledRadioButton
            checked={id === value}
            onChange={() => {
              props.onChange();
            }}
            onClick={() => {
              if(props?.onClick){
                props.onClick();
              }
            }}
            name={name}
            type="radio"
            // {...props}
            ref={ref}
          />
          <Mark />
          {label}
          {
            props?.extraText &&
            <span className="small">{`(${props?.extraText})`}</span>
          }
        </LabelRadioButton>
      </RadioWrapper>
    );
  }
);

export default RadioButton;
