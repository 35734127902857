import React from "react";
import { InquiryTypeProps } from "./types";

import Page from "./Page";

const View: React.FC<InquiryTypeProps> = ({
  bottomBadge = true,
}: InquiryTypeProps) => {
  return <Page bottomBadge= {bottomBadge} />;
};

export default View;
