import React, { FC, useState, useEffect, useRef } from "react";
import {
  AutioPlayer,
  CustomModal,
  CustomText,
  OutLineButton,
  PrimaryButton,
  ScreenHeader,
} from "@components";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import CategoryContainer from "./CategoryContainer";
import AdditionalDetails from "./AdditionalDetails";
import PaymentTerms from "./PaymentTerms";
import LoadDetails from "./LoadDetails";
import TransactionType from "./TransactionType";
import LocationDetails from "./LocationDetails";
import UploadedDocuments from "./UploadedDocuments";
import CargoContainer from "../AdditionalDetailsLclBulk/CargoDetailsCard";
import ContractDetails from "./ContractDetails";
import { LoadViewer } from "@temp/views/LoadDetail/components";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./style.scss";
import { CounterAtion, ProfileAction } from "@temp/store/actions";
import { ContractLocationDetails } from "./ContractLocationDetails";
import ReactAudioPlayer from "react-audio-player";
// import ReactAudioPlayer from "react-h5-audio-player"
import MicIcon from "@mui/icons-material/Mic";
import { isBrowser } from "react-device-detect";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import { showMessage } from "@temp/utils/helper";
import OutlineButton from "@temp/components/OutLineButton";
import domToPdf from 'dom-to-pdf';
import { UnitCategoryView } from "./UnitCategoryView";
import AudioAnalyser from "react-audio-analyser";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { Button, DialogTitle } from "@mui/material/";
import { Colors } from "@temp/assets";
import dayjs from "dayjs";
export interface ConfirmLoadProps {
  index: string;
  data?: any;
}

const MainContainer = styled.div`
  // height: 100vh;
  background-color: white;
  align-items: center;
`;

const DetailsContainer = styled.div`
  max-height: 90vh;
  min-height: 30vh;
  /* margin-bottom: 20px; */
`;

export const Image = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 70px;
  margin-top: 90px;
`;


export const ConfirmLoadScreen: FC<ConfirmLoadProps> = (
  props: ConfirmLoadProps
) => {
  const location = useLocation()
  const { state } = location
  const PostLoad = useSelector((state) => state?.Profile?.postLoad);

  const [additionalScreenData, setAdditionalScreenData] = useState([]);
  const [load, setLoad] = useState([]);
  const [loadData, setLoadData] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [contractDetails, setContractDetails] = useState(null);
  const [dateAndTime, setDateAndTime] = useState(null);
  const history = useHistory();
  const [instructions, setInstructions] = useState(PostLoad?.instructions || "");
  const [record_url, set_record_url] = useState(null)
  const [totalContainers, setTotalContainers] = useState(0);
  const [showModal, setShowModal] = useState(false)
  const [recordLimit, setRecordLimit] = useState(59)

  useEffect(() => {
    if (PostLoad) {
      let totalCat = 0;
      if (PostLoad?.vehicleScreen?.addedVehicles?.length > 0) {
        PostLoad?.vehicleScreen?.addedVehicles.forEach(element => {
          if (element?.values?.is_twin_load) {
            totalCat += Number(element.vehicle_quantity) * 2
          } else {
            totalCat += Number(element.vehicle_quantity)
          }
        }
        );
      }
      setTotalContainers(totalCat)
    }
  }, [PostLoad])

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isIOS17 = /iPad|iPhone|iPod/.test(userAgent) && /OS 17_/.test(userAgent);

    if (isIOS17) {
      setRecordLimit(9)
    }
  }, [])


  const onChange = (e) => {
    setInstructions(e.target.value);
  };

  useEffect(() => {
    if (PostLoad?.instructions_voice_note) {
      set_record_url(PostLoad?.instructions_voice_note)

    }
  }, [])

  useEffect(() => {
    if (PostLoad?.vehicleScreen?.addedVehicles) {
      setLoad(PostLoad?.vehicleScreen?.values);
      setLoadData(PostLoad);
      setVehicles(PostLoad?.vehicleScreen?.addedVehicles);
      setAdditionalScreenData(PostLoad?.additionalScreen);
      setDateAndTime(PostLoad?.responseDeadline);
    } else if (PostLoad?.location?.length > 0) {
      setLoad({
        ...load,
        is_covid_certf_req_driver:
          PostLoad?.contractDetails?.is_covid_certf_req_driver,
        is_hse_compliant_vehicle:
          PostLoad?.contractDetails?.is_hse_compliant_vehicle,
      });
      setLoadData(PostLoad);
      setAdditionalScreenData(PostLoad?.additionalScreen);
    }
    PostLoad.contractDetails && setContractDetails(PostLoad.contractDetails);
  }, []);

  const dispatch = useDispatch();
  const postLoadMeta = useSelector(
    (state: any) => state?.Profile?.postLoadMeta
  );

  const load_type = postLoadMeta.load_types.find(
    (val: any) => val.id === PostLoad.load_type
  );

  const inquiry_type_contract = postLoadMeta.inquiry_types.find(
    (val: any) => val.title === "Contract Rate"
  );

  const inquiry_type_spot = postLoadMeta.inquiry_types.find(
    (val: any) => val.title === "Spot Rate"
  );

  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [status, controlAudio] = useState('')

  // useEffect(() => {
  //   showMessage.current({
  //     message: `hello:  ${isActive}`
  //   })

  // }, [isActive])

  useEffect(() => {
    let intervalId;
    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);
        setCounter((counter) => counter + 1);

      }, 900);
    }

    return () => clearInterval(intervalId)

  }, [isActive, counter]);

  const stopTimer = () => {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");

  };

  var cb = (payload: any) => {
    set_record_url(payload)
  };

  const setUrlData = (data) => {
    let new_payload = {
      file_name: data?.fileName,
      cloudinary_url: data?.cloudinaryUrl,
      file_url: data?.fileUrl,
      mime_type: data?.mimeType
    }
    set_record_url(new_payload)
  }

  useEffect(() => {
    const target = message => {
      setUrlData(JSON.parse(message?.detail))
    }
    const target2 = message => {
      closeAccordion()
    }
    window.addEventListener("pdfDownloadComplete", target2);
    window.addEventListener("VoiceRecordComplete", target);
    return () => {
      if (!isBrowser) {
        if (sendEventToAndroid("closeRecording")) {
          window.Android.closeRecording(
            String("close")
          );
        } else if (sendEventToiOS("closeRecording")) {
          window.webkit.messageHandlers.closeRecording.postMessage(
            JSON.stringify({
              close: "close",
            })
          );
        } else {
        }
      }
      window.removeEventListener("VoiceRecordComplete", (e) => { })
      window.removeEventListener("pdfDownloadComplete", (e) => { })
      controlAudio('inactive')
      stopTimer()
    }
  }, [])

  const downloadRef = useRef(null);
  const [toShowAccordion, setToShowAccordion] = useState(true)


  const showAccordion = () => {
    document.querySelector('.confirmLoadWrapper').classList.add('activePrint');
    document.querySelector('.confirm-main-div').classList.remove('confirm-load-wrapper-div')
    if (state?.from_load_history) {
      document.querySelector('.confirmLoadWrapper').classList.remove('confirm_load')
    }
    // setToShowAccordion(true)
  }

  const closeAccordion = () => {
    document.querySelector('.confirmLoadWrapper').classList.remove('activePrint');
    document.querySelector('.confirm-main-div').classList.add('confirm-load-wrapper-div')
    if (state?.from_load_history) {
      document.querySelector('.confirmLoadWrapper').classList.add('confirm_load')
    }
    // setToShowAccordion(false)

  }

  const generatePdf = () => {
    const options = {
      filename: `New Load`
    };
    domToPdf(downloadRef?.current, options, () => {
    });
    setTimeout(() => {
      closeAccordion()
    }, 1000);
  };

  const audioProps = {
    audioType: "audio/wav",
    status,
    timeslice: 1000,
    // onPlay: (e: Event) => { },
    startCallback: e => {
    },
    pauseCallback: e => {
    },
    // onPause: (e: Event) => { },

    stopCallback: e => {
      // onEnded: e => {
      const formdata = new FormData()
      formdata.append('file', e)
      dispatch(ProfileAction.uploadFile({ data: formdata, cb }))
    },
    // onRecordCallback: (e: Event) => {
    // },
    onClickPrevious: e => { },
    onPlayError: e => { },

    // errorCallback: (e: Event) => {
    // }
  };

  useEffect(() => {
    if (second >= recordLimit) {
      controlAudio("inactive")
      stopTimer()
      if (!isBrowser) {
        if (sendEventToAndroid("voiceRecordStop")) {
          window.Android.voiceRecordStop(
            String("stop")
          );
        } else if (sendEventToiOS("voiceRecordStop")) {
          window.webkit.messageHandlers.voiceRecordStop.postMessage(
            JSON.stringify({
              start: "stop",
            })
          );
        } else {
        }
      }
    }
  }, [second])


  return (
    <MainContainer>
      <DetailsContainer>
        <div ref={downloadRef} className="pad15">
          <ScreenHeader
            pageNumber={9}
            fromNoti={state?.fromNoti ? true : false}
            progress={90}
            titleOnly={state?.from_load_history ? true : false}
            title={!state?.from_load_history ? `Load Summary - ${load_type?.title}` : 'Load Detail History'}
            titleOnlyText={state?.from_load_history && PostLoad?.inquiry_type == 1 ? `Load ID - ${PostLoad?.load_id}`
              : state?.from_load_history && PostLoad?.inquiry_type == 2 ? `Contract ID - ${PostLoad?.load_id}`
                : null}
            extraComponent={
              PostLoad?.inquiry_type == 1 ?
                <OutlineButton
                  classProp='tinyBtn mb-0'
                  dataColor="green"
                  title="Export"
                  icon={
                    <span className="icons-export"></span>
                  }
                  buttonStyle={{
                    marginLeft: '1rem',
                    marginBottom: '0 !important',
                  }}
                  onClick={() => {
                    showAccordion()
                    if (isBrowser) {
                      generatePdf();
                    } else {
                      if (sendEventToAndroid("loadDetailPDF")) {
                        window.Android.loadDetailPDF(
                          String('')
                        );
                      } else if (sendEventToiOS("loadDetailPDF")) {
                        window.webkit.messageHandlers.loadDetailPDF.postMessage(
                          JSON.stringify({
                            url: "",
                          })
                        );
                      }
                    }
                  }}
                /> : null
            }
          />
          <div className={`confirmLoadWrapper ${state?.from_load_history ? "confirm_load" : 'Load Summary'}`}>
            <div className="confirm-main-div confirm-load-wrapper-div">
              {
                !state?.from_load_history ?
                  <div style={{ width: "100%", marginTop: '1rem' }}>
                    <FadeInComponent is_apply={toShowAccordion ? false : true}>
                      <p className="simpleLabel fw-5">Special Instructions / Requirements</p>
                      <div className="textareaWrapper">
                        <textarea
                          style={{
                            outline: "none",
                            marginBottom: '1.5rem'
                          }}
                          maxLength={250}
                          value={instructions}
                          onChange={onChange}
                          placeholder="Enter Special Instructions"
                        />
                      </div>
                    </FadeInComponent>
                    <FadeInComponent is_apply={toShowAccordion ? false : true}>
                      <div>
                        <p className="simpleLabel fw-5">
                          Record Voice Note
                        </p>
                        {
                          recordLimit === 9 &&
                          <div className="error-container info">
                            <div>
                              <InfoIcon className="error-icon" />
                            </div>
                            <div className="error-text">
                              In iOS 17 limits voice recording to 9 seconds or less
                            </div>
                          </div>
                        }
                        <AudioAnalyser {...audioProps}
                        >
                        </AudioAnalyser>
                        <div className="recorderWrapper">
                          {
                            isActive ?
                              <div className="recording">
                                <div className="recordTime">
                                  <label>
                                    {minute}
                                    <span>:</span>
                                    {second}{" "}
                                  </label>
                                  {" | Start Recording ..."}
                                </div>
                                <div
                                  className="icon"
                                  onClick={() => {
                                    if (!isBrowser) {
                                      if (sendEventToAndroid("voiceRecordStop")) {
                                        window.Android.voiceRecordStop(
                                          String("stop")
                                        );
                                      } else if (sendEventToiOS("voiceRecordStop")) {
                                        window.webkit.messageHandlers.voiceRecordStop.postMessage(
                                          JSON.stringify({
                                            start: "stop",
                                          })
                                        );
                                      } else {
                                      }
                                    }
                                    controlAudio("inactive")
                                    stopTimer()
                                  }}
                                >
                                  <MicIcon />
                                </div>
                              </div>
                              :
                              <div>
                                {record_url ? (
                                  <div className="recorder">
                                    {/* <ReactAudioPlayer
                                      style={{
                                        marginTop: 10
                                      }}
                                      src={record_url?.cloudinary_url}
                                      autoPlay={false}
                                      controls
                                      loop={false}
                                    /> */}
                                    <AutioPlayer url={record_url?.cloudinary_url} />
                                    <span
                                      className="icons-cross"
                                      onClick={() => {
                                        set_record_url(null)
                                        showMessage.current({
                                          message: "Voice note deleted successfully.",
                                          status: 'success'
                                        })
                                      }}
                                    ></span>
                                  </div>
                                ) : (
                                  <div className="startRecord">
                                    <label>
                                      Start Recording
                                    </label>
                                    <div
                                      style={{
                                        cursor: "pointer"
                                      }}
                                      onClick={() => {
                                        if (isBrowser) {
                                          navigator.permissions.query({ name: 'microphone' }).then(function (permissionStatus) {
                                            if (permissionStatus?.state === "denied") {
                                              showMessage.current({ message: 'Please allow your mic permission to record audio', status: 'error' })
                                            }
                                            if (permissionStatus?.state !== "granted") {
                                              navigator.mediaDevices
                                                .getUserMedia({ video: false, audio: true })
                                                .then((stream) => {
                                                  setTimeout(() => {
                                                    controlAudio('recording')
                                                  }, 2000);
                                                  setIsActive(!isActive);
                                                })
                                                .catch((err) => {
                                                  console.error(`you got an error: ${err}`);
                                                });
                                            } else {
                                              setTimeout(() => {
                                                controlAudio('recording')
                                              }, 2000);
                                              setIsActive(!isActive);
                                            }
                                          })
                                        } else {
                                          setIsActive(true)
                                          setTimeout(() => {
                                            if (sendEventToAndroid("voiceRecordStart")) {
                                              window.Android.voiceRecordStart(
                                                String("start")
                                              );

                                            } else if (sendEventToiOS("voiceRecordStart")) {
                                              window.webkit.messageHandlers.voiceRecordStart.postMessage(
                                                JSON.stringify({
                                                  start: "start",
                                                })
                                              );
                                            }
                                          }, 2000);
                                        }
                                      }}
                                    >
                                      <MicIcon />
                                    </div>
                                  </div>
                                )}

                              </div>
                          }
                        </div>
                      </div>
                    </FadeInComponent>
                  </div> : null
              }

              <FadeInComponent is_apply={toShowAccordion ? false : true}>
                <LoadDetails
                  loadName={load}
                  loadData={loadData}
                  editable={state?.from_load_history ? false : true}
                  totalContainers={totalContainers}
                  fromHistory={state?.from_load_history ? true : false}
                  is_recipient={state?.is_recipient ? true : false}

                />
              </FadeInComponent>

              {inquiry_type_spot?.id === PostLoad?.inquiry_type && (
                <FadeInComponent is_apply={toShowAccordion ? false : true}>
                  <LocationDetails
                    editable={state?.from_load_history ? false : true}
                    value={PostLoad.location ? PostLoad.location : []}
                    otherData={dateAndTime}
                    additionalLocations={{
                      additional_pickup: PostLoad?.additional_pickups,
                      additional_dropoffs: PostLoad?.additional_dropoffs
                    }}
                    fromHistory={state?.from_load_history ? true : false}
                    is_recipient={state?.is_recipient ? true : false}
                  />
                </FadeInComponent>
              )}

              {inquiry_type_contract?.id === PostLoad?.inquiry_type && contractDetails &&
                <FadeInComponent is_apply={toShowAccordion ? false : true}>
                  <ContractDetails
                    value={contractDetails}
                    editable={state?.from_load_history ? false : true}
                    fromHistory={state?.from_load_history ? true : false}
                    is_recipient={state?.is_recipient ? true : false}
                  />
                </FadeInComponent>
              }
              <FadeInComponent is_apply={toShowAccordion ? false : true}>

                <PaymentTerms
                  editable={state?.from_load_history ? false : true}
                  fromHistory={state?.from_load_history ? true : false}
                  is_recipient={state?.is_recipient ? true : false}
                  loadDetail={state?.loadDetail}
                />
              </FadeInComponent>

              <FadeInComponent is_apply={toShowAccordion ? false : true}>
                <AdditionalDetails
                  inquiryType={PostLoad?.inquiry_type}
                  loadType={load_type}
                  value={additionalScreenData}
                  otherData={load}
                  editable={state?.from_load_history ? false : true}
                  fromHistory={state?.from_load_history ? true : false}
                  is_recipient={state?.is_recipient ? true : false}
                  loadDetail={state?.loadDetail}

                />
              </FadeInComponent>

              {inquiry_type_spot?.id === PostLoad?.inquiry_type && (
                <FadeInComponent is_apply={toShowAccordion ? false : true}>
                  <TransactionType
                    editable={state?.from_load_history ? false : true}
                    fromHistory={state?.from_load_history ? true : false}
                    is_recipient={state?.is_recipient ? true : false}
                  />
                </FadeInComponent>
              )}

              {inquiry_type_contract?.id === PostLoad?.inquiry_type && (
                <FadeInComponent is_apply={toShowAccordion ? false : true}>
                  <ContractLocationDetails
                    locations={PostLoad.location ? PostLoad.location : []}
                    otherData={dateAndTime}
                    editable={state?.from_load_history ? false : true}
                    // fromHistory={state?.from_load_history}
                    fromHistory={state?.from_load_history ? true : false}
                    is_recipient={state?.is_recipient ? true : false}
                  />
                </FadeInComponent>
              )}

              {/* !PostLoad?.vehicleScreen?.is_unit_basis && */}
              {PostLoad?.vehicleScreen?.values?.quote_by &&
                PostLoad?.vehicleScreen?.values?.quote_by === "weight_volume" && !PostLoad?.vehicleScreen?.is_unit_basis && (
                  <FadeInComponent is_apply={toShowAccordion ? false : true}>
                    <CargoContainer
                      value={PostLoad?.vehicleScreen?.values}
                      title={load_type.title}
                      screen={"confirm_load"}
                      vehicleScreenData={PostLoad?.vehicleScreen || null}
                      otherData={PostLoad}
                      // fromHistory={state?.from_load_history}
                      editable={state?.from_load_history ? false : true}
                      fromHistory={state?.from_load_history ? true : false}
                      is_recipient={state?.is_recipient ? true : false}
                    />
                  </FadeInComponent>
                )}
              {!!vehicles.length &&
                vehicles.map((val, index) => (
                  <div key={index}>
                    <FadeInComponent is_apply={toShowAccordion ? false : true}>
                      <CategoryContainer
                        fromConfirm={true}
                        value={val}
                        index={index}
                        otherData={PostLoad}
                        // fromHistory={state?.from_load_history}
                        categoryLength={vehicles.length}
                        accordianMode={true}
                        editable={state?.from_load_history ? false : true}
                        fromHistory={state?.from_load_history ? true : false}
                        is_recipient={state?.is_recipient ? true : false}
                      />
                    </FadeInComponent>
                  </div>
                ))}

              {
                PostLoad?.vehicleScreen?.is_unit_basis &&
                PostLoad?.vehicleScreen?.added_units.map((item, index) => {
                  return (
                    <div key={index}>
                      <FadeInComponent is_apply={toShowAccordion ? false : true}>
                        <UnitCategoryView
                          value={item}
                          index={index}
                          added_units={PostLoad?.vehicleScreen?.added_units}
                          // fromHistory={state?.from_load_history}
                          fromConfirm={true}
                          otherData={PostLoad}
                          editable={state?.from_load_history ? false : true}
                          fromHistory={state?.from_load_history ? true : false}
                          is_recipient={state?.is_recipient ? true : false}
                        />
                      </FadeInComponent>
                    </div>
                  )
                })
              }

              <FadeInComponent is_apply={toShowAccordion ? false : true}>
                <UploadedDocuments
                  value={PostLoad}
                  editable={state?.from_load_history ? false : true}
                  fromHistory={state?.from_load_history ? true : false}
                  is_recipient={state?.is_recipient ? true : false}
                />
              </FadeInComponent>
              
              {
                state?.from_load_history &&
                <div>
                  <LoadViewer
                    showViewer={state?.loadDetail?.total_awarded > 0}
                    // addViewer={addViewer}
                    loadDetail={state?.loadDetail}
                    fromHistory={state?.from_load_history ? true : false}
                  />
                </div>
              }

            </div>

            {
              !state?.from_load_history ?
                <FadeInComponent is_apply={toShowAccordion ? false : true}>
                  <div className="bottomBtnContainer addPadBot">
                    <OutLineButton
                      title="Cancel"
                      disabled={false}
                      onClick={() => {
                        setShowModal(true)
                      }}
                    />
                    <PrimaryButton
                      title={
                        inquiry_type_contract?.id === PostLoad?.inquiry_type
                          ? "Confirm Request"
                          : "Confirm Load"
                      }
                      rightIcon={true}
                      onClick={() => {
                        dispatch(
                          ProfileAction.postLoadData({
                            instructions,
                            instructions_voice_note: record_url,
                          })
                        );
                        history.push("selectRecipient");
                      }}
                    />
                  </div>
                </FadeInComponent>
                : null
            }

          </div>
        </div>
      </DetailsContainer >
      <CustomModal
        showModal={showModal}
        toggleModal={setShowModal}
        modalStyle={{
          width: 290,
          minHeight: 180
        }}
      >
        <div className="logout-modal-background">
          <DialogTitle id="simple-dialog-title">
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "1.25rem", textAlign: "center" }}
            >
              Cancel
            </CustomText.OpenSansSemiBold>
            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginTop: 20,
                width: 266
              }}
            >
              {
                PostLoad?.inquiry_type == 1 ? "Are you sure you want to cancel this Spot Load?"
                  : "Are you sure you want to cancel this Contract?"
              }

            </CustomText.OpenSansRegular>
            <div style={{ width: 260, justifyContent: 'space-around' }} className="action-button-container">
              <Button
                onClick={() => {
                  setShowModal(false);
                }}
                style={{
                  backgroundColor: Colors.Grayish,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  No
                </CustomText.OpenSansSemiBold>
              </Button>
              <Button
                onClick={() => {
                  history.push("/home");
                  dispatch(ProfileAction.postLoadDataRemove());
                  dispatch(CounterAtion.resetPostloadCounterData())
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  Yes
                </CustomText.OpenSansSemiBold>
              </Button>
            </div>
          </DialogTitle>
        </div>
      </CustomModal>
    </MainContainer >
  );
};


