import React, { FC, useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CustomizedSelectsProps } from "./types"; 
import "./style.scss";


const CustomizedSelects: FC<CustomizedSelectsProps> = ({
  disabled,
  selectedValue,
  values,
  handleModal,
  placeholder,
  modalState,
  classProp,
}: CustomizedSelectsProps) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    const selectedOption = values?.find((val) => val?.id === selectedValue);
    setValue(selectedOption?.title || "");
  }, [selectedValue, values]);

  return (
    <div onClick={() => handleModal(true)}>
      {modalState ? (
        <div className={`customSelectBox ${classProp}`}>
          {value || placeholder} <ArrowDropDownIcon />
        </div>
      ) : (
        <Select
          value={selectedValue || ""}
          input={<InputBase style={{ width: "100%" }} />}
          disabled={disabled}
        >
          <MenuItem value={null}>{placeholder || ""}</MenuItem>
          {values.length &&
            values.map((value: { id: string; title: string }, index: number) => (
              <MenuItem key={index} value={value?.id}>
                {value?.title}
              </MenuItem>
            ))}
        </Select>
      )}
    </div>
  );
};

export default CustomizedSelects;