import { Images } from "@temp/assets";
import { CustomText } from "@temp/components";
import React from "react";
import "../LoadDetailPoster/style.scss";

export const VehicleCounterContract = ({ recipient, style = {}, from_spot = true }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...style,
      }}
    >
      <div className="blue-truck-container">
        <img src={Images.TruckBlue} alt="" />
      </div>
      <CustomText.OpenSansRegular
        textStyle={{
          marginLeft: 5,
        }}
      >
        {recipient?.bid?.awarded_no_of_vehicles
          ? recipient?.bid?.awarded_no_of_vehicles
          : recipient?.bid?.no_of_vehicles}
      </CustomText.OpenSansRegular>
    </div>
  );
};
