import { AuthAction } from "../actions";

const INITIAL_STATE = {
  user: {},
  isLoading: false,
  signupData: {
    fullName: "",
    aliasName: "",
    frontImage: "",
    backImage: "",
    cnic_front: null,
    cnic_back: null,
  },
  attempt: 0,
  metaData: {},
  native_token: "",
  firstLogIn: null,
  logout_from_all_devices:false,
  notiUrl:null
};

function Reducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case AuthAction.TOGGLE_LOADER:
      return { ...state, isLoading: false }
    case AuthAction.SET_NATIVE_TOKEN:
      return { ...state, native_token: action?.payload };
    // login
    case AuthAction.LOGIN_WITH_OTP:
      return { ...state, isLoading: true };
    case AuthAction.LOGIN_WITH_OTP_SUCCESS:
      return { ...state, isLoading: false };
    case AuthAction.LOGIN_WITH_OTP_FAIL:
      return { ...state, isLoading: false };

    // signup
    case AuthAction.SIGNUP:
      return { ...state, isLoading: true };
    case AuthAction.SIGNUP_SUCCESS:
      return { ...state, isLoading: false };
    case AuthAction.SIGNUP_FAIL:
      return { ...state, isLoading: false };

    // verify code
    case AuthAction.VERIFY_CODE:
      return { ...state, isLoading: true };
    case AuthAction.VERIFY_CODE_SUCCESS:
      return { ...state, isLoading: false };
    case AuthAction.VERIFY_CODE_FAIL:
      return { ...state, isLoading: false };

    // verify code
    case AuthAction.LOGIN_WITH_PASSWORD:
      return { ...state, isLoading: true };
    case AuthAction.LOGIN_WITH_PASSWORD_SUCCESS:
      return { ...state, isLoading: false };
    case AuthAction.LOGIN_WITH_PASSWORD_FAIL:
      return { ...state, isLoading: false };

    // set signup data
    case AuthAction.SET_SIGNUP_DATA:
      return { ...state, signupData: action.payload };

    //reset Password

    case AuthAction.RESET_PASSWORD:
      return { ...state, isLoading: true };
    case AuthAction.RESET_PASSWORD_SUCCESS:
      return { ...state, isLoading: false };
    case AuthAction.RESET_PASSWORD_FAIL:
      return { ...state, isLoading: false };

    //set reset passwordRegex

    case AuthAction.SET_ATTEMPT:
      let new_data = state.attempt;
      new_data++;
      return { ...state, attempt: action.payload.attempt ? new_data : 0 };

    case AuthAction.LOG_OUT:
      return { ...state, isLoading: true };
    case AuthAction.LOG_OUT_SUCCESS:
      return { ...state, isLoading: false };
    case AuthAction.LOG_OUT_FAIL:
      return { ...state, isLoading: false };

    case AuthAction.UPDATE_TERMS_AND_CONDITION:
      return { ...state, isLoading: true };
    case AuthAction.UPDATE_TERMS_AND_CONDITION_SUCCESS:
      return { ...state, isLoading: false };
    case AuthAction.UPDATE_TERMS_AND_CONDITION_FAIL:
      return { ...state, isLoading: false };

    case AuthAction.GET_META_DATA:
      return { ...state, isLoading: true };
    case AuthAction.GET_META_DATA_SUCCESS:
      return { ...state, metaData: action.payload, isLoading: false };
    case AuthAction.GET_META_DATA_FAIL:
      return { ...state, isLoading: false };

    case AuthAction.SETUP_PASSWORD:
      return { ...state, isLoading: true };
    case AuthAction.SETUP_PASSWORD_SUCCESS:
      return { ...state, isLoading: false };
    case AuthAction.SETUP_PASSWORD_FAIL:
      return { ...state, isLoading: false };

    case AuthAction.FORGOT_PASSWORD:
      return { ...state, isLoading: true };
    case AuthAction.FORGOT_PASSWORD_SUCCESS:
      return { ...state, isLoading: false };
    case AuthAction.FORGOT_PASSWORD_FAIL:
      return { ...state, isLoading: false };

    case AuthAction.RESET_STORE:
      return INITIAL_STATE;

    case AuthAction.LOCATION_LOADER_SHOW:
      return { ...state, isLoading: action.payload ? true : false };

    case AuthAction.FIRST_LOGIN:
      return { ...state, isLoading: true };
    case AuthAction.FIRST_LOGIN_SUCCESS:
      return { ...state, isLoading: false, };
    case AuthAction.FIRST_LOGIN_FAIL:
      return { ...state, isLoading: false };

    case AuthAction.DEL_ACCOUNT:
      return { ...state, isLoading: true, };
    case AuthAction.DEL_ACCOUNT_SUCCESS:
      return { ...state, profile: action.payload, isLoading: false };
    case AuthAction.DEL_ACCOUNT_FAIL:
      return { ...state, isLoading: false };

      case AuthAction.SET_NOTI_URL:
        return { ...state, notiUrl:action?.payload };

    case AuthAction.LOGOUT_FROM_ALL_DEVICES:
      return {...state, logout_from_all_devices:action.payload}
    default:
      return state;
  }
}

export default Reducer;
