import React, { useState } from "react";
import styled from "styled-components";
import "./style.scss";
import { CustomizedSwitches, TextInput } from "@components";
import { useSelector } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material/";
import { ExpandMore } from "@mui/icons-material";
export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 100%;
  background-color: rgb(250, 250, 250);
  margin-bottom: 20px;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 0 0 !important;
`;
var numeral = require("numeral");

export const CategoryUnitCard = ({
  title,
  value,
  index,
  onChangeValue,
  added_units,
}) => {
  const FormRow = ({ label, value }) => {
    return (
      <div className="formGrid">
        <div className="grayLabel">{label}</div>
        <div className="fw-5">{value}</div>
      </div>
    );
  };
  const [isExpanded, setIsExpanded] = useState(true);

  const handleChange = (check) => {
    setIsExpanded(check);
  };
  const SwitchableWithText = (props: any) => {
    return (
      <Grid
        container
        spacing={1}
        style={{ marginBottom: 8, alignItems: "center" }}
        className="SwitchableWithText"
      >
        <Grid item xs={8} className="clr-label mb-0">
          <div style={{ marginTop: 0 }}>{props?.text}</div>
        </Grid>

        <Grid
          item
          xs={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div style={{ marginTop: 0 }}>
            <CustomizedSwitches {...props} />
          </div>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Maincontainer>
        <Accordion
          expanded={isExpanded}
          onChange={() => handleChange(!isExpanded)}
          className="offerAccordionContainer"
          style={{ backgroundColor: "rgb(250,250,250)" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="fw-6">
              {added_units.length > 1
                ? `Item Category ${index + 1}`
                : "Item Category"}
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0, paddingBottom: 10 }}>
            <Grid
              container
              spacing={1}
              style={{ overflowY: "scroll", margin: 0 }}
            >
              {
                value?.gross_weight && (
                  <FormRow
                    label={"Gross Weight in Ton"}
                    value={`${numeral(value?.gross_weight).format('0,0.00')} TON`}
                  />
                )
              }
              <FormRow
                label="Dimensions"
                value={`${value?.dimensions} ${value?.unit_dimension_value}`}
              />

              <FormRow label="Item Quantity" value={value?.unit_quantity} />

              {value?.unit_description ? (
                <FormRow
                  label="Item Description"
                  value={value?.unit_description}
                />
              ) : null}

              <Grid item xs={12}>
                <SwitchableWithText
                  id={"is_loading_required"}
                  handleChange={(e) => {
                    onChangeValue(
                      index,
                      "is_loading_required",
                      e.target.checked
                    );

                    onChangeValue(index, "loading_requirements", "");
                    onChangeValue(index, "loading_price", 0);
                  }}
                  checked={value?.is_loading_required || false}
                  name="is_loading_required"
                  text="Loading Required"
                />
                {value?.is_loading_required ? (
                  <div
                    className="inputField"
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    <div className="label">
                      Specify Labour / Equipment Requirement
                    </div>
                    <div className="field">
                      <TextInput
                        onChange={(e) => {
                          onChangeValue(
                            index,
                            "loading_requirements",
                            e.target.value
                          );
                        }}
                        type="text"
                        value={value?.loading_requirements || ""}
                        aria-describedby="standard-weight-helper-text"
                        placeholder="Optional"
                      />
                    </div>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <SwitchableWithText
                  id={"is_unloading_required"}
                  checked={value?.is_unloading_required || false}
                  handleChange={(e) => {
                    onChangeValue(
                      index,
                      "is_unloading_required",
                      e.target.checked
                    );
                    onChangeValue(index, "unloading_price", 0);

                    onChangeValue(index, "unloading_requirements", "");
                  }}
                  name="is_unloading_required"
                  text="Unloading Required"
                />
                {value?.is_unloading_required ? (
                  <div
                    className="inputField"
                    style={{ marginTop: "1rem" }}
                  >
                    <div className="label">
                      Specify Labour / Equipment Requirement
                    </div>
                    <div className="field">
                      <TextInput
                        onChange={(e) => {
                          onChangeValue(
                            index,
                            "unloading_requirements",
                            e.target.value
                          );
                        }}
                        type="text"
                        value={value?.unloading_requirements || ""}
                        aria-describedby="standard-weight-helper-text"
                        placeholder="Optional"
                      />
                    </div>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Maincontainer>
    </>
  );
};
