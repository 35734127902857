import React, { useState, useEffect } from "react";
import { IPromotionsProps } from "./types";
import { Colors, Images } from "@temp/assets";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { DrawerAction } from "@temp/store/actions";
import { BackHeader, ButtomTabs, CustomText, DrawerNav, TitleLayout } from "@components";
import styled from "styled-components";
import rootReducer from "./../../store/reducers";
import { CardComponent } from "./Card";
import { useHistory } from "react-router";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";

const CardConatiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<IPromotionsProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const promotions =
    useSelector((state: RootState) => state.Drawer.promotions) || [];
  
  useEffect(() => {
    dispatch(DrawerAction.getPromotions());
  }, []);
  const handleRoute = (e) => {
    e.preventDefault();
    history.push("/home");
  };
  return (

    <div className="root-wrapper networkMainWrapper networksContainer promotionContainer">
      <div className="root-center-container">
        {/* <div className="menu-bar">
          <div className="insideMenuBar">
            <DrawerNav />
            <p className="title">
              Promotions
            </p>
          </div>
        </div> */}
         <BackHeader
          onClick={() => {
            history.goBack();
          }}
          title={'Promotions'}
        />
        <div className="networkContentWrap scrollableDiv">
          <div className="network-wrapper">
            {promotions?.length ? (
              <FadeInComponent>
                <CardComponent promotionsArray={promotions} />
              </FadeInComponent>
            ) : <div>
              <CustomText.OpenSansRegular>No data found.</CustomText.OpenSansRegular>
            </div>}
          </div>
        </div>
      </div>
      {/* <ButtomTabs activeTab="promotions" /> */}
    </div>
  );
};

export default Page;
