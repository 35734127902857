import React, { useState, useEffect } from "react";
import { INetworkPageProps } from "./types";
import { Colors, Images } from "@temp/assets";
import "./style.scss";
import {
  CustomText,
  ButtomTabs,
  DrawerNav,
  CustomModal,
  PrimaryButton,
  OutLineButton
} from "@components";
import AddIcon from "@mui/icons-material/Add";
import rootReducer from "../../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
export type RootState = ReturnType<typeof rootReducer>;
import { DrawerAction } from "@temp/store/actions";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
// import QrReader from "modern-react-qr-reader";
import { useHistory } from "react-router";
import EmptyPlaceholder from "@temp/components/EmptyPlaceholder/EmptyPlaceholder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DialogTitle from "@mui/material//DialogTitle";
import { Button } from "@mui/material/";
import { phoneNumberFormater, showMessage, triggerMixpanelEvent } from "@utils/helper";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { Mixpanel } from "@temp/utils/mixpanel";
import { isBrowser } from "react-device-detect";

var searchFlag = false
const delay = 500;
const previewStyle = {
  height: 380,
  width: 320,
};
const Page: React.FC<INetworkPageProps> = (props) => {
  const [showModal, toggleModal] = useState(false);
  const [appearModal, setAppearModal] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const networkData = useSelector((state: RootState) => state.Drawer.networkData) || [];

  

  // const contactsHaveCompany = networkData.filter(
  //   (val) => val?.networkUser?.company && val?.networkUser?.company?.length
  // );
  // const contactsHaveNoCompany = networkData.filter(
  //   (val) =>
  //     (val?.networkUser?.company && val?.networkUser?.company?.length === 0) ||
  //     !val?.networkUser?.company
  // );
  // const sortByCompanyName = contactsHaveCompany.length
  //   ? contactsHaveCompany.sort(
  //     (a, b) =>
  //       a?.networkUser?.company[0]?.name - b?.networkUser?.company[0]?.name
  //   )
  //   : [];
  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
  const mg_admin = metaData?.mg_recipient?.PHONE_NO;

  const contacts = [...networkData];
  if (contacts.length) {
    let find_index_mg_approved = contacts.findIndex(item => item?.networkUser?.phone_no == mg_admin)
    let find_mg_approved = contacts.find(item => item?.networkUser?.phone_no == mg_admin)
    if (find_mg_approved) {
      contacts.splice(find_index_mg_approved, 1)
      contacts.unshift(find_mg_approved)
    }
  }
  const [search, setSearch] = useState("");

  const callback = () => {
    // toggleModal(false);
    showMessage.current({
      message:
        // "Contact Added",
        "New Contact Added",
      status: "success",
    });
    history.goBack();
  };

  const handleScan = (result) => {
    if (result) {
      toggleModal(false);
      dispatch(DrawerAction.AddOtpNetwork({ qr_code: result, cb: callback }));
    }
  };

  const handleError = (error) => { };
  const getSearchedNetwork = (params) => {
    dispatch(DrawerAction.searchMyNetwork('3'));
  };
  
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    dispatch(DrawerAction.searchMyNetwork(search));
  }, [search])

  const handleRoute = (e) => {
    e.preventDefault();
    history.push("/home");
  };
  const handleBarcodeScan = (data) => {
    dispatch(DrawerAction.GetNetwork({ only_company_users: "" }));
  };
  useEffect(() => {
    dispatch(DrawerAction.GetNetwork({ only_company_users: "" }));
    window.addEventListener("displayNetworkListing", handleBarcodeScan);
    // return () => {
    //   window.removeEventListener("displayNetworkListing", handleBarcodeScan);
    // };
  }, []);

  const handleDeleteNetwork = () => {
    setAppearModal(false);
    dispatch(DrawerAction.DeleteNetwork({ network_user_id: currentId }));
  };

  const handleOnCardClick = (data: any) => {
    
    if (sendEventToAndroid("ContactClicked")) {
      window.Android.ContactClicked(String(data));
    } else if (sendEventToiOS("ContactClicked")) {
      window.webkit.messageHandlers.ContactClicked.postMessage(
        JSON.stringify({
          phoneNumber: data,
        })
      );
    } else {
    }
  };
  return (
    <div className="root-wrapper networkMainWrapper networksContainer contactScreenWrap">
      <div className="root-center-container">
        <div className="menu-bar">
          <div className="insideMenuBar">
            <DrawerNav />
            <p className="title">
              My Contacts
            </p>
            <div className="addNewItemBtn">
              <OutLineButton
                classProp='tinyBtn'
                dataColor="blue"
                title="Add"
                icon={
                  <AddCircleOutlineOutlined />
                }
                onClick={() => {
                  history.push("/add-network", { from: 'contact' });
                }}
              />
            </div>
          </div>
        </div>
        <div className="networkSearchWrap">
          <FadeInComponent>
            <div className="searchContainer" style={{ width: "100%" }}>
              <input
                type="text"
                placeholder="Search"
                className="searchInput"
                value={search}
                style={{ width: "100%" }}
                onChange={handleChange}
              />
              <span className={`icons-search ${search ? '' : 'active'}`}></span>
              <span
                className={`icons-cross ${search ? 'active' : ''}`}
                onClick={() => {
                  setSearch("")
                  searchFlag = false
                }}
              ></span>
            </div>
          </FadeInComponent>
        </div>
        <div className="networkContentWrap scrollableDiv">
          <div className="network-wrapper">
            {contacts?.length ? (
              <div
                style={{
                  width: "100%",
                  paddingBottom: 50,
                }}
              >
                {
                  contacts?.map((item,index) => {
                    return (
                      <FadeInComponent key={index}>
                        <div
                          style={{
                            borderBottom: "1.5px solid #c5c5c5",
                            paddingBottom: 10,
                          }}
                          className="network-user-list-container"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <div className="network-user-image">
                              <img
                                alt=""
                                src={item?.networkUser?.picture || Images.myProfileImage}
                                className="network-user-icon-image"
                              />
                            </div>
                            <div className="network-user-info-container">
                              {
                                item?.networkUser?.company?.length ?
                                  <>
                                    {item?.networkUser?.company &&
                                      item?.networkUser?.company?.length ? (
                                      <CustomText.OpenSansBold>
                                        {item?.networkUser?.company &&
                                          item?.networkUser?.company?.length
                                          ? item?.networkUser?.company[0]?.name
                                            ? item?.networkUser?.company[0]?.name
                                            : ""
                                          : ""}
                                      </CustomText.OpenSansBold>
                                    ) : (
                                      ""
                                    )}
                                    <div className="newtwork-user-name-container">
                                      <CustomText.OpenSansRegular>
                                        {item?.networkUser?.name}
                                      </CustomText.OpenSansRegular>
                                    </div>
                                    <CustomText.OpenSansSemiBold>
                                      {`${item?.networkUser?.phone_no !== mg_admin ? `${phoneNumberFormater(item?.networkUser?.phone_code, item?.networkUser?.phone_no)}` : " "} `}
                                    </CustomText.OpenSansSemiBold>

                                    <CustomText.OpenSansSemiBold>
                                      {`${item?.networkUser?.name == "Unregistered User" ? "Invite Sent" : ""}`}
                                    </CustomText.OpenSansSemiBold>
                                  </> :
                                  <>
                                    <div className="newtwork-user-name-container">
                                      <CustomText.OpenSansBold>
                                        {item?.networkUser?.name}
                                      </CustomText.OpenSansBold>
                                    </div>
                                    <CustomText.OpenSansRegular>
                                      {
                                        item?.networkUser?.company_name
                                      }
                                    </CustomText.OpenSansRegular>
                                    
                                    <CustomText.OpenSansSemiBold>
                                      {`${item?.networkUser?.phone_no !== mg_admin ? `${phoneNumberFormater(item?.networkUser?.phone_code, item?.networkUser?.phone_no)}` : " "} `}
                                    </CustomText.OpenSansSemiBold>

                                    <CustomText.OpenSansSemiBold>
                                      {`${item?.networkUser?.name == "Unregistered User" ? "Invite Sent" : ""}`}
                                    </CustomText.OpenSansSemiBold>
                                  </>
                              }
                            </div>
                          </div>

                          <div className="contactRightSide">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                              }}
                            >
                              <>
                                <span
                                  style={{ marginLeft: 5 }}
                                  className="phone-whatsapp-button"
                                  onClick={() => {
                                    if (sendEventToAndroid("networkPhone")) {
                                      window.Android.networkPhone(
                                        String(item?.networkUser.phone_no)
                                      );
                                    } else if (sendEventToiOS("networkPhone")) {
                                      window.webkit.messageHandlers.networkPhone.postMessage(
                                        JSON.stringify({
                                          phoneNumber: item?.networkUser.phone_no,
                                        })
                                      );
                                    } else {
                                      window.open(
                                        `tel:92${item?.networkUser.phone_no}`,
                                        "_blank"
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    alt=""
                                    style={{ height: "100%" }}
                                    src={Images.Phone}
                                  />
                                </span>
                                <span
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                    if (sendEventToAndroid("networkWhatsapp")) {
                                      window.Android.networkWhatsapp(
                                        String(item?.networkUser.phone_no)
                                      );
                                    } else if (
                                      sendEventToiOS("networkWhatsapp")
                                    ) {
                                      window.webkit.messageHandlers.networkWhatsapp.postMessage(
                                        JSON.stringify({
                                          phoneNumber: item?.networkUser.phone_no,
                                        })
                                      );
                                    } else {
                                      window.open(
                                        `https://wa.me/+92${item?.networkUser.phone_no}`,
                                        "_blank"
                                      );
                                    }
                                  }}
                                  // href={`https://wa.me/+92${item?.networkUser.phone_no}`}
                                  className="phone-whatsapp-button"
                                >
                                  <img
                                    alt=""
                                    style={{ height: "100%" }}
                                    src={Images.WhatsAppBgGreen}
                                  />
                                </span>

                                {item?.networkUser?.phone_no !== mg_admin ? (
                                  <span className="deleteContactBtn">
                                    <span
                                      className="icons-cross"
                                      style={{
                                        cursor: "pointer",
                                        fontSize: '1rem'
                                      }}
                                      onClick={() => {
                                        setCurrentId(item?.networkUser.phone_no);
                                        setAppearModal(true);
                                      }}
                                    ></span>
                                  </span>
                                ) : null}
                              </>
                            </div>
                          </div>
                        </div>
                      </FadeInComponent>
                    );
                  })
                }

              </div>
            ) : (
              <div className="network-empty-component">
                <EmptyPlaceholder
                  image={Images.noNetworkNew}
                  title="No Contacts Added to Network"
                  detail="We value your privacy. Adding contacts in your network will allow you to direct your 
                  request to your relevant contact. 
                  Please click on below button to add contacts."
                />
              </div>
            )}
          </div>
        </div>
        {/* <div
          style={{
            bottom: 102,
          }}
          className="bottomAddIconWrap"
        > */}
        {/* <div className="insideBottomAddIcon">
            <button
              onClick={() => {
                if (sendEventToAndroid("scanQrCall")) {
                  window.Android.scanQrCall("hello");
                } else if (sendEventToiOS("scanQrCall")) {
                  window.webkit.messageHandlers.scanQrCall.postMessage(
                    JSON.stringify({
                      show_navigation_bar: "hello",
                    })
                  );
                } else {
                  toggleModal(!showModal);
                }
              }}
              className="add-btn"
            >
              <img src={Images.QrScan} />
            </button>
          </div> */}
        {/* </div> */}
      </div>
      <CustomModal
        isFullScreen={true}
        modalStyle={{
          with: "100%",
          height: "100vh",
        }}
        showModal={showModal}
        toggleModal={toggleModal}
      >
        <div className="qr-content-container">
          {/* <QrReader
            facingMode={"environment"}
            delay={delay}
            style={previewStyle}
            onError={handleError}
            onScan={handleScan}
          /> */}
          <PrimaryButton
            buttonStyle={{
              width: 320,
              height: 45,
            }}
            title="Close"
            onClick={() => {
              toggleModal(false);
            }}
          />
        </div>
      </CustomModal>

      {/* delete network modal  */}

      <CustomModal
        showModal={appearModal}
        toggleModal={setAppearModal}
        modalStyle={{
          width: 290,
          minHeight: 180
        }}
      >
        <div className="logout-modal-background">
          <DialogTitle id="simple-dialog-title">
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "1.25rem", textAlign: "center" }}
            >
              DELETE
            </CustomText.OpenSansSemiBold>
            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Are you sure you want to delete?
            </CustomText.OpenSansRegular>
            <div className="action-button-container">
              <Button
                onClick={() => {
                  setAppearModal(false);
                }}
                style={{
                  backgroundColor: Colors.Grayish,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  Cancel
                </CustomText.OpenSansSemiBold>
              </Button>
              <Button
                onClick={() => {
                  handleDeleteNetwork();
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  DELETE
                </CustomText.OpenSansSemiBold>
              </Button>
            </div>
          </DialogTitle>
        </div>
      </CustomModal>
      <ButtomTabs activeTab="network" />
    </div>
  );
};

export default Page;
