import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { LoadTypeProps } from "./types";
import { LoadTypeComponent } from "./LoadTypeComponent";
import rootReducer from "../../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import TitleLayout from "@temp/components/TitleLayout";
export type RootState = ReturnType<typeof rootReducer>;
import "./style.scss";
import { Images } from "@temp/assets";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";

const Page: React.FC<LoadTypeProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [load_type, setLoadType] = useState(null);
  const postLoadData = useSelector(
    (state: RootState) => state.Profile.postLoad
  );
  const postLoadMeta = useSelector(
    (state: RootState) => state.Profile.postLoadMeta
  );
  const [data, setData] = useState(postLoadMeta.load_types);

  useEffect(() => {
    setLoadType(postLoadData["load_type"] ? postLoadData.load_type : null);
    if (postLoadData["load_type"] ? postLoadData.load_type : null) {
      setLoadType(postLoadData.load_type);
    }
  }, []);

  const handleSubmit = (load_type) => {
    if (postLoadData.load_type && postLoadData.load_type !== load_type) {
      dispatch(
        ProfileAction.postLoadData({
          vehicleScreen: null,
          PaymentScreen: null,
          location: [],
          recipients: [],
          ref_no: "",
          responseDeadline: null,
          transaction_type_id: null,
          load_type,
        })
      );
    } else {
      dispatch(ProfileAction.postLoadData({ load_type }));
    }
    if (postLoadData?.inquiry_type === 2) {
      history.push("/contract-details");
    } else {
      history.push("/selectVehicle");
    }
  };

  return (
    <TitleLayout pageNumber={2} progress={20} title="Select Load Type">
      <div className="secondaryContainer">
        <div className="loadTypeBoxContainer" style={{ width: '100%' }}>
          {data.map((load, index) => {
            return (
              <FadeInComponent key={index}>
                <div style={{marginBottom:10}}>
                  <LoadTypeComponent
                    id={load.id}
                    setData={setData}
                    data={data}
                    item={load}
                    load_type={load_type}
                    img={load.title == 'Containerized' ? Images.Containerized : Images.NonContainerized}
                    onClick={(index) => handleSubmit(index)}
                  />
                </div>
              </FadeInComponent>
            );
          })}
        </div>
      </div>
    </TitleLayout>
  );
};
export default Page;