import React, { FC } from "react";
// import { makeStyles, Theme, createStyles } from "@mui/material//styles";
import { AdditionalDetailsProps } from "./types";
import Modal from "@mui/material//Modal";
import { PrimaryButton } from "@components";
import { css } from "@emotion/css";
import "./style.scss";
import { Close } from "@mui/icons-material";
const inputField = css({
  background: "rgb(241 241 241)",
  border: "none",
  width: "100%",
  borderRadius: 5,
  "&:focus": {
    outline: "none",
  },
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     paper: {
//       position: "absolute",
//       width: 250,
//       backgroundColor: theme.palette.background.paper,
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(2, 4, 3),
//       borderRadius: "4px",
//       maxHeight: 250,
//       overflowY: "auto",
//     },
//   })
// );

export const InputModal: FC<AdditionalDetailsProps> = ({
  showModal,
  handleModal,
  setValue,
  setShowModal,
  values,
  title,
  placeholder,
  type,
  maxLetter,
}: AdditionalDetailsProps) => {
//   const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle}
	//  className={classes.paper}
	 >
      {setShowModal ? (
        <Close
          className="modalCloseIcon"
          onClick={() => {
            setShowModal(false);
          }}
        />
      ) : (
        ""
      )}

      <h2
        style={{
          marginBottom: 15,
          fontSize: "1.125rem",
          color: "rgb(13 21 9)",
        }}
      >
        {title}
      </h2>
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          backgroundColor: "rgb(241 241 241)",
          padding: "20px",
        }}
      >
        <textarea
          onChange={(e) => setValue(e)}
          placeholder={placeholder}
          maxLength={500}
          className={inputField}
          value={values}
        ></textarea>
      </div>
      {maxLetter && (
        <div
          style={{
            color: "rgb(163,163,163)",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          Max 500 letters
        </div>
      )}
      <div className="input-btn">
        <PrimaryButton
          title="Submit"
          onClick={() => {
            handleModal(false, "pickup");
          }}
        />
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Modal
        onClose={() => handleModal(false)}
        aria-describedby="simple-modal-description"
        open={showModal}
      >
        <div> {body}</div>
      </Modal>
    </React.Fragment>
  );
};
