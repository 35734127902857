import React, { useState } from "react";
export const NewInput = ({
  value,
  placeholder,
  onChange,
  showEnd,
  disabled,
  id,
  type,
  decimal = false
}) => {
  var regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
  const [input_type, set_input_type] = useState('text')

  return (
    <div className={`input-container-new ${value ? 'filled' : ''}`} data-currency={showEnd ? 'true' : 'false'}>
      <input
        onWheelCapture={(e) => e.target?.blur()}
        id={id || ''}
        type={type ? type : input_type}
        onFocus={() => {
          if (!type) {
            set_input_type("number")
          }
        }}
        onBlur={() => {
          if (!type) {
            set_input_type("text")
          }
        }}
        onWheel={(e) => {
          e.stopPropagation()
        }}
        onKeyDown={(e) => {
          if (decimal) {
            if (e.key === 'e' || e.key === '+' || e.key === '-') {
              e.preventDefault()
            }
          } else {
            if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
              e.preventDefault()
            }
          }
          // if(e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-'){
          //   e.preventDefault()
          // }
        }}
        onChange={(e) => {
          if (e.target.value >= 0 && e.target.value[0] != '0') {
            if (!regexp.test(e.target.value)) {
              return
            }
            onChange(e)
          }
        }}
        disabled={disabled}
        value={type ? value : input_type == 'number' ? value : value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        }
        style={{ height: 40 }}
        placeholder={placeholder}
        aria-describedby="standard-weight-helper-text"
        className="new-inut-field"
        inputMode="numeric"
        // pattern="\d*"
        pattern="[1-9][0-9]*"
      />
      <span className='inputSlide'></span>
      {showEnd ? <span className="currency">({showEnd})</span> : null}
    </div>
  )
}