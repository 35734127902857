import React from "react";
import classnames from "classnames";

import styles from "./scss/index.module.scss";
import { IProps } from "./types";

export const Message: React.FC<IProps> = ({
  title,
  status = "neutral",
  children,
  onClick,
  actionText,
}: IProps) => {
  const isAction = !!actionText;
  return (
    <div
      className={classnames(styles.messageWrapper, {
        [styles.success]: status === "success",
        [styles.error]: status === "error",
        [styles.neutral]: status === "neutral",
      })}
      data-test="alert"
    >
      <div className={styles.alertContainer}>
        <div className={styles.title}>sjdfkjsdklfjsklfjsdkjfkjd</div>
        {isAction ? (
          !children && (
            <div onClick={onClick} className="action-button">
              {actionText}
            </div>
          )
        ) : (
            <div className="close-button" onClick={onClick}>
              <span>&times;</span>
            </div>
          )}
      </div>
      {children && <div>{children}</div>}
      {children && isAction && (
        <div
          onClick={onClick}
          style={{ marginTop: "1rem" }}
          className={styles.actionButton}
        >
          {actionText}
        </div>
      )}
    </div>
  );
};

export default Message;
