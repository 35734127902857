import React, { useEffect, useState } from "react";
import {
  BackHeader,
  PrimaryButton,
  CustomText,
  CustomModal,
  OutLineButton,
  PhoneInput
} from "@components";
import { Images, Colors } from "@temp/assets";
import "./style.scss";
import { EditProfilePageProps } from "./types";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
import { AuthAction, ProfileAction } from "@temp/store/actions";
import { preventNonNumericalInput, showMessage } from "@temp/utils/helper";
import {
  Checkbox,
  IconButton,
  Input,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material/";
import { Close } from "@mui/icons-material";
import TitleLayout from "@temp/components/TitleLayout";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material/";
import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material//TextField';
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import DialogTitle from "@mui/material//DialogTitle";
import Dialog from "@mui/material//Dialog";
import {
  SimpleInput,
} from "@temp/components";
import InfoIcon from "@mui/icons-material/Info";
import { AddCircleOutlineOutlined } from "@mui/icons-material";

import SimplePhoneInput from '../../../components/SimplePhoneInput';

export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<EditProfilePageProps> = (props) => {
  const history = useHistory();
  const profile = useSelector((state: RootState) => state.Profile.profile);

  const [profileData, setProfileData] = useState(profile);
  const [showModal, toggleModal] = useState(false);
  const [changePhone, setChangePhone] = useState("");
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const [userProfession, setUserProfession] = useState([]);
  const [showPic, setShowPic] = useState("");
  const [isWhatsAppNo, setIsWhatsAppNo] = useState(profile?.whatsapp_phone_no ? false : true);
  const [whatsapp, setWhatsappNo] = useState(profile?.whatsapp_phone_no ? profile?.whatsapp_phone_no : "");
  const [tempIndex, setTempIndex] = useState(null)
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const [showCancel, setShowCancel] = useState(false)

  const [showModalWaterMark, setShowModalWaterMark] = useState(false);
  const [showInfoWaterMark, setShowInfoWaterMark] = useState("");
  const [appearModal, setAppearModal] = useState(false);
  const [multipleEmail, setMultipleEmail] = useState([]);

  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();

  const handleDeleteEmail = (index) => {
    setAppearModal(false);
    let multi = [...multipleEmail]
    multi.splice(index, 1)
    setMultipleEmail(multi)
  };

  const handleChange = (event) => {
    setProfileData({ ...profileData, [event.target.name]: event.target.value });
  };

  const handlePasteWhatsapp = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    // Remove non-numeric characters
    const numericValue = paste.replace(/[^0-9]/g, '');
    // Prevent pasting if the first character is 0
    if (event?.target?.value.length > 10) {
      return;
    }
    // if (numericValue[0] !== '0') {
    //   setWhatsappNo(numericValue);
    // }
    if (numericValue.startsWith('0') || numericValue.startsWith('92')) {
      return;
    }
    // Set the pasted value to driverMobileNumber
    setWhatsappNo(numericValue);

  }

  const handlePastePhone = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    // Remove non-numeric characters
    const numericValue = paste.replace(/[^0-9]/g, '');
    // Prevent pasting if the first character is 0
    if (event?.target?.value.length > 10) {
      return;
    }
    /*if (numericValue[0] !== '0') {
      setChangePhone(numericValue);
    }*/
      if (numericValue.startsWith('0') || numericValue.startsWith('92')) {
        return;
      }
      // Set the pasted value to driverMobileNumber
      setChangePhone(numericValue);
  }

  useEffect(() => {
    if (profile?.associate_emails.length > 0) {
      let multiples = [];
      profile?.associate_emails.forEach((item) => {
        multiples.push(item?.email);
      });
      setMultipleEmail(multiples)
      // setMultipleEmail(profile?.associate_emails)
    } else {
      setMultipleEmail([''])
    }
  }, [profile?.associate_emails])


  useEffect(() => {
    let current_city = metaData?.cities?.find(item => item?.id == 2)
    setValue(current_city ? current_city : null || null)
    setInputValue(current_city?.label || "")
  }, [])

  useEffect(() => {
    setProfileData(profile);
  }, [profile]);

  const callBack = () => {
    if (state?.from == "login") {
      history.replace("/home");
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    dispatch(AuthAction.getMetaData());
    dispatch(ProfileAction.GetProfile());
  }, []);


  useEffect(() => {

    if (profile?.user_professions?.length) {
      let professions = [];
      profile?.user_professions.forEach((item) => {
        professions.push(item?.name);
      });
      setUserProfession(professions);
    }
  }, [profile?.user_professions]);

  const validateMoreEmail = (value, index) => {
    let multi = [...multipleEmail]
    multi[index] = value;
    setMultipleEmail(multi);
  };


  const handleSubmit = () => {
    const payload = {
      // cnic: profileData.cnic,
      name: profileData.name,
      name_friendly: profileData?.name_friendly || "",
      email: profileData.email ? profileData?.email : "",
      company_name: profileData?.company_name || "",
      user_professions: userProfession,
      picture: profileData?.picture,
      whatsapp_phone_no: whatsapp,
      whatsapp_phone_code: "92",
      associate_emails: multipleEmail.filter((val) => val !== ""),
      cb: callBack,
    };

    // return
    if (!isWhatsAppNo) {
      if (!whatsapp) {
        showMessage.current({
          message:
            //  "Whatsapp no. is required.",
            "Whatsapp number is required.",
          status: "error",
        });
      } else {
        dispatch(ProfileAction.UpdateProfile(payload));
      }
    } else {
      dispatch(ProfileAction.UpdateProfile(payload));
    }
  };

  const handleChangePhone = (e: any) => {
    let newValue = e.target.value;
    // Remove non-digit characters (including hyphens)
    newValue = newValue.replace(/[^0-9]/g, '');
    // Remove specific disallowed numbers like '92'
    if (newValue.startsWith('92')) {
      newValue = newValue.slice(2); // Remove '92' from the start
    }
    // Remove leading zeros
    newValue = newValue.replace(/^0+/, '');
    // Limit length to 10 digits
    newValue = newValue.slice(0, 10);
    setChangePhone(newValue);

    // if (e.target.value.length > 10) {
    //   return;
    // }
    // setChangePhone(e.target.value);
  };

  const changePhoneCallback = (data) => {
    history.push("/change-phone-otp", {
      type: data?.type,
      phoneNumber: data?.phoneNumber,
    });
  };

  const changePhoneNumber = (type: string) => {
    toggleModal(false);
    const payload = {
      phoneNumber: changePhone,
      type,
      cb: changePhoneCallback,
    };
    dispatch(ProfileAction.changePhoneNumberRequest(payload));
  };

  const handleChangeBack = (event) => {
    if (
      event?.target?.files[0]?.type.includes("image/png") ||
      event?.target?.files[0]?.type.includes("image/jpg") ||
      event?.target?.files[0]?.type.includes("image/jpeg")
    ) {
      setShowPic(URL.createObjectURL(event.target.files[0]));
      setProfileData({
        ...profileData,
        [event.target.name]: event.target.files[0],
      });
    } else {
      showMessage.current({
        message:
          // "Please upload image, with format of PNG/ JPG / JPEG",
          "Please upload image in PNG/ JPG / JPEG format",
        status: "error",
      });
    }
  };

  const handleChangeProfession = (event) => {

    setUserProfession([event.target.value]);
  };

  const handleDeleteImage = () => {
    setShowCancel(false);
    dispatch(ProfileAction.DelProfileImage());
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  return (
    <TitleLayout
      titleOnly={true}
      titleOnlyText={
        state?.from == "login" ? "complete profile" : "Edit Profile"
      }
    >
      <div className="myProfileMainWrapper editProfile">
        <FadeInComponent>
          <div className="profileEditContainer">
            <img
              style={{
                height: 100,
                width: 100,
                borderRadius: 100,
              }}
              src={showPic ? showPic : profile?.picture || Images.myProfileImage}
              className="my-profile-image"
              alt=""
            />

            <div
              className="delete-profile-button"
              onClick={() => {
                setShowCancel(true)
                // setShowPic(null)
                // handleDeleteImage();
              }}
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <img style={{ height: 25, width: 25 }} alt="" src={Images.removeLoad} />
              </IconButton>

            </div>
            <div className="edit-icon-button-profile">
              <label htmlFor="icon-button-file1">
                <input
                  accept="image/*"
                  id="icon-button-file1"
                  className="hide-input"
                  name="picture"
                  type="file"
                  onChange={handleChangeBack}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <img alt="" src={Images.cameraIcon} />
                </IconButton>
              </label>
            </div>
          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="my-profile-info">
            <CustomText.OpenSansBold
              textStyle={{
                fontSize: "0.875rem",
                color: Colors.Black,
              }}
            >
              {profile?.name}
            </CustomText.OpenSansBold>

            <CustomText.OpenSansBold
              textStyle={{
                fontSize: "0.625rem",
                color: Colors.Grayish,
                marginTop: 4,
              }}
            >
              {/* {profile.user_profession ? profile?.user_profession : ""} */}
            </CustomText.OpenSansBold>
          </div>
        </FadeInComponent>

        <div className="my-profile-details">

          {/* <div className="edit-input-wrapper">
            <CustomText.OpenSansSemiBold
              textStyle={{
                fontSize: 14,
                color: Colors.Grayish,
              }}
            >
              CNIC
            </CustomText.OpenSansSemiBold>
            <div className="edit-profile-textbox">
              <div className="edit-input-text">
                <CustomText.OpenSansRegular
                  textStyle={{
                    color: "rgb(45, 57, 52)",
                  }}
                >
                  {profile?.cnic}
                </CustomText.OpenSansRegular>
              </div>
            </div>
          </div> */}
          <FadeInComponent>
            <div className="inputField">
              <div className="label">Name *</div>
              <div className="field viewFieldLabel">
                {profile?.name}
              </div>
            </div>

          </FadeInComponent>

          <FadeInComponent>
            <div className="inputField">
              <div className="label">City Name *</div>
              <div className="field viewFieldLabel">
                {metaData?.cities?.find(item => item?.id == profile?.city_id)?.label}
              </div>
            </div>
          </FadeInComponent>

          {/* <div className="signup-form-container">
            <>
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: 14,
                  color: Colors.Grayish,
                }}
              >
                City Name
              </CustomText.OpenSansSemiBold>
              <Autocomplete
                id="country-select-demo"
                style={{ width: 360 }}
                options={metaData?.cities}
                autoHighlight
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                getOptionLabel={(option) => option?.label}
                renderOption={(option) => {
                  return (
                    <React.Fragment>
                      {option?.label}
                    </React.Fragment>
                  )
                }
                }
                renderInput={(params) => (
                  <TextField
                    {...params}

                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'country-select-demo', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </>
          </div> */}
          <FadeInComponent>
            <div className="bodNone" style={{ marginBottom: '1.5rem' }}>
              <div className="fw-5 ">
                User Role *
              </div>
              <Select
                name="user_professions"
                defaultValue={userProfession}
                onChange={handleChangeProfession}
                className="add-vehicle-select-box userSelectBox"
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple={false}
                input={<Input />}
                value={userProfession}
                // renderValue={(selected: any) => selected?.join(", ")}
                MenuProps={MenuProps}
              >
                {metaData?.user_professions?.map((item) => {
                  return (
                    <MenuItem
                      value={item?.name}
                      key={item?.name}
                    >
                      {/* <Checkbox
                        checked={userProfession.indexOf(item?.name) > -1}
                      /> */}
                      {/* <ListItemText primary= */}
                      {item?.name}
                      {/* /> */}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

          </FadeInComponent>

          <FadeInComponent>
            <div className="inputField">
              <div className="label">Email</div>
              <div className="field">
                <SimpleInput
                  value={profileData?.email || ""}
                  type="text"
                  onChange={handleChange}
                  placeholder={"Email"}
                  name={"email"}
                  maxLength={50}

                />
                {/* <span className="icons-edit editIcon newEditIcon"></span> */}
              </div>
            </div>
          </FadeInComponent>



          <FadeInComponent>
            <div className="inputField">
              <div className="label">Company Name *</div>
              <div className="field viewFieldLabel">
                {profile?.company?.length ?
                  profile?.company[0]?.name
                  : profile?.company_name}

                {/* {profile?.company_name} */}
              </div>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div className="inputField mbotZero">
              <div className="label">Mobile Number</div>
              <div className="field viewFieldLabel">
                {`+92 ${profile?.phone_no}`}
              </div>
              <div
                onClick={() => {
                  toggleModal(!showModal);
                }}
                className="change-number-button"
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: "#0d5296 !important" }}
                >
                  Change
                </CustomText.OpenSansSemiBold>
              </div>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div className="whatsapp-field-container">
              <div
                style={{
                  marginRight: 51,
                  marginTop: 10,
                }}
                className="check-box"
              >
                <Checkbox
                  checked={isWhatsAppNo}
                  style={{ color: "#7ac251" }}
                  defaultChecked
                  onClick={() => {
                    setIsWhatsAppNo(!isWhatsAppNo);
                    if (!isWhatsAppNo) {
                      setWhatsappNo("");
                    }
                  }}
                />
                <p className="keep-signin-text">
                  Phone number same as WhatsApp number
                </p>
              </div>
              {!isWhatsAppNo ? (
                <div className="inputField" style={{ width: '100%', marginTop: '0.5rem' }}>
                  <div className="label">Whatsapp Number</div>
                  <div className="field">
                    {/* <PhoneInput
                      value={whatsapp}
                      onChange={(e) => {
                        if (e.target.value.length > 10) {
                          return;
                        }
                        setWhatsappNo(e?.target.value);
                      }}
                      phoneCode={"92"}
                      id="whatsapp-number"
                      placeholder={"3000000000"}
                    /> */}
                    
                    <SimplePhoneInput
                      value={whatsapp}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        // Remove non-digit characters (including hyphens)
                        newValue = newValue.replace(/[^0-9]/g, '');
                        // Remove specific disallowed numbers like '92'
                        if (newValue.startsWith('92')) {
                          newValue = newValue.slice(2); // Remove '92' from the start
                        }
                        // Remove leading zeros
                        newValue = newValue.replace(/^0+/, '');
                        // Limit length to 10 digits
                        newValue = newValue.slice(0, 10);
                        setWhatsappNo(newValue);
                        // if (e.target.value.length > 10) {
                        //   return;
                        // }
                        // setWhatsappNo(e?.target.value);
                      }}
                      onPaste={handlePasteWhatsapp}
                      placeholder="3000000000"
                      maxLength={10}
                      id="phone-number"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div className="inputField">
              <div className="addEmailLabelWithButton">
                <div className="label" style={{ marginTop: 13 }}>
                  Add More Email Addresses
                  {/* <InfoIcon
                    onClick={() => {
                      setShowInfoWaterMark(
                        "Added"
                      );
                      setShowModalWaterMark(true);
                    }}
                  /> */}
                </div>
                {/*add new button div start */}
                <div className="addNewItemBtn">
                  {
                    multipleEmail?.length < 5 ?
                      <OutLineButton
                        classProp='tinyBtn'
                        dataColor="blue"
                        title="Add"
                        icon={
                          <AddCircleOutlineOutlined />
                        }
                        onClick={() => {
                          let multiple = [...multipleEmail]
                          multiple?.push("")
                          setMultipleEmail(multiple)

                        }}
                      />
                      : null}
                </div>
                {/*add new button div end */}
              </div>
              <div className="smallGreyText mtopZero">These emails will be receiving a copy of Bid Results and Booking Confirmations.</div>
              <div className="error-container info customInfoText">
                <div>
                  <InfoIcon className="error-icon" />
                </div>
                <div className="error-text">
                  You can add upto 5 email addresses
                </div>
              </div>
              {
                multipleEmail?.map((item, index) => {
                  return (

                    <div className="field addEmailField">
                      <SimpleInput
                        value={item}
                        type="text"
                        onChange={(event) => { validateMoreEmail(event?.target?.value, index) }}
                        placeholder={"example@email.com"}
                        name={"Add More Email"}
                        maxLength={50}
                      />
                      <div className="rightSideIconWrap">
                        {/*delete button start */}
                        <span className="deleteContactBtn">
                          <span
                            className="icons-cross"
                            style={{
                              cursor: "pointer",
                              fontSize: '1rem'
                            }}
                            onClick={() => {
                              setTempIndex(index)
                              setAppearModal(true);
                            }}
                          ></span>
                        </span>
                        {/*delete button end */}


                      </div>
                    </div>
                  )
                })
              }
            </div>
          </FadeInComponent>
        </div>
      </div>

      <FadeInComponent>
        <div style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}>
          <PrimaryButton
            buttonStyle={{
              height: 44,
            }}
            disabled={!profileData?.name.length || !userProfession.length || (!profile?.company_name?.length && !profile?.company?.length)}
            title={"UPDATE"}
            onClick={() => {
              if (!isWhatsAppNo) {
                if (whatsapp.length < 10) {
                  showMessage.current({ message: "Whatsapp number sholud be 10 digit.", status: 'error' })

                } else {
                  handleSubmit();
                }
              } else {
                handleSubmit();
              }
            }}
          />

          {state?.from == "login" && "complete profile" ? (
            <OutLineButton
              buttonStyle={{
                height: 44,
                fontSize: "1rem",
              }}
              title="SKIP"
              onClick={() => {
                history.push("/home");
              }}
            />
          ) : null}
        </div>
      </FadeInComponent>

      <CustomModal showModal={showModal} toggleModal={toggleModal}>
        <div className="editProfileModal">
          <Close
            className="closeIcon"
            onClick={() => {
              toggleModal(!showModal);
            }}
          />
          <div className="inputField" style={{ width: '100%', marginTop: '0.5rem' }}>
            <div className="field">
              {/* <PhoneInput
                value={changePhone}
                onChange={handleChangePhone}
                phoneCode={"92"}
                id="phone-number"
                placeholder={"3000000000"}
              /> */}
              <SimplePhoneInput
                value={changePhone}
                onChange={handleChangePhone}
                onPaste={handlePastePhone}
                placeholder="3000000000"
                maxLength={10}
                id="phone-number"
              />
            </div>
          </div>

          {/* <PrimaryButton
            disabled={changePhone.length < 10}
            title="Send Code via WhatsApp"
            onClick={() => {
              changePhoneNumber("whatsapp");
            }}
            icon={
              changePhone.length >= 10
                ? Images.WhatsappGreen
                : Images.WhatsappGray
            }
            buttonStyle={{
              width: "100%",
              marginTop: 30,
            }}
            buttonTitleStyle={{
              fontSize: 14,
            }}
          /> */}
          <OutLineButton
            disabled={changePhone.length < 10}
            icon={
              changePhone.length >= 10
                ? Images.MessageBubble
                : Images.MessageBubbleGray
            }
            title="Send Code via SMS"
            onClick={() => {
              changePhoneNumber("sms");
            }}
            buttonStyle={{
              width: "100%",
              marginTop: 60,
            }}
            buttonTitleStyle={{
              fontSize: "0.875rem",
            }}
          />
        </div>
      </CustomModal>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgb(241,241,241)",
            color: "black",
            borderRadius: 20,
            width: 286,
          },
        }}
        onClose={() => {
          setShowCancel(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={showCancel}
      >
        <div className="logout-modal-background">
          <DialogTitle id="simple-dialog-title">
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "1.25rem", textAlign: "center" }}
            >
              Delete Picture
            </CustomText.OpenSansSemiBold>
            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginTop: 20,
                width: 220
              }}
            >
            </CustomText.OpenSansRegular>
            <div className="action-button-container">
              <Button
                onClick={() => {
                  setShowCancel(false);
                }}
                style={{
                  backgroundColor: Colors.Grayish,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  No
                </CustomText.OpenSansSemiBold>
              </Button>
              <Button
                onClick={() => {
                  setShowPic(null)
                  handleDeleteImage()
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  Delete
                </CustomText.OpenSansSemiBold>
              </Button>
            </div>
          </DialogTitle>
        </div>
      </Dialog>

      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showModalWaterMark}
        toggleModal={setShowModalWaterMark}
      >
        <div>
          <InfoIcon className="icon" />
          <p className="title">Information</p>
          <p>
            {showInfoWaterMark}
          </p>
        </div>
      </CustomModal>

      {/* delete network email modal  */}

      <CustomModal
        showModal={appearModal}
        toggleModal={setAppearModal}
        modalStyle={{
          width: 290,
          minHeight: 180
        }}
      >
        <div className="logout-modal-background">
          <DialogTitle id="simple-dialog-title">
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "1.25rem", textAlign: "center" }}
            >
              DELETE
            </CustomText.OpenSansSemiBold>
            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Are you sure you want to delete email?
            </CustomText.OpenSansRegular>
            <div className="action-button-container">
              <Button
                onClick={() => {
                  setAppearModal(false);
                }}
                style={{
                  backgroundColor: Colors.Grayish,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  Cancel
                </CustomText.OpenSansSemiBold>
              </Button>
              <Button
                onClick={() => {
                  handleDeleteEmail(tempIndex);
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  DELETE
                </CustomText.OpenSansSemiBold>
              </Button>
            </div>
          </DialogTitle>
        </div>
      </CustomModal>
    </TitleLayout>
  );
};

export default Page;
