import React from "react";
import { StyleGuideProps } from "./types";

import Page from "./Page";

const View: React.FC<StyleGuideProps> = ({

}: StyleGuideProps) => {
  return <Page />;
};

export default View;
