import React from "react";
import Countdown from "react-countdown";
import CustomText from "../CustomText";

export const Counter: React.FC<any> = ({ date, renderComplete, iconComponent }: any) => {
  const renderDate = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      renderComplete()
      return "";
    } else {
      // Render a countdown
      return (
        <div className="customCounter">
          {days}d : {hours<10?`0${hours}`:hours}h : {minutes<10?`0${minutes}`:minutes}m : {seconds<10?`0${seconds}`:seconds}s
          <span className="editIcon">{iconComponent}</span>
        </div>
      );
    }
  };
  return <Countdown date={date} renderer={renderDate}></Countdown>;
};
