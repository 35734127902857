import { Grid } from "@mui/material/";
import { Images } from "@temp/assets";
import { CustomText } from "@temp/components";
import { price_units_transalation } from "@temp/utils/helper";
import React from "react";
import "../LoadDetailPoster/style.scss";
import { LOAD_TYPE } from "@temp/constants";
import { format } from "path";
var numeral = require("numeral");

export const RecipientPrices = ({
  price_per_vehicle,
  price_per_freight,
  no_of_vehicles,
  no_of_freight,
  loading_price,
  unloading_price,
  detention_rate,
  is_unit_basis,
  is_negotiate_bid,
  price_unit,
  from,
  negotiate_bid_detention,
  item,
  loadDetail,
  detention_tarif_policy,
  bid_detention_additional_charges

}) => {


  let total_amount;


  if (no_of_vehicles) {
    if (item?.is_twin_load !== 1) {
      total_amount =
        Number(no_of_vehicles) * Number(price_per_vehicle) +
        Number(loading_price) * Number(no_of_vehicles) +
        Number(unloading_price) * Number(no_of_vehicles);

    } else {
      total_amount =
        Number(no_of_vehicles) * Number(price_per_vehicle) +
        Number(loading_price) * (Number(no_of_vehicles) * 2) +
        Number(unloading_price) * (Number(no_of_vehicles) * 2);
    }
  } else {
    total_amount =
      Number(no_of_freight) * Number(price_per_freight) +
      Number(loading_price) * Number(no_of_freight) +
      Number(unloading_price) * Number(no_of_freight);

  }
  return (
    <div>
      {is_negotiate_bid ? (
        <div>
          {is_negotiate_bid && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Negotiated Bid (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel" style={{ margin: '0.3rem 0' }}>بات چیت کے ذر یعے طے کی گٔی بولی (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {/* {numeral(is_negotiate_bid).format("0,0") + " PKR"} */}
                {
                  loadDetail?.quote_by == "vehicle" ?
                    numeral(is_negotiate_bid).format("0,0") + " PKR"
                    :
                    numeral(is_negotiate_bid).format("0,0.00") + " PKR"
                }
              </div>
            </div>
          )}

          {negotiate_bid_detention && (
            <div className="formGrid">
              <div className="grayLabel">
                Negotiated Bid Detention Rate (Without GST)
              </div>
              <div className="fw-5">
                {/* {numeral(negotiate_bid_detention).format("0,0") + " PKR"} */}
                {
                  loadDetail?.quote_by == "vehicle" ?
                    numeral(negotiate_bid_detention).format("0,0") + " PKR"
                    :
                    numeral(negotiate_bid_detention).format("0,0.00") + " PKR"
                }
              </div>
            </div>
          )}

          {price_per_vehicle && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Price / {is_unit_basis ? "Item" : "Vehicle"} (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel">ریٹ  / {is_unit_basis ? "فی آئٹم" : "فی گاڑی"} (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {is_unit_basis ?
                  numeral(price_per_vehicle).format("0,0.00") + " PKR"
                  :
                  numeral(price_per_vehicle).format("0,0") + " PKR"}
              </div>
            </div>
          )}

          {detention_rate && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Detention Rate (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel"> ڈیٹنشن ریٹ (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {
                  loadDetail?.quote_by == "vehicle" ?
                    numeral(detention_rate).format("0,0") + " PKR"
                    :
                    numeral(detention_rate).format("0,0.00") + " PKR"
                }

              </div>
            </div>
          )}

          {detention_tarif_policy && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Detention Tariff & Policy</span>
                <span className="seperator">/</span>
                <span className="urduLabel">ڈیٹنشن ٹیرف اور پالیسی</span>
              </div>
              <div className="fw-5">
                {detention_tarif_policy}

              </div>
            </div>
          )}

          {item?.is_twin_load && loadDetail?.load_type?.title == LOAD_TYPE.fcl ? (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Price / Container (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel">ریٹ / فی کنٹینر (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {numeral(Number(price_per_vehicle) / 2).format("0,0") + " PKR"}
              </div>
            </div>
          ) : null}

          {price_per_freight && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Price / {price_unit} (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel">ریٹ / {price_units_transalation[price_unit]} (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {numeral(price_per_freight).format("0,0.00") + " PKR"}
              </div>
            </div>
          )}





          {loading_price > 0 && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Loading Price (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel"> لوڈنگ ریٹ  (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {
                  loadDetail?.quote_by == "vehicle" ?
                    numeral(loading_price).format("0,0") + " PKR"
                    :
                    numeral(loading_price).format("0,0.00") + " PKR"}
              </div>
            </div>
          )}

          {unloading_price > 0 && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Unloading Price (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel">ان لوڈنگ ریٹ (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {
                  loadDetail?.quote_by == "vehicle" ?
                    numeral(unloading_price).format("0,0") + " PKR"
                    :
                    numeral(unloading_price).format("0,0.00") + " PKR"}
              </div>
            </div>
          )}

          {from !== "contract" && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Total Amount (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel">کل رقم (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {

                  loadDetail?.quote_by == "vehicle" ?
                    numeral(total_amount).format("0,0") + " PKR"
                    :
                    numeral(total_amount).format("0,0.00") + " PKR"}
              </div>
            </div>
          )
          }

          {bid_detention_additional_charges && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Other Additional Charges</span>
                <span className="seperator">/</span>
                <span className="urduLabel">دیگر اضافی چارجز</span>
              </div>
              <div className="fw-5">
                {bid_detention_additional_charges}

              </div>
            </div>
          )}

        </div>
      ) : (
        <div>
          {price_per_vehicle && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Price / {is_unit_basis ? "Item" : "Vehicle"} (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel">ریٹ  / {is_unit_basis ? "فی  آئٹم" : "فی گاڑی"} (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {
                  is_unit_basis ?
                    numeral(price_per_vehicle).format("0,0.00") + " PKR"
                    :
                    numeral(price_per_vehicle).format("0,0") + " PKR"}
              </div>
            </div>
          )}


          {price_per_freight && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Price / {price_unit} (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel">ریٹ / {price_units_transalation[price_unit]} (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {numeral(price_per_freight).format("0,0.00") + " PKR"}
              </div>
            </div>
          )}

          {detention_rate && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Detention Rate (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel"> ڈیٹنشن ریٹ (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {
                  loadDetail?.quote_by == "vehicle" ?
                    numeral(detention_rate).format("0,0") + " PKR"
                    :
                    numeral(detention_rate).format("0,0.00") + " PKR"}
              </div>
            </div>
          )}

          {detention_tarif_policy && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Detention Tariff & Policy</span>
                <span className="seperator">/</span>
                <span className="urduLabel">ڈیٹنشن ٹیرف اور پالیسی</span>
              </div>
              <div className="fw-5">
                {detention_tarif_policy}
              </div>
            </div>
          )}

          {loadDetail?.load_type?.id === 1 && item?.is_twin_load ? (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Price / Container (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel">ریٹ / فی کنٹینر (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {numeral(Number(price_per_vehicle) / 2).format("0,0") + " PKR"}
              </div>
            </div>
          ) : null}

          {negotiate_bid_detention && (
            <div className="formGrid">
              <div className="grayLabel">
                Negotiated Bid Detention Rate (Without GST)
              </div>
              <div className="fw-5">
                {
                  loadDetail?.quote_by == "vehicle" ?
                    numeral(negotiate_bid_detention).format("0,0") + " PKR"
                    :
                    numeral(negotiate_bid_detention).format("0,0.00") + " PKR"}
              </div>
            </div>
          )}


          {loading_price > 0 && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Loading Price (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel"> لوڈنگ ریٹ  (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {
                  loadDetail?.quote_by == "vehicle" ?
                    numeral(loading_price).format("0,0") + " PKR"
                    :
                    numeral(loading_price).format("0,0.00") + " PKR"}
              </div>
            </div>
          )}

          {unloading_price > 0 && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Unloading Price  (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel">ان لوڈنگ ریٹ (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {
                  loadDetail?.quote_by == "vehicle" ?
                    numeral(unloading_price).format("0,0") + " PKR"
                    :
                    numeral(unloading_price).format("0,0.00") + " PKR"}
              </div>
            </div>
          )}

          {from !== "contract" && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Total Amount (Without GST)</span>
                <span className="seperator">/</span>
                <span className="urduLabel">کل رقم (GST کے بغیر)</span>
              </div>
              <div className="fw-5">
                {loadDetail?.quote_by == "vehicle" ?
                  numeral(total_amount).format("0,0") + " PKR"
                  :
                  numeral(total_amount).format("0,0.00") + " PKR"}
              </div>
            </div>
          )
          }

          {bid_detention_additional_charges && (
            <div className="formGrid">
              <div className="grayLabel">
                <span className="titleName">Other Additional Charges</span>
                <span className="seperator">/</span>
                <span className="urduLabel">دیگر اضافی چارجز</span>
              </div>
              <div className="fw-5">
                {bid_detention_additional_charges}

              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
