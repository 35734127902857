import React, { FC } from "react";
import { styled } from "@mui/system";
import { Radio, RadioProps } from "@mui/material";
import Modal from "@mui/material/Modal";
import { SimpleModalProps } from "./types";
import "./style.scss";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface GreenRadioStyleProps {
  checked: {};
}

const GreenRadio = styled(Radio)<GreenRadioStyleProps>(({ theme }) => ({
  color: "rgb(122 194 81)",
  "&.Mui-checked": {
    color: "rgb(122 194 81)",
  },
}));

export const SimpleModal: FC<SimpleModalProps> = ({
  showModal,
  handleModal,
  setType,
  selected,
  values,
  title,
}: SimpleModalProps) => {
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className="jsModal">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2
          style={{
            marginBottom: 15,
            fontSize: "1.125rem",
            color: "rgb(13 21 9)",
          }}
        >
          {title}
        </h2>
        <span
          className="icons-cross floatRight"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleModal(false);
          }}
        ></span>
      </div>

      {values.map((value: any, index) => (
        <div key={index} className="radioMainWrapper" style={{ display: "flex" }}>
          <div className="radioWrapper">
            <GreenRadio
              color="default"
              checked={selected === value.id}
              onChange={setType}
              value={value.id}
              name="radio-button-demo"
            />
          </div>
          <div
            className="radioLabel"
          >
            {value.title}{" "}
            {value?.sub_title ? "- (" + value?.sub_title + ")" : ""}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <React.Fragment>
      <Modal
        onClose={() => handleModal(false)}
        aria-describedby="simple-modal-description"
        open={showModal}
      >
        <div>{body}</div>
      </Modal>
    </React.Fragment>
  );
};
