import BackArrow from "./back-arrow.png";
import IntroImage1 from "./intro-image1.png";
import IntroImage2 from "./intro-image2.png";
import IntroImage3 from "./intro-image3.png";
import MgLogo from "./mg-logo.png";
import Play from "./play.png";
import WhatsappGreen from "./whatsapp-green.png";
import WhatsappGray from "./whatsapp-gray.png";
import BackArrowWhite from "./back-arrow-white.png";
import Check from "./check.png";
import AddPlus from "./add-plus.png";
import success from "./success-image.png";
import EditIcon from "./edit-icon.png";
import girlImage from "./girl-image.png";
import boyImage from "./boy-image.png";
import girlImage1 from "./girl-image1.png";
import threeDots from "./three-dots.png";
import iconMenu from "./icon-menu.png";
import UserIconInactive from "./user-icon-inactive.png";
import DraftInactive from "./draft-inactive.png";
import AccountInactive from "./account-inactive.png";
import LocationInactive from "./location-inactive.png";
import VehicleInactive from "./vehicle-selector-inactive.png";
import TermsInactive from "./terms-inactive.png";
import LogoutInactive from "./logout-inactive.png";
import HelpInactive from "./help-inactive.png";
import NetworkInactive from "./network-inactive.png";
import UserIconActive from "./user-active.png";
import DraftActive from "./draft-active.png";
import AccountActive from "./account-active.png";
import LocationActive from "./location-active.png";
import VehicleActive from "./vehicle-selector-active.png";
import TermsActive from "./terms-active.png";
import LogoutActive from "./logout-active.png";
import HelpActive from "./help-active.png";
import NetworkActive from "./network-active.png";
import correct from "./correct.png";
import homeIcon from "./home-icon.png";
import search from "./search.png";
import menu from "./menu.png";
import homeActive from "./home-icon.png";
import homeInActive from "./home-inactive.png";
import ChatActive from "./chat-active.png";
import ChatInActive from "./chat-inactive.png";
import NetworksActive from "./group-active.png";
import NetworksInActive from "./group-inactive.png";
import NotificationActive from "./notification-active.png";
import NotificationInActive from "./notification-inactive.png";
import PlusCircle from "./plus-circle.svg";
import FCL from "./FCL.png";
import LCL from "./LCL.png";
import Bulk from "./Bulk.png";
import Document from "./Document-01-01.png";
import HandShake from "./HandShake-01.png";
import WhatsAppBgGreen from "./whatsapp-new.svg";
import Whatsapp from "./whatsapp.png";
import WhatsappDarkGray from "./whatsapp-disable.svg";
import Phone from "./call-new.svg";
import PhoneGray from "./call-disable.svg";
// import WhatsappDarkGray from "./whatsapp-grey.png";
// import PhoneGray from "./phone-grey.png";
// import WhatsAppBgGreen from "./whatsapp-bg-green.png";
// import Phone from "./Phone.png";
import Auction from "./undraw_auction.png";
import CustomerCare from "./customer.png";
import Email from "./email.png";
import resetPassword from "./reset-password.png";
// import undrawEmail from "./undraw-mail.png";
import myProfileImage from "./profile-image.svg";
import cameraIcon from "./camera-icon.png";
import QRCode from "./qr-code.png";

import undrawEmail from "./Document-01-01.png";
import pickup from "./pickup.png";
import dropoff from "./dropoff.png";
import bill from "./bill.png";
import list from "./list.png";
import docPng from "./docPng.png";
import docPdf from "./docPdf.png";
import docOther from "./docOther.png";
import EditGreen from "./edit-green.png";
import FilterIcon from "./filter-icon.png";
import TruckBlue from "./truck-blue.png";
import TruckOrange from "./truck-orange.png";
import OfferIcon from "./offer.png";
import BidIcon from "./bid-icon.png";
import MessageBubble from "./message-bubble.png";
import MessageBubbleGray from "./message-bubble-gray.png";
import Container from "./containerIcon.png";
import Gauge from "./gaugeIcon.png";
import TwinLoad from "./twinLoadIcon.png";
import LoadHistoryInactive from "./load-history.png";
import LoadHistoryActive from "./load-history-active.svg";
import VehicleType from "./vehicleTypeIcon.png";
import DocTerms from "./Doc-terms.png";
import accountIcon from "./account-icon.png";
import accountAdd from "./account-plus.png";
import accountMinus from "./account-minus.png";
import EditBlue from "./edit-blue.png";
import pendingIcon from "./pending-icon.png";
import declinedIcon from "./declined-icon.png";
import acceptedIcon from "./accepted-icon.png";
import successPosted from "./posted-successfully-img.svg";
import successRecipentAdd from "./add-recipent-successfully-img.svg";
import dot from "./dot.png";
import dropoffsmall from "./dropoff-small.png";
import pickupsmall from "./pickup-small.png";
import filePng from "./filePng.png";
import filePdf from "./filePdf.png";
import fileBill from "./fileBill.png";
import QrScan from "./qr_code_scanner_black_24dp.png";
import clone from "./clone.png";
import removeLoad from "./remove-load.png";
import remove from "./remove.png";
import postIcon from "./post_icon.png";
import uploadDocIcon from "./upload-doc-icon.svg";
import Info from "./info.png";
import PickupMarker from "./pickupmarker.png";

import DropoffMarker from "./dropoffmarket.png";

import welcomeText from "./welcome.svg";

import box from "./box.svg";
import wall from "./wall.svg";

import packageIcon from "./packageIcon.svg";
import BlueRightArrow from "./blue-right-arrow.png";
import AddBlueButton from "./add-blue-button.png";

import cargoPackages from "./cargo-packages.svg";
import ForwardIcon from "./forward.svg";
import ForwardGreenIcon from "./forward-green.svg";
import AllocatedIcon from "./allocated.svg";
import DashboardPlaceholder from "./dashboard_placeholder.png";
import NetworkPlaceholder from "./network_placeholder.png";
import VehiclePlaceholder from "./vehicle_placeholder.png";
import NotificationsPlaceholder from "./notifications_placeholder.png";
import LoadDraftPlaceholder from "./load_draft.png";
import LoadHistoryPlaceholder from "./load_history.png";
import ClockIcon from "./time.svg";
import DateIcon from "./date.svg";
import dotIcon from "./dotIcon.svg";
import pay from "./pay.png";
import Pay from "./pay.png";
import user from "./user.svg";
import CargoCar from "./cargo-car.png";
import CheckLarge from "./check-large.png";
import Camera from "./camera.svg";
import Gallery from "./gallery.svg";
import DocumentIcon from "./document.svg";
import TruckLocation from "./TruckLocation.png";
import DriverLocation from "./DriverPin.png";

import Xls from "./xls.png";
import Txt from "./txt.png";
import Doc from "./doc.png";
// import Csv from "./Csv.png";
import ContainerPic from "./container.png";
import docCsv from "./docCsv.png"
import docXls from "./docXls.png"
import docTxt from "./docTxt.png"
import docDoc from "./docDoc.png"
import TruckGif from './tuck-gif.gif'
import ScanIcon from './ScanIcon.png';
import PromotionActive from './promotion-active.png';
import PromotionInActive from './promotion-inactive.png';
import newWelcomImg from './new-welcome-img.svg';
import LoaderLottie from './loader-lottie.json'
import draftLoadNew from "./draft-load-new.svg";
import loadHistoryNew from "./load-history-new.svg";
import homeScreenNew from "./home-screen-new.svg";
import savedLocationsNew from "./saved-locations-new.svg";
import noVehicleNew from "./no-vehicle-new.svg";
import noNotificationNew from "./no-notification-new.svg";
import noNetworkNew from "./contact-list-new.svg";
import RecipientAddedLottie from './recipient-added-lottie.json'
import TruckLottie from './truck-lottie.json';
import SuccessMobile from './mobile-img.png';
import ZoomMap from './zoom-map.png';
import UnZoomMap from "./un-zoom-map.png";
import RightArrow from './right-arrow-simple.svg'

// import SpotLoadPost from './spot-load-post.svg';
// import SpotLoadRecieved from './spot-load-recieved.svg';
// import ContractPost from './contract-post.svg';
// import ContractRecieved from './contract-recieved.svg';

import SpotLoadPost from './Spot-Loads-Posted-Multiple-new.png';
import SpotLoadRecieved from './Contract-Loads-Posted-Multiple-new.png';
import ContractPost from './Spots-loads-Received-multiple-new.png';
import ContractRecieved from './Contract-loads-Received-multiple-new.png';
import PostLoad from './post-btn-with-label-new.png';
import SpotLoadSingle from './Spot-loads-single-new.png';
import ContratLoadSingle from './Contrat-loads-single-new.png';
import HomeNewIconActive from './home_new_icon_active.svg';
import TrackingPinLocation from './pin-green-with-blue-icon.svg'
import AwardContractActive from './awarded-contract-active.svg'
import AwardContractInActive from './awarded-contract-inactive.svg'


import SpotRate from './spot-rate.svg';
import ContractRate from './contract-rate.svg';
import Express from './express.svg';
import AwardContract from './award-contract.svg';

import ActiveLoads from './active-loads.svg';
import ContractsInquiryPosted from './contracts-inquiry-posted.svg';
import SpotLoads from './spot-loads.svg';
import SpotLoadsRecieved from './spot-loads-recieved.svg';
import RightArrowGreen from './right-arrow-green.svg';
import ContractInquiryRecieved from './contract-inquiry-recieved.svg';
import Contracted from './contracted.svg';
import NonContracted from './non-contracted.svg';
import Containerized from './containerized.svg';
import NonContainerized from './noncontainerized.svg';
import TrackingPinLocationBlue from './pin-blue-with-green-icon.svg'

export const Images = {
  Containerized,
  NonContainerized,
  RightArrowGreen,
  SpotRate,
  ContractRate,
  Express,
  AwardContract,
  ActiveLoads,
  ContractsInquiryPosted,
  ContractInquiryRecieved,
  SpotLoads,
  SpotLoadsRecieved,
  HomeNewIconActive,
  TrackingPinLocation,
  SpotLoadSingle,
  ContratLoadSingle,
  SpotLoadPost,
  SpotLoadRecieved,
  ContractPost,
  ContractRecieved,
  PostLoad,
  RightArrow,
  ZoomMap,
  UnZoomMap,
  RecipientAddedLottie,
  TruckLottie,
  LoaderLottie,
  PromotionActive,
  PromotionInActive,
  ScanIcon,
  DashboardPlaceholder,
  NetworkPlaceholder,
  VehiclePlaceholder,
  NotificationsPlaceholder,
  LoadDraftPlaceholder,
  LoadHistoryPlaceholder,
  SuccessMobile,
  packageIcon,
  ClockIcon,
  DateIcon,
  CargoCar,
  CheckLarge,
  DocumentIcon,
  Gallery,
  box,
  wall,
  ForwardIcon,
  ForwardGreenIcon,
  AllocatedIcon,
  DropoffMarker,
  PickupMarker,
  Container,
  Gauge,
  Pay,
  TwinLoad,
  LoadHistoryActive,
  LoadHistoryInactive,
  VehicleType,
  postIcon,
  BackArrow,
  IntroImage1,
  IntroImage2,
  IntroImage3,
  MgLogo,
  Play,
  WhatsappGreen,
  WhatsappGray,
  WhatsappDarkGray,
  BackArrowWhite,
  Check,
  AddPlus,
  success,
  EditIcon,
  girlImage,
  boyImage,
  girlImage1,
  threeDots,
  iconMenu,
  UserIconInactive,
  DraftInactive,
  AccountInactive,
  LocationInactive,
  VehicleInactive,
  TermsInactive,
  LogoutInactive,
  HelpInactive,
  NetworkInactive,
  UserIconActive,
  DraftActive,
  AccountActive,
  LocationActive,
  VehicleActive,
  TermsActive,
  LogoutActive,
  HelpActive,
  NetworkActive,
  correct,
  homeIcon,
  search,
  menu,
  homeActive,
  homeInActive,
  ChatActive,
  ChatInActive,
  NetworksActive,
  NetworksInActive,
  NotificationActive,
  NotificationInActive,
  PlusCircle,
  FCL,
  LCL,
  Bulk,
  Document,
  HandShake,
  WhatsAppBgGreen,
  Auction,
  CustomerCare,
  Phone,
  PhoneGray,
  Email,
  Whatsapp,
  resetPassword,
  undrawEmail,
  myProfileImage,
  cameraIcon,
  Camera,
  QRCode,
  pickup,
  dropoff,
  bill,
  list,
  docPng,
  docPdf,
  docOther,
  EditGreen,
  DocTerms,
  accountIcon,
  accountAdd,
  accountMinus,
  TruckBlue,
  TruckOrange,
  FilterIcon,
  BidIcon,
  OfferIcon,
  MessageBubble,
  MessageBubbleGray,
  EditBlue,
  pendingIcon,
  declinedIcon,
  acceptedIcon,
  successPosted,
  successRecipentAdd,
  dot,
  dropoffsmall,
  pickupsmall,
  filePng,
  fileBill,
  filePdf,
  QrScan,
  uploadDocIcon,
  clone,
  removeLoad,
  remove,
  welcomeText,
  BlueRightArrow,
  AddBlueButton,
  cargoPackages,
  dotIcon,
  pay,
  user,
  TruckLocation,
  DriverLocation,
  Info,
  Xls,
  Txt,
  Doc,
  // Csv,
  ContainerPic,
  docXls,
  docCsv,
  docTxt,
  docDoc,
  TruckGif,
  newWelcomImg,
  draftLoadNew,
  loadHistoryNew,
  homeScreenNew,
  savedLocationsNew,
  noVehicleNew,
  noNotificationNew,
  noNetworkNew,
  AwardContractActive,
  AwardContractInActive,
  Contracted,
  NonContracted,
  TrackingPinLocationBlue
};
