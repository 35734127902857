/* eslint-disable radix */
import React, { useEffect } from "react";
import { PrimaryButton, NewInput } from "@components";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";

import FormControlLabel from "@mui/material//FormControlLabel";
import Checkbox from "@mui/material//Checkbox";
// import { withStyles } from "@mui/material//styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import rootReducer from "../../../store/reducers";
import "./style.scss";
import TitleLayout from "@temp/components/TitleLayout";
import { MenuItem, Select } from "@mui/material/";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";

export type RootState = ReturnType<typeof rootReducer>;

export interface PaymentTermsProps {
  index: string;
}

// const GreenCheckbox = withStyles({
//   root: {
//     color: "#7AC251",
//     "&$checked": {
//       color: "#7AC251",
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);

const Page: React.FC<PaymentTermsProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const postLoadData = useSelector(
    (state: RootState) => state.Profile.postLoad
  );
  const Profile = useSelector((state: RootState) => state.Profile.profile);

  const metaData = useSelector((state: RootState) => state.Auth.metaData);

  const PostLoadMeta = useSelector((state: RootState) => state.Profile.postLoadMeta);
  const default_payment = PostLoadMeta?.load_configurations?.payment_terms



  const currencies = [];
  for (let i = 1; i <= 60; i++) {
    if (i === 1) {
      currencies.push({
        value: `${i}`,
        label: `${i == 1 ? `${i} Day` : `${i} Days`}`,
      });
    }
    if (i % 5 === 0)
      currencies.push({
        value: `${i}`,
        label: `${i == 1 ? `${i} Day` : `${i} Days`}`,
      });
  }
  const [state, setState] = React.useState({
    pt_is_advance: false,
    pt_advance_value: null,
    pt_is_ondelivery: false,
    pt_ondelivery_value: null,
    pt_is_credit: false,
    pt_credit_percentage: null,
    pt_credit_days: Number(metaData?.default_credit_days),
    is_pt_fuel_card: false,
    pt_fuel_card_value: null,
    is_gst_inclusive: false,
    is_withholding_tax_inclusive: false,
    sales_tax_province: null,
  });


  const [isError, setIsError] = React.useState(false);
  const [isErrorTax, setIsErrorTax] = React.useState(false);


  React.useEffect(() => {
    if (postLoadData?.PaymentScreen) {

      setState(postLoadData?.PaymentScreen);
    } else {
      const obj = {
        pt_is_advance: default_payment?.advance ? true : false,
        pt_advance_value: default_payment?.advance && Math.trunc(default_payment?.advance),

        pt_is_ondelivery: default_payment?.on_delivery ? true : false,
        pt_ondelivery_value: default_payment?.on_delivery && Math.trunc(default_payment?.on_delivery),

        pt_is_credit: default_payment?.credit_percentage ? true : false,
        pt_credit_percentage: default_payment?.credit_percentage && Math.trunc(default_payment?.credit_percentage),

        is_pt_fuel_card: default_payment?.fuel_card ? true : false,
        pt_fuel_card_value: default_payment?.fuel_card && Math.trunc(default_payment?.fuel_card),

        pt_credit_days: Number(default_payment?.credit_days) || Number(metaData?.default_credit_days),

        is_withholding_tax_inclusive: default_payment?.is_withholding_tax_inclusive == "1" ? true : false,
        is_gst_inclusive: default_payment?.sales_tax_province,
        // default_payment?.sales_tax_province == "1" || default_payment?.sales_tax_province !== "" ? true : false,
        sales_tax_province: default_payment?.sales_tax_province !== "" ? default_payment?.sales_tax_province : ""

      };
      setState({ ...state, ...obj });
    }
  }, []);


  const checkShouldBeHundred = () => {
    const {
      pt_advance_value,
      pt_ondelivery_value,
      pt_credit_percentage,
      pt_is_advance,
      pt_is_ondelivery,
      pt_is_credit,
    } = state;
    if (
      Number(pt_is_advance ? pt_advance_value : 0) +
      Number(pt_is_ondelivery ? pt_ondelivery_value : 0) +
      Number(pt_is_credit ? pt_credit_percentage : 0) ===
      100
    ) {
      setIsError(false);
    }
    else {
      setIsError(true);
    }
  };

  const handleDisableTax = () => {
    if (state?.is_gst_inclusive == true) {
      setIsErrorTax(true);
      if (state?.is_gst_inclusive == true && state?.sales_tax_province == null || state?.sales_tax_province == "" || state?.sales_tax_province == 0) {
        setIsErrorTax(true);
      } else {
        setIsErrorTax(false);
      }
    } else {
      setIsErrorTax(false);
    }
  }
  React.useEffect(() => {
    checkShouldBeHundred();
    handleDisableTax()
  }, [state]);

  const handleChange = (event) => {

    if (event?.target?.name == "is_gst_inclusive") {
      if (!event?.target?.checked) {

        setState({
          ...state,
          sales_tax_province: "",
          is_gst_inclusive: false
        })
      }
    }
    setState({ ...state, [event.target.name]: event.target.checked });
    checkShouldBeHundred();


  };

  const handleChangeSalesTaxProvince = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = () => {

    if (
      postLoadData?.documents?.bill_of_ladings?.length ||
      postLoadData?.documents?.other_uploads?.length ||
      postLoadData?.documents?.packing_lists?.length
    ) {
      dispatch(ProfileAction.postLoadData({ PaymentScreen: state }));

      history.push("uploadedDocument");
    } else {
      dispatch(ProfileAction.postLoadData({ PaymentScreen: state, documents: { bill_of_ladings: [], packing_lists: [], other_uploads: [] } }));
      history.push("uploadedDocument");

    }
  };
  const handleChangeTextValue = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    checkShouldBeHundred();
  };



  return (
    <TitleLayout pageNumber={7} progress={70} title="Payment Terms">
      <div className="paymentTermsWrapper">
        {isError && (
          <div className="error-container info">
            <div>
              <InfoIcon className="error-icon" />
            </div>
            <div className="error-text">
              Total of Advance, On Delivery and Credit must be equal to 100%.
            </div>
          </div>
        )}
        <div className="fieldWrapper">
          <FadeInComponent>
            <div className="inputField">
              <div className="label">Advance</div>
              <div className="field">
                <NewInput
                  keyDown={(e) => {
                    return true
                    if (e.keyCode == 96) {
                      return true
                    }
                  }}
                  onChange={(e) => {
                    if (e?.target?.value.length <= 3 && e?.target?.value <= 100) {
                      setState({
                        ...state,
                        pt_advance_value: e.target.value,
                        pt_is_advance: e?.target?.value ? true : false,
                      });
                    }
                  }}
                  value={state.pt_is_advance ? state.pt_advance_value : ""}
                  showEnd={"%"}
                />
              </div>
            </div>
          </FadeInComponent>
          <FadeInComponent>
            <div className="inputField">
              <div className="label">On Delivery</div>
              <div className="field">
                <NewInput
                  onChange={(e) => {
                    if (e?.target?.value.length <= 3 && e?.target?.value <= 100) {
                      setState({
                        ...state,
                        pt_ondelivery_value: e.target.value,
                        pt_is_ondelivery: e?.target?.value ? true : false,
                      });
                    }
                  }}
                  value={state.pt_is_ondelivery ? state.pt_ondelivery_value : ""}
                  showEnd={"%"}
                />
              </div>
            </div>
          </FadeInComponent>
          <FadeInComponent>
            <div className="innerColWrapper multiSelect">
              <div className="inputField">
                <div className="label">Credit</div>
                <div className="field">
                  <NewInput
                    onChange={(e) => {
                      if (e?.target?.value.length <= 3 && e?.target?.value <= 100) {
                        setState({
                          ...state,
                          pt_credit_percentage: e.target.value,
                          pt_is_credit: e?.target?.value ? true : false,

                        });
                      }
                    }}
                    value={state.pt_is_credit ? state.pt_credit_percentage : ""}
                    showEnd={"%"}
                  />
                </div>
              </div>
              <div className="userSelectBox">
                <Select
                  id="outlined-select-date-native"
                  defaultValue={state?.pt_credit_days}
                  value={state?.pt_credit_days}
                  name="pt_credit_days"
                  onChange={handleChangeTextValue}
                  className="add-vehicle-select-box2"
                >
                  {currencies.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </FadeInComponent>
          <FadeInComponent>
            <div className="inputField">
              <div className="label">Fuel Card</div>
              <div className="field">
                <NewInput
                  onChange={(e) => {
                    if (e?.target?.value.length <= 3 && e?.target?.value <= 100) {
                      handleChangeTextValue(e);
                      setState({
                        ...state,
                        pt_fuel_card_value: e.target.value,
                        is_pt_fuel_card: e?.target?.value ? true : false,
                      });
                    }
                  }}
                  value={state.is_pt_fuel_card ? state.pt_fuel_card_value : ""}
                  showEnd={"%"}
                />
              </div>
            </div>
          </FadeInComponent>
        </div>



        <FadeInComponent>
          <div className="sectionBox">
            <label className="titleSection" style={{ display: "block", marginBottom: 0 }}>Tax</label>
            <FormControlLabel
              className="checkboxLabel"
              control={
                <Checkbox
                  checked={state.is_withholding_tax_inclusive}
                  onChange={(e) => {
                    setState({
                      ...state,
                      is_withholding_tax_inclusive: e.target.checked,

                    })

                  }}
                  name="is_withholding_tax_inclusive"
                />
              }
              label="Withholding tax deduction applicable"
            />
            <FormControlLabel
              className="checkboxLabel"
              control={
                <Checkbox
                  checked={state.is_gst_inclusive}
                  onChange={(e) => {
                    setState({
                      ...state,
                      is_gst_inclusive: e.target.checked,
                      sales_tax_province: e.target.checked ? state?.sales_tax_province : ""

                    })

                  }}
                  name="is_gst_inclusive"
                />
              }
              label="Sales Tax invoice required"
            />
            {state.is_gst_inclusive && (
              <div className="userSelectBox">
                <Select
                  id="outlined-select-date-native"
                  select
                  defaultValue={state?.sales_tax_province}
                  value={state.is_gst_inclusive ? state?.sales_tax_province : ""}
                  name="sales_tax_province"
                  onChange={handleChangeSalesTaxProvince}
                  className="add-vehicle-select-box2"
                >
                  {PostLoadMeta?.sales_tax_by_province.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}


                </Select>
              </div>
            )}

          </div>
        </FadeInComponent>
      </div>

      <PrimaryButton
        rightIcon
        title="CONTINUE"
        disabled={isError || isErrorTax ? true : false}
        onClick={() => {
          handleSubmit();
        }}
      />
    </TitleLayout>
  );
};

export default Page;
