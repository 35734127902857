export const Colors = {
  Primary: "#7ac251",
  Secondary: "rgba(176,215,146,0.1)",
  NavyBlue: "#0d5296",
  Green: "#7ac251",
  LightGreen: "#b0d792",
  LightBlue: "#589cd5",
  White: "#ffffff",
  LightWhite: "#f1f1f1",
  Grayish: "rgb(163,163,163)",
  PinkishGray: "#cccccc",
  Black: "rgb(13,21,9)",
  SageGreen: "rgb(176, 215, 146)",
  Orange:'rgb(254, 159, 17)',
  IceBlue: 'rgba(88, 156, 213, 0.1)',
  BrightYellow: 'rgba(254, 188, 17, 0.1)',
  LightGray:'rgb(250, 250, 250)',
  Red:'#DA2C2C',
  LightGreenish:'rgba(75, 156, 28, 0.1)',
  Redish:'rgba(238, 44, 44, 0.1)',
  Bluish:'rgba(13, 82, 150, 0.05)',
  Yellowish:'rgba(254, 159, 17, 0.1)',
  Yellow:'rgba(254, 159, 17, 1)'
};
