import React, { useEffect, useState } from "react";
import { IContractDetailRecipientPageProps } from "./types";
import "./style.scss";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import _ from "lodash";

import {
  CustomText,
  LocationTimeline,
  TitleLayout,
} from "@components";
import { useHistory, useLocation } from "react-router-dom";
import { Images } from "@temp/assets";
import { REQUEST_VEHICLE_CATEGORIES } from "@temp/app/routes";
import { useDispatch } from "react-redux";
import { LoadAction } from "@temp/store/actions";

const Page: React.FC<IContractDetailRecipientPageProps> = (props) => {
  const location = useLocation()
  const history = useHistory()
  const { state } = location;
  const dispatch = useDispatch()
  const clickLocation = (data, index) => {
    let new_categories = []
    if (data?.categories && data?.categories.length) {
      data?.categories.forEach(category => {
        new_categories.push({ ...category, recipients: category?.recipients?.filter(recipient => recipient?.status == 4) })
      })
    } else {
      new_categories.push({ ...data?.pickup?.attributes, quote_by: "weight_volume", recipients: data?.recipients?.filter(recipient => recipient?.status == 4) })
    }
    let payload = {
      data: state?.data,
      new_categories,
      location: index,
      location_data: data
    }
    dispatch(LoadAction.setRequestVehicleCategory([]))

    // 
    history.push(REQUEST_VEHICLE_CATEGORIES, { ...payload })
  }

  return (
    <TitleLayout titleOnly titleOnlyText={`Contract ID - ${state?.data?.id}`}>
      <div className="vehicleContentWrapper" style={{
        height: 'calc(100vh - 100px)',
        overflow: 'auto'
      }}>
        <div className="infoTextWrapper text-center">To Request vehicle select any one location</div>
        {
          state?.data?.contract_locations.map((location, index) => {
            if (location?.total_awarded > 0) {
              return (
                <div onClick={() => {
                  clickLocation(location, index + 1)
                }} className="request-vehicle-location-container">
                  <div className="request-vehicle-title-container">
                    <CustomText.OpenSansBold>{`Route ${index + 1} ${location?.pickup?.title} - ${location?.dropoff?.title}`}</CustomText.OpenSansBold>
                    <img src={Images.RightArrow} />
                  </div>
                  <LocationTimeline
                    location={[location?.pickup, location?.dropoff]}
                    handleModal={(value: boolean) => { }}
                    showTitle={false}
                  ></LocationTimeline>
                </div>
              )
            }
          })
        }
      </div>

    </TitleLayout>
  );

};
export default Page;
