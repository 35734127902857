import React, { useState } from "react";
import {
  BackHeader,
  PrimaryButton,
  CustomText,
  OutLineButton,
  PhoneInput,
} from "@components";

import { Images, Colors } from "@temp/assets";
import "./style.scss";
import { ForgotPasswordPageProps } from "./types";
import { preventNonNumericalInput } from "@temp/utils/helper";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthAction } from "@temp/store/actions";
import {
  SimpleInput,
} from "@temp/components";
import SimplePhoneInput from "@temp/components/SimplePhoneInput";

const Page: React.FC<ForgotPasswordPageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (e: any) => {

    // if (e.target.value.length > 10) {
    //   return;
    // }
    // setPhoneNumber(e.target.value);

    let newValue = e.target.value;
    // Remove non-digit characters (including hyphens)
    newValue = newValue.replace(/[^0-9]/g, '');
    // Remove specific disallowed numbers like '92'
    if (newValue.startsWith('92')) {
      newValue = newValue.slice(2); // Remove '92' from the start
    }
    // Remove leading zeros
    newValue = newValue.replace(/^0+/, '');
    // Limit length to 10 digits
    newValue = newValue.slice(0, 10);
    setPhoneNumber(newValue);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    // Remove non-numeric characters
    const numericValue = paste.replace(/[^0-9]/g, '');
    // Prevent pasting if the first character is 0
    if (event?.target?.value.length > 10) {
      return;
    }
    if (numericValue[0] !== '0') {
      setPhoneNumber(numericValue);
    }

  }

  const callback = (data) => {
    history.push("/otp", {
      phoneNumber: data.phoneNumber,
      type: data.type,
      isForgot: true,
    });
  };

  const handleSubmit = (type: string) => {
    let payload = {
      phoneNumber,
      type,
      cb: callback,
    };
    dispatch(AuthAction.forgotPassword(payload));
  };
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  return (
    <div>
      <div className="back-header">
        <BackHeader title="Reset Password" onClick={() => {
          history.goBack()
        }} />
      </div>
      <div className="forogt-pw-wrapper">
        <div className="center-wrapper">
          <div>
            <img src={Images.resetPassword} className="img" alt="" />
          </div>
          <div>
            <CustomText.OpenSansRegular
              textStyle={{
                fontSize: "0.875rem",
                color: "#8a8888",
              }}
            >
              Please enter your registered mobile number and we'll send a link
              with instructions to reset your password.
            </CustomText.OpenSansRegular>
          </div>
          <div className="inputField driverNumberField" style={{ marginTop: 15 }}>
            <div className="label">Enter Mobile Number</div>
            <div className="field">
              {/* <PhoneInput
                value={phoneNumber}
                phoneCode={"92"}
                onChange={(e) => {
                  handleChange(e)
                }}
                id="phone-number"
                placeholder={"3000000000"}
              /> */}
              
              <SimplePhoneInput
                value={phoneNumber}
                onChange={handleChange}
                onPaste={handlePaste}
                placeholder="3000000000"
                maxLength={10}
                id="phone-number"
              />
            </div>
          </div>

          <div style={{
            marginTop: 20
          }}>
            <div className="button-divider">
              {/* <PrimaryButton
                disabled={phoneNumber.length < 10}
                title="Send Code via WhatsApp"
                onClick={() => {
                  handleSubmit("whatsapp");
                }}
                icon={
                  phoneNumber.length >= 10
                    ? Images.WhatsappGreen
                    : Images.WhatsappGray
                }
              /> */}
            </div>
            <div className="button-divider">
              <OutLineButton
                disabled={phoneNumber.length < 10}
                title="Send Code via SMS"
                icon={
                  phoneNumber.length >= 10
                    ? Images.MessageBubble
                    : Images.MessageBubbleGray
                }
                onClick={() => {
                  handleSubmit("sms");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
