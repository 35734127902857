import React, { FC, useState, useEffect } from "react";
import { PrimaryButton, RadioButton, DraggableDialog, CustomModal, SimpleCheckBox } from "@components";
import { ProfileAction } from "@temp/store/actions";
import { useSelector, useDispatch } from "react-redux";
import { LOAD_TYPE, TRANSACTION_TYPE } from "@temp/constants";
import CategoryContainer from "../Offer/CategoryContainer";
import CargoContainer from "../../AdditionalDetailsLclBulk/CargoDetailsCard";
import { useHistory } from "react-router-dom";
import "./style.scss";
import TitleLayout from "@temp/components/TitleLayout";
import InfoIcon from "@mui/icons-material/Info";
import {Radio, 
	// withStyles 
} from "@mui/material/";
import { green } from "@mui/material//colors";
import { UnitCategory } from "../Offer/UnitCategory";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";

const Page: FC = () => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const PostLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta)
  const [vehicles, setVehicles] = useState([]);
  const [added_units, set_added_units] = useState([])
  const [vehicleScreen_values, setVehicleScreenValues] = useState(
    PostLoad?.vehicleScreen?.values || null
  );

  useEffect(() => {
    setVehicleScreenValues({
      ...vehicleScreen_values,
      max_bid_type: 'text',
      detention_free_time: PostLoad?.fromClone ? vehicleScreen_values?.detention_free_time : PostLoadMeta?.load_configurations?.free_time_setting || 24
    });
  }, [])


  const handleFocus = (index: any) => {
    let new_vehicle = [...vehicles]
    new_vehicle[index]["fieldType"] = "number"
    setVehicles(new_vehicle)
  }

  const handleBlur = (index) => {
    let new_vehicle = [...vehicles]
    new_vehicle[index].fieldType = "text"
    setVehicles(new_vehicle)
  }

  const handleFocus2 = (index: any) => {
    setVehicleScreenValues({ ...vehicleScreen_values, max_bid_type: 'number' });
  }

  const handleBlur2 = (index) => {
    setVehicleScreenValues({ ...vehicleScreen_values, max_bid_type: 'text' });
  }

  useEffect(() => {
    if (PostLoad?.vehicleScreen?.addedVehicles) {
      let new_data = [...PostLoad?.vehicleScreen?.addedVehicles];
      new_data?.forEach((item) => {
        if (item.hasOwnProperty("detention_free_time")) {
        } else {
          item["detention_free_time"] = PostLoadMeta?.load_configurations?.free_time_setting || 24;
          item["detention_rate"] = 0;
          item["max_bid"] = "";
        }
      });
      setVehicles(new_data);
    }
    if (PostLoad?.vehicleScreen?.added_units?.length) {
      let new_data = [...PostLoad?.vehicleScreen?.added_units]
      new_data?.forEach((item) => {
        if (item.hasOwnProperty("detention_free_time")) {
        } else {
          item["detention_free_time"] = PostLoadMeta?.load_configurations?.free_time_setting || 24;
          item["detention_rate"] = 0;
          item["max_bid"] = "";
        }
      })
      set_added_units(new_data)
    }
  }, []);

  let dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSumit = () => {
    if (PostLoad?.vehicleScreen?.addedVehicles) {
      let check = false;
      PostLoad?.vehicleScreen?.addedVehicles.forEach((val: any) => {
        if (val.price && val.price > 0) {
          check = true;
        }
        if (
          val?.price === null ||
          val?.price !== 0 ||
          val?.loading_price === null ||
          val?.loading_price !== 0 ||
          val?.unloading_price === null ||
          val?.unloading_price !== 0 ||
          val?.max_bid === null ||
          val?.max_bid !== 0
        ) {
          check = true;
        }
      });

      if (
        PostLoad?.vehicleScreen?.values?.quote_by &&
        PostLoad.vehicleScreen.values.quote_by === "weight_volume"
      ) {
        const {
          price,
          unloading_price,
          loading_price,
          max_bid,
        } = PostLoad?.vehicleScreen?.values;

        if (
          price === null ||
          price !== 0 ||
          loading_price === null ||
          loading_price !== 0 ||
          unloading_price === null ||
          unloading_price !== 0 ||
          max_bid === null ||
          max_bid !== 0
        ) {
          check = true;
        }
      }
      if (check) {
        handleContinue();
      } else {
        dispatch(
          ProfileAction.postLoadData({
            transaction_type_id: TRANSACTION_TYPE?.bidId,
          })
        );
        history.push(`/spot-location/add-new`, { from: 'spot' });
      }
    }
  };
  const handleContinue = () => {
    let values = PostLoad?.vehicleScreen?.addedVehicles;
    values.forEach((val: any, index) => {
      if (val.price && val.total_amount) {
        delete val.price;
        delete val.total_amount;
      }
      if (val.unloading_price || val.loading_price) {
        values[index].unloading_price = 0;
        values[index].loading_price = 0;
      }
    });
    if (
      vehicleScreen_values.total_amount &&
      vehicleScreen_values.price &&
      vehicleScreen_values?.unloading_price &&
      vehicleScreen_values?.loading_price
    ) {
      delete vehicleScreen_values.price;
      delete vehicleScreen_values.total_amount;
      vehicleScreen_values.unloading_price = 0;
      vehicleScreen_values.loading_price = 0;
    }
    dispatch(
      ProfileAction.postLoadData({
        transaction_type_id: TRANSACTION_TYPE?.bidId,
        vehicleScreen: {
          ...PostLoad.vehicleScreen,
          addedVehicles: values,
          values: vehicleScreen_values,
          added_units
        },
        additionalScreen: additional_data
      })
    );
    history.push(`/spot-location/add-new`, { from: 'spot' });
  };

  const postLoadMeta = useSelector((state) => state?.Profile?.postLoadMeta);
  let transaction_types_offer_id = postLoadMeta.transaction_types.find(
    (val: any) => val.title === "Offer"
  ).id;

  const [info, setInfo] = useState("");
  const [showModal, toggleModal] = useState(false);
  const [additional_data, set_additional_data] = useState(PostLoad?.additionalScreen)

  useEffect(() => {
    set_additional_data(PostLoad?.additionalScreen)
  }, [])

  const load_type = postLoadMeta.load_types.find(
    (val: object) => val.id === PostLoad.load_type
  );

//   const GreenRadio = withStyles({
//     root: {
//       color: green[400],
//       "&$checked": {
//         color: green[600],
//       },
//     },
//     checked: {},
//   })((props) => <Radio color="default" {...props} />);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value === "quote_price_inclusive_of_deposit_fee") {
      set_additional_data({ ...additional_data, is_quote_price_deposit_fee_inclusive: true })
    } else {
      set_additional_data({ ...additional_data, is_quote_price_deposit_fee_inclusive: false })
    }
  };

  const handleDisable = ()=>{
    if(vehicles.length){
     return vehicles.every((item) => item?.detention_free_time)
      ? false
      : true
    }else if(added_units.length){
      added_units.every((item) => item?.detention_free_time)
      ? false
      : true
    }else{
      return !vehicleScreen_values?.detention_free_time || !vehicleScreen_values?.price_unit
    }

    // vehicles?.length > 0
    // ? vehicles.every((item) => item?.detention_free_time)
    //   ? false
    //   : true
    // : !vehicleScreen_values?.detention_free_time || !vehicleScreen_values?.price_unit
  }
  return (
    <TitleLayout
      pageNumber={5}
      progress={50}
      title={`Transaction Type - ${load_type.title}`}
    >
      <DraggableDialog
        open={open}
        handleClose={() => {
          handleClose();
        }}
        text={"Changing transaction type will clear the entered data ?"}
        handleClickOpen={() => {
          handleClickOpen();
        }}
        handleContinue={handleContinue}
      />

      <FadeInComponent>
        <div className="tabWrapper">
          <RadioButton
            value={"1"}
            id={"1"}
            name="testt"
            label="Bid"
            defaultChecked
          />
          <RadioButton
            id="radio1"
            name="test"
            label="Offer"
            onChange={(value: any) => {
              dispatch(
                ProfileAction.postLoadData({
                  transaction_type_id: transaction_types_offer_id,
                })
              );
              history.replace("/offer");
            }}
          />
          <div className="radioTab_slider"></div>
        </div>
      </FadeInComponent>

      <div className="transactionTypeBidWrapper">
        <FadeInComponent>
          <p className="my-4 text-center allowText">
            You are allowing the recipient to place bid, please click continue to
            proceed
          </p>
        </FadeInComponent>

        {!!vehicles.length && (
          <div>
            {vehicles.map((val, index) => {
              
              return (
                <div key={index}>
                  <FadeInComponent>
                    <CategoryContainer
                      from={"bid"}
                      value={val}
                      index={index}
                      screenTitle={load_type.title}
                      handleFocus={handleFocus}
                      handleBlur={handleBlur}
                      onChangePrice={(
                        index: number,
                        value: string,
                        key,
                        is_string,
                        not_multiply
                      ) => {
                        if (load_type.title === LOAD_TYPE.fcl) {
                          vehicles[index];
                          if (is_string) {
                            vehicles[index][key] = value;
                          } else {
                            vehicles[index][key] = Number(value);
                            vehicles[index].total_amount =
                              (vehicles[index]["price"] +
                                vehicles[index]["detention_rate"]) *
                              vehicles[index].vehicle_quantity;
                          }
                          setVehicles([...vehicles]);
                        } else {
                          vehicles[index][key] = Number(value);
                          vehicles[index].total_amount =
                            (Number(vehicles[index].loading_price) +
                              Number(vehicles[index].unloading_price) +
                              Number(vehicles[index].price)) *
                            vehicles[index].vehicle_quantity;
                          setVehicles([...vehicles]);
                        }
                      }}
                      categoryLength={vehicles.length}
                    />
                  </FadeInComponent>      
                </div>
              )
            })}

            {/* {
              PostLoad?.load_type == 1 ?
              <FadeInComponent>
                <div className="additionalCheckboxStyle">
                  <SimpleCheckBox
                    id={"detention_included_transport"}
                    labelName="detention_included_transport"
                    name="detention_included_transport"
                    title="1 Mandatory Detention included in Transport Price"
                    handleChange={(e) => {
                      set_additional_data({ ...additional_data, detention_included_transport: e.target.checked })
                    }}
                    setInfo={setInfo}
                    toggleModal={toggleModal}
                    checked={additional_data.detention_included_transport}
                  />
                </div>
              </FadeInComponent> : null
            } */}
            {
              PostLoad?.load_type == 1 && additional_data?.is_container_deposit_by_transporter ? (
              <>
                <FadeInComponent>
                  <div style={{
                    marginTop: 0,

                  }} className={`filterBy customRadioCheck newCustomCheck ${additional_data?.is_quote_price_deposit_fee_inclusive ? 'filled' : ''}`}>
                    <Radio
                      checked={additional_data?.is_quote_price_deposit_fee_inclusive && "quote_price_inclusive_of_deposit_fee"}
                      onChange={handleChangeRadio}
                      value="quote_price_inclusive_of_deposit_fee"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "quote_price_inclusive_of_deposit_fee" }}
                    />
                    <label className="label">
                      Quote price inclusive of deposit fee
                    </label>
                  </div>
                </FadeInComponent>
                <FadeInComponent>
                  <div style={{
                    marginTop: 0
                  }} className={`filterBy customRadioCheck newCustomCheck ${!additional_data?.is_quote_price_deposit_fee_inclusive ? 'filled' : ''}`}>
                    <Radio
                      checked={!additional_data?.is_quote_price_deposit_fee_inclusive && "quote_deposit_fee_separately"}
                      onChange={handleChangeRadio}
                      value={"quote_deposit_fee_separately"}
                      name="radio-button-demo"
                      label="Quote deposit fee separately"
                      inputProps={{ "aria-label": "quote_deposit_fee_separately" }}
                    />
                    <label className="label">
                      Quote deposit fee separately
                    </label>
                  </div>
                </FadeInComponent>
              </> ) : null
            }
            {PostLoad.additionalScreen?.is_cross_stuffing_required ? (
              <div className="error-container info">
                <div>
                  <InfoIcon className="error-icon" />
                </div>
                <div className="error-text">
                  Bid price should be inclusive of local transportation + shifting charges + upcountry transportation.
                </div>
              </div>
            ) : null}
          </div>
        )}

        {PostLoad?.vehicleScreen?.values?.quote_by &&
          PostLoad?.vehicleScreen?.values?.quote_by === "weight_volume" && !PostLoad?.vehicleScreen?.is_unit_basis && (
            <div>'
              <FadeInComponent>
                <CargoContainer
                  value={vehicleScreen_values}
                  title={load_type.title}
                  screen={"bid"}
                  showTime={true}
                  handleFocus2={handleFocus2}
                  handleBlur2={handleBlur2}
                  vehicleScreenData={PostLoad?.vehicleScreen || null}
                  onChangeValue={(key: any, value: any) => {
                    vehicleScreen_values[key] = value;
                    setVehicleScreenValues({ ...vehicleScreen_values });
                  }}
                  onChangePrice={(value: string, key) => {
                    vehicleScreen_values[key] = Number(value);
                    vehicleScreen_values.total_amount =
                      (Number(vehicleScreen_values.loading_price) +
                        Number(vehicleScreen_values.unloading_price) +
                        Number(vehicleScreen_values.price)) *
                      vehicleScreen_values["total_freight_ton"];
                    setVehicleScreenValues({ ...vehicleScreen_values });
                  }}
                />
              </FadeInComponent>
              {PostLoad.additionalScreen?.is_cross_stuffing_required ? (
                <div className="error-container info">
                  <div>
                    <InfoIcon className="error-icon" />
                  </div>
                  <div className="error-text">
                    Bid price should be inclusive of local transportation + shifting charges + upcountry transportation.
                  </div>
                </div>
              ) : null}
            </div>
          )}

        {
          PostLoad?.vehicleScreen?.is_unit_basis &&
          <div>
            {
              added_units?.map((item, index) => {
                return (
                  <div key={index}>
                    <FadeInComponent>
                      <UnitCategory
                        value={item}
                        index={index}
                        added_units={added_units}
                        onChangePrice={(index, value, key, flag) => {
                          let new_data = [...added_units]
                          new_data[index][key] = Number(value);
                          new_data[index].total_amount =
                            (Number(new_data[index].loading_price) +
                              Number(new_data[index].unloading_price) +
                              Number(new_data[index].price)) *
                            new_data[index].vehicle_quantity;
                          set_added_units(new_data);

                        }}
                        from={"bid"}
                      />
                    </FadeInComponent>
                  </div>
                )
              })
            }
          </div>
        }
      </div>

      {
        
      }

      {
        
      }

      <PrimaryButton
        title="Continue"
        disabled={
         handleDisable()
        }
        rightIcon={true}
        onClick={() => {
          handleSumit();
        }}
      />

      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showModal}
        toggleModal={toggleModal}
      >
        <div>
          <InfoIcon className="icon" />
          <p className="title">Information</p>
          <p>
            {info}
          </p>
        </div>
      </CustomModal>
    </TitleLayout>
  );
};
export default Page;
