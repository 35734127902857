import { Colors, Images } from "@temp/assets";
import { CustomModal, CustomText, PrimaryButton } from "@temp/components";
import React, { useEffect, useState } from "react";
import "../LoadDetailPoster/style.scss";
import { phoneNumberFormater } from "@temp/utils/helper";
import { Close } from "@mui/icons-material";
import { LoadAction } from "@temp/store/actions";
import { useDispatch } from "react-redux";
import { Button, DialogTitle } from "@mui/material/";

export const LoadViewer = ({
  showViewer,
  addViewer,
  loadDetail,
  fromHistory
}) => {

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false)
  const [currentId, setCurrentId] = useState("");
  const [appearModal, setAppearModal] = useState(false);
  let sorted_data = loadDetail?.viewers.sort((a, b) => {
    let fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });


  const handleDeleteViewer = () => {
    setAppearModal(false);
    dispatch(LoadAction.removeViewer({ viewer_id: currentId, load_id: loadDetail?.id, }));
  };
  return (
    <div>
      {fromHistory || loadDetail?.viewers?.length > 0  ||( showViewer && sorted_data?.length > 0) ? (
        <div
          className="viewerListing newViewerListing"
        >

          <div style={{ width: 270 }} className="tooltip viewerWrapper" >
            {
              loadDetail?.viewers?.length > 0 &&
              <img
                className="viewerImg"
                src={loadDetail?.viewers[0]?.picture || Images.myProfileImage}
              />
            }
            <div style={{ marginLeft: 10 }}
              className="fw-5 customViewerWrap"
            >
              <div className="leftViewerWrap">
                {/* {sorted_data[0]?.name} */}
                {sorted_data?.company?.length ?
                  <>
                    {sorted_data[0]?.company && sorted_data[0]?.company.length ? (
                      <p style={{ fontWeight: 800 }} className="companyTitle">
                        {sorted_data[0]?.company && sorted_data[0]?.company.length
                          ? sorted_data[0]?.company[0]?.name || ""
                            ? sorted_data[0]?.company[0]?.name || ""
                            : ""
                          : ""}
                      </p>
                    ) : ""}
                    {loadDetail?.viewers?.length > 0  && sorted_data[0]?.name ? <p style={{ fontWeight: 100 }} className="name">{sorted_data[0]?.name || ""} </p> : ""}
                    {/* {<p className="phone">{`${phoneNumberFormater(sorted_data[0]?.phone_code, sorted_data[0]?.phone_no)}`}</p>} */}
                  </> :
                  <>
                    {loadDetail?.viewers?.length > 0  && sorted_data[0]?.name ? <p style={{ fontWeight: 800 }} className="companyTitle">{sorted_data[0]?.name || ""}</p> : ""}
                    {loadDetail?.viewers?.length > 0  && sorted_data[0]?.company_name ? <p style={{ fontWeight: 100 }} className="name">{sorted_data[0]?.company_name}</p> : ""}
                    {/* {<p className="phone"> {`${phoneNumberFormater(sorted_data[0]?.phone_code, sorted_data[0]?.phone_no)}`}</p>} */}
                  </>
                }
              </div>
              {
                !fromHistory && loadDetail?.status_name !== "Completed" ?

                  <span
                    className="icons-cross floatRight"
                    style={{
                      cursor: "pointer",
                      fontSize: '1rem'
                    }}
                    onClick={() => {
                      setCurrentId(sorted_data[0]?.viewer_id);
                      setAppearModal(true);
                    }}
                  ></span>
                  : null}
            </div>
          </div>

          {
            sorted_data?.length > 1 ?
              <div style={{ cursor: "pointer" }} onClick={() => {
                setShowModal(true)
              }}>
                <CustomText.OpenSansSemiBold textStyle={{
                  textDecoration: 'underline',
                  color: Colors.Primary
                }}>More</CustomText.OpenSansSemiBold>
              </div> : null
          }
        </div>
      ) : null}
      {!fromHistory && showViewer ?
        <div className="accordion-content">
          <PrimaryButton
            buttonStyle={{
              width: '100%',
              height: 44
            }}
            title="Add Viewer"
            disabled={loadDetail?.status_name == "Completed"}
            onClick={() => {
              addViewer({
                from: "detail",
                load_id: loadDetail.id,
                loadDetail,
                alreadyViewers: (loadDetail?.viewers)?.map((user) => {
                  return { networkUser: user };
                }),
                viewerNumbers: (loadDetail?.viewers)?.map(
                  (item) => item?.phone_no
                ),
              });
            }}
          />
        </div>
        : null
      }
      <CustomModal
        showModal={showModal}
        toggleModal={() => {
          setShowModal(false)
        }}
        className={"viewerModal"}
        modalStyle={{ width: 218 }}
      >

        <div>
          <span className="icons-cross floatRight" onClick={() => { setShowModal(false); }}></span>
          <div className="insideViewerData">
            {
              sorted_data?.map((item, index) => {

                return (
                  <div className="flexRow viewerDataWrap">
                    <div className="viewerWrapper">
                      <img
                        className="viewerImg"
                        src={item?.picture || Images.myProfileImage}
                      />
                    </div>
                    <div style={{ marginLeft: 10 }}
                      className="fw-5 customViewerWrap"
                    >

                      <div className="leftViewerWrap">
                        {/* {item?.name} */}
                        {item?.company?.length ?
                          <>
                            {item?.company && item?.company.length ? (
                              <p style={{ fontWeight: 800 }} className="companyTitle">
                                {item?.company && item?.company.length
                                  ? item?.company[0]?.name || ""
                                    ? item?.company[0]?.name || ""
                                    : ""
                                  : ""}
                              </p>
                            ) : ""}
                            {item?.name ? <p style={{ fontWeight: 100 }} className="name">{item?.name || ""} </p> : ""}
                            {/* {<p className="phone">{`${phoneNumberFormater(item?.phone_code, item?.phone_no)}`}</p>} */}
                          </> :
                          <>
                            {item?.name ? <p style={{ fontWeight: 800 }} className="companyTitle">{item?.name || ""}</p> : ""}
                            {item?.company_name ? <p style={{ fontWeight: 100 }} className="name">{item?.company_name}</p> : ""}
                            {/* {<p className="phone"> {`${phoneNumberFormater(item?.phone_code, item?.phone_no)}`}</p>} */}
                          </>
                        }
                      </div>
                      {
                        !fromHistory && loadDetail?.status_name !== "Completed" ?
                          <span
                            className="icons-cross floatRight"
                            style={{
                              cursor: "pointer",
                              fontSize: '1rem'
                            }}
                            onClick={() => {
                              setCurrentId(item?.viewer_id);
                              setAppearModal(true);
                            }}
                          ></span>
                          : null}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </CustomModal >

      {/* delete viewer modal  */}

      < CustomModal
        showModal={appearModal}
        toggleModal={setAppearModal}
        modalStyle={{
          width: 290,
          minHeight: 180
        }}
      >
        <div className="logout-modal-background">
          <DialogTitle id="simple-dialog-title">
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "1.25rem", textAlign: "center" }}
            >
              Remove Viewer
            </CustomText.OpenSansSemiBold>
            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Are you sure you want to remove viewer?
            </CustomText.OpenSansRegular>
            <div className="action-button-container">
              <Button
                onClick={() => {
                  setAppearModal(false);
                }}
                style={{
                  backgroundColor: Colors.Grayish,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  NO
                </CustomText.OpenSansSemiBold>
              </Button>
              <Button
                onClick={() => {
                  handleDeleteViewer();
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  YES
                </CustomText.OpenSansSemiBold>
              </Button>
            </div>
          </DialogTitle>
        </div>
      </CustomModal >
    </div >
  );
};
