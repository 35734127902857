import React, { useEffect } from "react";
import { IDashBoardPageProps } from "./types";
import "./style.scss";
import { ButtomTabs, DrawerNav, PrimaryButton } from "@temp/components";
import { Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import disableBrowserBackButton from "disable-browser-back-navigation";
import { useDispatch, useSelector } from "react-redux";
import { AuthAction, CounterAtion, ProfileAction } from "@temp/store/actions";
import LoadCard from "@temp/components/LoadCard";
import { triggerMixpanelEvent } from "@utils/helper";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
// import "./style.css"
const Page: React.FC<IDashBoardPageProps> = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const UserData = useSelector((state: any) => state?.Profile?.profile || []);
  useEffect(() => {
    dispatch(AuthAction.getMetaData());
    dispatch(ProfileAction.GetProfile());
    disableBrowserBackButton();
  }, [])
  const callBack = () => {
    history.push("inquiry-type");
  };

  return (
    <div className="root-wrapper dashboardMainWrapper">
      <div className="root-center-container">
        {/* dropdwon load posted, load received */}
        <div className="topDropDownWrap">
          <div
            className="drop-down"
            style={{ justifyContent: "space-between" }}
          >
            <DrawerNav />
            <p className="title">Home</p>
            <div></div>
          </div>
        </div>
        <div className="insideDashboardLoadWrapper">
          {/* 4 column layout */}
          <div className="loadCardWrapper" >
            
              <LoadCard
                disabled="false"
                title="Active Loads"
                img={Images.ActiveLoads}
                onClick={() => {
                  triggerMixpanelEvent("Active Loads Received Listing", "Click on active load listing")
                  history.push('/active-loads')
                }}
                imgStyle={{
                  width: '56%',
                  marginBottom: 16
                }}
              />

              <LoadCard
                title="Spot Loads Posted"
                img={Images.SpotLoads}
                onClick={() => {
                  triggerMixpanelEvent("Spot Loads Posted Open Listing", "Click on Spot Loads Posted listing")
                  history.push('/dashboard/spot-posted/open', { showTabs: true })
                }}
                imgStyle={{
                  marginRight: -22
                }}
              />
    
              <LoadCard
                title="Spot Loads Recieved"
                img={Images.SpotLoadsRecieved}
                onClick={() => {
                  triggerMixpanelEvent("Spot Loads Recieved New Listing", "Click on Spot Loads Recieved listing")
                  history.push('/dashboard/spot-recieved/new', { showTabs: true })
                }}
                imgStyle={{
                  marginRight: -22
                }}
              />
 
              <LoadCard
                title="Contract Inquiry Posted"
                img={Images.ContractsInquiryPosted}
                onClick={() => {
                  triggerMixpanelEvent("Contract Inquiry Posted Open Listing", "Click on Contract Inquiry Posted listing")
                  history.push('/dashboard/contract-posted/open', { showPostedRecievedTabs: false })
                }}
                imgStyle={{
                  marginLeft: -35
                }}
              />
   
              <LoadCard
                title="Contract Inquiry Recieved"
                img={Images.ContractInquiryRecieved}
                onClick={() => {
                  triggerMixpanelEvent("Contract Inquiry Recieved New Listing", "Click on Contract Inquiry Recieved listing")
                  history.push('/dashboard/contract-recieved/new', { showPostedRecievedTabs: false, showTabs: true })
                }}
                imgStyle={{
                  marginLeft: -35
                }}
              />
            
          </div>
          <div className="primaryLoadButtonWrap">
            <div className="insidePrimaryLoadButtonWrap">
              
                <PrimaryButton
                  onClick={() => {
                    if (UserData.roles.length && UserData.roles[0].name !== "Limited User") {
                      triggerMixpanelEvent("Post a Load start", "Click on Post a Load button to make a new load")
                      dispatch(ProfileAction.postLoadDataRemove());
                      dispatch(CounterAtion.resetPostloadCounterData())
                      dispatch(ProfileAction.JobLoadMeta(callBack));
                    }
                  }}
                  title="Post a load"
                />
              
            </div>
          </div>
        </div>
        <ButtomTabs activeTab="home" />
      </div>
    </div>
  );
};
export default Page;
