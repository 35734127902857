import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IWellcomePageProps } from "./types";
import { Images } from "@temp/assets";
import { PrimaryButton, OutLineButton, SignupContainer } from "@components";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { AuthAction } from "@temp/store/actions";
import { getItem, logOut } from "@temp/utils/helper";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import disableBrowserBackButton from "disable-browser-back-navigation";
import { Mixpanel } from "@temp/utils/mixpanel";
// import { requestFirebaseNotificationPermission } from "../../../utils/firebaseNew";
// import { requestFirebaseNotificationPermission } from "@temp/utils/firebase"; 


const Page: React.FC<IWellcomePageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const native_token = useSelector((state: RootState) => state.Auth.native_token);


  useEffect(()=>{
    const onDeviceReady=(data)=>{
      
    }
    window.addEventListener("deviceready", onDeviceReady, false);
  },[])

  const handleGetToken = async () => {
    
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
    

        
        // eslint-disable-next-line no-console

        // dispatch(
        //   ProfileAction.updateFCM({
        //     fcm_token: firebaseToken,
        //     device_id: deviceId(),
        //   })
        // );
      })
      .catch((err) => {
        
        return err;
      });
  };

  useEffect(() => {
    
    const currentToken = getItem("token");
    const isTermsAccepted = getItem("toc_accepted");
    const changed_password = getItem("changed_password");
    logOut();
    setTimeout(() => {
      if (currentToken && isTermsAccepted && changed_password) {
        history.replace("dashboard");
      }
      dispatch(AuthAction.getMetaData());
    }, 300);
    disableBrowserBackButton();
  }, []);
  return (
    <div className="wrapper">
      <div style={{
        backgroundColor:'white'
      }} className="welcome-header-container">
        {/* <p style={{ color: Colors.NavyBlue }} className="welcome-text">
          WELCOME TO
        </p> */}
        <div className="logo-container">
          <img alt="" src={Images.newWelcomImg} />
        </div>

        {/* <button onClick={handleGetToken}>clic me</button> */}

        <p className="img-style">
        Transforming Road Freight
        </p>
      </div>
      <div style={{
        backgroundColor:'white'
      }} className="welcome-content-container">
        <SignupContainer containerHeight="30vh">
          <div className="button-divider">
            <PrimaryButton
             buttonStyle={{
                width:330
              }}
              title="SIGN IN"
              onClick={() => {
                history.push("/login", { from: "login" });
              }}
            />
          </div>
          <div className="button-divider">
            <OutLineButton
              buttonStyle={{
                width:330
              }}
              title="CREATE ACCOUNT"
              onClick={() => {
                history.push("/signup", { from: "signup" });
                Mixpanel.track("registration start", {
                  name: "registration flow start",
                  des: 'registration flow start'
                })
              }}
            />
          </div>
          {/* <div className="demo-button">
            <img alt="" className="play-icon" src={Images.Play} />
            <div
              onClick={() => {
                history.push("/promo");
              }}
              style={{
                cursor: "pointer",
                marginLeft: 8,
              }}
            >
              <p style={{ color: Colors.NavyBlue }} className="view-demo-text">
                View Demo
              </p>
            </div>
          </div> */}
          
        </SignupContainer>
      </div>
    </div>
  );
};

export default Page;
