import React, { useState, useEffect, useRef } from "react";
import { INewDashboardPageProps } from "./types";
import "./style.scss";
import rootReducer from "../../../store/reducers";
import { Colors, Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { CloudDownloadOutlined } from "@mui/icons-material";
import { OutLineButton, PrimaryButton, MaterialUIPickers, CustomModal, CustomText } from "@components";
import { useDispatch, useSelector } from "react-redux";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer,
  Legend, LabelList, Text, Tooltip
} from 'recharts';
import { parse } from 'date-fns';
import { ProfileAction } from "@temp/store/actions";
import moment from "moment";
import { SVGProps } from 'react';

import { Grid, MenuItem, Select } from "@mui/material/";
import { showMessage } from "@temp/utils/helper";
import InfoIcon from "@mui/icons-material/Info";

export type RootState = ReturnType<typeof rootReducer>;
var numeral = require("numeral");


const Page: React.FC<INewDashboardPageProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const activeCategory = useSelector((state: RootState) => state.Profile?.activeCategoryReports);
  const activeDateFilter = useSelector((state: RootState) => state.Profile?.activeDateFilter);
  const fromResponseDate = useSelector((state: RootState) => state.Profile?.fromResponseDate);
  const responseDate = useSelector((state: RootState) => state.Profile?.responseDate);
  const dashboardData = useSelector((state: RootState) => state.Profile?.dashboardData);
  const chart_data = useSelector((state: RootState) => state.Profile?.chart_data);
  const [showModal, setShowModal] = useState(false);

  const new_dashboard_report = useSelector((state: RootState) => state.Profile?.new_dashboard_report);

  

  const cb = () => {
    history.push("/dashboard-vehicle-details");
  };

  // 
  
  
  
  
  


  const formattedData = chart_data?.map((entry) =>
  ({
    ...entry,
    date: entry?.date,

  }))

  const sortedData = formattedData
  // ?.slice()?.sort((a, b) => new Date(a?.date) - new Date(b?.date));

  
  const CustomXAxisTick = ({ x, y, payload }) => {
    const { value, index } = payload;
    const entry = sortedData[index];

    return (
      <g transform={`translate(${x + 32},${y})`}>
        {entry.posted && (
          <text x={0} y={0} dy={16} textAnchor="end" className={`recharts-cartesian-axis-tick label-posted`} transform={`rotate(-90)`}>
            <tspan x={5} dy={5}>
              {activeCategory == "posted" ? "Posted" : "Received"}
              {/* Posted */}
            </tspan>
          </text>
        )}
        {entry.awarded && (
          <text x={0} y={0} dy={16} textAnchor="end" className={`recharts-cartesian-axis-tick label-awarded`} transform={`rotate(-90)`}>
            <tspan x={5} dy={54}>
              Awarded
            </tspan>
          </text>
        )}
        {(entry.posted || entry.awarded) && (
          <text x={0} y={0} dy={16} textAnchor="end" className={`recharts-cartesian-axis-tick date-label`}>
            <tspan x={20} y={36} dy={0} dx={15}>
              {moment(value).format('DD-MMM')}
            </tspan>
          </text>
        )}
      </g>
    );
  };

  // for minimize six months date
  let minDate = moment(Number(moment(responseDate).format("x")) - 180 * 24 * 3600 * 1000).format('YYYY-MM-DD');

  // let minDate = moment(responseDate - 180 * 24 * 3600 * 1000).format('YYYY-MM-DD')
  // 

  // let diff = moment(fromResponseDate).diff(moment(responseDate).format('YYYY-MM-DD'))
  // 

  useEffect(() => {
    let date_from = moment(Date.now() - 0 * 24 * 3600 * 1000).format('YYYY-MM-DD')
    let date_to = moment().format("YYYY-MM-DD")
    if (activeDateFilter == "weekly") {
      date_from = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    } else if (activeDateFilter == "monthly") {
      date_from = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    } else if (activeDateFilter == "today") {
      date_from = moment(Date.now()).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    }
    else {
      date_from = moment(fromResponseDate).format('YYYY-MM-DD')
      date_to = moment(responseDate).format('YYYY-MM-DD')
    }
    dispatch(ProfileAction.dashboardDataReportNew({
      loads_by: activeCategory,
      date_range_from: date_from,
      date_range_to: date_to,
      date_type: activeDateFilter,
      stats: "dashboard",
      search: "",
      vehicle_type: "",
      next_page: 1
    }))
  }, []);

  const callBack = (param) => {
    
    if (param == "422") {
      setShowModal(true)
    }
  }

  const handleSubmit = () => {
    // if (fromResponseDate && responseDate) {
    //   const sixMonthsLater = moment(fromResponseDate).add(6, 'months');

    //   if (moment(responseDate).isSameOrBefore(fromResponseDate) || moment(responseDate).isBefore(sixMonthsLater)) {
    //     setError('End date must be greater than start date and at least 6 months apart.');
    //   } else {
    //     setError('');
    //   }
    // }
    dispatch(ProfileAction.dashboardDataReportNew({
      loads_by: activeCategory,
      date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
      date_range_to: moment(responseDate).format("YYYY-MM-DD"),
      date_type: activeDateFilter,
      stats: "dashboard",
      search: "",
      vehicle_type: "",
      next_page: 1,
      callBack
    }))
  }

  const handleClickCategory = (type: string) => {
    dispatch(ProfileAction.setReportsFilter({
      activeCategoryReports: type,
      activeDateFilter,
      fromResponseDate,
      responseDate,
    }))
    if (activeDateFilter == 'weekly') {
      let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateFilter,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    } else if (activeDateFilter == 'monthly') {
      let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateFilter,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    } else if (activeDateFilter == "today") {
      let start_date = moment(Date.now()).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateFilter,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))

    }
    else if (activeDateFilter == 'date') {
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: type,
        date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
        date_range_to: moment(responseDate).format("YYYY-MM-DD"),
        date_type: activeDateFilter,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1

      }))
    }
  };

  const handleDateFilter = (type) => {
    dispatch(ProfileAction.setReportsFilter({
      activeCategoryReports: activeCategory,
      activeDateFilter: type,
      fromResponseDate,
      responseDate,
    }))
    if (type == 'weekly') {
      let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: activeCategory,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    } else if (type == 'monthly') {
      let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: activeCategory,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    }
    else if (type == 'today') {
      let start_date = moment(Date.now()).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: activeCategory,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
      
    }
    else if (type == 'date') {
      
      dispatch(ProfileAction.dashboardDataReportNew({
        loads_by: activeCategory,
        date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
        date_range_to: moment(responseDate).format("YYYY-MM-DD"),
        date_type: type,
        stats: "dashboard",
        search: "",
        vehicle_type: "",
        next_page: 1

      }))
      

    }
  }

  return (
    <div style={{ height: 'calc(100vh - 15px)', overflow: 'auto' }} className="root-center-container">
      <div className="loads-top-wrappers">
        <div className="topDropDownWrap">
          <div
            className="drop-down"
            style={{ justifyContent: "space-between" }}
          >
            <button onClick={() => {
              history.goBack()
            }} className="header-buttons">
              <img
                src={Images.BackArrow}
                alt="Back"
              />
            </button>
            <p className="title">Dashboard</p>
            <div>
              <CloudDownloadOutlined style={{ cursor: "pointer" }}
                onClick={() => {
                  if (activeDateFilter == 'weekly') {
                    let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
                    let end_date = moment().format('YYYY-MM-DD');
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: start_date,
                      date_range_to: end_date,
                      date_type: activeDateFilter,
                      report: "dashboard",
                      search: "",
                      vehicle_type: ""
                    }))
                  } else if (activeDateFilter == 'monthly') {
                    let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
                    let end_date = moment().format('YYYY-MM-DD');
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: start_date,
                      date_range_to: end_date,
                      date_type: activeDateFilter,
                      report: "dashboard",
                      search: "",
                      vehicle_type: ""
                    }))
                  } else if (activeDateFilter == 'date') {
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
                      date_range_to: moment(responseDate).format("YYYY-MM-DD"),
                      date_type: activeDateFilter,
                      report: "dashboard",
                      search: "",
                      vehicle_type: ""
                    }))
                  } else if (activeDateFilter == 'today') {
                    let start_date = moment(Date.now()).format('YYYY-MM-DD')
                    let end_date = moment().format('YYYY-MM-DD');
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: start_date,
                      date_range_to: end_date,
                      date_type: activeDateFilter,
                      report: "dashboard",
                      search: "",
                      vehicle_type: ""
                    }))
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="tabContainerMainWrap reportLoadListing">
          {/* Top Tabs Posted recieved  */}
          {
            <FadeInComponent>
              <div className={`tab-btn radioTabs twoTab`}>
                <div
                  onClick={() => {
                    handleClickCategory("posted");
                  }}
                  className={`two-category-button ${activeCategory === "posted" ? "active" : ""
                    }`}
                >
                  Posted
                </div>
                <div
                  onClick={() => {
                    handleClickCategory("received");
                  }}
                  className={`two-category-button ${activeCategory === "received" ? "active" : ""
                    }`}
                >
                  Received
                </div>
                <div className="radioTab_slider"></div>
              </div>
            </FadeInComponent>
          }

          {/* Top Tabs date filters  */}
          <div className="tabContainerMainWrap reportLoadListing" style={{ display: 'none' }}>
            {
              <FadeInComponent className='pr-2'>
                <div className={`tab-btn radioTabs twoTab`}>
                  <div
                    onClick={() => {
                      handleDateFilter("today");
                    }}
                    className={`two-category-button ${activeDateFilter === "today" ? "active" : ""
                      }`}
                  >
                    Today
                  </div>
                  <div
                    onClick={() => {
                      handleDateFilter("weekly");
                    }}
                    className={`two-category-button ${activeDateFilter === "weekly" ? "active" : ""
                      }`}
                  >
                    Weekly
                  </div>
                  <div
                    onClick={() => {
                      handleDateFilter("monthly");
                    }}
                    className={`two-category-button ${activeDateFilter === "monthly" ? "active" : ""
                      }`}
                  >
                    Monthly
                  </div>

                  <div className="radioTab_slider"></div>
                </div>
              </FadeInComponent>
            }
          </div>
          <div className="newDashboarSelectDate">
            <div className="tabContainerMainWrap reportLoadListing">
              <Select
                id="outlined-select-date-native"
                select
                defaultValue={activeDateFilter}
                name=""
                onChange={(e) => handleDateFilter(e.target.value)}
              >
                <MenuItem value={'today'}>
                  Today
                </MenuItem>
                <MenuItem value={'weekly'}>
                  Weekly
                </MenuItem>
                <MenuItem value={'monthly'}>
                  Monthly
                </MenuItem>
                <MenuItem value={'date'}>
                  Date Filter
                </MenuItem>
              </Select>
              {
                activeDateFilter == "date" ?
                  <FadeInComponent className="dateLabel">
                    <div className="resports-date-filter-wraper">
                      <div onClick={() => {
                        setShowModal(true)

                      }} style={{ border: `1px solid ${Colors.NavyBlue}` }} className="reports-date-filter">
                        <span className="icons-calender calenderIcon"></span>
                        <p style={{ color: Colors.NavyBlue, marginTop: 4 }}>DATE FILTER</p>
                      </div>
                      <div>
                        {
                          fromResponseDate && responseDate ?
                            <p className="label">{`${moment(fromResponseDate).format('ll')} - ${moment(responseDate).format('ll')}`}</p> : null
                        }
                      </div>
                    </div>
                  </FadeInComponent>
                  : null}
            </div>
          </div>
        </div>
      </div>
      <div className="load-listing-container remCustomPadonMob tabContainerMainWrap reportLoadListing" style={{
        paddingTop: activeDateFilter == "date" ? 222 : 170
      }}>
        <Grid container className="graphWrapper newDashboardWrapper">
          {
            sortedData?.length > 0 ?
            <>
              <div className={activeDateFilter === "today" ? "todayDataWrap" : ""}>
                <ul className="recharts-default-legend" style={{padding: "0px", margin: "0px", textAlign: "center"}}>
                  <li className="recharts-legend-item legend-item-1" style={{display: "inline-block", marginRight: "10px"}}>
                    <span className="recharts-surface-circle" style={{background: "#689ad0"}}></span>
                    <span className="recharts-legend-item-text" style={{color: "#689ad0"}}>
                      {activeCategory === "posted" ? "Posted" : "Received"} - Spot
                    </span>
                  </li>
                  <li className="recharts-legend-item legend-item-0" style={{display: "inline-block", marginRight: "10px"}}>
                    <span className="recharts-surface-circle" style={{background: "#0d5296"}}></span>
                    <span className="recharts-legend-item-text" style={{color: "#0d5296"}}>
                      {activeCategory === "posted" ? "Posted" : "Received"} - Load (Against Contract)
                    </span>
                  </li>
                  <li className="recharts-legend-item legend-item-3" style={{display: "inline-block", marginRight: "10px"}}>
                    <span className="recharts-surface-circle" style={{background: "#7ac251"}}></span>
                    <span className="recharts-legend-item-text" style={{color: "#7ac251"}}>Awarded - Spot</span>
                  </li>
                  <li className="recharts-legend-item legend-item-2" style={{display: "inline-block", marginRight: "10px"}}>
                    <span className="recharts-surface-circle" style={{background: "#4b9c1c"}}></span>
                    <span className="recharts-legend-item-text" style={{color: "#4b9c1c"}}>Awarded - Load (Against Contract)</span>
                  </li>
                </ul>
              </div>
              <div id="bardiv">
                <div style={{ paddingTop: '0px' }}>
                
                  <div className={activeDateFilter === "today" ? "todayDataWrap" : ""} style={{ width: activeDateFilter == "today" ? "100%" : "600px" }}>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={sortedData} barSize={40}>
                          {/* <Legend
                            payload={[
                              {
                                value: `${
                                  activeCategory === "posted" ? "Posted" : "Received"
                                } - Load (Against Contract)`,
                                type: "rect",
                                id: "spot-contract",
                                color: "#0d5296",
                              },
                              // { value: 'Posted - Load (Against Contract)', type: 'rect', id: 'spot-contract', color: '#0d5296' },
                              // { value: 'Posted - Spot', type: 'rect', id: 'spot-spot', color: '#4b9c1c' },
                              {
                                value: `${
                                  activeCategory === "posted" ? "Posted" : "Received"
                                } - Spot`,
                                type: "rect",
                                id: "spot-spot",
                                color: "#4b9c1c",
                              },
                              { value: 'Awarded - Load (Against Contract)', type: 'rect', id: 'contract-contract', color: '#7ac251' },
                              
                              { value: 'Awarded - Spot', type: 'rect', id: 'contract-spot', color: '#689ad0' },
                            ]}
                          /> */}
                        <CartesianGrid />
                        {/* <XAxis
                          dataKey="date"
                          axisLine={false}
                          tickLine={false}
                          interval={0}
                          // height={60}
                          // dy={30}
                          scale="band"
                          tick={<CustomXAxisTick />}
                        />
                        <YAxis /> */}
                        <XAxis
                          dataKey="date"
                          tickFormatter={
                            // (tick) => new Date(tick).toLocaleDateString()
                            sortedData?.map((item) => {
                              item?.date
                            })
                          }
                        />
                        <YAxis />
                        {/* <Legend /> */}
                        {Object?.keys(sortedData[0])?.slice(1).map((recordType, index) => {
                          
                          // Filter to include only "Posted" and "Awarded"
                          if (recordType === "Posted" || recordType === "Awarded") {
                            return (
                              <React.Fragment key={recordType}>
                                <Bar
                                  dataKey={`${recordType}.Contract`}
                                  stackId={recordType}
                                  fill={index === 0 ? "#0d5296" : "#4b9c1c"}
                                  isAnimationActive={false}  // Disable animation on hover
                                >
                                  <LabelList
                                    dataKey={`${recordType}.Contract`}
                                    position="center"
                                    // formatter={(value) => `${value}`}
                                    formatter={(value) => value > 0 ? `${value}` : ''}
                                  />
                                </Bar>
                                <Bar
                                  dataKey={`${recordType}.Spot`}
                                  stackId={recordType}
                                  fill={index === 0 ? "#689ad0" : "#7ac251"}
                                  isAnimationActive={false}  // Disable animation on hover
                                >
                                  <LabelList
                                    dataKey={`${recordType}.Spot`}
                                    position="center"
                                    // formatter={(value) => `${value}`}
                                    formatter={(value) => value > 0 ? `${value}` : ''}
                                  />
                                </Bar>
                              </React.Fragment>
                            );
                          }
                          return null;
                        })}
                        <Tooltip
                          content={({ label, payload }) => {
                            
                            
                            if (payload && payload.length > 0) {
                              // const date = new Date(label).toLocaleDateString();
                              const tooltipContent = payload.reduce((acc, entry) => {
                                
                                

                                const [type, attribute] = (entry.dataKey as string).split('.');
                                
                                // const formattedAttribute = (type === 'Posted' || type === 'Awarded') ? `${attribute}:` : attribute;
                                const formattedAttribute = (attribute === 'Contract') ? `Load (Against Contract):` : attribute;

                                if (!acc[type]) {
                                  acc[type] = [];
                                }
                                acc[type].push(`${formattedAttribute} ${entry.value}`);
                                return acc;
                              }, {});
                              

                              return (
                                <div className="custom-tooltip" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)' }}>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}>
                                    <CustomText.OpenSansBold>
                                      Date:
                                    </CustomText.OpenSansBold>
                                    <CustomText.OpenSansRegular textStyle={{
                                      marginTop: 2,
                                      marginLeft: 2
                                    }}>
                                      <span className="customFontSize">{`${label}`}</span>
                                    </CustomText.OpenSansRegular>
                                  </div>
                                  {Object.entries(tooltipContent).map(([type, values]) => (
                                    
                                    // 
                                    <p key={type}>
                                      <strong>
                                      {activeCategory === 'received' && type === 'Posted' ? 'Received' : type}:
                                      </strong>{" "} 
                                      <span className="customFontSize">{values.join(', ')}</span>
                                    </p>
                                  ))}
                                </div>
                              );
                            }
                            return null;
                          }}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </>
              : "No data found"
            }
          {/* <div className="noDataFound">
            <p>No Record Found</p>
          </div> */}
          {/*grey box data Start*/}
          <div className="greyBoxWithData">
            <div className="insideGreyBox">
              <div className="smallGreyData">
                <label>Load</label>
                <p>{
                  activeCategory === "posted" ? "Posted" : "Received"}
                </p>
                <p>Awarded</p>
              </div>

              <div className="smallGreyData">
                <label>Spot</label>
                <p>{new_dashboard_report?.PostedAwardedCounts?.posted_spot ?
                  new_dashboard_report?.PostedAwardedCounts?.posted_spot : 0}</p>

                <p>{new_dashboard_report?.PostedAwardedCounts?.awarded_spot ?
                  new_dashboard_report?.PostedAwardedCounts?.awarded_spot : 0
                }</p>
              </div>

              <div className="smallGreyData customSpotGreyData">
                <label><span className="insideLabel">Load</span> <span className="insideLabelText">(Aganist Contract)</span></label>
                <p>{new_dashboard_report?.PostedAwardedCounts?.posted_contract ?
                  new_dashboard_report?.PostedAwardedCounts?.posted_contract : 0
                }</p>

                <p>{new_dashboard_report?.PostedAwardedCounts?.awarded_contract ?
                  new_dashboard_report?.PostedAwardedCounts?.awarded_contract : 0
                }</p>
              </div>

              <div className="smallGreyData">
                <label>Total</label>
                <p>
                  {new_dashboard_report?.PostedAwardedCounts?.posted_total}
                </p>
                <p>{new_dashboard_report?.PostedAwardedCounts?.awarded_total}</p>
              </div>

            </div>
          </div>
          {/*grey box data end*/}

          {/*data with Stats Wrap Start*/}
          <div className="dataWithStatsWrap greyDataWithStatsWrap">
            <h2 className="statsHeading">Containerized</h2>
            <div className="insideDataStats">
              <div className="insideDataBox">
                <label>Load</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.containerized?.loads ?
                  new_dashboard_report?.LoadTypeCounts?.containerized?.loads : 0
                }</p>
              </div>
              <div className="insideDataBox">
                <label>Awarded</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.containerized?.awarded ?
                  new_dashboard_report?.LoadTypeCounts?.containerized?.awarded : 0

                }</p>
              </div>
              <div className="insideDataBox">
                <label>Weight <span className="smallTonText">(Ton)</span></label>
                <p>{numeral(new_dashboard_report?.LoadTypeCounts?.containerized?.weight_awarded).format("0.000")}</p>
              </div>
              <div className="insideDataBox">
                <label>Vehicles</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.containerized?.vehicles ?
                  new_dashboard_report?.LoadTypeCounts?.containerized?.vehicles : 0

                }</p>
              </div>
              <div className="insideDataBox">
                <label>Containers</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.containerized?.container ?
                  new_dashboard_report?.LoadTypeCounts?.containerized?.container : 0

                }</p>
              </div>
              <div className="insideDataBox">
                <label>Amount <span className="smallPKRText">(PKR)</span></label>
                <p>{numeral(new_dashboard_report?.LoadTypeCounts?.containerized?.amount).format("0,0")}</p>
              </div>
            </div>
          </div>
          <div className="dataWithStatsWrap greyDataWithStatsWrap">
            <h2 className="statsHeading">Non - Containerized</h2>
            <div className="insideDataStats">
              <div className="insideDataBox">
                <label>Load</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.non_containerized?.loads ?
                  new_dashboard_report?.LoadTypeCounts?.non_containerized?.loads : 0

                }</p>
              </div>

              <div className="insideDataBox">
                <label>Awarded</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.non_containerized?.awarded ?
                  new_dashboard_report?.LoadTypeCounts?.non_containerized?.awarded : 0

                }</p>
              </div>

              <div className="insideDataBox">
                <label>Vehicles</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.non_containerized?.vehicles ?
                  new_dashboard_report?.LoadTypeCounts?.non_containerized?.vehicles : 0
                }</p>
              </div>

              <div className="insideDataBox">
                <label>Weight <span className="smallTonText">(Ton)</span></label>
                <p>{`${numeral(new_dashboard_report?.LoadTypeCounts?.non_containerized?.weight_awarded).format("0.000")} `}</p>
              </div>

              <div className="insideDataBox">
                <label>Items</label>
                <p>{new_dashboard_report?.LoadTypeCounts?.non_containerized?.item ?
                  new_dashboard_report?.LoadTypeCounts?.non_containerized?.item : 0
                }</p>
              </div>

              <div className="insideDataBox">
                <label>Amount <span className="smallPKRText">(PKR)</span></label>
                <p>{numeral(new_dashboard_report?.LoadTypeCounts?.non_containerized?.amount).format("0,0")}</p>
              </div>
            </div>
          </div>
          {/*data with Stats Wrap End*/}

          {/*blue box data start */}
          <div className="error-container info customInfoText dashboardCustomInfoText">
            <div>
              <InfoIcon className="error-icon" />
            </div>
            <div className="error-text">
              {
                activeCategory == "posted" ? " Filters not applied on Open, Awarded & Active Tabs"
                  : "Filters not applied on New, Open, Awarded & Active Tabs"
              }
            </div>
          </div>
          <div className="blueBoxDataBoxesWrap">
            {
              activeCategory == "received" ?
                <div className="blueBox" style={{
                  cursor: "pointer"
                }}
                  onClick={() => {
                    history.push('/dashboard/spot-recieved/new', { showTabs: true })
                  }}
                >
                  <label>New</label>
                  <span>{new_dashboard_report?.tabCounts?.new ? new_dashboard_report?.tabCounts?.new : 0}</span>
                </div>
                : null}

            <div className="blueBox" style={{
              cursor: "pointer"
            }}
              onClick={() => {
                activeCategory == "posted" ?
                  history.push('/dashboard/spot-posted/open', { showTabs: true }) :
                  history.push('/dashboard/spot-recieved/open', { showTabs: true })
              }}
            >
              <label
              >Open</label>
              <span>{new_dashboard_report?.tabCounts?.open ? new_dashboard_report?.tabCounts?.open : 0}</span>
            </div>

            <div className="blueBox"
              style={{
                cursor: "pointer"
              }}
              onClick={() => {
                activeCategory == "posted" ?
                  history.push('/dashboard/spot-posted/awarded', { showTabs: true }) :
                  history.push('/dashboard/spot-recieved/awarded', { showTabs: true })
              }}
            >
              <label>Awarded</label>
              <span>{new_dashboard_report?.tabCounts?.awarded ? new_dashboard_report?.tabCounts?.awarded : 0}</span>
            </div>

            <div className="blueBox" style={{
              cursor: "pointer"
            }}
              onClick={() => {
                dispatch(ProfileAction.updateActiveFilters({
                  loadType: activeCategory == "posted" ? "Loads Posted" : "Loads Received",
                }))
                history?.push("/active-loads")
              }}
            >
              <label>Active</label>
              <span>{new_dashboard_report?.tabCounts?.active ? new_dashboard_report?.tabCounts?.active : 0}</span>
            </div>

          </div>
          {/*blue box data end */}
          <div className="bottomButtonsWrap">
            <PrimaryButton
              title={"Vehicles Details"}
              onClick={() => {
                dispatch(ProfileAction.setVehicleFilter({
                  activeCategoryVehicle: "posted",
                  activeDateVehicle: 'today',
                  fromResponseDateVehicle: new Date(),
                  responseDateVehicle: new Date(),
                }))
                dispatch(ProfileAction.JobLoadMeta(cb));
              }}

            />
            <PrimaryButton
              title={"Loads Details"}
              onClick={() => {
                dispatch(ProfileAction.setDetailFilter({
                  activeCategoryDetail: "posted",
                  activeDateDetail: 'today',
                  fromResponseDateDetail: new Date(),
                  responseDateDetail: new Date(),
                }))
                setTimeout(() => {
                  history.push("/dashboard-load-details");
                }, 300)
              }}

            />
          </div>
        </Grid>
      </div>
      <CustomModal showModal={showModal} toggleModal={setShowModal}>
        <div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            // justifyContent: 'center',
            marginTop: 0
          }}>
            <div className="error-container info customInfoText dashboardCustomModalInfoText">
              <div>
                <InfoIcon className="error-icon" />
              </div>
              <div className="error-text">
                You can select upto 6 months data
              </div>
            </div>
            <span
              style={{
                right: 15,
                top: 30
              }}
              className="icons-cross directionRight"
              onClick={() => setShowModal(false)}
            ></span>
          </div>
          <div style={{ marginBottom: 10, marginTop: 15 }} className="inputField">
            <div className="label">From</div>
            <div className="DatePickerFieldWrap">
              <MaterialUIPickers
                date={fromResponseDate}
                setDate={(e) => {
                  if (e > responseDate) {
                    
                    showMessage.current({
                      message:
                        "From date should be less than To date.",
                      status: 'error'
                    })
                  }
                  dispatch(ProfileAction.setReportsFilter({
                    activeCategoryReports: activeCategory,
                    activeDateFilter: activeDateFilter,
                    fromResponseDate: e,
                    responseDate,
                  }))
                }}
                minDate={"1900/01/01"}
                // minDate={minDate}
                maxDate={new Date()}
              // from={true}
              />
            </div>
          </div>
          <div style={{ marginBottom: 10, pointerEvents: fromResponseDate ? "auto" : 'none' }} className="inputField">
            <div className="label">To</div>
            <div className="DatePickerFieldWrap">
              <MaterialUIPickers
                date={responseDate}
                setDate={(e) => {
                  if (fromResponseDate > e) {
                    
                    showMessage.current({
                      message:
                        "From date should be less than To date.",
                      status: 'error'
                    })
                  }
                  dispatch(ProfileAction.setReportsFilter({
                    activeCategoryReports: activeCategory,
                    activeDateFilter: activeDateFilter,
                    fromResponseDate: fromResponseDate,
                    responseDate: e,
                  }))
                }}
                minDate={"1900/01/01"}
                maxDate={new Date()}
              // from={true}
              />
            </div>
          </div>

          <OutLineButton
            classProp='tinyBtn'
            title="Apply"
            onClick={() => {
              handleSubmit()
              setShowModal(false)
            }}
            buttonStyle={{
              height: 36,
              width: '100%'
            }}
            disabled={fromResponseDate > responseDate ? true : false}
          />
        </div>
      </CustomModal>
    </div>
  );

};
export default Page;
