import { CustomText } from "@temp/components";
import React from "react";
import "../LoadDetailPoster/style.scss";
import { Images, Colors } from "@temp/assets";
import { useHistory } from "react-router-dom";
import rootReducer from "../../../store/reducers";
import { getFullTime } from "@temp/utils/helper";
import moment from "moment";
var numeral = require("numeral");
export type RootState = ReturnType<typeof rootReducer>;
export const AwardedVehiclesViewer = ({
    loadId,
    item,
    loadDetail,
    index
}) => {
  
    
    
    const history = useHistory();
    return (
        <div>
            <div
                className={`awarded-vehicle-list-recipient`}
            >
                <div>
                    <div
                        onClick={() => {
                            if (item?.status == 1) {
                                history.push(`/job-loads/${loadId}/active/${item?.id}`, { fromPoster: true, is_viewer:true });
                            }
                        }}
                        style={{
                            paddingTop: 5,
                            cursor: item?.status === 1 ? 'pointer' : 'default'
                        }}
                    >
                        <div className="flex_row_div">
                            <CustomText.OpenSansSemiBold textStyle={{ marginLeft: 5 }}>Details of Vehicle {index + 1}</CustomText.OpenSansSemiBold>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CustomText.OpenSansSemiBold
                                    textStyle={{
                                        fontSize: "0.688rem",
                                        color:
                                            item?.status == 0
                                                ? Colors.Orange
                                                : item?.status == 1
                                                    ? Colors.Primary
                                                    : Colors.Red,
                                    }}
                                >
                                    {item?.status == 0 && Number(moment(loadDetail?.deadline_at).format('x')) < getFullTime(new Date())
                                        ? "Awaiting response"
                                        : item?.status == 0 && Number(moment(loadDetail?.deadline_at).format('x')) > getFullTime(new Date()) ?
                                            "No Response"
                                            : item?.status == 1
                                                ? "View"
                                                : item?.status == 2
                                                    ? "Declined"
                                                    : "Completed"}
                                </CustomText.OpenSansSemiBold>
                                <img
                                    style={{
                                        height: 16,
                                        marginLeft: 5,
                                    }}
                                    src={Images.BlueRightArrow}
                                />
                            </div>
                        </div>
                        <div className="add-vehicle-refrence-number">
                            <CustomText.OpenSansRegular>
                                {item?.vehicle?.plate_number}
                            </CustomText.OpenSansRegular>
                        </div>
                        <>
                            {item?.container_ref_one ? (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <CustomText.OpenSansSemiBold
                                        textStyle={{
                                            color: "#0d5296",
                                            fontSize: "0.625rem",
                                            marginLeft: 5,
                                        }}
                                    >
                                        {`Container  Reference: ${item?.container_ref_one}`}
                                    </CustomText.OpenSansSemiBold>
                                </div>
                            ) : null}

                            {item?.no_of_packages && (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <CustomText.OpenSansSemiBold
                                        textStyle={{
                                            color: "#0d5296",
                                            fontSize: "0.625rem",
                                            marginLeft: 8,
                                        }}
                                    >
                                        {`No. of Packages: ${item?.no_of_packages}`}
                                    </CustomText.OpenSansSemiBold>
                                </div>
                            )}

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {item?.container_ref_two ? (
                                    <CustomText.OpenSansSemiBold
                                        textStyle={{
                                            color: "#0d5296",
                                            fontSize: "0.625rem",
                                            marginLeft: 8,
                                        }}
                                    >
                                        {`Container  Reference: ${item?.container_ref_two}`}
                                    </CustomText.OpenSansSemiBold>
                                ) : null}
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {item?.weight ? (
                                    <CustomText.OpenSansSemiBold
                                        textStyle={{
                                            color: "#0d5296",
                                            fontSize: "0.625rem",
                                            marginLeft: 8,
                                        }}
                                    >
                                        {`Weight: ${numeral(item?.weight).format("0,0.00")} Kg`}
                                    </CustomText.OpenSansSemiBold>
                                ) : null}
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};
