import React from "react";
import { CustomText, OutLineButton, PrimaryButton } from "@temp/components";
import { Button } from "@mui/material/";
import { Colors, Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { fileIconDisplay } from "@temp/utils/helper";
import { Delete } from "@mui/icons-material";
import { LoadAction } from "@temp/store/actions";
import { useDispatch } from "react-redux";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import './styles.scss'
export const UploadDocByRecipient = ({
  handleChangeFile,
  recipient_uploaded,
  set_recipient_uploaded,
  loadDetail
}) => {
  const history = useHistory();
  const dispatch = useDispatch()
  return (
    <div>
      <div style={{
        paddingTop: 10,
        marginBottom: 15,
      }}>
        <div className="uploadDocumentFileBoxWrap">
          <div className="documentWrapper">
            <div className="doc-title" style={{ display: "flex", marginBottom: 20 }}>
              <div className={`docTitle`}>Upload Document</div>
            </div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width:'100%',
              flexDirection:'column'
            }}>
              <label style={{
              }} htmlFor="upload-nic-front" id="upload-button-container" style={{ width: '100%' }}>
                <Button
                  variant="contained"
                  className="outline-button customUploadButton"
                  component="span"
                  style={{
                    height: 44,
                    width: '100%',
                    border: `1px solid ${Colors.Primary}`,
                    color: Colors.Primary,
                    flexDirection: 'row'
                  }}
                >
                  <span className="button-title" >Upload File</span>
                  <span className="seperator">/</span>
                  <span className="urduLabel">اپ لوڈ فائل</span>
                </Button>
              </label>
              <input
                className="hide-input"
                id="upload-nic-front"
                multiple
                type="file"
                onChange={handleChangeFile}
              />
            </div>
            {/* <div style={{
              paddingLeft:20,
              paddingRight:20
            }}>
              {
                recipient_uploaded?.map((file, index) => {
                  return (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop:10
                    }}>
                      <div
                        onClick={() => {
                          if (sendEventToAndroid("UploadedDocuments")) {
                            window.Android.UploadedDocuments(
                              file?.path
                            );
                          } else if (sendEventToiOS("UploadedDocuments")) {
                            window.webkit.messageHandlers.UploadedDocuments.postMessage(
                              JSON.stringify({
                                document: file?.path,
                              })
                            );
                          } else {
                            window.open(file?.path, "_blank");
                          }
                        }}
                        className="fileWrapper"
                      >
                        <img
                          className="fileTypeIcon"
                          src={fileIconDisplay(file?.mime_type)}
                        ></img>

                        <div className="fileName">{file?.file_name}</div>
                      </div>
                      <span
                        className="deleteIcon icons-cross"
                        onClick={() => {
                          let new_data = [...recipient_uploaded]
                          new_data.splice(index, 1)
                          set_recipient_uploaded(new_data)
                        }}
                      ></span>
                    </div>
                  )
                })
              }
            </div> */}
            {/* <div style={{ marginTop: 15 }}>
              <PrimaryButton
                title={"upload"}
                disabled={!recipient_uploaded.length}
                onClick={() => {
                  let payload = {
                    load_id: loadDetail?.id,
                    documents: recipient_uploaded
                  }
                  dispatch(LoadAction.uploadDocRecipient(payload))
                  set_recipient_uploaded([])
                }}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};