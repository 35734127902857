export default class Action {
  // Constants
  static GET_NETWORK = "GET_NETWORK";
  static GET_NETWORK_SUCCESS = "GET_NETWORK_SUCCESS";
  static GET_NETWORK_FAIL = "GET_NETWORK_FAIL";

  static GET_ACCOUNT_BALANCE = "GET_ACCOUNT_BALANCE";
  static GET_ACCOUNT_BALANCE_SUCCESS = "GET_ACCOUNT_BALANCE_SUCCESS";
  static GET_ACCOUNT_BALANCE_FAIL = "GET_ACCOUNT_BALANCE_FAIL";

  static GET_SAVED_LOCATIONS = "GET_SAVED_LOCATIONS";
  static GET_SAVED_LOCATIONS_SUCCESS = "GET_SAVED_LOCATIONS_SUCCESS";
  static GET_SAVED_LOCATIONS_FAIL = "GET_SAVED_LOCATIONS_FAIL";

  static GET_VEHICLE_SELECTOR = "GET_VEHICLE_SELECTOR";
  static GET_VEHICLE_SELECTOR_SUCCESS = "GET_VEHICLE_SELECTOR_SUCCESS";
  static GET_VEHICLE_SELECTOR_FAIL = "GET_VEHICLE_SELECTOR_FAIL";

  static GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
  static GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
  static GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

  static GET_LOAD_DRAFT = "GET_LOAD_DRAFT";
  static GET_LOAD_DRAFT_SUCCESS = "GET_LOAD_DRAFT_SUCCESS";
  static GET_LOAD_DRAFT_FAIL = "GET_LOAD_DRAFT_FAIL";

  static ADD_NETWORK = "ADD_NETWORK";
  static ADD_NETWORK_SUCCESS = "ADD_NETWORK_SUCCESS";
  static ADD_NETWORK_FAIL = "ADD_NETWORK_FAIL";

  static SEARCH_NETWORK = "SEARCH_NETWORK";
  static SEARCH_NETWORK_SUCCESS = "SEARCH_NETWORK_SUCCESS";
  static SEARCH_NETWORK_FAIL = "SEARCH_NETWORK_FAIL";

  static ADD_OTP_NETWORK = "ADD_OTP_NETWORK";
  static ADD_OTP_NETWORK_SUCCESS = "ADD_OTP_NETWORK_SUCCESS";
  static ADD_OTP_NETWORK_FAIL = "ADD_OTP_NETWORK_FAIL";

  static ADD_VEHICLE_SELECTOR = "ADD_VEHICLE_SELECTOR";
  static ADD_VEHICLE_SELECTOR_SUCCESS = "ADD_VEHICLE_SELECTOR_SUCCESS";
  static ADD_VEHICLE_SELECTOR_FAIL = "ADD_VEHICLE_SELECTOR_FAIL";

  static SEARCH_MY_NETWORK = "SEARCH_MY_NETWORK";

  static EDIT_VEHICLE_SELECTOR = "EDIT_VEHICLE_SELECTOR";
  static EDIT_VEHICLE_SELECTOR_SUCCESS = "EDIT_VEHICLE_SELECTOR_SUCCESS";
  static EDIT_VEHICLE_SELECTOR_FAIL = "EDIT_VEHICLE_SELECTOR_FAIL";

  static DELETE_VEHICLE_SELECTOR = "DELETE_VEHICLE_SELECTOR";
  static DELETE_VEHICLE_SELECTOR_SUCCESS = "DELETE_VEHICLE_SELECTOR_SUCCESS";
  static DELETE_VEHICLE_SELECTOR_FAIL = "DELETE_VEHICLE_SELECTOR_FAIL";

  static ADD_LOCATION = [
    "ADD_LOCATION",
    "ADD_LOCATION_SUCCESS",
    "ADD_LOCATION_FAIL",
  ];
  static DELETE_LOCATION = [
    "DELETE_LOCATION",
    "DELETE_LOCATION_SUCCESS",
    "DELETE_LOCATION_FAIL",
  ];

  
  static DELETE_NETWORK = "DELETE_NETWORK";
  static DELETE_NETWORK_SUCCESS = "DELETE_NETWORK_SUCCESS";
  static DELETE_NETWORK_FAIL = "DELETE_NETWORK_FAIL";

  static GET_FAQS = "GET_FAQS";
  static GET_PROMOTIONS = "GET_PROMOTIONS";
  static GET_PROMOTIONS_SUCCESS = "GET_PROMOTIONS_SUCESS";
  static GET_PROMOTIONS_FAIL = "GET_PROMOTIONS_FAIL";

  static GET_PROMOTION_DETAIL = "GET_PROMOTION_DETAIL";
  static GET_PROMOTION_DETAIL_SUCCESS = "GET_PROMOTION_DETAIL_SUCESS";
  static GET_PROMOTION_DETAIL_FAIL = "GET_PROMOTION_DETAIL_FAIL";

  static GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
  static GET_FAQS_FAIL = "GET_FAQS_FAIL";
  // Actions
  static deleteLocation(payload: any) {
    return {
      type: Action.DELETE_LOCATION[0],
      payload,
    };
  }


  static addLocation(payload: any) {
    return {
      type: Action.ADD_LOCATION[0],
      payload,
    };
  }

  static GetNetwork(payload) {
    return {
      type: Action.GET_NETWORK,
      payload
    };
  }

  static GetAccountBalance(payload) {
    return {
      type: Action.GET_ACCOUNT_BALANCE,
      payload,
    };
  }

  static GetSavedLocations() {
    return {
      type: Action.GET_SAVED_LOCATIONS,
    };
  }

  static GetVehicleSelector() {
    return {
      type: Action.GET_VEHICLE_SELECTOR,
    };
  }

  static GetNotifications(payload) {
    return {
      type: Action.GET_NOTIFICATIONS,
      payload,
    };
  }

  static GetLoadDraft() {
    return {
      type: Action.GET_LOAD_DRAFT,
    };
  }

  static AddNetwork(payload) {
    return {
      type: Action.ADD_NETWORK,
      payload,
    };
  }

  static SearchNetwork(payload) {
    return {
      type: Action.SEARCH_NETWORK,
      payload,
    };
  }

  static AddOtpNetwork(payload) {
    return {
      type: Action.ADD_OTP_NETWORK,
      payload,
    };
  }

  static AddVehicleSelector(payload) {
    return {
      type: Action.ADD_VEHICLE_SELECTOR,
      payload,
    };
  }

  static searchMyNetwork(payload) {
    return {
      type: Action.SEARCH_MY_NETWORK,
      payload,
    };
  }

  static EditVehicleSelector(payload) {
    return {
      type: Action.EDIT_VEHICLE_SELECTOR,
      payload,
    };
  }

  static DeleteVehicleSelector(payload) {
    return {
      type: Action.DELETE_VEHICLE_SELECTOR,
      payload,
    };
  }

  static DeleteNetwork(payload) {
    return {
      type: Action.DELETE_NETWORK,
      payload,
    };
  }

  
  static getFaqs(){
    return {
    type: Action.GET_FAQS,
  }; }

  static getPromotions(){
    return{
      type: Action.GET_PROMOTIONS,
    };
  }

  static getPromotionDetail(payload){
    return{
      type: Action.GET_PROMOTION_DETAIL, payload
    }
  }
}
