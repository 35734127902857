import React, { useState, useRef } from "react";
import { IReviewDetailPageProps } from "./types";
import { Colors, Images } from "@temp/assets";
import {
  PrimaryButton,
  SignupContainer,
  BackHeader,
  CustomText,
} from "@components";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { AuthAction } from "@temp/store/actions";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
const Page: React.FC<IReviewDetailPageProps> = (props) => {
  const fullNameRef = useRef<HTMLInputElement>(null);
  const aliasNameRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const signupData = useSelector((state: RootState) => state.Auth.signupData);
  const [enableFullname, setEnableFullname] = useState(true);
  const [enableAliasname, setEnableAliasname] = useState(true);
  const callback = (data: any) => {
    dispatch(
      AuthAction.SetSignupData({
        fullName: "",
        aliasName: "",
        frontImage: "",
        backImage: "",
        cnic_front: null,
        cnic_back: null,
      })
    );
    history.push("/success");
  };
  const handleSubmit = () => {
    dispatch(
      AuthAction.SignUp({
        ...signupData,
        phoneNumber: state?.phoneNumber ? state.phoneNumber : "",
        whatsapp:state?.whatsapp?state.whatsapp:'',
        cb: callback,
      })
    );
  };

  return (
    <div className="loginWrapper">
      <BackHeader
        title="Review Detail"
        onClick={() => {
          history.goBack();
        }}
      />
      <SignupContainer containerHeight="">
        <div className="insideafterOTPContainer reviewDetailWrapper">
          <div className="details-container">
            <div className="info-container">
              <label className="details-label">Phone Number</label>
              <div className="show-buttom-border">
                <CustomText.OpenSansRegular
                  textStyle={{
                    color: "rgb(204, 204, 192)",
                    marginTop: 10,
                    marginBottom: 10,
                    fontSize: "0.875rem",
                  }}
                >
                  +92{state?.phoneNumber}
                </CustomText.OpenSansRegular>
              </div>
            </div>
            <div className="info-container">
              <label className="details-label">Full Name</label>
              <div className="show-buttom-border">
                <input
                  ref={fullNameRef}
                  onChange={(e) => {
                    dispatch(
                      AuthAction.SetSignupData({
                        ...signupData,
                        fullName: e.target.value,
                      })
                    );
                  }}
                  type="text"
                  readOnly={enableFullname}
                  value={signupData.fullName}
                  className="details-value"
                />
                <button
                  onClick={() => {
                    setEnableFullname(false);
                    fullNameRef.current.focus();
                  }}
                  className="edit-button"
                >
                  <span className="icons-edit editIcon"></span> : null
                </button>
              </div>
            </div>
            <div className="info-container">
              <label className="details-label">Alias</label>
              <div className="show-buttom-border">
                <input
                  ref={aliasNameRef}
                  onChange={(e) => {
                    dispatch(
                      AuthAction.SetSignupData({
                        ...signupData,
                        aliasName: e.target.value,
                      })
                    );
                  }}
                  type="text"
                  readOnly={enableAliasname}
                  value={signupData.aliasName}
                  className="details-value"
                />
                <button
                  onClick={() => {
                    setEnableAliasname(false);
                    aliasNameRef.current.focus();
                  }}
                  className="edit-button"
                >
                  <span className="icons-edit editIcon"></span> : null
                </button>
              </div>
            </div>
            <div className="info-container">
              <div className="nic-label-container">
                <label className="details-label">CNIC</label>
                <div
                  onClick={() => history.goBack()}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <CustomText.OpenSansRegular
                    textStyle={{
                      color: Colors.NavyBlue,
                      fontSize: "0.875rem",
                    }}
                  >
                    Change
                  </CustomText.OpenSansRegular>
                </div>
              </div>
              {signupData?.cnic_front ? (
                <div className="nic-container">
                  <div>
                    <img
                      alt=""
                      className="nic-image"
                      src={signupData?.frontImage}
                    />
                    <p className="front-back">Front</p>
                  </div>
                  <div>
                    <img
                      alt=""
                      className="nic-image"
                      src={signupData?.backImage}
                    />
                    <p className="front-back">Back</p>
                  </div>
                </div>
              ) : (
                <div style={{ height: 170 }}></div>
              )}
            </div>
          </div>
          <div
            style={{
              bottom: "0px !important",
            }}
            className="bottomReviewScreen"
          >
            <div className="insideSignupBtnWrap">
              <PrimaryButton
                title="SUBMIT"
                disabled={
                  !signupData?.fullName ||
                  !signupData?.cnic_back ||
                  !signupData.cnic_front
                }
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </SignupContainer>
    </div>
  );
};

export default Page;
