import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Button,


} from "@mui/material/";
import { ExitToAppRounded, ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { LOAD_TYPE } from "@temp/constants";

var numeral = require("numeral");

export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 17px;
  border-radius: 10px;
`;
export interface LoadType {
  loadName: any;
  loadData: any;
  totalContainers: any;
  fromHistory,
  is_recipient :boolean,
}

export const LoadDetails: FC<LoadType> = ({ loadName, loadData, totalContainers, fromHistory, is_recipient, }: LoadType) => {
  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const [totalVehicle, setTotalVehicle] = useState(0);
  let totalVehicleCount = 0;
  useEffect(() => {
    if (PostLoad) {
      if (PostLoad?.vehicleScreen?.addedVehicles?.length > 0) {
        PostLoad?.vehicleScreen?.addedVehicles?.forEach(element => {
          if (element.vehicle_quantity) {
            totalVehicleCount += Number(element.vehicle_quantity)
          } else {
          }
        });
      }
      setTotalVehicle(totalVehicleCount)
    }
  }, [PostLoad])

  const FormRow = ({ label, value, sep, urdutitle, is_recipient, fromHistory }) => {
    return (
      <>
        <Grid item xs={6} className="customTextClass" style={{ marginBottom: '0.5rem' }}>
          <div className="grayLabel">
            <span className="titleName">{label}</span>
            
            {
              fromHistory && is_recipient &&
              
                <>
                  <span className="seperator">
                    {sep}
                  </span>
                  <span className="urduLabel lhEdit">
                    {urdutitle}
                  </span>
                </>
              
            }
          </div>
        </Grid>

       

        <Grid item xs={6} className="text-center customTextClassRight" style={{ marginBottom: '0.5rem' }}>
          <div className="text-right fw-5">
            {value}
          </div>
        </Grid>
      </>
    );
  };

  const [isExpanded, setIsExpanded] = useState(true);
  const handleChange = (check) => {
    setIsExpanded(check);
  };


  const load_type = postLoadMeta.load_types.find(
    (val: any) => val.id === PostLoad.load_type
  );

  const inquiry_type = postLoadMeta.inquiry_types.find(
    (val: any) => val.id === PostLoad.inquiry_type
  );

  return (
    <>
      <Accordion
        className="confirm-load-accordion"
        expanded={isExpanded}
        onChange={() => handleChange(!isExpanded)}
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className="fw-5 accHeading">
            <span>Load Details</span>
            {
             fromHistory && is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">لوڈ کی تفصیلات </span>
              </>
            }
          </p>
          {/* {editable ? (
          <div className="editBtnWrapper">
            <OutLineButton
                classProp='tinyBtn'
                dataColor="blue"
                title="Edit"
                icon={
                  <span className="icons-edit"></span>
                }
                onClick={onEdit}
              />
          </div>
          ) : null} */}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} style={{ overflowY: "scroll" }}>

            {loadName?.load_name ? (
              <FormRow
                label="Load Name"
                value={loadName.load_name}
                sep="/"
                urdutitle={"لوڈ کا نام"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
            ) : null}

            {inquiry_type?.title ? (
              <FormRow
                label="Inquiry Type"
                value={inquiry_type.title}
                sep="/"
                urdutitle="انکوائری کی قسم"
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
            ) : null}

            {load_type.title ? (
              <FormRow
                label="Load Type"
                value={load_type.title}
                urdutitle="لوڈ کی قسم"
                sep="/"
                is_recipient={is_recipient}
                fromHistory={fromHistory}

              />
            ) : null}

            {/* <FormRow 
            label="Quote Type" 
            value={loadData?.contractDetails?.quote_by == "weight_volume" ? "Weight Volume" : "Vehicle"} 
            /> */}

            {
              PostLoad?.load_type === 1
                && totalContainers ?
                <FormRow
                  label={"Total No. Of Containers "}
                  value={`${totalContainers}`}
                  sep="/"
                  urdutitle="کنٹینرز کی کل تعداد"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                /> : null
            }

            {
              // PostLoad?.load_type === 1 || PostLoad?.load_type === 3
              totalVehicle
                ?
                <FormRow
                  label={"Total No. Of Vehicles "}
                  value={totalVehicle}
                  sep="/"
                  urdutitle="گاڑیوں کی کل تعداد"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                /> : null
            }

            {loadData.ref_no ? (
              // <FormRow label="Client Reference No" value={loadData.ref_no} />
              <FormRow
                label="Reference Number"
                value={loadData.ref_no}
                sep="/"
                urdutitle="حوالہ نمبر"
                is_recipient={is_recipient}
              />

            ) : null}

            {loadData?.vehicleScreen?.values?.cargo_description || loadData?.cargo_description ? (
              <div className="cargoDescWrap">
                <FormRow
                  label="Cargo Description"
                  value={loadData?.vehicleScreen?.values?.cargo_description ?
                    loadData.vehicleScreen?.values?.cargo_description :
                    loadData?.cargo_description
                  }
                  sep="/"
                  urdutitle="کارگو کی تفصیل"
                  is_recipient={is_recipient}
                />
              </div>
            ) : null}

            {/* {loadData?.cargo_description ? (
              <div className="cargoDescWrap"><FormRow label="Cargo Description" value={loadData?.cargo_description} /></div>
            ) : null} */}

            {load_type.title !== LOAD_TYPE.fcl &&
              loadData?.vehicleScreen?.values?.quote_by !==
              "weight_volume" ? (
              <>

                {loadData?.vehicleScreen?.values?.gross_weight_in_kgs ? (
                  <FormRow
                    label="Gross Weight in KG"
                    value={
                      numeral(loadData?.vehicleScreen?.values?.gross_weight_in_kgs).format("0,0.00") + " KG"
                    }
                    sep="/"
                    urdutitle="مجموعی وزن (کلو)"
                    is_recipient={is_recipient}
                  />
                ) : null}

                {loadData?.vehicleScreen?.values?.gross_weight ? (
                  <FormRow
                    label="Gross Weight in Ton"
                    value={
                      numeral(loadData?.vehicleScreen?.values?.gross_weight).format("0,0.00") + " Ton"
                    }
                    sep="/"
                    urdutitle="مجموعی وزن (ٹن)"
                    is_recipient={is_recipient}
                  />
                ) : null}

                {loadData?.vehicleScreen?.values?.cargo_volume ? (
                  <FormRow
                    label="Cargo Volume"
                    value={
                      numeral(loadData?.vehicleScreen?.values?.cargo_volume).format("0,0.00") + " CBM"
                    }
                    urdutitle={"کارگو والیوم"}
                    sep="/"
                    is_recipient={is_recipient}
                  />
                ) : null}
                {loadData?.vehicleScreen?.values?.total_freight_ton &&
                  loadData?.vehicleScreen?.values?.quote_by !== "vehicle" ? (
                  <FormRow
                    label={"Total Weight"
                      // (loadData?.vehicleScreen?.values?.gross_weight && loadData?.vehicleScreen?.values?.cargo_volume) ? "F.Ton" : 'Ton'
                    }
                    value={loadData?.vehicleScreen?.values?.total_freight_ton
                      ? `${numeral(loadData?.vehicleScreen?.values?.total_freight_ton).format('0,0')} ${!loadData?.vehicleScreen?.values?.cargo_volume ? " Ton" : " F.Ton"}`
                      // + `${(loadData?.vehicleScreen?.values?.gross_weight && loadData?.vehicleScreen?.values?.cargo_volume)}`
                      : ""}
                      urdutitle={"کل وزن"}
                      sep="/"
                      is_recipient={is_recipient}
                  />
                ) : null}

                {loadData?.vehicleScreen?.values?.no_of_packages ? (
                  <FormRow
                    label="Number of Packages"
                    value={loadData?.vehicleScreen?.values?.no_of_packages}
                    urdutitle={"پیکیجز کی تعداد"}
                    sep="/"
                    is_recipient={is_recipient}

                  />
                ) : null}
              </>
            ) : null}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LoadDetails;
