import React, { useEffect, useState } from "react";
import { Button } from "@mui/material/";
import { IProps } from "./types";

export const VehicleIncDec: React.FC<IProps> = (props: IProps) => {
  const [state, setState] = useState(1);
  useEffect(() => {
    setState(props?.value);
  }, [props]);

  return (
    
    <div className="vehicleIncDec">
      <div className="">
        <div style={{ marginBottom: props?.name == "max_vehicle_weight" && props?.from_containerized ? 0 : '' }} className="label"> {props?.text}</div>
        {
          props?.name == "max_vehicle_weight" && props?.from_containerized ? (
            <div className="label">{`(Without Container)`}</div>
          ):null
        }
        <div className="incDecWrapper">
          <Button
            className="qtnBtn"
            onTouchStart={() => props?.onTouchStart("dec")}
            onTouchEnd={props?.onTouchEnd}
            onMouseUp={() => props?.onMouseUp("dec")}
            onMouseDown={props?.onMouseDown}
            disabled={props?.disableMin}
          >
            -
          </Button>

          <div className="field">
            {props?.withInputBox ? (
              <input
                value={state?state:""}
                maxLength={5}
                // min={1}
                onChange={(e) => {
                  let value = e.target?.value.replace(/[^\d]/g, "");
                  props?.updateValue(Number(value));
                  return
                  let reg = new RegExp("^[0-9]*$");
                  if (reg.test(value)) {
                    // setState(Number(value))
                  } else {
                    // e.target.value = null;
                    // setState(null);
                  }
                }}
                inputMode="numeric"
                pattern="[1-9][0-9]*"
              />
            ) : (
              <div>{state}</div>
            )}
          </div>

          <Button
            onTouchStart={() => props?.onTouchStart("inc")}
            onTouchEnd={props?.onTouchEnd}
            onMouseUp={props?.onMouseUp}
            onMouseDown={() => props?.onMouseDown("inc")}
            disabled={props?.disabledMax}
            className="qtnBtn"
          >
            +
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VehicleIncDec;
