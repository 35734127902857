import { CustomText } from "@temp/components";
import React from "react";
import "../LoadDetailPoster/style.scss";
import { RenderRTL } from "./RenderRTL";
import "../LoadDetailPoster/style.scss";

export const PickupAndDropOff = ({ data }) => {
  return (
    <div>
      {data?.pickup_contact_name ? (
        <div>
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <CustomText.OpenSansSemiBold
              textStyle={{
                fontSize: "0.875rem",
              }}
            >
              Contact at Pickup
            </CustomText.OpenSansSemiBold>
            <RenderRTL title={"Name"} val={data?.pickup_contact_name} />
            <RenderRTL title={"Phone Number"} val={`+92 ${data?.pickup_contact_phone_no}`} />
          </div>
          <div>
            <CustomText.OpenSansSemiBold
              textStyle={{
                fontSize: "0.875rem",
              }}
            >
              Contact at DropOff
            </CustomText.OpenSansSemiBold>
            <RenderRTL title={"Name"} val={data?.delivery_contact_name} />
            <RenderRTL title={"Phone Number"} val={`+92 ${data?.delivery_contact_phone_no}`}/>
          </div>
        </div>
      ) : null}
    </div>
  );
};
