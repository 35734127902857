import React, { useState, useEffect } from "react";
import { ISelectRecipientPageProps } from "./types";
import { Images } from "@temp/assets";
import "./style.scss";
import {
  OutLineButton,
  PrimaryButton,
  SimpleCheckBox,
  TitleLayout,
} from "@components";
import rootReducer from "../../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
export type RootState = ReturnType<typeof rootReducer>;
import { DrawerAction, LoadAction } from "@temp/store/actions";
import { useHistory, useLocation, useParams } from "react-router";
import { Grid } from "@mui/material/";
import {
  getDate,
  getTime,
  isObjectEmpty,
  phoneNumberFormater,
  showMessage
} from "@temp/utils/helper";
import moment from "moment";
import { LOAD_TYPE, QUOTE_BY } from "@temp/constants";
import EmptyPlaceholder from "@temp/components/EmptyPlaceholder/EmptyPlaceholder";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import {
  convertDataForFclAndBulkLoadDetailRedux,
  convertDataForLoadDetailRedux,
} from "@temp/utils/dataFormat";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
var numeral = require("numeral");

const Page: React.FC<ISelectRecipientPageProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  // 
  const params: any = useParams();
  const networkData = useSelector(
    (state: RootState) => state.Drawer.networkData
  );
  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
  const mg_admin = metaData?.mg_recipient?.PHONE_NO;
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [alreadyRecipient, setAlreadyRecipient] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);

  // 
  const [search, setSearch] = useState("");
  const [selectAllRecipients, setSelectAllRecipients] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const postLoadMeta = useSelector(
    (state: RootState) => state?.Profile?.postLoadMeta
  );
  const packageType = postLoadMeta.package_types;
  const load_type = postLoadMeta.load_types;
  const inquiry_type = postLoadMeta.inquiry_types;
  const PreferredVehicleType = postLoadMeta.preferred_vehicles;

  const contacts = [...networkData];


  // 

  // useEffect(() => {
  //   // if (contacts?.length == selectedNetwork?.length) {
  //     if (selectAllRecipients) {
  //       setSelectAllRecipients(true);
  //     }
  //   // } 
  //   else {
  //     setSelectAllRecipients(false);
  //   }

  // }, [])


  const handleCheckBoxForAllRecipients = () => {
    // let forAllNumber = [];
    let forAllNetwork = [];
    // 

    getDifference(contacts, alreadyRecipient)?.forEach((item: any) => {

      // if (item?.networkUser?.name !== "Unregistered User" && item?.networkUser?.phone_no !== state?.loadDetail?.poster?.phone_no) {
      if (
        item?.networkUser?.name !== "Unregistered User" && item?.networkUser?.name !== "MG approved" && item?.networkUser?.name !== "Meri Gari" &&
        item?.networkUser?.phone_no !== state?.loadDetail?.poster?.phone_no
      ) {
        if (!selectAllRecipients) {

          setSelectAllRecipients(true);
          forAllNetwork.push({
            networkUser: item?.networkUser,
          });
        } else {
          setSelectedNetwork([]);
          setSelectAllRecipients(false);
        }
      }
    });
    setSelectedNetwork(forAllNetwork);
    //   
  };

  const callback = () => {
    history.goBack();
  };

  const calback2 = () => {
    history.push("/dashboard/spot-recieved");
  };

  const removeDuplicate = (arr) => {
    let result = arr.reduce((unique, o) => {
      if (!unique.some((obj) => obj.phone_no === o.phone_no)) {
        unique.push(o);
      }
      return unique;
    }, []);
    return result;
  };

  const getVehiclesList = (data: any, postLoadData) => {
    let item = [];
    data?.forEach((val: any) => {
      item.push({
        master_vehicle_type_id: val.selectVehicleTypeObj.id,
        max_vehicle_weight: val.max_vehicle_weight,
        vehicle_quantity: val.vehicle_quantity,
        container_type_id: val?.containerType?.id || null,

        is_boxed: val?.values?.is_boxed || false,
        is_sidewall: val?.values?.is_sidewall || false,
        is_any_suitable_vehicle: val?.values?.is_any_suitable_vehicle || false,
        is_flat_bed_vehicle: val?.values?.is_flat_bed_vehicle || false,
        is_low_bed_vehicle: val?.values?.is_low_bed_vehicle || false,
        is_dum_truck_vehicle: val?.values?.is_dum_truck_vehicle || false,

        preferred_vehicle_id: val?.PreferredVehicleType?.id || null,
        package_type_id: val?.packageType?.id || null,
        is_out_of_guage: val?.values?.is_out_of_guage || false,
        package_type_other: val?.values?.package_type_other || "",
        is_twin_load: val?.values?.is_twin_load || false,
        is_shipper_owned_container:
          val?.values?.is_shipper_owned_container || false,

        is_loading_required:
          val?.is_loading_required || val?.values?.is_loading_required || false,
        is_unloading_required:
          val?.is_unloading_required ||
          val?.values?.is_unloading_required ||
          false,
        loading_requirements:
          val?.loading_requirements ||
          val?.values?.loading_requirements ||
          val?.values?.loading_requirements ||
          null,
        unloading_requirements:
          val?.unloading_requirements ||
          val?.values?.unloading_requirements ||
          null,
        ...(postLoadData?.transaction_type_id === 1 && {
          total_offer_price: val?.total_amount || null,
          offer_price_per_vehicle: val?.price || null,
          loading_price: val?.loading_price || null,
          unloading_price: val?.unloading_price || null,
          detention_rate: val?.detention_rate || null,
          detention_additional_charges:
            val?.detention_additional_charges || null,
        }),
        detention_included_transport: val.hasOwnProperty(
          "detention_included_transport"
        )
          ? val?.detention_included_transport
          : val?.values?.detention_included_transport,

        detention_free_time: val?.hasOwnProperty("detention_free_time")
          ? val?.detention_free_time
          : val?.values?.detention_free_time,
        max_bid: val?.hasOwnProperty("max_bid")
          ? val?.max_bid
          : val?.values?.max_bid,

        is_genset: val?.hasOwnProperty("is_genset")
          ? val?.is_genset
          : val?.values?.is_genset,
        required_temperature: val?.hasOwnProperty("required_temperature")
          ? val?.required_temperature
          : val?.values?.required_temperature,
      });
    });
    return item;
  };

  const success = () => { };

  const getCommonData = (postData: any, postLoadData) => {
    postData["title"] = postLoadData?.vehicleScreen?.values?.load_name;
    postData["cargo_description"] =
      postLoadData?.vehicleScreen?.values?.cargo_description;
    postData["inquiry_type_id"] = postLoadData?.inquiry_type;
    postData["load_type_id"] = postLoadData?.load_type;
    postData["transaction_type_id"] = postLoadData?.transaction_type_id;
    postData["instructions"] = postLoadData?.instructions || null;
    postData["additional_requirements"] =
      postLoadData?.additional_requirements || null;
    postData["instructions_voice_note"] =
      postLoadData?.instructions_voice_note?.file_url || null;
    postData["pt_is_advance"] = postLoadData?.PaymentScreen?.pt_is_advance;
    postData["pt_is_ondelivery"] =
      postLoadData?.PaymentScreen?.pt_is_ondelivery;
    postData["pt_is_credit"] = postLoadData?.PaymentScreen?.pt_is_credit;
    postData["is_pt_fuel_card"] = postLoadData?.PaymentScreen?.is_pt_fuel_card;
    postData["is_gst_inclusive"] =
      postLoadData?.PaymentScreen?.is_gst_inclusive;
    postData["is_withholding_tax_inclusive"] =
      postLoadData?.PaymentScreen?.is_withholding_tax_inclusive;
    postData["pt_advance_value"] =
      postLoadData?.PaymentScreen?.pt_advance_value || 0;
    postData["pt_ondelivery_value"] =
      postLoadData?.PaymentScreen?.pt_ondelivery_value || 0;
    postData["pt_credit_days"] =
      postLoadData?.PaymentScreen?.pt_credit_days || 0;
    postData["pt_credit_percentage"] =
      postLoadData?.PaymentScreen?.pt_credit_percentage || 0;
    postData["pt_fuel_card_value"] =
      postLoadData?.PaymentScreen?.pt_fuel_card_value || 0;
    postData["sales_tax_province"] =
      postLoadData?.PaymentScreen?.sales_tax_province || "";
    postData["categories"] = {};
    postData["categories"]["pickup"] = {};
    postData["categories"]["pickup"]["lat"] = postLoadData?.location[0].lat;
    postData["categories"]["pickup"]["description"] =
      postLoadData?.location[0]?.description;
    postData["categories"]["pickup"]["raw_address"] =
      postLoadData?.location[0]?.address;
    postData["categories"]["pickup"]["long"] = postLoadData?.location[0]?.lng;
    postData["categories"]["pickup"]["city"] = postLoadData?.location[0].city;
    postData["categories"]["pickup"]["title"] =
      postLoadData?.location[0]?.title;
    postData["categories"]["pickup"]["contact_name"] =
      postLoadData?.from == "forward"
        ? null
        : postLoadData?.location[0]?.contact_name;
    postData["categories"]["pickup"]["contact_phone_no"] =
      postLoadData?.from == "forward"
        ? null
        : postLoadData?.location[0]?.contact_phone_no;
    postData["categories"]["pickup"]["contact_phone_code"] =
      postLoadData?.from == "forward"
        ? null
        : postLoadData?.location[0]?.contact_phone_code;

    postData["pickup_date"] = getDate(
      postLoadData?.responseDeadline?.pickupDate
    );
    if (postLoadData?.isDraft) {
      postData["pickup_time"] = postLoadData?.responseDeadline?.pickupTime ? dayjs(`${postLoadData?.responseDeadline?.pickupDate} ${postLoadData?.responseDeadline?.pickupTime}`).format("HH:mm:ss") : null

    } else {
      postData["pickup_time"] = postLoadData?.responseDeadline?.pickupTime ? postLoadData?.responseDeadline?.pickupTime : null;
    }
    // if (postLoadData?.isDraft) {
    //   postData["pickup_time"] = postLoadData?.responseDeadline?.pickupTime
    //     ? moment(
    //       `${postLoadData?.responseDeadline?.pickupDate} ${postLoadData?.responseDeadline?.pickupTime}`
    //     ).format("HH:mm:ss")
    //     : null;
    // } else {
    //   postData["pickup_time"] = postLoadData?.responseDeadline?.pickupTime
    //     ? getTime(postLoadData?.responseDeadline?.pickupTime)
    //     : null;
    // }

    (postData["additional_pickups"] = postLoadData?.additional_pickups?.map(
      (item) => {
        return { ...item, raw_address: item?.address, long: item?.lng };
      }
    )),
      (postData["additional_dropoffs"] = postLoadData?.additional_dropoffs?.map(
        (item) => {
          return { ...item, raw_address: item?.address, long: item?.lng };
        }
      ));
    // delivery

    // if (!postLoadData?.isDraft) {
    //   postData["delivery_at"] =
    //     postLoadData?.responseDeadline?.deliveryDate &&
    //       postLoadData?.responseDeadline?.deliveryTime
    //       ? String(getDate(postLoadData?.responseDeadline?.deliveryDate)) +
    //       " " +
    //       String(getTime(postLoadData?.responseDeadline?.deliveryTime))
    //       : postLoadData?.responseDeadline?.deliveryDate
    //         ? moment(postLoadData?.responseDeadline?.deliveryDate).format(
    //           "YYYY-MM-DD HH:mm:ss"
    //         )
    //         : null;
    // } else {
    //   postData["delivery_at"] = postLoadData?.responseDeadline?.deliveryDate
    //     ? moment(postLoadData?.responseDeadline?.deliveryDate).format(
    //       "YYYY-MM-DD HH:mm:ss"
    //     )
    //     : null;
    // }
    if (!postLoadData?.isDraft) {
      postData["delivery_at"] =
        postLoadData?.responseDeadline?.deliveryDate &&
          postLoadData?.responseDeadline?.deliveryTime
          ? String(getDate(postLoadData?.responseDeadline?.deliveryDate)) +
          " " +
          String(postLoadData?.responseDeadline?.deliveryTime) :
          postLoadData?.responseDeadline?.deliveryDate
            ? moment(postLoadData?.responseDeadline?.deliveryDate).format('YYYY-MM-DD HH:mm:ss') : null
    } else {
      // postData["delivery_at"] = postLoadData?.responseDeadline?.deliveryDate ? moment(postLoadData?.responseDeadline?.deliveryDate).format('YYYY-MM-DD HH:mm:ss') : null
      postData["delivery_at"] =
        postLoadData?.responseDeadline?.deliveryDate &&
          postLoadData?.responseDeadline?.deliveryTime
          ? String(getDate(postLoadData?.responseDeadline?.deliveryDate)) +
          " " +
          String(postLoadData?.responseDeadline?.deliveryTime) :
          postLoadData?.responseDeadline?.deliveryDate
            ? moment(postLoadData?.responseDeadline?.deliveryDate).format('YYYY-MM-DD HH:mm:ss') : null
    }

    // if (!postLoadData?.isDraft) {
    //   postData["empty_return_deadline_at"] =
    //     postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate &&
    //       postLoadData?.responseDeadline?.emptyReturnDeadlineAtTime
    //       ? String(
    //         getDate(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate)
    //       ) +
    //       " " +
    //       String(
    //         getTime(postLoadData?.responseDeadline?.emptyReturnDeadlineAtTime)
    //       )
    //       : postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate
    //         ? moment(
    //           postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate
    //         ).format("YYYY-MM-DD HH:mm:ss")
    //         : null;
    // } else {
    //   postData["empty_return_deadline_at"] = postLoadData?.responseDeadline
    //     ?.emptyReturnDeadlineAtDate
    //     ? moment(
    //       postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate
    //     ).format("YYYY-MM-DD HH:mm:ss")
    //     : null;
    // }

    if (!postLoadData?.isDraft) {
      postData["empty_return_deadline_at"] =
        postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate &&
          postLoadData?.responseDeadline?.emptyReturnDeadlineAtTime
          ? String(getDate(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate)) +
          " " +
          String(postLoadData?.responseDeadline?.emptyReturnDeadlineAtTime)
          : postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate ? moment(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate).format('YYYY-MM-DD HH:mm:ss') : null;
    } else {
      // postData["empty_return_deadline_at"] = postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate ? moment(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate).format('YYYY-MM-DD HH:mm:ss') : null
      postData["empty_return_deadline_at"] =
        postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate &&
          postLoadData?.responseDeadline?.emptyReturnDeadlineAtTime
          ? String(getDate(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate)) +
          " " +
          String(postLoadData?.responseDeadline?.emptyReturnDeadlineAtTime)
          : postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate ? moment(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate).format('YYYY-MM-DD HH:mm:ss') : null;
    }

    // rate validity

    if (!postLoadData?.isDraft) {
      postData["rate_validity_date"] =
        postLoadData?.responseDeadline?.rateValidityDate
          ? String(getDate(postLoadData?.responseDeadline?.rateValidityDate))
          : null;
    } else {
      postData["rate_validity_date"] = postLoadData?.responseDeadline?.rateValidityDate ? moment(postLoadData?.responseDeadline?.rateValidityDate).format('YYYY-MM-DD') : null
    }

    // if (!postLoadData?.isDraft) {
    //   postData["rate_validity_date"] = postLoadData?.responseDeadline
    //     ?.rateValidityDate
    //     ? String(getDate(postLoadData?.responseDeadline?.rateValidityDate))
    //     : null;
    // } else {
    //   postData["rate_validity_date"] = postLoadData?.responseDeadline
    //     ?.rateValidityDate
    //     ? moment(postLoadData?.responseDeadline?.rateValidityDate).format(
    //       "YYYY-MM-DD"
    //     )
    //     : null;
    // }

    postData["empty_return_yard_name"] =
      // postLoadData?.responseDeadline?.empty_return_yard_name || null;
      postLoadData?.empty_return_yard_name || null;

    postData["is_empty_return"] =
      // postLoadData?.responseDeadline?.is_empty_return || false;
      postLoadData?.is_empty_return || false;

    postData["categories"]["dropoff"] = {};
    postData["categories"]["dropoff"]["lat"] = postLoadData?.location[1].lat;
    postData["categories"]["dropoff"]["description"] =
      postLoadData?.location[1]?.description;
    postData["categories"]["dropoff"]["raw_address"] =
      postLoadData?.location[1]?.address;
    postData["categories"]["dropoff"]["long"] = postLoadData?.location[1]?.lng;
    postData["categories"]["dropoff"]["city"] = postLoadData?.location[1].city;
    postData["categories"]["dropoff"]["title"] =
      postLoadData?.location[1]?.title;
    postData["categories"]["dropoff"]["contact_name"] =
      postLoadData?.from == "forward"
        ? null
        : postLoadData?.location[1]?.contact_name;
    postData["categories"]["dropoff"]["contact_phone_no"] =
      postLoadData?.from == "forward"
        ? null
        : postLoadData?.location[1]?.contact_phone_no;
    postData["categories"]["dropoff"]["contact_phone_code"] =
      postLoadData?.from == "forward"
        ? null
        : postLoadData?.location[1]?.contact_phone_code;

    postData["deadline_at"] =
      String(getDate(postLoadData?.responseDeadline?.responseDate)) +
      " " +
      String(postLoadData?.responseDeadline?.responseTime);
    // String(getTime(postLoadData?.responseDeadline?.responseTime));

    postData["is_covid_certf_req_driver"] =
      postLoadData?.additionalScreen?.is_covid_certf_req_driver;
    postData["is_vehicle_tracking"] =
      postLoadData?.additionalScreen?.is_vehicle_tracking;
    postData["is_hse_compliant_vehicle"] =
      postLoadData?.additionalScreen?.is_hse_compliant_vehicle;

    postData["is_weightment_received_required"] =
      postLoadData?.additionalScreen?.is_weightment_received_required;

    postData["weightment_received_name"] =
      postLoadData?.additionalScreen?.weightment_received_name;

    postData["driver_requried_document_details"] =
      postLoadData?.additionalScreen?.driver_requried_document_details;

    postData["is_bonded_vehicle_required"] =
      postLoadData?.additionalScreen?.is_bonded_vehicle_required;

    postData["is_tarpaulin_plastic_cover"] =
      postLoadData?.additionalScreen?.is_tarpaulin_plastic_cover;

    postData["is_under_hook_delivery_required"] =
      postLoadData?.additionalScreen?.is_under_hook_delivery_required;

    postData["under_hook_delivery"] =
      postLoadData?.additionalScreen?.under_hook_delivery;

    // postData["rate_validity_date"] = moment(postLoadData?.additionalScreen?.rate_validity_date).format("YYYY-MM-DD");
    postData["is_advance_vehicle_driver_docs"] =
      postLoadData?.additionalScreen?.is_advance_vehicle_driver_docs;

    postData["is_driver_negative_pcr_required"] =
      postLoadData?.additionalScreen?.is_driver_negative_pcr_required;

    postData["detention_included_transport"] =
      postLoadData?.additionalScreen?.detention_included_transport;

    postData["is_cross_stuffing_required"] =
      postLoadData?.additionalScreen?.is_cross_stuffing_required;
    postData["is_allow_partial_load"] =
      postLoadData?.vehicleScreen?.is_allow_partial_load;

    postData["is_cross_stuffing_box_vehicle_required"] =
      postLoadData?.additionalScreen?.is_cross_stuffing_box_vehicle_required;

    postData["cross_stuffing_no_of_packages"] =
      postLoadData?.additionalScreen?.cross_stuffing_no_of_packages;

    postData["documents"] = postLoadData?.documents || null;

    postData["recipients"] =
      removeDuplicate(
        selectedNetwork?.map((item) => {
          return { phone_no: item?.networkUser?.phone_no, phone_code: "+92" };
        })
      ) || null;

    postData["ref_no"] = postLoadData?.ref_no || null;

    postData["cb"] = success;
    return postData;
  };

  const getFclData = (postData: any, postLoadData) => {
    // return
    postData = {
      ...postData,
      ...getCommonData(postData, postLoadData),

      is_container_deposit_by_transporter:
        postLoadData?.additionalScreen?.is_container_deposit_by_transporter ||
        false,
      shipping_line_name:
        postLoadData?.additionalScreen?.shipping_line_name || null,
      container_deposit_amount:
        postLoadData?.additionalScreen?.container_deposit_amount || null,
      is_quote_price_deposit_fee_inclusive:
        postLoadData?.additionalScreen?.is_quote_price_deposit_fee_inclusive,
      quote_by: QUOTE_BY.vehicle,
    };

    postData["categories"]["items"] = getVehiclesList(
      postLoadData?.vehicleScreen?.addedVehicles || [],
      postLoadData
    );
    return postData;
  };

  const getLclData = (postData: any, postLoadData) => {
    postData = { ...postData, ...getCommonData(postData, postLoadData) };
    postData["gross_weight"] =
      postLoadData?.vehicleScreen?.values?.gross_weight;
    postData["total_freight_ton"] = numeral(
      postLoadData?.vehicleScreen?.values?.total_freight_ton
    ).format("0.00");

    postData["cargo_volume"] =
      postLoadData?.vehicleScreen?.values?.cargo_volume || null;
    postData["price_unit"] =
      postLoadData?.vehicleScreen?.values?.price_unit || null;
    postData["no_of_packages"] =
      postLoadData?.vehicleScreen?.values?.no_of_packages;
    postData["cargo_liter"] = postLoadData?.vehicleScreen?.values?.cargo_liter;
    postData["quote_by"] = postLoadData?.vehicleScreen?.values?.quote_by;
    postData["gross_weight"] =
      postLoadData?.vehicleScreen?.values?.gross_weight;
    if (
      postLoadData?.vehicleScreen?.values?.quote_by === QUOTE_BY.weightVolume
    ) {
      postData["price_per_freight"] =
        postLoadData?.vehicleScreen?.values?.price;

      postData["package_type_other"] =
        postLoadData?.vehicleScreen?.values?.package_type_other || "";

      postData["loading_price_per_freight"] =
        postLoadData?.vehicleScreen?.values?.loading_price;

      postData["unloading_price_per_freight"] =
        postLoadData?.vehicleScreen?.values?.unloading_price;

      postData["total_offer_price"] =
        postLoadData?.vehicleScreen?.values?.total_amount;

      postData["detention_rate"] =
        postLoadData?.vehicleScreen?.values?.detention_rate;

      postData["detention_additional_charges"] =
        postLoadData?.vehicleScreen?.values?.detention_additional_charges;
      postData["detention_free_time"] =
        postLoadData?.vehicleScreen?.values?.detention_free_time;
      postData["detention_included_transport"] =
        postLoadData?.vehicleScreen?.values?.detention_included_transport;
      postData["max_bid"] = postLoadData?.vehicleScreen?.values?.max_bid;

      postData["is_genset"] = postLoadData?.vehicleScreen?.values?.is_genset;
      postData["required_temperature"] =
        postLoadData?.vehicleScreen?.values?.required_temperature;

      postData["package_type_id"] =
        postLoadData?.vehicleScreen?.packageType?.id;

      postData["preferred_vehicle_id"] =
        postLoadData?.vehicleScreen?.PreferredVehicleType?.id;

      postData["is_sidewall"] =
        postLoadData?.vehicleScreen?.values?.is_sidewall || false;
      postData["unloading_requirements"] =
        postLoadData?.vehicleScreen?.values?.unloading_requirements;
      postData["loading_requirements"] =
        postLoadData?.vehicleScreen?.values?.loading_requirements;
      postData["is_unloading_required"] =
        postLoadData?.vehicleScreen?.values?.is_unloading_required;
      postData["is_loading_required"] =
        postLoadData?.vehicleScreen?.values?.is_loading_required;
      postData["is_shared_vehicle_not_allowed"] =
        postLoadData?.vehicleScreen?.values?.is_shared_vehicle_not_allowed;
    }
    postData["categories"]["items"] = getVehiclesList(
      postLoadData?.vehicleScreen?.addedVehicles || [],
      postLoadData
    );
    postData["is_unit_basis"] = postLoadData?.vehicleScreen?.is_unit_basis;
    if (postLoadData?.vehicleScreen?.is_unit_basis) {
      postData["added_units"] = postLoadData?.vehicleScreen?.added_units?.map(
        (element) => {
          return {
            ...element,
            total_offer_price: element?.total_amount || null,
            offer_price_per_vehicle: element?.price || null,
          };
        }
      );
      postData["is_unit_basis"] = true;
    }
    return postData;
  };

  useEffect(() => {
    if (state?.loadDetail?.categories.length) {
      let recip = state?.loadDetail?.categories[0]?.recipients?.map((item) => {
        if (item) {
          return {
            phone_code: item?.recipient?.phone_code,
            phone_no: item?.recipient?.phone_no,
          };
        }
      });
      setAlreadyRecipient(recip);
    } else {
      let recip = state?.loadDetail?.recipients?.map((item) => {
        if (item) {
          return {
            phone_code: item?.recipient?.phone_code,
            phone_no: item?.recipient?.phone_no,
          };
        }
      });
      setAlreadyRecipient(recip);
    }
  }, [state, networkData]);

  const selectNetwork = (e, num, item) => {
    // 
    // 
    // 
    if (params?.type == "allocate" || params?.type == "forward") {
      let new_selectedNetwork = [];
      let new_selectedNumbers = [];
      // if (new_selectedNetwork.length >= 1) {
      // new_selectedNetwork.splice(index, 1);
      // new_selectedNumbers.splice(index, 1);
      // } else {
      new_selectedNumbers.push(num);
      new_selectedNetwork.push(item);
      // }
      // 
      setSelectedNetwork(new_selectedNetwork);
      setSelectedNumbers(new_selectedNumbers);
    } else if (params?.type == "add-viewer") {
      let new_selectedNumbers = [...selectedNumbers];
      let new_selectedNetwork = [...selectedNetwork];
      // 
      // 
      // 
      let index = new_selectedNetwork.indexOf(num);
      // 
      const exist = new_selectedNetwork.find(
        (each) => each?.networkUser?.phone_no === num
      );

      if (exist) {
        const updatedNetworks = new_selectedNetwork.filter(
          (each) => each?.networkUser?.phone_no !== num
        );
        new_selectedNetwork = updatedNetworks;

        setSelectAllRecipients(false);

        // new_selectedNumbers.splice(index, 1);
        // new_selectedNetwork.splice(index, 1);
      } else {

        new_selectedNumbers.push(num);
        new_selectedNetwork.push(item);
      }

      // if (new_selectedNumbers.indexOf(num) === -1) {

      //   new_selectedNumbers.push(num)
      //   new_selectedNetwork.push(item);
      // } else {

      //   new_selectedNumbers.splice(index, 1);
      //   new_selectedNetwork.splice(index, 1);
      // }
      // 

      const renderedNetworks = getDifference(contacts, alreadyRecipient)
        .filter(
          (item) =>
            (item?.networkUser?.phone_no != mg_admin &&
              item?.networkUser?.name
                .toLowerCase()
                .includes(search.toLowerCase())) ||
            (item?.networkUser?.phone_no != mg_admin &&
              item?.networkUser?.company?.length &&
              item?.networkUser?.company[0]?.name
                ?.toLowerCase()
                .includes(search.toLowerCase())) ||
            item?.networkUser?.company_name
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            (item?.networkUser?.phone_no != mg_admin &&
              item?.networkUser?.phone_no.includes(
                search?.replace(/^0+/, "")
              ))
        )
      const registeredNetworks = renderedNetworks.filter(each => each?.networkUser?.name !== "Unregistered User" && each?.networkUser?.phone_no !== state?.loadDetail?.poster?.phone_no)
      setSelectAllRecipients(registeredNetworks?.length === new_selectedNetwork?.length ? true : false)
      setSelectedNetwork(new_selectedNetwork);
      setSelectedNumbers(new_selectedNumbers);

    }
  };


  const allocate = () => {
    contacts.forEach((each, index) => {
      if (each?.networkUser?.status == "Unverified") {
        showMessage.current({
          message: "This user is not verified",
          status: "error"
        })
      } else { }
    })
    dispatch(
      LoadAction.allocateLoad({
        load_id: state?.load_id,
        allocate_to_phone_code: selectedNetwork[0]?.networkUser.phone_code,
        allocate_to_phone_no: selectedNetwork[0]?.networkUser.phone_no,
        cb: callback,
      })
    );
  };

  useEffect(() => {
    if (state?.alreadyViewers) {
      let data = [...state?.alreadyViewers];
      setSelectedNetwork(data);
    }
    if (state?.viewerNumbers) {
      let data2 = [...state?.viewerNumbers];
      setSelectedNumbers(data2);
    }
  }, []);

  useEffect(() => {
    const regUsers = getDifference(contacts, alreadyRecipient)
      .filter(
        (item) =>
          (item?.networkUser?.phone_no != mg_admin &&
            item?.networkUser?.name
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (item?.networkUser?.phone_no != mg_admin &&
            item?.networkUser?.company?.length &&
            item?.networkUser?.company[0]?.name
              ?.toLowerCase()
              .includes(search.toLowerCase())) ||
          item?.networkUser?.company_name
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          (item?.networkUser?.phone_no != mg_admin &&
            item?.networkUser?.phone_no.includes(
              search?.replace(/^0+/, "")
            ))
      ).filter(each => each?.networkUser?.name !== "Unregistered User" && each?.networkUser?.phone_no !== state?.loadDetail?.poster?.phone_no)

    if (selectedNetwork?.length === regUsers?.length) {
      setSelectAllRecipients(true)
    } else {
      setSelectAllRecipients(false)
    }
  }, [selectedNetwork, contacts])

  const forward = () => {
    if (!isObjectEmpty(state?.loadDetail)) {
      let data = {};
      // 
      if (state?.loadDetail.load_type.title === LOAD_TYPE.bulk) {
        data = convertDataForFclAndBulkLoadDetailRedux(
          {
            ...state?.loadDetail,
            additional_pickups: state?.loadDetail?.additional_pickups?.map(
              (item) => {
                return {
                  ...item,
                  contact_name: null,
                  contact_phone_no: null,
                  contact_phone_code: null,
                };
              }
            ),
            additional_dropoffs: state?.loadDetail?.additional_dropoffs?.map(
              (item) => {
                return {
                  ...item,
                  contact_name: null,
                  contact_phone_no: null,
                  contact_phone_code: null,
                };
              }
            ),
          },
          packageType,
          load_type,
          inquiry_type,
          PreferredVehicleType,
          "forward",
          null
        );
      } else {
        data = convertDataForLoadDetailRedux(
          {
            ...state?.loadDetail,
            additional_pickups: state?.loadDetail?.additional_pickups?.map(
              (item) => {
                return {
                  ...item,
                  contact_name: null,
                  contact_phone_no: null,
                  contact_phone_code: null,
                };
              }
            ),
            additional_dropoffs: state?.loadDetail?.additional_dropoffs?.map(
              (item) => {
                return {
                  ...item,
                  contact_name: null,
                  contact_phone_no: null,
                  contact_phone_code: null,
                };
              }
            ),
          },
          packageType,
          load_type,
          inquiry_type,
          "forward",
          null
        );
      }
      contacts.forEach((each, index) => {
        if (each?.networkUser?.status == "Unverified") {
          showMessage.current({
            message: "This user is not verified",
            status: "error"
          })
        } else { }
      })
      if (!isObjectEmpty(data)) {
        if (state?.loadDetail.load_type.title === LOAD_TYPE.bulk) {
          let final_payload = getLclData({}, data);
          // return
          dispatch(
            LoadAction.forwardLoad({
              forwardData: { ...final_payload, cb: calback2 },
              load_id: state?.loadDetail?.id,
            })
          );
        } else {
          let final_payload = getFclData({}, data);
          dispatch(
            LoadAction.forwardLoad({
              forwardData: { ...final_payload, cb: calback2 },
              load_id: state?.loadDetail?.id,
            })
          );
        }
      }
    }
  };

  const addViewer = () => {
    const payload = {
      job_load_id: state?.load_id,
      job_load_category_id: state?.category_id,
      viewers: selectedNetwork?.map((item) => {
        return {
          phone_code: item?.networkUser?.phone_code,
          phone_no: item?.networkUser?.phone_no,
        };
      }),
      cb: callback,
    };
    contacts.forEach((each, index) => {
      if (each?.networkUser?.status == "Unverified") {
        showMessage.current({
          message: "This user is not verified",
          status: "error"
        })
      }
      else {
      }
    })
    dispatch(LoadAction.addViewer(payload))
  };

  useEffect(() => {
    if (params?.type == "allocate") {
      dispatch(DrawerAction.GetNetwork({ only_company_users: 1 }));
    } else {
      dispatch(DrawerAction.GetNetwork({ only_company_users: "" }));
    }
  }, []);

  const Card = ({ val, num, size = 6 }) => {
    let isChecked = selectedNetwork?.find(
      (values) => values?.networkUser?.phone_no === val?.networkUser?.phone_no
    );
    if (
      val?.networkUser?.phone_no !== mg_admin &&
      val?.networkUser?.phone_no !== state?.loadDetail?.poster?.phone_no
    ) {
      return (
        <div
          data-size={size == 6 ? "50" : "100"}
          className={`recipentGridBox ${isChecked ? "checked" : ""}`}
        >
          {val?.networkUser?.name !== "Unregistered User" ? (
            <div className="selectRecipentCheckBox">
              <SimpleCheckBox
                handleChange={(e) => {
                  selectNetwork(e, val?.networkUser?.phone_no, val);
                }}
                checked={
                  // selectedNumbers.indexOf(num) !== -1
                  isChecked ? true : false
                }
              />
            </div>
          ) : null}
          <div className="insideRecipentContentBoxWrap">
            <img
              src={
                val?.networkUser?.picture
                  ? val?.networkUser?.picture
                  : metaData?.default_avatar
              }
              className="userImg"
            />
            <div className="w-100">
              {val?.networkUser?.company?.length ? (
                <>
                  {val?.networkUser?.company &&
                    val?.networkUser?.company.length ? (
                    <p className="companyTitle">
                      {val?.networkUser?.company &&
                        val?.networkUser?.company.length
                        ? val?.networkUser?.company[0]?.name
                          ? val?.networkUser?.company[0]?.name
                          : ""
                        : ""}
                    </p>
                  ) : (
                    ""
                  )}
                  {val?.networkUser?.name ? (
                    <p className="name">{val?.networkUser?.name || ""} </p>
                  ) : (
                    ""
                  )}
                  {val?.networkUser?.phone_no ? (
                    <p className="phone">{`${val?.networkUser?.phone_no !== mg_admin
                      ? `${phoneNumberFormater(
                        val?.networkUser?.phone_code,
                        val?.networkUser?.phone_no
                      )}`
                      : " "
                      } `}</p>
                  ) : (
                    ""
                  )}
                  {val?.networkUser?.name == "Unregistered User" ? (
                    <p className="name">Invite Sent</p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {val?.networkUser?.name ? (
                    <p className="companyTitle">
                      {val?.networkUser?.name || ""}
                    </p>
                  ) : (
                    ""
                  )}
                  {val?.networkUser?.company_name ? (
                    <p className="name">{val?.networkUser?.company_name}</p>
                  ) : (
                    ""
                  )}
                  {val?.networkUser?.phone_no !== mg_admin ? (
                    <p className="phone">
                      {" "}
                      {`${val?.networkUser?.phone_no !== mg_admin
                        ? `${phoneNumberFormater(
                          val?.networkUser?.phone_code,
                          val?.networkUser?.phone_no
                        )}`
                        : " "
                        } `}
                    </p>
                  ) : (
                    ""
                  )}
                  {val?.networkUser?.name == "Unregistered User" ? (
                    <p className="name">Invite Sent</p>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.networkUser?.phone_no === object2.phone_no;
      });
    });
  }

  // const contactsHaveCompany = networkData.filter(
  //   (val) => val?.networkUser?.company && val?.networkUser?.company?.length
  // );
  // const contactsHaveNoCompany = networkData.filter(
  //   (val) =>
  //     (val?.networkUser?.company && val?.networkUser.company?.length === 0) ||
  //     !val?.networkUser.company
  // );
  // const sortByCompanyName = contactsHaveCompany.length
  //   ? contactsHaveCompany.sort(
  //     (a, b) =>
  //       a?.networkUser?.company[0]?.name - b?.networkUser?.company[0]?.name
  //   )
  //   : [];

  const handleDisabled = () => {
    return selectedNetwork.length ? false : true;
  };

  return (
    <TitleLayout
      titleOnly={true}
      titleOnlyText={
        params?.type == "add-viewer" ? "Select Viewer" : " Select Recipient"
      }
    >
      <div className="networkContentWrap allocateRecipentWrapper">
        <OutLineButton
          buttonStyle={{ marginLeft: "auto" }}
          classProp="tinyBtn"
          dataColor="blue"
          title="Add new"
          icon={<AddCircleOutlineOutlined />}
          onClick={() => {
            history.push("/add-network", {
              from: "detail",
              type: params?.type,
              loadDetail: state?.loadDetail,
            });
          }}
        />
        <div className="networkSearchWrap">
          <FadeInComponent>
            <div className="searchContainer" style={{ width: "100%" }}>
              <input
                type="text"
                placeholder="Search"
                className="searchInput recipentSelectBox"
                value={search}
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className={`icons-search ${search ? "" : "active"}`}></span>
              <span
                className={`icons-cross ${search ? "active" : ""}`}
                onClick={() => {
                  setSearch("");
                }}
              ></span>
            </div>
          </FadeInComponent>
        </div>

        {/* select checkbox for all recipients */}
        {
          params?.type === "add-viewer" &&
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <FadeInComponent>
              <div className="flexRow">
                <div
                  style={{
                    width: "100%",
                    height: 63,
                  }}
                >
                  <SimpleCheckBox
                    style={{ height: 60 }}
                    checked={selectAllRecipients}
                    handleChange={(e) => {
                      if (!selectAllRecipients) {
                        handleCheckBoxForAllRecipients();
                        setSelectAllRecipients(true);
                      }
                      else {
                        setSelectedNetwork([]);
                        setSelectedNumbers([]);
                        setSelectAllRecipients(false);
                      }
                    }}
                    labelName={"Select All Recipients"}
                    title={"Select All Recipients"}
                  />
                  <span className="selectedRecipientCounter">
                    {selectedNetwork?.length}
                  </span>
                </div>
                <div
                  style={{
                    width: "40%",
                    height: 63,
                    display: "none",
                  }}
                >
                  <div className="selected-recipient-container">
                    <div
                      style={{
                        marginBottom: 3,
                      }}
                      className="simpleLabel fw-5"
                    >
                      Selected Recipient
                    </div>
                    <div>{selectedNetwork?.length}</div>
                  </div>
                </div>
              </div>
            </FadeInComponent>
          </div>}
        <div className="recipent-wrapper">
          <Grid container xs={12} className="recipentGridBoxWrap">
            {(contacts && contacts.length && params?.type !== "allocate") ||
              params?.type !== "add-viewer" ? (
              <>
                {getDifference(contacts, alreadyRecipient)
                  .filter(
                    (item) =>
                      (item?.networkUser?.phone_no != mg_admin &&
                        item?.networkUser?.name
                          .toLowerCase()
                          .includes(search.toLowerCase())) ||
                      (item?.networkUser?.phone_no != mg_admin &&
                        item?.networkUser?.company?.length &&
                        item?.networkUser?.company[0]?.name
                          ?.toLowerCase()
                          .includes(search.toLowerCase())) ||
                      item?.networkUser?.company_name
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      (item?.networkUser?.phone_no != mg_admin &&
                        item?.networkUser?.phone_no.includes(
                          search?.replace(/^0+/, "")
                        ))
                  )
                  .map((val: any, index: any) => (
                    <Card
                      key={index}
                      val={val}
                      num={val?.networkUser?.phone_no}
                    />
                  ))}
              </>
            ) : (
              <>
                {contacts?.map((val: any, index: any) => (
                  <Card
                    key={index}
                    val={val}
                    num={val?.networkUser?.phone_no}
                  />
                ))}
              </>
            )}
          </Grid>
          {params?.type !== "allocate" || params?.type !== "add-viewer" ? (
            <>
              {getDifference(contacts, alreadyRecipient)?.filter(
                (item) =>
                  item?.networkUser?.phone_no !==
                  state?.loadDetail?.poster?.phone_no
              ).length ? null : (
                <EmptyPlaceholder
                  image={Images.noNetworkNew}
                  title="No Contacts Added to Network"
                  detail="We value your privacy. Adding contacts in your network will allow you to direct your 
                  request to your relevant contact. 
                  Please click on below button to add contacts."
                />
              )}
            </>
          ) : (
            <>
              {contacts?.filter(
                (item) =>
                  item?.networkUser?.phone_no !==
                  state?.loadDetail?.poster?.phone_no
              ).length ? null : (
                <EmptyPlaceholder
                  image={Images.noNetworkNew}
                  title="No Contacts Added to Network"
                  detail="We value your privacy. Adding contacts in your network will allow you to direct your 
                  request to your relevant contact. 
                  Please click on below button to add contacts."
                />
              )}
            </>
          )}
        </div>
      </div>

      <PrimaryButton
        disabled={handleDisabled()}
        onClick={() => {
          if (params?.type == "allocate") {
            allocate();
          } else if (params?.type == "add-viewer") {
            addViewer();
          } else {
            forward();
          }
        }}
        title={
          params?.type == "allocate"
            ? "Allocate"
            : params?.type == "add-viewer"
              ? "Add Viewer"
              : "Forward"
        }
      />
    </TitleLayout>
  );
};

export default Page;