import React from "react";
import { IProps } from "./types";
import "./style.scss";

export const OpenSansRegular: React.FC<IProps> = ({
  textStyle,
  children,
}: IProps) => {
  return (
    <p className="regular-font" style={{ ...textStyle }}>
      {children}
    </p>
  );
};

export const OpenSansSemiBold: React.FC<IProps> = ({
  textStyle,
  children,
}: IProps) => {
  return (
    <p style={{...textStyle}} className="semi-bold-font">
      {children}
    </p>
  );
};

export const OpenSansBold: React.FC<IProps> = ({
  textStyle,
  children,
}: IProps) => {
  return (
    <p className="bold-font" style={{ ...textStyle }}>
      {children}
    </p>
  );
};
