import { Colors } from "@temp/assets";
import { CustomModal, CustomText, PrimaryButton } from "@temp/components";
import OutlineButton from "@temp/components/OutLineButton";
import React, { useState } from "react";
import "../LoadDetailPoster/style.scss";

export const DeclineButton = ({ recipient, type, declineByPoster, loadDetail }) => {
  
  const [showModal, setShowModal] = useState(false)

  if (type == "outline") {
    
    return (
      <>

        <div className="icons-cross decline-btn"
          onClick={() => {
            setShowModal(true)
          }}
        ></div>
        <CustomModal showModal={showModal} toggleModal={setShowModal}>
          <div
            style={{
              marginTop: 10,
              marginBottom: 10,
              maxWidth:310,
              textAlign: "center"
            }}
          >
            <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.125rem" }}>
              {`${loadDetail?.quote_by == "vehicle" ?
                "Are you sure you want to cancel this Category?" :
                loadDetail?.quote_by == "weight_volume" && loadDetail?.is_unit_basis ?
                  "Are you sure you want to cancel this Item Category?" :
                  "Are you sure you want to cancel this Cargo details?"
                }`}
              {/* Are you sure you want to decline? */}
            </CustomText.OpenSansSemiBold>
          </div>
          <PrimaryButton
            onClick={() => {
              declineByPoster(recipient.id);
              setShowModal(false)
            }}
            buttonStyle={{
              marginTop: 10,
              marginBottom: 10,
              height: 40,
            }}
            title="Yes"
          />

          <OutlineButton
            title="No"
            onClick={() => setShowModal(false)}
            buttonStyle={{
              height: 40,
            }}
          />
        </CustomModal>
      </>
    )
  } else {
    

    return (
      <button
        disabled={type !== "outline"}
        onClick={() => {
          declineByPoster(recipient.id);
        }}
        className={
          type === "outline" ? "decline-button-outline" : "decline-button"
        }
      >
        <CustomText.OpenSansRegular
          textStyle={{
            color: type === "outline" ? "red" : "white",
          }}
        >
          {
            type == "outline" ? "Decline" : "Declined"
          }
        </CustomText.OpenSansRegular>
      </button>
    );
  }
};
