import React from "react";
import "../LoadDetailPoster/style.scss";
import { LOAD_TYPE, QUOTE_BY } from "@temp/constants";
import { CustomText } from "@temp/components";
import { RenderRTL } from "./RenderRTL";
var numeral = require("numeral");

export const TwoColumnRTLVehicle = ({ loadDetail, item, type, forward = false, is_recipient = false }) => {

  return (
    <div className="twoGridWrapper" style={{ marginBottom: '1rem' }}>
      <RenderRTL
        title={loadDetail?.is_unit_basis ? "Dimensions" : loadDetail?.load_type?.title == LOAD_TYPE.fcl ? "Container Type" : "Packaging Type"}
        sep={"/"}
        urdutitle={loadDetail?.is_unit_basis ? "پیمائش" : loadDetail?.load_type?.title == LOAD_TYPE.fcl ? "کنٹینر کی قسم" : "پیکیجنگ کی قسم"}
        val={loadDetail?.is_unit_basis ? `${item?.dimensions} ${item?.unit_dimension_value}`
          : loadDetail?.load_type?.title == LOAD_TYPE.fcl ? item?.container_type?.title
            : item?.package_type?.title == "Other" ?
              `Other(${item?.package_type_other})` : item?.package_type?.title}
        is_recipient={is_recipient}
      />

      {
        loadDetail?.load_type?.title !== LOAD_TYPE.fcl && loadDetail?.quote_by == QUOTE_BY.vehicle ?
          <RenderRTL
            title={"Vehicle Type"}
            sep={"/"}
            urdutitle={"گاڑی کی قسم"}
            val={item?.vehicle?.title}
            is_recipient={is_recipient}
          /> : null
      }

      {
        item?.is_twin_load ?
          <RenderRTL
            title={"Twin Load (2x20 Ft)"}
            sep={"/"}
            urdutitle={"ٹوئن لوڈ (2x20 فٹ)"}
            val={"Yes"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.fcl ?
          <RenderRTL
            title={"No. of Containers"}
            sep={"/"}
            urdutitle={"کنٹینرز کی تعداد"}
            val={item?.is_twin_load == 1 ? Number(item?.vehicle_quantity) * 2 : item?.vehicle_quantity}
            is_recipient={is_recipient}
          /> : null
      }

      {
        !loadDetail?.is_unit_basis && item?.max_vehicle_weight ?
          <div className="maxCargoWeightTextWrap">
            <RenderRTL
              title={loadDetail?.load_type?.title == LOAD_TYPE.fcl ? "Max Cargo Weight Per Vehicle (Without Container)" : "Max Cargo Weight Per Vehicle "}
              sep={"/"}
              urdutitle={loadDetail?.load_type?.title == LOAD_TYPE.fcl ? "زیادہ سے زیادہ سامان کا وزن (کنٹینر وزن کے بغیر)" : "زیادہ سے زیادہ سامان کا وزن فی گاڑی"}
              val={item?.max_vehicle_weight + " Ton"}
              is_recipient={is_recipient}
            /> </div> : null
      }

      {
        item?.is_out_of_guage == 1 ?
          <RenderRTL
            title={"Out of Gauge"}
            sep={"/"}
            urdutitle={"آؤٹ آف گیج"}
            val={"Yes"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        item?.is_shipper_owned_container ?
          <div className="shipperOwnedWrap">
            <RenderRTL
              title={"Shipper Owned Container(SOC)"}
              sep={"/"}
              urdutitle={"شپپر کی ملکیت والا کنٹینر (SOC)"}
              val={"Yes"}
              is_recipient={is_recipient}
            /> </div> : null
      }

      {
        item?.detention_free_time ?
          <RenderRTL
            title={"Free Time Required"}
            sep={"/"}
            urdutitle={"فری وقت درکار "}
            val={item?.detention_free_time + " hrs"}
            is_recipient={is_recipient}
          /> : null
      }

      <div className="mandatoryWrap">
        {
          loadDetail?.load_type?.title === "Containerized" && item?.detention_included_transport ?
            <div className="mandatoryDetentionWrap">
              <RenderRTL
                title={"Mandatory Detention included in Transport Price?"}
                sep={"/"}
                urdutitle={"لازمی ڈیٹنشن ٹرانسپورٹ کی قیمت میں شامل ہے؟"}
                val={item?.detention_included_transport ? "YES" : "No"}
                infoMessage={"1 day Mandatory Detention should be selected in cases where detention is applicable due to road restrictions, after expiry of free period"}
                is_recipient={is_recipient}
              /></div> : null
        }
      </div>



      {loadDetail?.is_unit_basis ||
        item?.container_type?.provide_temperature_control == 0 || item?.vehicle?.provide_temperature_control == 0
        &&
        item?.required_temperature_min == 0 && item?.required_temperature_max == 0 ? null :
        <div className="tempDataLoad">
          <RenderRTL
            title={"Required Temperature"}
            sep={"/"}
            urdutitle={"مطلوبہ درجہ حرارت"}
            val={`Min: ${item?.required_temperature_min}`}
            val2={`Max: ${item?.required_temperature_max}`}
            is_recipient={is_recipient}
          />
        </div>
      }

      {
        // !loadDetail?.is_unit_basis ||
        item?.is_genset == 1 ||
          item?.container_type?.provide_genset == 1 || item?.vehicle?.provide_genset == 1 ?
          <RenderRTL
            title={"Genset Required"}
            sep={"/"}
            urdutitle={"جنریٹر کی ضرورت ہے"}
            val={item?.is_genset ? "Yes" : "No"}
            is_recipient={is_recipient}
          />
          : null
      }

      {
        loadDetail?.categories?.length && loadDetail?.transaction_type?.title == "Offer" && !forward ?
          <RenderRTL
            title={`Price / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
            sep={"/"}
            urdutitle={`ریٹ / ${loadDetail?.is_unit_basis ? "فی آئٹم" : "فی گاڑی"} (GST کے بغیر)`}
            val={loadDetail?.is_unit_basis ?
              numeral(Number(item?.offer_price_per_vehicle)).format("0,0.00") + " PKR"
              :
              numeral(Number(item?.offer_price_per_vehicle)).format("0,0") + " PKR"}
            infoMessage={"Price is inclusive of Free Time."}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.transaction_type?.title == "Offer" && !forward && item?.detention_rate > 0 ?
          <RenderRTL
            title={`Detention Rate (Without GST)`}
            sep={"/"}
            urdutitle={"ڈیٹنشن ریٹ (GST کے بغیر)"}
            val={
              loadDetail?.is_unit_basis ?
                numeral(Number(item?.detention_rate)).format("0,0.00") + " PKR"
                :
                numeral(Number(item?.detention_rate)).format("0,0") + " PKR"

            }
            is_recipient={is_recipient}
          /> : null
      }

      {loadDetail?.load_type?.title == LOAD_TYPE.fcl &&
        loadDetail?.transaction_type?.title == "Offer" && !forward &&
        item?.is_twin_load ?
        <RenderRTL
          title={`Price / Container (Without GST)`}
          sep={"/"}
          urdutitle={`ریٹ /  فی کنٹینر (GST کے بغیر)`}
          val={numeral(Number(item?.offer_price_per_vehicle) / 2).format("0,0") + " PKR"}
          infoMessage={"Price is inclusive of Free Time."}
          is_recipient={is_recipient}
        /> : null
      }

      {/* {
        loadDetail?.transaction_type?.title == "Offer" &&!forward && item?.detention_rate > 0 ?
          <RenderRTL
            title={`Detention Rate (Without GST)`}
            sep={"/"}
            urdutitle={"ڈیٹنشن ریٹ (GST کے بغیر)"}
            val={numeral(Number(item?.detention_rate)).format("0,0") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      } */}

      {
        loadDetail?.transaction_type?.title == "Offer" && !forward && item?.detention_additional_charges ? (
          <>
            <CustomText.OpenSansSemiBold
              textStyle={{ marginTop: 10, fontSize: "0.813rem" }}
            >
              <span>Other Additional Charges (if applicable)</span>
              {
                is_recipient &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">اضافی چارجز ( اگر لاگو ہوں )</span>
                </>
              }
            </CustomText.OpenSansSemiBold>
            <CustomText.OpenSansRegular>
              <span className="customMarginAdd">{item?.detention_additional_charges}</span>
            </CustomText.OpenSansRegular>
          </>
        ) : null
      }

      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk) && !forward &&
          loadDetail.quote_by == QUOTE_BY.weightVolume && loadDetail?.total_freight_ton > 0
          && !loadDetail?.is_unit_basis
          ?
          <RenderRTL
            title={`Detention Rate (Without GST)`}
            sep={"/"}
            urdutitle={"ڈیٹنشن ریٹ (GST کے بغیر)"}
            val={numeral(Number(item?.detention_rate)).format("0,0.00") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk) && loadDetail.quote_by == QUOTE_BY.weightVolume && loadDetail?.total_freight_ton > 0 && !loadDetail?.is_unit_basis ?
          <RenderRTL
            title={
              // `Freight Ton`
              "Total Weight"
            }
            sep={"/"}
            urdutitle={"کل وزن"}
            val={loadDetail?.total_freight_ton + " F.Ton"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk) && loadDetail.quote_by == QUOTE_BY.vehicle && item?.total_freight_ton > 0 ?
          <RenderRTL
            title={
              // `Freight Ton`
              "Total Weight"
            }
            sep={"/"}
            urdutitle={"کل وزن"}
            val={item?.total_freight_ton + " F.Ton"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        item?.is_loading_required ?
          <RenderRTL
            title={"Loading Required"}
            sep={"/"}
            urdutitle={"لوڈنگ"}
            val={"Yes"}
            is_recipient={is_recipient}
          />
          : null
      }

      {item?.is_loading_required &&
        item?.loading_requirements ?
        <RenderRTL
          title={"Specify Labour / Loading Requirement"}
          sep={"/"}
          urdutitle={"لوڈنگ درکار ہے۔"}
          val={item?.loading_requirements ? `${item?.loading_requirements || "--"}` : "N/A"}
          is_recipient={is_recipient}
        />
        : null
      }

      {
        item?.is_unloading_required ?
          <RenderRTL
            title={"Unloading Required"}
            sep={"/"}
            urdutitle={"ان لوڈنگ"}
            val={"Yes"}
            is_recipient={is_recipient}
          />
          : null
      }

      {item?.is_unloading_required &&
        item?.unloading_requirements ?
        <RenderRTL
          title={"Specify Labour / UnLoading Requirement"}
          sep={"/"}
          urdutitle={"ان لوڈنگ درکار ہے۔"}
          val={item?.unloading_requirements ? `${item?.unloading_requirements || "--"}` : "N/A"}
          is_recipient={is_recipient}
        />
        : null
      }



      {
        // (loadDetail?.load_type?.title == LOAD_TYPE.bulk) && 
        item?.is_loading_required && loadDetail?.transaction_type?.title == "Offer" && !forward ?
          <RenderRTL
            title={`Loading Price / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
            sep={"/"}
            urdutitle={`لوڈنگ ریٹ / ${loadDetail?.is_unit_basis ? "فی آئٹم" : "فی گاڑی"} (GST کے بغیر)`}
            val={loadDetail?.is_unit_basis ?
              numeral(Number(item?.loading_price)).format("0,0.00") + " PKR"
              :
              numeral(Number(item?.loading_price)).format("0,0") + " PKR"}
            is_recipient={is_recipient}
          />
          : null
      }

      {
        // (loadDetail?.load_type?.title == LOAD_TYPE.bulk) && 
        loadDetail?.transaction_type?.title == "Offer" && item?.is_unloading_required && !forward ?
          <RenderRTL
            title={`UnLoading Price / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
            sep={"/"}
            urdutitle={`ان لوڈنگ ریٹ / ${loadDetail?.is_unit_basis ? "فی آئٹم" : "فی گاڑی"} (GST کے بغیر)`}
            val={loadDetail?.is_unit_basis ?
              numeral(Number(item?.unloading_price)).format("0,0.00") + " PKR"
              :
              numeral(Number(item?.unloading_price)).format("0,0") + " PKR"}
            is_recipient={is_recipient}
          />
          : null
      }

      {
        loadDetail?.transaction_type?.title == "Offer" && !forward ?
          <RenderRTL
            title={`Total Price (Without GST)`}
            sep={"/"}
            urdutitle={"کل ریٹ (GST کے بغیر)"}
            val={
              loadDetail?.is_unit_basis ?
                numeral(Number(item?.total_offer_price)).format("0,0.00") + " PKR"
                :
                numeral(Number(item?.total_offer_price)).format("0,0") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        (item?.max_bid) && (item?.max_bid != 0) && loadDetail?.transaction_type?.title == "Bid" && loadDetail?.quote_by == QUOTE_BY.vehicle && !forward ?
          <RenderRTL
            title={`Max bid / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
            sep={"/"}
            urdutitle={`زیادہ سے زیادہ بولی / ${loadDetail?.is_unit_basis ? " آئٹم" : " فی گاڑی"} (GST کے بغیر)`}
            val={
              loadDetail?.is_unit_basis ?
                numeral(Number(item?.max_bid)).format("0,0.00") + " PKR"
                :
                numeral(Number(item?.max_bid)).format("0,0") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        (item?.max_bid) && (item?.max_bid != 0) && loadDetail?.transaction_type?.title == "Bid" && loadDetail?.quote_by == QUOTE_BY.weightVolume && loadDetail?.is_unit_basis && !forward ?
          <RenderRTL
            title={`Max bid / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
            sep={"/"}
            urdutitle={`زیادہ سے زیادہ بولی / ${loadDetail?.is_unit_basis ? " آئٹم" : " فی گاڑی"} (GST کے بغیر)`}
            val={loadDetail?.is_unit_basis ?
              numeral(Number(item?.max_bid)).format("0,0.00") + " PKR"
              :
              numeral(Number(item?.max_bid)).format("0,0") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        item?.unit_quantity ?
          <RenderRTL
            title={"Item Quantity"}
            sep={"/"}
            urdutitle={"آئٹم کی مقدار"}
            val={item?.unit_quantity}
            is_recipient={is_recipient}
          /> : null
      }
      {
        item?.unit_description ?
          <RenderRTL
            title={"Item Description"}
            sep={"/"}
            urdutitle={"آئٹم کی تفصیل"}
            val={item?.unit_description}
            is_recipient={is_recipient}
          /> : null
      }
    </div>
  );
};