import { useState } from 'react'
import { Accordion, AccordionSummary } from "@mui/material/";
import { Colors, Images } from "@temp/assets";
import { CustomModal, CustomText } from "@temp/components";
import React from "react";
import "../LoadDetailPoster/style.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import { fileIconDisplay, showMessage } from "@temp/utils/helper";
import moment from "moment";
import { Button, DialogTitle } from "@mui/material/";
import { LoadAction } from "@temp/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router';

export const UploadedDocumentsRecipient = ({ loadDetail, data, uploadedBy, is_poster, is_recipient, fromActive, activeLoadDetail }) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const params = useParams();
  
  
  
  
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState("");
  
  const [hasUploadedDocument, setHasUploadedDocument] = useState(false);
  const profile = useSelector((state) => state.Profile.profile);




  const handleRemoveDoc = () => {
    setShowRemoveModal(false);
    dispatch(LoadAction.removeDocument({
      upload_api: currentId,
      load_id: params?.load_id,
      job_load_driver_id: params?.job_load_driver_id,

    }));
  }

  // let id = data?.find(item => item?.id == item?.uploaded_by?.id)
  // 

  // let find_recipient = data?.find(recipt => recipt?.uploaded_by?.id == recipt?.id
  //   )
  // 
  return (

    <div className="accordion-container">
      <Accordion className="accordion-background">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <CustomText.OpenSansSemiBold
            textStyle={{
              fontSize: "0.875rem",
              color: Colors.Black,
            }}
          >
            <span className="titleName">
              {/* Documents Uploaded By {uploadedBy} */}
              {fromActive && !is_poster ? "Uploaded Documents" : ` Documents Uploaded By ${uploadedBy}`}
            </span>
            {
              is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">آپ کی طرف سے اپ لوڈ کردہ دستاویز</span>
              </>
            }
          </CustomText.OpenSansSemiBold>
        </AccordionSummary>
        <div className="accordion-content">
          {
            data?.length ?

              <div>
                {
                  data?.map((doc, index) => {

                    
                    
                    

                    return (
                      <div style={{
                        marginBottom: 15
                      }} key={index}>
                        {
                          !is_poster ?
                            <div
                              onClick={() => {
                                if (sendEventToAndroid("UploadedDocuments")) {
                                  // showMessage.current({
                                  //   message: "UploadedDocuments"
                                  // });
                                  window.Android.UploadedDocuments(
                                    doc?.path
                                  );
                                } else if (sendEventToiOS("UploadedDocuments")) {
                                  window.webkit.messageHandlers.UploadedDocuments.postMessage(
                                    JSON.stringify({
                                      document: doc?.path,
                                    })
                                  );
                                } else {
                                  window.open(doc?.path, "_blank");
                                }
                              }}
                              // href={doc?.path}
                              className="file-container-row"
                              style={{ alignItems: "flex-start" }}
                            >
                              <img
                                alt=""
                                style={{ height: 32, width: 24 }}
                                src={fileIconDisplay(doc?.mime_type)}
                              />

                              <div className="files-text" >
                                <div style={{ display: "flex" }}>
                                  <CustomText.OpenSansRegular textStyle={{
                                    width: "auto",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}>
                                    {`${doc?.file_name}`}
                                  </CustomText.OpenSansRegular>

                                  {profile?.id === doc?.uploaded_by?.id && loadDetail?.status_name !== "Completed" ? <span
                                    className="deleteIcon icons-cross customDeleteIcon"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      // setShowRemoveModal(true)
                                      setShowRemoveModal(loadDetail?.status_name !== "Completed" ?true :false)
                                      setCurrentId(doc?.id);
                                    }}> </span> : null

                                  }
                                </div>
                                {
                                  
                                }
                                {fromActive && !is_poster ?

                                  <CustomText.OpenSansRegular textStyle={{
                                    width: 280,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    marginBottom: "2px"
                                  }}>
                                    {moment(doc?.created_at).format('DD-MM-YYYY hh:mm A')}
                                    {/* {"20-09-2023 10:47 AM"} */}
                                  </CustomText.OpenSansRegular>

                                  : null}


                                {fromActive && !is_poster ?
                                  <>
                                    <CustomText.OpenSansSemiBold textStyle={{
                                      width: 280,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontSize: "0.75rem",
                                      color: "#868686",
                                      fontWeight: "normal"
                                    }}>
                                      Updated By
                                    </CustomText.OpenSansSemiBold>

                                    <CustomText.OpenSansRegular textStyle={{
                                      width: 280,
                                      /*whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"*/
                                    }}>
                                      {
                                        doc?.uploaded_by?.name
                                      }
                                    </CustomText.OpenSansRegular>
                                  </>
                                  : null}

                              </div>

                            </div> :
                            <div>
                              <p className="fw-5">{doc.length && doc[0]?.recipient?.name}</p>
                              {
                                doc?.map((item, index2) => {
                                  
                                  return (
                                    <div
                                      key={index2}
                                      onClick={() => {
                                        if (sendEventToAndroid("UploadedDocuments")) {
                                          // showMessage.current({
                                          //   message: "UploadedDocuments",
                                          // })
                                          window.Android.UploadedDocuments(
                                            item?.path
                                          );
                                        } else if (sendEventToiOS("UploadedDocuments")) {
                                          window.webkit.messageHandlers.UploadedDocuments.postMessage(
                                            JSON.stringify({
                                              document: item?.path,
                                            })
                                          );
                                        } else {
                                          window.open(item?.path, "_blank");
                                        }
                                      }}
                                      // href={doc?.path}
                                      style={{
                                        marginTop: 5
                                      }}
                                      className="file-container-row"
                                    >
                                      <img
                                        alt=""
                                        style={{ height: 32, width: 24 }}
                                        src={fileIconDisplay(item?.mime_type)}
                                      />

                                      <div className="files-text">
                                        <div style={{ display: "flex" }}>
                                          <CustomText.OpenSansRegular textStyle={{
                                            width: 280,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                          }}>
                                            {`${item?.file_name}`}
                                          </CustomText.OpenSansRegular>

                                          {profile?.id === doc?.uploaded_by?.id && loadDetail?.status_name !== "Completed"   ?
                                            <span
                                              className="deleteIcon icons-cross customDeleteIcon"
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                setShowRemoveModal(loadDetail?.status_name !== "Completed" ?true :false)
                                                setCurrentId(item?.id);
                                              }}> </span> : null
                                          }

                                        </div>

                                        {
                                          fromActive && !is_poster ?

                                            <CustomText.OpenSansRegular textStyle={{
                                              width: 280,
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis"
                                            }}>
                                              {moment(item?.created_at).format('DD-MM-YYYY hh:mm A')}
                                              {/* {"20-09-2023 10:47 AM"} */}
                                            </CustomText.OpenSansRegular>
                                            : null}

                                        {fromActive && !is_poster ?
                                          <>
                                            <CustomText.OpenSansSemiBold textStyle={{
                                              width: 280,
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              fontSize: "0.75rem",
                                              color: "#868686",
                                              fontWeight: "normal"
                                            }}>
                                              Updated By
                                            </CustomText.OpenSansSemiBold>

                                            <CustomText.OpenSansRegular textStyle={{
                                              width: 280,
                                              /*whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis"*/
                                            }}>
                                              {
                                                doc?.uploaded_by?.name
                                              }
                                            </CustomText.OpenSansRegular>
                                          </>
                                          : null}
                                      </div>
                                    </div>
                                  )
                                })
                              }
                              {
                                index !== data.length - 1 && (
                                  <div className="dividing-bordered" />
                                )
                              }
                            </div>
                        }
                      </div>
                    )
                  })
                }

              </div> :
              <div>
                <div>No documents uploaded</div>
              </div>
          }

        </div>
      </Accordion >

      <CustomModal
        showModal={showRemoveModal}
        toggleModal={setShowRemoveModal}
        modalStyle={{
          minHeight: 180
        }}
      >
        <div className="logout-modal-background">
          <DialogTitle id="simple-dialog-title">
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "1.25rem", textAlign: "center" }}
            >
              Remove Document
            </CustomText.OpenSansSemiBold>
            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Are you sure you want to remove document?
            </CustomText.OpenSansRegular>
            <div className="action-button-container" style={{ justifyContent: "center" }}>
              <Button
                onClick={() => {
                  setShowRemoveModal(false);
                }}
                style={{
                  backgroundColor: Colors.Grayish,
                  width: 96,
                  height: 40,
                  marginRight: "0.5rem"
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  NO
                </CustomText.OpenSansSemiBold>
              </Button>
              <Button
                onClick={() => {
                  handleRemoveDoc();
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  YES
                </CustomText.OpenSansSemiBold>
              </Button>
            </div>
          </DialogTitle>
        </div>
      </CustomModal>


    </div >
  );
};
