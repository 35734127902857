import React, { useEffect } from "react";
import { ITermsDrawerPageProps } from "./types";
import "./style.scss";
import rootReducer from "../../../store/reducers";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import TitleLayout from "@temp/components/TitleLayout";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<ITermsDrawerPageProps> = (props) => {
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const location = useLocation();
  const { state } = location

  useEffect(() => {
    const element = document.querySelector('.terms-condition-text');
    if (state?.from === "terms") {
      element.innerHTML = metaData.terms_conditions ? metaData?.terms_conditions : ''
    } else {
      element.innerHTML = metaData.policy ? metaData?.policy : ""
    }
  }, [metaData]);

  return (
    <TitleLayout titleOnly={true} titleOnlyText={state?.from === "terms" ? "Terms and conditions" : "Privacy Policy"}>
      <div className="terms-main-container">
      </div>
      <FadeInComponent>
        <div className="terms-condition-text"></div>
      </FadeInComponent>
    </TitleLayout>
  );
};

export default Page;