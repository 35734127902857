import React, { useState, useEffect } from "react";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { DrawerAction } from "@temp/store/actions";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { BackHeader, CustomText, PrimaryButton } from "@components";
import { Delete } from "@mui/icons-material";
import EmptyPlaceholder from "@temp/components/EmptyPlaceholder/EmptyPlaceholder";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { phoneNumberFormater } from "@temp/utils/helper";

const Page: React.FC = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const locationsData = useSelector((state: RootState) => state.Drawer.locationsData);
  const [locationType, setLocationType] = useState(
    // "pick"
    ""
    );
  const [searchLocation, setSearchLocation] = useState("");

  useEffect(() => {
    const type = props?.match?.params?.type 
    // || "pick"
    ;
    // setLocationType(String(type));
    dispatch(DrawerAction.GetSavedLocations());
  }, []);

  const getSearchedNetwork = (params) => {
    dispatch(DrawerAction.SearchNetwork(params));
  };

  const handleTabClick = (type: string) => {
    setLocationType(type);
  };

  return (
    <div className="savedLocationsWrapper">
      <BackHeader
        title="Saved Locations"
        onClick={() => {
          history.goBack();
        }}
      />
      {/* <div className="tabContainerMainWrap">
        <FadeInComponent>
          <div className="tab-btn radioTabs twoTab mb-3">
            <div
              className={`two-category-button ${locationType === "pick" ? 'active' : ''}`}
              onClick={() => {
                handleTabClick("pick");
              }}
            >
              Pickup Location
            </div>
            <div
              className={`two-category-button ${locationType === "drop" ? 'active' : ''}`}
              onClick={() => {
                handleTabClick("drop");
              }}
            >
              Drop-off Location
            </div>
            <div className="radioTab_slider"></div>
          </div>
        </FadeInComponent>
      </div> */}
      
      <div className="locationsMainContentWrap">
        <FadeInComponent>
          <div className={`searchContainer ${searchLocation ? "filled" : ""}`}>
            <input
              className="searchInput"
              onChange={(e) => setSearchLocation(e.target.value)}
              type="text"
              autoComplete="off"
              name="search"
              style={{ width: "100%" }}
              value={searchLocation}
              placeholder="Search detail address"
            />
            <span className={`icons-search ${searchLocation ? '' : 'active'}`}></span>
            <span
              className={`icons-cross ${searchLocation ? 'active' : ''}`}
              onClick={() => {
                setSearchLocation("");
                getSearchedNetwork("");
              }}
            ></span>
          </div>
        </FadeInComponent>

        <div className="locationCardWrapper">
          {
          (
            // locationType === "pick"
            // ? 
            locationsData.filter(
              (item) =>
                // item?.type == "pickup" &&
                item?.raw_address
                  .toLowerCase()
                  ?.includes(searchLocation.toLowerCase())
            )
            // : locationsData.filter(
            //   (item) =>
            //     item?.type === "dropoff" &&
            //     item?.raw_address
            //       .toLowerCase()
            //       ?.includes(searchLocation.toLowerCase())
            // )
          )?.map((item, index) => {
            return (
              <FadeInComponent>
                <div className="icon-style">
                  <LocationOnIcon />
                  <div className="details">
                    <CustomText.OpenSansSemiBold
                      textStyle={{
                        fontSize: "0.75rem",
                        color: Colors.Black,
                      }}
                    >
                      {item?.title}
                    </CustomText.OpenSansSemiBold>
                    <CustomText.OpenSansRegular
                      textStyle={{
                        fontSize: "0.75rem",
                        color: Colors.Grayish,
                      }}
                    >
                      {item.raw_address}
                    </CustomText.OpenSansRegular>
                    {item.contact_name ?
                      <div className="custDetails mtopMar">
                        <span className="labelWrap">Contact Name:</span>
                        <div className="custTextWrap">

                          <CustomText.OpenSansRegular
                            textStyle={{
                              fontSize: "0.75rem",
                              color: Colors.Black,
                            }}
                          >
                            {item.contact_name}
                          </CustomText.OpenSansRegular>

                        </div>
                      </div>
                      : null}
                    {item?.contact_phone_no ?
                      <div className="custDetails">
                        <span className="labelWrap">Contact Number:</span>
                        <div className="custTextWrap">
                          <CustomText.OpenSansRegular
                            textStyle={{
                              fontSize: "0.75rem",
                              color: Colors.Black,
                            }}
                          >
                            {
                              phoneNumberFormater(item?.contact_phone_code,item?.contact_phone_no)
                            }
                          </CustomText.OpenSansRegular>
                        </div>
                      </div>
                      : null}

                  </div>
                  <Delete
                    onClick={() => {
                      dispatch(DrawerAction.deleteLocation(item.id));
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </FadeInComponent>
            );
          })}


          {
            // locationType == "pick" ?
              <>
                {
                  locationsData.filter((item) => 
                  // item?.type == "pickup" && 
                  item?.raw_address?.toLowerCase()?.includes(searchLocation?.toLowerCase())).length ? null :
                    <EmptyPlaceholder
                      image={Images.savedLocationsNew}
                      title="No Locations Added!"
                      detail="You have no location added, Please add location."
                    />
                }
              </> 
              // :
              // <>
              //   {
              //     locationsData.filter((item) => item?.type == "dropoff" && item?.raw_address?.toLowerCase()?.includes(searchLocation?.toLowerCase())).length ? null :
              //       <EmptyPlaceholder
              //         image={Images.savedLocationsNew}
              //         title="No Locations Added!"
              //         detail="You have no location added, Please add location."
              //       />
              //   }
              // </>

          }
        </div>

        <FadeInComponent>
          <div className="button-primary">
            <PrimaryButton
              title="Add Location"
              onClick={() => {
                history.push(
                  "/add-location/" 
                  // + (locationType 
                  //   === "pick" ? 0 : 1
                  //   )
                );
              }}
            />
          </div>
        </FadeInComponent>
      </div>
    </div>
  );
};

export default Page;
