import React, { useEffect, useState } from "react";
import { IForwardLoadPageProps } from "./types";
import "./style.scss";
import {
  CustomText,
  PrimaryButton,
  TitleLayout,
  MaterialUIPickers,
  SimpleInput,
  NewInput,
  CustomModal,
  TextInput,
  SimpleCheckBox,
  RadioButton,
  CustomizedSelects,
  SimpleModal,
  CustomizedSwitches,
  VehicleIncDec
} from "@components";
import {
  Accordion, AccordionSummary,
  //  withStyles,
  Grid
} from "@mui/material/";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import { LoadAction, ProfileAction } from "@temp/store/actions";
import { getMillisec, showMessage } from "@temp/utils/helper";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { CategoryCargoHeading, RenderRTL, ThreeColumnRTL, TwoColumnRTLVehicle, TwoColumnRTLWeight, } from "../components";
import { Counter } from "@temp/components/Counter";
import _ from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import FormControlLabel from "@mui/material//FormControlLabel";
import { MenuItem, Select } from "@mui/material/";
import Checkbox from "@mui/material//Checkbox";
import { DesktopAccessDisabledSharp } from "@mui/icons-material";
import { LOAD_TYPE } from "@temp/constants";
import { RenderFields } from "./renderFields"; import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import { isBrowser } from "react-device-detect";
import AudioAnalyser from "react-audio-analyser";
import MicIcon from "@mui/icons-material/Mic";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { ItemFields } from "./itemFields";
import { VehicleCounters } from "./vehicleCounters";
import { ExtraDetils } from "./extraDetails";
import ReactAudioPlayer from "react-audio-player";
import dayjs from "dayjs";
var numeral = require("numeral");

const SwitchableWithText = (props: any) => {
  return (
    <Grid
      container
      spacing={1}
      style={{ marginBottom: 8, alignItems: "center" }}
    >
      <Grid
        item
        xs={9}
        style={{ display: "flex" }}
      >
        {props?.icon ? (
          <img
            src={props?.icon}
            style={{ marginRight: 5 }}
            width="16px"
            height="16px"
          />
        ) : (
          ""
        )}
        <div style={{ marginTop: 0 }}>{props?.text}</div>
      </Grid>

      <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginTop: 0 }}>
          <CustomizedSwitches {...props} />
        </div>
      </Grid>
    </Grid>
  );
};

// const GreenCheckbox = withStyles({
//   root: {
//     color: "#7AC251",
//     "&$checked": {
//       color: "#7AC251",
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);

const deadline_date_time = {
  pickup_date: "",
  pickup_time: "",
  deadline_date: "",
  deadline_time: "",
  delivery_date: "",
  delivery_time: "",
  empty_return_date: "",
  empty_return_time: "",
  rate_validity_date: ""
}

let timmerRef_weight_inc = null;
let timmerRef_weight_dec = null;

const Page: React.FC<IForwardLoadPageProps> = (value, index) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;

  const PostLoadMeta = useSelector((state: RootState) => state.Profile.postLoadMeta);

  const [new_load_detail, set_new_load_detail] = useState(state?.loadDetail)
  const [pickup_data, set_pickup_data] = useState(state?.loadDetail?.locations[0])
  const [dropoff_data, set_dropoff_data] = useState(state?.loadDetail?.locations[1])

  const [expand_poster_detail, set_expand_poster_detail] = useState(true)
  const [expand_location_detail, set_expand_location_detail] = useState(false)
  const [expand_payment_terms, set_expand_payment_terms] = useState(false)
  const [expand_additional, set_expand_additional] = useState(false)
  const [transaction_accordion, set_transaction_accordion] = useState(false)
  const [isError, setIsError] = React.useState(false);
  const [isDateError, setIsDateError] = useState(false);
  const [dateErrorMessage, SetDateErrorMessage] = useState("")
  const [showInfoWaterMark, setShowInfoWaterMark] = useState("");
  const [showModalWaterMark, setShowModalWaterMark] = useState(false);
  const [isDeadlineComplete, setIsDeadlineComplete] = useState(false);
  const [info, setInfo] = useState("");
  const [showModal, toggleModal] = useState(false);
  const currencies = [];
  const [total_containers, set_total_containers] = useState(0)
  const [total_vehicles, set_total_vehicles] = useState(0)
  const [total_weight, set_total_weight] = useState(0)
  const [isGeneralError, setIsGeneralError] = useState(false)

  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [status, controlAudio] = useState('')
  const [recordLimit, setRecordLimit] = useState(59)
  const [isErrorTax, setIsErrorTax] = React.useState(false);

  // const [record_url, set_record_url] = useState

  const divStyle = {
    marginBottom: "0.625rem"
  };
  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isIOS17 = /iPad|iPhone|iPod/.test(userAgent) && /OS 17_/.test(userAgent);

    if (isIOS17) {
      setRecordLimit(9)
    }
  }, [])

  useEffect(() => {
    let intervalId;
    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);
        setCounter((counter) => counter + 1);

      }, 900);
    }

    return () => clearInterval(intervalId)

  }, [isActive, counter]);

  const stopTimer = () => {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");

  };

  var cb = (payload: any) => {
    // set_record_url(payload)
    set_new_load_detail({
      ...new_load_detail,
      instructions_voice_note: payload?.cloudinary_url,
      instructions_voice_note_object: payload
    })
  };

  const setUrlData = (data) => {
    let new_payload = {
      file_name: data?.fileName,
      cloudinary_url: data?.cloudinaryUrl,
      file_url: data?.fileUrl,
      mime_type: data?.mimeType
    }
    // set_record_url(new_payload)
    set_new_load_detail({
      ...new_load_detail,
      instructions_voice_note: new_payload.cloudinary_url,
      instructions_voice_note_object: new_payload
    })
  }

  useEffect(() => {
    const target = message => {
      setUrlData(JSON.parse(message?.detail))
    }
    const target2 = message => {
      // closeAccordion()
    }
    window.addEventListener("pdfDownloadComplete", target2);
    window.addEventListener("VoiceRecordComplete", target);
    return () => {
      if (!isBrowser) {
        if (sendEventToAndroid("closeRecording")) {
          window.Android.closeRecording(
            String("close")
          );
        } else if (sendEventToiOS("closeRecording")) {
          window.webkit.messageHandlers.closeRecording.postMessage(
            JSON.stringify({
              close: "close",
            })
          );
        } else {
        }
      }
      window.removeEventListener("VoiceRecordComplete", (e) => { })
      window.removeEventListener("pdfDownloadComplete", (e) => { })
      controlAudio('inactive')
      stopTimer()
    }
  }, [])

  useEffect(() => {
    if (second >= recordLimit) {
      controlAudio("inactive")
      stopTimer()
      if (!isBrowser) {
        if (sendEventToAndroid("voiceRecordStop")) {
          window.Android.voiceRecordStop(
            String("stop")
          );
        } else if (sendEventToiOS("voiceRecordStop")) {
          window.webkit.messageHandlers.voiceRecordStop.postMessage(
            JSON.stringify({
              start: "stop",
            })
          );
        } else {
        }
      }
    }
  }, [second])

  const [deadline_dates, set_deadline_dates] = useState(deadline_date_time)


  const [additional_pickups, set_additional_pickups] = useState(state?.loadDetail?.additional_pickups)
  const [additional_dropoffs, set_additional_dropoffs] = useState(state?.loadDetail?.additional_dropoffs)

  useEffect(() => {
    if (pickup_data?.title == "" || dropoff_data?.title == "") {
      setIsGeneralError(true)
    } else {
      setIsGeneralError(false)
    }
  }, [pickup_data, dropoff_data])

  useEffect(() => {
    set_deadline_dates({
      pickup_date: state?.loadDetail?.pickup_date,
      pickup_time: state?.loadDetail?.pickup_time ? `${dayjs(state?.loadDetail?.pickup_date)} ${dayjs(state?.loadDetail?.pickup_time)}` : null,
      deadline_date: state?.loadDetail?.deadline_at,
      deadline_time: dayjs(state?.loadDetail?.deadline_at),
      delivery_date: state?.loadDetail?.delivery_at ? state?.loadDetail?.delivery_at : null,
      delivery_time: state?.loadDetail?.delivery_at ? dayjs(state?.loadDetail?.delivery_at) : null,
      empty_return_date: state?.loadDetail?.empty_return_deadline_at ? state?.loadDetail?.empty_return_deadline_at : null,
      empty_return_time: state?.loadDetail?.empty_return_deadline_at ? dayjs(state?.loadDetail?.empty_return_deadline_at) : null,
      rate_validity_date: state?.loadDetail?.rate_validity_date
    })
  }, [])

  const [package_type_validation, set_package_type_validation] = useState(false)
  const [package_type_validation2, set_package_type_validation2] = useState(false)
  const [gross_weight_validation, set_gross_weight_validation] = useState(false)
  const [max_vehicle_weight, set_max_vehicle_weight] = useState(false)
  const [vehicle_quantity, set_vehicle_quantity] = useState(false)


  const audioProps = {
    audioType: "audio/wav",
    status,
    timeslice: 1000,
    startCallback: e => {
    },
    pauseCallback: e => {
    },
    stopCallback: e => {
      const formdata = new FormData()
      formdata.append('file', e)
      dispatch(ProfileAction.uploadFile({ data: formdata, cb }))
    },
    onRecordCallback: e => {
    },
    errorCallback: err => {
    }
  };

  useEffect(() => {
    if (state?.loadDetail?.categories.length) {
      let containers = 0
      let vehicles = 0
      // let total = 0
      let data = []
      let data2 = []
      new_load_detail?.categories?.forEach((cat) => {
        if (cat.is_checked) {
          containers = cat?.is_twin_load ? containers + Number(cat?.vehicle_quantity) * 2 : containers + Number(cat?.vehicle_quantity)
          vehicles = vehicles + Number(cat?.vehicle_quantity)
          if (cat?.package_type?.title == "Other") {
            data.push(cat)
          }
          data2.push(cat)
        }
      })
      set_total_containers(containers)
      set_total_vehicles(vehicles)
      // set_new_load_detail({...new_load_detail})
      let check = new_load_detail?.categories?.filter?.((item) => item?.is_checked)?.filter((item) => item?.package_type_other)
      let check_max_cargo_weight = new_load_detail?.categories?.filter?.((item) => item?.is_checked)?.filter((item) => item?.max_vehicle_weight > 0)
      let check_vehicle_quantity = new_load_detail?.categories?.filter?.((item) => item?.is_checked)?.filter((item) => item?.vehicle_quantity > 0)
      if (check.length !== data.length) {
        set_package_type_validation(true)
      } else {
        set_package_type_validation(false)
      }
      if (!state?.loadDetail?.is_unit_basis) {
        if (data2.length !== check_max_cargo_weight.length || data2.length !== check_vehicle_quantity.length) {
          set_max_vehicle_weight(true)
          set_vehicle_quantity(true)
        } else {
          set_max_vehicle_weight(false)
          set_vehicle_quantity(false)
        }
      }

    }

    if (new_load_detail?.load_type?.title !== "Containerized") {
      if (new_load_detail?.is_under_hook_delivery_required) {
        if (new_load_detail?.under_hook_delivery?.length) {
          setIsGeneralError(false)
        } else {
          setIsGeneralError(true)
        }
      } else {
        setIsGeneralError(false)
      }
      if (!new_load_detail?.categories.length) {
        if (new_load_detail?.price_unit == "Liter") {
          set_total_weight(new_load_detail?.cargo_liter)
          if (new_load_detail?.gross_weight > 0 && new_load_detail?.cargo_liter > 0) {
            set_gross_weight_validation(false)
          } else {
            set_gross_weight_validation(true)
          }
        } else if (new_load_detail?.price_unit == "F.Ton" || new_load_detail?.price_unit == "CBM") {
          if (new_load_detail?.price_unit == "F.Ton") {
            set_total_weight(new_load_detail?.total_freight_ton)
          } else {
            set_total_weight(new_load_detail?.gross_weight)
          }
          if (new_load_detail?.gross_weight > 0 && new_load_detail?.cargo_volume > 0) {
            set_gross_weight_validation(false)
          } else {
            set_gross_weight_validation(true)
          }
        } else if (new_load_detail?.price_unit == "Ton" || new_load_detail?.price_unit == "Kg") {
          if (new_load_detail?.price_unit == "Kg") {
            set_total_weight(Number(new_load_detail?.gross_weight) * 1000)
          } else {
            set_total_weight(Number(new_load_detail?.gross_weight))
          }
          if (new_load_detail?.gross_weight > 0) {
            set_gross_weight_validation(false)
          } else {
            set_gross_weight_validation(true)
          }
        }
      }
    }
    // if (state?.loadDetail?.load_type?.title == LOAD_TYPE?.bulk && state?.loadDetail?.quote_by == "weight_volume") {
    //   set_total_weight(new_load_detail?.total_freight_ton)
    // }
  }, [new_load_detail])


  const getMillisec = (t1, t2) => {
    let new_date = moment(`${dayjs(t1).format('YYYY-MM-DD')} ${dayjs(t2).format('HH:mm:ss')}`).format('x')
    return Number(new_date)
  }

  useEffect(() => {
    if (getMillisec(deadline_dates?.deadline_date, deadline_dates?.deadline_time) < new Date().getTime() + 5000) {
      setIsDateError(true)
      SetDateErrorMessage("Response deadline should be greater than current time.")
      return
    } else {
      setIsDateError(false)
      SetDateErrorMessage("")
    }

    if (getMillisec(deadline_dates?.deadline_date, deadline_dates?.deadline_time) < new Date(state?.loadDetail?.deadline_at).getTime()) {
      setIsDateError(false)
      SetDateErrorMessage("")
    } else {
      setIsDateError(true)
      SetDateErrorMessage("Response deadline should be less than deadline set by poster.")
      return
    }
    if (deadline_dates?.pickup_date && deadline_dates?.pickup_time && deadline_dates?.deadline_date && deadline_dates?.deadline_time) {
      if (
        getMillisec(deadline_dates?.pickup_date, deadline_dates?.pickup_time) <=
        getMillisec(deadline_dates?.deadline_date, deadline_dates?.deadline_time)
      ) {
        setIsDateError(true)
        SetDateErrorMessage("Pickup deadline should be greater than response deadline.")

        return
      } else {
        setIsDateError(false)
        SetDateErrorMessage("")
      }
    }
    if (deadline_dates?.pickup_date && deadline_dates?.pickup_time && deadline_dates?.delivery_date && deadline_dates?.delivery_time) {
      if (

        getMillisec(deadline_dates?.pickup_date, deadline_dates?.pickup_time) >=
        getMillisec(deadline_dates?.delivery_date, deadline_dates?.delivery_time)
        // getMillisec(deadline_dates?.delivery_date, deadline_dates?.delivery_time) <=
        // getMillisec(deadline_dates?.pickup_date, deadline_dates?.pickup_time)
      ) {
        setIsDateError(true)
        SetDateErrorMessage("Delivery deadline should be greater than pickup time.")

        return
      } else {
        setIsDateError(false)
        SetDateErrorMessage("")
      }
    }
    if (deadline_dates?.pickup_date && deadline_dates?.delivery_date && (!deadline_dates?.pickup_time || !deadline_dates?.delivery_time)) {
      if (deadline_dates?.pickup_date > deadline_dates?.delivery_date
      ) {
        setIsDateError(true)
        SetDateErrorMessage("Delivery deadline should be greater than pickup deadline.")
      }
      else {
        setIsDateError(false)
        SetDateErrorMessage("")
      }
    }
  }, [deadline_dates])


  for (let i = 1; i <= 60; i++) {
    if (i === 1) {
      currencies.push({
        value: `${i}`,
        label: `${i == 1 ? `${i} Day` : `${i} Days`}`,
      });
    }
    if (i % 5 === 0)
      currencies.push({
        value: `${i}`,
        label: `${i == 1 ? `${i} Day` : `${i} Days`}`,
      });
  }

  const [payment_values, set_payment_values] = React.useState({
    pt_is_advance: state?.loadDetail?.pt_is_advance,
    pt_advance_value: state?.loadDetail?.pt_advance_value,
    pt_is_ondelivery: state?.loadDetail?.pt_is_ondelivery,
    pt_ondelivery_value: state?.loadDetail?.pt_ondelivery_value,
    pt_is_credit: state?.loadDetail?.pt_is_credit,
    pt_credit_percentage: state?.loadDetail?.pt_credit_percentage,
    pt_credit_days: state?.loadDetail?.pt_credit_days,
    is_pt_fuel_card: state?.loadDetail?.pt_fuel_card,
    pt_fuel_card_value: state?.loadDetail?.pt_fuel_card_value,
    is_gst_inclusive: state?.loadDetail?.is_gst_inclusive,
    is_withholding_tax_inclusive: state?.loadDetail?.is_withholding_tax_inclusive,
    sales_tax_province: state?.loadDetail?.sales_tax_province,
  });

  React.useEffect(() => {
    checkShouldBeHundred();
    handleDisableTax()

  }, [payment_values]);


  const checkShouldBeHundred = () => {
    const {
      pt_advance_value,
      pt_ondelivery_value,
      pt_credit_percentage,
      pt_is_advance,
      pt_is_ondelivery,
      pt_is_credit,
    } = payment_values;
    if (
      Number(pt_is_advance ? pt_advance_value : 0) +
      Number(pt_is_ondelivery ? pt_ondelivery_value : 0) +
      Number(pt_is_credit ? pt_credit_percentage : 0) ===
      100
    ) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const handleDisableTax = () => {
    if (payment_values?.is_gst_inclusive == true &&
      (payment_values?.sales_tax_province === null || payment_values?.sales_tax_province === "")
    ) {
      setIsErrorTax(true);
    } else {
      setIsErrorTax(false);
    }
  }

  const handleChangeTextValue = (event) => {
    set_payment_values({ ...payment_values, [event.target.name]: event.target.value });
    checkShouldBeHundred();
  };

  const handleChangeSalesTaxProvince = (event) => {
    set_payment_values({ ...payment_values, [event.target.name]: event.target.value })
  }

  const handleChange = (event) => {
    set_payment_values({ ...payment_values, [event.target.name]: event.target.checked });
    checkShouldBeHundred();
  };

  useEffect(() => {
    dispatch(
      LoadAction.getForwardLoadDetail({
        load_id: params?.load_id,
        cat_id: params?.cat_id,
      })
    );
  }, []);
  const loadDetail = useSelector(
    (state: RootState) => state.Load.forwardLoadData
  );


  // const getCity = (formatted_address: any) => {
  //   var itemLocality = null;

  //   if (formatted_address?.length) {
  //     formatted_address.forEach((address: any) => {
  //       if (address.types[0] == "locality") {
  //         itemLocality = address.long_name;
  //       }
  //     });
  //   }
  //   return itemLocality;
  // };

  // // pickup
  // const handleAutoComplete = (e) => {
  //   set_pickup_data({
  //     title: pickup_data?.title,
  //     lat: null,
  //     long: null,
  //     raw_address: e.target.value,
  //     city: null,
  //     model_name: 'user',
  //     type: 'pickup'
  //   })
  //   var element = document.getElementById("map-address") as HTMLInputElement;

  //   if (element) {
  //     const options = {
  //       componentRestrictions: { country: "pk" },
  //       // fields: ["address_components", "formatted_address", "geometry"],
  //       fields: ["address_components", "geometry", "icon", "name"],
  //       strictBounds: false,
  //       types: ["establishment"],
  //     };
  //     const autocomplete = new window.google.maps.places.Autocomplete(
  //       element,
  //       options
  //     );
  //     autocomplete.addListener("place_changed", () => {
  //       const place = autocomplete.getPlace();
  //       // let new_address = autocomplete?.gm_accessors_?.place?.Fi?.formattedPrediction;
  //       // let new_address = place?.formatted_address
  //       const new_address = document.querySelector(`#map-address`)?.value;

  //       if (place) {
  //         getCity(place);
  //       }
  //       if (!place.geometry || !place.geometry.location) {
  //         return;
  //       } else {
  //         var coordinates = {
  //           lat: place.geometry.location.lat(),
  //           lng: place.geometry.location.lng(),
  //         };
  //         const lat = coordinates?.lat;
  //         const lng = coordinates?.lng;
  //         const raw_address = new_address;
  //         const city = getCity(place.address_components);
  //         set_pickup_data({
  //           title: pickup_data?.title,
  //           lat,
  //           long: lng,
  //           raw_address,
  //           city,
  //           model_name: "user",
  //           type: "pickup",
  //         });
  //         // set_additional_pickups({
  //         //   le: additional_pickups?.title,
  //         //   lat,
  //         //   long: lng,
  //         //   raw_address,
  //         //   city,
  //         //   model_name: "user",
  //         //   type: 'pickup_address'

  //         // });
  //       }
  //     });
  //   }
  // };

  // //dropoff
  // const handleAutoCompleteDrop = (e) => {
  //   set_dropoff_data({
  //     title: dropoff_data?.title,
  //     lat: null,
  //     long: null,
  //     raw_address: e.target.value,
  //     city: null,
  //     model_name: 'user',
  //     type: "dropoff",
  //   })
  //   var element = document.getElementById("map-address-drop") as HTMLInputElement;
  //   if (element) {
  //     const options = {
  //       componentRestrictions: { country: "pk" },
  //       // fields: ["address_components", "formatted_address", "geometry"],
  //       fields: ["address_components", "geometry", "icon", "name"],
  //       strictBounds: false,
  //       types: ["establishment"],
  //     };

  //     const autocomplete = new window.google.maps.places.Autocomplete(
  //       element,
  //       options
  //     );

  //     autocomplete.addListener("place_changed", () => {
  //       const place = autocomplete.getPlace();
  //       // let new_address = autocomplete?.gm_accessors_?.place?.Fi?.formattedPrediction;
  //       // let new_address = place?.formatted_address
  //       const new_address = document.querySelector(`#map-address-drop`)?.value;

  //       if (place) {
  //         getCity(place);
  //       }
  //       if (!place.geometry || !place.geometry.location) {
  //         return;
  //       } else {

  //         var coordinates = {
  //           lat: place.geometry.location.lat(),
  //           lng: place.geometry.location.lng(),
  //         };
  //         const lat = coordinates?.lat;
  //         const lng = coordinates?.lng;
  //         const raw_address = new_address;
  //         const city = getCity(place.address_components);
  //         set_dropoff_data({
  //           title: dropoff_data?.title,
  //           lat,
  //           long: lng,
  //           raw_address,
  //           city,
  //           model_name: "user",
  //           type: "dropoff",
  //         });
  //       }
  //     });
  //   }
  // };

  // pickup and dropoff city

  const getCity = (addressComponents) => {
    for (let i = 0; i < addressComponents.length; i++) {
      for (let j = 0; j < addressComponents[i].types.length; j++) {
        if (addressComponents[i].types[j] === "locality") {
          return addressComponents[i].long_name;
        }
      }
    }
    return null;
  };

  const handleAutoComplete = (e) => {
    set_pickup_data({
      title: pickup_data?.title,
      lat: null,
      long: null,
      raw_address: e.target.value,
      city: null,
      model_name: 'user',
      type: 'pickup',
    });

    var element = document.getElementById("map-address") as HTMLInputElement;
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const new_address = document.querySelector(`#map-address`)?.value;
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        const coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        const lat = coordinates?.lat;
        const lng = coordinates?.lng;
        const raw_address = new_address;
        const city = getCity(place.address_components);

        set_pickup_data({
          title: pickup_data?.title,
          lat,
          long: lng,
          raw_address,
          city,
          model_name: "user",
          type: "pickup",
        });
      });
    }
  };

  const handleAutoCompleteDrop = (e, data) => {
    set_dropoff_data({
      title: dropoff_data?.title,
      lat: null,
      long: null,
      raw_address: e.target.value,
      city: null,
      model_name: 'user',
      type: "dropoff",
    });

    var element = document.getElementById("map-address-drop") as HTMLInputElement;
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const new_address = document.querySelector(`#map-address-drop`)?.value;
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        const coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        const lat = coordinates?.lat;
        const lng = coordinates?.lng;
        const raw_address = new_address;
        const city = getCity(place.address_components);

        set_dropoff_data({
          title: dropoff_data?.title,
          lat,
          long: lng,
          raw_address,
          city,
          model_name: "user",
          type: "dropoff",
        });
      });
    }
  };

  // pickup
  const updateAdditionalPickup = (e, location, index) => {


    var element = document.getElementById(`map-address${index}`) as HTMLInputElement;
    let temp_obj = {
      ...location,
      lat: null,
      lng: null,
      raw_address: e.target.value
    }
    let change_data = [...additional_pickups]
    change_data[index] = temp_obj
    set_additional_pickups(change_data)

    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        // fields: ["address_components", "formatted_address", "geometry"],
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["establishment"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place) {
          getCity(place);
        }
        // let new_address = autocomplete?.gm_accessors_?.place?.Fi?.formattedPrediction;
        const new_address = document.querySelector(`#map-address${index}`)?.value;
        if (!place.geometry || !place.geometry.location) {
          return;
        } else {
          var coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          const lat = coordinates?.lat;
          const lng = coordinates?.lng;
          const raw_address = new_address;
          let temp_obj_2 = {
            ...location,
            lat: lat,
            lng: lng,
            raw_address: raw_address,
          }
          let change_data_2 = [...additional_pickups]
          change_data_2[index] = temp_obj_2
          set_additional_pickups(change_data_2)
        }
      });
    }
  };

  //dropoff
  const updateAdditionalDropoff = (e, location, index) => {
    var element = document.getElementById(`map-address-drop${index}`) as HTMLInputElement;
    let temp_obj = {
      ...location,
      lat: null,
      lng: null,
      raw_address: e.target.value
    }
    let change_data = [...additional_dropoffs]
    change_data[index] = temp_obj
    set_additional_dropoffs(change_data)
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        // fields: ["address_components", "formatted_address", "geometry"],
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["establishment"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place) {
          getCity(place);
        }
        // let new_address = autocomplete?.gm_accessors_?.place?.Fi?.formattedPrediction;
        const new_address = document.querySelector(`#map-address-drop${index}`)?.value;

        if (!place.geometry || !place.geometry.location) {
          return;
        } else {
          var coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          const lat = coordinates?.lat;
          const lng = coordinates?.lng;
          const raw_address = new_address;
          let temp_obj_2 = {
            ...location,
            lat: lat,
            lng: lng,
            raw_address: raw_address,
          }
          let change_data_2 = [...additional_dropoffs]
          change_data_2[index] = temp_obj_2
          set_additional_dropoffs(change_data_2)
        }
      });
    }
  };

  const handleDisableItemBasis = () => {
    if (new_load_detail?.is_unit_basis) {

      let seletect_cats = new_load_detail?.categories?.filter((item) => item?.is_checked)
      let filled_cats = new_load_detail?.categories?.filter((item) => item?.is_checked)?.filter((val) => val?.gross_weight && val?.cargo_volume && val?.dimensions !== "" && val?.unit_dimension_value !== "Select Unit")

      return seletect_cats.length == filled_cats.length ? false : true
    } else {
      return false
    }
  }

  const handleDisable = () => {
    if (new_load_detail?.categories.length) {
      if (new_load_detail?.transaction_type?.title == "Offer") {
        return new_load_detail?.categories?.filter((item) => item?.is_checked)?.filter(
          (val) => (val.is_loading_required &&
            (Number(val.loading_price) === 0 || val.loading_price === '')) ||
            (val.is_unloading_required &&
              (Number(val.unloading_price) === 0 || val.unloading_price === "")) ||
            Number(val.offer_price_per_vehicle) === 0 ||
            val.offer_price_per_vehicle === "" && val?.vehicle_quantity == 0 || val?.vehicle_quantity == ""
        ).length
          ? true
          : false;
      } else {
        return new_load_detail?.categories.length ? false : true
      }
    } else {
      if (new_load_detail?.transaction_type?.title == "Offer") {
        if (new_load_detail?.is_loading_required && new_load_detail?.is_unloading_required) {
          return (new_load_detail?.price_per_freight > 0 && new_load_detail?.loading_price_per_freight > 0 && new_load_detail?.unloading_price_per_freight > 0) ? false : true
        } else if (new_load_detail?.is_loading_required && !new_load_detail?.is_unloading_required) {
          return (new_load_detail?.price_per_freight > 0 && new_load_detail?.loading_price_per_freight > 0) ? false : true
        } else if (new_load_detail?.is_unloading_required && !new_load_detail?.is_loading_required) {
          return (new_load_detail?.price_per_freight > 0 && new_load_detail?.unloading_price_per_freight > 0) ? false : true
        } else if (new_load_detail?.transaction_type?.title == "Offer") {
          return (new_load_detail?.price_per_freight > 0) ? false : true
        }
      } else {
        return false
      }
    }

  }

  const handleContinue = () => {
    let deadline_date = moment(deadline_dates?.deadline_date).format("YYYY-MM-DD")
    // let deadline_time = moment(deadline_dates?.deadline_time).format("HH:mm:ss")
    let deadline_time = typeof (deadline_dates?.deadline_time) === 'string' ? deadline_dates?.deadline_time : moment(new Date(deadline_dates?.deadline_time)).format('HH:mm:ss')


    let delivery_date = moment(deadline_dates?.delivery_date).format("YYYY-MM-DD")
    let delivery_time = moment(deadline_dates?.delivery_time).format("HH:mm:ss")

    let empty_return_date = moment(deadline_dates?.empty_return_date).format("YYYY-MM-DD")
    let empty_return_time = moment(deadline_dates?.empty_return_time).format("HH:mm:ss")

    if (new_load_detail?.transaction_type?.title == "Bid") {
      if (new_load_detail?.categories.length) {
        let category_data = new_load_detail?.categories.map((item, index) => {
          return {
            ...item,
            offer_price_per_vehicle: null,
            detention_rate: null,
            loading_price: null,
            unloading_price: null,
            detention_additional_charges: null,
          }
        })
        let detail = {
          ...new_load_detail,
          categories: category_data?.filter((item) => item?.is_checked),
          // deadline_at: `${deadline_date} ${deadline_time}`,
          deadline_at: `${deadline_date} ${deadline_time && typeof (deadline_time) === 'string' ? deadline_time : moment(new Date(deadline_time)).format('HH:mm:ss')}`,
          pickup_date: moment(deadline_dates.pickup_date).format('YYYY-MM-DD'),
          // pickup_time: moment(deadline_dates?.pickup_time).format('HH:mm:ss'),
          pickup_time: deadline_dates?.pickup_time ? typeof (deadline_dates?.pickup_time) === 'string' ? deadline_dates?.pickup_time : moment(new Date(deadline_dates?.pickup_time)).format('HH:mm:ss') : null,
          // delivery_at: deadline_dates?.delivery_date && deadline_dates?.delivery_time ?
          //   `${delivery_date} ${delivery_time}` : deadline_dates?.delivery_date ?
          //     delivery_date : deadline_dates?.delivery_time ?
          //       delivery_time : null,
          delivery_at: deadline_dates?.delivery_date && deadline_dates?.delivery_time ?
            `${delivery_date} ${typeof (deadline_dates?.delivery_time) === 'string' ? deadline_dates?.delivery_time : moment(new Date(deadline_dates?.delivery_time)).format('HH:mm:ss')}` : deadline_dates?.delivery_date ?
              delivery_date : deadline_dates?.delivery_time ?
                typeof (deadline_dates?.pickup_time) === 'string' ? deadline_dates?.pickup_time : moment(new Date(deadline_dates?.pickup_time)).format('HH:mm:ss') : null,
          is_delivery_at_date_filled: deadline_dates?.delivery_date ? true : false,
          is_delivery_at_time_filled: deadline_dates?.delivery_time ? true : false,
          empty_return_deadline_at: deadline_dates?.empty_return_date && deadline_dates?.empty_return_time ?
            `${empty_return_date} ${empty_return_time}` : deadline_dates?.empty_return_date ?
              empty_return_date : deadline_dates?.empty_return_time ?
                empty_return_time : null,
          is_empty_return_deadline_at_date_filled: deadline_dates?.empty_return_date ? true : false,
          is_empty_return_deadline_at_time_filled: deadline_dates?.empty_return_time ? true : false,
          rate_validity_date: deadline_dates?.rate_validity_date,
          locations: [pickup_data, dropoff_data],
          additional_pickups: additional_pickups?.filter((item) => item?.title !== ""),
          additional_dropoffs: additional_dropoffs?.filter((item) => item?.title !== ""),
          ...payment_values
        }
        // return
        history.push('/select-recipient/forward', { loadDetail: detail })
      } else {
        let detail = {
          ...new_load_detail,
          price_per_freight: null,
          detention_rate: null,
          detention_additional_charges: null,
          loading_price_per_freight: null,
          unloading_price_per_freight: null,
          // deadline_at: `${deadline_date} ${deadline_time}`,
          deadline_at: `${deadline_date} ${deadline_time && typeof (deadline_time) === 'string' ? deadline_time : moment(new Date(deadline_time)).format('HH:mm:ss')}`,
          pickup_date: deadline_dates.pickup_date,
          // pickup_time: deadline_dates?.pickup_time,
          pickup_time: deadline_dates?.pickup_time ? typeof (deadline_dates?.pickup_time) === 'string' ? deadline_dates?.pickup_time : moment(new Date(deadline_dates?.pickup_time)).format('HH:mm:ss') : null,
          // delivery_at: deadline_dates?.delivery_date && deadline_dates?.delivery_time ?
          //   `${delivery_date} ${delivery_time}` : deadline_dates?.delivery_date ?
          //     delivery_date : deadline_dates?.delivery_time ?
          //       delivery_time : null,
          delivery_at: deadline_dates?.delivery_date && deadline_dates?.delivery_time ?
            `${delivery_date} ${typeof (deadline_dates?.delivery_time) === 'string' ? deadline_dates?.delivery_time : moment(new Date(deadline_dates?.delivery_time)).format('HH:mm:ss')}` : deadline_dates?.delivery_date ?
              delivery_date : deadline_dates?.delivery_time ?
                typeof (deadline_dates?.pickup_time) === 'string' ? deadline_dates?.pickup_time : moment(new Date(deadline_dates?.pickup_time)).format('HH:mm:ss') : null,
          is_delivery_at_date_filled: deadline_dates?.delivery_date ? true : false,
          is_delivery_at_time_filled: deadline_dates?.delivery_time ? true : false,
          empty_return_deadline_at: deadline_dates?.empty_return_date && deadline_dates?.empty_return_time ?
            `${empty_return_date} ${empty_return_time}` : deadline_dates?.empty_return_date ?
              empty_return_date : deadline_dates?.empty_return_time ?
                empty_return_time : null,
          is_empty_return_deadline_at_date_filled: deadline_dates?.empty_return_date ? true : false,
          is_empty_return_deadline_at_time_filled: deadline_dates?.empty_return_time ? true : false,
          rate_validity_date: deadline_dates?.rate_validity_date,
          locations: [pickup_data, dropoff_data],
          additional_pickups: additional_pickups?.filter((item) => item?.title !== ""),
          additional_dropoffs: additional_dropoffs?.filter((item) => item?.title !== ""),
          ...payment_values
        }
        // return
        history.push('/select-recipient/forward', { loadDetail: detail })
      }
    } else {
      if (new_load_detail?.categories.length) {
        let category_data = new_load_detail?.categories.map((item, index) => {
          return {
            ...item,
            max_bid: null
          }
        })
        let detail = {
          ...new_load_detail,
          categories: category_data?.filter((item) => item?.is_checked),
          // deadline_at: `${deadline_date} ${deadline_time}`,
          deadline_at: `${deadline_date} ${deadline_time && typeof (deadline_time) === 'string' ? deadline_time : moment(new Date(deadline_time)).format('HH:mm:ss')}`,
          pickup_date: deadline_dates.pickup_date,
          // pickup_time: deadline_dates?.pickup_time,
          pickup_time: deadline_dates?.pickup_time ? typeof (deadline_dates?.pickup_time) === 'string' ? deadline_dates?.pickup_time : moment(new Date(deadline_dates?.pickup_time)).format('HH:mm:ss') : null,
          // delivery_at: deadline_dates?.delivery_date && deadline_dates?.delivery_time ?
          //   `${delivery_date} ${delivery_time}` : deadline_dates?.delivery_date ?
          //     delivery_date : deadline_dates?.delivery_time ?
          //       delivery_time : null,
          delivery_at: deadline_dates?.delivery_date && deadline_dates?.delivery_time ?
            `${delivery_date} ${typeof (deadline_dates?.delivery_time) === 'string' ? deadline_dates?.delivery_time : moment(new Date(deadline_dates?.delivery_time)).format('HH:mm:ss')}` : deadline_dates?.delivery_date ?
              delivery_date : deadline_dates?.delivery_time ?
                typeof (deadline_dates?.pickup_time) === 'string' ? deadline_dates?.pickup_time : moment(new Date(deadline_dates?.pickup_time)).format('HH:mm:ss') : null,
          is_delivery_at_date_filled: deadline_dates?.delivery_date ? true : false,
          is_delivery_at_time_filled: deadline_dates?.delivery_time ? true : false,
          empty_return_deadline_at: deadline_dates?.empty_return_date && deadline_dates?.empty_return_time ?
            `${empty_return_date} ${empty_return_time}` : deadline_dates?.empty_return_date ?
              empty_return_date : deadline_dates?.empty_return_time ?
                empty_return_time : null,
          is_empty_return_deadline_at_date_filled: deadline_dates?.empty_return_date ? true : false,
          is_empty_return_deadline_at_time_filled: deadline_dates?.empty_return_time ? true : false,
          rate_validity_date: deadline_dates?.rate_validity_date,
          locations: [pickup_data, dropoff_data],
          additional_pickups: additional_pickups?.filter((item) => item?.title !== ""),
          additional_dropoffs: additional_dropoffs?.filter((item) => item?.title !== ""),
          ...payment_values
        }
        // return
        history.push('/select-recipient/forward', { loadDetail: detail })
      } else {
        let detail = {
          ...new_load_detail,
          max_bid: null,
          // deadline_at: `${deadline_date} ${deadline_time}`,
          deadline_at: `${deadline_date} ${deadline_time && typeof (deadline_time) === 'string' ? deadline_time : moment(new Date(deadline_time)).format('HH:mm:ss')}`,
          pickup_date: deadline_dates.pickup_date,
          // pickup_time: deadline_dates?.pickup_time,
          pickup_time: deadline_dates?.pickup_time ? typeof (deadline_dates?.pickup_time) === 'string' ? deadline_dates?.pickup_time : moment(new Date(deadline_dates?.pickup_time)).format('HH:mm:ss') : null,
          // delivery_at: deadline_dates?.delivery_date && deadline_dates?.delivery_time ?
          //   `${delivery_date} ${delivery_time}` : deadline_dates?.delivery_date ?
          //     delivery_date : deadline_dates?.delivery_time ?
          //       delivery_time : null,
          delivery_at: deadline_dates?.delivery_date && deadline_dates?.delivery_time ?
            `${delivery_date} ${typeof (deadline_dates?.delivery_time) === 'string' ? deadline_dates?.delivery_time : moment(new Date(deadline_dates?.delivery_time)).format('HH:mm:ss')}` : deadline_dates?.delivery_date ?
              delivery_date : deadline_dates?.delivery_time ?
                typeof (deadline_dates?.pickup_time) === 'string' ? deadline_dates?.pickup_time : moment(new Date(deadline_dates?.pickup_time)).format('HH:mm:ss') : null,
          is_delivery_at_date_filled: deadline_dates?.delivery_date ? true : false,
          is_delivery_at_time_filled: deadline_dates?.delivery_time ? true : false,
          empty_return_deadline_at: deadline_dates?.empty_return_date && deadline_dates?.empty_return_time ?
            `${empty_return_date} ${empty_return_time}` : deadline_dates?.empty_return_date ?
              empty_return_date : deadline_dates?.empty_return_time ?
                empty_return_time : null,
          is_empty_return_deadline_at_date_filled: deadline_dates?.empty_return_date ? true : false,
          is_empty_return_deadline_at_time_filled: deadline_dates?.empty_return_time ? true : false,
          rate_validity_date: deadline_dates?.rate_validity_date,
          locations: [pickup_data, dropoff_data],
          additional_pickups: additional_pickups?.filter((item) => item?.title !== ""),
          additional_dropoffs: additional_dropoffs?.filter((item) => item?.title !== ""),
          ...payment_values
        }
        // return
        history.push('/select-recipient/forward', { loadDetail: detail })
      }
    }
  }

  let is_recipient = false

  const renderComplete = () => {
    setIsDeadlineComplete(true);
  };

  const RenderResponseDeadline = ({ date, completed, iconComponent }: any) => {

    if (moment(date) >= moment()) {
      setIsDeadlineComplete(false);
    } else {
      setIsDeadlineComplete(true);
    }
    return !isDeadlineComplete ? (
      <div className="details-Content" style={{ marginTop: 0, flexDirection: 'column' }}>
        <div className="" style={{ paddingRight: 5 }}>
          <p className="customCounterTitle fw-5">
            Response Deadline Time Remaining:
          </p>
        </div>
        <Counter date={moment(date).toDate()} renderComplete={renderComplete} iconComponent={iconComponent} />
      </div>
    ) : (
      ""
    );
  };

  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const [selectVehicleTypeObj, setSelectVehicleTypeObj] = React.useState(null);
  const [containerType, setContainerType] = React.useState(null);
  const [packageType, setPackageType] = React.useState(null);

  let allVehicleTypes = postLoadMeta?.vehicle_types.filter((val) => val.load_type === new_load_detail.load_type?.id);
  let allContainerType = postLoadMeta.container_types;
  let allPreferredVehicleTypes = postLoadMeta.preferred_vehicles;


  const [showVehicleType, setShowVehicleType] = useState(false);
  const [showContainerModal, setShowContainerModal] = useState(false);
  const [packageModal, setPackageModal] = useState(false);
  const [showPreferredVehicleTypeModal, setShowPreferredVehicleTypeModal] = useState(false);
  const [current_index, set_current_index] = useState(null)
  const [current_index_container, set_current_index_container] = useState(null)
  const [current_index_package_type, set_current_index_package_type] = useState(null)

  const handleChangeVehicle = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = (event.target as HTMLInputElement).value;
    let typeVehicle = allVehicleTypes.find((val) => Number(val.id) === Number(id));
    let data = { ...new_load_detail }
    let obj = data?.categories[current_index]
    obj["vehicle"] = typeVehicle
    set_new_load_detail(data)
    setShowVehicleType(false);
  };

  const handleChangeContainer = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let id = (event.target as HTMLInputElement).value;
    let containerTypes = allContainerType.find((val) => Number(val.id) === Number(id));
    let data = { ...new_load_detail }
    let obj = data?.categories[current_index_container]
    obj["container_type"] = containerTypes
    set_new_load_detail(data)
    setShowContainerModal(false);
  };

  const handleChangePackage = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = (event.target as HTMLInputElement).value;
    let types = postLoadMeta?.package_types.find((val) => Number(val.id) === Number(id));
    let data = { ...new_load_detail }
    if (current_index_package_type !== null) {

      let obj = data?.categories[current_index_package_type]
      obj["package_type"] = types
    } else {
      data["package_type"] = types
      data['package_type_other'] = null
      if (types?.title == "Other") {
        set_package_type_validation2(true)
      } else {
        set_package_type_validation2(false)

      }
    }
    set_new_load_detail(data)
    setPackageModal(false);
  };


  const handleChangePreferredVehicleType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let id = (event.target as HTMLInputElement).value;
    let preferredVehicleTypes = allPreferredVehicleTypes.find((val) => Number(val.id) === Number(id));
    let data = { ...new_load_detail }
    data["preferred_vehicle"] = preferredVehicleTypes
    set_new_load_detail(data)
    setShowPreferredVehicleTypeModal(false);
  };



  const handleCHangePrices = (key, value) => {
    let data = { ...new_load_detail }
    data[key] = value
    let price_unit = new_load_detail?.price_unit
    let total_freight_ton = Number(data["total_freight_ton"])
    let total_ton = Number(data['gross_weight'])
    let total_ton_kg = Number(data['gross_weight']) * 1000
    let total_cargo_liter = Number(data['cargo_liter'])
    let total_cbm = Number(data['cargo_volume'])
    let price = Number(data['price_per_freight'])
    let loading_price = Number(data.loading_price_per_freight);
    let un_loading_price = Number(data.unloading_price_per_freight)

    if (price_unit == "Liter") {
      data['total_offer_price'] = (price * total_cargo_liter) + loading_price + un_loading_price
    } else if (price_unit == 'F.Ton') {
      data['total_offer_price'] = (price * total_freight_ton) + loading_price + un_loading_price
    } else if (price_unit == 'Kg') {
      data['total_offer_price'] = (price * total_ton_kg) + loading_price + un_loading_price
    } else if (price_unit == 'CBM') {
      data['total_offer_price'] = (price * total_cbm) + loading_price + un_loading_price
    } else {
      data['total_offer_price'] = (price * total_ton) + loading_price + un_loading_price
    }
    // let total = (Number(e.target.value) * Number(new_load_detail.total_freight_ton)) + Number(new_load_detail?.loading_price_per_freight) + Number(new_load_detail?.unloading_price_per_freight)
    // data["total_offer_price"] = total
    set_new_load_detail(data)
  }

  if (state?.loadDetail) {
    return (
      <TitleLayout titleOnly titleOnlyText={`Load ID - ${state?.loadDetail?.id}`}>
        <div className="loadDetailWrapper">
          <RenderResponseDeadline date={state?.loadDetail?.deadline_at} />

          {/* load detail */}
          <div className="accordion-container">
            <Accordion
              onChange={() => set_expand_poster_detail(!expand_poster_detail)}
              expanded={expand_poster_detail}
              className="accordion-background"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="fw-5 accHeading">
                  <span>Load Details</span>
                  {
                    is_recipient &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">لوڈ کی تفصیلات </span>
                    </>
                  }
                </p>
              </AccordionSummary>
              <div className="accordion-content">

                {state?.loadDetail?.my_allocates?.allocated_to && (
                  <RenderRTL
                    title="Load Allocated To"
                    val={state?.loadDetail?.my_allocates?.allocated_to?.name}
                    is_recipient={is_recipient}
                  />
                )}

                {state?.loadDetail?.my_allocates?.allocated_by && (
                  <RenderRTL
                    title="Allocated By"
                    val={loadDetail?.my_allocates?.allocated_by?.name}
                    is_recipient={is_recipient}
                  />
                )}

                <div className="inputField">
                  <div className="label">Load Name</div>
                  <div className="field">
                    <SimpleInput
                      onChange={(e) => {
                        set_new_load_detail({
                          ...new_load_detail,
                          title: e.target.value,
                        });
                      }}
                      value={new_load_detail.title}
                      id={"load_name"}
                      name={"load_name"}
                      placeholder={"Enter Load Name"}
                    />
                  </div>
                </div>

                <RenderRTL
                  title="Reference Number"
                  sep="/"
                  urdutitle="حوالہ نمبر"
                  val={`${state?.loadDetail?.ref_no || "--"}`}
                  is_recipient={is_recipient}
                />

                {
                  state?.loadDetail?.load_type?.title == "Containerized" ?
                    <div>
                      <RenderRTL
                        title="Total No. of Containers"
                        sep="/"
                        urdutitle="لوڈ کی قسم"
                        val={total_containers}
                        is_recipient={is_recipient}
                      />
                      <RenderRTL
                        title="Total No. of Vehicles"
                        sep="/"
                        urdutitle="لوڈ کی قسم"
                        val={total_vehicles}
                        is_recipient={is_recipient}
                      />
                    </div>
                    : null
                }

                {
                  state?.loadDetail?.load_type?.title == LOAD_TYPE?.bulk && state?.loadDetail?.quote_by == "vehicle" ?
                    <RenderRTL
                      title="Total No. of Vehicles"
                      sep="/"
                      urdutitle="لوڈ کی قسم"
                      val={total_vehicles}
                      is_recipient={is_recipient}
                    /> : null

                }

                {
                  state?.loadDetail?.load_type?.title == LOAD_TYPE?.bulk && state?.loadDetail?.quote_by == "weight_volume" && !state?.loadDetail?.is_unit_basis ?
                    <div>
                      <RenderRTL
                        title={`Total ${state?.loadDetail?.price_unit}`}
                        sep="/"
                        urdutitle="لوڈ کی قسم"
                        val={numeral(total_weight).format('0,0.00')}
                        is_recipient={is_recipient}
                      />
                    </div> : null
                }

                <RenderRTL
                  title="Inquiry Type"
                  sep="/"
                  urdutitle="انکوائری کی قسم"
                  val={state?.loadDetail?.contract_id ? "Contract Rate" : state?.loadDetail?.inquiry_type?.title}
                  is_recipient={is_recipient}
                />

                <RenderRTL
                  title="Load Type"
                  sep="/"
                  urdutitle="لوڈ کی قسم"
                  val={state?.loadDetail?.load_type?.title}
                  is_recipient={is_recipient}
                />

                {state?.loadDetail?.cargo_description ?
                  // <RenderRTL
                  //   title="Cargo Description"
                  //   sep="/"
                  //   urdutitle="کارگو کی تفصیل"
                  //   val={
                  //     state?.loadDetail?.cargo_description ? state?.loadDetail?.cargo_description : ""}
                  //   is_recipient={is_recipient}
                  // /> 
                  <div className="inputField">
                    <div className="label">Cargo Description</div>
                    <div className="field">
                      <SimpleInput
                        onChange={(e) => {
                          set_new_load_detail({
                            ...new_load_detail,
                            cargo_description: e.target.value,
                          });
                        }}
                        value={new_load_detail?.cargo_description}
                        id={"cargo_description"}
                        name={"cargo_description"}
                        maxLength={250}
                        placeholder="Optional"
                      />
                    </div>
                  </div>
                  : null}


                <div style={{ height: 16 }}></div>

                {!state?.loadDetail?.is_allow_partial_load && showMessage && !state?.loadDetail?.contract_id ?
                  <div className="error-container info smallErrorContainer" style={{ marginBottom: 10 }}>
                    <div>
                      <InfoIcon className="error-icon" />
                    </div>
                    <div className="error-text">
                      Partial load is not Allowed
                      {
                        is_recipient &&
                        <>
                          <span className="seperator">/</span>
                          <span className="urduLabel">پارشل لوڈ کی اجازت نہیں ہے</span>
                        </>
                      }
                    </div>
                  </div> : null}
              </div>
            </Accordion>
          </div>

          {/* location detail  */}
          <div className="accordion-container">
            <Accordion
              onChange={() => set_expand_location_detail(!expand_location_detail)}
              expanded={expand_location_detail}
              className="accordion-background"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="fw-5 accHeading">
                  <span>Location Details</span>
                  {
                    is_recipient &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">لوڈ کی تفصیلات </span>
                    </>
                  }
                </p>
              </AccordionSummary>
              <div style={{
                padding: 10
              }}>
                <div className="load-detail-location-container">
                  <div style={{ width: "100%" }} className="load-detail-location-info customLoadDetailInfo">
                    {/* pickup  */}
                    <div className="load-detail-dots-container">
                      <div className="load-detail-location-start">
                        <div className="load-detail-location-start-inner"></div>
                      </div>
                      <div className="load-detail-location-end">
                        <div className="load-detail-location-end-inner"></div>
                      </div>
                    </div>
                    <div className="locationRightSide">
                      {/* pickup  */}
                      <div className="pickup">
                        <div style={{ width: "100%" }}>
                          <div className="inputField mbot5">
                            <div className="field">
                              <SimpleInput
                                id="map-address-d"
                                onChange={(e) => {
                                  set_pickup_data({
                                    ...pickup_data,
                                    title: e.target.value
                                  })
                                }}
                                type="text"
                                autoComplete="off"
                                name="search"
                                value={pickup_data?.title}
                                placeholder="Enter your pickup location name"
                              />
                            </div>
                          </div>

                          <div className="inputField">
                            <div className="field customMapField customMapFieldNew newPadAdd">
                              <input
                                id="map-address"
                                onChange={(e) => { handleAutoComplete(e) }}

                                type="text"
                                autoComplete="off"
                                name="search"
                                value={pickup_data?.raw_address}
                                placeholder="Enter detailed address"
                                maxLength={150}
                                className="searchInput"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* dropofff */}
                      <div style={{ marginTop: 10 }} className="dropoff">
                        <div className="inputField mbot5">
                          <div className="field">
                            <SimpleInput
                              id="map-address-d"
                              onChange={(e) => {
                                set_dropoff_data({
                                  ...dropoff_data,
                                  title: e.target.value
                                })
                              }}
                              type="text"
                              autoComplete="off"
                              name="search"
                              value={dropoff_data?.title}
                              placeholder="Enter your dropoff location name"
                            />
                          </div>
                        </div>
                        <div className="inputField">
                          <div className="field customMapField customMapFieldNew newPadAdd">
                            <input
                              id="map-address-drop"
                              onChange={(e) => { handleAutoCompleteDrop(e) }}
                              type="text"
                              autoComplete="off"
                              name="search"
                              value={dropoff_data?.raw_address}
                              placeholder="Enter detailed address"
                              maxLength={150}
                              className="searchInput"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: 25 }}>
                  {/* additional pickup */}
                  {
                    additional_pickups?.length ?
                      <CustomText.OpenSansSemiBold textStyle={{
                        marginTop: 10
                      }}>
                        Additional Pickups
                      </CustomText.OpenSansSemiBold> : null
                  }
                  {
                    additional_pickups?.map((item, index) => {

                      return (
                        <div className="pickup">
                          <div style={{ width: "100%" }}>
                            <div className="inputField mbot5">

                              <div className="field">
                                <SimpleInput
                                  id="map-address"
                                  onChange={(e) => {
                                    let new_data = [...additional_pickups]
                                    new_data[index] = { ...item, title: e.target.value }
                                    set_additional_pickups(new_data)

                                  }}
                                  type="text"
                                  autoComplete="off"
                                  name="search"
                                  value={item?.title}
                                  placeholder="Enter your pickup location name"
                                  maxLength={30}
                                />
                              </div>
                            </div>

                            {/* <div className="inputField mbot5">
                              <div className="field">
                                <SimpleInput
                                  id={`map-address${index}`}
                                  onChange={(e) => updateAdditionalPickup(e, item, index)}
                                  type="text"
                                  autoComplete="off"
                                  name="search"
                                  value={item?.raw_address}
                                  placeholder="Enter detailed address"
                                  maxLength={100}
                                />
                              </div>
                            </div> */}
                            <div className="inputField">
                              <div className="field customMapField customMapFieldNew newPadAdd">
                                <input
                                  id={`map-address${index}`}
                                  onChange={(e) => updateAdditionalPickup(e, item, index)}
                                  type="text"
                                  autoComplete="off"
                                  name="search"
                                  value={item?.raw_address}
                                  placeholder="Enter detailed address"
                                  maxLength={100}
                                  className="searchInput"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                      )
                    }
                    )
                  }

                  {/* additional drop off */}
                  {
                    additional_dropoffs?.length ?
                      <CustomText.OpenSansSemiBold textStyle={{
                        marginTop: 25
                      }}>
                        Additional Dropoff
                      </CustomText.OpenSansSemiBold> : null

                  }
                  {
                    additional_dropoffs?.map((item, index) => {
                      return (
                        <div className="pickup">
                          <div style={{ width: "100%" }}>
                            <div className="inputField mbot5">

                              <div className="field">
                                <SimpleInput
                                  id="map-address"
                                  onChange={(e) => {
                                    let new_data = [...additional_dropoffs]
                                    new_data[index] = { ...item, title: e.target.value }
                                    set_additional_dropoffs(new_data)
                                  }}
                                  type="text"
                                  autoComplete="off"
                                  name="search"
                                  value={item?.title}
                                  placeholder="Enter your dropoff location name"
                                  maxLength={30}
                                />
                              </div>
                            </div>

                            {/* <div className="inputField mbot5">
                              <div className="field">
                                <SimpleInput
                                  id={`map-address-drop${index}`}
                                  onChange={(e) => updateAdditionalDropoff(e, item, index)}
                                  type="text"
                                  autoComplete="off"
                                  name="search"
                                  value={item?.raw_address}
                                  placeholder="Enter detailed address"
                                  maxLength={100}
                                />
                              </div>
                            </div> */}
                            <div className="inputField">
                              <div className="field customMapField customMapFieldNew newPadAdd">
                                <input
                                  id={`map-address-drop${index}`}
                                  onChange={(e) => updateAdditionalDropoff(e, item, index)}
                                  autoComplete="off"
                                  name="search"
                                  value={item?.raw_address}
                                  placeholder="Enter detailed address"
                                  maxLength={100}
                                  className="searchInput"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                      )
                    }
                    )
                  }
                </div>

                <div>
                  <div className="timeTitleWrapper">
                    <div className="timeTitle">Response Deadline</div>
                  </div>
                  <div className="DatePickerFieldWrap">
                    <MaterialUIPickers
                      setDate={(e) => {
                        let new_date = { ...deadline_dates, deadline_date: e };
                        set_deadline_dates(new_date)
                      }}
                      setTime={(e) => {
                        let new_date = { ...deadline_dates, deadline_time: e };
                        set_deadline_dates(new_date)
                      }}
                      date={deadline_dates?.deadline_date}
                      time={deadline_dates?.deadline_time ? deadline_dates?.deadline_time : dayjs(state?.loadDetail?.deadline_at)}
                      // time={deadline_dates?.deadline_time}
                      maxDate={state?.loadDetail?.deadline_at}
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div>
                  <div className="timeTitleWrapper">
                    <div className="timeTitle">Pickup  Deadline</div>
                    {
                      deadline_dates?.pickup_time ?
                        <div
                          onClick={() => {
                            set_deadline_dates({
                              ...deadline_dates,
                              pickup_time: null
                            })
                          }}
                          className="clearBtn">
                          Clear
                        </div> : null
                    }
                  </div>
                  <div className="DatePickerFieldWrap">
                    <MaterialUIPickers
                      setDate={(e) => {
                        let new_date = { ...deadline_dates, pickup_date: e };
                        set_deadline_dates(new_date)
                      }}
                      setTime={(e) => {
                        let new_date = { ...deadline_dates, pickup_time: e };
                        set_deadline_dates(new_date)
                      }}
                      date={new Date(deadline_dates?.pickup_date)}
                      time={deadline_dates?.pickup_time ? new Date(deadline_dates?.pickup_time) : null}
                      maxDate={new Date(deadline_dates?.rate_validity_date)}
                      minDate={new Date(deadline_dates?.deadline_date)}
                      fromTime={true}

                    />
                  </div>
                </div>
                {
                  state?.loadDetail?.delivery_at &&
                  <div>
                    <div className="timeTitleWrapper">
                      <div className="timeTitle">Delivery  Deadline</div>
                      {/* {
                        deadline_dates?.delivery_date || deadline_dates?.delivery_time ?
                          <div
                            onClick={() => {
                              set_deadline_dates({
                                ...deadline_dates,
                                delivery_date: null,
                                delivery_time: null,
                              })

                            }}
                            className="clearBtn">
                            Clear
                          </div> : null
                      } */}

                    </div>
                   
                    <div className="DatePickerFieldWrap">
                      <MaterialUIPickers
                        setDate={(e) => {
                          let new_date = { ...deadline_dates, delivery_date: e };
                          set_deadline_dates(new_date)
                        }}
                        setTime={(e) => {
                          let new_date = { ...deadline_dates, delivery_time: e };
                          set_deadline_dates(new_date)
                        }}
                        date={deadline_dates?.delivery_date ? new Date(deadline_dates?.delivery_date) : null}
                        time={deadline_dates?.delivery_time ? new Date(deadline_dates?.delivery_time) :
                          deadline_dates?.delivery_time == null ? "" :

                          dayjs(state?.loadDetail?.delivery_at)}
                        
                        maxDate={new Date("01-01-3023")}
                        minDate={new Date(deadline_dates?.pickup_date)}
                        fromTime={true}
                      />
                    </div>
                  </div>
                }

                {
                  state?.loadDetail?.load_type.title == LOAD_TYPE.fcl && state?.loadDetail?.is_empty_return ?
                    <div>
                      <div className="timeTitleWrapper">
                        <div className="timeTitle">Empty Pickup / Return Deadline</div>
                        {/* {
                          deadline_dates?.empty_return_date || deadline_dates?.empty_return_time ?
                            <div
                              onClick={() => {
                                set_deadline_dates({
                                  ...deadline_dates,
                                  empty_return_date: null,
                                  empty_return_time: null
                                })
                              }}
                              className="clearBtn">
                              Clear
                            </div> : null
                        } */}
                      </div>
                      <div className="DatePickerFieldWrap">
                        <MaterialUIPickers
                          setDate={(e) => {
                            let new_date = { ...deadline_dates, empty_return_date: e };
                            set_deadline_dates(new_date)
                          }}
                          setTime={(e) => {
                            let new_date = { ...deadline_dates, empty_return_time: e };
                            set_deadline_dates(new_date)
                          }}
                          date={deadline_dates?.empty_return_date ? new Date(deadline_dates?.empty_return_date) : null}
                          time={deadline_dates?.empty_return_time ? new Date(deadline_dates?.empty_return_time) : null}
                          // time={deadline_dates?.empty_return_time ? deadline_dates?.empty_return_time : dayjs(state?.loadDetail?.empty_return_deadline_at)}

                          // maxDate={new Date("01-01-3023")}
                          minDate={new Date()}
                          fromTime={true}
                        />
                      </div>
                    </div> : null
                }

                <div>
                  <div className="timeTitleWrapper">
                    <div className="timeTitle">Rate Validity</div>
                  </div>
                  <div>
                    <div className="DatePickerFieldWrap">
                      <MaterialUIPickers
                        setDate={(e) => {
                          let new_date = { ...deadline_dates, rate_validity_date: e };
                          set_deadline_dates(new_date)
                        }}
                        date={deadline_dates?.rate_validity_date ? deadline_dates?.rate_validity_date : null}
                        minDate={deadline_dates?.pickup_date}
                        dateType={"Rate Validity"}
                        maxDate={"3050-01-01"}  // new field added
                        from={true}
                      />
                    </div>
                  </div>
                </div>
                {
                  new_load_detail?.is_empty_return ?
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <div className="inputField mbot5">
                        <div className="label">
                          Name of Yard/City
                        </div>
                        <div className="field">
                          <SimpleInput
                            id="empty_pickup"
                            onChange={(e) => {
                              set_new_load_detail({ ...new_load_detail, empty_return_yard_name: e.target.value })
                            }}
                            type="text"
                            autoComplete="off"
                            name="search"
                            value={new_load_detail?.empty_return_yard_name}
                            placeholder="Enter name of Yard/City"
                          />
                        </div>
                      </div>
                    </div> : null
                }

                {isDateError && (
                  <div className="error-container info">
                    <div>
                      <InfoIcon className="error-icon" />
                    </div>
                    <div className="error-text">
                      {dateErrorMessage}
                    </div>
                  </div>
                )}
              </div>
            </Accordion>
          </div>

          {/* payment terms  */}
          <div className="accordion-container">
            <Accordion
              onChange={() => set_expand_payment_terms(!expand_payment_terms)}
              expanded={expand_payment_terms}
              className="accordion-background"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="fw-5 accHeading">
                  <span>Payment Terms</span>
                  {
                    is_recipient &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">لوڈ کی تفصیلات </span>
                    </>
                  }
                </p>
              </AccordionSummary>
              <div style={{
                padding: 10
              }}>
                {isError && (
                  <div className="error-container info">
                    <div>
                      <InfoIcon className="error-icon" />
                    </div>
                    <div className="error-text">
                      Total of Advance, On Delivery and Credit must be equal to 100%.
                    </div>
                  </div>
                )}
                <div className="fieldWrapper">

                  <div className="inputField">
                    <div className="label">Advance</div>
                    <div className="field">
                      <NewInput
                        keyDown={(e) => {
                          return true
                          if (e.keyCode == 96) {
                            return true
                          }
                        }}
                        onChange={(e) => {
                          if (e?.target?.value.length <= 3 && e?.target?.value <= 100) {
                            set_payment_values({
                              ...payment_values,
                              pt_advance_value: e.target.value,
                              pt_is_advance: e?.target?.value ? true : false,
                            });
                          }
                        }}
                        value={payment_values.pt_is_advance ? payment_values.pt_advance_value : ""}
                        showEnd={"%"}
                      />
                    </div>
                  </div>


                  <div className="inputField">
                    <div className="label">On Delivery</div>
                    <div className="field">
                      <NewInput
                        onChange={(e) => {
                          if (e?.target?.value.length <= 3 && e?.target?.value <= 100) {
                            set_payment_values({
                              ...payment_values,
                              pt_ondelivery_value: e.target.value,
                              pt_is_ondelivery: e?.target?.value ? true : false,
                            });
                          }
                        }}
                        value={payment_values.pt_is_ondelivery ? payment_values.pt_ondelivery_value : ""}
                        showEnd={"%"}
                      />
                    </div>
                  </div>

                  <div className="innerColWrapper multiSelect">
                    <div className="inputField">
                      <div className="label">Credit</div>
                      <div className="field">
                        <NewInput
                          onChange={(e) => {
                            if (e?.target?.value.length <= 3 && e?.target?.value <= 100) {
                              set_payment_values({
                                ...payment_values,
                                pt_credit_percentage: e.target.value,
                                pt_is_credit: e?.target?.value ? true : false,

                              });
                            }
                          }}
                          value={payment_values.pt_is_credit ? payment_values.pt_credit_percentage : ""}
                          showEnd={"%"}
                        />
                      </div>
                    </div>
                    <div className="userSelectBox">
                      <Select
                        id="outlined-select-date-native"
                        defaultValue={payment_values?.pt_credit_days}
                        value={payment_values?.pt_credit_days}
                        name="pt_credit_days"
                        onChange={handleChangeTextValue}
                        className="add-vehicle-select-box2"
                      >
                        {currencies.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>


                  <div className="inputField">
                    <div className="label">Fuel Card</div>
                    <div className="field">
                      <NewInput
                        onChange={(e) => {
                          if (e?.target?.value.length <= 3 && e?.target?.value <= 100) {
                            handleChangeTextValue(e);
                            set_payment_values({
                              ...payment_values,
                              pt_fuel_card_value: e.target.value,
                              is_pt_fuel_card: e?.target?.value ? true : false,
                            });
                          }
                        }}
                        value={payment_values.is_pt_fuel_card ? payment_values.pt_fuel_card_value : ""}
                        showEnd={"%"}
                      />
                    </div>
                  </div>

                </div>

                <div className="sectionBox">
                  <label className="titleSection" style={{ display: "block", marginBottom: 0 }}>Tax</label>
                  <FormControlLabel
                    className="checkboxLabel"
                    control={
                      <Checkbox
                        checked={payment_values.is_withholding_tax_inclusive}
                        // onChange={handleChange}
                        onChange={(e) => {
                          set_payment_values({
                            ...payment_values,
                            is_withholding_tax_inclusive: e.target.checked,

                          })
                        }}
                        name="is_withholding_tax_inclusive"
                      />
                    }
                    label="Withholding tax deduction applicable"
                  />
                  <FormControlLabel
                    className="checkboxLabel"
                    control={
                      <Checkbox
                        checked={payment_values.is_gst_inclusive}
                        // onChange={handleChange}
                        onChange={(e) => {
                          set_payment_values({
                            ...payment_values,
                            is_gst_inclusive: e.target.checked,
                            sales_tax_province: e.target.checked ? payment_values?.sales_tax_province : ""

                          })
                        }}
                        name="is_gst_inclusive"
                      />
                    }
                    label="Sales Tax invoice required"
                  />
                  {payment_values.is_gst_inclusive ? (
                    <div className="userSelectBox">
                      <Select
                        id="outlined-select-date-native"
                        select
                        defaultValue={payment_values?.sales_tax_province}
                        value={payment_values?.sales_tax_province ? payment_values?.sales_tax_province : ""}
                        name="sales_tax_province"
                        onChange={handleChangeSalesTaxProvince}
                        className="add-vehicle-select-box2"
                      >
                        {PostLoadMeta?.sales_tax_by_province.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}


                      </Select>
                    </div>
                  ) : null}

                </div>
              </div>
            </Accordion>
          </div>

          {/* additional details  */}
          <div className="accordion-container">
            <Accordion
              onChange={() => set_expand_additional(!expand_additional)}
              expanded={expand_additional}
              className="accordion-background"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="fw-5 accHeading">
                  <span>Additional Details</span>
                  {
                    is_recipient &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">لوڈ کی تفصیلات </span>
                    </>
                  }
                </p>
              </AccordionSummary>
              <div style={{ padding: 10 }}>
                {/* {false ? ( */}
                {/* {loadDetail?.instructions_voice_note ? ( */}
                {/* {record_url?.cloudinary_url ? ( */}
                {new_load_detail?.instructions_voice_note_object?.cloudinary_url ? (
                  <div
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <CustomText.OpenSansSemiBold>
                      <span>Instruction Voice Note</span>
                      {
                        is_recipient &&
                        <>
                          <span className="seperator">/</span>
                          <span className="urduLabel">وائس نوٹ</span>
                        </>
                      }
                    </CustomText.OpenSansSemiBold>
                    <div style={{ marginTop: 5 }}></div>
                    <div className="recordedVoiceNote newRecordedVoiceNote">
                      <ReactAudioPlayer
                        // src={loadDetail?.instructions_voice_note}
                        src={new_load_detail?.instructions_voice_note_object?.cloudinary_url}
                        // autoPlay
                        controls
                        loop={false}
                      />
                      <span
                        className="icons-cross"
                        onClick={() => {
                          // set_record_url(null)
                          set_new_load_detail({
                            ...new_load_detail,
                            instructions_voice_note: null,
                            instructions_voice_note_object: null
                          })
                          showMessage.current({
                            message: "Voice note deleted successfully.",
                            status: 'success'
                          })
                        }}
                      ></span>
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "100%", marginTop: '1rem' }}>
                    <FadeInComponent>
                      <div>
                        <p className="simpleLabel fw-5">
                          Record Voice Note
                        </p>
                        {
                          recordLimit === 9 &&
                          <div style={divStyle} className="error-container info">
                            <div>
                              <InfoIcon className="error-icon" />
                            </div>
                            <div className="error-text">
                              In iOS 17 limits voice recording to 9 seconds or less
                            </div>
                          </div>
                        }
                        <AudioAnalyser {...audioProps}>
                        </AudioAnalyser>
                        <div className="recorderWrapper">
                          {
                            isActive ?
                              <div className="recording">
                                <div className="recordTime">
                                  <label>
                                    {minute}
                                    <span>:</span>
                                    {second}{" "}
                                  </label>
                                  {" | Start Recording ..."}
                                </div>
                                <div
                                  className="icon"
                                  onClick={() => {
                                    if (!isBrowser) {
                                      if (sendEventToAndroid("voiceRecordStop")) {
                                        window.Android.voiceRecordStop(
                                          String("stop")
                                        );
                                      } else if (sendEventToiOS("voiceRecordStop")) {
                                        window.webkit.messageHandlers.voiceRecordStop.postMessage(
                                          JSON.stringify({
                                            start: "stop",
                                          })
                                        );
                                      } else {
                                      }
                                    }
                                    controlAudio("inactive")
                                    stopTimer()
                                  }}
                                >
                                  <MicIcon />
                                </div>
                              </div>
                              :
                              <div>
                                <div className="startRecord">
                                  <label>
                                    Start Recording
                                  </label>
                                  <div
                                    style={{
                                      cursor: "pointer"
                                    }}
                                    onClick={() => {
                                      if (isBrowser) {
                                        navigator.permissions.query({ name: 'microphone' }).then(function (permissionStatus) {
                                          if (permissionStatus?.state === "denied") {
                                            showMessage.current({ message: 'Please allow your mic permission to record audio', status: 'error' })
                                          }
                                          if (permissionStatus?.state !== "granted") {
                                            navigator.mediaDevices
                                              .getUserMedia({ video: false, audio: true })
                                              .then((stream) => {
                                                setTimeout(() => {
                                                  controlAudio('recording')
                                                }, 2000);
                                                setIsActive(!isActive);
                                              })
                                              .catch((err) => {
                                                console.error(`you got an error: ${err}`);
                                              });
                                          } else {
                                            setTimeout(() => {
                                              controlAudio('recording')
                                            }, 2000);
                                            setIsActive(!isActive);
                                          }
                                        })
                                      } else {
                                        setIsActive(true)
                                        setTimeout(() => {
                                          if (sendEventToAndroid("voiceRecordStart")) {
                                            window.Android.voiceRecordStart(
                                              String("start")
                                            );

                                          } else if (sendEventToiOS("voiceRecordStart")) {
                                            window.webkit.messageHandlers.voiceRecordStart.postMessage(
                                              JSON.stringify({
                                                start: "start",
                                              })
                                            );
                                          }
                                        }, 2000);
                                      }
                                    }}
                                  >
                                    <MicIcon />
                                  </div>
                                </div>
                              </div>
                          }
                        </div>
                      </div>
                    </FadeInComponent>
                  </div>
                )}
                <div className={new_load_detail.is_advance_vehicle_driver_docs ? "selectBoxWrapper" : ""}>
                  <SimpleCheckBox
                    id={"is_advance_vehicle_driver_docs"}
                    labelName="is_advance_vehicle_driver_docs"
                    name="is_advance_vehicle_driver_docs"
                    title="Advance Vehicle / Driver Documents "
                    handleChange={(e) => {
                      set_new_load_detail({
                        ...new_load_detail,
                        is_advance_vehicle_driver_docs: e.target.checked
                      })
                    }}
                    checked={new_load_detail.is_advance_vehicle_driver_docs}
                    iconComponent={
                      PostLoadMeta?.additional_details_charges
                        ?.is_advance_vehicle_driver_docs ? (
                        <InfoIcon
                          onClick={() => {
                            setShowInfoWaterMark(
                              PostLoadMeta?.additional_details_charges
                                ?.is_advance_vehicle_driver_docs
                            );
                            setShowModalWaterMark(true);
                          }}
                        />
                      ) : null
                    }
                  />
                  {new_load_detail.is_advance_vehicle_driver_docs ? (
                    <div className="inputWrapper">
                      <div className="inputField">
                        <div className="label">
                          Enter Details of Documents Required

                        </div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              if (e.target.value.length > 255) {
                                showMessage.current({
                                  message: "Details of Documents should not be greater than 255 characters",
                                  status: "error"
                                })
                              } else {
                                set_new_load_detail({
                                  ...new_load_detail,
                                  driver_requried_document_details: e.target.value,
                                });
                              }
                            }}
                            value={new_load_detail.driver_requried_document_details
                            }
                            type="text"
                            aria-describedby="standard-weight-helper-text"
                            placeholder={"License, Fitness, Vehicle Registration etc"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* is container deposit  */}
                {
                  new_load_detail.load_type?.title == "Containerized" ? (
                    <div
                      className={
                        new_load_detail.is_container_deposit_by_transporter
                          ? "selectBoxWrapper"
                          : ""
                      }
                    >
                      <SimpleCheckBox
                        id={"is_container_deposit_by_transporter"}
                        labelName="is_container_deposit_by_transporter"
                        title="Container Deposit By Transporter"
                        handleChange={(e) => {
                          set_new_load_detail({
                            ...new_load_detail,
                            is_container_deposit_by_transporter: e.target.checked
                          })
                        }}
                        checked={new_load_detail.is_container_deposit_by_transporter}
                        className="multicolortext"
                        iconComponent={
                          PostLoadMeta?.additional_details_charges
                            ?.is_container_deposit_by_transporter ? (
                            <InfoIcon
                              onClick={() => {
                                setShowInfoWaterMark(
                                  PostLoadMeta?.additional_details_charges
                                    ?.is_container_deposit_by_transporter
                                );
                                setShowModalWaterMark(true);
                              }}
                            />
                          ) : null
                        }
                      // isGrayOut={true}
                      />

                      {new_load_detail.is_container_deposit_by_transporter ? (
                        <div className="inputWrapper">
                          <div className="inputField">
                            <div className="label">Shipping Line Name</div>
                            <div className="field">
                              <TextInput
                                onChange={(e) => {
                                  if (e.target.value.length > 50) {
                                    showMessage.current({
                                      message: "Shipping Line Name should not be greater than 50 characters",
                                      status: "error"
                                    })
                                  } else {
                                    set_new_load_detail({
                                      ...new_load_detail,
                                      shipping_line_name: e.target.value,
                                    });
                                  }
                                }}
                                value={new_load_detail.shipping_line_name}
                                type="text"
                                aria-describedby="standard-weight-helper-text"
                                placeholder={"Optional"}
                              />
                            </div>
                          </div>

                          <div className="inputField">
                            <div className="label">Deposit Amount</div>
                            <div className="field">
                              <NewInput
                                onChange={(e) => {
                                  set_new_load_detail({
                                    ...new_load_detail,
                                    container_deposit_amount: e.target.value,
                                  });
                                }}
                                value={
                                  new_load_detail.container_deposit_amount
                                    ? new_load_detail.container_deposit_amount
                                    : ""
                                }
                                showEnd={"PKR"}
                                placeholder={"Optional"}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null
                }

                {/* is cross sttufing  */}
                {
                  new_load_detail.load_type?.title == "Containerized" ?
                    <div
                      className={
                        new_load_detail.is_cross_stuffing_required
                          ? "selectBoxWrapper"
                          : ""
                      }
                    >
                      <SimpleCheckBox
                        id={"is_cross_stuffing_required"}
                        labelName="is_cross_stuffing_required"
                        title="Cross Stuffing / Local Shifting"
                        handleChange={(e) => {
                          set_new_load_detail({
                            ...new_load_detail,
                            is_cross_stuffing_required: e.target.checked
                          })
                        }}
                        checked={new_load_detail.is_cross_stuffing_required}
                        iconComponent={
                          PostLoadMeta?.additional_details_charges
                            ?.is_cross_stuffing_required ? (
                            <InfoIcon
                              onClick={() => {
                                setShowInfoWaterMark(
                                  PostLoadMeta?.additional_details_charges
                                    ?.is_cross_stuffing_required
                                );
                                setShowModalWaterMark(true);
                              }}
                            />
                          ) : null
                        }
                      />
                      <div className="inputWrapper">
                        {new_load_detail.is_cross_stuffing_required ? (
                          <SimpleCheckBox
                            checked={
                              new_load_detail.is_cross_stuffing_box_vehicle_required
                            }
                            handleChange={(e) => {
                              set_new_load_detail({
                                ...new_load_detail,
                                is_cross_stuffing_box_vehicle_required: e.target.checked
                              })
                            }}
                            labelName="is_cross_stuffing_box_vehicle_required"
                            id="is_cross_stuffing_box_vehicle_required"
                            title="Containerized / Box Vehicle Only"
                          />
                        ) : null}
                      </div>
                    </div>
                    : null
                }

                {new_load_detail.load_type?.title !== "Containerized" ?
                  <SimpleCheckBox
                    labelName="is_bonded_vehicle_required"
                    name="is_bonded_vehicle_required"
                    title="Bonded Vehicle Required"
                    id={"is_bonded_vehicle_required"}
                    handleChange={(e) => {
                      set_new_load_detail({
                        ...new_load_detail,
                        is_bonded_vehicle_required: e.target.checked
                      })
                    }}
                    checked={new_load_detail.is_bonded_vehicle_required}
                    iconComponent={
                      PostLoadMeta?.additional_details_charges
                        ?.is_bonded_vehicle_required ? (
                        <InfoIcon
                          onClick={() => {
                            setShowInfoWaterMark(
                              PostLoadMeta?.additional_details_charges
                                ?.is_bonded_vehicle_required
                            );
                            setShowModalWaterMark(true);
                          }}
                        />
                      ) : null
                    }
                  /> :
                  <SimpleCheckBox
                    labelName="is_bonded_vehicle_required"
                    name="is_bonded_vehicle_required"
                    title="Custom Bonded Carrier"
                    id={"is_bonded_vehicle_required"}
                    handleChange={(e) => {
                      set_new_load_detail({
                        ...new_load_detail,
                        is_bonded_vehicle_required: e.target.checked
                      })
                    }}
                    checked={new_load_detail.is_bonded_vehicle_required}
                    iconComponent={
                      PostLoadMeta?.additional_details_charges
                        ?.is_bonded_vehicle_required ? (
                        <InfoIcon
                          onClick={() => {
                            setShowInfoWaterMark(
                              PostLoadMeta?.additional_details_charges
                                ?.is_bonded_vehicle_required
                            );
                            setShowModalWaterMark(true);
                          }}
                        />
                      ) : null
                    }
                  />
                }

                <SimpleCheckBox
                  id={"is_covid_certf_req_driver"}
                  labelName="is_covid_certf_req_driver"
                  name="is_covid_certf_req_driver"
                  title="Driver Covid-19 Vaccination Required"
                  handleChange={(e) => {
                    set_new_load_detail({
                      ...new_load_detail,
                      is_covid_certf_req_driver: e.target.checked
                    })
                  }}
                  checked={new_load_detail.is_covid_certf_req_driver}
                  iconComponent={
                    PostLoadMeta?.additional_details_charges
                      ?.is_covid_certf_req_driver ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            PostLoadMeta?.additional_details_charges
                              ?.is_covid_certf_req_driver
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />

                <SimpleCheckBox
                  id={"is_driver_negative_pcr_required"}
                  labelName="is_driver_negative_pcr_required"
                  name="is_driver_negative_pcr_required"
                  title="Driver Negative PCR Required"
                  handleChange={(e) => {
                    set_new_load_detail({
                      ...new_load_detail,
                      is_driver_negative_pcr_required: e.target.checked
                    })
                  }}
                  checked={new_load_detail.is_driver_negative_pcr_required}
                  iconComponent={
                    PostLoadMeta?.additional_details_charges
                      ?.is_driver_negative_pcr_required ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            PostLoadMeta?.additional_details_charges
                              ?.is_driver_negative_pcr_required
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />

                <SimpleCheckBox
                  labelName="is_hse_compliant_vehicle"
                  name="is_hse_compliant_vehicle"
                  title={"HSE Compliant Vehicle"}
                  id={"is_hse_compliant_vehicle"}
                  handleChange={(e) => {
                    set_new_load_detail({
                      ...new_load_detail,
                      is_hse_compliant_vehicle: e.target.checked
                    })
                  }}
                  checked={new_load_detail.is_hse_compliant_vehicle}
                  iconComponent={
                    PostLoadMeta?.additional_details_charges
                      ?.is_hse_compliant_vehicle ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            PostLoadMeta?.additional_details_charges
                              ?.is_hse_compliant_vehicle
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />

                {new_load_detail?.load_type?.title !== "Containerized" ? (
                  <SimpleCheckBox
                    id={"is_tarpaulin_plastic_cover"}
                    labelName="is_tarpaulin_plastic_cover"
                    name="is_tarpaulin_plastic_cover"
                    title="Tarpaulin / Plastic Cover"
                    handleChange={(e) => {
                      set_new_load_detail({
                        ...new_load_detail,
                        is_tarpaulin_plastic_cover: e.target.checked
                      })
                    }}
                    checked={new_load_detail.is_tarpaulin_plastic_cover}
                    iconComponent={
                      PostLoadMeta?.additional_details_charges
                        ?.is_tarpaulin_plastic_cover ? (
                        <InfoIcon
                          onClick={() => {
                            setShowInfoWaterMark(
                              PostLoadMeta?.additional_details_charges
                                ?.is_tarpaulin_plastic_cover
                            );
                            setShowModalWaterMark(true);
                          }}
                        />
                      ) : null
                    }
                  />
                ) : null}

                {
                  new_load_detail?.load_type?.title !== "Containerized" ?
                    < div
                      className={
                        new_load_detail?.is_under_hook_delivery_required ? "selectBoxWrapper" : ""
                      }
                    >
                      <SimpleCheckBox
                        id={"is_under_hook_delivery_required"}
                        labelName="is_under_hook_delivery_required"
                        title="Underhook / Direct Delivery Required"
                        handleChange={(e) => {
                          set_new_load_detail({
                            ...new_load_detail,
                            is_under_hook_delivery_required: e.target.checked
                          })
                        }}
                        checked={new_load_detail?.is_under_hook_delivery_required}
                        iconComponent={
                          PostLoadMeta?.additional_details_charges
                            ?.is_under_hook_delivery_required ? (
                            <InfoIcon
                              onClick={() => {
                                setShowInfoWaterMark(
                                  PostLoadMeta?.additional_details_charges
                                    ?.is_under_hook_delivery_required
                                );
                                setShowModalWaterMark(true);
                              }}
                            />
                          ) : null
                        }
                      />

                      {new_load_detail?.is_under_hook_delivery_required ? (
                        <div className="inputWrapper">
                          <div className="inputField">
                            <div className="label">Package No.</div>
                            <div className="field">
                              <TextInput
                                inputProps={{
                                  step: "1",
                                }}
                                onChange={(e) => {
                                  if (e.target.value.length > 255) {
                                    showMessage.current({
                                      message: "Details of Package No. should not be greater than 255 characters",
                                      status: "error"
                                    })
                                  } else {
                                    set_new_load_detail({
                                      ...new_load_detail,
                                      under_hook_delivery: e.target.value
                                    })
                                  }
                                }}
                                value={new_load_detail?.under_hook_delivery}
                                type="text"
                                aria-describedby="standard-weight-helper-text"
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div> : null
                }

                <SimpleCheckBox
                  id={"is_vehicle_tracking"}
                  labelName="is_vehicle_tracking"
                  name="is_vehicle_tracking"
                  title="Vehicle Tracking"
                  handleChange={(e) => {
                    set_new_load_detail({
                      ...new_load_detail,
                      is_vehicle_tracking: e.target.checked
                    })
                  }}
                  checked={new_load_detail.is_vehicle_tracking}
                  iconComponent={
                    PostLoadMeta?.additional_details_charges
                      ?.is_vehicle_tracking ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            PostLoadMeta?.additional_details_charges
                              ?.is_vehicle_tracking
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />

                <div
                  className={
                    new_load_detail.is_weightment_received_required
                      ? "selectBoxWrapper"
                      : ""
                  }
                >
                  <SimpleCheckBox
                    labelName="is_weightment_received_required"
                    name="is_weightment_received_required"
                    title="Weighment Required"
                    id={"is_weightment_received_required"}
                    handleChange={(e) => {
                      set_new_load_detail({
                        ...new_load_detail,
                        is_weightment_received_required: e.target.checked
                      })
                    }}
                    checked={new_load_detail.is_weightment_received_required}
                    iconComponent={
                      PostLoadMeta?.additional_details_charges
                        ?.is_weightment_received_required ? (
                        <InfoIcon
                          onClick={() => {
                            setShowInfoWaterMark(
                              PostLoadMeta?.additional_details_charges
                                ?.is_weightment_received_required
                            );
                            setShowModalWaterMark(true);
                          }}
                        />
                      ) : null
                    }
                  />
                  {new_load_detail.is_weightment_received_required ? (
                    <div className="inputWrapper">
                      <div className="inputField">
                        <div className="label">Weighment Bridge Name</div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              if (e.target.value.length > 50) {
                                showMessage.current({
                                  message: "Weighment Bridge Name should not be greater than 50 characters",
                                  status: "error"
                                })
                              } else {
                                set_new_load_detail({
                                  ...new_load_detail,
                                  weightment_received_name: e.target.value,
                                });
                              }
                            }}
                            value={new_load_detail.weightment_received_name}
                            type="text"
                            aria-describedby="standard-weight-helper-text"
                            placeholder="(Optional)"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <SimpleCheckBox
                  labelName="is_allow_partial_load"
                  title="Partial Load Allowed"
                  id={"is_allow_partial_load"}
                  handleChange={(e) => {
                    set_new_load_detail({
                      ...new_load_detail,
                      is_allow_partial_load: e.target.checked
                    })
                  }}
                  checked={new_load_detail?.is_allow_partial_load}
                />
              </div>
            </Accordion>
          </div>

          {/* transaction type  */}
          <div className="accordion-container">
            <Accordion
              onChange={() => set_transaction_accordion(!transaction_accordion)}
              expanded={transaction_accordion}
              className="accordion-background"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className="fw-5 accHeading">
                  <span>Transaction Type</span>
                  {
                    is_recipient &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">لوڈ کی تفصیلات </span>
                    </>
                  }
                </p>
              </AccordionSummary>
              <div style={{ padding: 10 }}>
                <div className="tabWrapper">
                  <RadioButton
                    value={new_load_detail?.transaction_type?.title == "Bid" ? '1' : '0'}
                    id={"1"}
                    name="testt"
                    label="Bid"
                    onChange={() => {
                      set_new_load_detail({
                        ...new_load_detail,
                        transaction_type: {
                          title: "Bid",
                          id: 2,
                          status: 1,
                          picture: null
                        }
                      })
                    }}
                  />
                  <RadioButton
                    value={new_load_detail?.transaction_type?.title == "Offer" ? '1' : '0'}
                    id="1"
                    name="test"
                    label="Offer"
                    onChange={(value: any) => {
                      set_new_load_detail({
                        ...new_load_detail,
                        transaction_type: {
                          title: "Offer",
                          id: 1,
                          status: 1,
                          picture: null
                        }
                      })
                    }}
                  />
                  <div className="radioTab_slider"></div>
                </div>
              </div>
            </Accordion>
          </div>
          {
            new_load_detail?.categories?.length ?
              <div>
                {
                  new_load_detail?.categories?.map((item, index) => {

                    return (
                      <div className="accordion-container">
                        <Accordion onChange={(e) => e.stopPropagation()} className="accordion-background">
                          <CategoryCargoHeading
                            loadDetail={new_load_detail}
                            item={item}
                            index={index}
                            categoryLength={new_load_detail?.categories.length}
                            is_recipient={false}
                          />
                          <Checkbox
                            checked={item?.is_checked}
                            onChange={(e) => {
                              let cats_count = new_load_detail?.categories?.filter((item) => item?.is_checked).length
                              if (e.target.checked) {
                                let data = { ...new_load_detail }
                                let obj = data?.categories[index]
                                obj["is_checked"] = e.target.checked
                                set_new_load_detail(data)
                              } else {
                                if (cats_count > 1) {
                                  let data = { ...new_load_detail }
                                  let obj = data?.categories[index]
                                  obj["is_checked"] = e.target.checked
                                  set_new_load_detail(data)
                                } else {
                                  showMessage.current({ message: "Please select at least one category.", status: 'error' })
                                }
                              }
                            }}
                            style={{
                              color: "#7ac251",
                            }}
                          />
                          <div style={{
                            pointerEvents: !item?.is_checked ? "none" : "auto"
                          }} className="accordion-content">
                            {/* <ThreeColumnRTL loadDetail={loadDetail} item={item} is_recipient={false} /> */}
                            {/* <TwoColumnRTLVehicle forward={true} loadDetail={loadDetail} item={item} type={params?.type} is_recipient={false} /> */}

                            {
                              new_load_detail?.load_type?.title == LOAD_TYPE?.fcl || new_load_detail?.quote_by == "vehicle" ?
                                <div>
                                  <div className="titleSection">Vehicle Type</div>
                                  <div onClick={() => { }}>
                                    <CustomizedSelects
                                      selectedValue={item?.vehicle?.id}
                                      values={item?.vehicle?.id ? allVehicleTypes : []}
                                      disabled={true}
                                      modalState={true}
                                      placeholder={"Select Vehicle Type"}
                                      handleModal={(value: boolean) => {
                                        if (!item?.is_checked) {
                                          return
                                        }
                                        set_current_index(index)
                                        setSelectVehicleTypeObj(item?.vehicle)
                                        setShowVehicleType(value);
                                      }}
                                    ></CustomizedSelects>
                                  </div>
                                </div> : null
                            }

                            {
                              new_load_detail?.load_type?.title == LOAD_TYPE?.fcl ?
                                <div>
                                  <div>
                                    <div className="titleSection">
                                      Container Type
                                    </div>
                                    <CustomizedSelects
                                      selectedValue={item?.container_type?.id}
                                      values={item?.container_type?.id ? allContainerType : []}
                                      disabled={true}
                                      modalState={true}
                                      placeholder={"Select Container Type"}
                                      handleModal={(value: boolean) => {
                                        if (!item?.is_checked) {
                                          return
                                        }
                                        setContainerType(item?.container_type)
                                        set_current_index_container(index)
                                        setShowContainerModal(value);
                                      }}
                                    ></CustomizedSelects>
                                  </div>
                                </div> : null
                            }

                            {
                              new_load_detail?.load_type?.title === LOAD_TYPE?.bulk && !new_load_detail?.is_unit_basis ?
                                <div>
                                  <div className="titleSection">
                                    Packaging Type
                                  </div>

                                  <CustomizedSelects
                                    classProp="mb-0"
                                    placeholder={"Select Packaging Type"}
                                    selectedValue={item?.package_type?.id}
                                    values={
                                      item?.package_type ? postLoadMeta?.package_types : []
                                    }
                                    disabled={true}
                                    modalState={true}
                                    handleModal={(value: boolean) => {
                                      setPackageType(item?.package_type)
                                      set_current_index_package_type(index)
                                      setPackageModal(value);
                                      if (item?.package_type?.title !== "Other") {
                                        let data = { ...new_load_detail }
                                        let obj = data?.categories[index]
                                        obj["package_type_other"] = null
                                        set_new_load_detail(data)
                                      }
                                    }}
                                  ></CustomizedSelects>
                                  {item?.package_type?.title === "Other" && (
                                    <div className="inputField" style={{ marginTop: '1rem' }}>
                                      <div className="label">Other package type</div>
                                      <div className="field">
                                        <SimpleInput
                                          onChange={(e) => {
                                            let data = { ...new_load_detail }
                                            let obj = data?.categories[index]
                                            obj["package_type_other"] = e.target.value
                                            set_new_load_detail(data)
                                          }}
                                          id="package_type_other"
                                          name="package_type_other"
                                          value={item?.package_type_other}
                                          type="text"
                                          placeholder="Type your Packaging type here"
                                          style={{ height: 41, width: "100%", marginBottom: 0, marginTop: '0.75rem' }}
                                          aria-describedby="standard-weight-helper-text"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div> : null
                            }

                            {
                              new_load_detail?.quote_by == "vehicle" ?
                                <div style={{
                                  marginTop: 20
                                }}>
                                  <VehicleCounters
                                    item={item}
                                    new_load_detail={new_load_detail}
                                    set_new_load_detail={(data) => {

                                      let total = (Number(data["categories"][index]["vehicle_quantity"]) * Number(item?.offer_price_per_vehicle)) + Number(item?.loading_price) + Number(item?.unloading_price)
                                      data["categories"][index]["total_offer_price"] = total
                                      set_new_load_detail(data)
                                    }}
                                    updateValue={(params) => {
                                      let data = { ...new_load_detail }
                                      let obj = data?.categories[index]
                                      obj[params?.key] = params.val
                                      set_new_load_detail(data)
                                    }}
                                  />
                                </div> : null
                            }

                            {item?.vehicle?.is_boxed == 1 && (
                              <div style={{ display: "flex", marginBottom: 8 }}>
                                <SimpleCheckBox
                                  title="Box"
                                  checked={item.is_boxed}
                                  handleChange={(e) => {
                                    let data = { ...new_load_detail }
                                    let obj = data?.categories[index]
                                    obj["is_boxed"] = e.target.checked
                                    set_new_load_detail(data)
                                  }}
                                  labelName="is_boxed"
                                  id="is_boxed"
                                />
                              </div>
                            )}

                            {item?.vehicle?.is_sidewall == 1 && (
                              <div style={{ display: "flex", marginBottom: 8 }}>
                                <SimpleCheckBox
                                  title="Box"
                                  checked={item.is_boxed}
                                  handleChange={(e) => {
                                    let data = { ...new_load_detail }
                                    let obj = data?.categories[index]
                                    obj["is_twin_load"] = e.target.checked
                                    set_new_load_detail(data)
                                  }}
                                  labelName="is_boxed"
                                  id="is_boxed"
                                />
                              </div>
                            )}

                            {
                              item?.vehicle?.is_support_twin_load ?
                                <div style={{ marginTop: 10 }}>
                                  <SwitchableWithText
                                    id={"is_twin_load"}
                                    handleChange={(e) => {
                                      // if (e.target.checked &&
                                      //   selectVehicleTypeObj?.title === "40 ft Trailer"
                                      // ) {
                                      //   setTemp(selectVehicleTypeObj.min_capacity);
                                      //   setSelectVehicleTypeObj({
                                      //     ...selectVehicleTypeObj,
                                      //     min_capacity: 45,
                                      //   });
                                      // }
                                      // if (
                                      //   !e.target.checked &&
                                      //   selectVehicleTypeObj?.title === "40 ft Trailer"
                                      // ) {
                                      //   selectVehicleTypeObj.min_capacity = temp;
                                      //   setSelectVehicleTypeObj(selectVehicleTypeObj);
                                      //   setTemp(null);
                                      // }
                                      let data = { ...new_load_detail }
                                      let obj = data?.categories[index]
                                      obj["is_twin_load"] = e.target.checked
                                      set_new_load_detail(data)
                                    }}
                                    checked={item.is_twin_load}
                                    name="is_twin_load"
                                    // icon={Images.TwinLoad}
                                    text="Twin Load (2x20 Ft)"
                                  // checked={formikVehicleType.values.is_twin_load}
                                  />
                                </div> : null
                            }

                            {
                              item?.container_type?.is_guage ?
                                <div>
                                  <SwitchableWithText
                                    checked={item.is_out_of_guage}
                                    handleChange={(e) => {
                                      let data = { ...new_load_detail }
                                      let obj = data?.categories[index]
                                      obj["is_out_of_guage"] = e.target.checked
                                      set_new_load_detail(data)
                                    }}
                                    name="is_out_of_guage"
                                    id="is_out_of_guage"
                                    // icon={Images.Gauge}
                                    text={"Out of Gauge"}
                                  />
                                </div> : null
                            }

                            {new_load_detail?.load_type?.title === LOAD_TYPE.fcl && (
                              <SwitchableWithText
                                id={"is_shipper_owned_container"}
                                handleChange={(e) => {
                                  let data = { ...new_load_detail }
                                  let obj = data?.categories[index]
                                  obj["is_shipper_owned_container"] = e.target.checked
                                  set_new_load_detail(data)
                                }}
                                name="is_shipper_owned_container"
                                text="Shipper Owned Container(SOC)"
                                checked={
                                  item.is_shipper_owned_container
                                }
                              />
                            )}

                            {
                              new_load_detail?.is_unit_basis ?
                                <div>
                                  <div style={{ marginTop: 20 }}>
                                    <RenderFields
                                      details={item}
                                      setDetils={(data) => {
                                        let clone_data = { ...new_load_detail }
                                        clone_data["categories"][index] = data
                                        set_new_load_detail(clone_data)
                                      }}
                                      toggleModal={toggleModal}
                                      setInfo={setInfo}
                                      is_unit_basis={new_load_detail?.is_unit_basis}
                                    />
                                  </div>
                                  <ItemFields
                                    item={item}
                                    new_load_detail={new_load_detail}
                                    set_new_load_detail={set_new_load_detail}
                                    handlePriceChange={(data) => {

                                      let total = (Number(data["categories"][index]["unit_quantity"]) * Number(item?.offer_price_per_vehicle)) + Number(item?.loading_price) + Number(item?.unloading_price)
                                      data["categories"][index]["total_offer_price"] = total
                                      set_new_load_detail(data)
                                    }}
                                    // updateValue={(params)=>{
                                    //   
                                    //   let data = { ...new_load_detail }
                                    //   let obj = data?.categories[index]
                                    //   obj[params?.key] = params.val
                                    //   set_new_load_detail(data)
                                    // }}
                                    index={index}
                                    postLoadMeta={postLoadMeta}
                                  />
                                </div>
                                : null
                            }
                          </div>
                          <div style={{
                            pointerEvents: !item?.is_checked ? "none" : "auto"
                          }} className="accordion-content">
                            {
                              new_load_detail?.transaction_type?.title == "Bid" ?
                                <div>
                                  <div className="inputField customEdit" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                    <div className="label">
                                      Max Bid (Without GST)
                                    </div>
                                    <div className="field">
                                      <NewInput
                                        onChange={(e) => {
                                          let data = { ...new_load_detail }
                                          let obj = data?.categories[index]
                                          obj["max_bid"] = e.target.value
                                          set_new_load_detail(data)
                                        }}
                                        disabled={!item?.is_checked}
                                        value={item?.max_bid ? item?.max_bid : ""}
                                        showEnd={"PKR"}
                                        placeholder={"Optional"}
                                        decimal={new_load_detail?.quote_by == "vehicle" ? false : true}

                                      />
                                    </div>
                                  </div>
                                </div> :
                                <div>
                                  <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                    <div className="label">
                                      Price / {new_load_detail?.is_unit_basis ? "Item" : "Vehicle"} (Without GST)
                                      <InfoIcon
                                        onClick={() => {
                                          setInfo("Price is inclusive of Free Time.");
                                          toggleModal(true);
                                        }}
                                      />
                                    </div>
                                    <div className="field">
                                      <NewInput
                                        onChange={(e) => {
                                          let data = { ...new_load_detail }
                                          let obj = data?.categories[index]
                                          obj["offer_price_per_vehicle"] = e.target.value
                                          if (new_load_detail?.is_unit_basis) {
                                            let total = (Number(e.target.value) * Number(item?.unit_quantity)) + Number(item?.loading_price) + Number(item?.unloading_price)
                                            obj["total_offer_price"] = total
                                          } else {
                                            let total = (Number(e.target.value) * Number(item?.vehicle_quantity)) + Number(item?.loading_price) + Number(item?.unloading_price)
                                            obj["total_offer_price"] = total
                                          }
                                          set_new_load_detail(data)
                                        }}
                                        disabled={!item?.is_checked}
                                        value={item?.offer_price_per_vehicle > 0 ? item?.offer_price_per_vehicle : ""}
                                        showEnd={"PKR"}
                                        decimal={new_load_detail?.is_unit_basis ? true : false}

                                      />
                                    </div>
                                  </div>

                                  {item?.is_twin_load ?
                                    <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                      <div className="label">
                                        Price / Container (Without GST)
                                      </div>
                                      <div className="field priceFieldDisabled customDisableClass2">
                                        <NewInput
                                          value={numeral(Number(item?.offer_price_per_vehicle) / 2).format(
                                            "0,0"
                                          )}
                                          disabled={true}
                                          aria-describedby="standard-weight-helper-text"
                                          showEnd={"PKR"}
                                        />
                                      </div>
                                    </div> : null
                                  }

                                  <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                    <div className="label">
                                      Detention Rate (Without GST)
                                      <InfoIcon
                                        onClick={() => {
                                          setInfo(
                                            "Detention is applicable on a daily basis from the expiry of free time."
                                          );
                                          toggleModal(true);
                                        }}
                                      />
                                    </div>
                                    <div className="field">
                                      <NewInput
                                        onChange={(e) => {
                                          let data = { ...new_load_detail }
                                          let obj = data?.categories[index]
                                          obj["detention_rate"] = e.target.value
                                          set_new_load_detail(data)
                                        }}
                                        disabled={!item?.is_checked}
                                        value={item?.detention_rate > 0 ? item?.detention_rate : ""}
                                        showEnd={"PKR"}
                                        placeholder={'Optional'}
                                        decimal={new_load_detail?.is_unit_basis ? true : false}
                                      />
                                    </div>
                                  </div>

                                  {
                                    item?.is_loading_required ?
                                      <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                        <div className="label">
                                          Loading Price / {new_load_detail?.is_unit_basis ? "Item" : "Vehicle"} (Without GST)
                                        </div>
                                        <div className="field">
                                          <NewInput
                                            onChange={(e) => {
                                              let data = { ...new_load_detail }
                                              let obj = data?.categories[index]
                                              obj["loading_price"] = e.target.value
                                              if (new_load_detail?.is_unit_basis) {
                                                let total = (Number(item?.offer_price_per_vehicle) * Number(item?.unit_quantity)) + Number(e.target.value) + Number(item?.unloading_price)
                                                obj["total_offer_price"] = total
                                              } else {
                                                let total = (Number(item?.offer_price_per_vehicle) * Number(item?.vehicle_quantity)) + Number(e.target.value) + Number(item?.unloading_price)
                                                obj["total_offer_price"] = total
                                              }
                                              set_new_load_detail(data)
                                            }}
                                            disabled={!item?.is_checked}
                                            value={item?.loading_price > 0 ? item?.loading_price : ""}
                                            showEnd={"PKR"}
                                            decimal={new_load_detail?.is_unit_basis ? true : false}
                                          />
                                        </div>
                                      </div> : null
                                  }

                                  {
                                    item?.is_unloading_required ?
                                      <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                        <div className="label">
                                          Unloading Price / {new_load_detail?.is_unit_basis ? "Item" : "Vehicle"} (Without GST)
                                        </div>
                                        <div className="field">
                                          <NewInput
                                            onChange={(e) => {
                                              let data = { ...new_load_detail }
                                              let obj = data?.categories[index]
                                              obj["unloading_price"] = e.target.value
                                              if (new_load_detail?.is_unit_basis) {
                                                let total = (Number(item?.offer_price_per_vehicle) * Number(item?.unit_quantity)) + Number(item?.loading_price) + Number(e.target.value)
                                                obj["total_offer_price"] = total
                                              } else {
                                                let total = (Number(item?.offer_price_per_vehicle) * Number(item?.vehicle_quantity)) + Number(item?.loading_price) + Number(e.target.value)
                                                obj["total_offer_price"] = total
                                              }
                                              set_new_load_detail(data)
                                            }}
                                            disabled={!item?.is_checked}
                                            value={item?.unloading_price > 0 ? item?.unloading_price : ""}
                                            showEnd={"PKR"}
                                            decimal={new_load_detail?.is_unit_basis ? true : false}

                                          />
                                        </div>
                                      </div> : null
                                  }

                                  <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                    <div className="label">
                                      Total Price (Without GST)
                                    </div>
                                    <div className="field">
                                      <div className="fw-5">
                                        {numeral(item?.total_offer_price).format('0,0')} PKR
                                        {/* {numeral((Number(item?.offer_price_per_vehicle) * Number(item?.vehicle_quantity)) + Number(item?.loading_price) + Number(item?.unloading_price)).format('0,0')} PKR */}
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="simpleLabel fw-5">
                                      Other Additional Charges (if applicable)
                                    </div>
                                    <div
                                      className="textareaWrapper"
                                    >
                                      <textarea
                                        style={{
                                          outline: "none",
                                        }}
                                        maxLength={250}
                                        disabled={!item?.is_checked}
                                        onChange={(e) => {
                                          let data = { ...new_load_detail }
                                          let obj = data?.categories[index]
                                          obj["detention_additional_charges"] = e.target.value
                                          set_new_load_detail(data)
                                        }}
                                        value={
                                          item?.detention_additional_charges
                                            ? item?.detention_additional_charges
                                            : ""
                                        }
                                        id={"cargo_description"}
                                        name={"cargo_description"}
                                        placeholder="Optional"
                                      />
                                    </div>
                                  </div>
                                </div>
                            }
                            <ExtraDetils
                              item={item}
                              details={null}
                              is_recipient={false}
                            />
                          </div>
                        </Accordion>
                      </div>
                    )
                  })
                }
              </div> :
              <div>
                <div className="accordion-container">
                  <Accordion className="accordion-background">
                    <CategoryCargoHeading loadDetail={loadDetail} is_recipient={false} />
                    <div className="accordion-content">
                      {/* <ThreeColumnRTL loadDetail={loadDetail} is_recipient={false} /> */}
                      {/* <TwoColumnRTLWeight forward={true} type={params?.type} loadDetail={loadDetail} is_recipient={false} /> */}

                      {
                        new_load_detail?.load_type?.title === LOAD_TYPE?.bulk && new_load_detail?.quote_by == "weight_volume" ?
                          <div>
                            <div className="titleSection">Vehicle Type</div>
                            <CustomizedSelects
                              selectedValue={new_load_detail?.preferred_vehicle?.id}
                              values={
                                new_load_detail?.preferred_vehicle?.id ? allPreferredVehicleTypes : []
                              }
                              disabled={true}
                              modalState={true}
                              placeholder={"Select Vehicle Type"}
                              handleModal={(value: boolean) => {
                                setShowPreferredVehicleTypeModal(value);
                              }}
                            // classProp="mb-0"
                            ></CustomizedSelects>
                          </div> : null
                      }

                      {
                        new_load_detail?.load_type?.title === LOAD_TYPE?.bulk ?
                          <div>
                            <div className="titleSection">
                              Packaging Type
                            </div>

                            <CustomizedSelects
                              classProp="mb-0"
                              placeholder={"Select Packaging Type"}
                              selectedValue={new_load_detail?.package_type?.id}
                              values={
                                new_load_detail?.package_type ? postLoadMeta?.package_types : []
                              }
                              disabled={true}
                              modalState={true}
                              handleModal={(value: boolean) => {
                                setPackageType(new_load_detail?.package_type)
                                set_current_index_package_type(null)
                                setPackageModal(value);
                              }}
                            ></CustomizedSelects>
                            {new_load_detail?.package_type?.title === "Other" && (
                              <div className="inputField" style={{ marginTop: '1rem', marginBottom: 20 }}>
                                <div className="label">Other package type</div>
                                <div className="field">
                                  <SimpleInput
                                    onChange={(e) => {
                                      if (e.target.value.length) {
                                        set_package_type_validation2(false)
                                      } else {
                                        set_package_type_validation2(true)
                                      }
                                      set_new_load_detail({
                                        ...new_load_detail,
                                        package_type_other: e.target.value
                                      })
                                    }}
                                    id="package_type_other"
                                    name="package_type_other"
                                    value={new_load_detail?.package_type_other}
                                    type="text"
                                    placeholder="Type your Packaging type here"
                                    style={{ height: 41, width: "100%", marginBottom: 0, marginTop: '0.75rem' }}
                                    aria-describedby="standard-weight-helper-text"
                                  />
                                </div>
                              </div>
                            )}
                          </div> : null
                      }
                      <div style={{
                        marginTop: 20
                      }}>
                        {/* <SimpleInput type="text" value={new_load_detail?.price_unit} onChange={(e)=>{set_new_load_detail({...new_load_detail,price_unit:e.target.value})}} /> */}
                        <RenderFields
                          details={new_load_detail}
                          setDetils={set_new_load_detail}
                          toggleModal={toggleModal}
                          setInfo={setInfo}
                          is_unit_basis={new_load_detail?.is_unit_basis}
                          handleCHangePrices={handleCHangePrices}
                        />
                      </div>
                    </div>
                    <div className="accordion-content">
                      {
                        new_load_detail?.transaction_type?.title == "Bid" ?
                          <div>
                            <div className="inputField customEdit" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                              <div className="label">
                                Max Bid (Without GST)
                              </div>
                              <div className="field">
                                <NewInput
                                  onChange={(e) => {
                                    let data = { ...new_load_detail }
                                    data["max_bid"] = e.target.value
                                    set_new_load_detail(data)
                                  }}
                                  value={new_load_detail.max_bid ? new_load_detail.max_bid : ""}
                                  showEnd={"PKR"}
                                  placeholder={"Optional"}
                                  decimal={new_load_detail?.quote_by == "vehicle" ? false : true}

                                />
                              </div>
                            </div>
                          </div> :
                          <div>
                            <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                              <div className="label">
                                Price / {new_load_detail?.price_unit} (Without GST)
                                <InfoIcon
                                  onClick={() => {
                                    setInfo("Price is inclusive of Free Time.");
                                    toggleModal(true);
                                  }}
                                />
                              </div>
                              <div className="field">
                                <NewInput
                                  onChange={(e) => {
                                    handleCHangePrices("price_per_freight", e.target.value)
                                  }}
                                  value={new_load_detail?.price_per_freight ? new_load_detail?.price_per_freight : ""}
                                  showEnd={"PKR"}
                                  decimal={true}
                                />
                              </div>
                            </div>

                            <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                              <div className="label">
                                Detention Rate (Without GST)
                                <InfoIcon
                                  onClick={() => {
                                    setInfo(
                                      "Detention is applicable on a daily basis from the expiry of free time."
                                    );
                                    toggleModal(true);
                                  }}
                                />
                              </div>
                              <div className="field">
                                <NewInput
                                  onChange={(e) => {
                                    let data = { ...new_load_detail }
                                    data["detention_rate"] = e.target.value
                                    set_new_load_detail(data)
                                  }}
                                  value={new_load_detail?.detention_rate}
                                  showEnd={"PKR"}
                                  placeholder={'Optional'}
                                  decimal={new_load_detail?.quote_by == "vehicle" ? false : true}

                                />
                              </div>
                            </div>

                            {
                              new_load_detail?.is_loading_required ?
                                <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                  <div className="label">
                                    Loading Price / {new_load_detail?.price_unit} (Without GST)
                                  </div>
                                  <div className="field">
                                    <NewInput
                                      onChange={(e) => {
                                        handleCHangePrices("loading_price_per_freight", e.target.value)
                                        return
                                        let data = { ...new_load_detail }
                                        data["loading_price_per_freight"] = e.target.value
                                        let total = (Number(new_load_detail?.price_per_freight) * Number(new_load_detail.total_freight_ton)) + Number(e.target.value) + Number(new_load_detail?.unloading_price_per_freight)
                                        data["total_offer_price"] = total
                                        set_new_load_detail(data)
                                      }}
                                      value={new_load_detail?.loading_price_per_freight ? new_load_detail?.loading_price_per_freight : ""}
                                      showEnd={"PKR"}
                                      decimal={true}
                                    />
                                  </div>
                                </div> : null
                            }

                            {
                              new_load_detail?.is_unloading_required ?
                                <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                                  <div className="label">
                                    Unloading Price / {new_load_detail?.price_unit} (Without GST)
                                  </div>
                                  <div className="field">
                                    <NewInput
                                      onChange={(e) => {
                                        handleCHangePrices("unloading_price_per_freight", e.target.value)
                                        return
                                        let data = { ...new_load_detail }
                                        data["unloading_price_per_freight"] = e.target.value
                                        let total = (Number(new_load_detail?.price_per_freight) * Number(new_load_detail.total_freight_ton)) + Number(new_load_detail?.loading_price_per_freight) + Number(e.target.value)
                                        data["total_offer_price"] = total
                                        set_new_load_detail(data)
                                      }}
                                      value={new_load_detail?.unloading_price_per_freight ? new_load_detail?.unloading_price_per_freight : ""}
                                      showEnd={"PKR"}
                                      decimal={true}
                                    />
                                  </div>
                                </div> : null
                            }

                            <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                              <div className="label">
                                Total Price (Without GST)
                              </div>
                              <div className="field">
                                <div className="fw-5">
                                  {numeral(new_load_detail?.total_offer_price).format('0,0')} PKR
                                  {/* {numeral((Number(item?.offer_price_per_vehicle) * Number(item?.vehicle_quantity)) + Number(item?.loading_price) + Number(item?.unloading_price)).format('0,0')} PKR */}
                                </div>
                              </div>
                            </div>

                            <div>
                              <div className="simpleLabel fw-5">
                                Other Additional Charges (if applicable)
                              </div>
                              <div
                                className="textareaWrapper"
                              >
                                <textarea
                                  style={{
                                    outline: "none",
                                  }}
                                  maxLength={250}
                                  onChange={(e) => {
                                    let data = { ...new_load_detail }
                                    data["detention_additional_charges"] = e.target.value
                                    set_new_load_detail(data)
                                  }}
                                  value={
                                    new_load_detail?.detention_additional_charges
                                      ? new_load_detail?.detention_additional_charges
                                      : ""
                                  }
                                  id={"cargo_description"}
                                  name={"cargo_description"}
                                  placeholder="Optional"
                                />
                              </div>
                            </div>

                          </div>
                      }

                      <ExtraDetils
                        item={null}
                        details={new_load_detail}
                        is_recipient={false}
                      />
                    </div>
                  </Accordion>
                </div>
              </div>
          }


          {/* show info icon watermark */}
          <CustomModal
            modalStyle={{
              width: 300,
              minHeight: 100,
            }}
            className="infoModal"
            showModal={showModalWaterMark}
            toggleModal={setShowModalWaterMark}
          >
            <div>
              <InfoIcon className="icon" />
              <p className="title">Information</p>
              <p>
                {showInfoWaterMark}
              </p>
            </div>
          </CustomModal>


          <CustomModal
            modalStyle={{
              width: 300,
              minHeight: 100,
            }}
            showModal={showModal}
            toggleModal={toggleModal}
          >
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InfoIcon color="primary" style={{ fontSize: 40 }} />
              <CustomText.OpenSansRegular>{info}</CustomText.OpenSansRegular>
            </div>
          </CustomModal>

          {/* select vevhile modal */}
          <SimpleModal
            showModal={showVehicleType}
            selected={selectVehicleTypeObj?.id}
            title={"Select Vehicle Type"}
            setType={handleChangeVehicle}
            handleModal={(value: boolean) => {
              setShowVehicleType(value);
            }}
            values={allVehicleTypes}
          ></SimpleModal>

          {/* select container type modal */}
          <SimpleModal
            showModal={showContainerModal}
            selected={containerType?.id}
            title={"Select Container Type"}
            setType={handleChangeContainer}
            handleModal={(value: boolean) => {
              setShowContainerModal(value);
            }}
            values={allContainerType}
          ></SimpleModal>

          {/* package type dropdworn modal */}
          <SimpleModal
            showModal={packageModal}
            selected={packageType?.id}
            title={"Select Packaging Type"}
            setType={handleChangePackage}
            handleModal={(value: boolean) => {
              setPackageModal(value);
            }}
            values={
              postLoadMeta?.package_types && postLoadMeta?.package_types.length
                ? postLoadMeta?.package_types.filter(
                  (val) => val.type === "package"
                )
                : []
            }
          ></SimpleModal>

          {/* preferred vehicle modal  */}
          <SimpleModal
            showModal={showPreferredVehicleTypeModal}
            selected={new_load_detail?.preferred_vehicle?.id}
            title={"Select Vehicle Type"}
            setType={handleChangePreferredVehicleType}
            handleModal={(value: boolean) => {
              setShowPreferredVehicleTypeModal(value);
            }}
            values={allPreferredVehicleTypes}
          ></SimpleModal>

        </div >
        <PrimaryButton
          title="Continue"
          disabled={handleDisable() || isError || !new_load_detail?.title || isDateError || isGeneralError || package_type_validation || package_type_validation2 || gross_weight_validation || handleDisableItemBasis() || max_vehicle_weight}
          onClick={() => {
            handleContinue()
          }}
        />

      </TitleLayout >
    );
  } else {
    return <div></div>;
  }
};
export default Page;
