import { Colors } from "@temp/assets";
import { CustomText } from "@temp/components";
import { getFullTime } from "@temp/utils/helper";
import moment from "moment";
import React from "react";
import '../LoadDetailPoster/style.scss'

export const RecipientStatus = ({ style = {}, status, decline_by, readOnly = false, loadDetail, from, is_recipient = false, item }) => {
  return (
    <div
      className="recipient-status"
      style={{
        border: '1px solid',
        backgroundColor: Colors.White,
        borderColor: status == 2 && (decline_by == "Recipient" || decline_by == "Poster")
          ? Colors.Red
          : (status == 1 || readOnly)
            ? Colors.Green
            : status === 0
              ? Colors.NavyBlue
              : status == 4
                ? Colors.Orange
                  : null,
        ...style
      }}
    >
      <span
        style={{
          fontSize: '0.75rem',
          color:
            status == 2 && (decline_by == "Recipient" || decline_by == "Poster")
              ? Colors.Red
              : (status === 1 || readOnly)
                ? Colors.Primary
                : status == 0
                  ? Colors.NavyBlue
                  : status == 4
                    ? Colors.Orange
                      : null,
        }} className="titleName"
      >
        {status === 2 && decline_by == "Recipient" && from == "poster"
          ? "Declined by Recipient"
          : status === 2 && decline_by == "Recipient" && from == "recipient" ?
            "Declined"
            : status == 2 && decline_by == "Poster"
              ? "Declined by Poster"
              : (status == 1 || readOnly)
                ? `${loadDetail?.inquiry_type?.title == "Contract Rate" ? 'Responded' : 'Responded'}`
                : status == 0
                  ? `${Number(moment(loadDetail?.deadline_at).format('x')) > getFullTime(new Date().getTime()) ? "Awaiting Response" : "No Response"}`
                  : status == 4
                    ? "Awarded"
                    : null}
      </span>
      {
        is_recipient &&
        <>
          <span style={{
            color:
              status == 2 && (decline_by == "Recipient" || decline_by == "Poster")
                ? Colors.Red
                : (status === 1 || readOnly)
                  ? Colors.Primary
                  : status == 0
                    ? Colors.NavyBlue
                    : status == 4
                      ? Colors.Orange
                      : null,
          }} className="seperator">/</span>
          <span className="urduLabel"
            style={{
              color:
                status == 2 && (decline_by == "Recipient" || decline_by == "Poster")
                  ? Colors.Red
                  : (status === 1 || readOnly)
                    ? Colors.Primary
                    : status == 0
                      ? Colors.NavyBlue
                      : status == 4
                        ? Colors.Orange
                        : null,
            }}
          >
            {status === 2 && decline_by == "Recipient" && from == "poster"
              ? ""
              : status === 2 && decline_by == "Recipient" && from == "recipient" ?
                "مسترد"
                : status == 2 && decline_by == "Poster"
                  ? "پوسٹر کے ذریعے مسترد کر دیا گیا"
                  : (status == 1 || readOnly)
                    ? `${loadDetail?.inquiry_type?.title == "Contract Rate" ? 'جواب دیا' : 'جواب دیا'}`
                    : status == 0
                      ? `${Number(moment(loadDetail?.deadline_at).format('x')) > getFullTime(new Date().getTime()) ? "" : ""}`
                      : status == 4
                        ? "نوازا"
                        : null}
          </span>
        </>
      }
    </div>
  );
};
