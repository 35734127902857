import React, { useEffect, useState } from "react";
import { IContractDetailRecipientPageProps } from "./types";
import "./style.scss";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
var numeral = require("numeral");

import {
  CustomText,
  PrimaryButton,
  TitleLayout,
} from "@components";
import { useHistory, useLocation } from "react-router-dom";
import { Checkbox } from "@mui/material/";
import { RecipientStatus } from "../components";
import { Images } from "@temp/assets";
import { showMessage } from "@temp/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { LoadAction, ProfileAction } from "@temp/store/actions";

const Page: React.FC<IContractDetailRecipientPageProps> = (props) => {
  const history = useHistory()
  const location = useLocation()
  const { state } = location;
  const dispatch = useDispatch()
  const selectedCategries = useSelector((state: RootState) => state.Load.selectedCategries) || []
  const handleChangeCategory = (category) => {
    let new_data = [...selectedCategries]
    let find = new_data.find(item => item?.id == category?.id)
    let find_index = new_data.findIndex(item => item?.id == category?.id)
    if (new_data.length > 0) {
      let new_recipient = new_data[0]?.new_recipients
      if (find) {
        new_data[find_index]["new_recipients"] = null
        new_data.splice(find_index, 1)
      } else {
        let already_awarded = category?.recipients.find(item => item?.recipient?.id == new_recipient?.recipient?.id)
        if (already_awarded) {
          let data = { ...category, new_recipients: already_awarded }
          new_data.push(data)
        } else {
          if (new_data[0]?.new_recipients) {
            showMessage.current({ message: 'Selected recipient is not awarded in this category', status: 'error' })
          } else {
            showMessage.current({
              message:
                // 'Please select recipient first from selected category', 
                "Please select recipient from selected category",
              status: 'error'
            })
          }
        }
      }
    } else {
      if (find) {
        new_data[find_index]["new_recipients"] = null
        new_data.splice(find_index, 1)
      } else {
        new_data.push(category)
      }
    }
    dispatch(LoadAction.setRequestVehicleCategory([...new_data]))
  }

  useEffect(()=>{
    dispatch(ProfileAction.JobLoadMeta(0));
  },[])

  // const [selectedRecipient, setSelctedRecipient] = useState([])

  const handleChangeRecipient = (category, recipient) => {
    let new_data = [...selectedCategries]
    let find = new_data.find(item => item?.id == category?.id)
    let find_recipient = find?.new_recipients?.recipient?.id == recipient?.recipient?.id
    // let find_index = find?.new_recipients?.findIndex(item => item?.id == recipient?.id)
    // if(new_data.length> 0 && new_data[0]?.new_recipients){
    let recipt = find?.new_recipients?.recipient
    if (new_data?.length > 1) {
      if (find_recipient) {
        // find['new_recipients'] = null
        // alert('herere')
        let find_cat = new_data.findIndex(item => item?.id == category?.id)
        new_data.splice(find_cat, 1)



      } else {
        if (recipient?.recipient?.id == recipt?.id) {
          find['new_recipients'] = {}
          find['new_recipients'] = recipient
        } else {
          showMessage.current({ message: 'You can not select another recipient.', status: 'error' })
        }
      }
    } else {
      if (find_recipient) {
        find['new_recipients'] = null
      } else {
        find['new_recipients'] = {}
        find['new_recipients'] = recipient
      }

    }
    // }

    // else{
    //   if (find_recipient) {
    //     find['new_recipients'] = null
    //   } else {
    //     find['new_recipients'] = {}
    //     find['new_recipients'] = recipient
    //   }
    // }
    dispatch(LoadAction.setRequestVehicleCategory([...new_data]))
  }

  const RenderRTL = ({ title, val }) => {
    return (
      <div style={{
        alignItems: 'center',
        marginBottom: '1rem'
      }} className="details-Content">
        <div className="details-rtl">
          <div className="grayLabel">{title}</div>
        </div>
        <div style={{
          alignItems: 'center',
          textAlign: 'right'
        }} className="details-rtl">
          <div className="fw-5">{val}</div>
        </div>
      </div>
    );
  }

  const RenderCategory = ({ category, findCategory, index, is_category, categoryLength }) => {
    
    return (
      <div className="request-vehicle-category-container">
        <div className="request-vehicle-category-header">
          <Checkbox
            checked={findCategory ? true : false}
            style={{ color: "#7ac251" }}
            onClick={() => {
              handleChangeCategory(category)
            }}
          />
          {
            is_category ?
              <>
                {
                  category?.is_unit_basis ?
                    <div className="fw-5">{categoryLength > 1 ? `Item Category ${index + 1}` : "Item Category"}</div>
                    :
                    <div className="fw-5">{categoryLength > 1 ? `Category ${index + 1}` : "Category"}</div>
                }
              </>
              // <CustomText.OpenSansBold> Category {index + 1}</CustomText.OpenSansBold> 
              :
              <div className="fw-5"> Cargo Details</div>
          }
        </div>
        <div className="request-vehicle-info-container">
          {
            category?.quote_by == "vehicle" ?
              <div>
                <RenderRTL title={category?.is_unit_basis ? "Dimensions" : "Vehicle Type"} val={category?.is_unit_basis ? ` ${category?.dimensions} ${category?.unit_dimension_value}  ` : category?.vehicle?.title} />
                <RenderRTL
                  title={category?.is_unit_basis ? "Item quantity"
                    : category?.vehicle_quantity ?
                      "Estimated Number Of Vehicles" : ""}
                  val={category?.is_unit_basis ? category?.unit_quantity : category?.vehicle_quantity} />
              </div> :
              <div>
                <RenderRTL title={"Package Type"} val={category?.package_type?.title} />
                <RenderRTL title={"Max Cargo Weight"} val={category?.total_freight_ton + ` ${category?.price_unit}`} />
              </div>
          }
        </div>

        <div style={{ padding: '0 1rem' }}>
          {
            category?.recipients.map((recipient) => {
              let findRecipient = findCategory?.new_recipients?.recipient?.id == recipient?.recipient?.id
              return (
                <>
                  <div className="detail-category-recepient-container">
                    <Checkbox
                      checked={findRecipient ? true : false}
                      style={{ color: "#7ac251" }}
                      disabled={findCategory ? false : true}
                      onClick={() => {
                        handleChangeRecipient(category, recipient)
                      }}
                    />
                    <div className="load-detail-user">
                      <img
                        alt="profile"
                        className="load-detail-user"
                        src={recipient?.recipient?.picture || Images.myProfileImage}
                      />
                    </div>
                    <div className="recepient-detail-container">
                      <div className="width-100">
                        {/* top row  */}
                        <div className="flexRow100">
                          <div>
                            <CustomText.OpenSansRegular>
                              {recipient?.recipient?.name || "UnRegistered User"}
                            </CustomText.OpenSansRegular>
                          </div>
                        </div>
                        {/* bottom row  */}
                        <div className="recepient-status-container">
                          <RecipientStatus
                            status={recipient?.status}
                            loadDetail={state?.data}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div className="chip">
                              {
                                category?.quote_by == "vehicle" && !category?.is_unit_basis ?
                                `${numeral(recipient?.bid?.bid_price_per_vehicle).format('0,0')} PKR / Vehicle` :

                                category?.is_unit_basis ? 
                                `${numeral(recipient?.bid?.bid_price_per_vehicle).format('0,0.00')} PKR / Item` :

                                `${numeral(recipient?.bid?.price_per_freight).format('0,0.00')} PKR / ${category?.price_unit}`

                                  // `${numeral(recipient?.bid?.bid_price_per_vehicle).format('0,0')} PKR / ${category?.is_unit_basis ? "Item" : "Vehicle"}` :
                                  // `${numeral(recipient?.bid?.price_per_freight).format('0,0.00')} PKR / ${category?.price_unit}`
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          }
        </div>
      </div>
    )
  }
  return (
    <TitleLayout titleOnly titleOnlyText={`Contract ID - ${state?.data?.id}`}>
      <div className="vehicleContentWrapper" style={{
        height: 'calc(100vh - 100px)',
        overflow: 'auto'
      }}>

        <div className="request-vehicle-main-containers">
          <div className="category-location-header">
            <CustomText.OpenSansSemiBold textStyle={{
              fontSize: "1rem"
            }}>{`Location ${state?.location}`}</CustomText.OpenSansSemiBold>
          </div>
          <div>
            {
              state?.data?.quote_by == "vehicle" ?
                <CustomText.OpenSansBold>Categories</CustomText.OpenSansBold> : null
            }
            {
              state?.new_categories?.map((category, index) => {
                let findCategory = selectedCategries?.find(item => item?.id == category?.id)
                if (category?.quote_by === "vehicle" && category?.total_awarded > 0) {
                  return (
                    <RenderCategory
                      category={category}
                      findCategory={findCategory}
                      index={index}
                      is_category={true}
                      categoryLength={state?.new_categories.length} />
                  )
                } else if (category?.quote_by !== 'vehicle') {
                  return (
                    <RenderCategory
                      category={category}
                      findCategory={findCategory}
                      index={index}
                      is_category={false}
                    />
                  )
                }
              })
            }
          </div>
        </div>
        <PrimaryButton
          title="Continue"
          disabled={!selectedCategries.filter(item => item?.new_recipients).length}
          onClick={() => {
            history.push('request-vehicle', { data: { ...state?.data, contract_locations: [{ categories: selectedCategries, pickup: state?.location_data?.pickup, dropoff: state?.location_data?.dropoff }] } })
          }}
        />
      </div>
    </TitleLayout>
  );
};
export default Page;
