import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Colors } from "@temp/assets";
import { AuthAction, ProfileAction } from "../actions";
var INITIAL_STATE = {
  profile: {},
  isLoading: false,
  postLoad: {},
  activeDrawerTab: "",
  postLoadMeta: {},
  loadType: "Loads Received",
  inquiryType: "spot",
  activeCategory: "new",
  selectedValue: "All",
  historyLoadType: "Loads Received",
  historyInquiryType: "spot",
  active_loads: [],
  active_load_current_page: 1,
  active_load_last_page: 1,
  activeLoadType: "Loads Received",
  // activeLoadType: "received",
  reportsData: {},
  dataSpot: null,
  dataContract: null,
  bar_chart_data: null,
  activeCategoryReports: "posted",
  // activeDateFilter: "weekly",
  activeDateFilter: "today",
  fromResponseDate: new Date(),
  responseDate: new Date(),
  // vehicle
  activeCategoryVehicle: "posted",
  activeDateVehicle: "today",
  fromResponseDateVehicle: new Date(),
  responseDateVehicle: new Date(),
  // load detail
  activeCategoryDetail: "posted",
  activeDateDetail: "today",
  fromResponseDateDetail: new Date(),
  responseDateDetail: new Date(),
  mgExpressData: {},
  // new dashboard data
  dashboardData: {},
  chart_data: [],
  listing_data: [],
  load_detail_data: [],
  dashboard_vehicle_current_page: 1,
  dashboard_vehicle_last_page: 1,
  dashboardArry: [],
  new_dashboard_report: [],
  new_dashboard_load_detail: [],
  counts: {}
};
function Reducer() {
  var _action$payload, _action$payload2, _action$payload3, _action$payload4, _action$payload5, _action$payload6, _action$payload7, _action$payload8, _action$payload9, _action$payload10, _action$payload11, _action$payload12, _action$payload13, _action$payload13$spo, _action$payload14, _action$payload14$spo, _action$payload15, _action$payload15$spo, _action$payload16, _action$payload16$spo, _action$payload17, _action$payload17$con, _action$payload18, _action$payload18$con, _action$payload19, _action$payload19$con, _action$payload20, _action$payload21, _action$payload21$cha, _action$payload22, _action$payload22$cha, _action$payload23, _action$payload23$cha, _action$payload24, _action$payload24$cha, _action$payload25, _action$payload26, _action$payload27, _action$payload28, _action$payload29, _action$payload30, _action$payload31, _action$payload32, _action$payload33, _action$payload34, _action$payload35, _action$payload36, _action$payload37, _action$payload38, _action$payload39, _action$payload40, _action$payload41, _action$payload41$met, _action$payload42, _action$payload42$met, _action$payload43, _action$payload44, _action$payload44$Pos;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ProfileAction.SET_REPORTS_FILTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeCategoryReports: action === null || action === void 0 ? void 0 : (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.activeCategoryReports,
        activeDateFilter: action === null || action === void 0 ? void 0 : (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.activeDateFilter,
        fromResponseDate: action === null || action === void 0 ? void 0 : (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.fromResponseDate,
        responseDate: action === null || action === void 0 ? void 0 : (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.responseDate
      });
    case ProfileAction.SET_VEHICLE_FILTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeCategoryVehicle: action === null || action === void 0 ? void 0 : (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.activeCategoryVehicle,
        activeDateVehicle: action === null || action === void 0 ? void 0 : (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.activeDateVehicle,
        fromResponseDateVehicle: action === null || action === void 0 ? void 0 : (_action$payload7 = action.payload) === null || _action$payload7 === void 0 ? void 0 : _action$payload7.fromResponseDateVehicle,
        responseDateVehicle: action === null || action === void 0 ? void 0 : (_action$payload8 = action.payload) === null || _action$payload8 === void 0 ? void 0 : _action$payload8.responseDateVehicle
      });
    case ProfileAction.SET_DETAIL_FILTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeCategoryDetail: action === null || action === void 0 ? void 0 : (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.activeCategoryDetail,
        activeDateDetail: action === null || action === void 0 ? void 0 : (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.activeDateDetail,
        fromResponseDateDetail: action === null || action === void 0 ? void 0 : (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : _action$payload11.fromResponseDateDetail,
        responseDateDetail: action === null || action === void 0 ? void 0 : (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : _action$payload12.responseDateDetail
      });
    case ProfileAction.DASHBOARD_REPORTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.DASHBOARD_REPORTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        reportsData: action.payload,
        dataSpot: {
          labels: ["Containerized", "Non-Containerized", "Bid", "Offer"],
          datasets: [{
            label: "Spot Data",
            data: [(_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : (_action$payload13$spo = _action$payload13.spot) === null || _action$payload13$spo === void 0 ? void 0 : _action$payload13$spo.containerized, (_action$payload14 = action.payload) === null || _action$payload14 === void 0 ? void 0 : (_action$payload14$spo = _action$payload14.spot) === null || _action$payload14$spo === void 0 ? void 0 : _action$payload14$spo.non_containerized, (_action$payload15 = action.payload) === null || _action$payload15 === void 0 ? void 0 : (_action$payload15$spo = _action$payload15.spot) === null || _action$payload15$spo === void 0 ? void 0 : _action$payload15$spo.bid_counts, (_action$payload16 = action.payload) === null || _action$payload16 === void 0 ? void 0 : (_action$payload16$spo = _action$payload16.spot) === null || _action$payload16$spo === void 0 ? void 0 : _action$payload16$spo.offer_counts],
            backgroundColor: [Colors.Primary, Colors.NavyBlue, Colors.Yellow, Colors.LightWhite],
            hoverOffset: 4
          }],
          key: new Date().getTime()
        },
        dataContract: {
          labels: ["Containerized", "Non-Containerized", "Bid"],
          datasets: [{
            label: "Contract Data",
            data: [(_action$payload17 = action.payload) === null || _action$payload17 === void 0 ? void 0 : (_action$payload17$con = _action$payload17.contract) === null || _action$payload17$con === void 0 ? void 0 : _action$payload17$con.containerized, (_action$payload18 = action.payload) === null || _action$payload18 === void 0 ? void 0 : (_action$payload18$con = _action$payload18.contract) === null || _action$payload18$con === void 0 ? void 0 : _action$payload18$con.non_containerized, (_action$payload19 = action.payload) === null || _action$payload19 === void 0 ? void 0 : (_action$payload19$con = _action$payload19.contract) === null || _action$payload19$con === void 0 ? void 0 : _action$payload19$con.bid_counts],
            backgroundColor: [Colors.Primary, Colors.NavyBlue, Colors.Yellow],
            hoverOffset: 4
          }],
          key: new Date().getTime()
        },
        bar_chart_data: {
          labels: (_action$payload20 = action.payload) === null || _action$payload20 === void 0 ? void 0 : _action$payload20.labels,
          datasets: [{
            label: 'Containerized',
            data: action === null || action === void 0 ? void 0 : (_action$payload21 = action.payload) === null || _action$payload21 === void 0 ? void 0 : (_action$payload21$cha = _action$payload21.chart_data) === null || _action$payload21$cha === void 0 ? void 0 : _action$payload21$cha.containerized,
            backgroundColor: Colors.Primary,
            borderColor: Colors.Primary
          }, {
            label: 'Non-Containerized',
            data: action === null || action === void 0 ? void 0 : (_action$payload22 = action.payload) === null || _action$payload22 === void 0 ? void 0 : (_action$payload22$cha = _action$payload22.chart_data) === null || _action$payload22$cha === void 0 ? void 0 : _action$payload22$cha.non_containerized,
            backgroundColor: Colors.NavyBlue,
            borderColor: Colors.NavyBlue
          }, {
            label: 'Bid',
            data: action === null || action === void 0 ? void 0 : (_action$payload23 = action.payload) === null || _action$payload23 === void 0 ? void 0 : (_action$payload23$cha = _action$payload23.chart_data) === null || _action$payload23$cha === void 0 ? void 0 : _action$payload23$cha.bid,
            backgroundColor: Colors.Yellow,
            borderColor: Colors.Yellow
          }, {
            label: 'Offer',
            data: action === null || action === void 0 ? void 0 : (_action$payload24 = action.payload) === null || _action$payload24 === void 0 ? void 0 : (_action$payload24$cha = _action$payload24.chart_data) === null || _action$payload24$cha === void 0 ? void 0 : _action$payload24$cha.offer,
            backgroundColor: Colors.LightWhite,
            borderColor: Colors.LightWhite
          }]
        },
        isLoading: false
      });
    case ProfileAction.DASHBOARD_REPORTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.DOWNLOAD_CSV_REPORT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.DOWNLOAD_CSV_REPORT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.DOWNLOAD_CSV_REPORT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.UPDATE_LOCATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.UPDATE_LOCATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.UPDATE_LOCATION_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.UPDATE_ACTIVE_FILTERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeLoadType: action === null || action === void 0 ? void 0 : (_action$payload25 = action.payload) === null || _action$payload25 === void 0 ? void 0 : _action$payload25.loadType
      });
    case ProfileAction.GET_ACTIVE_LOADS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: action === null || action === void 0 ? void 0 : (_action$payload26 = action.payload) === null || _action$payload26 === void 0 ? void 0 : _action$payload26.refresh,
        active_loads: (_action$payload27 = action.payload) !== null && _action$payload27 !== void 0 && _action$payload27.refresh || action !== null && action !== void 0 && (_action$payload28 = action.payload) !== null && _action$payload28 !== void 0 && _action$payload28.fromSearch ? [] : _toConsumableArray(state === null || state === void 0 ? void 0 : state.active_loads)
      });
    case ProfileAction.GET_ACTIVE_LOADS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        active_loads: action !== null && action !== void 0 && (_action$payload29 = action.payload) !== null && _action$payload29 !== void 0 && _action$payload29.fromSearch ? action === null || action === void 0 ? void 0 : action.payload.data : [].concat(_toConsumableArray(state === null || state === void 0 ? void 0 : state.active_loads), _toConsumableArray(action === null || action === void 0 ? void 0 : (_action$payload30 = action.payload) === null || _action$payload30 === void 0 ? void 0 : _action$payload30.data)),
        active_load_current_page: action === null || action === void 0 ? void 0 : (_action$payload31 = action.payload) === null || _action$payload31 === void 0 ? void 0 : _action$payload31.meta.current_page,
        active_load_last_page: action === null || action === void 0 ? void 0 : (_action$payload32 = action.payload) === null || _action$payload32 === void 0 ? void 0 : _action$payload32.meta.last_page
      });
    case ProfileAction.GET_ACTIVE_LOADS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.TOGGLE_LOADER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.UPDATE_FCM_TOKEN[0]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.UPDATE_FCM_TOKEN[1]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.UPDATE_FCM_TOKEN[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.POST_LOAD[0]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.POST_LOAD[1]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.POST_LOAD[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.UPLOAD_FILE[0]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.UPLOAD_FILE[1]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.UPLOAD_FILE[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.POST_LOAD_DATA_REMOVE:
      return _objectSpread(_objectSpread({}, state), {}, {
        postLoad: {}
      });
    case ProfileAction.JOB_LOAD_META[0]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.JOB_LOAD_META[1]:
      return _objectSpread(_objectSpread({}, state), {}, {
        postLoadMeta: action.payload,
        isLoading: false
      });
    case ProfileAction.JOB_LOAD_META[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.POST_LOAD_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        postLoad: _objectSpread(_objectSpread({}, state.postLoad), action.payload)
      });
    case ProfileAction.GET_PROFILE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true,
        postLoad: {}
      });
    case ProfileAction.GET_PROFILE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        profile: action.payload,
        isLoading: false
      });
    case ProfileAction.GET_PROFILE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // update profile //
    case ProfileAction.UPDATE_PROFILE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.UPDATE_PROFILE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.UPDATE_PROFILE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.SET_ACTIVE_DRAWER_TAB:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeDrawerTab: action.payload
      });
    case ProfileAction.CHANGE_PHONE_NUMBER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.CHANGE_PHONE_NUMBER_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.CHANGE_PHONE_NUMBER_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.CHANGE_PHONE_NUMBER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.CHANGE_PHONE_NUMBER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.CHANGE_PHONE_NUMBER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.CHANGE_PASSWORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.CHANGE_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.CHANGE_PASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.SET_DASHBOARD_FILTERS:
      var payload = action.payload;
      return _objectSpread(_objectSpread({}, state), {}, {
        loadType: payload === null || payload === void 0 ? void 0 : payload.loadType,
        inquiryType: payload === null || payload === void 0 ? void 0 : payload.inquiryType,
        activeCategory: payload === null || payload === void 0 ? void 0 : payload.activeCategory,
        selectedValue: payload === null || payload === void 0 ? void 0 : payload.selectedValue
      });
    case ProfileAction.UPDATE_HISTORY_FILTERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        historyLoadType: action === null || action === void 0 ? void 0 : (_action$payload33 = action.payload) === null || _action$payload33 === void 0 ? void 0 : _action$payload33.loadType,
        historyInquiryType: action === null || action === void 0 ? void 0 : (_action$payload34 = action.payload) === null || _action$payload34 === void 0 ? void 0 : _action$payload34.inquiryType
      });
    case ProfileAction.DEL_PROFILE_IMAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.DEL_PROFILE_IMAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        profile: action.payload,
        isLoading: false
      });
    case ProfileAction.DEL_PROFILE_IMAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case ProfileAction.DOCUMENTS_MG_EXPRESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case ProfileAction.DOCUMENTS_MG_EXPRESS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        mgExpressData: action.payload,
        isLoading: false
      });
    case ProfileAction.DOCUMENTS_MG_EXPRESS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // new dashboard data vehicle pagination
    case ProfileAction.DASHBOARD_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: action === null || action === void 0 ? void 0 : (_action$payload35 = action.payload) === null || _action$payload35 === void 0 ? void 0 : _action$payload35.refresh,
        // action?.payload?.fromSearch ||
        //   action?.payload?.search
        //   ? false : true,
        dashboardArry: action !== null && action !== void 0 && (_action$payload36 = action.payload) !== null && _action$payload36 !== void 0 && _action$payload36.fromSearch || action !== null && action !== void 0 && (_action$payload37 = action.payload) !== null && _action$payload37 !== void 0 && _action$payload37.search || (action === null || action === void 0 ? void 0 : (_action$payload38 = action.payload) === null || _action$payload38 === void 0 ? void 0 : _action$payload38.next_page) === 1 ? [] : _toConsumableArray(state === null || state === void 0 ? void 0 : state.dashboardArry),
        counts: action === null || action === void 0 ? void 0 : (_action$payload39 = action.payload) === null || _action$payload39 === void 0 ? void 0 : _action$payload39.counts
      });
    case ProfileAction.DASHBOARD_DATA_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        dashboardArry: [].concat(_toConsumableArray(state === null || state === void 0 ? void 0 : state.dashboardArry), _toConsumableArray(action === null || action === void 0 ? void 0 : (_action$payload40 = action.payload) === null || _action$payload40 === void 0 ? void 0 : _action$payload40.data)),
        isLoading: false,
        dashboard_vehicle_current_page: action === null || action === void 0 ? void 0 : (_action$payload41 = action.payload) === null || _action$payload41 === void 0 ? void 0 : (_action$payload41$met = _action$payload41.meta) === null || _action$payload41$met === void 0 ? void 0 : _action$payload41$met.current_page,
        dashboard_vehicle_last_page: action === null || action === void 0 ? void 0 : (_action$payload42 = action.payload) === null || _action$payload42 === void 0 ? void 0 : (_action$payload42$met = _action$payload42.meta) === null || _action$payload42$met === void 0 ? void 0 : _action$payload42$met.last_page,
        counts: action === null || action === void 0 ? void 0 : (_action$payload43 = action.payload) === null || _action$payload43 === void 0 ? void 0 : _action$payload43.counts
      });
    case ProfileAction.DASHBOARD_DATA_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // new dashboard report
    case ProfileAction.DASHBOARD_REPORT_NEW:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true,
        // new_dashboard_report: [...state?.new_dashboard_report],
        new_dashboard_report: state === null || state === void 0 ? void 0 : state.new_dashboard_report
      });
    case ProfileAction.DASHBOARD_REPORT_NEW_SUCCESS:
      var data = [];
      action === null || action === void 0 ? void 0 : (_action$payload44 = action.payload) === null || _action$payload44 === void 0 ? void 0 : (_action$payload44$Pos = _action$payload44.PostedAwardedDate) === null || _action$payload44$Pos === void 0 ? void 0 : _action$payload44$Pos.map(function (item) {
        data.push({
          date: item === null || item === void 0 ? void 0 : item.dated,
          Posted: {
            Contract: item === null || item === void 0 ? void 0 : item.posted_contract,
            Spot: item === null || item === void 0 ? void 0 : item.posted_spot
          },
          Awarded: {
            Contract: item === null || item === void 0 ? void 0 : item.awarded_contract,
            Spot: item === null || item === void 0 ? void 0 : item.awarded_spot
          }
        });
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        // new_dashboard_report: [action?.payload],
        new_dashboard_report: action === null || action === void 0 ? void 0 : action.payload,
        chart_data: data,
        isLoading: false
      });
    case ProfileAction.DASHBOARD_REPORT_NEW_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // new dashboard load detail
    case ProfileAction.DASHBOARD_LOAD_DETAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true,
        // new_dashboard_load_detail: [...state.new_dashboard_load_detail],
        new_dashboard_load_detail: state.new_dashboard_load_detail
      });
    case ProfileAction.DASHBOARD_LOAD_DETAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        // new_dashboard_load_detail: [action?.payload],
        new_dashboard_load_detail: action === null || action === void 0 ? void 0 : action.payload,
        isLoading: false
      });
    case ProfileAction.DASHBOARD_LOAD_DETAIL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.RESET_STORE:
      return INITIAL_STATE;
    default:
      return state;
  }
}
export default Reducer;