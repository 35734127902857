import { Colors, Images } from "@temp/assets";
import { CustomText, Message } from "@temp/components";
import React, { useState } from "react";
import { DeclineButton } from "./DeclineButton";
import { RecipientStatus } from "./RecipientStatus";
import { VehicleCounter } from "./VehicleCount";
import { useHistory } from "react-router-dom";
import { getFullTime, triggerMixpanelEvent } from "../../../utils/helper";
import { showMessage } from "../../../utils/helper";
import { Checkbox } from "@mui/material/";
import { useDispatch } from "react-redux";
import { NegotiateBid } from "./NegociateBid";
import { LoadAction } from "@temp/store/actions";
import {
  NewInput
} from "@components";
import { RecipientStatusContract } from "./RecipientStatusContract";
import moment from "moment";
var numeral = require("numeral");

export const RenderRecipientLCL: any = ({
  item2,
  declineByPoster,
  loadDetail,
  contract_location,
  awardedLCL,
  handleChange,
  clickCheckboxAward,
  clickCheckboxAwardContract,
  userRole,
  companyName,
  isDeadlineComplete,
  location,
  locationCompRef,
  price_unit
}) => {



  const history = useHistory();
  const fcl_award = awardedLCL?.find((item) => item?.id == item2?.id);
  const contract_award_lcl = awardedLCL?.find((item) => item?.job_load_recipient_bid_id == item2?.bid?.id)
  const [bid_negotiated_amount, set_bid_negotiated_amount] = useState(null)
  const [bid_negotiated_detention_rate, set_bid_negotiated_detention_rate] = useState(null)
  const [bid_negotiated_detention_tarif_policy, set_negotiated_bid_detention_tarif_policy] = useState(null)
  const [bid_negotiated_price_loading, set_bid_negotiated_price_loading] = useState(null)
  const [bid_negotiated_price_unloading, set_bid_negotiated_price_unloading] = useState(null)
  const [showModal, toggleModal] = useState(false)
  const [job_load_recipient_bid_id, set_job_load_recipient_bid_id] = useState(null)
  const dispatch = useDispatch()
  let isRateValidityCheck = moment(new Date()).format("x") < (moment(loadDetail?.rate_validity_date).format("x"))
  let unit_price = location?.pickup?.attributes?.price_unit

  const handleUpdateBid = () => {
    if (bid_negotiated_amount !== ".0" && bid_negotiated_amount !== ".00"
      && bid_negotiated_detention_rate !== ".0" && bid_negotiated_detention_rate !== ".00"
      && bid_negotiated_price_loading !== ".0" && bid_negotiated_price_loading !== ".00"
      && bid_negotiated_price_unloading !== ".0" && bid_negotiated_price_unloading !== ".00") {
        // return
        dispatch(LoadAction.negotiateBidPoster({
          load_id: loadDetail?.id,
          job_load_recipient_bid_id,
          bid_negotiated_amount,
          bid_negotiated_detention_rate,
          bid_negotiated_detention_tarif_policy,
          bid_negotiated_price_loading,
          bid_negotiated_price_unloading
  
        }))
        set_bid_negotiated_amount(null)
        set_job_load_recipient_bid_id(null)
        set_bid_negotiated_detention_rate(null)
        set_negotiated_bid_detention_tarif_policy(null)
        set_bid_negotiated_price_loading(null)
        set_bid_negotiated_price_unloading(null)
        toggleModal(!showModal)

    } else {
      showMessage.current({
        message:
          `Please enter a valid value.`,
        status: "error",
      });
    }
      // dispatch(LoadAction.negotiateBidPoster({
      //   load_id: loadDetail?.id,
      //   job_load_recipient_bid_id,
      //   bid_negotiated_amount,
      //   bid_negotiated_detention_rate,
      //   bid_negotiated_detention_tarif_policy,
      //   bid_negotiated_price_loading,
      //   bid_negotiated_price_unloading

      // }))
      // set_bid_negotiated_amount(null)
      // set_job_load_recipient_bid_id(null)
      // set_bid_negotiated_detention_rate(null)
      // set_negotiated_bid_detention_tarif_policy(null)
      // set_bid_negotiated_price_loading(null)
      // set_bid_negotiated_price_unloading(null)
      // toggleModal(!showModal)

  }

  const RenderRow = ({ title, val }) => {
    return (
      <div className="formGrid">
        <div className="grayLabel">{title}</div>
        <div className="fw-5">{val}</div>
      </div>
    )
  }

  const show_edit_bid_button = item2?.bid && item2?.status !== 4 && item2?.status !== 2 &&
    isDeadlineComplete && item2?.bid?.bid_negotiated_times < 2 && item2?.mark_complete_status !== 1 &&
    (item2?.status == 1 && !item2?.bid?.bid_negotiated_amount && loadDetail?.transaction_type.title == "Bid" && loadDetail?.is_allow_partial_load) && isDeadlineComplete ||
    (item2?.status == 1 && !item2?.bid?.bid_negotiated_amount && loadDetail?.transaction_type.title == "Bid" && loadDetail?.total_awarded < 1 && !loadDetail?.is_allow_partial_load) &&
    isDeadlineComplete && item2?.mark_complete_status !== 1

  return (
    <>
      {
        loadDetail?.inquiry_type.title == "Spot Rate" ?
          // sport rate 
          <div className="bottom-border-2px">
            <div className="detail-category-recepient-container">
              {(item2?.status == 1 && !item2?.bid?.bid_negotiated_amount && loadDetail?.transaction_type.title == "Bid" && loadDetail?.is_allow_partial_load) && isDeadlineComplete ||
                (item2?.status == 1 && !item2?.bid?.bid_negotiated_amount && loadDetail?.transaction_type.title == "Bid" && loadDetail?.total_awarded < 1 && !loadDetail?.is_allow_partial_load) &&
                isDeadlineComplete && item2?.mark_complete_status !== 1 ?
                <div className="check-wrapper">
                  {/* {isRateValidityCheck ? */}
                  <Checkbox
                    checked={!fcl_award ? false : true}
                    onChange={(e) => {
                      clickCheckboxAward(item2);
                    }}
                    style={{ color: "#7ac251" }}
                  />
                  {/* // : null} */}
                </div>
                : null}
              {(item2?.status == 1 && loadDetail?.transaction_type.title !== "Bid" && loadDetail?.is_allow_partial_load) ||
                (item2?.status == 1 && loadDetail?.transaction_type.title !== "Bid" && loadDetail?.total_awarded < 1 && !loadDetail?.is_allow_partial_load)
                ?
                <div>
                  {/* {isRateValidityCheck ? */}
                  <Checkbox
                    className="check-wrapper"
                    checked={!fcl_award ? false : true}
                    onChange={(e) => {
                      clickCheckboxAward(item2);
                    }}
                    style={{ color: "#7ac251" }}
                  />
                  {/* // : null} */}
                </div>
                : null}

              <div className="load-detail-user">
                <img
                  alt="profile"
                  className="load-detail-user"
                  src={item2?.picture || Images.myProfileImage}
                />
              </div>
              <div className="recepient-detail-container">
                <div className="width-100">
                  {/* top row  */}
                  <div style={{
                    justifyContent: 'space-between'
                  }} className="flexRow100">
                    <div>
                      <div className="title customTitleWithDot">
                        <span>{item2?.recipient?.company?.length ?
                          item2?.recipient?.company[0]?.name : item2?.recipient?.name
                          || "UnRegistered User"}</span>
                        <div style={{
                          backgroundColor: item2?.is_seen ? "#7ac251" : "#DA2C2C",
                          height: 8,
                          width: 8,
                          borderRadius: 5,
                          marginLeft: 8,
                          marginTop: 5
                        }}></div>
                      </div>
                      <div className="completeGreenText">
                        {
                          item2?.mark_complete_status == 1 ?
                            "Completed" : null
                        }
                      </div>
                    </div>

                    {/* top status when only accept for spot */}
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: show_edit_bid_button ? 'flex-end' : 'flex-end',
                      marginRight: 0,
                      alignItems: 'center'
                    }}>

                      {
                        (show_edit_bid_button
                          // && isRateValidityCheck 
                          && loadDetail?.is_allow_partial_load) ||
                          (show_edit_bid_button
                            // && isRateValidityCheck 
                            && !loadDetail?.is_allow_partial_load && loadDetail?.total_awarded < 1) ?
                          <div style={{ marginLeft: 5 }}>
                            <div className="nogotiate_button icon-btn newNegotiateBtn" onClick={() => {
                              if (loadDetail?.inquiry_type?.title == "Spot Rate" && (!loadDetail?.locations[0]?.raw_address || !loadDetail?.locations[1]?.raw_address ||
                                !loadDetail?.locations[0]?.contact_name || !loadDetail?.locations[0]?.contact_phone_no ||
                                !loadDetail?.locations[1]?.contact_name || !loadDetail?.locations[1]?.contact_phone_no)) {
                                locationCompRef?.current?.openModal()
                              } else {
                                set_job_load_recipient_bid_id(item2?.bid?.id)
                                set_bid_negotiated_amount(item2?.bid?.bid_negotiated_amount || item2?.bid?.price_per_freight)
                                set_bid_negotiated_detention_rate(item2?.bid?.bid_negotiated_detention_rate || item2?.bid?.bid_detention_rate)
                                set_negotiated_bid_detention_tarif_policy(item2?.bid?.bid_negotiated_detention_tarif_policy || item2?.bid?.detention_tarif_policy)
                                set_bid_negotiated_price_loading(item2?.bid?.bid_negotiated_price_loading || item2?.bid?.loading_price_per_freight)
                                set_bid_negotiated_price_unloading(item2?.bid?.bid_negotiated_price_unloading || item2?.bid?.unloading_price_per_freight)
                                toggleModal(true)
                              }
                            }}>
                              <span className="icons-edit"></span>
                              <span className="bid-text">Edit Bid</span>
                            </div>
                          </div>
                          : null
                      }
                    </div>

                  </div>
                  <div className="company">
                    {
                      item2?.recipient?.company?.length ?
                        item2?.recipient?.name : item2?.recipient?.company_name
                    }
                  </div>


                  {/* bottom row  */}
                  <div className="recepient-status-container">
                    {
                      loadDetail.transaction_type.title == "Offer" ||
                        (isDeadlineComplete && loadDetail.transaction_type.title == "Bid") ? (
                        <>
                          {
                            item2?.status == 0 && (
                              <RecipientStatus
                                status={item2?.status}
                                loadDetail={loadDetail}
                                decline_by={item2?.declined_by}
                                from={"poster"}
                                item={item2?.mark_complete_status}
                              />
                            )
                          }

                          {
                            item2?.status == 1 || item2.status == 4 ? (
                              <div className="flexRow">
                                <VehicleCounter price_unit={loadDetail?.price_unit} recipient={item2} loadDetail={loadDetail} />
                                <RecipientStatus
                                  status={item2?.status}
                                  loadDetail={loadDetail}
                                  decline_by={item2?.declined_by}
                                  from={"poster"}
                                  item={item2?.mark_complete_status}
                                />
                              </div>
                            ) : null
                          }

                          {
                            item2?.status == 2 && item2?.declined_by == "Poster" ?
                              <div className="flexRow">
                                <VehicleCounter price_unit={loadDetail?.price_unit} recipient={item2} loadDetail={loadDetail} />
                                {/* <RecipientStatus
                                  status={item2?.status}
                                  loadDetail={loadDetail}
                                  readOnly={true}
                                /> */}
                              </div> : null
                          }

                          {
                            item2?.status == 2 && item2?.declined_by == "Recipient" ?
                              <RecipientStatus
                                status={item2?.status}
                                loadDetail={loadDetail}
                                decline_by={item2?.declined_by}
                                from={"poster"}
                              /> : null
                          }

                          {(item2.status == 1
                            // && isRateValidityCheck
                          ) || (item2.status == 2 && item2?.declined_by == "Poster") ?
                            <DeclineButton
                              type={item2.declined_by == "Poster" && "redButton" || "outline"}
                              declineByPoster={declineByPoster}
                              recipient={item2}
                            />
                            : null}
                        </>
                      ) : null
                    }

                    {!isDeadlineComplete && loadDetail.transaction_type.title == "Bid" ? (
                      <RecipientStatus
                        status={item2?.status}
                        loadDetail={loadDetail}
                        decline_by={item2?.declined_by}
                        from={"poster"}
                        item={item2?.mark_complete_status}
                      />
                    ) : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div>
              {
                item2?.status_name == "Declined" && item2?.declined_by !== "Poster" && item2?.decline_reason || item2?.decline_reason_other ?
                  <div className="formGrid">
                    <p className="grayLabel">{`Decline Reason: `} </p> <p className="fw-5">{item2?.decline_reason == "Other" ? item2?.decline_reason_other : item2?.decline_reason}</p>
                  </div> : null
              }
            </div>
            {/* for offerrr  */}
            {
              loadDetail?.is_allow_partial_load ?
                <div>
                  {loadDetail?.transaction_type.title == "Offer" &&
                    loadDetail.quote_by == "weight_volume" &&
                    item2?.status === 1 && (
                      // isRateValidityCheck ?
                      <>
                        < div className="inputField">
                          <div className="label">{loadDetail?.price_unit}</div>
                          <div className="field">
                            <NewInput
                              onChange={(e) => {
                                handleChange({ e, id: item2?.id });
                              }}
                              value={awardedLCL?.length > 0 && fcl_award?.awarded_freight_ton ? awardedLCL?.length > 0 && fcl_award?.awarded_freight_ton : ""}
                              disabled={!fcl_award || fcl_award?.field_disabled}
                              showEnd={loadDetail?.price_unit}
                              decimal={true}
                            />
                          </div>
                        </div>
                      </>
                      //  : null
                    )}
                </div> : null
            }
            {/* for Bidd spot ratee */}
            <div>
              {loadDetail?.transaction_type.title == "Bid" &&
                loadDetail.quote_by == "weight_volume" &&
                isDeadlineComplete && (
                  <>
                    {
                      (loadDetail?.is_allow_partial_load && item2?.status == 1) ?
                        <div className="inputField">
                          {/* {isRateValidityCheck ? */}
                          <>
                            <div className="label">
                              {loadDetail?.price_unit}
                            </div>
                            <div className="field">
                              <NewInput
                                onChange={(e) => {

                                  handleChange({ e, id: item2?.id });

                                }}
                                value={awardedLCL?.length > 0 && fcl_award?.awarded_freight_ton ? awardedLCL?.length > 0 && fcl_award?.awarded_freight_ton : ""}
                                disabled={!fcl_award || fcl_award?.field_disabled}
                                showEnd={loadDetail?.price_unit}
                                decimal={true}
                              />
                            </div>
                          </>
                          {/* : null} */}
                        </div> : null
                    }

                    {/* for spot */}
                    {item2?.bid?.bid_negotiated_amount ?
                      <>
                        <div>
                          {(item2?.bid?.bid_negotiated_amount) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                {/* Negotiated Amount (Without GST) */}
                                {`Negotiated Price / ${loadDetail?.price_unit} (Without GST)`}

                              </div>
                              <div className="fw-5">
                                {numeral(
                                  Number(item2?.bid?.bid_negotiated_amount)
                                ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}
                          {(item2?.bid?.bid_negotiated_price_loading) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                {`Negotiated Loading Price / ${loadDetail?.price_unit} (Without GST)`}
                              </div>
                              <div className="fw-5">
                                {numeral(
                                  Number(item2?.bid?.bid_negotiated_price_loading)
                                ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}
                          {(item2?.bid?.bid_negotiated_price_unloading) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                {`Negotiated Unloading Price / ${loadDetail?.price_unit} (Without GST)`}
                              </div>
                              <div className="fw-5">
                                {numeral(
                                  Number(item2?.bid?.bid_negotiated_price_unloading)
                                ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}
                          {(item2?.bid?.bid_negotiated_loading_price) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Loading Price (Without GST)
                              </div>
                              <div className="fw-5">
                                {
                                  loadDetail?.quote_by == "vehicle"
                                    ?
                                    numeral(
                                      Number(item2?.bid?.bid_negotiated_loading_price)
                                    ).format("0,0") + " PKR"
                                    : numeral(
                                      Number(item2?.bid?.bid_negotiated_loading_price)
                                    ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.bid_negotiated_unloading_price) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Unloading Price (Without GST)
                              </div>
                              <div className="fw-5">
                                {
                                  loadDetail?.quote_by == "vehicle"
                                    ?
                                    numeral(
                                      Number(item2?.bid?.bid_negotiated_unloading_price)
                                    ).format("0,0") + " PKR"
                                    :
                                    numeral(
                                      Number(item2?.bid?.bid_negotiated_unloading_price)
                                    ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.bid_negotiated_detention_rate) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Negotiated Detention Rate (Without GST)
                              </div>
                              <div className="fw-5">
                                {
                                  loadDetail?.quote_by == "vehicle" ?
                                    numeral(
                                      Number(item2?.bid?.bid_negotiated_detention_rate)
                                    ).format("0,0") + " PKR"
                                    :

                                    numeral(
                                      Number(item2?.bid?.bid_negotiated_detention_rate)
                                    ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}


                          {(item2?.bid?.bid_negotiated_detention_tarif_policy) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Negotiated Detention Tariff & Policy
                              </div>
                              <div className="fw-5">
                                {item2?.bid?.bid_negotiated_detention_tarif_policy}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.price_per_freight) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Price / {loadDetail?.price_unit} (Without GST)
                              </div>
                              <div className="fw-5">
                                {numeral(Number(item2?.bid?.price_per_freight)).format(
                                  "0,0.00"
                                ) + " PKR"}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.bid_detention_rate) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Detention Rate (Without GST)
                              </div>
                              <div className="fw-5">
                                {
                                  loadDetail?.quote_by == "vehicle" ?
                                    numeral(
                                      Number(item2?.bid?.bid_detention_rate)
                                    ).format("0,0") + " PKR"
                                    :
                                    numeral(
                                      Number(item2?.bid?.bid_detention_rate)
                                    ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.detention_tarif_policy) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Detention Tariff & Policy
                              </div>
                              <div className="fw-5">
                                {item2?.bid?.detention_tarif_policy}

                              </div>
                            </div>
                          )}



                          {(item2?.bid?.loading_price_per_freight) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Loading Price / {loadDetail?.price_unit} (Without GST)
                              </div>
                              <div className="fw-5">
                                {numeral(
                                  Number(item2?.bid?.loading_price_per_freight)
                                ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.unloading_price_per_freight) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Unloading Price / {loadDetail?.price_unit} (Without GST)
                              </div>
                              <div className="fw-5">
                                {numeral(
                                  Number(item2?.bid?.unloading_price_per_freight)
                                ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}

                          {/* {(item2?.bid?.bid_detention_additional_charges) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Other Additional Charges
                              </div>
                              <div className="fw-5">
                                {item2?.bid?.bid_detention_additional_charges}

                              </div>
                            </div>
                          )} */}
                        </div>
                      </>
                      : <>
                        <div>
                          {(item2?.bid?.price_per_freight) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Price / {loadDetail?.price_unit} (Without GST)
                              </div>
                              <div className="fw-5">
                                {numeral(Number(item2?.bid?.price_per_freight)).format(
                                  "0,0.00"
                                ) + " PKR"}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.bid_detention_rate) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Detention Rate(Without GST)
                              </div>
                              <div className="fw-5">

                                {
                                  loadDetail?.quote_by == "vehicle" ?
                                    numeral(
                                      Number(item2?.bid?.bid_detention_rate)
                                    ).format("0,0") + " PKR"
                                    :
                                    numeral(
                                      Number(item2?.bid?.bid_detention_rate)
                                    ).format("0,0.00") + " PKR"}

                              </div>
                            </div>
                          )}

                          {(item2?.bid?.detention_tarif_policy) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Detention Tariff & Policy
                              </div>
                              <div className="fw-5">
                                {item2?.bid?.detention_tarif_policy}

                              </div>
                            </div>
                          )}


                          {(item2?.bid?.loading_price_per_freight) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Loading Price / {loadDetail?.price_unit} (Without GST)
                              </div>
                              <div className="fw-5">
                                {numeral(
                                  Number(item2?.bid?.loading_price_per_freight)
                                ).format("0,0.00") + " PKR"}
                              </div>
                            </div>

                          )}

                          {(item2?.bid?.unloading_price_per_freight) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Unloading Price / {loadDetail?.price_unit} (Without GST)
                              </div>
                              <div className="fw-5">
                                {numeral(
                                  Number(item2?.bid?.unloading_price_per_freight)
                                ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}

                          {/* {(item2?.bid?.bid_detention_additional_charges) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Other Additional Charges
                              </div>
                              <div className="fw-5">
                                {item2?.bid?.bid_detention_additional_charges}

                              </div>
                            </div>
                          )} */}
                        </div>
                      </>}
                    {/* {
                      item2?.status == 1 && (
                        <div className="formGrid">
                          <div className="grayLabel">
                            Total Amount(Without GST)
                          </div>
                          <div className="fw-5">
                            { 
                              loadDetail?.is_allow_partial_load ?
                                numeral((Number(item2?.bid?.price_per_freight) * (Number(fcl_award?.awarded_freight_ton) || 0)) +
                                 ( Number(item2?.bid?.loading_price_per_freight) || 0) +( Number(item2?.bid?.unloading_price_per_freight) || 0)).format('0,0') + " PKR" :
                                numeral((Number(item2?.bid?.price_per_freight) * (Number(item2?.bid?.bid_freight_ton))) +
                                 (Number(item2?.bid?.loading_price_per_freight) || 0) + (Number(item2?.bid?.unloading_price_per_freight)) || 0).format('0,0') + " PKR"
                            }
                          </div>
                        </div>
                      )
                    } */}
                    <>
                      {
                        !loadDetail?.is_allow_partial_load ?
                          <>
                            {
                              item2?.status == 1 && (
                                <RenderRow
                                  title={"Total Amount(Without GST)"}
                                  val={
                                    numeral((Number(item2?.bid?.price_per_freight) * (Number(item2?.bid?.bid_freight_ton))) +
                                      (Number(item2?.bid?.loading_price_per_freight) * (Number(item2?.bid?.bid_freight_ton)) || 0) + (Number(item2?.bid?.unloading_price_per_freight) * (Number(item2?.bid?.bid_freight_ton))) || 0).format('0,0.00') + " PKR"
                                  }
                                />
                              )
                            }
                          </> :
                          <>
                            {
                              item2?.status == 1 && (
                                <RenderRow
                                  title={"Total Amount(Without GST)"}
                                  val={
                                    fcl_award?.awarded_freight_ton > 0 ?
                                      numeral((Number(item2?.bid?.price_per_freight) * (Number(fcl_award?.awarded_freight_ton) || 0)) +
                                        (Number(item2?.bid?.loading_price_per_freight) * (Number(fcl_award?.awarded_freight_ton)) || 0) + (Number(item2?.bid?.unloading_price_per_freight) * (Number(fcl_award?.awarded_freight_ton)) || 0)).format('0,0.00') + " PKR"
                                      : 0
                                  }
                                />
                              )
                            }
                          </>
                      }
                    </>

                    {
                      item2?.status == 4 && (
                        <div className="formGrid">
                          <div className="grayLabel">
                            Total Amount(Without GST)
                          </div>
                          <div className="fw-5">

                            {
                              numeral((Number(item2?.bid?.price_per_freight) * (Number(item2?.bid?.awarded_freight_ton) || 0))
                                +
                                (Number(item2?.bid?.loading_price_per_freight) * (Number(item2?.bid?.awarded_freight_ton)) || 0)
                                + (Number(item2?.bid?.unloading_price_per_freight) * (Number(item2?.bid?.awarded_freight_ton)) || 0)
                              )
                                .format('0,0.00') + " PKR"
                            }
                          </div>
                        </div>
                      )
                    }

                    {(item2?.bid?.bid_detention_additional_charges) && (
                      <div className="formGrid">
                        <div className="grayLabel">
                          Other Additional Charges
                        </div>
                        <div className="fw-5">
                          {item2?.bid?.bid_detention_additional_charges}

                        </div>
                      </div>
                    )}
                  </>
                )}
            </div>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              {item2?.status == 4 &&
                Array(loadDetail?.vehicles_drivers_refs.length)
                  .fill("")
                  .map((item, index) => {
                    // let findAward = loadDetail?.vehicles_drivers_refs.find(
                    //   (cat) => cat?.vehicle_no == index + 1
                    // );
                    let findAward = loadDetail?.vehicles_drivers_refs.filter((cat) => cat?.vehicle_no == index + 1 && cat?.recipient_id == item2?.recipient?.id);

                    return (
                      <>
                        {
                          findAward?.map((award, award_index) => {

                            return (
                              <div>
                                <div
                                  onClick={() => {
                                    if (award?.status == 1) {
                                      triggerMixpanelEvent("View Active Load", "view active load from awarded vehicle list")
                                      history.push(
                                        `/job-loads/${loadDetail?.id}/active/${award?.id}`, { fromPoster: userRole == "posted" ? true : false }
                                      );
                                    }
                                  }}
                                  className="awarded-vehicle-list"
                                >
                                  <div className="flex_row_div">
                                    <CustomText.OpenSansSemiBold textStyle={{ marginLeft: 5 }}>Details of Vehicle {index + 1}</CustomText.OpenSansSemiBold>
                                    <div
                                      style={{
                                        display: "flex",
                                        marginRight: 12,
                                        alignItems: "center",
                                      }}
                                    >
                                      <CustomText.OpenSansSemiBold
                                        textStyle={{
                                          fontSize: "0.688rem",
                                          color:
                                            award?.status == 1
                                              ? Colors.Primary
                                              : Colors.Red,
                                        }}
                                      >
                                        {award?.status == 1 ? "View" : "Completed"}
                                      </CustomText.OpenSansSemiBold>
                                      <div className="blueRightArrow">
                                        <img
                                          src={Images.BlueRightArrow}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    award?.is_replaced == 1 && award?.replaced_by !== null ?
                                      <div className="vehicleDetailsTextWrap">
                                        <div className="fw-5">
                                          Replaced Vehicle
                                        </div>
                                        {/* <CustomText.OpenSansRegular>
                                          <span>
                                            {
                                              `From- ${award?.replaced_by?.vehicle?.plate_number} ${moment(award?.replaced_by?.created_at).format('DD-MM-YYYY hh:mm A')} To- ${award?.vehicle?.plate_number} ${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')}`
                                            }
                                          </span>
                                        </CustomText.OpenSansRegular> */}

                                        <CustomText.OpenSansRegular textStyle={{
                                          display: 'flex'
                                        }}>

                                          <span className="leftReplaceVehicle">{`From - ${award?.replaced_by?.vehicle?.plate_number} `}
                                            <span>{`${moment(award?.replaced_by?.created_at).format('DD-MM-YYYY hh:mm A')} `}</span>
                                          </span>
                                          <span className="rightReplaceVehicle">{`To - ${award?.vehicle?.plate_number} `}
                                            <span>{`${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')} `}</span>
                                          </span>
                                        </CustomText.OpenSansRegular>
                                      </div>
                                      : null}

                                  {award?.is_replaced == 0 ?
                                    <div className="vehicleDetailsTextWrap">
                                      <div className="fw-5">
                                        Plate Number
                                      </div>
                                      <CustomText.OpenSansRegular>
                                        {`${award?.vehicle?.plate_number} (${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')})`}


                                      </CustomText.OpenSansRegular>
                                    </div>
                                    : null}

                                  {/* {award?.created_at &&
                                    <div className="vehicleDetailsTextWrap">
                                      <div className="flexRowWrap">
                                        <CustomText.OpenSansRegular textStyle={{
                                          marginLeft: 5
                                        }}>
                                          {`${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')}`}
                                        </CustomText.OpenSansRegular>
                                      </div>
                                    </div>
                                  }
 */}


                                  {/* {award?.is_replaced &&
                                    <div className="vehicleDetailsTextWrap">
                                      <div className="flexRowWrap">
                                        <CustomText.OpenSansRegular textStyle={{
                                          marginLeft: 5
                                        }}>
                                          {`${moment(award?.replaced_by?.created_at).format('DD-MM-YYYY hh:mm A')}`}
                                        </CustomText.OpenSansRegular>
                                      </div>
                                    </div>
                                  } */}

                                  {
                                    award?.no_of_packages &&
                                    <div className="vehicleDetailsTextWrap">
                                      <div className="flexRowWrap">
                                        <div className="fw-5">
                                          No. of Packages

                                        </div>
                                      </div>
                                      <CustomText.OpenSansRegular>
                                        {`${award?.no_of_packages}`}
                                      </CustomText.OpenSansRegular>
                                    </div>
                                  }
                                  {
                                    award?.weight_one &&
                                    <div className="vehicleDetailsTextWrap">
                                      <div className="flexRowWrap">
                                        <div className="fw-5">
                                          Weight
                                        </div>
                                      </div>
                                      <CustomText.OpenSansRegular>
                                        {`${numeral(award?.weight_one).format("0,0.00")} Kg`}
                                      </CustomText.OpenSansRegular>
                                    </div>
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </>
                    );
                  })}
            </div>
          </div> :
          // contract rate 
          <div className="bottom-border-2px">
            <div className="detail-category-recepient-container">
              {item2?.status == 1 && !item2?.bid?.bid_negotiated_amount &&
                loadDetail?.transaction_type.title == "Bid" && isDeadlineComplete && item2?.mark_complete_status !== 1 && (
                  <div>
                    {/* {isRateValidityCheck ? */}
                    <Checkbox
                      checked={contract_award_lcl ? true : false}
                      onChange={(e) => {
                        clickCheckboxAwardContract({ job_load_recipient_bid_id: item2?.bid?.id, bid: item2?.bid });
                      }}
                      style={{ color: "#7ac251" }}
                    />
                    {/* : null} */}
                  </div>
                )}
              <div className="load-detail-user">
                <img
                  alt="profile"
                  className="load-detail-user"
                  src={item2?.picture || Images.myProfileImage}
                />
              </div>
              <div className="recepient-detail-container">
                <div className="width-100">
                  {/* top row  */}
                  <div style={{
                    justifyContent: 'space-between',
                    marginRight: 0
                  }} className="flexRow100">
                    <div className="title customTitleWithDot">
                      <span>{item2?.recipient?.company?.length ?
                        item2?.recipient?.company[0]?.name : item2?.recipient?.name
                        || "UnRegistered User"}
                      </span>
                      <div style={{
                        backgroundColor: item2?.is_seen ? "#7ac251" : "#DA2C2C",
                        height: 8,
                        width: 8,
                        borderRadius: 5,
                        marginLeft: 8,
                        marginTop: 5
                      }}></div>
                      <div className="completeGreenText">
                        {
                          item2?.mark_complete_status == 1 ?
                            "Completed" : null
                        }
                      </div>
                    </div>
                    {/* top status when only accept for contract */}
                    {
                      (show_edit_bid_button
                        // && isRateValidityCheck 
                        && loadDetail?.is_allow_partial_load) ||
                        (show_edit_bid_button
                          // && isRateValidityCheck 
                          && !loadDetail?.is_allow_partial_load && loadDetail?.total_awarded < 1) ?
                        <div>
                          <div className="nogotiate_button icon-btn newNegotiateBtn" onClick={() => {
                            if (loadDetail?.inquiry_type?.title == "Spot Rate" && (!loadDetail?.locations[0]?.raw_address || !loadDetail?.locations[1]?.raw_address ||
                              !loadDetail?.locations[0]?.contact_name || !loadDetail?.locations[0]?.contact_phone_no ||
                              !loadDetail?.locations[1]?.contact_name || !loadDetail?.locations[1]?.contact_phone_no)) {
                              locationCompRef?.current?.openModal()
                            } else {
                              set_job_load_recipient_bid_id(item2?.bid?.id)
                              set_bid_negotiated_amount(item2?.bid?.bid_negotiated_amount || item2?.bid?.price_per_freight)
                              set_bid_negotiated_detention_rate(item2?.bid?.bid_negotiated_detention_rate || item2?.bid?.bid_detention_rate)
                              set_negotiated_bid_detention_tarif_policy(item2?.bid?.bid_negotiated_detention_tarif_policy || item2?.bid?.detention_tarif_policy)
                              set_bid_negotiated_price_loading(item2?.bid?.bid_negotiated_price_loading || item2?.bid?.loading_price_per_freight)
                              set_bid_negotiated_price_unloading(item2?.bid?.bid_negotiated_price_unloading || item2?.bid?.unloading_price_per_freight)
                              toggleModal(true)

                            }
                          }} >
                            <span className="icons-edit"></span>
                            <span className="bid-text">Edit Bid</span>
                          </div>
                        </div>
                        : null
                    }
                  </div>
                  <div className="company">
                    {
                      item2?.recipient?.company?.length ?
                        item2?.recipient?.name : item2?.recipient?.company_name
                    }
                  </div>

                  {/* bottom row  */}
                  <div className="recepient-status-container">
                    {item2?.status == 2 && item2?.declined_by == "Poster" ? (
                      <div className="flexRow">
                        <div className="chip">
                          {numeral(item2?.bid?.price_per_freight).format("0,0.00") + " PKR"} / {unit_price}
                        </div>
                      </div>
                    ) : item2?.status !== 1 ? (
                      <RecipientStatusContract
                        recipient={item2}
                        loadDetail={loadDetail}
                      />
                    ) : (
                      <RecipientStatusContract
                        recipient={item2}
                        loadDetail={loadDetail}
                      />
                    )}

                    {item2?.status == 4 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div className="chip">
                          {numeral(item2?.bid?.price_per_freight).format("0,0.00") + " PKR"} / {unit_price}
                        </div>
                      </div>
                    )}

                    {/* decline button show when status is accepted  */}
                    {(loadDetail?.transaction_type?.title == "Bid" &&
                      item2?.status == 1 && isDeadlineComplete && item2?.mark_complete_status !== 1
                      // && isRateValidityCheck
                    ) ||
                      (loadDetail?.transaction_type?.title == "Offer" &&
                        item2?.status === 1
                        // && isRateValidityCheck
                      ) ? (
                      <DeclineButton
                        type={"outline"}
                        declineByPoster={declineByPoster}
                        recipient={item2}
                        loadDetail={loadDetail}

                      />
                    ) : null}
                    {/* shows when status is declined by poster */}
                    {item2?.status == 2 &&
                      item2?.declined_by == "Poster" && (
                        <DeclineButton
                          type={"redbutton"}
                          declineByPoster={declineByPoster}
                          recipient={item2}
                          loadDetail={loadDetail}

                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              {
                (
                  item2?.status_name == "Declined" && item2?.declined_by !== "Poster" && item2?.decline_reason || item2?.decline_reason_other) ?
                  <div className="formGrid">
                    <p className="grayLabel">{`Decline Reason: `} </p> <p className="fw-5">{item2?.decline_reason == "Other" ? item2?.decline_reason_other : item2?.decline_reason}</p>
                  </div> : null
              }
            </div>

            {/* for Bidd contract and spot ratee  */}
            <>
              {item2?.bid?.bid_negotiated_amount ? <>
                <div>
                  {loadDetail?.transaction_type.title == "Bid" &&
                    loadDetail.quote_by == "weight_volume" && isDeadlineComplete && (
                      <div>
                        {/* {isRateValidityCheck && item2?.status === 1 ?
                          <>
                            <div className="inputField">
                              <div className="label">
                                Freight Ton
                              </div>
                              <div className="field">
                                <NewInput
                                  onChange={(e) => {
                                    handleChange({ e, job_load_recipient_bid_id: item2?.bid?.id });;
                                  }}
                                  value={contract_award_lcl ? contract_award_lcl?.awarded_freight_ton : ""}
                                  disabled={!contract_award_lcl}
                                  showEnd={loadDetail?.price_unit}
                                />
                              </div>
                            </div>
                          </> : null} */}
                        {/* {(item2?.bid?.bid_freight_ton) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Freight Ton
                            </div>
                            <div className="fw-5">
                              {numeral(item2?.bid?.bid_freight_ton).format("0,0.00")}
                            </div>
                          </div>
                        )} */}

                        {/* for contract */}
                        {(item2?.bid?.bid_negotiated_amount) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              {/* Negotiated Amount (Without GST) */}
                              {`Negotiated Price / ${price_unit} (Without GST)`}
                            </div>
                            <div className="fw-5">
                              {numeral(
                                Number(item2?.bid?.bid_negotiated_amount)
                              ).format("0,0.00") + " PKR"}
                            </div>
                          </div>
                        )}

                        {(item2?.bid?.bid_negotiated_price_loading) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              {`Negotiated Loading Price / ${price_unit}  (Without GST)`}
                            </div>
                            <div className="fw-5">
                              {numeral(
                                Number(item2?.bid?.bid_negotiated_price_loading)
                              ).format("0,0.00") + " PKR"}
                            </div>
                          </div>
                        )}
                        {(item2?.bid?.bid_negotiated_price_unloading) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              {`Negotiated Unloading Price / ${price_unit}  (Without GST)`}
                            </div>
                            <div className="fw-5">
                              {numeral(
                                Number(item2?.bid?.bid_negotiated_price_unloading)
                              ).format("0,0.00") + " PKR"}
                            </div>
                          </div>
                        )}

                        {(item2?.bid?.bid_negotiated_loading_price) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Loading Price (Without GST)
                            </div>
                            <div className="fw-5">
                              {

                                loadDetail?.quote_by == "vehicle" ?
                                  numeral(
                                    Number(item2?.bid?.bid_negotiated_loading_price)
                                  ).format("0,0") + " PKR"

                                  :
                                  numeral(
                                    Number(item2?.bid?.bid_negotiated_loading_price)
                                  ).format("0,0.00") + " PKR"}
                            </div>
                          </div>
                        )}

                        {(item2?.bid?.bid_negotiated_unloading_price) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Unloading Price (Without GST)
                            </div>
                            <div className="fw-5">
                              {
                                loadDetail?.quote_by == "vehicle" ?
                                  numeral(
                                    Number(item2?.bid?.bid_negotiated_unloading_price)
                                  ).format("0,0") + " PKR"
                                  :

                                  numeral(
                                    Number(item2?.bid?.bid_negotiated_unloading_price)
                                  ).format("0,0.00") + " PKR"}
                            </div>
                          </div>
                        )}

                        {(item2?.bid?.bid_negotiated_detention_rate) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Negotiated Detention Rate (Without GST)
                            </div>
                            <div className="fw-5">
                              {
                                loadDetail?.quote_by == "vehicle" ?
                                  numeral(
                                    Number(item2?.bid?.bid_negotiated_detention_rate)
                                  ).format("0,0") + " PKR"
                                  :

                                  numeral(
                                    Number(item2?.bid?.bid_negotiated_detention_rate)
                                  ).format("0,0.00") + " PKR"}

                            </div>
                          </div>
                        )}

                        {(item2?.bid?.bid_negotiated_detention_tarif_policy) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Negotiated Detention Tariff & Policy
                            </div>
                            <div className="fw-5">
                              {item2?.bid?.bid_negotiated_detention_tarif_policy}
                            </div>
                          </div>
                        )}

                        {(item2?.bid?.bid_detention_rate) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Detention Rate (Without GST)
                            </div>
                            <div className="fw-5">
                              {
                                loadDetail?.quote_by == "vehicle" ?
                                  numeral(
                                    Number(item2?.bid?.bid_detention_rate)
                                  ).format("0,0") + " PKR"
                                  :

                                  numeral(
                                    Number(item2?.bid?.bid_detention_rate)
                                  ).format("0,0.00") + " PKR"}
                            </div>
                          </div>
                        )}

                        {(item2?.bid?.detention_tarif_policy) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Detention Tariff & Policy
                            </div>
                            <div className="fw-5">
                              {item2?.bid?.detention_tarif_policy}

                            </div>
                          </div>
                        )}




                        {(item2?.bid?.price_per_freight) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Price / {unit_price} (Without GST)
                            </div>
                            <div className="fw-5">
                              {numeral(Number(item2?.bid?.price_per_freight)).format(
                                "0,0.00"
                              ) + " PKR"}

                            </div>
                          </div>
                        )}

                        {(item2?.bid?.loading_price_per_freight) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Loading Price / {unit_price} (Without GST)
                            </div>
                            <div className="fw-5">
                              {numeral(
                                Number(item2?.bid?.loading_price_per_freight)
                              ).format("0,0.00") + " PKR"}
                            </div>
                          </div>
                        )}

                        {(item2?.bid?.unloading_price_per_freight) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Unloading Price / {unit_price} (Without GST)
                            </div>
                            <div className="fw-5">
                              {numeral(
                                Number(item2?.bid?.unloading_price_per_freight)
                              ).format("0,0.00") + " PKR"}
                            </div>
                          </div>
                        )}

                        {(item2?.bid?.bid_detention_additional_charges) && (
                          <div className="formGrid">
                            <div className="grayLabel">
                              Other Additional Charges
                            </div>
                            <div className="fw-5">
                              {item2?.bid?.bid_detention_additional_charges}

                            </div>
                          </div>
                        )}


                      </div>
                    )}
                </div>
              </>
                : <>
                  <div>
                    {loadDetail?.transaction_type.title == "Bid" &&
                      loadDetail.quote_by == "weight_volume" &&

                      isDeadlineComplete && (
                        <div>
                          {/* {isRateValidityCheck && item2?.status === 1 ?
                            <>
                              <div className="inputField">
                                <div className="label">
                                  Freight Ton
                                </div>
                                <div className="field">
                                  <NewInput
                                    onChange={(e) => {
                                      handleChange({ e, job_load_recipient_bid_id: item2?.bid?.id });;
                                    }}
                                    value={contract_award_lcl ? contract_award_lcl?.awarded_freight_ton : ""}
                                    disabled={!contract_award_lcl}
                                    showEnd={loadDetail?.price_unit}
                                  />
                                </div>
                              </div>
                            </> : null} */}

                          {/* {(item2?.bid?.bid_freight_ton) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Freight Ton
                              </div>
                              <div className="fw-5">
                                {numeral(item2?.bid?.bid_freight_ton).format("0,0.00")}
                              </div>
                            </div>
                          )} */}

                          {(item2?.bid?.price_per_freight) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Price / {unit_price} (Without GST)
                              </div>
                              <div className="fw-5">
                                {numeral(Number(item2?.bid?.price_per_freight)).format(
                                  "0,0.00"
                                ) + " PKR"}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.bid_detention_rate) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Detention Rate (Without GST)
                              </div>
                              <div className="fw-5">
                                {
                                  loadDetail?.quote_by == "vehicle" ?
                                    numeral(
                                      Number(item2?.bid?.bid_detention_rate)
                                    ).format("0,0") + " PKR"
                                    :

                                    numeral(
                                      Number(item2?.bid?.bid_detention_rate)
                                    ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.detention_tarif_policy) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Detention Tariff & Policy
                              </div>
                              <div className="fw-5">
                                {item2?.bid?.detention_tarif_policy}
                              </div>
                            </div>
                          )}



                          {(item2?.bid?.loading_price_per_freight) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Loading Price / {unit_price} (Without GST)
                              </div>
                              <div className="fw-5">
                                {numeral(
                                  Number(item2?.bid?.loading_price_per_freight)
                                ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.unloading_price_per_freight) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Unloading Price / {unit_price} (Without GST)
                              </div>
                              <div className="fw-5">
                                {numeral(
                                  Number(item2?.bid?.unloading_price_per_freight)
                                ).format("0,0.00") + " PKR"}
                              </div>
                            </div>
                          )}

                          {(item2?.bid?.bid_detention_additional_charges) && (
                            <div className="formGrid">
                              <div className="grayLabel">
                                Other Additional Charges
                              </div>
                              <div className="fw-5">
                                {item2?.bid?.bid_detention_additional_charges}

                              </div>
                            </div>
                          )}


                        </div>
                      )}
                  </div>
                </>}
            </>
          </div>
      }
      <NegotiateBid
        showModal={showModal}
        toggleModal={toggleModal}
        bid_negotiated_amount={bid_negotiated_amount}
        set_bid_negotiated_amount={set_bid_negotiated_amount}
        handleUpdateBid={handleUpdateBid}
        bid_negotiated_price_loading={bid_negotiated_price_loading}
        set_bid_negotiated_price_loading={set_bid_negotiated_price_loading}
        bid_negotiated_price_unloading={bid_negotiated_price_unloading}
        set_bid_negotiated_price_unloading={set_bid_negotiated_price_unloading}
        bid_negotiated_detention_rate={bid_negotiated_detention_rate}
        set_bid_negotiated_detention_rate={set_bid_negotiated_detention_rate}
        set_negotiated_bid_detention_tarif_policy={set_negotiated_bid_detention_tarif_policy}
        bid_negotiated_detention_tarif_policy={bid_negotiated_detention_tarif_policy}
        loadDetail={loadDetail}
        item2={item2}
        price_unit={price_unit}

      />
    </>
  );
};
