import React, { useEffect, useState } from "react";
import { PrimaryButton } from "@components";
import { useHistory } from "react-router-dom";
import { Images } from "@temp/assets";
import "./style.scss";
import { CounterAtion, ProfileAction } from "@temp/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";
// import Lottie from 'react-lottie';
import disableBrowserBackButton from "disable-browser-back-navigation";
import { useLottie } from "lottie-react";

export const AddLoadSuccessfully = (props: any) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Images.TruckLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { View } = useLottie(defaultOptions);
  const dispatch = useDispatch();
  let [status, setStatus] = useState(null);
  const postLoadData = useSelector(
    (state: RootState) => state.Profile.postLoad
  );

  const history = useHistory();
  useEffect(() => {
    disableBrowserBackButton();
    setStatus(parse(props.location.search).status);
  }, []);
  return (
    <div>
      <div className="root-wrapper">
        <div className="root-center-container generalCenterContainer">
          <div className="insideSuccessPostedContainer loadPostSuccess">
            <img
              alt=""
              width="105px"
              height="54px"
              src={Images.MgLogo}
              style={{ marginBottom: 24 }}
            />
            <div className="successPostedImg">
              <img alt="" width="46px" src={Images.CheckLarge} />
            </div>
            <div className="successPostedImg">
              {/* <img alt="" width="350px" height="135px" src={Images.TruckGif} /> */}
              {/* <Lottie
                options={defaultOptions}
                height={200}
                width={200}
              /> */}
              <div
                style={{
                  height: 200,
                  width: 200,
                }}
              >
                {View}
              </div>
              {/* loading */}
            </div>
            <div
              style={{
                color: "rgb(13,21,9)",
                fontSize: "1.25rem",
                textAlign: "center",
                marginBottom: 24,
              }}
            >
              {status == 1 ? "Successfully Posted!" : "Successfully Saved!"}
            </div>

            <div
              style={{
                color: "rgb(13,21,9)",
                fontSize: "1rem",
                textAlign: "center",
                fontWeight: 300,
                lineHeight: "22px",
                marginBottom: 10,
              }}
            >
              {status == 1
                ? postLoadData?.inquiry_type === 2
                  ? "Your contract rate request has been posted successfully. Thank you for choosing Meri Gari."
                  : " Your load has been posted successfully. Thank you for choosing Meri Gari for posting your load."
                : " Load has been successfully saved as draft."}
            </div>
            <div className="receipient-post-btn">
              <PrimaryButton
                title="Go To Home"
                onClick={() => {
                  dispatch(ProfileAction.postLoadDataRemove());
                  dispatch(CounterAtion.resetPostloadCounterData());

                  history.push("/home");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
