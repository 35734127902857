import { put, call, select } from "redux-saga/effects";
import ApiCaller from "@temp/api/api";
import { ResponseGenerator } from "@temp/api/globalTypes";
import ProfileAction from "../actions/ProfileAction";
import { getItem, showMessage, triggerMixpanelEvent } from "@utils/helper";
import Endpoints from "../endpoints";
import { LoadAction } from "../actions";
export const getUser = (state) => state.Profile.profile;

export class ProfileSaga {

  static *updateLocation(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.UPDATE_LOCATION_API(payload?.load_id),
      {
        job_load_id: payload?.load_id,
        locations: payload?.data
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 200 || response?.status === 201) {
      yield put({ type: ProfileAction.UPDATE_LOCATION_SUCCESS })
      triggerMixpanelEvent("Update contact details on a load by poster",
        "Update contact details on a load by poster")
      yield put({ type: LoadAction.GET_LOAD_DETAIL, payload: { load_id: payload?.load_id } })
    } else {
      yield put({ type: ProfileAction.UPDATE_LOCATION_FAIL })
    }
  }

  static *getActiveLoads(params: any) {
    const token = getItem("token");
    const { payload } = params;
    let new_data = {
      ...payload,
      load_type:
        payload?.load_type == "fcl"
          ? "Containerized"
          : payload?.load_type == "bulk"
            ? "Non-Containerized"
            : payload?.load_type,
    };
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_ACTIVE_LOADS_URL({ ...new_data }),
      {
        Authorization: `Bearer ${token}`,
      },
      null,
      true
    );
    if (response?.status === 200) {
      yield put({
        type: ProfileAction.GET_ACTIVE_LOADS_SUCCESS,
        payload: {
          ...response?.data?.data?.loads,
          search: payload?.search,
          fromSearch: payload?.fromSearch,
          fromNoti: payload?.fromNoti,
        },
      });
    } else {
      yield put({ type: ProfileAction.GET_ACTIVE_LOADS_FAIL });
    }
  }

  static *handleFCmToken(params: any) {
    const token = getItem("token");
    const { payload } = params;
    
    const response: ResponseGenerator = yield call(
      ApiCaller.Put,
      Endpoints.UPDATE_FCM(),
      { ...payload },
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {
      yield put({
        type: ProfileAction.UPDATE_FCM_TOKEN[1],
      });
      yield put({
        type: ProfileAction.GET_PROFILE,
      });
    } else {
      yield put({ type: ProfileAction.UPDATE_FCM_TOKEN[2] });
    }
  }

  static *uploadFile(params: any) {
    const token = getItem("token");
    const { payload } = params;

    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.UPLOAD_FILE,
      payload.data,
      {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 200) {
      yield put({
        type: ProfileAction.UPLOAD_FILE[1],
      });
      if (payload.cb) {
        payload.cb(response?.data?.data?.upload);
      }
    } else {
      yield put({ type: ProfileAction.UPLOAD_FILE[2] });
    }
  }

  static *postLoad(params: any) {
    const token = getItem("token");
    const { payload } = params;

    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.JOB_LOAD,
      payload.data,
      { Authorization: `Bearer ${token}` }
    );
    if (response?.status === 201 || response?.status === 200) {
      yield put({
        type: ProfileAction.POST_LOAD[1],
      });
      if (payload.cb) {
        payload.cb(payload.data);
      }
    } else {
      yield put({ type: ProfileAction.POST_LOAD[2] });
    }
  }

  static *GetProfile(params: any) {
    const token = getItem("token");
    const response: ResponseGenerator = yield call(ApiCaller.Get, "me", {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    if (response?.status === 200) {
      yield put({
        type: ProfileAction.GET_PROFILE_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({ type: ProfileAction.GET_PROFILE_FAIL });
    }
  }

  static *jobLoadMeta(params: any) {
    const { payload } = params;
    let user = yield select(getUser);
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.JOB_LOAD_META(user?.id),
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 200) {
      yield put({
        type: ProfileAction.JOB_LOAD_META[1],
        payload: response.data.data,
      });
      if (payload) {
        payload();
      }
    } else {
      yield put({ type: ProfileAction.JOB_LOAD_META[2] });
    }
  }

  static *UpdateProfile(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const final_payload = {
      name: payload.name,
      name_friendly: payload.name_friendly,
      email: payload.email,
      associate_emails: [...payload?.associate_emails],
      company_name: payload?.company_name ? payload?.company_name : "",
      user_professions: [...payload?.user_professions],
      whatsapp_phone_no: payload?.whatsapp_phone_no,
      whatsapp_phone_code: "92",
      picture: payload?.picture,
      _method: "patch",
    };

    const formData = new FormData();

    Object.keys(final_payload).forEach((key, index) => {
      if (key == "user_professions" && Object.values(final_payload)[index]?.length) {
        Object.values(final_payload)[index]?.forEach((item) => {
          formData.append("user_professions[]", item);
        })
      } else if (key === "associate_emails") {
        Object.values(final_payload)[index]?.forEach((item) => {
          formData.append("associate_emails[]", item);
        })
      } else {
        formData.append(key, Object.values(final_payload)[index]);
      }
    });

    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      "me",
      formData,
      {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      }
    );

    if (response?.status === 200) {
      yield put({
        type: ProfileAction.UPDATE_PROFILE_SUCCESS,
      });
      if (payload.cb) {
        payload.cb(response?.data.data);
      }
    } else {
      yield put({ type: ProfileAction.UPDATE_PROFILE_FAIL });
    }
  }

  static *changePhoneNumberRequest(params: any) {
    const token = getItem("token");
    const { payload } = params;
    let final_payload = {
      otp_method: payload?.type,
      phone_code: "+92",
      phone_no: payload?.phoneNumber,
      login_type: "IU",
    };
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.CHANGE_PHONE_NO_REQUEST,
      final_payload,
      { Authorization: `Bearer ${token}` }
    );
    if (response?.status === 201) {
      yield put({
        type: ProfileAction.CHANGE_PHONE_NUMBER_REQUEST_SUCCESS,
      });
      if (payload.cb) {
        payload.cb({
          type: payload?.type,
          phoneNumber: payload?.phoneNumber,
          isOTPSent: response?.data?.data?.isOTPSent
        });
      }
    } else {
      yield put({ type: ProfileAction.CHANGE_PHONE_NUMBER_REQUEST_FAIL });
    }
  }

  static *changePhoneNumber(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const final_payload_payload = {
      phone_code: "92",
      phone_no: payload.phoneNumber,
      otp_method: payload.type,
      otp_no: payload.otpCode,
      login_type: "IU",
    };
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.CHANGE_PHONE_NO,
      final_payload_payload,
      { Authorization: `Bearer ${token}` }
    );
    if (response?.status === 200) {
      yield put({
        type: ProfileAction.UPDATE_PROFILE_SUCCESS,
      });
      yield put({
        type: ProfileAction.GET_PROFILE,
      });
      if (payload.cb) {
        payload.cb(response?.data.data);
      }
    } else {
      yield put({ type: ProfileAction.UPDATE_PROFILE_FAIL });
    }
  }

  // static *handlePostLoadData(data:object){

  // yield put({type:ProfileAction.POST_LOAD_DATA})
  // }

  static *changePassword(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Patch,
      "me",
      payload,
      {
        Authorization: `Bearer ${token}`,
        login_type: "IU",
      }
    );

    if (response?.status === 200) {
      yield put({
        type: ProfileAction.CHANGE_PASSWORD_SUCCESS,
      });
      if (payload.cb) {
        payload.cb(response?.data.data);
      }
    } else {
      yield put({ type: ProfileAction.CHANGE_PASSWORD_FAIL });
    }
  }
  static *DelProfileImage(params: any) {
    const token = getItem("token");
    // const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Delete,
      Endpoints.DEL_PROFILE_IMAGE,
      {},

      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response.status == 200) {
      yield put({
        type: ProfileAction.DEL_PROFILE_IMAGE_SUCCESS,
      });

      yield put({
        type: ProfileAction.GET_PROFILE,
      });


    } else {
      yield put({
        type: ProfileAction.DEL_PROFILE_IMAGE_FAIL,
      });
    }

  }

  static *dashboardReports(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.DASHBOARD_REPORTS({ ...payload }),
      {
        Authorization: `Bearer ${token}`,
      },
      null,
    );
    if (response?.status === 200) {
      yield put({
        type: ProfileAction.DASHBOARD_REPORTS_SUCCESS,
        payload: {
          spot: response?.data?.data?.spot,
          contract: response?.data?.data?.contract,
          labels: response?.data?.data?.chart_data?.labels,
          chart_data: response?.data?.data?.chart_data?.data,
          // loads_count_by_status: response?.data?.data?.loads_count_by_status,
        },
      });
    } else {
      yield put({ type: ProfileAction.DASHBOARD_REPORTS_FAIL });
    }
  }

  static *downloadCsvReports(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.DOWNLOAD_CSV_REPORTS({ ...payload }),
      {
        Authorization: `Bearer ${token}`,
      },
      null,
    );
    
    if (response?.status === 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      })
      yield put({
        type: ProfileAction.DOWNLOAD_CSV_REPORT_SUCCESS,
      });
    } else {
      yield put({ type: ProfileAction.DOWNLOAD_CSV_REPORT_FAIL });
    }
  }

  static *documentsMgExpress(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.DOCUMENTS_MG_EXPRESS({ ...payload }),
      {
        Authorization: `Bearer ${token}`,
      },
      null,
    );
    
    if (response?.status === 200) {
      showMessage.current({
        message: response?.data?.message,
        status: "success",
      })
      yield put({
        type: ProfileAction.DOCUMENTS_MG_EXPRESS_SUCCESS,
      });
    } else {
      yield put({ type: ProfileAction.DOCUMENTS_MG_EXPRESS_FAIL });
    }
  }

  // pagination for dashboard vehicle
  static *dashboardData(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.DASHBOARD_DATA({ ...payload }),
      {
        Authorization: `Bearer ${token}`,
      },
      null,
      true
    );
    
    
    if (response?.status === 200) {
      yield put({
        type: ProfileAction.DASHBOARD_DATA_SUCCESS,
        payload: {
          ...response?.data?.data,
          data: response?.data?.data?.data,
          vehicle_awarded: response?.data?.data?.vehicle_awarded,
          vehicle_placed: response?.data?.data?.vehicle_placed,
          search: payload?.search,
          fromSearch: payload?.fromSearch,
          vehicle_type: payload?.vehicle_type,
          counts: response?.data?.data?.counts,
          vehicleSearch: payload?.vehicleSearch,
        },
      });
    } else if (response?.status === 422) {
      if (payload?.callBack) {
        payload.callBack("422");
      }
      yield put({ type: ProfileAction.DASHBOARD_DATA_FAIL });
    }
    else {
      yield put({ type: ProfileAction.DASHBOARD_DATA_FAIL });
    }
  }

// dashboard report page
  static *dashboardDataReportNew(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.DASHBOARD_DATA({ ...payload }),
      {
        Authorization: `Bearer ${token}`,
      },
      null,
      true
    );
    if (response?.status === 200) {
      yield put({
        type: ProfileAction.DASHBOARD_REPORT_NEW_SUCCESS,
        payload: {
          ...response?.data?.data,
          PostedAwardedCounts: response?.data?.data?.PostedAwardedCounts,
          PostedAwardedDate: response?.data?.data?.PostedAwardedDate,
          LoadTypeCounts: response?.data?.data?.LoadTypeCounts,
          tabCounts: response?.data?.data?.tabCounts,
          search: payload?.search,
          fromSearch: payload?.fromSearch,
          vehicle_type: payload?.vehicle_type,

        },
      });
    } else if (response?.status === 422) {
      if (payload?.callBack) {
        payload.callBack("422");
      }
      yield put({ type: ProfileAction.DASHBOARD_REPORT_NEW_FAIL  });
    }
    else {
      yield put({ type: ProfileAction.DASHBOARD_REPORT_NEW_FAIL });
    }
  }

  //dasboard load detail
  static *dashboardDataLoadDetail(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.DASHBOARD_DATA({ ...payload }),
      {
        Authorization: `Bearer ${token}`,
      },
      null,
      true
    );
    if (response?.status === 200) {
      yield put({
        type: ProfileAction.DASHBOARD_LOAD_DETAIL_SUCCESS,
        payload: {
          ...response?.data?.data,
          listing: response?.data?.data?.listing,
          search: payload?.search,
          fromSearch: payload?.fromSearch,
          vehicle_type: payload?.vehicle_type,
          vehicleSearch: payload?.vehicleSearch,
        },
      });
    } else if (response?.status === 422) {
      if (payload?.callBack) {
        payload.callBack("422");
      }
      yield put({ type: ProfileAction.DASHBOARD_LOAD_DETAIL_FAIL  });
    }
    else {
      yield put({ type: ProfileAction.DASHBOARD_LOAD_DETAIL_FAIL });
    }
  }
}
