import { VehicleIncDec } from "@temp/components";
import { LOAD_TYPE } from "@temp/constants";
import React from "react";
let timmerRef_weight_inc = null;
let timmerRef_weight_dec = null;

let timmerRef_vehicle_inc = null;
let timmerRef_vehicle_dec = null;
export const VehicleCounters = ({ item, new_load_detail, set_new_load_detail, updateValue }) => {
    return (
        <div>
            <VehicleIncDec
                className="labelWithIncrementField"
                text="Max Cargo Weight Per Vehicle (Tons)"
                value={item?.max_vehicle_weight}
                name={"max_vehicle_weight"}
                withInputBox={true}
                updateValue={(val) => {
                    if(updateValue){
                        updateValue({key:"max_vehicle_weight", val:val})
                    }
                }}
                from_containerized={new_load_detail?.load_type.title === LOAD_TYPE.fcl}
                onTouchStart={(type) => {
                    if (type == "inc") {
                        set_new_load_detail({ ...new_load_detail, max_vehicle_weight: item.max_vehicle_weight++ });
                        timmerRef_weight_inc = setInterval(() => {
                            set_new_load_detail({ ...new_load_detail, max_vehicle_weight: item.max_vehicle_weight++ });
                        }, 300);
                    } else {
                        if (item?.max_vehicle_weight > 1) {
                            set_new_load_detail({ ...new_load_detail, max_vehicle_weight: item.max_vehicle_weight-- });
                            timmerRef_weight_dec = setInterval(() => {
                                set_new_load_detail({ ...new_load_detail, max_vehicle_weight: item.max_vehicle_weight-- });
                            }, 300);
                        }
                    }
                }}
                onTouchEnd={() => {
                    clearInterval(timmerRef_weight_inc);
                    clearInterval(timmerRef_weight_dec);
                }}
                onMouseDown={(type) => {
                    if (type == "inc") {
                        set_new_load_detail({ ...new_load_detail, max_vehicle_weight: item.max_vehicle_weight++ });
                        timmerRef_weight_inc = setInterval(() => {
                            set_new_load_detail({ ...new_load_detail, max_vehicle_weight: item.max_vehicle_weight++ });
                        }, 300);
                    } else {
                        if (item?.max_vehicle_weight > 1) {
                            set_new_load_detail({ ...new_load_detail, max_vehicle_weight: item.max_vehicle_weight-- });
                            timmerRef_weight_dec = setInterval(() => {
                                set_new_load_detail({ ...new_load_detail, max_vehicle_weight: item.max_vehicle_weight-- });
                            }, 300);
                        }
                    }
                }}
                onMouseUp={() => {
                    clearInterval(timmerRef_weight_inc);
                    clearInterval(timmerRef_weight_dec);
                }}
            />
            <VehicleIncDec
                text="Number of Vehicles"
                withInputBox={true}
                value={item?.vehicle_quantity}
                updateValue={(val) => {
                    if(updateValue){
                        updateValue({key:"vehicle_quantity", val:val})
                    }
                }}
                onTouchStart={(type: any) => {
                    if (type == "inc") {
                        set_new_load_detail({
                            ...new_load_detail,
                            vehicle_quantity: item.vehicle_quantity++,
                        });

                        timmerRef_vehicle_inc = setInterval(() => {
                            set_new_load_detail({ ...new_load_detail, vehicle_quantity: item.vehicle_quantity++ });
                        }, 300);
                    } else {
                        set_new_load_detail({ ...new_load_detail, vehicle_quantity: item.vehicle_quantity-- });
                        timmerRef_vehicle_dec = setInterval(() => {
                            set_new_load_detail({ ...new_load_detail, vehicle_quantity: item.vehicle_quantity-- });
                        }, 300);
                    }
                }}
                onTouchEnd={() => {
                    clearInterval(timmerRef_vehicle_inc);
                    clearInterval(timmerRef_vehicle_dec);
                }}
                onMouseDown={(type) => {
                    if (type == "inc") {
                        set_new_load_detail({ ...new_load_detail, vehicle_quantity: item.vehicle_quantity++ });
                        timmerRef_vehicle_inc = setInterval(() => {
                            set_new_load_detail({ ...new_load_detail, vehicle_quantity: item.vehicle_quantity++ });
                        }, 300);
                    } else {
                        if (item?.vehicle_quantity > 1) {
                            set_new_load_detail({ ...new_load_detail, vehicle_quantity: item.vehicle_quantity-- });
                            timmerRef_vehicle_dec = setInterval(() => {
                                set_new_load_detail({ ...new_load_detail, vehicle_quantity: item.vehicle_quantity-- });
                            }, 300);
                        }
                    }
                }}
                onMouseUp={() => {
                    clearInterval(timmerRef_vehicle_inc);
                    clearInterval(timmerRef_vehicle_dec);
                }}
                name="vehicle_quantity"
            />
        </div>
    )
}