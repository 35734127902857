import React, { useState, useEffect } from "react";

import { MgExpressQuoteProps } from "./types";

import rootReducer from "../../../store/reducers";
import TitleLayout from "@temp/components/TitleLayout";
import "./style.scss";
import { DocumentsTable, PrimaryButton, SelectionModal } from "@temp/components";
import { Images } from "@temp/assets";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import AudioAnalyser from "react-audio-analyser";
import { isBrowser } from "react-device-detect";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import { showMessage } from "@temp/utils/helper";
import ReactAudioPlayer from "react-audio-player";
import MicIcon from "@mui/icons-material/Mic";
import { useHistory } from "react-router";
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<MgExpressQuoteProps> = (props) => {
  const dispatch = useDispatch();
  const [uploadDocuments, setUploadDocuments] = useState([]);
  const [showModalMedia, setShowModalMedia] = useState(false);
  const [record_url, set_record_url] = useState(null)

  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [status, controlAudio] = useState('')
  const [instructions, setInstructions] = useState("");
  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
  const mg_admin = metaData?.mg_recipient?.PHONE_NO;
  const mg_admin_code = metaData?.mg_recipient?.PHONE_CODE;

  
  
  

  const history = useHistory();

  const handleSubmit = () => {
    let final_payload = {
      upload_documents: uploadDocuments,
      recipients: [{
        phone_no: mg_admin,
        phone_code: mg_admin_code
      }],
      instructions,
      instructions_voice_note: record_url,
    }
    
    return
    dispatch(ProfileAction.PostLoad({ final_payload }));
  }


  const onChange = (e) => {
    setInstructions(e.target.value);
  };

  const onClickDocumentType = (val: any) => {
    window["docObj"] = val.key;
    setShowModalMedia(true);
  };


  useEffect(() => {
    let intervalId;
    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter + 1);
      }, 900);
    }

    return () => clearInterval(intervalId)

  }, [isActive, counter]);

  const stopTimer = () => {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");
  };

  var cb = (payload: any) => {
    set_record_url(payload)
  };

  const setUrlData = (data) => {
    let new_payload = {
      file_name: data?.fileName,
      cloudinary_url: data?.cloudinaryUrl,
      file_url: data?.fileUrl,
      mime_type: data?.mimeType
    }
    set_record_url(new_payload)
  }

  const audioProps = {
    audioType: "audio/wav",
    status,
    timeslice: 1000,
    startCallback: e => {
      
    },
    pauseCallback: e => {
      
    },
    stopCallback: e => {
      const formdata = new FormData()
      formdata.append('file', e)
      dispatch(ProfileAction.uploadFile({ data: formdata, cb }))
    },
    onRecordCallback: e => {
    },
    errorCallback: err => {
      
    }
  };

  useEffect(() => {
    const target = message => {
      setUrlData(JSON.parse(message?.detail))
    }
    window.addEventListener("VoiceRecordComplete", target);
    return () => {
      if (!isBrowser) {
        if (sendEventToAndroid("closeRecording")) {
          window.Android.closeRecording(
            String("close")
          );
        } else if (sendEventToiOS("closeRecording")) {
          window.webkit.messageHandlers.closeRecording.postMessage(
            JSON.stringify({
              close: "close",
            })
          );
        } else {
        }
      }
      window.removeEventListener("VoiceRecordComplete", (e) => { })

      controlAudio('inactive')
      stopTimer()
    }
  }, [])

  const handleCamrea = (data) => {
    if (sendEventToAndroid("accessCamera")) {
      window.Android.accessCamera(
        String("access")
      );
    } else if (sendEventToiOS("accessCamera")) {
      window.webkit.messageHandlers.accessCamera.postMessage(
        JSON.stringify({
          access: "access",
        })
      );
    } else {
    }
  }

  const handleDoc = (data) => {
    if (sendEventToAndroid("accessDoc")) {
      window.Android.accessDoc(
        String("access-doc")
      );
    } else if (sendEventToiOS("accessDoc")) {
      window.webkit.messageHandlers.accessDoc.postMessage(
        JSON.stringify({
          access: "access-doc",
        })
      );
    } else {
    }
  }

  const handleGallery = (data) => {
    if (sendEventToAndroid("accessGallery")) {
      window.Android.accessGallery(
        String("access-gallery")
      );
    } else if (sendEventToiOS("accessGallery")) {
      window.webkit.messageHandlers.accessGallery.postMessage(
        JSON.stringify({
          access: "access-gallery",
        })
      );
    } else {
    }
  }

  const onClickMediaType = (values: any) => {
    setShowModalMedia(false);
  };

  const handleUploadImage = (event: any, value: number) => {
    const formData = new FormData();
    formData.append("file", event?.target?.files[0]);
    setShowModalMedia(false);
    var cb = (payload: any) => {
      let data = [...uploadDocuments]
      data.push(payload)
      setUploadDocuments(data)
    };
    dispatch(ProfileAction.uploadFile({ data: formData, cb }));
  };




  return (
    <TitleLayout pageNumber={2} progress={20} title="MG Express Quote">
      <div className="secondaryContainer">
        {/* Coming Soon.... */}
        <div className="uploadDocumentFileBoxWrap" style={{ width: "100%" }}>
          <FadeInComponent>
            <DocumentsTable
              values={uploadDocuments}
              onHandleDelete={(ind) => {
                let documents = uploadDocuments.filter(
                  (val: any, index) => index !== ind
                );
                setUploadDocuments(documents)
              }}
              titleIcon={Images.bill}
              screen={'document'}
              title="Doc/images"
              onClickDocumentType={onClickDocumentType}
              datas={
                {
                  key: "other",
                  icon: Images.bill,
                  title: "Doc/images"
                }
              }
            />
          </FadeInComponent>

          <SelectionModal
            showModal={showModalMedia}
            title={"Media"}
            type="media"
            handleUploadImage={handleUploadImage}
            data={[
              {
                key: 0,
                iconClass: 'icons-camera',
                title: "Camera",
              },

              {
                key: 1,
                iconClass: 'icons-gallery',
                title: "Gallery",
              },
              {
                key: 2,
                iconClass: 'icons-document',
                title: "Document",
              }
            ]}
            onClick={onClickMediaType}
            handleModal={(value: boolean) => {
              setShowModalMedia(value);
            }}
            handleCamrea={handleCamrea}
            handleDoc={handleDoc}
            handleGallery={handleGallery}
          />
        </div>

        <FadeInComponent>
          <div>
            <p className="simpleLabel fw-5">
              Record Voice Note
            </p>
            <AudioAnalyser {...audioProps}>
            </AudioAnalyser>
            <div className="recorderWrapper">
              {
                isActive ?
                  <div className="recording">
                    <div className="recordTime">
                      <label>
                        {minute}
                        <span>:</span>
                        {second}{" "}
                      </label>
                      {" | Start Recording..."}
                    </div>
                    <div
                      className="icon"
                      onClick={() => {
                        if (!isBrowser) {
                          if (sendEventToAndroid("voiceRecordStop")) {
                            window.Android.voiceRecordStop(
                              String("stop")
                            );
                          } else if (sendEventToiOS("voiceRecordStop")) {
                            window.webkit.messageHandlers.voiceRecordStop.postMessage(
                              JSON.stringify({
                                start: "stop",
                              })
                            );
                          } else {
                          }
                        }
                        controlAudio("inactive")
                        stopTimer()
                      }}
                    >
                      <MicIcon />
                    </div>
                  </div>
                  :
                  <div>
                    {record_url ? (
                      <div className="recorder">
                        <ReactAudioPlayer
                          style={{
                            marginTop: 10
                          }}
                          src={record_url?.cloudinary_url}
                          autoPlay={false}
                          controls
                          loop={false}
                        />

                        <span
                          className="icons-cross"
                          onClick={() => {
                            set_record_url(null)
                            showMessage.current({
                              message: "Voice note deleted successfully.",
                              status: 'success'
                            })
                          }}
                        ></span>
                      </div>
                    ) : (
                      <div className="startRecord">
                        <label>
                          Start Recording
                        </label>
                        <div
                          style={{
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            setTimeout(() => {
                              if (!isBrowser) {
                                if (sendEventToAndroid("voiceRecordStart")) {
                                  window.Android.voiceRecordStart(
                                    String("start")
                                  );
                                } else if (sendEventToiOS("voiceRecordStart")) {
                                  window.webkit.messageHandlers.voiceRecordStart.postMessage(
                                    JSON.stringify({
                                      start: "start",
                                    })
                                  );
                                } else {
                                }
                              } else {
                                controlAudio('recording')
                              }
                            }, 2000);

                            setIsActive(!isActive);
                          }}
                        >
                          <MicIcon />
                        </div>
                      </div>
                    )}
                  </div>
              }
            </div>
          </div>
        </FadeInComponent>

        <FadeInComponent>
          <p className="simpleLabel fw-5">Instructions</p>
          <div className="textareaWrapper">
            <textarea
              style={{
                outline: "none",
                marginBottom: '1.5rem'
              }}
              maxLength={250}
              value={instructions}
              onChange={onChange}
              placeholder="Enter Instructions"
            />
          </div>
        </FadeInComponent>

        <div className="">
          <PrimaryButton
            title="Submit"
            onClick={() => {
              handleSubmit()
            }}
            disabled={!uploadDocuments?.length}
            buttonStyle={{
              height: 40,
              width: 350,
            }}
          />
        </div>
      </div>
    </TitleLayout>
  );
};

export default Page;
