import React from "react";
import { ILocationsPageProps } from "./types";

import Page from "./Page";

const View: React.FC<ILocationsPageProps> = ({
  room,
  role,
  id,
  match,
  canPlay,
  data = {},
}: ILocationsPageProps) => {
  return (
    <Page
      role={role}
      match={match}
      room={room}
      id={id}
      canPlay={canPlay}
      data={data}
    />
  );
};

export default View;
