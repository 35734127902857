// Import: Dependencies
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
// Imports: Redux Root Reducer
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import rootReducer from "./reducers/index";

// Imports: Redux Root Saga
import { rootSaga } from "./middleware";

const persistConfig = {
  key: "root",
  storage,
  blacklist:['Load','Drawer',]
};

// Middleware: Redux Saga
const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);
// Redux: Store

const middleware = [];
middleware.push(sagaMiddleware);
// if (process.env.REACT_APP_API_URL !== "https://mg-production-web.herokuapp.com/api/") {
  if (process.env.REACT_APP_API_URL !== "https://bk.merigari.com/api/") {
  middleware.push(logger);
}

const store = createStore(persistedReducer, {}, applyMiddleware(...middleware));
const persistor = persistStore(store);
// Middleware: Redux Saga
sagaMiddleware.run(rootSaga);

// Exports
export { store, persistor };


// const persistConfig = {
//   key: 'root',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)

// export default () => {
//   let store = createStore(persistedReducer)
//   let persistor = persistStore(store)
//   return { store, persistor }
// }