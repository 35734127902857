import { CustomText } from "@temp/components";
import { Colors, Images } from "@temp/assets";
import React, { useState, useEffect } from "react";
import "../LoadDetailPoster/style.scss";
import { isBrowser } from "react-device-detect";
import { Accordion, AccordionSummary, Grid, InputAdornment, OutlinedInput } from "@mui/material/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RenderRTL } from "./RenderRTL";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import { useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
import moment from "moment";
import { LOAD_TYPE } from "@temp/constants";
import InfoIcon from "@mui/icons-material/Info";
import { phoneNumberFormater, price_units_transalation } from "@temp/utils/helper";
var numeral = require('numeral')

export type RootState = ReturnType<typeof rootReducer>;

export const Poster = ({
  loadDetail,
  expand,
  setExpand,
  totalContainers,
  type,
  bid_deposit_fee_amount,
  set_bid_deposit_fee_amount,
  for_bit_amount = false,
  disableAmount,
  showMessage = false,
  category,
  is_recipient = false,
  fromViewer = false
}) => {

  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const [inputTypee, setInputTypee] = useState('text')
  const [totalVehicle, setTotalVehicle] = useState(0);
  let totalVehicleCount = 0;

  useEffect(() => {
    if (loadDetail) {
      if (loadDetail?.categories.length > 0) {
        loadDetail?.categories.forEach(element => {
          if (element.vehicle_quantity) {
            totalVehicleCount += Number(element.vehicle_quantity)
          } else {
          }
        });
      }
      setTotalVehicle(totalVehicleCount)
    }
  }, [loadDetail])

  const [estimatedVehicle, setEstimatedVehicle] = useState(0);
  let estimatedVehicleCount = 0;

  useEffect(() => {
    if (category) {
      if (category?.length > 0) {
        category.forEach(element => {
          if (element.vehicle_quantity) {
            estimatedVehicleCount += Number(element.vehicle_quantity)
          } else {
          }
        });
      }
      setEstimatedVehicle(estimatedVehicleCount)
    }
  }, [category])

  let total_weight = 0
  if (loadDetail?.price_unit == "Kg") {
    total_weight = Number(loadDetail?.gross_weight) * 1000
  } else if (loadDetail?.price_unit == "Ton") {
    total_weight = Number(loadDetail?.gross_weight)
  } else if (loadDetail?.price_unit == "F.Ton") {
    total_weight = loadDetail?.total_freight_ton
  } else if (loadDetail?.price_unit == "CBM") {
    total_weight = loadDetail?.cargo_volume
  } else if (loadDetail?.price_unit === "Liter") {
    total_weight = loadDetail?.cargo_liter
  }

  const [totalItem, setTotalItems] = useState(0);
  let totalItemCount = 0;

  useEffect(() => {
    if (loadDetail) {
      if (loadDetail?.categories.length > 0) {
        loadDetail?.categories.forEach(element => {
          if (element.unit_quantity) {
            totalItemCount += Number(element.unit_quantity)
          }
        });
      }
      setTotalItems(totalItemCount)
    }
  }, [loadDetail])

  return (
    <div className="accordion-container">
      <Accordion
        onChange={() => setExpand(!expand)}
        expanded={expand}
        className="accordion-background"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className="fw-5 accHeading">
            <span>Load Details</span>
            {
              is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">لوڈ کی تفصیلات </span>
              </>
            }
          </p>
        </AccordionSummary>
        <div className="accordion-content">

          {type == "received" ?
            <>
              <p className="grayLabel">
                <span>Load From</span>
                {
                  is_recipient &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">لوڈ بھجنے والا</span>
                  </>
                }
              </p>
              <div className="load-detail-user-container">
                <div className="load-detail-user">
                  <img
                    src={
                      loadDetail?.poster?.picture || metaData?.default_avatar
                    }
                  />
                </div>
                <div className="user-phone-whatsapp">
                  <div className="user-detail">
                    <p className="">
                      {loadDetail?.poster?.name}
                    </p>
                    {
                      loadDetail?.poster?.phone_no && (
                        <p className="title">
                          {phoneNumberFormater(loadDetail?.poster?.phone_code, loadDetail?.poster?.phone_no)}
                        </p>
                      )
                    }
                  </div>
                  {
                    loadDetail?.poster?.phone_no && (
                      <div className="whatsapp-button-container">
                        {isBrowser ? (
                          <>
                            <a
                              href={`tel:92${loadDetail?.poster?.phone_no}`}
                              target="blank"
                              style={{ marginRight: 30 }}
                              className="phone-whatsapp-button"
                            >
                              <img
                                alt=""
                                style={{ height: "100%" }}
                                src={Images.Phone}
                              />
                            </a>
                            <a
                              href={`https://wa.me/+92${loadDetail?.poster?.phone_no}`}
                              target="blank"
                              className="phone-whatsapp-button"
                            >
                              <img
                                alt=""
                                style={{ height: "100%" }}
                                src={Images.WhatsAppBgGreen}
                              />
                            </a>
                          </>
                        ) : (
                          <>
                            <img
                              alt=""
                              className="phone-whatsapp-button"
                              style={{ height: "100%", marginRight: 30 }}
                              src={Images.Phone}
                              onClick={() => {
                                if (sendEventToAndroid("loadDetailPhone")) {
                                  window.Android.loadDetailPhone(
                                    String(loadDetail?.poster?.phone_no)
                                  );
                                } else if (sendEventToiOS("loadDetailPhone")) {
                                  window.webkit.messageHandlers.loadDetailPhone.postMessage(
                                    JSON.stringify({
                                      phoneNumber: loadDetail?.poster?.phone_no,
                                    })
                                  );
                                } else {
                                }
                              }}
                            />
                            <img
                              onClick={() => {
                                if (sendEventToAndroid("loadDetailWhatsapp")) {
                                  window.Android.loadDetailWhatsapp(
                                    String(loadDetail?.poster?.phone_no)
                                  );
                                } else if (sendEventToiOS("loadDetailWhatsapp")) {
                                  window.webkit.messageHandlers.loadDetailWhatsapp.postMessage(
                                    JSON.stringify({
                                      phoneNumber: loadDetail?.poster?.phone_no,
                                    })
                                  );
                                } else {
                                }
                              }}
                              className="phone-whatsapp-button"
                              alt=""
                              style={{ height: "100%" }}
                              src={Images.WhatsAppBgGreen}
                            />
                          </>
                        )}
                      </div>
                    )
                  }
                </div>
              </div>
            </> : null}

          {loadDetail?.my_allocates?.allocated_to && (
            <RenderRTL
              title="Load Allocated To"
              val={loadDetail?.my_allocates?.allocated_to?.name}
              is_recipient={is_recipient}
            />
          )}

          {loadDetail?.my_allocates?.allocated_by && (
            <RenderRTL
              title="Allocated By"
              val={loadDetail?.my_allocates?.allocated_by?.name}
              is_recipient={is_recipient}
            />
          )}

          {!fromViewer && loadDetail?.title  ? (
            <RenderRTL
              title={ loadDetail?.inquiry_type == "Contract Rate" || loadDetail?.contract_id ? "Contract Name" : "Load Name"}
              sep="/"
              urdutitle={loadDetail?.contract_id ? "معاہدے کا نام" : "لوڈ کا نام"}
              val={`${loadDetail?.inquiry_type == "Contract Rate" || loadDetail?.contract_id ? loadDetail?.contract_name : loadDetail?.title}`}
              is_recipient={is_recipient}
            />
          ) : null}

          {loadDetail?.contract_id ? (
            <RenderRTL
              title={"Load Name"}
              sep="/"
              urdutitle={"لوڈ کا نام"}
              val={loadDetail?.title}
              is_recipient={is_recipient}
            />
          ) : null}

          <RenderRTL
            title="Reference Number"
            sep="/"
            urdutitle="حوالہ نمبر"
            val={`${loadDetail?.ref_no || "--"}`}
            is_recipient={is_recipient}
          />

          {
            fromViewer ?
              <RenderRTL
                title="Added by"
                sep="/"
                val={loadDetail?.viewer_added_by}
                is_recipient={is_recipient}
              /> : null
          }
          {
            !fromViewer ?
              <RenderRTL
                title="Inquiry Type"
                sep="/"
                urdutitle="انکوائری کی قسم"
                val={loadDetail?.contract_id ? "Contract Rate" : loadDetail?.inquiry_type?.title}
                is_recipient={is_recipient}
              /> : null
          }

          {
            loadDetail?.inquiry_type?.title !== "Contract Rate" && !loadDetail?.contract_id && !loadDetail?.is_unit_basis ?
              <>
                {
                  loadDetail?.load_type?.title == LOAD_TYPE.fcl
                    && totalContainers ?
                    <RenderRTL
                      title={"Total No. of Containers"}
                      sep="/"
                      urdutitle="کنٹینرز کی کل تعداد"
                      val={`${totalContainers}`}
                      is_recipient={is_recipient}
                    /> : null
                }
              </> : null}

          {
            loadDetail?.inquiry_type?.title !== "Contract Rate" && !loadDetail?.contract_id && !loadDetail?.is_unit_basis ?
              <>
                {
                  loadDetail?.load_type.title === "Non-Containerized"
                    && loadDetail?.quote_by === "vehicle"
                    // && totalContainers 
                    ?
                    <RenderRTL
                      title={"Total No. of Vehicles "}
                      sep="/"
                      urdutitle="گاڑیوں کی کل تعداد"
                      val={`${totalContainers}`}
                      is_recipient={is_recipient}
                    /> :
                    total_weight && fromViewer !== true ?
                      <RenderRTL
                        title={`Total ${loadDetail?.price_unit} in load`}
                        val={`${numeral(total_weight).format('0,0.00')}`}
                        is_recipient={is_recipient}
                        sep="/"
                        urdutitle={`لوڈ میں کل ${price_units_transalation[loadDetail?.price_unit]}`}
                      /> : null
                }
              </> : null
          }


          {
            loadDetail?.inquiry_type?.title !== "Contract Rate" && !loadDetail?.contract_id && !loadDetail?.is_unit_basis ?
              <>
                {
                  loadDetail?.load_type.title === "Containerized" ?
                    <RenderRTL
                      title={"Total No. of Vehicles "}
                      sep="/"
                      urdutitle="گاڑیوں کی کل تعداد"
                      val={totalVehicle}
                      is_recipient={is_recipient}

                    />
                    : null
                }
              </> : null
          }
          {loadDetail?.quote_by === "weight_volume" && !loadDetail?.is_unit_basis
            && loadDetail?.no_of_packages && fromViewer ?
            <>
              <RenderRTL
                title={"Total No. of Packages "}
                val={`${loadDetail?.no_of_packages}`}
              />
            </> : null
          }

          {loadDetail?.quote_by === "weight_volume" && loadDetail?.is_unit_basis ?
            <>
              <RenderRTL
                title={"Total No. of Items "}
                val={`${totalItem}`}
              />
            </> : null
          }

          {
            loadDetail?.inquiry_type?.title == "Contract Rate" && estimatedVehicle &&
              !loadDetail?.contract_id && !loadDetail?.is_unit_basis ?
              <>
                {
                  loadDetail?.quote_by === "vehicle" ?
                    <RenderRTL
                      title={"Estimated No. of Vehicles "}
                      val={estimatedVehicle}
                      is_recipient={is_recipient}

                    />
                    : null
                }
              </> : null
          }
          {
            fromViewer && loadDetail?.quote_by == "weight_volume" && !loadDetail?.is_unit_basis ?
              <>
                <RenderRTL
                  title={`Weight / Volume`}
                  val={`${numeral(total_weight).format('0,0.00')} ${loadDetail?.price_unit}`}
                />
              </> : null
          }

          <RenderRTL
            title="Load Type"
            sep="/"
            urdutitle="لوڈ کی قسم"
            val={loadDetail?.load_type?.title}
            is_recipient={is_recipient}
          />

          {loadDetail?.cargo_description && !fromViewer ?
            <RenderRTL
              title="Cargo Description"
              sep="/"
              urdutitle="کارگو کی تفصیل"
              val={
                loadDetail?.cargo_description ? loadDetail?.cargo_description : ""}
              is_recipient={is_recipient}

            /> : null}

          {
            type == "received" ?
              <RenderRTL
                title="Received Date & Time"
                sep="/"
                urdutitle="موصول ہونے کی تاریخ اور وقت"
                val={moment(loadDetail?.created_at).format("DD MMM, YYYY hh:mm A")}
                is_recipient={is_recipient}
              />
              : null}
          <div style={{ height: 16 }}></div>
          {
            loadDetail?.is_container_deposit_by_transporter && !loadDetail?.is_quote_price_deposit_fee_inclusive && for_bit_amount && !fromViewer ?
              <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
                pointerEvents: disableAmount ? 'none' : 'auto'
              }}>
                <Grid item xs={6}>
                  <div
                    style={{}}
                    className="transactionFieldLabel"
                  >
                    Deposit fee amount (Without GST)
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    padding: 0,
                  }}
                >
                  <OutlinedInput
                    inputProps={{
                      step: "1",
                    }}
                    type={inputTypee}
                    onFocus={(e) => {
                      setInputTypee("number")
                    }}
                    onBlur={() => {
                      setInputTypee('text')
                    }}
                    onInput={(e) => {
                      set_bid_deposit_fee_amount(e.target.value)
                    }}
                    value={inputTypee !== 'text' ? bid_deposit_fee_amount : bid_deposit_fee_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    style={{ height: 35 }}
                    disabled={disableAmount}
                    aria-describedby="standard-weight-helper-text"
                    endAdornment={
                      <InputAdornment position="end">
                        (PKR)
                      </InputAdornment>
                    }
                    className="rightFormField"
                  />
                </Grid>

              </div> : null
          }
          {!loadDetail?.is_allow_partial_load && showMessage && !loadDetail?.contract_id && !fromViewer ?
            <div className="error-container info smallErrorContainer" style={{ marginBottom: 10 }}>
              <div>
                <InfoIcon className="error-icon" />
              </div>
              <div className="error-text">
                Partial load is not Allowed
                {
                  is_recipient &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">پارشل لوڈ کی اجازت نہیں ہے</span>
                  </>
                }
              </div>
            </div> : null}
        </div>
      </Accordion>
    </div>
  );
};
