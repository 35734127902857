import React, { useEffect, useState } from "react";
import { RegistrationScreenProps } from "./types";
import {
  BackHeader,
  CustomText,
  PrimaryButton,
  SignupContainer,
} from "@components";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import {
  Checkbox,
  Input,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material/";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { AuthAction } from "@temp/store/actions";
import rootReducer from "../../../store/reducers";
import { showMessage } from "@temp/utils/helper";
// import Autocomplete from '@mui/lab/Autocomplete';
// import TextField from '@mui/material//TextField';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


export type RootState = ReturnType<typeof rootReducer>;
const Page: React.FC<RegistrationScreenProps> = (props) => {
  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const signupData = useSelector((state: RootState) => state.Auth.signupData);
  
  const [userProfession, setUserProfession] = useState([]);
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  
  

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    let current_city = metaData?.cities?.find(item => item?.id == signupData?.city_id)
    setValue(current_city ? current_city : null || null)
    setInputValue(current_city?.label || "")
  }, [])

  useEffect(() => {
    setUserProfession(signupData?.user_professions);
  }, [])


  const handleChangeProfession = (event) => {
    setUserProfession([event.target.value]);
  };

  const handleChange = (e) => {
    if (e.target.name === "fullName") {
      dispatch(
        AuthAction.SetSignupData({ ...signupData, fullName: e.target.value })
      );
    } else if (e.target.name === "email") {
      dispatch(
        AuthAction.SetSignupData({ ...signupData, email: e.target.value })
      );
    }
    else if (e.target.name === "companyName") {
      if (e.target.value.length <= 50) {
        dispatch(
          AuthAction.SetSignupData({ ...signupData, companyName: e.target.value })
        );
      } else {
        showMessage.current({
          message: "Company Name should not be greater than 50 characters",
          status: "error"
        })
      }
    }
    else {
      dispatch(
        AuthAction.SetSignupData({ ...signupData, aliasName: e.target.value })
      );
    }
  };

  const handleNext = () => {
    const payload = {
      ...signupData,
      fullName: signupData.fullName,
      email: signupData.email ? signupData?.email : "",
      companyName: signupData.companyName ? signupData.companyName : "",
      user_professions: userProfession,
      city_id: value?.id

    };
    
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(payload?.email) || (payload?.email === "")) {
      dispatch(AuthAction.SetSignupData(payload));
      history.push("upload-cnic", {
        phoneNumber: state?.phoneNumber,
        whatsapp: state?.whatsapp,
        mobileNetwork: state?.mobileNetwork,
      });
    } else {
      showMessage.current({ message: "Please enter a valid email.", status: 'error' })
    }
  };

  let format_num = state?.phoneNumber
  let new_phone = format_num?.split("");
  new_phone?.splice(3, 0, " ");
  let joined_no = new_phone?.join("");

  let format_num_1 = state?.whatsapp
  let new_phone_1 = format_num_1?.split("");
  new_phone_1?.splice(3, 0, " ");
  let joined_no_1 = new_phone?.join("");

  return (
    <div className="signup-wrapper">
      <BackHeader
        title="Personal Information"
        onClick={() => {
          history.goBack();
        }}
      />
      <SignupContainer containerHeight="">
        <div className="insideafterOTPContainer">
          <div className="signupRegisterScreenWrapper">
            <div className="number-verified-message-container">
              <img alt="" src={Images.Check} />
              <CustomText.OpenSansRegular
                textStyle={{
                  color: Colors.Green,
                  fontSize: "0.813rem"

                }}
              >
                {/* {`Great! Your number has been verified 0${state?.phoneNumber}`} */}
                {`Great! Your number has been verified 0${joined_no}`}

              </CustomText.OpenSansRegular>
            </div>
            <div className="register-text-container">
              <p>Please enter your Full Name,Email and User Role</p>
            </div>
            <div className="signup-form-container">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                }}
              >
                Full Name *
              </CustomText.OpenSansSemiBold>
              <input
                type="text"
                autoFocus
                placeholder="Enter Full Name"
                name="fullName"
                className="signup-form-field"
                value={signupData.fullName}
                onChange={handleChange}
              />
            </div>
            <div className="signup-form-container">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                }}
              >
                Email
              </CustomText.OpenSansSemiBold>
              <input
                type="text"
                placeholder="Enter Email (Optional)"
                name="email"
                className="signup-form-field"
                value={signupData.email}
                onChange={handleChange}
                maxLength={50}
              />
            </div>

            <div className="signup-form-container">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                }}
              >
                Company Name *
              </CustomText.OpenSansSemiBold>
              <input
                type="text"
                placeholder="Enter Company Name"
                name="companyName"
                className="signup-form-field"
                value={signupData.companyName}
                onChange={handleChange}
              // maxLength={50}
              />
            </div>

            <div className="edit-input-wrapper">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                }}
              >
                User Role *
              </CustomText.OpenSansSemiBold>
              <div className="userSelectBox">
                <Select
                  name="user_professions"
                  defaultValue={userProfession}
                  onChange={handleChangeProfession}
                  className="edit-select-box"
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple={false}
                  input={<Input />}
                  value={userProfession}
                  // renderValue={(selected: any) => selected?.join(", ")}
                  MenuProps={MenuProps}
                >
                  {metaData?.user_professions?.map((item) => {
                    return (
                      <MenuItem
                        value={item?.name}
                        key={item?.name}
                      >
                        {/* <Checkbox
                          checked={userProfession.indexOf(item?.name) > -1}
                        /> */}
                        {/* <ListItemText primary= */}
                        {item?.name}
                        {/* /> */}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>

            <div className="signup-form-container cityNameContainer">
              <>
                <CustomText.OpenSansSemiBold
                  textStyle={{
                    fontSize: "0.875rem",
                    color: Colors.Grayish,
                  }}
                >
                  City Name *
                </CustomText.OpenSansSemiBold>
                {/* <Autocomplete
                  id="country-select-demo"
                  style={{ width: "100%" }}
                  options={metaData?.cities}
                  autoHighlight
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  getOptionLabel={(option) => option?.label}
                  renderOption={(option) => {
                    return (
                      <React.Fragment>
                        {option?.label}
                      </React.Fragment>
                    )
                  }
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}

                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'country-select-demo', // disable autocomplete and autofill
                      }}
                    />
                  )}
                /> */}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={metaData?.cities}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <TextField {...params} />}
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
              </>
            </div>

          </div>
          <div className="bottomSignupScreen">
            <div className="insideSignupBtnWrap">
              <PrimaryButton
                disabled={!signupData.fullName || !userProfession?.length || !value || !signupData.companyName}
                title="Next"
                onClick={() => {
                  handleNext();
                }}
              />
            </div>
          </div>
        </div>
      </SignupContainer>
    </div>
  );
};

export default Page;
