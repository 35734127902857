import { Colors } from '@temp/assets'
import { CustomText } from '@temp/components'
import React from 'react'
import moment from "moment";

var numeral = require("numeral");


export const HistoryVehicle = ({ history_vechiles }) => {
    return (
        <div>
            {
                history_vechiles?.map((award, award_index) => {
                    
                    return (
                        <div
                            style={{
                                marginTop: 10,
                                borderRadius: 0
                            }}
                            className={`awarded-vehicle-list cursor-default`}
                        >
                            {/* <div className="flex_row_div">
                                <CustomText.OpenSansSemiBold textStyle={{ marginLeft: 5 }}>Details of Vehicle {award_index + 1}</CustomText.OpenSansSemiBold>
                                <div style={{ display: "flex", marginRight: 12, alignItems: "center", }}>
                                    <CustomText.OpenSansSemiBold
                                        textStyle={{ fontSize: "0.688rem", color: Colors.Primary }}>
                                        Completed
                                    </CustomText.OpenSansSemiBold>
                                </div>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{ paddingLeft: 5 }}>
                                    <CustomText.OpenSansRegular>
                                        {award?.vehicle?.plate_number}
                                        <span style={{
                                            fontWeight: 'bold'
                                        }}>
                                            {award?.is_replaced ? " (Replaced Vehicle)" : null}
                                        </span>
                                    </CustomText.OpenSansRegular>
                                </div>
                            </div> */}

                            {
                                award?.is_replaced == 1 && award?.replaced_by !== null ?
                                    <div className="vehicleDetailsTextWrap">
                                        <div className="fw-5"
                                        // style={{
                                        //     marginLeft: 5
                                        // }}
                                        >
                                            Replaced Vehicle
                                        </div>
                                        {/* <CustomText.OpenSansRegular

                                        >
                                            <span>
                                                {
                                                    `From- ${award?.replaced_by?.vehicle?.plate_number} ${moment(award?.replaced_by?.created_at).format('DD-MM-YYYY hh:mm A')} To- ${award?.vehicle?.plate_number} ${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')}`
                                                }
                                            </span>
                                        </CustomText.OpenSansRegular> */}
                                        <CustomText.OpenSansRegular textStyle={{
                                            display: 'flex'
                                            }}>
                                            
                                            <span className="leftReplaceVehicle">{ `From - ${award?.replaced_by?.vehicle?.plate_number} `}
                                                <span>{ `${moment(award?.replaced_by?.created_at).format('DD-MM-YYYY hh:mm A')} `}</span>
                                            </span>
                                            <span className="rightReplaceVehicle">{ `To - ${award?.vehicle?.plate_number} `}
                                                <span>{ `${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')} `}</span>
                                            </span>
                                        </CustomText.OpenSansRegular>
                                    </div>
                                    : null
                            }
                            
                            {award?.is_replaced == 0 ?
                                <div className="vehicleDetailsTextWrap">
                                    <div className="fw-5">
                                        Plate Number
                                    </div>
                                    <CustomText.OpenSansRegular>
                                        {`${award?.vehicle?.plate_number} (${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')})`}
                                    </CustomText.OpenSansRegular>
                                </div>
                                : null}

                            <div>
                                {award?.container_ref_one &&
                                    <div className="vehicleDetailsTextWrap">
                                        <div className="flexRowWrap">
                                            <div className="fw-5"
                                            // style={{
                                            //     marginLeft: 5
                                            // }}
                                            >
                                                {`${award?.container_ref_one && award?.container_ref_two ? "Container Number 1" : "Container Number"}`}
                                            </div>

                                        </div>
                                        <CustomText.OpenSansRegular
                                            // textStyle={{
                                            //     marginLeft: 2
                                            // }}
                                        >
                                            {`${award?.container_ref_one}`}
                                        </CustomText.OpenSansRegular>
                                    </div>
                                }

                                {award?.weight_one &&
                                    <div className="vehicleDetailsTextWrap">
                                        <div className="flexRowWrap">
                                            <div className="fw-5"
                                                // style={{
                                                //     marginLeft: 2
                                                // }}
                                            >
                                                {`${award?.container_ref_one && award?.container_ref_two ? "Weight 1" : "Weight"}`}
                                            </div>

                                        </div>
                                        <CustomText.OpenSansRegular 
                                        // textStyle={{
                                        //     marginLeft: 5
                                        // }}
                                        >
                                            {`${numeral(award?.weight_one).format("0,0")} Kg`}
                                        </CustomText.OpenSansRegular>
                                    </div>}


                                {
                                    award?.container_ref_two &&
                                    <div className="vehicleDetailsTextWrap">
                                        <div className="flexRowWrap">
                                            <div className="fw-5"
                                            // style={{
                                            //     marginLeft: 5
                                            // }}
                                            >
                                                Container Number 2
                                            </div>

                                        </div>
                                        <CustomText.OpenSansRegular 
                                        // textStyle={{
                                        //     marginLeft: 5
                                        // }}
                                        >
                                            {`${award?.container_ref_two}`}
                                        </CustomText.OpenSansRegular>
                                    </div>

                                }
                                {
                                    award?.no_of_packages ?
                                        <div className="vehicleDetailsTextWrap">
                                            <div className="flexRowWrap">
                                                <div className="fw-5"
                                                // style={{
                                                //     marginLeft: 5
                                                // }}
                                                >
                                                    No. of Packages
                                                </div>

                                            </div>
                                            <CustomText.OpenSansRegular 
                                            // textStyle={{
                                            //     marginLeft: 5
                                            // }}
                                            >
                                                {`${award?.no_of_packages}`}
                                            </CustomText.OpenSansRegular>
                                        </div>

                                        : null
                                }

                                {
                                    award?.weight_two ?
                                        <div className="vehicleDetailsTextWrap">
                                            <div className="flexRowWrap">
                                                <div className="fw-5"
                                                // style={{
                                                //     marginLeft: 5
                                                // }}
                                                >
                                                    Weight 2
                                                </div>

                                            </div>
                                            <CustomText.OpenSansRegular textStyle={{
                                                marginLeft: 5
                                            }}>
                                                {`${numeral(award?.weight_two).format("0,0.00")} Kg`}
                                            </CustomText.OpenSansRegular>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}