import React from "react";
import { RenderRTL } from "../components";

export const ExtraDetils = ({ item, details, is_recipient }) => {
    return (
        <div style={{ marginTop: 15 }}>
            {
                item?.detention_free_time ?
                    <RenderRTL
                        title={"Free Time Required"}
                        sep={"/"}
                        urdutitle={"فری وقت درکار "}
                        val={item?.detention_free_time + " hrs"}
                        is_recipient={is_recipient}
                    /> : null
            }
            {
                item?.is_genset ?
                    <RenderRTL
                        title={"Genset Required"}
                        sep={"/"}
                        urdutitle={"جینسیٹ"}
                        val={"Yes"}
                        is_recipient={is_recipient}
                    /> : null
            }
            {
                 item?.required_temperature ?
                 <div className="tempDataLoad">
                    <RenderRTL
                        title={"Required Temperature"}
                        sep={"/"}
                        urdutitle={"مطلوبہ درجہ حرارت"}
                        val={`Min: ${item?.required_temperature_min}`}
                        val2={`Max: ${item?.required_temperature_max}`}
                        is_recipient={is_recipient}
                    /></div> : null
                
            }

            {
                item?.is_loading_required ?
                    <RenderRTL
                        title={"Loading Required"}
                        sep={"/"}
                        urdutitle={"لوڈنگ"}
                        val={"Yes"}
                        is_recipient={is_recipient}
                    />
                    : null
            }

            {
                item?.loading_requirements ?
                    <RenderRTL
                        title={"Specify Labour / Loading Requirement"}
                        sep={"/"}
                        urdutitle={"لوڈنگ درکار ہے۔"}
                        val={item?.loading_requirements ? `${item?.loading_requirements || "--"}` : "N/A"}
                        is_recipient={is_recipient}
                    />
                    : null
            }

            {
                item?.is_unloading_required ?
                    <RenderRTL
                        title={"Unloading Required"}
                        sep={"/"}
                        urdutitle={"ان لوڈنگ"}
                        val={"Yes"}
                        is_recipient={is_recipient}
                    />
                    : null
            }

            {
                item?.unloading_requirements ?
                    <RenderRTL
                        title={"Specify Labour / UnLoading Requirement"}
                        sep={"/"}
                        urdutitle={"ان لوڈنگ درکار ہے۔"}
                        val={item?.unloading_requirements ? `${item?.unloading_requirements || "--"}` : "N/A"}
                        is_recipient={is_recipient}
                    />
                    : null
            }

            {
                details?.is_loading_required ?
                    <RenderRTL
                        title={"Loading Required"}
                        sep={"/"}
                        urdutitle={"لوڈنگ"}
                        val={"Yes"}
                        is_recipient={is_recipient}
                    /> : null
            }

            {
                details?.is_loading_required ?
                    <RenderRTL
                        title={"Specify Labour / Loading Requirement"}
                        sep={"/"}
                        urdutitle={"لوڈنگ درکار ہے۔"}
                        val={details?.loading_requirements}
                        is_recipient={is_recipient}
                    /> : null
            }

            {
                details?.is_unloading_required ?
                    <RenderRTL
                        title={"Unloading Required"}
                        sep={"/"}
                        urdutitle={"ان لوڈنگ"}
                        val={"Yes"}
                        is_recipient={is_recipient}
                    /> : null
            }

            {
                details?.is_unloading_required ?
                    <RenderRTL
                        title={"Specify Labour / Unloading Requirement"}
                        sep={"/"}
                        urdutitle={"ان لوڈنگ درکار ہے۔"}
                        val={details?.unloading_requirements}
                        is_recipient={is_recipient}
                    /> : null
            }

            {
                details?.detention_free_time ?
                    <RenderRTL
                        title={"Free Time Required"}
                        sep={"/"}
                        urdutitle={"فری وقت درکار "}
                        val={details?.detention_free_time + " hrs"}
                        is_recipient={is_recipient}
                    />
                    : null
            }

        </div>
    )
}