
import { NewInput } from "@temp/components";
import React from "react";
var numeral = require("numeral");
import InfoIcon from "@mui/icons-material/Info";


export const RenderFields = ({ details, setDetils, setInfo, toggleModal, is_unit_basis }) => {
    return (
        <>
            {/* gross weight field */}
            <div className="inputField">
                <div className="label">Gross Weight</div>
                <div className="field">
                    <NewInput
                        onChange={(e) => {
                            let grossWeight = e.target.value;
                            let total_freight = Number(grossWeight) / 1000 > Number(details?.cargo_volume) ? grossWeight / 1000 : details.cargo_volume
                            if (is_unit_basis) {
                                setDetils({
                                    ...details,
                                    gross_weight_in_kgs: grossWeight ? Number(grossWeight) : null,
                                    gross_weight: grossWeight / 1000,
                                    total_freight_ton: total_freight,
                                });
                            } else {
                                let total_price
                                if (details?.price_unit == "Kg") {
                                    total_price = (Number(details?.price_per_freight) * e.target.value) + Number(details?.loading_price_per_freight) + Number(details?.unloading_price_per_freight)
                                } else if (details?.price_unit == "Ton") {
                                    total_price = (Number(details?.price_per_freight) * e.target.value / 1000) + Number(details?.loading_price_per_freight) + Number(details?.unloading_price_per_freight)
                                } else if (details?.price_unit == "F.Ton") {
                                    total_price = (Number(details?.price_per_freight) * total_freight) + Number(details?.loading_price_per_freight) + Number(details?.unloading_price_per_freight)
                                }
                                let flag = details?.price_unit == "Kg" || details?.price_unit == "Ton" || details?.price_unit == "F.Ton"
                                setDetils({
                                    ...details,
                                    gross_weight_in_kgs: grossWeight ? Number(grossWeight) : null,
                                    gross_weight: grossWeight / 1000,
                                    total_freight_ton: total_freight,
                                    total_offer_price: flag ? total_price : details?.total_offer_price
                                });
                            }
                        }}
                        value={details?.gross_weight_in_kgs || details?.gross_weight * 1000 || ""}
                        showEnd={"KG"}
                        decimal={true}
                    />
                </div>
            </div>

            {/* gross weight in kgs field  */}
            <div className="inputField">
                <div className="label">Gross Weight in Tons</div>
                <div className="field">
                    <NewInput
                        id="total_freight_ton"
                        name="total_freight_ton"
                        value={numeral(details.gross_weight).format("0,0.00")}
                        disabled={true}
                        aria-describedby="standard-weight-helper-text"
                        showEnd={"Ton"}
                    />
                </div>
            </div>
            {/* cargo volume field  */}
            <div className="inputField">
                <div className="label">Cargo Volume</div>
                <div className="field">
                    <NewInput
                        onChange={(e) => {
                            let cargoVolume = e.target.value;
                            let total_freight = Number(cargoVolume) > Number(details.gross_weight) ? cargoVolume : details.gross_weight
                            if (is_unit_basis) {
                                setDetils({
                                    ...details,
                                    cargo_volume: cargoVolume ? Number(cargoVolume) : null,
                                    total_freight_ton: total_freight,
                                });
                            } else {
                                let total_price = (Number(details?.price_per_freight) * e.target.value) + Number(details?.loading_price_per_freight) + Number(details?.unloading_price_per_freight)
                                setDetils({
                                    ...details,
                                    cargo_volume: cargoVolume ? Number(cargoVolume) : null,
                                    total_freight_ton: total_freight,
                                    total_offer_price: details?.price_unit == "CBM" ? total_price : details?.total_offer_price
                                });
                            }
                        }}
                        value={details.cargo_volume ? details?.cargo_volume : ''}
                        placeholder={
                            details?.is_unit_basis || details?.price_unit == "F.Ton" || details?.price_unit == "CBM" ? "" : "Optional"
                        }
                        showEnd={"CBM"}
                        decimal={true}
                    />
                </div>
            </div>

            {/* total freight ton faded field readonly  */}
            <div className="inputField">
                <div className="label">
                    Total Weight
                    {details.cargo_volume ? (
                        <InfoIcon
                            onClick={() => {
                                setInfo(
                                    "Higher of weight in tons and volume in CBM."
                                );
                                toggleModal(true);
                            }}
                        />
                    ) : null}
                </div>
                <div className="field">
                    <NewInput
                        id="total_freight_ton"
                        name="total_freight_ton"
                        value={numeral(details.total_freight_ton).format(
                            "0,0.00"
                        )}
                        disabled={true}
                        aria-describedby="standard-weight-helper-text"
                        showEnd={`${!details.cargo_volume ? "Ton" : "F.Ton"
                            }`}
                    />
                </div>
            </div>
            {/* litter field */}
            {!is_unit_basis ? (
                <div className="inputField">
                    <div className="label">Liter</div>
                    <div className="field">
                        <NewInput
                            onChange={(e) => {
                                let liter = e.target.value;
                                let total_price = (Number(details?.price_per_freight) * e.target.value) + Number(details?.loading_price_per_freight) + Number(details?.unloading_price_per_freight)
                                setDetils({
                                    ...details,
                                    cargo_liter: liter ?
                                        Number(liter)
                                        : null,
                                    total_offer_price: details?.price_unit == "Liter" ? total_price : details?.total_offer_price
                                });
                            }}
                            value={details.cargo_liter}
                            placeholder={details?.price_unit == "Liter" ? "" : "Optional"}
                            showEnd={"liter"}
                            decimal={true}
                        />
                    </div>
                </div>
            ) : null}
            {/* number of packages field  */}
            {!is_unit_basis ? (
                <div className="inputField">
                    <div className="label">Number of Packages</div>
                    <div className="field">
                        <NewInput
                            onChange={(e) => {
                                setDetils({
                                    ...details,
                                    no_of_packages: Number(e.target.value),
                                });
                            }}
                            value={
                                details.no_of_packages
                                    ? details?.no_of_packages
                                    : ""
                            }
                            placeholder={"Optional"}
                            decimal={true}
                        // style={{ marginTop: 5 }}
                        />
                    </div>
                </div>
            ) : null}
        </>
    )
}