import { Accordion, AccordionSummary } from "@mui/material/";
import { AdditionalPickupDropoff, CustomModal, CustomText, PhoneInput, PrimaryButton, SimpleInput } from "@temp/components";
import moment from "moment";
import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import "../LoadDetailPoster/style.scss";
import { RenderRTL } from "./RenderRTL";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../LoadDetailPoster/style.scss";
import { useDispatch } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import InfoIcon from "@mui/icons-material/Info";
import { phoneNumberFormater, preventNonNumericalInput } from "@temp/utils/helper";
import SimplePhoneInput from "@temp/components/SimplePhoneInput";

const initial_pickup_and_dropoff = {
  raw_address: "",
  lat: null,
  long: null,
  contact_name: "",
  contact_phone_no: "",
  contact_phone_code: "92",
  id: null,
  address_disable: false,
  contact_details: false,
  city: ""
}

export const LocatonDetail = forwardRef((props, ref) => {
  const {
    loadDetail,
    setGenericDate,
    setGenericTime,
    setShowModalGeneric,
    showModalGeneric,
    setDateType,
    setMinDate,
    setMaxDate,
    type,
    recipient_status,
    is_recipient = false,
    fromActive

  } = props
  let isAwarded = false;
  loadDetail?.categories.map((item) => {
    item?.recipients.map((val) => {
      if (val?.status === 4) {
        isAwarded = true;
      }
    })
  })
  const dispatch = useDispatch()
  const [dataPickup, setDataPickup] = useState(initial_pickup_and_dropoff);
  const [dataDropoff, setDataDoropoff] = useState(initial_pickup_and_dropoff);
  const [showmodal, setShowModal] = useState(false)


  const handlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    // Remove non-numeric characters
    const numericValue = paste.replace(/[^0-9]/g, '');
    // Prevent pasting if the first character is 0
    if (event?.target?.value.length > 10) {
      return;
    }
    // if (numericValue[0] !== '0') {
    //   setDataPickup(numericValue);
    // }
    if (numericValue.startsWith('0') || numericValue.startsWith('92')) {
      return;
    }
    // Set the pasted value to driverMobileNumber
    setDataPickup({
      ...dataPickup,
      contact_phone_no: numericValue
    });

  }

  const handlePasteDrop = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    // Remove non-numeric characters
    const numericValue = paste.replace(/[^0-9]/g, '');
    // Prevent pasting if the first character is 0
    if (event?.target?.value.length > 10) {
      return;
    }
    // if (numericValue[0] !== '0') {
    //   setDataDoropoff(numericValue);
    // }
    // updated code Prevent pasting if the first character is '0' or the first two characters are '92'
    if (numericValue.startsWith('0') || numericValue.startsWith('92')) {
      return;
    }
    // Set the pasted value to driverMobileNumber
    setDataDoropoff({
      ...dataDropoff,
      contact_phone_no: numericValue
    });

  }

  const validateInput = (value) => {
    // Prevent input starting with 0, 92, or a hyphen
    const invalidPatterns = /^(0|92|-)/;
    return !invalidPatterns.test(value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;


    if (validateInput(value) || value === '') {
      if (event.target.value.length < 11) {
        setDataPickup({
          ...dataPickup,
          contact_phone_no: event.target.value
        });
      }
    }
  };

  const handleChangeDrop = (event) => {
    const { name, value } = event.target;


    if (validateInput(value) || value === '') {
      if (event.target.value.length < 11) {
        setDataDoropoff({
          ...dataDropoff,
          contact_phone_no: event.target.value
        });
      }
    }
  };


  // const getCity = (formatted_address: any) => {
  //   var itemLocality = null;

  //   if (formatted_address?.length) {
  //     formatted_address.forEach((address: any) => {
  //       if (address.types[0] == "locality") {
  //         itemLocality = address.long_name;
  //       }
  //     });
  //   }
  //   return itemLocality;
  // };


  // const handleAutoComplete = (e) => {
  //   setDataPickup({
  //     ...dataPickup,
  //     raw_address: e.target.value
  //   })
  //   var element = document.getElementById("map-address") as HTMLInputElement;
  //   if (element) {
  //     const options = {
  //       componentRestrictions: { country: "pk" },
  //       // fields: ["address_components", "formatted_address", "geometry"],
  // 	fields: ["address_components", "geometry", "icon", "name"],
  //       strictBounds: false,
  //       types: ["establishment"],
  //     };
  //     const autocomplete = new window.google.maps.places.Autocomplete(
  //       element,
  //       options
  //     );
  //     autocomplete.addListener("place_changed", () => {
  //       const place = autocomplete.getPlace();
  //       // let new_address = autocomplete?.gm_accessors_?.place?.Fi?.formattedPrediction;
  // 	// let new_address = place?.formatted_address
  // 	const new_address = document.querySelector(`#map-address`)?.value;
  //       if (place) {
  //         getCity(place);
  //       }
  //       if (!place.geometry || !place.geometry.location) {
  //         return;
  //       } else {
  //         var coordinates = {
  //           lat: place.geometry.location.lat(),
  //           lng: place.geometry.location.lng(),
  //         };
  //         const lat = coordinates?.lat;
  //         const lng = coordinates?.lng;
  //         const raw_address = new_address;
  //         setDataPickup({
  //           ...dataPickup,
  //           raw_address: raw_address,
  //           lat: lat,
  //           long: lng,
  //         });
  //       }
  //     });
  //   }
  // };

  // const handleAutoCompleteDrop = (e) => {
  //   setDataDoropoff({
  //     ...dataDropoff,
  //     raw_address: e.target.value
  //   });
  //   var element = document.getElementById("map-address-drop") as HTMLInputElement;
  //   if (element) {
  //     const options = {
  //       componentRestrictions: { country: "pk" },
  //       // fields: ["address_components", "formatted_address", "geometry"],
  // 	fields: ["address_components", "geometry", "icon", "name"],
  //       strictBounds: false,
  //       types: ["establishment"],
  //     };
  //     const autocomplete = new window.google.maps.places.Autocomplete(
  //       element,
  //       options
  //     );

  //     autocomplete.addListener("place_changed", () => {
  //       const place = autocomplete.getPlace();
  //       // let new_address = autocomplete?.gm_accessors_?.place?.Fi?.formattedPrediction;
  // 	// let new_address = place?.formatted_address
  // 	const new_address = document.querySelector(`#map-address-drop`)?.value;
  //       if (place) {
  //         getCity(place);
  //       }
  //       if (!place.geometry || !place.geometry.location) {
  //         return;
  //       } else {

  //         var coordinates = {
  //           lat: place.geometry.location.lat(),
  //           lng: place.geometry.location.lng(),
  //         };
  //         const lat = coordinates?.lat;
  //         const lng = coordinates?.lng;
  //         const raw_address =new_address;
  //         setDataDoropoff({
  //           ...dataDropoff,
  //           raw_address: raw_address,
  //           lat: lat,
  //           long: lng,
  //         });
  //       }
  //     });
  //   }
  // };


  const getCity = (addressComponents): string | null => {
    for (const component of addressComponents) {
      if (component.types.includes("locality")) {
        return component.long_name;
      }
    }
    return null;
  };

  const handleAutoComplete = (e, dataPickup) => {
    setDataPickup({
      ...dataPickup,
      raw_address: e.target.value,
    });

    const element = document.getElementById("map-address") as HTMLInputElement;
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["establishment"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(element, options);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace() as Place;
        const new_address = element.value;

        if (!place.geometry || !place.geometry.location) {
          return;
        }

        const coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        const city = getCity(place.address_components);

        setDataPickup({
          ...dataPickup,
          raw_address: new_address,
          lat: coordinates.lat,
          long: coordinates.lng,
          city: city, // Add city to the state
        });
      });
    }
  };

  const handleAutoCompleteDrop = (e, dataDropoff) => {
    setDataDoropoff({
      ...dataDropoff,
      raw_address: e.target.value,
    });

    var element = document.getElementById("map-address-drop") as HTMLInputElement;
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["establishment"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(element, options);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace() as Place;
        const new_address = element.value;

        if (!place.geometry || !place.geometry.location) {
          return;
        }

        const coordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        const city = getCity(place.address_components);

        setDataDoropoff({
          ...dataDropoff,
          raw_address: new_address,
          lat: coordinates.lat,
          long: coordinates.lng,
          city: city, // Add city to the state
        });
      });
    }
  };

  const handleUpdateLocation = () => {
    if (dataPickup?.id && dataDropoff?.id) {
      let data = [{ ...dataPickup, job_load_id: loadDetail?.id }, { ...dataDropoff, job_load_id: loadDetail?.id }]
      dispatch(ProfileAction.updateLocation({ load_id: loadDetail?.id, data }))
    } else if (dataPickup?.id) {
      let data = [{ ...dataPickup, job_load_id: loadDetail?.id }]
      dispatch(ProfileAction.updateLocation({ load_id: loadDetail?.id, data }))
    } else if (dataDropoff?.id) {
      let data = [{ ...dataDropoff, job_load_id: loadDetail?.id }]
      dispatch(ProfileAction.updateLocation({ load_id: loadDetail?.id, data }))
    }
    setShowModal(false)
    // setDataPickup(initial_pickup_and_dropoff)
    // setDataDoropoff(initial_pickup_and_dropoff)
  }

  const handleDisableButton = () => {
    if (dataPickup?.id && dataDropoff?.id) {
      if (
        dataPickup?.raw_address && dataPickup?.contact_name?.length && dataPickup?.contact_phone_no?.length >= 10 &&
        dataDropoff?.raw_address && dataDropoff?.contact_name?.length && dataDropoff?.contact_phone_no?.length >= 10
      ) {
        return false
      } else {
        return true
      }
    } else if (dataPickup?.id) {
      if (dataPickup?.raw_address && dataPickup?.contact_name?.length && dataPickup?.contact_phone_no?.length >= 10) {
        return false
      } else {
        return true
      }
    } else if (dataDropoff?.id) {
      if (dataDropoff?.raw_address && dataDropoff?.contact_name?.length && dataDropoff?.contact_phone_no?.length >= 10) {
        return false
      } else {
        return true
      }
    }
  }
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    if (dataPickup?.id && dataDropoff?.id) {
      if (
        dataPickup?.raw_address && dataPickup?.contact_name?.length && dataPickup?.contact_phone_no &&
        dataDropoff?.raw_address && dataDropoff?.contact_name?.length && dataDropoff?.contact_phone_no
      ) {
        setShowMessage(false)
      } else {
        setShowMessage(true)
      }
    } else if (dataPickup?.id) {
      if (dataPickup?.raw_address && dataPickup?.contact_name?.length && dataPickup?.contact_phone_no) {
        setShowMessage(false)
      } else {
        setShowMessage(true)
      }
    } else if (dataDropoff?.id) {
      if (dataDropoff?.raw_address && dataDropoff?.contact_name?.length && dataDropoff?.contact_phone_no) {
        setShowMessage(false)
      } else {
        setShowMessage(true)
      }
    }
  }, [dataPickup, dataDropoff])

  const openModal = () => {
    setDataPickup({
      ...dataPickup,
      id: loadDetail?.locations?.[0]?.id,
      address_disable: loadDetail?.locations?.[0]?.raw_address ? true : false,
      raw_address: loadDetail?.locations?.[0]?.raw_address,
      contact_name: loadDetail?.locations?.[0]?.contact_name,
      contact_phone_no: loadDetail?.locations?.[0]?.contact_phone_no,
      lat: loadDetail?.locations?.[0]?.lat,
      long: loadDetail?.locations?.[0]?.long,
      city: loadDetail?.locations?.[0]?.city
    });
    setDataDoropoff({
      ...dataDropoff,
      id: loadDetail?.locations?.[1]?.id,
      address_disable: loadDetail?.locations?.[1]?.raw_address ? true : false,
      raw_address: loadDetail?.locations?.[1]?.raw_address,
      contact_name: loadDetail?.locations?.[1]?.contact_name,
      contact_phone_no: loadDetail?.locations?.[1]?.contact_phone_no,
      lat: loadDetail?.locations?.[1]?.lat,
      long: loadDetail?.locations?.[1]?.long,
      city: loadDetail?.locations?.[1]?.city

    })
    setShowModal(true)
  }

  useImperativeHandle(ref, () => ({
    openModal
  }));
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  return (
    <div className="accordion-container">
      <Accordion className="accordion-background">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className="fw-5">
            <span>Location Details</span>
            {
              is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">تفصیلات</span>
              </>
            }
          </p>
        </AccordionSummary>
        <div className="accordion-content">
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <div>
              <p className="grayLabel">
                <span>Location</span>
                {
                  is_recipient &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">مقام</span>
                  </>
                }
              </p>
            </div>
            {
              (type == "posted") ?
                <div>
                  <span style={{
                    cursor: 'pointer'
                  }} onClick={() => {
                    openModal()
                  }} className="icons-edit editIcon"></span>
                </div> : null
            }
          </div>
          <div className="load-detail-location-container">
            <div className="load-detail-location-info">
              {loadDetail?.locations?.length && (
                <>
                  <div className="pickup">
                    <div className="load-detail-dots-container">
                      <div className="load-detail-location-start">
                        <div className="load-detail-location-start-inner"></div>
                      </div>
                      <div className="load-detail-location-end">
                        <div className="load-detail-location-end-inner"></div>
                      </div>
                    </div>
                    <div>
                      <CustomText.OpenSansSemiBold>
                        {loadDetail?.locations[0]?.title || ""}
                      </CustomText.OpenSansSemiBold>
                      <CustomText.OpenSansRegular>
                        {loadDetail?.locations[0]?.raw_address}
                      </CustomText.OpenSansRegular>
                      {
                        (type == "posted") || (type == "received" && recipient_status?.status == 4) ?
                          <div style={{ marginBottom: 35, marginTop: 15 }}>
                            {
                              loadDetail?.locations[0]?.contact_name?.length && (
                                <div className="formGrid">
                                  <div className="grayLabel">
                                    <span className="titleName">Contact Name</span>
                                    {
                                      is_recipient &&
                                      <>
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">رابطے کا نام</span>
                                      </>
                                    }
                                  </div>
                                  <div className="fw-5">
                                    {loadDetail?.locations[0]?.contact_name}
                                  </div>
                                </div>
                              )
                            }
                            {
                              loadDetail?.locations[0]?.contact_phone_no?.length && (
                                <div className="formGrid mb-0">
                                  <div className="grayLabel">
                                    <span className="titleName">Contact Number</span>
                                    {
                                      is_recipient &&
                                      <>
                                        <span className="seperator">/</span>
                                        <span className="urduLabel">رابطہ نمبر</span>
                                      </>
                                    }
                                  </div>
                                  <div className="fw-5">
                                    {
                                      phoneNumberFormater(loadDetail?.locations[0]?.contact_phone_code, loadDetail?.locations[0]?.contact_phone_no)
                                    }
                                  </div>
                                </div>
                              )
                            }
                          </div> : null
                      }
                    </div>
                  </div>
                  <div className="dropoff">
                    <CustomText.OpenSansSemiBold>
                      {loadDetail?.locations[1]?.title || ""}
                    </CustomText.OpenSansSemiBold>
                    <CustomText.OpenSansRegular>
                      {loadDetail?.locations[1]?.raw_address}
                    </CustomText.OpenSansRegular>
                    {
                      (type == "posted") || (type == "received" && recipient_status?.status == 4) ?
                        <div style={{ marginBottom: 0, marginTop: 15 }}>
                          {
                            loadDetail?.locations[1]?.contact_name?.length && (
                              <div className="formGrid">
                                <div className="grayLabel">
                                  <span className="titleName">Contact Name</span>
                                  {
                                    is_recipient &&
                                    <>
                                      <span className="seperator">/</span>
                                      <span className="urduLabel">رابطے کا نام</span>
                                    </>
                                  }
                                </div>
                                <div className="fw-5">
                                  {loadDetail?.locations[1]?.contact_name}
                                </div>
                              </div>
                            )
                          }
                          {
                            loadDetail?.locations[1]?.contact_phone_no?.length && (
                              <div className="formGrid">
                                <div className="grayLabel">
                                  <span className="titleName">Contact Number</span>
                                  {
                                    is_recipient &&
                                    <>
                                      <span className="seperator">/</span>
                                      <span className="urduLabel">رابطہ نمبر</span>
                                    </>
                                  }
                                </div>
                                <div className="fw-5">
                                  {
                                    phoneNumberFormater(loadDetail?.locations[1]?.contact_phone_code, loadDetail?.locations[1]?.contact_phone_no)
                                  }
                                </div>
                              </div>
                            )
                          }
                        </div> : null
                    }
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="accordion-content">
          <AdditionalPickupDropoff
            additional_pickup={loadDetail?.additional_pickups?.map((item) => { return { ...item, address: item?.raw_address } }) || []}
            additional_dropoffs={loadDetail?.additional_dropoffs?.map((item) => { return { ...item, address: item?.raw_address } }) || []}
            dataPickup={dataPickup}
            dataDropoff={dataDropoff}
            setDataPickup={setDataPickup}
            setDataDoropoff={setDataDoropoff}
            setShowModal={setShowModal}
            loadDetail={loadDetail}
            type={type}
            recipient_status={recipient_status}
            is_recipient={is_recipient}
          // fromActive={true}
          />
          <RenderRTL
            title={`${loadDetail?.transaction_type?.title == "Bid" ? 'Bidding' : 'Offer'} Response Deadline`}
            sep={"/"}
            urdutitle={"ریٹ دینے کی معیاد"}
            val={`${moment(loadDetail?.deadline_at).format(
              "MMM DD, YYYY hh:mm A"
            )}`}
            is_recipient={is_recipient}
          />
          <div style={{ display: "flex", cursor: "pointer" }}
            onClick={() => {
              if (type == 'posted' && !isAwarded) {
                let new_pickup = `${loadDetail?.pickup_date} ${loadDetail?.pickup_time}`
                setDateType(loadDetail?.pickup_date ? "Pick Up Date" : null)
                setGenericDate(loadDetail?.pickup_date)
                if (loadDetail?.pickup_time) {
                  setGenericTime(new_pickup)
                } else {
                  setGenericTime(null)

                }
                setMinDate(loadDetail?.deadline_at)
                setShowModalGeneric(!showModalGeneric);
                setMaxDate(loadDetail?.rate_validity_date ? loadDetail?.rate_validity_date : "3050-01-01")

              }
            }}>
            <RenderRTL
              title={"Pickup Deadline"}
              sep="/"
              urdutitle="پک اپ کی تاریخ اور وقت"
              val={`${moment(loadDetail?.pickup_date).format("MMM DD, YYYY")} ${loadDetail?.pickup_time ? moment(loadDetail?.pickup_time, "HH:mm:ss").format("hh:mm A") : ""} `}
              is_recipient={is_recipient}
            />
            {
              type == "posted" && !isAwarded ?
                <span className="icons-edit editIcon"></span>
                : null
            }
          </div>

          {loadDetail?.delivery_at ? (
            <div style={{ display: "flex", cursor: "pointer" }} onClick={() => {
              if (type == 'posted' && !isAwarded) {
                let new_pickup = `${loadDetail?.pickup_date} ${loadDetail?.pickup_time}`
                setDateType(loadDetail?.delivery_at ? "Delivery Deadline" : null)
                setMinDate(new_pickup)
                setGenericDate(loadDetail?.delivery_at)
                setGenericTime(loadDetail?.delivery_at)
                setShowModalGeneric(!showModalGeneric);
                setMaxDate(loadDetail?.empty_return_deadline_at ? loadDetail?.empty_return_deadline_at : "3050-01-01")
              }
            }}>
              <RenderRTL
                title={"Delivery Deadline"}
                sep="/"
                urdutitle="ڈیلیوری کی آخری تاریخ اور وقت"
                val={`${moment(loadDetail?.delivery_at).format(
                  "MMM DD, YYYY hh:mm A"
                )}`}
                is_recipient={is_recipient}

              />
              {
                type == "posted" && !isAwarded ?
                  <span className="icons-edit editIcon"></span> : null
              }
            </div>
          ) : null}

          {loadDetail?.empty_return_deadline_at ? (
            <div style={{ display: "flex", cursor: "pointer" }} onClick={() => {
              if (type == 'posted' && !isAwarded) {
                let new_pickup = `${loadDetail?.pickup_date} ${loadDetail?.pickup_time}`
                setDateType(loadDetail?.empty_return_deadline_at ? "Empty Return Deadline" : null)
                setGenericDate(loadDetail?.empty_return_deadline_at)
                setGenericTime(loadDetail?.empty_return_deadline_at)
                setMinDate(new Date())
                setShowModalGeneric(!showModalGeneric);
                setMaxDate("3050-01-01")
              }
            }}>
              <RenderRTL
                title={"Empty Pickup / Return Deadline"}
                sep="/"
                urdutitle={"ایم ٹی پک اپ / ڈراپ-آف  کی آخری تاریخ اور وقت "}
                val={`${moment(loadDetail?.empty_return_deadline_at).format(
                  "MMM DD, YYYY hh:mm A"
                )}`}
                is_recipient={is_recipient}

              />
              {
                type == "posted" && !isAwarded ?
                  <span className="icons-edit editIcon"></span> : null
              }
            </div>
          ) : null}

          <div>
            {loadDetail?.inquiry_type?.title === "Spot Rate" && loadDetail?.rate_validity_date ? <>
              <RenderRTL
                title="Rate Validity"
                sep="/"
                urdutitle="کرائے کی معیاد"
                val={loadDetail?.rate_validity_date ? moment(loadDetail?.rate_validity_date).format('MMM DD, YYYY') : null}
                is_recipient={is_recipient}

              />
            </> : null}

          </div>
          {
            loadDetail?.empty_return_yard_name ?
              <RenderRTL
                title={"Empty Pickup or Return Yard / City"}
                sep={"/"}
                urdutitle={"ایم ٹی پک اپ / ڈراپ-آف یارڈ/شہر کا نام"}
                val={loadDetail?.empty_return_yard_name}
                is_recipient={is_recipient}

              /> : null

          }
        </div>
      </Accordion>
      <div className="editLocationModal">
        <CustomModal showModal={showmodal} toggleModal={setShowModal}>
          {
            showMessage ?
              <div className="error-container info smallErrorContainer" style={{ marginBottom: 10 }}>
                <div>
                  <InfoIcon className="error-icon" />
                </div>
                <div className="error-text">
                  Please enter the required details
                </div>
              </div> : null
          }
          <div style={{ height: '100%', overflow: 'auto' }}>
            {
              dataPickup?.id && (
                <div>
                  <p className="titleSection">Pickup Location <span className="redCol">*</span></p>
                  <div className="searchContainer" style={{ width: "100%", marginBottom: '1rem' }}>
                    <input
                      className="searchInput"
                      id="map-address"
                      // onChange={handleAutoComplete}
                      onChange={(e) => { handleAutoComplete(e, dataPickup) }}

                      type="text"
                      autoComplete="off"
                      name="search"
                      style={{ width: "100%" }}
                      disabled={dataPickup?.address_disable}
                      value={dataPickup?.raw_address}
                      placeholder="Enter detailed address"
                      maxLength={100}
                    />
                    <span className={`icons-search ${!dataPickup?.raw_address ? 'active' : ''}`}></span>
                    {
                      !dataPickup?.address_disable && (
                        <span
                          className={`icons-cross ${dataPickup?.raw_address ? 'active' : ''}`}
                          onClick={() => {
                            setDataPickup({
                              ...dataPickup,
                              raw_address: '',
                              lat: dataPickup?.lat,
                              long: dataPickup?.long
                            })
                          }}>
                        </span>
                      )
                    }
                  </div>
                  <div className="inputField mtop8">
                    <div className="label">Contact Name <span className="redCol">*</span></div>
                    <div className="field">
                      <SimpleInput
                        type="text"
                        value={dataPickup?.contact_name}
                        onChange={(e) => {
                          setDataPickup({
                            ...dataPickup,
                            contact_name: e.target.value
                          });
                        }}
                        placeholder="Enter Contact Name"
                        maxLength={30}
                      />
                    </div>
                  </div>
                  <div className="inputField driverNumberField">
                    <div className="label">Contact Number <span className="redCol">*</span></div>
                    <div className="field">
                      {/* <PhoneInput
                        value={dataPickup?.contact_phone_no}
                        phoneCode={"92"}
                        onChange={(e) => {
                          if (e.target.value.length < 11) {
                            setDataPickup({
                              ...dataPickup,
                              contact_phone_no: e.target.value
                            });
                          }
                        }}
                        id="phone-number"
                        placeholder={"3000000000"}
                      /> */}
                      <SimplePhoneInput
                        value={dataPickup?.contact_phone_no}
                        onChange={handleChange}
                        onPaste={handlePaste}
                        placeholder="3000000000"
                        maxLength={10}
                        id="phone-number"
                      />
                    </div>
                  </div>
                </div>
              )
            }
            {
              dataDropoff?.id && (
                <div>
                  <p className="titleSection">Dropoff Location <span className="redCol">*</span></p>
                  <div className="searchContainer" style={{ width: "100%", marginBottom: '1rem' }}>
                    <input
                      className="searchInput"
                      id="map-address-drop"
                      onChange={(e) => { handleAutoCompleteDrop(e, dataDropoff) }}
                      type="text"
                      autoComplete="off"
                      name="search"
                      style={{ width: "100%" }}
                      disabled={dataDropoff?.address_disable}
                      value={dataDropoff?.raw_address}
                      placeholder="Enter detailed address"
                      maxLength={100}
                    />
                    <span className={`icons-search ${!dataDropoff?.raw_address ? 'active' : ''}`}></span>
                    {
                      !dataDropoff?.address_disable && (
                        <span
                          className={`icons-cross ${dataDropoff?.raw_address ? 'active' : ''}`}
                          onClick={() => {
                            setDataDoropoff({
                              ...dataDropoff,
                              raw_address: '',
                              lat: dataDropoff?.lat,
                              long: dataDropoff?.long
                            })
                          }}>
                        </span>
                      )
                    }
                  </div>
                  <div className="inputField mtop8">
                    <div className="label">Contact Name <span className="redCol">*</span></div>
                    <div className="field">
                      <SimpleInput
                        type="text"
                        value={dataDropoff?.contact_name}
                        onChange={(e) => {
                          setDataDoropoff({
                            ...dataDropoff,
                            contact_name: e.target.value
                          });
                        }}
                        placeholder="Enter Contact Name"
                        maxLength={30}
                      />
                    </div>
                  </div>
                  <div className="inputField driverNumberField">
                    <div className="label">Contact Number <span className="redCol">*</span></div>
                    <div className="field">
                      {/* <PhoneInput
                        value={dataDropoff?.contact_phone_no}
                        phoneCode={"92"}
                        onChange={(e) => {
                          if (e.target.value.length < 11) {
                            setDataDoropoff({
                              ...dataDropoff,
                              contact_phone_no: e.target.value
                            });
                          }
                        }}
                        id="phone-number"
                        placeholder={"3000000000"}
                      /> */}
                      
                      <SimplePhoneInput
                        value={dataDropoff?.contact_phone_no}
                        onChange={handleChangeDrop}
                        onPaste={handlePasteDrop}
                        placeholder="3000000000"
                        maxLength={10}
                        id="phone-number"
                      />
                    </div>
                  </div>
                </div>
              )
            }

            <div>
              <PrimaryButton
                title={"Save"}
                disabled={
                  handleDisableButton()
                }
                onClick={handleUpdateLocation}
              />
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
});
