import { AuthAction, LoadAction } from "../actions";

const INITIAL_STATE = {
  isLoading: false,
  loads: [],
  load_current_page: 1,
  load_last_page: 1,
  loadDetail: null,
  load_history: [],
  load_history_current_page: 1,
  load_history_last_page: 1,
  allocate_job: "",
  forwardLoadData: null,
  forwardLoadDataCopy: null,
  requestVehicleData: null,
  requestVehicleDataCopy: null,
  activeLoadDetail: null,
  selectedCategries: [],
  activeLoading: false,
  vehiclePinPointMarker: [],
  vehicleMarker: []

};

function Reducer(state = INITIAL_STATE, action: any) {





  switch (action.type) {
    case AuthAction.TOGGLE_LOADER:
      return { ...state, isLoading: false };

    case LoadAction.CREATE_FROM_DRAFT[0]:
      return {
        ...state,
        isLoading: true,
      };

    case LoadAction.SET_REQUEST_VEHICLE_CATEGORY:
      return {
        ...state,
        selectedCategries: action?.payload
      };

    case LoadAction.CREATE_FROM_DRAFT[1]:
      return {
        ...state,
        isLoading: false,
      };

    case LoadAction.CREATE_FROM_DRAFT[2]:
      return {
        ...state,
        isLoading: false,
      };

    case LoadAction.GET_LOADS:
      return {
        ...state,
        loads:
          action?.payload?.refresh || action?.payload?.fromSearch
            ? []
            : [...state.loads],
        isLoading: action?.payload?.refresh,
      };
    case LoadAction.GET_LOADS_SUCCESS:
      return {
        ...state,

        loads: action?.payload?.fromNoti
          ? action?.payload?.data
          : [...state?.loads, ...action?.payload?.data],
        load_current_page: action?.payload?.search
          ? 1
          : action?.payload?.meta.current_page,
        load_last_page: action?.payload?.search
          ? 1
          : action?.payload?.meta.last_page,
        isLoading: false,
      };
    case LoadAction.GET_LOADS_FAIL:
      return { ...state, isLoading: false };

    // get load detail
    case LoadAction.GET_LOAD_DETAIL:
      return {
        ...state,
        isLoading: true,
        loadDetail: null,
      };
    case LoadAction.GET_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        loadDetail: action?.payload,
        isLoading: false,
      };
    case LoadAction.GET_LOAD_DETAIL_FAIL:
      return { ...state, isLoading: false };

    // award job
    case LoadAction.AWARD_JOB:
      return {
        ...state,
        isLoading: true,
      };
    case LoadAction.AWARD_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.AWARD_JOB_FAIL:
      return { ...state, isLoading: false };

    // cancel
    case LoadAction.CANCEL_LOAD:
      return {
        ...state,
      };
    case LoadAction.CANCEL_LOAD_SUCCESS:
      return {
        ...state,
      };
    case LoadAction.CANCEL_LOAD_FAIL:
      return { ...state, isLoading: false };

    // recepient decline
    case LoadAction.RECIPIENT_DECLINED:
      return {
        ...state,
        isLoading: true,
      };
    case LoadAction.RECIPIENT_DECLINED_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.RECIPIENT_DECLINED_FAIL:
      return { ...state, isLoading: false };

    // poster decline
    case LoadAction.POSTER_DECLINED:
      return {
        ...state,
        isLoading: true,
      };
    case LoadAction.POSTER_DECLINED_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.POSTER_DECLINED_FAIL:
      return { ...state, isLoading: false };

    // accept load
    case LoadAction.ACCEPT_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case LoadAction.ACCEPT_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.ACCEPT_LOAD_FAIL:
      return { ...state, isLoading: false };
    case AuthAction.RESET_STORE:
      return INITIAL_STATE;

    //load history

    case LoadAction.GET_LOADS_HISTORY:
      return {
        ...state,
        // load_history: action?.payload?.refresh ? [] : [...state.load_history],
        load_history:
          action?.payload?.refresh || action?.payload?.fromSearch
            ? []
            : [...state.load_history],
        // isLoading: action?.payload?.refresh,
        isLoading: action?.payload?.refresh,
      };
    case LoadAction.GET_LOADS_HISTORY_SUCCESS:
      return {
        ...state,
        load_history: [...state?.load_history, ...action?.payload?.data],
        load_history_current_page: action?.payload?.meta.current_page,
        load_history_last_page: action?.payload?.meta.last_page,
        isLoading: false,
      };
    case LoadAction.GET_LOADS_HISTORY_FAIL:
      return { ...state, isLoading: false };

    //allocate load

    case LoadAction.ALLOCATE_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case LoadAction.ALLOCATE_LOAD_FAIL:
      return { ...state, isLoading: false };
    case LoadAction.ALLOCATE_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case AuthAction.RESET_STORE:
      return INITIAL_STATE;

    case LoadAction.SET_ALLOCATE_JOB:
      return {
        ...state,
        allocate_job: action?.payload,
      };

    case LoadAction.UPDATE_RESPONSE_DEADLINE:
      return {
        ...state,
        isLoading: true,
      };

    case LoadAction.UPDATE_RESPONSE_DEADLINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case LoadAction.UPDATE_RESPONSE_DEADLINE_FAIL:
      return { ...state, isLoading: false };

    ////forward load
    case LoadAction.GET_FORWARD_LOAD_DETAIL:
      return {
        ...state,
        isLoading: true,
        forwardLoadData: null,
      };
    case LoadAction.GET_FORWARD_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        forwardLoadData: action?.payload,
        forwardLoadDataCopy: Object.assign({}, { ...action?.payload }),
        isLoading: false,
      };
    case LoadAction.GET_FORWARD_LOAD_DETAIL_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.SET_FORWARD_LOAD_DATA:
      return { ...state, forwardLoadData: action?.payload };

    case LoadAction.FORWARD_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case LoadAction.FORWARD_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.FORWARD_LOAD_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.ADD_DRIVER_AND_REF:
      return {
        ...state,
        isLoading: true,
      };
    case LoadAction.ADD_DRIVER_AND_REF_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.ADD_DRIVER_AND_REF_FAIL:
      return { ...state, isLoading: false };

    // cancel load from home screen
    case LoadAction.CANCEL_LOAD_HOME_SCREEN:
      return {
        ...state,
        isLoading: true,
      };
    case LoadAction.CANCEL_LOAD_HOME_SCREEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.CANCEL_LOAD_HOME_SCREEN_FAIL:
      return { ...state, isLoading: false };
    case LoadAction.GET_ACTIVE_LOAD_DETAIL:
      return {
        ...state,
        activeLoading: true,
        activeLoadDetail: null,
      };
    case LoadAction.GET_ACTIVE_LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        activeLoading: false,
        activeLoadDetail: action?.payload,
      };
    case LoadAction.GET_ACTIVE_LOAD_DETAIL_FAIL:
      return { ...state, activeLoading: false };

    case LoadAction.GET_REQUEST_VEHICLE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case LoadAction.GET_REQUEST_VEHICLE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestVehicleData: action?.payload,
        requestVehicleDataCopy: action?.payload,
      };

    case LoadAction.GET_REQUEST_VEHICLE_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    // contact at pickup and drop off
    case LoadAction.CONTACT_PICK_UP_DROP_OFF:
      return {
        ...state,
        isLoading: true,
      };
    case LoadAction.CONTACT_PICK_UP_DROP_OFF_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.CONTACT_PICK_UP_DROP_OFF_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.GET_DRIVER_MARKER:
      return { ...state, isLoading: false };

    case LoadAction.GET_DRIVER_MARKER_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.GET_DRIVER_MARKER_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.GET_VEHICLE_MARKER:
      return { ...state, isLoading: false };

    case LoadAction.GET_VEHICLE_MARKER_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.GET_VEHICLE_MARKER_SUCCESS:
      return { ...state, vehicleMarker: action?.payload, isLoading: false };

    case LoadAction.PIN_POINTS_FOR_VEHICLE:
      return { ...state, };

    case LoadAction.PIN_POINTS_FOR_VEHICLE_SUCCESS:
      return { ...state, vehiclePinPointMarker: action?.payload };

    case LoadAction.PIN_POINTS_FOR_VEHICLE_FAIL:
      return { ...state, };


    case LoadAction.SET_REQUEST_VEHIVLE_DATA:
      return { ...state, requestVehicleDataCopy: action?.payload };

    case LoadAction.POST_REQUEST_VEHICLE:
      return { ...state, isLoading: true };

    case LoadAction.POST_REQUEST_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestVehicleDataCopy: null,
        requestVehicleData: null,
      };

    case LoadAction.POST_REQUEST_VEHICLE_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.ACCEPT_REQUEST_VEHICLE:
      return { ...state, isLoading: true };

    case LoadAction.ACCEPT_REQUEST_VEHICLE_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.ACCEPT_REQUEST_VEHICLE_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.ADD_VIEWER:
      return { ...state, isLoading: true };

    case LoadAction.ADD_VIEWER_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.ADD_VIEWER_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.REMOVE_VIEWER:
      return { ...state, isLoading: true };

    case LoadAction.REMOVE_VIEWER_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.REMOVE_VIEWER_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.ADD_REQUIREMENTS:
      return { ...state, isLoading: true };

    case LoadAction.ADD_REQUIREMENTS_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.ADD_REQUIREMENTS_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.MARK_AS_COMPLETE_BY_RECIPIENT:
      return { ...state, isLoading: true };

    case LoadAction.MARK_AS_COMPLETE_BY_RECIPIENT_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.MARK_AS_COMPLETE_BY_RECIPIENT_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.MARK_AS_COMPLETE_BY_POSTER:
      return { ...state, isLoading: true };

    case LoadAction.MARK_AS_COMPLETE_BY_POSTER_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.MARK_AS_COMPLETE_BY_POSTER_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.NAGOTIATE_BID_POSTER:
      return { ...state, isLoading: true };

    case LoadAction.NAGOTIATE_BID_POSTER_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.NAGOTIATE_BID_POSTER_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT:
      return { ...state, isLoading: true };

    case LoadAction.NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.AWARD_CONTRACT:
      return { ...state, isLoading: true };

    case LoadAction.AWARD_CONTRACT_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.AWARD_CONTRACT_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.ADD_VEHICLE_LAST_LOCATION:
      return { ...state, isLoading: true };

    case LoadAction.ADD_VEHICLE_LAST_LOCATION_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.ADD_VEHICLE_LAST_LOCATION_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.UPLOAD_FILES_RECIPIENT:
      return { ...state, isLoading: true };

    case LoadAction.UPLOAD_FILES_RECIPIENT_SUCCESS:
      return { ...state, isLoading: false };

    case LoadAction.UPLOAD_FILES_RECIPIENT_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.LOAD_DETAIL_EMPTY:
      return { ...state, loadDetail: null };

    // get Load Tracking Credentials
    case LoadAction.HANDLE_TRACKING_CREDENTIALS:
      return {
        ...state,
        isLoading: true,

      };
    case LoadAction.HANDLE_TRACKING_CREDENTIALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.HANDLE_TRACKING_CREDENTIALS_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.ADD_BULK_VEHICLES:
      return {
        ...state,
        isLoading: true,

      };
    case LoadAction.ADD_BULK_VEHICLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.ADD_BULK_VEHICLES_FAIL:
      return { ...state, isLoading: false };


    case LoadAction.ADD_BULK_VEHICLE_REFS:
      return {
        ...state,
        isLoading: true,

      };
    case LoadAction.ADD_BULK_VEHICLE_REFS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.ADD_BULK_VEHICLE_REFS_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.ADD_TRACKING_ID:
      return {
        ...state,
        isLoading: true,

      };
    case LoadAction.ADD_TRACKING_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.ADD_TRACKING_ID_FAIL:
      return { ...state, isLoading: false };


    case LoadAction.UPLOAD_DOC_RECIPIENT:
      return {
        ...state,
        isLoading: true,

      };
    case LoadAction.UPLOAD_DOC_RECIPIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.UPLOAD_DOC_RECIPIENT_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.SHOW_INDICATION_DOTS:
      return {
        ...state,
        isLoading: false,

      };
    case LoadAction.SHOW_INDICATION_DOTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.SHOW_INDICATION_DOTS_FAIL:
      return { ...state, isLoading: false };

    case LoadAction.REMOVE_DOCUMENT:
      return { ...state, activeLoading: true };

    case LoadAction.REMOVE_DOCUMENT_SUCCESS:
      return { ...state, activeLoading: false };

    case LoadAction.REMOVE_DOCUMENT_FAIL:
      return { ...state, activeLoading: false };

    case LoadAction.SHOW_INDICATION_DOTS_VIEWER:
      return {
        ...state,
        isLoading: false,

      };
    case LoadAction.SHOW_INDICATION_DOTS_VIEWER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LoadAction.SHOW_INDICATION_DOTS_VIEWER_FAIL:
      return { ...state, isLoading: false };


    case LoadAction.UPDATE_DRIVER_DETAIL:
      return {
        ...state,
        isLoading: true,
      };

    case LoadAction.UPDATE_DRIVER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case LoadAction.UPDATE_DRIVER_DETAIL_FAIL:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}

export default Reducer;
