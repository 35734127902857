import { showMessage } from "@temp/utils/helper";

export default class Action {
  // Constants
  static GET_PROFILE = "GET_PROFILE";
  static GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
  static GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

  static UPDATE_PROFILE = "UPDATE_PROFILE";
  static UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
  static UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

  static POST_LOAD_DATA = "POST_LOAD_DATA";
  static POST_LOAD_DATA_REMOVE = "POST_LOAD_DATA_REMOVE";
  static SET_ACTIVE_DRAWER_TAB = "SET_ACTIVE_DRAWER_TAB";

  static CHANGE_PHONE_NUMBER_REQUEST = "CHANGE_PHONE_NUMBER_REQUEST";
  static CHANGE_PHONE_NUMBER_REQUEST_SUCCESS =
    "CHANGE_PHONE_NUMBER_REQUEST_SUCCESS";
  static CHANGE_PHONE_NUMBER_REQUEST_FAIL = "CHANGE_PHONE_NUMBER_REQUEST_FAIL";

  static CHANGE_PHONE_NUMBER = "CHANGE_PHONE_NUMBER";
  static CHANGE_PHONE_NUMBER_SUCCESS = "CHANGE_PHONE_NUMBER_SUCCESS";
  static CHANGE_PHONE_NUMBER_FAIL = "CHANGE_PHONE_NUMBER_FAIL";

  static JOB_LOAD_META = [
    "JOB_LOAD_META",
    "JOB_LOAD_META_SUCCESS",
    "JOB_LOAD_META_FAIL",
  ];

  static CHANGE_PASSWORD = "CHANGE_PASSWORD";
  static CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
  static CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

  static UPLOAD_FILE = [
    "UPLOAD_FILE",
    "UPLOAD_FILE_SUCCESS",
    "UPLOAD_FILE_FAIL",
  ];
  // Actions
  static POST_LOAD = ["POST_LOAD", "POST_LOAD_SUCCESS", "POST_LOAD_FAIL"];
  static SET_DASHBOARD_FILTERS = "SET_DASHBOARD_FILTERS";
  static UPDATE_FCM_TOKEN = [
    "UPDATE_FCM_TOKEN",
    "UPDATE_FCM_TOKEN_SUCCESS",
    "UPDATE_FCM_TOKEN_FAIL",
  ];
  static DEL_PROFILE_IMAGE = "DEL_PROFILE_IMAGE";
  static DEL_PROFILE_IMAGE_SUCCESS = "DEL_PROFILE_IMAGE_SUCCESS";
  static DEL_PROFILE_IMAGE_FAIL = "DEL_PROFILE_IMAGE_FAIL";

  static UPDATE_HISTORY_FILTERS = "UPDATE_HISTORY_FILTERS"

  static GET_ACTIVE_LOADS = 'GET_ACTIVE_LOADS';
  static GET_ACTIVE_LOADS_SUCCESS = 'GET_ACTIVE_LOADS_SUCCESS';
  static GET_ACTIVE_LOADS_FAIL = 'GET_ACTIVE_LOADS_FAIL';

  static UPDATE_LOCATION = 'UPDATE_LOCATION';
  static UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
  static UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';

  static UPDATE_ACTIVE_FILTERS = 'UPDATE_ACTIVE_FILTERS'

  static DASHBOARD_REPORTS = 'DASHBOARD_REPORTS';
  static DASHBOARD_REPORTS_SUCCESS = 'DASHBOARD_REPORTS_SUCCESS';
  static DASHBOARD_REPORTS_FAIL = 'DASHBOARD_REPORTS_FAIL';


  static DOWNLOAD_CSV_REPORT = 'DOWNLOAD_CSV_REPORT';
  static DOWNLOAD_CSV_REPORT_SUCCESS = 'DOWNLOAD_CSV_REPORT_SUCCESS';
  static DOWNLOAD_CSV_REPORT_FAIL = 'DOWNLOAD_CSV_REPORT_FAIL';

  static DOCUMENTS_MG_EXPRESS = 'DOCUMENTS_MG_EXPRESS';
  static DOCUMENTS_MG_EXPRESS_SUCCESS = 'DOCUMENTS_MG_EXPRESS_SUCCESS';
  static DOCUMENTS_MG_EXPRESS_FAIL = 'DOCUMENTS_MG_EXPRESS_FAIL';

  static SET_REPORTS_FILTER = "SET_REPORTS_FILTER";

  static SET_VEHICLE_FILTER = "SET_VEHICLE_FILTER";

  static SET_DETAIL_FILTER = "SET_DETAIL_FILTER"

  // new dashboard vehicle
  
  static DASHBOARD_DATA = 'DASHBOARD_DATA';
  static DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS';
  static DASHBOARD_DATA_FAIL = 'DASHBOARD_DATA_FAIL';

  // dashboard report 

  static DASHBOARD_REPORT_NEW = 'DASHBOARD_REPORT_NEW';
  static DASHBOARD_REPORT_NEW_SUCCESS = 'DASHBOARD_REPORT_NEW_SUCCESS';
  static DASHBOARD_REPORT_NEW_FAIL = 'DASHBOARD_REPORT_NEW_FAIL';

  //new dashboard loaddetail

  static DASHBOARD_LOAD_DETAIL = 'DASHBOARD_LOAD_DETAIL';
  static DASHBOARD_LOAD_DETAIL_SUCCESS = 'DASHBOARD_LOAD_DETAIL_SUCCESS';
  static DASHBOARD_LOAD_DETAIL_FAIL = 'DASHBOARD_LOAD_DETAIL_FAIL';




  static setReportsFilter(payload) {
    return {
      type: Action.SET_REPORTS_FILTER,
      payload
    }
  }

  static setVehicleFilter(payload) {
    return {
      type: Action.SET_VEHICLE_FILTER,
      payload
    }
  }

  static setDetailFilter(payload) {
    return {
      type: Action.SET_DETAIL_FILTER,
      payload
    }
  }

  static updateLocation(payload) {
    return {
      type: Action.UPDATE_LOCATION,
      payload
    }
  }

  static updateActiveFilters(payload) {
    return {
      type: Action.UPDATE_ACTIVE_FILTERS,
      payload
    }
  }

  static getActiveLoads(payload) {
    return {
      type: Action.GET_ACTIVE_LOADS,
      payload
    }
  }

  static updateHistoryFilters(payload: any) {
    return {
      type: Action.UPDATE_HISTORY_FILTERS,
      payload
    }
  }


  static updateFCM(payload) {
    return {
      type: Action.UPDATE_FCM_TOKEN[0],
      payload,
    };
  }

  static PostLoad(payload) {
    return {
      type: Action.POST_LOAD[0],
      payload,
    };
  }

  static uploadFile(payload) {
    return {
      type: Action.UPLOAD_FILE[0],
      payload,
    };
  }
  static JobLoadMeta(payload) {
    return {
      type: Action.JOB_LOAD_META[0],
      payload,
    };
  }

  static GetProfile() {
    return {
      type: Action.GET_PROFILE,
    };
  }

  static UpdateProfile(payload) {
    return {
      type: Action.UPDATE_PROFILE,
      payload,
    };
  }

  static postLoadData(payload: any) {
    return {
      type: Action.POST_LOAD_DATA,
      payload,
    };
  }

  static postLoadDataRemove() {
    return {
      type: Action.POST_LOAD_DATA_REMOVE,
    };
  }

  static setActiveDrawerTab(payload: any) {
    return {
      type: Action.SET_ACTIVE_DRAWER_TAB,
      payload,
    };
  }

  static changePhoneNumberRequest(payload: any) {
    return {
      type: Action.CHANGE_PHONE_NUMBER_REQUEST,
      payload,
    };
  }

  static changePhoneNumber(payload: any) {
    return {
      type: Action.CHANGE_PHONE_NUMBER,
      payload,
    };
  }

  static changePassword(payload: any) {
    return {
      type: Action.CHANGE_PASSWORD,
      payload,
    };
  }

  static setDashboardFilters(payload: any) {
    return {
      type: Action.SET_DASHBOARD_FILTERS,
      payload,
    };
  }

  static DelProfileImage() {
    return {
      type: Action.DEL_PROFILE_IMAGE,
    };
  }

  static dashboardReports(payload: any) {
    return {
      type: Action.DASHBOARD_REPORTS,
      payload,
    };

  }

  static downloadCsvReports(payload: any) {
    return {
      type: Action.DOWNLOAD_CSV_REPORT,
      payload,
    };

  }

  static documentsMgExpress(payload: any) {
    return {
      type: Action.DOCUMENTS_MG_EXPRESS,
      payload,
    };
  }

  static dashboardData(payload: any) {
    return {
      type: Action.DASHBOARD_DATA,
      payload,
    };

  }

  static dashboardDataReportNew(payload: any) {
    return {
      type: Action.DASHBOARD_REPORT_NEW,
      payload,
    };

  }

  static dashboardDataLoadDetail(payload: any) {
    return {
      type: Action.DASHBOARD_LOAD_DETAIL,
      payload,
    };
  }
  
}
