import React from "react";
import { IProps } from "./types";
import { Images } from "@temp/assets/";
import "./scss/style.scss";
import { useHistory} from 'react-router-dom';

export const BasicHeader: React.FC<IProps> = ({
  title,
  inverted,
  headerStyle,
  hideBackIcon,
}: IProps) => {
  const history = useHistory();
  return (
    <div className="basic-button-wrapper" style={headerStyle}>
      {!hideBackIcon && (
        <button onClick={()=>history.goBack()} className="header-button">
          <img src={inverted ? Images.BackArrowWhite : Images.BackArrow} alt="" />
        </button>
      )}
      <p className={inverted ? "title-inverted" : "title"}>{title}</p>
    </div>
  );
};

export default BasicHeader;
