import React, { FC } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { LOAD_TYPE, QUOTE_BY } from "@temp/constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,

} from "@mui/material/";
import { ExpandMore } from "@mui/icons-material";
import { ConfirmLoadProps } from "./types";
import { useHistory } from "react-router-dom";
import { OutLineButton } from "@temp/components";
var numeral = require("numeral");



export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 17px;
  border-radius: 10px;
`;

export const AdditionalDetails: FC<ConfirmLoadProps> = ({
  value,
  otherData,
  inquiryType,
  loadType,
  index,
  editable,
  is_recipient,
  fromHistory,
  loadDetail



}: ConfirmLoadProps) => {

  const FormRow = ({ label, value, sep, urdutitle, is_recipient, fromHistory }) => {
    return (
      <Grid item xs={12} className="formGrid">
        <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
          <span className="titleName">{label}</span>
          {
            fromHistory && is_recipient &&
            <>
              <span className="seperator">
                {sep}
              </span>
              <span className="urduLabel lhEdit">
                {urdutitle}
              </span>
            </>
          }
        </div>
        <div className="fw-5">{value}</div>
      </Grid>
    );
  };

  const PostLoad = useSelector((state) => state?.Profile?.postLoad);
  const postLoadMeta = useSelector((state) => state?.Profile?.postLoadMeta);

  const load_type = postLoadMeta.load_types.find(
    (val: object) => val.id === PostLoad.load_type
  );

  const history = useHistory();
  const onEdit = () => {
    history.push("/additionalDetails");
  };

  return (
    <>
      <Accordion className="confirm-load-accordion" style={{ backgroundColor: "#FAFAFA" }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className="fw-6">
            Additional Details
            {
              fromHistory && is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">اضافی تفصیلات</span>
              </>
            }
          </p>

          {editable ? (
            <div className="editBtnWrapper">
              <OutLineButton
                classProp='tinyBtn'
                dataColor="blue"
                title="Edit"
                icon={
                  <span className="icons-edit"></span>
                }
                onClick={onEdit}
              />
            </div>
          ) : null}

        </AccordionSummary>
        <AccordionDetails>

          <Grid container spacing={1} style={{ overflowY: "scroll" }}>
            {PostLoad?.load_type === 1 && value?.detention_included_transport ?
              <>
                <FormRow
                  label="Mandatory Detention included in Transport Price?"
                  value={value?.detention_included_transport ? "YES" : "NO"}
                  sep={"/"}
                  urdutitle={"لازمی ڈیٹنشن ٹرانسپورٹ کی قیمت میں شامل ہے؟"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </> : null
            }

            {value?.is_advance_vehicle_driver_docs ?

              <FormRow

                label="Advance Vehicle / Driver Documents"
                value={value?.is_advance_vehicle_driver_docs ? "YES" : "NO"}
                sep={"/"}
                urdutitle="گاڑی / ڈرائیور کے دستاویزات"
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              /> : null}

            {/* {load_type.title === "Non-Containerized" ? <>
              {value?.is_weightment_received_required && value?.is_bonded_vehicle_required ?
                <>
                  <FormRow
                    label="Bonded Vehicle Required"
                    value={value?.is_bonded_vehicle_required ? "YES" : "NO"}
                    sep={"/"}
                    urdutitle="بانڈڈ کیریئر گاڑی درکار ہے۔"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}
                  />
                </> : null}
            </>
              : <>
                {value?.is_bonded_vehicle_required ? <>
                  <FormRow
                    label="Custom Bonded Carrier"
                    value={value?.is_bonded_vehicle_required ? "YES" : "NO"}
                    sep={"/"}
                    urdutitle={"کسٹم بانڈڈ کیریئر"}
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}
                  />
                </> : null}
              </>} */}

            {value?.is_bonded_vehicle_required ?
              <FormRow
              label="Bonded Vehicle Required"
                sep="/"
                urdutitle="بانڈڈ کیریئر گاڑی درکار ہے۔"
                value={value?.is_bonded_vehicle_required ? "YES" : "NO"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              /> : null}

            {load_type.title == LOAD_TYPE.fcl && value?.is_cross_stuffing_required && PostLoad?.inquiry_type == 1 ? (
              <>

                {value?.is_cross_stuffing_box_vehicle_required ?
                  <FormRow
                    label="Containerized / Box Vehicle Only"
                    value={value?.is_cross_stuffing_box_vehicle_required ? "YES" : "NO"}
                    sep={"/"}
                    urdutitle="کنٹینرائزڈ / باکس گاڑی"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}
                  /> : null}
                <FormRow
                  label="Cross Stuffing / Local Shifting"
                  value={value?.is_cross_stuffing_required ? "YES" : "NO"}
                  sep={"/"}
                  urdutitle="لوکل شفٹنگ۔"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </>
            ) : null}

            {((Number(inquiryType) === 1 &&
              loadType?.title === LOAD_TYPE.fcl) ||
              Number(inquiryType) === 2) &&
              (load_type.title == LOAD_TYPE.fcl && value?.is_container_deposit_by_transporter ? (
                <FormRow
                  label="Container Deposit by Transporter"
                  value={
                    value?.is_container_deposit_by_transporter ? "YES" : "NO"
                  }
                  sep={"/"}
                  urdutitle={"کنٹینر ڈپازٹس ٹرانسپوٹر دے گا "}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              ) : null)}

            {value?.is_advance_vehicle_driver_docs && value?.driver_requried_document_details ?
              <>
                <FormRow
                  label="Details of Advance Vehicle Documents Required"
                  value={value?.driver_requried_document_details}
                  sep={"/"}
                  urdutitle="گاڑی کے ایڈوانس دستاویزات کی تفصیلات درکار ہیں۔"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </> : null}

            {value?.is_container_deposit_by_transporter && value?.container_deposit_amount ?
              <FormRow
                label="Deposit Amount"
                value={numeral(value?.container_deposit_amount).format("0,0") + " PKR"}
                sep={"/"}
                urdutitle={"ڈپازٹس کی رقم"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              /> : null
            }

            {value?.is_covid_certf_req_driver ?
              <FormRow
                label="Driver covid-19 vaccination required"
                value={value?.is_covid_certf_req_driver ? "YES" : "NO"}
                sep={"/"}
                urdutitle="ڈرائیور کی کوویڈ 19 ویکسینیشن درکار ہے۔"
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              /> : null}

            {value?.is_driver_negative_pcr_required ?
              <FormRow
                label="Driver Negative PCR required"
                value={value?.is_driver_negative_pcr_required ? "YES" : "NO"}
                sep={"/"}
                urdutitle="ڈرائیور کی منفی پی سی آر کی ضرورت ہے۔"
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              /> : null}

            {value?.is_hse_compliant_vehicle ? <>
              <FormRow
                label="HSE Complaint Vehicle"
                value={value?.is_hse_compliant_vehicle ? "YES" : "NO"}
                sep={"/"}
                urdutitle="سیفٹی اور سینڈر  کے مطابق گاڑی"
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />

            </> : null}

            {fromHistory && loadDetail?.is_allow_partial_load 
            // && PostLoad?.inquiry_type == 1 
            ?
              <FormRow
                label="Partial Load Allow"
                value={loadDetail?.is_allow_partial_load ? "YES" : "NO"}
                sep={"/"}
                urdutitle={"پارشل لوڈ کی اجازت"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
               : null}

            {!fromHistory && value?.is_allow_partial_load 
            // && PostLoad?.inquiry_type == 1 
            ?
              <FormRow
                label="Partial Load Allow"
                value={value?.is_allow_partial_load ? "YES" : "NO"}
                sep={"/"}
                urdutitle={"پارشل لوڈ کی اجازت"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
               : null}

            {otherData?.quote_by == QUOTE_BY.weightVolume && otherData?.is_shared_vehicle_not_allowed ? (
              <FormRow
                label="Shared Vehicle Allowed"
                value={
                  otherData?.is_shared_vehicle_not_allowed ? "YES" : "NO"
                }
                sep={"/"}
                urdutitle={"مشترکہ گاڑی کی اجازت ہے۔"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
            ) : null}

            {value?.shipping_line_name && value?.is_container_deposit_by_transporter ?
              <>
                <FormRow
                  label="Shipping Line Name"
                  value={value?.shipping_line_name}
                  sep={"/"}
                  urdutitle="شپنگ لائن کا نام"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </> : null}

            {load_type.title === "Non-Containerized" && value?.is_tarpaulin_plastic_cover ?
              <>
                <FormRow
                  label="Tarpaulin / Plastic Cover"
                  value={value?.is_tarpaulin_plastic_cover ? "YES" : "NO"}
                  sep={"/"}
                  urdutitle="ترپال / پلاسٹک کا احاطہ"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </> : null}


            {load_type.title === "Non-Containerized" && value?.is_under_hook_delivery_required ? <>

              <FormRow
                label="Underhook / Direct Delivery Required"
                value={value?.is_under_hook_delivery_required ? "YES" : "NO"}
                sep={"/"}
                urdutitle="انڈر ہُک / ڈائریکٹ ڈیلیوری درکار ہے۔"
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
            </> : null}

            {value?.is_under_hook_delivery_required && value?.under_hook_delivery ?
              <>
                <FormRow
                  label="Package No."
                  value={value?.under_hook_delivery}
                  sep={"/"}
                  urdutitle="پیکیج نمبر"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </> : null}

            {value?.is_vehicle_tracking ?
              <>
                <FormRow
                  label="Vehicle Tracking"
                  value={value?.is_vehicle_tracking ? "YES" : "NO"}
                  sep={"/"}
                  urdutitle="ٹریکر"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </> : null}

            {value?.is_weightment_received_required ? <>

              <FormRow
                label="Weighment Required"
                value={value?.is_weightment_received_required ? "YES" : "NO"}
                sep={"/"}
                urdutitle="کانٹے کی ضرورت "
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
            </> : null}

            {value?.is_weightment_received_required && value?.weightment_received_name ?
              <>
                <FormRow
                  label="Weighment Bridge Name"
                  value={value?.weightment_received_name}
                  sep={"/"}
                  urdutitle="کانٹے کا نام"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </> : null}

          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AdditionalDetails;
