import React, { FC } from "react";
import { IProps } from "./types";
import { Images } from "@temp/assets";

export const LoadCard: FC<IProps> = (props: IProps) => {
  const {
    title,
    subtitle,
    img,
    onClick,
    disabled,
    imgContainerStyle,
    imgStyle,
    selected,
  } = props;
  return (
    <div className={`loadsCard ripple ${selected ? 'active' : ''}`} onClick={onClick} data-disabled={disabled} >
      <div className="detail">
        <div>
          <p className="title">{title}</p>
          <p className="subtitle">{subtitle}</p>
        </div>
        <img className="arrow" src={Images.RightArrowGreen} />
      </div>
      <div className="loadsImg" style={imgContainerStyle}>
        <img className="" style={imgStyle} src={img}></img>
      </div>
    </div>
  );
};

export default LoadCard;
