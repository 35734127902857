import { Colors, Images } from "@temp/assets";
import { CustomText, NewInput, VehicleIncDec } from "@temp/components";
import React, { useState, useEffect } from "react";
import { DeclineButton } from "./DeclineButton";
import { RecipientStatus } from "./RecipientStatus";
import { VehicleCounter } from "./VehicleCount";
import { useHistory } from "react-router-dom";
import { Checkbox } from "@mui/material/";
import { RecipientStatusContract } from "./index";
import { showMessage, triggerMixpanelEvent } from "@temp/utils/helper";
import { useDispatch } from "react-redux";
import { LoadAction } from "@temp/store/actions";
import { NegotiateBid } from "./NegociateBid";
import moment from "moment";
import { LOAD_TYPE } from "@temp/constants";
import { set } from "lodash";

var numeral = require("numeral");

var clickHoldTimerInc = null
var clickHoldTimerDec = null


export const RenderRecipient: any = ({
  item2,
  cat_id,
  total_vehicle,
  index,
  awardIncrement,
  awardDecrement,
  declineByPoster,
  awarded,
  loadDetail,
  location,
  final_category,
  awarded_contract,
  Award,
  clickCheckbox,
  awardIncrementMultiple,
  userRole,
  companyName,
  isDeadlineComplete,
  locationCompRef,
  setAwarded,
  awardIncrementOnInput,
  totalAwarded,
  responded
}) => {



  const history = useHistory();
  const [bid_negotiated_amount, set_bid_negotiated_amount] = useState(null)
  const [bid_negotiated_detention_rate, set_bid_negotiated_detention_rate] = useState(null)
  const [bid_negotiated_detention_tarif_policy, set_negotiated_bid_detention_tarif_policy] = useState(null)
  const [bid_negotiated_price_loading, set_bid_negotiated_price_loading] = useState(null)
  const [bid_negotiated_price_unloading, set_bid_negotiated_price_unloading] = useState(null)
  const [showModal, toggleModal] = useState(false)
  const [job_load_recipient_bid_id, set_job_load_recipient_bid_id] = useState(null)
  const dispatch = useDispatch()
  let responded_vehicles = awarded?.find((itm) => itm?.job_load_recipient_id == item2.id)?.no_of_vehicles
  const contract_award_fcl = awarded_contract?.find((item) => item?.job_load_recipient_bid_id == item2?.bid?.id)

  const show_edit_bid_button =
    !loadDetail?.is_allow_partial_load || loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_cross_stuffing_required ?
      (item2?.bid && item2?.status !== 4 && item2?.status !== 2 &&
        isDeadlineComplete && item2?.bid?.bid_negotiated_times < 2 && item2?.mark_complete_status !== 1
        && item2?.status == 1 && !item2?.bid?.bid_negotiated_amount && final_category?.total_awarded < 1 && (loadDetail?.is_container_deposit_by_transporter ||
          loadDetail?.is_cross_stuffing_required || !loadDetail?.is_allow_partial_load))
      :
      (item2?.bid && item2?.status !== 4 && item2?.status !== 2 && item2?.mark_complete_status !== 1 &&
        isDeadlineComplete && item2?.bid?.bid_negotiated_times < 2)


  let isRateValidityCheck = moment(new Date()).format("x") < (moment(loadDetail?.rate_validity_date).format("x"))

  // const handleUpdateBid = () => {
  //   dispatch(LoadAction.negotiateBidPoster({
  //     load_id: loadDetail?.id,
  //     job_load_recipient_bid_id,
  //     bid_negotiated_amount,
  //     bid_negotiated_detention_rate,
  //     bid_negotiated_detention_tarif_policy,
  //     bid_negotiated_price_loading,
  //     bid_negotiated_price_unloading
  //   }))
  //   set_bid_negotiated_amount(null)
  //   set_job_load_recipient_bid_id(null)
  //   set_bid_negotiated_detention_rate(null)
  //   set_negotiated_bid_detention_tarif_policy(null)
  //   set_bid_negotiated_price_loading(null)
  //   set_bid_negotiated_price_unloading(null)
  //   toggleModal(!showModal)
  //   setAwarded([])

  // }

  const handleUpdateBid = () => {
    if (bid_negotiated_amount !== ".0" && bid_negotiated_amount !== ".00"
      && bid_negotiated_detention_rate !== ".0" && bid_negotiated_detention_rate !== ".00"
      && bid_negotiated_price_loading !== ".0" && bid_negotiated_price_loading !== ".00"
      && bid_negotiated_price_unloading !== ".0" && bid_negotiated_price_unloading !== ".00") {
      // return
      dispatch(LoadAction.negotiateBidPoster({
        load_id: loadDetail?.id,
        job_load_recipient_bid_id,
        bid_negotiated_amount,
        bid_negotiated_detention_rate,
        bid_negotiated_detention_tarif_policy,
        bid_negotiated_price_loading,
        bid_negotiated_price_unloading

      }))
      set_bid_negotiated_amount(null)
      set_job_load_recipient_bid_id(null)
      set_bid_negotiated_detention_rate(null)
      set_negotiated_bid_detention_tarif_policy(null)
      set_bid_negotiated_price_loading(null)
      set_bid_negotiated_price_unloading(null)
      toggleModal(!showModal)
      setAwarded([])

    } else {
      showMessage.current({
        message:
          `Please enter a valid value.`,
        status: "error",
      });
    }
  }

  const RenderRow = ({ title, val }) => {
    return (
      <div className="formGrid">
        <div className="grayLabel">{title}</div>
        <div className="fw-5">{val}</div>
      </div>
    )
  }


  return (
    <div className="">
      <div className="detail-category-recepient-container">
        {/* checkbox for contract case  */}
        {item2?.status == 1 && !item2?.bid?.bid_negotiated_amount && loadDetail?.inquiry_type?.title != "Spot Rate" &&
          ((loadDetail?.transaction_type.title == "Bid" && isDeadlineComplete && item2?.mark_complete_status !== 1) ||
            loadDetail?.transaction_type.title !== "Bid") && (
            <div className="check-wrapper">
              {/* {isRateValidityCheck ? */}
              <Checkbox
                checked={contract_award_fcl ? true : false}
                onChange={(e) => {
                  clickCheckbox({ job_load_recipient_bid_id: item2?.bid?.id, bid: item2?.bid });
                }}
                style={{ color: "#7ac251" }}
              />
              {/* // : null} */}
            </div>
          )}

        {/* checkbox for spot case  */}
        {item2?.status == 1 && !item2?.bid?.bid_negotiated_amount && final_category?.total_awarded < 1 && (loadDetail?.is_container_deposit_by_transporter ||
          loadDetail?.is_cross_stuffing_required || !loadDetail?.is_allow_partial_load) &&
          loadDetail?.inquiry_type?.title != "Contract Rate" &&
          ((loadDetail?.transaction_type.title == "Bid" && isDeadlineComplete && item2?.mark_complete_status !== 1) ||
            loadDetail?.transaction_type.title !== "Bid") && (
            <div>
              {/* {isRateValidityCheck ? */}

              <Checkbox
                className="check-wrapper"
                checked={responded_vehicles ? true : false}
                onChange={(e) => {
                  // clickCheckbox({ job_load_recipient_bid_id: item2?.bid?.id, bid: item2?.bid });
                  if (e.target.checked) {
                    awardIncrementMultiple({ ...item2, cat_id, total_vehicle, index, showmessage: true, vehicles: loadDetail?.is_unit_basis ? final_category?.unit_quantity : final_category?.vehicle_quantity, add: true })
                  } else {
                    awardIncrementMultiple({ ...item2, cat_id, total_vehicle, index, showmessage: true, add: false })
                  }
                }}
                style={{ color: "#7ac251" }}
              />
              {/* // : null} */}
            </div>
          )}
        <div className="load-detail-user">
          <img
            alt="profile"
            className="load-detail-user"
            src={item2?.recipient?.picture || Images.myProfileImage}
          />
        </div>
        {loadDetail?.inquiry_type?.title == "Spot Rate" ? (
          <>
            <div className="recepient-detail-container">
              <div className="width-100">
                {/* top row  */}
                <div className="flexRow100 alignItemsTop" style={{ flexWrap: 'wrap' }}>
                  <div className="title customTitleWithDot customTitleDotNew" style={{ width: '60%' }}>
                    <span>{item2?.recipient?.company?.length ?
                      item2?.recipient?.company[0]?.name : item2?.recipient?.name
                      || "UnRegistered User"}
                    </span>

                    <div style={{
                      backgroundColor: item2?.is_seen ? "#7ac251" : "#DA2C2C",
                      height: 8,
                      width: 8,
                      borderRadius: 5,
                      marginLeft: 8,
                      marginTop: 5
                    }}></div>
                  </div>
                  <div className="completeGreenText">
                    {
                      item2?.mark_complete_status == 1 ?
                        "Completed" : null
                    }
                  </div>
                  {/* top status when only accept */}
                  {loadDetail?.transaction_type?.title == "Bid" ? (
                    <div style={{ width: '40%', marginRight: 0, justifyContent: show_edit_bid_button ? 'flex-end' : 'flex-end' }} className="flexRow">
                      {/* for bid spot */}
                      {isDeadlineComplete && item2?.status === 1 ? (
                        <>
                          {
                            (show_edit_bid_button
                              // && isRateValidityCheck 
                              && loadDetail?.is_allow_partial_load) ||
                              (show_edit_bid_button
                                // && isRateValidityCheck 
                                && !loadDetail?.is_allow_partial_load && final_category?.total_awarded < 1
                              ) ?
                              <div>
                                <div className="nogotiate_button icon-btn newNegotiateBtn" onClick={() => {
                                  if (loadDetail?.inquiry_type?.title == "Spot Rate" && (!loadDetail?.locations[0]?.raw_address || !loadDetail?.locations[1]?.raw_address ||
                                    !loadDetail?.locations[0]?.contact_name || !loadDetail?.locations[0]?.contact_phone_no ||
                                    !loadDetail?.locations[1]?.contact_name || !loadDetail?.locations[1]?.contact_phone_no)) {
                                    locationCompRef?.current?.openModal()
                                  } else {
                                    set_job_load_recipient_bid_id(item2?.bid?.id)
                                    set_bid_negotiated_amount(item2?.bid?.bid_negotiated_amount || item2?.bid?.bid_price_per_vehicle)
                                    set_bid_negotiated_detention_rate(item2?.bid?.bid_negotiated_detention_rate || item2?.bid?.bid_detention_rate)
                                    set_negotiated_bid_detention_tarif_policy(item2?.bid?.bid_negotiated_detention_tarif_policy || item2?.bid?.detention_tarif_policy)
                                    set_bid_negotiated_price_loading(item2?.bid?.bid_negotiated_price_loading || item2?.bid?.bid_price_loading)
                                    set_bid_negotiated_price_unloading(item2?.bid?.bid_negotiated_price_unloading || item2?.bid?.bid_price_unloading)
                                    toggleModal(!showModal)


                                  }
                                }}>

                                  <span className="icons-edit"></span>
                                  <span className="bid-text">Edit Bid</span>
                                </div>
                              </div>
                              : null
                          }
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </div>

                <div className="flexRow">
                  <div className="company">
                    {
                      item2?.recipient?.company?.length ?
                        item2?.recipient?.name : item2?.recipient?.company_name
                    }
                  </div>




                </div>
                {/* bottom row  */}
                <div className="recepient-status-container">
                  {
                    loadDetail.transaction_type.title == "Offer" ||
                      (isDeadlineComplete && loadDetail.transaction_type.title == "Bid") ? (
                      <>
                        {
                          item2?.status == 0 && (
                            <RecipientStatus
                              status={item2?.status}
                              loadDetail={loadDetail}
                              decline_by={item2?.declined_by}
                              from={"poster"}
                              item={item2?.mark_complete_status}
                            />
                          )
                        }

                        {
                          item2?.status == 1 || item2.status == 4 ? (
                            <div className="flexRow">
                              <VehicleCounter recipient={item2} loadDetail={loadDetail} />
                              <RecipientStatus
                                status={item2?.status}
                                loadDetail={loadDetail}
                                decline_by={item2?.declined_by}
                                from={"poster"}
                                item={item2?.mark_complete_status}

                              />
                            </div>
                          ) : null
                        }

                        {
                          item2?.status == 2 && item2?.declined_by == "Poster" ?
                            <div className="flexRow">
                              <VehicleCounter recipient={item2} loadDetail={loadDetail} />
                              {/* <RecipientStatus
                                status={item2?.status}
                                loadDetail={loadDetail}
                                readOnly={true}
                              /> */}
                            </div> : null
                        }

                        {
                          item2?.status == 2 && item2?.declined_by == "Recipient" ?
                            <RecipientStatus
                              status={item2?.status}
                              loadDetail={loadDetail}
                              decline_by={item2?.declined_by}
                              from={"poster"}
                              item={item2?.mark_complete_status}
                            /> : null
                        }
                        {(item2.status == 1 && item2?.mark_complete_status !== 1
                          // && isRateValidityCheck
                        ) || (item2.status == 2 && item2?.declined_by == "Poster") ?
                          <DeclineButton
                            type={item2.declined_by == "Poster" && "redButton" || "outline"}
                            declineByPoster={declineByPoster}
                            recipient={item2}
                            loadDetail={loadDetail}
                          />
                          : null}
                      </>
                    ) : null

                  }


                  {!isDeadlineComplete && loadDetail.transaction_type.title == "Bid" ? (
                    <RecipientStatus
                      status={item2?.status}
                      loadDetail={loadDetail}
                      decline_by={item2?.declined_by}
                      from={"poster"}
                      item={item2?.mark_complete_status}
                    />
                  ) : null
                  }
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="recepient-detail-container">
            <div className="width-100">
              {/* top row  */}
              <div style={{
                justifyContent: 'space-between',
                marginRight: 0,
              }} className="flexRow100">
                <div className="title customTitleWithDot">
                  <span>{item2?.recipient?.company?.length ?
                    item2?.recipient?.company[0]?.name : item2?.recipient?.name
                    || "UnRegistered User"}
                  </span>


                  <div style={{
                    backgroundColor: item2?.is_seen ? "#7ac251" : "#DA2C2C",
                    height: 8,
                    width: 8,
                    borderRadius: 5,
                    marginLeft: 8,
                    marginTop: 5
                  }}></div>
                  <div className="completeGreenText">
                    {
                      item2?.mark_complete_status == 1 ?
                        "Completed" : null
                    }
                  </div>
                </div>
                {/* for bid case  contract */}
                {
                  (show_edit_bid_button
                    // && isRateValidityCheck 
                    && loadDetail?.is_allow_partial_load) ||
                    (show_edit_bid_button
                      //  && isRateValidityCheck 
                      && !loadDetail?.is_allow_partial_load && final_category?.total_awarded < 1) ?
                    <div>
                      <div className="nogotiate_button icon-btn newNegotiateBtn" onClick={() => {
                        if (loadDetail?.inquiry_type?.title == "Spot Rate" && (!loadDetail?.locations[0]?.raw_address || !loadDetail?.locations[1]?.raw_address ||
                          !loadDetail?.locations[0]?.contact_name || !loadDetail?.locations[0]?.contact_phone_no ||
                          !loadDetail?.locations[1]?.contact_name || !loadDetail?.locations[1]?.contact_phone_no)) {
                          locationCompRef?.current?.openModal()
                        } else {
                          set_job_load_recipient_bid_id(item2?.bid?.id)
                          set_bid_negotiated_amount(item2?.bid?.bid_negotiated_amount || item2?.bid?.bid_price_per_vehicle)
                          set_bid_negotiated_detention_rate(item2?.bid?.bid_negotiated_detention_rate || item2?.bid?.bid_detention_rate)
                          set_negotiated_bid_detention_tarif_policy(item2?.bid?.bid_negotiated_detention_tarif_policy || item2?.bid?.detention_tarif_policy)
                          set_bid_negotiated_price_loading(item2?.bid?.bid_negotiated_price_loading || item2?.bid?.bid_price_loading)
                          set_bid_negotiated_price_unloading(item2?.bid?.bid_negotiated_price_unloading || item2?.bid?.bid_price_unloading)
                          toggleModal(!showModal)
                        }
                      }}>
                        <span className="icons-edit"></span>
                        <span className="bid-text">Edit Bid</span>

                      </div>
                    </div>
                    : null
                }

              </div>

              <div className="flexRow">

                <div className="company">
                  {
                    item2?.recipient?.company?.length ?
                      item2?.recipient?.name : item2?.recipient?.company_name
                  }
                </div>



              </div>
              {/* bottom row  */}

              <div className="recepient-status-container">
                {item2?.status == 2 && item2?.declined_by == "Poster" ? (
                  <div className="flexRow">
                    <div className="chip">
                      {loadDetail?.is_unit_basis ?
                        numeral(item2?.bid?.bid_price_per_vehicle).format("0,0.00") + " PKR " + "/ Item"
                        : numeral(item2?.bid?.bid_price_per_vehicle).format("0,0") + " PKR " + "/ Vehicle"}
                    </div>
                  </div>
                ) : item2?.status !== 1 ? (
                  <RecipientStatusContract
                    recipient={item2}
                    loadDetail={loadDetail}
                  />
                ) : (
                  <RecipientStatusContract
                    recipient={item2}
                    loadDetail={loadDetail}
                  />
                )}
                {item2?.status == 4 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div className="chip">
                      {/* {numeral(item2?.bid?.bid_price_per_vehicle).format("0,0") + " PKR"} /Vehicle  */}

                      {loadDetail?.is_unit_basis ?
                        numeral(item2?.bid?.bid_price_per_vehicle).format("0,0.00") + " PKR " + "/ Item"
                        : numeral(item2?.bid?.bid_price_per_vehicle).format("0,0") + " PKR " + "/ Vehicle"}
                    </div>
                  </div>
                )}

                {/* decline button show when status is accepted  */}
                {(loadDetail?.transaction_type?.title == "Bid" &&
                  item2?.status == 1 && isDeadlineComplete && item2?.mark_complete_status !== 1
                  // && isRateValidityCheck
                ) ||
                  (loadDetail?.transaction_type?.title == "Offer" &&
                    item2?.status === 1 && item2?.mark_complete_status !== 1
                    // && isRateValidityCheck
                  ) ? (
                  <DeclineButton
                    type={"outline"}
                    declineByPoster={declineByPoster}
                    recipient={item2}
                    loadDetail={loadDetail}

                  />
                ) : null}
                {/* shows when status is declined by poster */}
                {item2?.status == 2 &&
                  item2?.declined_by == "Poster" && (
                    <DeclineButton
                      type={"redbutton"}
                      declineByPoster={declineByPoster}
                      recipient={item2}
                      loadDetail={loadDetail}

                    />
                  )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* for offer case */}
      {loadDetail?.transaction_type?.title == "Offer" &&
        item2?.status == 1 && (
          <div className="">
            {
              loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_cross_stuffing_required || !loadDetail?.is_allow_partial_load ?
                <CustomText.OpenSansSemiBold>
                  {/* {loadDetail?.is_unit_basis ? final_category?.unit_quantity : final_category?.vehicle_quantity} */}
                </CustomText.OpenSansSemiBold> :
                // isRateValidityCheck ?
                <>
                  <VehicleIncDec
                    className="labelWithIncrementField"
                    value={responded_vehicles || 0}
                    fromDetail={true}
                    withInputBox={true}
                    updateValue={(val: number) => {
                      awardIncrementOnInput({ ...item2, cat_id, total_vehicle, index, showmessage: true, val, totalRequiredVehicle: Number(total_vehicle), responded })
                    }}
                    onMouseDown={(data) => {
                      if (data == "inc") {
                        if (loadDetail?.inquiry_type?.title == "Spot Rate" && (!loadDetail?.locations[0]?.raw_address || !loadDetail?.locations[1]?.raw_address ||
                          !loadDetail?.locations[0]?.contact_name || !loadDetail?.locations[0]?.contact_phone_no ||
                          !loadDetail?.locations[1]?.contact_name || !loadDetail?.locations[1]?.contact_phone_no)) {
                          locationCompRef?.current?.openModal()
                        } else {
                          if (loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_cross_stuffing_required || !loadDetail?.is_allow_partial_load) {
                            awardIncrementMultiple({ ...item2, cat_id, total_vehicle, index, showmessage: true, })
                          } else {
                            awardIncrement({
                              ...item2, cat_id, total_vehicle, index, showmessage: true,
                              // totalRequiredVehicle: Number(total_vehicle) - totalAwarded, 
                              totalRequiredVehicle: Number(total_vehicle),
                              responded
                            });
                          }
                          clickHoldTimerInc = setInterval(() => {
                            if (loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_cross_stuffing_required || !loadDetail?.is_allow_partial_load) {
                              awardIncrementMultiple({ ...item2, cat_id, total_vehicle, index, showmessage: true })
                            } else {
                              awardIncrement({
                                ...item2, cat_id, total_vehicle, index, showmessage: true, totalRequiredVehicle: Number(total_vehicle),
                                responded
                              });
                            }
                          }, 300);
                        }
                      } else {
                        awardDecrement({
                          ...item2, cat_id, total_vehicle, index, showmessage: true, totalRequiredVehicle: Number(total_vehicle),
                          responded
                        });
                        clickHoldTimerDec = setInterval(() => {
                          awardDecrement({
                            ...item2, cat_id, total_vehicle, index, showmessage: true, totalRequiredVehicle: Number(total_vehicle),
                            responded
                          });
                        }, 300);
                      }
                    }}
                    onMouseUp={() => {
                      clearInterval(clickHoldTimerInc);
                      clearInterval(clickHoldTimerDec);
                    }}
                    onTouchStart={(data) => {
                      if (data == "inc") {
                        if (loadDetail?.inquiry_type?.title == "Spot Rate" && (!loadDetail?.locations[0]?.raw_address || !loadDetail?.locations[1]?.raw_address ||
                          !loadDetail?.locations[0]?.contact_name || !loadDetail?.locations[0]?.contact_phone_no ||
                          !loadDetail?.locations[1]?.contact_name || !loadDetail?.locations[1]?.contact_phone_no)) {
                          locationCompRef?.current?.openModal()
                        } else {
                          awardIncrement({
                            ...item2, cat_id, total_vehicle, index, showmessage: true, totalRequiredVehicle: Number(total_vehicle),
                            responded
                          });
                          clickHoldTimerInc = setInterval(() => {
                            awardIncrement({ ...item2, cat_id, total_vehicle, index, showmessage: true, totalRequiredVehicle: Number(total_vehicle), responded });
                          }, 300);
                        }
                      } else {
                        awardDecrement({
                          ...item2, cat_id, total_vehicle, index, showmessage: true, totalRequiredVehicle: Number(total_vehicle),
                          responded
                        });
                        clickHoldTimerDec = setInterval(() => {
                          awardDecrement({
                            ...item2, cat_id, total_vehicle, index, showmessage: true, totalRequiredVehicle: Number(total_vehicle),
                            responded
                          });
                        }, 300);
                      }
                    }}
                    onTouchEnd={() => {
                      clearInterval(clickHoldTimerInc);
                      clearInterval(clickHoldTimerDec);
                    }}
                  />

                </>
              // : null

            }
          </div>
        )}

      {/* for bid case  */}

      {loadDetail?.transaction_type?.title == "Bid" &&
        item2?.status == 1 && isDeadlineComplete && item2?.mark_complete_status !== 1 &&
        (
          <div className="">
            {
              loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_cross_stuffing_required || !loadDetail?.is_allow_partial_load ?
                <CustomText.OpenSansSemiBold>
                  {/* {loadDetail?.is_unit_basis ? `${final_category?.unit_quantity} ${final_category?.unit_quantity>1?" Items":" Item"} `: `${final_category?.vehicle_quantity} ${final_category.vehicle_quantity>1?" Vehicles":" Vehicle"}`} */}
                </CustomText.OpenSansSemiBold> :
                // isRateValidityCheck ?
                <>
                  {
                    !item2?.bid?.bid_negotiated_amount ?
                      <VehicleIncDec
                        className="labelWithIncrementField"
                        value={responded_vehicles || 0}
                        fromDetail={true}
                        withInputBox={true}
                        updateValue={(val: number) => {
                          awardIncrementOnInput({ ...item2, cat_id, total_vehicle, index, showmessage: true, val, totalRequiredVehicle: Number(total_vehicle), responded })
                        }}
                        onMouseDown={(data) => {
                          if (data == "inc") {
                            if (loadDetail?.inquiry_type?.title == "Spot Rate" && (!loadDetail?.locations[0]?.raw_address || !loadDetail?.locations[1]?.raw_address ||
                              !loadDetail?.locations[0]?.contact_name || !loadDetail?.locations[0]?.contact_phone_no ||
                              !loadDetail?.locations[1]?.contact_name || !loadDetail?.locations[1]?.contact_phone_no)) {
                              locationCompRef?.current?.openModal()
                            } else {
                              if (loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_cross_stuffing_required || !loadDetail?.is_allow_partial_load) {
                                awardIncrementMultiple({ ...item2, cat_id, total_vehicle, index, showmessage: true, })
                              } else {
                                awardIncrement({
                                  ...item2, cat_id, total_vehicle, index, showmessage: true,
                                  totalRequiredVehicle: Number(total_vehicle),
                                  responded
                                });
                              }
                              clickHoldTimerInc = setInterval(() => {
                                if (loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_cross_stuffing_required || !loadDetail?.is_allow_partial_load) {
                                  awardIncrementMultiple({ ...item2, cat_id, total_vehicle, index, showmessage: true, })
                                } else {
                                  awardIncrement({
                                    ...item2, cat_id, total_vehicle, index, showmessage: true,
                                    // totalRequiredVehicle: Number(total_vehicle) - totalAwarded, 
                                    totalRequiredVehicle: Number(total_vehicle),
                                    responded
                                  });
                                }
                              }, 400);
                            }
                          } else {
                            awardDecrement({
                              ...item2, cat_id, total_vehicle, index, showmessage: true, totalRequiredVehicle: Number(total_vehicle),
                              responded
                            });
                            clickHoldTimerDec = setInterval(() => {
                              awardDecrement({
                                ...item2, cat_id, total_vehicle, index, showmessage: true, totalRequiredVehicle: Number(total_vehicle),
                                responded
                              });
                            }, 400);
                          }
                        }}
                        onMouseUp={() => {
                          clearInterval(clickHoldTimerInc);
                          clearInterval(clickHoldTimerDec)
                        }}

                        onTouchStart={(data) => {
                          if (data == "inc") {
                            if (loadDetail?.inquiry_type?.title == "Spot Rate" && (!loadDetail?.locations[0]?.raw_address || !loadDetail?.locations[1]?.raw_address ||
                              !loadDetail?.locations[0]?.contact_name || !loadDetail?.locations[0]?.contact_phone_no ||
                              !loadDetail?.locations[1]?.contact_name || !loadDetail?.locations[1]?.contact_phone_no)) {
                              locationCompRef?.current?.openModal()
                            } else {
                              clickHoldTimerInc = setInterval(() => {
                                if (loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_cross_stuffing_required || !loadDetail?.is_allow_partial_load) {
                                  awardIncrementMultiple({ ...item2, cat_id, total_vehicle, index, showmessage: true })
                                } else {
                                  awardIncrement({
                                    ...item2, cat_id, total_vehicle, index, showmessage: true,
                                    totalRequiredVehicle: Number(total_vehicle),
                                    responded
                                    // totalRequiredVehicle: Number(total_vehicle) - totalAwarded, responded 
                                  });
                                }
                              }, 200);
                            }
                          } else {
                            clickHoldTimerDec = setInterval(() => {
                              awardDecrement({
                                ...item2, cat_id, total_vehicle, index, showmessage: true, totalRequiredVehicle: Number(total_vehicle),
                                responded
                              });
                            }, 200);
                          }
                        }}
                        onTouchEnd={() => {
                          clearInterval(clickHoldTimerInc);
                          clearInterval(clickHoldTimerDec)
                        }}
                      /> : null
                  }
                </>
              // :
              // null

            }

          </div>
        )}

      <div>
        {
          item2?.status_name == "Declined" && item2?.declined_by !== "Poster" && item2?.decline_reason || item2?.decline_reason_other ?
            <div className="formGrid">
              <p className="grayLabel">{`Decline Reason: `} </p> <p className="fw-5">{item2?.decline_reason == "Other" ? item2?.decline_reason_other : item2?.decline_reason}</p>
            </div> : null
        }
      </div>

      {/*bid details for contract and spot qoute by vehicle  */}

      {
        isDeadlineComplete ?
          <>
            {item2?.bid?.bid_negotiated_amount ?
              <div>
                {(item2?.bid?.bid_negotiated_amount && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title=
                    // "Negotiated Amount (Without GST)"
                    {`Negotiated Price / Vehicle (Without GST)`}
                    val={loadDetail?.is_unit_basis ?
                      numeral(item2?.bid?.bid_negotiated_amount).format('0,0.00') + " PKR"
                      : numeral(item2?.bid?.bid_negotiated_amount).format('0,0') + " PKR"
                    }
                  />
                ) : null}
                {(item2?.bid?.bid_negotiated_price_loading && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title={`Negotiated Loading Price / Vehicle (Without GST)`}
                    val={loadDetail?.is_unit_basis ?

                      numeral(item2?.bid?.bid_negotiated_price_loading).format('0,0.00') + " PKR"
                      :
                      numeral(item2?.bid?.bid_negotiated_price_loading).format('0,0') + " PKR"

                    }
                  />
                ) : null}

                {(item2?.bid?.bid_negotiated_price_unloading && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title={`Negotiated Unloading Price / Vehicle (Without GST)`}
                    val={
                      loadDetail?.is_unit_basis ?
                        numeral(item2?.bid?.bid_negotiated_price_unloading).format('0,0.00') + " PKR"
                        :
                        numeral(item2?.bid?.bid_negotiated_price_unloading).format('0,0') + " PKR"

                    }
                  />
                ) : null}

                {(item2?.bid?.bid_negotiated_loading_price && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Loading Price (Without GST)"
                    val={loadDetail?.quote_by == "vehicle" ?
                      numeral(item2?.bid?.bid_negotiated_loading_price).format('0,0') + " PKR"
                      :
                      numeral(item2?.bid?.bid_negotiated_loading_price).format('0,0.00') + " PKR"}
                  />
                ) : null}

                {(item2?.bid?.bid_negotiated_unloading_price && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Unloading Price (Without GST)"
                    val={
                      loadDetail?.quote_by == "vehicle" ?
                        numeral(item2?.bid?.bid_negotiated_unloading_price).format('0,0') + " PKR"
                        :
                        numeral(item2?.bid?.bid_negotiated_unloading_price).format('0,0.00') + " PKR"}
                  />
                ) : null}

                {(item2?.bid?.bid_negotiated_detention_rate && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Negotiated Detention Rate (Without GST)"
                    val={
                      loadDetail?.quote_by == "vehicle"
                        ?
                        numeral(item2?.bid?.bid_negotiated_detention_rate).format('0,0') + " PKR"
                        :
                        numeral(item2?.bid?.bid_negotiated_detention_rate).format('0,0.00') + " PKR"}
                  />
                ) : null}

                {(item2?.bid?.bid_negotiated_detention_tarif_policy && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Negotiated Detention Tariff & Policy"
                    val={item2?.bid?.bid_negotiated_detention_tarif_policy}
                  />
                ) : null}

                {(item2?.bid?.bid_price_per_vehicle && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_price_per_vehicle && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title={`Price / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
                    val={
                      loadDetail?.is_unit_basis ?
                        numeral(Number(item2?.bid?.bid_price_per_vehicle)).format('0,0.00') + " PKR"
                        :
                        numeral(Number(item2?.bid?.bid_price_per_vehicle)).format('0,0') + " PKR"}
                  />
                ) : null}

                {(item2?.bid?.bid_detention_rate && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_detention_rate && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Detention Rate (Without GST)"
                    val={
                      loadDetail?.quote_by == "vehicle" ?
                        numeral(Number(item2?.bid?.bid_detention_rate)).format('0,0') + " PKR" :
                        numeral(Number(item2?.bid?.bid_detention_rate)).format('0,0.00') + " PKR"}
                  />
                ) : null}

                {(item2?.bid?.detention_tarif_policy && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Detention Tariff & Policy"
                    val={item2?.bid?.detention_tarif_policy}
                  />
                ) : null}


                {(item2?.bid?.bid_price_loading && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_price_loading && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title={`Loading Price / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
                    val={
                      loadDetail?.is_unit_basis ?
                        numeral(Number(item2?.bid?.bid_price_loading)).format('0,0.00') + " PKR"
                        :
                        numeral(Number(item2?.bid?.bid_price_loading)).format('0,0') + " PKR"}
                  />
                ) : null}

                {(item2?.bid?.bid_price_unloading && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_price_unloading && loadDetail.transaction_type.title !== "Offer") && (
                  <RenderRow
                    title={`Unloading Price / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
                    val={
                      loadDetail?.is_unit_basis ?

                        numeral(Number(item2?.bid?.bid_price_unloading)).format('0,0.00') + " PKR"
                        :
                        numeral(Number(item2?.bid?.bid_price_unloading)).format('0,0') + " PKR"}
                  />
                )}

                {(item2?.bid?.bid_deposit_fee_amount && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_deposit_fee_amount && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Deposit fee amount (Without GST)"
                    val={
                      loadDetail?.quote_by == "vehicle" ?
                        numeral(Number(item2?.bid?.bid_deposit_fee_amount)).format('0,0') + " PKR"
                        :
                        numeral(Number(item2?.bid?.bid_deposit_fee_amount)).format('0,0.00') + " PKR"}
                  />
                ) : null}

                {(item2?.bid?.bid_detention_additional_charges && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_detention_additional_charges && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Other Additional Charges"
                    val={item2?.bid?.bid_detention_additional_charges}
                  />
                ) : null}
              </div>
              :
              <div>

                {(item2?.bid?.bid_price_per_vehicle && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_price_per_vehicle && loadDetail.transaction_type.title !== "Offer") && (
                  <RenderRow
                    title={`Price / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
                    val={
                      loadDetail?.is_unit_basis ?
                        numeral(Number(item2?.bid?.bid_price_per_vehicle)).format('0,0.00') + " PKR" :

                        numeral(Number(item2?.bid?.bid_price_per_vehicle)).format('0,0') + " PKR"}
                  />
                )}

                {(item2?.bid?.bid_detention_rate && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_detention_rate && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Detention Rate(Without GST)"
                    val={
                      loadDetail?.quote_by == "vehicle" ?
                        numeral(Number(item2?.bid?.bid_detention_rate)).format('0,0') + " PKR" :
                        numeral(Number(item2?.bid?.bid_detention_rate)).format('0,0.00') + " PKR"}
                  />
                ) : null}

                {(item2?.bid?.detention_tarif_policy && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Detention Tariff & Policy"
                    val={item2?.bid?.detention_tarif_policy}
                  />
                ) : null}

                {/* for twin load */}
                {item2?.bid?.bid_price_per_vehicle && loadDetail.transaction_type.title !== "Offer"
                  && final_category?.is_twin_load && loadDetail?.load_type?.title == LOAD_TYPE.fcl ? (
                  <RenderRow
                    title={`Price / Container (Without GST)`}
                    val={numeral(Number(item2?.bid?.bid_price_per_vehicle) / 2).format('0,0') + " PKR"}
                  />
                ) : null}



                {(item2?.bid?.bid_price_loading && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_price_loading && loadDetail.transaction_type.title !== "Offer") && (
                  <RenderRow
                    title={`Loading Price / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
                    val={loadDetail?.is_unit_basis ?
                      numeral(Number(item2?.bid?.bid_price_loading)).format('0,0.00') + " PKR"
                      :
                      numeral(Number(item2?.bid?.bid_price_loading)).format('0,0') + " PKR"}
                  />
                )}

                {(item2?.bid?.bid_price_unloading && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_price_unloading && loadDetail.transaction_type.title !== "Offer") && (
                  <RenderRow
                    title={`Unloading Price / ${loadDetail?.is_unit_basis ? " Item" : " Vehicle"} (Without GST)`}
                    val={
                      loadDetail?.is_unit_basis ?
                        numeral(Number(item2?.bid?.bid_price_unloading)).format('0,0.00') + " PKR"
                        :
                        numeral(Number(item2?.bid?.bid_price_unloading)).format('0,0') + " PKR"}
                  />
                )}

                {(item2?.bid?.bid_deposit_fee_amount && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_deposit_fee_amount && loadDetail.transaction_type.title !== "Offer") ? (
                  <RenderRow
                    title="Deposit fee amount (Without GST)"
                    val={numeral(Number(item2?.bid?.bid_deposit_fee_amount)).format('0,0') + " PKR"}
                  />
                ) : null}

                {/* total price before award when not allow part load, cross stuffing and is contaier deposit  */}

                {
                  (loadDetail?.is_container_deposit_by_transporter || loadDetail?.is_cross_stuffing_required) ?
                    <>
                      {
                        item2?.status == 1 && loadDetail?.transaction_type?.title == "Bid" && loadDetail?.inquiry_type?.title !== "Contract Rate" && (
                          <RenderRow
                            title={"Total Amount (Without GST)"}
                            val={
                              loadDetail?.is_unit_basis ?
                                final_category?.is_twin_load == 1 ?
                                  numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.no_of_vehicles)) +
                                    (Number(item2?.bid?.bid_price_loading) * (Number(item2?.bid?.no_of_vehicles) * 2) || 0) + (Number(item2?.bid?.bid_price_unloading) * (Number(item2?.bid?.no_of_vehicles) * 2) || 0)).format('0,0') + " PKR"
                                  :
                                  numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.no_of_vehicles)) +
                                    (Number(item2?.bid?.bid_price_loading) * Number(item2?.bid?.no_of_vehicles) || 0) + (Number(item2?.bid?.bid_price_unloading) * Number(item2?.bid?.no_of_vehicles) || 0)).format('0,0.00') + " PKR"
                                :

                                final_category?.is_twin_load == 1 ?
                                  numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.no_of_vehicles)) +
                                    (Number(item2?.bid?.bid_price_loading) * (Number(item2?.bid?.no_of_vehicles) * 2) || 0) + (Number(item2?.bid?.bid_price_unloading) * (Number(item2?.bid?.no_of_vehicles) * 2) || 0)).format('0,0') + " PKR"

                                  :
                                  numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.no_of_vehicles)) +
                                    (Number(item2?.bid?.bid_price_loading) * Number(item2?.bid?.no_of_vehicles) || 0) + (Number(item2?.bid?.bid_price_unloading) * Number(item2?.bid?.no_of_vehicles) || 0)).format('0,0') + " PKR"

                            }
                          />
                        )
                      }

                    </> :
                    <>
                      {
                        !loadDetail?.is_allow_partial_load ?
                          <>
                            {
                              item2?.status == 1 && loadDetail?.transaction_type?.title == "Bid" && loadDetail?.inquiry_type?.title !== "Contract Rate" && (
                                <RenderRow
                                  title={"Total Amount (Without GST)"}
                                  val={

                                    loadDetail?.is_unit_basis ?
                                      final_category?.is_twin_load == 1 ?
                                        numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.no_of_vehicles)) +
                                          (Number(item2?.bid?.bid_price_loading) * (Number(item2?.bid?.no_of_vehicles) * 2) || 0) + (Number(item2?.bid?.bid_price_unloading) * (Number(item2?.bid?.no_of_vehicles) * 2) || 0)).format('0,0') + " PKR"
                                        :
                                        numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.no_of_vehicles)) +
                                          (Number(item2?.bid?.bid_price_loading) * Number(item2?.bid?.no_of_vehicles) || 0) + (Number(item2?.bid?.bid_price_unloading) * Number(item2?.bid?.no_of_vehicles) || 0)).format('0,0.00') + " PKR"

                                      :
                                      final_category?.is_twin_load == 1 ?
                                        numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.no_of_vehicles)) +
                                          (Number(item2?.bid?.bid_price_loading) * (Number(item2?.bid?.no_of_vehicles) * 2) || 0) + (Number(item2?.bid?.bid_price_unloading) * (Number(item2?.bid?.no_of_vehicles) * 2) || 0)).format('0,0') + " PKR"

                                        :
                                        numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.no_of_vehicles)) +
                                          (Number(item2?.bid?.bid_price_loading) * Number(item2?.bid?.no_of_vehicles) || 0) + (Number(item2?.bid?.bid_price_unloading) * Number(item2?.bid?.no_of_vehicles) || 0)).format('0,0') + " PKR"
                                  }




                                />
                              )
                            }
                          </> :
                          <>
                            {
                              item2?.status == 1 && loadDetail?.transaction_type?.title == "Bid" && loadDetail?.inquiry_type?.title !== "Contract Rate" && (
                                <RenderRow
                                  title={"Total Amount (Without GST)"}
                                  val={
                                    responded_vehicles > 0 ?
                                      loadDetail?.is_unit_basis ?

                                        final_category?.is_twin_load == 1 ?
                                          numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(responded_vehicles)) +
                                            (Number(item2?.bid?.bid_price_loading) * (Number(responded_vehicles) * 2) || 0) + (Number(item2?.bid?.bid_price_unloading) * (Number(responded_vehicles) * 2) || 0)).format('0,0') + " PKR"

                                          :
                                          numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(responded_vehicles)) +
                                            (Number(item2?.bid?.bid_price_loading) * Number(responded_vehicles) || 0) + (Number(item2?.bid?.bid_price_unloading) * Number(responded_vehicles) || 0)).format('0,0.00') + " PKR"
                                        :

                                        final_category?.is_twin_load == 1 ?
                                          numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(responded_vehicles)) +
                                            (Number(item2?.bid?.bid_price_loading) * (Number(responded_vehicles) * 2) || 0) + (Number(item2?.bid?.bid_price_unloading) * (Number(responded_vehicles) * 2) || 0)).format('0,0') + " PKR"

                                          :
                                          numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(responded_vehicles)) +
                                            (Number(item2?.bid?.bid_price_loading) * Number(responded_vehicles) || 0) + (Number(item2?.bid?.bid_price_unloading) * Number(responded_vehicles) || 0)).format('0,0') + " PKR"
                                      : 0
                                  }
                                />
                              )
                            }
                          </>
                      }

                    </>
                }

              </div>}

            {/* total price after award  */}
            {
              item2?.status == 4 && loadDetail?.transaction_type?.title == "Bid" && loadDetail?.inquiry_type?.title !== "Contract Rate" && (
                <RenderRow
                  title={"Total Amount (Without GST)"}
                  val={
                    loadDetail?.is_unit_basis ?
                      final_category?.is_twin_load == 1 ?
                        numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.awarded_no_of_vehicles)) +
                          (Number(item2?.bid?.bid_price_loading) * (Number(item2?.bid?.awarded_no_of_vehicles) * 2) || 0) + (Number(item2?.bid?.bid_price_unloading) * (Number(item2?.bid?.awarded_no_of_vehicles) * 2) || 0)).format('0,0') + " PKR"
                        :
                        numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.awarded_no_of_vehicles)) +
                          (Number(item2?.bid?.bid_price_loading) * Number(item2?.bid?.awarded_no_of_vehicles) || 0) + (Number(item2?.bid?.bid_price_unloading) * Number(item2?.bid?.awarded_no_of_vehicles) || 0)).format('0,0.00') + " PKR"

                      :
                      final_category?.is_twin_load == 1 ?
                        numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.awarded_no_of_vehicles)) +
                          (Number(item2?.bid?.bid_price_loading) * (Number(item2?.bid?.awarded_no_of_vehicles) * 2) || 0) + (Number(item2?.bid?.bid_price_unloading) * (Number(item2?.bid?.awarded_no_of_vehicles) * 2) || 0)).format('0,0') + " PKR"

                        :
                        numeral((Number(item2?.bid?.bid_price_per_vehicle) * Number(item2?.bid?.awarded_no_of_vehicles)) +
                          (Number(item2?.bid?.bid_price_loading) * Number(item2?.bid?.awarded_no_of_vehicles) || 0) + (Number(item2?.bid?.bid_price_unloading) * Number(item2?.bid?.awarded_no_of_vehicles) || 0)).format('0,0') + " PKR"

                  }
                />
              )
            }

            {(item2?.bid?.bid_detention_additional_charges && loadDetail.transaction_type.title == "Offer") || (item2?.bid?.bid_detention_additional_charges && loadDetail.transaction_type.title !== "Offer") ? (
              <RenderRow
                title="Other Additional Charges"
                val={item2?.bid?.bid_detention_additional_charges}
              />
            ) : null}
          </> : null


      }

      {/*bid details for contract qoute by weight volume */}
      {loadDetail?.inquiry_type?.title == "Contract Rate" &&
        item2?.status == 1 &&
        loadDetail?.quote_by !== "vehicle" && !loadDetail?.is_unit_basis && (
          <div>
            <RenderRow
              title="Price / Freight Ton"
              val={numeral(Number(item2?.bid?.bid_price_per_vehicle)).format('0,0.00') + " PKR"}
            />
            {item2?.bid?.loading_price_per_freight && (
              <RenderRow
                title="Loading Price / Freight Ton"
                val={numeral(Number(item2?.bid?.loading_price_per_freight)).format('0,0.00') + " PKR"}
              />
            )}
            {item2?.bid?.unloading_price_per_freight && (
              <RenderRow
                title="Unloading Price / Freight Ton"
                val={numeral(Number(item2?.bid?.unloading_price_per_freight)).format('0,0.00') + " PKR"}
              />
            )}

            <div className="inputField">
              <div className="label">Freight Ton</div>
              <div className="field">
                <NewInput
                  onChange={(e) => {
                    Award({ e, id: item2?.id });
                  }}
                  id="gross_weight"
                  name="gross_weight"
                  disabled={
                    awarded_contract
                      ? awarded_contract.find(
                        (item) => item?.recip_id == item2?.id
                      )
                        ? false
                        : true
                      : true
                  }
                  value={
                    awarded_contract
                      ? awarded_contract.find(
                        (itm) => itm?.recip_id == item2?.id
                      )?.bid_freight_ton
                      : ""
                  }
                  type="number"
                  aria-describedby="standard-weight-helper-text"
                  showEnd={"Ton"}
                />
              </div>
            </div>
          </div>
        )}

      {/* number of added vehicle by recipients  */}
      {item2?.status == 4 &&
        <div style={{ marginBottom: 10 }}>
          {Array(Number(loadDetail?.is_cross_stuffing_required || loadDetail?.is_unit_basis ? final_category?.vehicles_drivers_refs.length : item2?.bid?.awarded_no_of_vehicles))
            .fill("")
            .map((item, index) => {
              let findAward = final_category?.vehicles_drivers_refs.filter((cat) => cat?.vehicle_no == index + 1 && cat?.recipient_id == item2?.recipient?.id);
              return (
                <div style={{ marginTop: 10 }}>
                  {findAward.map((award, award_index) => {

                    return (
                      <div
                        style={{
                          marginTop: 0,
                          borderBottom: award_index !== findAward.length - 1 ? '1px solid #c0c0cd' : '0px solid white',
                          borderRadius: 0
                        }}
                        onClick={() => {
                          if (award?.status == 1) {
                            triggerMixpanelEvent("View Active Load", "view active load from awarded vehicle list")
                            history.push(
                              `/job-loads/${loadDetail?.id}/active/${award?.id}`, { fromPoster: userRole == "posted" ? true : false }
                            );
                          }
                        }}
                        className={`awarded-vehicle-list ${award?.status == 1 ? "cursor" : "cursor-default"}`}
                      >
                        <div className="flex_row_div">
                          <CustomText.OpenSansSemiBold textStyle={{ marginLeft: 5 }}>Details of Vehicle {index + 1}</CustomText.OpenSansSemiBold>
                          <div style={{ display: "flex", marginRight: 12, alignItems: "center", }}>
                            <CustomText.OpenSansSemiBold
                              textStyle={{ fontSize: "0.688rem", color: award?.status == 1 ? Colors.Primary : Colors.Red, }}>
                              {award?.status == 1 ? "View" : "Completed"}
                            </CustomText.OpenSansSemiBold>
                            <div className="blueRightArrow">
                              <img src={Images.BlueRightArrow} />
                            </div>
                          </div>
                        </div>
                        <div>
                          {
                            award?.is_replaced == 1 && award?.replaced_by !== null ?
                              <div className="vehicleDetailsTextWrap">
                                <div className="fw-5">
                                  Replaced Vehicle
                                </div>
                                {/* <CustomText.OpenSansRegular>
                                  <span>
                                    {
                                      `From- ${award?.replaced_by?.vehicle?.plate_number} ${moment(award?.replaced_by?.created_at).format('DD-MM-YYYY hh:mm A')} To- ${award?.vehicle?.plate_number} ${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')}`
                                    }
                                  </span>
                                </CustomText.OpenSansRegular> */}
                                <CustomText.OpenSansRegular textStyle={{
                                  display: 'flex'
                                }}>

                                  <span className="leftReplaceVehicle">{`From - ${award?.replaced_by?.vehicle?.plate_number} `}
                                    <span>{`${moment(award?.replaced_by?.created_at).format('DD-MM-YYYY hh:mm A')} `}</span>
                                  </span>
                                  <span className="rightReplaceVehicle">{`To - ${award?.vehicle?.plate_number} `}
                                    <span>{`${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')} `}</span>
                                  </span>
                                </CustomText.OpenSansRegular>
                              </div>
                              : null}

                          {award?.is_replaced == 0 ?
                            <div className="vehicleDetailsTextWrap">
                              <div className="fw-5">
                                Plate Number
                              </div>
                              <CustomText.OpenSansRegular>
                                {`${award?.vehicle?.plate_number} (${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')})`}

                              </CustomText.OpenSansRegular>
                            </div>
                            : null}
                          {/* {award?.created_at &&
                            <div className="vehicleDetailsTextWrap">
                              <div className="flexRowWrap">
                                <CustomText.OpenSansRegular textStyle={{
                                  marginLeft: 5
                                }}>
                                  {`${moment(award?.created_at).format('DD-MM-YYYY hh:mm A')}`}
                                </CustomText.OpenSansRegular>
                              </div>
                            </div>
                          } */}


                          {/* {award?.is_replaced &&
                            <div className="vehicleDetailsTextWrap">
                              <div className="flexRowWrap">
                                <CustomText.OpenSansRegular textStyle={{
                                  marginLeft: 5
                                }}>
                                  {`${moment(award?.replaced_by?.created_at).format('DD-MM-YYYY hh:mm A')}`}
                                </CustomText.OpenSansRegular>
                              </div>
                            </div>
                          } */}
                        </div>
                        <div>
                          {
                            // award?.container_ref_one || award?.container_ref_two || award?.no_of_packages ?
                            <div>

                              {award?.container_ref_one ?
                                <div className="vehicleDetailsTextWrap">
                                  <div className="fw-5">
                                    {/* Container Number 1 */}
                                    {`${award?.container_ref_one && award?.container_ref_two ? "Container Number 1 " : "Container Number"}`}
                                  </div>
                                  <CustomText.OpenSansRegular>
                                    <span>
                                      {`${award?.container_ref_one}`}
                                    </span>
                                  </CustomText.OpenSansRegular>
                                </div>
                                : null}

                              {
                                award?.weight_one ?
                                  <div className="vehicleDetailsTextWrap">
                                    <div className="fw-5">
                                      {/* Weight */}
                                      {`${award?.container_ref_one && award?.container_ref_two ? "Weight 1 " : "Weight"}`}
                                    </div>
                                    <CustomText.OpenSansRegular>
                                      <span>{`${numeral(award?.weight_one).format("0,0.00")} Kg`}</span>
                                    </CustomText.OpenSansRegular>
                                  </div>
                                  : null
                              }


                              {
                                award?.container_ref_two ?
                                  <div className="vehicleDetailsTextWrap">
                                    <div className="fw-5">
                                      Container Number 2
                                    </div>
                                    <CustomText.OpenSansRegular>
                                      <span>
                                        {`${award?.container_ref_two}`}
                                      </span>
                                    </CustomText.OpenSansRegular>
                                  </div>
                                  : null
                              }

                              {
                                award?.container_ref_two && award?.weight_two ?
                                  <div className="vehicleDetailsTextWrap">
                                    <div className="fw-5">
                                      Weight 2
                                    </div>
                                    <CustomText.OpenSansRegular>
                                      <span>{`${numeral(award?.weight_two).format("0,0.00")} Kg`}</span>
                                    </CustomText.OpenSansRegular>
                                  </div>
                                  : null
                              }

                              {
                                award?.no_of_packages ?
                                  <div className="vehicleDetailsTextWrap">
                                    <div className="fw-5">
                                      No. of Packages
                                    </div>
                                    <CustomText.OpenSansRegular>
                                      <span>
                                        {`${award?.no_of_packages}`}
                                      </span>
                                    </CustomText.OpenSansRegular>
                                  </div>
                                  : null
                              }
                            </div>
                            // : null
                          }
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              );
            })}
        </div>
      }

      {/* negotiate bid modal  */}
      <NegotiateBid
        showModal={showModal}
        toggleModal={toggleModal}
        bid_negotiated_amount={bid_negotiated_amount}
        set_bid_negotiated_amount={set_bid_negotiated_amount}
        bid_negotiated_detention_rate={bid_negotiated_detention_rate}
        set_bid_negotiated_detention_rate={set_bid_negotiated_detention_rate}
        handleUpdateBid={handleUpdateBid}
        set_negotiated_bid_detention_tarif_policy={set_negotiated_bid_detention_tarif_policy}
        bid_negotiated_detention_tarif_policy={bid_negotiated_detention_tarif_policy}

        bid_negotiated_price_loading={bid_negotiated_price_loading}
        set_bid_negotiated_price_loading={set_bid_negotiated_price_loading}
        bid_negotiated_price_unloading={bid_negotiated_price_unloading}
        set_bid_negotiated_price_unloading={set_bid_negotiated_price_unloading}
        final_category={final_category?.recipients}
        final_category_all={final_category}

        loadDetail={loadDetail}
        item2={item2}
      />
    </div>
  );
};