import React from "react";
import { PrimaryButton } from "@components";
import "./style.scss";

interface EmptyProps{
  title?:string,
  image?:any,
  detail?:string,
  onClick?:()=>void,
  buttonText?:any,
  style?:any
}

const EmptyPlaceholder = ({ title, image, detail, onClick, buttonText}:EmptyProps) => {
  return (
    <div className="dataPlaceholder">
      <div className="placeholderContentWrapper">
        <div className="image">
          <img src={image} />
        </div>
        {title && <p className="placeholderTitle">{title}</p>}
        {detail && detail}
      </div>
      {buttonText && (
        <PrimaryButton
          title={buttonText}
          onClick={() => {
            onClick()
          }}
        />
      )}
    </div>
  );
};

export default EmptyPlaceholder;
