import React, { useState } from "react";
import {
  BackHeader,
  PrimaryButton,
  CustomText,
  SignupContainer,
} from "@components";
import { ProfileAction } from "@temp/store/actions";
import { useDispatch } from "react-redux";
import { Colors } from "@temp/assets";
import "./style.scss";
import { ResetPageProps } from "./types";
import { showMessage } from "@utils/helper";

import {
  validateUpper,
  validateLower,
  validateSpecial,
  validateNum,
  validateNumeric
} from "@utils/helper";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useHistory, useLocation } from "react-router-dom";
import VisibilityOnIcon from "@mui/icons-material/Visibility";

const Page: React.FC<ResetPageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [upperCaseText, setUpperCaseText] = useState(false);
  const [lowerCaseText, setLowerCaseText] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [checkNum, setCheckNum] = useState(false);

  const [passwordChanged, setPasswordChanged] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [checkNumeric, setCheckNumeric] = useState(false);
  const callBack = (data: any) => {
    history.goBack();
  };
  const [OldPasswordType, setOldPasswordType] = useState("password");
  const [NewPasswordType, setNewPasswordType] = useState("password");
  const [RetypePasswordType, setRetypePasswordType] = useState("password");
  

  const toggleOldPassword = () => {
    if (OldPasswordType === "password") {
      setOldPasswordType("text")
      return;
    }
    setOldPasswordType("password")
  }
  const toggleNewPassword = () => {
    if (NewPasswordType === "password") {
      setNewPasswordType("text")
      return;
    }
    setNewPasswordType("password")
  }
  const toggleRetypePassword = () => {
    if (RetypePasswordType === "password") {
      setRetypePasswordType("text")
      return;
    }
    setRetypePasswordType("password")
  }
  const location = useLocation();
  const { state } = location;

  // const handleChange = (event) => {
  //   if (event.target.name === "new-pass") {
  //     let testPass = validatePassword(event.target.value);
  //     if (testPass) {
  //       setUpperCaseText(true);
  //     } else {
  //       setUpperCaseText(false);
  //     }
  //     setPasswordChanged(event.target.value);
  //   } else if (event.target.name === "old-pass") {
  //     setOldPassword(event.target.value);
  //   } else {
  //     setConfirmPassword(event.target.value);
  //   }
  // };

  const handlePassword = (event) => {
    if (event.target.name === "new-pass") {
      let testPass = validateUpper(event.target.value);
      if (testPass) {
        setUpperCaseText(true);
      } else {
        setUpperCaseText(false);
      }
      let testPassLower = validateLower(event.target.value);
      if (testPassLower) {
        setLowerCaseText(true);
      } else {
        setLowerCaseText(false);
      }
      let testPassNum = validateNum(event.target.value);
      if (testPassNum) {
        setCheckNum(true);
      } else {
        setCheckNum(false);
      }
      let testPassNumeric = validateNumeric(event.target.value);
      if (testPassNumeric) {
        setCheckNumeric(true);
      } else {
        setCheckNumeric(false);
      }
      setPasswordChanged(event.target.value);
    } else if (event.target.name === "old-pass") {
      setOldPassword(event.target.value);
    } else {
      setConfirmPassword(event.target.value);
      if(passwordChanged === event.target.value){
        showMessage.current({
          message:"Password Matched",
          status:"success"
        })
      }
    }
  };

  const handleSubmit = () => {
    if (passwordChanged === confirmPassword) {
      const payload = {
        old_Password: oldPassword,
        password: passwordChanged,
        cb: callBack,
      };
      dispatch(ProfileAction.changePassword(payload));
    } else {
      showMessage.current({
        message: 
        // "Passwords do not match",
        "The password doesn't match",
        status: "error",
      });
    }
  };

  return (
    <div className="signup-wrapper reset">
      <BackHeader
        title="Reset Password"
        onClick={() => {
          history.goBack();
        }}
      />
      <SignupContainer containerHeight="">
        <div className="insideafterOTPContainer">
          <div className="resetPasswordWrapper">
            <div
              style={{
                marginTop: 25,
                borderBottom: "0.4px solid #f1f1f1",
              }}
            >
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                  marginTop: 10,
                }}
              >
                Old Password
              </CustomText.OpenSansSemiBold>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                <input
                  value={oldPassword}
                  // onChange={handleChange}
                  onChange={handlePassword}
                  name="old-pass"
                  className="input-pw"
                  type={OldPasswordType}
                  style={{
                    padding: 3,
                  }}
                />
                {
                  OldPasswordType !== "password" ?
                    <VisibilityOnIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={toggleOldPassword} />
                    :
                    <VisibilityOffIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={toggleOldPassword} />
                }
              </div>
            </div>

            <div
              style={{
                borderBottom: "0.4px solid #f1f1f1",
              }}
            >
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                  marginTop: 10,
                }}
              >
                {state?.reset ? "Password" : " New Password"}
              </CustomText.OpenSansSemiBold>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                <input
                  value={passwordChanged}
                  // onChange={handleChange}
                  onChange={handlePassword}
                  name="new-pass"
                  className="input-pw"
                  type={NewPasswordType}
                  style={{
                    padding: 3,
                  }}
                />
                {
                  NewPasswordType !== "password" ?
                    <VisibilityOnIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={toggleNewPassword} />
                    :
                    <VisibilityOffIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={toggleNewPassword} />
                }
              </div>
            </div>
            <div
              style={{
                borderBottom: "0.4px solid #f1f1f1",
              }}
            >
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Grayish,
                  marginTop: 10,
                }}
              >
                Retype New Password
              </CustomText.OpenSansSemiBold>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                <input
                  value={confirmPassword}
                  // onChange={handleChange}
                  onChange={handlePassword}
                  name="confirm-pass"
                  className="input-pw"
                  type={RetypePasswordType}
                  style={{
                    padding: 3
                  }}
                />
                {
                  RetypePasswordType !== "password" ?
                    <VisibilityOnIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={toggleRetypePassword} />
                    :
                    <VisibilityOffIcon style={{ color: "rgb(72,72,72)", height: 20, paddingTop: 5, cursor: 'pointer' }} onClick={toggleRetypePassword} />
                }
              </div>
            </div>

            <div className="characters-wrapper">
              <div className="characters">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {passwordChanged.length > 6 ? (
                    <CheckCircleIcon style={{ color: "rgb(122,194,81)" }} />
                  ) : (
                    <RadioButtonUncheckedIcon
                      style={{ color: "rgb(122,194,81)" }}
                    />
                  )}

                  <CustomText.OpenSansRegular
                    textStyle={{
                      fontSize: "0.75rem",
                      color: Colors.Black,
                      marginLeft: 6,
                      padding: 8,
                    }}
                  >
                    Contain more than 6 characters
                  </CustomText.OpenSansRegular>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {lowerCaseText ? (
                    <CheckCircleIcon style={{ color: "rgb(122,194,81)" }} />
                  ) : (
                    <RadioButtonUncheckedIcon
                      style={{ color: "rgb(122,194,81)" }}
                    />
                  )}

                  <CustomText.OpenSansRegular
                    textStyle={{
                      fontSize: "0.75rem",
                      color: Colors.Black,
                      marginLeft: 6,
                      padding: 8,
                    }}
                  >
                    Contain at least 1 Lowercase
                  </CustomText.OpenSansRegular>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {upperCaseText ? (
                    <CheckCircleIcon style={{ color: "rgb(122,194,81)" }} />
                  ) : (
                    <RadioButtonUncheckedIcon
                      style={{ color: "rgb(122,194,81)" }}
                    />
                  )}

                  <CustomText.OpenSansRegular
                    textStyle={{
                      fontSize: "0.75rem",
                      color: Colors.Black,
                      marginLeft: 6,
                      padding: 8,
                    }}
                  >
                    Contain at least 1 uppercase
                  </CustomText.OpenSansRegular>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {checkNumeric ? (
                    <CheckCircleIcon style={{ color: "rgb(122,194,81)" }} />
                  ) : (
                    <RadioButtonUncheckedIcon
                      style={{ color: "rgb(122,194,81)" }}
                    />
                  )}

                  <CustomText.OpenSansRegular
                    textStyle={{
                      fontSize: "0.75rem",
                      color: Colors.Black,
                      marginLeft: 6,
                      padding: 8,
                    }}
                  >
                    Contain at least 1 Numeric digit
                  </CustomText.OpenSansRegular>
                </div>
              </div>
            </div>
          </div>
          <div className="bottomSignupScreen">
            <div className="insideSignupBtnWrap">
              <PrimaryButton
                title="Update Password"
                disabled={!upperCaseText || !confirmPassword || !lowerCaseText  || !passwordChanged || !checkNumeric 
                  || passwordChanged !== confirmPassword
                }
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </SignupContainer>
    </div>
  );
};

export default Page;
