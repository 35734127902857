import { put, call } from "redux-saga/effects";
import ApiCaller from "@temp/api/api";
import { ResponseGenerator } from "@temp/api/globalTypes";
import { AuthAction } from "../actions";
import { getItem, showMessage, updateLoadCountFlag } from "@utils/helper";
import Endpoints from "../endpoints";
export class AuthSaga {
  static *LogInWihtOtp(params: any) {
    const { payload } = params;
    const final_payload = {
      phone_code: "92",
      phone_no: payload.phoneNumber,
      otp_method: payload.type,
      type: payload.from,
      login_type: "IU",
      mnp: payload?.mobileNetwork?.mobile_networks
    };
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.LOGIN_WITH_OTP,
      final_payload,
      { "content-type": "application/json" }
    );
    if (response?.status === 200) {
      yield put({ type: AuthAction.LOGIN_WITH_OTP_SUCCESS });
      if (payload?.delete_from) {
        payload?.deleteCallBack()
      } else {
        if (payload?.cb) {
          payload.cb({
            phoneNumber: payload.phoneNumber,
            whatsapp: payload.whatsapp,
            type: payload.type,
            from: payload.from,
            mobileNetwork: payload?.mobileNetwork,
            ...response.data.data,
          });
        }
      }

    } else if (response?.status === 403) {
      payload.cb({
        forbidden: true,
      });
      yield put({ type: AuthAction.LOGIN_WITH_PASSWORD_FAIL });
    } else {
      yield put({ type: AuthAction.LOGIN_WITH_OTP_FAIL });
    }
  }

  static *LogInWithPassword(params: any) {
    const { payload } = params;
    const final_payload = {
      phone_code: "92",
      phone_no: payload.phoneNumber,
      password: payload.password,
      login_type: "IU",
      mobile_networks: payload?.mobileNetwork

    };

    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.LOGIN_WITH_PASSWORD,
      final_payload,
      { "content-type": "application/json", login: "true" }
    );
    if (response?.status === 200) {
      yield put({ type: AuthAction.LOGIN_WITH_PASSWORD_SUCCESS });
      payload.cb({
        phoneNumber: payload.phoneNumber,
        type: payload.type,
        from: payload.from,
        mobileNetwork: payload?.mobileNetwork,
        ...response?.data?.data,
      });
      if (response?.data?.data?.isLoggedOutFromAllDevices) {
        yield put({ type: AuthAction.LOGOUT_FROM_ALL_DEVICES, payload: true })
      }
    } else if (response?.status === 403) {
      payload.cb({
        forbidden: true,
      });
      yield put({ type: AuthAction.LOGIN_WITH_PASSWORD_FAIL });
    } else {
      yield put({ type: AuthAction.LOGIN_WITH_PASSWORD_FAIL });
    }
  }

  static *SignUp(params: any) {
    const { payload } = params;
    const final_payload = {
      name: payload.fullName,
      name_friendly: payload.aliasName,
      email: payload.email,
      company_name: payload.companyName,
      user_professions: [...payload?.user_professions],
      city_id: payload?.city_id,
      password: payload.password,
      phone_code: "92",
      phone_no: payload?.phoneNumber ? payload.phoneNumber : "",
      cnic_front_url_encoded: payload.front_image ? payload.front_image : "",
      cnic_back_url_encoded: payload.back_image ? payload.back_image : "",
      whatsapp_phone_no: payload.whatsapp,
      whatsapp_phone_code: "92",
      login_type: "IU",
      mnp: payload?.mobileNetwork ? payload?.mobileNetwork?.mobile_networks : ""
    };

    const formData = new FormData();
    Object.keys(final_payload).forEach((key, index) => {
      if (key == "user_professions" && Object.values(final_payload)[index]?.length) {
        Object.values(final_payload)[index]?.forEach((item) => {
          formData.append("user_professions[]", item);
        })
      } else {
        formData.append(key, Object.values(final_payload)[index]);

      }

    });
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      "auth/register",
      formData,
      {
        "content-type": "multipart/form-data",
      }
    );
    if (response?.status === 200) {
      yield put({ type: AuthAction.SIGNUP_SUCCESS });
      payload?.cb({ ...response?.data });
    } else {
      yield put({ type: AuthAction.SIGNUP_FAIL });
    }
  }

  static *VerifyCode(params: any) {
    const { payload } = params;
    const final_payload = {
      phone_code: "92",
      phone_no: payload.phoneNumber,
      otp_method: payload.type,
      otp_code: payload.otpCode,
      login_type: "IU",
      mobile_networks: payload.mobileNetwork

    };
    let payload_forgot_pass = {
      phone_code: "92",
      phone_no: payload.phoneNumber,
      otp_method: payload.type,
      otp_code: payload.otpCode,
      type: "password_changed",
      login_type: "IU",
      mobile_networks: payload.mobileNetwork
    };

    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.VERIFY_CODE,
      payload?.isForgot ? payload_forgot_pass : final_payload,
      { "content-type": "application/json" }
    );
    if (response.status === 200) {
      yield put({ type: AuthAction.VERIFY_CODE_SUCCESS });
      payload.cb({
        phoneNumber: payload.phoneNumber,
        mobileNetwork: payload.mobileNetwork,
        whatsapp: payload.whatsapp,
        type: payload.type,
        from: payload.from,
        ...response.data.data,
      });
      if (response?.data?.data?.isLoggedOutFromAllDevices) {
        yield put({ type: AuthAction.LOGOUT_FROM_ALL_DEVICES, payload: true })
      }
    } else {
      yield put({ type: AuthAction.VERIFY_CODE_FAIL });
    }
  }

  static *updateTerm(params: any) {
    const { payload } = params;
    const token = getItem("token");
    const final_payload = {
      is_toc_accepted: 1,
      login_type: "IU",
    };
    const response: ResponseGenerator = yield call(
      ApiCaller.Patch,
      Endpoints.UPDATE_TERMS,
      final_payload,
      { "content-type": "application/json", Authorization: `Bearer ${token}` }
    );
    if (response?.status === 200) {
      yield put({ type: AuthAction.VERIFY_CODE_SUCCESS });
      payload.cb({
        ...response?.data?.data,
      });
    } else {
      yield put({ type: AuthAction.VERIFY_CODE_FAIL });
    }
  }

  static *logOut(params: any) {
    const { payload } = params;
    const token = getItem("token");
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.LOGOUT,
      { ...payload },
      { "content-type": "application/json", Authorization: `Bearer ${token}` }
    );
    if (response?.status === 200) {
      yield put({ type: AuthAction.LOG_OUT_SUCCESS });
      payload.cb();
    } else {
      yield put({ type: AuthAction.LOG_OUT_FAIL });
    }
  }

  static *getMetaData(params: any) {
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_META_DATA,
      {
        "content-type": "application/json",
      }
    );
    if (response?.status === 200) {
      yield put({
        type: AuthAction.GET_META_DATA_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({ type: AuthAction.GET_META_DATA_FAIL });
    }
  }

  static *setupPassword(params: any) {
    const token = getItem("token");
    const tem_token = getItem("temp_token")
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Patch,
      Endpoints.SETUP_PASSWORD,
      payload,
      { Authorization: `Bearer ${tem_token || token}` }
    );


    if (response?.status === 200) {
      yield put({ type: AuthAction.SETUP_PASSWORD_SUCCESS });
      showMessage.current({ message: "Password reset successfully.", status: 'success' })
      setTimeout(() => {
        payload?.cb();
      }, 1000);
    } else {
      yield put({ type: AuthAction.SETUP_PASSWORD_FAIL });
    }
  }

  static *forgotPassword(params: any) {
    const { payload } = params;
    const final_payload = {
      otp_method: payload.type,
      phone_code: "92",
      phone_no: payload.phoneNumber,
      mobile_networks: payload?.mobileNetwork,
      login_type: "IU",
    };
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.FORGOT_PASSWORD,
      final_payload
    );

    if (response?.status === 200) {
      yield put({ type: AuthAction.FORGOT_PASSWORD_SUCCESS });
      payload?.cb({
        type: payload.type,
        phoneNumber: payload.phoneNumber,
        mobileNetwork: payload?.mobileNetwork,
      });
    } else {
      yield put({ type: AuthAction.FORGOT_PASSWORD_FAIL });
    }
  }

  static *getLoadsCount(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const response: ResponseGenerator = yield call(
      ApiCaller.Get,
      Endpoints.GET_LOADS_COUNT,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status === 200) {
      yield put({
        type: AuthAction.GET_LOADS_COUNT_SUCCESS,
      });
      if (payload?.cb) {
        payload.cb(response?.data);
        updateLoadCountFlag();
      }
    } else {
      yield put({ type: AuthAction.GET_LOADS_COUNT_FAIL });
    }
  }

  static *LogInFirst(params: any) {
    const { payload } = params;
    const final_payload = {
      phone_code: "92",
      phone_no: payload.phone_no,
      type: payload.from,
      login_type: "IU",
    };
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.FIRST_LOG_IN,

      { "content-type": "application/json", ...final_payload }
    );
    if (response?.status === 200) {
      yield put({ type: AuthAction.FIRST_LOGIN_SUCCESS });
      payload.firstTimeLogInCallBack(response?.data?.data);
    } else if (response?.status == 422) {
      yield put({ type: AuthAction.FIRST_LOGIN_SUCCESS });
      payload.firstTimeLogInCallBack({ not_found: true });
    } else {
      yield put({ type: AuthAction.FIRST_LOGIN_FAIL });
    }
  }

  static *DelAccount(params: any) {
    const token = getItem("token");
    const { payload } = params;
    const final_payload = {
      phone_code: "92",
      login_type: "IU",
    };
    const response: ResponseGenerator = yield call(
      ApiCaller.Post,
      Endpoints.DELETE_ACCOUNT_URL,
      payload,
      {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    );
    if (response?.status == 204 || response?.status == 200) {
      yield put({
        type: AuthAction.DEL_ACCOUNT_SUCCESS,
      });
      showMessage.current({ message: 'Account deleted successfully', status: 'success' })
      if (payload?.callBackDelete) {
        setTimeout(() => {
          payload?.callBackDelete()
        }, 500);
      }
    } else {
      yield put({
        type: AuthAction.DEL_ACCOUNT_FAIL,
      });
    }
  }
}
