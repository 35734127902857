import React, { FC } from 'react'
// import { makeStyles, Theme, createStyles } from '@mui/material//styles'
import { SelectionModalProps } from './types'
import Modal from '@mui/material//Modal'
import { Button } from '@mui/material/'
import Grid from '@mui/material//Grid'
import { isBrowser } from "react-device-detect";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     paper: {
//       position: "absolute",
//       maxWidth: 290,
//       width: '100%',
//       backgroundColor: theme.palette.background.paper,
//       boxShadow: theme.shadows[5],
//       padding: theme.spacing(2, 2, 2),
//       borderRadius: "4px",
//       maxHeight: 250,
//       overflowY: "auto",
//     },
//   })
// );

export const SelectionModal: FC<SelectionModalProps> = ({
  showModal,
  handleModal,
  title,
  data,
  type,
  onClick,
  handleUploadImage,
  handleCamrea,
  handleDoc,
  handleGallery,
}: SelectionModalProps) => {
//   const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} 
	// className={`${classes.paper} optionModal`}
  className="optionModal"
	>
      <h2
        style={{
          marginBottom: 20,
          fontSize: "1.125rem",
          color: "rgb(13 21 9)",
        }}
      >
        {title}
      </h2>
      {type === "document" &&
        data.map((value: any,index) => (
          <Button
            key={index}
            onClick={() => {
              onClick(value);
            }}
            style={{
              border: "none",
              background: "transparent",
              boxShadow: "none",
            }}
          >
            <div style={{ display: "flex", marginBottom: 15 }}>
              {value.icon ? <img src={value.icon} width={20} height={20}></img> : null}
              {value.iconClass ? <span className={`${value.iconClass} iconClass`}></span> : null}
              <div
                style={{
                  marginLeft: 15,
                  fontSize: "0.938rem",
                }}
              >
                {value.title}
              </div>
            </div>
          </Button>
        ))}
      <Grid style={{ display: "flex" }} className="fileUploadGridItems">
        {type === "media" &&
          data.map((value: any, index: any) => {
            return (
              <Grid
                key={index}
                item
                xs={4}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                {
                  <input
                    style={{ display: "none" }}
                    id={"raised-button-file" + index}
                    type="file"
                    onChange={(e) => {
                      if (value.title !== "Camera") {
                        handleUploadImage(e, value)
                      }
                    }}
                  />
                }
                <label
                  htmlFor={isBrowser?"raised-button-file" + index:""}
                >
                  <Button
                    onClick={()=>{
                      if(value.title == "Camera"){
                        handleCamrea(value)
                      }else if(value?.title == 'Gallery'){

                        handleGallery(value)
                      }else if(value?.title == "Document"){

                        handleDoc(value)
                      }
                    }}
                    disabled={isBrowser && value.key == 0 ? true : false}
                    // variant="raised"
                    component="span"
                    style={{ padding: "5px 10px" }}
                  >
                    <div>
                      <div className='iconWrapper'>
                        {value.icon ? 
                          <img
                          src={value.icon}
                          style={{ marginBottom: 20 }}
                          height={30}
                        ></img> : null}
                        {value.iconClass ? <span className={`${value.iconClass} ${value.title == 'Camera' ? 'camera' : ''} iconClass`}></span> : null}
                      </div>
                      <div className="title">
                        {value.title}
                      </div>
                    </div>
                  </Button>
                </label>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );

  return (
    <React.Fragment>
      <Modal
        onClose={() => handleModal(false)}
        aria-describedby="simple-modal-description"
        open={showModal}
      >
        <div> {body}</div>
      </Modal>
    </React.Fragment>
  );
};