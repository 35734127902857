/*global google*/
import { CustomText, PrimaryButton, OutLineButton, SimpleCheckBox, SimpleInput } from "@temp/components";
import React, { FC, useEffect, useState } from "react";
import RoomIcon from "@mui/icons-material/Room";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material/";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector, useDispatch } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import { useHistory } from "react-router";
import TitleLayout from "@temp/components/TitleLayout";
import { parse } from "query-string";
import { DrawerAction } from "@temp/store/actions";

import { ExpandMore } from "@mui/icons-material";
import { LOAD_TYPE } from "@temp/constants";
import { css } from "@emotion/css";
export const AddAddress: FC = (props: any) => {
  const [search, setSearch] = useState("");
  const [index, setIndex] = useState(null);
  const history = useHistory();

  const [tempLocation, setTempLocation] = useState([]);
  const [tempLocationDrop, setTempLocationDrop] = useState([])
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsDrop, setSuggestionsDrop] = useState([]);
  const [locationTitle, setTitle] = useState("");
  const [pickupDetails, setPickupDetails] = useState("");
  const [dropoffDetails, setDropoffDetails] = useState("");
  const [data, setData] = useState(null);
  const [dataDrop, setDataDrop] = useState(null);

  const [selected, setSelected] = useState({});
  const postLoadMeta = useSelector((state) => state?.Profile?.postLoadMeta);
  const PostLoad = useSelector((state) => state?.Profile?.postLoad);
  const queryParams = parse(history.location.search);
  const SavePickupLocations = postLoadMeta?.locations?.pickup || [];
  const SaveDropoffLocations = postLoadMeta?.locations?.dropoff || [];
  const SaveCompanyPickupLocations =
    postLoadMeta.company_locations.pickup || [];
  const SaveCompanyDropoffLocations =
    postLoadMeta.company_locations.dropoff || [];

  const [pickup_accordion, set_pickup_accordion] = useState(false)
  const [dropoff_accordion, set_dropoff_accordion] = useState(false)

  const [searchDrop, setSearchDrop] = useState("");
  const [locationTitleDrop, setTitleDrop] = useState("");
  const [selectedDrop, setSelectedDrop] = useState({});
  const [is_empty_return, setEmpty] = useState(false);
  const [empty_return_yard_name, setYard] = useState("");

  const pageTitle = css({
    color: "rgb(13 21 9)",
    fontSize: "0.875rem",
    marginTop: 20,
    marginBottom: 10,
  });

  useEffect(() => {
    setSuggestions([...SavePickupLocations, ...SaveCompanyPickupLocations]);
    setSuggestionsDrop([...SaveDropoffLocations, ...SaveCompanyDropoffLocations]);
  }, [SavePickupLocations, SaveDropoffLocations])

  useEffect(() => {
    const id = props.match.params.index;

    if (PostLoad?.location.length) {
      setSearch(PostLoad?.location[0].address);
      setTitle(PostLoad?.location[0].title);
      setPickupDetails(PostLoad?.location[0].description);
      setSelected({ lat: PostLoad?.location[0].lat, lng: PostLoad?.location[0].lng });
      //drop
      if (PostLoad?.location?.length > 1) {
        setSearchDrop(PostLoad?.location[1].address);
        setTitleDrop(PostLoad?.location[1].title);
        setDropoffDetails(PostLoad?.location[1]?.description);
        setSelectedDrop({ lat: PostLoad?.location[1].lat, lng: PostLoad?.location[1].lng });
      }

      if (PostLoad?.is_empty_return)
        setEmpty(PostLoad?.is_empty_return);

      if (PostLoad?.empty_return_yard_name)
        setYard(PostLoad?.empty_return_yard_name);
    }
  }, []);

  const getCity = (formatted_address: any) => {
    var itemLocality = null;

    if (formatted_address.length) {
      formatted_address.forEach((address: any) => {
        if (address.types[0] == "locality") {
          itemLocality = address.long_name;
        }
      });
    }
    return itemLocality;
  };

  const dispatch = useDispatch();
  const handleAutoComplete = (e) => {
    setSearch(e.target.value);
    var element = document.getElementById("map-address") as HTMLInputElement;
    let loc = PostLoad.location.length ? PostLoad.location : [];
    if (loc[0]?.lat && loc[0]?.lng) {
      loc[0].address = element.value;
      setTempLocation(loc);
    }
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "formatted_address", "geometry"],
        strictBounds: false,
        types: ["establishment"],
      };

      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        // let new_address = autocomplete?.gm_accessors_?.place?.Fi?.formattedPrediction;
		let new_address = place?.formatted_address

        if (place) {
          getCity(place);
        }
        if (!place.geometry || !place.geometry.location) {
          return;
        } else {
          var coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          const lat = coordinates?.lat;
          const lng = coordinates?.lng;
          const raw_address = new_address;

          if (!loc[0]) {
            loc.push({
              address: null,
              lat: null,
              lng: null,
              title: null,
              timestamp: null,
              description: null,
            });
          }
          setSearch(raw_address);
          if (lat && lng) {
            setSelected({ lat, lng });
          } else {
            setSelected({});
          }

          loc[0].lat = lat;
          loc[0].lng = lng;
          loc[0].address = raw_address;
          loc[0].title = locationTitle;
          loc[0].description = pickupDetails;
          const city = getCity(place.address_components);

          setData({
            lat,
            long: lng,
            raw_address,
            city,
            model_name: "user",
            type: "pickup",
          });

          dispatch(
            ProfileAction.postLoadData({
              location: [...loc],
            })
          );
        }
      });
    }
  };

  //dropoff
  const handleAutoCompleteDrop = (e) => {
    setSearchDrop(e.target.value);
    var element = document.getElementById("map-address-drop") as HTMLInputElement;
    let loc = PostLoad.location.length ? PostLoad.location : [];
    if (loc[1]?.lat && loc[1]?.lng) {
      loc[1].address = element.value;
      setTempLocationDrop(loc);
    }
    if (element) {
      const options = {
        componentRestrictions: { country: "pk" },
        fields: ["address_components", "formatted_address", "geometry"],
        strictBounds: false,
        types: ["establishment"],
      };

      const autocomplete = new window.google.maps.places.Autocomplete(
        element,
        options
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        // let new_address = autocomplete?.gm_accessors_?.place?.Fi?.formattedPrediction;
		let new_address = place?.formatted_address

        if (place) {
          getCity(place);
        }
        if (!place.geometry || !place.geometry.location) {
          return;
        } else {
          var coordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          const lat = coordinates?.lat;
          const lng = coordinates?.lng;
          const raw_address = new_address;

          if (!loc[1]) {
            loc.push({
              address: null,
              lat: null,
              lng: null,
              timestamp: null,
              description: null,
              title: null,

            });
          }
          setSearchDrop(raw_address);
          if (lat && lng) {
            setSelectedDrop({ lat, lng });
          } else {
            setSelected({});
          }
          const city = getCity(place.address_components);

          loc[1].lat = lat;
          loc[1].lng = lng;
          loc[1].address = raw_address;
          loc[1].title = locationTitleDrop;
          loc[1].description = dropoffDetails;

          setDataDrop({
            lat,
            long: lng,
            raw_address,
            city,
            model_name: "user",
            type: "dropoff",

          });

          dispatch(
            ProfileAction.postLoadData({
              location: [...loc],
            })
          );
        }
      });
    }
  };


  let title = Number(index) === 0 ? "Pickup Location" : "Dropoff Location";

  const load_type = postLoadMeta.load_types.find(
    (val: object) => val.id === PostLoad.load_type
  );

  return (
    <TitleLayout
      pageNumber={6}
      progress={60}
      title={`Add Location - ${load_type?.title}`}
      screen="addAddress"
    >
      <div className="addLocationsWrapper">
        <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.813rem", marginBottom: 5 }}>Pickup Location</CustomText.OpenSansSemiBold>
        <div className="searchContainer" style={{ width: "100%" }}>
          <input
            className="searchInput"
            onChange={(e) => {
              setTitle(e.target.value);
              let loc = PostLoad.location
              loc[0].title = e.target.value;

              dispatch(
                ProfileAction.postLoadData({
                  location: [...loc],
                }))
            }}
            type="text"
            autoComplete="off"
            name="search"
            style={{ width: "100%" }}
            value={locationTitle}
            placeholder="Enter your pickup location name"
          />
        </div>
        <div className="searchContainer" style={{ width: "100%" }}>
          <input
            className="searchInput"
            id="map-address"
            onChange={handleAutoComplete}
            type="text"
            autoComplete="off"
            name="search"
            style={{ width: "100%" }}
            value={search}
            placeholder="Search for your exact address"
          />
          {search !== "" && (
            <ClearIcon
              onClick={() => {
                setSearch("");
                setSelected({});
              }}
              style={{
                cursor: "pointer",
                color: "rgb(88,156,213)",
                marginRight: 10,
              }}
            />
          )}
        </div>
        <div className="searchContainer" style={{ width: "100%" }}>
          <input
            className="searchInput"
            onChange={(e) => {
              setPickupDetails(e.target.value);
            }}
            type="text"
            autoComplete="off"
            name="search"
            style={{ width: "100%" }}
            value={pickupDetails}
            placeholder="Add additional pickup details"
          />
        </div>

        <div
          className="addressAccordion"
          style={{
            width: "100%",
            overflowY: "scroll",
            marginBottom: 10
          }}
        >
          <Accordion
            onChange={() => set_pickup_accordion(!pickup_accordion)}
            expanded={pickup_accordion}
          >
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel-header">
              <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.813rem" }}>Select From Saved Addresses</CustomText.OpenSansSemiBold>
            </AccordionSummary>
            <AccordionDetails>
              <div className={`faqas-item${index}`}>
                {[...suggestions, ...suggestionsDrop]?.length ?
                  <>
                    {
                      [...suggestions, ...suggestionsDrop]?.map((val) => {
                        return (
                          <div>
                            <Button
                              onClick={() => {
                                const { raw_address, long, lat, title, description } = val;
                                let loc = PostLoad.location.length ? PostLoad.location : [];
                                if (!loc[0]) {
                                  loc.push({
                                    address: null,
                                    lat: null,
                                    lng: null,
                                    timestamp: null,
                                    description: null,
                                    title: null
                                  });
                                }
                                setSearch(raw_address);
                                setSelected({ lat: lat, lng: long });
                                loc[0].lat = Number(lat);
                                loc[0].lng = Number(long);
                                loc[0].address = raw_address;
                                loc[0].title = title;
                                loc[0].description = description;
                                setTitle(val?.title || title || "");

                                dispatch(
                                  ProfileAction.postLoadData({
                                    location: [...loc],
                                  })
                                );
                                set_pickup_accordion(!pickup_accordion)
                              }}
                            >
                              <div style={{ display: "flex", paddingBottom: 5 }}>
                                <div>
                                  <RoomIcon style={{ color: "rgb(88, 156, 213)" }} />
                                </div>
                                <div>
                                  <div
                                    style={{
                                      color: "black",
                                      marginLeft: 6,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {val?.title || ""}
                                  </div>
                                  <div
                                    style={{
                                      color: "rgb(174, 177, 173)",
                                      marginLeft: 6,
                                    }}
                                  >
                                    {val.raw_address}
                                  </div>
                                </div>
                              </div>
                            </Button>
                          </div>
                        );
                      })
                    }</> :
                  <>
                    <CustomText.OpenSansRegular>No saved Locations found</CustomText.OpenSansRegular>
                  </>
                }
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div style={{
          display: "flex",
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <OutLineButton
            type={"submit"}
            title="Add to address book"
            buttonStyle={{
              height: 30,
              marginTop: 20,
              alignItems: "center",
              width: '100%',
              padding: 23
            }}
            disabled={![...suggestions, ...suggestionsDrop]?.find((item) => item?.raw_address == search)?.id && selected?.lat && selected?.lng && locationTitle ? false : true}
            onClick={() => {
              if (data?.lat && data?.long) {
                dispatch(
                  DrawerAction.addLocation({
                    data: { ...data, title: locationTitle }
                  }))
              }
            }
            }
          />
        </div>

        <div style={{ marginTop: 30 }}></div>
        <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.813rem", marginBottom: 5 }}>Drop Off Location</CustomText.OpenSansSemiBold>

        <div className="searchContainer" style={{ width: "100%" }}>
          <input
            className="searchInput"
            // onChange={(e) => {
            //   setTitleDrop(e.target.value);
            // }}
            onChange={(e) => {
              setTitleDrop(e.target.value);
              let loc = PostLoad.location
              loc[1].title = e.target.value;

              dispatch(
                ProfileAction.postLoadData({
                  location: [...loc],
                }))
            }}
            type="text"
            autoComplete="off"
            name="search"
            style={{ width: "100%" }}
            value={locationTitleDrop}
            placeholder="Enter your dropoff location name"
          />
        </div>

        <div className="searchContainer" style={{ width: "100%" }}>
          <input
            className="searchInput"
            id="map-address-drop"
            onChange={handleAutoCompleteDrop}
            type="text"
            autoComplete="off"
            name="search"
            style={{ width: "100%" }}
            value={searchDrop}
            placeholder="Search for your exact address"
          />
          {searchDrop !== "" && (
            <ClearIcon
              onClick={() => {
                setSearchDrop("");
                setSelectedDrop({});
              }}
              style={{
                cursor: "pointer",
                color: "rgb(88,156,213)",
                marginRight: 10,
              }}
            />
          )}
        </div>
        <div className="searchContainer" style={{ width: "100%" }}>
          <input
            className="searchInput"
            onChange={(e) => {
              setDropoffDetails(e.target.value);
            }}
            type="text"
            autoComplete="off"
            name="search"
            style={{ width: "100%" }}
            value={dropoffDetails}
            placeholder="Add additional dropoff details"
          />
        </div>

        <div
          className="addressAccordion"
          style={{
            width: "100%",
            overflowY: "scroll",
            marginBottom: 10
          }}
        >
          <Accordion
            onChange={() => set_dropoff_accordion(!dropoff_accordion)}
            expanded={dropoff_accordion}
            style={{
              backgroundColor: "#fafafa"
            }}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel-header">
              <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.813rem" }}>Select From Saved Addresses</CustomText.OpenSansSemiBold>
            </AccordionSummary>
            <AccordionDetails>
              <div className={`faqas-item${index}`}>
                {[...suggestions, ...suggestionsDrop]?.length ?

                  <>
                    {
                      [...suggestions, ...suggestionsDrop]?.map((val) => {
                        return (
                          <div>
                            <Button
                              onClick={() => {
                                const { raw_address, long, lat, title, description } = val;
                                let loc = PostLoad.location.length ? PostLoad.location : [];

                                if (!loc[1]) {
                                  loc.push({
                                    address: null,
                                    lat: null,
                                    lng: null,
                                    timestamp: null,
                                    description: null,
                                    title: null,
                                  });
                                }
                                setSearchDrop(raw_address);
                                setSelectedDrop({ lat: lat, lng: long });
                                loc[1].lat = Number(lat);
                                loc[1].lng = Number(long);
                                loc[1].address = raw_address;
                                loc[1].title = title;
                                loc[1].description = description;
                                setTitleDrop(title);
                                dispatch(
                                  ProfileAction.postLoadData({
                                    location: [...loc],
                                  })
                                );
                                set_dropoff_accordion(!dropoff_accordion)
                              }}
                            >
                              <div style={{ display: "flex", paddingBottom: 5 }}>
                                <div>
                                  <RoomIcon style={{ color: "rgb(88, 156, 213)" }} />
                                </div>
                                <div>
                                  <div
                                    style={{
                                      color: "black",
                                      marginLeft: 6,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {val?.title || ""}
                                  </div>
                                  <div
                                    style={{
                                      color: "rgb(174, 177, 173)",
                                      marginLeft: 6,
                                    }}
                                  >
                                    {val.raw_address}
                                  </div>
                                </div>
                              </div>
                            </Button>
                          </div>
                        );
                      })
                    }
                  </> :
                  <>
                    <CustomText.OpenSansRegular>No saved Locations found</CustomText.OpenSansRegular>
                  </>
                }
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={{
          display: "flex",
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <OutLineButton
            type={"submit"}
            title="add to address book"
            buttonStyle={{
              height: 30,
              alignItems: "center",
              marginBottom: 20,
              width: '100%',
              padding: 23
            }}
            disabled={![...suggestions, ...suggestionsDrop]?.find((item) => item?.raw_address == searchDrop)?.id && selectedDrop?.lat && selectedDrop?.lng && locationTitleDrop ? false : true}
            onClick={() => {
              if (dataDrop?.lat && dataDrop?.long) {
                dispatch(
                  DrawerAction.addLocation({
                    data: { ...dataDrop, title: locationTitleDrop }
                  }))
              }
            }
            }
          />
        </div>
        {load_type.title === LOAD_TYPE.fcl && (
          <SimpleCheckBox
            checked={is_empty_return}
            handleChange={(e) => {
              if (!e.target.checked) {
                setYard("");
              }
              setEmpty(e.target.checked);
            }}
            labelName={"Empty Pickup or Return Yard / City"}
            title={"Empty Pickup or Return Yard / City"}
          />
        )}
        {is_empty_return && (
          <div>
            <div className={pageTitle} style={{ marginTop: 0 }}>
              <b>Name of Yard/City </b>
            </div>

            <div
              style={{
                backgroundColor: "rgb(241 241 241)",
                height: 45,
                borderRadius: 10,
                marginTop: 5,
              }}
            >
              <SimpleInput
                isPKR={false}
                borderColor="rgb(204 204 204)"
                value={empty_return_yard_name}
                placeholder="Enter Name of Yard/City"
                onChange={(e) => {
                  setYard(e.target.value);
                }}
              />
            </div>
          </div>
        )}

      </div>
      <PrimaryButton
        buttonStyle={{
          marginBottom: 15
        }}
        rightIcon={true}
        title="Confirm Location details"
        type="submit"
        disabled={selectedDrop?.lat && selectedDrop?.lng && selected?.lat && selected?.lng && locationTitleDrop && locationTitle ? false : true}
        onClick={() => {
          PostLoad.location.forEach((element, index) => {
            if (index == 0) {
              element.description = pickupDetails
            } else {
              element.description = dropoffDetails
            }
          });
          dispatch(
            ProfileAction.postLoadData({
              location: [...PostLoad.location],
              is_empty_return,
              empty_return_yard_name,
            })
          );
          history.push('/response-deadline')
        }}
      />
    </TitleLayout>
  );
};
