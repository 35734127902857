import React, { FC } from "react";
import { BackHeader, Progress_bar } from "@components";
import { Colors } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { IProps } from "./types";
import { useSelector } from "react-redux";

const ScreenHeader: FC<any> = ({
  title,
  progress,
  progressFlag,
  bottomTitle,
  screen,
  titleOnly,
  titleOnlyText,
  contract,
  extraComponent,
  fromNoti = false
}: any) => {
  const history = useHistory();
  const PostLoad = useSelector((state) => state?.Profile?.postLoad);
  return (
    <>
      <div className="topbarWrapper">
        {titleOnly && !progressFlag ? (
          <BackHeader
            onClick={() => {
              if(fromNoti){
                history.go(-2)
              }else{
                history.goBack();
              }
            }}
            title={titleOnlyText}
          />
        ) : progressFlag ? (
          <>
          <BackHeader
              onClick={() => {
                history.goBack();
              }}
              title={titleOnlyText}
            />
            {progressFlag && (
              <div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <Progress_bar
                    bgcolor={Colors.Primary}
                    progress={progress}
                    height={10}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <BackHeader
              onClick={() => {
                history.goBack();
              }}
              title={
                screen
                  ? "Add New Address"
                  : contract || PostLoad?.inquiry_type === 2
                  ? "Contract Request"
                  : "Post a Load"
              }
            />
            {!screen && (
              <div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <Progress_bar
                    bgcolor={Colors.Primary}
                    progress={progress}
                    height={10}
                  />
                </div>
                <div
                  className="row_horizantal_center current_page customHeaderStyle">
                  {title}
                  {extraComponent}
                </div>

                {bottomTitle && (
                  <div
                    className="row_horizantal_center"
                    style={{ fontSize: "0.938rem", marginBottom: 15 }}
                  >
                    {bottomTitle}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ScreenHeader;
