import React from "react";
import { FC } from "react";
import { ScreenHeader } from "..";
import "./style.scss";

const TitleLayout: FC<any> = (props: any) => {
  const { progressFlag, fixed = false, pageNumber, progress, title, active = true, bottomTitle, titleOnly, titleOnlyText, contract } = props;
  return (
    <div className={fixed ? 'fixedLayout' : ''}>
      {active && (
        <ScreenHeader
          pageNumber={pageNumber}
          progress={progress}
          title={title}
          titleOnly={titleOnly}
          titleOnlyText={titleOnlyText}
          contract={contract}
          bottomTitle={bottomTitle}
          progressFlag={progressFlag}
        />
      )}
      <div className={`content ${!active ? 'noProgressBar' : ''}`}>{props?.children}</div>
    </div>
  );
};

export default TitleLayout;
