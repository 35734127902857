import React from "react";
import { Colors } from "@temp/assets";
import { Button } from "@mui/material/";
import { IProps } from "./types";
import "./style.scss";

export const ButtonOutline: React.FC<IProps> = ({
  title,
  icon,
  disabled,
  onClick,
}: IProps) => {
  return (
    <Button
      disabled={disabled}
      style={
        disabled
          ? { border: `2px solid ${Colors.PinkishGray}` }
          : { border: `2px solid ${Colors.Primary}` }
      }
      className="outline-btn"
      variant="contained"
      onClick={onClick}
    >
      <p
        className="btn-title"
        style={
          disabled ? { color: Colors.PinkishGray } : { color: Colors.Primary }
        }
      >
        {title}
      </p>
    </Button>
  );
};

export default ButtonOutline;
