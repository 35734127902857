import { Accordion, AccordionSummary } from "@mui/material/";
import { Colors } from "@temp/assets";
import { CustomText } from "@temp/components";
import React from "react";
import "../LoadDetailPoster/style.scss";
import { RenderRTL } from "./RenderRTL";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../LoadDetailPoster/style.scss";
import { useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;

export const PaymentTerms = ({ loadDetail, is_recipient = false }) => {
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  
  return (
    <div className="accordion-container">
      <Accordion
        className="accordion-background">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <CustomText.OpenSansSemiBold
            textStyle={{
              fontSize: "0.875rem",
              color: Colors.Black,
            }}
          >
            <span>Payment Terms</span>
            {
              is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">پیمنٹ کی تفصیلات</span>
              </>
            }
          </CustomText.OpenSansSemiBold>
        </AccordionSummary>

        <div className="accordion-content">
          {loadDetail?.pt_advance_value &&
            Boolean(Number(loadDetail?.pt_advance_value)) ? (
            <>
              <RenderRTL
                title="Advance"
                sep="/"
                urdutitle={"ايڈوانس"}
                val={`${loadDetail?.pt_advance_value} %`}
                is_recipient={is_recipient}
              />
            </>
          ) : null}

          {loadDetail?.pt_is_ondelivery &&
            Boolean(Number(loadDetail?.pt_ondelivery_value)) ? (
            <>
              <RenderRTL
                title="On Delivery"
                sep="/"
                urdutitle={"پہنچانے پر"}
                val={`${loadDetail?.pt_ondelivery_value} %`}
                is_recipient={is_recipient}
              />
            </>
          ) : null}

          {loadDetail?.pt_credit_percentage &&
            Boolean(Number(loadDetail?.pt_credit_percentage)) ? (
            <>
              <RenderRTL
                title="Credit"
                sep="/"
                urdutitle={"کریڈٹ"}
                val={`${loadDetail?.pt_credit_percentage} % - 
                
                ${loadDetail?.pt_credit_days > 1
                    ? `${loadDetail?.pt_credit_days} days`
                    : `${loadDetail?.pt_credit_days} day`
                  }`}
                is_recipient={is_recipient}
              />
            </>
          ) : null}

          {loadDetail?.pt_fuel_card_value &&
            Boolean(Number(loadDetail?.pt_fuel_card_value)) ? (
            <>
              <RenderRTL
                title="Fuel Card"
                sep="/"
                urdutitle={"فیول کارڈ"}
                val={`${loadDetail?.pt_fuel_card_value} % `}
                is_recipient={is_recipient}
              />
            </>
          ) : null}

          {loadDetail?.is_gst_inclusive || loadDetail?.is_withholding_tax_inclusive ?
            <CustomText.OpenSansSemiBold textStyle={{ marginTop: 16 }}>
              <span>Tax</span>
              {
                is_recipient &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">ٹیکس</span>
                </>
              }
            </CustomText.OpenSansSemiBold>
            : null}

          {loadDetail?.is_gst_inclusive ?
            <RenderRTL
              title="Sales Tax Invoice Required"
              sep="/"
              urdutitle={"سیلز ٹیکس رسید درکار ہے۔"}
              // val={loadDetail?.is_gst_inclusive ? "YES" : "NO"}
              val={loadDetail?.is_gst_inclusive ? loadDetail?.sales_tax_province :null}
              is_recipient={is_recipient}
            /> : null}

          {/* {loadDetail?.is_gst_inclusive && loadDetail?.sales_tax_province ?
            <RenderRTL
              title="Sales Tax Invoice"
              sep="/"
              urdutitle={"سیلز ٹیکس رسید (ادارہ)"}
              val={loadDetail?.sales_tax_province}
              is_recipient={is_recipient}
            /> : null
          } */}

          {loadDetail?.is_withholding_tax_inclusive ?
            <RenderRTL
              title=
              {`Rate inclusive of ${loadDetail?.tax_wht}% WHT`}
              sep="/"
              urdutitle={`${loadDetail?.tax_wht}% ریٹ WHT کے ساتھ ہو`}
              // val={loadDetail?.is_withholding_tax_inclusive ? "YES" : "NO"}
              val={""}
              is_recipient={is_recipient}
            /> : null}

          <div style={{ height: 16 }}></div>
        </div>
      </Accordion>
    </div>
  );
};
