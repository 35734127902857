import React, { useEffect } from "react";
import { IHelpPageProps } from "./types";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import { useDispatch, useSelector } from "react-redux";
import { CustomText } from "@components";
import rootReducer from "../../../store/reducers";
import TitleLayout from "@temp/components/TitleLayout";
import { DrawerAction } from "@temp/store/actions";
import { Accordion, AccordionSummary } from "@mui/material/";
import { ExpandMore } from "@mui/icons-material";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<IHelpPageProps> = (props) => {
  const dispatch = useDispatch();
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const faqs = useSelector((state: RootState) => state.Drawer.faqs)

  useEffect(() => {
    dispatch(DrawerAction.getFaqs());
  }, []);

  return (
    <TitleLayout titleOnly={true} titleOnlyText="Help">
      <div className="helpContentWrap">
        <FadeInComponent>
          <div className="img helpImg">
            <img src={Images.CustomerCare} alt="" />
          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="helpInfoText">
            <CustomText.OpenSansRegular
              textStyle={{
                fontSize: "1rem",
                color: Colors.Grayish,
              }}
            >
              24/7 Customer Service Available
            </CustomText.OpenSansRegular>
          </div>
        </FadeInComponent>

        <div className="icons helpIconsWrap">
          <FadeInComponent>
            <a href={`tel:${metaData?.administration_contact?.phone}`} target="blank" className="phone-email-whatsapp">
              <div className="helpIconsImg">
                <img src={Images.Phone} alt="" />
              </div>
              <CustomText.OpenSansRegular
                textStyle={{ fontSize: "1rem", color: Colors.Black }}
              >
                {`${metaData.administration_contact.phone}`}
              </CustomText.OpenSansRegular>
            </a>
          </FadeInComponent>

          <FadeInComponent>
            <a href={`mailto:${metaData?.administration_contact?.email}`} className="phone-email-whatsapp">
              <div className="helpIconsImg">
                <img src={Images.Email} alt="" />
              </div>
              <CustomText.OpenSansRegular
                textStyle={{ fontSize: "1rem", color: Colors.Black }}
              >
                {metaData.administration_contact.email}
              </CustomText.OpenSansRegular>
            </a>
          </FadeInComponent>

          <FadeInComponent>
            <a href={`https://wa.me/${metaData?.administration_contact?.whatsapp}`} target="blank" className="phone-email-whatsapp">
              <div className="helpIconsImg">
                <img src={Images.Whatsapp} alt="" />
              </div>
              <CustomText.OpenSansRegular
                textStyle={{ fontSize: "1rem", color: Colors.Black }}
              >
                Chat with MG Team
              </CustomText.OpenSansRegular>
            </a>
          </FadeInComponent>

        </div>

        <FadeInComponent>
          <div style={{ marginTop: 20, marginBottom: 20, fontStyle: "bold" }}> <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>Quick Guide and FAQs</CustomText.OpenSansSemiBold> </div>
        </FadeInComponent>

        <div style={{ marginBottom: 20 }}>
          {
            faqs.length
              ? faqs.map((item, index) => {
                return (
                  <FadeInComponent className="mb-3">
                    <Accordion >
                      <AccordionSummary onClick={() => {
                        let element = document.querySelector(`.faqas-item${index}`);
                        if (element) {
                          element.innerHTML = item?.description == null ? "loading" : item?.description;
                        }
                      }} expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel-header">
                        <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.813rem" }}>{item.title}</CustomText.OpenSansSemiBold>
                      </AccordionSummary>
                      <div style={{
                        padding: 20
                      }}>
                        <div className={`faqas-item${index}`}>
                        </div>
                      </div>
                    </Accordion>
                  </FadeInComponent>
                )
              }) : null
          }
        </div>
      </div>
    </TitleLayout>
  );
};

export default Page;
