import React, { useEffect, useState } from "react";
import { ILoadHistoryPageProps } from "./types";
import "./style.scss";
import { CustomText, CustomModal, ButtomTabs, DrawerNav, LoadListingStatus } from "@components";
import { Colors, Images } from "@temp/assets";
import { useDispatch, useSelector } from "react-redux";
import { CounterAtion, LoadAction, ProfileAction } from "@temp/store/actions";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import { useHistory } from "react-router";
import Radio from "@mui/material//Radio";
import { green } from "@mui/material//colors";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress, MenuItem, Select } from "@mui/material/";
import { CheckCircle, Close } from "@mui/icons-material";
import "reactjs-popup/dist/index.css";
// import { withStyles } from "@mui/material//styles";
import EmptyPlaceholder from "@temp/components/EmptyPlaceholder/EmptyPlaceholder";
import { LOAD_TYPE } from "@temp/constants";
import { isObjectEmpty, triggerMixpanelEvent } from "@temp/utils/helper";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";
import {
  convertDataForFclAndBulkLoadDetailRedux,
  convertDataForLoadDetailRedux,
} from "@temp/utils/dataFormat";
import { CONTRACT_DETAILS_URL, SELECT_VEHICLE } from "@temp/app/routes";
import moment from "moment";
import TouchRipple from "@mui/material//ButtonBase/TouchRipple";
import { useRef } from "react";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import OutlineButton from "@temp/components/OutLineButton";

var searchFlag = false
const Page: React.FC<ILoadHistoryPageProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loads = useSelector((state: RootState) => state.Load.load_history);
  const load_history_current_page = useSelector((state: RootState) => state.Load.load_history_current_page);
  const load_history_last_page = useSelector((state: RootState) => state.Load.load_history_last_page);
  const postLoadMeta = useSelector((state: RootState) => state?.Profile?.postLoadMeta);
  const PostLoad = useSelector((state: RootState) => state?.Profile?.postLoad);
  const load_type = postLoadMeta?.load_types?.find((val: any) => val?.id === PostLoad?.load_type);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedValue, setSelectedValue] = useState("All");
  const loadType = useSelector((state: RootState) => state?.Profile?.historyLoadType) || "Loads Received";
  const inquiryType = useSelector((state: RootState) => state?.Profile?.historyInquiryType) || "spot";
  const packageType = postLoadMeta.package_types;
  const inquiry_type = postLoadMeta.inquiry_types;
  const PreferredVehicleType = postLoadMeta?.preferred_vehicles;
  const [allLocations, setAllLocations] = useState([]);
  const [location_type, set_location_type] = useState(null)
  const [show_location_modal, set_show_location_modal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [historyStatus, setHistoryStatus] = useState("All");

  useEffect(() => {
    getHistoryLoads({
      refresh: true,
      loadType,
      selectedValue,
      search,
      inquiryType,
      historyStatus
    });
  }, [loadType, selectedValue, historyStatus]);

  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        getHistoryLoads({
          refresh: false,
          loadType,
          selectedValue,
          search,
          fromSearch: true,
          inquiryType
        });
      }
    },
    [debouncedSearchTerm]
  );

  useEffect(() => {
    if (searchFlag) {
      if (search == "") {
        getHistoryLoads({
          refresh: false,
          loadType,
          selectedValue,
          search,
          fromSearch: true,
          inquiryType
        });
      }
    }
  }, [search])

  useEffect(() => {
    return () => {
      searchFlag = false
    }
  }, [])

  const handleChange = (event) => {
    switch (event?.target.value) {
      case "All":
        triggerMixpanelEvent("Load History Filter All", "Load Histry Filter All")
      case "Containerized":
        triggerMixpanelEvent("Load History Filter Containerized", "Load Histry Filter Containerized")
      case "Non-Containerized":
        triggerMixpanelEvent("Load History Filter Non-Containerized", "Load Histry Filter Non-Containerized")
    }
    setSelectedValue(event.target.value);
    setShowModal(false);
  };

  const handleChangeHistoryStatus = (event) => {
    switch (event?.target.value) {
      case "Completed":
        triggerMixpanelEvent("Load History Filter Completed", "Load Histry Filter Completed")
      case "Inactive":
        triggerMixpanelEvent("Load History Filter Inactive", "Load Histry Filter Inactive")
      case "Cancelled":
        triggerMixpanelEvent("Load History Filter Cancelled", "Load Histry Filter Cancelled")
      case "All":
        triggerMixpanelEvent("Load History Filter All", "Load Histry Filter All")
    }
    setHistoryStatus(event.target.value);
    setHistoryModal(false);
  };

  const handleChangeLoadType = (value: string) => {
    dispatch(ProfileAction.updateHistoryFilters({
      loadType: value,
      inquiryType,
    }))
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    searchFlag = true
  };

  // get history load api call function
  const getHistoryLoads = (data) => {
    dispatch(
      LoadAction.getLoadsHistory({
        refresh: data.refresh,
        load_history_last_page_type:
          data.loadType === "Loads Received" ? "received" : "posted",
        search: data?.search ? data?.search : "",
        fromSearch: data?.fromSearch,
        next_page:
          data?.refresh || data?.fromSearch || search
            ? 1
            :
            Number(load_history_current_page) + 1,

        load_history_type:
          data.selectedValue === "All"
            ? "all"
            : data.selectedValue === LOAD_TYPE.fcl
              ? "fcl"
              : data.selectedValue === LOAD_TYPE.bulk
                ? "bulk"
                : data.selectedValue === "Loads with Response"
                  ? "loads_with_response"
                  : "loads_without_response",
        job_load_history_status: ["completed", "cancelled"],
        inquiry_type:
          data.inquiryType === "spot" ? "spot_rate" : "contract_rate",
        statuses: historyStatus,
      })
    );
  };

  // clone a load from history load listing 
  const cloneALoadCallBack = (loadDetail: any) => {
    const callBack = () => {
      if (!isObjectEmpty(loadDetail)) {
        let data = {};
        if (
          loadDetail.load_type.title === LOAD_TYPE.bulk
        ) {
          data = convertDataForFclAndBulkLoadDetailRedux(
            loadDetail,
            packageType,
            load_type,
            inquiry_type,
            PreferredVehicleType,
            "clone"
          );
        } else {
          data = convertDataForLoadDetailRedux(
            loadDetail,
            packageType,
            load_type,
            inquiry_type,
            "clone"
          );
        }
        if (!isObjectEmpty(data)) {
          dispatch(ProfileAction.postLoadData({ ...data }));
          if (loadDetail.inquiry_type.title === "Spot Rate") {
            history.push(SELECT_VEHICLE);
          } else {
            history.push(CONTRACT_DETAILS_URL);
          }
        }
      }
    }
    dispatch(ProfileAction.JobLoadMeta(callBack));
  };
  const cloneALoad = (id: number) => {
    dispatch(
      LoadAction.getLoadDetail({
        load_id: id,
        cb: (data) => {
          cloneALoadCallBack(data);
        },
      })
    );
  };
  const handleCloneALoad = (event: any, id: number) => {
    event.stopPropagation();
    if (event.target.value === "clone") {
      dispatch(ProfileAction.postLoadDataRemove());
      dispatch(CounterAtion.resetPostloadCounterData())
      cloneALoad(id);
    }
  };


  // clone and view only history load from listing 
  const cloneALoadCallBackLoadHistory = (loadDetail: any) => {
    const callBack = () => {
      if (!isObjectEmpty(loadDetail)) {
        let data = {};
        if (loadDetail.load_type.title === LOAD_TYPE.bulk) {
          data = convertDataForFclAndBulkLoadDetailRedux(
            loadDetail,
            packageType,
            load_type,
            inquiry_type,
            PreferredVehicleType,
            "history",
            loadType
          );
        } else {
          data = convertDataForLoadDetailRedux(
            loadDetail,
            packageType,
            load_type,
            inquiry_type,
            "history",
            loadType,

          );
        }
        // return
        if (!isObjectEmpty(data)) {
          dispatch(ProfileAction.postLoadData({ ...data }));
          history.push(`/confirm-load`, {
            from_load_history: true,
            is_recipient: loadType === "Loads Received" ? true : false,
            loadDetail: loadDetail
          });
        }
      }
    }
    dispatch(ProfileAction.JobLoadMeta(callBack));
  };

  const handleRefresh = async () => {
    getHistoryLoads({
      refresh: true,
      loadType,
      selectedValue,
      search,
      inquiryType
    });
  }



  const openLoadHistory = (id: number) => {
    if (inquiryType == "spot") {
      triggerMixpanelEvent("Open Load Detail from History", "Open load detail from history")
    } else {
      triggerMixpanelEvent("Open Contract Detail from History", "Loads History Spot Recieved Listing")
    }
    dispatch(
      LoadAction.getLoadDetail({
        load_id: id,
        cb: (data) => {
          cloneALoadCallBackLoadHistory(data);
        },
      })
    );
  };

  //   const GreenRadio = withStyles({
  //     root: {
  //       color: green[400],
  //       "&$checked": {
  //         color: green[600],
  //       },
  //     },
  //     checked: {},
  //   })((props) => <Radio color="default" {...props} />);

  const RenderLoads = ({ load }) => {
    const rippleRef = useRef(null);
    const onRippleStart = (e) => {
      rippleRef.current.start(e);
    };
    const onRippleStop = (e) => {
      rippleRef.current.stop(e);
    };
    return (
      <div
        type="button"
        onMouseDown={onRippleStart}
        onMouseUp={onRippleStop}
        onClick={(e) => {
          e.stopPropagation();
          openLoadHistory(load?.id)
        }}
        className="sectionBox dashboardSectionBox"
      >
        <div className={loadType == "Load Posted" ? "left-container" : "left-container active"}>
          <div style={{ paddingBottom: 5, marginBottom: '10px' }} className="load-id" >

            <div style={{ alignItems: 'center' }} className="loadDetail">
              <p className="fw-6 LoadIdWrap">
                {load?.inquiry_type !== "Contract Rate" ? `Load ID - ${load?.id}` : `Contract ID - ${load?.id}`}
                {
                  loadType == "Loads Received" &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">{load?.inquiry_type !== "Contract Rate" ? `لوڈ آئی ڈی - ${load?.id}` : ` معاہدے کی آئی ڈی - ${load?.id}`}</span>
                  </>
                }
              </p>
              <div style={{
                width: load?.inquiry_type?.id == 2 ? 100 : 100,
                marginLeft: 4
              }} className="fcl-tag">
                {load?.load_type}
              </div>

              {load?.inquiry_type !== "Contract Rate" && load?.total_awarded > 0 ?
                <div style={{
                  border: "1px solid gray",
                  height: 20,
                  borderRadius: 15,
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginLeft: 5,
                  background: "#fff"
                }} className="flexRow">

                  <span style={{ color: Colors.Primary, fontSize: "0.675rem", position: "relative", top: "1px" }} className="icons-truck active"></span>
                  <div style={{ fontSize: "0.575rem", marginLeft: 5 }} className="fw-5">
                    {load?.counter}
                  </div>
                </div>
                : null}
              <div className="flexRow">
                {
                  load?.is_allocated ?
                    <div className="fcl-tag" style={{ padding: "3px 8px" }}>
                      <img
                        src={Images.AllocatedIcon}
                        alt=""
                      />
                    </div>
                    : null
                }

                {
                  load?.is_forwarded && !load?.parent_id ?
                    <div className="fcl-tag" style={{ padding: "3px 8px" }}>
                      <img
                        src={Images?.ForwardGreenIcon}
                        alt=""
                      />
                    </div>
                    : null
                }
                {
                  load?.parent_id && load?.is_poster ?
                    <div className="fcl-tag">
                      <img
                        src={Images?.ForwardGreenIcon}
                        alt=""
                      />
                      <span style={{ marginLeft: 5 }}>{load?.parent_id}</span>
                    </div> : null

                }
              </div>
            </div>

            <div className="" style={{ display: 'flex', alignItems: 'center', marginTop: -7 }}>
              {loadType === "Loads Posted" && (
                <div className="select-container customSelect noBorderSelect">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="dot-select"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleCloneALoad(e, load.id)}
                    IconComponent={(_props) => (
                      <span className="icons-dots" onClick={(e) => e.stopPropagation()}></span>
                    )}
                  >
                    <MenuItem value="clone">
                      <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>
                        Clone
                      </CustomText.OpenSansSemiBold>
                    </MenuItem>
                  </Select>
                </div>
              )}
            </div>
          </div>

          <div className="formGrid">
            <div className="grayLabel dBox" style={{ marginBottom: '0.25rem', display: 'inline-block' }}>
             
              <span className="titleName"> Status </span>
                  {
                    loadType == "Loads Received" &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">اسٹیٹس</span>
                    </>
                  }
            </div>
            <div className="fw-5 justifyBetween"
              style={{
                color: load?.status_name == "Active" ? Colors.Primary
                  : load?.status_name == "Completed" ? Colors.Primary
                    : load?.status_name == "Inactive" ? Colors.Orange
                      : Colors.Red
              }}
            >
              {load?.status_name}
            </div>
          </div>

          {loadType == "Loads Received" ?
            <>
              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                  {load?.inquiry_type == "Spot Rate" ? "Load From" : "Contract From"}
                  {
                    loadType == "Loads Received" &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">{load?.inquiry_type !== "Contract Rate" ? "لوڈ بھجنے والا" : "معاہدہ بھجنے والا"}</span>
                    </>
                  }
                </div>
                <div className="fw-5">
                  <span style={{ textTransform: "capitalize" }}>{`${load?.poster} `}</span>
                </div>
              </div>

              {load?.poster_system_company || load?.poster_profile_company ? (
                <div className="formGrid">
                  <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                    <span className="titleName">Company Name</span>
                    {
                      loadType == "Loads Received" &&
                      <>
                        <span className="seperator">/</span>
                        <span className="urduLabel">کمپنی کا نام</span>
                      </>
                    }
                  </div>
                  <div className="fw-5" style={{ textTransform: "capitalize" }}>
                    {load?.poster_system_company || load?.poster_profile_company}
                  </div>
                </div>
              ) : null}
            </> : null
          }

          <div className="formGrid">
            <div className="grayLabel" style={{ marginBottom: '0.5rem' }}>
              {load?.inquiry_type == "Contract Rate" || load?.contract_id ? "Contract Name:" : "Load Name:"}
              {
                loadType == "Loads Received" &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">{load?.inquiry_type == "Contract Rate" || load?.contract_id ? "معاہدے کا نام" : "لوڈ کا نام"}</span>
                </>
              }
            </div>
            <div className="fw-5">
              {load?.inquiry_type == "Contract Rate" || load?.contract_id ? load?.contract_name : load?.title}
            </div>
          </div>

          {
            load?.contract_id ?
              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                  <span className="titleName">{"Load Name"}</span>
                  {
                    loadType == "Loads Received" &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">{"لوڈ کا نام"}</span>
                    </>
                  }
                </div>
                <div className="fw-5">
                  {load?.title}
                </div>
              </div>
              : null}

          <div>
            {loadType === "Loads Received" ?
              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                  <span className="titleName">Received Date & Time</span>
                  {
                    loadType == "Loads Received" &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">موصول ہونے کی تاریخ اور وقت</span>
                    </>
                  }
                </div>
                <div className="fw-5">
                  {`${moment(load?.created_at).format("DD MMM, YYYY hh:mm A")}`}
                </div>
              </div>
              : null}
          </div>

          {/* response deadline  */}
          <div className="formGrid">
            <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
              <span className="titleName">Response Deadline (Date & Time)</span>
              {
                loadType == "Loads Received" &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">ریٹ دینے کی معیاد </span>
                </>
              }
            </div>
            <div className="fw-5">
              {`${moment(load?.deadline_at).format("DD MMM, YYYY hh:mm A")}`}
            </div>
          </div>

          <div className="formGrid">
            <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
              <span className="titleName"> Posted On </span>
                  {
                    loadType == "Loads Received" &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel"> پوسٹ كرنے  کی تاریخ اور وقت</span>
                    </>
                  }
            </div>
            <div className="fw-5">
              {`${moment(load?.created_at).format("DD MMM, YYYY hh:mm A")}`}
            </div>
          </div>

          {/* pickup locations  */}
          <div className="formGrid">
            <div className="grayLabel dBox" style={{ marginBottom: '0.25rem', display: 'inline-block' }}>
              <span className="titleName">Pick-up</span>
              {
                loadType == "Loads Received" &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">پک اپ</span>
                </>
              }
            </div>

            {<div className="fw-5 justifyBetween">
              <div className="fw-5">
                {
                  load?.pickups?.length > 1
                    ? "Multiple " : load?.pickups[0]
                }
              </div>
              <div className="recepient-container">
                {load?.pickups?.length > 1 ?
                  <>
                    <div
                      className="smallBlueLabel"
                      onClick={(e) => {
                        e.stopPropagation()
                        set_location_type({
                          type: 'pickup',
                          load_type: load.inquiry_type
                        })
                        set_show_location_modal(true)
                      }}
                    >
                      See All
                    </div>
                  </> : null}
              </div>
            </div>
            }
          </div>

          {/* dropoff locations  */}
          <div className="formGrid">
            <div className="grayLabel dBox" style={{ marginBottom: '0.25rem', display: 'inline-block' }}>
              <span className="titleName">Drop-off</span>
              {
                loadType == "Loads Received" &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">ڈراپ-آف</span>
                </>
              }

            </div>
            <div className="fw-5 justifyBetween">
              {/* {load?.inquiry_type == "Contract Rate" && load?.dropoffs?.length > 1
                ? "Multiples" : load?.dropoffs[0]} */}
              <div className="fw-5">
                {
                  load?.dropoffs?.length > 1
                    ? "Multiple" : load?.dropoffs[0]
                }
              </div>
              <div className="recepient-container">
                {load?.dropoffs?.length > 1 ?
                  <>
                    <div
                      className="smallBlueLabel"
                      onClick={(e) => {
                        e.stopPropagation()
                        setAllLocations(load?.dropoffs)
                        set_location_type({
                          type: 'dropoff',
                          load_type: load.inquiry_type
                        })
                        set_show_location_modal(true)
                      }}
                    >
                      See All
                    </div>
                  </> : null}
              </div>
            </div>
          </div>
        </div>
        {/* three dot menu for clone and cancel*/}
        <div>
          {
            loadType == "Load Posted" && (
              <div className="right-container"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPopUp(true);
                }}
              >
                <img alt="" style={{ height: 16 }} src={Images.iconMenu} />
              </div>
            )
          }
        </div>
        <div className="textBtn">OPEN</div>
        <TouchRipple ref={rippleRef} center={false} />
      </div>
    );
  };

  const handleTabClick = (type: string) => {
    // setInquiryType(type)
    dispatch(ProfileAction.updateHistoryFilters({
      loadType: loadType,
      inquiryType: type,
    }))

    getHistoryLoads({
      refresh: true,
      loadType,
      selectedValue,
      search,
      inquiryType: type
    });
  };

  return (
    <div className="root-center-container">
      <div className="loads-top-wrappers">
        {/* <BackHeader
          onClick={() => {
            history.goBack();
          }}
          title={'Loads History'}
        /> */}
        <div className="topDropDownWrap">
          <div className="drop-down" style={{ justifyContent: 'space-between' }}>
            <DrawerNav />
            <p className="title">
              Loads History
            </p>
            <div></div>
          </div>
        </div>

        {/* </div> */}
        <div className="tabContainerMainWrap">
          <FadeInComponent>
            <div className="tab-btn radioTabs twoTab mb-3">
              <div
                className={`two-category-button ${loadType === "Loads Posted" ? 'active' : ''}`}
                onClick={() => {
                  handleChangeLoadType("Loads Posted");
                  if (inquiryType == 'spot') {
                    triggerMixpanelEvent("Loads History Spot Posted Listing", "Loads History Spot Posted Listing")
                  } else {
                    triggerMixpanelEvent("Loads History Contract Posted Listing", "Loads History Contract Posted Listing")
                  }
                }}
              >
                Loads Posted
              </div>
              <div
                className={`two-category-button ${loadType === "Loads Received" ? 'active' : ''}`}
                onClick={() => {
                  handleChangeLoadType("Loads Received");
                  if (inquiryType == 'spot') {
                    triggerMixpanelEvent("Loads History Spot Recieved Listing", "Loads History Spot Recieved Listing")
                  } else {
                    triggerMixpanelEvent("Loads History Contract Recieved Listing", "Loads History Contract Recieved Listing")
                  }
                }}
              >
                Loads Received
              </div>
              <div className="radioTab_slider"></div>
            </div>
          </FadeInComponent>
          <FadeInComponent>
            <div className="tab-btn radioTabs twoTab mb-3">
              <div
                className={`two-category-button ${inquiryType === "spot" ? 'active' : ''}`}
                onClick={() => {
                  handleTabClick("spot");
                  if (loadType == 'Loads Received') {
                    triggerMixpanelEvent("Loads History Spot Recieved Listing", "Loads History Spot Recieved Listing")
                  } else {
                    triggerMixpanelEvent("Loads History Spot Posted Listing", "Loads History Spot Posted Listing")
                  }
                }}
              >
                Spot
              </div>
              <div
                className={`two-category-button ${inquiryType === "contract" ? 'active' : ''}`}
                onClick={() => {
                  handleTabClick("contract");
                  if (loadType == 'Loads Posted') {
                    triggerMixpanelEvent("Loads History Contract Posted Listing", "Loads History Contract Posted Listing")
                  } else {
                    triggerMixpanelEvent("Loads History Contract Recieved Listing", "Loads History Contract Recieved Listing")
                  }
                }}
              >
                Contract
              </div>
              <div className="radioTab_slider"></div>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div className="searchContainer">
              <input
                className="searchInput"
                onChange={handleSearch}
                type="text"
                name="search"
                autoComplete="off"
                style={{ width: "100%" }}
                value={search}
                placeholder="Search by name or id"
              />
              <span className={`icons-search ${search ? '' : 'active'}`}></span>
              <span
                className={`icons-cross ${search ? 'active' : ''}`}
                onClick={() => {
                  setSearch("")
                  // searchFlag = false
                }}
              ></span>
            </div>
          </FadeInComponent>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
            <div className="filter-container" style={{ justifyContent: "unset", marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <span className="icons-all" onClick={() => setShowModal(true)}></span>
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginTop: 3,
                }}
              >
                <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>
                  {selectedValue}
                </CustomText.OpenSansSemiBold>
              </div>
            </div>

            {/* history status */}
            {/* <div className="filter-container" style={{ justifyContent: "unset", marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <span className="icons-all" onClick={() => setHistoryModal(true)}></span>
              </div>
              <div
                style={{
                  marginLeft: 10,
                  marginTop: 3,
                }}
              >
                <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>
                  {historyStatus}
                </CustomText.OpenSansSemiBold>
              </div>
            </div> */}
          </div>
        </div>


      </div>


      <div style={{ paddingTop: 290 }} className="load-listing-container customLoadListingWrapper">
        <div>
          {
            loads.length ?
              <PullToRefresh
                onRefresh={handleRefresh}
              >
                <InfiniteScroll
                  pullDownToRefresh={true}
                  refreshFunction={() => {
                    alert('yessss')
                  }}
                  height={'calc(100vh - 298px)'}
                  dataLength={loads.length}
                  next={() => {
                    getHistoryLoads({
                      refresh: false,
                      loadType,
                      selectedValue,
                      search,
                      inquiryType
                    });
                  }}
                  hasMore={load_history_last_page > load_history_current_page}
                  loader={
                    <div className="load-more-loader">
                      <CircularProgress />
                    </div>
                  }
                  style={{
                    padding: "0 15px 15px",
                  }}
                  scrollThreshold={0.8}
                >
                  {loads?.map((load, loadIndex) => {
                    return (
                      <FadeInComponent key={loadIndex}>
                        <RenderLoads load={load} />
                      </FadeInComponent>
                    )
                  })}
                </InfiniteScroll>
              </PullToRefresh>
              :
              <div className="network-empty-component">
                <EmptyPlaceholder
                  image={Images.loadHistoryNew}
                  title="No Load History!"
                  detail="You have no load history please do some activity"

                />
              </div>
          }
        </div>
      </div>





      <div>
        <CustomModal
          showModal={showModal}
          toggleModal={setShowModal}
          modalStyle={{
            height: 310,
            width: 316,
          }}
        >
          <div>
            <div className="filterBy">
              <Radio
                checked={selectedValue === "All"}
                onChange={handleChange}
                value="All"
                name="radio-button-demo"
                inputProps={{ "aria-label": "All" }}
              />

              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginLeft: 6,
                  marginTop: 10,
                }}
              >
                All
              </CustomText.OpenSansRegular>
            </div>
            <div className="filterBy">
              <Radio
                checked={selectedValue === LOAD_TYPE.fcl}
                onChange={handleChange}
                value={LOAD_TYPE.fcl}
                name="radio-button-demo"
                inputProps={{ "aria-label": LOAD_TYPE.fcl }}
              />

              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginLeft: 6,
                  marginTop: 10,
                }}
              >
                {LOAD_TYPE.fcl}
              </CustomText.OpenSansRegular>
            </div>
            <div className="filterBy">
              <Radio
                checked={selectedValue === LOAD_TYPE.bulk}
                onChange={handleChange}
                value={LOAD_TYPE.bulk}
                name="radio-button-demo"
                inputProps={{ "aria-label": LOAD_TYPE.bulk }}
              />

              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginLeft: 6,
                  marginTop: 10,
                }}
              >
                {LOAD_TYPE.bulk}
              </CustomText.OpenSansRegular>
            </div>
            {loadType !== "Loads Received" && inquiryType == "spot" && (
              <>
                <div className="filterBy">
                  <Radio
                    checked={selectedValue === "Loads with Response"}
                    onChange={handleChange}
                    value="Loads with Response"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "Loads with Response" }}
                  />

                  <CustomText.OpenSansRegular
                    textStyle={{
                      fontSize: "0.875rem",
                      color: Colors.Black,
                      marginLeft: 6,
                      marginTop: 10,
                    }}
                  >
                    Loads with Response
                  </CustomText.OpenSansRegular>
                </div>
                <div className="filterBy">
                  <Radio
                    checked={selectedValue === "Loads without Response"}
                    onChange={handleChange}
                    value={"Loads without Response"}
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "Loads without Response" }}
                  />

                  <CustomText.OpenSansRegular
                    textStyle={{
                      fontSize: "0.875rem",
                      color: Colors.Black,
                      marginLeft: 6,
                      marginTop: 10,
                    }}
                  >
                    Loads without Response
                  </CustomText.OpenSansRegular>
                </div>
              </>
            )}
          </div>
        </CustomModal>
      </div>

      <div>
        <CustomModal
          showModal={historyModal}
          toggleModal={setHistoryModal}
          modalStyle={{
            height: 310,
            width: 316,
          }}
        >
          <div>
            <div className="filterBy">
              <Radio
                checked={historyStatus === "All"}
                onChange={handleChangeHistoryStatus}
                value="All"
                name="radio-button-demo"
                inputProps={{ "aria-label": "All" }}
              />
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginLeft: 6,
                  marginTop: 10,
                }}
              >
                All
              </CustomText.OpenSansRegular>
            </div>
            <div className="filterBy">
              <Radio
                checked={historyStatus === "Cancelled"}
                onChange={handleChangeHistoryStatus}
                value="Cancelled"
                name="radio-button-demo"
                inputProps={{ "aria-label": "Cancelled" }}
              />
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginLeft: 6,
                  marginTop: 10,
                }}
              >
                Cancelled
              </CustomText.OpenSansRegular>
            </div>

            <div className="filterBy">
              <Radio
                checked={historyStatus === "Completed"}
                onChange={handleChangeHistoryStatus}
                value="Completed"
                name="radio-button-demo"
                inputProps={{ "aria-label": "Completed" }}
              />
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginLeft: 6,
                  marginTop: 10,
                }}
              >
                Completed
              </CustomText.OpenSansRegular>
            </div>

            <div className="filterBy">
              <Radio
                checked={historyStatus === "Inactive"}
                onChange={handleChangeHistoryStatus}
                value="Inactive"
                name="radio-button-demo"
                inputProps={{ "aria-label": "Inactive" }}
              />
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginLeft: 6,
                  marginTop: 10,
                }}
              >
                Inactive
              </CustomText.OpenSansRegular>
            </div>
          </div>
        </CustomModal>
      </div>

      <div>
        <CustomModal
          showModal={showPopUp}
          toggleModal={setShowPopUp}
          modalStyle={{
            width: 290,
          }}
        >
          <div>
            <div className="more-options-heading">
              <CustomText.OpenSansSemiBold
                textStyle={{
                  fontSize: "1.125rem",
                  color: Colors.Black,
                }}
              >
                More Options
              </CustomText.OpenSansSemiBold>
              <Close onClick={() => setShowPopUp(false)} />
            </div>
            <div
              onClick={() => {
                setShowPopUp(false);
              }}
              className="more-options"
            >
              <img src={Images.clone} className="popup-img" alt="" />
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginTop: 18,
                  marginLeft: 10,
                }}
              >
                Clone
              </CustomText.OpenSansRegular>
            </div>
            <div
              onClick={() => {
                setShowPopUp(false);
              }}
              className="more-options"
            >
              <img src={Images.removeLoad} className="popup-img" alt="" />
              <CustomText.OpenSansRegular
                textStyle={{
                  fontSize: "0.875rem",
                  color: Colors.Black,
                  marginTop: 18,
                  marginLeft: 10,
                }}
              >
                Remove Load
              </CustomText.OpenSansRegular>
            </div>
          </div>
        </CustomModal>
      </div>

      {/* modal for all locations */}
      <div>
        <CustomModal
          showModal={show_location_modal}
          toggleModal={set_show_location_modal}
          modalStyle={{ width: 300 }}
        >
          <div>

            <div className="more-options-heading">
              {
                <CustomText.OpenSansBold
                  textStyle={{
                    fontSize: 18,
                    color: Colors.Black,
                  }}
                >
                  {
                    location_type?.type == 'pickup' ? "Pickup Locations" : "Dropoff Locations"
                  }
                </CustomText.OpenSansBold>
              }
              <Close onClick={() => set_show_location_modal(false)} />
            </div>

            {

              allLocations?.map((value, index) => {
                return (
                  <div key={index} style={{ marginTop: 24, marginLeft: 14 }}>
                    {location_type?.load_type == 'Spot Rate' ? ` ${index + 1} - ${value}` : `${value}`}
                  </div>
                )
              })
            }
          </div>
        </CustomModal>
      </div>
      <ButtomTabs activeTab="load-history" />

    </div>
  );
};
export default Page;

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );
  return debouncedValue;
}