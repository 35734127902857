import React, { useEffect, useState } from "react";
import { NewInput } from "@temp/components";
import "../LoadDetailPoster/style.scss";
import { showMessage } from "@temp/utils/helper";

export const RespondWeight = ({ my_bid, setResponded, responded, type, placeholder, decimal }) => {
    const [new_bid, set_new_bid] = useState(my_bid?.value)
    const debouncedSearchTerm = useDebounce(new_bid || 5, 500);
    useEffect(
        () => {
            if (debouncedSearchTerm) {
                let new_data = [...responded];
                let respond_index = new_data.findIndex((item) => item?.loc_id == my_bid?.loc_id);
                new_data[respond_index][my_bid?.key] = new_bid;
                setResponded(new_data);
            }
        },
        [debouncedSearchTerm]
    );

    return (
        <div>
            {
                type == 'number' ?
                    <NewInput
                        onChange={(e) => {
                            if (e.target.value === ".0") {
                                showMessage.current({
                                    message: "Please enter a valid value in Prices Fields",
                                    status: "error"
                                })
                                set_new_bid("")
                            } else {
                                set_new_bid(e.target.value)
                            }
                            // set_new_bid(e.target.value)
                        }}
                        value={new_bid ? new_bid : ""}
                        showEnd={"PKR"}
                        placeholder={placeholder}
                        decimal={decimal}
                    /> :
                    <textarea
                        style={{ outline: "none" }}
                        maxLength={250}
                        onInput={(e) => {
                            set_new_bid(e.target.value)

                        }}
                        value={new_bid ? new_bid : ""}
                        id={"cargo_description"}
                        name={"cargo_description"}
                        placeholder="Optional"

                    />
            }
        </div>
    );
};


function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay]
    );
    return debouncedValue;
}
