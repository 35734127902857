import React, { useEffect, useState, useRef } from "react";
import { ILoadDraftPageProps } from "./types";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import { ProfileAction } from "@temp/store/actions";
import { CustomText, CustomModal } from "@components";
import { DrawerAction } from "@temp/store/actions";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
import { useHistory } from "react-router";
import TitleLayout from "@temp/components/TitleLayout";
import { LoadAction } from "@temp/store/actions";
import EmptyPlaceholder from "@temp/components/EmptyPlaceholder/EmptyPlaceholder";
import { isObjectEmpty, triggerMixpanelEvent } from "@temp/utils/helper";
import { LOAD_TYPE } from "@temp/constants";
import { Close } from "@mui/icons-material";
import { convertDataForFclAndBulkLoadDetailRedux, convertDataForLoadDetailRedux } from "@temp/utils/dataFormat";
import TouchRipple from "@mui/material//ButtonBase/TouchRipple";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";

export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<ILoadDraftPageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loadDraftData = useSelector((state: RootState) => state.Drawer.loadDraftData);
  const postLoadMeta = useSelector((state: RootState) => state?.Profile?.postLoadMeta);
  const packageType = postLoadMeta.package_types;
  const PreferredVehicleType = postLoadMeta?.preferred_vehicles;
  const load_type = postLoadMeta.load_types;
  const inquiry_type = postLoadMeta.inquiry_types;
  const [allLocations, setAllLocations] = useState([]);
  const [location_type, set_location_type] = useState(null)
  const [show_location_modal, set_show_location_modal] = useState(false);

  // clone a load from draft 
  const cloneALoadCallBack = (loadDetail: any) => {
    const callBack = () => {
      if (!isObjectEmpty(loadDetail)) {
        let data = {};
        if (
          loadDetail.load_type.title === LOAD_TYPE.bulk ||
          loadDetail.load_type.title === LOAD_TYPE.bulk
        ) {
          data = convertDataForFclAndBulkLoadDetailRedux(
            loadDetail,
            packageType,
            load_type,
            inquiry_type,
            PreferredVehicleType
          );
        } else {
          data = convertDataForLoadDetailRedux(
            loadDetail,
            packageType,
            load_type,
            inquiry_type
          );
        }
        
        if (!isObjectEmpty(data)) {
          dispatch(ProfileAction.postLoadData({ ...data, isDraft: true }));
          triggerMixpanelEvent("Post a Load from Draft start", "create new load from draft load")
          history.push("confirm-load");
        }
      }
    }
    dispatch(ProfileAction.JobLoadMeta(callBack));
  };
  const cloneALoad = (id: number) => {
    dispatch(
      LoadAction.getLoadDetail({
        load_id: id,
        cb: (data) => {
          cloneALoadCallBack(data);
        },
      })
    );
  };

  useEffect(() => {
    dispatch(DrawerAction.GetLoadDraft());
  }, []);

  const RenderLoads = ({load}) => {
    const rippleRef = useRef(null);
    const onRippleStart = (e) => {
      rippleRef.current.start(e);
    };
    const onRippleStop = (e) => {
      rippleRef.current.stop(e);
    };

    return (
      <div 
        type="button"
        onMouseDown={onRippleStart}
        onMouseUp={onRippleStop}
        onClick={(e) => {
          e.stopPropagation();
          cloneALoad(load?.id)
        }}
        className="sectionBox dashboardSectionBox"
      >
        <div className={"left-container"}>
          <div className="load-id">
            <div className="loadDetail">
              <p className="fw-6">
                {load?.inquiry_type !== "Contract Rate" ? `Load ID - ${load?.id}` : `Contract ID - ${load?.id}`}
              </p>
              <div style={{
                width: load?.inquiry_type?.id == 2 ? 100 : 100,
                marginLeft: 8
              }} className="fcl-tag">
                {load?.load_type}
              </div>
            </div>
          </div>
          {
            <>
              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>Poster</div>
                <div className="fw-5">
                  <span style={{ textTransform: "capitalize" }}>{`${load?.poster} `}</span>
                </div>
              </div>

              {load?.poster_system_company || load?.poster_profile_company ? (
                <div className="formGrid">
                  <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>Company Name</div>
                  <div className="fw-5" style={{ textTransform: "capitalize" }}>
                    {load?.poster_system_company || load?.poster_profile_company}
                  </div>
                </div>
              ) : null}
            </>
          }

          <div className="formGrid">
            <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
              {load?.inquiry_type == "Contract Rate" || load?.contract_id ? "Contract Name:" : "Load Name:"}
            </div>
            <div className="fw-5">
              {load?.title ? load?.title : load?.contract_name}
            </div>
          </div>
        </div>
        <div className="textBtn">OPEN</div>
        <TouchRipple ref={rippleRef} center={false} />
      </div>
    );
  };

  return (
    <TitleLayout titleOnly={true} titleOnlyText="Draft Loads">
      <div className="draftloadContentWrapper">
        {loadDraftData?.length > 0 ? (
          loadDraftData?.map((item, index) => {
            let showMore = item?.categories?.length - 2;
            return (
              <FadeInComponent key={index}>
                <RenderLoads load={item} key={index} />
              </FadeInComponent>
            );
          })
        ) : (
          <div className="network-empty-component">
            <EmptyPlaceholder
              image={Images.draftLoadNew}
              title="No Draft Loads!"
              detail="You have no draft load 
              please post a Load"
            />
          </div>
        )}
        {/* modal for all locations */}
        <div>
          <CustomModal
            showModal={show_location_modal}
            toggleModal={set_show_location_modal}
            modalStyle={{ width: 300 }}
          >
            <div>
              <div className="more-options-heading">
                {
                  <CustomText.OpenSansBold
                    textStyle={{
                      fontSize: 18,
                      color: Colors.Black,
                    }}
                  >
                    {
                      location_type?.type == 'pickup' ? "Pickup Locations" : "Dropoff Locations"
                    }
                  </CustomText.OpenSansBold>
                }
                <Close onClick={() => set_show_location_modal(false)} />
              </div>
              {
                allLocations?.map((value, index) => {
                  return (
                    <div key={index} style={{ marginTop: 24, marginLeft: 14 }}>
                      {location_type?.load_type == 'Spot Rate' ? ` ${index + 1} - ${value}` : `${value}`}
                    </div>
                  )
                })
              }
            </div>
          </CustomModal>
        </div>
      </div>
    </TitleLayout>
  );
};
export default Page;