import React, { FC, useState, useEffect } from "react";
import { CustomModal, PrimaryButton, RadioButton, SimpleCheckBox } from "@components";
import CategoryContainer from "./CategoryContainer";
import { ProfileAction } from "@temp/store/actions";
import { useHistory } from "react-router-dom";
import CargoContainer from "../../AdditionalDetailsLclBulk/CargoDetailsCard";
import { useSelector, useDispatch } from "react-redux";
import TitleLayout from "@temp/components/TitleLayout";
import { LOAD_TYPE } from "@temp/constants";
import InfoIcon from "@mui/icons-material/Info";
import { Radio,
	//  withStyles 
	} from "@mui/material/";
import { green } from "@mui/material//colors";
import rootReducer from "@temp/store/reducers";
import { UnitCategory } from "./UnitCategory";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
export type RootState = ReturnType<typeof rootReducer>;

const Page: FC = () => {
  const [transaction_type_id, setTransaction_type_id] = useState("0");
  const [vehicles, setVehicles] = useState([]);
  const [added_units, set_added_units] = useState([])
  const dispatch = useDispatch();
  const history = useHistory();
  const PostLoad = useSelector((state: RootState) => state?.Profile?.postLoad);
  const [vehicleScreen_values, setVehicleScreenValues] = useState(PostLoad?.vehicleScreen?.values || null);
  const PostLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta)

  useEffect(() => {
    setVehicleScreenValues({
      ...vehicleScreen_values,
      priceFtonType: 'text',
      loadingFtonType: 'text',
      unloadingFtonType: 'text',
      detention_free_time: PostLoad?.fromClone ? vehicleScreen_values?.detention_free_time : PostLoadMeta?.load_configurations?.free_time_setting || 24

    })
  }, [])
  const bulk_vehicles = useSelector((state: RootState) => state?.Profile?.postLoadMeta?.vehicle_types);
  const filtered_bulk_vehicles = bulk_vehicles.filter((item) => item?.is_detention == 1);

  useEffect(() => {
    let data = []
    filtered_bulk_vehicles.map((item) => {
      data.push('text')
    })
  }, [])

  const [bulk_detention, set_bulk_detention] = useState([]);

  useEffect(() => {
    if (PostLoad?.vehicleScreen?.addedVehicles) {
      let new_data = [...PostLoad?.vehicleScreen?.addedVehicles];
      new_data?.forEach((item) => {
        if (item.hasOwnProperty("detention_free_time")) {
        } else {
          item["detention_free_time"] = PostLoadMeta?.load_configurations?.free_time_setting || 24;;
          item["detention_included_transport"] = false;
          item["detention_rate"] = 0;
          item["is_genset"] = false;
          item["required_temperature"] = "";
        }
        item["fieldType"] = ["text", "text"]
      });
      setVehicles(new_data);
    }
    if (PostLoad?.vehicleScreen?.added_units?.length) {
      let new_data = [...PostLoad?.vehicleScreen?.added_units]
      new_data?.forEach((item) => {
        if (item.hasOwnProperty("detention_free_time")) {
        } else {
          item["detention_free_time"] = PostLoadMeta?.load_configurations?.free_time_setting || 24;
          item["detention_rate"] = 0;
          item["max_bid"] = "";
        }
      })
      set_added_units(new_data)
    }
  }, []);
  const handleDisabled = () => {
    if (load_type.title === LOAD_TYPE.fcl || PostLoad?.vehicleScreen?.values?.quote_by !== "weight_volume") {
      return vehicles?.filter((val) => (val.is_loading_required &&
        (Number(val.loading_price) === 0 || val.loading_price === '')) ||
        (val.is_unloading_required &&
          (Number(val.unloading_price) === 0 || val.unloading_price === "")) ||
        !val.price
      ).length
        ? true
        : false;
    } else if (PostLoad?.vehicleScreen?.is_unit_basis) {
      return added_units?.filter((val) => (val.is_loading_required &&
        (Number(val.loading_price) === 0 || val.loading_price === '')) ||
        (val.is_unloading_required &&
          (Number(val.unloading_price) === 0 || val.unloading_price === "")) ||
        !val.price
      ).length
        ? true
        : false;
    } else {
      return (vehicleScreen_values.is_loading_required &&
        (vehicleScreen_values.loading_price === 0 || vehicleScreen_values.loading_price == "")) ||
        (vehicleScreen_values.is_unloading_required &&
          (vehicleScreen_values.unloading_price === 0 || vehicleScreen_values.unloading_price == "")) ||
        !vehicleScreen_values.price ||
        !vehicleScreen_values.hasOwnProperty("detention_free_time") ||
        !vehicleScreen_values?.detention_free_time ||
        !vehicleScreen_values?.price_unit
        ? true
        : false;
    }
  };

  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);

  const load_type = postLoadMeta.load_types.find(
    (val: any) => val.id === PostLoad.load_type
  );
  let transaction_types_offer_id = postLoadMeta.transaction_types.find(
    (val: any) => val.title === "Offer"
  ).id;
  let transaction_types_bid_id = postLoadMeta.transaction_types.find(
    (val: any) => val.title === "Bid"
  ).id;

  const [info, setInfo] = useState("");
  const [showModal, toggleModal] = useState(false);
  const [additional_data, set_additional_data] = useState(PostLoad?.additionalScreen)

  useEffect(() => {
    set_additional_data(PostLoad?.additionalScreen)
  }, [])


//   const GreenRadio = withStyles({
//     root: {
//       color: green[400],
//       "&$checked": {
//         color: green[600],
//       },
//     },
//     checked: {},
//   })((props) => <Radio color="default" {...props} />);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value === "quote_price_inclusive_of_deposit_fee") {
      set_additional_data({ ...additional_data, is_quote_price_deposit_fee_inclusive: true })
    } else {
      set_additional_data({ ...additional_data, is_quote_price_deposit_fee_inclusive: false })
    }
  };

  

  const calculate_price = (key, value) => {
    if (key == "price_unit") {
      vehicleScreen_values[key] = value;
    } else {
      vehicleScreen_values[key] = Number(value);
    }
    let price_unit = vehicleScreen_values?.price_unit
    let total_freight_ton = Number(vehicleScreen_values["total_freight_ton"])
    let total_ton = Number(vehicleScreen_values['gross_weight'])
    let total_ton_kg = Number(vehicleScreen_values['gross_weight_in_kgs'])
    let total_cargo_liter = Number(vehicleScreen_values['cargo_liter'])
    let total_cbm = Number(vehicleScreen_values['cargo_volume'])
    let price = Number(vehicleScreen_values['price'])
    let loading_price = Number(vehicleScreen_values.loading_price);
    let un_loading_price = Number(vehicleScreen_values.unloading_price)

    if (price_unit == "Liter") {
      vehicleScreen_values['total_amount'] = (price * total_cargo_liter) + (loading_price * total_cargo_liter) + (un_loading_price * total_cargo_liter)
    } else if (price_unit == 'F.Ton') {
      vehicleScreen_values['total_amount'] = (price * total_freight_ton) + (loading_price * total_freight_ton) + (un_loading_price * total_freight_ton)
    } else if (price_unit == 'Kg') {
      vehicleScreen_values['total_amount'] = (price * total_ton_kg) + (loading_price * total_ton_kg) + (un_loading_price * total_ton_kg)
    } else if (price_unit == 'CBM') {
      vehicleScreen_values['total_amount'] = (price * total_cbm) + (loading_price * total_cbm) + (un_loading_price * total_cbm)
    } else {
      vehicleScreen_values['total_amount'] = (price * total_ton) + (loading_price * total_ton) + (un_loading_price * total_ton)
    }
    setVehicleScreenValues({ ...vehicleScreen_values });
  }


  return (
    <TitleLayout
      pageNumber={5}
      progress={50}
      title={`Transaction Type - ${load_type.title}`}
    >
      <FadeInComponent>
        <div className="tabWrapper">
          <RadioButton
            onChange={(value: any) => {
              dispatch(
                ProfileAction.postLoadData({
                  vehicleScreen: {
                    ...PostLoad.vehicleScreen,
                    addedVehicles: vehicles.map((item) => {
                      return {
                        ...item,
                        detention_rate: '',
                        detention_additional_charges: '',
                        price: '',
                        total_amount: '',
                        loading_price: '',
                        unloading_price: '',
                        max_bid: '',
                      }
                    }),
                    added_units: added_units.map((item) => {
                      return {
                        ...item,
                        price: '',
                        total_amount: '',
                        loading_price: '',
                        unloading_price: '',
                        detention_rate: '',
                        max_bid: '',
                      }
                    }),
                    values: {
                      ...PostLoad?.vehicleScreen?.values,
                      detention_rate: '',
                      detention_additional_charges: '',
                      price: '',
                      total_amount: '',
                      loading_price: '',
                      unloading_price: '',
                      max_bid: '',
                    }
                  },
                  transaction_type_id: transaction_types_bid_id,

                })
              );

              history.replace("/bid");
            }}
            value={transaction_type_id}
            id={"1"}
            name="testt"
            label="Bid"
          />
          <RadioButton
            onChange={(value: any) => {
              dispatch(
                ProfileAction.postLoadData({
                  transaction_type_id: transaction_types_offer_id,
                })
              );
            }}
            value={transaction_type_id}
            id={"0"}
            name="test"
            label="Offer"
          />
          <div className="radioTab_slider"></div>
        </div>
      </FadeInComponent>

      <div className="transactionTypeContainer">
        {!!vehicles.length && (
          <div className="cardWrapper">
            {vehicles.map((val, index) => (
              // 
              <div key={index}>
                <FadeInComponent>
                  <CategoryContainer
                    from={"offer"}
                    value={val}
                    index={index}
                    screenTitle={load_type.title}
                    onChangePrice={(
                      index,
                      value,
                      key,
                      is_string,
                    ) => {
                      if (load_type.title === LOAD_TYPE.fcl) {
                        if (is_string) {
                          vehicles[index][key] = value;
                        } else {
                          vehicles[index][key] = Number(value.replace(/,/g, ''));
                        }
                        let no_of_vehicle = vehicles[index]["vehicle_quantity"];
                        let price = vehicles[index]["price"]
                        let total = vehicles[index]["total_amount"]
                        let loading = vehicles[index]['loading_price']
                        let unloading = vehicles[index]['unloading_price']
                        if (val?.values?.is_twin_load == 1) {
                          total = (Number(loading) * Number(no_of_vehicle) * 2) + (Number(unloading) * Number(no_of_vehicle) * 2)
                            + Number(price) * Number(no_of_vehicle);
                        } else {
                          total = (Number(loading) * Number(no_of_vehicle)) + (Number(unloading) * Number(no_of_vehicle))
                            + Number(price) * Number(no_of_vehicle);
                        }
                        vehicles[index]["total_amount"] = total
                        setVehicles([...vehicles]);
                      } else {
                        if (is_string) {
                          vehicles[index][key] = value
                        } else {
                          vehicles[index][key] = Number(value.replace(/,/g, ''));
                        }
                        let no_of_vehicle = vehicles[index]["vehicle_quantity"];
                        let price = vehicles[index]["price"]
                        let total = vehicles[index]["total_amount"]
                        let loading = vehicles[index]['loading_price']
                        let unloading = vehicles[index]['unloading_price']
                        if (val?.values?.is_twin_load == 1) {
                          total = (Number(loading) * Number(no_of_vehicle) * 2) + (Number(unloading) * Number(no_of_vehicle) * 2)
                            + Number(price) * Number(no_of_vehicle);
                        } else {
                          total = (Number(loading) * Number(no_of_vehicle)) + (Number(unloading) * Number(no_of_vehicle))
                            + Number(price) * Number(no_of_vehicle);
                        }
                        // total = (Number(loading) * Number(no_of_vehicle)) + (Number(unloading) * Number(no_of_vehicle)) + Number(price) * Number(no_of_vehicle);
                        vehicles[index]["total_amount"] = total
                        setVehicles([...vehicles]);
                      }
                    }}
                    categoryLength={vehicles.length}
                  />
                </FadeInComponent>
              </div>
            ))}
            {
              PostLoad?.load_type == 1 && additional_data?.is_container_deposit_by_transporter ?
                <div style={{
                  width: '100%'
                }}>
                  <FadeInComponent>
                    <div style={{
                      marginTop: 0,

                    }} className="filterBy customRadioCheck">
                      <Radio
                        checked={additional_data?.is_quote_price_deposit_fee_inclusive && "quote_price_inclusive_of_deposit_fee"}
                        handleChange={handleChangeRadio}
                        value="quote_price_inclusive_of_deposit_fee"
                        labelName="radio-button-demo"
                        inputProps={{ "aria-label": "quote_price_inclusive_of_deposit_fee" }}
                        title="Quote price inclusive of deposit fee"
                      />
                      <label className="label">
                        Quote price inclusive of deposit fee
                      </label>
                    </div>
                  </FadeInComponent>
                  <FadeInComponent>
                    <div style={{
                      marginTop: 0
                    }} className="filterBy customRadioCheck">
                      <Radio
                        checked={!additional_data?.is_quote_price_deposit_fee_inclusive && "quote_deposit_fee_separately"}
                        onChange={handleChangeRadio}
                        value={"quote_deposit_fee_separately"}
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "quote_deposit_fee_separately" }}
                      />
                      <label className="label">
                        Quote deposit fee separately
                      </label>
                    </div>
                  </FadeInComponent>
                </div> : null
            }
          </div>
        )}

        {PostLoad?.vehicleScreen?.values?.quote_by &&
          PostLoad?.vehicleScreen?.values?.quote_by === "weight_volume" && !PostLoad?.vehicleScreen?.is_unit_basis && (
            <FadeInComponent>
              <div className="cardWrapper">
                <CargoContainer
                  value={vehicleScreen_values}
                  title={load_type.title}
                  screen={"offer"}
                  showTime={true}
                  vehicleScreenData={PostLoad?.vehicleScreen || null}
                  onChangeValue={(key: any, value: any) => {
                    vehicleScreen_values[key] = value;
                    if (key == "price_unit") {
                      vehicleScreen_values['total_amount'] = 0
                      calculate_price(key, value)
                    }
                    if (key !== "price_unit") {
                      setVehicleScreenValues({ ...vehicleScreen_values });
                    }
                  }}
                  onChangeBulkFields={(ind: any, value) => {
                    let new_data = [...bulk_detention];
                    if (value?.rate !== "") {
                      new_data[ind] = value;
                    } else {
                      new_data.splice(ind);
                      delete vehicleScreen_values[ind];
                    }
                    set_bulk_detention(new_data);
                  }}
                  bulk_detention={bulk_detention}
                  onChangePrice={(value: string, key, is_string) => {
                    if (is_string) {
                      vehicleScreen_values[key] = value;
                      setVehicleScreenValues({ ...vehicleScreen_values });
                    } else {
                      calculate_price(key, value)
                    }
                  }}
                />
              </div>
            </FadeInComponent>
          )}

        {
          PostLoad?.vehicleScreen?.is_unit_basis &&
          <div className="cardWrapper">
            {
              added_units?.map((item, index) => {
                return (
                  <FadeInComponent>
                    <UnitCategory
                      value={item}
                      index={index}
                      added_units={added_units}
                      onChangePrice={(index, value, key, flag) => {
                        let new_data = [...added_units]
                        if (flag) {
                          new_data[index][key] = value;
                        } else {
                          new_data[index][key] = Number(value);
                        }
                        // new_data[index].total_amount =
                        //   (Number(new_data[index].loading_price) +
                        //     Number(new_data[index].unloading_price) +
                        //     Number(new_data[index].price)) *
                        //   Number(new_data[index].unit_quantity);
                        new_data[index].total_amount = (Number(new_data[index].price) * Number(new_data[index].unit_quantity)) + (Number(new_data[index].loading_price) * Number(new_data[index].unit_quantity)) + (Number(new_data[index].unloading_price) * Number(new_data[index].unit_quantity))
                        set_added_units(new_data);

                      }}
                      from={"offer"}
                    />
                  </FadeInComponent>
                )
              })
            }
          </div>
        }
      </div>

      <PrimaryButton
        title="Continue"
        rightIcon={true}
        disabled={handleDisabled()}
        onClick={() => {
          vehicleScreen_values[
            "vehicle_detention_rates"
          ] = bulk_detention;
          dispatch(
            ProfileAction.postLoadData({
              transaction_type_id: transaction_types_offer_id,
              vehicleScreen: {
                ...PostLoad.vehicleScreen,
                values: vehicleScreen_values,
                added_units

              },
            })
          );
          history.push(`/spot-location/add-new`, { from: 'spot' });
        }}
      />

      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showModal}
        toggleModal={toggleModal}
      >
        <div>
          <InfoIcon className="icon" />
          <p className="title">Information</p>
          <p>
            {info}
          </p>
        </div>
      </CustomModal>
    </TitleLayout>
  );
};
export default Page;
