import React, { FC } from "react";
import styled from "styled-components";
import { Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { DocumentsTable, OutLineButton } from "@components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
} from "@mui/material/";
import { ExitToAppRounded, ExpandMore } from "@mui/icons-material";
import { ConfirmLoadProps } from "./types";
import { useSelector } from "react-redux";
export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 17px;
  border-radius: 10px;
`;



export const UploadedDocuments: FC<ConfirmLoadProps> = ({
  value,
  editable,
  fromHistory,
  is_recipient
}: ConfirmLoadProps) => {

  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);

  const history = useHistory();
  const onEdit = () => {
    history.push("/uploadedDocument");
    // if (
    //   value.documents &&
    //   (value.documents.bill_of_ladings.length ||
    //     value.documents.other_uploads.length ||
    //     value.documents.packing_lists.length)
    // ) {
    //   history.push("/uploadedDocument");
    // } else {
    //   history.push("/uploadDoc");
    // }
  };
  return (
    <>
      <Accordion className="confirm-load-accordion" style={{ backgroundColor: "#FAFAFA" }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className="fw-6">
            Uploaded Documents
            {
              fromHistory && is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel"> اپ لوڈ کردہ دستاویزات</span>
              </>
            }
          </p>
          {
            editable ?
              <div className="editBtnWrapper">
                <OutLineButton
                  classProp='tinyBtn'
                  dataColor="blue"
                  title="Edit"
                  icon={
                    <span className="icons-edit"></span>
                  }
                  onClick={onEdit}
                />
              </div> : null
          }
        </AccordionSummary>
        <AccordionDetails className="customAccordian">
          <div className="documentContainer">
            <>
              {value?.documents?.bill_of_ladings?.length ||
                value?.documents?.packing_lists?.length ||
                value.documents.other_uploads.length ?
                <>
                  {value?.documents?.bill_of_ladings?.length ?
                    <div className="uploadDocumentFileBoxWrap">
                      {value?.documents?.bill_of_ladings?.length ?
                        <DocumentsTable
                          values={
                            value?.documents?.bill_of_ladings.length
                              ? value?.documents?.bill_of_ladings
                              : null
                          }
                          titleIcon={Images.bill}
                          title={PostLoad?.documents?.bill_of_ladings[0]?.file_name}
                          datas={
                            {
                              key: "bill_of_ladings",
                              icon: Images.bill,
                              title: "Bill of Lading"
                            }
                          }
                        />
                        : null}

                    </div>
                    : null}

                  {value?.documents?.packing_lists?.length ?
                    <div className="uploadDocumentFileBoxWrap">

                      {value?.documents?.packing_lists?.length ?

                        <DocumentsTable
                          titleIcon={Images.list}
                          values={
                            value?.documents?.packing_lists.length
                              ? value?.documents?.packing_lists
                              : null
                          }
                          title={PostLoad?.documents?.packing_lists[0]?.file_name}
                          datas={
                            {
                              key: "packing_lists",
                              icon: Images.list,
                              title: "Packing List",
                            }
                          }
                        />
                        : null}

                    </div>
                    : null}

                  {value.documents.other_uploads.length ?
                    <div className="uploadDocumentFileBoxWrap">
                      <DocumentsTable
                        titleIcon={Images.docOther}
                        values={PostLoad?.documents?.other_uploads.length
                          ? PostLoad?.documents?.other_uploads
                          : null}
                        title={PostLoad?.documents?.other_uploads[0]?.files[0]?.file_name}
                        datas={
                          {
                            key: "other_uploads",
                            icon: Images.docOther,
                            title: "",
                          }
                        }
                        from={'other'}
                      />
                    </div>
                    : null}
                </> : "No Document Uploaded"}
            </>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default UploadedDocuments;
