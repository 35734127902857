import React from "react";
import "./style.scss";
import { IProps } from "./types";

export const SignupContainer: React.FC<IProps> = ({
  containerHeight,
  children,
}: IProps) => {
  return (
    <div style={{ height: containerHeight }} className="signup-container">
      {children}
    </div>
  );
};

export default SignupContainer;
