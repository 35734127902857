import React from "react";

import "./scss/index.module.scss";
import { Message } from "@components";
import { IProps } from "./types";

const NotificationTemplate: React.FC<IProps> = ({
  message,
  options,
  close,
}) => {
  return (
    <Message
      actionText={message.actionText}
      status={options.type}
      title={message.title}
      onClick={close}
    >
      {message.content}
    </Message>
  );
};

export default NotificationTemplate;
