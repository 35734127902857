import { Accordion, AccordionSummary } from "@mui/material/";
import { Colors } from "@temp/assets";
import { CustomText } from "@temp/components";
import React from "react";
import "../LoadDetailPoster/style.scss";
import { RenderRTL } from "./RenderRTL";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../LoadDetailPoster/style.scss";

export const TransactionType = ({ loadDetail, is_recipient = false }) => {
  return (
    <div className="accordion-container">
      <Accordion
        className="accordion-background">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <CustomText.OpenSansSemiBold
            textStyle={{
              fontSize: "0.875rem",
              color: Colors.Black,
            }}
          >
            <span>Transaction Type</span>
            {
              is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">ریٹ کی قسم</span>
              </>
            }
          </CustomText.OpenSansSemiBold>
        </AccordionSummary>
        <div className="accordion-content">
          <RenderRTL
            title="Type"
            sep="/"
            urdutitle="قسم"
            val={`${loadDetail?.transaction_type?.title}`}
            is_recipient={is_recipient}
          />
          <div style={{ height: 16 }}></div>
        </div>
      </Accordion>
    </div>
  );
};
