import { Images } from "@temp/assets";
import LoadCard from "@temp/components/LoadCard";
import * as React from "react";
import styled from "styled-components";
import { LoadTypeProps } from "./types";

export const Icon = styled.img`
  height: 42px;
  align-self: center;
  justify-self: flex-end;
`;

export const Maincontainer = styled.div<{ selected: boolean }>`
  margin: auto;
  max-width: 327px;
  width: 284px;
  height: 122px;
  display: block !important;
  border-radius: 12px;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 12px;
  font-family: var(--font-family);
  padding: 15px;
  border: 2px solid transparent;
  border-color: ${({ selected }) => (selected ? "#B0D792" : "transparent")};
  margin-bottom: 20px
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 18px;
  color: #fff;
  margin-top:15px
`;

export const SubTitle = styled.div`
  font-size: 12px;
  color: #fff;
  width: 50%;
  margin-top: 5px
`;

export const LoadTypeComponent = ({
  id,
  item,
  onClick,
  img,
  load_type,
}: LoadTypeProps) => {
  const onCickLoad = () => {
    onClick(id);
  };
  return (
    <>
      <LoadCard
        selected={id === load_type}
        title={item.title} 
        subtitle={item.sub_title}
        img={img} 
        onClick={onCickLoad}
        imgStyle={{
          width: item.title == 'Containerized' ? '60%' : '60%',
          marginBottom: 18,
          marginLeft: -8
        }}
      />
      {/* <Maincontainer onClick={onCickLoad} selected={id === load_type} key={id} data-type={item.title}>

        {item.title === "Containerized" ?
          <Title>Containerized Cargo</Title>
          :
          <Title>Non-Containerized Cargo</Title>}

        {item.sub_title === "Vehicles for container movement" ?
          <SubTitle>Vehicles For Transporting Your Containers</SubTitle>
          :
          <SubTitle>Open / Box Vehicles For Loose / Bulk Cargo</SubTitle>}
      </Maincontainer> */}
    </>
  );
};

export default LoadTypeComponent;
