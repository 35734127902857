import React, { useEffect, useState } from "react";
import { ILoadDetailsPageProps } from "./types";
import "./style.scss";
import {
  CustomText,
  PrimaryButton,
  TitleLayout,
  MaterialUIPickers,
  CustomModal,
  Counter,
} from "@components";
import { Colors, Images } from "@temp/assets";
import { Accordion, AccordionSummary } from "@mui/material/";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory, useParams } from "react-router-dom";
import { LoadAction, ProfileAction } from "@temp/store/actions";
import { showMessage, getDate, getTime, getFullTime, isObjectEmpty, getMillisec } from "@temp/utils/helper";
import { RenderRecipient } from "../components/RenderRecipient";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import { RenderRecipientLCL } from "../components/RenderRecipientLCL";
import {
  AdditionalDetail,
  PaymentTerms,
  TwoColumnRTLContractVehicle,
  TwoColumnRTLContractWeight,
  UploadedDocuments,
} from "../components";
import { isBrowser } from "react-device-detect";
import { sendEventToAndroid, sendEventToiOS } from "../../../utils/mobileEventHandler";
import { REQUEST_VEHICLE_LOCATIONS } from "@temp/app/routes";
import { ExitToAppRounded } from "@mui/icons-material";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { convertDataForFclAndBulkLoadDetailRedux, convertDataForLoadDetailRedux } from "@temp/utils/dataFormat";
import { LOAD_TYPE } from "@temp/constants";
import PullToRefresh from "react-simple-pull-to-refresh";
import dayjs from "dayjs";

// import PullToRefresh from "react-pull-to-refresh";


const Page: React.FC<ILoadDetailsPageProps> = (props) => {
  const dispatch = useDispatch();
  const params: any = useParams();
  useEffect(() => {
    setTimeout(() => {
      dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
    }, 100);
  }, []);
  const loadDetail = useSelector((state: RootState) => state.Load.loadDetail);
  const [expand, setExpand] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [responseDate, setResponseDate] = useState(null);
  const [responseTime, setResponseTime] = useState(null);
  const [awardLcl, setAwardLcl] = useState([]);
  const [awarded_contract, setAwardedContract] = useState([]);
  const [isDeadlineComplete, setIsDeadlineComplete] = useState(false);

  const postLoadMeta = useSelector((state: RootState) => state?.Profile?.postLoadMeta);
  const PostLoad = useSelector((state: RootState) => state?.Profile?.postLoad);
  const load_type = postLoadMeta?.load_types?.find((val: any) => val?.id === PostLoad?.load_type);
  const packageType = postLoadMeta.package_types;
  const inquiry_type = postLoadMeta.inquiry_types;
  const PreferredVehicleType = postLoadMeta?.preferred_vehicles;
  const metaData = useSelector((state: RootState) => state.Auth.metaData);

  useEffect(() => {
    if (loadDetail?.is_load_history) {
      // setShowModalNoti(true)
      cloneALoadCallBackLoadHistory(loadDetail)
    }
  }, [loadDetail])

  const cloneALoadCallBackLoadHistory = (loadDetailHistory: any) => {
    // setShowModalNoti(false)
    const callBack = () => {
      if (!isObjectEmpty(loadDetailHistory)) {
        let data = {};
        if (
          loadDetailHistory.load_type.title === LOAD_TYPE.bulk ||
          loadDetailHistory.load_type.title === LOAD_TYPE.bulk
        ) {
          data = convertDataForFclAndBulkLoadDetailRedux(
            loadDetailHistory,
            packageType,
            load_type,
            inquiry_type,
            PreferredVehicleType,
            "history",
            "Loads Posted"
          );
        } else {
          data = convertDataForLoadDetailRedux(
            loadDetailHistory,
            packageType,
            load_type,
            inquiry_type,
            "history",
            "Loads Posted"
          );
        }
        
        // return
        if (!isObjectEmpty(data)) {
          dispatch(ProfileAction.postLoadData({ ...data }));
          history.push("/confirm-load", { from_load_history: true, fromNoti: true });
        }
      }
    }
    dispatch(ProfileAction.JobLoadMeta(callBack));
  };

  const getMillisec = (t1, t2) => {
    let new_date = moment(`${dayjs(t1).format('YYYY-MM-DD')} ${dayjs(t2).format('HH:mm:ss')}`).format('x')
    return Number(new_date)
  }

  const updateDeadlineResponse = () => {
    // let final_time = String(getDate(responseDate)) + " " + String(getTime(responseTime));
    let final_time = String(getDate(responseDate)) + " " + dayjs(responseTime).format("HH:mm:ss");
    if (getMillisec(responseDate, responseTime) > (new Date().getTime() + 5000)) {
      setShowModal(false);
      dispatch(
        LoadAction.updateResponseDeadline({
          deadline_at: final_time,
          load_id: loadDetail?.id,
        })
      );
    } else {
      showMessage.current({
        message: "Response time should be greater than current time.",
        status: "error",
      });
    }
  };

  useEffect(() => {
    setResponseDate(new Date(loadDetail?.deadline_at));
    setResponseTime(new Date(loadDetail?.deadline_at));
  }, [loadDetail]);

  // click award check box
  const clickCheckbox = (data) => {
    const new_data = [...awarded_contract];
    const find = new_data?.find((item) => item?.job_load_recipient_bid_id == data?.job_load_recipient_bid_id)
    const findIndex = new_data?.findIndex((item) => item?.job_load_recipient_bid_id == data?.job_load_recipient_bid_id)
    if (find) {
      new_data.splice(findIndex, 1)
    } else {
      new_data.push({
        job_load_recipient_bid_id: data?.job_load_recipient_bid_id,
        bid: data?.bid,
        awarded_freight_ton: null,
        no_of_vehicles: null
      })
    }
    setAwardedContract(new_data)
  };

  // handle change while awarding weight volume
  const hangleChangeFrightTon = (data) => {
    let new_data = [...awarded_contract];
    const findIndex = new_data?.findIndex((item) => item?.job_load_recipient_bid_id == data?.job_load_recipient_bid_id)
    new_data[findIndex]["awarded_freight_ton"] = data?.e.target.value;
    if (
      Number(new_data[findIndex]["awarded_freight_ton"]) >
      Number(new_data[findIndex]?.bid?.bid_freight_ton)
    ) {
      showMessage.current({
        message:
          "Freight ton should not be greater than the freight ton offered by recipient.",
        status: "error",
      });
      new_data[findIndex]["awarded_freight_ton"] =
        Number(new_data[findIndex]?.bid?.bid_freight_ton);
    }
    setAwardLcl(new_data);
  };

  // disable award button 
  const handleDisable = () => {
    if (loadDetail?.quote_by == "vehicle" || (loadDetail?.quote_by == "weight_volume" && loadDetail?.is_unit_basis)) {
      return awarded_contract.length ? false : true
    } else if (loadDetail?.quote_by == "weight_volume" && !loadDetail?.is_unit_basis) {
      return awarded_contract?.length ? false : true
    }
  }

  const handleRefresh = async () => {
    dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
  }

  // award api call when qoute by vehicle
  const awardContract = () => {
    let payload = {
      load_id: loadDetail?.id,
      awarded_contract
    }
    dispatch(LoadAction.awardContract(payload))
    setAwardedContract([])
  };

  const declineByPoster = (id) => {
    dispatch(
      LoadAction.posterDeclined({
        load_id: params?.id,
        job_load_recipient_id: id,
      })
    );
  };

  const renderComplete = () => {
    setIsDeadlineComplete(true);
  }

  const renderRTL = (key, val) => {
    return (
      <div className="formGrid">
        <div className="grayLabel">{key}</div>
        <div className="fw-5">{val}</div>
      </div>
    );
  };

  let notIsDeadline = getFullTime(loadDetail?.deadline_at) > getFullTime(new Date())


  const RenderResponseDeadline = ({ date, completed, iconComponent }: any) => {
    if (moment(date) >= moment()) {
      setIsDeadlineComplete(false);
    } else {
      setIsDeadlineComplete(true);
    }
    return !isDeadlineComplete ? (
      <div className="details-Content" style={{ marginTop: 0, flexDirection: 'column' }}>
        <div className="" style={{ paddingRight: 5 }}>
          <p className="customCounterTitle fw-5">
            Bidding Response Deadline
          </p>
        </div>
        <Counter date={moment(date).toDate()} renderComplete={renderComplete} iconComponent={iconComponent} />
      </div>
    ) : (
      null
    );
  };

  const [contract_locations, set_contract_locations] = useState([])

  useEffect(() => {
    if (loadDetail) {
      set_contract_locations(loadDetail?.contract_locations?.map((item) => { return { ...item, new_cat: [] } }))
    }
  }, [loadDetail])

  let awarded_recipients = []
  if (loadDetail) {
    loadDetail?.contract_locations?.forEach(location => {
      if (location?.categories && location?.categories.length) {
        location?.categories?.forEach(category => {
          category?.recipients?.forEach(recipient => {
            awarded_recipients.push(recipient)
          })
        })
      } else {
        location?.recipients?.forEach(recipient => {
          awarded_recipients.push(recipient)
        })
      }
    });
    if (awarded_recipients.length) {
      awarded_recipients = awarded_recipients.filter(item => item?.status == 4)
    }
  }

  const history = useHistory()

  if (loadDetail) {
    let isDeadline = new Date().getTime() < new Date(loadDetail?.deadline_at).getTime()
    return (
      <TitleLayout progressFlag={true} progress={0} titleOnly titleOnlyText={`Contract ID- ${loadDetail?.id}`}>
        {params?.id &&
          (params?.type == "received" || params?.type === "posted") ? (
          <PullToRefresh
            onRefresh={handleRefresh}
          >
            <div className="loadDetailWrapper">
              {/* load detail */}

              {/*response deadline*/}
              {
                loadDetail?.next_action_step ?
                  <div className="error-container info">
                    <div>
                      <InfoIcon className="error-icon" />
                    </div>
                    <div className="error-text">
                      {loadDetail?.next_action_step}
                    </div>
                  </div>
                  : null
              }
              <div
                style={{
                  paddingBottom: 10,
                  border: "none",
                  justifyContent: "space-between",
                }}
                className=""
              >
                <FadeInComponent>
                  <RenderResponseDeadline
                    date={loadDetail?.deadline_at}
                    iconComponent={
                      isDeadline && (
                        <div
                          style={{
                            width: "5%",
                            marginLeft: 5
                          }}
                          className="three-column-rtl-inner"
                        >
                          <div
                            onClick={() => {
                              setShowModal(!showModal);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <span className="icons-edit"></span>
                          </div>
                        </div>
                      )}
                  />
                </FadeInComponent>
              </div>

              {
                awarded_recipients.length ? (
                  <FadeInComponent>
                    <PrimaryButton
                      buttonStyle={{
                        marginBottom: 20,
                      }}
                      title="Request Vehicle"
                      onClick={() => {
                        history.push(REQUEST_VEHICLE_LOCATIONS, {
                          data: loadDetail,
                        });
                      }}
                    />
                  </FadeInComponent>
                ) : null
              }

              <FadeInComponent>
                <div className="accordion-container">
                  <Accordion
                    onChange={() => setExpand(!expand)}
                    expanded={expand}
                    className="accordion-background"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <CustomText.OpenSansSemiBold
                        textStyle={{
                          fontSize: "0.875rem",
                          color: Colors.Black,
                        }}
                      >
                        Contract Details
                      </CustomText.OpenSansSemiBold>
                    </AccordionSummary>
                    {
                      
                    }
                    <div className="accordion-content">
                      {[
                        renderRTL(
                          "Contract Name",
                          `${loadDetail?.contract_name}`
                        ),

                        renderRTL(
                          "Reference Number",
                          `${loadDetail?.ref_no || "--"}`
                        ),
                        renderRTL(
                          "Inquiry Type",
                          `${loadDetail?.inquiry_type?.title}`
                        ),
                        renderRTL("Contract Type", `${loadDetail?.load_type?.title}`),
                        loadDetail?.cargo_description ?
                          renderRTL("Cargo Description", `${loadDetail?.cargo_description}`) : null,


                        // renderRTL("Quote Type",
                        //   `${loadDetail?.quote_by == "vehicle" ? "Vehicle" : "Weight Volume"}`),
                      ]}

                      <div className="grayLabel"
                        style={{ marginBottom: 4 }}>
                        Contract Period
                      </div>

                      <div className="contract-period-detail">
                        <CustomText.OpenSansSemiBold textStyle={{
                          color: "#8A8888",

                        }}>Start Date</CustomText.OpenSansSemiBold>

                        <CustomText.OpenSansSemiBold textStyle={{ marginLeft: 5 }}>
                          {`${moment(
                            loadDetail?.contract_start_date
                          ).format(
                            "DD MMM, YYYY"
                          )}`}
                        </CustomText.OpenSansSemiBold>
                      </div>

                      <div className="contract-period-detail">
                        <CustomText.OpenSansSemiBold textStyle={{
                          color: "#8A8888"
                        }}> End Date
                        </CustomText.OpenSansSemiBold>

                        <CustomText.OpenSansSemiBold textStyle={{ marginLeft: 5 }}>
                          {`${moment(
                            loadDetail.contract_end_date
                          ).format(
                            "DD MMM, YYYY"
                          )}`}
                        </CustomText.OpenSansSemiBold>
                      </div>

                      <div style={{ height: 16 }}></div>
                    </div>
                  </Accordion>
                </div>
              </FadeInComponent>

              {/* Payment Terms */}
              <FadeInComponent>
                <PaymentTerms loadDetail={loadDetail} />
              </FadeInComponent>

              {/* Additional detail */}
              <FadeInComponent>
                <AdditionalDetail loadDetail={loadDetail} fromPoster={true} />
              </FadeInComponent>

              {/* Transaction type */}
              <FadeInComponent>
                <div className="accordion-container">
                  <Accordion className="accordion-background">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <CustomText.OpenSansSemiBold
                        textStyle={{
                          fontSize: "0.875rem",
                          color: Colors.Black,
                        }}
                      >
                        Transaction Type
                      </CustomText.OpenSansSemiBold>
                    </AccordionSummary>
                    <div className="accordion-content">
                      {[
                        renderRTL("Type", `${loadDetail?.transaction_type?.title}`),
                      ]}
                    </div>
                  </Accordion>
                </div>
              </FadeInComponent>

              {/* Render Categories */}
              <div>
                {contract_locations?.map((location, index) => {
                  
                  return (
                    <FadeInComponent key={index}>
                      <div className="accordion-container">
                        <Accordion className="accordion-background">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => {
                              if (!location?.is_data) {
                                location['new_cat'] = location?.categories
                                set_contract_locations([...contract_locations])
                                location['is_data'] = true
                              } else {
                                location['new_cat'] = []
                                set_contract_locations([...contract_locations])
                                location['is_data'] = false
                              }
                            }}
                          >
                            <CustomText.OpenSansSemiBold
                              textStyle={{
                                fontSize: "0.875rem",
                                color: Colors.Black,
                              }}
                            >
                              {/* Location {` ${index + 1}`} */}
                              Location {` ${index + 1} : ${location?.pickup?.title} - ${location?.dropoff?.title} `}

                            </CustomText.OpenSansSemiBold>
                          </AccordionSummary>

                          {/* <div>
                          <CustomText.OpenSansSemiBold
                            textStyle={{
                              fontSize: 14,
                              color: Colors.Black,
                              marginLeft:17
                            }}
                          >
                          {`${location?.pickup?.title} - ${location?.dropoff?.title}`}
                          </CustomText.OpenSansSemiBold>
                          </div> */}

                          <div className="accordion-content">
                            <div className="load-detail-location-container">
                              <div className="load-detail-location-info">
                                {loadDetail?.contract_locations.length && (
                                  <>
                                    <div className="pickup">
                                      <div className="load-detail-dots-container">
                                        <div className="load-detail-location-start">
                                          <div className="load-detail-location-start-inner"></div>
                                        </div>
                                        <div className="load-detail-location-end">
                                          <div className="load-detail-location-end-inner"></div>
                                        </div>
                                      </div>
                                      <div>
                                        <CustomText.OpenSansSemiBold>
                                          {location?.pickup?.title || ""}
                                        </CustomText.OpenSansSemiBold>
                                        <CustomText.OpenSansRegular>
                                          {location?.pickup?.raw_address}
                                        </CustomText.OpenSansRegular>
                                      </div>
                                    </div>
                                    <div className="dropoff">
                                      <CustomText.OpenSansSemiBold>
                                        {location?.dropoff?.title || ""}
                                      </CustomText.OpenSansSemiBold>
                                      <CustomText.OpenSansRegular>
                                        {location?.dropoff?.raw_address}
                                      </CustomText.OpenSansRegular>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          <div style={{ marginTop: 10 }} className="accordion-content">
                            {loadDetail?.transaction_type?.title == "Bid" && notIsDeadline && (
                              <>
                                <div className="dividing-bordered"></div>
                                <div className="error-container info" style={{ marginBottom: '1rem' }}>
                                  <div>
                                    <InfoIcon className="error-icon" />
                                  </div>
                                  <div className="error-text">
                                    Note: You will be able to see the Bid
                                    responses after the response deadline
                                    has passed.
                                  </div>
                                </div>
                              </>
                            )}
                            {location?.categories?.length ? (
                              <>
                                {location?.new_cat?.map((item, index) => {
                                  let final_category = item;
                                  return (
                                    <>
                                      <div>
                                        <div style={{
                                          marginTop: 15
                                        }} className="category-title-container">
                                          <div className="fw-5 mb-3">
                                            {
                                              loadDetail?.quote_by == 'vehicle' ?
                                                <>
                                                  {location?.new_cat.length > 1 ? ` Vehicle Category ${index + 1}` : " Vehicle Category"}
                                                </> :
                                                <>
                                                  {location?.new_cat.length > 1 ? ` Item Category ${index + 1}` : " Item Category"}
                                                </>
                                            }

                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <TwoColumnRTLContractVehicle
                                          loadDetail={loadDetail}
                                          category={item}
                                          location={location}
                                          type={"posted"}
                                        />
                                        <div className="dividing-bordered mb-0"></div>
                                      </div>

                                      {/* Render Recipients */}
                                      <div className="">
                                        {item?.recipients?.map((item2, index2) => {
                                          return (
                                            <div key={index2}>
                                              <RenderRecipient
                                                item2={item2}
                                                cat_id={item?.id}
                                                total_vehicle={
                                                  item?.vehicle_quantity
                                                }
                                                index={index + 1}
                                                declineByPoster={declineByPoster}
                                                loadDetail={loadDetail}
                                                location={location}
                                                final_category={final_category}
                                                awarded_contract={awarded_contract}
                                                clickCheckbox={clickCheckbox}
                                                companyName={item2?.recipient?.company[0]?.name}
                                                isDeadlineComplete={isDeadlineComplete}
                                           
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                {/* for lcl and bulk qoute by weight */}
                                <div className="">
                                  <div className="">
                                    <div>
                                      <div className="category-title-container mb-3">
                                        <CustomText.OpenSansSemiBold
                                          textStyle={{
                                            fontSize: "0.875rem",
                                            color: Colors.Black,
                                          }}
                                        >
                                          Cargo Details
                                        </CustomText.OpenSansSemiBold>
                                        <div className="chip">
                                          <span>
                                            {
                                              location?.pickup?.attributes
                                                ?.no_of_packages
                                            }{" "}
                                            Packages
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <TwoColumnRTLContractWeight
                                        loadDetail={loadDetail}
                                        location={location}
                                        type={"posted"}
                                      />
                                      <div className="dividing-bordered"></div>
                                    </div>

                                    {/* Render Recipients */}
                                    <div className="">
                                      {location?.recipients?.map(
                                        (item2, index2) => {
                                          let copy = JSON.parse(
                                            JSON.stringify(item2)
                                          );
                                          return (
                                            <div key={index2}>
                                              <RenderRecipientLCL
                                                item2={item2}
                                                copy_item={copy}
                                                cat_id={loadDetail?.id}
                                                total_vehicle={
                                                  loadDetail?.vehicle_quantity
                                                }
                                                index={loadDetail}
                                                declineByPoster={declineByPoster}
                                                loadDetail={loadDetail}
                                                contract_location={
                                                  location?.pickup?.id
                                                }
                                                awardedLCL={awarded_contract}
                                                handleChange={hangleChangeFrightTon}
                                                clickCheckboxAwardContract={
                                                  clickCheckbox
                                                }
                                                companyName={item2?.recipient?.company[0]?.name}
                                                isDeadlineComplete={isDeadlineComplete}
                                                location={location}
                                                price_unit={location?.pickup?.attributes?.price_unit}
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>


                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Accordion>
                      </div>
                    </FadeInComponent>
                  );
                })}
              </div>

              {/* Uploaded Documents */}
              <FadeInComponent>
                <UploadedDocuments loadDetail={loadDetail} />
              </FadeInComponent>

              {/* Award button vehicle base */}
              <FadeInComponent>
                <div className="accordion-content">
                  {
                    awarded_contract?.length ?
                      <PrimaryButton
                        disabled={handleDisable()}
                        buttonStyle={{ height: 44, marginBottom: 20, marginTop: 20, }}
                        title="award contract"
                        onClick={() => {
                          awardContract();
                        }}
                      /> : null
                  }
                </div>
              </FadeInComponent>
            </div>
          </PullToRefresh>

        ) : null}

        <CustomModal showModal={showModal} toggleModal={setShowModal}>
          <div className="DatePickerFieldWrap">
            <MaterialUIPickers
              date={responseDate}
              setDate={(e) => {
                setResponseDate(e);
              }}
              setTime={(e) => {
                setResponseTime(e);
              }}
              time={responseTime}
              minDate={new Date()}
              maxDate={"3050-01-01"}
            />
          </div>
          <PrimaryButton
            buttonStyle={{ height: 40, marginTop: 12 }}
            title="Save"
            onClick={() => {
              updateDeadlineResponse();
            }}
          />
        </CustomModal>

      </TitleLayout>
    );
  } else {
    return <div></div>;
  }
};
export default Page;