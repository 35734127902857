import { HELPER } from "./helper";
import { isAndroid, isIOS } from "react-device-detect";

/// handler used in mobile events
export const sendEventToAndroid = functionType => {
  return isAndroid && window.Android && functionType in window.Android;
};

export const sendEventToiOS = functionType => {
  return isIOS && functionType in window.webkit.messageHandlers
};

