import React, { FC } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { Images } from "@temp/assets";
import EditIcon from "@mui/icons-material/Edit";
import { InnerAdditionalDetail } from "./innerAdditionalDetail";

import "./style.scss";
import { IProps } from "./types";
import { useHistory } from "react-router";
import { isBrowser } from "react-device-detect";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../utils/mobileEventHandler";
import { phoneNumberFormater } from "@temp/utils/helper";
import { AdditionalPickupDropoff } from "../AdditionalPickupDropoff";

export const LocationTimeline: FC<IProps> = ({
  handleModal,
  location,
  disabled,
  innerAddDetail,
  handleAdditionalDetail,
  enableEdit,
  id,
  showTitle = true,
  fromActive,
  LoadDetail,
  type,
  recipient_status,
  is_recipient,
  is_viewer,
  fromHistory


}: IProps) => {

  let locations = location;
  const history = useHistory();
  // Function only trigger on Contract Location Screen
  const handleEditLocation = () => {
    if (innerAddDetail) {
      history.push(`/contract-location/add-new`, { id, from: 'contract' });
    }
  };

  return (
    // <div></div>
    <Timeline>
      {locations.map((loc, index) => {
        let lastConnector = locations.length - 1 === index;
        return (
          <div
            className={
              index === disabled ? "mapItem noPointer" : "mapItem pointer"
            }
            onClick={() => {
              handleModal(index);
              handleEditLocation();
            }}
            style={{ pointer: 'cursor' }}
            key={index}
          >
            <TimelineItem>
              <TimelineSeparator>
                {index === 0 ? (
                  <div className="location-start">
                    <div className="location-start-inner"></div>
                  </div>
                ) : (
                  <div className="location-end">
                    <div className="location-end-inner"></div>
                  </div>
                )}
                {!lastConnector && (
                  <TimelineConnector
                    className="timeline-connector"
                  />
                )}
              </TimelineSeparator>

              <TimelineContent style={{ marginTop: -8 }}>
                <p className="title">{loc?.title || ""}</p>

                <div
                  style={{ marginTop: 5 }}
                  className="address"
                >
                  {
                    // !is_viewer && fromActive !== true && 
                    loc?.address ? (
                      <>
                        {loc?.address}{" "}
                        {enableEdit && <span className="icons-edit icon" ></span>}{" "}
                      </>
                    ) : (
                      // !is_viewer && fromActive !== true && 
                      loc?.raw_address) ? (
                      <>
                        {loc?.raw_address}
                        {enableEdit && <span className="icons-edit icon" ></span>}{" "}
                      </>
                    ) : index === 0 ? (
                      !showTitle
                      // && "Select Pickup location"
                    ) : (
                      !showTitle
                      // && "Select Dropoff location"
                    )}
                </div>
                <div style={{ marginBottom: 35, marginTop: 15 }}>
                  {
                    (
                      // !is_viewer && fromActive !== true && 
                      loc?.contact_name?.length) ? (
                      <div className="formGrid">
                        <div className="grayLabel">
                        <span className="titleName">Contact Name</span>
                        
                        {
                          fromHistory && is_recipient &&
                          <>
                            <span className="seperator">/</span>
                            <span className="urduLabel lhEdit">رابطے کا نام</span>
                          </>
                        }
                        </div>
                        <div className="fw-5">
                          {loc?.contact_name}
                        </div>
                      </div>
                    ) : null
                  }
                  {
                    (
                      // !is_viewer && fromActive !== true && 
                      loc?.contact_phone_no?.length) ? (
                      <div className="formGrid mb-0">
                        {
                          fromActive || is_viewer ?
                            <div style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: "100%",
                              alignItems: 'center'
                            }}>
                              <div style={{ width: "75%" }}>
                                <div className="grayLabel">
                                <span className="titleName">Contact Number</span>
                                
                                {
                                  fromHistory && is_recipient &&
                                  <>
                                    <span className="seperator">/</span>
                                    <span className="urduLabel lhEdit">رابطہ نمبر</span>
                                  </>
                                }
                                </div>
                                <div className="fw-5">
                                  {
                                    phoneNumberFormater(loc?.contact_phone_code, loc?.contact_phone_no)
                                  }
                                </div>
                              </div>
                              <div style={{ width: "25%" }}>
                                <div className="whatsapp-button-container">
                                  {
                                    isBrowser ?
                                      <>
                                        <a
                                          href={`tel:92${loc?.contact_phone_no}`}
                                          target={"blank"}
                                          style={{ marginRight: 18 }}
                                          className="phone-whatsapp-button"
                                        >
                                          <img
                                            alt=""
                                            style={{ height: "100%" }}
                                            src={Images.Phone}
                                          />
                                        </a>
                                        <a
                                          href={`https://wa.me/+92${loc?.contact_phone_no}`}
                                          target={"blank"}
                                          className="phone-whatsapp-button"
                                        >
                                          <img
                                            alt=""
                                            style={{ height: "100%" }}
                                            src={Images.WhatsAppBgGreen}
                                          />
                                        </a>
                                      </> :
                                      <>
                                        <>
                                          <img
                                            alt=""
                                            className="phone-whatsapp-button"
                                            style={{ height: "100%", marginRight: 18 }}
                                            src={Images.Phone}
                                            onClick={() => {
                                              if (sendEventToAndroid("activeLoadPhone")) {
                                                window.Android.activeLoadPhone(
                                                  String(loc?.contact_phone_no)
                                                );
                                              } else if (sendEventToiOS("activeLoadPhone")) {
                                                window.webkit.messageHandlers.activeLoadPhone.postMessage(
                                                  JSON.stringify({
                                                    phoneNumber: loc?.contact_phone_no,
                                                  })
                                                );
                                              } else {
                                              }
                                            }}
                                          />
                                          <img
                                            onClick={() => {
                                              if (sendEventToAndroid("activeLoadWhatsapp")) {
                                                window.Android.activeLoadWhatsapp(
                                                  String(loc?.contact_phone_no)
                                                );
                                              } else if (sendEventToiOS("activeLoadWhatsapp")) {
                                                window.webkit.messageHandlers.activeLoadWhatsapp.postMessage(
                                                  JSON.stringify({
                                                    phoneNumber: loc?.contact_phone_no,
                                                  })
                                                );
                                              } else {
                                              }
                                            }}
                                            className="phone-whatsapp-button"
                                            alt=""
                                            style={{ height: "100%" }}
                                            src={Images.WhatsAppBgGreen}
                                          />
                                        </>
                                      </>
                                  }
                                </div>
                              </div>
                            </div> :
                            <>
                              <div className="grayLabel">
                              <span className="titleName">Contact Number</span>
                              
                              {
                                fromHistory && is_recipient &&
                                <>
                                  <span className="seperator">/</span>
                                  <span className="urduLabel lhEdit">رابطہ نمبر</span>
                                </>
                              }
                              </div>
                              <div className="fw-5">
                                {
                                  phoneNumberFormater(loc?.contact_phone_code, loc?.contact_phone_no)
                                }
                              </div>
                            </>

                        }

                      </div>
                    ) : null
                  }

                </div>
              </TimelineContent>
            </TimelineItem>
          </div>
        );
      })}

      <div>
        <AdditionalPickupDropoff
          is_viewer={is_viewer}
          additional_pickup={LoadDetail?.additional_pickups?.map((item) => {
            return {
              ...item,
              address: item?.raw_address,
            }
          }) || []}
          additional_dropoffs={LoadDetail?.additional_dropoffs?.map((item) => {
            return {
              ...item,
              address: item?.raw_address,
            }
          }) || []}
          type={type}
          recipient_status={recipient_status}
          is_recipient={is_recipient}
          fromActive={fromActive}
          fromHistory={fromHistory}
        />
      </div>
    </Timeline>
  );
};
