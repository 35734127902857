import React from "react";
import { IIntroPageProps } from "./types";
import { Colors, Images } from "@temp/assets";
import "./style.scss";
import { PrimaryButton, CustomText } from "@components";
import { useHistory } from 'react-router-dom';

const styles = {
  mainContainer: {
    padding: 0,
    margin: "0px auto",
    width: 380,
  },
  wrapper: {
    backgroundColor: "rgba(176,215,146,0.2)",
    height: "100vh",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  image: {
    height: "50vh",
  },
};

const IntroData = [
  {
    title: "Connect all stakeholders",
    description:
      "this is dummy text for description, words more to come herer, words more to come over herere",
    image: Images.IntroImage1,
  },
  {
    title: "Monitoring loads",
    description: "this is dummy text for description",
    image: Images.IntroImage2,
  },
  {
    title: "Package delivered",
    description: "this is dummy text for description",
    image: Images.IntroImage3,
  },
];

const Page: React.FC<IIntroPageProps> = props => {
  const history = useHistory();
  return (
    <div></div>
    // <Carousel
    //   showThumbs={false}
    //   swipeable
    //   showIndicators
    //   showStatus={false}
    //   stopOnHover={false}
    //   emulateTouch
    //   showArrows={false}
    // >
    //   {IntroData.map((item, index) => {
    //     return (
    //       <div
    //         key={index}
    //         style={{
    //           height: "100vh",
    //           width: 380,
    //           backgroundColor: "white",
    //         }}
    //       >
    //         <div>
    //           <img alt="" style={styles.image} src={item.image} />
    //         </div>
    //         <div
    //           style={{
    //             height: "20vh",
    //             width: 380,
    //             display: "flex",
    //             alignSelf: "center",
    //             flexDirection: "column",
    //             paddingLeft: 50,
    //             paddingRight: 50,
    //             marginTop: 10,
    //           }}
    //         >
    //           <CustomText.OpenSansSemiBold textStyle={{color:Colors.Black, fontSize:20}}>
    //             {item?.title}
    //           </CustomText.OpenSansSemiBold>
    //           <CustomText.OpenSansRegular textStyle={{color:Colors.Grayish, fontSize:16}}>
    //             {item?.description}
    //           </CustomText.OpenSansRegular>
    //         </div>
    //         <div
    //           style={{
    //             display: "flex",
    //             alignSelf: "center",
    //             justifyContent: "center",
    //             height: "30vh",
    //           }}
    //         >
    //           <PrimaryButton
    //             title="GET STARTED!"
    //             onClick={() => {
    //               history.push('/Wellcome');
    //             }}
    //           />
    //         </div>
    //       </div>
    //     );
    //   })}
    // </Carousel>
  );
};

export default Page;
