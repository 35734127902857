import React from 'react';
import { Fade, Modal, Slide } from "@mui/material/";
import { Close } from '@mui/icons-material';

export const SlideupModal = (props) => {
  const { open, onClose } = props;
  return (
    <Modal
      className="slideUpModal"
      open={open}
      closeAfterTransition
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={open}>
        <div className="modalBody">
          <span className="icons-cross modalClose" onClick={onClose}></span>
          {props.children}
        </div>
      </Fade>
    </Modal>
  );
};
