import React from "react";
import { IProps } from "./types";
import "./style.scss";
import rootReducer from "../../store/reducers";
import { useSelector } from "react-redux";
import SliderMultiple from '@mui/material//Slider';

export type RootState = ReturnType<typeof rootReducer>;

export const RangeSlider: React.FC<IProps> = ({ min, max, step, value, orientation, tooltip, onChange }: IProps) => {
  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
  return (
    <div>
      <SliderMultiple
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
      <div className="range-slider-labels-container">
        <div className="label">{
          metaData?.temperature_range?.min
        } <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
        <div className="label">0 <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
        <div className="label">{
          metaData?.temperature_range?.max
        } <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
      </div>
    </div>
  );
};

export default RangeSlider;
