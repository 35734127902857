import React, { FC, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./style.scss";
import {
  SimpleInput,
  SimpleCheckBox,
  PrimaryButton,
  VehicleTable,
  VehicleIncDec,
  CustomizedSelects,
  SimpleModal,
  CustomizedSwitches,
  OutLineButton,
  RadioButton,
  CustomModal,
  NewInput,
  RangeSlider,
} from "@components";
import { CounterAtion, ProfileAction } from "@temp/store/actions";
import Grid from "@mui/material//Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import { TitleLayout } from "@components";
import { LOAD_TYPE, QUOTE_BY } from "@temp/constants";
import OutlineButton from "@temp/components/OutLineButton";
var numeral = require("numeral");
import InfoIcon from "@mui/icons-material/Info";
import "../style.scss";
import { showMessage } from "@temp/utils/helper";
import { MenuItem, Select } from "@mui/material/";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;


const SwitchableWithText = (props: any) => {
  return (
    <Grid
      container
      spacing={1}
      style={{ marginBottom: 8, alignItems: "center" }}
    >
      <Grid
        item
        xs={9}
        style={{ display: "flex" }}
      >
        {props?.icon ? (
          <img
            src={props?.icon}
            style={{ marginRight: 5 }}
            width="16px"
            height="16px"
          />
        ) : (
          ""
        )}
        <div style={{ marginTop: 0 }}>{props?.text}</div>
      </Grid>

      <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginTop: 0 }}>
          <CustomizedSwitches {...props} />
        </div>
      </Grid>
    </Grid>
  );
};

let timmerRef_weight_inc = null;
let timmerRef_weight_dec = null;

let timmerRef_vehicle_inc = null;
let timmerRef_vehicle_dec = null;

export const SelectVehicle: FC = () => {
  var element = document.getElementById("myDIV");
  const history = useHistory();
  const dispatch = useDispatch();
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const load_type = postLoadMeta.load_types.find(
    (val: any) => val.id === PostLoad.load_type
  );

  
  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
  
  


  const [temp, setTemp] = useState(null);
  
  const [tempQuoteBy, setTemQuoteBy] = useState("vehicle");
  const [editMode, setEditMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [info, setInfo] = useState("");
  const [showModalIcon, toggleModalIcon] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [packageModal, setPackageModal] = useState(false);
  const [showContainerModal, setShowContainerModal] = useState(false);
  const [containerType, setContainerType] = React.useState(null);
  const [selectVehicleTypeObj, setSelectVehicleTypeObj] = React.useState(null);
  const [packageType, setPackageType] = React.useState(null);
  const [addedVehicles, setAddedVehicles] = useState([]);
  const [max_vehicle_weight, set_max_vehicle_weight] = useState(1);
  const [vehicle_quantity, set_vehicle_quantity] = useState(1);
  const [PreferredVehicleType, setPreferredVehicleType] = useState(null);
  const [item_quantity_tem, set_item_quantity_tem] = useState(1)
  const [showPreferredVehicleTypeModal, setShowPreferredVehicleTypeModal] = useState(false);
  
  const [initialValues, setInitialValues] = useState({
    load_name: "",
    cargo_description: "",
    ref_no: "",
    is_shared_vehicle_not_allowed: false,
    ...(load_type.title !== LOAD_TYPE.fcl && {
      quote_by: "vehicle",
      gross_weight: null,
      cargo_volume: null,
      total_freight_ton: null,
      no_of_packages: null,
      package_type_other: "",
      gross_weight_in_kgs: null,
      cargo_liter: null,
    }),
    ...(load_type.title !== LOAD_TYPE.fcl &&
      tempQuoteBy === "weight_volume" && {
      is_sidewall: null,
      is_boxed: null,
      is_any_suitable_vehicle: true,
      is_dum_truck_vehicle: null,
      is_flat_bed_vehicle: null,
      is_low_bed_vehicle: null,
      quote_by: "weight_volume",
      package_type_other: "",
    }),
  });

  
  
  

  const [categoryInitialValues, setCategoryInitialValue] = useState({
    is_twin_load: false,
    is_out_of_guage: false,
    is_sidewall: false,
    is_boxed: false,
    is_any_suitable_vehicle: true,
    is_dum_truck_vehicle: false,
    is_flat_bed_vehicle: false,
    is_low_bed_vehicle: false,
    is_shipper_owned_container: false,
    package_type_other: "",
    is_genset: false,
    required_temperature: [0, 0],
  });

  


  const [unitBasisValues, setUnitBasisValues] = useState({
    is_unit_basis: false,
    unit_description: '',
    dimensions: '',
    unit_quantity: 1,
    unit_edit_id: null,
    unit_dimension_value: null,
  });

  const [added_units, set_added_units] = useState([]);
  const [other_type_by, set_other_type_by] = useState('not_item_basis')

  let allVehicleTypes = postLoadMeta?.vehicle_types.filter((val) => val.load_type === PostLoad.load_type);
  let allContainerType = postLoadMeta.container_types;
  let allPreferredVehicleTypes = postLoadMeta.preferred_vehicles;

  


  useEffect(() => {
    if (PostLoad?.vehicleScreen) {
      let vehicleScreen = PostLoad?.vehicleScreen;
      if (vehicleScreen?.values) {
        let ref_no = "";
        if (PostLoad?.ref_no) {
          ref_no = PostLoad?.ref_no;
        }
        if (PostLoad?.vehicleScreen?.is_unit_basis) {
          setInitialValues({
            ...vehicleScreen?.values,
            gross_weight: null,
            cargo_volume: null,
            total_freight_ton: null,
            no_of_packages: null,
            gross_weight_in_kgs: null,
          });
        } else {
          setInitialValues({ ...vehicleScreen.values, ref_no });
        }
      }
      setTemQuoteBy(PostLoad?.vehicleScreen?.values?.quote_by || "vehicle");
      setPackageType(PostLoad?.vehicleScreen?.packageType || null);
      setPreferredVehicleType(
        PostLoad?.vehicleScreen?.PreferredVehicleType || null
      );
      if (vehicleScreen?.addedVehicles && vehicleScreen?.addedVehicles.length) {
        setAddedVehicles(vehicleScreen?.addedVehicles);
      }
      if (PostLoad?.vehicleScreen?.is_unit_basis) {
        setUnitBasisValues({
          ...unitBasisValues,
          is_unit_basis: true,
        });
        set_added_units(PostLoad?.vehicleScreen?.added_units);
        set_other_type_by('item_basis')
      } else {
        set_other_type_by('not_item_basis')
      }
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = (event.target as HTMLInputElement).value;
    let typeVehicle = allVehicleTypes.find(
      (val) => Number(val.id) === Number(id)
    );
    setShowModal(false);
    setSelectVehicleTypeObj(typeVehicle);
    dispatch(CounterAtion.setPostloadCounterData({ max_vehicle_weight: 1, vehicle_quantity : 1 }));
    set_max_vehicle_weight(1);
    set_vehicle_quantity(1)
    // setCategoryInitialValue({
    //   is_genset: false,
    //   required_temperature: [0, 0]
    // })
  };
  {
    
  }

  const handleChangeContainer = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let id = (event.target as HTMLInputElement).value;
    let containerTypes = allContainerType.find(
      (val) => Number(val.id) === Number(id)
    );
    setShowContainerModal(false);
    setContainerType(containerTypes);
    // if (valueSelectedOnce) {
    // 
      // setCategoryInitialValue({
      //   is_genset: false,
      //   required_temperature: [0, 0]
      // })
    // }

  };

  const handleChangePreferredVehicleType = (

    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let id = (event.target as HTMLInputElement).value;
    let preferredVehicleTypes = allPreferredVehicleTypes.find(
      (val) => Number(val.id) === Number(id)
    );
    setShowPreferredVehicleTypeModal(false);
    setPreferredVehicleType(preferredVehicleTypes);
  };

  const handleChangePackage = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = (event.target as HTMLInputElement).value;
    let types = postLoadMeta?.package_types.find(
      (val: object) => Number(val.id) === Number(id)
    );
    formikVehicleType.setFieldValue("package_type_other", "");
    setCategoryInitialValue({
      ...categoryInitialValues,
      package_type_other: "",
    });
    setPackageModal(false);
    setPackageType(types);
  };

  const handleDelete = (ind: number) => {
    let tempAddedVehicles = addedVehicles.filter((val, index) => index !== ind);
    setAddedVehicles([...tempAddedVehicles]);
  };

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const validationSchemaVehicleType = yup.object({
    is_twin_load: yup.boolean(),
    is_out_of_guage: yup.boolean(),
    is_boxed: yup.boolean(),
    is_sidewall: yup.boolean(),
    is_any_suitable_vehicle: yup.boolean(),
    is_dum_truck_vehicle: yup.boolean(),
    is_flat_bed_vehicle: yup.boolean(),
    is_low_bed_vehicle: yup.boolean(),
    is_shipper_owned_container: yup.boolean(),
    package_type_other:
      packageType && packageType?.title == "Other"
        ? yup.string().required()
        : yup.string(),
  });

  const formikVehicleType = useFormik({
    initialValues: categoryInitialValues,
    validationSchema: validationSchemaVehicleType,
    onSubmit: (values) => {
      if (editMode) {
        let added = {
          selectVehicleTypeObj,
          vehicle_quantity,
          containerType,
          max_vehicle_weight,
          values,
          packageType,
          PreferredVehicleType,
        };
        addedVehicles[currentIndex] = added;
        setEditMode(false);
        setAddedVehicles([...addedVehicles]);
      } else {
        if (
          load_type.title === LOAD_TYPE.fcl ||
          (load_type.title !== LOAD_TYPE.fcl &&
            initialValues.quote_by === "vehicle")
        ) {
          let added = {
            selectVehicleTypeObj,
            vehicle_quantity,
            containerType,
            max_vehicle_weight,
            values,
            packageType,
          };
          if (PostLoad?.from !== "forward") {
            setAddedVehicles([...addedVehicles, added]);
          } else {
            showMessage.current({
              message:
                // "You cannot add another vehicle in forward case.",
                "You cannot add another vehicle while forwarding a load.",
              status: "error",
            });
          }
        }
      }
      formikVehicleType.setValues(categoryInitialValues);
      formikVehicleType.setFieldValue("is_shipper_owned_container", false);
      set_vehicle_quantity(1);
      setContainerType(null);
      set_max_vehicle_weight(1);
      setPackageType(null);
      setSelectVehicleTypeObj(null);
      setPreferredVehicleType(null);
    },
  });

  const [valueSelectedOnce, setValueSelectedOnce] = useState(false)

  // category add function
  const handleAddEditCategory = (params) => {
    // setValueSelectedOnce(true)
    
    if (editMode) {
      
      let added = {
        selectVehicleTypeObj,
        vehicle_quantity,
        containerType,
        max_vehicle_weight,
        values: categoryInitialValues,
        packageType,
        PreferredVehicleType,
      };
      if (
        (load_type.title == LOAD_TYPE.fcl &&
          selectVehicleTypeObj &&
          containerType && max_vehicle_weight && vehicle_quantity
        )
        ||
        (load_type.title !== LOAD_TYPE.fcl &&
          selectVehicleTypeObj &&
          packageType && packageType?.title !== "Other" && max_vehicle_weight && vehicle_quantity) ||
        (load_type.title !== LOAD_TYPE.fcl &&
          selectVehicleTypeObj && max_vehicle_weight && vehicle_quantity && packageType &&
          packageType?.title === "Other" &&
          categoryInitialValues?.package_type_other)
      ) {
        addedVehicles[currentIndex] = added;
      }

      setEditMode(false);
      setAddedVehicles([...addedVehicles]);
      

      if (params) {
        dispatch(
          ProfileAction.postLoadData({
            ref_no: initialValues?.ref_no || "",
            vehicleScreen: {
              values: initialValues,
              is_allow_partial_load,
              addedVehicles,
              ...(load_type.title !== LOAD_TYPE.fcl &&
                tempQuoteBy === "weight_volume" && {
                packageType,
                PreferredVehicleType,
                values: {
                  is_loading_required: false,
                  loading_requirements: "",
                  is_unloading_required: false,
                  unloading_requirements: "",
                  loading_price: 0,
                  unloading_price: 0,
                  total_amount: 0,
                  price: 0,
                  ...initialValues,
                  // price_unit: PostLoad?.vehicleScreen?.values?.price_unit
                  price_unit: '',
                },
              }),
            },
          })
        );
        if (load_type.title === LOAD_TYPE.fcl) {
          history.push("/additionalDetails");
        } else {
          history.push("/FCL_BULK_ADDITIONAL_DETAILS");
        }
      }
    } else {
      
      if (load_type.title === LOAD_TYPE.fcl ||
        (load_type.title !== LOAD_TYPE.fcl &&
          initialValues.quote_by === "vehicle")
      ) {
        let added = {
          selectVehicleTypeObj,
          vehicle_quantity,
          containerType,
          max_vehicle_weight,
          values: categoryInitialValues,
          packageType,
        };

        let final_data = [];
        if (
          (load_type.title == LOAD_TYPE.fcl &&
            selectVehicleTypeObj &&
            containerType && max_vehicle_weight && vehicle_quantity
          ) ||
          (load_type.title !== LOAD_TYPE.fcl &&
            selectVehicleTypeObj &&
            packageType && packageType?.title !== "Other" && max_vehicle_weight && vehicle_quantity) ||
          (load_type.title !== LOAD_TYPE.fcl &&
            selectVehicleTypeObj && max_vehicle_weight && vehicle_quantity && packageType &&
            packageType?.title === "Other" &&
            categoryInitialValues?.package_type_other)
        ) {
          final_data = [...addedVehicles, added];
        } else {
          final_data = [...addedVehicles];
        }
        if (PostLoad?.from !== "forward") {
          setAddedVehicles(final_data);
          
        } else {
          if (!params) {
            showMessage.current({
              message:
                // "You cannot add another vehicle in forward case.",
                "You cannot add another vehicle while forwarding a load.",
              status: "error",
            });
          }
        }
        if (params) {
          dispatch(
            ProfileAction.postLoadData({
              ref_no: initialValues?.ref_no || "",
              vehicleScreen: {
                values: initialValues,
                is_allow_partial_load,
                addedVehicles: PostLoad?.from !== "forward" ? final_data : addedVehicles,
                ...(load_type.title !== LOAD_TYPE.fcl &&
                  tempQuoteBy === "weight_volume" && {
                  packageType,
                  PreferredVehicleType,
                  values: {
                    is_loading_required: false,
                    loading_requirements: "",
                    is_unloading_required: false,
                    unloading_requirements: "",
                    loading_price: 0,
                    unloading_price: 0,
                    total_amount: 0,
                    price: 0,
                    ...initialValues,
                    // price_unit: PostLoad?.vehicleScreen?.values?.price_unit
                    price_unit: ''
                  },
                }),
              },
            })
          );
          if (load_type.title === LOAD_TYPE.fcl) {
            history.push("/additionalDetails");
          } else {
            history.push("/FCL_BULK_ADDITIONAL_DETAILS");
          }
        }
      } else {
        if (unitBasisValues?.is_unit_basis) {
        } else {
          dispatch(
            ProfileAction.postLoadData({
              ref_no: initialValues?.ref_no || "",
              vehicleScreen: {
                values: initialValues,
                is_allow_partial_load,
                addedVehicles: [],
                ...(load_type.title !== LOAD_TYPE.fcl &&
                  tempQuoteBy === "weight_volume" && {
                  packageType,
                  PreferredVehicleType,
                  // added_units:
                  //   unitBasisValues?.is_unit_basis
                  //     ? added_units.length ? added_units
                  //       : [{
                  //         unit_description: unitBasisValues?.unit_description,
                  //         dimensions: unitBasisValues?.dimensions,
                  //         unit_quantity: item_quantity_tem,
                  //         gross_weight_in_kgs: initialValues?.gross_weight_in_kgs,
                  //         cargo_volume: initialValues?.cargo_volume,
                  //         total_freight_ton: initialValues?.total_freight_ton,
                  //         no_of_packages: initialValues?.no_of_packages,
                  //         gross_weight: initialValues.gross_weight,
                  //         is_unit_basis: true,
                  //         unit_dimension_value: unitBasisValues?.unit_dimension_value,
                  //       }]
                  //     : [],
                  ...unitBasisValues,
                  values: {
                    is_loading_required: false,
                    loading_requirements: "",
                    is_unloading_required: false,
                    unloading_requirements: "",
                    loading_price: 0,
                    unloading_price: 0,
                    total_amount: 0,
                    price: 0,
                    ...initialValues,
                    // price_unit: PostLoad?.vehicleScreen?.values?.price_unit
                    price_unit: ''
                  },
                }),
              },
            })
          );
        }
        if (load_type.title === LOAD_TYPE.fcl) {
          history.push("/additionalDetails");
        } else {
          history.push("/FCL_BULK_ADDITIONAL_DETAILS");
        }
      }
    }
    {
      
    }
    setCategoryInitialValue({
      ...categoryInitialValues,
      is_shipper_owned_container: false,
      is_out_of_guage: false,
      is_twin_load: false,
      is_sidewall: false,
      is_boxed: false,
      required_temperature: [0, 0],
      is_genset: false
    });
    
    set_vehicle_quantity(1);
    setContainerType(null);
    set_max_vehicle_weight(1);
    setPackageType(null);
    setSelectVehicleTypeObj(null);
    setPreferredVehicleType(null);

  };

  // edit to prfilled data
  const handleVehicleCategoryEdit = (index) => {
    setCurrentIndex(index);
    setEditMode(true);
    setSelectVehicleTypeObj(addedVehicles[index]?.selectVehicleTypeObj);
    dispatch(
      CounterAtion.setPostloadCounterData({
        vehicle_quantity: addedVehicles[index]?.vehicle_quantity,
      })
    );
    setContainerType(addedVehicles[index]?.containerType);
    dispatch(
      CounterAtion.setPostloadCounterData({
        max_vehicle_weight: addedVehicles[index]?.max_vehicle_weight,
      })
    );
    setPackageType(addedVehicles[index]?.packageType);
    setCategoryInitialValue({
      ...categoryInitialValues,
      ...addedVehicles[index]?.values,
    });
    
    formikVehicleType.setValues(addedVehicles[index]?.values);
    set_vehicle_quantity(Number(addedVehicles[index]?.vehicle_quantity));
    set_max_vehicle_weight(Number(addedVehicles[index]?.max_vehicle_weight));
    if (
      addedVehicles[index]?.values?.is_twin_load &&
      addedVehicles[index]?.selectVehicleTypeObj?.title === "40 ft Trailer"
    ) {
      setTemp(
        allVehicleTypes.find((val) => val.title === "40 ft Trailer")
          .min_capacity
      );
    }
  };


  // handle disable continue button

  const handleDisabilityContinue = () => {
    if (load_type.title !== LOAD_TYPE.fcl) {
      if (initialValues.quote_by == "vehicle") {
        if (
          (addedVehicles?.length && initialValues?.load_name && (selectVehicleTypeObj?.provide_temperature_control ? (categoryInitialValues?.required_temperature[0] !== 0
            || categoryInitialValues?.required_temperature[1] > 0)
            : true)
          ) ||
          (max_vehicle_weight > 0 &&  vehicle_quantity > 0 &&
            (packageType && packageType?.title === "Other"
              ? categoryInitialValues?.package_type_other !== ""
              : packageType) &&
            selectVehicleTypeObj &&
            initialValues.load_name)
          && (selectVehicleTypeObj?.provide_temperature_control ? (categoryInitialValues?.required_temperature[0] !== 0
            || categoryInitialValues?.required_temperature[1] > 0)
            : true)

        ) {
          return false;
        } else {
          return true;
        }
      } else if (unitBasisValues?.is_unit_basis) {
        if (
          (unitBasisValues?.is_unit_basis &&
            added_units.length &&
            initialValues?.load_name || (initialValues.gross_weight_in_kgs &&
              initialValues.gross_weight &&
              initialValues?.cargo_volume &&
              unitBasisValues?.unit_dimension_value &&
              unitBasisValues?.unit_dimension_value !== "Select Unit" &&
              unitBasisValues?.dimensions &&
              Number(unitBasisValues?.unit_quantity) > 0 &&  item_quantity_tem > 0))
        ) {
          return false
        } else {
          return true
        }
      } else {
        if (initialValues.quote_by === "weight_volume" &&
          initialValues.load_name && initialValues.gross_weight &&
          initialValues.gross_weight !== "" &&
          initialValues.gross_weight !== null &&
          PreferredVehicleType && packageType && (packageType?.title === "Other"
            ? initialValues?.package_type_other
            : packageType)) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      if (
        (addedVehicles.length && initialValues.load_name && (containerType?.provide_temperature_control ? (categoryInitialValues?.required_temperature[0] !== 0
          || categoryInitialValues?.required_temperature[1] > 0)
          : true)) ||
        ((
          initialValues.load_name &&
          containerType &&
          selectVehicleTypeObj && max_vehicle_weight > 0 && vehicle_quantity > 0)
          && (containerType?.provide_temperature_control ? (categoryInitialValues?.required_temperature[0] !== 0
            || categoryInitialValues?.required_temperature[1] > 0)
            : true))
        // ((selectVehicleTypeObj && containerType && initialValues.load_name && max_vehicle_weight > 0)
        //   && (containerType?.provide_temperature_control ? (categoryInitialValues?.required_temperature[0] !== 0
        //     || categoryInitialValues?.required_temperature[1] !== 0)
        //     : true))
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleDeleteUnit = (index) => {
    let new_data = [...added_units];
    new_data.splice(index, 1);
    set_added_units(new_data);
  };

  const hanleEditUnit = (data) => {
    setInitialValues({
      ...initialValues,
      gross_weight_in_kgs: data?.gross_weight_in_kgs,
      cargo_volume: data?.cargo_volume,
      total_freight_ton: data?.total_freight_ton,
      no_of_packages: data?.no_of_packages,
      gross_weight: data?.gross_weight,
    });

    setUnitBasisValues({
      ...unitBasisValues,
      unit_description: data?.unit_description,
      dimensions: data?.dimensions,
      unit_quantity: data?.unit_quantity,
      unit_dimension_value: data?.unit_dimension_value,
    });
  };
  const [unit_edit_mode, set_unit_edit_mode] = useState(false);
  const [edit_unit_index, set_edit_unit_index] = useState(null);
  
  const handleAddUpdateUnit = (params) => {
    let new_data = [...added_units];
    let unit_obj = {
      unit_description: unitBasisValues?.unit_description,
      dimensions: unitBasisValues?.dimensions,
      unit_quantity: item_quantity_tem,
      gross_weight_in_kgs: initialValues?.gross_weight_in_kgs,
      cargo_volume: initialValues?.cargo_volume,
      total_freight_ton: initialValues?.total_freight_ton,
      no_of_packages: initialValues?.no_of_packages,
      gross_weight: initialValues.gross_weight,
      is_unit_basis: true,
      unit_dimension_value: unitBasisValues?.unit_dimension_value,
    };

    if (handleDisableAddUnit()) { } else {
      if (unit_edit_mode) {
        new_data[edit_unit_index] = unit_obj;
      } else {
        new_data.push(unit_obj);
      }
    }
    set_added_units(new_data);

    if (params) {
      dispatch(
        ProfileAction.postLoadData({
          ref_no: initialValues?.ref_no || "",
          vehicleScreen: {
            values: initialValues,
            is_allow_partial_load,
            addedVehicles: [],
            ...(load_type.title !== LOAD_TYPE.fcl &&
              tempQuoteBy === "weight_volume" && {
              packageType,
              PreferredVehicleType,
              added_units: new_data,
              ...unitBasisValues,
              values: {
                is_loading_required: false,
                loading_requirements: "",
                is_unloading_required: false,
                unloading_requirements: "",
                loading_price: 0,
                unloading_price: 0,
                total_amount: 0,
                price: 0,
                ...initialValues,
                // price_unit: PostLoad?.vehicleScreen?.values?.price_unit
                price_unit: ''
              },
            }),
          },
        })
      );
      history.push("/FCL_BULK_ADDITIONAL_DETAILS");
    }
    setInitialValues({
      ...initialValues,
      gross_weight_in_kgs: "",
      cargo_volume: "",
      total_freight_ton: "",
      no_of_packages: "",
      gross_weight: 0,
    });
    setUnitBasisValues({
      ...unitBasisValues,
      unit_description: '',
      unit_quantity: 1,
      dimensions: '',
      unit_dimension_value: ''

    })
    set_item_quantity_tem(1)
    set_unit_edit_mode(false)

  }

  const handleDisableAddUnit = () => {
    if (
      initialValues.gross_weight_in_kgs &&
      initialValues.gross_weight &&
      initialValues?.cargo_volume &&
      unitBasisValues?.unit_dimension_value &&
      unitBasisValues?.unit_dimension_value !== "Select Unit" &&
      unitBasisValues?.dimensions &&
      Number(unitBasisValues?.unit_quantity) > 0 &&  item_quantity_tem > 0
    ) {
      return false;
    } else {
      return true;
    }
  };


  const additional_default_values =
    PostLoad?.load_type == 1 ?
      postLoadMeta?.load_configurations?.containerize_additional_details
      : postLoadMeta?.load_configurations?.non_containerize_additional_details;

  const [filterBy, setFilterBy] = useState('')
  const [unit_edit_modal, set_edit_unit_modal] = useState(false)
  const [unit_edit_data, set_edit_unit_data] = useState(null)

  useEffect(() => {
    if (load_type?.title == LOAD_TYPE.bulk && tempQuoteBy == 'vehicle') {
      setFilterBy("light_vehicle")
    } else {
      setFilterBy('')
    }
  }, [])

  const [is_allow_partial_load, set_is_allow_partial_load] = useState(
    additional_default_values?.is_allow_partial_load ? true : false
  );

  useEffect(() => {
    if (PostLoad?.vehicleScreen) {
      set_is_allow_partial_load(PostLoad?.vehicleScreen?.is_allow_partial_load)
    }
  }, [])


  const myRef = useRef(null)
  return (
    <TitleLayout
      pageNumber={3}
      progress={30}
      title={`Spot Rate - ${load_type.title}`}
    >
      <div
        className={
          load_type.title !== LOAD_TYPE.fcl
            ? "selectVehicleMainWrap lcl"
            : "selectVehicleMainWrap"
        }
      >
        <form onSubmit={(e) => e.preventDefault()} onKeyDown={onKeyDown}>
          <div
            ref={myRef}
            id={"myDIV"}
            className="selectVehicleContentWrap selectVehicleWrapper"
          >
            {load_type.title !== LOAD_TYPE.fcl && (
              <div>
                <FadeInComponent>
                  <div className="titleSection">Rate by</div>
                  <div className="field">
                    <div className="tabWrapper">
                      <RadioButton
                        onChange={(e) => {
                          setPackageType(null);
                          setAddedVehicles([]);
                          setTemQuoteBy("vehicle");
                          set_other_type_by('not_item_basis')
                          setInitialValues({
                            ...initialValues,
                            quote_by: "vehicle",
                            cargo_description: "",
                            cargo_liter: "",
                          });
                          setUnitBasisValues({
                            ...unitBasisValues,
                            is_unit_basis: false,
                          });
                        }}
                        disabled={PostLoad?.from == "forward" ? true : false}
                        id="vehicle"
                        name="quote_by"
                        label="Vehicle"
                        value={initialValues.quote_by}
                      />
                      <RadioButton
                        id="weight_volume"
                        name="quote_by"
                        value={initialValues.quote_by}
                        disabled={PostLoad?.from == "forward" ? true : false}
                        onChange={(e) => {
                          setPackageType(null);
                          setTemQuoteBy("weight_volume");
                          setAddedVehicles([]);
                          setPreferredVehicleType(null),
                            set_other_type_by('not_item_basis')
                          setInitialValues({
                            ...initialValues,
                            quote_by: "weight_volume",
                            is_sidewall: false,
                            is_boxed: false,
                            is_any_suitable_vehicle: true,
                            is_dum_truck_vehicle: false,
                            is_flat_bed_vehicle: false,
                            is_low_bed_vehicle: false,
                            cargo_description: "",
                            cargo_liter: "",
                          });
                        }}
                        label="Others"
                      />
                      <div className="radioTab_slider"></div>
                    </div>
                  </div>
                </FadeInComponent>
                {
                  initialValues?.quote_by == 'weight_volume' && (
                    <div>
                      <FadeInComponent>
                        <div className="titleSection">Other Rate Basis</div>
                        <div className="field">
                          <div className="tabWrapper">
                            <RadioButton
                              onChange={(e) => {
                                set_other_type_by('not_item_basis')
                                setUnitBasisValues({ ...unitBasisValues, is_unit_basis: false })
                              }}
                              disabled={PostLoad?.from == "forward" ? true : false}
                              id="not_item_basis"
                              name="other_by"
                              label="Weight/Volume"
                              value={other_type_by}
                            />
                            <RadioButton
                              id="item_basis"
                              name="other_by"
                              value={other_type_by}
                              disabled={PostLoad?.from == "forward" ? true : false}
                              onChange={(e) => {
                                set_other_type_by('item_basis')
                                setUnitBasisValues({ ...unitBasisValues, is_unit_basis: true })
                              }}
                              label="Item Basis"
                            />
                            <div className="radioTab_slider"></div>
                          </div>
                        </div>
                      </FadeInComponent>
                    </div>
                  )
                }
              </div>
            )}

            <FadeInComponent>
              <div className="sectionBox">
                {/* load name field  */}
                <div className="inputField">
                  <div className="label">Load Name</div>
                  <div className="field">
                    <SimpleInput
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          load_name: e.target.value,
                        });
                      }}
                      value={initialValues.load_name}
                      id={"load_name"}
                      name={"load_name"}
                      placeholder={"Enter Load Name"}
                      disabled={PostLoad?.from == "forward" ? true : false}
                    />
                  </div>
                </div>
                {/* ref no field  */}
                <div className="inputField">
                  <div className="label">Customer Ref No.</div>
                  <div className="field">
                    <SimpleInput
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          ref_no: e.target.value,
                        });
                      }}
                      value={initialValues.ref_no}
                      style={{ height: 35, width: "100%", marginTop: 10 }}
                      id={"ref_no"}
                      name={"ref_no"}
                      placeholder={"(Optional)"}
                      aria-describedby="standard-weight-helper-text"
                      className="rightFormField"
                      disabled={PostLoad?.from == "forward" ? true : false}
                    />
                  </div>
                </div>
                {
                  unitBasisValues?.is_unit_basis && (
                    <div style={{
                      marginTop: 10,
                      marginBottom: 12,
                      paddingTop: 8,
                      paddingBottom: 8,
                    }} className="titleSection">
                      {
                        added_units?.length < 1 ? `Details of Item` : `Details of Item ${added_units.length + 1}`
                      }
                    </div>
                  )
                }
                {
                  (tempQuoteBy == "weight_volume" && !unitBasisValues?.is_unit_basis) && (
                    <div style={{
                      marginTop: 10,
                      marginBottom: 12,
                      paddingTop: 8,
                      paddingBottom: 8,
                    }} className="titleSection">
                      Cargo Details
                    </div>
                  )
                }

                {/* {
                  (tempQuoteBy == "vehicle" && !unitBasisValues?.is_unit_basis && load_type?.title !== LOAD_TYPE?.fcl) && (
                    <div style={{
                      marginTop: 10,
                      marginBottom: 12,
                      paddingTop: 8,
                      paddingBottom: 8,
                    }} className="titleSection">
                      {
                        addedVehicles?.length < 1 ? `Details of Category` : `Details of Category ${addedVehicles.length + 1}`
                      }
                    </div>
                  )
                } */}

                {load_type.title !== LOAD_TYPE.fcl && initialValues?.quote_by !== "vehicle" && (
                  <>
                    {/* gross weight field */}
                    <div className="inputField">
                      <div className="label">Gross Weight</div>
                      <div className="field">
                        <NewInput
                          onChange={(e) => {
                            let grossWeight = e.target.value;
                            setInitialValues({
                              ...initialValues,
                              gross_weight_in_kgs: grossWeight
                                ? Number(grossWeight)
                                : null,
                              gross_weight: grossWeight / 1000,
                              total_freight_ton:
                                Number(grossWeight) / 1000 > Number(initialValues?.cargo_volume)
                                  ? grossWeight / 1000
                                  : initialValues.cargo_volume,
                            });
                          }}
                          value={initialValues.gross_weight_in_kgs ? initialValues?.gross_weight_in_kgs : ''}
                          showEnd={"KG"}
                          decimal={true}
                        />
                      </div>
                    </div>

                    {/* gross weight in kgs field  */}
                    <div className="inputField">
                      <div className="label">Gross Weight in Tons</div>
                      <div className="field">
                        <NewInput
                          id="total_freight_ton"
                          name="total_freight_ton"
                          value={numeral(initialValues.gross_weight).format(
                            "0,0.00"
                          )}
                          disabled={true}
                          aria-describedby="standard-weight-helper-text"
                          showEnd={"Ton"}
                        // style={{ height: 35 }}
                        // className="rightFormField customDisable textBlack"
                        />
                      </div>
                    </div>
                    {/* cargo volume field  */}
                    <div className="inputField">
                      <div className="label">Cargo Volume</div>
                      <div className="field">
                        <NewInput
                          onChange={(e) => {
                            let cargoVolume = e.target.value;
                            setInitialValues({
                              ...initialValues,
                              cargo_volume: cargoVolume
                                ? Number(cargoVolume)
                                : null,
                              total_freight_ton:
                                Number(cargoVolume) > Number(initialValues.gross_weight)
                                  ? cargoVolume
                                  : initialValues.gross_weight,
                            });
                          }}
                          value={initialValues.cargo_volume ? initialValues?.cargo_volume : ''}
                          placeholder={
                            unitBasisValues?.is_unit_basis ? "" : "Optional"
                          }
                          showEnd={"CBM"}
                          decimal={true}
                        />
                      </div>
                    </div>

                    {/* total freight ton faded field readonly  */}
                    <div className="inputField">
                      <div className="label">
                        {/* {`${!initialValues.cargo_volume
                          ? " Total Ton"
                          : "Total Freight Ton"
                          }`} */}
                        Total Weight
                        {initialValues.cargo_volume ? (
                          <InfoIcon
                            onClick={() => {
                              setInfo(
                                "Higher of weight in tons and volume in CBM."
                              );
                              toggleModalIcon(true);
                            }}
                          />
                        ) : null}
                      </div>
                      <div className="field">
                        <NewInput
                          id="total_freight_ton"
                          name="total_freight_ton"
                          value={numeral(initialValues.total_freight_ton).format(
                            "0,0.00"
                          )}
                          disabled={true}
                          aria-describedby="standard-weight-helper-text"
                          showEnd={`${!initialValues.cargo_volume ? "Ton" : "F.Ton"
                            }`}
                        />
                      </div>
                    </div>
                    {/* litter field */}
                    {load_type.title !== LOAD_TYPE.fcl &&
                      !unitBasisValues?.is_unit_basis &&
                      tempQuoteBy === "weight_volume" ? (
                      <div className="inputField">
                        <div className="label">Liter</div>
                        <div className="field">
                          <NewInput
                            // onChange={(e) => {
                            //   let liter = e.target.value;
                            //   setInitialValues({
                            //     ...initialValues,
                            //     cargo_liter: liter ?
                            //       Number(liter)
                            //       : null
                            //   });
                            // }}
                            onChange={(e) => {
                              setInitialValues({
                                ...initialValues,
                                cargo_liter: Number(e.target.value),
                              });
                            }}
                            value={initialValues.cargo_liter ? initialValues?.cargo_liter : ""}
                            placeholder={"Optional"}
                            showEnd={"liter"}
                            decimal={true}
                          />
                        </div>
                      </div>
                    ) : null}
                    {/* number of packages field  */}
                    {!unitBasisValues?.is_unit_basis ? (
                      <div className="inputField">
                        <div className="label">Number of Packages</div>
                        <div className="field">
                          <NewInput
                            onChange={(e) => {
                              setInitialValues({
                                ...initialValues,
                                no_of_packages: Number(e.target.value),
                              });
                            }}
                            value={
                              initialValues.no_of_packages
                                ? initialValues?.no_of_packages
                                : ""
                            }
                            placeholder={"Optional"}
                            decimal={false}

                          // style={{ marginTop: 5 }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </FadeInComponent>

            {/* filter by for qoute_by vehicle only */}
            {PostLoad?.inquiry_type == 1 &&
              PostLoad?.load_type == 3 &&
              tempQuoteBy == "vehicle" ? (
              <div>
                <FadeInComponent>
                  <div className="titleSection">Select Vehicle Category</div>
                  <div className="tabWrapper">
                    <RadioButton
                      onChange={(e) => {
                        setFilterBy("light_vehicle");
                      }}
                      id="light_vehicle"
                      name="filter_by"
                      label="Light Vehicle"
                      value={filterBy}
                      defaultChecked={true}
                      extraText={"Up to 18 Ft"}
                    />
                    <RadioButton
                      id="heavy_vehicle"
                      name="filter_by"
                      value={filterBy}
                      onChange={(e) => {
                        setFilterBy("heavy_vehicle");
                      }}
                      label="Heavy Vehicle"
                      extraText={"20 Ft & Above"}
                    />
                    <div className="radioTab_slider"></div>
                  </div>
                </FadeInComponent>
              </div>
            ) : null}


            {load_type.title === LOAD_TYPE.bulk &&
              !unitBasisValues?.is_unit_basis &&
              initialValues.quote_by === "weight_volume" && (
                <FadeInComponent>
                  <div className="sectionBox">
                    <div className="titleSection">Preferred Vehicle Type</div>

                    <CustomizedSelects
                      selectedValue={PreferredVehicleType?.id}
                      values={
                        PreferredVehicleType ? allPreferredVehicleTypes : []
                      }
                      disabled={true}
                      modalState={true}
                      placeholder={"Select Vehicle Type"}
                      handleModal={(value: boolean) => {
                        setShowPreferredVehicleTypeModal(value);
                      }}
                      classProp="mb-0"
                    ></CustomizedSelects>
                  </div>
                </FadeInComponent>
              )}

            {/* select packaging type dropdown */}
            {load_type.title !== LOAD_TYPE.fcl &&
              initialValues.quote_by === "weight_volume" &&
              !unitBasisValues?.is_unit_basis && (
                <FadeInComponent>
                  <div className="sectionBox">
                    <div className="titleSection">Packaging Type</div>
                    <CustomizedSelects
                      classProp="mb-0"
                      placeholder={"Select Packaging Type"}
                      selectedValue={packageType?.id}
                      values={packageType ? postLoadMeta?.package_types : []}
                      disabled={true}
                      modalState={true}
                      handleModal={(value: boolean) => {
                        setPackageModal(value);
                      }}
                    ></CustomizedSelects>

                    {packageType?.title === "Other" && (
                      <div className="inputField" style={{ marginTop: '1rem', marginBottom: 0 }}>
                        <div className="label">Other package type</div>
                        <div className="field">
                          <SimpleInput
                            onChange={(e) => {
                              setInitialValues({
                                ...initialValues,
                                package_type_other: e.target.value,
                              });
                            }}
                            id="package_type_other"
                            name="package_type_other"
                            value={
                              initialValues.package_type_other
                                ? initialValues.package_type_other
                                : ""
                            }
                            type="text"
                            placeholder="Type your Packaging type here"
                            style={{ height: 41, width: "100%", marginBottom: 0, marginTop: '0.75rem' }}
                            aria-describedby="standard-weight-helper-text"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </FadeInComponent>
              )}

            {/* quote_by vehicle  */}
            {tempQuoteBy !== "weight_volume" && (
              <>
                <FadeInComponent>
                  {
                    load_type.title == LOAD_TYPE.fcl && (
                      <div style={{
                        marginTop: 10,
                        marginBottom: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                      }} className="titleSection">
                        {
                          addedVehicles?.length < 1 ? `Details of Category` : `Details of Category ${addedVehicles.length + 1}`
                        }
                      </div>
                    )
                  }
                </FadeInComponent>

                <FadeInComponent>
                  <div className="sectionBox">
                    <div className="titleSection">Vehicle Type</div>
                    <div onClick={() => { }}>
                      <CustomizedSelects
                        selectedValue={selectVehicleTypeObj?.id}
                        values={selectVehicleTypeObj ? allVehicleTypes : []}
                        disabled={true}
                        modalState={true}
                        placeholder={"Select Vehicle Type"}
                        handleModal={(value: boolean) => {
                          setShowModal(value);
                        }}
                      ></CustomizedSelects>
                    </div>

                    {load_type.title === LOAD_TYPE.fcl && (
                      <div>
                        <div className="titleSection">
                          Container Type
                        </div>

                        <CustomizedSelects
                          selectedValue={containerType?.id}
                          values={containerType ? allContainerType : []}
                          disabled={true}
                          modalState={true}
                          placeholder={"Select Container Type"}
                          handleModal={(value: boolean) => {
                            
                            setShowContainerModal(value);

                          }}
                        ></CustomizedSelects>
                      </div>
                    )}

                    {(load_type.title === LOAD_TYPE.fcl ||
                      (load_type.title === LOAD_TYPE.bulk &&
                        tempQuoteBy === "vehicle")) && (
                        <div
                          style={{
                            pointerEvents: !selectVehicleTypeObj
                              ? "none"
                              : "auto",
                          }}
                        >
                          <VehicleIncDec
                            className="labelWithIncrementField"
                            text="Max Cargo Weight Per Vehicle (Tons)"
                            value={max_vehicle_weight}
                            name={"max_vehicle_weight"}
                            withInputBox={true}
                            updateValue={(val) => {
                              
                              set_max_vehicle_weight(val);
                              // set_max_vehicle_weight((prev)=> val)
                            }}
                            from_containerized={load_type.title === LOAD_TYPE.fcl}
                            onTouchStart={(type) => {
                              if (type == "inc") {
                                timmerRef_weight_inc = setInterval(() => {
                                  set_max_vehicle_weight((prev) => prev + 1);
                                }, 200);
                              } else {
                                if (max_vehicle_weight > 0) {
                                  timmerRef_weight_dec = setInterval(() => {
                                    set_max_vehicle_weight((prev) => {
                                      if (prev > 1) {
                                        return prev - 1;
                                      } else {
                                        return 1;
                                      }
                                    });
                                  }, 200);
                                }
                              }
                            }}
                            onTouchEnd={() => {
                              clearInterval(timmerRef_weight_inc);
                              clearInterval(timmerRef_weight_dec);
                            }}
                            onMouseDown={(type) => {
                              if (type == "inc") {
                                set_max_vehicle_weight((prev) => prev + 1);
                                timmerRef_weight_inc = setInterval(() => {
                                  set_max_vehicle_weight((prev) => prev + 1);
                                }, 200);
                              } else {
                                set_max_vehicle_weight((prev) => {
                                  if (prev > 1) {
                                    return prev - 1;
                                  } else {
                                    return 1;
                                  }
                                });
                                timmerRef_weight_dec = setInterval(() => {
                                  set_max_vehicle_weight((prev) => {
                                    if (prev > 1) {
                                      return prev - 1;
                                    } else {
                                      return 1;
                                    }
                                  });
                                }, 200);
                              }
                            }}
                            onMouseUp={() => {
                              clearInterval(timmerRef_weight_inc);
                              clearInterval(timmerRef_weight_dec);
                            }}
                            disableMin={
                              max_vehicle_weight
                                ? max_vehicle_weight > 0
                                  ? false
                                  : true
                                : true
                            }
                            disabledMax={false}
                          />
                        </div>
                      )}

                    <div
                      style={{
                        pointerEvents: !selectVehicleTypeObj ? "none" : "auto",
                      }}
                    >
                      <VehicleIncDec
                        text="Number of Vehicles"
                        withInputBox={true}
                        value={vehicle_quantity}
                        updateValue={(val) => {
                          
                          set_vehicle_quantity(val);
                        }}
                        onTouchStart={(type: any) => {
                          if (type == "inc") {
                            timmerRef_vehicle_inc = setInterval(() => {
                              set_vehicle_quantity((prev) => prev + 1);
                            }, 200);
                          } else {
                            timmerRef_vehicle_dec = setInterval(() => {
                              set_vehicle_quantity((prev) => {
                                if (prev > 1) {
                                  return prev - 1;
                                } else {
                                  return 1;
                                }
                              });
                            }, 200);
                          }
                        }}
                        onTouchEnd={() => {
                          clearInterval(timmerRef_vehicle_inc);
                          clearInterval(timmerRef_vehicle_dec);
                        }}
                        onMouseDown={(type) => {
                          if (type == "inc") {
                            set_vehicle_quantity((prev) => prev + 1);
                            timmerRef_vehicle_inc = setInterval(() => {
                              set_vehicle_quantity((prev) => prev + 1);
                            }, 200);
                          } else {
                            set_vehicle_quantity((prev) => {
                              if (prev > 1) {
                                return prev - 1;
                              } else {
                                return 1;
                              }
                            });
                            timmerRef_vehicle_dec = setInterval(() => {
                              set_vehicle_quantity((prev) => {
                                if (prev > 1) {
                                  return prev - 1;
                                } else {
                                  return 1;
                                }
                              });
                            }, 250);
                          }
                        }}
                        onMouseUp={() => {
                          clearInterval(timmerRef_vehicle_inc);
                          clearInterval(timmerRef_vehicle_dec);
                        }}
                        name="vehicle_quantity"
                        disabledMax={false}
                        disableMin={
                          vehicle_quantity
                            ? vehicle_quantity > 0
                              ? false
                              : true
                            : true
                          // vehicle_quantity > 1 ? false : true
                        }

                      />

                      {/* for containerized  */}

                      {
                        containerType?.provide_temperature_control == 1 ?
                          <div style={{ width: '100%' }}>
                            <div className="inputField" >
                              <div style={{ width: "100%", height: 25 }} className="flex_row_div">
                                <div className="label">
                                  Required Temperature</div>
                                {/* {
                                  categoryInitialValues?.required_temperature?.length ?
                                    <>
                                      <div className="label">{`Min: ${categoryInitialValues?.required_temperature[0]}`} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                                      <div className="label">{`Max: ${categoryInitialValues?.required_temperature[1]}`} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                                    </>
                                    : null
                                } */}
                              </div>
                              <div className="field rangeSliderWrap">
                                <RangeSlider
                                  min={Number(metaData?.temperature_range?.min)}
                                  max={Number(metaData?.temperature_range?.max)}
                                  // step={1}
                                  value={categoryInitialValues?.required_temperature}
                                  orientation={'horizontal'}
                                  tooltip={true}
                                  onChange={(event, newValue) => {
                                    
                                    
                                    setCategoryInitialValue({
                                      ...categoryInitialValues,
                                      required_temperature: newValue,
                                    });
                                  }}

                                />
                              </div>
                            </div>
                          </div>
                          : null
                      }

                      {containerType?.provide_genset == 1 ? (
                        <div style={{ marginTop: '1rem', width: '100%' }}>
                          <SimpleCheckBox
                            id={"is_genset"}
                            labelName="is_genset"
                            name="is_genset"
                            title="Genset Required"
                            handleChange={(e) => {
                              setCategoryInitialValue({
                                ...categoryInitialValues,
                                is_genset: e.target.checked,
                              });
                            }}
                            checked={categoryInitialValues?.is_genset ? true : false}
                          />
                        </div>
                      ) : null}


                      {/* for non-cont vehicle basis */}
                      {
                        selectVehicleTypeObj?.provide_temperature_control == 1 ?
                          <div style={{ width: '100%' }}>
                            <div className="inputField" >
                              <div style={{ width: "100%", height: 25 }} className="flex_row_div">
                                <div className="label">
                                  Required Temperature</div>
                                {/* {
                                  initialValues?.required_temperature?.length ?
                                    <>
                                      <div className="label">{`Min: ${categoryInitialValues?.required_temperature[0]}`} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                                      <div className="label">{`Max: ${categoryInitialValues?.required_temperature[1]}`} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                                    </>
                                    : null
                                } */}
                              </div>

                              <div className="field rangeSliderWrap">
                                <RangeSlider
                                  min={Number(metaData?.temperature_range?.min)}
                                  max={Number(metaData?.temperature_range?.max)}
                                  // step={1}
                                  value={categoryInitialValues?.required_temperature}
                                  orientation={'horizontal'}
                                  tooltip={true}
                                  onChange={(event, newValue) => {
                                    
                                    
                                    setCategoryInitialValue({
                                      ...categoryInitialValues,
                                      required_temperature: newValue,
                                    });
                                  }}

                                />
                              </div>
                            </div>
                          </div>
                          : null}

                      {selectVehicleTypeObj?.provide_genset == 1 && load_type.title !== LOAD_TYPE.fcl ? (
                        <div style={{ marginTop: '1rem', width: '100%' }}>
                          <SimpleCheckBox
                            id={"is_genset"}
                            labelName="is_genset"
                            name="is_genset"
                            title="Genset Required"
                            handleChange={(e) => {
                              setCategoryInitialValue({
                                ...categoryInitialValues,
                                is_genset: e.target.checked,
                              });
                            }}
                            checked={categoryInitialValues?.is_genset ? true : false}
                          />
                        </div>
                      ) : null}

                    </div>
                  </div>
                </FadeInComponent>

                <FadeInComponent>
                  <div className="sectionBox">
                    {/*  fields for contract screen */}
                    {selectVehicleTypeObj?.is_boxed == 1 && (
                      <div style={{ display: "flex", marginBottom: 8 }}>
                        <SimpleCheckBox
                          // icon={Images.box}
                          title="Box"
                          checked={categoryInitialValues.is_boxed}
                          handleChange={(e) => {
                            setCategoryInitialValue({
                              ...categoryInitialValues,
                              is_boxed: e.target.checked,
                            });
                          }}
                          labelName="is_boxed"
                          id="is_boxed"
                        />
                      </div>
                    )}

                    {selectVehicleTypeObj?.is_sidewall == 1 && (
                      <div style={{ display: "flex", marginBottom: 8 }}>
                        <SimpleCheckBox
                          checked={categoryInitialValues.is_sidewall}
                          handleChange={(e) => {
                            setCategoryInitialValue({
                              ...categoryInitialValues,
                              is_sidewall: e.target.checked,
                            });
                          }}
                          labelName="is_sidewall"
                          id="is_sidewall"
                          // icon={Images.wall}
                          title="Side wall"
                        />
                      </div>
                    )}

                    {selectVehicleTypeObj?.is_support_twin_load === 1 && (
                      <div style={{ marginTop: 10 }}>
                        <SwitchableWithText
                          id={"is_twin_load"}
                          handleChange={(e) => {
                            if (
                              e.target.checked &&
                              selectVehicleTypeObj?.title === "40 ft Trailer"
                            ) {
                              setTemp(selectVehicleTypeObj.min_capacity);
                              setSelectVehicleTypeObj({
                                ...selectVehicleTypeObj,
                                min_capacity: 45,
                              });
                            }
                            if (
                              !e.target.checked &&
                              selectVehicleTypeObj?.title === "40 ft Trailer"
                            ) {
                              selectVehicleTypeObj.min_capacity = temp;
                              setSelectVehicleTypeObj(selectVehicleTypeObj);
                              setTemp(null);
                            }
                            // formikVehicleType.handleChange(e);
                            setCategoryInitialValue({
                              ...categoryInitialValues,
                              is_twin_load: e.target.checked,
                            });
                          }}
                          checked={categoryInitialValues.is_twin_load}
                          name="is_twin_load"
                          // icon={Images.TwinLoad}
                          text="Twin Load (2x20 Ft)"
                        // checked={formikVehicleType.values.is_twin_load}
                        />
                      </div>
                    )}
                    {/* end fields for contract screen */}

                    {load_type.title !== LOAD_TYPE.fcl && (
                      <div>
                        <div className="titleSection">
                          Packaging Type
                        </div>

                        <CustomizedSelects
                          classProp="mb-0"
                          placeholder={"Select Packaging Type"}
                          selectedValue={packageType?.id}
                          values={
                            packageType ? postLoadMeta?.package_types : []
                          }
                          disabled={true}
                          modalState={true}
                          handleModal={(value: boolean) => {
                            setPackageModal(value);
                          }}
                        ></CustomizedSelects>
                        {packageType?.title === "Other" && (
                          <div className="inputField" style={{ marginTop: '1rem', marginBottom: 0 }}>
                            <div className="label">Other package type</div>
                            <div className="field">
                              <SimpleInput
                                onChange={(e) => {
                                  setCategoryInitialValue({
                                    ...categoryInitialValues,
                                    package_type_other: e.target.value,
                                  });
                                }}
                                id="package_type_other"
                                name="package_type_other"
                                value={
                                  categoryInitialValues?.package_type_other
                                    ? categoryInitialValues?.package_type_other
                                    : ""
                                }
                                type="text"
                                placeholder="Type your Packaging type here"
                                aria-describedby="standard-weight-helper-text"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    <div
                      style={{
                        pointerEvents: !containerType ? "none" : "auto",
                      }}
                      className="switchDivWrap"
                    >
                      {((containerType && containerType.is_guage == 1) ||
                        (load_type.title === "BULK" &&
                          tempQuoteBy === "vehicle")) && (
                          <div style={{ marginBottom: '0.5rem' }}>
                            <SwitchableWithText
                              checked={categoryInitialValues.is_out_of_guage}
                              handleChange={(e) => {
                                setCategoryInitialValue({
                                  ...categoryInitialValues,
                                  is_out_of_guage: e.target.checked,
                                });
                              }}
                              name="is_out_of_guage"
                              id="is_out_of_guage"
                              // icon={Images.Gauge}
                              text={
                                load_type.title === "BULK"
                                  ? "Heavy Lift / Out of Gauge"
                                  : "Out of Gauge"
                              }
                            />
                          </div>
                        )}

                      {load_type?.title === LOAD_TYPE.fcl && (
                        <SwitchableWithText
                          id={"is_shipper_owned_container"}
                          handleChange={(e) => {
                            setCategoryInitialValue({
                              ...categoryInitialValues,
                              is_shipper_owned_container: e.target.checked,
                            });
                          }}
                          name="is_shipper_owned_container"
                          // icon={Images.ContainerPic}
                          text="Shipper Owned Container(SOC)"
                          checked={
                            categoryInitialValues.is_shipper_owned_container
                          }
                        />
                      )}
                    </div>



                    {PostLoad?.from == "forward" ? (
                      <div style={{ marginTop: 15 }}>
                        <div className="error-container info">
                          <div>
                            <InfoIcon className="error-icon" />
                          </div>
                          <div className="error-text">
                            You cannot add another category or delete category in
                            forward case.
                          </div>
                        </div>
                      </div>
                    ) : null}



                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    <OutLineButton
                      type={"submit"}
                      title={editMode ? "Update Category" : "Add Another Category"}
                      disabled={
                        ((load_type.title === LOAD_TYPE.fcl &&
                          initialValues.load_name &&
                          containerType &&
                          selectVehicleTypeObj && max_vehicle_weight > 0 && vehicle_quantity > 0)
                          && (containerType?.provide_temperature_control ? (categoryInitialValues?.required_temperature[0] !== 0
                            || categoryInitialValues?.required_temperature[1] > 0)
                            : true))
                          ||
                          (load_type.title !== LOAD_TYPE.fcl && max_vehicle_weight > 0 && vehicle_quantity > 0 &&
                            (packageType && packageType?.title === "Other"
                              ? categoryInitialValues?.package_type_other !== ""
                              : packageType) &&
                            selectVehicleTypeObj &&
                            initialValues.load_name)
                          && (selectVehicleTypeObj?.provide_temperature_control ? (categoryInitialValues?.required_temperature[0] !== 0
                            || categoryInitialValues?.required_temperature[1] > 0)
                            : true)

                          ? false
                          : true
                      }
                      onClick={() => {
                        handleAddEditCategory(false);
                      }}
                    />
                  </div>


                </FadeInComponent>
                {addedVehicles && !!addedVehicles.length && (
                  <FadeInComponent>
                    <div className="sectionBox">
                      <VehicleTable
                        screenTitle={load_type.title}
                        onClickDelete={(index: number) => {
                          handleDelete(index);
                        }}
                        tempQuoteBy={tempQuoteBy}
                        loadType={load_type.title}
                        addedVehicles={addedVehicles.sort(
                          (a, b) =>
                            a?.selectVehicleTypeObj?.title?.split(" ")[0] -
                            b?.selectVehicleTypeObj?.title?.split(" ")[0]
                        )}
                        handleEdit={handleVehicleCategoryEdit}
                      />

                      {PostLoad?.from !== "forward" ? (
                        <div
                          style={{
                            display: "none",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <OutlineButton
                            title="Add another category"
                            onClick={() => {
                              element.scrollTop = 0;
                            }}
                          // buttonTitleStyle={{ fontSize: 12 }}
                          // buttonStyle={{ height: 35, width: 250 }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </FadeInComponent>
                )}
              </>
            )}

            {/* applies to only unit basis  */}
            {unitBasisValues?.is_unit_basis ? (
              <FadeInComponent>
                <div className="sectionBox">
                  <div className="inputField">
                    <div className="label">Dimensions</div>
                    <div className="field">
                      <div className={`input-container-new ${unitBasisValues?.dimensions ? 'filled' : ''}`}>
                        <input
                          type={"text"}
                          onChange={(e) => {
                            if (e.target.value.length <= 30) {
                              setUnitBasisValues({
                                ...unitBasisValues,
                                dimensions: e.target.value,
                              });
                            } else { }
                          }}
                          value={unitBasisValues.dimensions}
                          aria-describedby="standard-weight-helper-text"
                          className={`new-inut-field`}
                          placeholder={"45x76x26"}
                        />
                        <span className="inputSlide"></span>
                      </div>
                    </div>
                  </div>

                  <div className="fw-5">
                    Unit of Dimension
                  </div>
                  <Select
                    placeholder="Select Hours"
                    name="vehicle_type"
                    className="add-vehicle-select-box2 customSelectBox"
                    style={{ marginBottom: '1.8rem' }}
                    value={
                      unitBasisValues?.unit_dimension_value
                        ? unitBasisValues?.unit_dimension_value
                        : "Select Unit"
                    }
                    onChange={(e) => {
                      setUnitBasisValues({
                        ...unitBasisValues,
                        unit_dimension_value: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value={"Select Unit"}>
                      Select Unit
                    </MenuItem>
                    {postLoadMeta?.unit_basis_dimensions?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          selected={
                            item == unitBasisValues.unit_dimension_value
                          }
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  <VehicleIncDec
                    className="labelWithIncrementField"
                    text="Item Quantity"
                    value={item_quantity_tem}
                    name={"item_quantity"}
                    withInputBox={true}
                    updateValue={(val) => {
                      set_item_quantity_tem(val);
                    }}
                    onTouchStart={(type) => {
                      if (type == "inc") {
                        timmerRef_weight_inc = setInterval(() => {
                          set_item_quantity_tem((prev) => prev + 1);
                        }, 200);
                      } else {
                        if (item_quantity_tem > 0) {
                          timmerRef_weight_dec = setInterval(() => {
                            set_item_quantity_tem((prev) => {
                              if (prev > 1) {
                                return prev - 1;
                              } else {
                                return 1;
                              }
                            });
                          }, 200);
                        }
                      }
                    }}
                    onTouchEnd={() => {
                      clearInterval(timmerRef_weight_inc);
                      clearInterval(timmerRef_weight_dec);
                    }}
                    onMouseDown={(type) => {
                      if (type == "inc") {
                        set_item_quantity_tem((prev) => prev + 1);
                        timmerRef_weight_inc = setInterval(() => {
                          set_item_quantity_tem((prev) => prev + 1);
                        }, 200);
                      } else {
                        set_item_quantity_tem((prev) => {
                          if (prev > 1) {
                            return prev - 1;
                          } else {
                            return 1;
                          }
                        });
                        timmerRef_weight_dec = setInterval(() => {
                          set_item_quantity_tem((prev) => {
                            if (prev > 1) {
                              return prev - 1;
                            } else {
                              return 1;
                            }
                          });
                        }, 200);
                      }
                    }}
                    onMouseUp={() => {
                      clearInterval(timmerRef_weight_inc);
                      clearInterval(timmerRef_weight_dec);
                    }}
                    disableMin={
                      item_quantity_tem
                        ? item_quantity_tem > 0
                          ? false
                          : true
                        : true
                    }
                    disabledMax={false}
                  />

                  <div className="simpleLabel fw-5">Item Description</div>
                  <div
                    className="textareaWrapper"
                    style={{ marginBottom: 25 }}
                  >
                    <textarea
                      maxLength={250}
                      onChange={(e) => {
                        setUnitBasisValues({
                          ...unitBasisValues,
                          unit_description: e.target.value,
                        });
                      }}
                      value={unitBasisValues?.unit_description}
                      id={"cargo_description"}
                      name={"cargo_description"}
                      placeholder="Optional"
                    />
                  </div>
                  <OutLineButton
                    type={"submit"}
                    title={
                      unit_edit_mode
                        ? "Update Item"
                        : "Add another Item"
                    }
                    disabled={handleDisableAddUnit()}
                    onClick={() => {

                      handleAddUpdateUnit(false);
                    }}
                  />
                  {PostLoad?.from == "forward" ? (
                    <div className="error-container info">
                      <div>
                        <InfoIcon className="error-icon" />
                      </div>
                      <div className="error-text">
                        You cannot add another category or delete category in
                        forward case.
                      </div>
                    </div>
                  ) : null}

                  {/* render added units  */}

                  <div style={{ marginTop: 30, marginBottom: 20 }}>
                    {added_units.map((item, index) => {
                      return (
                        <div key={index} className="addVehicleRow" style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            onClick={() => {
                              set_edit_unit_modal(true);
                              set_edit_unit_data(item);
                              set_edit_unit_index(index);
                              set_item_quantity_tem(item?.unit_quantity)
                              // hanleEditUnit(item, index)
                            }}
                            className="addVehicleRowContent fw-5"
                          >
                            {`${item?.unit_quantity} X Units`}
                          </div>

                          <div
                            className="addVehicleRowDelete"
                            onClick={() => {
                              if (PostLoad?.from == "forward") {
                                showMessage.current({
                                  message:
                                    "You can not delete item in forward case",
                                  status: "error",
                                });
                              } else {
                                handleDeleteUnit(index);
                              }
                            }}
                            style={{
                              cursor: "pointer",
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <span className="icons-cross"></span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </FadeInComponent>
            ) : null}

            <FadeInComponent>
              <SimpleCheckBox
                labelName="is_allow_partial_load"
                title="Partial Load Allowed"
                id={"is_allow_partial_load"}
                handleChange={(e) => {
                  set_is_allow_partial_load(e.target.checked);
                }}
                checked={is_allow_partial_load}
              />
            </FadeInComponent>

            {!unitBasisValues?.is_unit_basis ? (
              <div>
                <FadeInComponent>
                  <div className="fw-5" style={{ marginTop: 25 }}>
                    <div> Cargo Description</div>
                  </div>
                  <div className="textareaWrapper">
                    <textarea
                      maxLength={250}
                      onChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          cargo_description: e.target.value,
                        });
                      }}
                      value={initialValues?.cargo_description}
                      id={"cargo_description"}
                      name={"cargo_description"}
                      placeholder="Optional"
                    />
                  </div>
                </FadeInComponent>
              </div>
            ) : null}

            {load_type.title == LOAD_TYPE.bulk &&
              initialValues.quote_by == QUOTE_BY.weightVolume &&
              !unitBasisValues?.is_unit_basis ? (
              <FadeInComponent>
                <SimpleCheckBox
                  labelName="is_shared_vehicle_not_allowed"
                  title="Shared Vehicle Allowed"
                  id={"is_shared_vehicle_not_allowed"}
                  handleChange={(e) => {
                    setInitialValues({
                      ...initialValues,
                      is_shared_vehicle_not_allowed: e.target.checked,
                    });
                  }}
                  checked={initialValues.is_shared_vehicle_not_allowed}
                />
              </FadeInComponent>
            ) : null}
          </div>
        
          {
            unitBasisValues?.is_unit_basis ?
              <PrimaryButton
                rightIcon
                title="CONTINUE"
                type="submit"
                disabled={!handleDisabilityContinue() && initialValues?.load_name?.length > 0  ? false : true}
                onClick={() => {
                  handleAddUpdateUnit(true);
                }}
              /> :
              <PrimaryButton
                rightIcon
                title="CONTINUE"
                type="submit"
                disabled={
                  !handleDisabilityContinue() && initialValues?.load_name?.length > 0 ? false : true
                }
                onClick={() => {
                  handleAddEditCategory(true);
                }}
              />
          }
        </form>
      </div>
      <CustomModal
        showModal={unit_edit_modal}
        toggleModal={() => {
          set_edit_unit_modal(!unit_edit_modal);
          set_unit_edit_mode(false);
        }}
        modalStyle={{
          minWidth: 330,

        }}
      >
        <div className="detailModal">
          <div className="display-flex-row mb-3">
            <div className="titleSection mb-0">
              Item Category
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                set_edit_unit_modal(!unit_edit_modal);
                set_unit_edit_mode(false);
              }}
            >
              <span className="modalClose icons-cross"></span>
            </div>
          </div>

          <div className="formGrid">
            <div className="grayLabel">
              Gross weight
            </div>
            <div className="fw-5">
              {unit_edit_data?.gross_weight + " Ton"}
            </div>
          </div>

          <div className="formGrid">
            <div className="grayLabel">
              Cargo volume
            </div>
            <div className="fw-5">
              {unit_edit_data?.cargo_volume + " CBM"}
            </div>
          </div>

          <div className="formGrid">
            <div className="grayLabel">
              Dimensions
            </div>
            <div className="fw-5">
              {unit_edit_data?.dimensions}
            </div>
          </div>

          <div className="formGrid">
            <div className="grayLabel">
              Unit of Dimension
            </div>
            <div className="fw-5">
              {unit_edit_data?.unit_dimension_value}
            </div>
          </div>

          <div className="formGrid">
            <div className="grayLabel">
              Item Quantity
            </div>
            <div className="fw-5">
              {unit_edit_data?.unit_quantity}
            </div>
          </div>
        </div>

        <OutlineButton
          title="EDIT"
          buttonStyle={{ marginTop: 15 }}
          onClick={() => {
            hanleEditUnit(unit_edit_data);
            set_unit_edit_mode(true);
            set_edit_unit_modal(false);
          }}
        />
      </CustomModal>

      {/* vehicle type dropdown modal  */}
      <SimpleModal
        showModal={showModal}
        selected={selectVehicleTypeObj?.id}
        title={"Select Vehicle Type"}
        setType={handleChange}
        handleModal={(value: boolean) => {
          setShowModal(value);
        }}
        values={
          filterBy
            ? allVehicleTypes?.filter((item) => item?.category == filterBy)
            : allVehicleTypes
        }
      ></SimpleModal>

      {/* package type dropdworn modal */}
      <SimpleModal
        showModal={packageModal}
        selected={packageType?.id}
        title={"Select Packaging Type"}
        setType={handleChangePackage}
        handleModal={(value: boolean) => {
          setPackageModal(value);
        }}
        values={
          postLoadMeta?.package_types && postLoadMeta?.package_types.length
            ? postLoadMeta?.package_types.filter(
              (val) => val.type === "package"
            )
            : []
        }
      ></SimpleModal>

      {/* vehicle type dropdown modal */}
      <SimpleModal
        showModal={showPreferredVehicleTypeModal}
        selected={PreferredVehicleType?.id}
        title={"Select Vehicle Type"}
        setType={handleChangePreferredVehicleType}
        handleModal={(value: boolean) => {
          setShowPreferredVehicleTypeModal(value);
        }}
        values={allPreferredVehicleTypes}
      ></SimpleModal>

      {/* container type dropdown modal */}
      <SimpleModal
        showModal={showContainerModal}
        selected={containerType?.id}
        title={"Select Container Type"}
        setType={handleChangeContainer}
        handleModal={(value: boolean) => {
          setShowContainerModal(value);
        }}
        values={allContainerType}
      ></SimpleModal>

      {/* info modal  */}
      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showModalIcon}
        toggleModal={toggleModalIcon}
      >
        <div>
          <InfoIcon className="icon" />
          <p className="title">Information</p>
          <p>
            {info}
          </p>
        </div>
      </CustomModal>

    </TitleLayout>
  );
};