import React from "react";
import { CustomText } from "@components";
import { Images, Colors } from "@temp/assets";
import "./style.scss";
import { CheckSMSPageProps } from "./types";

const Page: React.FC<CheckSMSPageProps> = (props) => {
  return (
    <div className="sms-wrapper">
      <div>
        <img src={Images.MgLogo} className="img-logo" />
        {/* <img src={Images.undrawEmail} className="sms-logo" /> */}
      </div>
      <div className="text-sms">
        <CustomText.OpenSansSemiBold
          textStyle={{
            fontSize: "1.125rem",
            color: Colors.Black,
          }}
        >
          Check your SMS
        </CustomText.OpenSansSemiBold>
      </div>
      <div>
        <CustomText.OpenSansRegular
          textStyle={{
            fontSize: "1rem",
            color: Colors.Grayish,
            marginLeft: 24,
            marginRight: 24,
            marginTop: 10,
          }}
        >
          We have sent a link on your provided given number to reset your
          password.
        </CustomText.OpenSansRegular>
      </div>
      <div className="verify-link">
        <CustomText.OpenSansRegular
          textStyle={{
            fontSize: "0.75rem",
            color: Colors.Grayish,

            marginTop: 10,
          }}
        >
          Didn't receive a SMS?
        </CustomText.OpenSansRegular>

        <CustomText.OpenSansRegular
          textStyle={{
            fontSize: "0.75rem",
            color: Colors.LightBlue,
            marginTop: 10,
          }}
        >
          Send Link again
        </CustomText.OpenSansRegular>
      </div>
    </div>
  );
};

export default Page;
