import React, { useEffect, useState } from "react";
import { AddIVehiclePageProps } from "./types";
import "./style.scss";
import { Colors } from "@temp/assets";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DrawerAction } from "@temp/store/actions";
import { CustomText, PrimaryButton, CustomModal, SimpleCheckBox, PhoneInput, NewInput } from "@components";
import rootReducer from "../../../store/reducers";
import { preventNonNumericalInput, showMessage } from "@temp/utils/helper";
import { MenuItem, Select } from "@mui/material/";
import TitleLayout from "@temp/components/TitleLayout";
import DialogTitle from "@mui/material//DialogTitle";
import { Button } from "@mui/material/";
import {
  SimpleInput,
} from "@temp/components";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import Radio, { RadioProps } from "@mui/material//Radio";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InfoIcon from "@mui/icons-material/Info";
import SimplePhoneInput from "@temp/components/SimplePhoneInput";

export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<AddIVehiclePageProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;

  const postLoadMeta = useSelector((state: RootState) => state.Profile.postLoadMeta);
  const [plateNumber, setPlateNumber] = useState(state?.data ? state?.data?.plate_number : null);
  const [vehicleType, setVehicleType] = useState(state?.data ? state?.data?.master_vehicle_type?.id : null);
  const [driverName, setDriverName] = useState(state?.data ? state?.data?.driver_name : null);
  const [driverMobileNumber, setDriverMobileNumber] = useState(state?.data ? state?.data?.driver.phone_no : "");
  const [modelName, setModelName] = useState(state?.data ? state?.data?.model : null);
  const [brand, setBrand] = useState(state?.data ? state?.data?.brand : null);
  const [year, setYear] = useState(state?.data ? state.data.year : null);
  const [color, setColor] = useState(state?.data ? state?.data?.color : "");
  const [tplDevice, setTplDevice] = useState(state?.data ? state?.data?.tracking_vehicle_reg_no ? true : false : false);
  const [tplDeviceImei, setTplDeviceImei] = useState(state?.data ? state?.data?.tracking_vehicle_reg_no : null);
  const [appearModal, setAppearModal] = useState(false);
  const [showTrackerModal, setShowTrackerModal] = useState(false);
  const [DeviceImeiDropDownLabel, setDeviceImeiDropDownLabel] = useState(null);
  const [DeviceImeiDropDownValue, setDeviceImeiDropDownValue] = useState(state?.data?.tracking_service_provider ? state?.data?.tracking_service_provider : "");
  const [showModalWaterMark, setShowModalWaterMark] = useState(false);
  const [showInfoWaterMark, setShowInfoWaterMark] = useState("");
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const deviceImei = useSelector((state: RootState) => metaData?.vehicle_tracking_services);

  const callBack = () => {
    history.goBack();
  };

  const cb_2 = () => {
    history.push(`/load-details-recipient/${state?.data?.load_id}/received`);
  };

  useEffect(() => {
    setDeviceImeiDropDownLabel(deviceImei?.find((itm) => itm?.value === state?.data?.tracking_service_provider)?.label)
  }, [])

  const addEditVehicle = () => {
    const payload = {
      id: state?.id,
      plate_number: plateNumber,
      master_vehicle_type_id: vehicleType,
      model: modelName,
      brand: brand,
      year: year,
      driver_name: driverName,
      driver_phone_no: driverMobileNumber,
      driver_phone_code: "92",
      color: color,
      cb: callBack,
      cb_2: cb_2,
      vehicle_no: state?.data?.vehicle_no,
      from: state?.from,
      load_id: state?.data?.load_id,
      cat_id: state?.data?.cat_id,
      link_device: tplDevice,
      tracking_vehicle_reg_no: tplDeviceImei,
      tracking_service_provider: DeviceImeiDropDownValue

    };

    if (plateNumber) {
      // if (vehicleType) {
      if (driverMobileNumber) {
        if (state?.from === "detail" || state?.from === "edit") {

          // return
          dispatch(DrawerAction.EditVehicleSelector(payload));
        } else {

          // return
          dispatch(DrawerAction.AddVehicleSelector(payload));
        }
      } else {
        showMessage.current({
          message:
            "Driver's phone number is required.",
          status: "error",
        });
      }
    } else {
      showMessage.current({
        message: "Plate number is required.",
        status: "error",
      });
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    // Remove non-numeric characters
    const numericValue = paste.replace(/[^0-9]/g, '');
    // Prevent pasting if the first character is 0
    if (event?.target?.value.length > 10) {
      return;
    }
    // if (numericValue[0] !== '0') {
    //   setDriverMobileNumber(numericValue);
    // }

    // updated code Prevent pasting if the first character is '0' or the first two characters are '92'
    if (numericValue.startsWith('0') || numericValue.startsWith('92')) {
      return;
    }
    // Set the pasted value to driverMobileNumber
    setDriverMobileNumber(numericValue);

  }

  const handleChange = (e) => {
    let newValue = e.target.value;
    // Remove non-digit characters (including hyphens)
    newValue = newValue.replace(/[^0-9]/g, '');
    // Remove specific disallowed numbers like '92'
    if (newValue.startsWith('92')) {
      newValue = newValue.slice(2); // Remove '92' from the start
    }
    // Remove leading zeros
    newValue = newValue.replace(/^0+/, '');
    // Limit length to 10 digits
    newValue = newValue.slice(0, 10);
    setDriverMobileNumber(newValue);
  };
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  return (
    <TitleLayout
      titleOnly={true}
      titleOnlyText={state?.from === "edit" ? "Edit vehicle" : state?.from === "detail" ? "Update Driver" : "Add vehicle"}
    >
      <div className="addVehicleWrapper">
        <FadeInComponent>
          <div className="inputField">
            <div className="label">Plate Number *</div>
            <div className="field">
              <SimpleInput
                maxLength={15}
                value={plateNumber}
                onChange={(event) => {
                  setPlateNumber(event.target.value);
                }}
                placeholder={"Enter Plate Number"}
              />
            </div>
          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="bodNone" style={{ marginBottom: '1.5rem' }}>
            <div className="fw-5 ">Vehicle Type (Optional)</div>
            <Select
              name="vehicle_type"
              className="add-vehicle-select-box userSelectBox"
              value={vehicleType}
              disabled={state?.from === "detail" ? true : false}
              onChange={(event) => {
                setVehicleType(event.target.value);
              }}
              placeholder={"Select Vehicle Type"}

            >
              <MenuItem value="">Select Type (Optional)</MenuItem>
              {postLoadMeta?.vehicle_types?.map((item) => {
                return (
                  <MenuItem selected={item?.id == vehicleType} value={item?.id}>
                    {item?.title}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="inputField">
            <div className="label">Driver Name</div>
            <div className="field">
              <SimpleInput
                value={driverName}
                type="text"
                onChange={(event) => {
                  setDriverName(event.target.value);
                }}
                placeholder={"(Optional)"}
              />
            </div>
          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="inputField driverNumberField">
            <div className="label">Driver Mobile Number *</div>
            <div className="field">
              {/* <PhoneInput
                value={driverMobileNumber}
                phoneCode={"92"}
                onChange={(e) => {

                  if (e?.target.value.length > 10) {
                    return;
                  }
                  setDriverMobileNumber(e.target.value);
                }}
                // onChange={handleChange}
                // onPaste={handlePaste}
                id="phone-number"
                placeholder={"3000000000"}
                maxLength={10}

              /> */}
              <SimplePhoneInput
                value={driverMobileNumber}
                onChange={handleChange}
                onPaste={handlePaste}
                placeholder="3000000000"
                maxLength={10}
                id="phone-number"
              />
            </div>
          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="inputField">
            <div className="label">Color</div>
            <div className="field">
              <SimpleInput
                disabled={state?.from === "detail" ? true : false}
                name="name"
                value={color}
                onChange={(event) => {
                  setColor(event.target.value);
                }}
                placeholder={"(Optional)"}
              />
            </div>
          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="inputField">
            <div className="label">Make/Model</div>
            <div className="field">
              <SimpleInput
                value={modelName}
                onChange={(event) => {
                  setModelName(event.target.value);
                }}
                placeholder={"(Optional)"}
              />
            </div>
          </div>
        </FadeInComponent>

        <FadeInComponent>
          <div className="inputField">
            <div className="label">Year</div>
            <div className="field">
              <SimpleInput
                value={year}
                onChange={(event) => {
                  setYear(event.target.value);
                }}
                placeholder={"(Optional)"}
              />
            </div>
          </div>
        </FadeInComponent>

        {/* TPL DEVICE Checkbox and Input field added */}
        <FadeInComponent>
          <div
            className={
              tplDevice
                ? "selectBoxWrapper"
                : ""
            }
          >
            <SimpleCheckBox
              checked={tplDevice}
              handleChange={() => {
                setTplDevice(!tplDevice);
                if (!tplDevice) {
                  setTplDeviceImei("");
                  setDeviceImeiDropDownLabel("")
                  setDeviceImeiDropDownValue("")
                }
              }}
              title="Link Tracking Device (Optional)"
            />
            <div className="inputWrapper">
              {tplDevice ? (
                <div className="inputField">
                  {/* <div className="label">Select Tracker Type</div>
                  <Select
                    id="outlined-select-date-native"
                    select
                    defaultValue={DeviceImeiDropDown}
                    value={DeviceImeiDropDown}
                    name='deviceImei'
                    onChange={
                      handleDeviceImei
                    }
                    style={{ marginLeft: 5, height: 38, width: "95%", marginTop: 10 }}

                  >
                    {deviceImei?.map((item) => {
                      
                      return (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  <div className="label" style={{ marginTop: 13 }}>
                    {`Enter ${DeviceImeiDropDown == "tpl" ? "TPL Trakker" : "Tracking World"} ID`}
                  </div> */}
                  <div
                    onClick={() => {
                      setShowTrackerModal(true);
                    }}
                  >
                    <div className={`customSelectBox`}>
                      {DeviceImeiDropDownLabel ? DeviceImeiDropDownLabel : "Select Tracker"} <ArrowDropDownIcon />
                    </div>
                  </div>
                  <div className="label" style={{ marginTop: 13 }}>
                    Enter Device ID
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          metaData?.tracking_information);
                        setShowModalWaterMark(true);
                      }}
                    />
                  </div>
                  <div className="field">
                    <SimpleInput
                      value={tplDeviceImei}
                      onChange={(event) => {
                        setTplDeviceImei(event.target.value);
                      }}
                      placeholder=" "
                      maxLength={30}
                    />
                  </div>
                </div>

              ) : null}
            </div>
          </div>
        </FadeInComponent>
      </div>
      {
        state?.from == 'drawer' ? null :
          <FadeInComponent>
            <PrimaryButton
              title={
                state?.from == "edit"
                  ? "Update VEHICLE"
                  : state?.from == "detail"
                    ? "Save & add"
                    : "ADD VEHICLE"
              }
              onClick={() => {
                if (location?.state?.from == 'detail') {
                  let is_active = state?.data?.containers?.find((itm) => itm?.vehicle?.plate_number == plateNumber && itm?.status !== 3)
                  let is_active2 = state?.data?.containers?.find((itm) => itm?.vehicle?.plate_number == plateNumber && itm?.status === 3)
                  // return
                  if (is_active) {
                    showMessage.current({ message: "This vehicle has already assigned a load.", status: 'error' })
                  } else if (is_active2) {
                    setAppearModal(true);
                  } else {
                    addEditVehicle();
                  }
                } else {
                  addEditVehicle();
                }
              }}
              disabled={driverMobileNumber?.length < 10}
            />
          </FadeInComponent>
      }

      <CustomModal
        showModal={appearModal}
        toggleModal={setAppearModal}
        modalStyle={{
          width: 290,
        }}
      >
        <div className="logout-modal-background">
          <DialogTitle id="simple-dialog-title">

            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginTop: 20,
                fontSize: "0.875rem"
              }}
            >
              Repeated placement of vehicle
            </CustomText.OpenSansRegular>
            <div className="action-button-container">
              <Button
                onClick={() => {
                  setAppearModal(false);
                }}
                style={{
                  backgroundColor: Colors.Grayish,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  Cancel
                </CustomText.OpenSansSemiBold>
              </Button>
              <Button
                onClick={() => {
                  addEditVehicle();
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  CONTINUE
                </CustomText.OpenSansSemiBold>
              </Button>
            </div>
          </DialogTitle>
        </div>
      </CustomModal>

      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showTrackerModal}
        toggleModal={setShowTrackerModal}
      >
        {deviceImei?.map((tracker, index) => {

          return (
            <div className="flexRow CustomGreenRadio">
              <Radio
                checked={DeviceImeiDropDownLabel == tracker?.label}
                value={tracker?.label}
                onClick={() => {
                  setDeviceImeiDropDownValue(tracker?.value)
                  setDeviceImeiDropDownLabel(tracker?.label)
                  setShowTrackerModal(false)
                }}

                style={{
                  color: "#7ac251",
                }}
              />
              <CustomText.OpenSansRegular
                textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
              >
                {tracker?.label}
              </CustomText.OpenSansRegular>
            </div>
          );
        })}
      </CustomModal>

      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showModalWaterMark}
        toggleModal={setShowModalWaterMark}
      >
        <div>
          <InfoIcon className="icon" />
          <p className="title">Information</p>
          <p>
            {showInfoWaterMark}
          </p>
        </div>
      </CustomModal>

    </TitleLayout>
  );
};

export default Page;