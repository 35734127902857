import React, { useState, useEffect } from "react";
import { Checkbox } from "@mui/material/";
import {
  PrimaryButton,
  OutLineButton,
  CustomText,
  SignupContainer,
} from "@components";
import { Images, Colors } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { AuthAction, ProfileAction } from "@temp/store/actions";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { showMessage, setItem, preventNonNumericalInput, deviceId } from "@utils/helper";
import { TOKEN } from "@app/constants";
import { LoginPageProps } from "./types";
import DialogTitle from "@mui/material//DialogTitle";
import Dialog from "@mui/material//Dialog";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityOnIcon from "@mui/icons-material/Visibility";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import OtpInput from 'react-otp-input'
import { requestFirebaseNotificationPermission } from "../../../utils/firebaseNew";
import { Mixpanel } from "@temp/utils/mixpanel";

// var uuid = new DeviceUUID().get();
const Page: React.FC<LoginPageProps> = (props) => {
  // 
  const history = useHistory();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [otpLogin, setOtpLogin] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isPasswordLogin, setIsPasswordLogin] = useState(false);
  const [otpSignIn, setOtpSignIn] = useState(false);

  const { metaData } = useSelector((state: RootState) => state.Auth);


  useEffect(() => {
    dispatch(AuthAction.getMetaData());
  }, []);

  const handleChange = (e: any) => {
    if (e.target.name === "phone") {
      if (e.target.value.length > 10) {
        return;
      }
      setPhoneNumber(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleGetToken = async () => {
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        
        // eslint-disable-next-line no-console

        dispatch(
          ProfileAction.updateFCM({
            fcm_token: firebaseToken,
            device_id: deviceId(),
          })
        );
      })
      .catch((err) => {
        
        return err;
      });
  };

  const callBack = (data: any) => {
    if (data?.forbidden) {
      setShowModal(true);
    } else {
      if (
        data?.user?.is_toc_accepted === 1 &&
        data?.user?.password_changed === 1
      ) {
        
        let user_profession = ""
        data?.user?.user_professions?.forEach((item) => {
          user_profession = user_profession + `${item?.name}, `
        })
        
        Mixpanel.identify(data?.user?.id);
        Mixpanel.track("Successful login", null)
        Mixpanel.people.set({
          $first_name: data?.user?.name,
          $email: data?.user?.email,
          $avatar: data?.user?.picture,
          $company_name: data?.user?.company_name,
          $user_professions: user_profession,
          $business_card_front: data?.cnic_front_url,
          $business_card_back: data?.cnic_back_url,
          $city: data?.user?.city,
          $phone_number: data?.user?.phone_no ? `0${data?.user?.phone_no}` : null,
          $whatsapp_number: data?.user?.whatsapp_phone_no ? `0${data?.user?.whatsapp_phone_no}` : null
        });
        setItem(TOKEN, data?.token);
        setItem("toc_accepted", true);
        setItem("changed_password", true);
        handleGetToken()
        history.replace("/home");
      } else if (data?.user?.is_toc_accepted === 0) {
        setItem(TOKEN, data?.token);
        history.replace("/terms");
      } else if (data?.user?.password_changed === 0) {
        setItem("toc_accepted", true);
        setItem(TOKEN, data?.token);
        history.replace("/setup-password");
      } else if (data.isOTPSent) {
        if (!data?.isUserExists) {
          showMessage.current({
            message:
              // "The number is not registered.",
              "This number is not registered",
            status: "error",
          });
        } else {
          history.push("/otp", { ...data });
          showMessage.current({
            message:
              // "Otp has been successfully sent",
              "OTP has been sent successfully",
            status: "success",
          });
        }
      } else {
        showMessage.current({
          message: "The number is not registered",
          status: "error",
        });
      }
    }
  };

  const onSubmit = (type: string) => {
    if (phoneNumber) {
      const payload = {
        phoneNumber: phoneNumber,
        type,
        from: "login",
        cb: callBack,
      };
      dispatch(AuthAction.LogInWihtOtp(payload));
    }
  };

  const loginWithOtp = () => {
    // setOtpLogin(true);
    onSubmit("sms");
  };

  const loginWithPassword = () => {
    const payload = {
      phoneNumber: phoneNumber,
      password,
      from: "login",
      cb: callBack,
    };
    dispatch(AuthAction.LogInWithPassword(payload));
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const firstTimeLogInCallBack = (data) => {
	    // if (data?.is_password_set) {
    //   setIsPasswordLogin(true);
    //   setOtpSignIn(true);
    // } 
    // else if (data?.is_user_exists && data?.is_user_verified) {
    //   setOtpSignIn(false);
    // }
    // else if(data?.not_found) {
    //   setOtpSignIn(false);
    //   setIsPasswordLogin(false)
    // } else if (!data?.is_password_set) {
    //   
    //   setOtpSignIn(true);
    //   setIsPasswordLogin(false)
    // }
    if (data.is_user_exists && data?.is_user_verified) {
      if (data?.is_password_set) {
        setIsPasswordLogin(true);
        setOtpSignIn(true);
      } else {
        setOtpSignIn(true);
        setIsPasswordLogin(false)
      }
    } else if (!data.is_user_exists && !data?.is_user_verified) {
      setOtpSignIn(false);
      setIsPasswordLogin(false)
      showMessage.current({
        message:
          // "This number is not registered. Click the link below to register.",
          "This number is not registered. Tap the link below to register",
        status: "error"
      })
    } else if (data.is_user_exists && !data?.is_user_verified) {
      setShowModal(true)
    }
  };

  return (
    <div
    // className="wrapper"
    >
      <div style={{ height: "35vh" }} className="welcome-header-container">
        {/* <p
          style={{ 
            color: Colors.NavyBlue, 
            marginTop: 50 }}
          className="welcome-text"
        >
          WELCOME BACK!
        </p> */}
        <div className="logo-container">
          <img alt="" src={Images.MgLogo} />
        </div>
      </div>
      {/* new changes for first time login */}

      <div
        style={{
          height: "65vh",
        }}
        className="welcome-content-container"
      >
        <SignupContainer containerHeight="60vh">
          <div className="login-form-wraper">
            <CustomText.OpenSansSemiBold
              textStyle={{
                fontSize: "0.875rem",
                color: Colors.Grayish,
              }}
            >
              Mobile Number
            </CustomText.OpenSansSemiBold>
            <div className="login-new-container">
              <div>
                <CustomText.OpenSansRegular textStyle={{ fontSize: "1rem" }}>
                  +92
                </CustomText.OpenSansRegular>
              </div>
              <div className="otpTypeInput">
                <OtpInput
                  value={phoneNumber}
                  inputStyle={{
                    padding: 0,
                    margin: 0,
                    height: 32,
                    width: 22,
                    marginLeft: 5,
                    backgroundColor: '#ededed',
                    border: 'none',
                    outline: 'none',
                    borderRadius: 5
                  }}
                  placeholder="3000000000"
                  isInputNum={true}
                  containerStyle={{
                    // backgroundColor:'blue'
                  }}
				  renderInput={(props) => <input {...props} />}
                  onChange={(number) => {

                    if (number.length == 10) {
                      dispatch(
                        AuthAction.firstLogIn({
                          phone_no: number,
                          firstTimeLogInCallBack,
                        })
                      );
                    }
                    setPhoneNumber(number)
                  }}
                  numInputs={10}
                />
              </div>
            </div>
          </div>

          <div>
            {isPasswordLogin ? (
              <>
                {!otpLogin ? (
                  <div className="login-form-wraper" style={{
                    borderBottom: '0.4px solid #f1f1f1'
                  }}>
                    <CustomText.OpenSansSemiBold
                      textStyle={{
                        fontSize: "0.875rem",
                        color: Colors.Grayish,
                      }}
                    >
                      Password
                    </CustomText.OpenSansSemiBold>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <input
                        type={passwordType}
                        style={{
                          padding: 3,
                        }}
                        name="password"
                        className="login-form-inputt-password"
                        value={password}
                        onChange={handleChange}
                      />
                      {passwordType !== "password" ? (
                        <VisibilityOnIcon
                          style={{
                            color: "rgb(72,72,72)",
                            height: 20,
                            paddingTop: 5,
                            cursor: "pointer",
                          }}
                          onClick={togglePassword}
                        />
                      ) : (
                        <VisibilityOffIcon
                          style={{
                            color: "rgb(72,72,72)",
                            height: 20,
                            paddingTop: 5,
                            cursor: "pointer",
                          }}
                          onClick={togglePassword}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: 50,
                    }}
                  ></div>
                )}
              </>
            ) : <div style={{ height: 100 }}></div>}
          </div>

          <div>
            {isPasswordLogin ? (
              <>
                <div style={{ width: 310 }} className="check-box-container">
                  {/* <div className="check-box">
              <Checkbox style={{ color: "#7ac251" }} defaultChecked />
              <p className="keep-signin-text">Keep me signed in</p>
            </div> */}

                  {!otpLogin ? (
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push("/forgot-password");
                      }}
                    >
                      <CustomText.OpenSansRegular
                        textStyle={{
                          textDecoration: "underline",
                          color: Colors.NavyBlue,
                          marginTop: 5,
                        }}
                      >
                        Forgot password
                      </CustomText.OpenSansRegular>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: 10,
                      }}
                    ></div>
                  )}
                </div>
              </>
            ) : null}
          </div>

          {!otpLogin ? (
            <div>
              <div>
                {isPasswordLogin ? (
                  <>
                    <div className="button-divider">
                      <PrimaryButton
                        buttonStyle={{
                          width: 330,
                        }}
                        title="SIGN IN"
                        disabled={!phoneNumber || !password}
                        onClick={() => {
                          loginWithPassword();
                        }}
                      />
                    </div>
                  </>
                ) : <div style={{ height: 50 }}></div>}
              </div>

              <div>
                {otpSignIn ? (
                  <>
                    <div className="button-divider">
                      <OutLineButton
                        buttonStyle={{
                          width: 330,
                        }}
                        title="Sign in with OTP"
                        disabled={phoneNumber.length < 10}
                        onClick={() => {
                          loginWithOtp();
                        }}
                      />
                    </div>
                  </>
                ) : <div style={{ height: 60 }}></div>}
              </div>
            </div>
          ) : (
            <div>
              <div className="button-divider"></div>
              <div className="button-divider">
                <OutLineButton
                  disabled={phoneNumber.length < 10}
                  title="Send Code via SMS"
                  buttonStyle={{
                    width: 330,
                    marginTop: 100,
                  }}
                  icon={
                    phoneNumber.length >= 10
                      ? Images.MessageBubble
                      : Images.MessageBubbleGray
                  }
                  onClick={() => {
                    onSubmit("sms");
                  }}
                />
              </div>
            </div>
          )}

          <div className="demo-button">
            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginLeft: 24,
                marginRight: 24,
              }}
            >
              Don't have account?
              <span
                onClick={() => {
                  history.replace("/signup")
                  Mixpanel.track("registration start", {
                    name: "registration flow start",
                    des: 'registration flow start'
                  })
                }}
                style={{
                  color: Colors.LightBlue,
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginLeft: 5,
                  fontSize: "0.938rem",
                }}
              >
                Create Account
              </span>{" "}
            </CustomText.OpenSansRegular>
          </div>
        </SignupContainer>
      </div>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={showModal}
      >
        <DialogTitle id="simple-dialog-title">
          <CustomText.OpenSansSemiBold
            textStyle={{ fontSize: "0.875rem", width: 255, textAlign: "center" }}
          >
            If your are registered please contact MG team to verify your account.
          </CustomText.OpenSansSemiBold>
          <div className="mg-contact-info">
            <CallIcon
              style={{
                color: "rgb(122,194,81)",
                height: 20,
              }}
            />
            <CustomText.OpenSansRegular
              textStyle={{ fontSize: "0.875rem", color: Colors.Black, marginLeft: 10 }}
            >
              {metaData?.administration_contact?.phone}
            </CustomText.OpenSansRegular>
          </div>
          <div className="mg-contact-info">
            <EmailIcon style={{ color: "rgb(122,194,81)", height: 20 }} />
            <CustomText.OpenSansRegular
              textStyle={{ fontSize: "0.875rem", color: Colors.Black, marginLeft: 10 }}
            >
              {metaData?.administration_contact?.email}
            </CustomText.OpenSansRegular>
          </div>
          <div style={{ marginTop: 25, marginBottom: 30 }}>
            <PrimaryButton
              onClick={handleClose}
              title="OK"
              buttonStyle={{
                height: 40,
                width: 262,
              }}
            />
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default Page;
