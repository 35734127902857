import React, { useEffect, useRef, useState } from "react";
import { ISelectVehicleFCLPageProps } from "./types";
import "./style.scss";
import {
  LocationTimeline,
  PrimaryButton,
  TitleLayout,
  RadioButton,
  SimpleCheckBox,
  CustomizedSelects,
  SimpleModal,
  VehicleIncDec,
  NewInput,
  CustomizedSwitches,
  OutLineButton,
  VehicleTable,
  TextInput,
  SimpleInput,
  RangeSlider,
} from "@components";
import { ProfileAction } from "@temp/store/actions";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material/";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
import { SelectVehicle } from "../../../components/contractSelectVehicle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { css } from "@emotion/css";
import styled from "styled-components";
import { ADDITIONAL_DETAILS } from "@temp/app/routes";
import { useHistory } from "react-router";
import { LOAD_TYPE } from "@temp/constants";
import { UnitsLocation } from "./UnitsLocation";
import Grid from "@mui/material//Grid/Grid";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { showMessage } from "@temp/utils/helper";

export type RootState = ReturnType<typeof rootReducer>;

const pageTitle = css({
  color: "rgb(13 21 9)",
  fontSize: "0.875rem",
  marginTop: 30,
  marginBottom: 12,
  display: "flex",
  alignItems: "center",
});

let timmerRef_weight_inc = null
let timmerRef_weight_dec = null

const categoryInitialValues = {
  is_twin_load: false,
  is_out_of_gauage: false,
  is_sidewall: false,
  is_boxed: false,
  is_shipper_owned_container: false,
  package_type_other: "",
  is_loading_required: false,
  loading_requirements: '',
  is_unloading_required: false,
  unloading_requirements: '',
  is_genset: false,
  required_temperature: [0, 0]


}

const Page: React.FC<ISelectVehicleFCLPageProps> = (props) => {
  const history = useHistory();
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const [locationState, setLocationState] = useState(PostLoad?.location);
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();
  const load_type = postLoadMeta.load_types.find((val: object) => val.id === PostLoad.load_type);
  const childRef = useRef(null);
  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
  

  const handleNewVehicle = (locationIndex, vehicleObject) => {
    const locations = locationState?.length > 0 ? locationState : [];
    if (locations?.length > 0) {
      locations[locationIndex].items = locations[locationIndex]?.items
        ? [...locations[locationIndex].items, vehicleObject]
        : [vehicleObject];
    }
    setLocationState([...locations]);
  };

  // addd unit 
  const handleNewUnit = (locationIndex, unitObj) => {
    const locations = locationState?.length > 0 ? locationState : [];
    if (locations?.length > 0) {
      locations[locationIndex].added_units = locations[locationIndex]?.added_units
        ? [...locations[locationIndex].items, unitObj]
        : [unitObj];
    }
    setLocationState([...locations]);
  };

  const handleDelete = (locationIndex, vehiclesArray) => {
    let loc = locationState;
    loc[locationIndex].items = vehiclesArray;
    setLocationState([...loc]);
  };

  useEffect(() => {
    if (PostLoad?.location) {
      setLocationState(
        locationState.map((val) => {
          return {
            ...val,
            ...(true && {
              pickup: {
                attribute: {
                  gross_weight: null,
                  cargo_volume: null,
                  total_freight_ton: null,
                  no_of_packages: null,
                  package_type_other: ""
                },
                ...val.pickup,
              },
            }),
          };
        })
      );
    }
    if (PostLoad?.cargo_description) {
      set_cargo_description(PostLoad?.cargo_description)
    }
  }, [PostLoad]);

  useEffect(() => {
    if (PostLoad?.vehicleScreen) {
      setAddedVehicles(PostLoad?.vehicleScreen?.addedVehiclesTemp)
    }
  }, [])

  const [tempQuoteBy, setTemQuoteBy] = useState(
    PostLoad?.contractDetails?.quote_by || "vehicle"
  );

  const [is_unit_basis, set_is_unit_basis] = useState(false)
  const [other_type_by, set_other_type_by] = useState('not_item_basis')

  useEffect(() => {
    set_is_unit_basis(PostLoad?.contractDetails?.is_unit_basis)
    if (PostLoad?.contractDetails?.is_unit_basis) {
      set_other_type_by('item_basis')
    } else {
      set_other_type_by('not_item_basis')
    }
  }, [])


  const handleDisabilityContinue = () => {
    if (load_type.title === LOAD_TYPE.fcl) {
      setDisable(
        locationState.every((loc: any) => loc?.items?.length > 0) || !addedVehiclesTemp.length ? false : true
      );
    } else if (load_type.title !== LOAD_TYPE.fcl && tempQuoteBy == "weight_volume") {
      setDisable(
        !locationState.every(
          (loc: any) =>
            loc.pickup?.attribute?.gross_weight !== null &&
            loc.pickup?.attribute?.gross_weight !== "" &&
            loc.pickup?.attribute?.gross_weight &&
            loc.pickup?.attribute?.PreferredVehicleType &&
            loc.pickup?.attribute?.packageType &&
            (loc.pickup?.attribute?.packageType?.title === "Other"
              ? loc.pickup?.attribute?.package_type_other
              : loc.pickup?.attribute?.packageType?.title !== null &&
              loc.pickup?.attribute?.packageType?.title !== "")
        )
      );
    } else {
      if ((addedVehiclesTemp.length && (selectVehicleTypeObj?.provide_temperature_control ? (categoryValues?.required_temperature[0] !== 0
        || categoryValues?.required_temperature[1] > 0)
        : true)) ||
        (selectVehicleTypeObj && packageType && max_vehicle_weight > 0) && (
          packageType && packageType?.title === "Other"
            ? categoryValues.package_type_other
            : packageType
        ) && ((selectVehicleTypeObj?.provide_temperature_control ? (categoryValues?.required_temperature[0] !== 0
          || categoryValues?.required_temperature[1] > 0)
          : true))


      ) {
        setDisable(false)
      } else {
        setDisable(true)
      }
    }
  };

  const handleSubmit = () => {
    locationState.forEach(element => {
      element["pickup"]["attribute"]['price_unit'] = ""
    });
    dispatch(
      ProfileAction.postLoadData({
        location: [...locationState],
        cargo_description: cargo_description,
        contractDetails: {
          ...PostLoad?.contractDetails,
          quote_by: tempQuoteBy,
          is_unit_basis
        },
      })
    );

    history.push(ADDITIONAL_DETAILS, { locations: locationState });
  };

  const handleChangeInLocationState = (locationIndex, key, value) => {
    locationState[locationIndex]["pickup"]["attribute"][key] = value;
    setLocationState([...locationState]);
  };

  const [isExpanded, setIsExpanded] = useState(
    locationState && locationState.length
      ? locationState.map((val, index) => (index === 0 ? true : false))
      : []
  );

  const handleChange = (check, index) => {
    isExpanded[index] = check;
    setIsExpanded([...isExpanded]);
  };

  const set_added_units = (loc_index, data) => {
    let new_loc = [...locationState]
    if (new_loc[loc_index]?.added_units?.length >= 5) {
      showMessage.current({
        message: "You can add Maximum 5 Items",
        status: 'error'
      })
    } else {
      new_loc[loc_index].added_units = new_loc[loc_index]?.added_units
        ? [...new_loc[loc_index].added_units, data]
        : [data];
    }
    setLocationState(new_loc)
  }

  const hanleEditUnit = (loc_index, index, data) => {
    let new_loc = [...locationState]
    new_loc[loc_index].added_units[index] = data
    setLocationState(new_loc)
  }

  const handleDeleteUnit = (loc_index, index) => {
    let new_loc = [...locationState]
    new_loc[loc_index]?.added_units.splice(index, 1)
    setLocationState(new_loc)
  }

  const [selectVehicleTypeObj, setSelectVehicleTypeObj] = React.useState(null);
  let allVehicleTypes = postLoadMeta?.vehicle_types.filter((val) => val.load_type === PostLoad.load_type);
  const [showModal, setShowModal] = useState(false);
  const [max_vehicle_weight, set_max_vehicle_weight] = useState(1)
  const [vehicle_quantity, set_vehicle_quantity] = useState("")
  const [categoryValues, setCategoryValues] = useState(categoryInitialValues)
  const [temp, setTemp] = useState(null);
  const [packageType, setPackageType] = React.useState(null);
  const [packageModal, setPackageModal] = useState(false);
  const [addedVehiclesTemp, setAddedVehicles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null)
  const [cargo_description, set_cargo_description] = useState("");
  const [is_shared_vehicle_not_allowed, set_is_shared_vehicle_not_allowed] = useState(false);
  

  const handleChangeVehicleModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = (event.target as HTMLInputElement).value;
    let typeVehicle = allVehicleTypes.find(
      (val) => Number(val.id) === Number(id)
    );
    setShowModal(false);
    setSelectVehicleTypeObj(typeVehicle);
  };

  const handleChangePackage = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = (event.target as HTMLInputElement).value;
    let types = postLoadMeta?.package_types.find(
      (val: object) => Number(val.id) === Number(id)
    );
    setCategoryValues({
      ...categoryValues,
      package_type_other: ''
    })
    setPackageModal(false);
    setPackageType(types);
  };

  const SwitchableWithText = (props: any) => {
    return (
      <Grid
        container
        spacing={1}
        style={{ marginBottom: 0, alignItems: "center" }}
      >
        <Grid
          item
          xs={10}
          style={{ color: "rgb(13 21 9)", fontSize: 14, display: "flex" }}
        >
          {props?.icon ? <img src={props?.icon} width="16px" height="16px" /> : ''}
          <div style={{ marginLeft: 5, marginTop: 0 }}>{props?.text}</div>
        </Grid>

        <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginTop: 0 }}>
            <CustomizedSwitches {...props} />
          </div>
        </Grid>
      </Grid>
    );
  };

  const handleAddCaregory = () => {
    let added = {
      selectVehicleTypeObj,
      vehicle_quantity,
      containerType: null,
      max_vehicle_weight,
      values: {
        ...categoryValues,
      },
      packageType,
    };
    let new_data = [...addedVehiclesTemp]
    if (currentIndex !== null) {
      new_data[currentIndex] = added
    } else {
      if (addedVehiclesTemp.length >= 5) {
        showMessage.current({
          message: "You can add Maximum 5 categories",
          status: 'error'
        })
      } else {
        new_data.push(added)
      }
    }
    setAddedVehicles(new_data)
    // setCategoryValues({
    //   ...categoryValues,
    //   required_temperature: [0, 0],
    //   is_genset: false
    // }
    // )
    setCategoryValues(categoryInitialValues)
    setCurrentIndex(null)
    setSelectVehicleTypeObj(null)
    setPackageType(null)
    set_max_vehicle_weight(1)
    set_vehicle_quantity('')
  }

  const handleDeleteCategory = (ind: number) => {
    let new_data = [...addedVehiclesTemp]
    new_data.splice(ind, 1)
    setAddedVehicles(new_data);
  };

  const handleVehicleCategoryEdit = (index) => {
    setCurrentIndex(index);
    setSelectVehicleTypeObj(addedVehiclesTemp[index]?.selectVehicleTypeObj);
    setPackageType(addedVehiclesTemp[index]?.packageType);
    setCategoryValues(addedVehiclesTemp[index]?.values)
    set_vehicle_quantity(addedVehiclesTemp[index]?.vehicle_quantity)
    set_max_vehicle_weight(Number(addedVehiclesTemp[index]?.max_vehicle_weight))
    if (
      addedVehiclesTemp[index]?.values?.is_twin_load &&
      addedVehiclesTemp[index]?.selectVehicleTypeObj?.title === "40 ft Trailer"
    ) {
      setTemp(
        allVehicleTypes.find((val) => val.title === "40 ft Trailer")
          .min_capacity
      );
    }
  };

  const validate = () => {
    if (
      (selectVehicleTypeObj && packageType && packageType?.title !== "Other" && max_vehicle_weight) ||
      (selectVehicleTypeObj && packageType && packageType?.title === "Other" && categoryValues?.package_type_other && max_vehicle_weight)
    ) {
      return true
    } else {
      false
    }
  }

  const handleContinueVehicle = () => {
    let new_data = [...addedVehiclesTemp]
    let added = {
      selectVehicleTypeObj,
      vehicle_quantity,
      containerType: null,
      max_vehicle_weight,
      values: {
        ...categoryValues,
      },
      packageType,
    };

    if (currentIndex !== null) {
      if (validate()) {
        new_data[currentIndex] = added
      }
    } else {
      if (validate()) {
        if (new_data.length < 5) {
          new_data.push(added)
        }
      }
    }

    let vehicleScreen = {
      values: {
        cargo_description,
        is_shared_vehicle_not_allowed
      },
      addedVehicles: [],
      addedVehiclesTemp: new_data?.map((item, index) => {
        return {
          ...item,
          vehicle_index: index
        }
      })
    }

    dispatch(
      ProfileAction.postLoadData({
        location: [...locationState],
        vehicleScreen,
        contractDetails: {
          ...PostLoad?.contractDetails,
          quote_by: tempQuoteBy,
          is_unit_basis: false
        }
      })
    );
    if (tempQuoteBy == "vehicle") {
      history.push("/contract-vehicle-display-new");
      setAddedVehicles(new_data)
      setCategoryValues(categoryInitialValues)
      setCurrentIndex(null)
      setSelectVehicleTypeObj(null)
      setPackageType(null)
      set_max_vehicle_weight(1)
      set_vehicle_quantity('')
    } else {
      history.push(ADDITIONAL_DETAILS, { locations: locationState });
    }
  }

  useEffect(() => {
    handleDisabilityContinue();
  }, [locationState, tempQuoteBy, addedVehiclesTemp, selectVehicleTypeObj, packageType,
    categoryValues?.package_type_other, max_vehicle_weight, categoryValues?.required_temperature[0], categoryValues?.required_temperature[1]]);

  const [filterBy, setFilterBy] = useState('')
  useEffect(() => {
    if (load_type?.title == LOAD_TYPE.bulk && tempQuoteBy == 'vehicle') {
      setFilterBy("light_vehicle")
    } else {
      setFilterBy('')
    }
  }, [])

  return (
    <TitleLayout
      pageNumber={5}
      contract={true}
      progress={30}
      title={`Select Vehicles`}
    >
      <SimpleModal
        showModal={showModal}
        selected={selectVehicleTypeObj?.id}
        title={"Select Vehicle Type"}
        setType={handleChangeVehicleModal}
        handleModal={(value: boolean) => {
          setShowModal(value);
        }}
        values={
          filterBy
            ? allVehicleTypes?.filter((item) => item?.category == filterBy)
            : allVehicleTypes
        }
      >
      </SimpleModal>
      <SimpleModal
        showModal={packageModal}
        selected={packageType?.id}
        title={"Select Packaging type"}
        setType={handleChangePackage}
        handleModal={(value: boolean) => {
          setPackageModal(value);
        }}
        values={
          postLoadMeta?.package_types && postLoadMeta?.package_types.length
            ? postLoadMeta?.package_types.filter(
              (val) => val.type === "package"
            )
            : []
        }
      >
      </SimpleModal>
      <div className="formFeildWrapper">
        {load_type.title !== LOAD_TYPE.fcl && (
          <div>
            <div className="titleSection">Rate by</div>
            <FadeInComponent>
              <div className="field">
                <div className="tabWrapper">
                  <RadioButton
                    onChange={() => {
                      setTemQuoteBy("vehicle");
                      set_is_unit_basis(false)
                      set_other_type_by('not_item_basis')
                      if (locationState && locationState.length) {
                        locationState.forEach((val, index) => {
                          val['added_units'] = []
                        });
                        setLocationState([...locationState])
                        childRef?.current?.resetVehicles();
                      }

                    }}
                    id="vehicle"
                    name="quote_by"
                    label="Vehicle"
                    value={tempQuoteBy}
                  />
                  <RadioButton
                    id="weight_volume"
                    name="quote_by"
                    value={tempQuoteBy}
                    onChange={() => {
                      setTemQuoteBy("weight_volume");
                      set_other_type_by('not_item_basis')

                      if (locationState && locationState.length) {
                        locationState.forEach((val, index) => {
                          val['items'] = []
                          val['new_cat'] = []
                        });
                        setLocationState([...locationState])
                        childRef?.current?.resetVehicles();
                      }
                    }}
                    label="Other"
                  />
                  <div className="radioTab_slider"></div>
                </div>
              </div>
            </FadeInComponent>
            {
              tempQuoteBy == "weight_volume" && (
                <div>
                  <div className="titleSection">Other Rate Basis</div>
                  <FadeInComponent>
                    <div className="field">
                      <div className="tabWrapper">
                        <RadioButton
                          onChange={(e) => {
                            set_other_type_by('not_item_basis')
                            set_is_unit_basis(false)
                          }}
                          disabled={PostLoad?.from == "forward" ? true : false}
                          id="not_item_basis"
                          name="other_by"
                          label="Weight/Volume"
                          value={other_type_by}
                        />
                        <RadioButton
                          id="item_basis"
                          name="other_by"
                          value={other_type_by}
                          disabled={PostLoad?.from == "forward" ? true : false}
                          onChange={(e) => {
                            set_other_type_by('item_basis')
                            set_is_unit_basis(true)
                            if (locationState && locationState.length) {
                              locationState.forEach((val, index) => {
                                val['pickup']['attribute'] = {
                                  ...val?.pickup?.attribute,
                                  gross_weight: null,
                                  cargo_volume: null,
                                  gross_weight_in_kgs: null,
                                  total_freight_ton: null,
                                  no_of_packages: null,
                                  cargo_liter: null,
                                  PreferredVehicleType: "",
                                  packageType: ""
                                }
                              });
                              setLocationState([...locationState])
                              childRef?.current?.resetVehicles();
                            }
                          }}
                          label="Item Basis"
                        />
                        <div className="radioTab_slider"></div>
                      </div>
                    </div>
                  </FadeInComponent>
                </div>
              )
            }
          </div>
        )}

        <div
          className="contractVehicleAccordianWrapper"
          container-type={load_type.title !== LOAD_TYPE.fcl ? "lcl" : "fcl"}
        >
          {
            tempQuoteBy == "vehicle" ?
              <div>
                <FadeInComponent>
                  <div style={{
                    marginBottom: 12,
                    paddingTop: 8,
                    paddingBottom: 8,
                  }} className="titleSection">
                    {
                      addedVehiclesTemp.length >= 1 ? `Details of Category ${addedVehiclesTemp?.length + 1}` : `Details of Category`
                    }
                  </div>
                </FadeInComponent>

                <FadeInComponent>
                  <div className="sectionBox">
                    {/* filter by for qoute_by vehicle only */}
                    {PostLoad?.load_type == 3 &&
                      tempQuoteBy == "vehicle" ? (
                      <div>
                        <FadeInComponent>
                          <div className="titleSection">Select Vehicle Category</div>
                          <div className="tabWrapper">
                            <RadioButton
                              onChange={(e) => {
                                setFilterBy("light_vehicle");
                              }}
                              id="light_vehicle"
                              name="filter_by"
                              label="Light Vehicle"
                              value={filterBy}
                              defaultChecked={true}
                              extraText={"Up to 18 Ft"}
                            />
                            <RadioButton
                              id="heavy_vehicle"
                              name="filter_by"
                              value={filterBy}
                              onChange={(e) => {
                                setFilterBy("heavy_vehicle");
                              }}
                              label="Heavy Vehicle"
                              extraText={"20 Ft & Above"}
                            />
                            <div className="radioTab_slider"></div>
                          </div>
                        </FadeInComponent>
                      </div>
                    ) : null}

                    <div className="titleSection">Select Vehicle Type</div>
                    <div onClick={() => { }}>
                      <CustomizedSelects
                        classProp="mb-0"
                        selectedValue={selectVehicleTypeObj?.id}
                        values={selectVehicleTypeObj ? allVehicleTypes : []}
                        disabled={true}
                        modalState={true}
                        placeholder={"Select Vehicle Type"}
                        handleModal={(value: boolean) => {
                          setShowModal(value);
                        }}
                      >
                      </CustomizedSelects>
                    </div>
                  </div>
                </FadeInComponent>

                <FadeInComponent>
                  <div
                    style={{
                      pointerEvents: !selectVehicleTypeObj ? "none" : "auto",
                    }}
                  >
                    <VehicleIncDec
                      className="labelWithIncrementField"
                      text="Max Cargo weight  per vehicle (Tons)"
                      value={max_vehicle_weight}
                      name={"max_vehicle_weight"}
                      withInputBox={true}
                      updateValue={(val) => {
                        set_max_vehicle_weight(val)
                      }}
                      onTouchStart={(type) => {
                        if (type == 'inc') {
                          timmerRef_weight_inc = setInterval(() => {
                            set_max_vehicle_weight((prev) => prev + 1)
                          }, 200);
                        } else {
                          if (max_vehicle_weight > 0) {
                            timmerRef_weight_dec = setInterval(() => {
                              set_max_vehicle_weight((prev) => {
                                if (prev > 1) {
                                  return prev - 1
                                } else {
                                  return 1
                                }
                              })
                            }, 200);
                          }
                        }
                      }}
                      onTouchEnd={() => {
                        clearInterval(timmerRef_weight_inc);
                        clearInterval(timmerRef_weight_dec);
                      }}
                      onMouseDown={(type) => {
                        if (type == 'inc') {
                          set_max_vehicle_weight((prev) => prev + 1)
                          timmerRef_weight_inc = setInterval(() => {
                            set_max_vehicle_weight((prev) => prev + 1)
                          }, 200);
                        } else {
                          set_max_vehicle_weight((prev) => {
                            if (prev > 1) {
                              return prev - 1
                            } else {
                              return 1
                            }
                          })
                          timmerRef_weight_dec = setInterval(() => {
                            set_max_vehicle_weight((prev) => {
                              if (prev > 1) {
                                return prev - 1
                              } else {
                                return 1
                              }
                            })
                          }, 200);
                        }
                      }}
                      onMouseUp={() => {
                        clearInterval(timmerRef_weight_inc);
                        clearInterval(timmerRef_weight_dec);
                      }}
                      disableMin={
                        max_vehicle_weight
                          ? max_vehicle_weight > 0
                            ? false
                            : true
                          : true
                      }
                      disabledMax={false}
                    />
                  </div>
                </FadeInComponent>

                <FadeInComponent>
                  <>
                    <div
                      style={{
                        pointerEvents: !selectVehicleTypeObj ? "none" : "auto",
                      }}
                    >
                      <div className="inputField">
                        <div className="label">Estimated Number of Vehicles</div>
                        <div className="field">
                          <NewInput
                            onChange={(e) => {
                              let reg = new RegExp("^[0-9]*$");
                              let value = e.target?.value.replace(/[^\d]/g, "");
                              if (value && reg.test(value) && Number(value) > 0) {
                                if (value?.length <= 5) {
                                  set_vehicle_quantity(Number(value))
                                }
                              } else {
                                e.target.value = null;
                                set_vehicle_quantity(null)
                              }
                            }}
                            value={vehicle_quantity}
                            placeholder={"(Optional)"}
                          />
                        </div>
                      </div>
                    </div>
                    <>
                      {/* for non-cont vehicle basis */}

                      {/* {categoryValues?.is_genset ? */}

                      {
                        selectVehicleTypeObj?.provide_temperature_control == 1 &&
                        <div style={{ width: '97%' }}>
                          <div className="inputField" >
                            <div style={{ width: "100%", height: 25 }} className="flex_row_div">
                              <div className="label">
                                Required Temperature</div>
                              {/* {
                                categoryValues?.required_temperature?.length ?
                                  <>
                                    <div className="label">{`Min: ${categoryValues?.required_temperature[0]}`} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                                    <div className="label">{`Max: ${categoryValues?.required_temperature[1]}`} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                                  </>
                                  : null
                              } */}
                            </div>

                            <div className="field rangeSliderWrap">
                              <RangeSlider
                                min={Number(metaData?.temperature_range?.min)}
                                max={Number(metaData?.temperature_range?.max)}
                                // step={1}
                                value={categoryValues?.required_temperature}
                                orientation={'horizontal'}
                                tooltip={true}
                                onChange={(event, newValue) => {
                                  
                                  
                                  setCategoryValues({
                                    ...categoryValues,
                                    required_temperature: newValue,
                                  });
                                }}

                              />
                            </div>
                          </div>
                        </div>
                      }

                      {selectVehicleTypeObj?.provide_genset == 1 && load_type.title !== LOAD_TYPE.fcl ? (
                        <div style={{ marginTop: '1rem', width: '100%' }}>
                          <SimpleCheckBox
                            id={"is_genset"}
                            labelName="is_genset"
                            name="is_genset"
                            title="Genset Required"
                            handleChange={(e) => {
                              setCategoryValues({
                                ...categoryValues,
                                is_genset: e.target.checked,
                              });
                            }}
                            checked={categoryValues?.is_genset ? true : false}
                          />
                        </div>
                      ) : null}
                    </>
                  </>

                </FadeInComponent>

                {selectVehicleTypeObj?.is_boxed == 1 && (
                  <FadeInComponent>
                    <div style={{ display: "flex" }}>
                      <SimpleCheckBox
                        title="Box"
                        checked={categoryValues.is_boxed}
                        handleChange={(e) => {
                          setCategoryValues({
                            ...categoryValues,
                            is_boxed: e.target.checked
                          })
                        }}
                        labelName="is_boxed"
                        id="is_boxed"
                      />
                    </div>
                  </FadeInComponent>
                )}

                {selectVehicleTypeObj?.is_sidewall == 1 && (
                  <FadeInComponent>
                    <div style={{ display: "flex", marginTop: 16 }}>
                      <SimpleCheckBox
                        checked={categoryValues.is_sidewall}
                        handleChange={(e) => {
                          setCategoryValues({
                            ...categoryValues,
                            is_sidewall: e.target.checked
                          })
                        }}
                        labelName="is_sidewall"
                        id="is_sidewall"
                        title="Side wall"
                      />
                    </div>
                  </FadeInComponent>
                )}

                {selectVehicleTypeObj?.is_support_twin_load === 1 && (
                  <div style={{ marginTop: 16 }}>
                    <FadeInComponent>
                      <SwitchableWithText
                        id={"is_twin_load"}
                        handleChange={(e) => {
                          if (
                            e.target.checked &&
                            selectVehicleTypeObj?.title === "40 ft Trailer"
                          ) {
                            setTemp(selectVehicleTypeObj.min_capacity);
                            setSelectVehicleTypeObj({
                              ...selectVehicleTypeObj,
                              min_capacity: 45,
                            });
                          }
                          if (
                            !e.target.checked &&
                            selectVehicleTypeObj?.title === "40 ft Trailer"
                          ) {
                            selectVehicleTypeObj.min_capacity = temp;
                            setSelectVehicleTypeObj(selectVehicleTypeObj);
                            setTemp(null);
                          }
                          setCategoryValues({
                            ...categoryValues,
                            is_twin_load: e.target.checked
                          })
                        }}
                        name="is_twin_load"
                        text="Twin Load (2x20 Ft)"
                        checked={categoryValues.is_twin_load}
                      />
                    </FadeInComponent>
                  </div>
                )}

                <FadeInComponent>
                  <div className="sectionBox">
                    <div className="titleSection">
                      Packaging type
                    </div>

                    <CustomizedSelects
                      placeholder={"Select Packaging type"}
                      selectedValue={packageType?.id}
                      values={packageType ? postLoadMeta?.package_types : []}
                      disabled={true}
                      modalState={true}
                      handleModal={(value: boolean) => {
                        setPackageModal(value);
                      }}
                    ></CustomizedSelects>

                    {packageType?.title === "Other" && (
                      <div className="inputField" style={{ marginTop: '1rem', marginBottom: 0 }}>
                        <div className="label">Other package type</div>
                        <div className="field">
                          <SimpleInput
                            onChange={(e) => {
                              setCategoryValues({
                                ...categoryValues,
                                package_type_other: e.target.value,
                              })
                            }}
                            id="package_type_other"
                            name="package_type_other"
                            value={
                              categoryValues.package_type_other
                                ? categoryValues.package_type_other
                                : ""
                            }
                            type="text"
                            placeholder="Type your Packaging type here"
                            style={{ height: 41, width: "100%", marginBottom: 0, marginTop: '0.75rem' }}
                            aria-describedby="standard-weight-helper-text"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </FadeInComponent>

                <FadeInComponent>
                  <div>
                    <Grid item xs={12}>
                      <SwitchableWithText
                        id={"is_loading_required"}
                        handleChange={(e) => {
                          setCategoryValues({
                            ...categoryValues,
                            is_loading_required: e.target.checked
                          })
                        }}
                        checked={categoryValues.is_loading_required}
                        name="is_loading_required"
                        text="Loading Required"
                      />
                    </Grid>
                    {categoryValues?.is_loading_required && (
                      <div className="inputField">
                        <div className="label">
                          Specify Labour / Equipment Requirement
                        </div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              setCategoryValues({
                                ...categoryValues,
                                loading_requirements: e.target.value
                              })
                            }}
                            type={"text"}
                            value={categoryValues?.loading_requirements}
                            id={"loading_requirements"}
                            name="loading_requirements"
                            style={{ height: 35, width: "100%" }}
                            aria-describedby="standard-weight-helper-text"
                            placeholder="Optional"
                          />
                        </div>
                      </div>
                    )}
                    <Grid item xs={12}>
                      <SwitchableWithText
                        id={"is_unloading_required"}
                        name="is_unloading_required"
                        checked={categoryValues.is_unloading_required}
                        handleChange={(e) => {
                          setCategoryValues({
                            ...categoryValues,
                            is_unloading_required: e.target.checked
                          })
                        }}
                        text="Unloading Required"
                      />
                    </Grid>
                    {categoryValues?.is_unloading_required && (
                      <div className="inputField">
                        <div className="label">
                          Specify Labour / Equipment Requirement
                        </div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              setCategoryValues({
                                ...categoryValues,
                                unloading_requirements: e.target.value
                              })
                            }}
                            type={"text"}
                            value={categoryValues?.unloading_requirements}
                            id={"unloading_requirements"}
                            aria-describedby="standard-weight-helper-text"
                            className="rightFormField"
                            placeholder="Optional"
                          />
                        </div>
                      </div>
                    )}
                    <OutLineButton
                      buttonStyle={{
                        marginTop: 10,
                        marginBottom: 15
                      }}
                      disabled={
                        ((max_vehicle_weight > 0 &&
                          (packageType && packageType?.title === "Other"
                            ? categoryValues?.package_type_other !== ""
                            : packageType) &&
                          selectVehicleTypeObj
                        )
                          && (selectVehicleTypeObj?.provide_temperature_control ? (categoryValues?.required_temperature[0] !== 0
                            || categoryValues?.required_temperature[1] > 0)
                            : true))
                          //   selectVehicleTypeObj && max_vehicle_weight && (packageType &&
                          //   packageType?.title === "Other"
                          //   ? categoryValues?.package_type_other
                          //   : packageType
                          // ) 
                          ? false : true}
                      title={currentIndex == null ? "Add Another Category" : "Update category"}
                      onClick={handleAddCaregory}
                    />

                    {addedVehiclesTemp && !!addedVehiclesTemp.length && (
                      <>
                        <VehicleTable
                          onClickDelete={(index: number) => {
                            handleDeleteCategory(index);
                          }}
                          tempQuoteBy={tempQuoteBy}
                          loadType={load_type.title}
                          addedVehicles={addedVehiclesTemp.sort((a, b) => a?.selectVehicleTypeObj?.title?.split(" ")[0] - b?.selectVehicleTypeObj?.title?.split(" ")[0])}
                          handleEdit={handleVehicleCategoryEdit}
                        />
                      </>
                    )}
                  </div>
                </FadeInComponent>

              </div> :
              <div>
                {!!locationState.length &&
                  locationState?.map((item, index) => (
                    <FadeInComponent>
                      <Accordion
                        className="contractVehicleAccordian"
                        expanded={isExpanded[index]}
                        onChange={() => handleChange(!isExpanded[index], index)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <span className="fw-6">{`Route ${index + 1} ${item?.pickup?.title} - ${item?.dropoff?.title}`}</span>
                        </AccordionSummary>
                        <AccordionDetails className="contractVehicleAccordianDetail">
                          <LocationTimeline
                            location={[item?.pickup, item?.dropoff]}
                            handleModal={(value: boolean) => { }}
                          ></LocationTimeline>
                          {
                            is_unit_basis && (
                              <div style={{
                                marginTop: 10,
                                marginBottom: 12,
                                paddingTop: 8,
                                paddingBottom: 8,
                              }} className="titleSection">
                                {
                                  item?.added_units && item?.added_units.length >= 1 ? `Details of Item ${item?.added_units?.length + 1}` : `Details of Item`
                                }
                              </div>
                            )
                          }
                          {
                            (tempQuoteBy == "weight_volume" && !is_unit_basis) && (
                              <div style={{
                                marginTop: 10,
                                marginBottom: 12,
                                paddingTop: 8,
                                paddingBottom: 8,
                              }} className="titleSection">
                                Cargo Details
                              </div>
                            )
                          }
                          <div className="selectVehicleMainWrap">
                            {
                              !is_unit_basis ?
                                <SelectVehicle
                                  ref={childRef}
                                  index={index}
                                  tempQuoteBy={tempQuoteBy}
                                  handleNewVehicle={handleNewVehicle}
                                  handleNewUnit={handleNewUnit}
                                  handleChangeInLocationState={handleChangeInLocationState}
                                  handlerDelete={handleDelete}
                                  pickup={item?.pickup}
                                  vehicles={item?.items?.length ? item?.items : []}
                                /> :
                                <div>
                                  <UnitsLocation
                                    added_units={item?.added_units ? item?.added_units : []}
                                    set_added_units={set_added_units}
                                    is_unit_basis={is_unit_basis}
                                    loc_index={index}
                                    handleDeleteUnit={handleDeleteUnit}
                                    hanleEditUnit={hanleEditUnit}
                                  />
                                </div>
                            }
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </FadeInComponent>
                  ))}
              </div>

          }
        </div>
        {
          !is_unit_basis ?
            <FadeInComponent>
              <div className={pageTitle} style={{ marginTop: 25 }}>
                <div> Cargo Description</div>
              </div>
              <div className="textareaWrapper">
                <textarea
                  style={{
                    outline: "none",
                  }}
                  maxLength={250}
                  onChange={(e) => {
                    set_cargo_description(e.target.value)
                  }}
                  value={cargo_description}
                  id={"cargo_description"}
                  name={"cargo_description"}
                  placeholder="Optional"
                />
              </div>
            </FadeInComponent> : null
        }
        <div>
        </div>
      </div>
      {
        is_unit_basis ?
          <PrimaryButton
            rightIcon
            title="CONTINUE"
            type="submit"
            onClick={() => handleSubmit()}
            disabled={
              locationState?.every((loc) => loc?.added_units?.length) ? false : true
            }
            buttonStyle={{
              marginBottom: 15,
              marginTop: 20
            }}
          /> :
          <PrimaryButton
            rightIcon
            title="CONTINUE"
            type="submit"
            onClick={() => {
              if (tempQuoteBy == "vehicle") {
                handleContinueVehicle()
              } else {
                handleSubmit()
              }
            }}
            disabled={disable || (load_type.title !== LOAD_TYPE.fcl && tempQuoteBy == "weight_volume" && !locationState.every((loc: any) => loc.pickup?.attribute?.packageType))}
            buttonStyle={{
              marginBottom: 15,
              marginTop: 20
            }}
          />

      }
    </TitleLayout>
  );
};
export default Page;