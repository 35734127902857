import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { AuthAction } from "../actions";
var INITIAL_STATE = {
  user: {},
  isLoading: false,
  signupData: {
    fullName: "",
    aliasName: "",
    frontImage: "",
    backImage: "",
    cnic_front: null,
    cnic_back: null
  },
  attempt: 0,
  metaData: {},
  native_token: "",
  firstLogIn: null,
  logout_from_all_devices: false,
  notiUrl: null
};
function Reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case AuthAction.TOGGLE_LOADER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.SET_NATIVE_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        native_token: action === null || action === void 0 ? void 0 : action.payload
      });
    // login
    case AuthAction.LOGIN_WITH_OTP:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.LOGIN_WITH_OTP_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.LOGIN_WITH_OTP_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // signup
    case AuthAction.SIGNUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.SIGNUP_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.SIGNUP_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // verify code
    case AuthAction.VERIFY_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.VERIFY_CODE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.VERIFY_CODE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // verify code
    case AuthAction.LOGIN_WITH_PASSWORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.LOGIN_WITH_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.LOGIN_WITH_PASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // set signup data
    case AuthAction.SET_SIGNUP_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        signupData: action.payload
      });

    //reset Password

    case AuthAction.RESET_PASSWORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.RESET_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.RESET_PASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    //set reset passwordRegex

    case AuthAction.SET_ATTEMPT:
      var new_data = state.attempt;
      new_data++;
      return _objectSpread(_objectSpread({}, state), {}, {
        attempt: action.payload.attempt ? new_data : 0
      });
    case AuthAction.LOG_OUT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.LOG_OUT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.LOG_OUT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.UPDATE_TERMS_AND_CONDITION:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.UPDATE_TERMS_AND_CONDITION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.UPDATE_TERMS_AND_CONDITION_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.GET_META_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.GET_META_DATA_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        metaData: action.payload,
        isLoading: false
      });
    case AuthAction.GET_META_DATA_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.SETUP_PASSWORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.SETUP_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.SETUP_PASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.FORGOT_PASSWORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.FORGOT_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.FORGOT_PASSWORD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.RESET_STORE:
      return INITIAL_STATE;
    case AuthAction.LOCATION_LOADER_SHOW:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: action.payload ? true : false
      });
    case AuthAction.FIRST_LOGIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.FIRST_LOGIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.FIRST_LOGIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.DEL_ACCOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case AuthAction.DEL_ACCOUNT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        profile: action.payload,
        isLoading: false
      });
    case AuthAction.DEL_ACCOUNT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.SET_NOTI_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        notiUrl: action === null || action === void 0 ? void 0 : action.payload
      });
    case AuthAction.LOGOUT_FROM_ALL_DEVICES:
      return _objectSpread(_objectSpread({}, state), {}, {
        logout_from_all_devices: action.payload
      });
    default:
      return state;
  }
}
export default Reducer;