import React, { useCallback, useState, useRef, useEffect } from "react";
import { IUploadCnicPageProps } from "./types";
import { Colors, Images } from "@temp/assets";
import {
  PrimaryButton,
  SignupContainer,
  BackHeader,
  CustomText,
  CustomModal,
} from "@components";
import "./style.scss";
import { Button } from "@mui/material/";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { AuthAction } from "@temp/store/actions";
import IconButton from "@mui/material//IconButton";
import { showMessage } from "@utils/helper";
import rootReducer from "../../../store/reducers";
// import Cropper from 'react-easy-crop'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CropIcon from "@mui/icons-material/Crop";
import Compressor from 'compressorjs'
export type RootState = ReturnType<typeof rootReducer>;
const Page: React.FC<IUploadCnicPageProps> = (props) => {
  const { state } = useLocation();
  
  const history = useHistory();
  const dispatch = useDispatch();
  const signupData = useSelector((state: RootState) => state.Auth.signupData);
  const [show, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  const [curentTab, setCurrentTab] = useState("");
  const [finalUrl, setFinalUrl] = useState('')

  const [front_image, set_front_image] = useState(null)
  const [back_image, set_back_image] = useState(null)


  const cropperRef = useRef<HTMLImageElement>(null);
  const onCrop = () => {
    // const imageElement: any = cropperRef?.current;
    // const cropper: any = imageElement?.cropper;
    // setFinalUrl(cropper.getCroppedCanvas().toDataURL())
    // 
    // 
    // return
    // if (curentTab == "front") {
      // set_front_image(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
      // dispatch(AuthAction.SetSignupData({
      //   ...signupData,
      //   frontImage: cropper.getCroppedCanvas().toDataURL(),
      //   cnic_front: cropper.getCroppedCanvas().toDataURL(),
      // }))
    // } else {
      set_front_image(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
      dispatch(AuthAction.SetSignupData({
        ...signupData,
        frontImage: cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
        cnic_front: cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
      }))
    // }
    setShowModal(false);
  };

  useEffect(()=>{
    set_front_image(signupData?.cnic_front)

  },[])

  const handleChangeFront = (event: any) => {
    
    if (event?.target?.files[0]?.type.includes("image")) {
      setCurrentTab("front");
      new Compressor(event?.target?.files[0], {
        quality: 0.4,
        success: (compressedResult) => {
          
          setUrl(URL.createObjectURL(compressedResult));
          setShowModal(true);
          return 
        },
      });
    } else {
      showMessage.current({
        message: "Please upload image only.",
        status: "error",
      });
    }
  };
  const handleChangeBack = (event: any) => {
    if (event?.target?.files[0]?.type.includes("image")) {
      setCurrentTab("back");
      new Compressor(event?.target?.files[0], {
        quality: 0.4,
        success: (compressedResult) => {
          
          setUrl(URL.createObjectURL(compressedResult));
          setShowModal(true);
          return 
        },
      });
      // setUrl(URL.createObjectURL(event.target.files[0]));
      // setShowModal(true);
      return;
      dispatch(
        AuthAction.SetSignupData({
          ...signupData,
          backImage: URL.createObjectURL(event.target.files[0]),
          cnic_back: event.target.files[0],
        })
      );
    } else {
      showMessage.current({
        message: "Please upload image only.",
        status: "error",
      });
    }
  };
  

  const handleNext = () => {
    // if (signupData.backImage && signupData.frontImage) {
    history.push("/setup-password",
      {
        phoneNumber: state?.phoneNumber,
        whatsapp: state?.whatsapp,
        mobileNetwork: state?.mobileNetwork,
        city_id: state?.city_id,
        front_image,
        back_image
      
      }
    );

    // } else {
    //   showMessage.current({
    //     message: "Please upload your CNIC.",
    //     status: "error",
    //   });
    // }
  };

  
  return (
    <div className="loginWrapper">
      <BackHeader
        title="Business Card Upload "
        onClick={() => {
          history.goBack();
        }}
      />
      <SignupContainer containerHeight="">
        <div className="insideafterOTPContainer">
          <div className="cnicUploadWrapper customCnicUploader">
            <div>
              <div
                onClick={() => {
                  dispatch(
                    AuthAction.SetSignupData({
                      ...signupData,
                      backImage: "",
                      cnic_back: "",
                      frontImage: "",
                      cnic_front: "",
                    })
                  );
                }}
                className="info-text-container"
              >
                <CustomText.OpenSansRegular
                  textStyle={{
                    fontSize: "1rem",
                    color: Colors.Grayish,
                  }}
                >
                  Last Important Step! <br /> Please upload image of Business Card
                </CustomText.OpenSansRegular>
              </div>
            </div>
            {/* <div className="nic-front-container">
              {front_image ? (
                <div>
                  <img
                    alt=""
                    className="nic-image-display"
                    src={front_image}
                  />
                  <div className="edit-icon-button">
                    <label htmlFor="icon-button-file">
                      <input
                        // inputProps={{ accept: 'image/*' }}
                        id="icon-button-file"
                        className="hide-input"
                        type="file"
                        onChange={handleChangeFront}
                        accept="image/*"
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img alt="" src={Images.EditIcon} />
                      </IconButton>
                    </label>
                  </div>
                </div>
              ) : (
                <label htmlFor="upload-nic-front" id="upload-button-container">
                  <Button
                    variant="contained"
                    className="upload-button"
                    component="span"
                  >
                    <div>
                      <img alt="" src={Images.AddPlus} />
                    </div>
                    <div>
                      <p className="upload-text">Upload</p>
                    </div>
                  </Button>
                </label>
              )}

              <input
                accept="image/*"
                className="hide-input"
                id="upload-nic-front"
                multiple
                type="file"
                onChange={handleChangeFront}
              />
              <p className="front-back-text">Front</p>
            </div> */}

            <div className="nic-back-container">
              {front_image ? (
                <div>
                  <img
                    alt=""
                    className="nic-image-display"
                    src={front_image}
                  />
                  <div className="edit-icon-button">
                    <label htmlFor="icon-button-file1">
                      <input
                        accept="image/*"
                        id="icon-button-file1"
                        className="hide-input"
                        type="file"
                        onChange={handleChangeFront}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <img alt="" src={Images.EditIcon} />
                      </IconButton>
                    </label>
                  </div>
                </div>
              ) : (
                <label htmlFor="upload-nic-front" className="UploadTextContainer" id="upload-button-container">
                  <Button
                    variant="contained"
                    className="upload-button"
                    component="span"
                  >
                    <div>
                      <img alt="" src={Images.AddPlus} />
                    </div>
                    <div className="uploadTextWrap">
                      <p className="upload-text">Upload</p>
                    </div>
                  </Button>
                </label>
              )}

              <input
                accept="image/*"
                className="hide-input"
                id="upload-nic-front"
                multiple
                type="file"
                onChange={handleChangeFront}
              />
              {/* <p className="front-back-text">Back</p> */}
            </div>
          </div>
          <div className="bottomSignupScreen">
            <div className="insideSignupBtnWrap">
              <PrimaryButton
                title="NEXT"
                disabled={!signupData.frontImage}
                // disabled={!back_image}
                onClick={() => {
                  handleNext();
                }}
              />
            </div>
          </div>
        </div>
      </SignupContainer>
      <CustomModal
        showModal={show}
        toggleModal={() => {
          setShowModal(!show);
        }}
      >
        <Cropper
          src={url}
          style={{ height: 400, width: "100%" }}
          // Cropper.js options
          initialAspectRatio={16 / 9}
          guides={false}
          // crop={onCrop}
          ref={cropperRef}
          rotatable={true}
        />

        <div style={{ display: "flex" }}>

          <PrimaryButton
            onClick={() => {
              
              cropperRef.current.cropper.move(1, -1).rotate(90).scale(1, -1);
            }}
            title="Rotate"
            startIcon={<AutorenewIcon />}
            buttonStyle={{ color: "rgb(255,255,255)", marginTop: 10, marginLeft: -5 }}
          />
          <PrimaryButton
            onClick={() => {
              onCrop()
            }}
            title="Crop"
            startIcon={<CropIcon />}
            buttonStyle={{ color: "rgb(255,255,255)", marginTop: 10, marginLeft: 5 }}
          />

        </div>
      </CustomModal>
    </div>
  );
};

export default Page;
