import React, { useState, useEffect } from "react";
import { ILoadDetailDashboardPageProps } from "./types";
import "./style.scss";
import rootReducer from "../../../store/reducers";
import { Colors, Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { CircularProgress, Grid, MenuItem, Select } from "@mui/material/";
import { CustomModal, MaterialUIPickers, OutLineButton } from "@components";
import {
  Accordion,
  AccordionSummary,

} from "@mui/material/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ProfileAction } from "@temp/store/actions";
import moment from "moment";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";
import InfiniteScroll from "react-infinite-scroll-component";
import { phoneNumberFormater } from "@utils/helper";
import EmptyPlaceholder from "@temp/components/EmptyPlaceholder/EmptyPlaceholder";
import { showMessage } from "@temp/utils/helper";

import InfoIcon from "@mui/icons-material/Info";

var numeral = require("numeral");

export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<ILoadDetailDashboardPageProps> = (props) => {
  const history = useHistory()
  const activeCategoryDetail = useSelector((state: RootState) => state.Profile?.activeCategoryDetail);
  const dispatch = useDispatch();
  const activeDateDetail = useSelector((state: RootState) => state.Profile?.activeDateDetail);
  const fromResponseDateDetail = useSelector((state: RootState) => state.Profile?.fromResponseDateDetail);
  const responseDateDetail = useSelector((state: RootState) => state.Profile?.responseDateDetail);
  const dashboardData = useSelector((state: RootState) => state.Profile?.dashboardData);
  const loadDetailData = useSelector((state: RootState) => state.Profile?.load_detail_data);
  const new_dashboard_load_detail = useSelector((state: RootState) => state?.Profile?.new_dashboard_load_detail)
  const [showModal, setShowModal] = useState(false)

  
  
  
  
  

  // for minimize six months date
  let minDate = moment(Number(moment(responseDateDetail).format("x")) - 180 * 24 * 3600 * 1000).format('YYYY-MM-DD');
  // 

  useEffect(() => {
    let date_from = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD')
    let date_to = moment().format("YYYY-MM-DD")
    if (activeDateDetail == "weekly") {
      date_from = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    } else if (activeDateDetail == "monthly") {
      date_from = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    } else if (activeDateDetail == "today") {
      date_from = moment(Date.now()).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    }
    else {
      date_from = moment(fromResponseDateDetail).format('YYYY-MM-DD')
      date_to = moment(responseDateDetail).format('YYYY-MM-DD')
    }
    dispatch(ProfileAction.dashboardDataLoadDetail({
      loads_by: activeCategoryDetail,
      date_range_from: date_from,
      date_range_to: date_to,
      date_type: activeDateDetail,
      stats: "loadDetails",
      search: "",
      vehicle_type: "",
      next_page: 1
    }))
  }, []);

  const callBack = (param) => {
    
    if (param == "422") {
      setShowModal(true)
    }
  }

  const handleSubmit = () => {
    dispatch(ProfileAction.dashboardDataLoadDetail({
      loads_by: activeCategoryDetail,
      date_range_from: moment(fromResponseDateDetail).format("YYYY-MM-DD"),
      date_range_to: moment(responseDateDetail).format("YYYY-MM-DD"),
      date_type: activeDateDetail,
      stats: "loadDetails",
      search: "",
      vehicle_type: "",
      next_page: 1,
      callBack
    }))
  }

  const handleRefresh = async() => {
    getDetailScreenData({
      refresh: true,
      loads_by: activeCategoryDetail,
      date_range_from: date_from,
      date_range_to: date_to,
      date_type: activeDateDetail,
      stats: "vehicleDetails",
      search: "",
      vehicle_type: "",
    })
  }

  const handleClickCategory = (type: string) => {
    dispatch(ProfileAction.setDetailFilter({
      activeCategoryDetail: type,
      activeDateDetail,
      fromResponseDateDetail,
      responseDateDetail,
    }))
    if (activeDateDetail == 'weekly') {
      let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataLoadDetail({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateDetail,
        stats: "loadDetails",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    } else if (activeDateDetail == 'monthly') {
      let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataLoadDetail({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateDetail,
        stats: "loadDetails",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    } else if (activeDateDetail == "today") {
      let start_date = moment(Date.now()).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataLoadDetail({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateDetail,
        stats: "loadDetails",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))

    }
    else if (activeDateDetail == 'date') {
      dispatch(ProfileAction.dashboardDataLoadDetail({
        loads_by: type,
        date_range_from: moment(fromResponseDateDetail).format("YYYY-MM-DD"),
        date_range_to: moment(responseDateDetail).format("YYYY-MM-DD"),
        date_type: activeDateDetail,
        stats: "loadDetails",
        search: "",
        vehicle_type: "",
        next_page: 1

      }))
    }
  };

  const handleDateFilter = (type) => {
    dispatch(ProfileAction.setDetailFilter({
      activeCategoryDetail: activeCategoryDetail,
      activeDateDetail: type,
      fromResponseDateDetail,
      responseDateDetail,
    }))
    if (type == 'weekly') {
      let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataLoadDetail({
        loads_by: activeCategoryDetail,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
        stats: "loadDetails",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    } else if (type == 'monthly') {
      let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataLoadDetail({
        loads_by: activeCategoryDetail,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
        stats: "loadDetails",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    }
    else if (type == 'today') {
      let start_date = moment(Date.now()).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardDataLoadDetail({
        loads_by: activeCategoryDetail,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
        stats: "loadDetails",
        search: "",
        vehicle_type: "",
        next_page: 1
      }))
    }
  }


  let date_from = moment(Date.now() - 0 * 24 * 3600 * 1000).format('YYYY-MM-DD')
  let date_to = moment().format("YYYY-MM-DD")

  const getDetailScreenData = (data) => {
    let date_from = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD')
    let date_to = moment().format("YYYY-MM-DD")
    if (activeDateDetail == "weekly") {
      date_from = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    } else if (activeDateDetail == "monthly") {
      date_from = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    } else if (activeDateDetail == "today") {
      date_from = moment(Date.now()).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    }
    else {
      date_from = fromResponseDateDetail.format("YYYY-MM-DD")
      date_to = responseDateDetail.format("YYYY-MM-DD")
    }
    dispatch(ProfileAction.dashboardDataLoadDetail({
      loads_by: activeCategoryDetail,
      date_range_from: date_from,
      date_range_to: date_to,
      date_type: activeDateDetail,
      stats: "loadDetails",
      refresh: false,
      search: "",
      vehicle_type: "",
      next_page: 1
    }))
  };

  return (
    <div style={{
      height: 'calc(100vh - 15px)',
      overflow: 'auto'
    }} className="root-center-container">
      <div className="loads-top-wrappers">
        <div className="topDropDownWrap">
          <div
            className="drop-down"
            style={{ justifyContent: "space-between" }}
          >
            <button onClick={() => {
              history.goBack()
            }} className="header-buttons">
              <img
                src={Images.BackArrow}
                alt="Back"
              />
            </button>
            <p className="title">Loads Details</p>
            <div></div>
          </div>
        </div>
        <div className="tabContainerMainWrap reportLoadListing">
          {/* Top Tabs Posted recieved  */}
          {
            <FadeInComponent>
              <div className={`tab-btn radioTabs twoTab`}>
                <div
                  onClick={() => {
                    handleClickCategory("posted");
                  }}
                  className={`two-category-button ${activeCategoryDetail === "posted" ? "active" : ""
                    }`}
                >
                  Posted
                </div>
                <div
                  onClick={() => {
                    handleClickCategory("received");
                  }}
                  className={`two-category-button ${activeCategoryDetail === "received" ? "active" : ""
                    }`}
                >
                  Received
                </div>
                <div className="radioTab_slider"></div>
              </div>
            </FadeInComponent>
          }

          {/* Top Tabs date filters  */}
          <div className="tabContainerMainWrap reportLoadListing" style={{ display: 'none' }}>
            {
              <FadeInComponent className='pr-2'>
                <div className={`tab-btn radioTabs twoTab`}>
                  <div
                    onClick={() => {
                      handleDateFilter("today");
                    }}
                    className={`two-category-button ${activeDateDetail === "today" ? "active" : ""
                      }`}
                  >
                    Today
                  </div>
                  <div
                    onClick={() => {
                      handleDateFilter("weekly");
                    }}
                    className={`two-category-button ${activeDateDetail === "weekly" ? "active" : ""
                      }`}
                  >
                    Weekly
                  </div>
                  <div
                    onClick={() => {
                      handleDateFilter("monthly");
                    }}
                    className={`two-category-button ${activeDateDetail === "monthly" ? "active" : ""
                      }`}
                  >
                    Monthly
                  </div>

                  <div className="radioTab_slider"></div>
                </div>
              </FadeInComponent>
            }
          </div>
          <div className="newloadDetailSelectDate">
            <div className="tabContainerMainWrap reportLoadListing">
              <Select
                id="outlined-select-date-native"
                select
                defaultValue={activeDateDetail}
                name=""
                onChange={(e) => handleDateFilter(e.target.value)}
              >
                <MenuItem value={'today'}>
                  Today
                </MenuItem>
                <MenuItem value={'weekly'}>
                  Weekly
                </MenuItem>
                <MenuItem value={'monthly'}>
                  Monthly
                </MenuItem>
                <MenuItem value={'date'}>
                  Date Filter
                </MenuItem>
              </Select>
              {
                activeDateDetail == "date" ?
                  <FadeInComponent className="dateLabel">
                    <div className="resports-date-filter-wraper">
                      <div onClick={() => {
                        setShowModal(true)
                      }} style={{ border: `1px solid ${Colors.NavyBlue}` }} className="reports-date-filter">
                        <span className="icons-calender calenderIcon"></span>
                        <p style={{ color: Colors.NavyBlue, marginTop: 4 }}>DATE FILTER</p>
                      </div>
                      <div>
                        {
                          fromResponseDateDetail && responseDateDetail ?
                            <p className="label">{`${moment(fromResponseDateDetail).format('ll')} - ${moment(responseDateDetail).format('ll')}`}</p> : null
                        }
                      </div>
                    </div>
                  </FadeInComponent>
                  : null}

            </div>
          </div>
        </div>
      </div>
      <div className="load-listing-container remCustomPadonMob tabContainerMainWrap reportLoadListing" style={{
        paddingTop: activeDateDetail == "date" ? 215 : 170
      }}>
        {/* pagination work */}
        <div style={{
        }}>
          <div>
            {new_dashboard_load_detail?.listing?.length ?
              <PullToRefresh
                onRefresh={handleRefresh}
              >
                <InfiniteScroll
                  dataLength={new_dashboard_load_detail?.listing?.length}
                  next={() => {
                    getDetailScreenData({
                      refresh: true,
                      loads_by: activeCategoryDetail,
                      date_range_from: date_from,
                      date_range_to: date_to,
                      date_type: activeDateDetail,
                      stats: "vehicleDetails",
                      search: "",
                      vehicle_type: "",
                      // next_page : 1

                    })
                  }}
                  // hasMore={dashboard_vehicle_last_page > dashboard_vehicle_current_page}
                  loader={<div className="load-more-loader"> <CircularProgress /></div>}
                  // style={{ padding: 15 }}
                  height={'calc(100vh - 242px)'}
                  scrollThreshold={0.8}
                >
                  <Grid container>
                    <Grid item xs={12} className="loadDetailsDataMainWrap">
                      {new_dashboard_load_detail?.listing?.length > 0 ?
                        new_dashboard_load_detail?.listing?.map((val, index) => {
                          
                          return (
                            <Accordion className="accordion-container newDashboardDetailContainer loadDetailContainer">
                              <AccordionSummary
                                className="accordion-background"
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div className="counterDiv">
                                  <span>{index + 1}</span>
                                </div>
                                <div className="showDataFirst loadDetailsDataFirst">
                                  <div className="loadDetailLeftBox darkerGreyBox">
                                    <div className="dataFirstBox">
                                      <div className="w-100">
                                        {/* {val?.associated_company ? ( */}
                                        <>
                                          {/* {val?.associated_company ? ( */}
                                          <div className="companyTitle">
                                            <label>Company</label>
                                            <p>{val?.associated_company ? val?.associated_company : val?.company_name ? val?.company_name :
                                              val?.associated_company && val?.company_name ? val?.associated_company :
                                                "-"}</p>
                                          </div>
                                          {/* // ) : null} */}
                                          {val?.user_name ? (
                                            <div className="name">
                                              <label>{activeCategoryDetail === "posted" ? "Recipient" : "Poster"}</label>
                                              <p>{val?.user_name || ""}</p>
                                            </div>
                                          ) : null}
                                          {val?.phone_no ? (
                                            <div className="phone">
                                              <label>Number</label>
                                              <p>{`${phoneNumberFormater(val?.phone_code, val?.phone_no)}`}</p>
                                            </div>
                                          ) : null}
                                          {val?.networkUser?.name === "Unregistered User" ? (
                                            <div className="name">
                                              <label>Unregistered User</label>
                                              <p>Invite Sent</p>
                                            </div>
                                          ) : null}
                                        </>
                                        {/* )  */}
                                        {/* : (
                                          <>
                                            {val?.user_name ? (
                                              <div className="companyTitle">
                                                <label>Company</label>
                                                <p>{val?.user_name || ""}</p>
                                              </div>
                                            ) : null}
                                            {val?.company_name ? (
                                              <div className="name">
                                                <label>Recipient</label>
                                                <p>{val.company_name}</p>
                                              </div>
                                            ) : null}
                                            {val?.phone_no ? (
                                              <div className="phone">
                                                <label>Number</label>
                                                <p>{`${phoneNumberFormater(val?.phone_code, val?.phone_no)}`}</p>
                                              </div>
                                            ) : null}
                                            {val?.networkUser?.name === "Unregistered User" ? (
                                              <div className="name">
                                                <label>Unregistered User</label>
                                                <p>Invite Sent</p>
                                              </div>
                                            ) : null}
                                          </>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="loadDetailRightBox normalGreyBox">
                                    <div className="dataFirstBox">
                                      <label>
                                        {
                                          activeCategoryDetail === "posted" ? "Posted" : "Received"
                                        }

                                      </label>
                                      <span>{val?.posted}</span>
                                    </div>
                                    <div className="dataFirstBox">
                                      <label>Responded</label>
                                      <span>{val?.responded}</span>
                                    </div>
                                    <div className="dataFirstBox">
                                      <label>Awarded</label>
                                      <span>{val?.awarded}</span>
                                    </div>
                                    <div className="dataFirstBox">
                                      <label>Completed</label>
                                      <span>{val?.completed}</span>
                                    </div>
                                    {
                                      activeCategoryDetail === "posted" ?
                                        <>
                                          <div className="dataFirstBox">
                                            <label>Response Rate</label>
                                            <span>{val?.response_rate}</span>
                                          </div>
                                          <div className="dataFirstBox">
                                            <label>Avg. Response Rate</label>
                                            <span>{val?.avg_response_time}</span>
                                          </div>
                                        </>
                                        : null}

                                  </div>
                                </div>
                              </AccordionSummary>
                              <div
                                className="accordion-content"
                              >
                                <div className="loadSummaryData">
                                  <div className="loadSummaryDataRow">
                                    <div className="subLoadSummaryData">
                                      <label>Containerized</label>
                                      <span>{val?.containerized}</span>
                                    </div>
                                    <div className="subLoadSummaryData">
                                      <label>Non-Containerized</label>
                                      <span>{val?.non_containerized}</span>
                                    </div>
                                    <div className="subLoadSummaryData">
                                      <label>Spot</label>
                                      <span>{val?.spot}</span>
                                    </div>
                                    <div className="subLoadSummaryData customSubLoadSummaryData">
                                      <label><span className="insideLabel">Load</span> <span className="insideLabelText">(Aganist Contract)</span></label>
                                      <span>{val?.contract}</span>
                                    </div>
                                    <div className="subLoadSummaryData smallTextData">
                                      <label>Ton Awarded</label>
                                      <span>{`${numeral(val?.weight_awarded).format("0.000")}`} </span>
                                    </div>
                                    <div className="subLoadSummaryData smallTextData">
                                      <label>Ton Moved</label>
                                      <span>{`${numeral(val?.weight_moved).format("0.000")}`} </span>
                                    </div>
                                    <div className="subLoadSummaryData">
                                      <label>Vehicles</label>
                                      <span>{val?.vehicles}</span>
                                    </div>
                                    <div className="subLoadSummaryData">
                                      <label>Items</label>
                                      <span>{val?.item}</span>
                                    </div>
                                    <div className="subLoadSummaryData smallTextData">
                                      <label>Amount <span className="smallPKRText">(PKR)</span></label>
                                      <span className="fw-5">
                                        {numeral(val?.amount).format("0,0")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Accordion>
                          )
                        })
                        : null
                      }
                    </Grid>
                  </Grid>

                </InfiniteScroll>
              </PullToRefresh>
              :
              <div className="network-empty-component">
                <EmptyPlaceholder
                  title="No Data Found!"
                />
              </div>
            }

          </div>
        </div>
        <CustomModal showModal={showModal} toggleModal={setShowModal}>
          <div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 0
            }}>
              <div className="error-container info customInfoText dashboardCustomModalInfoText">
                <div>
                  <InfoIcon className="error-icon" />
                </div>
                <div className="error-text">
                  You can select upto 6 months data
                </div>
              </div>
              <span
                style={{
                  right: 15,
                  top: 30
                }}
                className="icons-cross directionRight"
                onClick={() => setShowModal(false)}
              ></span>
            </div>
            <div style={{ marginBottom: 10, marginTop: 15 }} className="inputField">
              <div className="label">From</div>
              <div className="DatePickerFieldWrap">
                <MaterialUIPickers
                  date={fromResponseDateDetail}
                  setDate={(e) => {
                    if (e > responseDateDetail) {
                      
                      showMessage.current({
                        message:
                          "From date should be less than To date.",
                        status: 'error'
                      })
                    }
                    dispatch(ProfileAction.setDetailFilter({
                      activeCategoryDetail: activeCategoryDetail,
                      activeDateDetail: activeDateDetail,
                      fromResponseDateDetail: e,
                      responseDateDetail,
                    }))
                  }}
                  minDate={"1900/01/01"}
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div style={{ marginBottom: 10, pointerEvents: fromResponseDateDetail ? "auto" : 'none' }} className="inputField">
              <div className="label">To</div>
              <div className="DatePickerFieldWrap">
                <MaterialUIPickers
                  date={responseDateDetail}
                  setDate={(e) => {
                    if (fromResponseDateDetail > e) {
                      
                      showMessage.current({
                        message:
                          "From date should be less than To date.",
                        status: 'error'
                      })
                    }
                    dispatch(ProfileAction.setDetailFilter({
                      activeCategoryDetail: activeCategoryDetail,
                      activeDateDetail: activeDateDetail,
                      fromResponseDateDetail: fromResponseDateDetail,
                      responseDateDetail: e,
                    }))
                  }}
                  minDate={"1900/01/01"}
                  maxDate={new Date()}
                />
              </div>
            </div>

            <OutLineButton
              classProp='tinyBtn'
              title="Apply"
              onClick={() => {
                handleSubmit()
                setShowModal(false)
              }}
              buttonStyle={{
                height: 36,
                width: '100%'
              }}
              disabled={fromResponseDateDetail > responseDateDetail}
            />
          </div>
        </CustomModal>
      </div>
    </div>
  );

};
export default Page;
