import React from "react";
import { Images } from "@temp/assets/";
import { IProps } from "./types";
import "./style.scss";
import { useHistory } from "react-router";

export const BackHeader: React.FC<IProps> = ({
  title,
  inverted,
  onClick,
  headerStyle,
  className
}: IProps) => {
  const history = useHistory();

  const handleRoute = (e) => {
    e.preventDefault();
    history.push("/home");
  };

  return (
    <div
      className={className ? className + " topHeader" : "topHeader"}
      style={{
        backgroundColor: "rgba(176,215,146,0.2)",
        width: "100%",
      }}
    >
      <div className="button-wrapper" style={headerStyle}>
        <div className="header-button">
          <button onClick={onClick} className="header-buttons">
            <img
              src={inverted ? Images.BackArrowWhite : Images.BackArrow}
              alt="Back"
            />
          </button>
        </div>
        <p className={inverted ? "title-inverted" : "title"}>{title}</p>
        <span className="icon icons-home active rightImg" style={{ cursor: 'pointer' }} onClick={(e) => handleRoute(e)}></span>
      </div>
    </div>
  );
};

export default BackHeader;
