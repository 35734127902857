import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import moment from "moment";
var isEmpty = require("lodash/isEmpty");
export var actionCreator = function actionCreator(name) {
  return [name, name + "_success", name + "_fail"];
};
import { store } from "@temp/store";
import { AuthAction } from "@temp/store/actions";
import { Images } from "@temp/assets";
import { isBrowser } from "react-device-detect";
import { sendEventToAndroid, sendEventToiOS } from "../utils/mobileEventHandler";
import { Mixpanel } from "./mixpanel";
import dayjs from "dayjs";
export var is_get_load_count = false;
export var updateLoadCountFlag = function updateLoadCountFlag() {
  is_get_load_count = true;
};
export var phoneNumberFormater = function phoneNumberFormater(phone_code, phone_number) {
  if (phone_code == "92" || phone_code == 92) {
    var split_number = phone_number === null || phone_number === void 0 ? void 0 : phone_number.split("");
    split_number === null || split_number === void 0 ? void 0 : split_number.splice(3, 0, " ");
    var joined_number = split_number === null || split_number === void 0 ? void 0 : split_number.join("");
    return "0".concat(joined_number);
  } else {
    return "".concat(phone_code, " ").concat(phone_number);
  }
};
export var rand_date = moment().format('YYYY-MM-DD-HH-mm');
export var triggerMixpanelEvent = function triggerMixpanelEvent(event_name, event_description) {
  if (isBrowser) {
    Mixpanel.track(event_name, {
      name: event_name,
      des: event_description
    });
  } else {
    if (sendEventToAndroid("mixpanelEventSend")) {
      window.Android.mixpanelEventSend(event_name);
    } else if (sendEventToiOS("mixpanelEventSend")) {
      window.webkit.messageHandlers.mixpanelEventSend.postMessage(JSON.stringify({
        access: event_name
      }));
    }
  }
};
export var fetchSearchApi = function fetchSearchApi(fn, delay) {
  var timerId;
  return function () {
    clearTimeout(timerId);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    timerId = setTimeout(fn, delay, [].concat(args));
  };
};
export var getDate = function getDate(value) {
  return moment(value).format("Y-MM-DD");
};
export var getTime = function getTime(value) {
  return moment(value).format("HH:mm:ss");
};
export var setItem = function setItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
};
export var getItem = function getItem(key) {
  var item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};
export var removeItem = function removeItem(key) {
  localStorage.removeItem(key);
};
export var showMessage = null;
export var getMessageRef = function getMessageRef(ref) {
  showMessage = ref;
};
export var showErrorMessage = null;
export var getErrorMessageRef = function getErrorMessageRef(ref) {
  showErrorMessage = ref;
};
var passwordRegex = /^(?=.{6,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/;

//changes

// unique device id
export var deviceId = function deviceId() {
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  var uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, "");
  uid += navigator_info.plugins.length;
  uid += screen_info.height || "";
  uid += screen_info.width || "";
  uid += screen_info.pixelDepth || "";
  return uid;
};
export var validateNameNumber = function validateNameNumber(name, number) {
  if (number !== null && number !== void 0 && number.length && name !== null && name !== void 0 && name.length) {
    if ((number === null || number === void 0 ? void 0 : number.length) >= 10) {
      if ((name === null || name === void 0 ? void 0 : name.length) > 2 && (name === null || name === void 0 ? void 0 : name.length) <= 30) {
        return true;
      } else {
        showMessage.current({
          message: "Contact Name should be min 3 characters and max 30 characters.",
          status: "error"
        });
        return false;
      }
    } else {
      showMessage.current({
        message: "Contact number should be of 10 digit.",
        status: "error"
      });
      return false;
    }
  } else if (number !== null && number !== void 0 && number.length) {
    if ((number === null || number === void 0 ? void 0 : number.length) >= 10) {
      return true;
    } else {
      showMessage.current({
        message: "Contact number should be of 10 digit.",
        status: "error"
      });
      return false;
    }
  } else if (name !== null && name !== void 0 && name.length) {
    if ((name === null || name === void 0 ? void 0 : name.length) > 2 && name.length <= 30) {
      return true;
    } else {
      showMessage.current({
        message: "Contact Name should be min 3 characters and max 30 characters.",
        status: "error"
      });
      return false;
    }
  } else {
    return true;
  }
};
var upperCase = /[A-Z]/;
var specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
var lowerCase = /[a-z]/;
var checkNum = /^[0-9]{1,6}$/;
var checkNumeric = /^(?=.*\d).{1,20}$/;
var refRegix = /^[a-zA-Z]{4}[0-9]{7}/;
var exp = /^(https\:\/\/www\.?|http\:\/\/www\.?|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
export var urlRegex = new RegExp(exp);
export var getMillisec = function getMillisec(t1, t2) {
  var temp = typeof t2 === "string" ? t2 : dayjs(t2).format('HH:mm:ss');
  return moment("".concat(t1, " ").concat(temp)).format('x');
  // return moment(`${dayjs(t1).format('YYYY-MM-DD')} ${dayjs(t2).format('HH:mm:ss')}`).format('x')
};
export var handleDateValidation = function handleDateValidation(deadline_date, deadline_time, pickup_date, pickup_time, delivery_date, delivery_time, emptyreturn_date, emptyreturn_time, from) {
  // when response deadline less than current time
  if (deadline_date && deadline_time) {
    if (getMillisec(deadline_date, deadline_time) < new Date().getTime() + 5000) {
      return from == "response_deadline_screen" ? "Response deadline should be greater than current time." : "Acceptance deadline should be greater than current time.";
    }
  }

  // when pickup less than response deadline
  if (deadline_date && deadline_time && pickup_date && pickup_time) {
    if (getMillisec(pickup_date, pickup_time) <= getMillisec(deadline_date, deadline_time)) {
      return "Pickup time should be greater than response deadline.";
    }
  } else {
    if (deadline_date && deadline_time && pickup_date && !pickup_time) {
      if (deadline_date > pickup_date) {
        return "Pickup time should be greater than response deadline.";
      }
    }
  }
  if (pickup_date && pickup_time && delivery_date && delivery_time) {
    if (getMillisec(delivery_date, delivery_time) <= getMillisec(pickup_date, pickup_time)) {
      return "Delivery deadline should be greater than pickup time.";
    }
  } else {
    if (pickup_date && delivery_date && (!pickup_time || !delivery_time)) {
      if (pickup_date > delivery_date) {
        return "Delivery deadline should be greater than pickup time.";
      }
    }
  }
  return "";
};
export var price_units_transalation = {
  Ton: " فی ٹن",
  "F.Ton": " فی فریٹ ٹن",
  CBM: "فی سی بی ایم",
  Liter: "فی لیٹر",
  Kg: "کلوگرام"
};
export function validateRefNo(ref) {
  return refRegix.test(ref);
}
export function validateUpper(password) {
  return upperCase.test(password);
}
export function validateLower(password) {
  return lowerCase.test(password);
}
export function validateSpecial(password) {
  return specialChar.test(password);
}
export function validateNum(password) {
  return checkNum.test(password);
}
export function validateNumeric(password) {
  return checkNumeric.test(password);
}
export function validatePassword(password) {
  return passwordRegex.test(password);
}
export var getFullTime = function getFullTime(params) {
  return new Date(params).getTime();
};
export var deadlineTime = function deadlineTime(params) {
  return new Date(params).getTime();
};
export var fileIconDisplay = function fileIconDisplay(mime_type) {
  if (mime_type.includes("image") || mime_type.includes("jpeg") || mime_type.includes("png")) {
    return Images.docPng;
  } else if (mime_type.includes("pdf")) {
    return Images.docPdf;
  } else if (mime_type.includes("text/plain") || mime_type.includes("plain")) {
    return Images.docTxt;
  } else if (mime_type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || mime_type.includes("spreadsheetml") || mime_type.includes("xlsx") || mime_type.includes("sheet")) {
    return Images.Xls;
  } else if (mime_type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") || mime_type.includes("word")) {
    return Images.Doc;
  } else if (mime_type.includes("text/csv")) {
    return Images.docCsv;
  }
};
export var dateIsGreater = function dateIsGreater(date1) {
  if (getFullTime(date1) > getFullTime(new Date())) {
    return true;
  } else {
    return false;
  }
};
export var convertTimeZone = function convertTimeZone(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
    timeZone: tzString
  }));
};
export function preventNonNumericalInput(e) {
  e = e || window.event;
  var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);
  if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
}
export function logOut() {
  store.dispatch(AuthAction.resetStore());
  setItem("token", null);
  setItem("toc_accepted", null);
  setItem("changed_password", null);
}
export function isObjectEmpty(object) {
  return Object.keys(object).length === 0 && object.constructor === Object;
}
export function isSupported() {
  if (self && "ServiceWorkerGlobalScope" in self) {
    // Running in ServiceWorker context
    return isSWControllerSupported();
  } else {
    // Assume we are in the window context.
    return isWindowControllerSupported();
  }
}
function isWindowControllerSupported() {
  return "indexedDB" in window && indexedDB !== null && navigator.cookieEnabled && "serviceWorker" in navigator && "PushManager" in window && "Notification" in window && "fetch" in window && ServiceWorkerRegistration.prototype.hasOwnProperty("showNotification") && PushSubscription.prototype.hasOwnProperty("getKey");
}
function isSWControllerSupported() {
  return "indexedDB" in self && indexedDB !== null && "PushManager" in self && "Notification" in self && ServiceWorkerRegistration.prototype.hasOwnProperty("showNotification") && PushSubscription.prototype.hasOwnProperty("getKey");
}
var getVehiclesList = function getVehiclesList(data) {
  var item = [];
  data.forEach(function (val) {
    var _val$selectVehicleTyp, _val$containerType, _val$values, _val$values2, _val$values3, _val$packageType, _val$values4;
    item.push({
      master_vehicle_type_id: val === null || val === void 0 ? void 0 : (_val$selectVehicleTyp = val.selectVehicleTypeObj) === null || _val$selectVehicleTyp === void 0 ? void 0 : _val$selectVehicleTyp.id,
      max_vehicle_weight: val === null || val === void 0 ? void 0 : val.max_vehicle_weight,
      vehicle_quantity: val === null || val === void 0 ? void 0 : val.vehicle_quantity,
      container_type_id: (val === null || val === void 0 ? void 0 : (_val$containerType = val.containerType) === null || _val$containerType === void 0 ? void 0 : _val$containerType.id) || null,
      total_offer_price: (val === null || val === void 0 ? void 0 : val.total_amount) || null,
      offer_price_per_vehicle: (val === null || val === void 0 ? void 0 : val.price) || null,
      is_boxed: (val === null || val === void 0 ? void 0 : (_val$values = val.values) === null || _val$values === void 0 ? void 0 : _val$values.is_boxed) || false,
      is_sidewall: (val === null || val === void 0 ? void 0 : (_val$values2 = val.values) === null || _val$values2 === void 0 ? void 0 : _val$values2.is_sidewall) || false,
      is_out_of_gauge: (val === null || val === void 0 ? void 0 : (_val$values3 = val.values) === null || _val$values3 === void 0 ? void 0 : _val$values3.is_out_of_gauage) || false,
      package_type_id: (val === null || val === void 0 ? void 0 : (_val$packageType = val.packageType) === null || _val$packageType === void 0 ? void 0 : _val$packageType.id) || (val === null || val === void 0 ? void 0 : val.package_type_id),
      is_twin_load: (val === null || val === void 0 ? void 0 : (_val$values4 = val.values) === null || _val$values4 === void 0 ? void 0 : _val$values4.is_twin_load) || false,
      id: val === null || val === void 0 ? void 0 : val.id
    });
  });
  return item;
};
export var getFclData = function getFclData(postData, postLoadData, callback) {
  var _postLoadData$vehicle, _postLoadData$vehicle2, _postLoadData$Payment, _postLoadData$Payment2, _postLoadData$Payment3, _postLoadData$Payment4, _postLoadData$Payment5, _postLoadData$Payment6, _postLoadData$Payment7, _postLoadData$Payment8, _postLoadData$Payment9, _postLoadData$Payment10, _postLoadData$Payment11, _postLoadData$locatio, _postLoadData$locatio2, _postLoadData$locatio3, _postLoadData$respons, _postLoadData$respons2, _postLoadData$respons3, _postLoadData$locatio4, _postLoadData$locatio5, _postLoadData$locatio6, _postLoadData$respons4, _postLoadData$respons5, _postLoadData$vehicle3, _postLoadData$vehicle4, _postLoadData$vehicle5, _postLoadData$vehicle6, _postLoadData$vehicle7, _postLoadData$vehicle8, _postLoadData$vehicle9, _postLoadData$vehicle10, _postLoadData$vehicle11, _postLoadData$vehicle12, _postLoadData$vehicle13;
  postData["title"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle = postLoadData.vehicleScreen) === null || _postLoadData$vehicle === void 0 ? void 0 : (_postLoadData$vehicle2 = _postLoadData$vehicle.values) === null || _postLoadData$vehicle2 === void 0 ? void 0 : _postLoadData$vehicle2.load_name;
  postData["inquiry_type_id"] = postLoadData === null || postLoadData === void 0 ? void 0 : postLoadData.inquiry_type;
  postData["load_type_id"] = postLoadData === null || postLoadData === void 0 ? void 0 : postLoadData.load_type;
  postData["transaction_type_id"] = postLoadData === null || postLoadData === void 0 ? void 0 : postLoadData.transaction_type_id;
  postData["pt_is_advance"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment = postLoadData.PaymentScreen) === null || _postLoadData$Payment === void 0 ? void 0 : _postLoadData$Payment.pt_is_advance;
  postData["pt_is_ondelivery"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment2 = postLoadData.PaymentScreen) === null || _postLoadData$Payment2 === void 0 ? void 0 : _postLoadData$Payment2.pt_is_ondelivery;
  postData["pt_is_credit"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment3 = postLoadData.PaymentScreen) === null || _postLoadData$Payment3 === void 0 ? void 0 : _postLoadData$Payment3.pt_is_credit;
  postData["is_pt_fuel_card"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment4 = postLoadData.PaymentScreen) === null || _postLoadData$Payment4 === void 0 ? void 0 : _postLoadData$Payment4.is_pt_fuel_card;
  postData["is_gst_inclusive"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment5 = postLoadData.PaymentScreen) === null || _postLoadData$Payment5 === void 0 ? void 0 : _postLoadData$Payment5.is_gst_inclusive;
  postData["is_withholding_tax_inclusive"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment6 = postLoadData.PaymentScreen) === null || _postLoadData$Payment6 === void 0 ? void 0 : _postLoadData$Payment6.is_withholding_tax_inclusive;
  postData["pt_advance_value"] = (postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment7 = postLoadData.PaymentScreen) === null || _postLoadData$Payment7 === void 0 ? void 0 : _postLoadData$Payment7.pt_advance_value) || 0;
  postData["pt_ondelivery_value"] = (postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment8 = postLoadData.PaymentScreen) === null || _postLoadData$Payment8 === void 0 ? void 0 : _postLoadData$Payment8.pt_ondelivery_value) || 0;
  postData["pt_credit_days"] = (postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment9 = postLoadData.PaymentScreen) === null || _postLoadData$Payment9 === void 0 ? void 0 : _postLoadData$Payment9.pt_credit_days) || 0;
  postData["pt_credit_percentage"] = (postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment10 = postLoadData.PaymentScreen) === null || _postLoadData$Payment10 === void 0 ? void 0 : _postLoadData$Payment10.pt_credit_percentage) || 0;
  postData["pt_fuel_card_value"] = (postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$Payment11 = postLoadData.PaymentScreen) === null || _postLoadData$Payment11 === void 0 ? void 0 : _postLoadData$Payment11.pt_fuel_card_value) || 0;
  postData["categories"] = {};
  postData["categories"]["items"] = {};
  postData["categories"]["pickup"] = {};
  postData["categories"]["pickup"]["lat"] = postLoadData === null || postLoadData === void 0 ? void 0 : postLoadData.location[0].lat;
  postData["categories"]["pickup"]["description"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$locatio = postLoadData.location[0]) === null || _postLoadData$locatio === void 0 ? void 0 : _postLoadData$locatio.description;
  postData["categories"]["pickup"]["raw_address"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$locatio2 = postLoadData.location[0]) === null || _postLoadData$locatio2 === void 0 ? void 0 : _postLoadData$locatio2.address;
  postData["categories"]["pickup"]["long"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$locatio3 = postLoadData.location[0]) === null || _postLoadData$locatio3 === void 0 ? void 0 : _postLoadData$locatio3.lng;
  postData["pickup_at"] = String(postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$respons = postLoadData.responseDeadline) === null || _postLoadData$respons === void 0 ? void 0 : _postLoadData$respons.pickupDate) + " " + String(postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$respons2 = postLoadData.responseDeadline) === null || _postLoadData$respons2 === void 0 ? void 0 : _postLoadData$respons2.pickupTime);
  postData["empty_return_yard_name"] = (postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$respons3 = postLoadData.responseDeadline) === null || _postLoadData$respons3 === void 0 ? void 0 : _postLoadData$respons3.empty_return_yard_name) || null;
  postData["categories"]["dropoff"] = {};
  postData["categories"]["dropoff"]["lat"] = postLoadData === null || postLoadData === void 0 ? void 0 : postLoadData.location[1].lat;
  postData["categories"]["dropoff"]["description"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$locatio4 = postLoadData.location[1]) === null || _postLoadData$locatio4 === void 0 ? void 0 : _postLoadData$locatio4.description;
  postData["categories"]["dropoff"]["raw_address"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$locatio5 = postLoadData.location[1]) === null || _postLoadData$locatio5 === void 0 ? void 0 : _postLoadData$locatio5.address;
  postData["categories"]["dropoff"]["long"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$locatio6 = postLoadData.location[1]) === null || _postLoadData$locatio6 === void 0 ? void 0 : _postLoadData$locatio6.lng;
  postData["deadline_at"] = String(postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$respons4 = postLoadData.responseDeadline) === null || _postLoadData$respons4 === void 0 ? void 0 : _postLoadData$respons4.responseDate) + " " + String(postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$respons5 = postLoadData.responseDeadline) === null || _postLoadData$respons5 === void 0 ? void 0 : _postLoadData$respons5.responseTime);
  postData["is_covid_certf_req_driver"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle3 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle3 === void 0 ? void 0 : (_postLoadData$vehicle4 = _postLoadData$vehicle3.values) === null || _postLoadData$vehicle4 === void 0 ? void 0 : _postLoadData$vehicle4.is_covid_certf_req_driver;
  postData["is_hse_compliant_vehicle"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle5 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle5 === void 0 ? void 0 : (_postLoadData$vehicle6 = _postLoadData$vehicle5.values) === null || _postLoadData$vehicle6 === void 0 ? void 0 : _postLoadData$vehicle6.is_hse_compliant_vehicle;
  (postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle7 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle7 === void 0 ? void 0 : (_postLoadData$vehicle8 = _postLoadData$vehicle7.values) === null || _postLoadData$vehicle8 === void 0 ? void 0 : _postLoadData$vehicle8.quote_by) === "weight_volume" ? null : postData["categories"]["items"] = getVehiclesList((postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle9 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle9 === void 0 ? void 0 : _postLoadData$vehicle9.addedVehicles) || []);
  (postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle10 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle10 === void 0 ? void 0 : (_postLoadData$vehicle11 = _postLoadData$vehicle10.values) === null || _postLoadData$vehicle11 === void 0 ? void 0 : _postLoadData$vehicle11.quote_by) === "weight_volume" ? postData["package_type_id"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle12 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle12 === void 0 ? void 0 : (_postLoadData$vehicle13 = _postLoadData$vehicle12.package_type) === null || _postLoadData$vehicle13 === void 0 ? void 0 : _postLoadData$vehicle13.id : null;
  postData["documents"] = (postLoadData === null || postLoadData === void 0 ? void 0 : postLoadData.document) || null;
  postData["recipients"] = (postLoadData === null || postLoadData === void 0 ? void 0 : postLoadData.recipients) || null;
  postData["ref_no"] = (postLoadData === null || postLoadData === void 0 ? void 0 : postLoadData.ref_no) || null;
  postData["cb"] = callback;
  return postData;
};
export var getLclData = function getLclData(postData, postLoadData, callback) {
  var _postLoadData$vehicle14, _postLoadData$vehicle15, _postLoadData$vehicle16, _postLoadData$vehicle17, _postLoadData$vehicle18, _postLoadData$vehicle19, _postLoadData$vehicle20, _postLoadData$vehicle21, _postLoadData$vehicle22, _postLoadData$vehicle23, _postLoadData$vehicle24, _postLoadData$vehicle25, _postLoadData$vehicle26, _postLoadData$vehicle27, _postLoadData$vehicle40;
  postData = _objectSpread(_objectSpread({}, postData), getFclData({}, postLoadData, null));
  postData["gross_weight"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle14 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle14 === void 0 ? void 0 : (_postLoadData$vehicle15 = _postLoadData$vehicle14.values) === null || _postLoadData$vehicle15 === void 0 ? void 0 : _postLoadData$vehicle15.gross_weight;
  postData["total_freight_ton"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle16 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle16 === void 0 ? void 0 : (_postLoadData$vehicle17 = _postLoadData$vehicle16.values) === null || _postLoadData$vehicle17 === void 0 ? void 0 : _postLoadData$vehicle17.total_freight_ton;
  postData["cargo_volume"] = (postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle18 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle18 === void 0 ? void 0 : (_postLoadData$vehicle19 = _postLoadData$vehicle18.values) === null || _postLoadData$vehicle19 === void 0 ? void 0 : _postLoadData$vehicle19.cargo_volume) || null;
  postData["no_of_packages"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle20 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle20 === void 0 ? void 0 : (_postLoadData$vehicle21 = _postLoadData$vehicle20.values) === null || _postLoadData$vehicle21 === void 0 ? void 0 : _postLoadData$vehicle21.no_of_packages;
  postData["quote_by"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle22 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle22 === void 0 ? void 0 : (_postLoadData$vehicle23 = _postLoadData$vehicle22.values) === null || _postLoadData$vehicle23 === void 0 ? void 0 : _postLoadData$vehicle23.quote_by;
  postData["gross_weight"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle24 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle24 === void 0 ? void 0 : (_postLoadData$vehicle25 = _postLoadData$vehicle24.values) === null || _postLoadData$vehicle25 === void 0 ? void 0 : _postLoadData$vehicle25.gross_weight;
  if ((postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle26 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle26 === void 0 ? void 0 : (_postLoadData$vehicle27 = _postLoadData$vehicle26.values) === null || _postLoadData$vehicle27 === void 0 ? void 0 : _postLoadData$vehicle27.quote_by) === "weight_volume") {
    var _postLoadData$vehicle28, _postLoadData$vehicle29, _postLoadData$vehicle30, _postLoadData$vehicle31, _postLoadData$vehicle32, _postLoadData$vehicle33, _postLoadData$vehicle34, _postLoadData$vehicle35, _postLoadData$vehicle36, _postLoadData$vehicle37, _postLoadData$vehicle38, _postLoadData$vehicle39;
    postData["price_per_freight"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle28 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle28 === void 0 ? void 0 : (_postLoadData$vehicle29 = _postLoadData$vehicle28.values) === null || _postLoadData$vehicle29 === void 0 ? void 0 : _postLoadData$vehicle29.unloading_price;
    postData["loading_price_per_freight"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle30 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle30 === void 0 ? void 0 : (_postLoadData$vehicle31 = _postLoadData$vehicle30.values) === null || _postLoadData$vehicle31 === void 0 ? void 0 : _postLoadData$vehicle31.loading_price;
    postData["total_offer_price"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle32 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle32 === void 0 ? void 0 : (_postLoadData$vehicle33 = _postLoadData$vehicle32.values) === null || _postLoadData$vehicle33 === void 0 ? void 0 : _postLoadData$vehicle33.price;
    postData["package_type_id"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle34 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle34 === void 0 ? void 0 : (_postLoadData$vehicle35 = _postLoadData$vehicle34.values) === null || _postLoadData$vehicle35 === void 0 ? void 0 : _postLoadData$vehicle35.package_type_id;
    postData["is_boxed"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle36 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle36 === void 0 ? void 0 : (_postLoadData$vehicle37 = _postLoadData$vehicle36.values) === null || _postLoadData$vehicle37 === void 0 ? void 0 : _postLoadData$vehicle37.is_boxed;
    postData["is_sidewall"] = postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle38 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle38 === void 0 ? void 0 : (_postLoadData$vehicle39 = _postLoadData$vehicle38.values) === null || _postLoadData$vehicle39 === void 0 ? void 0 : _postLoadData$vehicle39.is_sidewall;
  }
  getVehiclesList((postLoadData === null || postLoadData === void 0 ? void 0 : (_postLoadData$vehicle40 = postLoadData.vehicleScreen) === null || _postLoadData$vehicle40 === void 0 ? void 0 : _postLoadData$vehicle40.addedVehicles) || []);
  postData["cb"] = callback;
  return postData;
};
export var HELPER = {
  isNotEmpty: function isNotEmpty(x) {
    return !isEmpty(x);
  },
  isEmpty: function isEmpty(x) {
    return _typeof(x) === undefined || typeof x === "undefined" || x === null || x === "null" || x === "undefined" || x === false || x.length === 0 || x === "object" && Object.key(x).length === 0 || x === "" || x &&
    // 👈 null and undefined check
    Object.keys(x).length === 0 && Object.getPrototypeOf(x) === Object.prototype;
  }
};
export var convertNumberToBoolean = function convertNumberToBoolean(value) {
  return Boolean(Number(value));
};