import React from "react";
import { Colors } from "@temp/assets";
import { Button } from "@mui/material/";
import { IProps } from "./types";
import "./style.scss";

export const OutlineButton: React.FC<any> = ({
  title,
  icon,
  disabled,
  onClick,
  buttonStyle,
  type,
  buttonTitleStyle,
  startIcon,
  classProp,
  dataColor,
  is_recipient,
  urdutitle,
  counter,
}: IProps) => {
  return (
      <Button
        disabled={disabled}
        style={
          disabled
            ? { border: `1px solid ${Colors.PinkishGray}`, ...buttonStyle }
            : { border: `1px solid ${Colors.Primary}`, ...buttonStyle }
        }
        className={classProp ? `${classProp} outline-button` : 'outline-button'}
        type={type}
        startIcon={startIcon}
        data-color={dataColor}
        variant="contained"
        onClick={onClick}
      >
      {typeof icon == 'object' ? icon : icon ? <img alt="" src={icon} className="button-icon" /> : null}{" "}
      <p
        className="button-title"
        style={
          disabled
            ? { color: Colors.PinkishGray, ...buttonTitleStyle }
            : { color: Colors.Primary, ...buttonTitleStyle }
        }
      >
        {title}
        <span className="loadsCounter">{counter}</span>
        {
          is_recipient &&
          <>
            <span className="seperator">/</span>
            <span className="urduLabel">{urdutitle}</span>
          </>
        }
      </p>
    </Button>
  );
};

export default OutlineButton;
