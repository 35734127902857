import { Accordion, AccordionSummary } from "@mui/material/";
import { Colors, Images } from "@temp/assets";
import { CustomText } from "@temp/components";
import React from "react";
import "../LoadDetailPoster/style.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../LoadDetailPoster/style.scss";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import { fileIconDisplay } from "@temp/utils/helper";

export const UploadedDocuments = ({ loadDetail, uploadedBy, is_recipient }) => {
  return (
    <div className="accordion-container">
      <Accordion className="accordion-background">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="fw-5">
            {
              uploadedBy ?
                <>
                  <span className="titleName">{`Documents Uploaded By ${uploadedBy}`}</span>
                  {
                    is_recipient &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">پوسٹر کی جانب سے اپ لوڈ کردہ دستاویزات</span>
                    </>
                  }
                </> :
                <>
                  <span className="titleName">Uploaded Documents</span>
                  {
                    is_recipient &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">دستاویزات</span>
                    </>
                  }
                </>
            }
          </div>
        </AccordionSummary>
        <div className="accordion-content">
          <div>
            {loadDetail?.documents?.length ? (
              <>
                {loadDetail?.documents?.filter((item) => item?.type == 1)
                  .length ? (
                  <div className="grayLabel fw-5">
                    Bill of Lading
                    {
                      is_recipient &&
                      <>
                        <span className="seperator">/</span>
                        <span className="urduLabel">اسباب محمولہ کا بل</span>
                      </>
                    }
                  </div>
                ) : null}
                {loadDetail?.documents
                  ?.filter((item) => item?.type == 1)
                  .map((bill, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (sendEventToAndroid("UploadedDocuments")) {
                            window.Android.UploadedDocuments(
                              bill?.path
                            );
                          } else if (sendEventToiOS("UploadedDocuments")) {
                            window.webkit.messageHandlers.UploadedDocuments.postMessage(
                              JSON.stringify({
                                document: bill?.path,
                              })
                            );
                          } else {
                            window.open(bill?.path, "_blank");
                          }
                        }}
                        className="file-container-row"
                      >
                        <img
                          alt=""
                          style={{ height: 20, width: 20 }}
                          src={fileIconDisplay(bill?.mime_type)}
                        />

                        <div className="files-text">
                          {bill?.file_name}
                        </div>
                      </div>
                    );
                  })}

                {loadDetail?.documents?.filter((item) => item?.type == 2)
                  .length > 0 ? (
                  <div className="file-container-row">
                    <div className="grayLabel fw-5">
                      Packing List
                      {
                        is_recipient &&
                        <>
                          <span className="seperator">/</span>
                          <span className="urduLabel">پیکنگ لسٹ</span>
                        </>
                      }
                    </div>
                  </div>
                ) : null}
                {loadDetail?.documents
                  ?.filter((item) => item?.type == 2)
                  .map((bill, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (sendEventToAndroid("UploadedDocuments")) {
                            window.Android.UploadedDocuments(
                              bill?.path
                            );
                          } else if (sendEventToiOS("UploadedDocuments")) {
                            window.webkit.messageHandlers.UploadedDocuments.postMessage(
                              JSON.stringify({
                                document: bill?.path,
                              })
                            );
                          } else {
                            window.open(bill?.path, "_blank");
                          }
                        }}
                        className="file-container-row"
                      >
                        <img
                          alt=""
                          style={{ height: 20, width: 20 }}
                          src={fileIconDisplay(bill?.mime_type)}
                        />
                        <div className="files-text">
                          {/* {`packing_list ${index + 1}`} */}
                          {bill?.file_name}
                        </div>
                      </div>
                    );
                  })}

                {/* {loadDetail?.documents?.filter((item) => item?.type == 0)
                  .length ? (
                  <div className="file-container-row">
                    <div className="grayLabel fw-5">
                      Others
                      {
                        is_recipient &&
                        <>
                          <span className="seperator">/</span>
                          <span className="urduLabel">دیگر</span>
                        </>
                      }
                    </div>
                  </div>
                ) : null} */}
                {loadDetail?.documents
                  ?.filter((item) => item?.type == 0)
                  .map((bill, index) => {
                    return (
                      <div
                        onClick={() => {
                          if (sendEventToAndroid("UploadedDocuments")) {
                            window.Android.UploadedDocuments(
                              bill?.path
                            );
                          } else if (sendEventToiOS("UploadedDocuments")) {
                            window.webkit.messageHandlers.UploadedDocuments.postMessage(
                              JSON.stringify({
                                document: bill?.path,
                              })
                            );
                          } else {
                            window.open(bill?.path, "_blank");
                          }
                        }}
                        className="file-container-row"
                      >
                        <img
                          alt=""
                          style={{ height: 20, width: 20 }}
                          src={fileIconDisplay(bill?.mime_type)}
                        />
                        <div className="files-text">
                          {bill.other_type ? (
                            <div className="">
                              {bill?.file_name}
                            </div>
                          ) : (
                            <div className="">
                              {bill?.file_name}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : (
              <div>No documents uploaded</div>
            )}
            <div style={{ height: 16 }}></div>
          </div>
        </div>
      </Accordion>
    </div>
  );
};
