import React, { useState } from "react";
import styled from "styled-components";
import "./style.scss";
import { CustomizedSwitches, TextInput } from "@components";
import { useSelector } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material/";
import { ExpandMore } from "@mui/icons-material";
import { convertNumberToBoolean } from "@temp/utils/helper";
import { LOAD_TYPE } from "@temp/constants";
export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 100%;
  background-color: rgb(250, 250, 250);
  margin-bottom: 20px;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 0 0 !important;
`;

export const CategoryContainer = ({
  title,
  value,
  index,
  onChangeValue,
  categoryLength,
}) => {
  const FormRow = ({ label, value }) => {
    return (
      <>
        <Grid item xs={5}>
          <div className="transactionFieldLabel">{label}</div>
        </Grid>
        <Grid item xs={7}>
          <div className="transactionRightFieldLabel">{value}</div>
        </Grid>
      </>
    );
  };
  const [isExpanded, setIsExpanded] = useState(true);
  const postLoadMeta = useSelector(
    (state: any) => state?.Profile?.postLoadMeta
  );
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);

  const load_type = postLoadMeta.load_types.find(
    (val: any) => val.id === PostLoad.load_type
  );

  const handleChange = (check) => {
    setIsExpanded(check);
  };
  const SwitchableWithText = (props: any) => {
    return (
      <Grid
        container
        spacing={1}
        style={{ marginBottom: 5, alignItems: "center" }}
        className="SwitchableWithText"
      >
        <Grid item xs={8}>
          <div className="clr-label mb-0">{props?.text}</div>
        </Grid>

        <Grid
          item
          xs={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div style={{ marginTop: 0 }}>
            <CustomizedSwitches {...props} />
          </div>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Maincontainer>
        <Accordion
          expanded={isExpanded}
          onChange={() => handleChange(!isExpanded)}
          className="offerAccordionContainer"
          style={{ backgroundColor: "rgb(250,250,250)" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <label className="fw-6">
              {categoryLength > 1
                ? ` Vehicle Category ${index + 1}`
                : " Vehicle Category"}
            </label>
          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0, paddingBottom: 10 }}>
            <Grid
              container
              spacing={1}
              style={{ overflowY: "scroll", margin: 0 }}
            >
              <FormRow
                label="Vehicle Type"
                value={value?.selectVehicleTypeObj?.title}
              />

              {load_type.title === LOAD_TYPE.bulk &&
                value?.selectVehicleTypeObj?.sub_title && (
                  <FormRow
                    label="Vehicle Capacity"
                    value={value?.selectVehicleTypeObj?.sub_title}
                  />
                )}
                
              {load_type.title === LOAD_TYPE.bulk ?
                <FormRow
                  label="Packaging Type"
                  value={
                    value?.packageType?.title === "Other"
                      ? value?.packageType?.title +
                      " (" +
                      value?.values?.package_type_other +
                      ")"
                      : value?.packageType?.title || ""
                  }
                /> : null}

              {convertNumberToBoolean(
                value?.selectVehicleTypeObj?.is_boxed
              ) && (
                  <FormRow
                    label="Box"
                    value={value?.values?.is_boxed ? "YES" : "NO "}
                  />
                )}
              {convertNumberToBoolean(
                value?.selectVehicleTypeObj?.is_sidewall
              ) && (
                  <FormRow
                    label="Half Body"
                    value={value?.values?.is_sidewall ? "YES" : "NO "}
                  />
                )}
              {title === "BULK" && (
                <FormRow
                  label="Heavy Lift / Out of Gauge"
                  value={value?.values?.is_out_of_gauage ? "YES" : "NO "}
                />
              )}

              <FormRow label="Quantity" value={value?.vehicle_quantity} />
              <Grid item xs={12}>
                <SwitchableWithText
                  id={"is_loading_required"}
                  handleChange={(e) => {
                    onChangeValue(
                      index,
                      "is_loading_required",
                      e.target.checked
                    );

                    onChangeValue(index, "loading_requirements", "");
                    onChangeValue(index, "loading_price", 0);
                  }}
                  checked={value?.is_loading_required || false}
                  name="is_loading_required"
                  text="Loading Required"
                />
                {value?.is_loading_required && (
                  <div className="inputField" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                    <div className="label">
                      Specify Labour / Equipment Requirement
                    </div>
                    <div className="field">
                      <TextInput
                        onChange={(e) => {
                          onChangeValue(
                            index,
                            "loading_requirements",
                            e.target.value
                          );
                        }}
                        type="text"
                        value={value?.loading_requirements || ""}
                        aria-describedby="standard-weight-helper-text"
                        placeholder="Optional"
                      />
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <SwitchableWithText
                  id={"is_unloading_required"}
                  checked={value?.is_unloading_required || false}
                  handleChange={(e) => {
                    onChangeValue(
                      index,
                      "is_unloading_required",
                      e.target.checked
                    );
                    onChangeValue(index, "unloading_price", 0);

                    onChangeValue(index, "unloading_requirements", "");
                  }}
                  name="is_unloading_required"
                  text="Unloading Required"
                />
                {value?.is_unloading_required && (
                  <div className="inputField" style={{ marginTop: '1rem', marginBottom: 0 }}>
                    <div className="label">
                      Specify Labour / Equipment Requirement
                    </div>
                    <div className="field">
                      <div className="field">
                        <TextInput
                          onChange={(e) => {
                            onChangeValue(
                              index,
                              "unloading_requirements",
                              e.target.value
                            );
                          }}
                          type="text"
                          value={value?.unloading_requirements || ""}
                          aria-describedby="standard-weight-helper-text"
                          placeholder="Optional"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Maincontainer>
    </>
  );
};

export default CategoryContainer;
