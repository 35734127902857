import React, { useEffect, useState } from "react";
import { INotificationsPageProps } from "./types";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { CustomText, ButtomTabs, DrawerNav } from "@components";
import { DrawerAction, LoadAction, ProfileAction } from "@temp/store/actions";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
import EmptyPlaceholder from "@temp/components/EmptyPlaceholder/EmptyPlaceholder";
import moment from "moment";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material/";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { isBrowser } from "react-device-detect";
import {
    sendEventToAndroid,
    sendEventToiOS,
} from "../../../utils/mobileEventHandler";


export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<INotificationsPageProps> = (props) => {
    var searchFlag = false
    const history = useHistory();
    const dispatch = useDispatch();
    const lastReadTimestamp = useSelector(
        (state) => state?.Profile?.profile?.last_notification_read_at || ""
    );
    const notifications_data_current_page = useSelector((state: RootState) => state.Drawer.notifications_data_current_page);
    const notifications_data_last_page = useSelector((state: RootState) => state.Drawer.notifications_data_last_page);
    const notificationsData = useSelector(
        (state: RootState) => state.Drawer.notificationsData
    );
    const [query, setQuery] = useState("");
    
    
    


    useEffect(() => {
        getNotificationsData({ 
            refresh: true, 
            query,
         })
        dispatch(ProfileAction.GetProfile());
    }, []);

    const debouncedSearchTerm = useDebounce(query, 500);
    
    useEffect(
        () => {
            if (debouncedSearchTerm) {
                getNotificationsData({
                    refresh: false,
                    query: query,
                    fromSearch: true,
                })
            }
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
    );

    useEffect(() => {
        // if (searchFlag) {
            if (query == "") {
                getNotificationsData({
                    refresh: false,
                    query: query,
                    fromSearch: true,
                })
            }
        // }
        
    }, [query])

    const checkIsRead = (date) => {
        return moment(date).isAfter(moment(lastReadTimestamp));
    };

    const handleRefresh = async() => {
        getNotificationsData({ refresh: true, query })
    }

    const getNotificationsData = (data) => {
        
        // return
        dispatch(
            DrawerAction.GetNotifications({
                refresh: data.refresh,
                next_page: data?.refresh || data?.fromSearch || query ? 1 : Number(notifications_data_current_page) + 1,
                query: data?.query ? data?.query : "",
                fromSearch: data?.fromSearch,
            })
        );
    };

    const downloadCSV = (params) => {
        if (isBrowser) {
            window.open(params, "_blank");
        } else {
            if (sendEventToAndroid("dashboardGenerateReport")) {
                window.Android.dashboardGenerateReport(params);
            } else if (sendEventToiOS("dashboardGenerateReport")) {
                window.webkit.messageHandlers.dashboardGenerateReport.postMessage(
                    JSON.stringify({
                        access: params,
                    })
                );
            } else {
            }
        }
    }

    const RenderNotification = ({ item, index }) => {
        
        return (
            <div
                onClick={() => {
                    if (item?.data?.title == "Report Generated") {
                        if (item?.data?.report_url) {
                            downloadCSV(item?.data?.report_url)
                        }
                    } else if (
                        item?.data?.status_code == "all_user_notification"
                    ) {
                        history.push("/all-notification", {
                            title: item?.data?.title,
                            body: item?.data?.body,
                        });
                    } else {
                        dispatch(LoadAction.loadDetailEmpty())
                        setTimeout(() => {
                            history.push(item?.data?.url);
                        }, 200);
                    }
                }}
                className="notifications-info-wrapper"
            >
                <div
                    className={
                        checkIsRead(item?.created_at)
                            ? "notifications-img-container active"
                            : "notifications-img-container"
                    }
                >
                    <img
                        alt=""
                        src={item?.data?.icon}
                        className="icon-image"
                    />
                    <div className="rightNotifyText">
                        <CustomText.OpenSansSemiBold
                            textStyle={{
                                fontSize: "0.75rem",
                                color: Colors.Black,
                            }}
                        >
                            {item?.data?.title}
                        </CustomText.OpenSansSemiBold>
                        <CustomText.OpenSansRegular
                            textStyle={{
                                fontSize: "0.75rem",
                                color: Colors.Black,
                            }}
                        >
                            {item?.data?.status_code == "all_user_notification"
                                ? item?.data?.body.length > 50
                                    ? item?.data?.body?.substring(0, 50) + "..."
                                    : item?.data?.body
                                : item?.data?.body}
                        </CustomText.OpenSansRegular>

                        <CustomText.OpenSansRegular
                            textStyle={{
                                fontSize: "0.563rem",
                                color: Colors.Grayish,
                                margin: "3px 0 0 0",
                            }}
                        >
                            {moment(item?.created_at).format(
                                "Y-MM-DD HH:mm:ss"
                            )}
                        </CustomText.OpenSansRegular>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="root-center-container">
            <div className="loads-top-wrappers">
                <div className="topDropDownWrap">
                    <div className="drop-down notifydropdown" style={{ justifyContent: 'space-between' }}>
                        <DrawerNav />
                        <p className="title">
                                Notifications
                        </p>
                    </div>
                </div>
                <div className="notifySearchBar">
                    <FadeInComponent>
                        <div className="searchContainer" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search"
                                className="searchInput recipentSelectBox"
                                value={query}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    setQuery(e.target.value)
                                    // searchFlag = true
                                }}
                            />
                            <span className={`icons-search ${query ? '' : 'active'}`}></span>
                            <span
                                className={`icons-cross ${query ? 'active' : ''}`}
                                onClick={() => {
                                    setQuery("")
                                }}
                            ></span>
                        </div>
                    </FadeInComponent>
                </div>
            </div>
            <div style={{
                paddingTop: 110
            }} className="load-listing-container customNotifyWrap remCustomPadonMob notifyWrap">
                <div>
                    {
                        notificationsData.length ?
                            <PullToRefresh
                                onRefresh={handleRefresh}
                            >
                                <InfiniteScroll
                                    dataLength={notificationsData.length}
                                    next={() => {
                                        getNotificationsData({ refresh: false, query })
                                    }}
                                    className="notifications-wrapper"
                                    height={'calc(100vh - 132px)'}
                                    hasMore={notifications_data_last_page > notifications_data_current_page}
                                    loader={<div className="load-more-loader"> <CircularProgress color="primary" /></div>}
                                    scrollThreshold={0.7}
                                >
                                    {notificationsData?.map((item, index) => {
                                        return (
                                            <FadeInComponent key={index}>
                                                <RenderNotification item={item} index={index} />
                                            </FadeInComponent>
                                        )
                                    })}
                                </InfiniteScroll>
                            </PullToRefresh>
                            :
                            <div className="network-empty-component">
                                <EmptyPlaceholder
                                    image={Images.noNotificationNew}
                                    title="No Notification!"
                                    detail="You have no notification please post a Load"
                                />
                            </div>
                    }
                </div>
            </div>
            <ButtomTabs activeTab="notifications" />
        </div>
    )
};

export default Page;

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay]
    );
    return debouncedValue;
}
