import Axios from "axios";
import { showMessage, showErrorMessage, setItem } from "@utils/helper";
import Endpoints from "@temp/store/endpoints";
import { store } from "@temp/store";
import { AuthAction } from "@temp/store/actions";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../utils/mobileEventHandler"
import { history } from "@temp/history";

const api_version = "v1";
const api_version_2 = "v2"

// ng rok urls 
// const baseUrlV1 = `https://e530-119-157-69-72.ngrok.io/api/${api_version}/`
// const baseUrlV2 = `https://e530-119-157-69-72.ngrok.io/api/${api_version_2}/`

// env url 
console.log('process', process.env.REACT_APP_API_URL)
const baseUrlV1 = `${process.env.REACT_APP_API_URL}${api_version}/`;
const baseUrlV2 = `${process.env.REACT_APP_API_URL}${api_version_2}/`;

// staging url 
// const baseUrlV1 = `https://bk-stage.merigari.com/api/${api_version}/`
// const baseUrlV2 = `https://bk-stage.merigari.com/api/${api_version_2}/`

// production url 
// const baseUrlV1 = `https://mg-production-web.herokuapp.com/api/${api_version}/`
// const baseUrlV2 = `https://mg-production-web.herokuapp.com/api/${api_version_2}/`

Axios.defaults.withCredentials = true;

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (response) => {
    if (response.response.status === 401) {
      if (sendEventToAndroid("webviewLogout")) {
        window.Android.webviewLogout(String("logout"));
      } else if (sendEventToiOS("webviewLogout")) {
        window.webkit.messageHandlers.webviewLogout.postMessage(
          JSON.stringify({
            logout: true,
          })
        ); 
      } 
      history.replace('/')
      setItem("token", null);
      setItem("toc_accepted", null);
      setItem("changed_password", null);
      window.location.reload();
      store.dispatch(AuthAction.resetStore());
      showMessage.current({
        message: response.response.data.message,
        status: "error",
      });
    } else if (response.response.status === 422) {
      if (
        response.response.config.url ==
        `${baseUrlV1 + Endpoints.LOGIN_WITH_PASSWORD}`
      ) {
        showMessage.current({
          message: response.response?.data?.message,
          status: "error",
        });
      } else {
        showErrorMessage.current({
          data: response.response?.data,
          status: "error",
        });
      }
    } else if (response.response.status === 403) {
      showMessage.current({
        message: response.response?.data?.message,
        status: "error",
      });
    } else if (response.response.status === 500) {
      showMessage.current({
        message: "Something went wrong! please try again later.",
        status: "error",
      });
    }
    return response.response;
  }
);

export default class ApiCaller {
  static Get = (url = "", headers = {}, params = {},from_listing) => {
    return Axios.get(from_listing?baseUrlV2 +url:baseUrlV1 + url, {
      params,
      headers: { "Content-Type": "application/json", ...headers },
    })
      .then((res) => res)
      .catch((err) => err.response);
  };

  static Post = async (url = "", body = {}, headers = {}, from_listing) => {
    return Axios.post(from_listing?baseUrlV2 +url:baseUrlV1 + url, body, {
      headers: { ...headers, Accept: "application/json" },
    })
      .then((res) => res)
      .catch((err) => err);
  };

  static Put = (url = "", body = {}, headers = {}) => {
    return Axios.put(baseUrlV1 + url, body, {
      headers: { "Content-Type": "application/json", ...headers },
    })
      .then((res) => res)
      .catch((err) => err.response);
  };

  static Patch = (url = "", body = {}, headers = {}) => {
    return Axios.patch(baseUrlV1 + url, body, {
      headers: { "Content-Type": "application/json", ...headers },
    })
      .then((res) => res)
      .catch((err) => err.response);
  };

  static Delete = (url = "", body = {}, headers = {}) => {
    return Axios.delete(baseUrlV1 + url, {
      headers: { ...headers },
      data: body,
    })
      .then((res) => res)
      .catch((err) => err.response);
  };
}