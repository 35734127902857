import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { PlayCircleFilled, PauseCircleFilled } from "@mui/icons-material";
import './styles.scss'
import {useAudioPlayer} from './useAudioPlayer'



function Bar(props) {
    const { duration, curTime, onTimeUpdate } = props;
    
    

    const curPercentage = (curTime / duration) * 100;

    // function formatDuration(duration) {
    //     return moment.duration(duration, "seconds").format("mm:ss", { trim: false });
    // }

    function calcClickedTime(e) {
        const clickPositionInPage = e.pageX;
        const bar = document.querySelector(".bar__progress");
        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;
        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = duration / barWidth;
        return timePerPixel * clickPositionInBar;
    }

    function handleTimeDrag(e) {
        onTimeUpdate(calcClickedTime(e));

        const updateTimeOnMove = eMove => {
            onTimeUpdate(calcClickedTime(eMove));
        };

        document.addEventListener("mousemove", updateTimeOnMove);

        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", updateTimeOnMove);
        });
    }

    return (
        <div className="bar">
            {/* <span className="bar__time">{formatDuration(curTime)}</span> */}
            <div
                className="bar__progress"
                style={{
                    background: `linear-gradient(to right, orange ${curPercentage}%, white 0)`
                }}
                onMouseDown={e => handleTimeDrag(e)}
            >
                <span
                    className="bar__progress__knob"
                    style={{ left: `${curPercentage - 2}%` }}
                />
            </div>
            {/* <span className="bar__time">{formatDuration(duration)}</span> */}
        </div>
    );
}

function Play(props) {
    const { handleClick } = props;

    return (
        <button className="player__button" onClick={() => handleClick()}>
            <PlayCircleFilled />
        </button>
    );
}

function Pause(props) {
    const { handleClick } = props;

    return (
        <button className="player__button" onClick={() => handleClick()}>
            <PauseCircleFilled />
        </button>
    );
}


export const AudioPlayer = ({url}) => {
    const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer();

    return (
        <div className="player">
            <audio id="audio">
                <source src={"https://file-examples.com/storage/fefe3c760763a87999556e8/2017/11/file_example_MP3_700KB.mp3"} />
                Your browser does not support the <code>audio</code> element.
            </audio>
            <div className="controls">
                {playing ?
                    <Pause handleClick={() => setPlaying(false)} /> :
                    <Play handleClick={() => setPlaying(true)} />
                }
                <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} />
            </div>
        </div>
    );
}

