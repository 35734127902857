import React from "react";
// import { makeStyles, createStyles, Theme } from "@mui/material//styles";
import Grid from "@mui/material//Grid";
import { IProps } from "./types";
import OutlineButton from "../OutLineButton";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../utils/mobileEventHandler";
import { fileIconDisplay } from "@temp/utils/helper";
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       flexGrow: 1,
//       marginTop: 15,
//     },
//     paper: {
//       backgroundColor: "transparent",
//       border: "none",
//     },
//   })
// );

export const DocumentsTable: React.FC<IProps> = ({
  values,
  title,
  titleIcon,
  onHandleDelete,
  onClickDocumentType,
  datas,
  from,
  screen,
}: IProps) => {
//   const classes = useStyles();
  let documents = values && values.length ? values : [];
  const FormRow = ({ value, index, arrayIndex, val }) => {
    return (
      <React.Fragment>
        <Grid item xs={10} style={{ display: "flex", padding: 0 }}>
          <div
            className="fileWrapper"
            onClick={() => {
              if (sendEventToAndroid("UploadedDocuments")) {
                window.Android.UploadedDocuments(value?.cloudinary_url);
              } else if (sendEventToiOS("UploadedDocuments")) {
                window.webkit.messageHandlers.UploadedDocuments.postMessage(
                  JSON.stringify({
                    document: value?.cloudinary_url,
                  })
                );
              } else {
                window.open(value?.cloudinary_url, "_blank");
              }
            }}
          >
            <img
              className="fileTypeIcon"
              src={fileIconDisplay(value?.mime_type)}
            ></img>

            <div className="fileName">{value?.file_name}</div>
          </div>
        </Grid>

        <Grid
          item
          xs={2}
          style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
        >
          {onHandleDelete ? (
            <span
              className="deleteIcon icons-cross"
              onClick={() => {
                if (from == "other") {
                  onHandleDelete(index, arrayIndex, val);
                } else {
                  onHandleDelete(index);
                }
              }}
            ></span>
          ) : null}
        </Grid>
        <div>
          {/* {value?.file_url} */}
        </div>
      </React.Fragment>
    );
  };
  return (
    <div className="documentWrapper">
      <div style={{ display: "flex", marginBottom: 15 }}>
        <div className={`docTitle`}>{datas?.title}</div>
      </div>

      <div
	//    className={classes.root}
	   >
        {from !== "other" ? (
          <Grid container>
            {documents && documents.length ? (
              documents.map((value: object, index: number) => (
                <Grid
                  key={index}
                  container
                  item
                  xs={12}
                  style={{ marginBottom: 4 }}
                >
                  <FormRow value={value} index={index} />
                </Grid>
              ))
            ) : (
              <div>No Document Uploaded</div>
            )}
          </Grid>
        ) : (
          <div>
            {values?.map((item, arrIndex) => {
              return (
                <Grid key={arrIndex} className="otherDoc" container >
                  <label className="" style={{ marginBottom: 10 }}>
                    {item?.title}
                  </label>
                  {item?.files && item?.files.length ? (
                    item?.files.map((value: object, index: number) => (
                      <Grid key={index} container item xs={12}>
                        <FormRow
                          value={value}
                          index={index}
                          arrayIndex={arrIndex}
                          val={item}
                        />
                      </Grid>
                    ))
                  ) : (
                    <div>No Document Uploaded</div>
                  )}
                </Grid>
              );
            })}
          </div>
        )}
        {screen == "document" ? (
          <div className="">
            <OutlineButton
              title="Upload File"
              onClick={() => {
                onClickDocumentType(datas);
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DocumentsTable;
