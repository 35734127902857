import React, { FC, useState, useEffect } from "react";
import {
  InputModal,
  LocationTimeline,
  PrimaryButton,
  GoogleMap,
} from "@components";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material/";
import { ProfileAction } from "@temp/store/actions";
import { useHistory } from "react-router-dom";
import TitleLayout from "@temp/components/TitleLayout";

export const DropoffLocation: FC = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const [inputValue, setInputValue] = React.useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue((event.target as HTMLInputElement).value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    let loc = location;
    if (loc[1].description) setInputValue(loc[1].description);
  }, []);
  const PostLoad = useSelector((state) => state?.Profile?.postLoad);
  const [location, setLocation] = useState(
    PostLoad.location.length > 1
      ? PostLoad.location
      : [
          ...PostLoad.location,
          {
            address: null,
            lat: null,
            lng: null,
            timestamp: null,
            description: null,
            title: null
          },
        ]
  );
  const postLoadMeta = useSelector((state) => state?.Profile?.postLoadMeta);

  const load_type = postLoadMeta.load_types.find(
    (val: object) => val.id === PostLoad.load_type
  );

  return (
    <TitleLayout
      pageNumber={6}
      progress={60}
      title={`Dropoff Location - ${load_type.title}`}
    >
      <InputModal
        values={inputValue}
        showModal={showModal}
        title={"Add Additional Detail"}
        placeholder={"Add details here"}
        setValue={handleChange}
        maxLetter={true}
        handleModal={(value: boolean, type: string) => {
          if (type === "pickup") {
            let loc = location;
            loc[1].description = inputValue;
            setLocation([...loc]);
          }

          setShowModal(value);
        }}
      />
      <div className="mapInputWrapper">
        <GoogleMap
          zoom={13}
          center={
            location[1].lat
              ? {
                  lat: location[1].lat,
                  lng: location[1].lng,
                }
              : {
                  lat: 24.8607,
                  lng: 67.0011,
                }
          }
        />
        <div
          style={{
            margin: "20px 0",
            paddingTop: 10,
            borderRadius: 4,
            zIndex: 1,
            position: "absolute",
            top: 40,
            left: "3%",
            width: "94%",
            background: "white",
          }}
        >
          <LocationTimeline
            location={location}
            enableEdit={true}
            handleModal={(value: number) => {
              const url = value === 0 ? value + "?redirect=dropoff" : value;
              // history.push("/add-address/" + url);
              history.replace("contract-location/add-new");
            }}
          ></LocationTimeline>
          <div
            style={{
              borderTop: "1px solid rgb(204 204 204)",
              marginBottom: 5,
              marginLeft: 20,
              marginRight: 20,
            }}
          ></div>

          <center>
            <Button
              onClick={() => {
                setShowModal(true);
              }}
              style={{
                fontWeight: "bold",
                fontSize: "0.75rem",
                color: "rgb(88 156 213)",
                border: "none",
                background: "transparent",
                boxShadow: "none",
              }}
            >
              Add Additional Details
            </Button>
          </center>
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <PrimaryButton
          disabled={
            location && location.length && location[1].address ? false : true
          }
          rightIcon={true}
          title="Confirm Dropoff"
          onClick={() => {
            dispatch(
              ProfileAction.postLoadData({
                location: [...location],
              })
            );

            history.push("/response-deadline");
          }}
        />
      </div>
    </TitleLayout>
  );
};
