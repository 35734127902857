import React, { FC, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./style.scss";
import {
  SimpleCheckBox,
  PrimaryButton,
  VehicleTable,
  VehicleIncDec,
  CustomizedSelects,
  SimpleModal,
  CustomizedSwitches,
  OutLineButton,
  NewInput,
  TextInput,
  RangeSlider,
} from "@components";
import { CounterAtion, ProfileAction } from "@temp/store/actions";
import Grid from "@mui/material//Grid";
import { TitleLayout } from "@components";
import { LOAD_TYPE } from "@temp/constants";
import OutlineButton from "@temp/components/OutLineButton";
import "../style.scss";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { showMessage } from "@temp/utils/helper";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;

const SwitchableWithText = (props: any) => {
  return (
    <Grid
      container
      spacing={1}
      style={{ marginBottom: 0, alignItems: "center" }}
    >
      <Grid
        item
        xs={10}
        style={{ display: "flex" }}
      >
        {props?.icon ? <img src={props?.icon} width="16px" height="16px" /> : ''}
        <div style={{ marginLeft: 5, marginTop: 0 }}>{props?.text}</div>
      </Grid>

      <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginTop: 0 }}>
          <CustomizedSwitches {...props} />
        </div>
      </Grid>
    </Grid>
  );
};

let timmerRef_weight_inc = null
let timmerRef_weight_dec = null

const initial_values = {
  load_name: "",
  cargo_description: "",
  ref_no: "",
  is_shared_vehicle_not_allowed: false,
}
const category_values = {
  is_twin_load: false,
  is_out_of_guage: false,
  is_sidewall: false,
  is_boxed: false,
  is_shipper_owned_container: false,
  package_type_other: "",
  is_loading_required: false,
  loading_requirements: '',
  is_unloading_required: false,
  unloading_requirements: '',
  is_genset: false,
  required_temperature: [0, 0],

}


export const SelectVehicleContract: FC = () => {
  var element = document.getElementById("myDIV");
  const history = useHistory();
  const dispatch = useDispatch();
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const load_type = postLoadMeta.load_types.find((val: object) => val.id === PostLoad.load_type);
  const [temp, setTemp] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showContainerModal, setShowContainerModal] = useState(false);
  const [containerType, setContainerType] = React.useState(null);
  const [selectVehicleTypeObj, setSelectVehicleTypeObj] = React.useState(null);
  const [addedVehiclesTemp, setAddedVehicles] = useState([]);
  const [max_vehicle_weight, set_max_vehicle_weight] = useState(1)
  const [vehicle_quantity, set_vehicle_quantity] = useState("")
  const [initialValues, setInitialValues] = useState(initial_values);
  const [categoryInitialValues, setCategoryInitialValues] = useState(category_values);
  

  let allVehicleTypes = postLoadMeta?.vehicle_types.filter((val) => val.load_type === PostLoad.load_type);
  let allContainerType = postLoadMeta.container_types;

  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
  
  

  useEffect(() => {
    setInitialValues({ ...initialValues, cargo_description: PostLoad.vehicleScreen?.values?.cargo_description })
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = (event.target as HTMLInputElement).value;
    let typeVehicle = allVehicleTypes.find(
      (val) => Number(val.id) === Number(id)
    );
    setShowModal(false);
    setSelectVehicleTypeObj(typeVehicle);
    dispatch(CounterAtion.setPostloadCounterData({ max_vehicle_weight: 1 }));
    set_max_vehicle_weight(1);
    // setCategoryInitialValues({
    //   is_genset: false,
    //   required_temperature:[0,0]
    // })
  };

  

  const [valueSelectedOnce,setValueSelectedOnce ] = useState(false)

  const handleChangeContainer = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let id = (event.target as HTMLInputElement).value;
    let containerTypes = allContainerType.find(
      (val) => Number(val.id) === Number(id)
    );
    setShowContainerModal(false);
    setContainerType(containerTypes);
  };

  const handleDelete = (ind: number) => {
    let tempAddedVehicles = addedVehiclesTemp.filter((val, index) => index !== ind);
    setAddedVehicles([...tempAddedVehicles]);
  };

  const handleAddEditCategory = () => {
    setValueSelectedOnce(true)
    let added = {
      selectVehicleTypeObj,
      vehicle_quantity,
      containerType,
      max_vehicle_weight,
      values: categoryInitialValues,
      packageType: null,
    };
    let new_data = [...addedVehiclesTemp]
    if (editMode) {
      new_data[currentIndex] = added
      setEditMode(false);
    } else {
      if (addedVehiclesTemp.length >= 5) {
        showMessage.current({
          message: "You can add Maximum 5 categories",
          status: 'error'
        })
      } else {
        new_data.push(added)
      }
    }
    setAddedVehicles(new_data)
    set_vehicle_quantity("");
    set_max_vehicle_weight(1)
    setContainerType(null);
    setSelectVehicleTypeObj(null);
    setCategoryInitialValues(category_values)
    // setCategoryInitialValues({
    //   ...categoryInitialValues,
    //   required_temperature: [0, 0],
    //   is_genset: false

    // })
  }

  const validate = () => {
    if (selectVehicleTypeObj && containerType && max_vehicle_weight) {
      return true
    } else {
      return false
    }
  }

  const handleContinue = () => {
    let new_data = [...addedVehiclesTemp]
    let added = {
      selectVehicleTypeObj,
      vehicle_quantity,
      containerType,
      max_vehicle_weight,
      values: categoryInitialValues,
      packageType: null,
    };
    if (editMode) {
      if (validate()) {
        new_data[currentIndex] = added
      }
    } else {
      if (validate()) {
        new_data.push(added)
      }
    }
    let vehicleScreen = {
      values: initialValues,
      addedVehicles: [],
      addedVehiclesTemp: new_data?.map((item, index) => {
        return {
          ...item,
          vehicle_index: index
        }
      })
      ,
    }
    
    dispatch(
      ProfileAction.postLoadData({
        vehicleScreen
      })
    );
    if (load_type.title === LOAD_TYPE.fcl) {
      history.push("/contract-vehicle-display-new");
    } else {
      history.push("/FCL_BULK_ADDITIONAL_DETAILS");
    }
    setAddedVehicles(new_data)
    set_vehicle_quantity("");
    set_max_vehicle_weight(1)
    setContainerType(null);
    setSelectVehicleTypeObj(null);
    setCategoryInitialValues(category_values)
  }

  const handleVehicleCategoryEdit = (index) => {
    setCurrentIndex(index);
    setEditMode(true);
    setSelectVehicleTypeObj(addedVehiclesTemp[index]?.selectVehicleTypeObj);
    setContainerType(addedVehiclesTemp[index]?.containerType);
    setCategoryInitialValues(addedVehiclesTemp[index].values)
    set_vehicle_quantity(addedVehiclesTemp[index]?.vehicle_quantity)
    set_max_vehicle_weight(Number(addedVehiclesTemp[index]?.max_vehicle_weight))
    setInitialValues
    if (
      addedVehiclesTemp[index]?.values?.is_twin_load &&
      addedVehiclesTemp[index]?.selectVehicleTypeObj?.title === "40 ft Trailer"
    ) {
      setTemp(
        allVehicleTypes.find((val) => val.title === "40 ft Trailer")
          .min_capacity
      );
    }
  };

  const handleDisabilityContinue = () => {
    if ((addedVehiclesTemp.length && (containerType?.provide_temperature_control ? (categoryInitialValues?.required_temperature[0] !== 0
      || categoryInitialValues?.required_temperature[1] > 0)
      : true))
      || ((containerType && selectVehicleTypeObj && max_vehicle_weight)  
    && (containerType?.provide_temperature_control ? (categoryInitialValues?.required_temperature[0] !== 0
      || categoryInitialValues?.required_temperature[1] > 0)
      : true))
    )
    {
      return false
    } else {
      return true
    }
  };

  useEffect(() => {
    
    if (PostLoad?.location.length) {
      if (PostLoad?.vehicleScreen) {
        setAddedVehicles(PostLoad?.vehicleScreen?.addedVehiclesTemp)
      }
    }
  }, [])

  const myRef = useRef(null)
  return (
    <TitleLayout
      pageNumber={3}
      progress={30}
      title={`Select Vehicles`}
    >
      <div className="selectVehicleMainWrap">
        <div ref={myRef} id={"myDIV"} className="selectVehicleContentWrap selectVehicleWrapper">
          <div>
            <FadeInComponent>
              <div style={{
                marginBottom: 12,
                paddingTop: 8,
                paddingBottom: 8,
              }} className="titleSection">
                {
                  addedVehiclesTemp?.length < 1 ? `Details of Category` : `Details of Category ${addedVehiclesTemp?.length + 1}`
                }
              </div>
            </FadeInComponent>


            <FadeInComponent>
              <div className="sectionBox">
                <div className="titleSection">Select Vehicle Type</div>
                <div onClick={() => { }}>
                  <CustomizedSelects
                    classProp="mb-0"
                    selectedValue={selectVehicleTypeObj?.id}
                    values={selectVehicleTypeObj ? allVehicleTypes : []}
                    disabled={true}
                    modalState={true}
                    placeholder={"Select Vehicle Type"}
                    handleModal={(value: boolean) => {
                      setShowModal(value);
                    }}
                  >
                  </CustomizedSelects>
                </div>

                <div style={{ marginTop: 20 }} className="titleSection">
                  Container Type
                </div>
                <CustomizedSelects
                  classProp="mb-0"
                  selectedValue={containerType?.id}
                  values={containerType ? allContainerType : []}
                  disabled={true}
                  modalState={true}
                  placeholder={"Select Container Type"}
                  handleModal={(value: boolean) => {
                    setShowContainerModal(value);
                  }}
                >
                </CustomizedSelects>
              </div>
            </FadeInComponent>

            <FadeInComponent>
              <div className="sectionBox">
                <div style={{ pointerEvents: !selectVehicleTypeObj ? "none" : "auto" }}>
                  <VehicleIncDec
                    className="labelWithIncrementField"
                    text="Max Cargo Weight Per Vehicle (Tons)"
                    value={max_vehicle_weight}
                    name={"max_vehicle_weight"}
                    from_containerized={true}
                    withInputBox={true}
                    updateValue={(val) => {
                      set_max_vehicle_weight(val)
                    }}
                    onTouchStart={(type) => {
                      if (type == 'inc') {
                        timmerRef_weight_inc = setInterval(() => {
                          set_max_vehicle_weight((prev) => prev + 1)
                        }, 200);
                      } else {
                        if (max_vehicle_weight > 0) {
                          timmerRef_weight_dec = setInterval(() => {
                            set_max_vehicle_weight((prev) => {
                              if (prev > 1) {
                                return prev - 1
                              } else {
                                return 1
                              }
                            })
                          }, 200);
                        }
                      }
                    }}
                    onTouchEnd={() => {
                      clearInterval(timmerRef_weight_inc);
                      clearInterval(timmerRef_weight_dec);
                    }}
                    onMouseDown={(type) => {
                      if (type == 'inc') {
                        set_max_vehicle_weight((prev) => prev + 1)
                        timmerRef_weight_inc = setInterval(() => {
                          set_max_vehicle_weight((prev) => prev + 1)
                        }, 200);
                      } else {
                        set_max_vehicle_weight((prev) => {
                          if (prev > 1) {
                            return prev - 1
                          } else {
                            return 1
                          }
                        })
                        timmerRef_weight_dec = setInterval(() => {
                          set_max_vehicle_weight((prev) => {
                            if (prev > 1) {
                              return prev - 1
                            } else {
                              return 1
                            }
                          })
                        }, 200);
                      }
                    }}
                    onMouseUp={() => {
                      clearInterval(timmerRef_weight_inc);
                      clearInterval(timmerRef_weight_dec);
                    }}
                    disableMin={
                      max_vehicle_weight
                        ? max_vehicle_weight > 0
                          ? false
                          : true
                        : true
                    }
                    disabledMax={false}
                  />
                </div>

                <div style={{ pointerEvents: !selectVehicleTypeObj ? "none" : "auto" }}>
                  <div className="inputField">
                    <div className="label">Estimated Number of Vehicles</div>
                    <div className="field">
                      <NewInput
                        onChange={(e) => {
                          let reg = new RegExp("^[0-9]*$");
                          let value = e.target?.value.replace(/[^\d]/g, "");
                          if (value && reg.test(value) && Number(value) > 0) {
                            if (value?.length <= 5) {
                              set_vehicle_quantity(Number(value))
                            }
                          } else {
                            e.target.value = null;
                            set_vehicle_quantity(null)
                          }
                        }}
                        value={vehicle_quantity}
                        placeholder={"(Optional)"}
                      />
                    </div>
                  </div>
                </div>

                <>

                  {/* for containerized  */}


                  {
                    containerType?.provide_temperature_control == 1 &&
                    <div style={{ width: '100%' }}>
                      <div className="inputField" >
                        <div style={{ width: "100%", height: 25 }} className="flex_row_div">
                          <div className="label">
                            Required Temperature</div>
                          {/* {
                            categoryInitialValues?.required_temperature?.length ?
                              <>
                                <div className="label">{`Min: ${categoryInitialValues?.required_temperature[0]}`} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                                <div className="label">{`Max: ${categoryInitialValues?.required_temperature[1]}`} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                              </>
                              : null
                          } */}
                        </div>
                        <div className="field rangeSliderWrap">
                          <RangeSlider
                            min={Number(metaData?.temperature_range?.min)}
                            max={Number(metaData?.temperature_range?.max)}
                            // step={1}
                            value={categoryInitialValues?.required_temperature}
                            orientation={'horizontal'}
                            tooltip={true}
                            onChange={(event, newValue) => {
                              
                              
                              setCategoryInitialValues({
                                ...categoryInitialValues,
                                required_temperature: newValue,
                              });
                            }}

                          />
                        </div>
                      </div>
                    </div>
                  }

                  {containerType?.provide_genset == 1 ? (
                    <div style={{ marginTop: '1rem', width: '100%' }}>
                      <SimpleCheckBox
                        id={"is_genset"}
                        labelName="is_genset"
                        name="is_genset"
                        title="Genset Required"
                        handleChange={(e) => {
                          setCategoryInitialValues({
                            ...categoryInitialValues,
                            is_genset: e.target.checked
                          })
                        }}
                        checked={categoryInitialValues?.is_genset ? true : false}
                      />
                    </div>
                  ) : null}

                </>

                {selectVehicleTypeObj?.is_boxed == 1 && (
                  <div style={{ display: "flex" }}>
                    <SimpleCheckBox
                      title="Box"
                      checked={categoryInitialValues.is_boxed}
                      handleChange={(e) => {
                        setCategoryInitialValues({
                          ...categoryInitialValues,
                          is_boxed: e.target.checked
                        })
                      }}
                      labelName="is_boxed"
                      id="is_boxed"
                    />
                  </div>
                )}

                {selectVehicleTypeObj?.is_sidewall == 1 && (
                  <div style={{ display: "flex", marginTop: 16 }}>
                    <SimpleCheckBox
                      checked={categoryInitialValues.is_sidewall}
                      handleChange={(e) => {
                        setCategoryInitialValues({
                          ...categoryInitialValues,
                          is_sidewall: e.target.checked
                        })
                      }}
                      labelName="is_sidewall"
                      id="is_sidewall"
                      title="Side wall"
                    />
                  </div>
                )}

                {selectVehicleTypeObj?.is_support_twin_load === 1 && (
                  <div style={{ marginTop: 16 }}>
                    <SwitchableWithText
                      id={"is_twin_load"}
                      handleChange={(e) => {
                        if (
                          e.target.checked &&
                          selectVehicleTypeObj?.title === "40 ft Trailer"
                        ) {
                          setTemp(selectVehicleTypeObj.min_capacity);
                          setSelectVehicleTypeObj({
                            ...selectVehicleTypeObj,
                            min_capacity: 45,
                          });
                        }
                        if (
                          !e.target.checked &&
                          selectVehicleTypeObj?.title === "40 ft Trailer"
                        ) {
                          selectVehicleTypeObj.min_capacity = temp;
                          setSelectVehicleTypeObj(selectVehicleTypeObj);
                          setTemp(null);
                        }
                        setCategoryInitialValues({
                          ...categoryInitialValues,
                          is_twin_load: e.target.checked
                        })
                      }}
                      name="is_twin_load"
                      text="Twin Load (2x20 Ft)"
                      checked={categoryInitialValues.is_twin_load}
                    />
                  </div>
                )}

                <div style={{ pointerEvents: !containerType ? "none" : "auto" }}>
                  {(containerType && containerType.is_guage == 1) && (
                    <div>
                      <SwitchableWithText
                        checked={categoryInitialValues.is_out_of_guage}
                        handleChange={(e) => {
                          setCategoryInitialValues({
                            ...categoryInitialValues,
                            is_out_of_guage: e.target.checked
                          })
                        }}
                        name="is_out_of_guage"
                        id="is_out_of_guage"
                        text={
                          load_type.title === "BULK"
                            ? "Heavy Lift / Out of Gauge"
                            : "Out of Gauge"
                        }
                      />
                    </div>
                  )}

                  {load_type?.title === LOAD_TYPE.fcl && (
                    <SwitchableWithText
                      id={"is_shipper_owned_container"}
                      handleChange={(e) => {
                        setCategoryInitialValues({
                          ...categoryInitialValues,
                          is_shipper_owned_container: e.target.checked
                        })
                      }}
                      name="is_shipper_owned_container"
                      text="Shipper Owned Container(SOC)"
                      checked={
                        categoryInitialValues.is_shipper_owned_container
                      }
                    />
                  )}
                </div>

                <FadeInComponent>
                  <div>
                    <Grid item xs={12}>
                      <SwitchableWithText
                        id={"is_loading_required"}
                        handleChange={(e) => {
                          setCategoryInitialValues({
                            ...categoryInitialValues,
                            is_loading_required: e.target.checked
                          })
                        }}
                        checked={categoryInitialValues.is_loading_required}
                        name="is_loading_required"
                        text="Loading Required"
                      />
                    </Grid>
                    {categoryInitialValues?.is_loading_required && (
                      <div className="inputField">
                        <div className="label">
                          Specify Labour / Equipment Requirement
                        </div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              setCategoryInitialValues({
                                ...categoryInitialValues,
                                loading_requirements: e.target.value
                              })
                            }}
                            type={"text"}
                            value={categoryInitialValues?.loading_requirements}
                            id={"loading_requirements"}
                            name="loading_requirements"
                            style={{ height: 35, width: "100%" }}
                            aria-describedby="standard-weight-helper-text"
                            placeholder="Optional"
                          />
                        </div>
                      </div>
                    )}
                    <Grid item xs={12}>
                      <SwitchableWithText
                        id={"is_unloading_required"}
                        name="is_unloading_required"
                        checked={categoryInitialValues.is_unloading_required}
                        handleChange={(e) => {
                          setCategoryInitialValues({
                            ...categoryInitialValues,
                            is_unloading_required: e.target.checked
                          })
                        }}
                        text="Unloading Required"
                      />
                    </Grid>
                    {categoryInitialValues?.is_unloading_required && (
                      <div className="inputField">
                        <div className="label">
                          Specify Labour / Equipment Requirement
                        </div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              setCategoryInitialValues({
                                ...categoryInitialValues,
                                unloading_requirements: e.target.value
                              })
                            }}
                            type={"text"}
                            value={categoryInitialValues?.unloading_requirements}
                            id={"unloading_requirements"}
                            aria-describedby="standard-weight-helper-text"
                            className="rightFormField"
                            placeholder="Optional"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </FadeInComponent>




              </div>
              <div style={{ marginTop: 16, marginBottom: 16 }}>
                <OutLineButton
                  title={editMode ? "Update Category" : "Add another category"}
                  disabled={containerType && selectVehicleTypeObj && max_vehicle_weight 
                    && (containerType?.provide_temperature_control ? (categoryInitialValues?.required_temperature[0] !== 0
                      || categoryInitialValues?.required_temperature[1] > 0)
                      : true)
                    ? false : true}
                  onClick={handleAddEditCategory}
                />
              </div>
            </FadeInComponent>

          </div>
          {addedVehiclesTemp && !!addedVehiclesTemp.length && (
            <>
              <FadeInComponent>
                <VehicleTable
                  onClickDelete={(index: number) => {
                    handleDelete(index);
                  }}
                  tempQuoteBy={"vehicle"}
                  loadType={load_type.title}
                  addedVehicles={addedVehiclesTemp.sort((a, b) => a?.selectVehicleTypeObj?.title?.split(" ")[0] - b?.selectVehicleTypeObj?.title?.split(" ")[0])}
                  handleEdit={handleVehicleCategoryEdit}
                />
              </FadeInComponent>
              <div style={{ display: "none", alignItems: "center", justifyContent: "center" }}>
                <FadeInComponent>
                  <OutlineButton
                    title="Add another category"
                    onClick={() => {
                      element.scrollTop = 0
                    }}
                    buttonStyle={{ marginBottom: '1.5rem' }}
                  />
                </FadeInComponent>
              </div>
            </>
          )}
          <FadeInComponent>
            <div style={{ marginBottom: 10 }}>Cargo Description</div>
            <div className="textareaWrapper">
              <textarea
                style={{
                  outline: "none",
                }}
                maxLength={250}
                onChange={(e) => {
                  setInitialValues({
                    ...initialValues,
                    cargo_description: e.target.value
                  })
                }}
                value={initialValues?.cargo_description}
                id={"cargo_description"}
                name={"cargo_description"}
                placeholder="Optional"
              />
            </div>
          </FadeInComponent>
        </div>
        <PrimaryButton
          rightIcon
          title="CONTINUE"
          type="submit"
          disabled={handleDisabilityContinue()}
          onClick={(e) => {
            handleContinue()
          }}
        />
      </div>
      <SimpleModal
        showModal={showModal}
        selected={selectVehicleTypeObj?.id}
        title={"Select Vehicle Type"}
        setType={handleChange}
        handleModal={(value: boolean) => {
          setShowModal(value);
        }}
        values={allVehicleTypes}
      >
      </SimpleModal>
      <SimpleModal
        showModal={showContainerModal}
        selected={containerType?.id}
        title={"Select Container Type"}
        setType={handleChangeContainer}
        handleModal={(value: boolean) => {
          setShowContainerModal(value);
        }}
        values={allContainerType}
      >
      </SimpleModal>
    </TitleLayout>
  );
};