import { LOAD_TYPE } from "@temp/constants";
import { price_units_transalation } from "@temp/utils/helper";
import React from "react";
import "../LoadDetailPoster/style.scss";
import { RenderRTL } from "./RenderRTL";
var numeral = require("numeral");

export const TwoColumnRTLWeight = ({ loadDetail, type, forward = false, is_recipient }) => {
  return (
    <div>
      <RenderRTL
        title={"Packaging Type"}
        sep={"/"}
        urdutitle={"پیکیجنگ کی قسم"}
        val={loadDetail?.package_type?.title == "Other" ?
          `Other(${loadDetail?.package_type_other})` : loadDetail?.package_type?.title
        }
        is_recipient={is_recipient}
      />

      {
        // loadDetail?.preferred_vehicle && 
        loadDetail?.load_type?.title == LOAD_TYPE.bulk ?
          <RenderRTL
            title={"Vehicle Type"}
            sep={"/"}
            urdutitle={"گاڑی کی قسم"}
            val={loadDetail?.preferred_vehicle?.title}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.is_loading_required && loadDetail?.load_type?.title == LOAD_TYPE.bulk ?
          <RenderRTL
            title={"Loading Required"}
            sep={"/"}
            urdutitle={"لوڈنگ"}
            val={"Yes"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.is_loading_required && loadDetail?.loading_requirements && loadDetail?.load_type?.title == LOAD_TYPE.bulk ?
          <RenderRTL
            title={"Specify Labour / Loading Requirement"}
            sep={"/"}
            urdutitle={"لوڈنگ درکار ہے۔"}
            val={loadDetail?.loading_requirements}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.is_unloading_required && loadDetail?.load_type?.title == LOAD_TYPE.bulk ?
          <RenderRTL
            title={"Unloading Required"}
            sep={"/"}
            urdutitle={"ان لوڈنگ"}
            val={"Yes"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.is_unloading_required && loadDetail?.unloading_requirements && loadDetail?.load_type?.title == LOAD_TYPE.bulk ?
          <RenderRTL
            title={"Specify Labour / Unloading Requirement"}
            sep={"/"}
            urdutitle={"ان لوڈنگ درکار ہے۔"}
            val={loadDetail?.unloading_requirements}
            is_recipient={is_recipient}
          /> : null
      }

      {
        // loadDetail?.detention_free_time ?
        <RenderRTL
          title={"Free Time Required"}
          sep={"/"}
          urdutitle={"فری وقت درکار "}
          val={loadDetail?.detention_free_time + " hrs"}
          is_recipient={is_recipient}
        />
        // : null
      }

      {
        loadDetail?.cargo_liter ?
          <RenderRTL
            title={"Liter"}
            sep={"/"}
            urdutitle={"لیٹر"}
            val={`${numeral(loadDetail?.cargo_liter).format('0,0.00')} liter`}
            is_recipient={is_recipient}
          /> : null
      }

      {/* {
        loadDetail?.required_temperature_min && loadDetail?.required_temperature_max ?
          <RenderRTL
            title={"Required Temperature"}
            sep={"/"}
            urdutitle={"مطلوبہ درجہ حرارت"}
            val={`Min: ${loadDetail?.required_temperature_min}`}
            val2={`Max: ${loadDetail?.required_temperature_max}`}
            is_recipient={is_recipient}
          /> : null
      } */}
      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk || loadDetail?.load_type?.title == LOAD_TYPE.bulk) && !forward && loadDetail?.transaction_type?.title == "Offer" ?
          <RenderRTL
            title={`Price / ${loadDetail?.price_unit || "Freight"} (Without GST)`}
            sep={"/"}
            urdutitle={`ریٹ / ${price_units_transalation[loadDetail?.price_unit] || "فریٹ"} (GST کے بغیر)`}
            val={`${numeral(loadDetail?.price_per_freight).format('0,0.00')} PKR`}
            is_recipient={is_recipient}
          /> : null
      }

      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk) && !forward && loadDetail?.transaction_type?.title == "Offer" && loadDetail?.detention_rate > 0 ?
          <RenderRTL
            title={`Detention Rate (Without GST)`}
            sep={"/"}
            urdutitle={"ڈیٹنشن ریٹ (GST کے بغیر)"}
            val={`${numeral(loadDetail?.detention_rate).format('0,0.00')} PKR`}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.transaction_type?.title == "Offer" ?
          <>
            {
              loadDetail?.detentionRates.map((itm) => {
                return (
                  <RenderRTL
                    title={itm?.vehicle?.title}
                    val={`${itm?.rate} PKR (Without GST)`}
                    is_recipient={is_recipient}
                  />
                )
              })
            }
          </> : null
      }

      {
        loadDetail?.max_bid && (loadDetail?.max_bid != 0) && !forward && loadDetail?.transaction_type?.title !== "Offer" ?
          <RenderRTL
            title={"Max bid (Without GST)"}
            sep={"/"}
            urdutitle={"زیادہ سے زیادہ بولی  (GST کے بغیر)"}
            val={numeral(Number(loadDetail?.max_bid)).format("0,0.00") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.transaction_type?.title == "Offer" && !forward && loadDetail?.detention_additional_charges ?
          <RenderRTL
            title={"Other Additional Charges (if applicable)"}
            sep={"/"}
            urdutitle={"اضافی چارجز ( اگر لاگو ہوں )"}
            val={loadDetail?.detention_additional_charges}
            is_recipient={is_recipient}
          />
          : null
      }

      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk || loadDetail?.load_type?.title == LOAD_TYPE.bulk) && !forward && loadDetail?.is_loading_required && loadDetail?.transaction_type?.title == "Offer" ?
          <RenderRTL
            title={`Loading Price / ${loadDetail?.price_unit} (Without GST)`}
            sep={"/"}
            urdutitle={`لوڈنگ ریٹ / ${price_units_transalation[loadDetail?.price_unit]} (GST کے بغیر)`}
            val={numeral(Number(loadDetail?.loading_price_per_freight)).format("0,0.00") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk || loadDetail?.load_type?.title == LOAD_TYPE.bulk) && !forward && loadDetail?.is_unloading_required && loadDetail?.transaction_type?.title == "Offer" ?
          <RenderRTL
            title={`UnLoading Price / ${loadDetail?.price_unit} (Without GST)`}
            sep={"/"}
            urdutitle={`ان لوڈنگ ریٹ / ${price_units_transalation[loadDetail?.price_unit]} (GST کے بغیر)`}
            val={numeral(Number(loadDetail?.unloading_price_per_freight)).format("0,0.00") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.transaction_type?.title == "Offer" && !forward ?
          <RenderRTL
            title={"Total Offer Price (Without GST)"}
            sep={"/"}
            urdutitle={"کل پیشکش ریٹ (GST کے بغیر)"}
            val={numeral(Number(loadDetail?.total_offer_price)).format("0,0.00") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }
    </div>
  );
};
