import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { LOAD_TYPE } from "@temp/constants";
import dayjs from "dayjs";
var getInquiryType = function getInquiryType(id, arr) {
  return arr === null || arr === void 0 ? void 0 : arr.find(function (val) {
    return (val === null || val === void 0 ? void 0 : val.id) === id;
  });
};
var getPackageById = function getPackageById(id, arr) {
  return arr === null || arr === void 0 ? void 0 : arr.find(function (val) {
    return val.id === id;
  });
};
var getPreferredVehicleById = function getPreferredVehicleById(id, arr) {
  return arr === null || arr === void 0 ? void 0 : arr.find(function (val) {
    return val.id === id;
  });
};
var convertArrayToObject = function convertArrayToObject(arr) {
  var data = arr === null || arr === void 0 ? void 0 : arr.map(function (cat) {
    return {
      cargo_volume: (cat === null || cat === void 0 ? void 0 : cat.cargo_volume) || null,
      gross_weight: (cat === null || cat === void 0 ? void 0 : cat.gross_weight) || null,
      total_freight_ton: (cat === null || cat === void 0 ? void 0 : cat.total_freight_ton) || null,
      no_of_packages: (cat === null || cat === void 0 ? void 0 : cat.no_of_packages) || null,
      cargo_liter: cat === null || cat === void 0 ? void 0 : cat.cargo_liter,
      is_boxed: cat !== null && cat !== void 0 && cat.is_boxed ? true : false,
      is_loading_required: cat !== null && cat !== void 0 && cat.is_loading_required ? true : false,
      is_sidewall: cat !== null && cat !== void 0 && cat.is_sidewall ? true : false,
      is_any_suitable_vehicle: cat !== null && cat !== void 0 && cat.is_any_suitable_vehicle ? true : false,
      is_flat_bed_vehicle: cat !== null && cat !== void 0 && cat.is_flat_bed_vehicle ? true : false,
      is_low_bed_vehicle: cat !== null && cat !== void 0 && cat.is_low_bed_vehicle ? true : false,
      is_dum_truck_vehicle: cat !== null && cat !== void 0 && cat.is_dum_truck_vehicle ? true : false,
      is_unloading_required: cat !== null && cat !== void 0 && cat.is_unloading_required ? true : false,
      loading_requirements: cat === null || cat === void 0 ? void 0 : cat.loading_requirements,
      packageType: cat === null || cat === void 0 ? void 0 : cat.package_type,
      PreferredVehicleType: cat === null || cat === void 0 ? void 0 : cat.preferred_vehicle,
      unloading_requirements: cat === null || cat === void 0 ? void 0 : cat.unloading_requirements
    };
  });
  return (data === null || data === void 0 ? void 0 : data.length) > 0 ? data[0] : null;
};
export var convertDataForFclAndBulkLoadDetailRedux = function convertDataForFclAndBulkLoadDetailRedux(loadDetail, packageType, load_type, inquiry_type, PreferredVehicleType) {
  var from = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
  var loadType = arguments.length > 6 ? arguments[6] : undefined;
  if (loadDetail) {
    var _loadDetail$recipient, _loadDetail$instructi, _loadDetail$categorie, _loadDetail$categorie2, _loadDetail$locations, _loadDetail$additiona, _loadDetail$additiona2, _getInquiryType, _loadDetail$inquiry_t, _loadDetail$inquiry_t2;
    var new_docs = {};
    var temp_bill_of_lading = [];
    var temp_packing_list = [];
    var temp_other_uploads = [];
    loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.documents.forEach(function (item) {
      if ((item === null || item === void 0 ? void 0 : item.type_name) == "bill_of_ladings" || (item === null || item === void 0 ? void 0 : item.type_name) == "bill_of_lading") {
        temp_bill_of_lading.push({
          file_name: item.file_name,
          cloudinary_url: item.path,
          file_url: item.relative_path,
          mime_type: item.mime_type
        });
      } else if ((item === null || item === void 0 ? void 0 : item.type_name) == "packing_list" || (item === null || item === void 0 ? void 0 : item.type_name) == "packing_list") {
        temp_packing_list.push({
          file_name: item.file_name,
          cloudinary_url: item.path,
          file_url: item.relative_path,
          mime_type: item.mime_type
        });
      } else {
        if (temp_other_uploads.find(function (itm) {
          return itm.title == item.other_type;
        })) {
          temp_other_uploads[temp_other_uploads.findIndex(function (it) {
            return it.title == item.other_type;
          })].files.push({
            file_name: item.file_name,
            cloudinary_url: item.path,
            file_url: item.relative_path,
            mime_type: item.mime_type
          });
        } else {
          temp_other_uploads.push({
            title: item === null || item === void 0 ? void 0 : item.other_type,
            files: [{
              file_name: item.file_name,
              cloudinary_url: item.path,
              file_url: item.relative_path,
              mime_type: item.mime_type
            }]
          });
        }
      }
    });
    new_docs["bill_of_ladings"] = temp_bill_of_lading;
    new_docs["packing_lists"] = temp_packing_list;
    new_docs["other_uploads"] = temp_other_uploads;
    var history_recipients = [];
    var recipients = [];
    loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$recipient = loadDetail.recipients) === null || _loadDetail$recipient === void 0 ? void 0 : _loadDetail$recipient.forEach(function (recipient) {
      var find = recipients.find(function (item) {
        var _recipient$recipient;
        return (item === null || item === void 0 ? void 0 : item.phone_no) == (recipient === null || recipient === void 0 ? void 0 : (_recipient$recipient = recipient.recipient) === null || _recipient$recipient === void 0 ? void 0 : _recipient$recipient.phone_no);
      });
      if (find == undefined) {
        var _recipient$recipient2, _recipient$recipient3;
        recipients.push({
          phone_no: recipient === null || recipient === void 0 ? void 0 : (_recipient$recipient2 = recipient.recipient) === null || _recipient$recipient2 === void 0 ? void 0 : _recipient$recipient2.phone_no,
          phone_code: recipient === null || recipient === void 0 ? void 0 : (_recipient$recipient3 = recipient.recipient) === null || _recipient$recipient3 === void 0 ? void 0 : _recipient$recipient3.phone_code
        });
        history_recipients.push(_objectSpread({}, recipient));
      }
    });
    if (from == 'forward') {
      var _loadDetail$poster, _loadDetail$poster2;
      recipients.push({
        phone_no: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$poster = loadDetail.poster) === null || _loadDetail$poster === void 0 ? void 0 : _loadDetail$poster.phone_no,
        phone_code: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$poster2 = loadDetail.poster) === null || _loadDetail$poster2 === void 0 ? void 0 : _loadDetail$poster2.phone_code
      });
    }
    var draftData = {
      allocates: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.allocates,
      is_allocated: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_allocated,
      from: from,
      fromClone: true,
      load_id: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.id,
      title: loadDetail.title,
      inquiry_type: loadDetail.inquiry_type.id,
      load_type: loadDetail.load_type.id,
      additional_requirements: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.additional_requirements,
      instructions: loadDetail !== null && loadDetail !== void 0 && loadDetail.instructions ? loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.instructions : null,
      instructions_voice_note: loadDetail !== null && loadDetail !== void 0 && loadDetail.instructions_voice_note ? {
        cloudinary_url: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.instructions_voice_note,
        file_url: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$instructi = loadDetail.instructions_voice_note_object) === null || _loadDetail$instructi === void 0 ? void 0 : _loadDetail$instructi.file_url
      } : null,
      cargo_description: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.cargo_description,
      vehicleScreen: {
        values: {
          load_name: loadDetail.title,
          ref_no: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.ref_no,
          cargo_description: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.cargo_description,
          gross_weight: loadDetail.gross_weight,
          gross_weight_in_kgs: loadDetail.gross_weight ? Number(loadDetail.gross_weight * 1000) : null,
          cargo_liter: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.cargo_liter,
          cargo_volume: loadDetail.cargo_volume,
          no_of_packages: loadDetail.no_of_packages,
          total_freight_ton: loadDetail.total_freight_ton,
          is_shared_vehicle_not_allowed: loadDetail.is_shared_vehicle_not_allowed,
          quote_by: loadDetail.quote_by,
          is_loading_required: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_loading_required ? true : false,
          is_unloading_required: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_unloading_required ? true : false,
          loading_requirements: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.loading_requirements,
          unloading_requirements: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.unloading_requirements,
          is_boxed: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_boxed ? true : false,
          is_sidewall: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_sidewall ? true : false,
          is_any_suitable_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_any_suitable_vehicle ? true : false,
          is_flat_bed_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_flat_bed_vehicle ? true : false,
          is_low_bed_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_low_bed_vehicle ? true : false,
          is_dum_truck_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_dum_truck_vehicle ? true : false,
          price: (loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.price_per_freight) > 0 ? loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.price_per_freight : null,
          detention_free_time: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.detention_free_time,
          detention_included_transport: loadDetail !== null && loadDetail !== void 0 && loadDetail.detention_included_transport ? true : false,
          vehicle_detention_rates: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.detentionRates,
          detention_rate: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.detention_rate,
          package_type_other: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.package_type_other,
          total_amount: (loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.total_offer_price) > 0 ? Number(loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.total_offer_price) : null,
          // (Number(loadDetail?.price_per_freight) *
          //   Number(loadDetail.total_freight_ton)) + (Number(loadDetail?.loading_price_per_freight) + Number(loadDetail?.unloading_price_per_freight)),
          loading_price: loadDetail.loading_price_per_freight > 0 ? loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.loading_price_per_freight : null,
          unloading_price: loadDetail.unloading_price_per_freight > 0 ? loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.unloading_price_per_freight : null,
          max_bid: loadDetail !== null && loadDetail !== void 0 && loadDetail.max_bid && (loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.max_bid) != 0 ? loadDetail.max_bid : null,
          price_unit: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.price_unit
        },
        addedVehicles: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$categorie = loadDetail.categories) === null || _loadDetail$categorie === void 0 ? void 0 : _loadDetail$categorie.map(function (item) {
          var _item$recipients, _item$package_type;
          item === null || item === void 0 ? void 0 : (_item$recipients = item.recipients) === null || _item$recipients === void 0 ? void 0 : _item$recipients.forEach(function (recipient) {
            var find = recipients === null || recipients === void 0 ? void 0 : recipients.find(function (item) {
              var _recipient$recipient4;
              return (item === null || item === void 0 ? void 0 : item.phone_no) == (recipient === null || recipient === void 0 ? void 0 : (_recipient$recipient4 = recipient.recipient) === null || _recipient$recipient4 === void 0 ? void 0 : _recipient$recipient4.phone_no);
            });
            if (find == undefined) {
              var _recipient$recipient5, _recipient$recipient6;
              recipients.push({
                phone_no: recipient === null || recipient === void 0 ? void 0 : (_recipient$recipient5 = recipient.recipient) === null || _recipient$recipient5 === void 0 ? void 0 : _recipient$recipient5.phone_no,
                phone_code: recipient === null || recipient === void 0 ? void 0 : (_recipient$recipient6 = recipient.recipient) === null || _recipient$recipient6 === void 0 ? void 0 : _recipient$recipient6.phone_code
              });
              history_recipients.push(_objectSpread({}, recipient));
            }
          });
          return {
            selectVehicleTypeObj: _objectSpread({}, item === null || item === void 0 ? void 0 : item.vehicle),
            vehicle_quantity: item !== null && item !== void 0 && item.vehicle_quantity ? item === null || item === void 0 ? void 0 : item.vehicle_quantity : "",
            containerType: _objectSpread({}, item === null || item === void 0 ? void 0 : item.container_type),
            packageType: item !== null && item !== void 0 && (_item$package_type = item.package_type) !== null && _item$package_type !== void 0 && _item$package_type.id ? _objectSpread({}, item === null || item === void 0 ? void 0 : item.package_type) : {
              id: item.package_type_id
            },
            package_type_other: item === null || item === void 0 ? void 0 : item.package_type_other,
            max_vehicle_weight: item === null || item === void 0 ? void 0 : item.max_vehicle_weight,
            total_amount: item === null || item === void 0 ? void 0 : item.total_offer_price,
            price: item === null || item === void 0 ? void 0 : item.offer_price_per_vehicle,
            loading_price: item === null || item === void 0 ? void 0 : item.loading_price,
            unloading_price: item === null || item === void 0 ? void 0 : item.unloading_price,
            is_loading_required: item !== null && item !== void 0 && item.is_loading_required ? true : false,
            is_unloading_required: item !== null && item !== void 0 && item.is_unloading_required ? true : false,
            loading_requirements: item === null || item === void 0 ? void 0 : item.loading_requirements,
            unloading_requirements: item === null || item === void 0 ? void 0 : item.unloading_requirements,
            vehicles_drivers_refs: item === null || item === void 0 ? void 0 : item.vehicles_drivers_refs,
            new_recipients: item === null || item === void 0 ? void 0 : item.recipients,
            values: {
              is_out_of_guage: item !== null && item !== void 0 && item.is_out_of_guage ? true : false,
              is_twin_load: item !== null && item !== void 0 && item.is_twin_load ? true : false,
              is_boxed: item !== null && item !== void 0 && item.is_boxed ? true : false,
              is_sidewall: item !== null && item !== void 0 && item.is_sidewall ? true : false,
              is_any_suitable_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_any_suitable_vehicle ? true : false,
              is_flat_bed_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_flat_bed_vehicle ? true : false,
              is_low_bed_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_low_bed_vehicle ? true : false,
              is_dum_truck_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_dum_truck_vehicle ? true : false,
              package_type_other: item === null || item === void 0 ? void 0 : item.package_type_other,
              loading_price: item === null || item === void 0 ? void 0 : item.loading_price,
              unloading_price: item === null || item === void 0 ? void 0 : item.unloading_price,
              is_loading_required: item !== null && item !== void 0 && item.is_loading_required ? true : false,
              is_unloading_required: item !== null && item !== void 0 && item.is_unloading_required ? true : false,
              loading_requirements: item === null || item === void 0 ? void 0 : item.loading_requirements,
              unloading_requirements: item === null || item === void 0 ? void 0 : item.unloading_requirements,
              required_temperature: [item !== null && item !== void 0 && item.required_temperature_min ? Number(item === null || item === void 0 ? void 0 : item.required_temperature_min) : 0, item !== null && item !== void 0 && item.required_temperature_max ? Number(item === null || item === void 0 ? void 0 : item.required_temperature_max) : 0],
              is_genset: item !== null && item !== void 0 && item.is_genset ? true : false
            },
            detention_free_time: item === null || item === void 0 ? void 0 : item.detention_free_time,
            detention_included_transport: item !== null && item !== void 0 && item.detention_included_transport ? true : false,
            detention_rate: item === null || item === void 0 ? void 0 : item.detention_rate,
            max_bid: item !== null && item !== void 0 && item.max_bid && (item === null || item === void 0 ? void 0 : item.max_bid) != 0 ? item.max_bid : null
          };
        }),
        added_units: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$categorie2 = loadDetail.categories) === null || _loadDetail$categorie2 === void 0 ? void 0 : _loadDetail$categorie2.map(function (item) {
          return {
            gross_weight: (item === null || item === void 0 ? void 0 : item.gross_weight) || null,
            cargo_volume: (item === null || item === void 0 ? void 0 : item.cargo_volume) || null,
            no_of_packages: (item === null || item === void 0 ? void 0 : item.no_of_packages) || null,
            total_freight_ton: item === null || item === void 0 ? void 0 : item.total_freight_ton,
            gross_weight_in_kgs: item !== null && item !== void 0 && item.gross_weight ? Number(item === null || item === void 0 ? void 0 : item.gross_weight) * 1000 : null,
            dimensions: item === null || item === void 0 ? void 0 : item.dimensions,
            unit_dimension_value: item === null || item === void 0 ? void 0 : item.unit_dimension_value,
            unit_quantity: item === null || item === void 0 ? void 0 : item.unit_quantity,
            unit_description: item === null || item === void 0 ? void 0 : item.unit_description,
            is_unit_basis: true,
            unit_edit_mode: false,
            unit_edit_id: null,
            detention_additional_charges: (item === null || item === void 0 ? void 0 : item.detention_additional_charges) || null,
            detention_free_time: item === null || item === void 0 ? void 0 : item.detention_free_time,
            detention_rate: item === null || item === void 0 ? void 0 : item.detention_rate,
            is_loading_required: item === null || item === void 0 ? void 0 : item.is_loading_required,
            is_unloading_required: item === null || item === void 0 ? void 0 : item.is_unloading_required,
            loading_requirements: item === null || item === void 0 ? void 0 : item.loading_requirements,
            unloading_requirements: item === null || item === void 0 ? void 0 : item.unloading_requirements,
            loading_price: item === null || item === void 0 ? void 0 : item.loading_price,
            unloading_price: item === null || item === void 0 ? void 0 : item.unloading_price,
            price: item === null || item === void 0 ? void 0 : item.offer_price_per_vehicle,
            total_amount: item === null || item === void 0 ? void 0 : item.total_offer_price,
            max_bid: item === null || item === void 0 ? void 0 : item.max_bid,
            vehicles_drivers_refs: item === null || item === void 0 ? void 0 : item.vehicles_drivers_refs,
            new_recipients: item === null || item === void 0 ? void 0 : item.recipients
          };
        }),
        is_unit_basis: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_unit_basis ? true : false,
        packageType: loadDetail.quote_by === "weight_volume" ? getPackageById(loadDetail.package_type_id, packageType) : null,
        PreferredVehicleType: loadDetail.quote_by === "weight_volume" ? getPreferredVehicleById(loadDetail.preferred_vehicle_id, PreferredVehicleType) : null,
        is_allow_partial_load: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_allow_partial_load ? true : false,
        vehicles_drivers_refs: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.vehicles_drivers_refs,
        new_recipients: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.recipients,
        cargo_description: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.cargo_description
      },
      additionalScreen: {
        is_hse_compliant_vehicle: loadDetail.is_hse_compliant_vehicle ? true : false,
        is_weightment_received_required: loadDetail.is_weightment_received_required ? true : false,
        weightment_received_name: loadDetail.weightment_received_name ? loadDetail.weightment_received_name : null,
        is_bonded_vehicle_required: loadDetail.is_bonded_vehicle_required ? true : false,
        is_tarpaulin_plastic_cover: loadDetail.is_tarpaulin_plastic_cover ? true : false,
        rate_validity_date: loadDetail !== null && loadDetail !== void 0 && loadDetail.rate_validity_date ? new Date(loadDetail.rate_validity_date) : null,
        is_covid_certf_req_driver: loadDetail.is_covid_certf_req_driver ? true : false,
        is_vehicle_tracking: loadDetail.is_vehicle_tracking ? true : false,
        is_shipper_owned_container: loadDetail.is_shipper_owned_container ? true : false,
        is_container_deposit_by_transporter: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_container_deposit_by_transporter ? true : false,
        is_quote_price_deposit_fee_inclusive: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_quote_price_deposit_fee_inclusive ? true : false,
        container_deposit_amount: loadDetail.container_deposit_amount ? loadDetail.container_deposit_amount : "",
        shipping_line_name: loadDetail.shipping_line_name ? loadDetail.shipping_line_name : "",
        is_cross_stuffing_required: loadDetail.is_cross_stuffing_required ? true : false,
        is_cross_stuffing_box_vehicle_required: loadDetail.is_cross_stuffing_box_vehicle_required ? true : false,
        cross_stuffing_no_of_packages: loadDetail.cross_stuffing_no_of_packages ? loadDetail.cross_stuffing_no_of_packages : "",
        is_advance_vehicle_driver_docs: loadDetail.is_advance_vehicle_driver_docs ? true : false,
        is_driver_negative_pcr_required: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_driver_negative_pcr_required ? true : false,
        driver_requried_document_details: loadDetail.driver_requried_document_details ? loadDetail.driver_requried_document_details : null,
        is_under_hook_delivery_required: loadDetail.is_under_hook_delivery_required ? true : false,
        under_hook_delivery: loadDetail.under_hook_delivery ? loadDetail.under_hook_delivery : null
      },
      detention_included_transport: loadDetail !== null && loadDetail !== void 0 && loadDetail.detention_included_transport ? true : false,
      transaction_type_id: loadDetail.transaction_type.id,
      location: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$locations = loadDetail.locations) === null || _loadDetail$locations === void 0 ? void 0 : _loadDetail$locations.map(function (item) {
        return {
          address: item === null || item === void 0 ? void 0 : item.raw_address,
          lat: item === null || item === void 0 ? void 0 : item.lat,
          lng: item === null || item === void 0 ? void 0 : item.long,
          description: item === null || item === void 0 ? void 0 : item.description,
          timestamp: null,
          title: item === null || item === void 0 ? void 0 : item.title,
          contact_name: item === null || item === void 0 ? void 0 : item.contact_name,
          contact_phone_no: item === null || item === void 0 ? void 0 : item.contact_phone_no,
          contact_phone_code: "92",
          city: item === null || item === void 0 ? void 0 : item.city,
          pickup_accordion: false,
          dropoff_accordion: false
        };
      }),
      additional_pickups: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$additiona = loadDetail.additional_pickups) === null || _loadDetail$additiona === void 0 ? void 0 : _loadDetail$additiona.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          lng: item === null || item === void 0 ? void 0 : item.long,
          address: item === null || item === void 0 ? void 0 : item.raw_address,
          pickup_accordion: false,
          dropoff_accordion: false
        });
      }),
      additional_dropoffs: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$additiona2 = loadDetail.additional_dropoffs) === null || _loadDetail$additiona2 === void 0 ? void 0 : _loadDetail$additiona2.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          lng: item === null || item === void 0 ? void 0 : item.long,
          address: item === null || item === void 0 ? void 0 : item.raw_address
        });
      }),
      responseDeadline: from == "clone" ? null : {
        //   pickupDate: new Date(loadDetail.pickup_date),
        //   pickupTime: loadDetail?.pickup_time ? new Date(`${loadDetail.pickup_date} ${loadDetail.pickup_time}`) : null,
        //   responseDate: new Date(loadDetail.deadline_at),
        //   responseTime: new Date(loadDetail.deadline_at),
        //   deliveryDate: loadDetail?.delivery_at
        //     ? new Date(loadDetail.delivery_at)
        //     : null,
        //   deliveryTime: loadDetail?.delivery_at
        //     ? new Date(loadDetail.delivery_at)
        //     : null,
        //   emptyReturnDeadlineAtDate: loadDetail?.empty_return_deadline_at
        //     ? new Date(loadDetail.empty_return_deadline_at)
        //     : null,
        //   emptyReturnDeadlineAtTime: loadDetail?.empty_return_deadline_at
        //     ? new Date(loadDetail.empty_return_deadline_at)
        //     : null,
        //   rateValidityDate: loadDetail?.rate_validity_date
        //     ? new Date(loadDetail.rate_validity_date)
        //     : null,
        pickupDate: dayjs(new Date(loadDetail.pickup_date)).format("YYYY-MM-DD"),
        pickupTime: loadDetail !== null && loadDetail !== void 0 && loadDetail.pickup_time ? dayjs(new Date("".concat(loadDetail.pickup_date, " ").concat(loadDetail.pickup_time))).format("HH:mm:ss") : null,
        responseDate: dayjs(new Date(loadDetail.deadline_at)).format("YYYY-MM-DD"),
        responseTime: dayjs(new Date(loadDetail.deadline_at)).format("HH:mm:ss"),
        is_empty_return: loadDetail.is_empty_return,
        empty_return_yard_name: loadDetail.empty_return_yard_name,
        deliveryDate: loadDetail !== null && loadDetail !== void 0 && loadDetail.delivery_at ? dayjs(new Date(loadDetail.delivery_at)).format("YYYY-MM-DD") : null,
        deliveryTime: loadDetail !== null && loadDetail !== void 0 && loadDetail.delivery_at ? dayjs(new Date(loadDetail.delivery_at)).format("HH:mm:ss") : null,
        emptyReturnDeadlineAtDate: loadDetail !== null && loadDetail !== void 0 && loadDetail.empty_return_deadline_at ? dayjs(new Date(loadDetail.empty_return_deadline_at)).format("YYYY-MM-DD") : null,
        emptyReturnDeadlineAtTime: loadDetail !== null && loadDetail !== void 0 && loadDetail.empty_return_deadline_at ? dayjs(new Date(loadDetail.empty_return_deadline_at)).format("HH:mm:ss") : null,
        rateValidityDate: loadDetail !== null && loadDetail !== void 0 && loadDetail.rate_validity_date ? dayjs(new Date(loadDetail.rate_validity_date)).format("YYYY-MM-DD") : null
      },
      poster_deadline_date: new Date(loadDetail.deadline_at),
      poster_deadline_Time: new Date(loadDetail.deadline_at),
      PaymentScreen: {
        pt_is_advance: Boolean(loadDetail.pt_is_advance),
        pt_advance_value: loadDetail.pt_advance_value,
        pt_is_ondelivery: Boolean(loadDetail.pt_is_ondelivery),
        pt_ondelivery_value: loadDetail.pt_ondelivery_value,
        pt_is_credit: Boolean(loadDetail.pt_is_credit),
        pt_credit_percentage: loadDetail.pt_credit_percentage,
        pt_credit_days: loadDetail.pt_credit_days,
        is_pt_fuel_card: loadDetail.pt_fuel_card !== 0 && loadDetail.pt_fuel_card !== "0" ? true : false,
        pt_fuel_card_value: loadDetail.pt_fuel_card_value,
        is_gst_inclusive: Boolean(loadDetail.is_gst_inclusive),
        sales_tax_province: loadDetail.sales_tax_province,
        is_withholding_tax_inclusive: Boolean(loadDetail.is_withholding_tax_inclusive)
      },
      documents: new_docs,
      ref_no: loadDetail.ref_no,
      recipients: from == 'forward' ? [] : recipients,
      forward_recipients: recipients,
      history_recipients: loadType == "Loads Received" ? [] : history_recipients
    };
    if (((_getInquiryType = getInquiryType(loadDetail.inquiry_type.id, inquiry_type)) === null || _getInquiryType === void 0 ? void 0 : _getInquiryType.title) === "Contract Rate") {
      var _loadDetail$contract_;
      var addedVehiclesTemp = [];
      var data = {
        contractDetails: {
          is_unit_basis: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_unit_basis ? true : false,
          contract_name: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.contract_name,
          contract_remarks: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.remarks,
          contract_start_date: from == "clone" ? null : loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.contract_start_date,
          contract_end_date: from == "clone" ? null : loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.contract_end_date,
          // deadline_date: from == "clone" ? null : loadDetail?.deadline_at,
          // deadline_time: from == "clone" ? null : loadDetail?.deadline_at,
          deadline_date: from == "clone" ? null : dayjs(loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.deadline_at).format("YYYY-MM-DD"),
          deadline_time: from == "clone" ? null : dayjs(loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.deadline_at).format("HH:mm:ss"),
          quote_by: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.quote_by,
          is_hse_compliant_vehicle: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_hse_compliant_vehicle,
          is_weightment_received_required: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_weightment_received_required,
          weightment_received_name: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.weightment_received_name,
          is_bonded_vehicle_required: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_bonded_vehicle_required,
          is_tarpaulin_plastic_cover: loadDetail.is_tarpaulin_plastic_cover,
          rate_validity_date: loadDetail !== null && loadDetail !== void 0 && loadDetail.rate_validity_date ? new Date(loadDetail.rate_validity_date) : null,
          is_covid_certf_req_driver: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_covid_certf_req_driver,
          is_vehicle_tracking: loadDetail.is_vehicle_tracking,
          is_advance_vehicle_driver_docs: loadDetail.is_advance_vehicle_driver_docs,
          is_driver_negative_pcr_required: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_driver_negative_pcr_required,
          detention_included_transport: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.detention_included_transport,
          cargo_description: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.cargo_description,
          is_cross_stuffing_required: loadDetail.is_cross_stuffing_required ? true : false,
          is_cross_stuffing_box_vehicle_required: loadDetail.is_cross_stuffing_box_vehicle_required ? true : false,
          cross_stuffing_no_of_packages: loadDetail.cross_stuffing_no_of_packages ? loadDetail.cross_stuffing_no_of_packages : "",
          driver_requried_document_details: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.driver_requried_document_details,
          is_under_hook_delivery_required: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_under_hook_delivery_required,
          under_hook_delivery: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.under_hook_delivery
        },
        location: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$contract_ = loadDetail.contract_locations) === null || _loadDetail$contract_ === void 0 ? void 0 : _loadDetail$contract_.map(function (loc) {
          var _loc$dropoff, _loc$dropoff2, _loc$dropoff3, _loc$dropoff4, _loc$dropoff5, _loc$dropoff6, _loc$dropoff7, _loc$dropoff8, _loc$dropoff9, _loc$dropoff10, _loadDetail$load_type, _loadDetail$load_type2, _loc$pickup, _loc$pickup2, _loc$pickup2$attribut, _loc$pickup3, _loc$pickup4, _loc$pickup4$attribut, _loc$pickup5, _loc$pickup5$attribut, _loc$pickup6, _loc$pickup6$attribut, _loc$pickup7, _loc$pickup8, _loc$pickup9, _loc$pickup10, _loc$pickup11, _loc$pickup12, _loc$pickup13, _loc$pickup14, _loc$pickup15, _loc$pickup16, _loc$categories, _loc$categories2;
          return {
            dropoff: {
              lat: loc === null || loc === void 0 ? void 0 : (_loc$dropoff = loc.dropoff) === null || _loc$dropoff === void 0 ? void 0 : _loc$dropoff.lat,
              lng: loc === null || loc === void 0 ? void 0 : (_loc$dropoff2 = loc.dropoff) === null || _loc$dropoff2 === void 0 ? void 0 : _loc$dropoff2.long,
              address: loc === null || loc === void 0 ? void 0 : (_loc$dropoff3 = loc.dropoff) === null || _loc$dropoff3 === void 0 ? void 0 : _loc$dropoff3.raw_address,
              description: loc === null || loc === void 0 ? void 0 : (_loc$dropoff4 = loc.dropoff) === null || _loc$dropoff4 === void 0 ? void 0 : _loc$dropoff4.description,
              type: loc === null || loc === void 0 ? void 0 : (_loc$dropoff5 = loc.dropoff) === null || _loc$dropoff5 === void 0 ? void 0 : _loc$dropoff5.type,
              city: loc === null || loc === void 0 ? void 0 : (_loc$dropoff6 = loc.dropoff) === null || _loc$dropoff6 === void 0 ? void 0 : _loc$dropoff6.city,
              id: loc === null || loc === void 0 ? void 0 : (_loc$dropoff7 = loc.dropoff) === null || _loc$dropoff7 === void 0 ? void 0 : _loc$dropoff7.id,
              title: loc === null || loc === void 0 ? void 0 : (_loc$dropoff8 = loc.dropoff) === null || _loc$dropoff8 === void 0 ? void 0 : _loc$dropoff8.title,
              contact_name: loc === null || loc === void 0 ? void 0 : (_loc$dropoff9 = loc.dropoff) === null || _loc$dropoff9 === void 0 ? void 0 : _loc$dropoff9.contact_name,
              contact_phone_no: loc === null || loc === void 0 ? void 0 : (_loc$dropoff10 = loc.dropoff) === null || _loc$dropoff10 === void 0 ? void 0 : _loc$dropoff10.contact_phone_no,
              contact_phone_code: "92"
            },
            pickup: {
              attribute: ((_loadDetail$load_type = loadDetail.load_type) === null || _loadDetail$load_type === void 0 ? void 0 : _loadDetail$load_type.title) === LOAD_TYPE.fcl ? {
                cargo_volume: (loc === null || loc === void 0 ? void 0 : loc.cargo_volume) || null,
                gross_weight: (loc === null || loc === void 0 ? void 0 : loc.gross_weight) || null,
                gross_weight_in_kgs: loc !== null && loc !== void 0 && loc.gross_weight ? Number(loc === null || loc === void 0 ? void 0 : loc.gross_weight) * 1000 : null,
                total_freight_ton: (loc === null || loc === void 0 ? void 0 : loc.total_freight_ton) || null,
                no_of_packages: (loc === null || loc === void 0 ? void 0 : loc.no_of_packages) || null
              } : ((_loadDetail$load_type2 = loadDetail.load_type) === null || _loadDetail$load_type2 === void 0 ? void 0 : _loadDetail$load_type2.title) === LOAD_TYPE.bulk ? (loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.quote_by) === "vehicle" ? _objectSpread(_objectSpread({}, loc === null || loc === void 0 ? void 0 : (_loc$pickup = loc.pickup) === null || _loc$pickup === void 0 ? void 0 : _loc$pickup.attributes), {}, {
                gross_weight_in_kgs: Number(loc === null || loc === void 0 ? void 0 : (_loc$pickup2 = loc.pickup) === null || _loc$pickup2 === void 0 ? void 0 : (_loc$pickup2$attribut = _loc$pickup2.attributes) === null || _loc$pickup2$attribut === void 0 ? void 0 : _loc$pickup2$attribut.gross_weight) * 1000,
                inputType: ["text", "text", "text"]
              }) : _objectSpread(_objectSpread({}, loc === null || loc === void 0 ? void 0 : (_loc$pickup3 = loc.pickup) === null || _loc$pickup3 === void 0 ? void 0 : _loc$pickup3.attributes), {}, {
                inputType: ["text", "text", "text"],
                gross_weight_in_kgs: Number(loc === null || loc === void 0 ? void 0 : (_loc$pickup4 = loc.pickup) === null || _loc$pickup4 === void 0 ? void 0 : (_loc$pickup4$attribut = _loc$pickup4.attributes) === null || _loc$pickup4$attribut === void 0 ? void 0 : _loc$pickup4$attribut.gross_weight) * 1000,
                new_recipients: loc === null || loc === void 0 ? void 0 : loc.recipients,
                packageType: getPackageById(loc === null || loc === void 0 ? void 0 : (_loc$pickup5 = loc.pickup) === null || _loc$pickup5 === void 0 ? void 0 : (_loc$pickup5$attribut = _loc$pickup5.attributes) === null || _loc$pickup5$attribut === void 0 ? void 0 : _loc$pickup5$attribut.package_type_id, packageType),
                PreferredVehicleType: getPreferredVehicleById(loc === null || loc === void 0 ? void 0 : (_loc$pickup6 = loc.pickup) === null || _loc$pickup6 === void 0 ? void 0 : (_loc$pickup6$attribut = _loc$pickup6.attributes) === null || _loc$pickup6$attribut === void 0 ? void 0 : _loc$pickup6$attribut.preferred_vehicle_id, PreferredVehicleType)
              }) : convertArrayToObject(loc === null || loc === void 0 ? void 0 : loc.categories),
              lat: loc === null || loc === void 0 ? void 0 : (_loc$pickup7 = loc.pickup) === null || _loc$pickup7 === void 0 ? void 0 : _loc$pickup7.lat,
              lng: loc === null || loc === void 0 ? void 0 : (_loc$pickup8 = loc.pickup) === null || _loc$pickup8 === void 0 ? void 0 : _loc$pickup8.long,
              address: loc === null || loc === void 0 ? void 0 : (_loc$pickup9 = loc.pickup) === null || _loc$pickup9 === void 0 ? void 0 : _loc$pickup9.raw_address,
              description: loc === null || loc === void 0 ? void 0 : (_loc$pickup10 = loc.pickup) === null || _loc$pickup10 === void 0 ? void 0 : _loc$pickup10.description,
              type: loc === null || loc === void 0 ? void 0 : (_loc$pickup11 = loc.pickup) === null || _loc$pickup11 === void 0 ? void 0 : _loc$pickup11.type,
              city: loc === null || loc === void 0 ? void 0 : (_loc$pickup12 = loc.pickup) === null || _loc$pickup12 === void 0 ? void 0 : _loc$pickup12.city,
              id: loc === null || loc === void 0 ? void 0 : (_loc$pickup13 = loc.pickup) === null || _loc$pickup13 === void 0 ? void 0 : _loc$pickup13.id,
              title: loc === null || loc === void 0 ? void 0 : (_loc$pickup14 = loc.pickup) === null || _loc$pickup14 === void 0 ? void 0 : _loc$pickup14.title,
              contact_name: loc === null || loc === void 0 ? void 0 : (_loc$pickup15 = loc.pickup) === null || _loc$pickup15 === void 0 ? void 0 : _loc$pickup15.contact_name,
              contact_phone_no: loc === null || loc === void 0 ? void 0 : (_loc$pickup16 = loc.pickup) === null || _loc$pickup16 === void 0 ? void 0 : _loc$pickup16.contact_phone_no,
              contact_phone_code: "92"
            },
            items: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_unit_basis ? [] : loc === null || loc === void 0 ? void 0 : (_loc$categories = loc.categories) === null || _loc$categories === void 0 ? void 0 : _loc$categories.map(function (item) {
              var obj_vehicle = {
                containerType: item === null || item === void 0 ? void 0 : item.container_type,
                PreferredVehicleType: getPreferredVehicleById(item === null || item === void 0 ? void 0 : item.preferred_vehicles, PreferredVehicleType),
                max_vehicle_weight: item === null || item === void 0 ? void 0 : item.max_vehicle_weight,
                packageType: getPackageById(item === null || item === void 0 ? void 0 : item.package_type_id, packageType),
                vehicle_quantity: item !== null && item !== void 0 && item.vehicle_quantity ? item === null || item === void 0 ? void 0 : item.vehicle_quantity : "",
                selectVehicleTypeObj: item === null || item === void 0 ? void 0 : item.vehicle,
                new_recipients: item === null || item === void 0 ? void 0 : item.recipients,
                values: load_type === LOAD_TYPE.fcl ? {
                  is_boxed: item !== null && item !== void 0 && item.is_boxed ? true : false,
                  is_loading_required: item !== null && item !== void 0 && item.is_loading_required ? true : false,
                  is_out_of_guage: item !== null && item !== void 0 && item.is_out_of_guage ? true : false,
                  is_sidewall: item !== null && item !== void 0 && item.is_sidewall ? true : false,
                  is_any_suitable_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_any_suitable_vehicle ? true : false,
                  is_flat_bed_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_flat_bed_vehicle ? true : false,
                  is_low_bed_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_low_bed_vehicle ? true : false,
                  is_dum_truck_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_dum_truck_vehicle ? true : false,
                  is_twin_load: item !== null && item !== void 0 && item.is_twin_load ? true : false,
                  is_unloading_required: item !== null && item !== void 0 && item.is_unloading_required ? true : false,
                  is_loading_requirements: item === null || item === void 0 ? void 0 : item.loading_requirements,
                  unloading_requirements: item === null || item === void 0 ? void 0 : item.unloading_requirements,
                  is_shipper_owned_container: item === null || item === void 0 ? void 0 : item.is_shipper_owned_container,
                  is_genset: item !== null && item !== void 0 && item.is_genset ? true : false,
                  required_temperature: [item !== null && item !== void 0 && item.required_temperature_min ? Number(item === null || item === void 0 ? void 0 : item.required_temperature_min) : 0, item !== null && item !== void 0 && item.required_temperature_max ? Number(item === null || item === void 0 ? void 0 : item.required_temperature_max) : 0]
                } : {
                  cargo_volume: (item === null || item === void 0 ? void 0 : item.cargo_volume) || null,
                  gross_weight: (item === null || item === void 0 ? void 0 : item.gross_weight) || null,
                  gross_weight_in_kgs: item !== null && item !== void 0 && item.gross_weight ? Number(item === null || item === void 0 ? void 0 : item.gross_weight) * 1000 : null,
                  total_freight_ton: (item === null || item === void 0 ? void 0 : item.total_freight_ton) || null,
                  no_of_packages: (item === null || item === void 0 ? void 0 : item.no_of_packages) || null,
                  is_boxed: item !== null && item !== void 0 && item.is_boxed ? true : false,
                  is_sidewall: item !== null && item !== void 0 && item.is_sidewall ? true : false,
                  is_any_suitable_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_any_suitable_vehicle ? true : false,
                  is_flat_bed_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_flat_bed_vehicle ? true : false,
                  is_low_bed_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_low_bed_vehicle ? true : false,
                  is_dum_truck_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_dum_truck_vehicle ? true : false,
                  is_loading_required: item !== null && item !== void 0 && item.is_loading_required ? true : false,
                  is_unloading_required: item !== null && item !== void 0 && item.is_unloading_required ? true : false,
                  loading_requirements: item === null || item === void 0 ? void 0 : item.loading_requirements,
                  packageType: item === null || item === void 0 ? void 0 : item.package_type,
                  unloading_requirements: item === null || item === void 0 ? void 0 : item.unloading_requirements,
                  detention_free_time: item === null || item === void 0 ? void 0 : item.detention_free_time,
                  is_genset: item !== null && item !== void 0 && item.is_genset ? true : false,
                  required_temperature: [item !== null && item !== void 0 && item.required_temperature_min ? Number(item === null || item === void 0 ? void 0 : item.required_temperature_min) : 0, item !== null && item !== void 0 && item.required_temperature_max ? Number(item === null || item === void 0 ? void 0 : item.required_temperature_max) : 0],
                  max_bid: item !== null && item !== void 0 && item.max_bid && (item === null || item === void 0 ? void 0 : item.max_bid) != 0 ? item.max_bid : null,
                  detention_included_transport: item === null || item === void 0 ? void 0 : item.detention_included_transport,
                  package_type_other: item === null || item === void 0 ? void 0 : item.package_type_other
                }
              };
              if (!(addedVehiclesTemp !== null && addedVehiclesTemp !== void 0 && addedVehiclesTemp.find(function (item) {
                var _item$PreferredVehicl, _obj_vehicle$Preferre, _item$selectVehicleTy, _obj_vehicle$selectVe;
                return (item === null || item === void 0 ? void 0 : (_item$PreferredVehicl = item.PreferredVehicleType) === null || _item$PreferredVehicl === void 0 ? void 0 : _item$PreferredVehicl.id) == (obj_vehicle === null || obj_vehicle === void 0 ? void 0 : (_obj_vehicle$Preferre = obj_vehicle.PreferredVehicleType) === null || _obj_vehicle$Preferre === void 0 ? void 0 : _obj_vehicle$Preferre.id) && (item === null || item === void 0 ? void 0 : (_item$selectVehicleTy = item.selectVehicleTypeObj) === null || _item$selectVehicleTy === void 0 ? void 0 : _item$selectVehicleTy.id) == (obj_vehicle === null || obj_vehicle === void 0 ? void 0 : (_obj_vehicle$selectVe = obj_vehicle.selectVehicleTypeObj) === null || _obj_vehicle$selectVe === void 0 ? void 0 : _obj_vehicle$selectVe.id);
              }))) {
                addedVehiclesTemp.push(obj_vehicle);
              }
              return obj_vehicle;
            }),
            added_units: !(loadDetail !== null && loadDetail !== void 0 && loadDetail.is_unit_basis) ? [] : loc === null || loc === void 0 ? void 0 : (_loc$categories2 = loc.categories) === null || _loc$categories2 === void 0 ? void 0 : _loc$categories2.map(function (item) {
              return {
                gross_weight: (item === null || item === void 0 ? void 0 : item.gross_weight) || null,
                cargo_volume: (item === null || item === void 0 ? void 0 : item.cargo_volume) || null,
                no_of_packages: (item === null || item === void 0 ? void 0 : item.no_of_packages) || null,
                total_freight_ton: item === null || item === void 0 ? void 0 : item.total_freight_ton,
                gross_weight_in_kgs: item !== null && item !== void 0 && item.gross_weight ? Number(item === null || item === void 0 ? void 0 : item.gross_weight) * 1000 : null,
                dimensions: item === null || item === void 0 ? void 0 : item.dimensions,
                unit_dimension_value: item === null || item === void 0 ? void 0 : item.unit_dimension_value,
                unit_quantity: item === null || item === void 0 ? void 0 : item.unit_quantity,
                unit_description: item === null || item === void 0 ? void 0 : item.unit_description,
                is_unit_basis: true,
                unit_edit_mode: false,
                unit_edit_id: null,
                detention_additional_charges: (item === null || item === void 0 ? void 0 : item.detention_additional_charges) || null,
                detention_free_time: item === null || item === void 0 ? void 0 : item.detention_free_time,
                detention_rate: item === null || item === void 0 ? void 0 : item.detention_rate,
                is_loading_required: item === null || item === void 0 ? void 0 : item.is_loading_required,
                is_unloading_required: item === null || item === void 0 ? void 0 : item.is_unloading_required,
                loading_requirements: item === null || item === void 0 ? void 0 : item.loading_requirements,
                unloading_requirements: item === null || item === void 0 ? void 0 : item.unloading_requirements,
                loading_price: item === null || item === void 0 ? void 0 : item.loading_price,
                unloading_price: item === null || item === void 0 ? void 0 : item.unloading_price,
                price: item === null || item === void 0 ? void 0 : item.price,
                total_amount: item === null || item === void 0 ? void 0 : item.total_amount,
                max_bid: item === null || item === void 0 ? void 0 : item.max_bid,
                new_recipients: item === null || item === void 0 ? void 0 : item.recipients
              };
            })
          };
        })
      };

      // draftData.vehicleScreen = null;
      draftData.vehicleScreen = {
        addedVehicles: [],
        addedVehiclesTemp: addedVehiclesTemp
      };
      draftData = _objectSpread(_objectSpread({}, draftData), data);
    }
    if (loadDetail !== null && loadDetail !== void 0 && loadDetail.is_unit_basis && (loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$inquiry_t = loadDetail.inquiry_type) === null || _loadDetail$inquiry_t === void 0 ? void 0 : _loadDetail$inquiry_t.title) == 'Spot Rate') {
      draftData["vehicleScreen"]["addedVehicles"] = [];
    } else if (!(loadDetail !== null && loadDetail !== void 0 && loadDetail.is_unit_basis) && (loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$inquiry_t2 = loadDetail.inquiry_type) === null || _loadDetail$inquiry_t2 === void 0 ? void 0 : _loadDetail$inquiry_t2.title) == 'Spot Rate') {
      draftData["vehicleScreen"]["added_units"] = [];
    }
    return _objectSpread({}, draftData);
  }
};
export var convertDataForLoadDetailRedux = function convertDataForLoadDetailRedux(loadDetail, packageType, load_type, inquiry_type) {
  var from = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  var loadType = arguments.length > 5 ? arguments[5] : undefined;
  if (loadDetail) {
    var _loadDetail$instructi2, _loadDetail$categorie3, _loadDetail$locations2, _loadDetail$additiona3, _loadDetail$additiona4, _getInquiryType2;
    var new_docs = {};
    var temp_bill_of_lading = [];
    var temp_packing_list = [];
    var temp_other_uploads = [];
    loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.documents.forEach(function (item) {
      if ((item === null || item === void 0 ? void 0 : item.type_name) == "bill_of_ladings" || (item === null || item === void 0 ? void 0 : item.type_name) == "bill_of_lading") {
        temp_bill_of_lading.push({
          file_name: item.file_name,
          cloudinary_url: item.path,
          file_url: item.relative_path,
          mime_type: item.mime_type
        });
      } else if ((item === null || item === void 0 ? void 0 : item.type_name) == "packing_lists" || (item === null || item === void 0 ? void 0 : item.type_name) == "packing_list") {
        temp_packing_list.push({
          file_name: item.file_name,
          cloudinary_url: item.path,
          file_url: item.relative_path,
          mime_type: item.mime_type
        });
      } else {
        if (temp_other_uploads.find(function (itm) {
          return itm.title == item.other_type;
        })) {
          temp_other_uploads[temp_other_uploads.findIndex(function (it) {
            return it.title == item.other_type;
          })].files.push({
            file_name: item.file_name,
            cloudinary_url: item.path,
            file_url: item.relative_path,
            mime_type: item.mime_type
          });
        } else {
          temp_other_uploads.push({
            title: item === null || item === void 0 ? void 0 : item.other_type,
            files: [{
              file_name: item.file_name,
              cloudinary_url: item.path,
              file_url: item.relative_path,
              mime_type: item.mime_type
            }]
          });
        }
      }
    });
    new_docs["bill_of_ladings"] = temp_bill_of_lading;
    new_docs["packing_lists"] = temp_packing_list;
    new_docs["other_uploads"] = temp_other_uploads;
    var recipients = [];
    var history_recipients = [];
    if (from == 'forward') {
      var _loadDetail$poster3, _loadDetail$poster4;
      recipients.push({
        phone_no: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$poster3 = loadDetail.poster) === null || _loadDetail$poster3 === void 0 ? void 0 : _loadDetail$poster3.phone_no,
        phone_code: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$poster4 = loadDetail.poster) === null || _loadDetail$poster4 === void 0 ? void 0 : _loadDetail$poster4.phone_code
      });
    }
    var draftData = {
      from: from,
      allocates: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.allocates,
      is_allocated: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_allocated,
      fromClone: true,
      load_id: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.id,
      title: loadDetail.title,
      inquiry_type: loadDetail.inquiry_type.id,
      load_type: loadDetail.load_type.id,
      instructions: loadDetail !== null && loadDetail !== void 0 && loadDetail.instructions ? loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.instructions : null,
      additional_requirements: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.additional_requirements,
      instructions_voice_note: loadDetail !== null && loadDetail !== void 0 && loadDetail.instructions_voice_note ? {
        cloudinary_url: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.instructions_voice_note,
        file_url: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$instructi2 = loadDetail.instructions_voice_note_object) === null || _loadDetail$instructi2 === void 0 ? void 0 : _loadDetail$instructi2.file_url
      } : null,
      is_empty_return: loadDetail.is_empty_return,
      empty_return_yard_name: loadDetail.empty_return_yard_name,
      vehicleScreen: {
        values: {
          load_name: loadDetail.title,
          cargo_description: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.cargo_description,
          ref_no: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.ref_no
        },
        addedVehicles: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$categorie3 = loadDetail.categories) === null || _loadDetail$categorie3 === void 0 ? void 0 : _loadDetail$categorie3.map(function (item) {
          var _item$recipients2;
          item === null || item === void 0 ? void 0 : (_item$recipients2 = item.recipients) === null || _item$recipients2 === void 0 ? void 0 : _item$recipients2.forEach(function (recipient) {
            var find = recipients.find(function (item) {
              var _recipient$recipient7;
              return (item === null || item === void 0 ? void 0 : item.phone_no) == (recipient === null || recipient === void 0 ? void 0 : (_recipient$recipient7 = recipient.recipient) === null || _recipient$recipient7 === void 0 ? void 0 : _recipient$recipient7.phone_no);
            });
            if (find == undefined) {
              var _recipient$recipient8, _recipient$recipient9;
              recipients.push({
                phone_no: recipient === null || recipient === void 0 ? void 0 : (_recipient$recipient8 = recipient.recipient) === null || _recipient$recipient8 === void 0 ? void 0 : _recipient$recipient8.phone_no,
                phone_code: recipient === null || recipient === void 0 ? void 0 : (_recipient$recipient9 = recipient.recipient) === null || _recipient$recipient9 === void 0 ? void 0 : _recipient$recipient9.phone_code
              });
              history_recipients.push(_objectSpread({}, recipient));
            }
          });
          return {
            selectVehicleTypeObj: _objectSpread({}, item === null || item === void 0 ? void 0 : item.vehicle),
            vehicle_quantity: item !== null && item !== void 0 && item.vehicle_quantity ? item === null || item === void 0 ? void 0 : item.vehicle_quantity : "",
            containerType: _objectSpread({}, item === null || item === void 0 ? void 0 : item.container_type),
            vehicles_drivers_refs: item === null || item === void 0 ? void 0 : item.vehicles_drivers_refs,
            new_recipients: item === null || item === void 0 ? void 0 : item.recipients,
            max_vehicle_weight: item === null || item === void 0 ? void 0 : item.max_vehicle_weight,
            total_amount: item === null || item === void 0 ? void 0 : item.total_offer_price,
            price: item === null || item === void 0 ? void 0 : item.offer_price_per_vehicle,
            detention_rate: item === null || item === void 0 ? void 0 : item.detention_rate,
            detention_free_time: item === null || item === void 0 ? void 0 : item.detention_free_time,
            // is_genset: item?.is_genset ? true : false,

            max_bid: item !== null && item !== void 0 && item.max_bid && (item === null || item === void 0 ? void 0 : item.max_bid) != 0 ? item.max_bid : null,
            detention_included_transport: item !== null && item !== void 0 && item.detention_included_transport ? true : false,
            detention_additional_charges: item === null || item === void 0 ? void 0 : item.detention_additional_charges,
            package_type_other: item === null || item === void 0 ? void 0 : item.package_type_other,
            loading_price: item === null || item === void 0 ? void 0 : item.loading_price,
            unloading_price: item === null || item === void 0 ? void 0 : item.unloading_price,
            is_loading_required: item !== null && item !== void 0 && item.is_loading_required ? true : false,
            is_unloading_required: item !== null && item !== void 0 && item.is_unloading_required ? true : false,
            loading_requirements: item === null || item === void 0 ? void 0 : item.loading_requirements,
            unloading_requirements: item === null || item === void 0 ? void 0 : item.unloading_requirements,
            values: {
              is_out_of_guage: item !== null && item !== void 0 && item.is_out_of_guage ? true : false,
              is_twin_load: item !== null && item !== void 0 && item.is_twin_load ? true : false,
              is_shipper_owned_container: item !== null && item !== void 0 && item.is_shipper_owned_container ? true : false,
              required_temperature: [item !== null && item !== void 0 && item.required_temperature_min ? Number(item === null || item === void 0 ? void 0 : item.required_temperature_min) : 0, item !== null && item !== void 0 && item.required_temperature_max ? Number(item === null || item === void 0 ? void 0 : item.required_temperature_max) : 0],
              is_genset: item !== null && item !== void 0 && item.is_genset ? true : false
            }
          };
        }),
        is_allow_partial_load: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_allow_partial_load ? true : false
      },
      additionalScreen: {
        is_hse_compliant_vehicle: loadDetail.is_hse_compliant_vehicle ? true : false,
        is_weightment_received_required: loadDetail.is_weightment_received_required ? true : false,
        weightment_received_name: loadDetail.weightment_received_name ? loadDetail.weightment_received_name : null,
        is_bonded_vehicle_required: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_bonded_vehicle_required ? true : false,
        is_tarpaulin_plastic_cover: loadDetail.is_tarpaulin_plastic_cover ? true : false,
        rate_validity_date: loadDetail !== null && loadDetail !== void 0 && loadDetail.rate_validity_date ? new Date(loadDetail.rate_validity_date) : null,
        is_covid_certf_req_driver: loadDetail.is_covid_certf_req_driver ? true : false,
        is_vehicle_tracking: loadDetail.is_vehicle_tracking ? true : false,
        is_shipper_owned_container: loadDetail.is_shipper_owned_container ? true : false,
        is_container_deposit_by_transporter: loadDetail.is_container_deposit_by_transporter ? true : false,
        is_quote_price_deposit_fee_inclusive: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_quote_price_deposit_fee_inclusive ? true : false,
        container_deposit_amount: loadDetail.container_deposit_amount ? loadDetail.container_deposit_amount : "",
        shipping_line_name: loadDetail.shipping_line_name ? loadDetail.shipping_line_name : "",
        is_cross_stuffing_required: loadDetail.is_cross_stuffing_required ? true : false,
        is_cross_stuffing_box_vehicle_required: loadDetail.is_cross_stuffing_box_vehicle_required ? true : false,
        cross_stuffing_no_of_packages: loadDetail.cross_stuffing_no_of_packages ? loadDetail.cross_stuffing_no_of_packages : "",
        is_advance_vehicle_driver_docs: loadDetail.is_advance_vehicle_driver_docs ? true : false,
        is_driver_negative_pcr_required: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_driver_negative_pcr_required ? true : false,
        driver_requried_document_details: loadDetail.driver_requried_document_details ? loadDetail.driver_requried_document_details : null,
        is_under_hook_delivery_required: loadDetail.is_under_hook_delivery_required ? true : false,
        under_hook_delivery: loadDetail.under_hook_delivery ? loadDetail.under_hook_delivery : null
      },
      detention_included_transport: loadDetail !== null && loadDetail !== void 0 && loadDetail.detention_included_transport ? true : false,
      transaction_type_id: loadDetail.transaction_type.id,
      location: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$locations2 = loadDetail.locations) === null || _loadDetail$locations2 === void 0 ? void 0 : _loadDetail$locations2.map(function (item) {
        return {
          address: item === null || item === void 0 ? void 0 : item.raw_address,
          lat: item === null || item === void 0 ? void 0 : item.lat,
          lng: item === null || item === void 0 ? void 0 : item.long,
          description: item === null || item === void 0 ? void 0 : item.description,
          timestamp: null,
          title: item === null || item === void 0 ? void 0 : item.title,
          contact_name: item === null || item === void 0 ? void 0 : item.contact_name,
          contact_phone_no: item === null || item === void 0 ? void 0 : item.contact_phone_no,
          contact_phone_code: "92",
          pickup_accordion: false,
          dropoff_accordion: false,
          city: item === null || item === void 0 ? void 0 : item.city
        };
      }),
      additional_pickups: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$additiona3 = loadDetail.additional_pickups) === null || _loadDetail$additiona3 === void 0 ? void 0 : _loadDetail$additiona3.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          lng: item === null || item === void 0 ? void 0 : item.long,
          address: item === null || item === void 0 ? void 0 : item.raw_address,
          pickup_accordion: false,
          dropoff_accordion: false
        });
      }),
      additional_dropoffs: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$additiona4 = loadDetail.additional_dropoffs) === null || _loadDetail$additiona4 === void 0 ? void 0 : _loadDetail$additiona4.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          lng: item === null || item === void 0 ? void 0 : item.long,
          address: item === null || item === void 0 ? void 0 : item.raw_address,
          pickup_accordion: false,
          dropoff_accordion: false
        });
      }),
      responseDeadline: from == "clone" ? null : {
        pickupDate: dayjs(new Date(loadDetail.pickup_date)).format("YYYY-MM-DD"),
        pickupTime: loadDetail !== null && loadDetail !== void 0 && loadDetail.pickup_time ? dayjs(new Date("".concat(loadDetail.pickup_date, " ").concat(loadDetail.pickup_time))).format("HH:mm:ss") : null,
        responseDate: dayjs(new Date(loadDetail.deadline_at)).format("YYYY-MM-DD"),
        responseTime: dayjs(new Date(loadDetail.deadline_at)).format("HH:mm:ss"),
        is_empty_return: loadDetail.is_empty_return,
        empty_return_yard_name: loadDetail.empty_return_yard_name,
        deliveryDate: loadDetail !== null && loadDetail !== void 0 && loadDetail.delivery_at ? dayjs(new Date(loadDetail.delivery_at)).format("YYYY-MM-DD") : null,
        deliveryTime: loadDetail !== null && loadDetail !== void 0 && loadDetail.delivery_at ? dayjs(new Date(loadDetail.delivery_at)).format("HH:mm:ss") : null,
        emptyReturnDeadlineAtDate: loadDetail !== null && loadDetail !== void 0 && loadDetail.empty_return_deadline_at ? dayjs(new Date(loadDetail.empty_return_deadline_at)).format("YYYY-MM-DD") : null,
        emptyReturnDeadlineAtTime: loadDetail !== null && loadDetail !== void 0 && loadDetail.empty_return_deadline_at ? dayjs(new Date(loadDetail.empty_return_deadline_at)).format("HH:mm:ss") : null,
        rateValidityDate: loadDetail !== null && loadDetail !== void 0 && loadDetail.rate_validity_date ? dayjs(new Date(loadDetail.rate_validity_date)).format("YYYY-MM-DD") : null
      },
      poster_deadline_date: new Date(loadDetail.deadline_at),
      poster_deadline_Time: new Date(loadDetail.deadline_at),
      PaymentScreen: {
        pt_is_advance: Boolean(loadDetail.pt_is_advance),
        pt_advance_value: loadDetail.pt_advance_value,
        pt_is_ondelivery: Boolean(loadDetail.pt_is_ondelivery),
        pt_ondelivery_value: loadDetail.pt_ondelivery_value,
        pt_is_credit: Boolean(loadDetail.pt_is_credit),
        pt_credit_percentage: loadDetail.pt_credit_percentage,
        pt_credit_days: loadDetail.pt_credit_days,
        is_pt_fuel_card: loadDetail.pt_fuel_card !== 0 && loadDetail.pt_fuel_card !== "0" ? true : false,
        pt_fuel_card_value: loadDetail.pt_fuel_card_value,
        is_gst_inclusive: Boolean(loadDetail.is_gst_inclusive),
        sales_tax_province: loadDetail.sales_tax_province,
        is_withholding_tax_inclusive: Boolean(loadDetail.is_withholding_tax_inclusive)
      },
      documents: new_docs,
      ref_no: loadDetail.ref_no,
      recipients: from == 'forward' ? [] : recipients,
      forward_recipients: recipients,
      history_recipients: loadType == "Loads Received" ? [] : history_recipients
    };
    if (((_getInquiryType2 = getInquiryType(loadDetail.inquiry_type.id, inquiry_type)) === null || _getInquiryType2 === void 0 ? void 0 : _getInquiryType2.title) === "Contract Rate") {
      var _loadDetail$contract_2;
      var addedVehiclesTemp = [];
      var data = {
        contractDetails: {
          contract_name: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.contract_name,
          contract_remarks: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.remarks,
          contract_start_date: from == "clone" ? null : loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.contract_start_date,
          contract_end_date: from == "clone" ? null : loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.contract_end_date,
          // deadline_date: from == "clone" ? null : loadDetail?.deadline_at,
          // deadline_time: from == "clone" ? null : loadDetail?.deadline_at,
          deadline_date: from == "clone" ? null : dayjs(loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.deadline_at).format("YYYY-MM-DD"),
          deadline_time: from == "clone" ? null : dayjs(loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.deadline_at).format("HH:mm:ss"),
          quote_by: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.quote_by,
          is_hse_compliant_vehicle: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_hse_compliant_vehicle,
          is_weightment_received_required: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_weightment_received_required,
          weightment_received_name: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.weightment_received_name,
          is_bonded_vehicle_required: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_bonded_vehicle_required,
          is_tarpaulin_plastic_cover: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_tarpaulin_plastic_cover,
          rate_validity_date: loadDetail !== null && loadDetail !== void 0 && loadDetail.rate_validity_date ? new Date(loadDetail.rate_validity_date) : null,
          is_covid_certf_req_driver: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_covid_certf_req_driver,
          is_vehicle_tracking: loadDetail.is_vehicle_tracking,
          is_advance_vehicle_driver_docs: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_advance_vehicle_driver_docs,
          is_driver_negative_pcr_required: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_driver_negative_pcr_required,
          detention_included_transport: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.detention_included_transport,
          is_cross_stuffing_required: loadDetail.is_cross_stuffing_required ? true : false,
          is_cross_stuffing_box_vehicle_required: loadDetail.is_cross_stuffing_box_vehicle_required ? true : false,
          cross_stuffing_no_of_packages: loadDetail.cross_stuffing_no_of_packages ? loadDetail.cross_stuffing_no_of_packages : "",
          driver_requried_document_details: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.driver_requried_document_details,
          cargo_description: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.cargo_description,
          is_under_hook_delivery_required: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.is_under_hook_delivery_required,
          under_hook_delivery: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.under_hook_delivery
        },
        location: loadDetail === null || loadDetail === void 0 ? void 0 : (_loadDetail$contract_2 = loadDetail.contract_locations) === null || _loadDetail$contract_2 === void 0 ? void 0 : _loadDetail$contract_2.map(function (loc) {
          var _loc$dropoff11, _loc$dropoff12, _loc$dropoff13, _loc$dropoff14, _loc$dropoff15, _loc$dropoff16, _loc$dropoff17, _loc$dropoff18, _loc$dropoff19, _loc$dropoff20, _loc$pickup17, _loc$pickup18, _loc$pickup19, _loc$pickup20, _loc$pickup21, _loc$pickup22, _loc$pickup23, _loc$pickup24, _loc$pickup25, _loc$pickup26, _loc$categories3;
          return {
            dropoff: {
              lat: loc === null || loc === void 0 ? void 0 : (_loc$dropoff11 = loc.dropoff) === null || _loc$dropoff11 === void 0 ? void 0 : _loc$dropoff11.lat,
              lng: loc === null || loc === void 0 ? void 0 : (_loc$dropoff12 = loc.dropoff) === null || _loc$dropoff12 === void 0 ? void 0 : _loc$dropoff12.long,
              address: loc === null || loc === void 0 ? void 0 : (_loc$dropoff13 = loc.dropoff) === null || _loc$dropoff13 === void 0 ? void 0 : _loc$dropoff13.raw_address,
              description: loc === null || loc === void 0 ? void 0 : (_loc$dropoff14 = loc.dropoff) === null || _loc$dropoff14 === void 0 ? void 0 : _loc$dropoff14.description,
              type: loc === null || loc === void 0 ? void 0 : (_loc$dropoff15 = loc.dropoff) === null || _loc$dropoff15 === void 0 ? void 0 : _loc$dropoff15.type,
              city: loc === null || loc === void 0 ? void 0 : (_loc$dropoff16 = loc.dropoff) === null || _loc$dropoff16 === void 0 ? void 0 : _loc$dropoff16.city,
              id: loc === null || loc === void 0 ? void 0 : (_loc$dropoff17 = loc.dropoff) === null || _loc$dropoff17 === void 0 ? void 0 : _loc$dropoff17.id,
              title: loc === null || loc === void 0 ? void 0 : (_loc$dropoff18 = loc.dropoff) === null || _loc$dropoff18 === void 0 ? void 0 : _loc$dropoff18.title,
              contact_name: loc === null || loc === void 0 ? void 0 : (_loc$dropoff19 = loc.dropoff) === null || _loc$dropoff19 === void 0 ? void 0 : _loc$dropoff19.contact_name,
              contact_phone_no: loc === null || loc === void 0 ? void 0 : (_loc$dropoff20 = loc.dropoff) === null || _loc$dropoff20 === void 0 ? void 0 : _loc$dropoff20.contact_phone_no,
              contact_phone_code: "92"
            },
            pickup: {
              attribute: {
                cargo_volume: (loc === null || loc === void 0 ? void 0 : loc.cargo_volume) || null,
                gross_weight: (loc === null || loc === void 0 ? void 0 : loc.gross_weight) || null,
                gross_weight_in_kgs: loc !== null && loc !== void 0 && loc.gross_weight ? Number(loc === null || loc === void 0 ? void 0 : loc.gross_weight) * 1000 : null,
                no_of_packages: (loc === null || loc === void 0 ? void 0 : loc.no_of_packages) || null,
                total_freight_ton: (loc === null || loc === void 0 ? void 0 : loc.total_freight_ton) || null,
                detention_free_time: loc === null || loc === void 0 ? void 0 : loc.detention_free_time,
                cargo_liter: loc === null || loc === void 0 ? void 0 : loc.cargo_liter,
                detention_included_transport: loc !== null && loc !== void 0 && loc.detention_included_transport ? true : false
              },
              lat: loc === null || loc === void 0 ? void 0 : (_loc$pickup17 = loc.pickup) === null || _loc$pickup17 === void 0 ? void 0 : _loc$pickup17.lat,
              lng: loc === null || loc === void 0 ? void 0 : (_loc$pickup18 = loc.pickup) === null || _loc$pickup18 === void 0 ? void 0 : _loc$pickup18.long,
              address: loc === null || loc === void 0 ? void 0 : (_loc$pickup19 = loc.pickup) === null || _loc$pickup19 === void 0 ? void 0 : _loc$pickup19.raw_address,
              description: loc === null || loc === void 0 ? void 0 : (_loc$pickup20 = loc.pickup) === null || _loc$pickup20 === void 0 ? void 0 : _loc$pickup20.description,
              type: loc === null || loc === void 0 ? void 0 : (_loc$pickup21 = loc.pickup) === null || _loc$pickup21 === void 0 ? void 0 : _loc$pickup21.type,
              city: loc === null || loc === void 0 ? void 0 : (_loc$pickup22 = loc.pickup) === null || _loc$pickup22 === void 0 ? void 0 : _loc$pickup22.city,
              id: loc === null || loc === void 0 ? void 0 : (_loc$pickup23 = loc.pickup) === null || _loc$pickup23 === void 0 ? void 0 : _loc$pickup23.id,
              title: loc === null || loc === void 0 ? void 0 : (_loc$pickup24 = loc.pickup) === null || _loc$pickup24 === void 0 ? void 0 : _loc$pickup24.title,
              contact_name: loc === null || loc === void 0 ? void 0 : (_loc$pickup25 = loc.pickup) === null || _loc$pickup25 === void 0 ? void 0 : _loc$pickup25.contact_name,
              contact_phone_no: loc === null || loc === void 0 ? void 0 : (_loc$pickup26 = loc.pickup) === null || _loc$pickup26 === void 0 ? void 0 : _loc$pickup26.contact_phone_no,
              contact_phone_code: "92"
            },
            items: loc === null || loc === void 0 ? void 0 : (_loc$categories3 = loc.categories) === null || _loc$categories3 === void 0 ? void 0 : _loc$categories3.map(function (item) {
              var obj_vehicle = {
                containerType: item === null || item === void 0 ? void 0 : item.container_type,
                max_vehicle_weight: item === null || item === void 0 ? void 0 : item.max_vehicle_weight,
                packageType: getPackageById(item === null || item === void 0 ? void 0 : item.package_type_id, packageType),
                vehicle_quantity: item !== null && item !== void 0 && item.vehicle_quantity ? item === null || item === void 0 ? void 0 : item.vehicle_quantity : "",
                selectVehicleTypeObj: item === null || item === void 0 ? void 0 : item.vehicle,
                new_recipients: item === null || item === void 0 ? void 0 : item.recipients,
                values: {
                  is_boxed: item !== null && item !== void 0 && item.is_boxed ? true : false,
                  is_loading_required: item !== null && item !== void 0 && item.is_loading_required ? true : false,
                  is_out_of_guage: item !== null && item !== void 0 && item.is_out_of_guage ? true : false,
                  is_sidewall: item !== null && item !== void 0 && item.is_sidewall ? true : false,
                  is_any_suitable_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_any_suitable_vehicle ? true : false,
                  is_flat_bed_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_flat_bed_vehicle ? true : false,
                  is_low_bed_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_low_bed_vehicle ? true : false,
                  is_dum_truck_vehicle: loadDetail !== null && loadDetail !== void 0 && loadDetail.is_dum_truck_vehicle ? true : false,
                  is_twin_load: item !== null && item !== void 0 && item.is_twin_load ? true : false,
                  is_unloading_required: item !== null && item !== void 0 && item.is_unloading_required ? true : false,
                  is_loading_requirements: item === null || item === void 0 ? void 0 : item.loading_requirements,
                  unloading_requirements: item === null || item === void 0 ? void 0 : item.unloading_requirements,
                  is_shipper_owned_container: item === null || item === void 0 ? void 0 : item.is_shipper_owned_container,
                  detention_free_time: item === null || item === void 0 ? void 0 : item.detention_free_time,
                  is_genset: item !== null && item !== void 0 && item.is_genset ? true : false,
                  required_temperature: [item !== null && item !== void 0 && item.required_temperature_min ? Number(item === null || item === void 0 ? void 0 : item.required_temperature_min) : 0, item !== null && item !== void 0 && item.required_temperature_max ? Number(item === null || item === void 0 ? void 0 : item.required_temperature_max) : 0],
                  detention_included_transport: item !== null && item !== void 0 && item.detention_included_transport ? true : false,
                  package_type_other: item === null || item === void 0 ? void 0 : item.package_type_other
                }
              };
              if (!(addedVehiclesTemp !== null && addedVehiclesTemp !== void 0 && addedVehiclesTemp.find(function (item) {
                var _item$containerType, _obj_vehicle$containe, _item$selectVehicleTy2, _obj_vehicle$selectVe2;
                return (item === null || item === void 0 ? void 0 : (_item$containerType = item.containerType) === null || _item$containerType === void 0 ? void 0 : _item$containerType.id) == (obj_vehicle === null || obj_vehicle === void 0 ? void 0 : (_obj_vehicle$containe = obj_vehicle.containerType) === null || _obj_vehicle$containe === void 0 ? void 0 : _obj_vehicle$containe.id) && (item === null || item === void 0 ? void 0 : (_item$selectVehicleTy2 = item.selectVehicleTypeObj) === null || _item$selectVehicleTy2 === void 0 ? void 0 : _item$selectVehicleTy2.id) == (obj_vehicle === null || obj_vehicle === void 0 ? void 0 : (_obj_vehicle$selectVe2 = obj_vehicle.selectVehicleTypeObj) === null || _obj_vehicle$selectVe2 === void 0 ? void 0 : _obj_vehicle$selectVe2.id);
              }))) {
                addedVehiclesTemp.push(obj_vehicle);
              }
              return obj_vehicle;
            })
          };
        })
      };
      draftData.vehicleScreen = {
        addedVehicles: [],
        addedVehiclesTemp: addedVehiclesTemp,
        values: {
          cargo_description: loadDetail === null || loadDetail === void 0 ? void 0 : loadDetail.cargo_description
        }
      };
      draftData = _objectSpread(_objectSpread({}, draftData), data);
    }
    return _objectSpread({}, draftData);
  }
};