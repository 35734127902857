import React, { useState, useEffect } from "react";
import { IDashboardVehiclePageProps } from "./types";
import "./style.scss";
import rootReducer from "../../../store/reducers";
import { Colors, Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { CloudDownloadOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { CircularProgress, Grid, MenuItem, Select } from "@mui/material/";
import { CustomModal, CustomText, CustomizedSelects, MaterialUIPickers, OutLineButton, RadioButton, SimpleModal } from "@components";
import {
	Accordion,
	AccordionSummary
} from "@mui/material/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { ProfileAction } from "@temp/store/actions";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";
import InfiniteScroll from "react-infinite-scroll-component";
import { showMessage } from "@temp/utils/helper";

import { phoneNumberFormater } from "@utils/helper";
import EmptyPlaceholder from "@temp/components/EmptyPlaceholder/EmptyPlaceholder";

import Radio from "@mui/material//Radio";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InfoIcon from "@mui/icons-material/Info";


export type RootState = ReturnType<typeof rootReducer>;

var searchFlag = false
var searchVehicle = false



const Page: React.FC<IDashboardVehiclePageProps> = (props) => {
	const history = useHistory()
	const activeCategoryVehicle = useSelector((state: RootState) => state.Profile?.activeCategoryVehicle);
	const dispatch = useDispatch();
	const activeDateVehicle = useSelector((state: RootState) => state.Profile?.activeDateVehicle);
	const fromResponseDateVehicle = useSelector((state: RootState) => state.Profile?.fromResponseDateVehicle);
	const responseDateVehicle = useSelector((state: RootState) => state.Profile?.responseDateVehicle);
	const dashboardData = useSelector((state: RootState) => state.Profile?.dashboardData);
	const dashboardArry = useSelector((state: any) => state.Profile?.dashboardArry);
	const listing_data = useSelector((state: RootState) => state.Profile?.listing_data);
	const dashboard_vehicle_current_page = useSelector((state: RootState) => state?.Profile?.dashboard_vehicle_current_page);
	const dashboard_vehicle_last_page = useSelector((state: RootState) => state?.Profile?.dashboard_vehicle_last_page);
	const postLoadMeta = useSelector((state: RootState) => state?.Profile?.postLoadMeta);

	const counts = useSelector((state: RootState) => state?.Profile?.counts)
	
	

	

	

	

	
	



	const [search, setSearch] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [filterBy, setFilterBy] = useState(1)
	const [selectVehicleTypeObj, setSelectVehicleTypeObj] = useState(null);
	const [showVehicleModal, setShowVehicleModal] = useState(false);

	
	

	useEffect(() => {
		setFilterBy(1)
	}, [])

	let allVehicleTypes = postLoadMeta?.vehicle_types.filter((val) =>
		val.load_type
	);

	
	

	// for minimize six months date
	let minDate = moment(Number(moment(responseDateVehicle).format("x")) - 180 * 24 * 3600 * 1000).format('YYYY-MM-DD');
	

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let id = (event.target as HTMLInputElement).value;
		let typeVehicle = allVehicleTypes.find(
			(val) => Number(val.id) === Number(id)
		);
		setSelectVehicleTypeObj(typeVehicle);
		setShowVehicleModal(false);
		

	};

	useEffect(() => {
		setTimeout(() => {
			getVehicleScreenData({
				refresh: true,
				search,
				loads_by: activeCategoryVehicle,
				date_range_from: date_from,
				date_range_to: date_to,
				date_type: activeDateVehicle,
				stats: "vehicleDetails",
				vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",

			});
		}, 100);
		return () => {
			searchFlag = false
			searchVehicle = false
		}
	}, []);

	const debouncedSearchTerm = useDebounce(search, 500);
	

	useEffect(
		() => {
			if (debouncedSearchTerm) {
				getVehicleScreenData({
					refresh: false,
					search: search,
					fromSearch: true,
					vehicleSearch: false,
					loads_by: activeCategoryVehicle,
					date_range_from: date_from,
					date_range_to: date_to,
					date_type: activeDateVehicle,
					vehicle_type: selectVehicleTypeObj?.id,
				})
			}
		},
		[debouncedSearchTerm] // Only call effect if debounced search term changes
	);

	const debouncedSearchVehicleTerm = useDebounceVehicle(selectVehicleTypeObj, 500);
	

	useEffect(() => {
		
		if (debouncedSearchVehicleTerm) {
			getVehicleScreenData({
				refresh: true,
				search: search,
				fromSearch: false,
				vehicleSearch: true,
				loads_by: activeCategoryVehicle,
				date_range_from: date_from,
				date_range_to: date_to,
				date_type: activeDateVehicle,
				vehicle_type: selectVehicleTypeObj?.id,
			})
		}
	},
		[debouncedSearchVehicleTerm] // Only call effect if debounced search term changes
	);

	useEffect(() => {
		if (searchFlag) {
			if (search == "") {
				getVehicleScreenData({
					refresh: false,
					search: search,
					fromSearch: true,
					loads_by: activeCategoryVehicle,
					date_range_from: date_from,
					date_range_to: date_to,
					date_type: activeDateVehicle,
					vehicle_type: selectVehicleTypeObj?.id,
				})
			}

		}
		
	}, [search])


	let date_from = moment(Date.now() - 0 * 24 * 3600 * 1000).format('YYYY-MM-DD')
	let date_to = moment().format("YYYY-MM-DD")

	const handleRefresh = async () => {
		getVehicleScreenData({
			refresh: true,
			search,
			loads_by: activeCategoryVehicle,
			date_range_from: date_from,
			date_range_to: date_to,
			date_type: activeDateVehicle,
			stats: "vehicleDetails",
			vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : ""
		})
	}

	const getVehicleScreenData = (data) => {
		
		let date_from = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD')
		let date_to = moment().format("YYYY-MM-DD")
		if (activeDateVehicle == "weekly") {
			date_from = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD')
			date_to = moment().format("YYYY-MM-DD")
		} else if (activeDateVehicle == "monthly") {
			date_from = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
			date_to = moment().format("YYYY-MM-DD")
		} else if (activeDateVehicle == "today") {
			date_from = moment(Date.now()).format('YYYY-MM-DD')
			date_to = moment().format("YYYY-MM-DD")
		}
		else {
			date_from = moment(fromResponseDateVehicle).format('YYYY-MM-DD')
			date_to = moment(responseDateVehicle).format('YYYY-MM-DD')
		}
		dispatch(ProfileAction.dashboardData({
			refresh: data.refresh,
			loads_by: activeCategoryVehicle,
			date_range_from: date_from,
			date_range_to: date_to,
			date_type: activeDateVehicle,
			stats: "vehicleDetails",
			search: data?.search ? data?.search : "",
			fromSearch: data?.fromSearch,
			vehicleSearch: data?.vehicleSearch,
			vehicle_type: data?.vehicle_type ? data?.vehicle_type : "",
			next_page:
				data?.refresh || data?.fromSearch ||
					search
					? 1
					: Number(dashboard_vehicle_current_page) + 1,

		}))
	};

	const callBack = (param) => {
		
		if (param == "422") {
			setShowModal(true)
		}
	}

	const handleSubmit = (active: string) => {
		
		dispatch(ProfileAction.dashboardData({
			refresh: true,
			loads_by: activeCategoryVehicle,
			date_range_from: moment(fromResponseDateVehicle).format("YYYY-MM-DD"),
			date_range_to: moment(responseDateVehicle).format("YYYY-MM-DD"),
			date_type: activeDateVehicle,
			stats: "vehicleDetails",
			search,
			vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",
			next_page:
				search || active ? 1 :
					Number(dashboard_vehicle_current_page) + 1,
			callBack
		}))
	}

	const handleClickCategory = (type: string) => {
		dispatch(ProfileAction.setVehicleFilter({
			activeCategoryVehicle: type,
			activeDateVehicle,
			fromResponseDateVehicle,
			responseDateVehicle,
		}))
		if (activeDateVehicle == 'weekly') {
			let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
			let end_date = moment().format('YYYY-MM-DD');
			dispatch(ProfileAction.dashboardData({
				loads_by: type,
				date_range_from: start_date,
				date_range_to: end_date,
				date_type: activeDateVehicle,
				stats: "vehicleDetails",
				refresh: true,
				search: search,
				vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",
				next_page:
					search || type ? 1 :
						Number(dashboard_vehicle_current_page) + 1
			}))
		} else if (activeDateVehicle == 'monthly') {
			let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
			let end_date = moment().format('YYYY-MM-DD');
			dispatch(ProfileAction.dashboardData({
				loads_by: type,
				date_range_from: start_date,
				date_range_to: end_date,
				date_type: activeDateVehicle,
				stats: "vehicleDetails",
				refresh: true,
				search: search,
				vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",
				next_page:
					search || type ? 1 :
						Number(dashboard_vehicle_current_page) + 1
			}))
		} else if (activeDateVehicle == "today") {
			let start_date = moment(Date.now()).format('YYYY-MM-DD')
			let end_date = moment().format('YYYY-MM-DD');
			dispatch(ProfileAction.dashboardData({
				loads_by: type,
				date_range_from: start_date,
				date_range_to: end_date,
				date_type: activeDateVehicle,
				stats: "vehicleDetails",
				refresh: true,
				search: search,
				vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",
				next_page:
					search || type ? 1 :
						Number(dashboard_vehicle_current_page) + 1
			}))

		}
		else if (activeDateVehicle == 'date') {
			dispatch(ProfileAction.dashboardData({
				loads_by: type,
				date_range_from: moment(fromResponseDateVehicle).format("YYYY-MM-DD"),
				date_range_to: moment(responseDateVehicle).format("YYYY-MM-DD"),
				date_type: activeDateVehicle,
				stats: "vehicleDetails",
				refresh: true,
				search: search,
				vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",
				next_page:
					search || type ? 1 :
						Number(dashboard_vehicle_current_page) + 1

			}))
		}
	};

	const handleDateFilter = (type) => {
		
		dispatch(ProfileAction.setVehicleFilter({
			activeCategoryVehicle: activeCategoryVehicle,
			activeDateVehicle: type,
			fromResponseDateVehicle,
			responseDateVehicle,
		}))
		if (type == 'weekly') {
			let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
			let end_date = moment().format('YYYY-MM-DD');
			dispatch(ProfileAction.dashboardData({
				loads_by: activeCategoryVehicle,
				date_range_from: start_date,
				date_range_to: end_date,
				date_type: type,
				stats: "vehicleDetails",
				refresh: true,
				search: search,
				vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",
				next_page:
					search || type ? 1 :
						Number(dashboard_vehicle_current_page) + 1
			}))
		} else if (type == 'monthly') {
			let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
			let end_date = moment().format('YYYY-MM-DD');
			dispatch(ProfileAction.dashboardData({
				loads_by: activeCategoryVehicle,
				date_range_from: start_date,
				date_range_to: end_date,
				date_type: type,
				stats: "vehicleDetails",
				refresh: true,
				search: search,
				vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",
				next_page:
					search || type ? 1 :
						Number(dashboard_vehicle_current_page) + 1
			}))
		}
		else if (type == 'today') {
			let start_date = moment(Date.now()).format('YYYY-MM-DD')
			let end_date = moment().format('YYYY-MM-DD');
			dispatch(ProfileAction.dashboardData({
				loads_by: activeCategoryVehicle,
				date_range_from: start_date,
				date_range_to: end_date,
				date_type: type,
				stats: "vehicleDetails",
				refresh: true,
				search: search,
				vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",
				next_page:
					search || type ? 1 :
						Number(dashboard_vehicle_current_page) + 1
			}))
		}

		else if (type == 'date') {
			let start_date = moment(fromResponseDateVehicle).format('YYYY-MM-DD')
			let end_date = moment(responseDateVehicle).format('YYYY-MM-DD');
			
			dispatch(ProfileAction.dashboardData({
				loads_by: activeCategoryVehicle,
				date_range_from: start_date,
				date_range_to: end_date,
				date_type: type,
				stats: "vehicleDetails",
				refresh: true,
				search: search,
				vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",
				next_page:
					search || type ? 1 :
						Number(dashboard_vehicle_current_page) + 1
			}))
		}
	}

	return (
		<div style={{
			height: 'calc(100vh - 15px)',
			overflow: 'auto'
		}} className="root-center-container">
			<div className="loads-top-wrappers">
				<div className="topDropDownWrap">
					<div
						className="drop-down"
						style={{ justifyContent: "space-between" }}
					>
						<button onClick={() => {
							history.goBack()
						}} className="header-buttons">
							<img
								src={Images.BackArrow}
								alt="Back"
							/>
						</button>
						<p className="title">Vehicles Details</p>
						<div>
							<CloudDownloadOutlined style={{ cursor: "pointer" }}
								onClick={() => {
									if (activeDateVehicle == 'weekly') {
										let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
										let end_date = moment().format('YYYY-MM-DD');
										dispatch(ProfileAction.downloadCsvReports({
											loads_by: activeCategoryVehicle,
											date_range_from: start_date,
											date_range_to: end_date,
											date_type: activeDateVehicle,
											report: "vehicleDetails",
											search: search ? search : "",
											vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : ""
										}))
									} else if (activeDateVehicle == 'monthly') {
										let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
										let end_date = moment().format('YYYY-MM-DD');
										dispatch(ProfileAction.downloadCsvReports({
											loads_by: activeCategoryVehicle,
											date_range_from: start_date,
											date_range_to: end_date,
											date_type: activeDateVehicle,
											report: "vehicleDetails",
											search: search ? search : "",
											vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : ""
										}))
									} else if (activeDateVehicle == 'date') {
										dispatch(ProfileAction.downloadCsvReports({
											loads_by: activeCategoryVehicle,
											date_range_from: moment(fromResponseDateVehicle).format("YYYY-MM-DD"),
											date_range_to: moment(responseDateVehicle).format("YYYY-MM-DD"),
											date_type: activeDateVehicle,
											report: "vehicleDetails",
											search: search ? search : "",
											vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : ""
										}))
									} else if (activeDateVehicle == 'today') {
										let start_date = moment(Date.now()).format('YYYY-MM-DD')
										let end_date = moment().format('YYYY-MM-DD');
										dispatch(ProfileAction.downloadCsvReports({
											loads_by: activeCategoryVehicle,
											date_range_from: start_date,
											date_range_to: end_date,
											date_type: activeDateVehicle,
											report: "vehicleDetails",
											search: search ? search : "",
											vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : ""
										}))
									}
								}}
							/>
						</div>
					</div>
					<div className="tabContainerMainWrap reportLoadListing">
						{/* Top Tabs Posted recieved  */}
						{
							<FadeInComponent>
								<div className={`tab-btn radioTabs twoTab`}>
									<div
										onClick={() => {
											handleClickCategory("posted");
										}}
										className={`two-category-button  ${activeCategoryVehicle === "posted" ? "active" : ""
											}`}
									>
										Posted
									</div>
									<div
										onClick={() => {
											handleClickCategory("received");
										}}
										className={`two-category-button ${activeCategoryVehicle === "received" ? "active" : ""
											}`}
									>
										Received
									</div>
									<div className="radioTab_slider"></div>
								</div>
							</FadeInComponent>
						}
						{/* Top Tabs date filters  */}
						<div className="tabContainerMainWrap reportLoadListing" style={{ display: 'none' }}>
							{
								<FadeInComponent className='pr-2'>
									<div className={`tab-btn radioTabs twoTab`}>
										<div
											onClick={() => {
												handleDateFilter("today");
											}}
											className={`two-category-button ${activeCategoryVehicle === "today" ? "active" : ""
												}`}
										>
											Today
										</div>
										<div
											onClick={() => {
												handleDateFilter("weekly");
											}}
											className={`two-category-button ${activeCategoryVehicle === "weekly" ? "active" : ""
												}`}
										>
											Weekly
										</div>
										<div
											onClick={() => {
												handleDateFilter("monthly");
											}}
											className={`two-category-button ${activeCategoryVehicle === "monthly" ? "active" : ""
												}`}
										>
											Monthly
										</div>

										<div className="radioTab_slider"></div>
									</div>
								</FadeInComponent>
							}
						</div>
						<div className="vehcileDetailListing">
							<div className="newVehicleSelectDate">
								<div className="tabContainerMainWrap reportLoadListing">
									<Select
										id="outlined-select-date-native"
										select
										defaultValue={activeDateVehicle}
										name=""
										onChange={(e) => handleDateFilter(e.target.value)}
									>
										<MenuItem value={'today'}>
											Today
										</MenuItem>
										<MenuItem value={'weekly'}>
											Weekly
										</MenuItem>
										<MenuItem value={'monthly'}>
											Monthly
										</MenuItem>
										<MenuItem value={'date'}>
											Date Filter
										</MenuItem>
									</Select>
									{
										activeDateVehicle == "date" ?
											<FadeInComponent className="dateLabel">
												<div className="resports-date-filter-wraper">
													<div onClick={() => {
														setShowModal(true)
													}} style={{ border: `1px solid ${Colors.NavyBlue}` }} className="reports-date-filter">
														<span className="icons-calender calenderIcon"></span>
														<p style={{ color: Colors.NavyBlue, marginTop: 4 }}>DATE FILTER</p>
													</div>
													<div>
														{
															fromResponseDateVehicle && responseDateVehicle ?
																<p className="label">{`${moment(fromResponseDateVehicle).format('ll')} - ${moment(responseDateVehicle).format('ll')}`}</p> : null
														}
													</div>
												</div>
											</FadeInComponent>
											: null}

								</div>
							</div>
							<div className="vehicleDetailSearchBar">
								<div className={`searchContainer ${search ? "filled" : ""}`}>
									<input
										className="searchInput"
										onChange={(e) => {
											setSearch(e.target.value)
											searchFlag = true
										}}
										type="text"
										autoComplete="off"
										name="search"
										style={{ width: "100%" }}
										value={search}
										placeholder="Search by Plate Number, Load ID & Company"
									/>
									<span className={`icons-search ${search ? '' : 'active'}`}></span>
									<span
										className={`icons-cross ${search ? 'active' : ''}`}
										onClick={() => {
											setSearch("");
										}}
									></span>
								</div>
							</div>

							<div className="vehicleSelectDropDown">
								<div className={`customSelectBox`}>
									{selectVehicleTypeObj?.title ? (
										<>
											{selectVehicleTypeObj.title}
											<span
												className={'remove-select'}
												onClick={() => {
													setSelectVehicleTypeObj([]);
												}}
											></span>
										</>
									) : (
										"Search by Vehicle Category"
									)}
									<div onClick={() => {
										setShowVehicleModal(true);
										searchVehicle = true
									}} className="fakeLinkDiv"></div>
									<ArrowDropDownIcon />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mainVehicleTopBoxes">
					<div className="vehicleDetailTopBoxes">
						<div className="insideVehicleDetailTopBox">
							<span>{counts?.awarded_vehicles}</span>
							<label>Awarded Vehicles</label>
						</div>

						<div className="insideVehicleDetailTopBox">
							<span>{counts?.placed_vehicles}</span>
							<label>Vehicles Placed</label>
						</div>
					</div>
				</div>
			</div>
			<div className={`load-listing-container remCustomPadonMob tabContainerMainWrap reportLoadListing ${activeDateVehicle === "date" ? "withDate" : "withoutDate"}`}>
				{/* pagination work */}
				<div style={{
				}}>
					<div>
						{dashboardArry?.length ?
							<PullToRefresh
								onRefresh={handleRefresh}
							>
								<InfiniteScroll
									dataLength={dashboardArry?.length}
									next={() => {
										
										getVehicleScreenData({
											refresh: false,
											search,
											loads_by: activeCategoryVehicle,
											date_range_from: date_from,
											date_range_to: date_to,
											date_type: activeDateVehicle,
											stats: "vehicleDetails",
											vehicle_type: selectVehicleTypeObj?.id ? selectVehicleTypeObj?.id : "",
										})
									}}
									hasMore={dashboard_vehicle_last_page > dashboard_vehicle_current_page}
									loader={<div className="load-more-loader"> <CircularProgress /></div>}
									// style={{ padding: 15 }}
									height={'calc(100vh - 242px)'}
									scrollThreshold={.8}
								>
									<Grid container>
										<Grid item xs={12} className="vehicleDetailsDataMainWrap">
											{dashboardArry?.length > 0 ?
												dashboardArry?.map((val, index) => {
													
													return (
														<Accordion className="accordion-container newDashboardDetailContainer">
															<AccordionSummary
																className="accordion-background"
																expandIcon={<ExpandMoreIcon />}
																aria-controls="panel1a-content"
																id="panel1a-header"
															>
																<div className="counterDiv">
																	<span>{index + 1}</span>
																</div>
																<div className="showDataFirst">
																	<div className="showDataFirst">
																		<div className="dataFirstBox firstDataBox">
																			<label>Load Id</label>
																			<span>{val?.load_id}</span>
																		</div>
																		<div className="dataFirstBox">
																			<label>Plate No</label>
																			<span>{val?.plate_no}</span>
																		</div>
																		<div className="dataFirstBox">
																			<label>Mobile No</label>
																			<span>
																				{`${phoneNumberFormater(val?.phone_code, val?.phone_no)}`}
																			</span>
																		</div>
																		<div className="dataFirstBox lastDataBox">
																			<label>Vehicle Category</label>
																			<span>{val?.vehicle_category}</span>
																		</div>
																	</div>
																</div>
															</AccordionSummary>
															<div
																className="accordion-content"
															>
																<div className="vehicleSummaryData">
																	<div className="subVehicleSummaryData">
																		<label>
																			{
																				activeCategoryVehicle == "posted" ? "Transport By" : "Posted By"
																			}

																		</label>
																		{
																			activeCategoryVehicle == "received" ?
																				<>
																					<div className="w-100">
																						{val?.poster_associated_company ?
																							<>
																								{val?.poster_associated_company ? (
																									<p className="companyTitle">
																										{val?.poster_associated_company
																											? val?.poster_associated_company || ""
																											: ""
																										}
																									</p>
																								) : ""}
																								{val?.poster_name ? <p className="name">{val?.poster_name || ""} </p> : ""}
																							</> :
																							<>
																								{val?.poster_name ? <p className="companyTitle">{val?.poster_name || ""}</p> : ""}
																								{val?.poster_company_name ? <p className="name">{val.poster_company_name}</p> : ""}
																							</>
																						}
																					</div>


																				</> :
																				<>
																					<div className="w-100">
																						{val?.transporter_associated_company ?
																							<>
																								{val?.transporter_associated_company ? (
																									<p className="companyTitle">
																										{val?.transporter_associated_company
																											? val?.transporter_associated_company || ""
																											: ""
																										}
																									</p>
																								) : ""}
																								{val?.transporter_name ? <p className="name">{val?.transporter_name || ""} </p> : ""}
																							</> :
																							<>
																								{val?.transporter_name ? <p className="companyTitle">{val?.transporter_name || ""}</p> : ""}
																								{val?.transporter_company_name ? <p className="name">{val.transporter_company_name}</p> : ""}
																							</>
																						}
																					</div>

																				</>
																		}

																	</div>

																	<div className="subVehicleSummaryData">
																		<label>Vehicle Placed On</label>
																		<span>{`${moment(val?.created_at).format("MMM DD, YYYY hh:mm A")}`}</span>
																	</div>

																	<div className="subVehicleSummaryData">
																		<label>Load Type</label>
																		<span>{val?.load_type}</span>
																	</div>

																	<div className="subVehicleSummaryData">
																		<label>Pick up</label>
																		<span>{val?.pickup}</span>
																	</div>
																	<div className="subVehicleSummaryData">
																		<label>Drop Off</label>
																		<span>{val?.drop_off}</span>
																	</div>
																	{
																		val?.empty_pickup_return !== "-" ?
																			<div className="subVehicleSummaryData">
																				<label>Empty Pickup/Return</label>
																				<span>{val?.empty_pickup_return}</span>
																			</div>
																			: null}
																</div>
															</div>
														</Accordion>
													)
												}) : null
											}

										</Grid>
									</Grid>

								</InfiniteScroll>
							</PullToRefresh>
							:
							<div className="network-empty-component">
								<EmptyPlaceholder
									title="No Data Found!"
								/>
							</div>
						}
					</div>
				</div>

				<CustomModal showModal={showModal} toggleModal={setShowModal}>
					<div>
						<div style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							marginTop: 0
						}}>
							<div className="error-container info customInfoText dashboardCustomModalInfoText">
								<div>
									<InfoIcon className="error-icon" />
								</div>
								<div className="error-text">
									You can select upto 6 months data
								</div>
							</div>
							<span
								style={{
									right: 15,
									top: 30
								}}
								className="icons-cross directionRight"
								onClick={() => setShowModal(false)}
							></span>
						</div>
					</div>

					<div style={{ marginBottom: 10, marginTop: 15 }} className="inputField">
						<div className="label">From</div>
						<div className="DatePickerFieldWrap">
							<MaterialUIPickers
								date={fromResponseDateVehicle}
								setDate={(e) => {
									
									if (e > responseDateVehicle) {
										
										showMessage.current({
											message:
												"From date should be less than To date.",
											status: 'error'
										})
									}
									dispatch(ProfileAction.setVehicleFilter({
										activeCategoryVehicle: activeCategoryVehicle,
										activeDateVehicle: activeDateVehicle,
										fromResponseDateVehicle: e,
										responseDateVehicle,
									}))

								}}
								minDate={"1900/01/01"}
								maxDate={new Date()}
							/>
						</div>
					</div>
					<div style={{ marginBottom: 10, pointerEvents: fromResponseDateVehicle ? "auto" : 'none' }} className="inputField">
						<div className="label">To</div>
						<div className="DatePickerFieldWrap">
							<MaterialUIPickers
								date={responseDateVehicle}
								setDate={(e) => {
									
									if (fromResponseDateVehicle > e) {
										
										showMessage.current({
											message:
												"From date should be less than To date.",
											status: 'error'
										})
									}
									dispatch(ProfileAction.setVehicleFilter({
										activeCategoryVehicle: activeCategoryVehicle,
										activeDateVehicle: activeDateVehicle,
										fromResponseDateVehicle: fromResponseDateVehicle,
										responseDateVehicle: e,
									}))
								}}
								minDate={"1900/01/01"}
								maxDate={new Date()}
							/>
						</div>
					</div>

					<OutLineButton
						classProp='tinyBtn'
						title="Apply"
						onClick={() => {
							handleSubmit("active")
							setShowModal(false)
						}}
						buttonStyle={{
							height: 36,
							width: '100%'
						}}
						disabled={fromResponseDateVehicle > responseDateVehicle}
					/>
				</CustomModal>

				{/* vehicle type dropdown modal  */}
				<CustomModal showModal={showVehicleModal} toggleModal={setShowVehicleModal} className={'vehicleSelect'}>
					<div>
						<FadeInComponent>
							<div className="titleSection">
								<span>Select Vehicle Category</span>
								<span
									style={{
										right: 3,
										top: 1
									}}
									className="icons-cross directionRight"
									onClick={() => setShowVehicleModal(false)}
								></span>
							</div>
							<div className="tabWrapper">
								{/* Tab 1: Containerized */}
								<RadioButton
									onChange={() => {
										setFilterBy(1)
									}}
									id={1}
									name="filter_by"
									label="Containerized"
									value={filterBy}
									defaultChecked={true}
								/>
								{/* Tab 2: Non-Containerized */}
								<RadioButton
									id={3}
									name="filter_by"
									value={filterBy}
									onChange={() => {
										setFilterBy(3);
									}}
									label="Non-Containerized"
								/>
								<div className="radioTab_slider"></div>
							</div>
						</FadeInComponent>
					</div>

					<div className="RadioButtonWrapper">
						{allVehicleTypes?.filter((item) => item?.load_type == filterBy)?.map((type) => {
							
							return (
								<div className="flexRow">
									<Radio
										checked={selectVehicleTypeObj?.id === type?.id}
										onChange={handleChange}
										value={type?.id}
										style={{
											color: "#7ac251",
										}}
									/>
									<CustomText.OpenSansRegular
										textStyle={{ fontSize: "0.875rem", marginLeft: 10 }}
									>
										{type?.title}
									</CustomText.OpenSansRegular>
								</div>
							);
						})}
					</div>
				</CustomModal>
			</div>
		</div>
	);

};
export default Page;

function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(
		() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay]
	);
	return debouncedValue;
}

function useDebounceVehicle(value, delay) {
	const [debouncedVehicleValue, setDebouncedVehicleValue] = useState(value);
	useEffect(
		() => {
			const handler = setTimeout(() => {
				setDebouncedVehicleValue(value);
			}, delay);
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay]
	);
	return debouncedVehicleValue;
}

