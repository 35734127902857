import React, { useEffect, useState } from "react";
import { IVehiclePageProps } from "./types";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DrawerAction, ProfileAction } from "@temp/store/actions";
import { CustomModal, CustomText, OutLineButton } from "@components";
import rootReducer from "../../../store/reducers";
import { MenuItem, Select } from "@mui/material/";
import { AddCircleOutlineOutlined, MoreVert } from "@mui/icons-material";
import DialogTitle from "@mui/material//DialogTitle";
import { Button } from "@mui/material/";
import TitleLayout from "@temp/components/TitleLayout";
import EmptyPlaceholder from "@temp/components/EmptyPlaceholder/EmptyPlaceholder";
import { ContactModal } from "@temp/components/Modals/ContactModal";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { phoneNumberFormater } from "@temp/utils/helper";

export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<IVehiclePageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location
  const vehicleData = useSelector((state: RootState) => state.Drawer.vehicleData);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [search, setSearch] = useState("");
  

  useEffect(() => {
    dispatch(ProfileAction.JobLoadMeta(0));
    dispatch(DrawerAction.GetVehicleSelector());
  }, []);

  const getSearchedNetwork = (params) => {
    dispatch(DrawerAction.SearchNetwork(params));
  };

  const deleteVehicle = () => {
    setShow(false);
    dispatch(DrawerAction.DeleteVehicleSelector({ id: currentId }));
  };

  return (
    <TitleLayout titleOnly={true} titleOnlyText="Vehicles">
      <div className="vehicleContentWrapper">
        <div className="headerContainer">
          <FadeInComponent>
            <div className={`searchContainer ${search ? "filled" : ""}`}>
              <input
                className="searchInput"
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                autoComplete="off"
                name="search"
                style={{ width: "100%" }}
                value={search}
                placeholder="Search by Plate Number or Vehicle"
              />
              <span className={`icons-search ${search ? '' : 'active'}`}></span>
              <span
                className={`icons-cross ${search ? 'active' : ''}`}
                onClick={() => {
                  setSearch("");
                  getSearchedNetwork("");
                }}
              ></span>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <OutLineButton
              classProp='tinyBtn'
              dataColor="blue"
              title="Add new"
              icon={
                <AddCircleOutlineOutlined />
              }
              onClick={() => {
                history.push("/add-vehicle");
              }}
            />
          </FadeInComponent>
        </div>

        <div className="vehicleContentWrap">
          <div className="vehicle-wrapper">
            {vehicleData?.length ? (
              <>
                {vehicleData
                  .filter(
                    (item) =>
                      item?.plate_number
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      (item?.master_vehicle_type ? item?.master_vehicle_type?.title : "")
                        .toLowerCase()
                        .includes(search.toLowerCase())
                  )
                  ?.map((item, index) => {
                    return (
                      <FadeInComponent noWidth={false} className="info-wrapper box">
                        <div
                          onClick={(e) => {
                            history.push("/add-vehicle", {
                              id: item?.id,
                              data: {
                                ...item,
                                vehicle_no: location?.state?.vehicle_no,
                                load_id: location?.state?.load_id,
                                cat_id: location?.state?.cat_id,
                                containers: state?.containers
                              },
                              from: state?.from ? "detail" : 'drawer',
                            });

                          }}
                          className="vehicleBoxWrapper"
                        >
                          <div className="insideVehicleWrapper">
                            <div className="img-container">
                              <CustomText.OpenSansSemiBold
                              >
                                {item?.plate_number}
                              </CustomText.OpenSansSemiBold>
                            </div>
                            {!location?.state?.from && (
                              <Select value="" IconComponent={() => <MoreVert />}>
                                <MenuItem
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    history.push("/add-vehicle", {
                                      id: item?.id,
                                      data: {
                                        ...item,
                                        vehicle_no: location?.state?.vehicle_no,
                                      },
                                      from: "edit",
                                    });
                                  }}
                                  value="Loads Posted"
                                >
                                  <CustomText.OpenSansSemiBold
                                    textStyle={{ fontSize: "0.875rem" }}
                                  >
                                    Edit
                                  </CustomText.OpenSansSemiBold>
                                </MenuItem>

                                <MenuItem
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setCurrentId(item?.id);
                                    setShow(true);
                                  }}
                                  value="Loads Received"
                                >
                                  <CustomText.OpenSansSemiBold
                                    textStyle={{ fontSize: "0.875rem" }}
                                  >
                                    Delete
                                  </CustomText.OpenSansSemiBold>
                                </MenuItem>
                              </Select>
                            )}
                          </div>
                          <div className="user-details">
                            <div className="bigFont">
                              <CustomText.OpenSansRegular
                                
                              >
                                {item?.master_vehicle_type?.title}
                              </CustomText.OpenSansRegular>
                            </div>
                            <div className="divider"></div>
                            <div className="smallFont">
                              <CustomText.OpenSansSemiBold
                                
                              >
                                {item?.driver_name}
                              </CustomText.OpenSansSemiBold>
                            </div>
                            <div className="numberFont">
                              <CustomText.OpenSansRegular
                                
                              >
                                {/* {`+${item?.driver?.phone_code} ${item?.driver?.phone_no}`} */}
                                {`${`${phoneNumberFormater(item?.driver?.phone_code, item?.driver?.phone_no)}`} `}
                              </CustomText.OpenSansRegular>
                            </div>
                          </div>
                        </div>
                      </FadeInComponent>
                    );
                  })}
              </>
            ) : (
              <div className="network-empty-component">
                <EmptyPlaceholder
                  image={Images.noVehicleNew}
                  title="No Vehicles Added!"
                  detail="You have no vehicle added,
                  Please add vehicle."
                  buttonText="Add New Vehicle"
                  onClick={() => {
                    history.push("/add-vehicle");
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ContactModal showModal={showModal} setShowModal={setShowModal} />

      <CustomModal
        showModal={show}
        toggleModal={setShow}
        modalStyle={{
          width: 290,
        }}
      >
        <div className="logout-modal-background">
          <DialogTitle id="simple-dialog-title">
            <CustomText.OpenSansSemiBold
              textStyle={{ fontSize: "1.25rem", textAlign: "center" }}
            >
              DELETE
            </CustomText.OpenSansSemiBold>
            <CustomText.OpenSansRegular
              textStyle={{
                color: Colors.Grayish,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Are you sure you want to delete?
            </CustomText.OpenSansRegular>
            <div className="action-button-container">
              <Button
                onClick={() => {
                  setShow(false);
                }}
                style={{
                  backgroundColor: Colors.Grayish,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  Cancel
                </CustomText.OpenSansSemiBold>
              </Button>
              <Button
                onClick={() => {
                  deleteVehicle();
                }}
                style={{
                  backgroundColor: Colors.Primary,
                  width: 96,
                  height: 40,
                }}
              >
                <CustomText.OpenSansSemiBold
                  textStyle={{ color: Colors.White }}
                >
                  DELETE
                </CustomText.OpenSansSemiBold>
              </Button>
            </div>
          </DialogTitle>
        </div>
      </CustomModal>
    </TitleLayout>
  );
};

export default Page;
