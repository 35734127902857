export default class Action {
  // Constants
  static SET_POSTLOAD_COUNTER_DATA = "SET_POSTLOAD_COUNTER_DATA";
  static RESET_POSTLOAD_COUNTER_DATA = "RESET_POSTLOAD_COUNTER_DATA";

  static RESPOND_TO_LOAD_COUNTER_DATA = "RESPOND_TO_LOAD_COUNTER_DATA"


  // Actions
  static setPostloadCounterData(payload) {
    return {
      type: Action.SET_POSTLOAD_COUNTER_DATA,
      payload,
    };
  }

  static resetPostloadCounterData() {
    return {
      type: Action.RESET_POSTLOAD_COUNTER_DATA,
    };
  }

  static respondToLoadCounterData(payload) {
    return {
      type: Action.RESPOND_TO_LOAD_COUNTER_DATA,
      payload,
    };
  }
}
