import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./style.scss";
import {
  CustomizedSwitches,
  CustomText,
  CustomModal,
  NewInput,
  OutLineButton,
  TextInput,
} from "@components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Select,
  MenuItem,
} from "@mui/material/";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { ExpandMore } from "@mui/icons-material";
import { LOAD_TYPE } from "@temp/constants";
var numeral = require("numeral");
import { Images } from "@temp/assets";
import rootReducer from "../../../store/reducers";
import { useHistory } from "react-router-dom";
import { HistoryVehicle } from "../ConfirmLoad/HistoryVehicles";
import { price_units_transalation } from "@temp/utils/helper";


export type RootState = ReturnType<typeof rootReducer>;

export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 100%;
  background-color: rgb(250, 250, 250);
  margin-bottom: 20px;
  border-radius: 10px;
  border: 0;
`;


export const CategoryContainer = ({
  onChangePrice,
  title,
  value,
  onChangeValue,
  screen,
  vehicleScreenData,
  active = true,
  showTime = false,
  otherData,
  fromHistory = false,
  index,
  editable,
  is_recipient,


}) => {


  const PostLoad = useSelector((state: RootState) => state?.Profile?.postLoad);
  const hoursData = useSelector(
    (state: RootState) => state?.Profile?.postLoadMeta?.detention_free_time_hrs?.map((item) => { return { key: `${item} hrs`, value: item } })
  );
  const [info, setInfo] = useState("");
  const [showModal, toggleModal] = useState(false);

  const profile = useSelector((state: any) => state.Profile.profile);
  const orignal_recipient_id = PostLoad?.is_allocated ? PostLoad?.allocates[0]?.allocated_by : profile?.id

  const history = useHistory();
  const onEdit = () => {
    history.push("/selectVehicle");
  };

  const FormRow = ({ label, value, sep, urdutitle, is_recipient, fromHistory }) => {
    return (
      <Grid item xs={12} className="formGrid">
        <div className="grayLabel iconLabel customTextClassCargo" style={{ marginBottom: '0.25rem' }}>
          <span className="titleName">{label}</span>
          {
            fromHistory && is_recipient &&
            <>
              <span className="seperator">
                {sep}
              </span>
              <span className="urduLabel lhEdit">
                {urdutitle}
              </span>
            </>
          }
          {label == "Total Offer Price" ? (
            <InfoIcon
              onClick={() => {
                setInfo(
                  "Inclusive of Free Time, Loading (if applicable) and Unloading (if applicable) per F.Ton.."
                );
                toggleModal(true);
              }}
            />
          ) : null}
        </div>
        <div className="fw-5">{value}</div>
      </Grid>
    );
  };

  const [isExpanded, setIsExpanded] = useState(active);
  const handleChange = (check) => {
    setIsExpanded(check);
  };

  const SwitchableWithText = (props: any) => {
    return (
      <Grid
        container
        spacing={1}
        style={{ marginBottom: 0, alignItems: "center" }}
        className="SwitchableWithText"
      >
        <Grid item xs={10}>
          <div className="clr-label mb-0">{props?.text}</div>
        </Grid>

        <Grid
          item
          xs={2}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div style={{ marginTop: 0 }}>
            <CustomizedSwitches {...props} />
          </div>
        </Grid>
      </Grid>
    );
  };

  const [prices_units, set_prices_units] = useState([]);

  useEffect(() => {
    if (value?.cargo_volume && value?.gross_weight && value?.cargo_liter) {
      set_prices_units(["Ton", "F.Ton", "CBM", "Liter", "Kg"])
    } else if (value?.cargo_volume && value?.gross_weight && !value?.cargo_liter) {
      set_prices_units(["Ton", "F.Ton", "CBM", "Kg"])
    } else if (value?.gross_weight && value?.cargo_liter && !value?.cargo_volume) {
      set_prices_units(["Ton", "Liter", "Kg"])
    } else if (value?.gross_weight && !value?.cargo_liter && !value?.cargo_volume) {
      set_prices_units(["Ton", "Kg"])
    }
  }, [])
  return (
    <>
      <Maincontainer>
        <CustomModal
          modalStyle={{
            width: 300,
            minHeight: 100,
          }}
          showModal={showModal}
          toggleModal={toggleModal}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InfoIcon color="primary" style={{ fontSize: 40 }} />
            <CustomText.OpenSansRegular>{info}</CustomText.OpenSansRegular>
          </div>
        </CustomModal>

        <Accordion
          expanded={isExpanded}
          onChange={() => handleChange(!isExpanded)}
          style={{ backgroundColor: "rgb(250,250,250)" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <label className="fw-6" style={{ fontWeight: "bold" }}>
              Cargo Details
              {
                fromHistory && is_recipient &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">کارگو کی تفصیلات</span>
                </>
              }
            </label>

            {editable ? (
              <div className="editBtnWrapper">
                <OutLineButton
                  classProp='tinyBtn'
                  dataColor="blue"
                  title="Edit"
                  icon={
                    <span className="icons-edit"></span>
                  }
                  onClick={onEdit}
                />
              </div>
            ) : null}
          </AccordionSummary>

          <AccordionDetails style={{ paddingTop: 0, paddingBottom: 10 }}>
            <Grid
              container
              spacing={1}
              style={{ overflowY: "scroll", margin: 0 }}
            >
              <FormRow
                label="Gross Weight in KG"
                value={
                  value.gross_weight
                    ? numeral(value.gross_weight_in_kgs).format("0,0.00") + " KG"
                    : ""
                }
                sep="/"
                urdutitle="مجموعی وزن (کلو)"
                is_recipient={is_recipient}
                fromHistory={fromHistory}

              />

              <FormRow
                label="Gross Weight in Ton"
                value={
                  value.gross_weight
                    ? numeral(value.gross_weight).format("0,0.00") + " TON"
                    : ""
                }
                sep="/"
                urdutitle="مجموعی وزن (ٹن)"
                is_recipient={is_recipient}
                fromHistory={fromHistory}


              />

              {value.cargo_volume && (
                <FormRow
                  label="Cargo Volume"
                  value={
                    value.cargo_volume
                      ? numeral(value.cargo_volume).format("0,0.00") + " CBM"
                      : ""
                  }
                  urdutitle={"کارگو والیوم"}
                  sep="/"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}

                />
              )}

              {
                value?.quote_by === "vehicle" ?
                  <FormRow
                    label={
                      "Total Weight"
                    }
                    value={`${numeral(value?.total_freight_ton).format("0,0.00")} ${value?.gross_weight && value?.cargo_volume ? " F.Ton" : " TON"}`
                    }
                    urdutitle={"کل وزن"}
                    sep="/"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}

                  /> :
                  <FormRow
                    label={
                      "Total Weight"
                    }
                    value={`${numeral(value?.total_freight_ton).format("0,0.00")} ${value?.gross_weight && value?.cargo_volume ? " F.Ton" : " TON"}`}
                    urdutitle={"کل وزن"}
                    sep="/"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}

                  />

              }
              {value.no_of_packages ?
                <FormRow
                  label="Number of Packages"
                  value={value.no_of_packages ? numeral(value.no_of_packages).format('0,0'): "N/A"}
                  urdutitle={"پیکیجز کی تعداد"}
                  sep="/"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}

                /> : null}

              {value.cargo_liter ?
                <FormRow
                  label="Liter"
                  value={
                    value.cargo_liter ? numeral(value.cargo_liter).format('0,0.00') + " " + "liter" : "N/A"
                  }
                  urdutitle={"لیٹر"}
                  sep="/"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}

                /> : null}
              <FormRow
                label="Packaging Type"
                value={
                  vehicleScreenData?.packageType?.title === "Other"
                    ? vehicleScreenData?.packageType?.title +
                    " (" +
                    vehicleScreenData?.values?.package_type_other +
                    ")"
                    : vehicleScreenData?.packageType?.title || ""
                }
                urdutitle={"پیکیجنگ کی قسم"}
                sep="/"
                is_recipient={is_recipient}
                fromHistory={fromHistory}

              />

              {vehicleScreenData?.PreferredVehicleType ? (
                <FormRow
                  label="Vehicle Type"
                  value={vehicleScreenData?.PreferredVehicleType?.title || ""}
                  urdutitle={" گاڑی کی قسم"}
                  sep="/"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}

                />
              ) : null}

              {screen && screen !== "additional_details" && (
                <>
                  {value.is_loading_required ? (
                    <FormRow
                      label="Loading Required"
                      value={value.is_loading_required ? "YES" : "NO"}
                      urdutitle={"لوڈنگ"}
                      sep="/"
                      is_recipient={is_recipient}
                      fromHistory={fromHistory}

                    />
                  ) : null}

                  {value?.is_loading_required && value.loading_requirements && (
                    <FormRow
                      label="Labour / Equipment "
                      value={
                        value.loading_requirements
                          ? value.loading_requirements
                          : ""
                      }
                      urdutitle={"لوڈنگ درکار ہے۔"}
                      sep="/"
                      is_recipient={is_recipient}
                      fromHistory={fromHistory}

                    />
                  )}

                  {value.is_unloading_required ? (
                    <FormRow
                      label="Unloading Required"
                      value={value.is_unloading_required ? "YES" : "NO"}
                      urdutitle={"ان لوڈنگ"}
                      sep="/"
                      is_recipient={is_recipient}
                      fromHistory={fromHistory}

                    />
                  ) : null}

                  {value.is_unloading_required &&
                    value.unloading_requirements && (
                      <FormRow
                        label="Labour / Equipment "
                        value={
                          value.unloading_requirements
                            ? value.unloading_requirements
                            : ""
                        }
                        urdutitle={"ان لوڈنگ درکار ہے"}
                        sep="/"
                        is_recipient={is_recipient}
                        fromHistory={fromHistory}

                      />
                    )}
                </>
              )}

              {!showTime &&
                screen !== "additional_details" &&
                title === LOAD_TYPE.bulk && (
                  <FormRow
                    label="Free Time Required"
                    value={value?.detention_free_time + " hrs"}
                    urdutitle={"فری وقت درکار"}
                    sep="/"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}

                  />
                )}

              {!showTime &&
                screen !== "additional_details" &&
                title === LOAD_TYPE.bulk &&
                value?.max_bid && (
                  <FormRow
                    label="Max Bid (Without GST)"
                    value={numeral(value?.max_bid).format("0,0") + " PKR"}
                    urdutitle={"زیادہ سے زیادہ بولی  (GST کے بغیر)"}
                    sep="/"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}
                  />
                )}

              {!showTime &&
                screen !== "additional_details" &&
                title === LOAD_TYPE.bulk &&
                value?.detention_included_transport && (
                  <FormRow
                    label="Free Time Mandatory Detention included in Transport Price? (hours)"
                    value={value?.detention_included_transport ? "Yes" : "No"}
                    urdutitle={"مفت وقت لازمی حراستی ٹرانسپورٹ کی قیمت میں شامل ہے؟ (گھنٹے)"}
                    sep="/"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}

                  />
                )}

              {title === "BULK" && value.quote_by !== "weight_volume" && (
                <FormRow
                  label="Heavy Lift / Out of Gauge"
                  value={value?.values?.is_out_of_gauge ? "YES" : "NO "}
                  urdutitle={"بھاری لفٹ / گیج سے باہر"}
                  sep="/"
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}

                />
              )}
              {screen === "additional_details" && (
                <div className="otherLoadingDiv">
                  <Grid item xs={12}>
                    <SwitchableWithText
                      id={"is_loading_required"}
                      handleChange={(e) => {
                        onChangeValue("is_loading_required", e.target.checked);
                        onChangeValue("loading_requirements", "");
                        onChangeValue("loading_price", 0);
                      }}
                      checked={value?.is_loading_required || false}
                      name="is_loading_required"
                      text="Loading Required"
                    />
                    {value?.is_loading_required && (
                      <div className="inputField" style={{ width: '100%' }}>
                        <div className="label">
                          Specify Labour / Equipment Requirement
                        </div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              onChangeValue(
                                "loading_requirements",
                                e.target.value
                              );
                            }}
                            type="text"
                            value={value?.loading_requirements || ""}
                            aria-describedby="standard-weight-helper-text"
                            placeholder="Optional"
                          />
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <SwitchableWithText
                      id={"is_unloading_required"}
                      checked={value?.is_unloading_required || false}
                      handleChange={(e) => {
                        onChangeValue(
                          "is_unloading_required",
                          e.target.checked
                        );
                        onChangeValue("unloading_price", 0);

                        onChangeValue("unloading_requirements", "");
                      }}
                      name="is_unloading_required"
                      text="Unloading Required"
                    />
                    {value?.is_unloading_required && (
                      <div className="inputField" style={{ width: '100%' }}>
                        <div className="label">
                          Specify Labour / Equipment Requirement
                        </div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              onChangeValue(
                                "unloading_requirements",
                                e.target.value
                              );
                            }}
                            type="text"
                            value={value?.unloading_requirements || ""}
                            aria-describedby="standard-weight-helper-text"
                            placeholder="Optional"
                          />
                        </div>
                      </div>
                    )}
                  </Grid>
                </div>
              )}

              {screen !== "confirm_load" && showTime ? (
                <>
                  <Grid item xs={6}>
                    <div className="transactionFieldLabel">
                      Select Price Unit
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="userSelectBox">
                      <Select
                        placeholder="Select Hours"
                        name="vehicle_type"
                        className="add-vehicle-select-box2"
                        value={
                          value.price_unit
                        }
                        onChange={(e) => {
                          onChangeValue("price_unit", e.target.value);
                        }}
                      >
                        {prices_units?.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              selected={item == value.price_unit}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: 16 }}>
                    <div className="transactionFieldLabel">
                      Free Time Required
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="userSelectBox">
                      <Select
                        placeholder="Select Hours"
                        name="vehicle_type"
                        className="add-vehicle-select-box2"
                        value={
                          value.detention_free_time
                            ? value.detention_free_time
                            : "Select Hours"
                        }
                        onChange={(e) => {
                          onChangePrice(
                            e.target.value,
                            "detention_free_time",
                            true
                          );
                        }}
                      >
                        {hoursData?.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              selected={item?.value == value.detention_free_time}
                              value={item?.value}
                            >
                              {item?.key}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </Grid>
                  {
                    screen === "bid" ?
                      <>
                        <div className="inputField" style={{ width: '100%' }}>
                          <div className="label">Max Bid (Without GST)</div>
                          <div className="field">
                            <NewInput
                              onChange={(e) => {
                                onChangePrice(e.target.value, "max_bid");
                              }}
                              value={value.max_bid ? value.max_bid : ""}
                              showEnd={"PKR"}
                              placeholder={"Optional"}
                              decimal={true}
                            />
                          </div>
                        </div>
                      </> : null
                  }
                </>
              ) : null}

              {screen === "offer" && (
                <>
                  <div className="inputField" style={{ width: '100%' }}>
                    <div className="label">
                      Price / {value.price_unit || "Ton"} (Without GST)
                      <InfoIcon
                        onClick={() => {
                          setInfo("Price is inclusive of Free Time.");
                          toggleModal(true);
                        }}
                      />
                    </div>
                    <div className="field">
                      <NewInput
                        onChange={(e) => {
                          onChangePrice(e.target.value, "price");
                        }}
                        value={value?.price > 0 ? value?.price : ""}
                        showEnd={"PKR"}
                        decimal={true}
                      />
                    </div>
                    <div className="">
                      {PostLoad?.additionalScreen
                        ?.is_cross_stuffing_required ? (
                        <div className="error-container info">
                          <div>
                            <InfoIcon className="error-icon" />
                          </div>
                          <div className="error-text">
                            Offer price should be inclusive of shifting & local
                            & upcountry transport charges.
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {value?.is_loading_required && (
                    <div className="inputField" style={{ width: '100%' }}>
                      <div className="label">
                        Loading Price / {value.price_unit || "Ton"} (Without GST)
                      </div>
                      <div className="field">
                        <NewInput
                          onChange={(e) => {
                            onChangePrice(e.target.value, "loading_price");
                          }}
                          value={
                            value?.loading_price ? value?.loading_price : ""
                          }
                          showEnd={"PKR"}
                          decimal={true}
                        />
                      </div>
                    </div>
                  )}

                  {value?.is_unloading_required && (
                    <div className="inputField" style={{ width: '100%' }}>
                      <div className="label">
                        Unloading Price / {value.price_unit || "Ton"} (Without GST)
                      </div>
                      <div className="field">
                        <NewInput
                          onChange={(e) => {
                            onChangePrice(e.target.value, "unloading_price");
                          }}
                          value={
                            value?.unloading_price ? value?.unloading_price : ""
                          }
                          showEnd={"PKR"}
                          decimal={true}
                        />
                      </div>
                    </div>
                  )}

                  {screen !== "confirm_load" ? (
                    <>
                      <div className="inputField" style={{ width: '100%' }}>
                        <div className="label">
                          Detention Rate (Without GST)
                          <InfoIcon
                            onClick={() => {
                              setInfo(
                                "Please enter daily Detention Rates only for applicable vehicle categories."
                              );
                              toggleModal(true);
                            }}
                          />
                        </div>
                        <div className="field">
                          <NewInput
                            onChange={(e) => {
                              onChangePrice(
                                e.target.value,
                                "detention_rate",
                                true
                              );
                            }}
                            value={
                              value?.detention_rate ? value?.detention_rate : ""
                            }
                            showEnd={"PKR"}
                            placeholder={
                              "Optional"
                            }
                            decimal={true}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {showTime && screen! == "confirm_load" ? (
                    <>
                      <div className="iconLabel fw-5 mb-3">
                        Other Additional Charges (if applicable){" "}
                        <InfoIcon
                          onClick={() => {
                            setInfo(
                              "Please enter other charges that are applicable to the request. Where category specific, please identify which categories they apply to."
                            );
                            toggleModal(true);
                          }}
                        />
                      </div>
                      <div className="textareaWrapper">
                        <textarea
                          style={{
                            outline: "none",
                          }}
                          maxLength={250}
                          value={
                            value.detention_additional_charges
                              ? value.detention_additional_charges
                              : ""
                          }
                          onChange={(e) => {
                            onChangePrice(
                              e.target.value,
                              "detention_additional_charges",
                              true
                            );
                          }}
                          id={"cargo_description"}
                          name={"cargo_description"}
                          placeholder="Optional"
                        />
                      </div>
                    </>
                  ) : null}
                </>
              )}
              {screen == "confirm_load" && PostLoad?.transaction_type_id === 1 && (
                <>

                  <FormRow
                    label={`Price / ${value?.price_unit} (Without GST)`}
                    value={
                      numeral(value.price).format("0,0.00") + " PKR"
                        ? numeral(value.price).format("0,0.00") + " PKR"
                        : null
                    }
                    urdutitle={`ریٹ / ${price_units_transalation[value?.price_unit] || "فریٹ"} (GST کے بغیر)`}
                    sep="/"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}
                  />

                  {value?.detention_rate ? (
                    <FormRow
                      label={"Detention Rates (Without GST)"}
                      value={
                        numeral(value?.detention_rate).format("0,0.00") + " PKR"
                          ? numeral(value?.detention_rate).format("0,0.00") + " PKR" : null
                      }
                      urdutitle={`/ ڈیٹنشن ریٹ${price_units_transalation[value?.price_unit]} (GST کے بغیر)`}
                      sep="/"
                      is_recipient={is_recipient}
                      fromHistory={fromHistory}

                    />
                  ) : null}

                  {value?.is_loading_required && (
                    <FormRow
                      label={`Loading Price / ${value?.price_unit} (Without GST)`}
                      value={
                        numeral(value.loading_price).format("0,0.00") + " PKR"
                          ? numeral(value.loading_price).format("0,0.00") + " PKR"
                          : null
                      }
                      urdutitle={`لوڈنگ ریٹ /  ${price_units_transalation[value?.price_unit]} (GST کے بغیر)`}
                      sep="/"
                      is_recipient={is_recipient}
                      fromHistory={fromHistory}

                    />
                  )}
                  {value?.is_unloading_required && (
                    <FormRow
                      label={`Unloading Price / ${value?.price_unit} (Without GST)`}
                      value={
                        numeral(value.unloading_price).format("0,0.00") + " PKR"
                          ? numeral(value.unloading_price).format("0,0.00") +
                          " PKR"
                          : null
                      }
                      urdutitle={` ان لوڈنگ ریٹ /  ${price_units_transalation[value?.price_unit]} (GST کے بغیر)`}
                      sep="/"
                      is_recipient={is_recipient}
                      fromHistory={fromHistory}

                    />
                  )}

                  <FormRow
                    label={`Total Amount (Without GST)`}
                    value={value.total_amount < 1 ? value?.total_amount : numeral(value.total_amount).format("0,0.00") + " PKR"}
                    sep="/"
                    urdutitle={"کل رقم (GST کے بغیر)"}
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}

                  />
                </>
              )}

              {screen &&
                screen == "offer" &&
                screen !== "additional_details" &&
                PostLoad?.transaction_type_id === 1 && (
                  <FormRow
                    label="Total Offer Price (Without GST)"
                    value={value?.total_amount > 1 ? numeral(value.total_amount).format("0,0.00") || 0 + " PKR" :
                      value?.total_amount || 0 + " PKR"
                    }
                    urdutitle={"(GST کے بغیر) کل پیشکش کی قیمت"}
                    sep="/"
                    is_recipient={is_recipient}
                    fromHistory={fromHistory}

                  />
                )}

              {/* prices added for recipient */}


              {
                <>
                  {PostLoad?.transaction_type_id == 2 && is_recipient &&
                    <>
                      {
                        <>
                          {PostLoad?.vehicleScreen?.new_recipients?.filter((itm) => itm?.recipient_id == profile.id)
                            .map((reci) => {
                              return (
                                <>
                                  {
                                    reci?.bid?.price_per_freight &&
                                    <>
                                      <CustomText.OpenSansBold
                                        textStyle={{
                                          marginLeft: 8,
                                        }}
                                      >
                                        {
                                          reci?.status == 4 ? "Awarded Price" : "Responded Price"
                                        }
                                      </CustomText.OpenSansBold>

                                      <FormRow
                                        label={`Price / ${value?.price_unit}  (Without GST)`}
                                        value={`PKR ${numeral(reci?.bid?.price_per_freight).format("0,0.00")}`}
                                        urdutitle={`ریٹ / ${price_units_transalation[value?.price_unit] || "فریٹ"} (GST کے بغیر)`}
                                        sep="/"
                                        is_recipient={is_recipient}
                                        fromHistory={fromHistory}
                                      />
                                    </>
                                  }
                                </>
                              );
                            })}
                        </>
                      }
                    </>
                  }
                </>
              }

              <div style={{
                width: '100%'
              }}>
                {screen == "confirm_load" && fromHistory && otherData?.history_recipients?.length ? (
                  <>
                    {
                      PostLoad?.vehicleScreen?.new_recipients?.filter((not_award) => not_award?.status !== 4).length ? <>
                        <>
                          <div className="dividing-bordered"></div>
                          <CustomText.OpenSansBold
                            textStyle={{
                              marginLeft: 4,
                            }}
                          >
                            Recipients
                          </CustomText.OpenSansBold>
                          {PostLoad?.vehicleScreen?.new_recipients?.filter((statu) => statu?.status !== 4)
                            ?.map((loadDetail, index) => {
                              let history_vechiles = PostLoad?.vehicleScreen?.vehicles_drivers_refs?.filter((cat) => cat?.recipient_id == loadDetail?.recipient?.id)?.sort((a, b) => a?.vehicle_no - b?.vehicle_no);
                              return (
                                <>
                                  <div style={{ marginBottom: 0, marginTop: 10 }} key={index} className="detail-category-recepient-container">
                                    <div>
                                      <img
                                        alt="profile"
                                        style={{ border: "1px solid #0D5296" }}
                                        className="load-detail-user"
                                        src={
                                          loadDetail?.recipient?.picture ||
                                          Images.myProfileImage
                                        }
                                      />
                                    </div>

                                    <div className="load-history-profile-name">
                                      <CustomText.OpenSansSemiBold>
                                        {loadDetail?.recipient?.company?.length ?
                                          loadDetail?.recipient?.company[0]?.name : loadDetail?.recipient?.name
                                          || "UnRegistered User"}
                                      </CustomText.OpenSansSemiBold>

                                      <CustomText.OpenSansRegular>
                                        {
                                          loadDetail?.recipient?.company?.length ?
                                            loadDetail?.recipient?.name : loadDetail?.recipient?.company_name
                                        }
                                      </CustomText.OpenSansRegular>

                                      {
                                        PostLoad?.transaction_type_id == 2 && loadDetail?.bid?.price_per_freight ?
                                          <div className="chip">
                                            PKR {numeral(loadDetail?.bid?.price_per_freight).format("0,0.00")} / {PostLoad?.vehicleScreen?.values?.price_unit}
                                          </div>
                                          : null
                                      }
                                    </div>
                                  </div>
                                  <HistoryVehicle history_vechiles={history_vechiles} />

                                </>
                              );
                            })}
                        </>
                      </> : null
                    }
                    <>
                      {
                        PostLoad?.vehicleScreen?.new_recipients?.filter((award) => award?.status == 4).length ?
                          <>
                            <>
                              <div className="dividing-bordered"></div>
                              <CustomText.OpenSansBold
                                textStyle={{
                                  marginLeft: 4,
                                }}
                              >
                                Awarded Recipients
                              </CustomText.OpenSansBold>
                              {
                                PostLoad?.vehicleScreen?.new_recipients?.filter((stat) =>
                                  stat?.status == 4)?.map((reci) => {
                                    let history_vechiles = PostLoad?.vehicleScreen?.vehicles_drivers_refs?.filter((cat) => cat?.recipient_id == reci?.recipient?.id)?.sort((a, b) => a?.vehicle_no - b?.vehicle_no);
                                    return (
                                      <>
                                        <div style={{ marginBottom: 0, marginTop: 10 }} key={index} className="detail-category-recepient-container">
                                          <div>
                                            <img
                                              alt="profile"
                                              style={{ border: "1px solid #0D5296" }}
                                              className="load-detail-user"
                                              src={
                                                reci?.recipient?.picture ||
                                                Images.myProfileImage
                                              }
                                            />
                                          </div>

                                          <div className="load-history-profile-name">
                                            <CustomText.OpenSansSemiBold>
                                              {reci?.recipient?.company?.length ?
                                                reci?.recipient?.company[0]?.name : reci?.recipient?.name
                                                || "UnRegistered User"}
                                            </CustomText.OpenSansSemiBold>

                                            <CustomText.OpenSansRegular>
                                              {
                                                reci?.recipient?.company?.length ?
                                                  reci?.recipient?.name : reci?.recipient?.company_name
                                              }
                                            </CustomText.OpenSansRegular>
                                            {
                                              PostLoad?.transaction_type_id == 2 && reci?.bid?.price_per_freight ?
                                                <div className="chip">
                                                  PKR {numeral(reci?.bid?.price_per_freight).format("0,0.00")} / {PostLoad?.vehicleScreen?.values?.price_unit}
                                                </div>
                                                : null
                                            }
                                          </div>
                                        </div>
                                        <HistoryVehicle history_vechiles={history_vechiles} />

                                      </>
                                    );
                                  })
                              }
                            </>

                          </> : null
                      }
                    </>
                  </>
                ) : null}

                {
                  screen == "confirm_load" && fromHistory && !otherData?.history_recipients?.length ?
                    <div>
                      <HistoryVehicle history_vechiles={PostLoad?.vehicleScreen?.vehicles_drivers_refs?.filter((vehicle) => vehicle?.recipient_id == orignal_recipient_id)?.sort((a, b) => a?.vehicle_no - b?.vehicle_no)} />
                    </div> : null

                }
              </div>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Maincontainer>
    </>
  );
};

export default CategoryContainer;
