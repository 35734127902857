import { Accordion, AccordionSummary } from "@mui/material/";
import { Colors } from "@temp/assets";
import { CustomText, CustomModal, PrimaryButton, AutioPlayer } from "@temp/components";
import React, { useState } from "react";
import "../LoadDetailPoster/style.scss";
import { RenderRTL } from "./RenderRTL";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
var numeral = require("numeral");
import ReactAudioPlayer from "react-audio-player";
// import ReactAudioPlayer from "react-h5-audio-player"
import { LOAD_TYPE, QUOTE_BY } from "@temp/constants";
import { useDispatch } from "react-redux";
import { LoadAction } from "@temp/store/actions";
import { getFullTime } from "@temp/utils/helper";

export const AdditionalDetail = ({
  loadDetail,
  fromPoster,
  is_recipient = false
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [additionalRequirement, setAdditionalRequirements] = useState("");
  const requirementsChange = (e) => {
    setAdditionalRequirements(e.target.value);
  };
  const additionalRequirements = () => {
    let payload = {
      job_load_category_id: "",
      additional_requirements: additionalRequirement,
      load_id: loadDetail?.id,
    };
    setShowModal(false);
    dispatch(LoadAction.addRequirements(payload));
  };
  return (
    <div className="accordion-container">
      <Accordion
        className="accordion-background"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <CustomText.OpenSansSemiBold
            textStyle={{
              fontSize: "0.875rem",
              color: Colors.Black,
            }}
          >
            <span>Additional Details</span>
            {
              is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">اضافی تفصیلات</span>
              </>
            }
          </CustomText.OpenSansSemiBold>
        </AccordionSummary>
        <div className="accordion-content">

          {loadDetail?.is_advance_vehicle_driver_docs ?
            <RenderRTL
              title="Advance Vehicle / Driver Documents"
              sep="/"
              urdutitle="گاڑی / ڈرائیور کے دستاویزات"
              val={loadDetail?.is_advance_vehicle_driver_docs ? "YES" : "NO"}
              is_recipient={is_recipient}
            /> : null}

          {loadDetail?.is_bonded_vehicle_required ?
            <RenderRTL
              title="Bonded Vehicle Required"
              sep="/"
              urdutitle="بانڈڈ کیریئر گاڑی درکار ہے۔"
              val={loadDetail?.is_bonded_vehicle_required ? "YES" : "NO"}
              is_recipient={is_recipient}
            /> : null}

          {loadDetail?.load_type.title == LOAD_TYPE.fcl && loadDetail?.is_cross_stuffing_required ? (
            <>

              {loadDetail?.is_cross_stuffing_box_vehicle_required ?
                <RenderRTL
                  title="Containerized / Box Vehicle Only"
                  sep="/"
                  urdutitle="کنٹینرائزڈ / باکس گاڑی"
                  val={loadDetail?.is_cross_stuffing_box_vehicle_required ? "YES" : "NO"}
                  is_recipient={is_recipient}

                />
                : null
              }
              <RenderRTL
                title="Cross Stuffing / Local Shifting"
                sep="/"
                urdutitle="لوکل شفٹنگ۔"
                val={loadDetail?.is_cross_stuffing_required ? "YES" : "NO"}
                is_recipient={is_recipient}

              />
            </>
          ) : null}

          {loadDetail?.is_container_deposit_by_transporter ?
            <RenderRTL
              title="Container Deposit By Transporter"
              sep="/"
              urdutitle="کنٹینر ڈپازٹس ٹرانسپوٹر دے گا "
              val={loadDetail?.is_container_deposit_by_transporter ? "YES" : "NO"}
              is_recipient={is_recipient}

            />
            : null}

          {loadDetail?.is_advance_vehicle_driver_docs && loadDetail?.driver_requried_document_details ?
            <>
              <RenderRTL
                title="Details of Advance Vehicle Documents Required"
                sep="/"
                urdutitle="گاڑی کے ایڈوانس دستاویزات کی تفصیلات درکار ہیں۔"
                val={loadDetail?.driver_requried_document_details}
                is_recipient={is_recipient}

              />
            </> : null}

          {loadDetail?.is_container_deposit_by_transporter === 1 && loadDetail?.is_container_deposit_by_transporter && loadDetail?.container_deposit_amount ? (
            <RenderRTL
              title="Deposit Amount"
              sep="/"
              urdutitle="ڈپازٹس کی رقم"
              val={
                numeral(loadDetail?.container_deposit_amount).format("0,0") +
                " PKR"
              }
              is_recipient={is_recipient}
            />
          ) : null}
          <div className="customUrduLabelWrap">
            {loadDetail?.is_covid_certf_req_driver ?
              <RenderRTL
                title="Driver Covid-19 Vaccination Required"
                sep="/"
                urdutitle="ڈرائیور کی کوویڈ 19 ویکسینیشن درکار ہے۔"
                val={loadDetail?.is_covid_certf_req_driver ? "YES" : "NO"}
                is_recipient={is_recipient}
              /> : null}
          </div>

          {loadDetail?.is_driver_negative_pcr_required ?
            <RenderRTL
              title="Driver Negative PCR Required"
              sep="/"
              urdutitle="ڈرائیور کی منفی پی سی آر کی ضرورت ہے۔"
              val={loadDetail?.is_driver_negative_pcr_required ? "YES" : "NO"}
              is_recipient={is_recipient}
            /> : null}

          {loadDetail?.is_hse_compliant_vehicle ?
            <RenderRTL
              title="HSE Compliant Vehicle"
              sep="/"
              urdutitle="سیفٹی اور سینڈر  کے مطابق گاڑی"
              val={loadDetail?.is_hse_compliant_vehicle ? "YES" : "NO"}
              is_recipient={is_recipient}
            /> : null}

          <div className="mandatoryWrap">
            {
              loadDetail?.load_type?.title === "Containerized" && loadDetail?.detention_included_transport ?
                <div className="mandatoryDetentionWrap">
                  <RenderRTL
                    title={"Mandatory Detention included in Transport Price?"}
                    sep={"/"}
                    urdutitle={"لازمی ڈیٹنشن ٹرانسپورٹ کی قیمت میں شامل ہے؟"}
                    val={loadDetail?.detention_included_transport ? "YES" : "No"}
                    infoMessage={"1 day Mandatory Detention should be selected in cases where detention is applicable due to road restrictions, after expiry of free period"}
                    is_recipient={is_recipient}
                  /></div> : null
            }
          </div>
          {loadDetail?.is_allow_partial_load ?
            <RenderRTL
              title="Partial Load Allow"
              sep={"/"}
              urdutitle={"پارشل لوڈ کی اجازت"}
              val={loadDetail?.is_allow_partial_load ? "YES" : "NO"}
              is_recipient={is_recipient}
            /> : null}

          {loadDetail.load_type.title === LOAD_TYPE.bulk &&
            loadDetail.quote_by == QUOTE_BY.weightVolume && loadDetail?.is_shared_vehicle_not_allowed ? (
            <RenderRTL
              title="Shared Vehicle Allowed"
              sep={"/"}
              urdutitle={"مشترکہ گاڑی کی اجازت ہے۔"}
              val={loadDetail?.is_shared_vehicle_not_allowed ? "YES" : "NO"}
              is_recipient={is_recipient}
            />
          ) : null}

          {loadDetail?.is_container_deposit_by_transporter === 1 && loadDetail?.shipping_line_name ? (
            <RenderRTL
              title="Shipping Line Name"
              sep="/"
              urdutitle="شپنگ لائن کا نام"
              val={loadDetail?.shipping_line_name}
              is_recipient={is_recipient}
            />
          ) : null}

          {loadDetail?.load_type.title === "Non-Containerized" && loadDetail?.is_tarpaulin_plastic_cover ? <>
            <RenderRTL
              title="Tarpaulin / Plastic Cover"
              sep="/"
              urdutitle="ترپال / پلاسٹک کا احاطہ"
              val={loadDetail?.is_tarpaulin_plastic_cover ? "YES" : "NO"}
              is_recipient={is_recipient}
            />
          </> : null}

          {loadDetail?.load_type.title === "Non-Containerized" && loadDetail?.is_under_hook_delivery_required ?
            <RenderRTL
              title="Underhook / Direct Delivery Required"
              sep="/"
              urdutitle="انڈر ہُک / ڈائریکٹ ڈیلیوری درکار ہے۔"
              val={loadDetail?.is_under_hook_delivery_required ? "YES" : "NO"}
              is_recipient={is_recipient}
            /> : null}

          {loadDetail?.load_type.title === "Non-Containerized" &&
            loadDetail?.under_hook_delivery ?
            <RenderRTL
              title="Package No."
              sep="/"
              urdutitle="پیکیج نمبر"
              val={loadDetail?.under_hook_delivery}
              is_recipient={is_recipient}
            />
            : null}

          {loadDetail?.is_vehicle_tracking ?
            <RenderRTL
              title="Vehicle Tracking"
              sep="/"
              urdutitle="ٹریکر"
              val={loadDetail?.is_vehicle_tracking ? "YES" : "NO"}
              is_recipient={is_recipient}
            />
            : null}

          {loadDetail?.is_weightment_received_required ?
            <RenderRTL
              title="Weighment Required"
              sep="/"
              urdutitle="کانٹے کی ضرورت "
              val={loadDetail?.is_weightment_received_required ? "YES" : "NO"}
              is_recipient={is_recipient}
            /> : null}

          {loadDetail?.is_weightment_received_required && loadDetail?.weightment_received_name ?
            <>
              <RenderRTL
                title="Weighment Bridge Name"
                sep="/"
                urdutitle="کانٹے کا نام"
                val={loadDetail?.weightment_received_name}
                is_recipient={is_recipient}
              />
            </> : null}

          {/* requirements box */}

          {fromPoster || loadDetail?.additional_requirements ?
            <>
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                }}
              >
                {getFullTime(loadDetail?.deadline_at) >
                  getFullTime(new Date()) || loadDetail?.additional_requirements ?
                  <>
                    <span className="titleName">
                      Additional Requirements
                    </span>
                    {
                      is_recipient &&
                      <>
                        <span className="seperator">/</span>
                        <span className="urduLabel">اضافی ضروریات</span>
                      </>
                    }

                  </> : null}

                {fromPoster ? (
                  <div style={{ display: "flex", marginLeft: "auto" }}>
                    {getFullTime(loadDetail?.deadline_at) >
                      getFullTime(new Date()) ? (
                      <div
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                          setShowModal(!showModal);
                          setAdditionalRequirements(
                            loadDetail?.additional_requirements
                          );
                        }}
                      >
                        <span className="icons-edit editIcon"></span>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>

            </> : null}

          <div>
            <CustomText.OpenSansRegular>
              {loadDetail?.additional_requirements}
            </CustomText.OpenSansRegular>
          </div>

          {loadDetail?.instructions ? (
            <div
              style={{
                marginTop: 10,
              }}
            >
              <CustomText.OpenSansSemiBold>
                <span className="titleName">Special Instruction</span>
                {
                  is_recipient &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">خصوصی ہدایات</span>
                  </>
                }
              </CustomText.OpenSansSemiBold>
              <CustomText.OpenSansRegular>
                <span className="customTextAdd">{loadDetail?.instructions}</span>
              </CustomText.OpenSansRegular>
            </div>
          ) : null}
          {loadDetail?.instructions_voice_note ? (
            <div
              style={{
                marginTop: 10,
              }}
            >
              <CustomText.OpenSansSemiBold>
                <span>Instruction Voice Note</span>
                {
                  is_recipient &&
                  <>
                    <span className="seperator">/</span>
                    <span className="urduLabel">وائس نوٹ</span>
                  </>
                }
              </CustomText.OpenSansSemiBold>
              <div style={{ marginTop: 5 }}></div>
              <div className="recordedVoiceNote">
                {/* <ReactAudioPlayer
                  src={loadDetail?.instructions_voice_note}
                  // autoPlay
                  controls
                  loop={false}
                /> */}
                <AutioPlayer url={loadDetail?.instructions_voice_note} />
              </div>
            </div>
          ) : null}
          <div style={{ height: 16 }}></div>
        </div>
      </Accordion>

      <CustomModal showModal={showModal} toggleModal={setShowModal}>
        <div
          style={{
            padding: 5,
            outline: "none",
            border: "1px solid #c6c6c6",
            borderRadius: 5,
            marginTop: 5,
            marginBottom: 10,
          }}
        >
          <textarea
            style={{
              border: "none",
              outline: "none",
            }}
            maxLength={250}
            value={additionalRequirement}
            onChange={requirementsChange}
            placeholder="Additional Requirements"
          />
        </div>
        <PrimaryButton
          buttonStyle={{ height: 40, marginTop: 25 }}
          title="Submit"
          onClick={() => {
            additionalRequirements();
          }}
        />
      </CustomModal>
    </div>
  );
};
