import React, { useState } from "react";
import "../LoadDetailPoster/style.scss";
import InfoIcon from "@mui/icons-material/Info";
import { CustomModal } from "@temp/components";

export const RenderRTL = ({ title, sep, urdutitle, val, is_recipient, infoMessage = null, val2 }) => {
  const [info, setInfo] = useState("");
  const [showModal, toggleModal] = useState(false);
  return (
    <div className="formGrid">
      <div className="grayLabel"><span className="titleName">{title}</span>
        {
          is_recipient &&
          <>
            <span className="seperator">{sep}</span><span className="urduLabel">{urdutitle}</span>
          </>
        }
        {
          infoMessage &&
          <InfoIcon
            onClick={() => {
              setInfo(infoMessage);
              toggleModal(true);
            }}
            style={{ cursor: "pointer" }}
          />
        }
      </div>
      <div className="tempData">
      <div className="fw-5">
        {val}

        {
          title == "Required Temperature" &&
          <>
            <span style={{ marginLeft: 3 }}>&#176;</span><span>C</span>
          </>
        }
      </div>

      <div className="fw-5">
        {val2}

        {
          title == "Required Temperature" &&
          <>
            <span style={{ marginLeft: 3 }}>&#176;</span><span>C</span>
          </>
        }
      </div>
      </div>
      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showModal}
        toggleModal={toggleModal}
      >
        <div>
          <InfoIcon className="icon" />
          <p className="title">Information</p>
          <p>
            {info}
          </p>
        </div>
      </CustomModal>
    </div>
  );
};
