import React from "react";
import { IProps } from "./types";
import "./style.scss";
import Snackbar from "@mui/material/Snackbar";
// import MuiAlert, { AlertProps } from "@mui/lab/Alert";
import { Alert } from "@mui/material";

// function Alert(props: AlertProps) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

export const AlertMessage: React.FC<IProps> = ({
  showMessage,
}: IProps) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [status, setStatus] = React.useState('');

  React.useEffect(() => {
    showMessage.current = handleClick;
  }, []);

  const handleClick = (params) => {
    setMessage(params?.message);
    setStatus(params?.status);
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar key={"bottom" + "center"} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={open} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={status}>
       {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
