import React, { FC, useState, useEffect } from "react";
import {
  DocumentsTable,
  ScreenHeader,
  PrimaryButton,
  SelectionModal,
  InputModal,
} from "@components";
import { useHistory } from "react-router-dom";
import { Images } from "@temp/assets";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { showMessage } from "@utils/helper";
import { ProfileAction } from "@temp/store/actions";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
export const UploadedDocuments: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [docuentNameModal, setDocumentNameModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const [showModalMedia, setShowModalMedia] = useState(false);
  const [selectionValue, setSelectionValue] = React.useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectionValue((event.target as HTMLInputElement).value);
  };
  const postLoadMeta = useSelector((state) => state?.Profile?.postLoadMeta);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [otherType, setOtherType] = React.useState("");
  const [showModalDocumentType, setDocumentTypeModal] = useState(false);
  const load_type = postLoadMeta.load_types.find(
    (val: object) => val.id === PostLoad.load_type
  );
  const onClickMediaType = (values: any) => {
    setShowModalMedia(false);
  };
  const [temp, setTemp] = useState("");
  const isCross_stuffing = PostLoad?.additionalScreen?.is_cross_stuffing_required;
  // useEffect(()=>{
  //   
  //   showMessage.current({message:`BL:${PostLoad?.documents?.bill_of_ladings.length} PL: ${PostLoad?.documents?.packing_lists.length} and other ${PostLoad?.documents?.other_uploads.length}`, status:'error'})
  // }, [PostLoad])
  const handleDocumentDelete = (ind, arrayIndex, val) => {
    let documents = PostLoad.documents;
    if (val.files.length === 1) {
      let other_uploads = documents.other_uploads.filter(
        (val: any, index: number) => index !== arrayIndex
      );
      documents.other_uploads = other_uploads;
    } else {
      let other_uploads = documents.other_uploads[arrayIndex].files.filter(
        (val: any, index: number) => index !== ind
      );
      documents.other_uploads[arrayIndex].files = other_uploads;
    }
    dispatch(ProfileAction.postLoadData({ documents }));
  };

  const handleUploadImage = (event: any, value: number) => {
    const formData = new FormData();
    formData.append("file", event?.target?.files[0]);
    setShowModalMedia(false);
    // dispatch(ProfileAction.uploadFile({ data: formData, cb: callBack2 }));
    // return

    var cb = (payload: any) => {
      let documents = PostLoad.documents
        ? PostLoad.documents
        : {
          bill_of_ladings: [],
          packing_lists: [],
          other_uploads: [],
        };
      if (Number(value.key) == 1) {
        setShowModalMedia(false);
      } else {
        if (payload?.mime_type) {
          setShowModalMedia(false);
        } else {
          showMessage.current({
            message: "Please upload File.",
            status: "error",
          });
          return;
        }
      }
      if (selectedDocumentType.key === "bill_of_ladings") {
        documents.bill_of_ladings = [...documents.bill_of_ladings, payload];
      } else if (selectedDocumentType.key === "packing_lists") {
        documents.packing_lists = [...documents.packing_lists, payload];
      } else {
        let isNameAlreadyExist = documents.other_uploads.find(
          (val: any) => val.title === otherType
        );

        if (isNameAlreadyExist) {
          let indexOfArray = documents.other_uploads.findIndex(
            (val: any) => val.title === otherType
          );
          documents.other_uploads[indexOfArray].files = [
            payload,
            ...documents.other_uploads[indexOfArray].files,
          ];
        } else {
          documents.other_uploads = [
            ...documents.other_uploads,
            { title: otherType, files: [payload] },
          ];
        }
      }
      dispatch(ProfileAction.postLoadData({ documents }));
    };
    dispatch(ProfileAction.uploadFile({ data: formData, cb }));
  };

  

  const onClickDocumentType = (val: any) => {
    setSelectedDocumentType(val);
    window["myObj"] = val.key;
    if (val?.key === "other_uploads") {
      // setDocumentTypeModal(false);
      // setDocumentNameModal(true);
    } else {
    }
    setDocumentTypeModal(false);
    setShowModalMedia(true);
    setTemp("");
    setOtherType("");
    window["docTitle"] = ""
  };


  const onAddOtherDocumentType = (key: any) => {
    setOtherType(temp);
    window["docTitle"] = temp;
    setDocumentNameModal(false);
    setShowModalMedia(true);
  };

  const callBack2 = (payload) => {
    let new_payload = {
      file_name: payload?.fileName,
      cloudinary_url: payload?.cloudinaryUrl,
      file_url: payload?.fileUrl,
      mime_type: payload?.mimeType
    }
    let documents = PostLoad.documents
      ? PostLoad.documents
      : {
        bill_of_ladings: [],
        packing_lists: [],
        other_uploads: [],
      }

    setShowModalMedia(false);

    

    if (window?.myObj === "bill_of_ladings") {
      if (!documents["bill_of_ladings"]?.find(item => item?.file_url == new_payload?.file_url)) {
        documents["bill_of_ladings"].push(new_payload)
      }
    } else if (window?.myObj === "packing_lists") {
      if ((!documents["packing_lists"]?.find(item => item?.file_url == new_payload?.file_url))) {
        documents["packing_lists"].push(new_payload)
      }
    } else if (window?.myObj === "other_uploads") {
      let isNameAlreadyExist = documents.other_uploads.find(
        (val: any) => val.title === window?.docTitle
      );

      if (isNameAlreadyExist) {
        let indexOfArray = documents.other_uploads.findIndex(
          (val: any) => val.title === window?.docTitle
        );
        documents["other_uploads"][indexOfArray].files = [
          ...documents.other_uploads[indexOfArray].files,
        ];
        if(!documents["other_uploads"][indexOfArray]?.files.find(item=> item?.file_url == new_payload?.file_url)){
          documents["other_uploads"][indexOfArray].files.push(new_payload)
        }
      } else {
        documents["other_uploads"] = [...documents.other_uploads];
        let files_arr = []
        if (!files_arr.find(item => item?.file_url == new_payload?.file_url)) {
          files_arr.push(new_payload)
        }
        
        let obj = {
          title: window?.docTitle, files: files_arr
        }
        documents["other_uploads"].push(obj)
      }
    }
    dispatch(ProfileAction.postLoadData({ documents }));
    new_payload = null
  }


  useEffect(() => {
    const target = message => {
      callBack2(JSON.parse(message?.detail))
    }
    window.addEventListener("AudioUploadsuccess", target);

    return () => {
      window["myObj"] = ""
      window.removeEventListener("AudioUploadsuccess", (e) => {} )
    }
  }, [])


  const handleCamrea = (data) => {
    if (sendEventToAndroid("accessCamera")) {
      window.Android.accessCamera(
        String("access")
      );
    } else if (sendEventToiOS("accessCamera")) {
      window.webkit.messageHandlers.accessCamera.postMessage(
        JSON.stringify({
          access: "access",
        })
      );
    } else {
    }
  }

  const handleDoc = (data) => {
    if (sendEventToAndroid("accessDoc")) {
      window.Android.accessDoc(
        String("access-doc")
      );
    } else if (sendEventToiOS("accessDoc")) {
      window.webkit.messageHandlers.accessDoc.postMessage(
        JSON.stringify({
          access: "access-doc",
        })
      );
    } else {
    }
  }

  const handleGallery = (data) => {
    if (sendEventToAndroid("accessGallery")) {
      window.Android.accessGallery(
        String("access-gallery")
      );
    } else if (sendEventToiOS("accessGallery")) {
      window.webkit.messageHandlers.accessGallery.postMessage(
        JSON.stringify({
          access: "access-gallery",
        })
      );
    } else {
    }
  }

  return (
    <div>

      <ScreenHeader
        bottomTitle={""}
        pageNumber={8}
        progress={90}
        title={`Upload Document - ${load_type.title}`}
      />

      <div className="root-wrapper">
        <div className="root-center-container generalCenterContainer">
          <SelectionModal
            showModal={showModalDocumentType}
            type="document"
            title={"Select Document Type"}
            data={[
              {
                key: "bill_of_ladings",
                icon: Images.bill,
                title: "Bill of Lading",
              },

              {
                key: "packing_lists",
                icon: Images.list,
                title: "Packing List",
              },
              {
                key: "other_uploads",
                icon: Images.docOther,
                title: "Upload Documents",
              },
            ]}
            onClick={onClickDocumentType}
            handleModal={(value: boolean) => {
              setDocumentTypeModal(value);
            }}

          />
          <InputModal
            values={temp}
            showModal={docuentNameModal}
            title={"Add Document Type"}
            placeholder={"Add document name here"}
            maxLetter={false}
            setValue={(e) => setTemp(e.target.value)}
            handleModal={(value: boolean, type: string) => {
              onAddOtherDocumentType(value);
            }}
          />

          <SelectionModal
            values={selectionValue}
            showModal={showModalMedia}
            title={"Media"}
            type="media"
            handleUploadImage={handleUploadImage}
            data={[
              {
                key: 0,
                iconClass: 'icons-camera',
                title: "Camera",
              },

              {
                key: 1,
                iconClass: 'icons-gallery',
                title: "Gallery",
              },
              {
                key: 2,
                iconClass: 'icons-document',
                title: "Document",
              }
            ]}
            onClick={onClickMediaType}
            handleModal={(value: boolean) => {
              setShowModalMedia(value);
            }}
            handleCamrea={handleCamrea}
            handleDoc={handleDoc}
            handleGallery={handleGallery}
          />

          <FadeInComponent>
            {isCross_stuffing ? (
              <div className="error-container info">
                <div>
                  <InfoIcon className="error-icon" />
                </div>
                <div className="error-text">
                  {/* Upload document is mandatory as you have selected vehicle with Cross Stuffing / Local Shifting. */}
                  Packing list is mandatory as you have selected vehicle with cross stuffing.
                </div>
              </div>
            ) : (
              <div className="error-container info">
                <div>
                  <InfoIcon className="error-icon" />
                </div>
                <div className="error-text">
                  Uploading documents is advisable for the categories selected
                  by you.
                </div>
              </div>
            )}
          </FadeInComponent>


          <InputModal
            values={""}
            type="documentName"
            showModal={showModal}
            title={"Add Additional Detail"}
            placeholder={"Give Instruction for driver"}
            setValue={handleChange}
            handleModal={(value: boolean) => {
              setShowModal(value);
            }}
          />
          <InputModal
            values={temp}
            showModal={docuentNameModal}
            title={"Add Document Type"}
            placeholder={"Add document name here"}
            maxLetter={false}
            setValue={(e) => setTemp(e.target.value)}
            handleModal={(value: boolean, type: string) => {
              onAddOtherDocumentType(value);
            }}
          />
          <div className="uploadedDocumentsWrapper">
              {/* <div className="uploadDocumentFileBoxWrap">
                <FadeInComponent>
                  <DocumentsTable
                    values={
                      PostLoad?.documents?.bill_of_ladings.length
                        ? PostLoad?.documents?.bill_of_ladings
                        : null
                    }
                    onHandleDelete={(ind) => {
                      let documents = PostLoad.documents;
                      let bill_of_ladings = documents.bill_of_ladings.filter(
                        (val: any, index) => index !== ind
                      );
                      documents.bill_of_ladings = bill_of_ladings;
                      dispatch(ProfileAction.postLoadData({ documents }));
                    }}
                    titleIcon={Images.bill}
                    screen={'document'}
                    title={PostLoad?.documents?.bill_of_ladings[0]?.file_name}
                    onClickDocumentType={onClickDocumentType}
                    datas={
                      {
                        key: "bill_of_ladings",
                        icon: Images.bill,
                        title: "Bill of Lading"
                      }
                    }
                  />
                </FadeInComponent>
              </div> */}
            
              {/* <div className="uploadDocumentFileBoxWrap">
                <FadeInComponent>
                  <DocumentsTable
                    titleIcon={Images.list}
                    values={
                      PostLoad?.documents?.packing_lists.length
                        ? PostLoad?.documents?.packing_lists
                        : null
                    }
                    screen={'document'}
                    onHandleDelete={(ind) => {
                      let documents = PostLoad.documents;
                      let packing_lists = documents.packing_lists.filter(
                        (val: any, index) => index !== ind
                      );
                      documents.packing_lists = packing_lists;
                      dispatch(ProfileAction.postLoadData({ documents }));
                    }}
                    title={PostLoad?.documents?.packing_lists[0]?.file_name}
                    onClickDocumentType={onClickDocumentType}
                    datas={
                      {
                        key: "packing_lists",
                        icon: Images.list,
                        title: "Packing List",
                      }
                    }
                  />
                </FadeInComponent>
              </div> */}

            <div className="uploadDocumentFileBoxWrap">
              <FadeInComponent>
                <DocumentsTable
                  titleIcon={Images.docOther}
                  values={
                    PostLoad?.documents?.other_uploads.length
                      ? PostLoad?.documents?.other_uploads
                      : null
                  }
                  screen={'document'}
                  title={PostLoad?.documents?.other_uploads[0]?.files[0]?.file_name}
                  onClickDocumentType={onClickDocumentType}
                  onHandleDelete={(ind, arrayIndex, val) => handleDocumentDelete(ind, arrayIndex, val)}
                  from={'other'}
                  datas={
                    {
                      key: "other_uploads",
                      icon: Images.docOther,
                      title: "Upload Documents",
                    }
                  }
                />
              </FadeInComponent>
            </div>
          </div>

          <FadeInComponent>
            <div className="uploadTypeLabel">
              Please upload images, csv, xls, txt, docx and pdf only
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div
              style={{
                marginTop: 0,
                color: "red",
                textAlign: "center",
              }}
            >
              <PrimaryButton
                rightIcon={true}
                disabled={isCross_stuffing ? ((
                  // PostLoad?.documents?.bill_of_ladings.length && 
                  PostLoad?.documents?.other_uploads.length) ? false : true) : false}
                title="CONTINUE"
                onClick={() => {
                  history.push("confirm-load");
                }}
              />
            </div>
          </FadeInComponent>
        </div>
      </div>
    </div>
  );
};
