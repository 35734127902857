import { ScreenHeader } from "@components";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import React from "react";
import { useHistory } from "react-router-dom";
import { IVideoPageProps } from "./types";
import { Images } from "@temp/assets/";


const Page: React.FC<IVideoPageProps> = (props) => {
  const history = useHistory();
  return (
    <>
      {/* <BackHeader
        inverted
        title="View Demo"
        onClick={() => {
          if (sendEventToAndroid("promoVideoBackHandler")) {
            window.Android.promoVideoBackHandler("hello");
          } else if (sendEventToiOS("promoVideoBackHandler")) {
            window.webkit.messageHandlers.promoVideoBackHandler.postMessage(
              JSON.stringify({
                show_navigation_bar: "hello",
              })
            );
          } else {
            history.goBack();
          }
        }}
      /> */}
      <div style={{display:"flex", flexDirection:"column", justifyContent:"center" , alignItems:"center", marginTop:150 }}>
      <img
        // className="rightImg"
        style={{ width: 250, height: 200, cursor: 'pointer' }}
        src={Images.MgLogo}
        alt=""
      // onClick={(e) => handleRoute(e)}
      />
      <div style={{marginTop:30, fontStyle:"bold", fontSize:"2.5rem"}}>COMING SOON</div>
      {/* <video
        style={{
          width: "100%",
          height: "88vh",
        }}
        loop
        autoPlay
        controls
        muted
      >
        <source
          src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag. I suggest you upgrade your
        browser.
      </video> */}
    </div>
    </>
  );
};

export default Page;
