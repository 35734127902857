import { LocatonDetail } from "./LocationDetail";
import { AwardedVehicles } from "./AwardedVehicles";
import { DeclineButton } from "./DeclineButton";
import { RecipientStatus } from "./RecipientStatus";
import { RenderRecipient } from "./RenderRecipient";
import { RenderRecipientLCL } from "./RenderRecipientLCL";
import { VehicleCounter } from "./VehicleCount";
import { RenderRTL } from "./RenderRTL";
import { PaymentTerms} from './PaymentTerms';
import { AdditionalDetail} from './AdditionalDetail';
import { TransactionType } from "./TransactionType";
import { Poster } from "./Poster";
import {RecipientStatusContract} from './RecipientStatusContract';
import {UploadedDocuments} from './UploadedDocuments';
import {CategoryCargoHeading} from './CategoryCargoHeading';
import { ThreeColumnRTL } from './ThreeColumnRTL';
import {TwoColumnRTLWeight} from './TwoColumnRTLWeight';
import {TwoColumnRTLVehicle} from './TwoColumnRTLVehicle'
import {TwoColumnRTLContractVehicle} from './TwoColumnRTLContractVehicle';
import {TwoColumnRTLContractWeight} from './TwoColumnRTLContractWeight';
import {PickupAndDropOff} from './PickupAndDropOff';
import {VehicleCounterContract} from './VehicleCounterContract';
import {LoadViewer} from './loadViewer';
import {AwardedVehiclesViewer} from './AwardedVehiclesViewer'
import {PosterContract} from './PosterContract'
import {RecipientPrices} from './RecipientPrices';
import {ActiveVehicles} from './ActiveVehicles';
import {AudioPlayer} from './AudioPlayer'
import {UploadDocByRecipient} from './UploadDocByRecipient'
import {UploadedDocumentsRecipient} from './UploadedDocumentsRecipient'
export{
    LocatonDetail,
    AwardedVehicles,
    DeclineButton,
    RecipientStatus,
    RenderRecipient,
    RenderRecipientLCL,
    VehicleCounter,
    RenderRTL,
    PaymentTerms,
    AdditionalDetail,
    TransactionType,
    Poster,
    RecipientStatusContract,
    UploadedDocuments,
    CategoryCargoHeading,
    ThreeColumnRTL,
    TwoColumnRTLWeight,
    TwoColumnRTLVehicle,
    TwoColumnRTLContractVehicle,
    TwoColumnRTLContractWeight,
    PickupAndDropOff,
    VehicleCounterContract,
    LoadViewer,
    AwardedVehiclesViewer,
    PosterContract,
    RecipientPrices,
    ActiveVehicles,
    AudioPlayer,
    UploadDocByRecipient,
    UploadedDocumentsRecipient
}