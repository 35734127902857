import React, { FC } from "react";
import styled from "styled-components";
import { ConfirmLoadProps } from "./types";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,

} from "@mui/material/";
import { ExpandMore } from "@mui/icons-material";
import { OutLineButton } from "@temp/components";

export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 17px;
  border-radius: 10px;
`;

export const ContractDetails: FC<ConfirmLoadProps> = ({
  value,
  editable,
  fromHistory,
  is_recipient
}: ConfirmLoadProps) => {
  const history = useHistory();
  const onEdit = () => {
    history.push("/contract-details");
  };

  const FormRow = ({ label, value, sep, urdutitle, is_recipient, fromHistory }) => {
    return (
      <div className="formGrid">
        <div className="grayLabel">
          <span className="titleName">{label}</span>
          {
            fromHistory && is_recipient &&
            <>
              <span className="seperator">
                  {sep}
              </span>
              <span className="urduLabel lhEdit">
                  {urdutitle}
              </span>
            </>
          }
        </div>
        <div className="fw-5">
          {value}
        </div>
      </div>
    );
  };

  const contractStartDate = value?.contract_start_date
    ? moment(value?.contract_start_date).format("DD-MM-yyyy")
    : "-";

  const contractEndDate = value?.contract_end_date
    ? moment(value?.contract_end_date).format("DD-MM-yyyy")
    : "-";

  const deadlineDate = value?.deadline_date
    ? moment(value?.deadline_date).format("DD-MM-yyyy")
    : "-";

  const deadlineTime = value?.deadline_time
    // ? moment(value?.deadline_time).format("hh:mm a")
    ? moment(value?.deadline_time, 'hh:mm A').format('hh:mm A')
    : "-";

  return (
    <>
      <Accordion className="confirm-load-accordion" style={{ backgroundColor: "#FAFAFA" }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="fw-6">
            Contract Details
            {
              fromHistory && is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">  معاہدے کی تفصیلات </span>
              </>
            }
          </div>

          {editable ? (
            <div className="editBtnWrapper">
              <OutLineButton
                classProp='tinyBtn'
                dataColor="blue"
                title="Edit"
                icon={
                  <span className="icons-edit"></span>
                }
                onClick={onEdit}
              />
            </div>
          ) : null}

        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} style={{ overflowY: "scroll" }}>
            <FormRow
              label="Contract Name"
              value={value?.contract_name}
              sep="/"
              urdutitle={" معاہدہ کا نام"}
              is_recipient={is_recipient}
              fromHistory={fromHistory}

            />
            <div className="grayLabel" style={{ marginBottom: 4 }}>
              Contract Period
              {
                fromHistory && is_recipient &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">   معاہدہ کی مدت  </span>
                </>
              }
            </div>

            <FormRow
              label="Start Date"
              value={contractStartDate}
              sep="/"
              urdutitle={"  شروع کرنے کی تاریخ "}
              is_recipient={is_recipient}
              fromHistory={fromHistory}
            />
            <FormRow
              label="End Date"
              value={contractEndDate}
              sep="/"
              urdutitle={"  آخری تاریخ "}
              is_recipient={is_recipient}
              fromHistory={fromHistory}
            />

            <FormRow
              label="Response Deadline"
              value={deadlineDate + " at " + deadlineTime}
              sep="/"
              urdutitle={"  جواب کی آخری تاریخ "}
              is_recipient={is_recipient}
              fromHistory={fromHistory}

            />

          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ContractDetails;
