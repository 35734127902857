import { Colors } from "@temp/assets";
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material/";
import { IProps } from "./types";
import "./style.scss";

export const PrimaryButton: React.FC<any> = ({
  title,
  icon,
  disabled,
  onClick,
  buttonStyle,
  rightIcon,
  type,
  buttonTitleStyle,
  startIcon,
  classProp,
  buttonRef,
  is_recipient,
  urdutitle
}: IProps) => {
  const [disable, setDisable] = useState(true)

  useEffect(()=>{
    setTimeout(()=>{
      setDisable(false)
    },500)
  },[])
  return (
    <Button
      ref={buttonRef}
      disabled={disable || disabled}
      variant="contained"
      className={classProp ? `${classProp} primary-button` : 'primary-button'}
      type={type}
      startIcon={startIcon}
      style={
        disabled
          ? { backgroundColor: Colors.PinkishGray, ...buttonStyle }
          : { backgroundColor: Colors.Primary, ...buttonStyle }
      }
      onClick={onClick}
    >
      {icon ? <img alt="" src={icon} className="button-icon" /> : null}{" "}
      <p
        style={{
          ...buttonTitleStyle,
        }}
        className="button-title"
      >
        {title.toUpperCase()}
        {
          is_recipient &&
          <>
            <span className="seperator">/</span>
            <span className="urduLabel">{urdutitle}</span>
          </>
        }
      </p>
    </Button>
  );
};

export default PrimaryButton;
