import React, { useEffect, useState } from "react";
import { BackHeader } from "@temp/components";
import { PrimaryButton } from "@components";
import { useHistory, useLocation } from "react-router-dom";
import { Images } from "@temp/assets";
import "./style.scss";
// import Lottie from 'react-lottie';

export const AddReceipientSuccessfully = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  
  
  const { goToContactScreen } = props.match.params;
  
  let [phoneNumber, setPhoneNumber] = useState(null);
  useEffect(() => {
    const id = props.match.params.id;
    setPhoneNumber(id);
  }, []);


  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: Images.RecipientAddedLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
      <BackHeader
        onClick={() => {
          history.replace("/selectRecipient");
        }}
        title="Add Recipient"
      />

      <div className="root-wrapper">
        <div className="root-center-container generalCenterContainer">
          <div className="successPostedImg">
            {/* <img
              alt=""
              width="224px"
              height="196px"
              src={Images.successRecipentAdd}
            /> */}
            {/* <Lottie
              options={defaultOptions}
              height={200}
              width={200}
            /> */}
          </div>
          <div
            style={{
              color: "rgb(13,21,9)",
              fontSize: "1rem",
              textAlign: "center",
              fontWeight: 300,
              lineHeight: "22px",
              marginBottom: 10,
            }}
          >
            {
              (typeof state == 'object' ? (state?.id ? `Thank you for adding - 0${phoneNumber}.`
                :
                `Thank you for adding - 0${phoneNumber}. 
              The MG team will add the user to your network after verification and registration of the User.`) :
                (state ? `Thank you for adding - 0${phoneNumber}.`
                  :
                  `Thank you for adding - 0${phoneNumber}. 
              The MG team will add the user to your network after verification and registration of the User.`))
            }

          </div>
          <div className="receipient-post-btn">
            <PrimaryButton
              title="Continue"
              rightIcon
              onClick={() => {
                // history.replace("/selectRecipient");
                history.go(-1)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
