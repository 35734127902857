import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Images } from "@temp/assets";
import {
  PrimaryButton,
  ScreenHeader,
  SelectionModal,
  InputModal,
  OutLineButton
} from "@components";
import { useDispatch, useSelector } from "react-redux";
import { setItem, showMessage } from "@utils/helper";
import { ProfileAction } from "@temp/store/actions";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import "./style.scss";
import { LOAD_TYPE } from "@temp/constants";
export const UploadDoc: React.FC = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [temp, setTemp] = useState("");

  const PostLoad = useSelector((state) => state?.Profile?.postLoad);

  const isCross_stuffing = PostLoad?.additionalScreen?.is_cross_stuffing_required;

  const [isCrossStuffing, setIsCrossStuffing] = React.useState(false);


  const [otherType, setOtherType] = React.useState("");
  const [docuentNameModal, setDocumentNameModal] = useState(false);

  const [showModalDocumentType, setDocumentTypeModal] = useState(false);

  const [showModalMedia, setShowModalMedia] = useState(false);

  const [selectedDocumentType, setSelectedDocumentType] = useState("");

  const postLoadMeta = useSelector((state) => state?.Profile?.postLoadMeta);

  const load_type = postLoadMeta.load_types?.find(
    (val: object) => val?.id === PostLoad?.load_type
  );

  useEffect(() => {
    if (PostLoad?.inquiry_type == 2 && PostLoad?.load_type === 1) {
      let isVehicle = PostLoad?.location[0]?.items.length ? true : false;
      let isGauge = false;
      if (isVehicle) {
        PostLoad?.location.forEach((values) => {
          if (
            values.items.find(
              (val: any) =>
                val?.containerType?.is_guage === 1 &&
                val?.selectVehicleTypeObj?.is_guage === 1
            )
          ) {
            isGauge = true;
          }
        });
        if (isGauge) {
        }
      }
    }
    let vehicles = PostLoad?.vehicleScreen?.addedVehicles
      ? PostLoad?.vehicleScreen?.addedVehicles
      : null;
    if (vehicles) {
      let isGauge = vehicles.find(
        (val: any) =>
          val?.containerType?.is_guage === 1 &&
          val?.selectVehicleTypeObj?.is_guage === 1
      );
      if (isGauge) {
      }
    }
    if (load_type.title !== LOAD_TYPE.fcl) {
    }
  }, []);

  useEffect(() => {
    if (isCross_stuffing) {
      setIsCrossStuffing(true);
    }
  }, []);

  const callBack2 = (payload) => {
    let new_payload = {
      file_name: payload?.fileName,
      cloudinary_url: payload?.cloudinaryUrl,
      file_url: payload?.fileUrl,
      mime_type: payload?.mimeType,
    };
    let documents = {
      bill_of_ladings: [],
      packing_lists: [],
      other_uploads: [],
    };
    if (window?.myObj?.key == "bill_of_ladings") {
      documents.bill_of_ladings = [new_payload];
    } else if (window?.myObj?.key == "packing_lists") {
      documents.packing_lists = [new_payload];
    } else {
      documents.other_uploads = [{ title: otherType, files: [new_payload] }];
    }
    if (window?.docObj?.key == 1 || window?.docObj?.key == 0) {
      dispatch(ProfileAction.postLoadData({ documents }));
      history.replace("/uploadedDocument");
    } else {
      if (new_payload?.mime_type) {
        dispatch(ProfileAction.postLoadData({ documents }));
        history.replace("/uploadedDocument");
      } else {
        showMessage.current({
          message: "Please upload file.",
          status: "error",
        });
      }
    }
  };
  useEffect(() => {
    const target = (message) => {
      callBack2(JSON.parse(message?.detail));
    };
    window.addEventListener("AudioUploadsuccess", target);
  }, []);

  const handleUploadImage = async (event: any, value: number) => {
    let documents = {
      bill_of_ladings: [],
      packing_lists: [],
      other_uploads: [],
    };

    var cb = (payload: any) => {
      let new_pay = {
        fileUrl: payload?.file_url,
        fileName: payload?.file_name,
        mimeType: payload?.mime_type,
        cloudinaryUrl: payload?.cloudinary_url,
      };
      callBack2(new_pay);
      if (selectedDocumentType.key === "bill_of_ladings") {
        documents.bill_of_ladings = [payload];
      } else if (selectedDocumentType.key === "packing_lists") {
        documents.packing_lists = [payload];
      } else {
        documents.other_uploads = [{ title: otherType, files: [payload] }];
      }

      if (Number(value.key) == 1) {
        // if (payload?.mime_type.includes("image")) {
        dispatch(ProfileAction.postLoadData({ documents }));
        // } else {
        //   showMessage.current({
        //     message: "Please upload image only.",
        //     status: "error",
        //   });
        // }
      } else {
        if (payload?.mime_type) {
          dispatch(ProfileAction.postLoadData({ documents }));
        } else {
          showMessage.current({
            message: "Please upload file.",
            status: "error",
          });
        }
      }
    };

    const formData = new FormData();
    formData.append("file", event?.target?.files[0]);
    dispatch(ProfileAction.uploadFile({ data: formData, cb }));
  };

  const handleCamrea = (data) => {
    window["docObj"] = data;
    if (sendEventToAndroid("accessCamera")) {
      window.Android.accessCamera(String("access"));
    } else if (sendEventToiOS("accessCamera")) {
      window.webkit.messageHandlers.accessCamera.postMessage(
        JSON.stringify({
          access: "access",
        })
      );
    } else {
    }
  };

  const handleDoc = (data) => {
    window["docObj"] = data;
    if (sendEventToAndroid("accessDoc")) {
      window.Android.accessDoc(String("access-doc"));
    } else if (sendEventToiOS("accessDoc")) {
      window.webkit.messageHandlers.accessDoc.postMessage(
        JSON.stringify({
          access: "access-doc",
        })
      );
    } else {
    }
  };

  const handleGallery = (data) => {
    window["docObj"] = data;
    if (sendEventToAndroid("accessGallery")) {
      window.Android.accessGallery(String("access-gallery"));
    } else if (sendEventToiOS("accessGallery")) {
      window.webkit.messageHandlers.accessGallery.postMessage(
        JSON.stringify({
          access: "access-gallery",
        })
      );
    } else {
    }
  };

  const onClickDocumentType = (val: any) => {
    window["myObj"] = val;
    setSelectedDocumentType(val);
    if (val?.key === "other_uploads") {
      setDocumentTypeModal(false);

      setDocumentNameModal(true);
    } else {
      setDocumentTypeModal(false);

      setShowModalMedia(true);
    }
    setTemp("");

    setOtherType("");
  };
  const onAddOtherDocumentType = (key: any) => {
    setOtherType(temp);
    setDocumentNameModal(false);
    setShowModalMedia(true);
  };
  const onClickMediaType = (values: any) => {
    setShowModalMedia(false);
  };

  return (
    <div>
      <SelectionModal
        showModal={showModalDocumentType}
        type="document"
        title={"Select Document Type"}
        data={[
          {
            key: "bill_of_ladings",
            icon: Images.bill,
            title: "Bill of Lading",
          },

          {
            key: "packing_lists",
            icon: Images.list,
            title: "Packing List",
          },
          {
            key: "other_uploads",
            icon: Images.docOther,
            title: "Other",
          },
        ]}
        onClick={onClickDocumentType}
        handleModal={(value: boolean) => {
          setDocumentTypeModal(value);
        }}
      />

      <InputModal
        values={temp}
        showModal={docuentNameModal}
        title={"Add Document Type"}
        placeholder={"Add document name here"}
        maxLetter={false}
        setValue={(e) => setTemp(e.target.value)}
        handleModal={(value: boolean, type: string) => {
          onAddOtherDocumentType(value);
        }}
      />

      <SelectionModal
        values={""}
        showModal={showModalMedia}
        title={"Media"}
        type="media"
        handleUploadImage={handleUploadImage}
        handleCamrea={handleCamrea}
        handleDoc={handleDoc}
        handleGallery={handleGallery}
        data={[
          {
            key: 0,
            title: "Camera",
            iconClass: 'icons-camera'
          },

          {
            key: 1,
            iconClass: 'icons-gallery',
            title: "Gallery",
          },
          {
            key: 2,
            iconClass: 'icons-document',
            title: "Document",
          },
        ]}
        onClick={onClickMediaType}
        handleModal={(value: boolean) => {
          setShowModalMedia(value);
        }}
      />
      <ScreenHeader pageNumber={8} progress={80} title="Upload Documents" />
      <div className="root-wrapper">
        <div className="root-center-container generalCenterContainer">


          {isCrossStuffing ? (
            <div className="error-container info">
              <div>
                <InfoIcon className="error-icon" />
              </div>
              <div className="error-text">
                Upload document is mandatory as you have selected vehicle with
                cross stuffing.
              </div>
            </div>
          ) : (
            <div className="error-container info">
              <div>
                <InfoIcon className="error-icon" />
              </div>
              <div className="error-text">
                Uploading documents is advisable for the categories selected
                by you.
              </div>
            </div>
          )}
          <div className="uploadedDocumentsWrapper">
            {
              [
                {
                  key: "bill_of_ladings",
                  icon: Images.bill,
                  title: "Bill of Lading",
                },

                {
                  key: "packing_lists",
                  icon: Images.list,
                  title: "Packing List",
                },
                {
                  key: "other_uploads",
                  icon: Images.docOther,
                  title: "Other",
                },
              ].map((item,index) => {
                return (
                  <div key={index} className="uploadDocumentFileBoxWrap">
                    <div className="documentWrapper">
                      <div style={{ display: "flex", marginBottom: 15 }}>
                        <div className={`docTitle`}>
                          {
                            `Upload ${item?.title}`
                          }
                        </div>
                      </div>
                      <OutLineButton
                        onClick={() => {
                          onClickDocumentType(item)
                        }}
                        title="Upload File"
                      />
                    </div>
                  </div>

                )
              })
            }
          </div>

          <div className="uploadTypeLabel">
            Please upload images, csv, xls, txt, docx and pdf only
          </div>
          <PrimaryButton
            rightIcon
            disabled={isCrossStuffing}
            title={"Continue"}
            onClick={() => {
              history.push("confirm-load");
            }}
          ></PrimaryButton>
        </div>
      </div>
    </div>
  );
};
