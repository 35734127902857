import React from "react";
import { INotificationsPageProps } from "./types";
import "./style.scss";
import { useLocation } from "react-router-dom";
import {
  CustomText,
  TitleLayout,
} from "@components";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<INotificationsPageProps> = (props) => {
  const params = useLocation()
  const { state } = params;
  return (
    <TitleLayout titleOnly={true} titleOnlyText={"All User Notification"}>
      <div className="myProfileMainWrapper">
        <CustomText.OpenSansSemiBold>{state?.title}</CustomText.OpenSansSemiBold>
        <CustomText.OpenSansRegular textStyle={{ marginTop: 20 }}>{state?.body}</CustomText.OpenSansRegular>
      </div>
    </TitleLayout>
  );
};

export default Page;
