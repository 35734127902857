import React from "react";
import { IProps } from "./types";
import "./style.scss";
import Snackbar from "@mui/material//Snackbar";
// import MuiAlert, { AlertProps } from "@mui/lab/Alert";
import { Alert } from "@mui/material";

// function Alert() {
//   return <Alert elevation={6} variant="filled"  />;
// }

export const AlertMessage: React.FC<IProps> = ({
  showErrorMessage,
}: IProps) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [messages, setMessages] = React.useState("");
  const [status, setStatus] = React.useState("");

  React.useEffect(() => {
    showErrorMessage.current = handleClick;
  }, []);

  const handleClick = (params) => {
    if (params?.data?.data == null) {
      setMessage(params?.data?.message);
    } else {
      setMessages(params?.data?.data);
    }
    setStatus(params?.status);
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={status}>
        {message ? (
          message
        ) : (
          <div>
            {Object.values(messages)?.map((item, index) => {
              return <div key={index}>{item[0]}</div>;
            })}
          </div>
        )}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
