import * as React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material/";
import { ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { OutLineButton } from "@temp/components";
import { TRANSACTION_TYPE_BID_URL, TRANSACTION_TYPE_OFFER_URL } from "@routes";


export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 17px;
  border-radius: 10px;
`;

export const TransactionType = ({editable, is_recipient, fromHistory}) => {
 
  const FormRow = ({ label, value, sep, urdutitle, is_recipient, fromHistory}) => {
    return (
      <Grid item xs={12} className="formGrid">
        <div className="grayLabel" style={{marginBottom: '0.25rem'}}>
        <span className="titleName">{label}</span>
        
        {
          fromHistory && is_recipient &&
          <>
             <span className="seperator">
              {sep}
            </span>
            <span className="urduLabel lhEdit">
              {urdutitle}
            </span>
          </>
        }
        </div>
        <div className="fw-5">{value}</div>
      </Grid>
    );
  };
  const history = useHistory();
  
  const onEdit = () => {
    transaction_name?.title == "Bid" ?
      history.push(TRANSACTION_TYPE_BID_URL)
      : history.push(TRANSACTION_TYPE_OFFER_URL)
  };
  const postLoadMeta = useSelector((state) => state?.Profile?.postLoadMeta);
  const PostLoad = useSelector((state) => state?.Profile?.postLoad);
  const transaction_name = postLoadMeta.transaction_types.find(
    (val: object) => val.id === PostLoad.transaction_type_id
  );
  return (
    <>
      <Accordion className="confirm-load-accordion" style={{ backgroundColor: "#FAFAFA" }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className="fw-6">
            Transaction Type
            {
              fromHistory && is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">ریٹ کی قسم</span>
              </>
            }
          </p>
          {editable ? (
            <div className="editBtnWrapper">
              <OutLineButton
                classProp='tinyBtn'
                dataColor="blue"
                title="Edit"
                icon={
                  <span className="icons-edit"></span>
                }
                onClick={onEdit}
              />
            </div>
          ) : null}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} style={{ overflowY: "scroll" }}>
            <FormRow 
            label="Type" 
            value={transaction_name?.title} 
            sep="/"
            urdutitle="قسم"
            is_recipient={is_recipient}
            fromHistory={fromHistory}
            
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TransactionType;
