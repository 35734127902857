export default class Action {
  // Constants
  static GET_LOADS = "GET_LOADS";
  static GET_LOADS_SUCCESS = "GET_LOADS_SUCCESS";
  static GET_LOADS_FAIL = "GET_LOADS_FAIL";

  static GET_LOAD_DETAIL = "GET_LOAD_DETAIL";
  static GET_LOAD_DETAIL_SUCCESS = "GET_LOAD_DETAIL_SUCCESS";
  static GET_LOAD_DETAIL_FAIL = "GET_LOAD_DETAIL_FAIL";

  static ACCEPT_LOAD = "ACCEPT_LOAD";
  static ACCEPT_LOAD_SUCCESS = "ACCEPT_LOAD_SUCCESS";
  static ACCEPT_LOAD_FAIL = "ACCEPT_LOAD_FAIL";

  static AWARD_JOB = "AWARD_JOB";
  static AWARD_JOB_SUCCESS = "AWARD_JOB_SUCCESS";
  static AWARD_JOB_FAIL = "AWARD_JOB_FAIL";

  static CANCEL_LOAD = "CANCEL_LOAD";
  static CANCEL_LOAD_SUCCESS = "CANCEL_LOAD_SUCCESS";
  static CANCEL_LOAD_FAIL = "CANCEL_LOAD_FAIL";

  static RECIPIENT_DECLINED = "RECIPIENT_DECLINED";
  static RECIPIENT_DECLINED_SUCCESS = "RECIPIENT_DECLINED_SUCCESS";
  static RECIPIENT_DECLINED_FAIL = "RECIPIENT_DECLINED_FAIL";

  static POSTER_DECLINED = "POSTER_DECLINED";
  static POSTER_DECLINED_SUCCESS = "POSTER_DECLINED_SUCCESS";
  static POSTER_DECLINED_FAIL = "POSTER_DECLINED_FAIL";

  static GET_LOADS_HISTORY = "GET_LOADS_HISTORY";
  static GET_LOADS_HISTORY_SUCCESS = "GET_LOADS_HISTORY_SUCCESS";
  static GET_LOADS_HISTORY_FAIL = "GET_LOADS_HISTORY_FAIL";

  static ALLOCATE_LOAD = "ALLOCATE_LOAD";
  static ALLOCATE_LOAD_SUCCESS = "ALLOCATE_LOAD_SUCCESS";
  static ALLOCATE_LOAD_FAIL = "ALLOCATE_LOAD_FAIL";

  static SET_ALLOCATE_JOB = "SET_ALLOCATE_JOB";

  static UPDATE_RESPONSE_DEADLINE = "UPDATE_RESPONSE_DEADLINE";
  static UPDATE_RESPONSE_DEADLINE_SUCCESS = "UPDATE_RESPONSE_DEADLINE_SUCCESS";
  static UPDATE_RESPONSE_DEADLINE_FAIL = "UPDATE_RESPONSE_DEADLINE_FAIL";

  static GET_FORWARD_LOAD_DETAIL = "GET_FORWARD_LOAD_DETAIL";
  static GET_FORWARD_LOAD_DETAIL_SUCCESS = "GET_FORWARD_LOAD_DETAIL_SUCCESS";
  static GET_FORWARD_LOAD_DETAIL_FAIL = "GET_FORWARD_LOAD_DETAIL_FAIL";

  static SET_FORWARD_LOAD_DATA = "SET_FORWARD_LOAD_DATA";

  static FORWARD_LOAD = "FORWARD_LOAD";
  static FORWARD_LOAD_SUCCESS = "FORWARD_LOAD_SUCCESS";
  static FORWARD_LOAD_FAIL = "FORWARD_LOAD_FAIL";

  static ADD_DRIVER_AND_REF = "ADD_DRIVER_AND_REF";
  static ADD_DRIVER_AND_REF_SUCCESS = "ADD_DRIVER_AND_REF_SUCCESS";
  static ADD_DRIVER_AND_REF_FAIL = "ADD_DRIVER_AND_REF_FAIL";

  static CREATE_FROM_DRAFT = [
    "CREATE_FROM_DRAFT",
    "CREATE_FROM_DRAFT_SUCCESS",
    "CREATE_FROM_DRAFT_FAIL",
  ];
  //cancel load, recipient declined , poster declined

  static CANCEL_LOAD_HOME_SCREEN = "CANCEL_LOAD_HOME_SCREEN";
  static CANCEL_LOAD_HOME_SCREEN_SUCCESS = "CANCEL_LOAD_HOME_SCREEN_SUCCESS";
  static CANCEL_LOAD_HOME_SCREEN_FAIL = "CANCEL_LOAD_HOME_SCREEN_FAIL";
  // ActionsAWARD_JOB

  static SET_REQUEST_VEHIVLE_DATA = "SET_REQUEST_VEHIVLE_DATA";
  static GET_ACTIVE_LOAD_DETAIL = "GET_ACTIVE_LOAD_DETAIL";
  static GET_ACTIVE_LOAD_DETAIL_SUCCESS = "GET_ACTIVE_LOAD_DETAIL_SUCCESS";
  static GET_ACTIVE_LOAD_DETAIL_FAIL = "GET_ACTIVE_LOAD_DETAIL_FAIL";
  // Active load detail

  static GET_REQUEST_VEHICLE_DATA = "GET_REQUEST_VEHICLE_DATA";
  static GET_REQUEST_VEHICLE_DATA_SUCCESS = "GET_REQUEST_VEHICLE_DATA_SUCCESS";
  static GET_REQUEST_VEHICLE_DATA_FAIL = "GET_REQUEST_VEHICLE_DATA_FAIL";

  static CONTACT_PICK_UP_DROP_OFF = "CONTACT_PICK_UP_DROP_OFF";
  static CONTACT_PICK_UP_DROP_OFF_SUCCESS = "CONTACT_PICK_UP_DROP_OFF_SUCCESS";
  static CONTACT_PICK_UP_DROP_OFF_FAIL = "CONTACT_PICK_UP_DROP_OFF_FAIL";

  static GET_VEHICLE_MARKER = "GET_VEHICLE_MARKER";
  static GET_VEHICLE_MARKER_SUCCESS = "GET_VEHICLE_MARKER_SUCCESS";
  static GET_VEHICLE_MARKER_FAIL = "GET_VEHICLE_MARKER_FAIL";

  static SET_REQUEST_VEHICLE_DATA = "SET_REQUEST_VEHICLE_DATA";

  static GET_DRIVER_MARKER = "GET_DRIVER_MARKER";
  static GET_DRIVER_MARKER_SUCCESS = "GET_DRIVER_MARKER_SUCCESS";
  static GET_DRIVER_MARKER_FAIL = "GET_DRIVER_MARKER_FAIL";

  static POST_REQUEST_VEHICLE = "POST_REQUEST_VEHICLE";
  static POST_REQUEST_VEHICLE_SUCCESS = "POST_REQUEST_VEHICLE_SUCCESS";
  static POST_REQUEST_VEHICLE_FAIL = "POST_REQUEST_VEHICLE_FAIL";

  static ACCEPT_REQUEST_VEHICLE = "ACCEPT_REQUEST_VEHICLE";
  static ACCEPT_REQUEST_VEHICLE_SUCCESS = "ACCEPT_REQUEST_VEHICLE_SUCCESS";
  static ACCEPT_REQUEST_VEHICLE_FAIL = "ACCEPT_REQUEST_VEHICLE_FAIL";

  static ADD_VIEWER = "ADD_VIEWER";
  static ADD_VIEWER_SUCCESS = "ADD_VIEWER_SUCCESS";
  static ADD_VIEWER_FAIL = "ADD_VIEWER_FAIL";

  static ADD_REQUIREMENTS = "ADD_REQUIREMENTS";
  static ADD_REQUIREMENTS_SUCCESS = "ADD_REQUIREMENTS_SUCCESS";
  static ADD_REQUIREMENTS_FAIL = "ADD_REQUIREMENTS_FAIL";

  static MARK_AS_COMPLETE_BY_RECIPIENT = "MARK_AS_COMPLETE_BY_RECIPIENT";
  static MARK_AS_COMPLETE_BY_RECIPIENT_SUCCESS = "MARK_AS_COMPLETE_BY_RECIPIENT_SUCCESS";
  static MARK_AS_COMPLETE_BY_RECIPIENT_FAIL = "MARK_AS_COMPLETE_BY_RECIPIENT_FAIL";

  static MARK_AS_COMPLETE_BY_POSTER = "MARK_AS_COMPLETE_BY_POSTER";
  static MARK_AS_COMPLETE_BY_POSTER_SUCCESS = "MARK_AS_COMPLETE_BY_POSTER_SUCCESS";
  static MARK_AS_COMPLETE_BY_POSTER_FAIL = "MARK_AS_COMPLETE_BY_POSTER_FAIL";

  static LOAD_DETAIL_EMPTY = "LOAD_DETAIL_EMPTY";
  static UPDATE_DRIVER_CHECKLIST = "UPDATE_DRIVER_CHECKLIST";
  static UPDATE_DRIVER_CHECKLIST_SUCCESS = "UPDATE_DRIVER_CHECKLIST_SUCCESS";
  static UPDATE_DRIVER_CHECKLIST_FAIL = "UPDATE_DRIVER_CHECKLIST_FAIL";

  static NAGOTIATE_BID_POSTER = "NAGOTIATE_BID_POSTER"
  static NAGOTIATE_BID_POSTER_SUCCESS = "NAGOTIATE_BID_POSTER_SUCCESS"
  static NAGOTIATE_BID_POSTER_FAIL = "NAGOTIATE_BID_POSTER_FAIL"

  static NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT = "NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT"
  static NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_SUCCESS = "NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_SUCCESS"
  static NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_FAIL = "NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_FAIL"

  static AWARD_CONTRACT = "AWARD_CONTRACT"
  static AWARD_CONTRACT_SUCCESS = "AWARD_CONTRACT_SUCCESS"
  static AWARD_CONTRACT_FAIL = "AWARD_CONTRACT_FAIL"

  static ADD_VEHICLE_LAST_LOCATION = "ADD_VEHICLE_LAST_LOCATION"
  static ADD_VEHICLE_LAST_LOCATION_SUCCESS = "ADD_VEHICLE_LAST_LOCATION_SUCCESS"
  static ADD_VEHICLE_LAST_LOCATION_FAIL = "ADD_VEHICLE_LAST_LOCATION_FAIL"

  static SET_REQUEST_VEHICLE_CATEGORY = "SET_REQUEST_VEHICLE_CATEGORY"

  static UPLOAD_FILES_RECIPIENT = "UPLOAD_FILES_RECIPIENT";
  static UPLOAD_FILES_RECIPIENT_SUCCESS = "UPLOAD_FILES_RECIPIENT_SUCCESS";
  static UPLOAD_FILES_RECIPIENT_FAIL = "UPLOAD_FILES_RECIPIENT_FAIL";

  static HANDLE_TRACKING_CREDENTIALS = "HANDLE_TRACKING_CREDENTIALS";
  static HANDLE_TRACKING_CREDENTIALS_SUCCESS = "HANDLE_TRACKING_CREDENTIALS_SUCCESS";
  static HANDLE_TRACKING_CREDENTIALS_FAIL = "HANDLE_TRACKING_CREDENTIALS_FAIL";

  static ADD_BULK_VEHICLES = "ADD_BULK_VEHICLES"
  static ADD_BULK_VEHICLES_SUCCESS = "ADD_BULK_VEHICLES_SUCCESS"
  static ADD_BULK_VEHICLES_FAIL = "ADD_BULK_VEHICLES_FAIL"

  static ADD_BULK_VEHICLE_REFS = "ADD_BULK_VEHICLE_REFS"
  static ADD_BULK_VEHICLE_REFS_SUCCESS = "ADD_BULK_VEHICLE_REFS_SUCCESS"
  static ADD_BULK_VEHICLE_REFS_FAIL = "ADD_BULK_VEHICLE_REFS_FAIL"

  static ADD_TRACKING_ID = "ADD_TRACKING_ID"
  static ADD_TRACKING_ID_SUCCESS = "ADD_TRACKING_ID_SUCCESS"
  static ADD_TRACKING_ID_FAIL = "ADD_TRACKING_ID_FAIL"

  static UPLOAD_DOC_RECIPIENT = "UPLOAD_DOC_RECIPIENT"
  static UPLOAD_DOC_RECIPIENT_SUCCESS = "UPLOAD_DOC_RECIPIENT_SUCCESS"
  static UPLOAD_DOC_RECIPIENT_FAIL = "UPLOAD_DOC_RECIPIENT_FAIL"

  static SHOW_INDICATION_DOTS = "SHOW_INDICATION_DOTS"
  static SHOW_INDICATION_DOTS_SUCCESS = "SHOW_INDICATION_DOTS_SUCCESS"
  static SHOW_INDICATION_DOTS_FAIL = "SHOW_INDICATION_DOTS_FAIL"

  static SHOW_INDICATION_DOTS_VIEWER = "SHOW_INDICATION_DOTS_VIEWER"
  static SHOW_INDICATION_DOTS_VIEWER_SUCCESS = "SHOW_INDICATION_DOTS_VIEWER_SUCCESS"
  static SHOW_INDICATION_DOTS_VIEWER_FAIL = "SHOW_INDICATION_DOTS_VIEWER_FAIL"


  static REMOVE_VIEWER = "REMOVE_VIEWER";
  static REMOVE_VIEWER_SUCCESS = "REMOVE_VIEWER_SUCCESS";
  static REMOVE_VIEWER_FAIL = "REMOVE_VIEWER_FAIL";

  static PIN_POINTS_FOR_VEHICLE = "PIN_POINTS_FOR_VEHICLE";
  static PIN_POINTS_FOR_VEHICLE_SUCCESS = "PIN_POINTS_FOR_VEHICLE_SUCCESS";
  static PIN_POINTS_FOR_VEHICLE_FAIL = "PIN_POINTS_FOR_VEHICLE_FAIL";

  static REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
  static REMOVE_DOCUMENT_SUCCESS = 'REMOVE_DOCUMENT_SUCCESS';
  static REMOVE_DOCUMENT_FAIL = 'REMOVE_DOCUMENT_FAIL';


  static UPDATE_DRIVER_DETAIL = "UPDATE_DRIVER_DETAIL";
  static UPDATE_DRIVER_DETAIL_SUCCESS = 'UPDATE_DRIVER_DETAIL_SUCCESS';
  static UPDATE_DRIVER_DETAIL_FAIL = 'UPDATE_DRIVER_DETAIL_FAIL';


  static uploadDocRecipient(payload: any) {
    return {
      type: Action.UPLOAD_DOC_RECIPIENT,
      payload: payload,
    };
  }

  static addTrackingId(payload: any) {
    return {
      type: Action.ADD_TRACKING_ID,
      payload: payload,
    };
  }

  static addBulkVehicles(payload: any) {
    return {
      type: Action.ADD_BULK_VEHICLES,
      payload: payload,
    };
  }

  static addBulkVehicleRefs(payload: any) {
    return {
      type: Action.ADD_BULK_VEHICLE_REFS,
      payload: payload,
    };
  }

  static uploadFilesRecipient(payload: any) {
    return {
      type: Action.UPLOAD_FILES_RECIPIENT,
      payload: payload?.payload,
    };
  }

  static setRequestVehicleCategory(payload: any) {
    return {
      type: Action.SET_REQUEST_VEHICLE_CATEGORY,
      payload,
    };
  }

  static addVehicleLastLocation(payload: any) {
    return {
      type: Action.ADD_VEHICLE_LAST_LOCATION,
      payload,
    };
  }

  static awardContract(payload: any) {
    return {
      type: Action.AWARD_CONTRACT,
      payload,
    };
  }

  static negotiateBidPoster(payload: any) {
    return {
      type: Action.NAGOTIATE_BID_POSTER,
      payload,
    };
  }


  static negotiateBidAcceptRejectRecipient(payload: any) {
    return {
      type: Action.NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT,
      payload,
    };
  }


  static updateDriverChecklist(payload: any) {
    return {
      type: Action.UPDATE_DRIVER_CHECKLIST,
      payload,
    };
  }

  static loadDetailEmpty() {
    return {
      type: Action.LOAD_DETAIL_EMPTY,
    };
  }

  static getLoads(payload: any) {
    return {
      type: Action.GET_LOADS,
      payload,
    };
  }

  static setVehicleData(payload: any) {
    return {
      type: Action.SET_REQUEST_VEHICLE_DATA,
      payload,
    };
  }

  static getLoadDetail(payload: any) {
    return {
      type: Action.GET_LOAD_DETAIL,
      payload,
    };
  }

  static acceptLoad(payload: any) {
    return {
      type: Action.ACCEPT_LOAD,
      payload,
    };
  }

  static awardJob(payload: any) {
    return {
      type: Action.AWARD_JOB,
      payload,
    };
  }

  static cancelLoad(payload: any) {
    return {
      type: Action.CANCEL_LOAD,
      payload,
    };
  }

  static recipientDeclined(payload: any) {
    return {
      type: Action.RECIPIENT_DECLINED,
      payload,
    };
  }

  static posterDeclined(payload: any) {
    return {
      type: Action.POSTER_DECLINED,
      payload,
    };
  }

  // get load history
  static getLoadsHistory(payload: any) {
    return {
      type: Action.GET_LOADS_HISTORY,
      payload,
    };
  }

  static allocateLoad(payload: any) {
    return {
      type: Action.ALLOCATE_LOAD,
      payload,
    };
  }

  static setAllocatJob(payload: any) {
    return {
      type: Action.SET_ALLOCATE_JOB,
      payload,
    };
  }

  static updateResponseDeadline(payload: any) {
    return {
      type: Action.UPDATE_RESPONSE_DEADLINE,
      payload,
    };
  }

  static getForwardLoadDetail(payload: any) {
    return {
      type: Action.GET_FORWARD_LOAD_DETAIL,
      payload,
    };
  }

  static setForwardLoadData(payload: any) {
    return {
      type: Action.SET_FORWARD_LOAD_DATA,
      payload,
    };
  }

  static forwardLoad = (payload: any) => {
    return {
      type: Action.FORWARD_LOAD,
      payload,
    };
  };

  static addDriverAndRef = (payload: any) => {
    return {
      type: Action.ADD_DRIVER_AND_REF,
      payload,
    };
  };

  static actionCreateFromDraft = (payload: any) => {
    return {
      type: Action.CREATE_FROM_DRAFT[0],
      payload,
    };
  };
  static cancelLoadHomeScreen(payload: any) {
    return {
      type: Action.CANCEL_LOAD_HOME_SCREEN,
      payload,
    };
  }
  static setRequestVehicleData(payload: any) {
    return {
      type: Action.SET_REQUEST_VEHIVLE_DATA,
      payload,
    };
  }

  static getActiveLoadDetail(payload: any) {
    return {
      type: Action.GET_ACTIVE_LOAD_DETAIL,
      payload,
    };
  }

  static getRequestVehicleData(payload: any) {
    return {
      type: Action.GET_REQUEST_VEHICLE_DATA,
      payload,
    };
  }

  static contactPickUpDropOff(payload: any) {
    return {
      type: Action.CONTACT_PICK_UP_DROP_OFF,
      payload,
    };
  }

  static getVehicleMarker(payload: any) {
    return {
      type: Action.GET_VEHICLE_MARKER,
      payload,
    };
  }

  static getDriverMarker(payload: any) {
    return {
      type: Action.GET_DRIVER_MARKER,
      payload,
    };
  }

  static postRequestVehicle(payload: any) {
    return {
      type: Action.POST_REQUEST_VEHICLE,
      payload,
    };
  }

  static acceptRequestVehicle(payload: any) {
    return {
      type: Action.ACCEPT_REQUEST_VEHICLE,
      payload,
    };
  }

  static addViewer(payload: any) {
    return {
      type: Action.ADD_VIEWER,
      payload
    }
  }

  static addRequirements(payload: any) {
    return {
      type: Action.ADD_REQUIREMENTS,
      payload
    }
  }

  static markAsCompleteByRecipient(payload: any) {
    return {
      type: Action.MARK_AS_COMPLETE_BY_RECIPIENT,
      payload,
    };
  }

  static markAsCompleteByPoster(payload: any) {
    return {
      type: Action.MARK_AS_COMPLETE_BY_POSTER,
      payload,
    };
  }

  static hanldeTrackingCredentials(payload: any) {
    return {
      type: Action.HANDLE_TRACKING_CREDENTIALS,
      payload
    }
  }

  static showIndicationDots(payload: any) {
    return {
      type: Action.SHOW_INDICATION_DOTS,
      payload
    }
  }

  static showIndicationDotsViewer(payload: any) {
    return {
      type: Action.SHOW_INDICATION_DOTS_VIEWER,
      payload
    }
  }

  static removeViewer(payload: any) {
    return {
      type: Action.REMOVE_VIEWER,
      payload
    }
  }

  static pinPointsForVehicle(payload: any) {
    return {
      type: Action.PIN_POINTS_FOR_VEHICLE,
      payload
    }
  }

  static removeDocument(payload: any) {
    return {
      type: Action.REMOVE_DOCUMENT,
      payload,
    };

  }

  static updateDriverDetail(payload: any) {
    return {
      type: Action.UPDATE_DRIVER_DETAIL,
      payload: payload,
    };
  }


}
