export const BASE_URL = "/";
export const STYLE_GUIDE = "/style-guide";
export const NOT_FOUND = `${BASE_URL}404`;
export const LOGIN_URL = `${BASE_URL}login`;
export const OTP_URL = `${BASE_URL}otp`;
export const WELLCOME_URL = `${BASE_URL}wellcome`;
export const PROMO_VIDEO_URL = `${BASE_URL}promo`;
export const UPLOAD_CNIC_URL = `${BASE_URL}upload-cnic`;
export const REGISTRATION_URL = `${BASE_URL}registration`;
export const REVIEW_DETAIL_URL = `${BASE_URL}review-detail`;
export const SUCCESS_URL = `${BASE_URL}success`;
export const DASHBOARD_URL = `${BASE_URL}dashboard/:type/:tabs`;
export const TERMS_URL = `${BASE_URL}terms`;
export const INQUIRY_TYPE = `${BASE_URL}inquiry-type`;
export const SELECT_VEHICLE = `${BASE_URL}selectVehicle`;
export const ADDITIONAL_DETAILS = `${BASE_URL}additionalDetails`;
export const LOAD_TYPE_URL = `${BASE_URL}load-type`;
export const HELP_URL = `${BASE_URL}help`;
export const RESET_PASSWORD_URL = `${BASE_URL}reset-password`;
export const ACCOUNT_BALANCE_URL = `${BASE_URL}account-balance`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}forgot-password`;
export const CHECK_SMS_URL = `${BASE_URL}check-sms`;
export const TRANSACTION_TYPE_OFFER_URL = `${BASE_URL}offer`;
export const TRANSACTION_TYPE_BID_URL = `${BASE_URL}bid`;
export const MY_PROFILE_URL = `${BASE_URL}my-profile`;
export const EDIT_PROFILE_URL = `${BASE_URL}edit-profile`;
export const SELECT_LOCATION = `${BASE_URL}selectLocation`;
export const SELECT_LOCATION_DROPOFF = `${BASE_URL}selectLocationDropoff`;
export const INQUIRY_TYPE_URL = `${BASE_URL}inquiry-type`;
export const SIGNUP_URL = `${BASE_URL}signup`;
export const PAYMENT_TERMS_URL = `${BASE_URL}payment-terms`;
export const RESPONSE_DEADLINE = `${BASE_URL}response-deadline`;
export const UPLOADED_DOCUMENT = `${BASE_URL}uploadedDocument`;
export const Upload_Doc = `${BASE_URL}uploadDoc`;
export const SELECT_RECIPIENT = `${BASE_URL}selectRecipient`;
export const ADD_RECIPIENT = `${BASE_URL}addRecipient`;
export const ADD_RECIPIENT_SUCCESSFULLY = `${BASE_URL}AddReceipientSuccessfully/:id`;
export const ADD_RECIPIENT_SUCCESSFULLY2 = `${BASE_URL}AddReceipient-uccessfully/:id`;
export const POSTED_SUCCESSFULLY = `${BASE_URL}postSuccessfully`;
export const SETUP_PASSWORD = `${BASE_URL}setup-password`;
export const TERMS_DRAWER_URL = `${BASE_URL}terms-and-condition`;
export const VEHICLE_URL = `${BASE_URL}vehicles`;
export const LOCATIONS_URL = `${BASE_URL}locations`;

// export const LOCATIONS_URL = `${BASE_URL}locations/:type`;
export const NOTIFICATIONS_URL = `${BASE_URL}notifications`;
export const CHANGE_PHONE_OTP_URL = `${BASE_URL}change-phone-otp`;
export const LOAD_DRAFT_URL = `${BASE_URL}load-draft`;
export const ADD_ADDRESS = `${BASE_URL}add-address`;
export const CONFIRM_LOAD_URL = `${BASE_URL}confirm-load`;
export const LOAD_DETAIL_POSTER_URL = `${BASE_URL}load-details-poster/:id/:type`;
export const LOAD_DETAIL_RECIPIENT_URL = `${BASE_URL}load-details-recipient/:id/:type`;
export const ADD_NETWORK_URL = `${BASE_URL}add-network`;
export const ADD_VEHICLE_URL = `${BASE_URL}add-vehicle`;
export const FCL_BULK_ADDITIONAL_DETAILS = `${BASE_URL}FCL_BULK_ADDITIONAL_DETAILS`;
export const LOAD_HISTORY_URL = `${BASE_URL}load-history`;

export const ADD_LOCATION = `${BASE_URL}add-location`;

// export const ADD_LOCATION = `${BASE_URL}add-location/:index`;
export const ACTIVE_LOAD_URL = `${BASE_URL}job-loads/:load_id/active/:job_load_driver_id`;
export const CONTAINER_REFERNCE_URL = `${BASE_URL}container-reference`;
export const CONTRACT_DETAILS_URL = `${BASE_URL}contract-details`;
export const SELECT_VEHICLE_CONTRACT = `${BASE_URL}select-vehicle-contract`;
export const CONTRACT_LOCATION = `${BASE_URL}contract-location`;
export const CONTRACT_ADD_LOCATION = `${BASE_URL}contract-location/add/:index`;
export const CONTRACT_ADD_NEW_LOCATION = `${BASE_URL}contract-location/add-new`;
export const SPOT_ADD_NEW_LOCATION = `${BASE_URL}spot-location/add-new`;
export const SELECT_RECIPIENT_URL = `${BASE_URL}select-recipient/:type`;
export const FORWARD_FLOW_URL = `${BASE_URL}forward-load/:load_id/`;
export const CONTRACT_DETAIL_RECEPIENT_URL = `${BASE_URL}contract-detail-recepient/:id/:type`;
export const CONTRACT_DETAIL_POSTER_URL = `${BASE_URL}contract-details-poster/:id/:type`;
export const REQUEST_VEHICLE_URL = `${BASE_URL}request-vehicle`;
export const PROMOTIONS_URL = `${BASE_URL}promotions`;
export const PROMOTION_DETAIL_URL = `${BASE_URL}promotion-detail/:id`;
export const NETWORK_NEW_SCREEN_URL = `${BASE_URL}network-new`;
export const ALLNOTIFICATION_URL = `${BASE_URL}all-notification`;
export const TERMS_POLICY = `${BASE_URL}terms-and-policy`
export const SELECT_VEHICLE_CONTRACT_NEW = `${BASE_URL}select-vehicle-contract-new`
export const CONTRACT_VEHICLE_DISPLAY_NEW = `${BASE_URL}contract-vehicle-display-new`
export const REQUEST_VEHICLE_LOCATIONS = `${BASE_URL}request-vehicle-locations`
export const REQUEST_VEHICLE_CATEGORIES = `${BASE_URL}request-vehicle-categoires`
export const HOME_URL = `${BASE_URL}home`;
export const BULK_ADD_VEHICLE_SCREEN = `${BASE_URL}bulk-add-vehicle-screen`;
export const MG_EXPRESS_QUOTE = `${BASE_URL}mg-express-quote-screen`;
export const ACTIVE_LOADS = `${BASE_URL}active-loads`;
export const REPORTS = `${BASE_URL}reports`;
export const VIEWER_DETAIL = `${BASE_URL}viewer-detail/:id`;
export const NEW_DASHBOARD_URL = `${BASE_URL}new-dashboard`;
export const LOAD_DETAIL_DASHBOARD_URL = `${BASE_URL}dashboard-load-details`;
export const VEHICLE_DETAIL_DASHBOARD_URL = `${BASE_URL}dashboard-vehicle-details`;





