import React, { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  PrimaryButton,
  MaterialUIPickers,
  CustomModal,
} from "@components";
import { ProfileAction } from "@temp/store/actions";

import { useSelector, useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import TitleLayout from "@temp/components/TitleLayout";
import "./style.scss";
import { LOAD_TYPE } from "@temp/constants";
import { handleDateValidation, showMessage, getMillisec } from "@temp/utils/helper";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import dayjs from "dayjs";
import moment from "moment";

export const ResponseDeadlineScreen: FC = () => {
  const history = useHistory();
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const [pickupDate, setPickupDate] = useState(null);
  const [pickupTime, setPickupTime] = useState(null);
  const [responseDate, setDropoffDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));
  const [responseTime, setDropoffTime] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [emptyReturnDeadlineAtDate, setEmptyReturnDeadlineAtDate] = useState(null);
  const [emptyReturnDeadlineAtTime, setEmptyReturnDeadlineAtTime] = useState(null);
  const [showModal, toggleModal] = useState(false)
  const [info, setInfo] = useState('')
  const [rateValidityDate, setRateValidityDate] = useState(null);
  const dispatch = useDispatch();
  const load_type = postLoadMeta.load_types.find((val: object) => val.id === PostLoad.load_type);

  useEffect(() => {
    if (PostLoad?.responseDeadline?.pickupDate) {
      const { responseDeadline } = PostLoad;
      if (responseDeadline?.responseTime)
        setDropoffTime(responseDeadline?.responseTime);
      if (responseDeadline?.responseDate)
        setDropoffDate(responseDeadline?.responseDate);
      if (responseDeadline?.pickupTime)
        setPickupTime(responseDeadline?.pickupTime);
      if (responseDeadline?.pickupDate)
        setPickupDate(responseDeadline?.pickupDate);
      if (responseDeadline?.deliveryTime)
        setDeliveryTime(responseDeadline?.deliveryTime);
      if (responseDeadline?.deliveryDate)
        setDeliveryDate(responseDeadline?.deliveryDate);
      if (responseDeadline?.emptyReturnDeadlineAtTime)
        setEmptyReturnDeadlineAtTime(responseDeadline?.emptyReturnDeadlineAtTime);
      if (responseDeadline?.emptyReturnDeadlineAtDate)
        setEmptyReturnDeadlineAtDate(responseDeadline?.emptyReturnDeadlineAtDate);
      if (responseDeadline?.rateValidityDate)
        setRateValidityDate(responseDeadline?.rateValidityDate);
      setShowDateError(handleDateValidation(responseDeadline?.responseDate, responseDeadline?.responseTime, responseDeadline?.pickupDate, responseDeadline?.pickupTime, responseDeadline?.deliveryDate, responseDeadline?.deliveryTime, responseDeadline?.emptyReturnDeadlineAtDate, responseDeadline?.emptyReturnDeadlineAtTime, 'response_deadline_screen'))

    }
  }, []);

  const handleSubmit = () => {
    if (PostLoad?.from == "forward") {
      let poster_time = getMillisec(PostLoad?.poster_deadline_date, PostLoad?.poster_deadline_Time)
      let response_deadline = getMillisec(responseDate, responseTime)
      if (response_deadline >= poster_time) {
        showMessage.current({
          message:
            // "Response deadline should be less than deadline set by poster.",
            "Response deadline should be less than the deadline set by poster.",
          status: 'error'
        })
      } else {
        dispatch(
          ProfileAction.postLoadData({
            responseDeadline: {
              pickupDate,
              pickupTime,
              responseDate,
              responseTime: responseTime && moment(responseTime).format('HH:mm:ss'),
              deliveryDate,
              deliveryTime: deliveryTime && deliveryTime.format("HH:mm:ss"),
              emptyReturnDeadlineAtDate,
              emptyReturnDeadlineAtTime,
              rateValidityDate
            },
          })
        );
        history.push("payment-terms");
      }
    } else {
      dispatch(
        ProfileAction.postLoadData({
          responseDeadline: {
            pickupDate,
            pickupTime: pickupTime? pickupTime&& typeof(pickupTime) ==='string'?pickupTime: moment(new Date(pickupTime)).format('HH:mm:ss'): null,
            responseDate,
            responseTime: typeof(responseTime) ==='string'?responseTime: moment(new Date(responseTime)).format('HH:mm:ss'),
            deliveryDate,
            deliveryTime: deliveryTime ? deliveryTime&& typeof(deliveryTime) ==='string'?deliveryTime: moment(new Date(deliveryTime)).format('HH:mm:ss') : null,
            emptyReturnDeadlineAtDate,
            emptyReturnDeadlineAtTime: emptyReturnDeadlineAtTime ? emptyReturnDeadlineAtTime && typeof(emptyReturnDeadlineAtTime) ==='string'?emptyReturnDeadlineAtTime: moment(new Date(emptyReturnDeadlineAtTime)).format('HH:mm:ss'): null,
            rateValidityDate
          },
        })
      );
      history.push("payment-terms");
    }
  };
  const [showDateError, setShowDateError] = useState(null)

  return (
    <TitleLayout
      pageNumber={6}
      progress={60}
      title={`Specify Date and Time- ${load_type?.title}`}
    >
      <div className="responseDeadlineWrapper DatePickerFieldWrap">
        {/* response deadline  */}
        <FadeInComponent>
          <div className="timeTitle">
            {`${PostLoad?.transaction_type_id == 2 ? "Bidding" : "Offer"} Response Deadline`}
          </div>
          <MaterialUIPickers
            setDate={(e) => {
              setDropoffDate(e);
              setShowDateError(handleDateValidation(e, responseTime, pickupDate, pickupTime, deliveryDate, deliveryTime, emptyReturnDeadlineAtDate, emptyReturnDeadlineAtTime, 'response_deadline_screen'))
            }}
            setTime={(e) => {
              setDropoffTime(e);
              setShowDateError(handleDateValidation(responseDate, e, pickupDate, pickupTime, deliveryDate, deliveryTime, emptyReturnDeadlineAtDate, emptyReturnDeadlineAtTime, 'response_deadline_screen'))
            }}
            date={responseDate}
            time={responseTime}
            from={true}
            minDate={new Date()}
            maxDate={rateValidityDate
              ? rateValidityDate : "3050-01-01"
            }
          />
        </FadeInComponent>
        


        {/* pickup deadline */}
        <FadeInComponent>
          <div className="timeTitleWrapper">
            <div className="timeTitle">Pickup  Deadline
              {
                PostLoad?.additional_pickups?.length ?
                  <InfoIcon
                    onClick={() => {
                      setInfo("Target pickup deadline at first pickup location");
                      toggleModal(true);
                    }}
                  /> : ''
              }
            </div>
            {
              (pickupTime) ?
                <div
                  onClick={() => {
                    // setPickupDate(null)
                    setPickupTime(null)
                    setShowDateError(handleDateValidation(responseDate, responseTime, pickupDate, null, deliveryDate, deliveryTime, emptyReturnDeadlineAtDate, emptyReturnDeadlineAtTime, 'response_deadline_screen'))
                  }}
                  className="clearBtn">
                  Clear
                </div> : ''
            }
          </div>
          <MaterialUIPickers
            date={pickupDate}
            setDate={(e) => {
              setPickupDate(e);
              setRateValidityDate(e);
              setShowDateError(handleDateValidation(responseDate, responseTime, e, pickupTime, deliveryDate, deliveryTime, emptyReturnDeadlineAtDate, emptyReturnDeadlineAtTime, 'response_deadline_screen'))
            }}
            setTime={(e) => {
              setPickupTime(e);
              setShowDateError(handleDateValidation(responseDate, responseTime, pickupDate, e, deliveryDate, deliveryTime, emptyReturnDeadlineAtDate, emptyReturnDeadlineAtTime, 'response_deadline_screen'))
            }}
            dateType={"PickUp Date"}
            time={pickupTime}
            from={true}
            fromTime={true}
            // extraData={{
            //   setPickupTime: setPickupTime,
            // }}
            minDate={responseDate}
            maxDate={rateValidityDate
              ? rateValidityDate : null
            }
          />

        </FadeInComponent>

        {/* delivery deadline */}
        <FadeInComponent>
          <div className="timeTitleWrapper">
            <div className="timeTitle">
              Delivery Deadline
              {
                PostLoad?.additional_dropoffs?.length ?
                  <InfoIcon
                    onClick={() => {
                      setInfo("Target delivery deadline at final drop off location");
                      toggleModal(true);
                    }}
                  /> : ''
              }
            </div>
            {
              (deliveryDate || deliveryTime) ?
                <div
                  onClick={() => {
                    setDeliveryDate(null)
                    setDeliveryTime(null)
                    setShowDateError(handleDateValidation(responseDate, responseTime, pickupDate, pickupTime, null, null, emptyReturnDeadlineAtDate, emptyReturnDeadlineAtTime, 'response_deadline_screen'))
                  }}
                  className="clearBtn">
                  Clear
                </div> : ''
            }
          </div>
          <div>
            <MaterialUIPickers
              setDate={(e) => {
                setDeliveryDate(e);
                setShowDateError(handleDateValidation(responseDate, responseTime, pickupDate, pickupTime, e, deliveryTime, emptyReturnDeadlineAtDate, emptyReturnDeadlineAtTime, 'response_deadline_screen'))
              }}
              setTime={(e) => {
                setDeliveryTime(e);
                setShowDateError(handleDateValidation(responseDate, responseTime, pickupDate, pickupTime, deliveryDate, e, emptyReturnDeadlineAtDate, emptyReturnDeadlineAtTime, 'response_deadline_screen'))
              }}
              date={deliveryDate}
              time={deliveryTime}
              minDate={pickupDate}
              //old field
              maxDate={"3050-01-01"}
              // new field added
              from={false}
              fromTime={true}
            />

          </div>
        </FadeInComponent>

        {/* empty return deadline at */}
        <div>
          {
            load_type?.title == LOAD_TYPE?.fcl && PostLoad?.is_empty_return ?
              <FadeInComponent>
                <div className="timeTitleWrapper">
                  <div className="timeTitle">Empty Pickup / Return Deadline</div>
                  {
                    (emptyReturnDeadlineAtDate || emptyReturnDeadlineAtTime) ?
                      <div
                        onClick={() => {
                          setEmptyReturnDeadlineAtDate(null)
                          setEmptyReturnDeadlineAtTime(null)
                          setShowDateError(handleDateValidation(responseDate, responseTime, pickupDate, pickupTime, deliveryDate, deliveryTime, null, null, 'response_deadline_screen'))
                        }}
                        className="clearBtn">
                        Clear
                      </div> : ''
                  }
                </div>
                <div>

                  <MaterialUIPickers
                    setDate={(e) => {
                      setEmptyReturnDeadlineAtDate(e);
                      setShowDateError(handleDateValidation(responseDate, responseTime, pickupDate, pickupTime, deliveryDate, deliveryTime, e, emptyReturnDeadlineAtTime, 'response_deadline_screen'))
                    }}
                    setTime={(e) => {
                      setEmptyReturnDeadlineAtTime(e);
                      setShowDateError(handleDateValidation(responseDate, responseTime, pickupDate, pickupTime, deliveryDate, deliveryTime, emptyReturnDeadlineAtDate, e, 'response_deadline_screen'))
                    }}
                    date={emptyReturnDeadlineAtDate}
                    time={emptyReturnDeadlineAtTime}
                    minDate={new Date()}
                    maxDate={"3050-01-01"}
                    from={false}
                    fromTime={true}
                  />
                </div>
              </FadeInComponent> : ''
          }
        </div>

        {/* rate Validity */}
        <div>
          {
            PostLoad?.inquiry_type == 1 ?
              <FadeInComponent>
                <div className="timeTitleWrapper">
                  <div className="timeTitle">Rate Validity</div>

                </div>
                <div>

                  <MaterialUIPickers
                    setDate={(e) => {
                      setRateValidityDate(e);
                    }}
                    date={rateValidityDate}
                    minDate={pickupDate} // old field 
                    maxDate={"3050-01-01"}  // new field added
                    from={true}
                    dateType={"Rate Validity"}
                  />
                </div>
              </FadeInComponent>
              : ''
          }
        </div>
        {
          showDateError &&
          <div className="error-container error">
            <div>
              <InfoIcon className="error-icon_error" />
            </div>
            <div className="error-text">
              {showDateError}
            </div>
          </div>
        }
      </div>
      <PrimaryButton
        disabled={responseDate && responseTime && pickupDate && showDateError == "" ? false : true} 
        rightIcon={true}
        title="Continue"
        onClick={() => {
          handleSubmit();
        }}
      />
      {/* show info icon watermark */}
      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showModal}
        toggleModal={toggleModal}
      >
        <div>
          <InfoIcon className="icon" />
          <p className="title">Information</p>
          <p>
            {info}
          </p>
        </div>
      </CustomModal>
    </TitleLayout>
  );
};
