import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CustomText, OutLineButton } from "@temp/components";
import { Accordion, AccordionSummary } from "@mui/material/";
import { Images, Colors } from "@temp/assets";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { triggerMixpanelEvent } from "@temp/utils/helper";
import { isBrowser } from "react-device-detect";
import {
    sendEventToAndroid,
    sendEventToiOS,
} from "../../../utils/mobileEventHandler";

var numeral = require("numeral");

export const ActiveVehicles = ({
    loadDetail,
    fromViewer = false,
    expandVehicle,
    setExpandVehicle,
    markersVehicle

}) => {
    const history = useHistory();

    let pickup_dropoff_location = []
    if (loadDetail) {
        if (loadDetail?.additional_dropoffs.length) {
            pickup_dropoff_location.push(loadDetail?.locations[0])
            pickup_dropoff_location.push(loadDetail?.additional_dropoffs[loadDetail?.additional_dropoffs.length - 1])
        } else {
            pickup_dropoff_location.push(loadDetail?.locations[0])
            pickup_dropoff_location.push(loadDetail?.locations[1])
        }
    }


    const shareOnWhatsapp = () => {
        let text = `MG Delivery Alert\nLoad Name: ${loadDetail?.title}\nLoad ID: ${loadDetail?.id} ${loadDetail?.ref_no ? `\nClient Ref: ${loadDetail?.ref_no}` : ""}\nFrom: ${pickup_dropoff_location[0]?.title}\nTo: ${pickup_dropoff_location[1]?.title}\n`
        loadDetail?.active_vehicles?.map((item, index) => {
            const vehicle = markersVehicle?.find((tem) => tem?.vehicle_id === item?.vehicle_id)

            if (loadDetail?.load_type == "Non-Containerized") {
                text += `\nVehicle No: ${index + 1}\nPlate Number: ${item?.plate_no} ${item?.no_of_package ?`\nNo of Packages: ${item?.no_of_package}`: "" } ${item?.weight_one && item?.weight_two ? `\nWeight 1: ${numeral(item?.weight_one).format("0,0")} Kg\nWeight 2 : ${numeral(item?.weight_two).format("0,0")} Kg` : item?.weight_one ? `\nWeight: ${numeral(item?.weight_one).format("0,0")} Kg` : ""}\nDriver Mobile: 0${item?.driver_phone_no
                    }\nCurrent Location: ${markersVehicle?.length > 0 ? vehicle?.location ? vehicle?.location : "--"
                        : item?.driver_last_location
                    }\n`
            } else {
                text += `\nVehicle No: ${index + 1}\nPlate Number: ${item?.plate_no} ${item?.container_ref_one && item?.container_ref_two ? `\nContainer Number 1: ${item?.container_ref_one}\nContainer Number 2: ${item?.container_ref_two}` : item?.container_ref_one ? `\nContainer Number 1: ${item?.container_ref_one}` : ""} ${item?.no_of_package ? `\nNo. of Packages: ${item?.no_of_package}` : ''}${item?.weight_one && item?.weight_two ? `\nWeight 1: ${numeral(item?.weight_one).format("0,0")} Kg \nWeight 2 : ${numeral(item?.weight_two).format("0,0")} Kg` : item?.weight_one ? `\nWeight: ${numeral(item?.weight_one).format("0,0")} Kg` : ""}\nDriver Mobile: 0${item?.driver_phone_no
                    }\nCurrent Location: ${markersVehicle?.length > 0 ? vehicle?.location ? vehicle?.location : "--"
                        : item?.driver_last_location
                    }\n`
            }
        })

        let encoded_data = window.encodeURIComponent(text)

        if (isBrowser) {
            window.open(`https://wa.me/?text=${encoded_data}`)
        } else {
            if (sendEventToAndroid("ShareViaWhatsapp")) {
                window.Android.ShareViaWhatsapp(
                    String(encoded_data)
                );
            } else if (sendEventToiOS("ShareViaWhatsapp")) {
                window.webkit.messageHandlers.ShareViaWhatsapp.postMessage(
                    JSON.stringify({
                        encoded_data: encoded_data,
                    })
                );
            }
        }
    }
    return (
        <div className="accordion-container">
            <Accordion
                className="accordion-background"
                onChange={() => setExpandVehicle(!expandVehicle)}
                expanded={expandVehicle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <CustomText.OpenSansSemiBold
                        textStyle={{
                            fontSize: "0.875rem",
                            color: Colors.Black,
                        }}
                    >
                        Vehicles Status
                    </CustomText.OpenSansSemiBold>
                </AccordionSummary>
                <div className="accordion-content">
                    {loadDetail?.active_vehicles?.length ?
                        <div>
                            {loadDetail?.active_vehicles?.map((item, index) => {
                                return (
                                    <div className="awarded-vehicle-list"
                                        onClick={() => {
                                            triggerMixpanelEvent("View Active Load", "view active load from awarded vehicle list")
                                            history.push(
                                                `/job-loads/${loadDetail?.id}/active/${item?.id}`, { fromPoster: fromViewer ? false : true }
                                            );
                                        }}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div className="flex_row_div">
                                            <CustomText.OpenSansSemiBold textStyle={{ marginLeft: 5 }}>Details of Vehicle {index + 1}</CustomText.OpenSansSemiBold>
                                            <div style={{ display: "flex", marginRight: 12, alignItems: "center", }}>
                                                <CustomText.OpenSansSemiBold
                                                    textStyle={{ fontSize: "0.688rem", color: Colors.Primary }}>
                                                    {"View"}
                                                </CustomText.OpenSansSemiBold>
                                                <div className="blueRightArrow">
                                                    <img src={Images.BlueRightArrow} />
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            item?.is_replaced == 1 && item?.replaced_at !== null
                                                ?
                                                <div className="vehicleDetailsTextWrap">
                                                    <div className="fw-5">
                                                        Replaced Vehicle
                                                    </div>
                                                    {/* <CustomText.OpenSansRegular>
                                                        <span>
                                                            {
                                                                `From- ${item?.replaced_plate_no} ${moment(item?.replaced_at).format('DD-MM-YYYY hh:mm A')} To- ${item?.plate_no} ${moment(item?.created_at).format('DD-MM-YYYY hh:mm A')} `
                                                            }

                                                        </span>
                                                    </CustomText.OpenSansRegular> */}
                                                    <CustomText.OpenSansRegular textStyle={{
                                                        display: 'flex'
                                                    }}>

                                                        <span className="leftReplaceVehicle">{`From - ${item?.replaced_plate_no} `}
                                                            <span>{`${moment(item?.replaced_at).format('DD-MM-YYYY hh:mm A')} `}</span>
                                                        </span>
                                                        <span className="rightReplaceVehicle">{`To - ${item?.plate_no} `}
                                                            <span>{`${moment(item?.created_at).format('DD-MM-YYYY hh:mm A')} `}</span>
                                                        </span>
                                                    </CustomText.OpenSansRegular>
                                                </div>
                                                : null
                                        }

                                        {item?.replaced_at == null ?
                                            <div className="vehicleDetailsTextWrap">
                                                <div className="fw-5">
                                                    Plate Number
                                                </div>
                                                <CustomText.OpenSansRegular>
                                                    {`${item?.plate_no} (${moment(item?.created_at).format('DD-MM-YYYY hh:mm A')})`}
                                                </CustomText.OpenSansRegular>
                                            </div>
                                            : null}

                                        {/* {item?.created_at &&
                                            <div className="vehicleDetailsTextWrap">
                                                <div className="flexRowWrap">
                                                    <CustomText.OpenSansRegular textStyle={{
                                                        // marginLeft: 5
                                                    }}>
                                                        {`${moment(item?.created_at).format('DD-MM-YYYY hh:mm A')}`}
                                                    </CustomText.OpenSansRegular>
                                                </div>
                                            </div>
                                        } */}


                                        {/* {item?.replaced_at &&
                                            <div className="vehicleDetailsTextWrap">
                                                <div className="flexRowWrap">
                                                    <CustomText.OpenSansRegular textStyle={{
                                                        // marginLeft: 
                                                        
                                                    }}>
                                                        {`${moment(item?.replaced_at).format('DD-MM-YYYY hh:mm A')}`}
                                                    </CustomText.OpenSansRegular>
                                                </div>
                                            </div>
                                        } */}

                                        {
                                            item?.vehicle_type ?
                                                <div className="vehicleDetailsTextWrap">
                                                    <div className="fw-5">
                                                        Vehicle Type
                                                    </div>
                                                    {
                                                        item?.vehicle_type ?
                                                            <CustomText.OpenSansRegular
                                                            >
                                                                {item?.vehicle_type}
                                                            </CustomText.OpenSansRegular>
                                                            :
                                                            <CustomText.OpenSansRegular>N/A</CustomText.OpenSansRegular>
                                                    }
                                                </div>
                                                : null}

                                        {
                                            item?.container_type ?
                                                <div className="vehicleDetailsTextWrap">
                                                    <div className="fw-5">
                                                        {"Container Type"}
                                                    </div>
                                                    {
                                                        item?.container_type ?
                                                            <CustomText.OpenSansRegular>
                                                                {item?.container_type}
                                                            </CustomText.OpenSansRegular>
                                                            :
                                                            <CustomText.OpenSansRegular
                                                                textStyle={{
                                                                    marginLeft: 2
                                                                }}>N/A</CustomText.OpenSansRegular>
                                                    }
                                                </div>
                                                : null}

                                        {/* {
                                            loadDetail?.no_of_packages ?
                                                <div style={{ paddingLeft: 5, display: "flex", alignItems: 'center' }}>
                                                    <div className="fw-5">
                                                        {"Load Packages:"}
                                                    </div>
                                                    {
                                                        loadDetail?.no_of_packages ?
                                                            <CustomText.OpenSansRegular
                                                                textStyle={{
                                                                    marginLeft: 2
                                                                }}>
                                                                {loadDetail?.no_of_packages}
                                                            </CustomText.OpenSansRegular>
                                                            :
                                                            <CustomText.OpenSansRegular
                                                                textStyle={{
                                                                    marginLeft: 2
                                                                }}>N/A</CustomText.OpenSansRegular>
                                                    }
                                                </div>
                                                : null} */}
                                        <div>

                                            <div>
                                                {item?.container_ref_one ?
                                                    <div className="vehicleDetailsTextWrap">
                                                        <div className="fw-5">
                                                            {/* {`Container Number 1 `} */}
                                                            {item?.container_ref_one && item?.container_ref_two ? "Container Number 1" :
                                                                "Container Number"
                                                            }
                                                        </div>
                                                        <CustomText.OpenSansRegular>
                                                            {`${item?.container_ref_one}`}
                                                        </CustomText.OpenSansRegular>
                                                    </div>
                                                    : null}
                                                {
                                                    item?.weight_one ?
                                                        <div className="vehicleDetailsTextWrap">
                                                            <div className="fw-5">
                                                                {item?.container_ref_one && item?.container_ref_two ? "Weight 1" :
                                                                    "Weight"
                                                                }
                                                            </div>
                                                            <CustomText.OpenSansRegular>
                                                                {`${numeral(item?.weight_one).format("0,0.00")} Kg`}
                                                            </CustomText.OpenSansRegular>
                                                        </div> : null
                                                }

                                                {
                                                    item?.container_ref_two ?
                                                        <div className="vehicleDetailsTextWrap">
                                                            <div className="fw-5">
                                                                {`Container Number 2 `}
                                                            </div>
                                                            <CustomText.OpenSansRegular>
                                                                {`${item?.container_ref_two}`}
                                                            </CustomText.OpenSansRegular>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    item?.no_of_package ?
                                                        <div className="vehicleDetailsTextWrap">
                                                            <div className="fw-5">
                                                                {`No. of Packages `}
                                                            </div>
                                                            <CustomText.OpenSansRegular>
                                                                {`${item?.no_of_package}`}
                                                            </CustomText.OpenSansRegular>
                                                        </div>
                                                        : null
                                                }

                                                {
                                                    item?.weight_two ?
                                                        <div className="vehicleDetailsTextWrap">
                                                            <div className="fw-5">
                                                                {`Weight 2 `}
                                                            </div>
                                                            <CustomText.OpenSansRegular>
                                                                {`${numeral(item?.weight_two).format("0,0.00")} Kg`}
                                                            </CustomText.OpenSansRegular>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                        <div className="vehicleDetailsTextWrap">
                                            <div className="fw-5">
                                                Location
                                            </div>
                                            {
                                                item?.driver_last_location?.length && item?.driver_last_location_time?.length ?
                                                    <CustomText.OpenSansRegular
                                                        textStyle={{
                                                            marginLeft: 2
                                                        }}>
                                                        {` ${item?.driver_last_location ? item?.driver_last_location : "N/A"} @ ${item?.driver_last_location_time ? moment(item?.driver_last_location_time).format("DD MMM, YYYY hh:mm A") : "N/A"}`}
                                                    </CustomText.OpenSansRegular>

                                                    :
                                                    <CustomText.OpenSansRegular
                                                        textStyle={{
                                                            marginLeft: 2
                                                        }}>N/A</CustomText.OpenSansRegular>
                                            }
                                        </div>
                                        <div className="vehicleDetailsTextWrap">
                                            <div className="fw-5">
                                                Status
                                            </div>
                                            <CustomText.OpenSansRegular>
                                                {` ${item?.last_checklist ? `${item?.last_checklist} @ ${moment(item?.last_checklist_at).format('DD MMM, YYYY hh:mm A')}` : "N/A"}`}
                                            </CustomText.OpenSansRegular>
                                        </div>
                                    </div>
                                )
                            })}
                            {
                                fromViewer ?
                                    <OutLineButton
                                        title="Share via whatsapp"
                                        // disabled={!loadDetail?.show_recipient_mark_complete_btn || loadDetail?.status_name == "Completed"}
                                        onClick={shareOnWhatsapp}
                                        buttonStyle={{
                                            marginTop: 10
                                        }}
                                    /> : null
                            }
                        </div> : null}
                    <div style={{ height: 16 }}></div>
                </div>
            </Accordion>
        </div>
    );
};