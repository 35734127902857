import React from "react";
import { IForwardLoadPageProps } from "./types";

import Page from "./Page";

const View: React.FC<IForwardLoadPageProps> = ({
  room,
  role,
  id,
  canPlay,
  data = {},
}: IForwardLoadPageProps) => {
  return <Page role={role} room={room} id={id} canPlay={canPlay} data={data} />;
};

export default View;
