import React, { useEffect, useState } from "react";
import { Colors, Images } from "@temp/assets";
import { PrimaryButton, CustomText, TitleLayout } from "@components";
import Checkbox from "@mui/material//Checkbox";
import { ITermsPageProps } from "./types";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { AuthAction } from "@temp/store/actions";
import { showMessage, setItem } from "@temp/utils/helper";
import { useHistory, useLocation } from "react-router-dom";
import rootReducer from "../../../store/reducers";
import { Mixpanel } from "@temp/utils/mixpanel";
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<ITermsPageProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [is_toc, setIs_toc] = useState(false);
  const location = useLocation();

  const { state } = location;

  

  const Terms = useSelector(
    (state: RootState) => state.Auth?.metaData?.terms_conditions
  );
  const signupData = useSelector((state: RootState) => state.Auth.signupData);

  



  // const callback = (data) => {
  //   if (data?.user?.is_toc_accepted === 1) {
  //     setItem("toc_accepted", true);
  //     history.replace("/setup-password");
  //   }
  // };

  const callback = (data: any) => {
    dispatch(
      AuthAction.SetSignupData({
        fullName: "",
        aliasName: "",
        frontImage: "",
        backImage: "",
        cnic_front: "",
        cnic_back: "",
      })
    );
    Mixpanel.track("registration complete", {
      name: "registration flow complete",
      des: 'registration flow complete'
    })
    history.push("/success");
  };

  const acceptTerms = () => {
    if (is_toc) {
      // return
      dispatch(
        AuthAction.SignUp({
          ...signupData,
          phoneNumber: state?.phoneNumber ? state.phoneNumber : "",
          whatsapp:state?.whatsapp?state.whatsapp:'',
          mobileNetwork: state?.mobileNetwork,
          front_image:state?.front_image,
          back_image:state?.back_image,
          cb: callback,
        })
      );
      return
      dispatch(
        
        AuthAction.updateTerm({ is_toc_accepted: is_toc, cb: callback })
      );
    } else {
      showMessage.current({
        message: "Please accept terms and conditions.",
        status: "error",
      });
    }
  };

  useEffect(() => {
    const element = document.querySelector(".terms-text-container");
    element.innerHTML = Terms;
  }, [Terms]);

  return (
    <TitleLayout titleOnly={true} titleOnlyText="Terms & Conditions">
    <div className="main-centered-container">
      <div className="terms-wrapper">
        {/* <div className="header-container">
          <div className="image-check">
            <img alt="" src={Images.correct} />
          </div>
          <CustomText.OpenSansBold
            textStyle={{
              fontSize: 35,
              color: Colors.Black,
            }}
          >
            Terms & Conditions
          </CustomText.OpenSansBold>
        </div> */}
        <div className="terms-text-container">
          <CustomText.OpenSansRegular
            textStyle={{
              fontSize: "0.75rem",
              color: Colors.Grayish,
            }}
          ></CustomText.OpenSansRegular>
        </div>
        <div className="termsBottomWrap">
          <div>
            <CustomText.OpenSansRegular
              textStyle={{
                fontSize: "0.75rem",
                color: Colors.Grayish,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Checkbox
                checked={is_toc}
                onChange={(e) => {
                  setIs_toc(e.target.checked);
                }}
                style={{ color: "#7ac251" }}
              />
              I agree with terms and conditions
            </CustomText.OpenSansRegular>
          </div>
          <div className="accept-btn">
            <PrimaryButton
            disabled={!is_toc}
              buttonStyle={{ height: 44, width: 327 }}
              onClick={() => {
                acceptTerms();
              }}
              title="Accept"
            />
          </div>
        </div>
      </div>
    </div>
    </TitleLayout>
  );
};

export default Page;
