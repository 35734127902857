import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { StyleGuideProps } from "./types";
import rootReducer from "../../store/reducers";
import { Button, Grid } from "@mui/material/";
import {
  CustomizedSelects,
  CustomizedSwitches,
  CustomText,
  DocumentsTable,
  OutLineButton,
  PrimaryButton,
  RadioButton,
  SimpleCheckBox,
  SimpleInput,
  VehicleIncDec,
} from "@temp/components";
import { ReactMediaRecorder } from "react-media-recorder";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Colors, Images } from "@temp/assets";
import { isBrowser } from "react-device-detect";
import ReactAudioPlayer from "react-audio-player";
import { showMessage } from "@temp/utils/helper";
import BottomTabs from "@temp/components/BottomTabs";
import { AddCircleOutlineOutlined, CheckBox, ExitToAppRounded } from "@mui/icons-material";
import { DeclineButton, RecipientStatus, VehicleCounter } from "../LoadDetail/components";
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<StyleGuideProps> = (props) => {
  const QuantityBtn = () => {
    const [qtn, setQtn] = useState(1);

    return (
      <VehicleIncDec
        className="labelWithIncrementField"
        withInputBox={true}
        text="Qtn"
        value={qtn}
        onTouchStart={(value) => {
          if (value == "inc") {
            setQtn(qtn + 1);
          } else {
            setQtn(qtn - 1);
          }
        }}
      />
    );
  };

  const InputFieldPKR = () => {
    const [value, setValue] = useState("");
    return (
      <div className="inputField">
        <div className="label">Deposit</div>
        <div className="field">
          <SimpleInput
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
            placeholder={"Enter Load Name"}
            isPKR={true}
          />
        </div>
      </div>
    );
  };

  const InputField = () => {
    const [value, setValue] = useState("");
    return (
      <div className="inputField">
        <div className="label">Reference No.</div>
        <div className="field">
          <SimpleInput
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
            placeholder={"Enter reference no."}
          />
        </div>
      </div>
    );
  };

  const Select = () => {
    return (
      <CustomizedSelects
        values={[]}
        modalState={true}
        placeholder={"Select Vehicle Type"}
      ></CustomizedSelects>
    );
  };

  const SearchInput = () => {
    const [value, setValue] = useState("");
    return (
      <div className={`searchContainer ${value ? "filled" : ""}`}>
        <input
          className="searchInput"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          type="text"
          autoComplete="off"
          name="search"
          style={{ width: "100%" }}
          value={value}
          placeholder="Search detail address"
        />
        <span className={`icons-search ${value ? '' : 'active'}`}></span>
        <span
          className={`icons-cross ${value ? 'active' : ''}`}
          onClick={() => setValue("")}
        ></span>
      </div>
    );
  };

  const InfoAlert = () => {
    return (
      <div className="error-container info">
        <div>
          <InfoIcon className="error-icon" />
        </div>
        <div className="error-text">
          Total of Advance, On Delivery and Credit must be equal to 100%.
        </div>
      </div>
    );
  };

  const Recorder = () => {
    const [second, setSecond] = useState("00");
    const [minute, setMinute] = useState("00");
    const [isActive, setIsActive] = useState(false);
    const [counter, setCounter] = useState(0);
    const [record_url, set_record_url] = useState(null);
    const sendVoiceNote = (url) => {
      set_record_url(
        "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3"
      );
      
      setIsActive(true);
    };

    const stopTimer = () => {
      setIsActive(false);
      setCounter(0);
      setSecond("00");
      setMinute("00");
    };

    return (
      <ReactMediaRecorder
        audio={true}
        video={false}
        render={({
          status,
          startRecording,
          stopRecording,
          mediaBlobUrl,
          error,
        }) => {
          if (second >= 59) {
            stopRecording();
            stopTimer();
          }
          return (
            <div className="recorderWrapper">
              {isActive ? (
                <div className="recording">
                  <div className="recordTime">
                    <label>
                      {minute}
                      <span>:</span>
                      {second}
                    </label>{" "}
                    | Start Recording...
                  </div>

                  <div
                    className="icon"
                    onClick={() => {
                      // if (!isBrowser) {
                      //   if (sendEventToAndroid("voiceRecordStop")) {
                      //     window.Android.voiceRecordStop(String("stop"));
                      //   } else if (sendEventToiOS("voiceRecordStop")) {
                      //     window.webkit.messageHandlers.voiceRecordStop.postMessage(
                      //       JSON.stringify({
                      //         start: "stop",
                      //       })
                      //     );
                      //   } else {
                      //   }
                      // }
                      stopRecording();
                      stopTimer();
                    }}
                  >
                    <MicIcon />
                  </div>
                </div>
              ) : (
                <div>
                  {record_url ? (
                    <div className="recorder">
                      <ReactAudioPlayer
                        src={record_url}
                        autoPlay={false}
                        controls
                        loop={false}
                      />
                      <span
                        className="icons-cross"
                        onClick={() => {
                          set_record_url(null);
                          showMessage.current({
                            message: "Voice note deleted successfully.",
                            status: "success",
                          });
                        }}
                      ></span>
                    </div>
                  ) : (
                    <div className="startRecord">
                      <label>Start Recording...</label>
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setTimeout(() => {
                            if (!isBrowser) {
                              if (sendEventToAndroid("voiceRecordStart")) {
                                window.Android.voiceRecordStart(
                                  String("start")
                                );
                              } else if (sendEventToiOS("voiceRecordStart")) {
                                window.webkit.messageHandlers.voiceRecordStart.postMessage(
                                  JSON.stringify({
                                    start: "start",
                                  })
                                );
                              } else {
                              }
                            } else {
                              startRecording();
                            }
                          }, 2000);

                          setIsActive(!isActive);
                        }}
                      >
                        <MicIcon />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        }}
        onStop={(url, blob) => {
          sendVoiceNote(blob);
        }}
      />
    );
  };

  const Checkbox = () => {
    const [value, setValue] = useState(false);
    return (
      <SimpleCheckBox
        title="Vehicles Tracking"
        checked={value}
        handleChange={() => setValue(!value)}
      />
    );
  };

  const Document = () => {
    return (
      <div className="uploadDocumentFileBoxWrap">
        <DocumentsTable
          values={{}}
          // title={PostLoad?.documents?.bill_of_ladings[0]?.file_name}
          screen={"document"}
          datas={{
            key: "bill_of_ladings",
            icon: null,
            title: "Bill of Lading",
          }}
        />
      </div>
    );
  };

  const RecipientCard = () => {
    return (
      <div className="recipentGridBox">
        <div className="selectRecipentCheckBox">
          <SimpleCheckBox />
        </div>
        <div className="insideRecipentContentBoxWrap">
          <img
            className="userImg"
            src="https://res.cloudinary.com/hbkbnyy03/image/upload/default_avtar_fwfmu0.png"
          ></img>
          <div className="w-100">
            <p className="companyTitle">Meri Gari (Pvt.) Ltd</p>
            <p className="name">Kashif</p>
            <p className="phone">+92 345 0000000</p>
          </div>
        </div>
      </div>
    );
  };

  const RecipientCards = () => {
    return (
      <div className="recipentGridBoxWrap">
        <RecipientCard />
        <RecipientCard />
      </div>
    );
  };

  const Tabs = () => {
    const [value, setValue] = useState("radio1");
    return (
      <div className="tabWrapper">
        <RadioButton
          id="radio1"
          name="test"
          label="Offer"
          value={value}
          defaultChecked
          onClick={() => setValue("radio1")}
        />
        <RadioButton
          id="radio2"
          name="test"
          label="Bid"
          value={value}
          onClick={() => setValue("radio2")}
        />
        <div className="radioTab_slider"></div>
      </div>
    );
  };

  const Nav = () => {
    return (
      <div className="navWrapper">
        <BottomTabs activeTab="home" />
      </div>
    );
  };

  const LoadCard = (data: any) => {
    const { title, subtitle, img, onClick } = data;
    return (
      <div className="loadsCard" onClick={onClick}>
        <div className="detail">
          <div>
            <p className="title">{title}</p>
            <p className="subtitle">{subtitle}</p>
          </div>
          <img className="arrow" src={Images.RightArrowGreen} />
        </div>
        <div className="loadsImg">
          <img className="" src={img}></img>
        </div>
      </div>
    );
  };

  const ButtonType = () => {
    return (
      <>
        <PrimaryButton
          buttonStyle={{ marginBottom: "1rem" }}
          title="CONTINUE"
        />
        <PrimaryButton
          disabled={true}
          buttonStyle={{ marginBottom: "1rem" }}
          title="CONTINUE"
        />
        <OutLineButton
          classProp='tinyBtn'
          dataColor="green"
          title="Export PDF"
          icon={
            <span className="icons-export"></span>
          }
          onClick={() => {
            
          }}
        />
        <OutLineButton
          classProp='tinyBtn'
          dataColor="blue"
          title="Add new"
          icon={
            <AddCircleOutlineOutlined />
          }
          onClick={() => {
            
          }}
        />
        <OutLineButton
          classProp='tinyBtn'
          dataColor="blue"
          title="Edit"
          icon={
            <span className="icons-edit"></span>
          }
          onClick={() => {
            
          }}
        />
      </>
    );
  };

  const Recipient = () => {
  
    return (
      <div className="bottom-border-2px">
        <div className="detail-category-recepient-container">
          <div className="check-wrapper">
            <CheckBox
              style={{ color: "#7ac251" }}
            />
          </div>
  
          {false && (
              <div className="check-wrapper">
                <CheckBox
                  style={{ color: "#7ac251" }}
                />
              </div>
            )}
          <div className="load-detail-user">
            <img
              alt="profile"
              className="load-detail-user"
              src={Images.myProfileImage}
            />
          </div>
          {true ? (
            <div className="recepient-detail-container">
              <div className="width-100">
                {/* top row  */}
                <div className="flexRow100" style={{ justifyContent: 'space-between' }}>
                  <div className="title">
                      Unregistered User
                  </div>
  
                  {/* top status when only accept */}
                  {true ? (
                    <div style={{ width: '40%', justifyContent: true ? 'space-between' : 'flex-end' }} className="flexRow">
                      {/* for bid  */}
                      {true &&
                        true ? (
                        <>
                          {
                            true ?
                              <div>
                                <div className="nogotiate_button" >
                                  <span className="icons-edit"></span>
                                  Edit
                                </div>
                              </div>
                              : null
                          }
                          <div className="chip">5 Vehicles</div>
                        </>
                      ) : null}
                    </div>
                  ) : false ? (
                    // for offer
                    <div style={{ width: "50%" }} className="flexRow">
                      <div className="chip">5 Vehicles</div>
                      <div
                        className="recipient-status"
                        style={{
                          border: '1px solid',
                          backgroundColor: Colors.White,
                          borderColor: Colors.Red}}
                      >
                        <CustomText.OpenSansRegular
                          textStyle={{
                            color: Colors.Red}}>
                             Declined 
                        </CustomText.OpenSansRegular>
                      </div>
                    </div>
                  ) : null}
                </div>
  
                <div className="company" style={{ marginTop: 5 }}>
                    Cargo Inc
                </div>
                {/* bottom row  */}
                <div className="recepient-status-container">
                  {true ? (
                    <div className="flexRow">
                      <div className="chip">5 Vehicles</div>
                      <div
                        className="recipient-status"
                        style={{
                          border: '1px solid',
                          backgroundColor: Colors.White,
                          borderColor: Colors.Red}}
                      >
                        <CustomText.OpenSansRegular
                          textStyle={{
                            color: Colors.Red}}>
                             Declined 
                        </CustomText.OpenSansRegular>
                      </div>
                    </div>
                  ) : false ? (
                    <div
                      className="recipient-status"
                      style={{
                        border: '1px solid',
                        backgroundColor: Colors.White,
                        borderColor: Colors.Red}}
                    >
                      <CustomText.OpenSansRegular
                        textStyle={{
                          color: Colors.Red}}>
                           Declined 
                      </CustomText.OpenSansRegular>
                    </div>
                  ) : null}
                  {true && <div className="chip">5 Vehicles</div>}
  
                  {/* for bid case  */}
              
                  {/* for offer case */}
                  {true &&
                    true && (
                      <div className="flexRow">
                        {
                          true ?
                            <CustomText.OpenSansSemiBold>
                              10
                            </CustomText.OpenSansSemiBold> :
                            <QuantityBtn />
                        }
                        {true && (
                          <CustomText.OpenSansRegular>
                            1000 PKR
                          </CustomText.OpenSansRegular>
                        )}
                      </div>
                    )}
                  {true && (
                      <div
                        className="recipient-status"
                        style={{
                          border: '1px solid',
                          backgroundColor: Colors.White,
                          borderColor: Colors.Red}}
                      >
                        <CustomText.OpenSansRegular
                          textStyle={{
                            color: Colors.Red}}>
                            Declined 
                        </CustomText.OpenSansRegular>
                      </div>
                    )}
                  {/* decline button show when status is accepted  */}
                  {true ? (
                    <DeclineButton
                      type={"outline"}
                      declineByPoster={() => {}}
                      recipient={{id: 1}}
                    />
                  ) : null}
                  {/* shows when status is declined by poster */}
                  {true ? (
                      <DeclineButton
                        type={"outline"}
                        declineByPoster={() => {}}
                        recipient={{id: 1}}
                      />
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className="recepient-detail-container">
              <div className="width-100">
                {/* top row  */}
                <div style={{
                  justifyContent: 'space-between'
                }} className="flexRow100">
                  <div>
                    <CustomText.OpenSansRegular>
                      Bilal
                    </CustomText.OpenSansRegular>
                  </div>
                  {/* for bid case  */}
                  {
                    true ?
                      <div>
                       <div className="nogotiate_button" >
                          <span className="icons-edit"></span>
                          Edit
                        </div>
                      </div>
                      : null
                  }
  
                </div>
  
                  <div style={{ marginTop: 5 }}>
                    <CustomText.OpenSansSemiBold>
                      Cargo Inc
                    </CustomText.OpenSansSemiBold>
                  </div>
  
                {/* bottom row  */}
                <div className="recepient-status-container">
                  {true ? (
                    <div className="flexRow">
                      <CustomText.OpenSansRegular>
                        {"1000" + " PKR"}/Vehicle
                      </CustomText.OpenSansRegular>
                    </div>
                  ) : true ? (
                    <div
                      className="recipient-status"
                      style={{
                        border: '1px solid',
                        backgroundColor: Colors.Yellowish,
                        borderColor: Colors.Orange}}
                    >
                      <CustomText.OpenSansRegular
                        textStyle={{
                          color: Colors.Orange}}>
                           Awarded 
                      </CustomText.OpenSansRegular>
                    </div>
                  ) : (
                    <div
                      className="recipient-status"
                      style={{
                        border: '1px solid',
                        backgroundColor: Colors.Yellowish,
                        borderColor: Colors.Orange}}
                    >
                      <CustomText.OpenSansRegular
                        textStyle={{
                          color: Colors.Orange}}>
                           Awarded 
                      </CustomText.OpenSansRegular>
                    </div>
                  )}
                  {true && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <CustomText.OpenSansRegular>
                        {"1000" + " PKR"}/Vehicle
                      </CustomText.OpenSansRegular>
                    </div>
                  )}
  
                  {/* decline button show when status is accepted  */}
                  {true ? (
                    <DeclineButton
                      type={"outline"}
                      declineByPoster={() => {}}
                      recipient={{id: 1}}
                    />
                  ) : null}
                  {/* shows when status is declined by poster */}
                  {true && (
                      <DeclineButton
                        type={"outline"}
                        declineByPoster={() => {}}
                        recipient={{id: 1}}
                      />
                    )}
                </div>
              </div>
            </div>
          )}
  
        </div>
        {true && (
          <div className="flexRow">
            <QuantityBtn />
          </div>
        )}
      </div>
    );
  }

  const NegoButtons = () => {
    return (
      <Grid container className="mb-3">
        <Grid item xs={4} style={{ paddingBottom: 0, marginBottom: 0 }}>
            <div className="nogotiate_button"
             style={{
                backgroundColor: Colors.Primary,
                width: 'fit-content',
                padding: '0.25rem 0.75rem',
                borderRadius: 24,
            }}>
                <CustomText.OpenSansRegular textStyle={{
                    color: "white",
                }}>Accept</CustomText.OpenSansRegular>
            </div>
        </Grid>
        <Grid item xs={3} style={{ paddingBottom: 0, marginBottom: 0 }}>
            <div className="nogotiate_button"
              style={{
                backgroundColor: Colors.Red,
                                    width: 'fit-content',
                                    padding: '0.25rem 0.75rem',
                                    borderRadius: 24,
            }}>
                <CustomText.OpenSansRegular textStyle={{
                    color: "white",
                }}>Reject</CustomText.OpenSansRegular>
            </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className="customGrid">
      <Grid container spacing={2}>
        <Grid item md={6}>
          <NegoButtons />

          <Recipient />
          
          <div className="heading">Load Card</div>
          <LoadCard
            title="MG Express Quote"
            subtitle="Get Instant Quote from MG Approved"
            img={Images.Express}
            onClick={() => {}}
          />

          <div className="heading">Navigation</div>
          <Nav />

          <div className="heading">Tabs</div>
          <Tabs />

          <div className="heading">Select Options</div>
          <Checkbox />

          <div className="heading">Upload</div>
          <Document />

          <div className="heading">Recipient card</div>
          <RecipientCards />

          <div className="heading">Button type</div>
          <ButtonType />
        </Grid>
        <Grid item md={6}>
          <div className="heading">Qtn</div>
          <QuantityBtn />

          <div className="heading">Price Field</div>
          <InputFieldPKR />

          <div className="heading">Input Field</div>
          <InputField />

          <div className="heading">Dropdown</div>
          <Select />

          <div className="heading">Search</div>
          <SearchInput />

          <div className="heading">Info</div>
          <InfoAlert />

          <div className="heading">Recording</div>
          <Recorder />

          <div className="heading">Toggle</div>
          <CustomizedSwitches />
        </Grid>
      </Grid>
    </div>
  );
};

export default Page;
