import React, { useEffect, useState } from "react";
import { ISelectVehicleFCLPageProps } from "./types";
import "./style.scss";
import {
  LocationTimeline,
  PrimaryButton,
  SimpleCheckBox,
  TitleLayout,
} from "@components";
import { ProfileAction } from "@temp/store/actions";
import {
  Accordion,
  AccordionSummary,
} from "@mui/material/";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ADDITIONAL_DETAILS } from "@temp/app/routes";
import { useHistory } from "react-router";
import { LOAD_TYPE } from "@temp/constants";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";

export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<ISelectVehicleFCLPageProps> = (props) => {
  const history = useHistory();
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const [locationState, setLocationState] = useState([]);
  const load_type = postLoadMeta.load_types.find((val: any) => val.id === PostLoad.load_type);
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (PostLoad?.location?.length > 0) {
      let new_loc = []
      PostLoad?.location?.forEach((element, index) => {
        let new_pickup = {
          ...element,
          pickup: {
            ...element.pickup,
            attribute: element?.pickup?.attribute ? element?.pickup?.attribute : {}
          },
        }
        let new_vehicle = []
        PostLoad?.vehicleScreen?.addedVehiclesTemp?.forEach((item2, index2) => {
          if (item2?.values?.detention_free_time) {
            new_vehicle.push({ ...item2, loc_index: index, vehicle_index: index2 })
          } else {
            let new_item = { ...item2, values: { ...item2?.values, detention_free_time: postLoadMeta?.load_configurations?.free_time_setting || 24 } }
            new_vehicle.push({ ...new_item, loc_index: index, vehicle_index: index2 })
          }
        })
        new_loc.push({ ...new_pickup, items: new_vehicle, is_data: false, new_cat: [] })
      });
      setLocationState(new_loc);
    }
  }, [])


  const handleNewVehicle = (locationIndex, vehicleObject) => {
    const locations = locationState?.length > 0 ? locationState : [];
    if (locations?.length > 0) {
      locations[locationIndex].items = locations[locationIndex]?.items
        ? [...locations[locationIndex].items, vehicleObject]
        : [vehicleObject];
    }
    setLocationState([...locations]);
  };

  const handleDelete = (locationIndex, vehiclesArray) => {
    let loc = locationState;
    loc[locationIndex].items = vehiclesArray;
    setLocationState([...loc]);
  };

  const handleDisabilityContinue = () => {
    setDisable(
      locationState.every((loc: any) => loc?.items?.length > 0) ? false : true
    );
  };

  const handleSubmit = () => {

    dispatch(
      ProfileAction.postLoadData({
        location: [...locationState],
        contractDetails: {
          ...PostLoad?.contractDetails
        },
      })
    );
    history.push(ADDITIONAL_DETAILS, { locations: locationState });
  };

  useEffect(() => {
    handleDisabilityContinue();
  }, [locationState]);

  return (
    <TitleLayout
      pageNumber={5}
      contract={true}
      progress={30}
      title={`Select Vehicles`}
    >
      <div className="formFeildWrapper">
        <div
          className="contractVehicleAccordianWrapper"
          container-type={load_type.title !== LOAD_TYPE.fcl ? "lcl" : "fcl"}
        >
          {!!locationState.length &&
            locationState?.map((item, index) => {
              
              return (
                <FadeInComponent>
                  <Accordion
                    className="contractVehicleAccordian"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"

                    >
                      <div className="fw-6">
                        {`Route ${index + 1} : ${item?.pickup?.title} - ${item?.dropoff?.title}`}
                      </div>
                    </AccordionSummary>
                    <div style={{ paddingLeft: 15, paddingRight: 15 }} className="contractVehicleAccordianDetail">
                      <LocationTimeline
                        location={[item?.pickup, item?.dropoff]}
                        handleModal={(value: boolean) => { }}
                        showTitle={false}
                      ></LocationTimeline>
                      <div className="selectVehicleMainWrap">
                        <div className="fw-5 mb-3">
                          Categories
                        </div>
                        {
                          PostLoad?.vehicleScreen?.addedVehiclesTemp?.map((vehicle, vehicle_index) => {
                            let title
                            if (vehicle?.containerType) {
                              title = vehicle?.vehicle_quantity ?
                                vehicle?.vehicle_quantity + 'X' +
                                ` ${vehicle?.selectVehicleTypeObj?.title} ` +
                                ` ${vehicle?.max_vehicle_weight}Ton`
                                :
                                ` ${vehicle?.selectVehicleTypeObj?.title} ` +
                                ` ${vehicle?.max_vehicle_weight}Ton`
                            } else {
                              title = vehicle?.vehicle_quantity ?
                                vehicle?.vehicle_quantity + 'X' +
                                ` ${vehicle?.selectVehicleTypeObj?.title} ` +
                                ` ${vehicle?.max_vehicle_weight}Ton`
                                :
                                ` ${vehicle?.selectVehicleTypeObj?.title} ` +
                                ` ${vehicle?.max_vehicle_weight}Ton`
                            }
                            let is_added_vehicle = item?.items?.find(vehi => vehi?.vehicle_index == vehicle_index)
                            return (
                              <SimpleCheckBox
                                checked={is_added_vehicle ? true : false}
                                handleChange={() => {
                                  if (is_added_vehicle) {
                                    let tempAddedVehicles = item?.items?.filter((val) => val?.vehicle_index !== vehicle_index);
                                    handleDelete(index, [...tempAddedVehicles]);
                                  } else {
                                    handleNewVehicle(index, { ...vehicle, vehicle_index, loc_index: index })
                                  }
                                }}
                                title={title}
                              />
                            )
                          })
                        }
                        <div>

                        </div>
                      </div>
                    </div>
                  </Accordion>
                </FadeInComponent>
              )
            }
            )}
        </div>
        <div>
        </div>
      </div>
      <PrimaryButton
        rightIcon
        title="CONTINUE"
        type="submit"
        onClick={() => handleSubmit()}
        disabled={disable}
        buttonStyle={{
          marginBottom: 15,
          marginTop: 20
        }}
      />
    </TitleLayout>
  );
};
export default Page;