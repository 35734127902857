import React, { FC, useState } from "react";
import styled from "styled-components";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Grid,
    Button

} from "@mui/material/";
import { useSelector } from "react-redux";
var numeral = require("numeral");
import { ExitToAppRounded, ExpandMore } from "@mui/icons-material";
import { ConfirmLoadProps } from "./types";
import { LOAD_TYPE } from "@temp/constants";
import { CustomText, OutLineButton } from "@temp/components";
import { Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { HistoryVehicle } from "./HistoryVehicles";

export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 20px;
  border-radius: 10px;
`;

export const UnitCategoryView: FC<ConfirmLoadProps> = ({
    value,
    index,
    added_units,
    fromHistory,
    fromConfirm,
    otherData,
    editable,
    is_recipient
}: ConfirmLoadProps) => {

    const history = useHistory();
    const onEdit = () => {
        history.push("/selectVehicle");
    };
    const FormRow = ({ label, value, adornement, sep, urdutitle, is_recipient, fromHistory }) => {
        return (
            <div className="formGrid">
                <div className="grayLabel">
                    <span className="titleName">{label}</span>
                    {
                        fromHistory && is_recipient &&
                        <>
                            <span className="seperator">
                                {sep}
                            </span>
                            <span className="urduLabel lhEdit">
                                {urdutitle}
                            </span>
                        </>
                    }
                </div>
                <div className="fw-5">
                    {value} {adornement}
                </div>
            </div>
        );
    };


    const postLoadMeta = useSelector(
        (state: any) => state?.Profile?.postLoadMeta
    );
    const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
    const load_type = postLoadMeta.load_types.find(
        (val: any) => val.id === PostLoad.load_type
    );
    const [isExpanded, setIsExpanded] = useState(true);
    const handleChange = (check) => {
        setIsExpanded(check);
    };

    const profile = useSelector((state: any) => state.Profile.profile);
    const orignal_recipient_id = PostLoad?.is_allocated ? PostLoad?.allocates[0]?.allocated_by : profile?.id



    return (
        <Accordion
            className="confirm-load-accordion"
            style={{ backgroundColor: "#FAFAFA" }}
            expanded={isExpanded}
            onChange={() => handleChange(!isExpanded)}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography
                    style={{
                        paddingTop: "5px",
                        fontWeight: "bold",
                        fontSize: "0.75rem",
                        marginRight: -22,
                    }}
                >
                    {added_units.length > 1 ? `Item Category ${index + 1}` : "Item Category"}
                    {
                        is_recipient && fromHistory &&
                            added_units.length > 1 ? `/ آئٹم کی کیٹاگری ${index + 1}` : " / آئٹم کی کیٹاگری "}

                </Typography>
                {editable ? (
                    <div className="editBtnWrapper">
                        <OutLineButton
                            classProp='tinyBtn'
                            dataColor="blue"
                            title="Edit"
                            icon={
                                <span className="icons-edit"></span>
                            }
                            onClick={onEdit}
                        />
                    </div>
                ) : null}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1} style={{ overflowY: "scroll" }}>

                    <FormRow
                        label={"Gross Weight in KG"}
                        value={numeral(Number(value?.gross_weight * 1000)).format("0,0.00")}
                        adornement={"KG"}
                        sep="/"
                        urdutitle="مجموعی وزن (کلو)"
                        is_recipient={is_recipient}
                        fromHistory={fromHistory}
                    />
                    <FormRow
                        label={"Gross Weight in Ton"}
                        value={numeral(value?.gross_weight).format("0,0.00")}
                        adornement={"TON"}
                        sep="/"
                        urdutitle="مجموعی وزن (ٹن)"
                        is_recipient={is_recipient}
                        fromHistory={fromHistory}
                    />

                    {
                        value?.cargo_volume ?
                            <FormRow
                                label={"Cargo Volume"}
                                value={numeral(value?.cargo_volume).format("0,0.00")}
                                adornement={"CBM"}
                                urdutitle={"کارگو والیوم"}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}

                            /> : null
                    }
                    <FormRow
                        label={
                            "Total Weight"
                        }
                        value={numeral(value?.total_freight_ton).format("0,0.00")}
                        adornement={"F.Ton"}
                        urdutitle={"کل وزن"}
                        sep="/"
                        is_recipient={is_recipient}
                        fromHistory={fromHistory}

                    />

                    <FormRow
                        label={"Dimensions"}
                        value={`${value?.dimensions} ${value?.unit_dimension_value}`}
                        urdutitle={" پیمائش"}
                        sep="/"
                        is_recipient={is_recipient}
                        fromHistory={fromHistory}
                    />

                    <FormRow
                        label={"Unit Quantity"}
                        value={value?.unit_quantity}
                        urdutitle={" اکائی مقدار"}
                        sep="/"
                        is_recipient={is_recipient}
                        fromHistory={fromHistory}
                    />

                    {
                        value?.unit_description ?
                            <FormRow
                                label={"Item Description"}
                                value={value?.unit_description}
                                urdutitle={"آئٹم کی تفصیل"}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}
                            /> : null
                    }

                    {
                        value?.no_of_packages ?
                            <FormRow
                                label={"No. of Packages"}
                                value={value?.no_of_packages}
                                urdutitle={"پیکجوں کی تعداد"}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}
                            /> : null
                    }

                    {
                        value?.max_bid ?
                            <FormRow
                                label={"Max Bid (Without GST)"}
                                value={numeral(value?.max_bid).format("0,0.00")}
                                adornement={"PKR"}
                                urdutitle={"زیادہ سے زیادہ بولی  (GST کے بغیر)"}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}

                            /> : null
                    }

                    <FormRow
                        label={"Free Time Required"}
                        value={value?.detention_free_time + " hrs"}
                        urdutitle={"فری وقت درکار"}
                        sep="/"
                        is_recipient={is_recipient}
                        fromHistory={fromHistory}

                    />
                    {
                        value?.price ?
                            <FormRow
                                label={"Offer Price / Item (Without GST)"}
                                value={numeral(value?.price).format("0,0.00")}
                                adornement={"PKR"}
                                urdutitle={"پیشکش کی قیمت /  فی آئٹم  (GST کے بغیر)"}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}

                            /> : null
                    }

                    {value?.detention_rate > 0 ?
                        <FormRow
                            label={"Detention Rate (Without GST)"}
                            value={numeral(value?.detention_rate).format("0,0.00")}
                            adornement={"PKR"}
                            urdutitle={"ڈیٹنشن ریٹ (GST کے بغیر)"}
                            sep="/"
                            is_recipient={is_recipient}
                            fromHistory={fromHistory}

                        />
                        : null
                    }

                    {
                        value?.loading_price > 0 ?
                            <FormRow
                                label={"Loading Price / Item (Without GST)"}
                                value={numeral(value?.loading_price).format('0,0.00')}
                                adornement={"PKR"}
                                urdutitle={` لوڈنگ ریٹ /  فی آئٹم (GST کے بغیر)`}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}


                            /> : null
                    }
                    {
                        value?.unloading_price > 0 ?
                            <FormRow
                                label={"UnLoading Price / Item (Without GST)"}
                                value={numeral(value?.unloading_price).format("0,0.00")}
                                adornement={"PKR"}
                                urdutitle={` ان لوڈنگ ریٹ /  فی آئٹم  (GST کے بغیر)`}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}

                            /> : null

                    }

                    {
                        value?.total_amount > 0 ?
                            <FormRow
                                label={"Total Offer Price (Without GST)"}
                                value={numeral(value?.total_amount).format("0,0.00")}
                                adornement={"PKR"}
                                urdutitle={" کل پیشکش کی قیمت  (GST کے بغیر)"}
                                sep="/"
                                is_recipient={is_recipient}
                                fromHistory={fromHistory}

                            /> : null

                    }

                    {/* prices added for recipient */}

                    {
                        <>
                            {PostLoad?.transaction_type_id == 2 && is_recipient &&
                                <>

                                    {value?.new_recipients?.filter((itm) => itm?.recipient_id == profile.id)
                                        .map((reci) => {
                                            return (
                                                <>
                                                    {
                                                        reci?.bid?.bid_price_per_vehicle &&
                                                        <>

                                                            <CustomText.OpenSansBold
                                                                textStyle={{
                                                                    marginLeft: 8,
                                                                }}
                                                            >
                                                                {
                                                                    reci?.status == 4 ? "Awarded Price" : "Responded Price"
                                                                }
                                                            </CustomText.OpenSansBold>

                                                            <FormRow
                                                                label={`Price / Item  (Without GST)`}
                                                                value={`PKR ${numeral(reci?.bid?.bid_price_per_vehicle).format("0,0")}`}
                                                                urdutitle={`ریٹ / فی آئٹم (GST کے بغیر)`}
                                                                fromHistory={fromHistory}
                                                                sep="/"
                                                                is_recipient={is_recipient}

                                                            />
                                                        </>
                                                    }

                                                </>
                                            );
                                        })}

                                </>
                            }
                        </>

                    }

                    <Grid item xs={12}>
                        {fromConfirm && fromHistory && otherData?.history_recipients?.length ?
                            <>
                                {
                                    value?.new_recipients?.filter((not_award) => not_award?.status !== 4).length ?
                                        <>

                                            <div className="dividing-bordered"></div>
                                            <CustomText.OpenSansBold textStyle={{
                                                marginLeft: 4,
                                                marginBottom: 10
                                            }}>
                                                Recipients
                                            </CustomText.OpenSansBold>

                                            {value?.new_recipients?.filter((statu) => statu?.status !== 4)
                                                ?.map((item) => {
                                                    let history_vechiles = value?.vehicles_drivers_refs?.filter((cat) => cat?.recipient_id == item?.recipient?.id)?.sort((a, b) => a?.vehicle_no - b?.vehicle_no);

                                                    return (
                                                        <>
                                                            <div style={{ marginBottom: 0, marginTop: 10 }} className="detail-category-recepient-container" >
                                                                <div>
                                                                    <img
                                                                        alt="profile"
                                                                        style={{ border: "1px solid #0D5296" }}
                                                                        className="load-detail-user"
                                                                        src={item?.recipient?.picture || Images.myProfileImage}
                                                                    />
                                                                </div>

                                                                <div className="load-history-profile-name">
                                                                    <CustomText.OpenSansSemiBold>
                                                                        {item?.recipient?.company?.length ?
                                                                            item?.recipient?.company[0]?.name : item?.recipient?.name
                                                                            || "UnRegistered User"}
                                                                    </CustomText.OpenSansSemiBold>

                                                                    <CustomText.OpenSansRegular>
                                                                        {
                                                                            item?.recipient?.company?.length ?
                                                                                item?.recipient?.name : item?.recipient?.company_name
                                                                        }
                                                                    </CustomText.OpenSansRegular>

                                                                    {
                                                                        item?.bid?.bid_price_per_vehicle ?
                                                                            <div className="chip">
                                                                                {"PKR " + numeral(item?.bid?.bid_price_per_vehicle).format("0,0")} / Item
                                                                            </div>
                                                                            : null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <HistoryVehicle history_vechiles={history_vechiles} />
                                                        </>
                                                    )
                                                })}
                                        </>
                                        : null
                                }

                                {
                                    value?.new_recipients?.filter((award) => award?.status == 4).length ?
                                        <>
                                            <div className="dividing-bordered"></div>
                                            <CustomText.OpenSansBold textStyle={{
                                                marginLeft: 4,
                                                marginBottom: 10
                                            }}>
                                                Awarded Recipients
                                            </CustomText.OpenSansBold>
                                            {
                                                value?.new_recipients?.filter((stat) =>
                                                    stat?.status == 4)?.map((reci) => {
                                                        let history_vechiles = value?.vehicles_drivers_refs?.filter((cat) => cat?.recipient_id == reci?.recipient?.id)?.sort((a, b) => a?.vehicle_no - b?.vehicle_no);
                                                        return (
                                                            <>
                                                                <div style={{ marginBottom: 0, marginTop: 10 }} className="detail-category-recepient-container" >
                                                                    <div>
                                                                        <img
                                                                            alt="profile"
                                                                            style={{ border: "1px solid #0D5296" }}
                                                                            className="load-detail-user"
                                                                            src={reci?.recipient?.picture || Images.myProfileImage}
                                                                        />
                                                                    </div>

                                                                    <div className="load-history-profile-name">
                                                                        <CustomText.OpenSansSemiBold>
                                                                            {reci?.recipient?.company?.length ?
                                                                                reci?.recipient?.company[0]?.name : reci?.recipient?.name
                                                                                || "UnRegistered User"}
                                                                        </CustomText.OpenSansSemiBold>

                                                                        <CustomText.OpenSansRegular>
                                                                            {
                                                                                reci?.recipient?.company?.length ?
                                                                                    reci?.recipient?.name : reci?.recipient?.company_name
                                                                            }
                                                                        </CustomText.OpenSansRegular>

                                                                        {
                                                                            reci?.bid?.bid_price_per_vehicle ?
                                                                                <div className="chip">
                                                                                    {"PKR " + numeral(reci?.bid?.bid_price_per_vehicle).format("0,0")} / Item
                                                                                </div>
                                                                                : null
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <HistoryVehicle history_vechiles={history_vechiles} />
                                                            </>
                                                        )
                                                    })
                                            }
                                        </>
                                        : null
                                }
                            </> : null}

                        {
                            fromConfirm && fromHistory && !otherData?.history_recipients?.length ?
                                <div>
                                    <HistoryVehicle history_vechiles={value?.vehicles_drivers_refs?.filter((vehicle) => vehicle?.recipient_id == orignal_recipient_id)?.sort((a, b) => a?.vehicle_no - b?.vehicle_no)} />
                                </div> : null

                        }
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
