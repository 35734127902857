export default class Action {
  // Constants
  static LOGIN_WITH_OTP = "LOGIN_WITH_OTP";
  static LOGIN_WITH_OTP_SUCCESS = "LOGIN_WITH_OTP_SUCCESS";
  static LOGIN_WITH_OTP_FAIL = "LOGIN_WITH_OTP_FAIL";

  static LOGIN_WITH_PASSWORD = "LOGIN_WITH_PASSWORD";
  static LOGIN_WITH_PASSWORD_SUCCESS = "LOGIN_WITH_PASSWORD_SUCCESS";
  static LOGIN_WITH_PASSWORD_FAIL = "LOGIN_WITH_PASSWORD_FAIL";

  static SIGNUP = "SIGNUP";
  static SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
  static SIGNUP_FAIL = "SIGNUP_FAIL";
  static VERIFY_CODE = "VERIFY_CODE";

  static VERIFY_CODE_SUCCESS = "VERIFY_CODE_SUCCESS";
  static VERIFY_CODE_FAIL = "VERIFY_CODE_FAIL";
  static SET_SIGNUP_DATA = "SET_SIGNUP_DATA";

  static RESET_PASSWORD = "RESET_PASSWORD";
  static RESET_PASSWORD_SUCCESS = "SET_RESET_PASSWORD_SUCCESS";
  static RESET_PASSWORD_FAIL = "SET_RESET_PASSWORD_SUCCESS";

  static UPDATE_TERMS_AND_CONDITION = "UPDATE_TERMS_AND_CONDITION";
  static UPDATE_TERMS_AND_CONDITION_SUCCESS =
    "UPDATE_TERMS_AND_CONDITION_SUCCESS";
  static UPDATE_TERMS_AND_CONDITION_FAIL = "UPDATE_TERMS_AND_CONDITION_FAIL";

  static LOG_OUT = "LOG_OUT";
  static LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
  static LOG_OUT_FAIL = "LOG_OUT_FAIL";

  static SET_ATTEMPT = "SET_ATTEMPT";

  static GET_META_DATA = "GET_META_DATA";
  static GET_META_DATA_SUCCESS = "GET_META_DATA_SUCCESS";
  static GET_META_DATA_FAIL = "GET_META_DATA_FAIL";

  static SETUP_PASSWORD = "SETUP_PASSWORD";
  static SETUP_PASSWORD_SUCCESS = "SETUP_PASSWORD_SUCCESS";
  static SETUP_PASSWORD_FAIL = "SETUP_PASSWORD_FAIL";

  static RESET_STORE = "RESET_STORE";

  static FORGOT_PASSWORD = "FORGOT_PASSWORD";
  static FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
  static FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

  static LOCATION_LOADER_SHOW = "LOCATION_LOADER_SHOW";

  static SET_NATIVE_TOKEN = "SET_NATIVE_TOKEN";

  static GET_LOADS_COUNT = "GET_LOADS_COUNT";
  static GET_LOADS_COUNT_SUCCESS = "GET_LOADS_COUNT_SUCCESS";
  static GET_LOADS_COUNT_FAIL = "GET_LOADS_COUNT_FAIL";


  static FIRST_LOGIN = "FIRST_LOGIN";
  static FIRST_LOGIN_SUCCESS = "FIRST_LOGIN_SUCCESS";
  static FIRST_LOGIN_FAIL = "FIRST_LOGIN_FAIL";

  static DEL_ACCOUNT = "DEL_ACCOUNT";
  static DEL_ACCOUNT_SUCCESS = "DEL_ACCOUNT_SUCCESS";
  static DEL_ACCOUNT_FAIL = "DEL_ACCOUNT_FAIL";


  static TOGGLE_LOADER = "TOGGLE_LOADER";

  static LOGOUT_FROM_ALL_DEVICES = "LOGOUT_FROM_ALL_DEVICES"

  static SET_NOTI_URL = "SET_NOTI_URL"

  
  // Actions

  static setNotiUrl(payload:any){
    return{
      type: Action.SET_NOTI_URL,
      payload
    }
  }

  static logoutFromAllDevices(payload:any){
    return{
      type: Action.LOGOUT_FROM_ALL_DEVICES,
      payload
    }
  }
  static toggleLoader(payload:any){
    return{
      type: Action.TOGGLE_LOADER,
      payload
    }
  }

  static setNativeToken(payload: any) {
    return {
      type: Action.SET_NATIVE_TOKEN,
      payload,
    };
  }

  static LogInWihtOtp(payload: any) {
    return {
      type: Action.LOGIN_WITH_OTP,
      payload,
    };
  }

  static LogInWithPassword(payload: any) {
    return {
      type: Action.LOGIN_WITH_PASSWORD,
      payload,
    };
  }

  static SignUp(payload: any) {
    return {
      type: Action.SIGNUP,
      payload,
    };
  }

  static VerifyCode(payload: any) {
    return {
      type: Action.VERIFY_CODE,
      payload,
    };
  }

  static SetSignupData(payload: any) {
    return {
      type: Action.SET_SIGNUP_DATA,
      payload,
    };
  }

  static SetAttempt(payload: any) {
    return {
      type: Action.SET_ATTEMPT,
      payload,
    };
  }

  static updateTerm(payload: any) {
    return {
      type: Action.UPDATE_TERMS_AND_CONDITION,
      payload,
    };
  }

  static logOut(payload: any) {
    return {
      type: Action.LOG_OUT,
      payload,
    };
  }

  static ResetPassword(payload: any) {
    return {
      type: Action.RESET_PASSWORD,
      payload,
    };
  }

  static getMetaData() {
    return {
      type: Action.GET_META_DATA,
    };
  }

  static setupPassword(payload) {
    return {
      type: Action.SETUP_PASSWORD,
      payload,
    };
  }

  static resetStore() {
    return {
      type: Action.RESET_STORE,
    };
  }

  static forgotPassword(payload) {
    return {
      type: Action.FORGOT_PASSWORD,
      payload,
    };
  }

  static locationLoader(payload) {
    return {
      type: Action.LOCATION_LOADER_SHOW,
      payload,
    };
  }

  static getLoadsCount(payload){
    return{
      type: Action.GET_LOADS_COUNT,
      payload
    }
  }
  static firstLogIn(payload: any) {
    return {
      type: Action.FIRST_LOGIN,
      payload,
    };
  }

  static DelAccount(payload) {
    return {
      type: Action.DEL_ACCOUNT,
      payload
    };
  }

}
