import React, { useState, useEffect } from "react";
import { IReportPageProps } from "./types";
import "./style.scss";
import { CustomModal, CustomText, MaterialUIPickers, OutLineButton } from "@components";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import { useHistory } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { Grid, MenuItem, Select } from "@mui/material/";
import { Chart } from "chart.js";
// import { Doughnut, Bar } from "react-chartjs-3";
import { Colors, Images } from "@temp/assets";
import { CloudDownloadOutlined } from "@mui/icons-material";
import { ProfileAction } from "@temp/store/actions";
import moment from "moment";
import { triggerMixpanelEvent } from "@temp/utils/helper";

Chart.pluginService.register({
  beforeDraw: function (chart) {
    if (chart.config.options.elements.center) {
      // Get ctx from string
      var ctx = chart.chart.ctx;

      // Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || 'Arial';
      var txt = centerConfig.text;
      var color = centerConfig.color || '#000';
      var maxFontSize = 18;
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
      // Start with a base font of 30px
      ctx.font = "30px " + fontStyle;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      var minFontSize = centerConfig.minFontSize;
      var lineHeight = centerConfig.lineHeight || 25;
      var wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 20;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY);
        return;
      }

      var words = txt.split(' ');
      var line = '';
      var lines = [];

      // Break words up into multiple lines if necessary
      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' ';
        var metrics = ctx.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = words[n] + ' ';
        } else {
          line = testLine;
        }
      }

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight;

      for (var n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY);
        centerY += lineHeight;
      }
      //Draw text in center
      ctx.fillText(line, centerX, centerY);
    }
  }
});

const Page: React.FC<IReportPageProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const dashboardReportsData = useSelector((state: RootState) => state.Profile?.reportsData);
  const dataSpot = useSelector((state: RootState) => state.Profile?.dataSpot);
  const dataContract = useSelector((state: RootState) => state.Profile?.dataContract);
  const bar_chart_data = useSelector((state: RootState) => state.Profile?.bar_chart_data);
  const activeCategory = useSelector((state: RootState) => state.Profile?.activeCategoryReports);
  const activeDateFilter = useSelector((state: RootState) => state.Profile?.activeDateFilter);
  const fromResponseDate = useSelector((state: RootState) => state.Profile?.fromResponseDate);
  const responseDate = useSelector((state: RootState) => state.Profile?.responseDate);
  const [showModal, setShowModal] = useState(false)
  
  useEffect(() => {
    let date_from = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD')
    let date_to = moment().format("YYYY-MM-DD")
    if (activeDateFilter == "weekly") {
      date_from = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    } else if (activeDateFilter) {
      date_from = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      date_to = moment().format("YYYY-MM-DD")
    } else {
      date_from = fromResponseDate
      date_to = responseDate
    }
    dispatch(ProfileAction.dashboardReports({
      loads_by: activeCategory,
      date_range_from: date_from,
      date_range_to: date_to,
      date_type: activeDateFilter,
    }))
  }, []);

  const handleSubmit = () => {
    dispatch(ProfileAction.dashboardReports({
      loads_by: activeCategory,
      date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
      date_range_to: moment(responseDate).format("YYYY-MM-DD"),
      date_type: activeDateFilter,
    }))
  }

  const handleClickCategory = (type: string) => {
    dispatch(ProfileAction.setReportsFilter({
      activeCategoryReports: type,
      activeDateFilter,
      fromResponseDate,
      responseDate,
    }))
    if (activeDateFilter == 'weekly') {
      let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardReports({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateFilter,
      }))
    } else if (activeDateFilter == 'monthly') {
      let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardReports({
        loads_by: type,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: activeDateFilter,
      }))
    } else if (activeDateFilter == 'date') {
      dispatch(ProfileAction.dashboardReports({
        loads_by: type,
        date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
        date_range_to: moment(responseDate).format("YYYY-MM-DD"),
        date_type: activeDateFilter,
      }))
    }
  };

  const handleDateFilter = (type) => {
    dispatch(ProfileAction.setReportsFilter({
      activeCategoryReports: activeCategory,
      activeDateFilter: type,
      fromResponseDate,
      responseDate,
    }))
    if (type == 'weekly') {
      let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardReports({
        loads_by: activeCategory,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
      }))
    } else if (type == 'monthly') {
      let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
      let end_date = moment().format('YYYY-MM-DD');
      dispatch(ProfileAction.dashboardReports({
        loads_by: activeCategory,
        date_range_from: start_date,
        date_range_to: end_date,
        date_type: type,
      }))
    }
  }

  const option = {
    maintainAspectRatio: true,
    cutoutPercentage: 70,
    legend: {
      display: false,
      position: 'bottom',
      align: 'start',
      labels: {
        color: 'rgb(255, 255, 255)',
        boxWidth: 15,
        boxHeight: 15,
        useBorderRadius: true,
        borderRadius: 15
      }
    },
    responsive: true,
  };


  const optionSpot = {
    ...option,
    elements: {
      display: true,
      center: {
        display: true,
        text: `Spot ${dashboardReportsData?.spot?.total}`,
        font: {
          size: 18,
        },
      },
    },
  };

  const optionContract = {
    ...option,
    elements: {
      display: true,
      center: {
        display: true,
        text: `Contract ${dashboardReportsData?.contract?.total}`,
        font: {
          size: 18,
        },
      },
    },
  };


  const optionsBar = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        gridLines: {
          offsetGridLines: true
        }
      }]
    },
    type: 'bar',
  }

  const RenderDot = ({ bgColor }) => {
    return (
      <div style={{ height: 12, width: 12, borderRadius: 6, backgroundColor: bgColor, marginTop: 2 }}></div>
    )
  }

  return (
    <div style={{
      height: 'calc(100vh - 15px)',
      overflow: 'auto'
    }} className="root-center-container">
      <div className="loads-top-wrappers">
        <div className="topDropDownWrap">
          <div
            className="drop-down"
            style={{ justifyContent: "space-between" }}
          >
            <button onClick={() => {
              history.goBack()
            }} className="header-buttons">
              <img
                src={Images.BackArrow}
                alt="Back"
              />
            </button>
            <p className="title">Reports</p>
            <div>
              <CloudDownloadOutlined style={{ cursor: "pointer" }}
                onClick={() => {
                  if (activeDateFilter == 'weekly') {
                    let start_date = moment(Date.now() - 6 * 24 * 3600 * 1000).format('YYYY-MM-DD');
                    let end_date = moment().format('YYYY-MM-DD');
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: start_date,
                      date_range_to: end_date,
                      date_type: activeDateFilter,
                    }))
                  } else if (activeDateFilter == 'monthly') {
                    let start_date = moment(Date.now() - 29 * 24 * 3600 * 1000).format('YYYY-MM-DD')
                    let end_date = moment().format('YYYY-MM-DD');
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: start_date,
                      date_range_to: end_date,
                      date_type: activeDateFilter,
                    }))
                  } else if (activeDateFilter == 'date') {
                    dispatch(ProfileAction.downloadCsvReports({
                      loads_by: activeCategory,
                      date_range_from: moment(fromResponseDate).format("YYYY-MM-DD"),
                      date_range_to: moment(responseDate).format("YYYY-MM-DD"),
                      date_type: activeDateFilter,
                    }))
                  }
                }} />
            </div>
          </div>
        </div>

        {/* Top Tabs posted recieved  */}
        <div className="tabContainerMainWrap reportLoadListing">
          {
            <FadeInComponent>
              <div className={`tab-btn radioTabs twoTab`}>
                <div
                  onClick={() => {
                    handleClickCategory("posted");
                  }}
                  className={`two-category-button ${activeCategory === "posted" ? "active" : ""
                    }`}
                >
                  Posted
                </div>
                <div
                  onClick={() => {
                    handleClickCategory("received");
                  }}
                  className={`two-category-button ${activeCategory === "received" ? "active" : ""
                    }`}
                >
                  Received
                </div>
                <div className="radioTab_slider"></div>
              </div>
            </FadeInComponent>
          }
        </div>

        {/* Top Tabs date filters  */}
        <div className="tabContainerMainWrap reportLoadListing" style={{ display: 'none' }}>
          {
            <FadeInComponent className='pr-2'>
              <div className={`tab-btn radioTabs twoTab`}>
                <div
                  onClick={() => {
                    handleDateFilter("weekly");
                  }}
                  className={`two-category-button ${activeDateFilter === "weekly" ? "active" : ""
                    }`}
                >
                  Weekly
                </div>
                <div
                  onClick={() => {
                    handleDateFilter("monthly");
                  }}
                  className={`two-category-button ${activeDateFilter === "monthly" ? "active" : ""
                    }`}
                >
                  Monthly
                </div>
                <div className="radioTab_slider"></div>
              </div>
            </FadeInComponent>
          }
        </div>

        <div className="tabContainerMainWrap reportLoadListing">
          <Select
            id="outlined-select-date-native"
            select
            defaultValue={activeDateFilter}
            name=""
            onChange={(e) => handleDateFilter(e.target.value)}
          >
            <MenuItem value={'weekly'}>
              Weekly
            </MenuItem>
            <MenuItem value={'monthly'}>
              Monthly
            </MenuItem>
            <MenuItem value={'date'}>
              Date Filter
            </MenuItem>
          </Select>
          {
            activeDateFilter == "date" ?
              <FadeInComponent className="dateLabel">
                <div className="resports-date-filter-wraper">
                  <div onClick={() => {
                    setShowModal(true)
                  }} style={{ border: `1px solid ${Colors.NavyBlue}` }} className="reports-date-filter">
                    <span className="icons-calender calenderIcon"></span>
                    <p style={{ color: Colors.NavyBlue, marginTop: 4 }}>DATE FILTER</p>
                  </div>
                  <div>
                    {
                      fromResponseDate && responseDate ?
                        <p className="label">{`${moment(fromResponseDate).format('ll')} - ${moment(responseDate).format('ll')}`}</p> : null
                    }
                  </div>
                </div>
              </FadeInComponent>
              : null}

        </div>
      </div>

      <div
        className="load-listing-container remCustomPadonMob reportLoadListing"
        style={{ paddingTop: activeDateFilter == "date" ? 225 : 150 }}
      >

        <Grid container className="graphWrapper">
          <Grid item xs={6} style={{ padding: "0rem", paddingRight: "0.3rem" }}>
            {/* {
              dataSpot ?
                <Doughnut
                  width={"100%"}
                  data={dataSpot}
                  options={optionSpot}
                  // ref={onRefChangeSpot}
                  key={dataSpot?.key}
                /> : null
            } */}
            <div className="legend" id="legendSpot">
              {/* {legendSpot} */}
              <div className="spot-contract-reports-legends">
                <RenderDot bgColor={Colors.Primary} />
                <CustomText.OpenSansRegular textStyle={{ marginLeft: 10 }}>Containerized - {dashboardReportsData?.spot?.containerized}</CustomText.OpenSansRegular>
              </div>
              <div className="spot-contract-reports-legends">
                <RenderDot bgColor={Colors.NavyBlue} />
                <CustomText.OpenSansRegular textStyle={{ marginLeft: 10 }}>Non-Containerized - {dashboardReportsData?.spot?.non_containerized}</CustomText.OpenSansRegular>
              </div>
              <div className="spot-contract-reports-legends">
                <RenderDot bgColor={Colors.Yellow} />
                <CustomText.OpenSansRegular textStyle={{ marginLeft: 10 }}>Bid - {dashboardReportsData?.spot?.bid_counts}</CustomText.OpenSansRegular>
              </div>
              <div className="spot-contract-reports-legends">
                <RenderDot bgColor={Colors.LightWhite} />
                <CustomText.OpenSansRegular textStyle={{ marginLeft: 10 }}>Offer - {dashboardReportsData?.spot?.offer_counts}</CustomText.OpenSansRegular>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} style={{ padding: "0rem", paddingLeft: "0.3rem" }}>
            {/* {
              dataContract ?
                <Doughnut
                  width={"100%"}
                  data={dataContract}
                  options={optionContract}
                  // ref={onRefChangeContract}
                  key={dataContract?.key}
                /> : null
            } */}
            <div className="legend" id="legendContract">
              {/* {legendContract} */}
              <div className="spot-contract-reports-legends">
                <RenderDot bgColor={Colors.Primary} />
                <CustomText.OpenSansRegular textStyle={{ marginLeft: 10 }}>Containerized - {dashboardReportsData?.contract?.containerized}</CustomText.OpenSansRegular>
              </div>
              <div className="spot-contract-reports-legends">
                <RenderDot bgColor={Colors.NavyBlue} />
                <CustomText.OpenSansRegular textStyle={{ marginLeft: 10 }}>Non-Containerized - {dashboardReportsData?.contract?.non_containerized}</CustomText.OpenSansRegular>
              </div>
              <div className="spot-contract-reports-legends">
                <RenderDot bgColor={Colors.Yellow} />
                <CustomText.OpenSansRegular textStyle={{ marginLeft: 10 }}>Bid - {dashboardReportsData?.contract?.bid_counts}</CustomText.OpenSansRegular>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: '1rem' }}>
            <div style={{ width: "100%", overflow: 'scroll' }} id="bardiv">
              <div className="bar-chart-3" style={{ width: "900px", }}>
                {
                  bar_chart_data ?
                    <Bar
                      data={bar_chart_data}
                      options={optionsBar}
                    /> : null
                }
              </div>
            </div>
          </Grid>

          {/* spot active loads  */}
          <Grid item xs={6} className="customGraphPadd">
            <OutLineButton
              classProp='smallFontBtn'
              title={`Active Loads Spot`}
              counter={`${dashboardReportsData?.spot?.total_active || 0}`}
              onClick={() => {
                dispatch(ProfileAction.updateActiveFilters({
                  loadType: activeCategory == "posted" ? "Loads Posted" : "Loads Received",
                }))
                setTimeout(() => {
                  if(activeCategory == 'posted'){
                    triggerMixpanelEvent("Active Loads Posted Listing", "Click on active load listing")
                  }else{
                    triggerMixpanelEvent("Active Loads Received Listing", "Click on active load listing")
                  }
                  history.push('/active-loads')
                }, 300)
              }}
            />
          </Grid>

          {/* spot awarded loads  */}
          <Grid item xs={6} className="customGraphPadd">
            <OutLineButton
              classProp='smallFontBtn'
              title={`Awarded Loads Spot`}
              counter={`${dashboardReportsData?.spot?.total_awarded || 0}`}
              onClick={() => {
                if (activeCategory == "posted") {
                  triggerMixpanelEvent("Spot Loads Posted Awarded Listing", "Click on Spot Loads Posted Awarded listing")
                  history.push('/dashboard/spot-posted/awarded', { showTabs: true, reports: true })
                } else {
                  triggerMixpanelEvent("Spot Loads Received Awarded Listing", "Click on Spot Loads Received Awarded listing")
                  history.push('/dashboard/spot-recieved/awarded', { showTabs: true, reports: true })
                }
              }}
            />
          </Grid>

          {/* spot cancelled loads */}
          <Grid item xs={6} className="customGraphPadd">
            <OutLineButton
              classProp='smallFontBtn'
              title={`Cancelled Loads Spot`}
              counter={`${dashboardReportsData?.spot?.total_cancelled || 0}`}
            />
          </Grid>

          {/* spot completed loads */}
          <Grid item xs={6} className="customGraphPadd">
            <OutLineButton
              classProp='smallFontBtn'
              title={`Completed Loads Spot`}
              counter={`${dashboardReportsData?.spot?.total_completed || 0}`}
            />
          </Grid>

          {/* spot inactive loads */}
          <Grid item xs={6} className="customGraphPadd">
            <OutLineButton
              classProp='smallFontBtn'
              title={`Inactive Loads Spot`}
              counter={`${dashboardReportsData?.spot?.total_inactive || 0}`}
            />
          </Grid>

          {/* contract awarded */}
          <Grid item xs={6} className="customGraphPadd">
            <OutLineButton
              classProp='smallFontBtn'
              title={`Awarded Contract`}
              counter={`${dashboardReportsData?.contract?.total_awarded || 0}`}
              onClick={() => {
                if (activeCategory == 'posted') {
                  triggerMixpanelEvent("Contract Inquiry Posted Awarded Listing", "Click on Contract Inquiry Posted listing")
                  history.push('/dashboard/contract-posted/awarded', { showPostedRecievedTabs: true, showTabs: true, reports: true })
                } else {
                  triggerMixpanelEvent("Contract Inquiry Received Awarded Listing", "Click on Contract Inquiry Received listing")
                  history.push('/dashboard/contract-recieved/awarded', { showPostedRecievedTabs: true, showTabs: true, reports: true })
                }
              }}
            />
          </Grid>

          {/* contract cancelled */}
          <Grid item xs={6} className="customGraphPadd">
            <OutLineButton
              classProp='smallFontBtn'
              title={`Cancelled Contract`}
              counter={`${dashboardReportsData?.contract?.total_cancelled || 0}`}
            />
          </Grid>

          {/* contract inactive */}
          <Grid item xs={6} className="customGraphPadd">
            <OutLineButton
              classProp='smallFontBtn'
              title={`Inactive Contract`}
              counter={`${dashboardReportsData?.contract?.total_inactive || 0}`}
            />
          </Grid>
        </Grid>
      </div>

      <CustomModal showModal={showModal} toggleModal={setShowModal}>
        <div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 0
          }}>
            <span
              style={{
                right: 15,
                top: 15
              }}
              className="icons-cross directionRight"
              onClick={() => setShowModal(false)}
            ></span>
          </div>
          <div style={{ marginBottom: 10, marginTop: 15 }} className="inputField">
            <div className="label">From</div>
            <div style={{ marginTop: -15 }}>
              <MaterialUIPickers
                date={fromResponseDate}
                setDate={(e) => {
                  dispatch(ProfileAction.setReportsFilter({
                    activeCategoryReports: activeCategory,
                    activeDateFilter: activeDateFilter,
                    fromResponseDate: e,
                    responseDate,
                  }))

                }}
                minDate={"1900/01/01"}
                maxDate={new Date()}
                from={true}
              />
            </div>
          </div>
          <div style={{ marginBottom: 10, pointerEvents: fromResponseDate ? "auto" : 'none' }} className="inputField">
            <div className="label">To</div>
            <div style={{ marginTop: -15 }}>
              <MaterialUIPickers
                date={responseDate}
                setDate={(e) => {
                  dispatch(ProfileAction.setReportsFilter({
                    activeCategoryReports: activeCategory,
                    activeDateFilter: activeDateFilter,
                    fromResponseDate: fromResponseDate,
                    responseDate: e,
                  }))
                }}
                minDate={fromResponseDate}
                maxDate={new Date()}
                from={true}
              />
            </div>
          </div>

          <OutLineButton
            classProp='tinyBtn'
            title="Apply"
            onClick={() => {
              handleSubmit()
              setShowModal(false)
            }}
            buttonStyle={{
              height: 36,
              width: '100%'
            }}
          />
        </div>
      </CustomModal>
    </div>
  );

};
export default Page;
