import React, { useState, useEffect } from "react";
import { DetailProps } from "./type";
import { useDispatch, useSelector } from "react-redux";
import { DrawerAction } from "@temp/store/actions";
import { useHistory, useParams } from "react-router-dom";
import { BackHeader, CustomText } from "@components";
import styled from "styled-components";
import rootReducer from "../../store/reducers";
import moment from "moment";
import "./style.scss";
export type RootState = ReturnType<typeof rootReducer>;

const MainContainer = styled.div`
  height: 100vh;
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const DetailConatiner = styled.div`
  height: 100vh;
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 300px;
`;

const ImageDiv = styled.div`
  height: 217px;
  width: 375px;
`;
export const timeHumanize = (time, from) => {
  let current_time = moment().format("x");
  let to_local = moment(time).format("YYYY-MM-DD HH:mm:ss");
  let that_time = moment(to_local).format("x");
  let diff = current_time - that_time;
  var final_time = Math.floor(diff / 1000 / 60);
  if (final_time < 1) {
    return "just now";
  } else if (final_time >= 1 && final_time < 60) {
    if (final_time < 2) {
      if (from == "homeCard") {
        return `${final_time} min ago`;
      } else {
        return `${final_time}m ago`;
      }
    } else {
      if (from == "homeCard") {
        return `${final_time} min ago`;
      } else {
        return `${final_time}m ago`;
      }
    }
  } else if (final_time >= 60 && final_time < 1440) {
    let new_hour = Math.floor(final_time / 60);
    if (new_hour <= 1) {
      if (from == "homeCard") {
        return `${new_hour} hour ago`;
      } else {
        return `${new_hour}h ago`;
      }
    } else {
      if (from == "homeCard") {
        return `${new_hour} hours ago`;
      } else {
        return `${new_hour}h ago`;
      }
    }
  } else {
    return moment(time).calendar(null, {
      lastDay: `[yesterday ${moment(time).format("hh:mm A")}]`,
      lastWeek: `[${moment(time).format("DD, MMM YYYY  hh:mm A")}]`,
      sameElse: `[${moment(time).format("DD, MMM YYYY  hh:mm A")}]`,
    });
  }
};

const Page: React.FC<DetailProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const metaData = useSelector((state: RootState) => state.Auth.metaData);
  const promotionDetail = useSelector(
    (state: RootState) => state.Drawer.promotionDetail
  );
  

  useEffect(() => {
    dispatch(DrawerAction.getPromotionDetail({ id: params.id }));
  }, []);

  useEffect(() => {
    const element = document.querySelector(".desc");
    element.innerHTML = promotionDetail?.description;
  }, [promotionDetail]);

  return (
    <>
      <BackHeader
        className="promotionDetailHeader"
        onClick={() => {
          history.goBack();
        }}
        title="Promotion Detail"
      />
      <div className="promotionDetailBody">
        <div className="">
          <img
            className="promotionImg"
            src={promotionDetail?.image}
            alt={promotionDetail?.title}
          />
        </div>
        <div className="topWrapper">
          <div className="titleWrapper">
            <CustomText.OpenSansBold textStyle={{
              fontSize:"0.875rem"
            }}>{promotionDetail?.title}</CustomText.OpenSansBold>
            <div className="date"> {timeHumanize(promotionDetail?.created_at, "homeCard")}</div>
          </div>
          <div style={{
            fontSize:"0.813rem"
          }} className="desc promotionDesc"></div>
        </div>
      </div>
    </>
  );
};

export default Page;
