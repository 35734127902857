import { all, takeLatest } from "redux-saga/effects";
import {
  AuthAction,
  ProfileAction,
  DrawerAction,
  LoadAction,
} from "../actions";
import { AuthSaga } from "./AuthSaga";
import { ProfileSaga } from "./ProfileSaga";
import { DrawerSaga } from "./DrawerSaga";
import { LoadSaga } from "./LoadSaga";

export function* rootSaga() {
  // auth
  yield all([
    // sagas will go here
    takeLatest(AuthAction.LOGIN_WITH_OTP, AuthSaga.LogInWihtOtp),
    takeLatest(AuthAction.LOGIN_WITH_PASSWORD, AuthSaga.LogInWithPassword),
    takeLatest(AuthAction.SIGNUP, AuthSaga.SignUp),
    takeLatest(AuthAction.VERIFY_CODE, AuthSaga.VerifyCode),
    takeLatest(AuthAction.UPDATE_TERMS_AND_CONDITION, AuthSaga.updateTerm),
    takeLatest(AuthAction.LOG_OUT, AuthSaga.logOut),
    takeLatest(ProfileAction.GET_PROFILE, ProfileSaga.GetProfile),
    takeLatest(ProfileAction.UPLOAD_FILE[0], ProfileSaga.uploadFile),
    takeLatest(ProfileAction.JOB_LOAD_META[0], ProfileSaga.jobLoadMeta),
    takeLatest(ProfileAction.UPDATE_FCM_TOKEN[0], ProfileSaga.handleFCmToken),
    takeLatest(ProfileAction.UPDATE_PROFILE, ProfileSaga.UpdateProfile),
    takeLatest(AuthAction.GET_META_DATA, AuthSaga.getMetaData),
    takeLatest(AuthAction.SETUP_PASSWORD, AuthSaga.setupPassword),
    takeLatest(
      ProfileAction.CHANGE_PHONE_NUMBER_REQUEST,
      ProfileSaga.changePhoneNumberRequest
    ),
    takeLatest(
      ProfileAction.CHANGE_PHONE_NUMBER,
      ProfileSaga.changePhoneNumber
    ),
    takeLatest(AuthAction.FORGOT_PASSWORD, AuthSaga.forgotPassword),
    takeLatest(ProfileAction.CHANGE_PASSWORD, ProfileSaga.changePassword),

    // drawer
    takeLatest(DrawerAction.GET_NETWORK, DrawerSaga.GetNetwork),
    takeLatest(DrawerAction.GET_ACCOUNT_BALANCE, DrawerSaga.GetAccountBalance),
    takeLatest(DrawerAction.GET_SAVED_LOCATIONS, DrawerSaga.GetSavedLocations),
    takeLatest(
      DrawerAction.GET_VEHICLE_SELECTOR,
      DrawerSaga.GetVehicleSelector
    ),
    takeLatest(DrawerAction.GET_NOTIFICATIONS, DrawerSaga.GetNotifications),
    takeLatest(DrawerAction.GET_LOAD_DRAFT, DrawerSaga.GetLoadDraft),
    takeLatest(DrawerAction.ADD_NETWORK, DrawerSaga.AddNetwork),
    takeLatest(DrawerAction.SEARCH_NETWORK, DrawerSaga.SearchNetwork),
    takeLatest(DrawerAction.ADD_LOCATION[0], DrawerSaga.addLocation),
    takeLatest(DrawerAction.ADD_OTP_NETWORK, DrawerSaga.AddOtpNetwork),
    takeLatest(DrawerAction.DELETE_LOCATION[0], DrawerSaga.DeleteLocation),
    takeLatest(
      DrawerAction.ADD_VEHICLE_SELECTOR,
      DrawerSaga.AddVehicleSelector
    ),
    takeLatest(
      DrawerAction.EDIT_VEHICLE_SELECTOR,
      DrawerSaga.EditVehicleSelector
    ),
    takeLatest(
      DrawerAction.DELETE_VEHICLE_SELECTOR,
      DrawerSaga.DeleteVehicleSelector
    ),
    // loads
    takeLatest(LoadAction.GET_LOADS, LoadSaga.getLoads),
    takeLatest(LoadAction.AWARD_JOB, LoadSaga.awardJob),
    takeLatest(LoadAction.CANCEL_LOAD, LoadSaga.cancelLoad),
    takeLatest(LoadAction.RECIPIENT_DECLINED, LoadSaga.recipientDeclined),
    takeLatest(LoadAction.POSTER_DECLINED, LoadSaga.posterDeclined),
    takeLatest(LoadAction.ACCEPT_LOAD, LoadSaga.acceptLoad),
    takeLatest(LoadAction.GET_LOAD_DETAIL, LoadSaga.getLoadDetail),
    takeLatest(ProfileAction.POST_LOAD[0], ProfileSaga.postLoad),
    takeLatest(LoadAction.GET_LOADS_HISTORY, LoadSaga.getLoadsHistory),
    takeLatest(LoadAction.ALLOCATE_LOAD, LoadSaga.allocateLoad),
    takeLatest(LoadAction.UPDATE_RESPONSE_DEADLINE, LoadSaga.updateResponseDeadline),
    takeLatest(LoadAction.GET_FORWARD_LOAD_DETAIL, LoadSaga.getForwardLoadDetail),
    takeLatest(LoadAction.FORWARD_LOAD, LoadSaga.forwardLoad),
    takeLatest(LoadAction.ADD_DRIVER_AND_REF, LoadSaga.addDriverAndRef),
    takeLatest(LoadAction.CREATE_FROM_DRAFT[0], LoadSaga.createFromDraft),
    takeLatest(LoadAction.CANCEL_LOAD_HOME_SCREEN, LoadSaga.cancelLoadHomeScreen),
    takeLatest(LoadAction.GET_ACTIVE_LOAD_DETAIL, LoadSaga.getActiveLoadDetail),
    takeLatest(LoadAction.GET_REQUEST_VEHICLE_DATA, LoadSaga.getRequestVehicleData),
    takeLatest(LoadAction.CONTACT_PICK_UP_DROP_OFF, LoadSaga.contactPickUpDropOff),
    takeLatest(LoadAction.GET_VEHICLE_MARKER, LoadSaga.getVehicleMarker),
    takeLatest(LoadAction.GET_DRIVER_MARKER, LoadSaga.getDriverMarker),
    takeLatest(LoadAction.POST_REQUEST_VEHICLE, LoadSaga.postRequestVehicle),
    takeLatest(LoadAction.ACCEPT_REQUEST_VEHICLE, LoadSaga.acceptRequestVehicle),
    takeLatest(LoadAction.ADD_VIEWER, LoadSaga.addViewer),
    takeLatest(DrawerAction.DELETE_NETWORK, DrawerSaga.DeleteNetwork),
    takeLatest(DrawerAction.GET_FAQS, DrawerSaga.GetFaqs),
    takeLatest(AuthAction.GET_LOADS_COUNT, AuthSaga.getLoadsCount),
    takeLatest(LoadAction.ADD_REQUIREMENTS, LoadSaga.addRequirements),
    takeLatest(AuthAction.FIRST_LOGIN, AuthSaga.LogInFirst),
    takeLatest(DrawerAction.GET_PROMOTIONS, DrawerSaga.GetPromotions),
    takeLatest(DrawerAction.GET_PROMOTION_DETAIL, DrawerSaga.GetPromotionDetail),
    takeLatest(ProfileAction.DEL_PROFILE_IMAGE, ProfileSaga.DelProfileImage),
    takeLatest(LoadAction.MARK_AS_COMPLETE_BY_POSTER, LoadSaga.markAsCompleteByPoster),
    takeLatest(LoadAction.MARK_AS_COMPLETE_BY_RECIPIENT, LoadSaga.markAsCompleteByRecipient),
    takeLatest(AuthAction.DEL_ACCOUNT, AuthSaga.DelAccount),
    takeLatest(LoadAction.UPDATE_DRIVER_CHECKLIST, LoadSaga.updateDriverChecklist),
    takeLatest(LoadAction.NAGOTIATE_BID_POSTER, LoadSaga.negotiateBidPoster),
    takeLatest(LoadAction.NAGOTIATE_BID_ACCEPT_REJECT_RECIPIENT, LoadSaga.negotiateBidAcceptRejectRecipient),
    takeLatest(LoadAction.AWARD_CONTRACT, LoadSaga.awardContract),
    takeLatest(LoadAction.ADD_VEHICLE_LAST_LOCATION, LoadSaga.addVehicleLastLocation),
    takeLatest(LoadAction.UPLOAD_FILES_RECIPIENT, LoadSaga.uploadFilesRecipient),
    takeLatest(LoadAction.HANDLE_TRACKING_CREDENTIALS, LoadSaga.hanldeTrackingCredentials),
    takeLatest(LoadAction.ADD_BULK_VEHICLES, LoadSaga.addBulkVehicles),
    takeLatest(LoadAction.ADD_BULK_VEHICLE_REFS, LoadSaga.addBulkVehicleRefs),
    takeLatest(LoadAction.ADD_TRACKING_ID, LoadSaga.addTrackingId),
    takeLatest(LoadAction.UPLOAD_DOC_RECIPIENT, LoadSaga.uploadDocRecipient),
    takeLatest(ProfileAction.GET_ACTIVE_LOADS, ProfileSaga.getActiveLoads),
    takeLatest(ProfileAction.UPDATE_LOCATION, ProfileSaga.updateLocation),
    takeLatest(ProfileAction.DASHBOARD_REPORTS, ProfileSaga.dashboardReports),
    takeLatest(ProfileAction.DOWNLOAD_CSV_REPORT, ProfileSaga.downloadCsvReports),
    takeLatest(ProfileAction.DOCUMENTS_MG_EXPRESS, ProfileSaga.documentsMgExpress),
    takeLatest(LoadAction.SHOW_INDICATION_DOTS, LoadSaga.showIndicationDots),
    takeLatest(LoadAction.REMOVE_VIEWER, LoadSaga.removeViewer),
    takeLatest(LoadAction.PIN_POINTS_FOR_VEHICLE, LoadSaga.pinPointsForVehicle),
    takeLatest(LoadAction.REMOVE_DOCUMENT, LoadSaga.removeDocument),
    takeLatest(ProfileAction.DASHBOARD_DATA, ProfileSaga.dashboardData),
    takeLatest(ProfileAction.DASHBOARD_REPORT_NEW, ProfileSaga.dashboardDataReportNew),
    takeLatest(ProfileAction.DASHBOARD_LOAD_DETAIL, ProfileSaga.dashboardDataLoadDetail),
    takeLatest(LoadAction.SHOW_INDICATION_DOTS_VIEWER, LoadSaga.showIndicationDotsViewer),
    takeLatest(LoadAction.UPDATE_DRIVER_DETAIL, LoadSaga.updateDriverDetail),




  ]);
}
