import React, { FC, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { TransactionTypeProps } from "./type";
import "./style.scss";
import { useSelector } from "react-redux";
var numeral = require("numeral");
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Select,
  MenuItem
} from "@mui/material/";
import { ExpandMore } from "@mui/icons-material";
import { LOAD_TYPE } from "@temp/constants";
import InfoIcon from "@mui/icons-material/Info";
import { CustomModal, CustomText, NewInput} from "@temp/components";
import { SimpleCheckBox } from "@components";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";

export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 100%;
  background-color: rgb(250, 250, 250);
  margin-bottom: 20px;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 0 0 !important;
`;

export const CategoryContainer: FC<TransactionTypeProps> = ({
  value,
  index,
  onChangePrice,
  screenTitle,
  from,
  categoryLength,
  totalContainers,
}: TransactionTypeProps) => {
  const [info, setInfo] = useState("");
  const [showModal, toggleModal] = useState(false);

  const FormRow = ({ label, value, adornement }) => {
    return (
      <>
        <div className="formGrid">
          <div className="grayLabel iconLabel">
            {label}
            {label == "Total Price" ? (
              <InfoIcon
                onClick={() => {
                  setInfo(
                    "Price is for all vehicles in category, inclusive of requested free time and 1 day mandatory detention."
                  );
                  toggleModal(true);
                }}
              />
            ) : null}
          </div>
          <div className="fw-5">
            {value} {adornement}
          </div>
        </div>
      </>
    );
  };

  const FormRow2 = ({ label, value1, value2 }) => {
    return (
      <Grid item xs={12} className="formGrid">
        <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>{label}</div>
        <div className="tempData">
          <div className="fw-5"><span style={{ width: 50 }}>Min: </span>{value1} {<><span style={{ marginLeft: 3 }}>&#176;</span><span>C</span></>} - </div>
          <div className="fw-5"><span style={{ width: 50 }}>Max: </span> {value2} {<><span style={{ marginLeft: 3 }}>&#176;</span><span>C</span></>}</div>
        </div>

      </Grid>
    );
  };
  const [isExpanded, setIsExpanded] = useState(true);
  const handleChange = (check) => {
    setIsExpanded(check);
  };
  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  let hoursData = useSelector(
    (state: any) => state?.Profile?.postLoadMeta?.detention_free_time_hrs.map((item) => { return { value: item, key: `${item} hrs` } })
  );

  const load_type = postLoadMeta.load_types.find(
    (val: object) => val.id === PostLoad.load_type
  );

  

  const [additional_data, set_additional_data] = useState(PostLoad?.additionalScreen)

  useEffect(() => {
    set_additional_data(PostLoad?.additionalScreen)
  }, [])


  return (
    <>
      <Maincontainer>
        <Accordion
          expanded={isExpanded}
          onChange={() => handleChange(!isExpanded)}
          className="offerAccordionContainer"
          style={{ backgroundColor: "rgb(250,250,250)" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={{ paddingTop: "5px", fontWeight: "500", color: "#000", letterSpacing: "-0.56px" }}>
              {categoryLength > 1 ? `Category ${index + 1}` : "Category"}
            </Typography>

          </AccordionSummary>
          <AccordionDetails style={{ paddingTop: 0, paddingBottom: 10 }}>
            <Grid
              container
              spacing={1}
              style={{ overflowY: "scroll", margin: 0, alignItems: "center" }}
            >
              <FormRow
                label="Vehicle Type"
                value={value?.selectVehicleTypeObj?.title}
              />

              {value?.containerType?.title ?
                <FormRow
                  label="Container Type"
                  value={value?.containerType?.title}
                />
                : null}

              {load_type.title === LOAD_TYPE.bulk && value?.selectVehicleTypeObj?.sub_title && (
                <FormRow
                  label="Vehicle Capacity"
                  value={value?.selectVehicleTypeObj?.sub_title}
                />
              )}
              {value?.packageType?.title && (
                <FormRow
                  label="Packaging type"
                  value={
                    value?.packageType?.title === "Other"
                      ? value?.packageType?.title +
                      " (" +
                      value?.values?.package_type_other +
                      ")"
                      : value?.packageType?.title || ""
                  }
                />
              )}


              <FormRow
                label={load_type.title == LOAD_TYPE.fcl && value?.max_vehicle_weight ? "Max Cargo Weight Per Vehicle (Without Container)"
                  : value?.max_vehicle_weight ? "Max Cargo Weight Per Vehicle" : ""
                }
                value={
                  value.max_vehicle_weight
                    ? value.max_vehicle_weight + " Ton"
                    : "0 Ton"
                }
              />

              {value?.selectVehicleTypeObj?.is_boxed === 1 && value?.is_boxed || value?.values?.is_boxed ?
                (
                  <FormRow
                    label="Box"
                    value={
                      value?.is_boxed || value?.values?.is_boxed ? "YES" : "NO"
                    }
                  />
                ) : null}

              {value?.selectVehicleTypeObj?.is_sidewall === 1 && value?.is_sidewall || value?.values?.is_sidewall ?
                (
                  <FormRow
                    label="Sidewall"
                    value={
                      value?.is_sidewall || value?.values?.is_sidewall
                        ? "YES"
                        : "NO"
                    }
                  />
                ) : null}

              {PostLoad?.load_type == 3 || PostLoad?.load_type == 1 ?
                <FormRow
                  label="Number of Vehicles"
                  value={value?.vehicle_quantity}
                />
                : null}

              {PostLoad?.load_type == 1 ?
                <FormRow
                  label="Number of Containers"
                  value={value?.values?.is_twin_load == 1 ? Number(value?.vehicle_quantity) * 2 : value?.vehicle_quantity}
                />
                : null}

              {
               value?.values?.required_temperature?.length
                // && value?.values?.required_temperature
                
                  && value?.containerType?.provide_temperature_control
                    ==1
                    || value?.selectVehicleTypeObj?.provide_temperature_control
                    ==1
                  ?
                  <>
                    <FormRow2
                      label="Required Temperature"
                      value1={value?.values?.required_temperature ? value?.values?.required_temperature[0]:null}
                      value2={value?.values?.required_temperature ? value?.values?.required_temperature[1]:null}
                    />
                  </>
                  : null
              }

              {value?.selectVehicleTypeObj?.provide_genset ==1
              || value?.containerType?.provide_genset ==1
              ?
                <FormRow
                  label="Genset Required"
                  value={value?.values?.is_genset ? "Yes" : "No"}
                />
                : null}

              {value?.values?.is_twin_load ? (
                <FormRow label="Twin Load (2x20 Ft)" value="YES" />
              ) : null}

              {value?.values?.is_shipper_owned_container ? (
                <FormRow label="Shipper Owned Container(SOC)" value="YES" />
              ) : null}

              {value?.values?.is_out_of_guage ? (
                <FormRow label="Out of Gauge" value="YES" />
              ) : null}


              {/* {screenTitle !== LOAD_TYPE.fcl && ( */}
              <>
                {value?.is_loading_required ?
                  <FormRow
                    label="Loading Required"
                    value={value?.is_loading_required ? "YES" : "NO"}
                  /> : null}

                {value?.is_loading_required &&
                  value?.loading_requirements && (
                    <FormRow
                      label="Specify Labour / Equipment Requirement"
                      value={value?.loading_requirements || ""}
                    />
                  )}

                {value?.is_unloading_required ?
                  <FormRow
                    label="Unloading Required"
                    value={value?.is_unloading_required ? "YES" : "NO"}
                  /> : null}

                {value?.is_unloading_required &&
                  value?.unloading_requirements && (
                    <FormRow
                      label="Specify Labour / Equipment Requirement"
                      value={value?.unloading_requirements || ""}
                    />
                  )}
              </>
              {/* // )} */}

              <>
                {from !== "offer" ? (<>
                  <Grid item xs={12}>
                    <div className="inputField customEdit" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                      <div className="label">
                        Max Bid (Without GST)
                      </div>
                      <div className="field">
                        <NewInput
                          onChange={(e) => {
                            onChangePrice(
                              index,
                              e.target.value,
                              "max_bid",
                              false,
                            );
                          }}
                          value={value?.max_bid ? value?.max_bid : ""}
                          showEnd={"PKR"}
                          placeholder={"Optional"}
                        />
                      </div>
                    </div>
                  </Grid> </>) : null}


                <Grid item xs={6}>
                  <div className="transactionFieldLabel">
                    Free Time Required
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="userSelectBox">
                    <Select
                      placeholder="Select Hours"
                      name="vehicle_type"
                      className="add-vehicle-select-box2"
                      value={
                        value?.detention_free_time
                          ? value.detention_free_time
                          : "Select Hours"
                      }
                      onChange={(e) => {
                        
                        onChangePrice(
                          index,
                          e.target.value,
                          "detention_free_time",
                          true
                        );
                      }}
                    >
                      {hoursData?.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            selected={item?.value == value.detention_free_time}
                            value={item?.value}
                          >
                            {item?.key}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </Grid>

                {
                  PostLoad?.load_type == 1 && value?.detention_free_time < 24 ?
                    <div className="additionalCheckboxStyle">
                      <SimpleCheckBox
                        id={"detention_included_transport"}
                        labelName="detention_included_transport"
                        name="detention_included_transport"
                        title="1 Mandatory Detention included in Transport Price"
                        setInfo={setInfo}
                        toggleModal={toggleModal}
                        checked={value.detention_included_transport ? true : false}
                        handleChange={(e) => {
                          onChangePrice(
                            index,
                            e.target.checked,
                            "detention_included_transport",
                            true,
                          );

                        }}
                      />
                    </div>
                    : null
                }

                {from == "offer" ? (
                  <>
                    <Grid item xs={12}>
                      <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                        <div className="label">
                          {/* Transport Price / Vehicle (Without GST) */}
                          Price / Vehicle (Without GST)

                          <InfoIcon
                            onClick={() => {
                              setInfo("Price is inclusive of Free Time.");
                              toggleModal(true);
                            }}
                          />
                        </div>
                        <div className="field">
                          <NewInput
                            onChange={(e) => {
                              onChangePrice(
                                index,
                                e.target.value,
                                "price",
                              );
                            }}
                            value={value?.price ? value?.price : ""}
                            showEnd={"PKR"}
                          />
                        </div>
                      </div>
                    </Grid>



                    {PostLoad?.additionalScreen?.is_cross_stuffing_required ?
                      <Grid item xs={12} style={{ padding: 0, marginBottom: 12 }}>
                        <div className="error-container info">
                          <div>
                            <InfoIcon className="error-icon" />
                          </div>
                          <div className="error-text">
                            Offer price should be inclusive of local transportation + shifting charges + upcountry transportation.
                          </div>
                        </div>
                      </Grid> : null
                    }

                    <Grid item xs={12}>
                      <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                        <div className="label">
                          Detention Rate (Without GST)
                          <InfoIcon
                            onClick={() => {
                              setInfo(
                                "Detention is applicable on a daily basis from the expiry of free time."
                              );
                              toggleModal(true);
                            }}
                          />
                        </div>
                        <div className="field">
                          <NewInput
                            onChange={(e) => {
                              onChangePrice(
                                index,
                                e.target.value,
                                "detention_rate",
                                true
                              );
                            }}
                            value={value?.detention_rate ? value?.detention_rate : ""}
                            showEnd={"PKR"}
                            placeholder={'Optional'}
                          />
                        </div>
                      </div>
                    </Grid>
                  </>
                ) : null}

                {/* for twin load */}

                {from == "offer" && screenTitle == LOAD_TYPE.fcl && value?.values?.is_twin_load ?
                  <>
                    <Grid item xs={12}>
                      <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                        <div className="label">
                          Price / Container (Without GST)
                        </div>
                        <div className="field priceFieldDisabled customDisableClass2">
                          <NewInput
                            value={numeral(Number(value?.price) / 2).format(
                              "0,0"
                            )}
                            disabled={true}
                            aria-describedby="standard-weight-helper-text"
                            showEnd={"PKR"}
                          />
                        </div>
                      </div>
                    </Grid>

                  </> : null}

                {/* <Grid item xs={10}>
                  <div className="transactionFieldLabel">
                    1 Mandatory Detention included in Transport Price?
                    <InfoIcon
                      onClick={() => {
                        setInfo(
                          "1 day Mandatory Detention should be selected in cases where detention is applicable due to road restrictions, after expiry of free period."
                        );
                        toggleModal(true);
                      }}
                      style={{
                        height: 17,
                        position: "absolute",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={2} style={{ padding: 0, marginBottom: 12 }}>
                  <Checkbox
                    checked={value?.detention_included_transport ? true : false}
                    style={{ color: "#7ac251", marginLeft: 20 }}
                    onClick={() => {
                      if (value?.detention_included_transport) {
                        onChangePrice(
                          index,
                          false,
                          "detention_included_transport",
                          true
                        );
                      } else {
                        onChangePrice(
                          index,
                          true,
                          "detention_included_transport",
                          true
                        );
                      }
                    }}
                  />
                </Grid> */}
              </>

              {/* {screenTitle !== LOAD_TYPE.fcl && ( */}
              <>
                {value?.is_loading_required && from == "offer" && (
                  <Grid item xs={12}>
                    <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                      <div className="label">
                        Loading Price / Vehicle (Without GST)
                      </div>
                      <div className="field">
                        <NewInput
                          onChange={(e) => {
                            onChangePrice(
                              index,
                              e.target.value,
                              "loading_price",
                            );
                          }}
                          value={value?.loading_price ? value?.loading_price : ""}
                          showEnd={"PKR"}
                        />
                      </div>
                    </div>
                  </Grid>
                )}

                {value?.is_unloading_required && from == "offer" && (
                  <Grid item xs={12}>
                    <div className="inputField" style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}>
                      <div className="label">
                        Unloading Price / Vehicle (Without GST)
                      </div>
                      <div className="field">
                        <NewInput
                          onChange={(e) => {
                            onChangePrice(
                              index,
                              e.target.value,
                              "unloading_price",
                            );
                          }}
                          value={value?.unloading_price ? value?.unloading_price : ""}
                          showEnd={"PKR"}
                        />
                      </div>
                    </div>
                  </Grid>
                )}
              </>
              {/* )} */}

              {from == "offer" ? (
                <>
                  <FormRow
                    label="Total Price (Without GST)"
                    value={
                      numeral(Number(value?.total_amount || 0)).format("0,0") +
                      " PKR"
                    }
                  />

                  <Grid
                    item
                    xs={12}
                    style={{ paddingBottom: 0, marginBottom: 0, paddingLeft: 0 }}
                  >
                    <div className="simpleLabel fw-5">
                      Other Additional Charges (if applicable)
                    </div>
                  </Grid>
                  <Grid style={{ marginTop: -6, padding: 0 }} item xs={12}>
                    <div
                      className="textareaWrapper"
                    >
                      <textarea
                        style={{
                          outline: "none",
                        }}
                        maxLength={250}
                        onChange={(e) => {
                          onChangePrice(
                            index,
                            e.target.value,
                            "detention_additional_charges",
                            true
                          );
                        }}
                        value={
                          value?.detention_additional_charges
                            ? value?.detention_additional_charges
                            : ""
                        }
                        id={"cargo_description"}
                        name={"cargo_description"}
                        placeholder="Optional"
                      />
                    </div>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <CustomModal
          modalStyle={{
            width: 300,
            minHeight: 100,
          }}
          showModal={showModal}
          toggleModal={toggleModal}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InfoIcon color="primary" style={{ fontSize: 40 }} />
            <CustomText.OpenSansRegular>{info}</CustomText.OpenSansRegular>
          </div>
        </CustomModal>
      </Maincontainer>
    </>


  );
};

export default CategoryContainer;
