import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CustomText } from "@temp/components";
import { timeHumanize } from "../PromotionDetailScreen/Page";


interface PromotionsProps {
    promotionsArray: any
}


export const CardComponent: FC<PromotionsProps> = ({
    promotionsArray,
}: PromotionsProps) => {
    const history = useHistory();

    const clickFunction = (indexx: number) => {
        history.push(`/promotion-detail/${indexx}`)
    }

    useEffect(() => {
        const elements = document.querySelectorAll('[id*="desc-"]');
        elements.forEach((element, index) => {
            element.innerHTML = promotionsArray[index].description;
        });
    }, [promotionsArray]);

    return (
        <>
            {
                promotionsArray ? promotionsArray.length && promotionsArray.map((item, index) => {
                    
                    return (
                        <div className="promotion-wraper" onClick={() => { clickFunction(item.id) }} >
                            <div style={{
                                width: "100%",
                                height: 200,
                            }}>
                                <img src={item?.image} style={{ height: "100%", width: '100%' }} />
                            </div>
                            <div style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'space-between',
                                marginTop:10
                            }}>
                                <CustomText.OpenSansSemiBold textStyle={{
                                    fontSize: "0.938rem",
                                }}>
                                    {item.title}
                                </CustomText.OpenSansSemiBold>
                                <div className="date"> {timeHumanize(item?.created_at, "homeCard")}</div>
                            </div>
                            <div style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginBottom: 10
                            }}>
                                <p style={{
                                    fontFamily: 'Poppins',
                                    fontSize: "0.75rem",
                                    marginTop: 10
                                }} id={'desc-' + index}></p>

                            </div>
                        </div>
                    )
                }) : null
            }
        </>
    )
}

export default CardComponent;
