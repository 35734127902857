import React, { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material//Grid";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import "./style.scss";
import { LOAD_TYPE } from "@temp/constants";
import { CustomModal, RangeSlider, TextInput } from "@temp/components";
import {
  PrimaryButton,
  SimpleCheckBox,
  LocationTimeline,
  NewInput,
} from "@components";
import TitleLayout from "@temp/components/TitleLayout";
import { PAYMENT_TERMS_URL } from "@temp/app/routes";
import { INQUIRY_TYPE, TRANSACTION_TYPE } from "@temp/constants";
import {
  Accordion,
  AccordionSummary,
  Select,
  MenuItem,
} from "@mui/material/";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import rootReducer from "../../../store/reducers";
import { showMessage } from "@temp/utils/helper";
var numeral = require("numeral");
import _ from "lodash";
export type RootState = ReturnType<typeof rootReducer>;
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import CategoryContainer from "../AdditionalDetailsLclBulk/CategoryContainer";

export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 17px;
  border-radius: 10px;
`;
export const AdditionalDetails: FC = (editable) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [showModalWaterMark, setShowModalWaterMark] = useState(false);
  const [showInfoWaterMark, setShowInfoWaterMark] = useState("");

  const PostLoad = useSelector((state: RootState) => state?.Profile?.postLoad);
  const postLoadMeta = useSelector((state: RootState) => state?.Profile?.postLoadMeta);
  const additional_default_values = PostLoad?.load_type == 1
    ? postLoadMeta?.load_configurations?.containerize_additional_details
    : postLoadMeta?.load_configurations?.non_containerize_additional_details;
  const load_type = postLoadMeta.load_types.find((val: any) => val?.id === PostLoad.load_type);
  const inquiry_type_contract = postLoadMeta.inquiry_types.find((val: any) => val?.title === INQUIRY_TYPE.contractRate);
  const offer_transaction_type = postLoadMeta.transaction_types.find((val: any) => val?.title === TRANSACTION_TYPE.offer);

  const [initialValues, setInitialValues] = useState({
    is_hse_compliant_vehicle: additional_default_values?.is_hse_compliant_vehicle
      ? true
      : false,
    is_covid_certf_req_driver: additional_default_values?.is_covid_certf_req_driver
      ? true
      : false,
    is_vehicle_tracking: additional_default_values?.is_vehicle_tracking
      ? true
      : false,
    is_container_deposit_by_transporter: additional_default_values?.is_container_deposit_by_transporter
      ? true
      : false,
    shipping_line_name: "",
    container_deposit_amount: "",
    is_cross_stuffing_required:
      PostLoad?.inquiry_type == 1 &&
        additional_default_values?.is_cross_stuffing_required
        ? true
        : false,
    is_cross_stuffing_box_vehicle_required: "",
    cross_stuffing_no_of_packages: "",
    max_bid: "",
    is_quote_price_deposit_fee_inclusive: true,
    is_advance_vehicle_driver_docs: additional_default_values?.is_advance_vehicle_driver_docs
      ? true
      : false,
    is_driver_negative_pcr_required: additional_default_values?.is_driver_negative_pcr_required
      ? true
      : false,
    detention_included_transport: false,
    is_weightment_received_required: additional_default_values?.is_weightment_received_required
      ? true
      : false,
    weightment_received_name: "",
    is_bonded_vehicle_required: additional_default_values?.is_bonded_vehicle_required
      ? true
      : false,
    is_tarpaulin_plastic_cover: additional_default_values?.is_tarpaulin_plastic_cover
      ? true
      : false,
    is_genset: false,
    required_temperature: "",
    is_allow_partial_load: PostLoad?.vehicleScreen?.is_allow_partial_load,
    driver_requried_document_details: "",
    is_under_hook_delivery_required: additional_default_values?.is_under_hook_delivery_required
      ? true
      : false,
    under_hook_delivery: "",
  });
  const [locations, setLocation] = useState(PostLoad?.location)
  const [new_det_rates, set_new_det_rates] = useState([])
  const [info, setInfo] = useState("");
  const [showModal, toggleModal] = useState(false);

  useEffect(() => {
    if (PostLoad?.additionalScreen) {
      let additionalScreen = PostLoad?.additionalScreen;
      setInitialValues({ ...additionalScreen });
    }
  }, []);

  useEffect(() => {
    if (
      PostLoad?.inquiry_type == 2 &&
      PostLoad?.contractDetails?.quote_by !== "weight_volume"
    ) {
      let data = [];
      PostLoad?.location.forEach((loc, loc_index) => {
        loc?.items.forEach((cat, cat_index) => {
          
          if (cat?.values?.detention_free_time) {
            data.push({
              loc_index,
              cat_index,
              detention_free_time: cat?.values?.detention_free_time,
              is_genset: cat?.values?.is_genset,
              // required_temperature: "",
              required_temperature: [cat?.values?.required_temperature[0], cat?.values?.required_temperature[1]],

              detention_included_transport: cat?.values?.detention_included_transport
            });
          } else {
            data.push({
              loc_index,
              cat_index,
              detention_free_time:
                postLoadMeta?.load_configurations?.free_time_setting || 24,
              is_genset: cat?.values?.is_genset,
              // required_temperature: "",
              required_temperature: [cat?.values?.required_temperature[0], cat?.values?.required_temperature[1]],

              detention_included_transport: cat?.values?.detention_included_transport
            });
          }
        });
      });
      set_new_det_rates(data);
    }
  }, []);

  useEffect(() => {
    if (PostLoad?.contractDetails?.quote_by == "weight_volume") {
      if (PostLoad?.contractDetails?.is_unit_basis) {
        let data = [];
        PostLoad?.location.forEach((loc, loc_index) => {
          loc?.added_units.forEach((cat, cat_index) => {
            if (cat?.detention_free_time) {
              data.push({
                loc_index,
                cat_index,
                detention_free_time: cat?.detention_free_time,
              });
            } else {
              data.push({
                loc_index,
                cat_index,
                detention_free_time:
                  postLoadMeta?.load_configurations?.free_time_setting || 24,
              });
            }
          });
        });
        set_new_det_rates(data);
      }
      let new_loc = [];
      if (PostLoad?.load_type !== 1) {
        PostLoad?.location.forEach((loc, loc_index) => {
          if (loc?.pickup?.attribute?.cargo_volume && loc?.pickup?.attribute?.gross_weight && loc?.pickup?.attribute?.cargo_liter) {
            loc["prices_units"] = ["Ton", "F.Ton", "CBM", "Liter", "Kg"]
          } else if (loc?.pickup?.attribute?.cargo_volume && loc?.pickup?.attribute?.gross_weight && !loc?.pickup?.attribute?.cargo_liter) {
            loc["prices_units"] = ["Ton", "F.Ton", "CBM", "Kg"]
          } else if (loc?.pickup?.attribute?.gross_weight && loc?.pickup?.attribute?.cargo_liter && !loc?.pickup?.attribute?.cargo_volume) {
            loc["prices_units"] = ["Ton", "Liter", "Kg"]
          } else if (loc?.pickup?.attribute?.gross_weight && !loc?.pickup?.attribute?.cargo_liter && !loc?.pickup?.attribute?.cargo_volume) {
            loc["prices_units"] = ["Ton", "Kg"]
          }
          if (loc?.pickup?.attribute?.hasOwnProperty("detention_free_time")) {
            new_loc.push({ ...loc });
          } else {
            loc["pickup"]["attribute"]["detention_free_time"] =
              postLoadMeta?.load_configurations?.free_time_setting || 24;
            new_loc.push({ ...loc });
          }
        });
      }
      setLocation(new_loc);
    }
  }, []);

  const handleSubmit = () => {
    if (PostLoad?.contractDetails?.quote_by !== "weight_volume") {
      if (PostLoad?.inquiry_type == 2) {
        let new_locations = [...locations];
        let data = [];
        new_locations.forEach((loc, loc_index) => {
          let arr2 = [];
          loc?.items.forEach((cat, cat_index) => {
            
            let find = new_det_rates.find(
              (item) =>
                item?.loc_index == loc_index && item?.cat_index == cat_index
            );
            
            arr2.push({
              ...cat,
              values: {
                ...cat?.values,
                detention_free_time: find?.detention_free_time,
                is_genset: find?.is_genset,
                required_temperature: [find?.required_temperature[0], find?.required_temperature[1]],
                detention_included_transport: find?.detention_included_transport
              },
            });
          });
          data.push({ ...loc, items: arr2, new_cat: [], is_data: false });
        });
        dispatch(
          ProfileAction.postLoadData({
            additionalScreen: { ...initialValues, is_allow_partial_load: PostLoad?.vehicleScreen?.is_allow_partial_load },
            location: data,
            vehicleScreen: {
              ...PostLoad?.vehicleScreen,
              addedVehicles: vehicles,
            },
          })
        );
      } else {
        dispatch(
          ProfileAction.postLoadData({
            additionalScreen: { ...initialValues, is_allow_partial_load: PostLoad?.vehicleScreen?.is_allow_partial_load },
            location: PostLoad?.location,
            vehicleScreen: {
              ...PostLoad?.vehicleScreen,
              addedVehicles: vehicles,
            },
          })
        );
      }
    } else {
      if (!PostLoad?.contractDetails?.is_unit_basis) {
        dispatch(
          ProfileAction.postLoadData({
            additionalScreen: { ...initialValues, is_allow_partial_load: PostLoad?.vehicleScreen?.is_allow_partial_load },
            location: locations,
          })
        );
      } else {
        let new_locations = [...locations];
        let data = [];
        new_locations.forEach((loc, loc_index) => {
          let arr2 = [];
          loc?.added_units.forEach((cat, cat_index) => {
            let find = new_det_rates.find(
              (item) =>
                item?.loc_index == loc_index && item?.cat_index == cat_index
            );
            arr2.push({
              ...cat,
              detention_free_time: find?.detention_free_time,
            });
          });
          data.push({ ...loc, added_units: arr2, new_cat: [], is_data: false });
        });
        dispatch(
          ProfileAction.postLoadData({
            additionalScreen: { ...initialValues, is_allow_partial_load: PostLoad?.vehicleScreen?.is_allow_partial_load },
            location: data,
            vehicleScreen: {
              ...PostLoad?.vehicleScreen,
              addedVehicles: vehicles,
            },
          })
        );
      }
    }
    if (PostLoad.inquiry_type === inquiry_type_contract?.id) {
      history.push(PAYMENT_TERMS_URL);
    } else {
      if (PostLoad.transaction_type_id === offer_transaction_type.id) {
        history.push("/offer");
      } else {
        history.push("/bid");
      }
    }
  };

  const handleChange = (e) => {
    if (
      e.target.name !== "shipping_line_name" ||
      e.target.name !== "container_deposit_amount" ||
      e.target.name !== "is_box_vehicle_required" ||
      e.target.name !== "is_weightment_received_required" ||
      e.target.name !== "weightment_received_name" ||
      e.target.name !== "required_temperature" ||
      e.target.name !== "is_under_hook_delivery_required" ||
      e.target.name !== "under_hook_delivery"

    ) {
      setInitialValues({
        ...initialValues,
        [e.target.name]: e.target.checked,
      });
    } else {
      setInitialValues({
        ...initialValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleDisabled = () => {
    if (PostLoad?.contractDetails?.is_unit_basis) {
      return locations.every(
        (item) => item?.pickup.attribute.detention_free_time
      )
        ? false
        : true;
    } else if (PostLoad?.contractDetails?.quote_by == "weight_volume" && !PostLoad?.contractDetails?.is_unit_basis) {
      return locations.every(
        (item) =>
          item?.pickup.attribute.detention_free_time && item?.pickup?.attribute?.price_unit
      )
        ? false
        : true;
    }
    else {
      return locations.every(
        (item) => item?.pickup.attribute.detention_free_time
      )
        ? false
        : true;
    }
  };

  const handleDisable2 = () => {
    if (PostLoad?.inquiry_type == 2) {
      let data = [];
      locations?.forEach((element) => {
        data.push(...element.items);
      });
      return data.length == new_det_rates.length ? false : true;
    } else {
      // return initialValues?.rate_validity_date ? false : true
    }
  };

  const updateFree = (data) => {
    let new_data = [...new_det_rates];
    if (
      new_data.find(
        (item) =>
          item?.loc_index == data?.location_index &&
          item?.cat_index == data.cat_index
      )
    ) {
      let findindex = new_data.findIndex(
        (item) =>
          item.loc_index == data.location_index &&
          item?.cat_index == data.cat_index
      );
      new_data[findindex][data?.key] = data?.value;
      new_data[findindex]["loc_index"] = data.location_index;
      new_data[findindex]["cat_index"] = data.cat_index;
    } else {
      let obj = {};
      obj[data?.key] = data?.value;
      obj["loc_index"] = data.location_index;
      obj["cat_index"] = data.cat_index;
      new_data.push(obj);
    }
    set_new_det_rates(new_data);
  };

  const FormRow = ({ label, value }) => {
    return (
      <div className="formGrid" style={{ width: '100%', marginBottom: '0.75rem !important' }}>
        <div className="grayLabel">
          {label}
        </div>
        <div className="fw-5">
          {value}
        </div>
      </div>
    );
  };

  const FormRow2 = ({ label, value1, value2 }) => {
    return (
      <Grid item xs={12} className="formGrid">
        <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>{label}</div>
        <div className="tempData">
          <div className="fw-5"><span style={{ width: 50 }}>Min: </span>{value1} {<><span style={{ marginLeft: 3 }}>&#176;</span><span>C</span></>} - </div>
          <div className="fw-5"><span style={{ width: 50 }}>Max: </span> {value2} {<><span style={{ marginLeft: 3 }}>&#176;</span><span>C</span></>}</div>
        </div>

      </Grid>
    );
  };
  const quoteBy = PostLoad?.contractDetails?.quote_by;
  const hoursData = useSelector(
    (state: RootState) => state?.Profile?.postLoadMeta?.detention_free_time_hrs?.map((item) => { return { key: `${item} hrs`, value: item } })
  );
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    if (PostLoad?.vehicleScreen?.addedVehicles) {
      let vehicles = [];
      PostLoad?.vehicleScreen?.addedVehicles.forEach((val: any) => {
        vehicles.push({
          is_loading_required: false,
          loading_requirements: "",
          is_unloading_required: false,
          unloading_requirements: "",
          loading_price: 0,
          unloading_price: 0,
          ...val,
        });
      });
      setVehicles(vehicles);
    }
  }, []);

  const CategoryHeader = ({ title }) => {
    return (
      <div className="fw-6 mb-3">
        {title}
      </div>
    );
  };

  return (
    <TitleLayout
      pageNumber={4}
      progress={40}
      title={`Additional Information - ${load_type.title}`}
    >

      {quoteBy == "weight_volume" && !PostLoad?.contractDetails?.is_unit_basis ?
        <div className="error-container info smallErrorContainer" style={{ marginBottom: 10 }}>
          <div>
            <InfoIcon className="error-icon" />
          </div>
          <div className="error-text">
            Please select price unit under route(s)

          </div>
        </div> : null}
      <div>
        {/* <form> */}
        <div className="formFeildWrapper">
          {PostLoad?.inquiry_type == 2 ? (
            <div>
              {locations?.map((location, index) => {
                const locationObj = [location?.pickup, location?.dropoff];
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    <FadeInComponent>
                      <Accordion className="accordion-container">
                        <AccordionSummary
                          className="accordion-background"
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => {
                            if (!location?.is_data) {
                              location["new_cat"] = location?.items;
                              setLocation([...locations]);
                              location["is_data"] = true;
                            } else {
                              location["new_cat"] = [];
                              setLocation([...locations]);
                              location["is_data"] = false;
                            }
                          }}
                        >
                          <span className="fw-6">
                            {`Route  ${index + 1} : ${location?.pickup?.title} - ${location?.dropoff?.title
                              }`}
                          </span>

                        </AccordionSummary>
                        <div
                          className="accordion-content"
                        >
                          <LocationTimeline
                            location={
                              locationObj && locationObj?.length
                                ? locationObj
                                : []
                            }
                            handleModal={(value: boolean) => { }}
                            disabled={0}
                          ></LocationTimeline>

                          {/* for qoute by vehicle and weight volume  */}
                          {location?.pickup?.attribute?.gross_weight && (
                            <>

                              <FormRow
                                label="Gross Weight in KG"
                                value={
                                  numeral(
                                    Number(location?.pickup?.attribute?.gross_weight * 1000)
                                  ).format("0,0.00") + " KG"
                                }
                              />
                              <FormRow
                                label="Gross Weight in Ton"
                                value={
                                  numeral(
                                    location?.pickup?.attribute?.gross_weight
                                  ).format("0,0.00") + " Ton"
                                }
                              />

                              {location?.pickup?.attribute?.cargo_volume ?
                                <FormRow
                                  label="Cargo Volume"
                                  value={
                                    location?.pickup?.attribute?.cargo_volume
                                      ? numeral(
                                        location?.pickup?.attribute
                                          ?.cargo_volume
                                      ).format("0,0.00") + " CBM"
                                      : "N/A"
                                  }
                                /> : null}

                              <FormRow
                                label={
                                  "Total Weight"
                                }
                                value={
                                  location?.pickup?.attribute?.total_freight_ton
                                    ? numeral(
                                      location?.pickup?.attribute?.total_freight_ton
                                    ).format("0,0.00") + `${!location?.pickup?.attribute?.cargo_volume ? " Ton"
                                      : " F.Ton"}`
                                    : ""
                                }
                              />
                              {location?.pickup?.attribute?.no_of_packages ?
                                <FormRow
                                  label="Number of Packages"
                                  value={
                                    location?.pickup?.attribute?.no_of_packages
                                      ? location?.pickup?.attribute
                                        ?.no_of_packages
                                      : "N/A"
                                  }
                                /> : null}
                              {location?.pickup?.attribute?.cargo_liter ?
                                <FormRow
                                  label="Liter"
                                  value={
                                    location?.pickup?.attribute?.cargo_liter
                                      ? location?.pickup?.attribute?.cargo_liter +
                                      " " +
                                      "liter"
                                      : "N/A"
                                  }
                                /> : null
                              }
                            </>
                          )}

                          {/* for only weight volume  */}
                          {quoteBy === "weight_volume" &&
                            !PostLoad?.contractDetails?.is_unit_basis && (
                              <>
                                <FormRow
                                  label="Vehicle Type"
                                  value={
                                    location?.pickup?.attribute
                                      ?.PreferredVehicleType?.title
                                  }
                                />

                                <FormRow
                                  label="Packaging type"
                                  value={
                                    location?.pickup?.attribute?.packageType
                                      ?.title === "Other"
                                      ? location?.pickup?.attribute?.packageType
                                        ?.title +
                                      " (" +
                                      location?.pickup?.attribute
                                        ?.package_type_other +
                                      ")"
                                      : location?.pickup?.attribute?.packageType
                                        ?.title || ""
                                  }
                                />
                                {location?.pickup?.attribute
                                  ?.is_loading_required ? (
                                  <FormRow
                                    label="Loading Required"
                                    value={
                                      location?.pickup?.attribute
                                        ?.is_loading_required
                                        ? "YES"
                                        : "NO"
                                    }
                                  />
                                ) : null}

                                {location?.pickup?.attribute
                                  ?.is_loading_required &&
                                  location?.pickup?.attribute
                                    ?.loading_requirements ? (
                                  <FormRow
                                    label="Specify Labour / Equipment Requirement"
                                    value={
                                      location?.pickup?.attribute
                                        ?.loading_requirements
                                    }
                                  />
                                ) : null}

                                {location?.pickup?.attribute
                                  ?.is_unloading_required ? (
                                  <FormRow
                                    label="Unloading Required"
                                    value={
                                      location?.pickup?.attribute
                                        ?.is_unloading_required
                                        ? "YES"
                                        : "NO"
                                    }
                                  />
                                ) : null}

                                {location?.pickup?.attribute
                                  ?.is_unloading_required &&
                                  location?.pickup?.attribute
                                    ?.unloading_requirements ? (
                                  <FormRow
                                    label="Specify Labour / Equipment Requirement"
                                    value={
                                      location?.pickup?.attribute
                                        ?.unloading_requirements
                                    }
                                  />
                                ) : null}
                              </>
                            )}

                          {/* for only weight volume  */}
                          {quoteBy === "weight_volume" &&
                            !PostLoad?.contractDetails?.is_unit_basis ? (
                            <>
                              <Grid container xs={12} style={{ marginBottom: '1rem' }}>
                                <Grid item xs={5}>
                                  <div className="transactionFieldLabel">
                                    Free Time Required
                                  </div>
                                </Grid>
                                <Grid item xs={7}>
                                  <div className="userSelectBox">
                                    <Select
                                      placeholder="Select Hours"
                                      name="vehicle_type"
                                      className="add-vehicle-select-box2"
                                      value={
                                        location?.pickup?.attribute
                                          ?.detention_free_time
                                          ? location?.pickup?.attribute
                                            ?.detention_free_time
                                          : "Select Hours"
                                      }
                                      onChange={(e) => {
                                        let new_locations = [...locations];
                                        new_locations[index]["pickup"][
                                          "attribute"
                                        ]["detention_free_time"] = e.target.value;
                                        setLocation(new_locations);
                                      }}
                                    >
                                      {hoursData?.map((item) => {
                                        return (
                                          <MenuItem
                                            selected={
                                              item?.value ==
                                              location?.pickup?.attribute
                                                ?.detention_free_time
                                            }
                                            value={item?.value}
                                          >
                                            {item?.key}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </Grid>
                              </Grid>

                              {/* price unit  */}
                              <Grid container xs={12} style={{ marginBottom: '1rem' }}>
                                <Grid item xs={5}>
                                  <div className="transactionFieldLabel">
                                    Select Price Unit
                                  </div>
                                </Grid>
                                <Grid item xs={7}>
                                  <div className="userSelectBox">
                                    <Select
                                      placeholder="Select Hours"
                                      name="vehicle_type"
                                      className="add-vehicle-select-box2"
                                      value={location?.pickup?.attribute?.price_unit}
                                      onChange={(e) => {
                                        let new_locations = [...locations];
                                        new_locations[index]["pickup"]["attribute"]["price_unit"] = e.target.value;
                                        setLocation(new_locations);
                                      }}
                                    >
                                      {location?.prices_units?.map((item) => {
                                        return (
                                          <MenuItem
                                            selected={item == location?.pickup?.attribute?.price_unit}
                                            value={item}
                                          >
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                          ) : null}

                          {/* for qoute by vehicle only  */}
                          {!!location?.new_cat?.length &&
                            !PostLoad?.contractDetails?.is_unit_basis && (
                              <>
                                {location?.new_cat?.map((value, cat_ind) => {
                                  
                                  let findd = new_det_rates.find(
                                    (item) =>
                                      item?.cat_index == cat_ind &&
                                      item.loc_index == index
                                  );
                                  
                                  return (
                                    <div key={cat_ind} className="categoryWrapper">
                                      <CategoryHeader
                                        title={
                                          location?.new_cat.length > 1
                                            ? `Category ${cat_ind + 1}`
                                            : "Category"
                                        }
                                      />
                                      <FormRow
                                        label="Vehicle Type"
                                        value={
                                          value?.selectVehicleTypeObj?.title
                                        }
                                      />

                                      {value?.containerType?.title ? (
                                        <FormRow
                                          label="Container Type"
                                          value={value?.containerType?.title}
                                        />
                                      ) : null}
                                      {value?.max_bid > 0 ? (
                                        <FormRow
                                          label="Max Bid (Without GST)"
                                          value={
                                            numeral(value?.max_bid).format(
                                              "0,0"
                                            ) + " PKR"
                                          }
                                        />
                                      ) : null}
                                      {(load_type.title === LOAD_TYPE.fcl) && (
                                        <FormRow
                                          label="Max Cargo Weight Per Vehicle (Without Container)"
                                          value={
                                            value.max_vehicle_weight
                                              ? value.max_vehicle_weight +
                                              " Ton"
                                              : "0 Ton"
                                          }
                                        />
                                      )}

                                      {(load_type.title === LOAD_TYPE.bulk && !PostLoad?.contractDetails?.is_unit_basis && PostLoad?.contractDetails?.quote_by != "weight_volume") && (
                                        <FormRow
                                          label="Max Cargo Weight Per Vehicle"
                                          value={
                                            value.max_vehicle_weight
                                              ? value.max_vehicle_weight +
                                              " Ton"
                                              : "0 Ton"
                                          }
                                        />
                                      )}

                                      {load_type.title === LOAD_TYPE.bulk &&
                                        value?.selectVehicleTypeObj
                                          ?.sub_title && (
                                          <FormRow
                                            label="Vehicle Capacity"
                                            value={
                                              value?.selectVehicleTypeObj
                                                ?.sub_title
                                            }
                                          />
                                        )}
                                      {value?.vehicle_quantity ? (
                                        <FormRow
                                          label={PostLoad?.inquiry_type == 2 ? "Estimated Number of Vehicle(s)"
                                            : "Number of Vehicle(s)"}
                                          value={value?.vehicle_quantity}
                                        />
                                      ) : null}

                                      {PostLoad?.load_type === 1 && value?.vehicle_quantity ?
                                        <FormRow label="Estimated Number of Container(s)"
                                          value={value?.values?.is_twin_load == 1 ? Number(value?.vehicle_quantity) * 2 : value?.vehicle_quantity} />
                                        : ""}

                                      {value.values?.is_out_of_guage ||
                                        value?.values?.is_out_of_guage ? (
                                        <FormRow
                                          label="Out of Guage"
                                          value="Yes"
                                        />
                                      ) : null}

                                      {value.values?.is_twin ||
                                        value?.values?.is_twin_load ? (
                                        <FormRow
                                          label="Twin Load (2x20 Ft)"
                                          value="Yes"
                                        />
                                      ) : null}

                                      {load_type.title === LOAD_TYPE.fcl && value?.values
                                        ?.is_shipper_owned_container ? (
                                        <FormRow
                                          label="Shipper Owned Container"
                                          value={
                                            value?.values
                                              ?.is_shipper_owned_container
                                              ? "YES"
                                              : "NO"
                                          }
                                        />
                                      ) : null}

                                      {load_type.title === LOAD_TYPE.bulk &&
                                        value?.selectVehicleTypeObj
                                          ?.is_sidewall === 1 &&
                                        value?.values?.is_sidewall ? (
                                        <FormRow
                                          label="Half Body"
                                          value={
                                            value?.values?.is_sidewall
                                              ? "YES"
                                              : "NO"
                                          }
                                        />
                                      ) : null}

                                      {load_type.title === LOAD_TYPE.bulk &&
                                        value?.selectVehicleTypeObj
                                          ?.is_boxed === 1 &&
                                        value?.values?.is_boxed ? (
                                        <FormRow
                                          label="Box"
                                          value={
                                            value?.values?.is_boxed
                                              ? "YES"
                                              : "NO"
                                          }
                                        />
                                      ) : null}


                                      <>
                                        {load_type.title !== LOAD_TYPE.fcl ?
                                          <FormRow
                                            label="Packaging type"
                                            value={
                                              value?.packageType?.title ===
                                                "Other"
                                                ? value?.packageType?.title +
                                                " (" +
                                                value?.values
                                                  ?.package_type_other +
                                                ")"
                                                : value?.packageType?.title ||
                                                ""

                                            }
                                          /> : null}

                                        {value?.is_loading_required ||
                                          value?.values
                                            ?.is_loading_required ? (
                                          <FormRow
                                            label="Loading Required"
                                            value={
                                              value?.is_loading_required ||
                                                value?.values
                                                  ?.is_loading_required
                                                ? "YES"
                                                : "NO"
                                            }
                                          />
                                        ) : null}
                                        {((value?.is_loading_required &&
                                          value?.loading_requirements) ||
                                          (value?.values
                                            ?.is_loading_required &&
                                            value?.values
                                              ?.loading_requirements)) ? (
                                          <FormRow
                                            label="Specify Labour / Equipment Requirement"
                                            value={
                                              value?.loading_requirements ||
                                              value?.values
                                                ?.loading_requirements
                                            }
                                          />
                                        ) : null}
                                        {value?.is_unloading_required ||
                                          value?.values
                                            ?.is_unloading_required ? (
                                          <FormRow
                                            label="Unloading Required"
                                            value={
                                              value?.is_unloading_required ||
                                                value?.values
                                                  ?.is_unloading_required
                                                ? "YES"
                                                : "NO"
                                            }
                                          />
                                        ) : null}

                                        {((value?.is_unloading_required &&
                                          value?.unloading_requirements) ||
                                          (value?.values
                                            ?.is_unloading_required &&
                                            value?.values
                                              ?.unloading_requirements)) ? (
                                          <FormRow
                                            label="Specify Labour / Equipment Requirement"
                                            value={
                                              value?.unloading_requirements ||
                                              value?.values
                                                ?.unloading_requirements
                                            }
                                          />
                                        ) : null}
                                      </>

                                      <>
                                        <Grid container xs={12} style={{ marginBottom: '1rem' }}>
                                          <Grid item xs={6}>
                                            <div className="transactionFieldLabel">
                                              Free Time Required
                                            </div>
                                          </Grid>
                                          <Grid item xs={6}>
                                            <div className="userSelectBox">
                                              <Select
                                                placeholder="Select Hours"
                                                name="vehicle_type"
                                                className="add-vehicle-select-box2"
                                                value={
                                                  findd
                                                    ? findd?.detention_free_time
                                                    : "Select Hours"
                                                }
                                                onChange={(e) => {
                                                  setTimeout(() => {
                                                    updateFree({
                                                      location_index: index,
                                                      cat_index: cat_ind,
                                                      key:
                                                        "detention_free_time",
                                                      value: e.target.value,
                                                    });
                                                  }, 100);
                                                }}
                                              >
                                                {hoursData?.map((item, index) => {
                                                  return (
                                                    <MenuItem
                                                      key={index}
                                                      selected={
                                                        item?.value ==
                                                        findd?.detention_free_time
                                                      }
                                                      value={item?.value}
                                                    >
                                                      {item?.key}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Select>
                                            </div>
                                          </Grid>
                                        </Grid>

                                        {PostLoad?.inquiry_type == 2 && PostLoad?.load_type == 1
                                          && findd?.detention_free_time < 24
                                          ? (
                                            <FadeInComponent>
                                              <SimpleCheckBox
                                                id={"detention_included_transport"}
                                                labelName="detention_included_transport"
                                                name="detention_included_transport"
                                                title="1 Mandatory Detention included in Transport Price"
                                                handleChange={(e) => {
                                                  updateFree({
                                                    location_index: index,
                                                    cat_index: cat_ind,
                                                    key: "detention_included_transport",
                                                    value: e.target.checked,
                                                  });
                                                }}
                                                setInfo={setInfo}
                                                toggleModal={toggleModal}
                                                checked={findd?.detention_included_transport
                                                  ? true
                                                  : false}
                                              />
                                            </FadeInComponent>
                                          ) : null}

                                        {
                                          value?.containerType?.provide_temperature_control == 1 ||
                                            value?.selectVehicleTypeObj?.provide_temperature_control == 1
                                            ?
                                            <>
                                              <FormRow2
                                                label="Required Temperature"
                                                value1={value?.values?.required_temperature[0]}
                                                value2={value?.values?.required_temperature[1]}
                                              />
                                            </>
                                            : null
                                        }

                                        {
                                          value?.containerType?.provide_genset == 1 ||
                                            value?.selectVehicleTypeObj?.provide_genset == 1
                                          // &&
                                          //   value?.values?.is_genset
                                            ?
                                            <>
                                              <FormRow
                                                label="Genset Required"
                                                value={value?.values?.is_genset ? "Yes" : "No"}

                                              />
                                            </>
                                            : null
                                        }

                                        {/* {value?.containerType?.title ==
                                          "RF - Reefer" ? (
                                          <div style={{ height: 40 }} className="selectBoxWrapper">
                                            <SimpleCheckBox
                                              id={"is_genset"}
                                              labelName="is_genset"
                                              name="is_genset"
                                              title="Genset"
                                              handleChange={(e) => {
                                                updateFree({
                                                  location_index: index,
                                                  cat_index: cat_ind,
                                                  key: "is_genset",
                                                  value: e.target.checked,
                                                });
                                                if (!e.target.checked) {
                                                  updateFree({
                                                    location_index: index,
                                                    cat_index: cat_ind,
                                                    key: "required_temperature",
                                                    value: "",
                                                  });
                                                }
                                              }}
                                              className="multicolortext"
                                              checked={findd?.is_genset
                                                ? true
                                                : false}
                                            />
                                          </div>
                                        ) : null}

                                        {findd?.is_genset ? (
                                          <div className="inputField">
                                            <div className="label">
                                              <div style={{ width: "100%", height: 25 }} className="flex_row_div">
                                                Required Temperature
                                                {
                                                  findd?.required_temperature  ?
                                                    <div className="label">{findd?.required_temperature} <span style={{ marginLeft: 3 }}>&#176;</span> C</div> 
                                                    : null
                                                }
                                              </div>
                                            </div>
                                            <div className="field">
                                              <RangeSlider
                                                min={-25}
                                                max={25}
                                                step={1}
                                                value={Number(findd?.required_temperature) || 0}
                                                orientation={'horizontal'}
                                                tooltip={true}
                                                onChange={(e) => {
                                                  updateFree({
                                                    location_index: index,
                                                    cat_index: cat_ind,
                                                    key: "required_temperature",
                                                    value: e,
                                                  });
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ) : null} */}
                                      </>
                                      <Grid item xs={12}>
                                        <div
                                          style={{
                                            borderBottom: "2px solid #f1f1f1",
                                            marginTop: 10,
                                            marginBottom: 10,
                                          }}
                                        ></div>
                                      </Grid>
                                    </div>
                                  );
                                })}
                              </>
                            )}

                          {/* for item basis only */}
                          {PostLoad?.contractDetails?.is_unit_basis ? (
                            <div
                              style={{
                                paddingBottom: 30,
                              }}
                            >
                              {location?.added_units?.map((value, cat_ind) => {
                                
                                let findd = new_det_rates.find(
                                  (item) =>
                                    item?.cat_index == cat_ind &&
                                    item.loc_index == index
                                );

                                return (
                                  <div key={cat_ind}>
                                    <CategoryHeader
                                      title={
                                        location?.added_units.length > 1
                                          ? `Item Category ${cat_ind + 1}`
                                          : "Item Category"
                                      }
                                    />

                                    <FormRow
                                      label="Gross Weight in KG"
                                      value={
                                        numeral(Number(value?.gross_weight * 1000)).format(
                                          "0,0.00"
                                        ) + " KG"
                                      }
                                    />

                                    <FormRow
                                      label="Gross Weight in Ton"
                                      value={
                                        numeral(value?.gross_weight).format(
                                          "0,0.00"
                                        ) + " Ton"
                                      }
                                    />


                                    {value?.cargo_volume ? (
                                      <FormRow
                                        label="Cargo Volume"
                                        value={
                                          numeral(value?.cargo_volume).format(
                                            "0,0.00"
                                          ) + " CBM"
                                        }
                                      />
                                    ) : null}
                                    {value?.total_freight_ton ? (
                                      <FormRow
                                        label=
                                        "Total Weight"
                                        value={
                                          numeral(
                                            value?.total_freight_ton
                                          ).format("0,0.00") + " F.Ton"
                                        }
                                      />
                                    ) : null}
                                    <FormRow
                                      label="Dimensions"
                                      value={`${value?.dimensions} ${value?.unit_dimension_value}`}
                                    />
                                    <FormRow
                                      label="Item Quantity"
                                      value={value?.unit_quantity}
                                    />

                                    {value?.unit_description ? (
                                      <FormRow
                                        label="Item Description"
                                        value={value?.unit_description}
                                      />
                                    ) : null}
                                    {value?.is_loading_required ? (
                                      <FormRow
                                        label="Loading Required"
                                        value={"Yes"}
                                      />
                                    ) : null}
                                    {value?.loading_requirements ? (
                                      <FormRow
                                        label="Loading Requirements"
                                        value={value?.loading_requirements}
                                      />
                                    ) : null}
                                    {value?.is_unloading_required ? (
                                      <FormRow
                                        label="UnLoading Required"
                                        value={"Yes"}
                                      />
                                    ) : null}
                                    {value?.unloading_requirements ? (
                                      <FormRow
                                        label="UnLoading Requirements"
                                        value={value?.unloading_requirements}
                                      />
                                    ) : null}

                                    <Grid container xs={12} style={{ marginBottom: '1rem' }}>
                                      <Grid item xs={6}>
                                        <div className="transactionFieldLabel">
                                          Free Time Required
                                        </div>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <div className="userSelectBox">
                                          <Select
                                            placeholder="Select Hours"
                                            name="vehicle_type"
                                            className="add-vehicle-select-box2"
                                            value={
                                              findd
                                                ? findd?.detention_free_time
                                                : "Select Hours"
                                            }
                                            onChange={(e) => {
                                              setTimeout(() => {
                                                updateFree({
                                                  location_index: index,
                                                  cat_index: cat_ind,
                                                  key: "detention_free_time",
                                                  value: e.target.value,
                                                });
                                              }, 100);
                                            }}
                                          >
                                            {hoursData?.map((item, index) => {
                                              return (
                                                <MenuItem
                                                  key={index}
                                                  selected={
                                                    item?.value ==
                                                    findd?.detention_free_time
                                                  }
                                                  value={item?.value}
                                                >
                                                  {item?.key}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </div>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <div
                                        style={{
                                          borderBottom: "2px solid #f1f1f1",
                                          marginBottom: 10,
                                        }}
                                      ></div>
                                    </Grid>
                                  </div>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </Accordion>
                    </FadeInComponent>
                  </div>
                );
              })}
            </div>
          ) : null}

          {/* additional requiments  */}
          <div>
            {PostLoad?.inquiry_type == 2 ? (
              <div className="titleSection customFontSize">
                Special Requirements (Applicable on all vehicles)
              </div>
            ) : null}

            {vehicles.map((val, index) => (
              <FadeInComponent>
                <CategoryContainer
                  value={val}
                  index={index}
                  title={load_type.title}
                  onChangeValue={(index, key: any, value: any) => {
                    vehicles[index][key] = value;
                    vehicles[index]["total_amount"] =
                      vehicles[index]["loading_price"] +
                      vehicles[index]["unloading_price"] +
                      vehicles[index]["price"];
                    setVehicles([...vehicles]);
                  }}
                  active={true}
                  categoryLength={vehicles?.length}
                />
              </FadeInComponent>
            ))}

            <FadeInComponent>
              <div
                className={
                  initialValues.is_advance_vehicle_driver_docs
                    ? "selectBoxWrapper"
                    : ""
                }
              >
                <SimpleCheckBox
                  id={"is_advance_vehicle_driver_docs"}
                  labelName="is_advance_vehicle_driver_docs"
                  name="is_advance_vehicle_driver_docs"
                  title="Advance Vehicle / Driver Documents "

                  handleChange={handleChange}
                  checked={initialValues.is_advance_vehicle_driver_docs}
                  iconComponent={
                    postLoadMeta?.additional_details_charges
                      ?.is_advance_vehicle_driver_docs ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            postLoadMeta?.additional_details_charges
                              ?.is_advance_vehicle_driver_docs
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />

                {initialValues.is_advance_vehicle_driver_docs && (
                  <div className="inputWrapper">
                    <div className="inputField">
                      <div className="label">
                        Enter Details of Documents Required
                      </div>
                      <div className="field">
                        <TextInput
                          onChange={(e) => {
                            if (e.target.value.length > 255) {
                              showMessage.current({
                                message: "Details of Documents should not be greater than 255 characters",
                                status: "error"
                              })
                            } else {
                              setInitialValues({
                                ...initialValues,
                                driver_requried_document_details: e.target.value,
                              });
                            }
                          }}
                          value={initialValues.driver_requried_document_details
                          }
                          type="text"
                          aria-describedby="standard-weight-helper-text"
                          placeholder={"License, Fitness, Vehicle Registration etc"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FadeInComponent>


            {load_type.title == LOAD_TYPE.fcl ? (
              <FadeInComponent>
                <div
                  className={
                    initialValues.is_container_deposit_by_transporter
                      ? "selectBoxWrapper"
                      : ""
                  }
                >
                  {load_type.title == LOAD_TYPE.fcl ? (
                    <SimpleCheckBox
                      id={"is_container_deposit_by_transporter"}
                      labelName="is_container_deposit_by_transporter"
                      title="Container Deposit By Transporter"
                      handleChange={handleChange}
                      checked={initialValues.is_container_deposit_by_transporter}
                      className="multicolortext"
                      iconComponent={
                        postLoadMeta?.additional_details_charges
                          ?.is_container_deposit_by_transporter ? (
                          <InfoIcon
                            onClick={() => {
                              setShowInfoWaterMark(
                                postLoadMeta?.additional_details_charges
                                  ?.is_container_deposit_by_transporter
                              );
                              setShowModalWaterMark(true);
                            }}
                          />
                        ) : null
                      }
                    />
                  ) : null}

                  {initialValues.is_container_deposit_by_transporter ? (
                    <div className="inputWrapper">
                      <div className="inputField">
                        <div className="label">Shipping Line Name</div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              if (e.target.value.length > 50) {
                                showMessage.current({
                                  message: "Shipping Line Name should not be greater than 50 characters",
                                  status: "error"
                                })
                              } else {
                                setInitialValues({
                                  ...initialValues,
                                  shipping_line_name: e.target.value,
                                });
                              }
                            }}
                            value={initialValues.shipping_line_name}
                            type="text"
                            aria-describedby="standard-weight-helper-text"
                            placeholder={"Optional"}
                          />
                        </div>
                      </div>

                      <div className="inputField">
                        <div className="label">Deposit Amount</div>
                        <div className="field">
                          <NewInput
                            onChange={(e) => {
                              setInitialValues({
                                ...initialValues,
                                container_deposit_amount: e.target.value,
                              });
                            }}
                            value={
                              initialValues.container_deposit_amount
                                ? initialValues.container_deposit_amount
                                : ""
                            }
                            showEnd={"PKR"}
                            placeholder={"Optional"}

                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </FadeInComponent>
            ) : null}

            {/* cross stuffing/ destuffing */}
            <FadeInComponent>
              <div
                className={
                  initialValues.is_cross_stuffing_required
                    ? "selectBoxWrapper"
                    : ""
                }
              >
                {load_type.title == LOAD_TYPE.fcl &&
                  PostLoad?.inquiry_type === 1 ? (
                  <SimpleCheckBox
                    id={"is_cross_stuffing_required"}
                    labelName="is_cross_stuffing_required"
                    title="Cross Stuffing / Local Shifting"
                    handleChange={handleChange}
                    checked={initialValues.is_cross_stuffing_required}
                    iconComponent={
                      postLoadMeta?.additional_details_charges
                        ?.is_cross_stuffing_required ? (
                        <InfoIcon
                          onClick={() => {
                            setShowInfoWaterMark(
                              postLoadMeta?.additional_details_charges
                                ?.is_cross_stuffing_required
                            );
                            setShowModalWaterMark(true);
                          }}
                        />
                      ) : null
                    }
                  />
                ) : null}

                <div className="inputWrapper">
                  {load_type.title == LOAD_TYPE.fcl &&
                    initialValues.is_cross_stuffing_required && (
                      <SimpleCheckBox
                        checked={
                          initialValues.is_cross_stuffing_box_vehicle_required
                        }
                        handleChange={handleChange}
                        labelName="is_cross_stuffing_box_vehicle_required"
                        id="is_cross_stuffing_box_vehicle_required"
                        title="Containerized / Box Vehicle Only"
                      />
                    )}
                </div>
              </div>
            </FadeInComponent>

            <FadeInComponent>
              {PostLoad?.load_type === 3 ? (
                <SimpleCheckBox
                  labelName="is_bonded_vehicle_required"
                  name="is_bonded_vehicle_required"
                  title="Bonded Vehicle Required"
                  id={"is_bonded_vehicle_required"}
                  handleChange={handleChange}
                  checked={initialValues.is_bonded_vehicle_required}
                  iconComponent={
                    postLoadMeta?.additional_details_charges
                      ?.is_bonded_vehicle_required ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            postLoadMeta?.additional_details_charges
                              ?.is_bonded_vehicle_required
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />
              ) : (
                <SimpleCheckBox
                  labelName="is_bonded_vehicle_required"
                  name="is_bonded_vehicle_required"
                  title="Custom Bonded Carrier"
                  id={"is_bonded_vehicle_required"}
                  handleChange={handleChange}
                  checked={initialValues.is_bonded_vehicle_required}
                  iconComponent={
                    postLoadMeta?.additional_details_charges
                      ?.is_bonded_vehicle_required ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            postLoadMeta?.additional_details_charges
                              ?.is_bonded_vehicle_required
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />
              )}
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_covid_certf_req_driver"}
                labelName="is_covid_certf_req_driver"
                name="is_covid_certf_req_driver"
                title="Driver Covid-19 Vaccination Required"
                handleChange={handleChange}
                checked={initialValues.is_covid_certf_req_driver}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_covid_certf_req_driver ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_covid_certf_req_driver
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_driver_negative_pcr_required"}
                labelName="is_driver_negative_pcr_required"
                name="is_driver_negative_pcr_required"
                title="Driver Negative PCR Required"
                handleChange={handleChange}
                checked={initialValues.is_driver_negative_pcr_required}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_driver_negative_pcr_required ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_driver_negative_pcr_required
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                labelName="is_hse_compliant_vehicle"
                name="is_hse_compliant_vehicle"
                title={"HSE Compliant Vehicle"}
                id={"is_hse_compliant_vehicle"}
                handleChange={handleChange}
                checked={initialValues.is_hse_compliant_vehicle}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_hse_compliant_vehicle ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_hse_compliant_vehicle
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            {
              PostLoad?.load_type == 3 ?
                <>
                  <FadeInComponent>
                    <SimpleCheckBox
                      id={"is_tarpaulin_plastic_cover"}
                      labelName="is_tarpaulin_plastic_cover"
                      name="is_tarpaulin_plastic_cover"
                      title="Tarpaulin / Plastic Cover"
                      handleChange={handleChange}
                      checked={initialValues.is_tarpaulin_plastic_cover}
                      iconComponent={
                        postLoadMeta?.additional_details_charges
                          ?.is_tarpaulin_plastic_cover ? (
                          <InfoIcon
                            onClick={() => {
                              setShowInfoWaterMark(
                                postLoadMeta?.additional_details_charges
                                  ?.is_tarpaulin_plastic_cover
                              );
                              setShowModalWaterMark(true);
                            }}
                          />
                        ) : null
                      }
                    />
                  </FadeInComponent>

                  <FadeInComponent>
                    <div
                      className={
                        initialValues.is_under_hook_delivery_required
                          ? "selectBoxWrapper"
                          : ""
                      }
                    >
                      <SimpleCheckBox
                        labelName="is_under_hook_delivery_required"
                        name="is_under_hook_delivery_required"
                        title="Underhook / Direct Delivery Required"
                        id={"is_under_hook_delivery_required"}
                        handleChange={handleChange}
                        checked={initialValues.is_under_hook_delivery_required}
                        iconComponent={
                          postLoadMeta?.additional_details_charges
                            ?.is_under_hook_delivery_required ? (
                            <InfoIcon
                              onClick={() => {
                                setShowInfoWaterMark(
                                  postLoadMeta?.additional_details_charges
                                    ?.is_under_hook_delivery_required
                                );
                                setShowModalWaterMark(true);
                              }}
                            />
                          ) : null
                        }
                      />
                      {initialValues.is_under_hook_delivery_required && (
                        <div className="inputWrapper">
                          <div className="inputField">
                            <div className="label">Package No.</div>
                            <div className="field">
                              <TextInput
                                onChange={(e) => {
                                  if (e.target.value.length > 255) {
                                    showMessage.current({
                                      message: "Details of Package No. should not be greater than 255 characters",
                                      status: "error"
                                    })
                                  } else {
                                    setInitialValues({
                                      ...initialValues,
                                      under_hook_delivery: e.target.value,
                                    });
                                  }
                                }}
                                value={initialValues.under_hook_delivery}
                                type="text"
                                aria-describedby="standard-weight-helper-text"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </FadeInComponent>

                </> : null
            }

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_vehicle_tracking"}
                labelName="is_vehicle_tracking"
                name="is_vehicle_tracking"
                title="Vehicle Tracking"
                handleChange={handleChange}
                checked={initialValues.is_vehicle_tracking}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_vehicle_tracking ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_vehicle_tracking
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <div
                className={
                  initialValues.is_weightment_received_required
                    ? "selectBoxWrapper"
                    : ""
                }
              >
                <SimpleCheckBox
                  labelName="is_weightment_received_required"
                  name="is_weightment_received_required"
                  title="Weighment Required"
                  id={"is_weightment_received_required"}
                  handleChange={handleChange}
                  checked={initialValues.is_weightment_received_required}
                  iconComponent={
                    postLoadMeta?.additional_details_charges
                      ?.is_weightment_received_required ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            postLoadMeta?.additional_details_charges
                              ?.is_weightment_received_required
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />
                {initialValues.is_weightment_received_required && (
                  <div className="inputWrapper">
                    <div className="inputField">
                      <div className="label">Weighment Bridge Name</div>
                      <div className="field">
                        <TextInput
                          onChange={(e) => {
                            if (e.target.value.length > 50) {
                              showMessage.current({
                                message: "Weighment Bridge Name should not be greater than 50 characters",
                                status: "error"
                              })
                            } else {
                              setInitialValues({
                                ...initialValues,
                                weightment_received_name: e.target.value,
                              });
                            }
                          }}
                          value={initialValues.weightment_received_name}
                          type="text"
                          aria-describedby="standard-weight-helper-text"
                          placeholder="(Optional)"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FadeInComponent>

          </div>
        </div>

        {/* continue button  */}
        <div style={{ marginTop: 20 }}>
          {PostLoad?.contractDetails?.quote_by == "weight_volume" ? (
            <PrimaryButton
              rightIcon={true}
              title="CONTINUE"
              type="submit"
              disabled={
                (initialValues?.is_under_hook_delivery_required ?
                  initialValues?.under_hook_delivery?.length >= 1 ? false : true : false) ||
                handleDisabled()
              }
              onClick={() => {
                handleSubmit();
              }}
            />
          ) : (
            <PrimaryButton
              rightIcon={true}
              title="CONTINUE"
              type="submit"
              disabled={
                (initialValues?.is_under_hook_delivery_required ?
                  initialValues?.under_hook_delivery?.length >= 1 ? false : true : false) ||
                handleDisable2()
              }
              onClick={() => {
                handleSubmit();
              }}
            />
          )}
        </div>

        {/* info modal  */}
        <CustomModal
          modalStyle={{
            width: 300,
            minHeight: 100,
          }}
          className="infoModal"
          showModal={showModal}
          toggleModal={toggleModal}
        >
          <div>
            <InfoIcon className="icon" />
            <p className="title">Information</p>
            <p>
              {info}
            </p>
          </div>
        </CustomModal>

        {/* show info icon watermark */}
        <CustomModal
          modalStyle={{
            width: 300,
            minHeight: 100,
          }}
          className="infoModal"
          showModal={showModalWaterMark}
          toggleModal={setShowModalWaterMark}
        >
          <div>
            <InfoIcon className="icon" />
            <p className="title">Information</p>
            <p>
              {showInfoWaterMark}
            </p>
          </div>
        </CustomModal>
      </div>
    </TitleLayout>
  );
};
