import { CustomModal, CustomText, NewInput, PrimaryButton } from "@temp/components";
import React, { useState } from "react";
import "../LoadDetailPoster/style.scss";
import InfoIcon from "@mui/icons-material/Info";
import { RecipientPrices, RecipientStatus } from "../components";
import { NeotiateBidAcceptReject } from "../components/NegotiateBidAcceptReject";
import { RespondVehicle } from "./RespondVehicle";
import { LOAD_TYPE } from "@temp/constants";
var numeral = require("numeral");

export const RespondedStatusVehile = ({
    alreadyResponded,
    is_deadline,
    category,
    loadDetail,
    is_negotiate_bid,
    set_contract_locations,
    contract_locations,
    responded,
    setResponded,
    respond
}) => {

    const [info, setInfo] = useState('')
    const [showModal, toggleModal] = useState(false)
    let bidData = alreadyResponded?.bid
    return (
        <div style={{
            marginTop: 10
        }}>
            {(alreadyResponded && alreadyResponded?.status !== 1 || !is_deadline) ? (
                <div>
                    {
                        alreadyResponded && (
                            <div className="fw-5">
                                <span className="titleName">Vehicles Response</span>
                                <span className="seperator">/</span>
                                <span className="urduLabel">گاڑیوں کا جواب</span>
                            </div>
                        )
                    }
                    <div style={{ marginTop: 5 }} className="flexRow-class">
                        {
                            alreadyResponded ?
                                <RecipientStatus from={'recipient'} status={alreadyResponded?.status ? alreadyResponded?.status : 0} loadDetail={loadDetail} decline_by={alreadyResponded?.declined_by} is_recipient={true} /> : null
                        }
                        {
                            alreadyResponded && alreadyResponded?.status !== 2 &&
                            <div style={{ display: 'flex' }}>
                                <div className="flexRow">
                                    <>
                                        <div className="chip">
                                            {
                                                loadDetail?.is_unit_basis ?
                                                    numeral(alreadyResponded?.bid?.bid_price_per_vehicle).format("0,0.00") + " PKR " + " / Item" :
                                                    numeral(alreadyResponded?.bid?.bid_price_per_vehicle).format("0,0") + " PKR " + " / Vehicle"}

                                            {/* numeral(alreadyResponded?.bid?.bid_price_per_vehicle).format("0,0") + " PKR"}
                                            /
                                            {loadDetail?.is_unit_basis ? "Item" : "Vehicle"} */}
                                            {/* <span className="seperator">/</span> */}
                                            <div></div>
                                            <span className="urduLabel">
                                                {
                                                    `${loadDetail?.is_unit_basis ? "فی آئٹم" : "فی گاڑی"} /PKR ${numeral(alreadyResponded?.bid?.bid_price_per_vehicle).format("0,0")}`
                                                }
                                            </span>
                                        </div>
                                    </>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        alreadyResponded?.status !== 2 &&
                        <RecipientPrices
                            no_of_vehicles={category?.vehicle_quantity || null}
                            no_of_freight={null}
                            price_per_vehicle={bidData?.bid_price_per_vehicle}
                            price_per_freight={null}
                            loading_price={bidData?.bid_price_loading || null}
                            unloading_price={bidData?.bid_price_unloading || null}
                            detention_rate={bidData?.bid_detention_rate}
                            is_unit_basis={loadDetail?.is_unit_basis}
                            is_negotiate_bid={bidData?.bid_negotiated_amount}
                            from={'contract'}
                            item={category}
                            loadDetail={loadDetail}
                            detention_tarif_policy={bidData?.detention_tarif_policy}
                            bid_detention_additional_charges={bidData?.bid_detention_additional_charges}

                        />
                    }
                    {
                        is_negotiate_bid ?
                            <NeotiateBidAcceptReject loadDetail={loadDetail} bid={is_negotiate_bid} /> : null
                    }
                </div>
            ) : (
                <div>
                    {category?.my_bid ? (
                        <div>
                            <div className="titleSection mb-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                <div className="fw-5">
                                    <span className="titleName">Enter Bid</span>
                                    <span className="seperator">/</span>
                                    <span className="urduLabel">بولی درج کریں</span>
                                </div>
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        category['my_bid'] = null
                                        set_contract_locations([...contract_locations])
                                        let new_respond = [...responded];
                                        let findIndex = responded.findIndex((itm) => itm.job_load_category_id == category?.id);
                                        new_respond.splice(findIndex, 1);
                                        setResponded(new_respond);
                                    }}
                                >
                                    <span className="icons-cross color-danger"></span>
                                </div>
                            </div>
                            <div className="inputField">
                                <div className="label divBlockComplete">
                                    <div className="divBlock" style={{ width: '100%' }}>
                                        <span className="titleName">Price / {loadDetail?.is_unit_basis ? "Item" : 'Vehicle'} (Without GST)</span>
                                        <span className="seperator">/</span>
                                    </div>
                                    <div className="divBlock">
                                        <span className="urduLabel">ریٹ / {loadDetail?.is_unit_basis ? "فی آئٹم" : 'فی گاڑی'} (GST کے بغیر)</span>
                                    </div>
                                    <InfoIcon onClick={() => {
                                        setInfo('Price is inclusive of Free Time.')
                                        toggleModal(true)
                                    }}
                                    />
                                </div>


                                <div className="field">
                                    <RespondVehicle
                                        my_bid={{ key: "bid_price_per_vehicle", value: category?.my_bid?.bid_price_per_vehicle, job_load_category_id: category?.id }}
                                        setResponded={setResponded}
                                        responded={responded}
                                        type={'number'}
                                        decimal={loadDetail?.is_unit_basis ? true : false}
                                    />
                                </div>
                            </div>


                            {/* detention price  */}

                            <div className="inputField">
                                <div className="label divBlockComplete">
                                    <div className="divBlock" style={{ width: '100%' }}>
                                        <span className="titleName">Detention Rate (without GST)</span>
                                        <span className="seperator">/</span>
                                    </div>
                                    <div className="divBlock">
                                        <span className="urduLabel">ڈیٹنشن ریٹ (GST کے بغیر)</span>
                                    </div>
                                    <InfoIcon onClick={() => {
                                        setInfo('Please enter daily Detention Rates only for applicable vehicle categories.')
                                        toggleModal(true)
                                    }} />
                                </div>
                                <div className="field">
                                    <RespondVehicle
                                        my_bid={{ key: "bid_detention_rate", value: category?.my_bid?.bid_detention_rate, job_load_category_id: category?.id }}
                                        setResponded={setResponded}
                                        responded={responded}
                                        type={'number'}
                                        placeholder={'Optional'}
                                        decimal={loadDetail?.is_unit_basis ? true : false}
                                    />
                                </div>
                            </div>

                            {category?.is_unit_basis ?
                                <div className="inputField">
                                    <div className="label divBlockComplete">
                                        <div className="divBlock" style={{ width: '100%' }}>
                                            <span className="titleName">Detention Tariff & Policy</span>
                                            <span className="seperator">/</span>
                                        </div>
                                        <div className="divBlock">
                                            <span className="urduLabel">ڈیٹنشن ٹیرف اور پالیسی</span>
                                        </div>

                                    </div>
                                    <div className="textareaWrapper">
                                        <RespondVehicle
                                            my_bid={{ key: "detention_tarif_policy", value: category?.my_bid?.detention_tarif_policy, job_load_category_id: category?.id }}
                                            setResponded={setResponded}
                                            responded={responded}
                                            type={'textarea'}
                                            placeholder={'Optional'}

                                        />
                                    </div>
                                </div>
                                : null}

                            {/* for twin load */}
                            {category?.is_twin_load && loadDetail?.load_type?.title == LOAD_TYPE.fcl ?
                                <>
                                    <div className="inputField">
                                        <div className="label divBlockComplete">
                                            <div className="divBlock" style={{ width: '100%' }}>
                                                <span className="titleName">Price / Container (Without GST)</span>
                                                <span className="seperator">/</span>
                                            </div>
                                            <div className="divBlock">
                                                <span className="urduLabel">ریٹ / فی کنٹینر (GST کے بغیر)</span>
                                            </div>
                                            {/* <InfoIcon onClick={() => {
                                                setInfo('Price is inclusive of Free Time.')
                                                toggleModal(true)
                                            }}
                                            /> */}
                                        </div>
                                        <div className="field priceFieldDisabled customDisableClass">
                                            <NewInput
                                                value={responded?.find((itm) => itm?.job_load_category_id == category?.id)?.bid_price_per_vehicle / 2}
                                                showEnd={"PKR"}
                                                disabled={true}
                                                aria-describedby="standard-weight-helper-text"
                                            />
                                            {/* {
                                                responded?.find((itm)=>itm?.job_load_category_id == category?.id)?.bid_price_per_vehicle/2
                                            } */}
                                        </div>
                                    </div>
                                </> : null}


                            {
                                // (loadDetail?.load_type?.title == LOAD_TYPE.bulk) &&
                                category?.is_loading_required ? (
                                    <div className="inputField">
                                        <div className="label divBlockComplete">
                                            <div className="divBlock" style={{ width: '100%' }}>
                                                <span className="titleName">Loading Price / {loadDetail?.is_unit_basis ? "Item" : 'Vehicle'} (without GST)</span>
                                                <span className="seperator">/</span>
                                            </div>
                                            <div className="divBlock">
                                                <span className="urduLabel">لوڈنگ ریٹ / {loadDetail?.is_unit_basis ? "فی آئٹم" : 'فی گاڑی'} (GST کے بغیر)</span>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <RespondVehicle
                                                my_bid={{ key: "bid_price_loading", value: category?.my_bid?.bid_price_loading, job_load_category_id: category?.id }}
                                                setResponded={setResponded}
                                                responded={responded}
                                                type={'number'}
                                                decimal={loadDetail?.is_unit_basis ? true : false}
                                            />
                                        </div>
                                    </div>
                                ) : null}

                            {
                                // (loadDetail?.load_type?.title == LOAD_TYPE.bulk) &&
                                category?.is_unloading_required ? (
                                    <div className="inputField">
                                        <div className="label divBlockComplete">
                                            <div className="divBlock" style={{ width: '100%' }}>
                                                <span className="titleName">Unloading Price / {loadDetail?.is_unit_basis ? "Item" : 'Vehicle'} (without GST)</span>
                                                <span className="seperator">/</span>
                                            </div>
                                            <div className="divBlock">
                                                <span className="urduLabel">ان لوڈنگ ریٹ / {loadDetail?.is_unit_basis ? "فی آئٹم" : 'فی گاڑی'} (GST کے بغیر)</span>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <RespondVehicle
                                                my_bid={{ key: "bid_price_unloading", value: category?.my_bid?.bid_price_unloading, job_load_category_id: category?.id }}
                                                setResponded={setResponded}
                                                responded={responded}
                                                type={'number'}
                                                decimal={loadDetail?.is_unit_basis ? true : false}
                                            />
                                        </div>
                                    </div>) : null}

                            {/* other additional charges */}
                            <div className="inputField">
                                <div className="label divBlockComplete">
                                    <div className="divBlock" style={{ width: '100%' }}>
                                        <span className="titleName">Other Additional Charges (if applicable) </span>
                                        <span className="seperator">/</span>
                                    </div>
                                    <div className="divBlock">
                                        <span className="urduLabel">اضافی چارجز ( اگر لاگو ہوں )</span>
                                    </div>
                                    <InfoIcon onClick={() => {
                                        setInfo('Please enter other charges that are applicable to the request. Where category specific, please identify which categories they apply to.')
                                        toggleModal(true)
                                    }} />
                                </div>
                                <div className="field">
                                    <RespondVehicle
                                        my_bid={{ key: "bid_detention_additional_charges", value: category?.my_bid?.bid_detention_additional_charges, job_load_category_id: category?.id }}
                                        setResponded={setResponded}
                                        responded={responded}
                                        type={'textarea'}
                                    />
                                </div>
                            </div>
                            {
                                is_negotiate_bid ?
                                    <NeotiateBidAcceptReject loadDetail={loadDetail} bid={is_negotiate_bid} /> : null
                            }
                        </div>
                    ) : (
                        <div>
                            {
                                is_deadline ?
                                    <PrimaryButton
                                        buttonStyle={{
                                            height: 44,
                                            marginBottom: '1rem'
                                        }}
                                        title="Respond"
                                        is_recipient={true}
                                        urdutitle={"جواب دیں"}
                                        onClick={() => {
                                            category["my_bid"] = {
                                                job_load_category_id: category?.id,
                                                no_of_vehicles: "",
                                                bid_price_per_vehicle: "",
                                                bid_price_loading: "",
                                                bid_price_unloading: "",
                                                bid_detention_rate: "",
                                                loading: category?.is_loading_required ? true : false,
                                                unloading: category?.is_unloading_required ? true : false,
                                                detention_tarif_policy: "",
                                            }
                                            set_contract_locations([...contract_locations])
                                            // return
                                            respond(category);
                                        }}
                                    /> : null
                            }
                        </div>
                    )}
                </div>
            )
            }

            {/* info modal  */}
            <CustomModal
                modalStyle={{
                    width: 300,
                    minHeight: 100,
                }}
                showModal={showModal}
                toggleModal={toggleModal}
            >
                <div
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <InfoIcon color="primary" style={{ fontSize: 40 }} />
                    <CustomText.OpenSansRegular>{info}</CustomText.OpenSansRegular>
                </div>
            </CustomModal>
        </div >
    )
};
