import React, { FC } from "react";
import Button from "@mui/material//Button";
import Dialog from "@mui/material//Dialog";
import DialogActions from "@mui/material//DialogActions";
import DialogContent from "@mui/material//DialogContent";
import DialogContentText from "@mui/material//DialogContentText";
import Paper, { PaperProps } from "@mui/material//Paper";
import { IProps } from "./type";
function PaperComponent(props: PaperProps) {
  return <Paper {...props} />;
}

export const DraggableDialog: FC<IProps> = ({
  open,
  handleClose,
  text,
  handleContinue,
}: IProps) => (
  <div>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          style={{ color: "rgb(122, 194, 81)" }}
        >
          Cancel
        </Button>
        <Button onClick={handleContinue}>Continue</Button>
      </DialogActions>
    </Dialog>
  </div>
);
