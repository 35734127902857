import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { AuthAction, DrawerAction } from "../actions";
var INITIAL_STATE = {
  activeDrawerTab: "",
  notificationsData: [],
  networkDataCopy: [],
  networkData: [],
  locationsData: [],
  vehicleData: [],
  loadDraftData: [],
  addNetworkData: [],
  searchNetworkData: [],
  editVehicleData: [],
  deleteVehicleData: [],
  account_balance: [],
  account_data_current_page: 1,
  account_data_last_page: 1,
  balance: "",
  deleteNetworkData: [],
  isLoading: false,
  faqs: [],
  promotions: [],
  promotionDetail: null,
  notifications_data_current_page: 1,
  notifications_data_last_page: 1
};
function Reducer() {
  var _action$payload, _action$payload2, _action$payload3, _action$payload3$invo, _action$payload4, _action$payload4$invo, _action$payload5, _action$payload5$invo, _action$payload6, _action$payload7, _action$payload8, _action$payload9, _action$payload10, _action$payload11, _action$payload11$met, _action$payload12, _action$payload12$met, _action$payload13, _action$payload13$loa;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    // get network

    case DrawerAction.ADD_OTP_NETWORK:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.ADD_OTP_NETWORK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.ADD_OTP_NETWORK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case AuthAction.TOGGLE_LOADER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.DELETE_LOCATION[0]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.DELETE_LOCATION[1]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.DELETE_LOCATION[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.ADD_LOCATION[0]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.ADD_LOCATION[1]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.ADD_LOCATION[2]:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.GET_NETWORK:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true,
        searchNetworkData: []
      });
    case DrawerAction.GET_NETWORK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        networkData: (action === null || action === void 0 ? void 0 : action.payload) || [],
        networkDataCopy: (action === null || action === void 0 ? void 0 : action.payload) || [],
        isLoading: false
      });
    case DrawerAction.GET_NETWORK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.GET_ACCOUNT_BALANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        account_balance: action !== null && action !== void 0 && (_action$payload = action.payload) !== null && _action$payload !== void 0 && _action$payload.refresh ? [] : _toConsumableArray(state.account_balance),
        isLoading: action === null || action === void 0 ? void 0 : (_action$payload2 = action.payload) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.refresh
      });
    case DrawerAction.GET_ACCOUNT_BALANCE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        account_balance: [].concat(_toConsumableArray(state === null || state === void 0 ? void 0 : state.account_balance), _toConsumableArray(action === null || action === void 0 ? void 0 : (_action$payload3 = action.payload) === null || _action$payload3 === void 0 ? void 0 : (_action$payload3$invo = _action$payload3.invoices) === null || _action$payload3$invo === void 0 ? void 0 : _action$payload3$invo.data)),
        account_balance_current_page: action === null || action === void 0 ? void 0 : (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : (_action$payload4$invo = _action$payload4.invoices) === null || _action$payload4$invo === void 0 ? void 0 : _action$payload4$invo.meta.current_page,
        account_balance_last_page: action === null || action === void 0 ? void 0 : (_action$payload5 = action.payload) === null || _action$payload5 === void 0 ? void 0 : (_action$payload5$invo = _action$payload5.invoices) === null || _action$payload5$invo === void 0 ? void 0 : _action$payload5$invo.meta.last_page,
        balance: action === null || action === void 0 ? void 0 : (_action$payload6 = action.payload) === null || _action$payload6 === void 0 ? void 0 : _action$payload6.balance,
        isLoading: false
      });
    case DrawerAction.GET_ACCOUNT_BALANCE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // get saved locations

    case DrawerAction.GET_SAVED_LOCATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.GET_SAVED_LOCATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        locationsData: action.payload,
        isLoading: false
      });
    case DrawerAction.GET_SAVED_LOCATIONS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // get vehicle selector

    case DrawerAction.GET_VEHICLE_SELECTOR:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.GET_VEHICLE_SELECTOR_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        vehicleData: action.payload,
        isLoading: false
      });
    case DrawerAction.GET_VEHICLE_SELECTOR_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    //get notifications

    case DrawerAction.GET_NOTIFICATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        notificationsData: action !== null && action !== void 0 && (_action$payload7 = action.payload) !== null && _action$payload7 !== void 0 && _action$payload7.refresh || action !== null && action !== void 0 && (_action$payload8 = action.payload) !== null && _action$payload8 !== void 0 && _action$payload8.fromSearch ? [] : _toConsumableArray(state.notificationsData),
        isLoading: action === null || action === void 0 ? void 0 : (_action$payload9 = action.payload) === null || _action$payload9 === void 0 ? void 0 : _action$payload9.refresh
        // isLoading: true 
      });
    case DrawerAction.GET_NOTIFICATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        notificationsData: [].concat(_toConsumableArray(state === null || state === void 0 ? void 0 : state.notificationsData), _toConsumableArray(action === null || action === void 0 ? void 0 : (_action$payload10 = action.payload) === null || _action$payload10 === void 0 ? void 0 : _action$payload10.data)),
        notifications_data_current_page: action === null || action === void 0 ? void 0 : (_action$payload11 = action.payload) === null || _action$payload11 === void 0 ? void 0 : (_action$payload11$met = _action$payload11.meta) === null || _action$payload11$met === void 0 ? void 0 : _action$payload11$met.current_page,
        notifications_data_last_page: action === null || action === void 0 ? void 0 : (_action$payload12 = action.payload) === null || _action$payload12 === void 0 ? void 0 : (_action$payload12$met = _action$payload12.meta) === null || _action$payload12$met === void 0 ? void 0 : _action$payload12$met.last_page,
        isLoading: false

        // new added
        // notificationsData: [...state?.notificationsData, ...action?.payload?.data],
        // notifications_data_current_page: action?.payload?.query
        //   ? 1
        //   : action?.payload?.meta.current_page,
        // notifications_data_last_page: action?.payload?.query
        //   ? 1
        //   : action?.payload?.meta.last_page,
        // isLoading: false
      });
    case DrawerAction.GET_NOTIFICATIONS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    //get load draft

    case DrawerAction.GET_LOAD_DRAFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.GET_LOAD_DRAFT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadDraftData: (_action$payload13 = action.payload) === null || _action$payload13 === void 0 ? void 0 : (_action$payload13$loa = _action$payload13.loads) === null || _action$payload13$loa === void 0 ? void 0 : _action$payload13$loa.data,
        isLoading: false
      });
    case DrawerAction.GET_LOAD_DRAFT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.ADD_NETWORK:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.ADD_NETWORK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.ADD_NETWORK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.GET_FAQS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.GET_FAQS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        faqs: action.payload
      });
    case DrawerAction.GET_FAQS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.GET_PROMOTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.GET_PROMOTIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        promotions: action.payload
      });
    case DrawerAction.GET_PROMOTIONS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.GET_PROMOTION_DETAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.GET_PROMOTION_DETAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        promotionDetail: action.payload
      });
    case DrawerAction.GET_PROMOTION_DETAIL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.SEARCH_NETWORK:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.SEARCH_NETWORK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchNetworkData: action.payload,
        isLoading: false
      });
    case DrawerAction.SEARCH_NETWORK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        searchNetworkData: []
      });
    case DrawerAction.ADD_VEHICLE_SELECTOR:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.ADD_VEHICLE_SELECTOR_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.ADD_VEHICLE_SELECTOR_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.SEARCH_MY_NETWORK:
      var new_data = _toConsumableArray(state.networkDataCopy);
      var final_data = new_data.filter(function (item) {
        var _item$networkUser, _item$networkUser2, _action$payload14, _item$networkUser3, _item$networkUser4, _item$networkUser5, _item$networkUser6, _item$networkUser6$co, _action$payload15;
        return (item === null || item === void 0 ? void 0 : (_item$networkUser = item.networkUser) === null || _item$networkUser === void 0 ? void 0 : _item$networkUser.name.toLowerCase().includes(action.payload.toLowerCase())) || (item === null || item === void 0 ? void 0 : (_item$networkUser2 = item.networkUser) === null || _item$networkUser2 === void 0 ? void 0 : _item$networkUser2.phone_no.includes((_action$payload14 = action.payload) === null || _action$payload14 === void 0 ? void 0 : _action$payload14.replace(/^0+/, ""))) || (item === null || item === void 0 ? void 0 : (_item$networkUser3 = item.networkUser) === null || _item$networkUser3 === void 0 ? void 0 : _item$networkUser3.company) && (item === null || item === void 0 ? void 0 : (_item$networkUser4 = item.networkUser) === null || _item$networkUser4 === void 0 ? void 0 : _item$networkUser4.company.length) && (item === null || item === void 0 ? void 0 : (_item$networkUser5 = item.networkUser) === null || _item$networkUser5 === void 0 ? void 0 : _item$networkUser5.company[0].name.toLowerCase().includes(action.payload.toLowerCase())) || (item === null || item === void 0 ? void 0 : (_item$networkUser6 = item.networkUser) === null || _item$networkUser6 === void 0 ? void 0 : (_item$networkUser6$co = _item$networkUser6.company_name) === null || _item$networkUser6$co === void 0 ? void 0 : _item$networkUser6$co.toLowerCase().includes(action === null || action === void 0 ? void 0 : (_action$payload15 = action.payload) === null || _action$payload15 === void 0 ? void 0 : _action$payload15.toLowerCase()));
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        networkData: final_data
      });

    //edit vehicle data

    case DrawerAction.EDIT_VEHICLE_SELECTOR:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.EDIT_VEHICLE_SELECTOR_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.EDIT_VEHICLE_SELECTOR_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // delete vehicle data
    case DrawerAction.DELETE_VEHICLE_SELECTOR:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.DELETE_VEHICLE_SELECTOR_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.DELETE_VEHICLE_SELECTOR_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    // delete network
    case DrawerAction.DELETE_NETWORK:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case DrawerAction.DELETE_NETWORK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case DrawerAction.DELETE_NETWORK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    default:
      return state;
  }
}
export default Reducer;