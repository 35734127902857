import React, { FC } from "react";
// import { withStyles } from "@mui/material//styles";
import FormControlLabel from "@mui/material//FormControlLabel";
import Checkbox, { CheckboxProps } from "@mui/material//Checkbox";
import { IProps } from "./types";
import InfoIcon from "@mui/icons-material/Info";

// const GreenCheckbox = withStyles({
//   root: {
//     color: "rgb(204 204 204)",

//     "&$checked": {
//       color: "rgb(122 194 81)",
//     },
//   },
//   checked: {},
// })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const SimpleCheckBox: FC<IProps> = (props: IProps) => {
  const show_icon =
    // props?.labelName == "is_cross_stuffing_required" ||
    props?.labelName == "detention_included_transport";
  const is_detention = props?.labelName == "detention_included_transport";
  return (
    <div
      className={`${props?.title ? "simpleCheckbox" : ""} ${
        props?.checked ? "checked" : ""
      }`}
      style={{...props?.style}}
    >
      <FormControlLabel
        control={
          <Checkbox
            id={props?.id}
            onChange={props?.handleChange}
            checked={props?.checked}
            name={props?.labelName}
            disabled={props?.disabled}
            // defaultChecked={props?.defaultChecked}
            inputProps={props?.inputProps}
          />
        }
        label={
          <div className="label">
            {props.icon && (
              <img
                style={{ marginRight: 6 }}
                src={props.icon}
                alt=""
                width="20"
                height="20"
              />
            )}
            <div>{props?.title}</div>
          </div>
        }
      />

      {show_icon ? (
        <InfoIcon
          onClick={() => {
            if (props?.labelName == "detention_included_transport") {
              props?.setInfo(
                "1 day Mandatory Detention should be selected in cases where detention is applicable due to road restrictions, after expiry of free period."
              );
              props?.toggleModal(true);
            } else {
              // props?.setInfoCross(
              //   "Price will be inclusive of shifting & local & upcountry transport charges. Part Load cannot be awarded if Cross Stuffing selected."
              // );
              // props?.toggleModalCrossStuffing(true);
            }
          }}
          className="icon"
        />
      ) : null}

      {props.iconComponent ? props.iconComponent : null}
    </div>
  );
};
export default SimpleCheckBox;
