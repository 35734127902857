import * as React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Grid,
} from "@mui/material/";
import { ExitToAppRounded, ExpandMore } from "@mui/icons-material";

import { useSelector } from "react-redux";
import { OutLineButton } from "@temp/components";

export const Maincontainer = styled.div<{ selected: boolean }>`
  width: 99%;
  background-color: #fafafa;
  margin-bottom: 17px;
  border-radius: 10px;
`;

export const PaymentTerms = ({
  editable,
  fromHistory,
  is_recipient,
  loadDetail

}) => {
  const FormRow = ({ label, value, sep, urdutitle, is_recipient, fromHistory }) => {
    return (
      <Grid item xs={12} className="formGrid">
        <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
          <span className="titleName">{label}</span>
          {
            fromHistory && is_recipient &&
            <>
              <span className="seperator">
                {sep}
              </span>
              <span className="urduLabel lhEdit">
                {urdutitle}
              </span>
            </>
          }
        </div>
        <div className="fw-5">{value}</div>
      </Grid>
    );
  };
  const PostLoad = useSelector((state) => state?.Profile?.postLoad);
  const metaData = useSelector((state) => state.Auth.metaData);

  const history = useHistory();
  const onEdit = () => {
    history.push("/payment-terms");
  };
  const {
    PaymentScreen: {
      pt_advance_value,
      pt_is_ondelivery,
      pt_ondelivery_value,
      pt_is_credit,
      pt_credit_percentage,
      pt_credit_days,
      is_pt_fuel_card,
      pt_fuel_card_value,
      is_gst_inclusive,
      is_withholding_tax_inclusive,
      pt_is_advance,
    },
  } = PostLoad;
  return (
    <>
      <Accordion className="confirm-load-accordion" style={{ backgroundColor: "#FAFAFA" }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className="fw-6">
            Payment Terms
            {
              fromHistory && is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">پیمنٹ کی تفصیلات</span>
              </>
            }
          </p>

        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} style={{ overflowY: "scroll" }}>
            {pt_is_advance && (
              <FormRow
                label="Advance"
                value={
                  pt_is_advance ? (pt_advance_value || "0") + " %" : "0%"
                }
                sep="/"
                urdutitle={"ايڈوانس"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}

              />
            )}
            {pt_is_ondelivery && (
              <FormRow
                label="On Delivery"
                value={
                  pt_is_ondelivery
                    ? (pt_ondelivery_value || "0") + " %"
                    : "0%"
                }
                sep="/"
                urdutitle={"پہنچانے پر"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
            )}
            {pt_is_credit && (
              <FormRow
                label="Credit"
                value={
                  (pt_is_credit
                    ? (pt_credit_percentage || "0") + " %"
                    : "0%") +
                  " - " +
                  (pt_credit_days
                    ? (pt_credit_days || "0") +
                    (pt_credit_days > 1 ? " Days" : " Day ")
                    : "0 Day")
                }
                sep="/"
                urdutitle={"کریڈٹ"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
            )}
            {is_pt_fuel_card && pt_fuel_card_value > 0 ? (
              <FormRow
                label="Fuel Card"
                value={
                  is_pt_fuel_card ? (pt_fuel_card_value || "0") + " %" : "0%"
                }
                sep="/"
                urdutitle={"فیول کارڈ"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
            ) : null}

            {is_gst_inclusive || is_withholding_tax_inclusive ? (
              <Grid xs={12} item>
                <div
                  className="fw-6"
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                >
                  Tax
                  {
                    fromHistory && is_recipient &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel" style={{lineHeight: '1.5rem',}}>ٹیکس</span>
                    </>
                  }
                </div>
              </Grid>
            ) : null}

            {is_gst_inclusive ?
              <FormRow
                label="Sales Tax Invoice Required"
                // value={is_gst_inclusive ? "YES" : "NO"}
                value={is_gst_inclusive ? PostLoad?.PaymentScreen?.sales_tax_province : null}
                sep="/"
                urdutitle={"سیلز ٹیکس رسید درکار ہے۔"}
                is_recipient={is_recipient}
                fromHistory={fromHistory}
              />
              : null}

            {/* {is_gst_inclusive ?
              <>
                <FormRow
                  label="Sales Tax Invoice:"
                  value={PostLoad?.PaymentScreen?.sales_tax_province}
                  sep="/"
                  urdutitle={"سیلز ٹیکس رسید (ادارہ)"}
                  is_recipient={is_recipient}
                  fromHistory={fromHistory}
                />
              </> : null
            } */}

            {is_withholding_tax_inclusive ?
              <FormRow
                label=
                { fromHistory ? `Rate inclusive of ${loadDetail?.tax_wht}% WHT` :
                  `Rate inclusive of ${metaData?.tax_wht}% WHT`
                }
                // value={is_withholding_tax_inclusive ? "YES" : "NO"}
                value={""}
                sep="/"
                // urdutitle={`${metaData?.tax_wht}% ریٹ WHT کے ساتھ ہو`}
                urdutitle={fromHistory ?`${loadDetail?.tax_wht}% ریٹ WHT کے ساتھ ہو` : `${metaData?.tax_wht}% ریٹ WHT کے ساتھ ہو`}

                is_recipient={is_recipient}
                fromHistory={fromHistory}
              /> : null}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PaymentTerms;
