import React, { useState } from "react";
import './style.scss'
import { PhoneInputProps } from './types'
export const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  placeholder,
  onChange,
  disabled,
  id,
  phoneCode,
  maxLength = 10,
  onPaste
}) => {
  const [inputClass, setInputClass] = useState('')



  return (
    <div className="phone-input-wrapper">
      <span className={`phone-input-code ${value ? "phone-input-focused" : inputClass}`}>{`+${phoneCode}`}
        <input
          id={id || ''}
          maxLength={10}
          type={"number"}
          onChange={(e) => {
            //   let val = e.target?.value.replace(/^0|92|"+92"/, "")
            //   let obj = {...e,target:{value:val}}
            onChange(e)
          }}
          prefix={"92"}
          onFocus={() => {
            setInputClass('phone-input-focused')
          }}
          onBlur={() => {
            setInputClass('')
          }}
          onWheelCapture={(e) => e.target?.blur()}
          disabled={disabled}
          value={value}
          style={{ height: 40 }}
          placeholder={placeholder}
          className="phone-new-input"
          inputMode="numeric"
          pattern="\d*"
          onPaste={onPaste}
        />
      </span>

    </div>
  )
}