import React, { FC, useState, useEffect } from "react";
import {
  PrimaryButton,
  SimpleCheckBox,
  CustomModal,
  TextInput,
} from "@components";
import styled from "styled-components";
import CategoryContainer from "./CategoryContainer";
import CargoContainer from "./CargoDetailsCard";
import { ProfileAction } from "@temp/store/actions";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TitleLayout from "@temp/components/TitleLayout";
import { QUOTE_BY, TRANSACTION_TYPE } from "@temp/constants";
import InfoIcon from "@mui/icons-material/Info";
import { CategoryUnitCard } from "./CategoryUnitCard";
import { showMessage } from "@temp/utils/helper";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";


const ButtonContainer = styled.div`
  height: 10vh;
`;
export const Image = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 70px;
  margin-top: 90px;
`;

const Button = styled.div`
  margin-top: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyledCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  height: calc(100vh - 216px);
`;

const Page: FC = () => {
  const [vehicles, setVehicles] = useState([]);
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const [vehicleScreen_values, setVehicleScreenValues] = useState(
    PostLoad?.vehicleScreen?.values || null
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const quote_by = PostLoad?.vehicleScreen?.values?.quote_by || "";
  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const additional_default_values = PostLoad?.load_type == 1
    ? postLoadMeta?.load_configurations?.containerize_additional_details
    : postLoadMeta?.load_configurations?.non_containerize_additional_details;
  const load_type = postLoadMeta.load_types.find((val: any) => val.id === PostLoad.load_type);
  const [is_hse_compliant_vehicle, set_is_hse_compliant_vehicle] = useState(additional_default_values?.is_hse_compliant_vehicle ? true : false);
  const [is_covid_certf_req_driver, set_is_covid_certf_req_driver] = useState(additional_default_values?.is_covid_certf_req_driver ? true : false);
  const [is_vehicle_tracking, set_is_vehicle_tracking] = useState(additional_default_values?.is_vehicle_tracking ? true : false);
  const [is_advance_vehicle_driver_docs, set_is_advance_vehicle_driver_docs,] = useState(additional_default_values?.is_advance_vehicle_driver_docs ? true : false);
  const [is_driver_negative_pcr_required, set_is_driver_negative_pcr_required,] = useState(additional_default_values?.is_driver_negative_pcr_required ? true : false);

  const [is_weightment_received_required, set_is_weightment_received_required,] = useState(additional_default_values?.is_weightment_received_required ? true : false);

  const [is_under_hook_delivery_required, set_is_under_hook_delivery_required] = useState(additional_default_values?.is_under_hook_delivery_required ? true : false)
  const [under_hook_delivery, set_under_hook_delivery] = useState("");


  const [weightment_received_name, set_weightment_received_name] = useState("");
  const [driver_requried_document_details, set_driver_requried_document_details] = useState("");
  const [is_bonded_vehicle_required, set_is_bonded_vehicle_required] = useState(additional_default_values?.is_bonded_vehicle_required ? true : false);
  const [is_tarpaulin_plastic_cover, set_is_is_tarpaulin_plastic_cover,] = useState(additional_default_values?.is_tarpaulin_plastic_cover ? true : false);
  const [showModalWaterMark, setShowModalWaterMark] = useState(false);
  const [showInfoWaterMark, setShowInfoWaterMark] = useState("");
  const [added_units, set_added_units] = useState([])

  useEffect(() => {
    if (PostLoad?.additionalScreen) {
      let additionalScreen = PostLoad?.additionalScreen;
      set_is_covid_certf_req_driver(additionalScreen.is_covid_certf_req_driver);
      set_is_hse_compliant_vehicle(additionalScreen.is_hse_compliant_vehicle);
      set_is_weightment_received_required(additionalScreen.is_weightment_received_required);
      set_is_vehicle_tracking(additionalScreen.is_vehicle_tracking);
      set_is_advance_vehicle_driver_docs(additionalScreen.is_advance_vehicle_driver_docs)
      set_is_driver_negative_pcr_required(additionalScreen.is_driver_negative_pcr_required)
      set_weightment_received_name(additionalScreen.weightment_received_name)
      set_is_bonded_vehicle_required(additionalScreen.is_bonded_vehicle_required)
      set_is_is_tarpaulin_plastic_cover(additionalScreen.is_tarpaulin_plastic_cover)
      set_driver_requried_document_details(additionalScreen?.driver_requried_document_details)
      set_under_hook_delivery(additionalScreen?.under_hook_delivery)
      set_is_under_hook_delivery_required(additionalScreen?.is_under_hook_delivery_required)
    }
  }, []);

  useEffect(() => {
    if (PostLoad?.vehicleScreen?.addedVehicles) {
      let vehicles = [];
      let units = [];
      PostLoad?.vehicleScreen?.addedVehicles.forEach((val: any) => {
        vehicles.push({
          is_loading_required: false,
          loading_requirements: "",
          is_unloading_required: false,
          unloading_requirements: "",
          loading_price: 0,
          unloading_price: 0,
          total_amount: 0,
          price: 0,
          max_bid: 0,
          ...val,
        });
      });
      setVehicles(vehicles);
      PostLoad?.vehicleScreen?.added_units?.forEach((val) => {
        units.push({
          is_loading_required: false,
          loading_requirements: "",
          is_unloading_required: false,
          unloading_requirements: "",
          loading_price: 0,
          unloading_price: 0,
          total_amount: 0,
          price: 0,
          max_bid: 0,
          ...val,
        });
      });
      set_added_units(units);
    }
  }, []);

  const handleUnitChange = (index, key, value) => {
    let new_data = [...added_units];
    new_data[index][key] = value;
    set_added_units(new_data);
  };

  return (
    <TitleLayout
      pageNumber={4}
      progress={40}
      title={`Additional Details - ${load_type.title}`}
    >
      {!!vehicles.length && (
        <StyledCategoryContainer>
          {vehicles.map((val, index) => (
            <FadeInComponent>
              <CategoryContainer
                value={val}
                index={index}
                title={load_type.title}
                onChangeValue={(index, key: any, value: any) => {
                  vehicles[index][key] = value;
                  vehicles[index]["total_amount"] =
                    vehicles[index]["loading_price"] +
                    vehicles[index]["unloading_price"] +
                    vehicles[index]["price"];

                  setVehicles([...vehicles]);
                }}
                active={true}
                categoryLength={vehicles?.length}
              />
            </FadeInComponent>
          ))}

          <div style={{ width: "100%" }}>

            <FadeInComponent>
              <div
                className={
                  is_advance_vehicle_driver_docs ? "selectBoxWrapper" : ""
                }
              >
                <SimpleCheckBox
                  id={"is_advance_vehicle_driver_docs"}
                  labelName="is_advance_vehicle_driver_docs"
                  title="Advance Vehicle / Driver Documents"
                  handleChange={(e) => {
                    set_is_advance_vehicle_driver_docs(
                      is_advance_vehicle_driver_docs ? false : true
                    );
                  }}
                  checked={is_advance_vehicle_driver_docs}
                  iconComponent={
                    postLoadMeta?.additional_details_charges
                      ?.is_advance_vehicle_driver_docs ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            postLoadMeta?.additional_details_charges
                              ?.is_advance_vehicle_driver_docs
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />

                {is_advance_vehicle_driver_docs && (
                  <div className="inputWrapper">
                    <div className="inputField">
                      <div className="label">Enter Details of Documents Required</div>
                      <div className="field">
                        <TextInput
                          inputProps={{
                            step: "1",
                          }}
                          onChange={(e) => {
                            if (e.target.value.length > 255) {
                              showMessage.current({
                                message: "Details of Documents should not be greater than 255 characters",
                                status: "error"
                              })
                            } else {
                              set_driver_requried_document_details(e.target.value)
                            }
                          }}
                          value={driver_requried_document_details}
                          type="text"
                          aria-describedby="standard-weight-helper-text"
                          placeholder={"License, Fitness, Vehicle Registration etc"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_bonded_vehicle_required"}
                labelName="is_bonded_vehicle_required"
                title="Bonded Vehicle Required"
                handleChange={(e) => {
                  set_is_bonded_vehicle_required(
                    is_bonded_vehicle_required ? false : true
                  );
                }}
                checked={is_bonded_vehicle_required}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_bonded_vehicle_required ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_bonded_vehicle_required
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_covid_certf_req_driver"}
                labelName="is_covid_certf_req_driver"
                title="Driver Covid-19 Vaccination Required"
                handleChange={(e) => {
                  set_is_covid_certf_req_driver(
                    is_covid_certf_req_driver ? false : true
                  );
                }}
                checked={is_covid_certf_req_driver}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_covid_certf_req_driver ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_covid_certf_req_driver
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_driver_negative_pcr_required"}
                labelName="is_driver_negative_pcr_required"
                title="Driver Negative PCR Required"
                handleChange={(e) => {
                  set_is_driver_negative_pcr_required(
                    is_driver_negative_pcr_required ? false : true
                  );
                }}
                checked={is_driver_negative_pcr_required}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_driver_negative_pcr_required ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_driver_negative_pcr_required
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                labelName="is_hse_compliant_vehicle"
                title="HSE Compliant Vehicle"
                id={"is_hse_compliant_vehicle"}
                handleChange={(e) => {
                  set_is_hse_compliant_vehicle(
                    is_hse_compliant_vehicle ? false : true
                  );
                }}
                checked={is_hse_compliant_vehicle}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_hse_compliant_vehicle ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_hse_compliant_vehicle
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_tarpaulin_plastic_cover"}
                labelName="is_tarpaulin_plastic_cover"
                title="Tarpaulin / Plastic Cover"
                handleChange={(e) => {
                  set_is_is_tarpaulin_plastic_cover(
                    is_tarpaulin_plastic_cover ? false : true
                  );
                }}
                checked={is_tarpaulin_plastic_cover}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_tarpaulin_plastic_cover ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_tarpaulin_plastic_cover
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <div
                className={
                  is_under_hook_delivery_required ? "selectBoxWrapper" : ""
                }
              >
                <SimpleCheckBox
                  id={"is_under_hook_delivery_required"}
                  labelName="is_under_hook_delivery_required"
                  title="Underhook / Direct Delivery Required"
                  handleChange={(e) => {
                    set_is_under_hook_delivery_required(
                      is_under_hook_delivery_required ? false : true
                    );
                  }}
                  checked={is_under_hook_delivery_required}
                  iconComponent={
                    postLoadMeta?.additional_details_charges
                      ?.is_under_hook_delivery_required ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            postLoadMeta?.additional_details_charges
                              ?.is_under_hook_delivery_required
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />

                {is_under_hook_delivery_required && (
                  <div className="inputWrapper">
                    <div className="inputField">
                      <div className="label">Package No.</div>
                      <div className="field">
                        <TextInput
                          inputProps={{
                            step: "1",
                          }}
                          onChange={(e) => {
                            if (e.target.value.length > 255) {
                              showMessage.current({
                                message: "Details of Package No. should not be greater than 255 characters",
                                status: "error"
                              })
                            } else {
                              set_under_hook_delivery(e.target.value)
                            }
                          }}
                          value={under_hook_delivery}
                          type="text"
                          aria-describedby="standard-weight-helper-text"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_vehicle_tracking"}
                labelName="is_vehicle_tracking"
                title="Vehicle Tracking"
                handleChange={(e) => {
                  set_is_vehicle_tracking(is_vehicle_tracking ? false : true);
                }}
                checked={is_vehicle_tracking}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_vehicle_tracking ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_vehicle_tracking
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <div
                className={
                  is_weightment_received_required ? "selectBoxWrapper" : ""
                }
              >
                <SimpleCheckBox
                  labelName="is_weightment_received"
                  title="Weighment Required"
                  id={"is_weightment_received"}
                  handleChange={(e) => {
                    set_is_weightment_received_required(
                      is_weightment_received_required ? false : true
                    );
                  }}
                  checked={is_weightment_received_required}
                  iconComponent={
                    postLoadMeta?.additional_details_charges
                      ?.is_weightment_received_required ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            postLoadMeta?.additional_details_charges
                              ?.is_weightment_received_required
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />

                {is_weightment_received_required && (
                  <div className="inputWrapper">
                    <div className="inputField">
                      <div className="label">Weighment Bridge Name</div>
                      <div className="field">
                        <TextInput
                          onChange={(e) => {
                            if (e.target.value.length > 50) {
                              showMessage.current({
                                message: "Weighment Bridge Name should not be greater than 50 characters",
                                status: "error"
                              })
                            } else {
                              set_weightment_received_name(e.target.value);
                            }
                          }}
                          value={weightment_received_name}
                          type="text"
                          aria-describedby="standard-weight-helper-text"
                          placeholder="(Optional)"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FadeInComponent>
          </div>
        </StyledCategoryContainer>
      )}

      {quote_by === QUOTE_BY.weightVolume && (
        <StyledCategoryContainer>
          {!PostLoad?.vehicleScreen?.is_unit_basis ? (
            <FadeInComponent>
              <CargoContainer
                value={vehicleScreen_values}
                title={load_type.title}
                onChangePrice={() => { }}
                screen={"additional_details"}
                vehicleScreenData={PostLoad?.vehicleScreen || null}
                onChangeValue={(key: any, value: any) => {
                  vehicleScreen_values[key] = value;
                  vehicleScreen_values["total_amount"] =
                    vehicleScreen_values["loading_price"] +
                    vehicleScreen_values["unloading_price"] +
                    vehicleScreen_values["price"];

                  setVehicleScreenValues({ ...vehicleScreen_values });
                }}
                active={true}
              />
            </FadeInComponent>
          ) : (
            <div>
              {PostLoad?.vehicleScreen?.is_unit_basis && (
                <div>
                  {added_units.map((item, index) => {
                    return (
                      <FadeInComponent>
                        <CategoryUnitCard
                          value={item}
                          index={index}
                          added_units={added_units}
                          onChangeValue={(index, key, value) => {
                            handleUnitChange(index, key, value);
                          }}
                        />
                      </FadeInComponent>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          <div style={{ width: "100%" }}>
            <FadeInComponent>
              <div
                className={
                  is_advance_vehicle_driver_docs ? "selectBoxWrapper" : ""
                }
              >
                <SimpleCheckBox
                  id={"is_advance_vehicle_driver_docs"}
                  labelName="is_advance_vehicle_driver_docs"
                  title="Advance Vehicle / Driver Documents"
                  handleChange={(e) => {
                    set_is_advance_vehicle_driver_docs(
                      is_advance_vehicle_driver_docs ? false : true
                    );
                  }}
                  checked={is_advance_vehicle_driver_docs}
                  iconComponent={
                    postLoadMeta?.additional_details_charges
                      ?.is_advance_vehicle_driver_docs ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            postLoadMeta?.additional_details_charges
                              ?.is_advance_vehicle_driver_docs
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />
                {is_advance_vehicle_driver_docs && (
                  <div className="inputWrapper">
                    <div className="inputField">
                      <div className="label">Enter Details of Documents Required</div>
                      <div className="field">
                        <TextInput
                          onChange={(e) => {
                            if (e.target.value.length > 255) {
                              showMessage.current({
                                message: "Details of Documents should not be greater than 255 characters",
                                status: "error"
                              })
                            } else {
                              set_driver_requried_document_details(e.target.value)
                            }
                          }}
                          value={driver_requried_document_details}
                          type="text"
                          aria-describedby="standard-weight-helper-text"
                          placeholder={"License, Fitness, Vehicle Registration etc"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_bonded_vehicle_required"}
                labelName="is_bonded_vehicle_required"
                title="Bonded Vehicle Required"
                handleChange={(e) => {
                  set_is_bonded_vehicle_required(
                    is_bonded_vehicle_required ? false : true
                  );
                }}
                checked={is_bonded_vehicle_required}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_bonded_vehicle_required ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_bonded_vehicle_required
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_covid_certf_req_driver"}
                labelName="is_covid_certf_req_driver"
                title="Driver Covid-19 Vaccination Required"
                handleChange={(e) => {
                  set_is_covid_certf_req_driver(
                    is_covid_certf_req_driver ? false : true
                  );
                }}
                checked={is_covid_certf_req_driver}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_covid_certf_req_driver ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_covid_certf_req_driver
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_driver_negative_pcr_required"}
                labelName="is_driver_negative_pcr_required"
                title="Driver Negative PCR Required"
                handleChange={(e) => {
                  set_is_driver_negative_pcr_required(
                    is_driver_negative_pcr_required ? false : true
                  );
                }}
                checked={is_driver_negative_pcr_required}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_driver_negative_pcr_required ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_driver_negative_pcr_required
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                labelName="is_hse_compliant_vehicle"
                title="HSE Compliant Vehicle"
                id={"is_hse_compliant_vehicle"}
                handleChange={(e) => {
                  set_is_hse_compliant_vehicle(
                    is_hse_compliant_vehicle ? false : true
                  );
                }}
                checked={is_hse_compliant_vehicle}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_hse_compliant_vehicle ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_hse_compliant_vehicle
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <SimpleCheckBox
                id={"is_tarpaulin_plastic_cover"}
                labelName="is_tarpaulin_plastic_cover"
                title="Tarpaulin / Plastic Cover"
                handleChange={(e) => {
                  set_is_is_tarpaulin_plastic_cover(
                    is_tarpaulin_plastic_cover ? false : true
                  );
                }}
                checked={is_tarpaulin_plastic_cover}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_tarpaulin_plastic_cover ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_tarpaulin_plastic_cover
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <div
                className={
                  is_under_hook_delivery_required ? "selectBoxWrapper" : ""
                }
              >
                <SimpleCheckBox
                  id={"is_under_hook_delivery_required"}
                  labelName="is_under_hook_delivery_required"
                  title="Underhook / Direct Delivery Required"
                  handleChange={(e) => {
                    set_is_under_hook_delivery_required(
                      is_under_hook_delivery_required ? false : true
                    );
                  }}
                  checked={is_under_hook_delivery_required}
                  iconComponent={
                    postLoadMeta?.additional_details_charges
                      ?.is_under_hook_delivery_required ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            postLoadMeta?.additional_details_charges
                              ?.is_under_hook_delivery_required
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />
               

                {is_under_hook_delivery_required && (
                  <div className="inputWrapper">
                    <div className="inputField">
                      <div className="label">Package No.</div>
                      <div className="field">
                        <TextInput
                          inputProps={{
                            step: "1",
                          }}
                          onChange={(e) => {
                            if (e.target.value.length > 255) {
                              showMessage.current({
                                message: "Details of Package No. should not be greater than 255 characters",
                                status: "error"
                              })
                            } else {
                              set_under_hook_delivery(e.target.value)
                            }
                          }}
                          value={under_hook_delivery}
                          type="text"
                          aria-describedby="standard-weight-helper-text"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FadeInComponent>


            <FadeInComponent>
              <SimpleCheckBox
                id={"is_vehicle_tracking"}
                labelName="is_vehicle_tracking"
                title="Vehicle Tracking"
                handleChange={(e) => {
                  set_is_vehicle_tracking(is_vehicle_tracking ? false : true);
                }}
                checked={is_vehicle_tracking}
                iconComponent={
                  postLoadMeta?.additional_details_charges
                    ?.is_vehicle_tracking ? (
                    <InfoIcon
                      onClick={() => {
                        setShowInfoWaterMark(
                          postLoadMeta?.additional_details_charges
                            ?.is_vehicle_tracking
                        );
                        setShowModalWaterMark(true);
                      }}
                    />
                  ) : null
                }
              />
            </FadeInComponent>

            <FadeInComponent>
              <div
                className={
                  is_weightment_received_required ? "selectBoxWrapper" : ""
                }
              >
                <SimpleCheckBox
                  labelName="is_weightment_received"
                  title="Weighment Required"
                  id={"is_weightment_received"}
                  handleChange={(e) => {
                    set_is_weightment_received_required(
                      is_weightment_received_required ? false : true
                    );
                  }}
                  checked={is_weightment_received_required}
                  iconComponent={
                    postLoadMeta?.additional_details_charges
                      ?.is_weightment_received_required ? (
                      <InfoIcon
                        onClick={() => {
                          setShowInfoWaterMark(
                            postLoadMeta?.additional_details_charges
                              ?.is_weightment_received_required
                          );
                          setShowModalWaterMark(true);
                        }}
                      />
                    ) : null
                  }
                />

                {is_weightment_received_required && (
                  <div className="inputWrapper">
                    <div className="inputField">
                      <div className="label">Weighment Bridge Name</div>
                      <div className="field">
                        <TextInput
                          onChange={(e) => {
                            if (e.target.value.length > 50) {
                              showMessage.current({
                                message: "Weighment Bridge Name should not be greater than 50 characters",
                                status: "error"
                              })
                            } else {
                              set_weightment_received_name(e.target.value);
                            }
                          }}
                          value={weightment_received_name}
                          type="text"
                          aria-describedby="standard-weight-helper-text"
                          placeholder="(Optional)"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FadeInComponent>
          </div>
        </StyledCategoryContainer>
      )}

      <ButtonContainer>
        <Button>
          <FadeInComponent>
            <PrimaryButton
              title="Continue"
              rightIcon={true}
              disabled=
              {is_under_hook_delivery_required ? 
                under_hook_delivery ?.length >= 1 ? false :true :false
              }
              onClick={() => {
                dispatch(
                  ProfileAction.postLoadData({
                    transaction_type_id: PostLoad?.transaction_type_id || 1,
                    vehicleScreen: {
                      ...PostLoad?.vehicleScreen,
                      addedVehicles: vehicles,
                      values: vehicleScreen_values,
                      added_units,
                    },
                    additionalScreen: {
                      is_hse_compliant_vehicle,
                      is_covid_certf_req_driver,
                      is_vehicle_tracking,
                      is_advance_vehicle_driver_docs,
                      is_driver_negative_pcr_required,
                      is_weightment_received_required,
                      weightment_received_name,
                      is_bonded_vehicle_required,
                      is_tarpaulin_plastic_cover,
                      is_allow_partial_load: PostLoad?.vehicleScreen?.is_allow_partial_load,
                      driver_requried_document_details,
                      is_under_hook_delivery_required,
                      under_hook_delivery
                    },
                  })
                );
                PostLoad?.transaction_type_id === TRANSACTION_TYPE.offerId
                  ? history.push("/offer")
                  : history.push("/bid");
              }}
            />
          </FadeInComponent>
        </Button>
      </ButtonContainer>

      {/* show info icon watermark */}
      <CustomModal
        modalStyle={{
          width: 300,
          minHeight: 100,
        }}
        className="infoModal"
        showModal={showModalWaterMark}
        toggleModal={setShowModalWaterMark}
      >
        <div>
          <InfoIcon className="icon" />
          <p className="title">Information</p>
          <p>
            {showInfoWaterMark}
          </p>
        </div>
      </CustomModal>
    </TitleLayout>
  );
};
export default Page;