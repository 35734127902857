// import "date-fns";
import React, { FC } from "react";
// import DateFnsUtils from "@date-io/date-fns";
import "./style.scss";
import moment from "moment";
import { convertTimeZone } from "@temp/utils/helper";
import { CheckCircle } from "@mui/icons-material";
import OutlineButton from "../OutLineButton";

interface types {
  load_type:string
  inquiry_type_id?:number
  from?:string
}
export const LoadListingStatus: FC<types> = ({
  load_type,
  inquiry_type_id,
}: types) => {

  return (
    <div className="flexRow" style={{
      paddingBottom: 10,
      justifyContent: 'space-between'
    }}>
      <div className="fcl-tag" style={{
        width: inquiry_type_id == 2 ? 100 : 100,
        height: 28,
        borderRadius: 18
      }} >
        {load_type}
      </div>
      {
        true && (
          <OutlineButton
            buttonStyle={{ height: 30, width: 95 }}
            buttonTitleStyle={{ fontSize: 10 }}
            classProp='mb-0'
            title={"completed"}
            icon={
              <CheckCircle />
            }
          />
        )
      }
      <div style={{
        border: "2px solid gray",
        height: 28,
        borderRadius: 18,
        paddingLeft: 15,
        paddingRight: 15
      }} className="flexRow">
        <div style={{ fontSize: 10 }} className="fw-5">
          10/30
        </div>
      </div>
    </div>
  );
};
