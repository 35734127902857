import React, { useState, useEffect } from "react";
import {
  BackHeader,
  PrimaryButton,
  OutLineButton,
  CustomText,
} from "@components";
import { Images, Colors } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { AuthAction } from "@temp/store/actions";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { showMessage, setItem, preventNonNumericalInput } from "@utils/helper";
import { TOKEN } from "@app/constants";
import { LoginPageProps } from "./types";
import { Checkbox, Select, MenuItem } from "@mui/material/";

import { TERMS_DRAWER_URL, TERMS_URL } from "@temp/app/routes";

import OtpInput from "react-otp-input";
import { SentryError } from "@sentry/utils";
import { Mixpanel } from "@temp/utils/mixpanel";

const Page: React.FC<LoginPageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isWhatsAppNo, setIsWhatsAppNo] = useState(true);
  const [whatsapp, setWhatsappNo] = useState("");
  

  const metaData = useSelector((state: RootState) => state.Auth.metaData);

  const [mobileNetwork, setMobileNetwork] = useState({ mobile_networks: null });
  

  useEffect(() => {
    dispatch(AuthAction.getMetaData());
  }, []);

  const handleChangeMobileNetwork = (e) => {
    
    setMobileNetwork({ ...mobileNetwork, [e.target.name]: e.target.value })

  }

  const handleChange = (e: any) => {
    if (e.target.value.length > 10) {
      return;
    } else {
      if (e.target.name === "phone") {
        setPhoneNumber(e.target.value);
      } else {
        setWhatsappNo(e.target.value);
      }
    }
  };

  const callBack = (data: any) => {
    if (data?.forbidden) {
      showMessage.current({
        message:
          // "The number Already exists.",
          "This number is already registered",
        status: "error",
      });
    } else {
      if (data?.user?.is_toc_accepted === 1) {
        setItem(TOKEN, data?.token);
        setItem("toc_accepted", true);
        history.replace("/home");
      } else if (data?.user?.is_toc_accepted === 0) {
        setItem(TOKEN, data?.token);
        history.replace("/terms");
      } else if (data.isOTPSent) {
        if (data?.isUserExists) {
          showMessage.current({
            message:
              //  "The number Already exists.",
              "This number is already registered",
            status: "error",
          });
        } else {
          history.push("/otp", { ...data });
          showMessage.current({
            message:
              // "Otp has been successfully sent",
              "OTP has been sent successfully",
            status: "success",
          });
        }
      } else {
        showMessage.current({
          message:
            // "Otp not sent, please try again",
            "OTP not sent, please try again",
          status: "error",
        });
      }
    }
  };

  const onSubmit = (type: string) => {
    if (phoneNumber) {
      if (isWhatsAppNo) {
        const payload = {
          phoneNumber: phoneNumber,
          type,
          from: "signup",
          cb: callBack,
          whatsapp: null,
          mobileNetwork: mobileNetwork
        };
        dispatch(AuthAction.LogInWihtOtp(payload));
      } else {
        if (whatsapp) {
          const payload = {
            phoneNumber: phoneNumber,
            type,
            from: "signup",
            cb: callBack,
            whatsapp: whatsapp,
            mobileNetwork: mobileNetwork

          };
          dispatch(AuthAction.LogInWihtOtp(payload));
        } else {
          showMessage.current({
            message: "Please enter whatsapp number.",
            status: "error",
          });
        }
      }
    }
  };

  return (
    <div className="signupWrapper" style={{ height: "100vh" }}>
      <BackHeader
        title="Enter Mobile Number"
        onClick={() => {
          history.goBack();
        }}
      />
      <div className="root-wrapper signUpRootWrapper">
        <div className="root-center-container">
          <div className="signupMobileNumberWrapper">
            <div className="login-form-container">
              <div className="country-code-container">
                <CustomText.OpenSansRegular textStyle={{ fontSize: "1rem" }}>
                  +92
                </CustomText.OpenSansRegular>
              </div>
              <div className="input-container">
                {/* <input
                  className="login-form-field"
                  name="phone"
                  autoFocus
                  style={{
                    paddingLeft: 4,
                  }}
                  value={phoneNumber}
                  onChange={handleChange}
                  placeholder="300 0000000"
                  type="text"
                  pattern="[0-9]*"
                  onKeyPress={(event) => preventNonNumericalInput(event)}
                /> */}
                <div className="otpTypeInput">
                  <OtpInput
                    value={phoneNumber}
                    inputStyle={{
                      padding: 0,
                      margin: 0,
                      height: 34,
                      width: 25,
                      marginLeft: 5,
                      backgroundColor: '#ededed',
                      border: 'none',
                      outline: 'none',
                      borderRadius: 5
                    }}
                    placeholder="3000000000"
                    isInputNum={true}
                    containerStyle={{
                      // backgroundColor:'blue'
                    }}
                    onChange={(number) => {
                      setPhoneNumber(number);
                    }}
                    numInputs={10}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
              </div>
            </div>
            {/* dropdown added for MNP */}

            <div>

              <CustomText.OpenSansSemiBold textStyle={{
                fontSize: "0.875rem",
                color: Colors.Grayish,
                marginTop: 20,
                marginLeft: 6
              }}>
                Please Select Your Network
              </CustomText.OpenSansSemiBold>

              <Select
                id="outlined-select-date-native"
                select
                defaultValue={mobileNetwork?.mobile_networks}
                value={mobileNetwork?.mobile_networks}
                name='mobile_networks'
                onChange={handleChangeMobileNetwork}
                style={{ marginLeft: 5, height: 38, width: "95%", marginTop: 10 }}

              >
                {metaData?.mobile_networks?.map((value) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  )
                })}
              </Select>

            </div>
            <div className="whatsapp-field-container">
              <div
                style={{
                  // marginRight: 51,
                  width: '100%',
                  marginTop: 10,
                }}
                className="check-box"
              >
                <Checkbox
                  checked={isWhatsAppNo}
                  style={{ color: "#7ac251" }}
                  // defaultChecked
                  onClick={() => {
                    setIsWhatsAppNo(!isWhatsAppNo);
                    if (!isWhatsAppNo) {
                      setWhatsappNo("");
                    }
                  }}
                />
                <p className="keep-signin-text">
                  Phone number same as WhatsApp number
                </p>
              </div>
              {!isWhatsAppNo ? (
                <div className="login-form-wraper">
                  <CustomText.OpenSansSemiBold
                    textStyle={{
                      fontSize: "0.875rem",
                      color: Colors.Grayish,
                    }}
                  >
                    Whatsapp Number
                  </CustomText.OpenSansSemiBold>
                  <div className="login-new-container">
                    <div style={{ marginLeft: 4 }}>
                      <CustomText.OpenSansRegular textStyle={{ fontSize: "1rem" }}>
                        +92
                      </CustomText.OpenSansRegular>
                    </div>
                    {/* <div>
                      <input
                        name="whatsapp"
                        className="login-form-inputt"
                        style={{
                          paddingLeft: 4,
                        }}
                        value={whatsapp}
                        onChange={handleChange}
                        type="text"
                        pattern="[0-9]*"
                        // onKeyPress={(event) => preventNonNumericalInput(event)}
                      />
                    </div> */}
                    <div className="otpTypeInput">
                      <OtpInput
                        value={whatsapp}
                        inputStyle={{
                          padding: 0,
                          margin: 0,
                          height: 34,
                          width: 25,
                          marginLeft: 5,
                          backgroundColor: '#ededed',
                          border: 'none',
                          outline: 'none',
                          borderRadius: 5
                        }}
                        isInputNum={true}
                        containerStyle={{
                          // backgroundColor:'blue'
                        }}
                        onChange={(number) => {
                          setWhatsappNo(number)
                        }}
                        numInputs={10}
                        renderInput={(props) => <input {...props} />}
                    />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>


          </div>
          <div className="bottomSignupScreen">
            <div className="insideSignupBtnWrap">
              <div
                style={{
                  paddingTop: 30,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CustomText.OpenSansRegular
                  textStyle={{
                    color: Colors.Grayish,
                    textAlign: "center",
                    marginLeft: 24,
                    marginRight: 24,
                  }}
                >
                  By signing in or creating an account, you agree with our{" "}
                  <span
                    style={{
                      color: Colors.LightBlue,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => history.push("terms-and-policy", { from: "terms" })}
                  >
                    Terms & Condition
                  </span>{" "}
                  and{" "}
                  <span
                    style={{
                      color: Colors.LightBlue,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => history.push("terms-and-policy", { from: "policy" })}
                  >

                    Privacy Policy
                  </span>
                </CustomText.OpenSansRegular>
              </div>

              <div className="signupBtns">
                {/* <PrimaryButton
                  disabled={phoneNumber.length < 10}
                  title="Send Code via WhatsApp"
                  onClick={() => {
                    onSubmit("whatsapp");
                  }}
                  icon={
                    phoneNumber.length >= 10
                      ? Images.WhatsappGreen
                      : Images.WhatsappGray
                  }
                /> */}
                <OutLineButton
                  disabled={
                    isWhatsAppNo ? (!mobileNetwork?.mobile_networks || phoneNumber.length < 10 ? true : false)
                      : ((!mobileNetwork?.mobile_networks || phoneNumber.length < 10 || whatsapp.length < 10) ? true : false)}

                  icon={
                    isWhatsAppNo ? (!mobileNetwork?.mobile_networks || phoneNumber.length < 10 ? Images.MessageBubbleGray : Images.MessageBubble)
                      : ((!mobileNetwork?.mobile_networks || phoneNumber.length < 10 || whatsapp.length < 10) ? Images.MessageBubbleGray : Images.MessageBubble)}



                  // icon={
                  //   mobileNetwork?.mobile_networks || phoneNumber.length >= 10  && 
                  //   (mobileNetwork?.mobile_networks || whatsapp.length >= 10)
                  //     ? Images.MessageBubble
                  //     : Images.MessageBubbleGray
                  // }
                  title="Send Code via SMS"
                  onClick={() => {
                    onSubmit("sms");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
