import moment from "moment";
const isEmpty = require("lodash/isEmpty");

export const actionCreator = (name) => {
  return [name, name + "_success", name + "_fail"];
};

import { store } from "@temp/store";
import { AuthAction } from "@temp/store/actions";
import { Images } from "@temp/assets";
import { isBrowser } from "react-device-detect";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../utils/mobileEventHandler";
import { Mixpanel } from "./mixpanel";
import dayjs from "dayjs";

export var is_get_load_count = false;
export const updateLoadCountFlag = () => {
  is_get_load_count = true;
};

export const phoneNumberFormater = (phone_code, phone_number) => {
  if (phone_code == "92" || phone_code == 92) {
    let split_number = phone_number?.split("");
    split_number?.splice(3, 0, " ");
    let joined_number = split_number?.join("");
    return `0${joined_number}`
  } else {
    return `${phone_code} ${phone_number}`
  }
};

export const rand_date = moment().format('YYYY-MM-DD-HH-mm')


export const triggerMixpanelEvent = (event_name, event_description) => {
  if (isBrowser) {
    Mixpanel.track(event_name, {
      name: event_name,
      des: event_description,
    });
  } else {
    if (sendEventToAndroid("mixpanelEventSend")) {
      window.Android.mixpanelEventSend(event_name);
    } else if (sendEventToiOS("mixpanelEventSend")) {
      window.webkit.messageHandlers.mixpanelEventSend.postMessage(
        JSON.stringify({
          access: event_name,
        })
      );
    }
  }
};

export const fetchSearchApi = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(fn, delay, [...args]);
  };
};

export const getDate = (value: any) => {
  return moment(value).format("Y-MM-DD");
};
export const getTime = (value: any) => {
  return moment(value).format("HH:mm:ss");
};

export const setItem = (key, value) => {
  
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key) => {
  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export let showMessage = null;
export const getMessageRef = (ref: any) => {
  showMessage = ref;
};

export let showErrorMessage = null;
export const getErrorMessageRef = (ref: any) => {
  showErrorMessage = ref;
};
const passwordRegex = /^(?=.{6,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/;

//changes

// unique device id
export const deviceId = () => {
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  var uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, "");
  uid += navigator_info.plugins.length;
  uid += screen_info.height || "";
  uid += screen_info.width || "";
  uid += screen_info.pixelDepth || "";
  return uid;
};

export const validateNameNumber = (name, number) => {
  if (number?.length && name?.length) {
    if (number?.length >= 10) {
      if (name?.length > 2 && name?.length <= 30) {
        return true;
      } else {
        showMessage.current({
          message:
            "Contact Name should be min 3 characters and max 30 characters.",
          status: "error",
        });
        return false;
      }
    } else {
      showMessage.current({
        message:
          "Contact number should be of 10 digit.",
        status: "error",
      });
      return false;
    }
  } else if (number?.length) {
    if (number?.length >= 10) {
      return true;
    } else {
      showMessage.current({
        message:
          "Contact number should be of 10 digit.",
        status: "error",
      });
      return false;
    }
  } else if (name?.length) {
    if (name?.length > 2 && name.length <= 30) {
      return true;
    } else {
      showMessage.current({
        message:
          "Contact Name should be min 3 characters and max 30 characters.",
        status: "error",
      });
      return false;
    }
  } else {
    return true;
  }
};

const upperCase = /[A-Z]/;

const specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const lowerCase = /[a-z]/;

const checkNum = /^[0-9]{1,6}$/;

const checkNumeric = /^(?=.*\d).{1,20}$/;

const refRegix = /^[a-zA-Z]{4}[0-9]{7}/;

const exp = /^(https\:\/\/www\.?|http\:\/\/www\.?|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
export const urlRegex = new RegExp(exp);

export const getMillisec = (t1, t2) => {
  let temp = typeof(t2) === "string"?t2:dayjs(t2).format('HH:mm:ss')
  return moment(`${t1} ${temp}`).format('x')
  // return moment(`${dayjs(t1).format('YYYY-MM-DD')} ${dayjs(t2).format('HH:mm:ss')}`).format('x')
}

export const handleDateValidation = (
  deadline_date,
  deadline_time,
  pickup_date,
  pickup_time,
  delivery_date,
  delivery_time,
  emptyreturn_date,
  emptyreturn_time,
  from
) => {
  // when response deadline less than current time
  if (deadline_date && deadline_time) {
    if (
      getMillisec(deadline_date, deadline_time) <
      new Date().getTime() + 5000
    ) {
      return from == "response_deadline_screen"
        ? "Response deadline should be greater than current time."
        : "Acceptance deadline should be greater than current time.";
    }
  }

  // when pickup less than response deadline
  if (deadline_date && deadline_time && pickup_date && pickup_time) {
    if (
      getMillisec(pickup_date, pickup_time) <=
      getMillisec(deadline_date, deadline_time)
    ) {
      return "Pickup time should be greater than response deadline.";
    }
  }
  else{
  if (deadline_date && deadline_time && pickup_date && !pickup_time) {
    if (
      deadline_date > pickup_date
    ) {
      return "Pickup time should be greater than response deadline.";
    }
  }}
  if (pickup_date && pickup_time && delivery_date && delivery_time) {
    if (
      getMillisec(delivery_date, delivery_time) <=
      getMillisec(pickup_date, pickup_time)
    ) {
      return "Delivery deadline should be greater than pickup time.";
    }
  }
  else {
    if (pickup_date && delivery_date && (!pickup_time || !delivery_time)) {
      if (
        pickup_date > delivery_date
      ) {
        return "Delivery deadline should be greater than pickup time.";
      }
    }
  }

  return "";
};

export const price_units_transalation = {
  Ton: " فی ٹن",
  "F.Ton": " فی فریٹ ٹن",
  CBM: "فی سی بی ایم",
  Liter: "فی لیٹر",
  Kg:"کلوگرام"
};

export function validateRefNo(ref) {
  return refRegix.test(ref);
}

export function validateUpper(password) {
  return upperCase.test(password);
}

export function validateLower(password) {
  return lowerCase.test(password);
}

export function validateSpecial(password) {
  return specialChar.test(password);
}

export function validateNum(password) {
  return checkNum.test(password);
}

export function validateNumeric(password) {
  return checkNumeric.test(password);
}

export function validatePassword(password) {
  return passwordRegex.test(password);
}

export const getFullTime = (params) => {
  return new Date(params).getTime();
};
export const deadlineTime = (params) => {
  return new Date(params).getTime();
};

export const fileIconDisplay = (mime_type) => {
  if (
    mime_type.includes("image") ||
    mime_type.includes("jpeg") ||
    mime_type.includes("png")
  ) {
    return Images.docPng;
  } else if (mime_type.includes("pdf")) {
    return Images.docPdf;
  } else if (mime_type.includes("text/plain") || mime_type.includes("plain")) {
    return Images.docTxt;
  } else if (
    mime_type.includes(
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) ||
    mime_type.includes("spreadsheetml") ||
    mime_type.includes("xlsx") ||
    mime_type.includes("sheet")
  ) {
    return Images.Xls;
  } else if (
    mime_type.includes(
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) ||
    mime_type.includes("word")
  ) {
    return Images.Doc;
  } else if (mime_type.includes("text/csv")) {
    return Images.docCsv;
  }
};

export const dateIsGreater = (date1) => {
  if (getFullTime(date1) > getFullTime(new Date())) {
    return true;
  } else {
    return false;
  }
};

export const convertTimeZone = (date, tzString) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};

export function preventNonNumericalInput(e) {
  e = e || window.event;
  var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
}

export function logOut() {
  store.dispatch(AuthAction.resetStore());
  setItem("token", null);
  setItem("toc_accepted", null);
  setItem("changed_password", null);
}

export function isObjectEmpty(object) {
  return Object.keys(object).length === 0 && object.constructor === Object;
}

export function isSupported() {
  if (self && "ServiceWorkerGlobalScope" in self) {
    // Running in ServiceWorker context
    return isSWControllerSupported();
  } else {
    // Assume we are in the window context.
    return isWindowControllerSupported();
  }
}

function isWindowControllerSupported() {
  return (
    "indexedDB" in window &&
    indexedDB !== null &&
    navigator.cookieEnabled &&
    "serviceWorker" in navigator &&
    "PushManager" in window &&
    "Notification" in window &&
    "fetch" in window &&
    ServiceWorkerRegistration.prototype.hasOwnProperty("showNotification") &&
    PushSubscription.prototype.hasOwnProperty("getKey")
  );
}

function isSWControllerSupported() {
  return (
    "indexedDB" in self &&
    indexedDB !== null &&
    "PushManager" in self &&
    "Notification" in self &&
    ServiceWorkerRegistration.prototype.hasOwnProperty("showNotification") &&
    PushSubscription.prototype.hasOwnProperty("getKey")
  );
}

const getVehiclesList = (data: any) => {
  let item = [];

  data.forEach((val: any) => {
    item.push({
      master_vehicle_type_id: val?.selectVehicleTypeObj?.id,
      max_vehicle_weight: val?.max_vehicle_weight,
      vehicle_quantity: val?.vehicle_quantity,
      container_type_id: val?.containerType?.id || null,
      total_offer_price: val?.total_amount || null,
      offer_price_per_vehicle: val?.price || null,
      is_boxed: val?.values?.is_boxed || false,
      is_sidewall: val?.values?.is_sidewall || false,
      is_out_of_gauge: val?.values?.is_out_of_gauage || false,
      package_type_id: val?.packageType?.id || val?.package_type_id,
      is_twin_load: val?.values?.is_twin_load || false,
      id: val?.id,
    });
  });
  return item;
};

export const getFclData = (postData: any, postLoadData, callback) => {
  postData["title"] = postLoadData?.vehicleScreen?.values?.load_name;
  postData["inquiry_type_id"] = postLoadData?.inquiry_type;
  postData["load_type_id"] = postLoadData?.load_type;
  postData["transaction_type_id"] = postLoadData?.transaction_type_id;

  postData["pt_is_advance"] = postLoadData?.PaymentScreen?.pt_is_advance;
  postData["pt_is_ondelivery"] = postLoadData?.PaymentScreen?.pt_is_ondelivery;
  postData["pt_is_credit"] = postLoadData?.PaymentScreen?.pt_is_credit;
  postData["is_pt_fuel_card"] = postLoadData?.PaymentScreen?.is_pt_fuel_card;
  postData["is_gst_inclusive"] = postLoadData?.PaymentScreen?.is_gst_inclusive;
  postData["is_withholding_tax_inclusive"] =
    postLoadData?.PaymentScreen?.is_withholding_tax_inclusive;

  postData["pt_advance_value"] =
    postLoadData?.PaymentScreen?.pt_advance_value || 0;
  postData["pt_ondelivery_value"] =
    postLoadData?.PaymentScreen?.pt_ondelivery_value || 0;
  postData["pt_credit_days"] = postLoadData?.PaymentScreen?.pt_credit_days || 0;
  postData["pt_credit_percentage"] =
    postLoadData?.PaymentScreen?.pt_credit_percentage || 0;
  postData["pt_fuel_card_value"] =
    postLoadData?.PaymentScreen?.pt_fuel_card_value || 0;
  postData["categories"] = {};
  postData["categories"]["items"] = {};

  postData["categories"]["pickup"] = {};
  postData["categories"]["pickup"]["lat"] = postLoadData?.location[0].lat;
  postData["categories"]["pickup"]["description"] =
    postLoadData?.location[0]?.description;
  postData["categories"]["pickup"]["raw_address"] =
    postLoadData?.location[0]?.address;
  postData["categories"]["pickup"]["long"] = postLoadData?.location[0]?.lng;

  postData["pickup_at"] =
    String(postLoadData?.responseDeadline?.pickupDate) +
    " " +
    String(postLoadData?.responseDeadline?.pickupTime);

  postData["empty_return_yard_name"] =
    postLoadData?.responseDeadline?.empty_return_yard_name || null;

  postData["categories"]["dropoff"] = {};
  postData["categories"]["dropoff"]["lat"] = postLoadData?.location[1].lat;
  postData["categories"]["dropoff"]["description"] =
    postLoadData?.location[1]?.description;
  postData["categories"]["dropoff"]["raw_address"] =
    postLoadData?.location[1]?.address;
  postData["categories"]["dropoff"]["long"] = postLoadData?.location[1]?.lng;
  postData["deadline_at"] =
    String(postLoadData?.responseDeadline?.responseDate) +
    " " +
    String(postLoadData?.responseDeadline?.responseTime);

  postData["is_covid_certf_req_driver"] =
    postLoadData?.vehicleScreen?.values?.is_covid_certf_req_driver;
  postData["is_hse_compliant_vehicle"] =
    postLoadData?.vehicleScreen?.values?.is_hse_compliant_vehicle;

  postLoadData?.vehicleScreen?.values?.quote_by === "weight_volume"
    ? null
    : (postData["categories"]["items"] = getVehiclesList(
      postLoadData?.vehicleScreen?.addedVehicles || []
    ));
  postLoadData?.vehicleScreen?.values?.quote_by === "weight_volume"
    ? (postData["package_type_id"] =
      postLoadData?.vehicleScreen?.package_type?.id)
    : null;
  postData["documents"] = postLoadData?.document || null;

  postData["recipients"] = postLoadData?.recipients || null;

  postData["ref_no"] = postLoadData?.ref_no || null;

  postData["cb"] = callback;

  return postData;
};

export const getLclData = (postData: any, postLoadData, callback) => {
  postData = { ...postData, ...getFclData({}, postLoadData, null) };

  postData["gross_weight"] = postLoadData?.vehicleScreen?.values?.gross_weight;
  postData["total_freight_ton"] =
    postLoadData?.vehicleScreen?.values?.total_freight_ton;

  postData["cargo_volume"] =
    postLoadData?.vehicleScreen?.values?.cargo_volume || null;

  postData["no_of_packages"] =
    postLoadData?.vehicleScreen?.values?.no_of_packages;
  postData["quote_by"] = postLoadData?.vehicleScreen?.values?.quote_by;
  postData["gross_weight"] = postLoadData?.vehicleScreen?.values?.gross_weight;
  if (postLoadData?.vehicleScreen?.values?.quote_by === "weight_volume") {
    postData["price_per_freight"] =
      postLoadData?.vehicleScreen?.values?.unloading_price;

    postData["loading_price_per_freight"] =
      postLoadData?.vehicleScreen?.values?.loading_price;
    postData["total_offer_price"] = postLoadData?.vehicleScreen?.values?.price;
    postData["package_type_id"] =
      postLoadData?.vehicleScreen?.values?.package_type_id;

    postData["is_boxed"] = postLoadData?.vehicleScreen?.values?.is_boxed;

    postData["is_sidewall"] = postLoadData?.vehicleScreen?.values?.is_sidewall;
  }
  getVehiclesList(postLoadData?.vehicleScreen?.addedVehicles || []);

  postData["cb"] = callback;

  return postData;
};

export const HELPER = {
  isNotEmpty: (x) => {
    return !isEmpty(x);
  },

  isEmpty: (x: any) => {
    return (
      typeof x === undefined ||
      typeof x === "undefined" ||
      x === null ||
      x === "null" ||
      x === "undefined" ||
      x === false ||
      x.length === 0 ||
      (x === "object" && Object.key(x).length === 0) ||
      x === "" ||
      (x && // 👈 null and undefined check
        Object.keys(x).length === 0 &&
        Object.getPrototypeOf(x) === Object.prototype)
    );
  },
};

export const convertNumberToBoolean = (value: any) => Boolean(Number(value));
