import React from "react";
import { IProps } from "./types";
import "./style.scss";
import rootReducer from "@temp/store/reducers";
// import Lottie from 'react-lottie';
import { useSelector } from "react-redux";
import { Backdrop } from "@mui/material/";
import { Images } from "@temp/assets";
export type RootState = ReturnType<typeof rootReducer>;
// import Lottie from "lottie-react";
import { useLottie } from "lottie-react";
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     backdrop: {
//       zIndex: theme.zIndex.drawer + 1,
//       color: '#fff',
//     },
//   }),
// );
export const Loader: React.FC<IProps> = ({show=false }: IProps) => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: Images.LoaderLottie,
		rendererSettings: {
		  preserveAspectRatio: "xMidYMid slice"
		}
	  };
	const { View } = useLottie(defaultOptions);
  const authLoader = useSelector((state: RootState) => state?.Auth.isLoading);
  const profileLoader = useSelector((state: RootState) => state?.Profile.isLoading);
  const drawerLoader = useSelector((state: RootState) => state?.Drawer.isLoading);
  const loadLoader = useSelector((state: RootState) => state?.Load.isLoading);
  const activeLoading = useSelector((state: RootState)=> state?.Load?.activeLoading)
//   const classes = useStyles();
  

  return (
    <Backdrop style={{
		zIndex: 1000,
      color: '#fff',
	}} open={authLoader || profileLoader || drawerLoader || loadLoader || activeLoading || show} >
      {/* <Lottie
        options={defaultOptions}
        height={200}
        width={200}
      /> */}
	  <div style={{
		height:200,
		width:200
	  }}>
	  {View}

	  </div>
	  {/* loading */}
    </Backdrop>
	// <div>loaderrr</div>
  );
};

export default Loader;
