import React, { FC, useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./style.scss";

import {
  SimpleCheckBox,
  VehicleTable,
  VehicleIncDec,
  CustomizedSelects,
  SimpleModal,
  CustomizedSwitches,
  OutLineButton,
  NewInput,
  CustomModal,
  CustomText,
  TextInput,
  RangeSlider,
} from "@components";
import { CounterAtion, ProfileAction } from "@temp/store/actions";
import InputAdornment from "@mui/material//InputAdornment";
import OutlinedInput from "@mui/material//OutlinedInput";

import { css } from "@emotion/css";
import Grid from "@mui/material//Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import { Images } from "@temp/assets";
import InfoIcon from "@mui/icons-material/Info";
import { LOAD_TYPE } from "@temp/constants";
import rootReducer from "../../store/reducers";
import { showMessage} from "@temp/utils/helper";

export type RootState = ReturnType<typeof rootReducer>;

var numeral = require("numeral");

const pageTitle = css({
  color: "rgb(13 21 9)",
  fontSize: "0.875rem",
  marginBottom: 12,
  display: "flex",
  alignItems: "center",
});

const SwitchableWithText = (props: any) => {
  return (
    <Grid
      container
      spacing={1}
      style={{ marginBottom: 0, alignItems: "center" }}
    >
      <Grid
        item
        xs={10}
        style={{ display: "flex", paddingLeft: 0 }}
      >
        {props?.icon && (
          <img src={props?.icon} width="16px" height="16px"></img>
        )}
        <div style={{ marginLeft: 5, marginTop: 0 }}>{props?.text}</div>
      </Grid>

      <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end", paddingLeft: 0 }}>
        <div style={{ marginTop: 0 }}>
          <CustomizedSwitches {...props} />
        </div>
      </Grid>
    </Grid>
  );
};

let timmerRef_weight_inc = null
let timmerRef_weight_dec = null

let timmerRef_vehicle_inc = null
let timmerRef_vehicle_dec = null
export const SelectVehicle: any = forwardRef((props: any, ref: any) => {
  const { pickup, index, handleNewVehicle, vehicles, handlerDelete } = props;
  const [temp, setTemp] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const PostLoad = useSelector((state: any) => state?.Profile?.postLoad);
  const postLoadMeta = useSelector((state: any) => state?.Profile?.postLoadMeta);
  const [editMode, setEditMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [info, setInfo] = useState("");
  const [showModalIcon, toggleModalIcon] = useState(false);
  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);

  const load_type = postLoadMeta.load_types.find(
    (val: object) => val.id === PostLoad.load_type
  );
  const { tempQuoteBy } = props;

  const [initialValues, setInitialValues] = useState({
    ...((load_type.title === LOAD_TYPE.bulk) && {
      quote_by: "vehicle",
      gross_weight: null,
      cargo_volume: null,
      total_freight_ton: null,
      no_of_packages: null,
      cargo_liter: null,
      is_genset: false,
      required_temperature: false

    }),

    ...((load_type.title === LOAD_TYPE.bulk) &&
      tempQuoteBy === "weight_volume" && {
      is_sidewall: null,
      is_boxed: null,
      gross_weight: null,
      cargo_liter: null,
      quote_by: "weight_volume",
    }),
  });
  const [categoryInitialValues, setCategoryInitialValues] = useState({
    is_twin_load: false,
    is_out_of_gauage: false,
    is_sidewall: false,
    is_boxed: false,
    is_loading_required: false,
    loading_requirements: "",
    is_unloading_required: false,
    unloading_requirements: "",
    is_shipper_owned_container: false,
    package_type_other: "",
  });
  const validationSchema = yup.object({
    ...((load_type.title === LOAD_TYPE.bulk) && {
      quote_by: yup.string().required(),
      gross_weight: yup.number().required(),
      cargo_volume: yup.mixed(),
      total_freight_ton: yup.number().required(),
      no_of_packages: yup.number().required(),
      cargo_liter: yup.number().required(),
    }),
  });
  const [bulkLoading, setBulkLoading] = useState({
    is_loading_required: false,
    loading_requirements: "",
    is_unloading_required: false,
    unloading_requirements: "",
  });
  const counterData = useSelector((state) => state?.Counter?.postLoadCounterData);


  const [showModal, setShowModal] = useState(false);
  const [packageModal, setPackageModal] = useState(false);
  const [max_vehicle_weight, set_max_vehicle_weight] = useState(1)
  const [vehicle_quantity, set_vehicle_quantity] = useState("")
  const [showContainerModal, setShowContainerModal] = useState(false);
  const [containerType, setContainerType] = React.useState(null);
  const [selectVehicleTypeObj, setSelectVehicleTypeObj] = React.useState(null);
  const [packageType, setPackageType] = React.useState(null);
  const [addedVehicles, setAddedVehicles] = useState([]);
  const [PreferredVehicleType, setPreferredVehicleType] = useState(null);
  const [showPreferredVehicleTypeModal, setShowPreferredVehicleTypeModal] = useState(false);


  useEffect(() => {
    if (PostLoad?.vehicleScreen) {
      let vehicleScreen = PostLoad?.vehicleScreen;
      if (vehicleScreen?.addedVehicles && vehicleScreen?.addedVehicles.length) {
        setAddedVehicles(vehicleScreen?.addedVehicles);
      }
    } else if (vehicles?.length > 0) {
      setAddedVehicles(vehicles);
    }

    if (load_type?.title === LOAD_TYPE.bulk || load_type?.title === LOAD_TYPE.bulk) {
      setPackageType(pickup?.attribute?.packageType || null);
    }
  }, [vehicles]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = (event.target as HTMLInputElement).value;
    let typeVehicle = allVehicleTypes.find(
      (val) => Number(val.id) === Number(id)
    );
    setShowModal(false);
    setSelectVehicleTypeObj(typeVehicle);
    set_max_vehicle_weight(1)


  };

  useEffect(() => {
    setPackageType(null);
  }, [tempQuoteBy]);

  const handleChangeContainer = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let id = (event.target as HTMLInputElement).value;
    let containerTypes = allContainerType.find(
      (val) => Number(val.id) === Number(id)
    );
    setShowContainerModal(false);
    setContainerType(containerTypes);
  };

  const handleChangePackage = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = (event.target as HTMLInputElement).value;
    let types = postLoadMeta?.package_types.find(
      (val: object) => Number(val.id) === Number(id)
    );
    setPackageModal(false);
    setPackageType(types);
    formikVehicleType.setFieldValue("package_type_other", "");

    if (load_type.title !== LOAD_TYPE.fcl && tempQuoteBy === "weight_volume") {
      props.handleChangeInLocationState(index, "packageType", types);
      props.handleChangeInLocationState(index, "package_type_other", "");

    }
  };

  const handleChangePreferredVehicleType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let id = (event.target as HTMLInputElement).value;
    let preferredVehicleTypes = allPreferredVehicleTypes.find(
      (val) => Number(val.id) === Number(id)
    );
    setShowPreferredVehicleTypeModal(false);
    setPreferredVehicleType(preferredVehicleTypes);

    if (load_type.title !== LOAD_TYPE.fcl && tempQuoteBy === "weight_volume") {
      props.handleChangeInLocationState(index, "PreferredVehicleType", preferredVehicleTypes);


    }
  };

  let allVehicleTypes = postLoadMeta?.vehicle_types.filter(
    (val) => val.load_type === PostLoad.load_type
  );
  let allContainerType = postLoadMeta.container_types;

  let allPreferredVehicleTypes = postLoadMeta.preferred_vehicles;


  const handleDelete = (ind: number) => {
    let tempAddedVehicles = addedVehicles.filter((val, index) => index !== ind);
    setAddedVehicles([...tempAddedVehicles]);
    handlerDelete(index, [...tempAddedVehicles]);
  };
  const validationSchemaVehicleType = yup.object({
    is_twin_load: yup.boolean(),
    is_out_of_gauage: yup.boolean(),
    is_boxed: yup.boolean(),
    is_sidewall: yup.boolean(),
    is_loading_required: yup.boolean(),
    is_shipper_owned_container: yup.boolean(),
    package_type_other:
      packageType && packageType?.title == "Other"
        ? yup.string().required()
        : yup.string(),

    loading_requirements: yup.string(),
    is_unloading_required: yup.boolean(),
    unloading_requirements: yup.string(),
  });

  var formikVehicleType = useFormik({
    initialValues: categoryInitialValues,
    validationSchema: validationSchemaVehicleType,
    onSubmit: (values) => {
      if (editMode) {
        let added = {
          selectVehicleTypeObj,
          vehicle_quantity,
          containerType,
          max_vehicle_weight,
          values,
          packageType,
        };
        addedVehicles[currentIndex] = added;
        setEditMode(false);
        setAddedVehicles([...addedVehicles]);
      } else {
        if (
          load_type.title === LOAD_TYPE.fcl ||
          ((load_type.title === LOAD_TYPE.bulk || load_type.title === LOAD_TYPE.bulk) &&
            tempQuoteBy === "vehicle")
        ) {
          let added = {
            selectVehicleTypeObj,
            vehicle_quantity,
            containerType,
            max_vehicle_weight,
            values,
            packageType,
            PreferredVehicleType,

          };
          setAddedVehicles([...addedVehicles, added]);
          handleNewVehicle(index, added);

        }

      }
      formikVehicleType.setValues(categoryInitialValues);

      set_vehicle_quantity("")

      setContainerType(null);
      set_max_vehicle_weight(1)

      setPackageType(null);
      setSelectVehicleTypeObj(null);
      setPreferredVehicleType(null);

    },
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      dispatch(
        ProfileAction.postLoadData({
          vehicleScreen: {
            values,
            addedVehicles,

            ...((load_type.title === LOAD_TYPE.bulk || load_type.title === LOAD_TYPE.bulk) &&
              tempQuoteBy === "weight_volume" && {
              packageType,
              PreferredVehicleType,

              values: {
                ...values,
                is_loading_required: false,
                loading_requirements: "",
                is_unloading_required: false,
                unloading_requirements: "",
                loading_price: 0,
                unloading_price: 0,
                total_amount: 0,
                price: 0,
              },
            }),
          },
        })
      );
      if (load_type.title === LOAD_TYPE.fcl) history.push("/additionalDetails");
      else history.push("/FCL_BULK_ADDITIONAL_DETAILS");
    },
  });

  const handleVehicleCategoryEdit = (index) => {
    setCurrentIndex(index);
    setEditMode(true);
    setSelectVehicleTypeObj(addedVehicles[index]?.selectVehicleTypeObj);
    dispatch(CounterAtion.setPostloadCounterData({ max_vehicle_weight: addedVehicles[index]?.vehicle_quantity }));

    setContainerType(addedVehicles[index]?.containerType);
    dispatch(CounterAtion.setPostloadCounterData({ max_vehicle_weight: addedVehicles[index]?.max_vehicle_weight }));

    setPackageType(addedVehicles[index]?.packageType);
    formikVehicleType.setValues(addedVehicles[index]?.values);
    set_vehicle_quantity(addedVehicles[index]?.vehicle_quantity)
    set_max_vehicle_weight(Number(addedVehicles[index]?.max_vehicle_weight))
    if (
      addedVehicles[index]?.values?.is_twin_load &&
      addedVehicles[index]?.selectVehicleTypeObj?.title === "40 ft Trailer"
    ) {
      setTemp(
        allVehicleTypes.find((val) => val.title === "40 ft Trailer")
          .min_capacity
      );
      dispatch(CounterAtion.setPostloadCounterData({ max_vehicle_weight: 45 }));

    }
  };

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const handleDisableAddCategory = () => {
    if (load_type.title === LOAD_TYPE.fcl && containerType && selectVehicleTypeObj)
      return false;
    else if (
      (load_type.title === LOAD_TYPE.bulk || load_type.title === LOAD_TYPE.bulk) &&
      tempQuoteBy === "weight_volume" &&
      packageType &&
      PreferredVehicleType !== null &&
      selectVehicleTypeObj
    )
      return false;
    else if (
      (load_type.title === LOAD_TYPE.bulk || load_type.title === LOAD_TYPE.bulk) &&
      tempQuoteBy === "vehicle" &&
      selectVehicleTypeObj &&
      (packageType && packageType?.title === "Other"
        ? formikVehicleType?.values?.package_type_other !== ""
        : packageType)

    )
      return false;
    else return true;
  };

  useImperativeHandle(ref, () => ({
    resetVehicles() {
      setAddedVehicles([])
    }
  }));

  return (
    <>
      <SimpleModal
        showModal={showModal}
        selected={selectVehicleTypeObj?.id}
        title={"Select Vehicle Type"}
        setType={handleChange}
        handleModal={(value: boolean) => {
          setShowModal(value);
        }}
        values={allVehicleTypes}
      ></SimpleModal>

      <SimpleModal
        showModal={packageModal}
        selected={packageType?.id || pickup?.attribute?.packageType?.id}
        title={"Select Packaging type"}
        setType={handleChangePackage}
        handleModal={(value: boolean) => {
          setPackageModal(value);
        }}
        values={
          postLoadMeta?.package_types && postLoadMeta?.package_types.length
            ? postLoadMeta?.package_types.filter(
              (val) => val.type === "package"
            )
            : []
        }
      ></SimpleModal>

      <SimpleModal
        showModal={showPreferredVehicleTypeModal}
        selected={PreferredVehicleType?.id}
        title={"Select Vehicle Type"}
        setType={handleChangePreferredVehicleType}
        handleModal={(value: boolean) => {
          setShowPreferredVehicleTypeModal(value);
        }}
        values={allPreferredVehicleTypes}
      >
      </SimpleModal>

      <SimpleModal
        showModal={showContainerModal}
        selected={containerType?.id}
        title={"Select Container Type"}
        setType={handleChangeContainer}
        handleModal={(value: boolean) => {
          setShowContainerModal(value);
        }}
        values={allContainerType}
      ></SimpleModal>

      <form className="" onKeyDown={onKeyDown} onSubmit={formik.handleSubmit}>
        <div className="selectVehicleContentWrap selectVehicleWrapper">
          {(load_type.title === LOAD_TYPE.bulk || load_type.title === LOAD_TYPE.bulk) && (
            <div style={{ marginTop: 10 }}>
              <div className="inputField">
                <div className="label">Gross Weight</div>
                <div className="field">
                  <NewInput
                    onChange={(e) => {
                      let value = e.target.value;
                      let cargo_volume = pickup?.attribute?.cargo_volume || 0;
                      if (value == ".0") {
                        showMessage.current({
                          message: "Please enter a valid value in Prices Fields",
                          status: "error"
                        })
                      } else {
                        props.handleChangeInLocationState(
                          index,
                          "gross_weight_in_kgs",
                          value
                        );
                        props.handleChangeInLocationState(
                          index,
                          "gross_weight",
                          Number(value / 1000)
                        );
                        props.handleChangeInLocationState(
                          index,
                          "total_freight_ton",
                          Number(value) > Number(cargo_volume) ? Number(value) / 1000 : cargo_volume
                        );
                      }
                    }
                    }
                    value={pickup?.attribute?.gross_weight_in_kgs}
                    showEnd={"KG"}
                    placeholder={tempQuoteBy == "weight_volume" ? "" : "Optional"}
                    decimal={true}
                  />
                </div>
              </div>

              <div className="inputField">
                <div className="label">Gross Weight in Tons</div>
                <div className="field">
                  <NewInput
                    id="total_freight_ton"
                    value={numeral(pickup?.attribute?.gross_weight || 0).format("0,0.00")}
                    type="text"
                    disabled={true}
                    aria-describedby="standard-weight-helper-text"
                    className="rightFormField customDisable"
                    showEnd="TON"
                  />
                </div>
              </div>

              <div className="inputField">
                <div className="label">Cargo Volume</div>
                <div className="field">
                  <NewInput
                    onChange={(e) => {
                      let cargo_volume = e.target.value;
                      let gross_weight = pickup?.attribute?.gross_weight || 0;
                      if (cargo_volume == ".0") {
                        showMessage.current({
                          message: "Please enter a valid value in Prices Fields",
                          status: "error"
                        })
                      }else {
                      props.handleChangeInLocationState(
                        index,
                        "cargo_volume",
                        cargo_volume
                      );

                      props.handleChangeInLocationState(
                        index,
                        "total_freight_ton",
                        Number(cargo_volume) > Number(gross_weight) ? cargo_volume : gross_weight
                      );
                    }}
                  }
                    value={pickup?.attribute?.cargo_volume ? pickup?.attribute?.cargo_volume : ""}
                    showEnd={"CBM"}
                    placeholder={"Optional"}
                    decimal={true}
                  />
                </div>
              </div>

              <div className="inputField">
                <div className="label">
                  {
                    "Total Weight"
                  }
                  {pickup?.attribute?.cargo_volume ? (
                    <InfoIcon
                      className="icon"
                      onClick={() => {
                        setInfo(
                          "Higher of weight in tons and volume in CBM."
                        );
                        toggleModalIcon(true);
                      }}
                    />
                  ) : null}
                </div>
                <div className="field">
                  <NewInput
                    id="total_freight_ton"
                    name="total_freight_ton"
                    value={numeral(pickup?.attribute?.total_freight_ton || null).format("0,0.00")}
                    type="text"
                    disabled={true}
                    aria-describedby="standard-weight-helper-text"
                    className="rightFormField customDisable"
                    showEnd={`${!pickup?.attribute?.cargo_volume ? "Ton" : "F.Ton"}`}
                  />
                </div>
              </div>

              <div className="inputField">
                <div className="label">
                  Number of Packages
                </div>
                <div className="field">
                  <NewInput
                    onChange={(e) => {
                      let no_of_packages = e.target.value;
                      props.handleChangeInLocationState(
                        index,
                        "no_of_packages",
                        no_of_packages
                      );
                    }}
                    value={pickup?.attribute?.no_of_packages ? pickup?.attribute?.no_of_packages : ""}
                    placeholder={"Optional"}
                  />
                </div>
              </div>

              {load_type.title !== LOAD_TYPE.fcl &&
                tempQuoteBy === "weight_volume" ?
                <>
                  <div className="inputField">
                    <div className="label">Liter</div>
                    <div className="field">
                      <NewInput
                        onChange={(e) => {
                          let cargo_liter = e.target.value;
                          if (cargo_liter == ".0") {
                            showMessage.current({
                              message: "Please enter a valid value in Prices Fields",
                              status: "error"
                            })
                          } else {
                          props.handleChangeInLocationState(
                            index,
                            "cargo_liter",
                            cargo_liter
                          );
                        }}}
                        value={pickup?.attribute?.cargo_liter ? pickup?.attribute?.cargo_liter : ""}
                        placeholder={"Optional"}
                        style={{ marginTop: 5 }}
                        showEnd={"liter"}
                        decimal={true}
                      />
                    </div>
                  </div>
                </> : null}
            </div>
          )}
          <div>
            {load_type.title === LOAD_TYPE.bulk && tempQuoteBy === "weight_volume" ?
              <div className="fw-5" style={{ marginTop: 15 }}> Select Vehicle Type</div>
              : null}
          </div>

          {load_type.title === LOAD_TYPE.bulk && tempQuoteBy === "weight_volume" && (
            <div style={{ marginTop: 10 }}>
              <CustomizedSelects
                selectedValue={PreferredVehicleType?.id || pickup?.attribute?.PreferredVehicleType?.id}
                values={postLoadMeta?.preferred_vehicles}
                disabled={true}
                modalState={true}
                placeholder={"Select Vehicle Type"}
                handleModal={(value: boolean) => {
                  setShowPreferredVehicleTypeModal(value);
                }}
              >
              </CustomizedSelects>
            </div>
          )}

          {(load_type.title === LOAD_TYPE.bulk || load_type.title === LOAD_TYPE.bulk) &&
            tempQuoteBy === "weight_volume" && (
              <div>
                <div className="fw-5" style={{ marginBottom: 10 }}>
                  Packaging type
                </div>

                <CustomizedSelects
                  placeholder={"Select Packaging type"}
                  selectedValue={
                    packageType?.id || pickup?.attribute?.packageType?.id
                  }
                  values={postLoadMeta?.package_types}
                  disabled={true}
                  modalState={true}
                  classProp="mb-3"
                  handleModal={(value: boolean) => {
                    setPackageModal(value);
                  }}
                ></CustomizedSelects>
                {packageType?.title === "Other" && (
                  <div className="inputField" style={{ marginTop: '1rem' }}>
                    <div className="field">
                      <TextInput
                        onChange={(e) => {
                          props.handleChangeInLocationState(
                            index,
                            "package_type_other",
                            e.target.value
                          );
                        }}
                        value={
                          pickup?.attribute?.package_type_other
                            ? pickup?.attribute?.package_type_other
                            : ""
                        }
                        type="text"
                        placeholder="Type your Packaging type here"
                        style={{ height: 41, width: "100%" }}
                        aria-describedby="standard-weight-helper-text"
                      />
                    </div>
                  </div>
                )}
                {
                  <>
                    <Grid item xs={12}>
                      <SwitchableWithText
                        id={"is_loading_required"}
                        handleChange={(e) => {
                          props.handleChangeInLocationState(
                            index,
                            "is_loading_required",
                            e.target.checked
                          );
                          props.handleChangeInLocationState(
                            index,
                            "loading_requirements",
                            ""
                          );
                        }}
                        checked={
                          pickup?.attribute?.is_loading_required || false
                        }
                        name="is_loading_required"
                        text="Loading Required"

                      />
                    </Grid>
                    {pickup?.attribute?.is_loading_required && (
                      <div className="inputField">
                        <div className="label">
                          Specify Labour / Equipment Requirement
                        </div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              props.handleChangeInLocationState(
                                index,
                                "loading_requirements",
                                e.target.value
                              );
                            }}
                            value={pickup?.attribute?.loading_requirements}
                            id={"loading_requirements"}
                            name="loading_requirements"
                            style={{ height: 35, width: "100%" }}
                            aria-describedby="standard-weight-helper-text"
                            placeholder="Optional"
                          />
                        </div>
                      </div>
                    )}
                    <Grid item xs={12}>
                      <SwitchableWithText
                        id={"is_unloading_required"}
                        name="is_unloading_required"
                        checked={
                          pickup?.attribute?.is_unloading_required || false
                        }
                        handleChange={(e) => {
                          props.handleChangeInLocationState(
                            index,
                            "is_unloading_required",
                            e.target.checked
                          );
                          props.handleChangeInLocationState(
                            index,
                            "unloading_requirements",
                            ""
                          );
                        }}
                        text="Unloading Required"
                      />
                    </Grid>
                    {pickup?.attribute?.is_unloading_required && (
                      <div className="inputField">
                        <div className="label">
                          Specify Labour / Equipment Requirement
                        </div>
                        <div className="field">
                          <TextInput
                            onChange={(e) => {
                              props.handleChangeInLocationState(
                                index,
                                "unloading_requirements",
                                e.target.value
                              );
                            }}
                            value={pickup?.attribute?.unloading_requirements}
                            id={"unloading_requirements"}
                            aria-describedby="standard-weight-helper-text"
                            className="rightFormField"
                            placeholder="Optional"
                          />
                        </div>
                      </div>
                    )}
                  </>
                }
              </div>
            )}
          {tempQuoteBy !== "weight_volume" && (
            <>
              <form
                className="formWrapper"
                onSubmit={formikVehicleType.handleSubmit}
              >
                <div className="fw-5" style={{ marginBottom: 10 }}>
                  Vehicle Type
                </div>

                <div onClick={() => { }}>
                  <CustomizedSelects
                    selectedValue={selectVehicleTypeObj?.id}
                    values={selectVehicleTypeObj ? allVehicleTypes : []}
                    disabled={true}
                    modalState={true}
                    placeholder={"Select Vehicle Type"}
                    handleModal={(value: boolean) => {
                      setShowModal(value);
                    }}
                  ></CustomizedSelects>
                </div>

                {(load_type.title === LOAD_TYPE.fcl ||
                  (load_type.title === LOAD_TYPE.bulk &&
                    tempQuoteBy === "vehicle")) && (
                    <div style={{ pointerEvents: selectVehicleTypeObj ? 'auto' : "none" }}>
                      <VehicleIncDec
                        className="labelWithIncrementField"
                        text="Max Cargo Weight Per Vehicle (Tons)"
                        name="max_vehicle_weight"
                        value={max_vehicle_weight}
                        withInputBox={true}
                        updateValue={(val) => {
                          set_max_vehicle_weight(val)
                        }}
                        onTouchStart={(type) => {
                          if (type == 'inc') {
                            timmerRef_weight_inc = setInterval(() => {
                              set_max_vehicle_weight((prev) => prev + 1)
                            }, 200);
                          } else {
                            if (max_vehicle_weight > 0) {
                              timmerRef_weight_dec = setInterval(() => {
                                set_max_vehicle_weight((prev) => {
                                  if (prev > 1) {
                                    return prev - 1
                                  } else {
                                    return 1
                                  }
                                })
                              }, 200);
                            }
                          }
                        }}
                        onTouchEnd={() => {
                          clearInterval(timmerRef_weight_inc);
                          clearInterval(timmerRef_weight_dec);
                        }}
                        onMouseDown={(type) => {
                          if (type == 'inc') {
                            set_max_vehicle_weight((prev) => prev + 1)
                            timmerRef_weight_inc = setInterval(() => {
                              set_max_vehicle_weight((prev) => prev + 1)
                            }, 200);
                          } else {
                            set_max_vehicle_weight((prev) => {
                              if (prev > 1) {
                                return prev - 1
                              } else {
                                return 1
                              }
                            })
                            timmerRef_weight_dec = setInterval(() => {
                              set_max_vehicle_weight((prev) => {
                                if (prev > 1) {
                                  return prev - 1
                                } else {
                                  return 1
                                }
                              })
                            }, 250);
                          }
                        }}
                        onMouseUp={() => {
                          clearInterval(timmerRef_weight_inc);
                          clearInterval(timmerRef_weight_dec);
                        }}
                        disableMin={
                          max_vehicle_weight
                            ? max_vehicle_weight > 0
                              ? false
                              : true
                            : true
                        }
                        disabledMax={false}
                      />
                    </div>
                  )}

                <div style={{
                  pointerEvents: selectVehicleTypeObj ? 'auto' : "none"
                }}>
                  <div className="inputField">
                    <div className="label">Estimated Number of Vehicles</div>
                    <div className="field">
                      <NewInput
                        onChange={(e) => {
                          let reg = new RegExp("^[0-9]*$");
                          let value = e.target?.value.replace(/[^\d]/g, "");
                          if (value && reg.test(value) && Number(value) > 0) {
                            if (value?.length <= 5) {
                              set_vehicle_quantity(Number(value))
                            }
                          } else {
                            e.target.value = null;
                            set_vehicle_quantity(null)
                          }
                        }}
                        value={vehicle_quantity}
                        placeholder={"(Optional)"}
                      />
                    </div>
                  </div>

                </div>

                <>

                  {/* for containerized  */}

                  {/* {PostLoad?.load_type == 1 && containerType?.code === "RF" ? ( */}
                  <div style={{ marginTop: '1rem', width: '100%' }}>
                    <SimpleCheckBox
                      id={"is_genset"}
                      labelName="is_genset"
                      name="is_genset"
                      title="Genset Required"
                      handleChange={(e) => {
                        setInitialValues({
                          ...initialValues,
                          is_genset: e.target.checked,
                        });
                      }}
                      checked={initialValues?.is_genset ? true : false}
                    />
                  </div>
                  {/* // ) : null} */}


                  {/* {initialValues?.is_genset ? */}
                  <div style={{ width: '100%' }}>
                    <div className="inputField" >
                      <div style={{ width: "100%", height: 25 }} className="flex_row_div">
                        <div className="label">Required Temperature</div>
                        {
                          initialValues?.required_temperature ?
                            <div className="label">{initialValues?.required_temperature} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                            : null
                        }
                      </div>
                      <div className="field rangeSliderWrap">
                        <RangeSlider
                          min={metaData?.temperature_range?.min}
                          max={metaData?.temperature_range?.max}
                          step={1}
                          value={Number(initialValues?.required_temperature) || 0}
                          orientation={'horizontal'}
                          tooltip={true}
                          onChange={(e) => {
                            // setInitialValues({
                            //   required_temperature: 
                            // });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* : null} */}

                  {/* for non-cont vehicle basis */}

                  {/* {load_type.title !== LOAD_TYPE.fcl ? (
                    <div style={{ marginTop: '1rem', width: '100%' }}>
                      <SimpleCheckBox
                        id={"is_genset"}
                        labelName="is_genset"
                        name="is_genset"
                        title="Genset"
                        handleChange={(e) => {
                          setInitialValues({
                            ...initialValues,
                            is_genset: e.target.checked,
                          });
                        }}
                        checked={initialValues?.is_genset ? true : false}
                      />
                    </div>
                  ) : null}


                  {initialValues?.is_genset ?
                    <div style={{ width: '100%' }}>
                      <div className="inputField" >
                        <div style={{ width: "100%", height: 25 }} className="flex_row_div">
                          <div className="label">Required Temperature</div>
                          {
                            initialValues?.required_temperature ?
                              <div className="label">{initialValues?.required_temperature} <span style={{ marginLeft: 3 }}>&#176;</span> C</div>
                              : null
                          }
                        </div>
                        <div className="field">
                          <RangeSlider
                            min={metaData?.temperature_range?.min}
                            max={metaData?.temperature_range?.max}
                            step={1}
                            value={Number(initialValues?.required_temperature) || 0}
                            orientation={'horizontal'}
                            tooltip={true}
                            onChange={(e) => {
                              // setInitialValues({
                              //   required_temperature: 
                              // });
                            }}
                          />
                        </div>
                      </div>
                    </div> : null} */}

                </>
                <div style={{
                  pointerEvents: selectVehicleTypeObj ? "auto" : 'none'
                }}>
                  {selectVehicleTypeObj?.is_boxed == 1 && (
                    <div style={{ display: "flex" }}>
                      <SimpleCheckBox
                        title="Box"
                        checked={formikVehicleType.values.is_boxed}
                        handleChange={formikVehicleType.handleChange}
                        labelName="is_boxed"
                        id="is_boxed"
                      />
                    </div>
                  )}
                  {selectVehicleTypeObj?.is_sidewall == 1 && (
                    <div style={{ display: "flex" }}>
                      <SimpleCheckBox
                        checked={formikVehicleType.values.is_sidewall}
                        handleChange={formikVehicleType.handleChange}
                        labelName="is_sidewall"
                        id="is_sidewall"
                        title="Side wall"
                      />
                    </div>
                  )}
                  {selectVehicleTypeObj?.is_support_twin_load === 1 && (
                    <div style={{ marginTop: 10 }}>
                      <SwitchableWithText
                        id={"is_twin_load"}
                        handleChange={(e) => {
                          if (
                            e.target.checked &&
                            selectVehicleTypeObj?.title === "40 ft Trailer"
                          ) {
                            setTemp(selectVehicleTypeObj.min_capacity);
                            dispatch(CounterAtion.setPostloadCounterData({ max_vehicle_weight: 45 }));

                            setSelectVehicleTypeObj({
                              ...selectVehicleTypeObj,
                              min_capacity: 45,
                            });
                          }
                          if (
                            !e.target.checked &&
                            selectVehicleTypeObj?.title === "40 ft Trailer"
                          ) {
                            selectVehicleTypeObj.min_capacity = temp;
                            dispatch(CounterAtion.setPostloadCounterData({ max_vehicle_weight: temp }));


                            setSelectVehicleTypeObj(selectVehicleTypeObj);
                            setTemp(null);
                          }
                          formikVehicleType.handleChange(e);
                        }}
                        name="is_twin_load"
                        text="Twin Load (2x20 Ft)"
                        checked={formikVehicleType.values.is_twin_load}
                      />
                    </div>
                  )}
                  {load_type.title === LOAD_TYPE.fcl && (
                    <div>
                      <div className="fw-5" style={{ marginBottom: 10 }}>
                        Container Type
                      </div>

                      <CustomizedSelects
                        selectedValue={containerType?.id}
                        values={containerType ? allContainerType : []}
                        disabled={true}
                        modalState={true}
                        placeholder={"Select Container Type"}
                        handleModal={(value: boolean) => {
                          setShowContainerModal(value);
                        }}
                      ></CustomizedSelects>
                    </div>
                  )}
                </div>

                {(load_type.title === LOAD_TYPE.bulk || load_type.title === LOAD_TYPE.bulk) && (
                  <div>
                    <div className="fw-5" style={{ marginBottom: 10 }}>
                      Packaging type
                    </div>

                    <CustomizedSelects
                      placeholder={"Select Packaging type"}
                      selectedValue={packageType?.id}
                      values={packageType ? postLoadMeta?.package_types : []}
                      disabled={true}
                      modalState={true}
                      classProp="mb-3"
                      handleModal={(value: boolean) => {
                        setPackageModal(value);
                      }}
                    ></CustomizedSelects>

                    {packageType?.title === "Other" && (
                      <div className="inputField" style={{ marginBottom: '1rem' }}>
                        <div className="field">
                          <TextInput
                            onChange={formikVehicleType.handleChange}
                            id="package_type_other"
                            name="package_type_other"
                            value={
                              formikVehicleType.values.package_type_other
                                ? formikVehicleType.values.package_type_other
                                : ""
                            }
                            type="text"
                            placeholder="Type your Packaging type here"
                            aria-describedby="standard-weight-helper-text"
                            className="rightFormField"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {((containerType &&
                  selectVehicleTypeObj &&
                  containerType.is_guage == 1 &&
                  selectVehicleTypeObj.is_guage == 1) ||
                  (load_type.title === "BULK" &&
                    tempQuoteBy === "vehicle")) && (
                    <div>
                      <SwitchableWithText
                        checked={formikVehicleType.values.is_out_of_gauage}
                        handleChange={formikVehicleType.handleChange}
                        name="is_out_of_gauage"
                        id="is_out_of_gauage"
                        text={
                          load_type.title === "BULK"
                            ? "Heavy Lift / Out of Gauge"
                            : "Out of Gauge"
                        }
                      />
                    </div>
                  )}

                {containerType &&
                  selectVehicleTypeObj &&
                  load_type.title === LOAD_TYPE.fcl &&
                  tempQuoteBy === "vehicle" && (
                    <div>
                      <SwitchableWithText
                        checked={
                          formikVehicleType.values.is_shipper_owned_container
                        }
                        handleChange={formikVehicleType.handleChange}
                        name="is_shipper_owned_container"
                        id="is_shipper_owned_container"
                        text={"Shipper Owned Container(SOC)"}
                      />
                    </div>
                  )}

                {load_type.title !== LOAD_TYPE.fcl && (
                  <>
                    <SwitchableWithText
                      id={"is_loading_required"}
                      handleChange={(e) => {
                        formikVehicleType.handleChange(e);
                        if (e.target.checked) {
                          formikVehicleType.setFieldValue(
                            "loading_requirements",
                            ""
                          );
                        }
                      }}
                      checked={
                        formikVehicleType.values?.is_loading_required || false
                      }
                      name="is_loading_required"
                      text="Loading Required"
                    />

                    {formikVehicleType.values?.is_loading_required && (
                      <>
                        <div className="inputField" style={{ marginTop: 10 }}>
                          <div className="label">
                            Specify Labour / Equipment Requirement
                          </div>
                          <div className="field">
                            <NewInput
                              onChange={formikVehicleType.handleChange}
                              value={
                                formikVehicleType?.values?.loading_requirements
                              }
                              id={"loading_requirements"}
                              name="loading_requirements"
                              aria-describedby="standard-weight-helper-text"
                              className="rightFormField"
                              placeholder="Optional"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <SwitchableWithText
                      id={"is_unloading_required"}
                      name="is_unloading_required"
                      checked={
                        formikVehicleType.values?.is_unloading_required ||
                        false
                      }
                      handleChange={(e) => {
                        formikVehicleType.handleChange(e);
                        if (e.target.checked) {
                          formikVehicleType.setFieldValue(
                            "unloading_requirements",
                            ""
                          );
                        }
                      }}
                      text="Unloading Required"
                    />

                    {formikVehicleType.values?.is_unloading_required && (
                      <>
                        <div className="inputField" style={{ marginTop: 10 }}>
                          <div className="label">
                            Specify Labour / Equipment Requirement
                          </div>
                          <div className="field">
                            <TextInput
                              onChange={formikVehicleType.handleChange}
                              value={
                                formikVehicleType?.values?.unloading_requirements
                              }
                              id={"unloading_requirements"}
                              name="unloading_requirements"
                              style={{ height: 35, width: "100%" }}
                              aria-describedby="standard-weight-helper-text"
                              className="rightFormField"
                              placeholder="Optional"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div style={{ marginBottom: 20, marginTop: 10 }}>
                  <OutLineButton
                    type={"submit"}
                    title={editMode ? "Update Category" : "Add Another Category"}
                    disabled={handleDisableAddCategory()}
                  />
                </div>
              </form>
              {addedVehicles && !!addedVehicles.length && (
                <VehicleTable
                  screenTitle={load_type.title}
                  onClickDelete={(index: number) => {
                    handleDelete(index);
                  }}
                  loadType={load_type.title}
                  addedVehicles={addedVehicles?.sort((a, b) => a?.selectVehicleTypeObj?.title?.split(" ")[0] - b?.selectVehicleTypeObj?.title?.split(" ")[0])}
                  handleEdit={handleVehicleCategoryEdit}
                />
              )}
            </>
          )}

          <CustomModal
            modalStyle={{
              width: 300,
              minHeight: 100,
            }}
            showModal={showModalIcon}
            toggleModal={toggleModalIcon}
          >
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InfoIcon color="primary" style={{ fontSize: 40 }} />
              <CustomText.OpenSansRegular>{info}</CustomText.OpenSansRegular>
            </div>
          </CustomModal>
        </div>
      </form>
    </>
  );
});
