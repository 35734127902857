import { initializeApp } from "firebase/app";
// import firebase from 'firebase/app';
import { getAuth } from "firebase/auth";
import "firebase/messaging";
import React from "react";
import { ReactNotifications, Store } from "react-notifications-component";
import { Images } from "@temp/assets";
import { isSupported } from "@utils/helper";
import { store } from "@temp/store";
import { LoadAction, ProfileAction } from "@temp/store/actions";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// const firebaseConfig = {
//     apiKey: "AIzaSyCTw7qiJu0A9rOX7L1vQViQI9iCifoUPhQ",
//     authDomain: "mg-meri-gari.firebaseapp.com",
//     projectId: "mg-meri-gari",
//     storageBucket: "mg-meri-gari.appspot.com",
//     messagingSenderId: "228601598204",
//     appId: "1:228601598204:web:4415cd1465d42596a40779",
//     measurementId: "G-EVXEE3X2E5",
// };

const firebaseConfig = {
    apiKey: "AIzaSyCTw7qiJu0A9rOX7L1vQViQI9iCifoUPhQ",
    authDomain: "mg-meri-gari.firebaseapp.com",
    projectId: "mg-meri-gari",
    storageBucket: "mg-meri-gari.appspot.com",
    messagingSenderId: "228601598204",
    appId: "1:228601598204:web:866f377ca8bb6990a40779",
    measurementId: "G-FFLC4C6XXT"
};
const VAPID_KEY = "BOziaVBbxbZBgzBu7e2x8VOM83KlEvDDR4OfG_R9HMTGREIRFEFzdnoOR_Mhluhg8n8HhtWacoikVZemxK6BenM"

export const firebaseNew = initializeApp(firebaseConfig);
let messaging;
// let messaging;
if (isSupported()) {
    // messaging = firebase.messaging();
    // messaging = getMessaging();
    messaging = getMessaging(firebaseNew);
}
// export const registerServiceWorker = () => {
// 	if ("serviceWorker" in navigator && isSupported()) {
// 		
// 		 navigator.serviceWorker
// 			.register("firebase-messaging-sw.js")
// 			.then(function (registration) {
// 				
// 				return registration.scope;
// 			})
// 			.catch(function (err) {
// 				
// 				return err;
// 			});
// 	}
// };
export const registerServiceWorker = async () => {
    try {
        if ("serviceWorker" in navigator && isSupported()) {
            
            const registration = await navigator.serviceWorker.register("firebase-messaging-sw.js")
            
            return registration.scope;
        }
    }
    catch (err) {
        
        return err;
    };
};
registerServiceWorker();

export const requestFirebaseNotificationPermission = async () => {
    return new Promise(async (resolve, reject) => {
        

        if (isSupported()) {
            try {
                const currentToken = await getToken(messaging, { vapidKey: VAPID_KEY });
                if (currentToken) {
                    
                    resolve(currentToken);
                } else {
                    // Show permission request UI
                    
                }
            } catch (err) {
                reject("no token")
                
            }
        } else {
            
            reject("not supported");
        }
    });
}
// export const requestFirebaseNotificationPermission = async () =>{
// // export const requestFirebaseNotificationPermission = () =>
// 
// // await new Promise((resolve, reject) => {
//   new Promise((resolve, reject) => {
//     if (isSupported()) {
//         
//         
// // Notification
//        messaging
//                 .requestPermission()
//                 .then(() => {
//                     
//                     messaging.getToken()})
//                 .then((firebaseToken) => {
//                     
//                     resolve(firebaseToken);
//                 })
//                 .catch((err) => {
//                     
//                     reject(err);
//                 });
//         } else {
//             
//             reject("not supported");
//         }
//     });
// }
// export const onMessageListener = async (callbackNotification) => {

const callback = (payload) => {
    store?.dispatch(
        ProfileAction.setDashboardFilters({
            loadType: "Loads Received",
            inquiryType:
                payload?.data?.load_type == "spot_rate" ? "spot" : "contract",
            activeCategory: "new",
        })
    );
    store?.dispatch(
        LoadAction.getLoads({
            refresh: false,
            fromNoti: true,
            inquiry_type:
                payload?.data?.load_type == "spot_rate" ? "spot_rate" : "contract_rate",
            status: "new",
            load_post_type: "received",
            load_allocate_type: "all",
            search: "",
            next_page: 1,
            load_type: "all",
        })
    );
};
export const onMessageListener = (callbackNotification) =>
 new Promise((resolve) => {
        // return new Promise( (resolve) => {
        onMessage(messaging, (payload) => {
            // messaging.onMessage((payload) => {
            
            const {
                notification: { body, title, click_action },
            } = payload;
            if (payload?.data?.status_code) {
                callback(payload);
            }

            Store.addNotification({
                content: (
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            minWidth: "256px",
                            backgroundColor: "rgb(122, 194, 81)",
                            borderRadius: 5,
                            color: "white",
                        }}
                        onClick={() => {
                            callbackNotification(click_action, { title, body, report_url: payload?.data?.report_url });
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img
                                src={Images.MgLogo}
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    marginLeft: ".5rem",
                                    marginRight: 10,
                                    objectFit: "contain",
                                }}
                                alt=""
                            />
                            <div>
                                <h3>{title}</h3>
                                <p>
                                    {click_action ==
                                        "https://mg-dev-poster-recipient-app.herokuapp.com/all-notification"
                                        ? body.length > 50
                                            ? body?.substring(1, 50) + "..."
                                            : body
                                        : body}
                                </p>
                            </div>
                        </div>
                    </div>
                ),
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                },
            });
        });
    });

