export default class Endpoints {
  // auth
  static LOGIN_WITH_OTP = `auth/login-with-otp`;
  static LOGIN_WITH_PASSWORD = `auth/login-with-number`;
  static SIGNUP = `auth/register`;
  static VERIFY_CODE = `auth/verify-otp`;
  static UPDATE_TERMS = `me`;
  static LOGOUT = "auth/logout";
  static GET_META_DATA = `meta`;
  static SETUP_PASSWORD = `me`;
  static FORGOT_PASSWORD = "auth/forgot-with-number";
  static JOB_LOAD_META = (params) => `job-loads/meta?id=${params}`;

  //profile
  static CHANGE_PHONE_NO_REQUEST = `auth/number-change/request-otp`;
  static CHANGE_PHONE_NO = `auth/number-change/verify-otp`;
  static GET_NETWORK_URL = (params) =>
    `my-networks?only_company_users=${params?.only_company_users}`;
  static SEARCH_NETWORK_URL = (params) =>
    `my-networks/company-networks?phone_no=${params.phone}`;
  static ADD_NETWORK_URL = `my-networks`;
  static ADD_OTP_NETWORK = "my-networks/add-by-qr-code";
  static GET_SAVED_LOCATIONS = `locations?model_name=user`;
  static GET_SAVED_PROMOTIONS = `promotions`;
  static GET_PROMOTION_DETAIL = (params) => `promotions/${params.id}`;
  static ADD_LOCATION = `locations`;
  static GET_VEHICLE_URL = `vehicles`;
  static DEL_PROFILE_IMAGE = `me/picture`;
  static UPDATE_LOCATION_API = (params) => `locations/bulk-update?load_id=${params}`;


  // Loads

  // job-loads/active-job-loads
  static GET_ACTIVE_LOADS_URL = (params) => `job-loads/active-job-loads?page=${params?.next_page}&load_post_type=${params?.load_post_type}&job_load_status[]=active&search=${params?.search}`;
  static GET_LOADS_URL = (params) => `job-loads?page=${params?.next_page}&load_post_type=${params?.load_post_type}&inquiry_type=${params?.inquiry_type}&status=${params?.status}&load_type=${params?.load_type}&job_load_status[]=active&load_allocate_type=${params?.load_allocate_type}&search=${params?.search}`;
  static GET_LOADS_HISTORY_URL = (params) =>
    `job-loads?page=${params?.next_page}&load_post_type=${params?.load_history_last_page_type}&load_type=${params?.load_history_type}&inquiry_type=${params?.inquiry_type}&job_load_status[]=cancelled&job_load_status[]=completed&job_load_status[]=active&status=completed&search=${params?.search}&statuses[]=${params?.statuses}`;
  static GET_LOAD_DETAIL_URL = (params) => `job-loads/${params.load_id}`;
  static ACCEPT_LOAD_URL = (params) =>
    `job-loads/${params.load_id}/respond-by-recipient`;
  static AWARD_LOAD_URL = (params) => `job-loads/${params?.load_id}/award`;
  static DECLINE_BY_RECIPIENT = (params) =>
    `job-loads/${params?.load_id}/decline`;
  static DECLINE_BY_POSTER = (params) => `job-loads/${params?.load_id}/decline`;

  // vehicle
  static ADD_VEHICLE_URL = `vehicles`;
  static EDIT_VEHICLE_URL = (params) => `vehicles/${params.id}`;
  static DELETE_VEHICLE_URL = (params) => `vehicles/${params.id}`;
  static JOB_LOAD = `job-loads`;
  static DELETE_LOCATION_URL = (params) =>
    `locations/${params}?model_name=user`;
  static UPLOAD_FILE = `shared/upload-file`;
  static GET_ACCOUNT_STATEMENT_URL = `invoices`;
  static GET_NOTIFICATIONS_LISTING = (params) => `notifications?page=${params?.next_page}&query=${params?.query}`;

  // loads
  static GET_LOAD_DRAFT_URL = `job-loads?job_load_status[]=draft&load_post_type=posted&load_type=all`;
  static ALLOCATE_LOAD_URL = (params) => `job-loads/${params.load_id}/allocate`;
  static FORWARD_LOAD_URL = (params) => `job-loads/${params.load_id}/forward`;
  static UPDATE_RESPONSE_DEADLINE_API = (params) =>
    // `job-loads/${params?.load_id}/update-deadline`;
    `job-loads/${params?.load_id}/update-load-dates`;
  static ADD_DRIVER_AND_REF = (params) =>
    `job-loads/${params?.load_id}/add-vehicle-ref-by-recipient`;
  static UPDATE_FCM = () => `update-device-token`;
  static CREATE_FROM_DRAFT = (params) =>
    `job-loads/${params?.id}/create-from-draft`;
  static CANCEL_LOAD_HOME_SCREEN_URL = (params) =>
    `job-loads/${params.load_id}/cancel`;
  static GET_ACTIVE_LOAD_DETAIL_URL = (params) =>
    `job-loads/${params.load_id}/active/${params.job_load_driver_id}`;
  static CONTACT_PICK_UP_DROP_OFF_URL = (params) =>
    `job-loads/${params.load_id}/update-contact-details`;
  static GET_VEHICLE_MARKER = (params) =>
    `job-loads/${params.load_id}/vehicle/${params.job_load_driver_id}/last-location`;
  static GET_DRIVER_MARKER = (params) =>
    `drivers/${params.job_load_driver_id}/last-location`;
  static POST_REQUEST_VEHICLE_URL = (params) =>
    `job-loads/${params.load_id}/request-vehicles`;
  static ACCEPT_REQUEST_VEHILE_URL = (params) =>
    `job-loads/${params?.load_id}/accept-by-recipient`;
  static ADD_VIEWER_URL = (params) =>
    `job-loads/${params?.load_id}/add-viewers`;
  static DELETE_NETWORK_URL = (params) => `my-networks/delete`;
  static GET_FAQS_URL = `faqs`;
  static GET_LOADS_COUNT = `job-loads/count`;
  static ADDITIONAL_REQUIREMENTS_URL = (params) =>
    `job-loads/${params.load_id}/update-additional-requirements`;
  static FIRST_LOG_IN = `auth/check-password-by-number`;
  static MARK_AS_COMPLETE_BY_RECIPIENT = (params) =>
    `job-loads/${params?.load_id}/mark-complete-by-recipient`;
  static MARK_AS_COMPLETE_BY_POSTER = (params) =>
    `job-loads/${params?.load_id}/mark-complete-by-poster`;
  static DELETE_ACCOUNT_URL = `me/destroy`;
  static UPDATE_DRIVER_CHECKLIST = (params) => `job-loads/${params?.load_id}/driver-checklist-by-recipient/${params?.checklist_id}`
  static NEGOTIATE_BID_POSTER_URL = (params) => `job-loads/${params?.load_id}/negotiate-bid-by-poster/${params?.job_load_recipient_bid_id}`
  static NEGOTIATE_BID_ACCEPT_REJECT_RECIPIENT_URL = (params) => `job-loads/${params?.load_id}/negotiate-bid-by-recipient/${params?.job_load_recipient_bid_id}`
  static AWARD_CONTRACT_URL = (params) => `job-loads/${params?.load_id}/award-contract`
  static ADD_VEHICLE_LAST_LOCATION_URL = (params) => `job-loads/${params?.load_id}/add-vehicle-location-by-recipient`
  static UPLOAD_FILES_RECIPIENT_URL = (params) => `job-loads/driver-uploads-by-recipient/${params}`
  static LOAD_TRACKING_CREDENTIALS = (params) => `job-loads/${params?.load_id}/add-tracking-by-recipient`;
  static ADD_BULK_VEHICLES_API = (params) => `job-loads/${params?.load_id}/add-bulk-vehicles-by-recipient${params?.is_replace ? "?is_replace=1" : ""}`
  static ADD_BULK_VEHICLE_REFS_API = (params) => `job-loads/${params?.load_id}/add-bulk-vehicle-refs-by-recipient`;
  static UPLOAD_DOC_RECIPIENT_URL = (params) => `job-loads/${params?.load_id}/uploads-by-recipient`
  static DASHBOARD_REPORTS = (params) => `dashboard-stats?loads_by=${params?.loads_by}&date_range_from=${params?.date_range_from}&date_range_to=${params?.date_range_to}&date_type=${params?.date_type}`
  static DOWNLOAD_CSV_REPORTS = (params) => `dashboard-stats/download?status_code=dashboard_report_generated&loads_by=${params?.loads_by}&report=${params?.report}&search=${params?.search}&date_range_from=${params?.date_range_from}&date_range_to=${params?.date_range_to}&vehicle_type=${params?.vehicle_type}`
  static DOCUMENTS_MG_EXPRESS = (params) => `job-loads/${params?.load_id}`
  static SHOW_INDICATION_DOTS = (params) => `job-loads/${params?.load_id}/seen-by-recipient/`
  static SHOW_INDICATION_DOTS_VIEWER = (params) => `job-loads/${params?.load_id}/seen-by-viewer`
  static REMOVE_VIEWER = (params) => `job-loads/remove-viewer/${params?.viewer_id}`
  static GET_PIN_POINTS_FOR_TRACKER =(params)=>  `job-loads/${params.load_id}/vehicles/live-locations`
  static REMOVE_DOCUMENT = (params) => `job-loads/driver-uploads-by-recipient/${params?.upload_api}`
  static DASHBOARD_DATA = (params) => `dashboard-stats?page=${params?.next_page}&search=${params?.search}&loads_by=${params?.loads_by}&date_range_from=${params?.date_range_from}&date_range_to=${params?.date_range_to}&date_type=${params?.date_type}&stats=${params?.stats}&vehicle_type=${params?.vehicle_type}`
  
  
  static UPDATE_DRIVER_DETAIL = (params) => `vehicles/${params.id}/driver-update/${params?.driver_id}`

}



