import { AccordionSummary, Checkbox } from "@mui/material/";
import { Colors, Images } from "@temp/assets";
import { CustomText } from "@temp/components";
import React from "react";
import "../LoadDetailPoster/style.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../LoadDetailPoster/style.scss";

export const CategoryCargoHeading = ({ loadDetail, item, index, categoryLength, is_recipient = false, }) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      
      <div className="category-title-container">
        <CustomText.OpenSansSemiBold
          textStyle={{
            fontSize: "0.875rem",
            color: Colors.Black,
          }}
        >
          {
            loadDetail?.quote_by == 'vehicle' ?
              <>
                {
                  categoryLength > 1 ?
                    <>
                      <span>Vehicle Category</span>
                      <span>{` ${index + 1}`}</span>
                      {
                        is_recipient &&
                        <>
                          <span className="seperator">/</span>
                          <span>{` ${index + 1} `}</span>
                          <span className="urduLabel">گاڑی کی کیٹاگری </span>
                        </>
                      }
                    </>
                    :
                    <>
                      <span>Vehicle Category</span>
                      {
                        is_recipient &&
                        <>
                          <span className="seperator">/</span>
                          <span className="urduLabel">گاڑی کی کیٹاگری </span>
                        </>
                      }
                    </>
                }
              </> :
              (loadDetail?.quote_by == 'weight_volume' && !loadDetail?.is_unit_basis) ?
                <>
                  <span>Cargo Details</span>
                  {
                    is_recipient &&
                    <>
                      <span className="seperator">/</span>
                      <span className="urduLabel">کارگو کی تفصیلات</span>
                    </>
                  }
                </> :
                (loadDetail?.quote_by == 'weight_volume' && loadDetail?.is_unit_basis) ?
                  <>
                    {
                      categoryLength > 1 ?
                        <>
                          <span>Item Category</span>
                          <span>{` ${index + 1}`}</span>
                          {
                            is_recipient &&
                            <>
                              <span className="seperator">/</span>
                              <span>{` ${index + 1} `}</span>
                              <span className="urduLabel"> آئٹم کی کیٹاگری </span>
                            </>
                          }
                        </>
                        :
                        <>
                          <span>Item Category</span>
                          {
                            is_recipient &&
                            <>
                              <span className="seperator">/</span>
                              <span className="urduLabel"> آئٹم کی کیٹاگری </span>
                            </>
                          }
                        </>
                    }
                    {
                      //categoryLength > 1 ? `Item Category ${index + 1}` : "Item Category"
                    }
                  </> : null

          }

        </CustomText.OpenSansSemiBold>
        <div className="chip">
          {loadDetail?.new_category ? (
            <>
              <span>
                {loadDetail?.quote_by == "vehicle"
                  ? loadDetail?.new_category?.vehicle_quantity + `${loadDetail?.new_category?.vehicle_quantity > 1 ? "Vehicles" : "Vehicle"}`
                  : loadDetail?.no_of_packages ? loadDetail?.no_of_packages + `${loadDetail?.no_of_packages > 1 ? "Packages" : "Package"}` : "N/A"}
              </span>
              {
                is_recipient &&
                <>
                  <span className="seperator">/</span>
                  <span className="urduLabel">
                    {
                      loadDetail?.quote_by == "vehicle"
                        ? loadDetail?.new_category?.vehicle_quantity + `${loadDetail?.new_category?.vehicle_quantity > 1 ? "گاڑیاں" : "گاڑی"}`
                        : loadDetail?.no_of_packages && loadDetail?.no_of_packages + `${loadDetail?.no_of_packages > 1 ? "پیکیجز " : "پیکج "}`
                    }
                  </span>
                </>
              }
            </>
          ) : (
            <>

              <span>
                {
                  loadDetail?.quote_by == "vehicle" ?
                    `${item?.vehicle_quantity} ${item?.vehicle_quantity > 1 ? "Vehicles" : "Vehicle"}` :
                    loadDetail?.quote_by == "weight_volume" && !loadDetail?.is_unit_basis ?
                      (loadDetail?.no_of_packages) ? `${loadDetail?.no_of_packages} ${loadDetail?.no_of_packages > 1 ? "Packages" : "Package"}` : "N/A" :
                      loadDetail?.quote_by == "weight_volume" && loadDetail?.is_unit_basis ?
                        `${item?.unit_quantity} ${item?.unit_quantity > 1 ? "Items" : "Item"}`
                        : null
                }
              </span>
              {
                is_recipient &&
                <>
                  {
                    loadDetail?.quote_by == "weight_volume" && !loadDetail?.is_unit_basis ?
                      <>
                        {loadDetail?.no_of_packages ?
                          <>
                            <span className="seperator">/</span>
                          </> : null
                        }
                      </> :
                      <>
                        <span className="seperator">/</span>
                      </>
                  }
                  <span className="urduLabel">
                    {
                      loadDetail?.quote_by == "vehicle" ?
                        `${item?.vehicle_quantity} ${item?.vehicle_quantity > 1 ? "گاڑیاں" : "گاڑی"}` :
                        loadDetail?.quote_by == "weight_volume" && !loadDetail?.is_unit_basis ?
                          (loadDetail?.no_of_packages) ? `${loadDetail?.no_of_packages} ${loadDetail?.no_of_packages > 1 ? "پیکیجز" : "پیکج"}` : "" :
                          loadDetail?.quote_by == "weight_volume" && loadDetail?.is_unit_basis ?
                            `${item?.unit_quantity} ${item?.unit_quantity > 1 ? "آئٹمز" : "آئٹم"}`
                            : null
                    }
                  </span>
                </>
              }
            </>
          )}
        </div>
      </div>
    </AccordionSummary>
  );
};
