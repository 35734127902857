import React, {  useEffect, useState } from "react";
import TitleLayout from "@temp/components/TitleLayout";
import { LocationTimeline, PrimaryButton } from "@temp/components";
import "./style.scss";
import { useHistory } from "react-router";
import {
  SELECT_VEHICLE_CONTRACT,
} from "@temp/app/routes";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAction } from "@temp/store/actions";
import { showMessage } from "@temp/utils/helper";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";

export const CFLocation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const postLoadLocations = useSelector(
    (state:any) => state?.Profile?.postLoad?.location
    );
    const postLoadData = useSelector(
      (state:any) => state?.Profile?.postLoad
      );
      const [locations, setLocations] = useState(postLoadLocations);

  useEffect(() => {
    setLocations(postLoadLocations);
  }, [postLoadLocations]);

  const deleteLocation = (index) => {
    locations?.splice(index, 1);
    setLocations([...locations]);
    dispatch(
      ProfileAction.postLoadData({
        location: locations,
      })
    );
  };

  const handleAdditionalDetail = (data, id) => {
    const updatedData = locations?.map((loc, index) => {
      if (id === index) {
        loc = data;
      }
      return loc;
    });
    setLocations([...updatedData]);
    dispatch(
      ProfileAction.postLoadData({
        location: [...updatedData],
      })
    );
  };

  // Listing all locations from data

  const LocationCardList = locations?.map((location, index) => (
    <FadeInComponent className="mb-3">
      <LocationCards
        location={location}
        key={index}
        index={index}
        handleAdditionalDetail={handleAdditionalDetail}
        deleteLocation={deleteLocation}
      />
    </FadeInComponent>
  ));

  return (
    <TitleLayout title="Add Routes " pageNumber={4} progress={40}>
      <div className="locationCardWrapper">
        {/* Added location list */}
        {LocationCardList}

        {/* Add new location section */}
        <FadeInComponent>
          <div
            className="addNewLocation"
            style={{ cursor: "pointer" }}
            onClick={() =>
              {
                if(locations.length>=20){
                  showMessage.current({message:'You can not add more than 20 routes.', status:'error'})
                }else{
                  history.push(`/contract-location/add-new`,{from:'contract'})
                }
              }
            }
          >
            <p className="addNewLocationBtn">Add {locations.length>0&&'Another '} Route</p>
          </div>
        </FadeInComponent>
      </div>
        <div className="floatBtn">
            <PrimaryButton
              title="Continue"
              disabled={locations?.length > 0 ? false : true}
              onClick={() => {
                if(postLoadData?.load_type == 1){
                  history.push('select-vehicle-contract-new')
                }else{
                  history.push(SELECT_VEHICLE_CONTRACT);
                }
              }}
            />
        </div>
    </TitleLayout>
  );
};

// Single location card component
const LocationCards = (props) => {
  const { location, index, deleteLocation, handleAdditionalDetail } = props;

  const data = [
    { ...location?.pickup, card_type: "pickup" },
    { ...location?.dropoff, card_type: "dropoff" },
  ];
  return (
    <div className="locationCard">
      <div className="locationCardHeader">
        <span className="">{`Route ${index + 1} : ${data[0]?.title} - ${data[1]?.title}`}</span>
        <span className="icons-cross" onClick={() => deleteLocation(index)} ></span>
      </div>
      <div className="locationCardBody">
        <LocationTimeline
          location={data}
          id={index}
          handleModal={(value: boolean) => {}}
          handleAdditionalDetail={handleAdditionalDetail}
          disabled={0}
          innerAddDetail={true}
        ></LocationTimeline>
      </div>
    </div>
  );
};
