import { LOAD_TYPE } from "@temp/constants";
import React from "react";
import "../LoadDetailPoster/style.scss";
import { RenderRTL } from "./RenderRTL";
var numeral = require("numeral");

export const TwoColumnRTLContractWeight = ({ loadDetail, location, type, is_recipient }) => {
  return (
    <div>

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk
          ?
          <RenderRTL
            title={"Gross Weight in KG"}
            sep={"/"}
            urdutitle={"مجموعی وزن (کلو)"}
            val={`${numeral(Number(location?.pickup?.attributes?.gross_weight * 1000)).format("0,0.00")} KG `}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk ?
          <RenderRTL
            title={"Gross Weight in Ton"}
            sep={"/"}
            urdutitle={"مجموعی وزن (ٹن)"}
            val={`${numeral(location?.pickup?.attributes?.gross_weight).format("0,0.00")} TON `}
            is_recipient={is_recipient}
          /> : null

      }

      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk) && location?.pickup?.attributes?.cargo_volume ?
          <RenderRTL
            title={"Cargo Volume"}
            sep={"/"}
            urdutitle={"کارگو والیوم"}
            val={location?.pickup?.attributes?.cargo_volume ? `${location?.pickup?.attributes?.cargo_volume} CBM ` : "N/A"}
            is_recipient={is_recipient}
          /> : null
      }
      {/* {
        location?.pickup?.attributes?.required_temperature_min && location?.pickup?.attributes?.required_temperature_max ?
          <RenderRTL
            title={"Required Temperature"}
            sep={"/"}
            urdutitle={"مطلوبہ درجہ حرارت"}
            val={`Min: ${location?.pickup?.attributes?.required_temperature_min}`}
            val2={`Max: ${location?.pickup?.attributes?.required_temperature_max}`}
            is_recipient={is_recipient}
          /> : null
      } */}
      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk ?
          <RenderRTL
            title={"Total Weight"
              // (location?.pickup?.attributes?.cargo_volume && location?.pickup?.attributes?.gross_weight) ? "F.Ton" : "Ton"
            }
            sep={"/"}
            urdutitle={"کل وزن"
              // (location?.pickup?.attributes?.cargo_volume && location?.pickup?.attributes?.gross_weight) ? "فریٹ ٹن" : "ٹن"
            }
            val={`${numeral(location?.pickup?.attributes?.total_freight_ton).format('0,0.00')} ${!location?.pickup?.attributes?.cargo_volume ? " TON"
              : " F.TON"} `}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk ?
          <RenderRTL
            title={"Package Type"}
            sep={"/"}
            urdutitle={"پیکیج کی قسم"}
            val={
              location?.pickup?.attributes?.package_type.title == "Other" ?
                `Other(${location?.pickup?.attributes?.package_type_other})`
                : location?.pickup?.attributes?.package_type?.title
            }
            is_recipient={is_recipient}
          /> : null
      }

      {
        location?.pickup?.attributes?.preferred_vehicle && loadDetail?.load_type?.title == LOAD_TYPE.bulk ?
          <RenderRTL
            title={"Vehicle Type"}
            sep={"/"}
            urdutitle={"گاڑی کی قسم"}
            val={`${location?.pickup?.attributes?.preferred_vehicle.title}`}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.quote_by !== "vehicle" && location?.pickup?.attributes?.no_of_packages &&
        <RenderRTL
          title={"Number Of Packages"}
          sep={"/"}
          urdutitle={"پیکیجز کی تعداد"}
          val={`${location?.pickup?.attributes?.no_of_packages}`}
          is_recipient={is_recipient}
        />
      }

      <RenderRTL
        title={"Free Time Required"}
        sep={"/"}
        urdutitle={"فری وقت درکار "}
        val={`${location?.pickup?.attributes?.detention_free_time} hrs`}
        is_recipient={is_recipient}
      />

      {
        location?.pickup?.attributes?.cargo_liter ?
          <RenderRTL
            title={"Liter"}
            sep={"/"}
            urdutitle={"لیٹر"}
            val={`${numeral(location?.pickup?.attributes?.cargo_liter).format("0,0.00")} liter`}
            is_recipient={is_recipient}
          /> : null
      }

      {
        location?.pickup?.attributes?.max_bid ?
          <RenderRTL
            title={"Max Bid (Without GST)"}
            sep={"/"}
            urdutitle={"زیادہ سے زیادہ بولی  (GST کے بغیر)"}
            val={numeral(Number(location?.pickup?.attributes?.max_bid)).format("0,0.00") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        location?.pickup?.attributes?.lowest_bid && type !== "received" ?
          <RenderRTL
            title={"Min Bid (Without GST)"}
            sep={"/"}
            urdutitle={"کم سے کم بولی (GST کے بغیر)"}
            val={numeral(Number(location?.pickup?.attributes?.lowest_bid)).format("0,0.00") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk && location?.pickup?.attributes?.is_loading_required ?
          <RenderRTL
            title={"Loading Required"}
            sep={"/"}
            urdutitle={"لوڈنگ"}
            val={"Yes"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk) && location?.pickup?.attributes?.is_loading_required &&
          location?.pickup?.attributes?.loading_requirements
          ?
          <RenderRTL
            title={"Specify Labour / Loading Requirement"}
            sep={"/"}
            urdutitle={"لوڈنگ درکار ہے۔"}
            val={location?.pickup?.attributes?.loading_requirements}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk && location?.pickup?.attributes?.is_unloading_required ?
          <RenderRTL
            title={"Unloading Required"}
            sep={"/"}
            urdutitle={"ان لوڈنگ"}
            val={"Yes"}
            is_recipient={is_recipient}
          /> : null
      }
      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk) && location?.pickup?.attributes?.is_loading_required
          && location?.pickup?.attributes?.unloading_requirements
          ?
          <RenderRTL
            title={"Specify Labour / Unloading Requirement"}
            sep={"/"}
            urdutitle={"ان لوڈنگ درکار ہے۔"}
            val={location?.pickup?.attributes?.unloading_requirements}
            is_recipient={is_recipient}
          /> : null
      }
    </div>
  );
};
