import React from "react";
export const TextInput = ({
  value,
  placeholder,
  onChange,
  showEnd,
  disabled,
  id,
  type
}) => {
  return (
    <div className={`input-container-new ${value ? 'filled' : ''}`} data-currency={showEnd ? 'true' : 'false'}>
      <input
        id={id || ''}
        type={type || 'text'}
        onChange={(e) => {
          onChange(e)
        }}
        disabled={disabled}
        value={value}
        style={{ height: 40 }}
        placeholder={placeholder}
        aria-describedby="standard-weight-helper-text"
        className="new-inut-field"
      />
      <span className="inputSlide"></span>
      {showEnd ? <span className="currency">({showEnd})</span> : null}
    </div>
  )
}