import React, { useEffect } from "react";
import { IAccountBalancePageProps } from "./types";
import "./style.scss";
import { Colors, Images } from "@temp/assets";
import { CustomText } from "@components";
import { DrawerAction } from "@temp/store/actions";
import { useDispatch, useSelector } from "react-redux";
import rootReducer from "../../../store/reducers";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material/";
import TitleLayout from "@temp/components/TitleLayout";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
export type RootState = ReturnType<typeof rootReducer>;

const Page: React.FC<IAccountBalancePageProps> = (props) => {
  const dispatch = useDispatch();
  const accountBalance = useSelector((state: RootState) => state.Drawer.account_balance) || [];
  const balance = useSelector((state: RootState) => state.Drawer.balance);
  const account_data_current_page = useSelector((state: RootState) => state.Drawer.account_data_current_page);
  const account_data_last_page = useSelector((state: RootState) => state.Drawer.account_data_last_page);

  const getAccountStatement = (refresh, page) => {
    dispatch(
      DrawerAction.GetAccountBalance({
        refresh: refresh,
        page: page,
      })
    );
  };

  useEffect(() => {
    getAccountStatement(true, 1);
  }, []);

  return (
    <TitleLayout titleOnly={true} titleOnlyText="Account Statement">
      <div className="accountWrapper">
        <FadeInComponent>
          <div className="account-text">
            <CustomText.OpenSansSemiBold
              textStyle={{
                color: Colors.Black,
                fontSize: "0.875rem",
              }}
            >
              Account Balance
            </CustomText.OpenSansSemiBold>
          </div>
        </FadeInComponent>
        <div className="account-amount">
          <CustomText.OpenSansSemiBold
            textStyle={{
              color: Colors.Green,
              fontSize: "1.5rem",
              marginTop: 10,
              marginBottom: 27,
            }}
          >
            {balance > 0 ? `-${balance}` : ` RS. ${balance}`}
          </CustomText.OpenSansSemiBold>
        </div>
        <InfiniteScroll
          dataLength={accountBalance.length}
          next={() => {
            getAccountStatement(false, account_data_current_page + 1);
          }}
          hasMore={account_data_last_page > account_data_current_page}
          loader={
            <div className="load-more-loader">
              <CircularProgress />
            </div>
          }
          style={{
            padding: 15,
          }}
          scrollThreshold={0.8}
        >
          {accountBalance?.length ? (
            <>
              {accountBalance.map((item, index) => {
                return (
                  <FadeInComponent>
                    <div className="account-container">
                      <div className="acc-text-container">
                        <div className="insideAccountLeftText">
                          <CustomText.OpenSansRegular
                            textStyle={{
                              color: Colors.Grayish,
                              fontSize: "0.625rem",
                            }}
                          >
                            {item?.is_paid
                              ? "Payments Received By"
                              : "Success Fee Charge"}

                          </CustomText.OpenSansRegular>
                        </div>
                        <div className="insideAccountRightText">
                          <CustomText.OpenSansRegular
                            textStyle={{
                              color: Colors.Grayish,
                              fontSize: "0.625rem",
                              marginLeft: 80,
                              marginTop: 5,
                            }}
                          >
                            {item?.paid_at}
                          </CustomText.OpenSansRegular>
                        </div>
                      </div>
                      <div className="accountAmountImgWrap">
                        <div className="insideAccountImgLeft">
                          <img
                            src={Images.accountIcon}
                            alt=""
                            className="img-icon"
                          />
                          <div className="amt-percent">
                            {item?.is_paid == 0 ? (
                              <>
                                <div>
                                  <CustomText.OpenSansSemiBold
                                    textStyle={{
                                      color: Colors.Black,
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {`${item?.success_fee_percentage}%`}
                                  </CustomText.OpenSansSemiBold>
                                </div>
                                <div className="load-id">
                                  <CustomText.OpenSansRegular
                                    textStyle={{
                                      color: Colors.Grayish,
                                      fontSize: "0.625rem",
                                    }}
                                  >
                                    Load ID
                                  </CustomText.OpenSansRegular>
                                  <CustomText.OpenSansSemiBold
                                    textStyle={{
                                      color: Colors.Black,
                                      fontSize: "0.625rem",
                                      marginLeft: 5,
                                    }}
                                  >
                                    {item?.jobLoad?.id || ""}
                                  </CustomText.OpenSansSemiBold>
                                </div>
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <CustomText.OpenSansSemiBold textStyle={{}}>
                                    MG Team
                                  </CustomText.OpenSansSemiBold>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="insideAccountImgRight">
                          {item?.is_paid == 0 ? (
                            <>
                              <div>
                                <CustomText.OpenSansRegular
                                  textStyle={{
                                    color: Colors.Black,
                                    fontSize: "0.813rem",
                                    display: "flex",
                                  }}
                                >
                                  <img
                                    src={Images.accountMinus}
                                    alt=""
                                    className="amount-sign"
                                  />
                                  {item?.success_fee_amount}
                                </CustomText.OpenSansRegular>
                              </div>
                            </>
                          ) : (
                            <div>
                              <CustomText.OpenSansRegular
                                textStyle={{
                                  color: Colors.Black,
                                  fontSize: "0.813rem",
                                  display: "flex",
                                }}
                              >
                                <img
                                  src={Images.accountAdd}
                                  alt=""
                                  className="amount-sign"
                                />
                                {item?.success_fee_amount}
                              </CustomText.OpenSansRegular>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </FadeInComponent>
                );
              })}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomText.OpenSansSemiBold textStyle={{ fontSize: "0.875rem" }}>
                No Entries
              </CustomText.OpenSansSemiBold>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </TitleLayout>
  );
};
export default Page;