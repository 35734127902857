import { CounterAtion } from "../actions";

const INITIAL_STATE = {
  postLoadCounterData: {
    vehicle_quantity: 1,
    max_vehicle_weight: 1,
  },
};

function Reducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case CounterAtion.SET_POSTLOAD_COUNTER_DATA:
      return {
        ...state,
        postLoadCounterData: {
          ...state.postLoadCounterData,
          ...action.payload,
        },
      };
    case CounterAtion.RESET_POSTLOAD_COUNTER_DATA:
      return {
        ...state,
        postLoadCounterData: {
          vehicle_quantity: 1,
          max_vehicle_weight: 1,
        },
      };
    default:
      return state;
  }
}

export default Reducer;
