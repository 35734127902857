import React, { useEffect, useState } from "react";
import { IActiveLoadsPageProps } from "./types";
import {
	CustomModal,
	GoogleMap,
	Loader,
	LocationTimeline,
	TitleLayout,
} from "@components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import rootReducer from "../../../store/reducers";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";
export type RootState = ReturnType<typeof rootReducer>;
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material/";
import { AuthAction, LoadAction } from "@temp/store/actions";
import { Images } from "@temp/assets";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ActiveVehicles, LoadViewer, Poster } from "../components";
import "./style.scss"
import { isBrowser } from "react-device-detect";

const Page: React.FC<IActiveLoadsPageProps> = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams()
	const loadDetail = useSelector((state: RootState) => state.Load.loadDetail);
	const vehiclePinPointMarker = useSelector((state: RootState) => state?.Load?.vehiclePinPointMarker);
	const [showModal, setShowModal] = useState(false)
	const [isFulScreen, setisFulScreen] = useState(false)
	const [center, setCenter] = useState({ lat: 24.8631724, lng: 67.0251307 });
	const [pakCenter, setPakCenter] = useState({
		lat: 30.3753, lng: 69.3451
	})
	
	const [marker, setMarker] = useState({ lat: 24.9705032, lng: 67.1005716 });
	const [totalContainers, setTotalContainers] = useState(0);
	const [expand, setExpand] = useState(true);
	const [expandVehicle, setExpandVehicle] = useState(true);

	const [markers, setMarkers] = useState([]);



   
	const callBack = (param) => {

		if (param == "200") {
			dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
		} else {
			history.goBack()
		}
	}

	useEffect(() => {
		setTimeout(() => {
			dispatch(LoadAction.getLoadDetail({ load_id: params?.id, callBack }));
		}, 200);
	}, [])


	let pickup_dropoff_location = []
	let additional_locations = []

	if (loadDetail) {
		if (loadDetail?.additional_dropoffs.length) {
			pickup_dropoff_location.push(loadDetail?.locations[0])
			additional_locations.push(loadDetail?.locations[1])
			let tempIndex = null
			loadDetail?.additional_dropoffs?.forEach((element, index) => {
				if (element?.lat && element?.long) {
					pickup_dropoff_location[1] = element
					tempIndex = index
				}
			});
			loadDetail?.additional_dropoffs?.forEach((element, index) => {
				if (element?.lat && element?.long && index !== tempIndex) {
					additional_locations.push(element)
				}
			});

			if (pickup_dropoff_location.length !== 2) {
				pickup_dropoff_location[1] = loadDetail?.locations[1]
			}
		} else {
			pickup_dropoff_location.push(loadDetail?.locations[0])
			pickup_dropoff_location.push(loadDetail?.locations[1])
		}
	}

	// if (loadDetail) {
	// 	if (loadDetail?.additional_dropoffs.length) {
	// 		pickup_dropoff_location.push(loadDetail?.locations[0])
	// 		additional_locations.push(loadDetail?.locations[1])
	// 		let tempIndex = null
	// 		loadDetail?.additional_dropoffs?.forEach((element, index) => {
	// 			if (element?.lat && element?.long) {
	// 				pickup_dropoff_location[1] = element
	// 				tempIndex = index
	// 			}
	// 		});
	// 		loadDetail?.additional_dropoffs?.forEach((element, index) => {
	// 			if (element?.lat && element?.long && index !== tempIndex) {
	// 				additional_locations.push(element)
	// 			}
	// 		});
	// 	} else {
	// 		pickup_dropoff_location.push(loadDetail?.locations[0])
	// 		pickup_dropoff_location.push(loadDetail?.locations[1])
	// 	}
	// }


	useEffect(() => {
		if (loadDetail && pickup_dropoff_location.length) {

		}
	}, [loadDetail])


	useEffect(() => {
		if (loadDetail) {
			let totalCat = 0;
			if (loadDetail?.categories?.length > 0) {
				loadDetail?.categories.forEach(element => {
					if (element?.is_twin_load) {
						if (loadDetail?.load_type?.title === "Containerized") {
							totalCat += Number(element.vehicle_quantity) * 2
						} else {
							totalCat += Number(element.vehicle_quantity)
						}
					} else {
						totalCat += Number(element.vehicle_quantity)
					}
				});
			}
			setTotalContainers(totalCat)
		}
	}, [loadDetail])


	const addViewer = (data: any) => {
		history.push("/select-recipient/add-viewer", data);
	};

	const handleRefresh = async () => {
		dispatch(LoadAction.getLoadDetail({ load_id: params?.id }));
		dispatch(LoadAction.pinPointsForVehicle({ load_id: params?.id }));
	}

	function getViewerLatLong() {
		dispatch(
			LoadAction.pinPointsForVehicle({
				load_id: "",

			})
		);
	}

	const [showDiv, setShowDiv] = useState(true)

	useEffect(() => {
		if (loadDetail) {
			dispatch(AuthAction.toggleLoader(true))
			setTimeout(() => {
				dispatch(AuthAction.toggleLoader(false))
				setShowDiv(false)
			}, 1000);
		}
	}, [loadDetail])

	if (loadDetail) {
		if (showDiv) {
			return (
				<Loader show={true} />
			)
		} else {
			return (
				<TitleLayout progressFlag={true} progress={0} titleOnly titleOnlyText={`Load ID - ${params?.id}`}>
					<div className="loadDetailWrapper activeLoadWrap">
						{showDiv && <div style={{ height: 1000 }}></div>}
						<div className="googleMapContainer">
							<div style={{ height: 320 }} className="googleMap">
								{
									!isBrowser ?
										<div onClick={() => {
											setisFulScreen(!isFulScreen)
											if (!isFulScreen) {
												document.querySelector('.googleMap').classList.add('inside-map-div1')
												document.querySelector('.googleMap').classList.remove('googleMap')
											} else {
												document.querySelector('.inside-map-div1').classList.add('googleMap')
												document.querySelector('.googleMap').classList.remove('inside-map-div1')
											}
										}} className="map-zoom-button">
											<img style={{
												height: '100%',
												width: '100%'
											}} src={!isFulScreen ? Images.ZoomMap : Images.UnZoomMap} />
										</div> : null
								}

								{/* {(center || marker) && ( */}
								<GoogleMap
									// zoom={
									// 	// pickup_dropoff_location && additional_locations ? 4 
									// 	additional_locations?.length > 0 ? 4
									// 		: 8}
									zoom={
										vehiclePinPointMarker?.length > 1 ? 4 :
											pickup_dropoff_location[0]?.lat == null && pickup_dropoff_location[0]?.long == null ||
												pickup_dropoff_location[1]?.lat == null && pickup_dropoff_location[1]?.long == null
												? 12 :
												pickup_dropoff_location[0]?.lat !== null && pickup_dropoff_location[0]?.long !== null ||
													pickup_dropoff_location[1]?.lat !== null && pickup_dropoff_location[1]?.long !== null ?
													16 :
													// pickup_dropoff_location[0]?.lat !== null && pickup_dropoff_location[0]?.lng !== null ||
													// 	pickup_dropoff_location[1]?.lat !== null && pickup_dropoff_location[1]?.lng !== null &&
													// 	vehiclePinPointMarker?.length == null ? 10
													// 	:
														pickup_dropoff_location?.length ? 12 :
															16
									}
									screen="active_load"
									marker={
										vehiclePinPointMarker?.map((item) => {
											return {
												// lat: item?.Lat,
												lat: item?.lat,
												// lng: item?.Long,
												lng: item?.lng,
												ChasisNo: item?.ChasisNo,
												VrnColor: item?.VrnColor,
												// location: item?.Location,
												location: item?.location,
												EngineNo: item?.EngineNo,
												Ignition: item?.Ignition,
												// Speed: item?.Speed,
												speed: item?.speed,
												reg_no: item?.reg_no,
												is_moving: item?.is_moving,
												is_idle: item?.is_idle,
												vehicle_id : item?.vehicle_id

											}
										})
									}
									// center={center}
									center={
										vehiclePinPointMarker?.length == 1 ?
											{
												lat: vehiclePinPointMarker[0]?.lat,
												lng: vehiclePinPointMarker[0]?.lng

											} :
											vehiclePinPointMarker?.length >= 2 ? pakCenter : center
									}

									locations={pickup_dropoff_location}
									additional_locations={additional_locations}
								/>
								{/* )} */}
							</div>
						</div>

						<PullToRefresh
							onRefresh={handleRefresh}
						>
							<FadeInComponent>
								<Poster
									loadDetail={loadDetail}
									expand={expand}
									setExpand={setExpand}
									totalContainers={totalContainers}
									showMessage={true}
									fromViewer={true}
								/>
							</FadeInComponent>

							<FadeInComponent>
								<ActiveVehicles
									loadDetail={loadDetail}
									fromViewer={true}
									expandVehicle={expandVehicle}
									setExpandVehicle={setExpandVehicle}
									markersVehicle={vehiclePinPointMarker}
								/>
							</FadeInComponent>

							<FadeInComponent>
								<div className="accordion-container">
									<Accordion className="accordion-background"
									>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"

										>
											<p className="fw-5 accHeading">Location Details</p>
										</AccordionSummary>

										<AccordionDetails >
											<LocationTimeline
												location={[{ ...loadDetail?.locations[0] }, { ...loadDetail?.locations[1] }]}
												handleModal={(value: boolean) => { }}
												fromActive={false}
												is_viewer={false}
												LoadDetail={loadDetail}
												type={"received"}
												recipient_status={{
													status: 4
												}}
												is_recipient={false}
											></LocationTimeline>
										</AccordionDetails>
									</Accordion>
								</div>
							</FadeInComponent>

							<div>
								<LoadViewer
									showViewer={loadDetail?.total_awarded > 0}
									addViewer={addViewer}
									loadDetail={loadDetail}
								/>
							</div>

						</PullToRefresh>
					</div>
					
				<div className="allLocationModal">
					<CustomModal
						showModal={showModal}
						toggleModal={setShowModal}
						modalStyle={{ width: 300, minHeight: "unset", alignItems: "unset" }}
					>

					</CustomModal>
				</div>

			</TitleLayout >
		);

		}
	} else {
		return (<div></div>)
	}

};
export default Page;