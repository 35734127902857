import React, { useEffect, useState } from "react";
import { IContainerReferencePageProps } from "./types";
import "./style.scss";
import { CustomText, NewInput, PrimaryButton, TitleLayout } from "@components";
import { useDispatch } from "react-redux";
import rootReducer from "../../../store/reducers";
import { useHistory, useLocation } from "react-router-dom";
import { LoadAction } from "../../../store/actions";
import { phoneNumberFormater, validateRefNo } from "@temp/utils/helper";
export type RootState = ReturnType<typeof rootReducer>;
import InfoIcon from "@mui/icons-material/Info";
import { LOAD_TYPE } from "@temp/constants";
import { SimpleInput, } from "@temp/components";
import { FlashlightOnOutlined } from "@mui/icons-material";

const Page: React.FC<IContainerReferencePageProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [vehicle_ref_data, set_vehicle_ref_data] = useState([])
  const [ref_validation, set_ref_validation] = useState([])

  useEffect(() => {
    let data = []
    let new_data = [...ref_validation]
    state?.already_added?.forEach((item, index) => {
      if (state?.is_cross_stuff) {
        if (item?.container_ref_one?.length || item?.no_of_packages) {
          new_data.push(index)
        }
      } else {
        if (item?.container_ref_one?.length) {
          new_data.push(index)
        }
      }
      data.push({
        id: item?.id,
        driver_id: item?.driver?.id,
        vehicle_id: item?.vehicle.id,
        container_ref_one: item?.container_ref_one ? item?.container_ref_one : '',
        container_ref_two: item?.container_ref_two ? item?.container_ref_two : '',
        vehicle_no: item?.vehicle_no,
        load_id: state?.load_id,
        job_load_category_id: state?.cat_id,
        no_of_packages: item?.no_of_packages ? item?.no_of_packages : '',
        add_another_ref: item?.container_ref_one && item?.container_ref_two ? true : false,
        is_container_reff: item?.is_container_reff,
        weight_one: item?.weight_one ? item?.weight_one : "",
        weight_two: item?.weight_two ? item?.weight_two : '',
      })
    })
    set_vehicle_ref_data(data)
    set_ref_validation(new_data)
  }, [])

  const callback = () => {
    history.goBack()
  }
  const submit = () => {
    dispatch(LoadAction.addBulkVehicleRefs({ load_id: state?.load_id, vehicle_ref_data, cb: callback }));
  };

  const handleDisable = () => {
    if (state?.loadDetail?.load_type?.title == LOAD_TYPE.fcl && !state?.loadDetail?.is_unit_basis) {
      if (!state?.is_cross_stuff) {
        let flag = []
        if(state?.final_cat?.is_twin_load==1){

          vehicle_ref_data.forEach((item) => {
            if (validateRefNo(item?.container_ref_one) && validateRefNo(item?.container_ref_two )) {
              flag.push(item)
            }
          })
        }else{
          
          vehicle_ref_data.forEach((item) => {
            if (validateRefNo(item?.container_ref_one)) {
              flag.push(item)
            }
          })
        }
        return !ref_validation.length ? true : ref_validation.length == flag.length ? false : true
      } else {
        let flag = []
        if(state?.final_cat?.is_twin_load==1){
          vehicle_ref_data.forEach((item) => {
            if (item?.is_container_reff) {
              if (validateRefNo(item?.container_ref_one) && validateRefNo(item?.container_ref_two)) {
                flag.push(item)
              }
            }
            else {
              if (item?.no_of_packages) {
                flag.push(item)
              }
            }
          })
        }
        else{

   vehicle_ref_data.forEach((item) => {
          if (item?.is_container_reff) {
            if (validateRefNo(item?.container_ref_one)) {
              flag.push(item)
            }
            }
          // }
          else {
            if (item?.no_of_packages) {
              flag.push(item)
            }
          }
        })
        }
        return !ref_validation.length ? true : ref_validation.length == flag.length ? false : true
      }
    }
    else {
      return vehicle_ref_data.find(item => item?.no_of_packages) ? false : true
    }
  }

  return (
    <TitleLayout fixed={true} titleOnly titleOnlyText={
      state?.loadDetail?.is_unit_basis ? 'Item Category' : state?.loadDetail?.load_type?.title == LOAD_TYPE.fcl ? "Vehicle Category" : "Cargo Detail"
    }>
      <div style={{
        height: 'calc(100vh - 120px)',
        overflow: 'auto'
      }}>
        {
          state?.already_added.map((item, index) => {
            
            let find_vehicle = vehicle_ref_data.find(vehicle => vehicle?.id == item?.id)
            let find_index_vehicle = vehicle_ref_data.findIndex(vehicle => vehicle?.id == item?.id)
            
            
            return (
              <div className="allocatedVehicle sectionBox">
                <div>
                  <div className="formGrid">
                    <div className="grayLabel" style={{ marginBottom: "0.25rem" }}>
                      Vehicle No.
                    </div>
                    <div className="fw-5">
                      <span style={{ textTransform: "capitalize" }}>{item?.vehicle_no}</span>
                    </div>
                  </div>
                  <div className="formGrid">
                    <div className="grayLabel" style={{ marginBottom: "0.25rem" }}>
                      Vehicle Plate No
                    </div>
                    <div className="fw-5">
                      <span style={{ textTransform: "capitalize" }}>{item?.vehicle?.plate_number}</span>
                    </div>
                  </div>
                  <div className="formGrid">
                    <div className="grayLabel" style={{ marginBottom: "0.25rem" }}>
                      Driver Phone No
                    </div>
                    <div className="fw-5">
                      <span style={{ textTransform: "capitalize" }}>
                        {`${`${phoneNumberFormater(item?.driver?.phone_code, item?.driver?.phone_no)}`} `}

                      </span>
                    </div>
                  </div>
                  {
                    item?.driver?.name &&
                    <div className="formGrid">
                      <div className="grayLabel" style={{ marginBottom: "0.25rem" }}>
                        Driver Name
                      </div>
                      <div className="fw-5">
                        <span style={{ textTransform: "capitalize" }}>{item?.driver?.name}</span>
                      </div>
                    </div>
                  }
                </div>
                <div>
                  {
                    state?.is_cross_stuff ?
                      <div>
                        {item?.is_container_reff ? (
                          <div className="inputField" style={{ marginTop: '0.75rem' }}>
                            <div className="label">
                              {`${state?.final_cat?.is_twin_load == 1 && find_vehicle?.is_container_reff == 1

                                ? "Container Number 1" : "Container Number"}`}
                            </div>
                            <div className="field">
                              <SimpleInput
                                value={find_vehicle?.container_ref_one}
                                type="text"
                                placeholder=" "
                                inputProps={{ maxLength: "11" }}
                                maxLength={11}
                                onChange={(e) => {
                                  let validate_data = [...ref_validation]
                                  if (e.target.value?.length) {
                                    if (validate_data.find(item => item == index) == undefined) {
                                      validate_data.push(index)
                                    }
                                  } else {
                                    if (find_vehicle?.no_of_packages == "" && e.target.value.length == 0) {
                                      validate_data.splice(validate_data.findIndex(item => item == index), 1)
                                    }
                                  }
                                  set_ref_validation(validate_data)
                                  let new_data = [...vehicle_ref_data]
                                  new_data[find_index_vehicle]['container_ref_one'] = e.target.value
                                  set_vehicle_ref_data(new_data);
                                }}
                              />
                            </div>
                            <div className="inputField" style={{ marginTop: '0.75rem' }}>
                              <div className="label">
                                {`${find_vehicle?.is_container_reff == 1 && state?.final_cat?.is_twin_load == 1 ? "Weight 1 (Kg)" : "Weight (Kg)"}`}
                              </div>
                              
                              <div className="field">
                                <NewInput
                                  value={find_vehicle?.weight_one}
                                  onChange={(e) => {
                                    // let reg = new RegExp("^[0-9]*$");
                                    let reg = new RegExp(/^(?!0\d)(\d+(\.\d+)?|\.\d+)$/)
                                    let value = e.target?.value;
                                    if (value && reg.test(value) && Number(value) > 0) {
                                      if (e.target.value.length < 10) {
                                        let new_data = [...vehicle_ref_data]
                                        new_data[find_index_vehicle]['weight_one'] = e.target.value
                                        set_vehicle_ref_data(new_data);
                                      }
                                    } else {
                                      let new_data = [...vehicle_ref_data]
                                      new_data[find_index_vehicle]['weight_one'] = ""
                                      set_vehicle_ref_data(new_data)
                                    }
                                  }}
                                  placeholder="Optional"
                                  decimal={state?.loadDetail?.quote_by == "vehicle" ? false :true}
                                />
                              </div>
                            </div>

                          </div>
                        ) :
                          <>
                            <div className="inputField">
                              <div className="label">No. of Packages</div>
                              <div className="field">
                                <SimpleInput
                                  value={find_vehicle?.no_of_packages}
                                  onChange={(e) => {
                                    let validate_data = [...ref_validation]
                                    if (e.target.value?.length) {
                                      if (validate_data.find(item => item == index) == undefined) {
                                        validate_data.push(index)
                                      }
                                    } else {
                                      if (find_vehicle?.container_ref_one == "") {
                                        validate_data.splice(validate_data.findIndex(item => item == index), 1)
                                      }
                                    }
                                    set_ref_validation(validate_data)

                                    let new_data = [...vehicle_ref_data]
                                    new_data[find_index_vehicle]['no_of_packages'] = e.target.value
                                    set_vehicle_ref_data(new_data);
                                  }}
                                  type="text"
                                  placeholder=" "
                                />
                              </div>
                            </div>
                            <div className="inputField" style={{ marginTop: '0.75rem' }}>
                              <div className="label">
                                {`${find_vehicle?.is_container_reff == 1 ? "Weight 1 (Kg)" : "Weight (Kg)"}`}
                              </div>
                              <div className="field">
                                <NewInput
                                  value={find_vehicle?.weight_one}
                                  onChange={(e) => {
                                    // let reg = new RegExp("^[0-9]*$");
                                    let reg = new RegExp(/^(?!0\d)(\d+(\.\d+)?|\.\d+)$/)
                                    let value = e.target?.value;
                                    if (value && reg.test(value) && Number(value) > 0) {
                                      if (e.target.value.length < 10) {
                                        let new_data = [...vehicle_ref_data]
                                        new_data[find_index_vehicle]['weight_one'] = e.target.value
                                        set_vehicle_ref_data(new_data);
                                      }
                                    } else {
                                      let new_data = [...vehicle_ref_data]
                                      new_data[find_index_vehicle]['weight_one'] = ""
                                      set_vehicle_ref_data(new_data)
                                    }
                                   
                                  }}
                                  placeholder="Optional"
                                  decimal={state?.loadDetail?.quote_by == "vehicle" ? false :true}
                                />
                              </div>
                            </div>
                          </>
                        }

                        {state?.final_cat?.is_twin_load == 1 && find_vehicle?.is_container_reff == 1
                          && (
                            <div className="inputField">
                              <div className="flex_row_div">
                                <div className="label">Container Number 2</div>
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                  let new_data = [...vehicle_ref_data]
                                  new_data[find_index_vehicle]['add_another_ref'] = false
                                  new_data[find_index_vehicle]['container_ref_two'] = ''
                                  set_vehicle_ref_data(new_data);
                                }}
                                >

                                </div>
                              </div>

                              <div className="field">
                                <SimpleInput
                                  maxLength={11}
                                  value={find_vehicle?.container_ref_two}
                                  onChange={(e) => {
                                    let validate_data = [...ref_validation]
                                    if (e.target.value?.length) {
                                      if (validate_data.find(item => item == index) == undefined) {
                                        validate_data.push(index)
                                      }
                                    } else {
                                      if (find_vehicle?.no_of_packages == "" && find_vehicle?.container_ref_one == "") {
                                        validate_data.splice(validate_data.findIndex(item => item == index), 1)
                                      }
                                    }
                                    set_ref_validation(validate_data)

                                    let new_data = [...vehicle_ref_data]
                                    new_data[find_index_vehicle]['container_ref_two'] = e.target.value
                                    set_vehicle_ref_data(new_data);
                                  }
                                  }
                                  type="text"
                                  inputProps={{ maxLength: "11" }}
                                  placeholder=" "
                                />
                              </div>
                            </div>
                          )}
                       

                      </div> :
                      <div>
                        {state?.loadDetail?.load_type?.title == LOAD_TYPE.fcl && (
                          <div className="inputField" style={{ marginTop: '0.75rem' }}>
                            <div className="label">
                              {`${state?.final_cat?.is_twin_load == 1 && find_vehicle?.is_container_reff

                                ? "Container Number 1" : "Container Number"}`}
                            </div>
                            <div className="field">
                              <SimpleInput
                                value={find_vehicle?.container_ref_one}
                                type="text"
                                placeholder=" "
                                inputProps={{ maxLength: "11" }}
                                maxLength={11}
                                onChange={(e) => {
                                  let validate_data = [...ref_validation]
                                  if (e.target.value?.length) {
                                    if (validate_data.find(item => item == index) == undefined) {
                                      validate_data.push(index)
                                    }
                                  } else {
                                    if (find_vehicle?.no_of_packages == "" && e.target.value?.length == 0) {
                                      validate_data.splice(validate_data.findIndex(item => item == index), 1)
                                    }
                                  }
                                  set_ref_validation(validate_data)
                                  let new_data = [...vehicle_ref_data]
                                  new_data[find_index_vehicle]['container_ref_one'] = e.target.value
                                  set_vehicle_ref_data(new_data);
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {
                          state?.loadDetail?.load_type?.title == LOAD_TYPE.fcl &&
                          <div className="inputField" style={{ marginTop: '0.75rem' }}>
                            <div className="label">
                              {`${find_vehicle?.is_container_reff == 1 && state?.final_cat?.is_twin_load == 1  ? "Weight 1 (Kg)" : "Weight (Kg)"}`}
                            </div>
                            <div className="field">
                              <NewInput
                                value={find_vehicle?.weight_one}
                                onChange={(e) => {
                                  // let reg = new RegExp("^[0-9]*$");
                                  let reg = new RegExp(/^(?!0\d)(\d+(\.\d+)?|\.\d+)$/)
                                  let value = e.target?.value;
                                  if (value && reg.test(value) && Number(value) > 0) {
                                    if (e.target.value.length < 10) {
                                      let new_data = [...vehicle_ref_data]
                                      new_data[find_index_vehicle]['weight_one'] = e.target.value
                                      set_vehicle_ref_data(new_data);
                                    }
                                  } else {
                                    let new_data = [...vehicle_ref_data]
                                    new_data[find_index_vehicle]['weight_one'] = ""
                                    set_vehicle_ref_data(new_data)
                                  }
                                }}
                                placeholder="Optional"
                                decimal={state?.loadDetail?.quote_by == "vehicle" ? false :true}
                              />
                            </div>
                          </div>

                        }

                        {state?.loadDetail?.load_type?.title == LOAD_TYPE.bulk && (
                          <div className="inputField">
                            <div className="label">No. of Packages </div>
                            <div className="field">
                              <SimpleInput
                                value={find_vehicle?.no_of_packages}
                                onChange={(e) => {
                                  let validate_data = [...ref_validation]
                                  if (e.target.value?.length) {
                                    if (validate_data.find(item => item == index) == undefined) {
                                      validate_data.push(index)
                                    }
                                  } else {
                                    if (find_vehicle?.container_ref_one == "") {
                                      validate_data.splice(validate_data.findIndex(item => item == index), 1)
                                    }
                                  }
                                  set_ref_validation(validate_data)

                                  let new_data = [...vehicle_ref_data]
                                  new_data[find_index_vehicle]['no_of_packages'] = e.target.value
                                  set_vehicle_ref_data(new_data);
                                }}
                                type="text"
                                placeholder=" "
                              />
                            </div>
                          </div>
                        )}

                        {state?.loadDetail?.load_type?.title == LOAD_TYPE.bulk &&
                          <div className="inputField" style={{ marginTop: '0.75rem' }}>
                            <div className="label">
                              {`${find_vehicle?.is_container_reff == 1 && state?.final_cat?.is_twin_load == 1 ? "Weight 1 (Kg)" : "Weight (Kg)"}`}
                            </div>
                            <div className="field">
                              <NewInput
                                value={find_vehicle?.weight_one}
                                onChange={(e) => {
                                  // let reg = new RegExp("^[0-9]*$");
                                  let reg = new RegExp(/^(?!0\d)(\d+(\.\d+)?|\.\d+)$/)
                                  let value = e.target?.value;
                                  if (value && reg.test(value) && Number(value) > 0) {
                                    if (e.target.value.length < 10) {
                                      let new_data = [...vehicle_ref_data]
                                      new_data[find_index_vehicle]['weight_one'] = e.target.value
                                      set_vehicle_ref_data(new_data);
                                    }
                                  } else {
                                    let new_data = [...vehicle_ref_data]
                                    new_data[find_index_vehicle]['weight_one'] = ""
                                    set_vehicle_ref_data(new_data)
                                  }
                                 
                                }}
                                placeholder="Optional"
                                decimal={state?.loadDetail?.quote_by == "vehicle" ? false :true}
                              />
                            </div>
                          </div>
                        }



                        {state?.final_cat?.is_twin_load == 1 && find_vehicle?.is_container_reff == 1
                          && (
                            <div className="inputField">
                              <div className="flex_row_div">
                                <div className="label">Container Number 2</div>
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                  let new_data = [...vehicle_ref_data]
                                  new_data[find_index_vehicle]['add_another_ref'] = false
                                  new_data[find_index_vehicle]['container_ref_two'] = ''
                                  new_data[find_index_vehicle]['weight_two'] = ''
                                  set_vehicle_ref_data(new_data);
                                }}
                                >

                                </div>
                              </div>
                              <div className="field">
                                <SimpleInput
                                  maxLength={11}
                                  value={find_vehicle?.container_ref_two}
                                  onChange={(e) => {
                                    let validate_data = [...ref_validation]
                                    if (e.target.value?.length) {
                                      if (validate_data.find(item => item == index) == undefined) {
                                        validate_data.push(index)
                                      }
                                    } else {
                                      if (find_vehicle?.no_of_packages == "" && find_vehicle?.container_ref_one == "") {
                                        validate_data.splice(validate_data.findIndex(item => item == index), 1)
                                      }
                                    }
                                    set_ref_validation(validate_data)

                                    let new_data = [...vehicle_ref_data]
                                    new_data[find_index_vehicle]['container_ref_two'] = e.target.value
                                    set_vehicle_ref_data(new_data);
                                  }
                                  }
                                  type="text"
                                  inputProps={{ maxLength: "11" }}
                                  placeholder=" "
                                />
                              </div>
                            </div>
                          )}
                        
                      </div>
                  }

                  {state?.final_cat?.is_twin_load == 1 && find_vehicle?.is_container_reff == 1
                    &&
                    <div className="inputField" style={{ marginTop: '0.75rem' }}>
                      <div className="label">Weight 2 (Kg)</div>
                      <div className="field">
                        <NewInput
                          value={find_vehicle?.weight_two}
                          onChange={(e) => {
                            // let reg = new RegExp("^[0-9]*$");
                            let reg = new RegExp(/^(?!0\d)(\d+(\.\d+)?|\.\d+)$/)
                            let value = e.target?.value;
                            if (value && reg.test(value) && Number(value) > 0) {
                              if (e.target.value.length < 10) {
                                let new_data = [...vehicle_ref_data]
                                new_data[find_index_vehicle]['weight_two'] = e.target.value
                                set_vehicle_ref_data(new_data);
                              }
                            } else {
                              let new_data = [...vehicle_ref_data]
                              new_data[find_index_vehicle]['weight_two'] = ""
                              set_vehicle_ref_data(new_data)
                            }
                          }}
                          placeholder="Optional"
                          decimal={state?.loadDetail?.quote_by == "vehicle" ? false :true}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            )
          })
        }
        {state?.loadDetail?.load_type?.title == LOAD_TYPE.fcl || state?.is_cross_stuff ? (
          <div className="error-container info">
            <div>
              <InfoIcon className="error-icon" />
            </div>
            <div className="error-text">
              Please enter the 11 digit Container Number.
              First 4 characters of the Container Number should be alphabets and rest of characters should be numbers.
            </div>
          </div>
        ) : null}

        <div style={{
          marginTop: 20,
          marginBottom: 20,
        }}>
          <PrimaryButton
            onClick={() => {
              submit();
            }}
            disabled={handleDisable()}
            buttonStyle={{
              width: "100%",
            }}
            title="Submit"
          />
        </div>
      </div>
    </TitleLayout>
  );
};
export default Page;
