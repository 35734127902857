import React, {useState} from "react";
import DialogTitle from "@mui/material//DialogTitle";
import Dialog from "@mui/material//Dialog";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import { PrimaryButton, CustomText } from "@components";
import { useSelector } from "react-redux";
import { Colors } from "@temp/assets";

export const ContactModal = (props: any) => {
  const { showModal, setShowModal } = props;
  const metaData = useSelector((state: RootState) => state.Auth.metaData);

  const handleContactMg = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={showModal}
    >
      <DialogTitle id="simple-dialog-title">
        <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.25rem" }}>
          Please Contact MG Team
        </CustomText.OpenSansSemiBold>
        <div className="mg-contact-info">
          <CallIcon
            style={{
              color: "rgb(122,194,81)",
              height: 20,
            }}
          />
          <CustomText.OpenSansRegular
            textStyle={{ fontSize: "0.875rem", color: Colors.Black, marginLeft: 10 }}
          >
            {metaData?.administration_contact?.phone}
          </CustomText.OpenSansRegular>
        </div>
        <div className="mg-contact-info">
          <EmailIcon style={{ color: "rgb(122,194,81)", height: 20 }} />
          <CustomText.OpenSansRegular
            textStyle={{ fontSize: "0.875rem", color: Colors.Black, marginLeft: 10 }}
          >
            {metaData?.administration_contact?.email}
          </CustomText.OpenSansRegular>
        </div>
        <div style={{ marginTop: 25, marginBottom: 30 }}>
          <PrimaryButton
            onClick={handleClose}
            title="OK"
            buttonStyle={{
              height: 40,
              width: 262,
            }}
          />
        </div>
      </DialogTitle>
    </Dialog>
  );
};
