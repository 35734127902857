import React, { useEffect, useState } from "react";
import { IAddNetworkPageProps } from "./types";
import { Images } from "@temp/assets";
import "./style.scss";
import {
  CustomText,
  PrimaryButton,
  TitleLayout,
  CustomModal,
  OutLineButton,
} from "@components";

import rootReducer from "../../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
export type RootState = ReturnType<typeof rootReducer>;
import { DrawerAction } from "@temp/store/actions";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../../utils/mobileEventHandler";
import { preventNonNumericalInput, showMessage } from "@temp/utils/helper";
// import QrReader from "modern-react-qr-reader";
import QrReader from "react-qr-scanner";
// import { QrReader } from "react-qr-reader";

import { AddCircleOutlineOutlined, CheckCircle } from "@mui/icons-material";

const delay = 500;
const previewStyle = {
  height: 380,
  width: 320,
};
const Page: React.FC<IAddNetworkPageProps> = (props) => {
  const [search, setSearch] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const networkData = useSelector(
    (state: RootState) => state.Drawer.searchNetworkData
  );
  const all_contacts =
    useSelector((state: RootState) => state.Drawer.networkData) || [];
  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
  const callback = (phoneNumber: any, id) => {
    if (phoneNumber) {
      history.replace("AddReceipient-uccessfully/" + phoneNumber, {
        id,
        from: state?.from,
        type: state?.type,
        loadDetail: state?.loadDetail,
      });
    } else {
      history.goBack();
    }
  };

  const [showModal, toggleModal] = useState(false);

  const addNetwork = (item, from) => {
    if (from == "native") {
      let data = [];
      let num =
        item?.phoneNumber?.toString().substring(0, 2) == "03"
          ? item?.phoneNumber.slice(1)
          : item.phoneNumber?.toString().substring(0, 3) == "+92"
            ? item?.phoneNumber.slice(3)
            : item?.phoneNumber?.toString().substring(0, 2) == "92"
              ? item?.phoneNumber.slice(2)
              : item?.phoneNumber;
      let new_num = num.split(" ").join("");
      let obj = {
        phone_no: new_num,
        phone_code: "92",
      };
      data.push(obj);
      if(item?.status !=="verified") {
        showMessage.current({
          message: "This user is unverified",
          status:"error"
        })
      }else {
        dispatch(
          DrawerAction.AddNetwork({
            selectedNetwork: data,
            cb: callback,
          })
        );
      }

    } else {
      let selectedNetwork = [item];
      if(item?.status !=="verified") {
        showMessage.current({
          message: "This user is unverified",
          status:"error"
        })
      } else {
        dispatch(
          DrawerAction.AddNetwork({
            selectedNetwork,
            cb: callback,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (sendEventToAndroid("requestContact")) {
      window.Android.requestContact(String("request-contact"));
    } else if (sendEventToiOS("requestContact")) {
      window.webkit.messageHandlers.requestContact.postMessage(
        JSON.stringify({
          request: "request-contact",
        })
      );
    } else {
    }
  }, []);

  const callbackss = () => {
    setTimeout(() => {
      history.goBack();
    }, 3000);
  };

  const handleScan = (result) => {
    if (result) {
      toggleModal(false);
      dispatch(DrawerAction.AddOtpNetwork({ qr_code: result, cb: callbackss }));
    }
  };

  const handleError = (error) => {};

  const [local_contacts, setContacts] = useState([]);
  useEffect(() => {
    const target = (message) => {
      setContacts(JSON.parse(message?.detail));
    };
    window.addEventListener("GetPhoneContacts", target);
  }, []);

  const getSearchedNetwork = (params) => {
    dispatch(DrawerAction.SearchNetwork(params));
  };
  const searchNetwork = (event) => {
    if (event.target.value.length == 11) {
      getSearchedNetwork(event.target.value.replace(/^0+/, ""));
    } else if (event.target.value.length > 10) {
      return;
    } else if (event.target.value === "") {
      getSearchedNetwork("");
    }
    setSearch(event.target.value);
  };

  useEffect(() => {
    getSearchedNetwork("");
    dispatch(DrawerAction.GetNetwork({ only_company_users: "" }));
  }, []);

  const removeDuplicate = (arr) => {
    let result = arr.reduce((unique, o) => {
      if (
        !unique.some(
          (obj) =>
            obj.phoneNumber.replace(/\s/g, "") ===
            o.phoneNumber.replace(/\s/g, "")
        )
      ) {
        unique.push(o);
      }
      return unique;
    }, []);
    return result.map((item) => {
      return {
        givenName: item?.givenName,
        phoneNumber: item?.phoneNumber.replace(/\s/g, ""),
      };
    });
  };

  return (
    <TitleLayout titleOnlyText="Add Recipient" titleOnly={true}>
      <div className="addNetwork addNewNetworks">
        <div className="networkSearchWrap">
          <div
            className={`searchContainer ${search ? "filled" : ""}`}
            style={{ width: "100%" }}
          >
            <input
              className="searchInput"
              onKeyPress={(event) => preventNonNumericalInput(event)}
              inputMode="numeric"
              pattern="\d*"
              onChange={searchNetwork}
              type="number"
              autoComplete="off"
              name="search"
              style={{ width: "-webkit-fill-available " }}
              value={search}
              placeholder="Search by number eg: 03123456789"
            />
            <span className={`icons-search ${search ? "" : "active"}`}></span>

            <span
              className={`icons-cross ${search ? "active" : ""}`}
              onClick={() => {
                setSearch("");
                getSearchedNetwork("");
              }}
            ></span>
          </div>
          <div
            onClick={() => {
              if (sendEventToAndroid("scanQrCall")) {
                window.Android.scanQrCall("hello");
              } else if (sendEventToiOS("scanQrCall")) {
                window.webkit.messageHandlers.scanQrCall.postMessage(
                  JSON.stringify({
                    show_navigation_bar: "hello",
                  })
                );
              } else {
                toggleModal(!showModal);
              }
            }}
            className="network-qr-scanner"
          >
            <img
              style={{
                height: "100%",
                width: "100%",
              }}
              src={Images?.ScanIcon}
            />
          </div>
        </div>
        <div className="networkContentWrap">
          {/* print will herer : {print} */}
          <div
            style={{
              height: "50vh",
              paddingLeft: 0,
              paddingRight: 0,
            }}
            className="network-wrapper"
          >
            <div
              style={{
                width: "100%",
              }}
            >
              {networkData?.length > 0 || local_contacts?.length > 0 ? (
                <>
                  <>
                    {networkData?.length > 0 ? (
                      <>
                        {networkData[0]?.name !== "Unregistered User" ? (
                          <CustomText.OpenSansBold>
                            Meri Gari Members
                          </CustomText.OpenSansBold>
                        ) : (
                          <CustomText.OpenSansBold>
                            Search result
                          </CustomText.OpenSansBold>
                        )}
                        {networkData?.map((item, index) => {
                          let already_in_contact = all_contacts?.find(
                            (contact) =>
                              contact?.networkUser?.phone_no == item?.phone_no
                          );
                          return (
                            <div className="network-user-list-container">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="network-user-image">
                                  <img
                                    alt=""
                                    src={
                                      item?.picture || metaData.default_avatar
                                    }
                                    className="network-user-icon-image"
                                  />
                                </div>
                                <div className="network-user-info-container">
                                  <div className="newtwork-user-name-container">
                                    <CustomText.OpenSansBold>
                                      {item?.name}
                                    </CustomText.OpenSansBold>
                                  </div>
                                  <CustomText.OpenSansRegular>
                                    {item?.company && item?.company.length
                                      ? item?.company[0]?.name
                                        ? item?.company[0]?.name
                                        : ""
                                      : ""}
                                  </CustomText.OpenSansRegular>
                                </div>
                              </div>

                              <div>
                                <OutLineButton
                                  classProp="tinyBtn mb-0"
                                  dataColor={
                                    already_in_contact ? "gray" : "blue"
                                  }
                                  title={already_in_contact ? "Added" : "Add"}
                                  disabled={already_in_contact ? true : false}
                                  icon={
                                    already_in_contact ? (
                                      <CheckCircle />
                                    ) : (
                                      <AddCircleOutlineOutlined />
                                    )
                                  }
                                  onClick={() => {
                                    addNetwork(item);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </>

                  <>
                    {local_contacts.length > 0 ? (
                      <>
                        <CustomText.OpenSansBold>
                          In Your Contacts
                        </CustomText.OpenSansBold>
                        {removeDuplicate(
                          local_contacts?.filter(
                            (item) =>
                              item.phoneNumber?.toString().substring(0, 3) ==
                                "+92" ||
                              item.phoneNumber?.toString().substring(0, 2) ==
                                "92" ||
                              item.phoneNumber.toString().substring(0, 2) ==
                                "03"
                          )
                        )?.map((item) => {
                          return (
                            <div className="network-user-list-container">
                              <div className="" style={{ display: "flex" }}>
                                <div className="network-user-image">
                                  <img
                                    alt=""
                                    src={metaData?.default_avatar}
                                    className="network-user-icon-image"
                                  />
                                </div>
                                <div>
                                  <div className="network-user-info-container">
                                    <div className="newtwork-user-name-container">
                                      <CustomText.OpenSansBold>
                                        {item?.givenName}
                                      </CustomText.OpenSansBold>
                                    </div>
                                  </div>
                                  <div className="network-user-info-container">
                                    <div className="newtwork-user-name-container">
                                      <CustomText.OpenSansRegular>
                                        {item?.phoneNumber
                                          ?.toString()
                                          .substring(0, 2) == "03"
                                          ? "0" + item?.phoneNumber.slice(1)
                                          : item.phoneNumber
                                                ?.toString()
                                                .substring(0, 3) == "+92"
                                            ? "0" + item?.phoneNumber.slice(3)
                                            : item?.phoneNumber
                                                  ?.toString()
                                                  .substring(0, 2) == "92"
                                              ? "0" + item?.phoneNumber.slice(2)
                                              : "hello"}
                                      </CustomText.OpenSansRegular>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <OutLineButton
                                  classProp="tinyBtn mb-0"
                                  dataColor="blue"
                                  title="Add"
                                  icon={<AddCircleOutlineOutlined />}
                                  onClick={() => {
                                    addNetwork(item, "native");
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </>
                </>
              ) : (
                <div className="network-empty-component">
                  <CustomText.OpenSansSemiBold>
                    No Data Found
                  </CustomText.OpenSansSemiBold>
                </div>
              )}
            </div>
          </div>
          {/* <div className="button-primary">
            <PrimaryButton
              disabled={!selectedNetwork.length}
              buttonStyle={{
                width: 350,
              }}
              title="Add to Network"
              onClick={() => {
                addNetwork();
              }}
            />
          </div> */}
        </div>
      </div>
      <CustomModal
        isFullScreen={true}
        modalStyle={{
          with: "100%",
          height: "100vh",
        }}
        showModal={showModal}
        toggleModal={toggleModal}
      >
        <div className="qr-content-container">
          <div style={{ height: "380px", width: "320px", color: "silver" }}>
            {/* <QrReader
              facingMode={"environment"}
              delay={delay}
              style={previewStyle}
              onError={handleError}
              onScan={handleScan}
            />  */}
            <QrReader
              delay={delay}
              // facingmode={"user"}
              legacyMode={true}
              onError={handleError}
              // onResult={handleScan}
              onScan={handleScan}
              style={{ width: "100%" }}
              // style={previewStyle}
            />
          </div>
          <PrimaryButton
            buttonStyle={{
              width: 320,
              height: 45,
            }}
            title="Close"
            onClick={() => {
              toggleModal(false);
            }}
          />
        </div>
      </CustomModal>
    </TitleLayout>
  );
};

export default Page;