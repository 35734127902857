import "./theme/style.scss";
import { PersistGate } from "redux-persist/integration/react";
import * as React from "react";
import { Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import "react-notifications-component/dist/theme.css";
import {
	Loader,
	AlertMessage,
	ErrorMessage,
} from "@components";
import { Provider } from "react-redux";
import { getMessageRef, getErrorMessageRef, isSupported } from "@utils/helper";
import { App } from "./app";
import { history } from "./history";
import { persistor, store } from "./store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from 'react-dom/client';
import { ReactNotifications } from "react-notifications-component";

const StartApp = () => {
	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0,
	});

	const Root = () => {
		const messageRef = React.useRef(null);
		const errorMessageRef = React.useRef(null);
		React.useEffect(() => {
			getMessageRef(messageRef);
			getErrorMessageRef(errorMessageRef);
		}, []);
		return (
			<Router history={history}>
				<QueryParamProvider ReactRouterRoute={Route}>
					<Provider store={store}>
						<PersistGate loading={null} persistor={persistor}>
							<div>
								<ReactNotifications />
								<Loader />
								<AlertMessage showMessage={messageRef} />
								<ErrorMessage showErrorMessage={errorMessageRef} />
								<App />
							</div>
						</PersistGate>
					</Provider>
				</QueryParamProvider>
			</Router>
		);
	}
	return (
		<Root />
	)
};



const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<StartApp />
);

// export const registerServiceWorker = () => {
// 	if ("serviceWorker" in navigator && isSupported()) {
// 		navigator.serviceWorker
// 			.register("firebase-messaging-sw.js")
// 			.then(function (registration) {
// 				return registration.scope;
// 			})
// 			.catch(function (err) {
// 				return err;
// 			});
// 	}
// };
// registerServiceWorker();

if (process.env.REACT_APP_API_URL === "https://bk.merigari.com/api/") {
	console.log = () => { }
	console.error = () => { }
	console.debug = () => { }
}