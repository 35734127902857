// import "date-fns";
import React, { FC, useState } from "react";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
import "./style.scss";
import moment from "moment";
import { convertTimeZone } from "@temp/utils/helper";

import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DesktopTimePicker, StaticTimePicker, TimePicker } from "@mui/x-date-pickers";
import { TimeClock } from '@mui/x-date-pickers/TimeClock';

interface types {
  date?: any;
  setDate?: any;
  setTime?: any;
  time?: any;
  maxDate?: any;
  minDate?: any;
  from?: any;
  fromTime: any;
  dateType: any;
  extraData: any;
  fromForward: any
}
export const MaterialUIPickers: FC<types> = ({
  setDate,
  setTime,
  time,
  date,
  maxDate,
  minDate,
  from = false,
  fromTime = false,
  dateType,
  extraData,
  fromForward = false
}: types) => {


  const handleDateChange = (
    date
    // date: Date | null
  ) => {
    setDate(date && dayjs(date).format("YYYY-MM-DD"));
  };
  const handleTimeChange = (
    time
    // date: Date | null
  ) => {
    setTime(time && dayjs(time));
  };

  return (
    <>
      {/* <div>date timee</div> */}
      {/* {
        dateType == 'PickUp Date' ?
          (time) ?
            <div className="pickupClearWrapper">
              <div
                onClick={() => {
                  extraData?.setPickupTime(null)
                  extraData?.clearPickupTime()
                }}
                className="clearBtn">
                Clear
              </div>
            </div>
            : <div></div>
          : <div></div>
      } */}

      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={[
            'DatePicker',
            'MobileDatePicker',
            'DesktopDatePicker',
            'StaticDatePicker',
          ]}
        >
          <DemoItem>
            <MobileDatePicker defaultValue={date ? dayjs(date) : dayjs(new Date())} />
            <span
              style={{ color: date ? "black" : "gray" }}
              className="show_picket_text"
            >
              {date ? dayjs(date).format('DD/MM/YYYY') : from ? "DD/MM/YYYY" : "DD/MM/YYYY (Optional)"}
            </span>
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider> */}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="datePickerFieldWrapCustom">
          <MobileDatePicker
            // value={null}
            value={date ? dayjs(date) : null}
            // value={dayjs(date)}
            onChange={handleDateChange}
            maxDate={dayjs(maxDate)}
            minDate={minDate ? dayjs(minDate) : dayjs(new Date())}
            disabled={false}
            // label={from ? "DD/MM/YYYY" : "DD/MM/YYYY (Optional)"}
            // renderInput={(params) => <TextField {...params} />}
            // renderInput={(params) => (
            //   <TextField
            //     {...params}
            //     label="Select Date"
            //     placeholder="Custom Placeholder"  // Customize the placeholder here
            //   />
            // )}
            // slotProps={{ textField: { placeholder: from ? 'MM/DD/YYYY' : 'MM/DD/YYYY (Optional)'}} }
            className="DatePickerField datePicker"
          />
          <span
            style={{ color: date ? "black" : "gray" }}
            className="show_picket_text"
          >
            {date ? dayjs(date).format('DD/MM/YYYY') : from ? "DD/MM/YYYY" : "DD/MM/YYYY (Optional)"}
          </span>
        </div>
      </LocalizationProvider>

      {dateType !== "Rate Validity" ? (
        <>
          {setTime ? (
            <div className="timePickerFieldWrap">

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'TimePicker',
                    'MobileTimePicker',
                    'DesktopTimePicker',
                    'StaticTimePicker',
                  ]}
                >
                  <DemoItem>
                    <MobileTimePicker
                      defaultValue={time ? dayjs(time) : dayjs(new Date())}
                      onChange={handleTimeChange}
                    />
                    <span
                      // style={{ color: time ? "black" : "gray" }}

                      style={{
                        color: time ? "black" :
                          !fromTime || fromTime ? "gray" :
                            "white"
                      }}
                      className="show_picket_text"
                    >
                      {
                        time ? typeof time == "object" &&
                          !Number.isNaN(dayjs(time).hour())
                          ? dayjs(time).format("hh:mm A")
                          : typeof time === "string"
                            ? dayjs(new Date(`${date} ${time}`)).format("hh:mm A")
                            : null
                          : !fromTime ? "Enter Time" : "Enter Time (Optional)"}

                    </span>

                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                  value={
                    time
                      ? typeof time == "object" &&
                        !Number.isNaN(dayjs(time).hour())
                        ? dayjs(time)
                        : typeof time === "string"
                          ? dayjs(new Date(`${date} ${time}`))
                          : null
                      : null
                  }
                  onChange={handleTimeChange}
                  // label={!fromTime ? "Enter Time" : "Enter Time (Optional)"}
                  // renderInput={(params) => <TextField {...params} />}
                  className="TimePickerField datePicker"
                 
                />
                <span
                  style={{ color: time ? "black" : "gray" }}
                  className="show_picket_text"
                >
                  {time ? typeof time == "object" &&
                        !Number.isNaN(dayjs(time).hour())
                        ? dayjs(time).format("hh:mm A")
                        : typeof time === "string"
                          ? dayjs(new Date(`${date} ${time}`)).format("hh:mm A")
                          : null
                      : !fromTime ? "Enter Time" : "Enter Time (Optional)"}
                </span>
              </LocalizationProvider> */}
              <p className="smallLabel">
                All times have to be Pakistan standard time (
                {moment(convertTimeZone(new Date(), "Asia/Karachi")).format(
                  "DD MMM, YYYY hh:mm A"
                )}
                )
              </p>
            </div>
          ) : null}
        </>
      ) : null}
    </>


// <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //   {
    //     dateType == 'PickUp Date'?
    //       (time) ?
    //         <div className="pickupClearWrapper">
    //           <div
    //             onClick={() => {
    //               extraData?.setPickupTime(null)
    //               extraData?.clearPickupTime()
    //             }}
    //             className="clearBtn">
    //               Clear
    //           </div>
    //         </div>
    //        :<div></div>
    //     :<div></div>
    //   }
    //   <KeyboardDatePicker
    //     margin="normal"
    //     style={{ width: "100%" }}
    //     id="date-picker-dialog"
    //     className="datePicker"
    //     format="dd/MM/yyyy"
    //     minDate={minDate ? minDate : new Date()}
    //     maxDate={maxDate}
    //     value={date}
    //     disable={"true"}
    //     placeholder={from ? "DD/MM/YYYY" : "DD/MM/YYYY (Optional)"}
    //     onChange={handleDateChange}
    //     InputProps={{ readOnly: true }}
    //     keyboardIcon={<span className="icons-calender calenderIcon"></span>}
    //     KeyboardButtonProps={{
    //       "aria-label": "change date",
    //     }}
    //   />

    //   {dateType !== "Rate Validity" ?
    //     <>
    //       {setTime ? (
    //         <div>
    //           <KeyboardTimePicker
    //             margin="normal"
    //             style={{ width: "100%" }}
    //             id="time-picker"
    //             className="datePicker dateWithLabel"
    //             ampm={true}
    //             value={time}
    //             onChange={handleTimeChange}
    //             InputProps={{ readOnly: true }}
    //             placeholder={!fromTime ? "Enter Time" : "Enter Time (Optional)"}
    //             keyboardIcon={<span className="icons-clock calenderIcon"></span>}
    //             KeyboardButtonProps={{
    //               "aria-label": "change time",
    //             }}
    //           />
    //           <p className="smallLabel">
    //             All times have to be Pakistan standard time (
    //             {moment(convertTimeZone(new Date(), 'Asia/Karachi')).format("DD MMM, YYYY hh:mm A")})

    //           </p>
    //         </div>
    //       ) : null}
    //     </> :null}

    // </MuiPickersUtilsProvider>
  );
};
