import React from "react";
import "../LoadDetailPoster/style.scss";
import { price_units_transalation } from "@temp/utils/helper";
var numeral = require("numeral");


export const VehicleCounter = ({ recipient, style = {}, from_spot = true, showResponded = false, price_unit, loadDetail, is_recipient = false }) => {
  
  if (recipient?.bid) {
    return (
      <div
        className="chip chipMaxWidth"
        style={{
          ...style,
        }}
      >
        {from_spot && !showResponded ? (
          <>
            <span>
              {(recipient?.bid?.awarded_freight_ton || recipient?.bid?.bid_freight_ton) ?
                recipient?.bid?.awarded_freight_ton
                  ? `${numeral(recipient?.bid?.awarded_freight_ton).format("0,0.00")} ${price_unit ? price_unit : "F.Ton"} `
                  : `${numeral(recipient?.bid?.bid_freight_ton).format("0,0.00")}  ${price_unit ? price_unit : "F.Ton"}` : null
              }
              {(recipient?.bid?.awarded_no_of_vehicles || recipient?.bid?.no_of_vehicles) ? recipient?.bid?.awarded_no_of_vehicles
                ? `${recipient?.bid?.awarded_no_of_vehicles} ${loadDetail?.is_unit_basis ? 'Item' : "Vehicle"}`
                : `${recipient?.bid?.no_of_vehicles} ${loadDetail?.is_unit_basis ? 'Item' : "Vehicle"}` : null}
            </span>
            {
              is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">
                  {(recipient?.bid?.awarded_freight_ton || recipient?.bid?.bid_freight_ton) ?
                    recipient?.bid?.awarded_freight_ton
                      ? `${numeral(recipient?.bid?.awarded_freight_ton).format("0,0.00")} ${price_unit?price_units_transalation[price_unit]:"فریٹ ٹن"}`
                      : `${numeral(recipient?.bid?.bid_freight_ton).format("0,0.00")} ${price_unit?price_units_transalation[price_unit]:"فریٹ ٹن"}` : null
                  }
                  {(recipient?.bid?.awarded_no_of_vehicles || recipient?.bid?.no_of_vehicles) ? recipient?.bid?.awarded_no_of_vehicles
                    ? `${recipient?.bid?.awarded_no_of_vehicles} ${loadDetail?.is_unit_basis ? 'آئٹم' : "گاڑی"}`
                    : `${recipient?.bid?.no_of_vehicles} ${loadDetail?.is_unit_basis ? 'آئٹم' : "گاڑی"}` : null}
                </span>
              </>
            }
          </>
        ) : null}

        {showResponded ? (
          <>
            <span
              textStyle={{
                marginLeft: 5,
              }}
            >
              {(recipient?.bid?.bid_freight_ton) ?
                `${numeral(recipient?.bid?.bid_freight_ton).format("0,0.00")}  F.Ton` : null
              }
              {recipient?.bid?.no_of_vehicles} Vehicle
            </span>
            {
              is_recipient &&
              <>
                <span className="seperator">/</span>
                <span className="urduLabel">
                  {(numeral(recipient?.bid?.bid_freight_ton).format("0,0.00")) ?
                    `فریٹ ٹن` : "گاڑی"
                  }
                </span>
              </>
            }
          </>
        ) : null}
      </div>
    )
  } else {
    return <div></div>
  }
};
