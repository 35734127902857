import React, { FC } from 'react';
// import { createStyles, makeStyles, Theme, } from '@mui/material//styles';
import InputBase from '@mui/material//InputBase';
import { IProps } from "./types";

// const useStyles = makeStyles((theme: Theme) =>
// 	createStyles({
// 		root: {
// 			border: 'none',
// 			margin: theme.spacing(1),
// 		},
// 		margin: {
// 			margin: theme.spacing(1),
// 		},
// 	}),
// )

export const SimpleInput: FC<IProps> = ({
	isPKR,
	value,
	disabled,
	placeholder,
	borderColor = "rgb(241, 241, 241)",
	onChange,
	id,
	name,
	maxLength,
	type,
	inputRef = null,
}: IProps) => {
	// const classes = useStyles();
	return (
		<div className='simpleInputWrapper' data-currency={isPKR}>
			<div style={{ display: "flex" }}>
				<InputBase
					onWheelCapture={(e) => {
						e.target?.blur()
					}}
					id={id}
					ref={inputRef}
					name={name}
					style={{ width: "100%" }}
					// className={`${classes.root} ${value ? 'filled' : ''}`}
					value={value}
					type={type || "text"}
					onChange={onChange}
					placeholder={placeholder || "Search for your exact address "}
					disabled={disabled || false}
					inputProps={{
						"aria-label": "naked",
						maxLength: maxLength ? maxLength : 30,
					}}
				/>
				<span className='inputSlide'></span>
				{isPKR && (
					<div className='currency'>PKR</div>
				)}
			</div>
		</div>
	);
};
export default SimpleInput;
