import React, { useEffect, useRef, useState } from "react";
import { IActiveLoadsPageProps } from "./types";
import "./style.scss";
import {
  BackHeader,
  CustomModal,
  CustomText,
  LoadListingStatus,
  OutLineButton,
} from "@components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import rootReducer from "../../../store/reducers";
import PullToRefresh from "react-simple-pull-to-refresh";
// import PullToRefresh from "react-pull-to-refresh";
import InfiniteScroll from "react-infinite-scroll-component";
export type RootState = ReturnType<typeof rootReducer>;
import { CircularProgress, MenuItem, Select } from "@mui/material/";
import { LoadAction, ProfileAction } from "@temp/store/actions";
import { Colors, Images } from "@temp/assets";
import moment from "moment";
import { CheckCircle, Close } from "@mui/icons-material";
import TouchRipple from "@mui/material//ButtonBase/TouchRipple";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import { triggerMixpanelEvent } from "@temp/utils/helper";
import { LOAD_TYPE } from "@temp/constants";
import { RenderRTL } from "../components";
var numeral = require('numeral')


var searchFlag = false

const Page: React.FC<IActiveLoadsPageProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()
  const { state } = location
  // Reducers data 
  const loadType = useSelector((state: RootState) => state.Profile.activeLoadType);
  const activeCategory = useSelector((state: RootState) => state.Profile.activeCategory);
  const loads = useSelector((state: RootState) => state.Profile.active_loads) || [];

  const active_load_current_page = useSelector((state: RootState) => state.Profile.active_load_current_page);
  const active_load_last_page = useSelector((state: RootState) => state.Profile.active_load_last_page);
  // State data
  const [search, setSearch] = useState("");
  const [showLink, setShowLink] = useState(false);
  const [addRecipients, setAddRecipients] = useState([]);
  const [show_location_modal, set_show_location_modal] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [location_type, set_location_type] = useState(null)

  const [is_seen_by_viewer, set_is_seen_viewer] = useState(false)

  const isShowIndicationDot = (jobLoadRecId) => {
    dispatch(LoadAction.showIndicationDotsViewer({
      load_id: jobLoadRecId,
    }))
  }

  useEffect(() => {
    setTimeout(() => {
      getActiveLoads({
        refresh: true,
        loadType,
        search,
      })
    }, 100);
  }, [loadType])

  useEffect(() => {
    return () => {
      searchFlag = false
    }
  }, [])

  const getActiveLoads = (data) => {

    // return
    dispatch(
      ProfileAction.getActiveLoads({
        refresh: data.refresh,
        load_post_type:
          data.loadType === "Loads Received" ? "received" :
            data.loadType === "Loads Posted" ? "posted" :
              data.loadType === "viewer" ? "viewer" : "",
        search: data?.search ? data?.search : "",
        fromSearch: data?.fromSearch,
        next_page: data?.refresh || data?.fromSearch || search ? 1 : Number(active_load_current_page) + 1,
      })
    );
  }


  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        getActiveLoads({
          refresh: false,
          loadType,
          search: search,
          fromSearch: true,
        })
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (searchFlag) {
      if (search == "") {
        getActiveLoads({
          refresh: false,
          loadType,
          search: search,
          fromSearch: true,
        })
      }
    }
  }, [search])

  const handleChangeLoadType = (value: string) => {

    dispatch(ProfileAction.updateActiveFilters({
      loadType: value,
    }))
  };

  const handleRefresh = async () => {
    getActiveLoads({
      refresh: true,
      loadType,
      search,
    });
  }

  const RenderLoads = ({ load }) => {

    let total_weight = 0
    if (load?.price_unit == "Kg") {
      total_weight = Number(load?.gross_weight) * 1000
    } else if (load?.price_unit == "Ton") {
      total_weight = Number(load?.gross_weight)
    } else if (load?.price_unit == "F.Ton") {
      total_weight = load?.total_freight_ton
    } else if (load?.price_unit == "CBM") {
      total_weight = load?.cargo_volume
    } else if (load?.price_unit === "Liter") {
      total_weight = load?.cargo_liter
    }
    const rippleRef = useRef(null);
    const onRippleStart = (e) => {
      rippleRef.current.start(e);
    };
    const onRippleStop = (e) => {
      rippleRef.current.stop(e);
    };

    return (
      <div
        type="button"
        onMouseDown={onRippleStart}
        onMouseUp={onRippleStop}
        onClick={(e) => {
          e.stopPropagation();
          if (loadType == "Loads Received") {
            dispatch(LoadAction.loadDetailEmpty())
            setTimeout(() => {
              if (load.inquiry_type === "Spot Rate") {
                history.push(`/load-details-recipient/${load.id}/received`, {
                  load_id: load.id,
                  ref_no: load?.ref_no,
                });
              } else {
                history.push(`/contract-detail-recepient/${load.id}/received`, {
                  load_id: load.id,
                  ref_no: load?.ref_no,
                });
              }
            }, 200)
          } else if (loadType == "Loads Posted") {
            dispatch(LoadAction.loadDetailEmpty())
            setTimeout(() => {
              if (load?.inquiry_type == "Spot Rate") {
                history.push(`/load-details-poster/${load.id}/posted`, {
                  load_id: load.id,
                  ref_no: load?.ref_no,
                  activeTab: activeCategory,
                });
              } else {
                history.push(`/contract-details-poster/${load.id}/posted`, {
                  load_id: load.id,
                  ref_no: load?.ref_no,
                  activeTab: activeCategory,
                });
              }
            }, 200);
          } else if (loadType == "viewer") {
            dispatch(LoadAction.loadDetailEmpty())
            setTimeout(() => {
              if (load?.inquiry_type == "Spot Rate") {
                history.push(`viewer-detail/${load.id}`, {
                  load_id: load.id,
                  ref_no: load?.ref_no,
                  activeTab: activeCategory,
                });
              } else {
                history.push(`viewer-detail/${load.id}`, {
                  load_id: load.id,
                  ref_no: load?.ref_no,
                  activeTab: activeCategory,
                });
              }
            }, 200);
            let jobLoadRecId = load?.id
            if (!load?.is_seen) {
              if (!is_seen_by_viewer) {
                isShowIndicationDot(jobLoadRecId)
                set_is_seen_viewer(true)
              }
            }
          }
        }}
        className="sectionBox dashboardSectionBox"
      >
        <div className={loadType == "Load Posted" ? "left-container" : "left-container active"}>
          <div className="load-id">
            <div style={{ alignItems: 'center' }} className="loadDetail">
              <p className="fw-6">
                {load?.inquiry_type !== "Contract Rate" ? `Load ID - ${load?.id}` : `Contract ID - ${load?.id}`}
              </p>
              <div style={{
                width: load?.inquiry_type?.id == 2 ? 100 : 100,
                marginLeft: 5
              }} className="fcl-tag">
                <span>{load?.load_type}</span>
              </div>
              <div style={{
                border: "1px solid gray",
                height: 20,
                borderRadius: 15,
                paddingLeft: 5,
                paddingRight: 5,
                marginLeft: 5,
                background: "#fff"
              }} className="flexRow">

                <span style={{ color: Colors.Primary, fontSize: "0.675rem", position: "relative", top: "1px" }} className="icons-truck active"></span>
                <div style={{ fontSize: ".575rem", marginLeft: 5 }} className="fw-5">
                  {load?.counter}
                </div>
              </div>
              <div className="flexRow">
                {
                  load?.is_allocated ?
                    <div className="fcl-tag" style={{ padding: "3px 8px" }}>
                      <img
                        src={Images.AllocatedIcon}
                        style={{ marginLeft: 8 }}
                        alt=""
                      />
                    </div>
                    : null
                }

                {
                  load?.is_forwarded && !load?.parent_id ?
                    <div className="fcl-tag" style={{ padding: "3px 8px" }}>
                      <img
                        src={Images?.ForwardGreenIcon}
                        alt=""
                      />
                    </div>
                    : null
                }
                {
                  load?.parent_id && load?.is_poster ?
                    <div className="fcl-tag">
                      <img
                        src={Images?.ForwardGreenIcon}
                        alt=""
                      />
                      <span style={{ marginLeft: 5 }}>{load?.parent_id}</span>
                    </div> : null

                }
              </div>
              {
                <div style={{
                  width: load?.inquiry_type?.id == 2 ? 100 : 100,
                  marginLeft: 5
                }} className="fcl-tag">
                  <span>{load?.status_name}</span>
                </div>
              }

            </div>
          </div>

          {
            loadType == "viewer" ?
              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                  Added By
                </div>
                <div className="fw-5">
                  {load?.added_by}
                </div>
              </div>
              : null}

          {loadType == "Loads Received" ?
            <>
              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>Load From</div>
                <div className="fw-5">
                  <span style={{ textTransform: "capitalize" }}>{`${load?.poster} `}</span>
                </div>
              </div>

              {load?.poster_system_company || load?.poster_profile_company ? (
                <div className="formGrid">
                  <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>Company Name</div>
                  <div className="fw-5" style={{ textTransform: "capitalize" }}>
                    {load?.poster_system_company || load?.poster_profile_company}
                  </div>
                </div>
              ) : null}
            </> : null
          }

          {
            loadType !== "viewer" ?
              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>

                  {load?.inquiry_type == "Contract Rate" || load?.contract_id ? "Contract Name:" : ""}
                </div>
                <div className="fw-5">
                  {load?.inquiry_type == "Contract Rate" || load?.contract_id ? load?.contract_name : ""}
                </div>
              </div>
              : null
          }

          {
            <div className="formGrid">
              <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>

                {"Load Name:"}
              </div>
              <div className="fw-5">
                {load?.title}
              </div>
            </div>
          }

          {/* {
            load?.contract_id ?
              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                  <span className="titleName">{"Load Name"}</span>
                </div>
                <div className="fw-5">
                  {load?.title}
                </div>
              </div>
              : null} */}

          <div>
            {
              loadType === "viewer" && load?.ref_no ?
                <div className="formGrid">
                  <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                    Customer Ref No.:
                  </div>
                  <div className="fw-5">
                    {load?.ref_no}
                  </div>
                </div>
                : null
            }

          </div>

          {
            loadType == "viewer" && load?.load_type == "Containerized" ?
              <>
                <RenderRTL
                  title={"Total No. of Containers"}
                  val={load?.is_twin_load ? Number(load.vehicle_quantity) * 2
                    : load?.vehicle_quantity}
                />
              </> : null}

          {
            loadType == "viewer" && load?.load_type === "Non-Containerized" ?
              <>
                {
                  load?.quote_by === "vehicle" ?
                    <RenderRTL
                      title={"Total No. of Vehicles "}
                      val={load?.vehicle_quantity}
                    /> :
                    load?.quote_by === "weight_volume" && load?.is_unit_basis ?
                      <RenderRTL
                        title={"Total No. of Items "}
                        val={`${load?.unit_quantity}`}
                      />
                      : load?.no_of_packages ?
                        <RenderRTL
                          title={"Total No. of Packages "}
                          val={`${load?.no_of_packages}`}
                        />
                        : ""
                }
              </> : null
          }

          {
            loadType == "viewer" && load?.quote_by == "weight_volume" && !load?.is_unit_basis ?
              <>
                <RenderRTL
                  title={`Weight / Volume`}
                  val={`${numeral(total_weight).format('0,0.00')} ${load?.price_unit}`}
                />
              </>
              : null
          }

          <div>
            {loadType === "Loads Received" ?
              <div className="formGrid">
                <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                  Received Date & Time:
                </div>
                <div className="fw-5">
                  {`${moment(load?.created_at).format("DD MMM, YYYY hh:mm A")}`}
                </div>
              </div>
              : null}
          </div>

          {/* response deadline  */}
          <div>
            {
              (loadType === "Loads Received") || (loadType === "Loads Posted") ?
                <div className="formGrid">
                  <div className="grayLabel" style={{ marginBottom: '0.25rem' }}>
                    Response Deadline (Date & Time):
                  </div>
                  <div className="fw-5">
                    {`${moment(load?.deadline_at).format("DD MMM, YYYY hh:mm A")}`}
                  </div>
                </div>
                : null
            }
          </div>


          {/* pickup locations  */}
          <div className="formGrid">
            <div className="grayLabel dBox" style={{ marginBottom: '0.25rem' }}>
              Pick-up:
            </div>

            {<div className="fw-5 justifyBetween">
              <div className="fw-5">
                {
                  load?.pickups?.length > 1
                    ? "Multiple " : load?.pickups[0]
                }
              </div>
              <div className="recepient-container">
                {load?.pickups?.length > 1 ?
                  <>
                    <div
                      className="smallBlueLabel"
                      onClick={(e) => {
                        e.stopPropagation()
                        setAllLocations(load?.pickups)
                        set_location_type({
                          type: 'pickup',
                          load_type: load.inquiry_type
                        })
                        set_show_location_modal(true)
                      }}
                    >
                      See All
                    </div>
                  </> : null}
              </div>
            </div>
            }
          </div>

          {/* dropoff locations  */}
          <div className="formGrid">
            <div className="grayLabel dBox" style={{ marginBottom: '0.25rem' }}>
              Drop-off:

            </div>
            <div className="fw-5 justifyBetween">
              <div className="fw-5">
                {
                  load?.dropoffs?.length > 1
                    ? "Multiple" : load?.dropoffs[0]
                }
              </div>
              <div className="recepient-container">
                {load?.dropoffs?.length > 1 ?
                  <>
                    <div
                      className="smallBlueLabel"
                      onClick={(e) => {
                        e.stopPropagation()
                        setAllLocations(load?.dropoffs)
                        set_location_type({
                          type: 'dropoff',
                          load_type: load.inquiry_type
                        })
                        set_show_location_modal(true)
                      }}
                    >
                      See All
                    </div>
                  </> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="textBtn">OPEN</div>
        <TouchRipple ref={rippleRef} center={false} />
      </div >
    );
  };

  return (
    <div className="root-center-container">
      {/* dropdwon load posted, load received */}

      <div className="loads-top-wrappers">
        <BackHeader
          onClick={() => {
            history.goBack()
          }}
          title={"Active Loads"}
        />
        <div className="tabContainerMainWrap">
          <FadeInComponent>
            <div className={`searchContainer ${search ? "filled" : ""}`} style={{ marginBottom: '1rem' }}>
              <input
                className="searchInput"
                onChange={(e) => {
                  setSearch(e.target.value)
                  searchFlag = true
                }}
                type="text"
                autoComplete="off"
                name="search"
                style={{ width: "100%" }}
                value={search}
                placeholder="Search by name or id"
              />
              <span className={`icons-search ${search ? '' : 'active'}`}></span>
              <span
                className={`icons-cross ${search ? 'active' : ''}`}
                onClick={() => {
                  setSearch("")
                }}
              ></span>
            </div>
          </FadeInComponent>

          <FadeInComponent>
            <div className="tab-btn radioTabs threeTab customThreeTabs">
              <div
                className={`three-category-button ${loadType === "Loads Posted" ? 'active' : ''}`}
                onClick={() => {
                  triggerMixpanelEvent("Active Loads Posted Listing", "Click on active load listing")
                  handleChangeLoadType("Loads Posted");
                }}
              >
                Loads Posted
              </div>
              <div
                className={`three-category-button ${loadType === "Loads Received" ? 'active' : ''}`}
                onClick={() => {
                  triggerMixpanelEvent("Active Loads Received Listing", "Click on active load listing")
                  handleChangeLoadType("Loads Received");
                }}
              >
                Loads Received
              </div>

              <div
                className={`three-category-button ${loadType === "viewer" ? 'active' : ''}`}
                onClick={() => {
                  triggerMixpanelEvent("Active Loads viewer Listing", "Click on active load listing")
                  handleChangeLoadType("viewer");
                }}
              >
                View Only
              </div>
              <div className="radioTab_slider"></div>
            </div>
          </FadeInComponent>
        </div>
      </div>

      {/* Loads maping  */}
      <div style={{
        paddingTop: state?.showPostedRecievedTabs ? 110 : 169
      }} className="load-listing-container remCustomPadonMob activeLoadListing">
        <div>
          {
            loads.length ?
              <PullToRefresh
                onRefresh={handleRefresh}
              >
                <InfiniteScroll
                  dataLength={loads.length}
                  next={() => {
                    getActiveLoads({
                      refresh: false,
                      loadType,
                      search,
                    });
                  }}
                  hasMore={active_load_last_page > active_load_current_page}
                  loader={<div className="load-more-loader"> <CircularProgress /></div>}
                  style={{ padding: "0 15px 15px", marginTop: "18px" }}
                  height={'calc(100vh - 243px)'}
                  scrollThreshold={0.8}
                >
                  {loads?.map((load: object, index: number) => {
                    return (
                      <FadeInComponent key={index} >
                        <RenderLoads load={load} />
                      </FadeInComponent>
                    )
                  })}
                </InfiniteScroll>
              </PullToRefresh>
              :
              <div className="network-empty-component">
                <div className="dataPlaceholder">
                  <div className="placeholderContentWrapper">
                    <div className="image">
                      <img src={Images.homeScreenNew} />
                    </div>
                    <p>
                      You have no active loads
                    </p>
                  </div>
                </div>
              </div>
          }
        </div>
      </div>

      {/* see link modal */}
      <div >
        <CustomModal
          showModal={showLink}
          toggleModal={setShowLink}
          modalStyle={{
            width: 290,
          }}
        >
          <div>
            <div className="more-options-heading">
              <CustomText.OpenSansBold
                textStyle={{
                  fontSize: "1.125rem",
                  color: Colors.Black,
                }}
              >
                Awarded Recipients
              </CustomText.OpenSansBold>
              <Close onClick={() => setShowLink(false)} />
            </div>

            {addRecipients?.map((value, index) => {
              return (
                <div key={index} style={{ marginTop: 24, marginLeft: 14 }}>
                  {value ? value : ''}
                </div>
              )
            })}
          </div>
        </CustomModal>
      </div>

      {/* modal for all locations */}
      <div className="allLocationModal">
        <CustomModal
          showModal={show_location_modal}
          toggleModal={set_show_location_modal}
          modalStyle={{ width: 300, minHeight: "unset", alignItems: "unset" }}
        >
          <div>
            <div className="more-options-heading">
              {
                <CustomText.OpenSansBold
                  textStyle={{
                    fontSize: "1.125rem",
                    color: Colors.Black,
                  }}
                >
                  {
                    location_type?.type == 'pickup' ? "Pickup Locations" : "Dropoff Locations"
                  }
                </CustomText.OpenSansBold>
              }
              <Close onClick={() => set_show_location_modal(false)} />
            </div>

            {
              // location_type?.load_type == 'Spot Rate' ? showAllLocations() :
              allLocations?.map((value, index) => {

                return (
                  <div key={index} style={{ marginTop: 24, marginLeft: 14 }}>
                    {location_type?.load_type == 'Spot Rate' ? ` ${index + 1} - ${value}` : `${value}`}
                  </div>
                )
              })
            }
          </div>
        </CustomModal>
      </div>

    </div>
  );
};
export default Page;
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );
  return debouncedValue;
}