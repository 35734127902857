import React from "react";
import { IProps } from "./types";
import "./style.scss";
import { BottomNavigation, BottomNavigationAction } from "@mui/material/";
import { Images } from "@temp/assets";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  sendEventToAndroid,
  sendEventToiOS,
} from "../../utils/mobileEventHandler";
import { CounterAtion, ProfileAction } from "@temp/store/actions";
import { triggerMixpanelEvent } from "@temp/utils/helper";

export const BottomTabs: React.FC<IProps> = ({ activeTab }: IProps) => {
  const history = useHistory();
  const [value, setValue] = React.useState(activeTab);
  const dispatch = useDispatch();
  const UserData = useSelector((state: any) => state?.Profile?.profile || []);
  const callBack = () => {
    history.push("inquiry-type");
  };
  const handleChangeBottomTab = (
    event: React.ChangeEvent<{}>,
    newValue: string
  ) => {
    setValue(newValue);
    switch (newValue) {
      case "home":
        history.push("/home");
        break;
      // case "promotions":
      //   history.push("/promotions");
      //   break;
      case "load-history":
        triggerMixpanelEvent("Loads History Spot Recieved Listing", "Loads History Spot Recieved Listing")
        history.push("/load-history");
        break;
      case "network":
        history.push("/network-new");
        break;
      case "post":
        if (UserData.roles.length && UserData.roles[0].name !== "Limited User") {
          dispatch(ProfileAction.postLoadDataRemove());
          dispatch(CounterAtion.resetPostloadCounterData())
          dispatch(ProfileAction.JobLoadMeta(callBack));
        }
        break;
      case "notifications":
        if (sendEventToAndroid("notificationIconClicked")) {
          window.Android.notificationIconClicked("HELLo");
        } else if (sendEventToiOS("notificationIconClicked")) {
          window.webkit.messageHandlers.notificationIconClicked.postMessage(
            JSON.stringify({
              show_navigation_bar: "hello",
            })
          );
        } else {
          history.push("/notifications");
        }

        break;
    }
  };

  return (
    <div className="bottom-tab-container">
      <div className="insideBottomTab">
        <BottomNavigation
          value={value}
          onChange={handleChangeBottomTab}
          showLabels
          style={{
            justifyContent: 'space-between'
          }}
        >
          <BottomNavigationAction
            label="Home"
            value="home"
            icon={
              // <img
              //   alt=""
              //   className="bottom-icons"
              //   src={value === "home" ? Images.homeActive : Images.homeInActive}
              // />
              <span className={value === "home" ? "icon icons-home active" : "icon icons-home"}></span>
            }
          />
          <BottomNavigationAction
            label="Contacts"
            value="network"
            icon={
              // <img
              //   alt=""
              //   className="bottom-icons"
              //   src={
              //     value === "network"
              //       ? Images.NetworksActive
              //       : Images.NetworksInActive
              //   }
              // />
              <span className={value === "network" ? "icon icons-contact active" : "icon icons-contact"}></span>
            }
          />

          {/* <BottomNavigationAction
            label="Post"
            value="post"
            icon={
              <div className="post-load-circle-icon">
                <img className="post-icon" src={Images.PlusCircle} alt="" />
              </div>
            }
          /> */}

          {/* <BottomNavigationAction
            label="Promotions"
            value="promotions"
            icon={
              // <img
              //   alt=""
              //   className="bottom-icons"
              //   src={value === "promotions" ? Images.PromotionActive : Images.PromotionInActive}
              // />
              <span className={value === "promotions" ? "icon icons-promo active" : "icon icons-promo"}></span>
            }
          /> */}

          <BottomNavigationAction
            label="Load History"
            value="load-history"
            icon={
              // <img
              //   alt=""
              //   className="bottom-icons"
              //   src={value === "promotions" ? Images.PromotionActive : Images.PromotionInActive}
              // />
              <span className={value === "load-history" ? "icon icons-history active" : "icon icons-history"}></span>
            }
          />

          <BottomNavigationAction
            label="Notifications"
            value="notifications"
            className={UserData?.unread_notifications ? "active" : ""}
            icon={
              // <img
              //   alt=""
              //   className="bottom-icons"
              //   src={
              //     value === "notifications"
              //       ? Images.NotificationActive
              //       : Images.NotificationInActive
              //   }
              // />
              <span className={value === "notifications" ? "icon icons-notification active" : "icon icons-notification"}></span>
            }
          />
        </BottomNavigation>
      </div>
    </div>
  );
};

export default BottomTabs;
