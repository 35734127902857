import React, { useState } from 'react';
import './style.scss'
const SimplePhoneInput = ({ value, onChange, onPaste, maxLength, placeholder, id, index }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const preventNonNumericalInput = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div className="phone-input-wrapper">
      <span className={`phone-input-code ${isFocused ? 'phone-input-focused' : ''}`}>
        +92{' '}
        <input
          value={value}
          onChange={onChange}
          onPaste={onPaste}
          id={id || 'phone-number'}
          maxLength={maxLength || 10}
          placeholder={placeholder || '3000000000'}
          type="tel"
          onKeyPress={preventNonNumericalInput}
          inputMode="numeric"
          pattern="\d*"
          className="phone-new-input"
          onFocus={handleFocus}
          onBlur={handleBlur}
          data-index={index}
        />
      </span>
    </div>
  );
};

export default SimplePhoneInput;
