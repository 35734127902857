import { LOAD_TYPE, QUOTE_BY } from "@temp/constants";
import React from "react";
import "../LoadDetailPoster/style.scss";
var numeral = require("numeral");
import { RenderRTL } from "./RenderRTL";

export const TwoColumnRTLContractVehicle = ({
  loadDetail,
  category,
  location,
  type,
  requestVehicle = false,
  is_recipient = false
}) => {

  return (
    <div>
      {
        loadDetail?.quote_by == QUOTE_BY.vehicle ?
          <RenderRTL
            title={"Vehicle Type"}
            sep={"/"}
            urdutitle={"گاڑی کی قسم"}
            val={category?.vehicle?.title}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.quote_by == QUOTE_BY.vehicle && category?.is_twin_load ?
          <RenderRTL
            title={"Twin Load (2x20 Ft)"}
            sep={"/"}
            urdutitle={"ٹوئن لوڈ (2x20 فٹ)"}
            val={"Yes"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.quote_by == QUOTE_BY.vehicle && category?.vehicle_quantity ?
          <RenderRTL
            title={requestVehicle ? "Number of Vehicles" : " Estimated Number of Vehicles"}
            sep={"/"}
            urdutitle={requestVehicle ? "گاڑیوں کی تعداد" : "گاڑیوں کی تخمینی تعداد"}
            val={category?.vehicle_quantity}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.load_type?.id == 1 && category?.vehicle_quantity ?
          <RenderRTL
            title={requestVehicle ? "Number of Containers" : "Estimated Number of Containers"}
            sep={"/"}
            urdutitle={requestVehicle ? "کنٹینرز کی تعداد" : "کنٹینرز کی تخمینی تعداد"}
            val={`${category?.is_twin_load == 1 ? Number(category?.vehicle_quantity) * 2 : category?.vehicle_quantity}`}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.quote_by == QUOTE_BY.vehicle && category?.package_type?.title ?
          <RenderRTL
            title={"Package Type"}
            sep={"/"}
            urdutitle={"پیکیج کی قسم"}
            val={
              category?.package_type?.title == "Other" ? `Other(${category?.package_type_other})`
                : category?.package_type?.title
            }
            is_recipient={is_recipient}
          /> : null
      }

      {
        !loadDetail?.is_unit_basis && category?.max_vehicle_weight ?
          <div className="maxCargoContractorWrap">
            <RenderRTL
              title={loadDetail?.load_type?.title == LOAD_TYPE.fcl ? "Max Cargo Weight Per Vehicle (Without Container)" : "Max Cargo Weight Per Vehicle "}
              sep={"/"}
              urdutitle={loadDetail?.load_type?.title == LOAD_TYPE.fcl ? "زیادہ سے زیادہ سامان کا وزن (کنٹینر وزن کے بغیر)" : "زیادہ سے زیادہ سامان کا وزن"}
              val={category?.max_vehicle_weight + " Ton"}
              is_recipient={is_recipient}
            />
          </div>
          : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk && !loadDetail?.is_unit_basis && loadDetail?.quote_by == "weight_volume" ?
          <RenderRTL
            title={"Gross Weight in KG"}
            sep={"/"}
            urdutitle={"مجموعی وزن (کلو)"}
            val={location?.pickup?.attributes?.gross_weight ? `${numeral(Number(location?.pickup?.attributes?.gross_weight * 1000)).format("0,0.00")} KG` : "N/A"}
            is_recipient={is_recipient}
          /> :
          loadDetail?.load_type?.title == LOAD_TYPE.bulk && loadDetail?.is_unit_basis && loadDetail?.quote_by == "weight_volume" ?
            <RenderRTL
              title={"Gross Weight in KG"}
              sep={"/"}
              urdutitle={"مجموعی وزن (کلو)"}
              val={category?.gross_weight ? `${numeral(Number(category?.gross_weight * 1000)).format("0,0.00")} KG` : "N/A"}
              is_recipient={is_recipient}
            /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk && !loadDetail?.is_unit_basis && loadDetail?.quote_by == "weight_volume" ?
          <RenderRTL
            title={"Gross Weight in Ton"}
            sep={"/"}
            urdutitle={"مجموعی وزن (ٹن)"}
            val={location?.pickup?.attributes?.gross_weight ? `${numeral(location?.pickup?.attributes?.gross_weight).format("0,0.00")} TON` : "N/A"}
            is_recipient={is_recipient}
          /> :
          loadDetail?.load_type?.title == LOAD_TYPE.bulk && loadDetail?.is_unit_basis && loadDetail?.quote_by == "weight_volume" ?
            <RenderRTL
              title={"Gross Weight in Ton"}
              sep={"/"}
              urdutitle={"مجموعی وزن (ٹن)"}
              val={category?.gross_weight ? `${numeral(category?.gross_weight).format("0,0.00")} TON` : "N/A"}
              is_recipient={is_recipient}
            /> : null
      }

      {
        (loadDetail?.load_type?.title == LOAD_TYPE.bulk) &&
          location.pickup.attributes?.cargo_volume && !loadDetail?.is_unit_basis && loadDetail?.quote_by == "weight_volume"
          ?
          <RenderRTL
            title={"Cargo Volume"}
            sep={"/"}
            urdutitle={"کارگو والیوم"}
            val={`${numeral(location.pickup.attributes?.cargo_volume).format("0,0.00")} CBM `}
            is_recipient={is_recipient}
          />
          :
          loadDetail?.load_type?.title == LOAD_TYPE.bulk && loadDetail?.is_unit_basis && loadDetail?.quote_by == "weight_volume" ?
            <RenderRTL
              title={"Cargo Volume"}
              sep={"/"}
              urdutitle={"کارگو والیوم"}
              val={category?.cargo_volume ? `${numeral(category?.cargo_volume).format("0,0.00")} TON` : "N/A"}
              is_recipient={is_recipient}
            /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk && !loadDetail?.is_unit_basis && loadDetail?.quote_by == "weight_volume" ?
          <RenderRTL
            title={
              // "Freight Ton"
              "Total Weight"
            }
            sep={"/"}
            urdutitle={" کل وزن"}
            val={location?.pickup?.attributes?.total_freight_ton > 0 ? `${numeral(location?.pickup?.attributes?.total_freight_ton).format("0,0.00")}
            ${!location?.pickup?.attributes?.cargo_volume ? " TON"
                : " F.TON"}
            ` : "N/A"}
            is_recipient={is_recipient}
          /> : null

      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk && loadDetail?.is_unit_basis && loadDetail?.quote_by == "weight_volume"
          // || requestVehicle 
          ?
          <RenderRTL
            title={
              // "Freight Ton"
              "Total Weight"

            }
            sep={"/"}
            urdutitle={" کل وزن"}
            val={category?.total_freight_ton > 0 ? `${numeral(category?.total_freight_ton).format("0,0.00")}
            ${!category?.cargo_volume ? " TON"
                : " F.TON"}
            ` : "N/A"}
            is_recipient={is_recipient}
          />
          : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.fcl ?
          <RenderRTL
            title={"Container Type"}
            sep={"/"}
            urdutitle={"کنٹینر کی قسم"}
            val={`${category?.container_type?.title}`}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.fcl && category?.is_out_of_guage ?
          <RenderRTL
            title={"Out Of Gauge"}
            sep={"/"}
            urdutitle={"آؤٹ آف گیج"}
            val={`Yes`}
            is_recipient={is_recipient}
          /> : null
      }

      <RenderRTL
        title={"Free Time Required"}
        sep={"/"}
        urdutitle={"فری وقت درکار "}
        val={category?.detention_free_time + " hrs"}
        is_recipient={is_recipient}
      />

      <div className="mandatoryWrap">
        {
          loadDetail?.load_type?.title === "Containerized" && category?.detention_included_transport ?
            <div className="mandatoryDetentionWrap">
              <RenderRTL
                title={"Mandatory Detention included in Transport Price?"}
                sep={"/"}
                urdutitle={"لازمی ڈیٹنشن ٹرانسپورٹ کی قیمت میں شامل ہے؟"}
                val={category?.detention_included_transport ? "YES" : "No"}
                infoMessage={"1 day Mandatory Detention should be selected in cases where detention is applicable due to road restrictions, after expiry of free period"}
                is_recipient={is_recipient}
              /></div> : null
        }
      </div>



      {loadDetail?.is_unit_basis ||
        category?.container_type?.provide_temperature_control == 0 || category?.vehicle?.provide_temperature_control == 0
        &&
        category?.required_temperature_min == 0 && category?.required_temperature_max == 0 ? null :
        <div className="tempDataLoad">
          <RenderRTL
            title={"Required Temperature"}
            sep={"/"}
            urdutitle={"مطلوبہ درجہ حرارت"}
            val={`Min: ${category?.required_temperature_min}`}
            val2={`Max: ${category?.required_temperature_max}`}
            is_recipient={is_recipient}
          />
        </div>
      }

      {
        // !loadDetail?.is_unit_basis ||
        category?.is_genset ||
          category?.container_type?.provide_genset == 1 || category?.vehicle?.provide_genset == 1 ?
          <RenderRTL
            title={"Genset Required"}
            sep={"/"}
            val={category?.is_genset ? "Yes" : "No"}
            is_recipient={is_recipient}
            urdutitle={"جنریٹر کی ضرورت ہے"}
          /> : null
      }

      {
        category?.max_bid ?
          <RenderRTL
            title={"Max Bid (Without GST)"}
            sep={"/"}
            urdutitle={"زیادہ سے زیادہ بولی  (GST کے بغیر)"}
            val={category?.is_unit_basis ? numeral(Number(category?.max_bid)).format("0,0.00") + " PKR"
              :
              numeral(Number(category?.max_bid)).format("0,0") + " PKR"

            }
            is_recipient={is_recipient}
          /> : null
      }

      {
        category?.lowest_bid && type !== "received" ?
          <RenderRTL
            title={"Min Bid (Without GST)"}
            sep={"/"}
            urdutitle={"کم سے کم بولی (GST کے بغیر)"}
            val={numeral(Number(category?.lowest_bid)).format("0,0") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.transaction_type?.title == "Offer" ?
          <RenderRTL
            title={"Detention Rate (Without GST)"}
            sep={"/"}
            urdutitle={"ڈیٹنشن ریٹ (GST کے بغیر)"}
            val={category?.is_unit_basis ? numeral(Number(category?.detention_rate)).format("0,0.00") + " PKR"
              :
              numeral(Number(category?.detention_rate)).format("0,0") + " PKR"
            }
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.fcl && loadDetail?.transaction_type?.title == "Offer" ?
          <RenderRTL
            title={"Price/Vehicle (Without GST)"}
            sep={"/"}
            urdutitle={"ریٹ  / فی گاڑی  (GST کے بغیر)"}
            val={numeral(Number(category?.offer_price_per_vehicle)).format("0,0") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        category?.is_shipper_owned_container ?
          <RenderRTL
            title={"Shipper Owned Container(SOC)"}
            sep={"/"}
            urdutitle={"شپپر کی ملکیت والا کنٹینر (SOC)"}
            val={"Yes"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        // loadDetail?.load_type?.title == LOAD_TYPE.bulk && 
        category?.is_loading_required ?
          <RenderRTL
            title={"Loading Required"}
            sep={"/"}
            urdutitle={"لوڈنگ"}
            val={"Yes"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        // loadDetail?.load_type?.title == LOAD_TYPE.bulk && 
        category?.is_loading_required && category?.loading_requirements ?
          <RenderRTL
            title={"Specify Labour / Loading Requirement"}
            sep={"/"}
            urdutitle={"لوڈنگ درکار ہے۔"}
            val={category?.loading_requirements}
            is_recipient={is_recipient}
          /> : null
      }

      {
        // loadDetail?.load_type?.title == LOAD_TYPE.bulk && 
        category?.is_unloading_required ?
          <RenderRTL
            title={"Unloading Required"}
            sep={"/"}
            urdutitle={"ان لوڈنگ"}
            val={"Yes"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        // loadDetail?.load_type?.title == LOAD_TYPE.bulk  &&
        category?.is_unloading_required && category?.unloading_requirements ?
          <RenderRTL
            title={"Specify Labour / Unloading Requirement"}
            sep={"/"}
            urdutitle={"ان لوڈنگ درکار ہے۔"}
            val={category?.unloading_requirements}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.transaction_type?.title == "Offer" ?
          <RenderRTL
            title={"Total Offer Price (Without GST)"}
            val={
              loadDetail?.is_unit_basis ?
                numeral(Number(category?.total_offer_price)).format("0,0.00") + " PKR"
                :
                numeral(Number(category?.total_offer_price)).format("0,0") + " PKR"}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk && category?.is_unit_basis ?
          <RenderRTL
            title={"Dimensions"}
            sep={"/"}
            urdutitle={"پیمائش"}
            val={`${category?.dimensions} ${category?.unit_dimension_value}`}
            is_recipient={is_recipient}
          /> : null
      }

      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk && category?.unit_quantity ?
          <RenderRTL
            title={"Item Quantity"}
            sep={"/"}
            urdutitle={"آئٹم کی مقدار"}
            val={`${category?.unit_quantity}`}
            is_recipient={is_recipient}
          /> : null
      }
      {
        loadDetail?.load_type?.title == LOAD_TYPE.bulk && category?.unit_description ?
          <RenderRTL
            title={"Item Description"}
            sep={"/"}
            urdutitle={"آئٹم کی تفصیل"}
            val={`${category?.unit_description}`}
            is_recipient={is_recipient}
          /> : null
      }
    </div>
  );
};
