import React, { useState, useRef, useEffect } from "react";
import { Typography } from "@mui/material/";
import {
  OutLineButton,
  PrimaryButton,
  BackHeader,
  CustomText,
  SignupContainer,
} from "@components";

import "./style.scss";
import { requestFirebaseNotificationPermission } from "../../../utils/firebaseNew.js"
import { Colors, Images } from "@temp/assets";
import { useLocation } from "react-router-dom";
import { AuthAction, ProfileAction } from "@temp/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { setItem, showMessage, deviceId } from "@utils/helper";
import { TOKEN } from "@app/constants";
import DialogTitle from "@mui/material//DialogTitle";
import Dialog from "@mui/material//Dialog";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import { OTPScreenProps } from "./types";
import rootReducer from "../../../store/reducers";
export type RootState = ReturnType<typeof rootReducer>;
import { history } from "@temp/history";
import { Mixpanel } from "@temp/utils/mixpanel";
const Page: React.FC<OTPScreenProps> = (props) => {
  const { state } = useLocation();


  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const [seconds, setSeconds] = useState(60);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [numberMode, setNumberMode] = useState(
    state?.type === "sms" ? "sms" : "whatsapp"
  );

  const [showModal, setShowModal] = useState(false);
  const attempt = useSelector((state: RootState) => state.Auth.attempt);
  const metaData = useSelector((state: RootState) => state.Auth.metaData);

  let format_num = state?.phoneNumber
  let new_phone = format_num?.split("");
  new_phone?.splice(3, 0, " ");
  let joined_no = new_phone?.join("");

  let format_num_1 = state?.whatsapp
  let new_phone_1 = format_num_1?.split("");
  new_phone_1?.splice(3, 0, " ");
  let joined_no_1 = new_phone?.join("");

  // interface OTPCredentialRequestOptions extends CredentialRequestOptions {
  //   otp?: {
  //     transport: string[];
  //   };
  // }
  // interface OTPCredential {
  //   code: string;
  // }

  //   const handleChange = (setOtp, nextRef, value) => {
  //     if (!/^[0-9]$/.test(value) && value !== '') return; // Allow only digits

  //     setOtp(value);

  //     // Focus on the next input
  //     if (value !== '' && nextRef) {
  //         nextRef.current.focus();
  //     }
  // };
  //   // Auto Fill SMS OTP using navigator WebOTP API
  //   useEffect(() => {
  //     if ('OTPCredential' in window) {
  //       const ac = new AbortController();
  //       const signal = ac.signal;
  //       navigator.credentials
  //         .get({
  //           otp: { transport: ['sms'] },
  //           signal,
  //         } as OTPCredentialRequestOptions)
  //         .then((otpCredential) => {
  //           const otpCode = otpCredential?.code;
  //           if (otpCode && otpCode.length === 4) {
  //               setOtp1(otpCode[0]);
  //               setOtp2(otpCode[1]);
  //               setOtp3(otpCode[2]);
  //               setOtp4(otpCode[3]);

  //               // Automatically focus the last input box
  //               inputRef3.current.focus();
  //           }
  //         })
  //         .catch((err) => {
  //           console.error('Error fetching navigator WebOTP: ', err);
  //         });
  //       // Cleanup on unmount component
  //       return () => ac.abort();
  //     }
  //   }, []);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  });

  function preventNonNumericalInput(e) {
    e = e || window.event;
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  }

  const callBack = (data: any) => {

    // return
    dispatch(AuthAction.SetAttempt({ attempt: false }));
    if (data.isOTPVerified) {
      if (state?.isForgot) {
        // setItem("toc_accepted", true);
        setItem("temp_token", data?.token);
        history.replace("/setup-password", { isForgot: true });
      } else {
        if (!state?.isUserExists) {
          history.replace("/registration", {
            phoneNumber: data.phoneNumber,
            whatsapp: state.whatsapp,
            mobileNetwork: data.mobileNetwork
          });
        } else {
          if (
            data?.user?.is_toc_accepted === 1 &&
            data?.user?.password_changed === 1
          ) {
            let user_profession = ""
            data?.user?.user_professions?.forEach((item) => {
              user_profession = user_profession + `${item?.name}, `
            })


            Mixpanel.identify(data?.user?.id);
            Mixpanel.track("Successful login", null)
            Mixpanel.people.set({
              $first_name: data?.user?.name,
              $email: data?.user?.email,
              $avatar: data?.user?.picture,
              $company_name: data?.user?.company_name,
              $user_professions: user_profession,
              $business_card_front: data?.cnic_front_url,
              $business_card_back: data?.cnic_back_url,
              $city: data?.user?.city,
              $phone_number: data?.user?.phone_no ? `0${data?.user?.phone_no}` : null,
              $whatsapp_number: data?.user?.whatsapp_phone_no ? `0${data?.user?.whatsapp_phone_no}` : null
            });
            // return
            setItem(TOKEN, data?.token);
            setItem("toc_accepted", true);
            setItem("changed_password", true);
            handleGetToken();

            history.replace("/home");
          } else if (
            data?.user?.is_toc_accepted == 0 &&
            data?.user?.password_changed == 0
          ) {
            setItem(TOKEN, data?.token);
            history.replace("/terms");
          } else if (data?.user?.is_toc_accepted === 0) {
            setItem(TOKEN, data?.token);
            history.replace("/terms");
          } else if (data?.user?.password_changed === 0) {
            setItem("toc_accepted", true);
            setItem(TOKEN, data?.token);
            history.replace("/setup-password");
          }
        }
      }
    } else {
      showMessage.current({
        message:
          // "Otp not sent, please try again",
          "OTP not sent, please try again",
        status: "error",
      });
    }
  };

  const callBackResendOtp = (data: any) => {

    if (data.isOTPSent) {
      showMessage.current({
        message:
          //  "Otp has been successfully sent",
          "OTP has been sent successfully",
        status: "success",
      });
    } else {
      showMessage.current({
        message:
          // "Otp not sent, please try again",
          "OTP not sent, please try again",
        status: "error",
      });
    }
  };
  const handleGetToken = async () => {
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {

        // eslint-disable-next-line no-console

        dispatch(
          ProfileAction.updateFCM({
            fcm_token: firebaseToken,
            device_id: deviceId(),
          })
        );
      })
      .catch((err) => {

        return err;
      });
  };

  const callbackChangePhoneNo = () => {
    history.replace("/home");
    handleGetToken();
  };

  const verifyCode = (code: any) => {
    dispatch(AuthAction.SetAttempt({ attempt: true }));
    const payload = {
      otpCode: `${otp1}${otp2}${otp3}${otp4 || code}`,
      type: state?.type,
      phoneNumber: state?.phoneNumber,
      from: state?.from,
      isUserExists: state?.isUserExists,
      mobileNetwork: state?.mobileNetwork,

      cb:
        history?.location?.pathname === "/change-phone-otp"
          ? callbackChangePhoneNo
          : callBack,
      isForgot: state?.isForgot,
    };
    if (history?.location?.pathname === "/change-phone-otp") {
      dispatch(ProfileAction.changePhoneNumber(payload));
    } else {
      dispatch(AuthAction.VerifyCode(payload));
    }
  };

  const callBackDelete = () => {

    setItem("token", null);
    setItem("toc_accepted", null);
    setItem("changed_password", null);
    dispatch(AuthAction.resetStore());
    window.location.reload();
  };

  const deleteUser = (code) => {
    const payload = {
      "phone_code": "92",
      "phone_no": state?.phoneNumber,
      "otp_method": "sms",
      "otp_no": `${otp1}${otp2}${otp3}${otp4 || code}`,
      "reason": state?.reason,
      callBackDelete
    }

    dispatch(AuthAction.DelAccount(payload))
  }

  const resendCode = (type: string) => {
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    inputRef.current.focus();
    const payload = {
      phoneNumber: state?.phoneNumber,
      mobileNetwork: state?.mobileNetwork,
      type: type,
      from: state?.from,
      cb: callBackResendOtp,
    };
    if (history?.location?.pathname === "/change-phone-otp") {
      dispatch(ProfileAction.changePhoneNumberRequest(payload));
    } else {
      dispatch(AuthAction.LogInWihtOtp(payload));
    }
    setSeconds(60);
    if (type === "sms") {
      setNumberMode("sms");
    } else {
      setNumberMode("whatsapp");
    }
  };

  const handleContactMg = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="otp-wrapper">
      <BackHeader
        title="Verification Code"
        onClick={() => {
          history.goBack();
        }}
      />
      <SignupContainer containerHeight="">
        <div className="insideOTPContainer">
          <div className="signupOtpScreenWrapper">
            <div
              style={{
                marginTop: 24,
              }}
            >
              {numberMode === "sms" ? (
                <div>
                  <CustomText.OpenSansSemiBold
                    textStyle={{
                      fontSize: "0.75rem",
                      color: Colors.Black,
                    }}
                  >
                    {`Please enter the code sent to 0${joined_no ? joined_no : joined_no_1}`}
                  </CustomText.OpenSansSemiBold>
                </div>
              ) : (
                <div className="whatsapp">
                  <CustomText.OpenSansSemiBold
                    textStyle={{
                      fontSize: "0.75rem",
                      color: Colors.Black,
                    }}
                  >
                    {"Please enter the code sent to  "}
                  </CustomText.OpenSansSemiBold>
                  <CustomText.OpenSansSemiBold textStyle={{
                    marginLeft: 2
                  }}>
                    {`0${joined_no_1 ? joined_no_1 : joined_no}`}
                  </CustomText.OpenSansSemiBold>
                </div>
              )}
            </div>
            <div className="otpFieldWrapper">
              <input
                autoFocus
                ref={inputRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 8) {
                    setOtp1("");
                  }
                }}
                value={otp1}
                maxLength={1}
                onChange={(e) => {
                  if (otp1.length >= 1) {
                    return;
                  } else {
                    setOtp1(e.target.value);
                    // handleChange(setOtp1, inputRef1, e.target.value)
                  }
                  inputRef1.current?.focus();
                }}
                style={{
                  width: 60,
                  border: 0,
                  textAlign: "center",
                }}
                className="otp-input"
                type="text"
                pattern="[0-9]*"
                onKeyPress={(event) => preventNonNumericalInput(event)}
              />
              <input
                value={otp2}
                ref={inputRef1}
                onKeyDown={(e) => {
                  if (e.keyCode === 8) {
                    setOtp2("");
                    inputRef.current.focus();
                  }
                }}
                style={{
                  width: 60,
                  border: 0,
                  textAlign: "center",
                }}
                onChange={(e) => {
                  if (otp2.length >= 1) {
                    return;
                  } else {
                    setOtp2(e.target.value);
                    // handleChange(setOtp2, inputRef2, e.target.value)

                  }
                  inputRef2.current?.focus();
                }}
                className="otp-input"
                type="text"
                pattern="[0-9]*"
                onKeyPress={(event) => preventNonNumericalInput(event)}
                maxLength={1}
              />
              <input
                value={otp3}
                ref={inputRef2}
                onKeyDown={(e) => {
                  if (e.keyCode === 8) {
                    setOtp3("");
                    inputRef1.current.focus();
                  }
                }}
                style={{
                  width: 60,
                  border: 0,
                  textAlign: "center",
                }}
                onChange={(e) => {
                  if (otp3.length >= 1) {
                    return;
                  } else {
                    setOtp3(e.target.value);
                    // handleChange(setOtp3, inputRef3, e.target.value)

                  }
                  inputRef3.current?.focus();
                }}
                className="otp-input"
                type="text"
                pattern="[0-9]*"
                onKeyPress={(event) => preventNonNumericalInput(event)}
                maxLength={1}
              />
              <input
                value={otp4}
                ref={inputRef3}
                onKeyDown={(e) => {
                  if (e.keyCode === 8) {
                    setOtp4("");
                    inputRef2.current.focus();
                  }
                }}
                style={{
                  width: 60,
                  border: 0,
                  textAlign: "center",
                }}
                onChange={(e) => {
                  if (otp4.length >= 1) {
                    return;
                  } else {
                    setOtp4(e.target.value);
                    // handleChange(setOtp4, null, e.target.value)

                  }
                  if (state?.reason) {
                    deleteUser(e.target.value)
                  } else {
                    verifyCode(e.target.value);
                  }

                }}
                className="otp-input"
                type="text"
                pattern="[0-9]*"
                onKeyPress={(event) => preventNonNumericalInput(event)}
                maxLength={1}
              />
            </div>

            <div>
              <p
                style={{
                  fontSize: "0.75rem",
                  textAlign: "center",
                  color: Colors.Grayish,
                }}
              >
                {`Resend code if it didn't arrive in 00:${seconds < 10 ? `0${seconds}` : seconds
                  }`}
              </p>
            </div>
          </div>
          <div className="bottomSignupScreen">
            <div className="insideSignupBtnWrap">
              <div className="generalLineDiv">
                <Typography
                  variant="h6"
                  component="h2"
                  style={{
                    fontSize: "0.75rem",
                    textAlign: "center",
                    color: "#a3a3a3",
                  }}
                >
                  Didn't receive a code?
                </Typography>
              </div>
              <div className="signupBtns">
                <OutLineButton
                  icon={
                    seconds > 0
                      ? Images.MessageBubbleGray
                      : Images.MessageBubble
                  }
                  disabled={seconds > 0}
                  title="Resend OTP via SMS"
                  onClick={() => {
                    resendCode("sms");
                  }}
                />
              </div>
              {attempt >= 2 && (
                <div
                  onClick={handleContactMg}
                  style={{
                    width: "350px",
                    height: "50px",
                    color: "#7ac251",
                    fontFamily: "OpenSans-SemiBold",
                    fontSize: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    cursor: "pointer",
                    border: 0,
                  }}
                >
                  Contact MG Team
                </div>
              )}
            </div>
          </div>
        </div>
      </SignupContainer>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={showModal}
      >
        <DialogTitle id="simple-dialog-title">
          <CustomText.OpenSansSemiBold textStyle={{ fontSize: "1.125rem" }}>
            Please Contact MG Team
          </CustomText.OpenSansSemiBold>
          <div className="mg-contact-info">
            {/* <img src={Images.WhatsappGreen} /> */}
            <CallIcon
              style={{
                color: "rgb(122,194,81)",
                height: 20,
              }}
            />
            <CustomText.OpenSansRegular
              textStyle={{ fontSize: "0.875rem", color: Colors.Black, marginLeft: 10 }}
            >
              {metaData?.administration_contact?.phone}
            </CustomText.OpenSansRegular>
          </div>
          <div className="mg-contact-info">
            {/* <img src={Images.WhatsappGreen} /> */}
            <EmailIcon style={{ color: "rgb(122,194,81)", height: 20 }} />
            <CustomText.OpenSansRegular
              textStyle={{ fontSize: "0.875rem", color: Colors.Black, marginLeft: 10 }}
            >
              {metaData?.administration_contact?.email}
            </CustomText.OpenSansRegular>
          </div>
          <div style={{ marginTop: 25, marginBottom: 30 }}>
            <PrimaryButton
              onClick={handleClose}
              title="OK"
              buttonStyle={{
                height: 40,
                width: 262,
              }}
            />
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default Page;
